// @ts-nocheck
import * as React from "react";
import { Modal } from "antd";
import { DEFAULT_MODAL_WIDTH_MD } from "../../../constants/ui";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";

interface DisablePspUserConfirmationProps {
  visible: boolean;
  handleCancel: (e: any) => void;
  handleUserUnassignBusinessUnit: (e: any) => void;
  name?: string;
  match?: any;
  member?: any;
}

const DisablePspUserConfirmationModal: React.FC<
  DisablePspUserConfirmationProps
> = ({
  visible,
  name,
  handleUserUnassignBusinessUnit,
  handleCancel,
  match,
  member,
}) => {
  return (
    <Modal
      visible={visible}
      width={DEFAULT_MODAL_WIDTH_MD}
      onCancel={handleCancel}
      footer={[
        <SimpleButton
          key="a"
          type="primary"
          buttonText="Unassign Business Units"
          ignoreStatus={true}
          onClick={() => handleUserUnassignBusinessUnit({ match, member })}
        />,
        <SimpleButton
          key="b"
          buttonText="Cancel"
          ignoreStatus={true}
          onClick={handleCancel}
        />,
      ]}
    >
      {name} has been assigned business units. Please unasssign the business
      units before disabling the user.
    </Modal>
  );
};
export default DisablePspUserConfirmationModal;
