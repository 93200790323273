import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { validateWhitespacesAsEmpty } from '@xemplo/common-util';
import { ChangeTaskTypes } from '@xemplo/gp-types';
import { TextAreaV2 } from '@xemplo/text-area';

import * as S from '../../modal.styles';

export const Notes = () => {
  const { register, watch } = useFormContext();

  const watchNotes = watch('notes');
  const watchChangeTask = watch('changeTask');
  const isPayInstruction = watchChangeTask === ChangeTaskTypes.PAY_INSTRUCTION;
  useEffect(() => {
    register('notes', {
      required: isPayInstruction ? 'This field is required' : false,
      maxLength: {
        value: 2048,
        message: 'Must be less than 2048 characters',
      },
      validate: (value) => (isPayInstruction ? validateWhitespacesAsEmpty(value) : true),
    });
  }, [isPayInstruction, register]);

  return (
    <S.FormLabel>
      <div>
        {isPayInstruction ? (
          'Instructions'
        ) : (
          <>
            Notes <S.OptionalLabel>(optional)</S.OptionalLabel>
          </>
        )}
      </div>
      <TextAreaV2
        placeholder="Enter notes"
        defaultValue={watchNotes}
        testId="notes"
        aria-label="Notes field"
        name="notes"
        stretch
        rows={4}
      />
    </S.FormLabel>
  );
};
