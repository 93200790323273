import styled from 'styled-components';

import { BodyStandardRegular, Colour, MinBreakpoint } from '@xemplo/style-constants';

import { ModalBodyOverflow } from './body.types';

export const StyledModalBody = styled.div<{ $textColor?: string }>`
  padding: 40px 24px;
  scroll-behavior: smooth;
  color: ${({ $textColor }) => $textColor ?? Colour.Gray[600]};
  ${BodyStandardRegular};
  overflow: auto;

  @media ${MinBreakpoint.medium} {
    padding: 40px;
    &[data-overflow=${ModalBodyOverflow.Extended}] {
      overflow: visible;
    }
  }
`;
