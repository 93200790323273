//@ts-nocheck
import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Checkbox,
  message,
  Popconfirm,
} from "antd";
import ApplicableFeesOptions from "../common/feeOption";
import StateRate from "../common/stateRate";
import EmployeeRate from "../common/employeeRate";
import _ from "lodash";
import {
  mustBeArray,
  errorDisplay,
  delay,
  getMatchParams,
  errorHandler,
  getOptionalParameter,
  getConditionalResponse,
} from "../../../libs/utilities";
import {
  isEmployeeRangeValid,
  isProcessingFeesValid,
  isRateRequired,
} from "../../../libs/validations";
import PayCategory from "../common/payCategory";
import BaseSalary from "../common/baseSalary";
import {
  END_EMPLOYEE_NUMBER,
  PROCESSING_FEES_OPTIONS,
} from "../../../constants/payrun";

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class paySchedule extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      feeOptions: PROCESSING_FEES_OPTIONS,
      selectedOptions: [],
      employeeNumberRangeFactor: [],
      amendments: [],
      earningLines: [],
      states: [],
      defaultAmendments: [],
      defaultEarningLines: [],
      defaultExpenses: [],
      validRange: [],
      expenses: [],
      expensesLoading: false,
      checkStateRateConfirmation: false,
    };
  }
  componentDidMount() {
    const { businessunitid } = this.props;
    if (businessunitid) {
      this.fetchAllLookups(businessunitid);

      this.props.getBusinessUnit({ id: businessunitid }).then((resp) => {
        if (resp.status && resp.data) {
          this.props
            .populateStates({ options: { countryId: resp.data.countryId } })
            .then((res) => {
              if (res.status) {
                this.setState({ states: res.data });
              } else {
                errorHandler({ response: res, hasValidationErrors: true });
              }
            });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.props.visible) {
      this.props.form.resetFields();
      this.cancelConfirmation();
      if (nextProps.visble) {
        this.setState({ submitLoading: false });
      }
    }
  }

  fetchAllLookups = (id) => {
    this.props
      .earningLinesLookup({ options: {}, businessUnitID: id })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            earningLines: resp.data,
            defaultEarningLines: resp.data,
          });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });

    this.props
      .listAmendmentTypes({ options: {}, businessUnitID: id, lookUp: true })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            amendments: resp.data,
            defaultAmendments: resp.data,
          });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });

    this.props
      .payCategoryLookUp({ options: { q: { businessUnitID: id } } })
      .then((resp) => {
        if (resp.status) {
          this.setState({ expenses: resp.data, defaultExpenses: resp.data });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  addEmployeeRange = () => {
    this.cancelConfirmation();

    if (_.isEmpty(this.state.employeeNumberRangeFactor)) {
      this.setState((prevState) => {
        prevState.employeeNumberRangeFactor = [{ from: 1, to: "", rate: "" }];
        prevState.validRange = [{ isValid: false }];
        return prevState;
      });
    } else {
      const previousRange =
        this.state.employeeNumberRangeFactor[
          this.state.employeeNumberRangeFactor.length - 1
        ];

      this.setState((prevState) => {
        prevState.employeeNumberRangeFactor = _.concat(
          prevState.employeeNumberRangeFactor,
          [{ from: Number(previousRange.to) + 1, to: "", rate: "" }]
        );
        prevState.validRange = [
          ...mustBeArray(prevState.validRange),
          { isValid: false },
        ];
        return prevState;
      });
    }
  };

  deleteEmployeeRange = () => {
    this.cancelConfirmation();

    this.setState((prevState) => {
      prevState.employeeNumberRangeFactor.pop();
      prevState.validRange.pop();
      return prevState;
    });
  };

  updateEmployeeNumber = (label, i, value) => {
    this.cancelConfirmation();
    const testPattern = /^[0-9.]+$/;

    this.setState((prevState) => {
      if (label === "rate") {
        prevState.employeeNumberRangeFactor[i][label] =
          !value || testPattern.test(Number(value))
            ? value
            : prevState.employeeNumberRangeFactor[i][label];
      } else {
        prevState.employeeNumberRangeFactor[i][label] = value;
      }

      if (
        label === "to" &&
        prevState.employeeNumberRangeFactor[i + 1] &&
        Number(value) >= prevState.employeeNumberRangeFactor[i + 1].from
      ) {
        prevState.employeeNumberRangeFactor[i + 1].from = Number(value) + 1;
      }
      if (
        label === "to" &&
        prevState.employeeNumberRangeFactor[i + 1] &&
        Number(value) <
          Number(prevState.employeeNumberRangeFactor[i + 1].from) - 1
      ) {
        prevState.employeeNumberRangeFactor[i + 1].from = Number(value) + 1;
      }
      return prevState;
    });
  };

  updateIsValid = (value, index) => {
    this.setState((prevState) => {
      prevState.validRange[index].isValid = value;
      return prevState;
    });
  };

  changeFeeOptions = (value) => {
    if (Number(value) === 3) {
      this.setState({
        employeeNumberRangeFactor: [],
        expenses: [...mustBeArray(this.state.defaultExpenses)],

        earningLines: [...mustBeArray(this.state.defaultEarningLines)],
      });

      this.props.form.resetFields(["applyStateRate", "addEmployeeNumber"]);
    }
  };

  updateStatesRate = (value, index) => {
    this.cancelConfirmation();
    const testPattern = /^[0-9.]+$/;
    if (!value || testPattern.test(Number(value))) {
      this.setState((prevState) => {
        prevState.states[index].rate = value;
        return prevState;
      });
    }
  };

  handleChangeCheckbox = (value, label) => {
    this.cancelConfirmation();
    switch (label) {
      case "addEmployeeNumber":
        this.setState((prevState) => {
          if (!value) {
            prevState.employeeNumberRangeFactor = [
              { from: 1, to: "", rate: "" },
            ];
            prevState.validRange = [{ isValid: false }];
            return prevState;
          }
          prevState.employeeNumberRangeFactor = [];
          prevState.validRange = [];
          return prevState;
        });
        break;
      case "applyStateRate":
        this.setState((prevState) => {
          prevState.states = _.map(prevState.states, (o) => {
            return { ...o, rate: 0 };
          });
          return prevState;
        });
        break;
      default:
        break;
    }
  };

  resetProcessingFees = () => {
    this.setState((prevState) => {
      prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
      prevState.earningLines = [...mustBeArray(prevState.defaultEarningLines)];
      prevState.states = _.map(prevState.states, (o) => {
        return { ...o, rate: "" };
      });
      prevState.employeeNumberRangeFactor = [];
      return prevState;
    });
  };

  cancelProcessingFees = () => {
    this.setState((prevState) => {
      this.props.form.setFieldsValue({
        feeOption: "",
        invoiceDescription: "",
        feeValue: "",
        tax: "",
      });
      prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
      prevState.earningLines = [...mustBeArray(prevState.defaultEarningLines)];
      prevState.states = _.map(prevState.states, (o) => {
        return { ...o, rate: 0 };
      });
      prevState.submitLoading = false;
      return prevState;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;

    this.props.form.validateFields((err, values) => {
      if (err) {
        return this.setState({ submitLoading: false });
      }
      let updatedEmployeeRangeFactor = form.getFieldValue("addEmployeeNumber")
        ? _.map(this.state.employeeNumberRangeFactor, (o) => {
            return { ...o, rate: Number(o.rate) };
          })
        : [];

      let updatedStateRate = _.map(this.state.states, (o) => {
        return { rate: Number(o.rate), stateName: o.value, stateId: o.id };
      });

      const validProcessingFees = isProcessingFeesValid({
        feeOption: form.getFieldValue("feeOption"),
        tax: form.getFieldValue("tax"),
        feeValue: form.getFieldValue("feeValue"),
        applyStateRate: form.getFieldValue("applyStateRate"),
        invoiceDescription: form.getFieldValue("invoiceDescription"),
        updatedStateRate,
      });
      const validEmployeeRange = isEmployeeRangeValid({
        updatedEmployeeRangeFactor,
        feeOption: form.getFieldValue("feeOption"),
        addEmployeeNumber: form.getFieldValue("addEmployeeNumber"),
        validRange: this.state.validRange,
      });
      if (!validProcessingFees || !validEmployeeRange) return;
      if ([1, 2].includes(Number(form.getFieldValue("feeOption")))) {
        // validation at least one field is selected

        const isEarninglineSelected = !(
          _.isEmpty(this.props.form.getFieldValue("earningLines")) ||
          _.isUndefined(this.props.form.getFieldValue("earningLines"))
        );

        const isPayCategorySelected = !(
          _.isEmpty(this.props.form.getFieldValue("payCategory")) ||
          _.isUndefined(this.props.form.getFieldValue("payCategory"))
        );

        const isExpenseCategorySelected = !(
          _.isEmpty(this.props.form.getFieldValue("expenseCategory")) ||
          _.isUndefined(this.props.form.getFieldValue("expenseCategory"))
        );

        if (
          [1].includes(Number(form.getFieldValue("feeOption"))) &&
          !this.props.form.getFieldValue("basePayChecked") &&
          isEarninglineSelected &&
          isPayCategorySelected &&
          isExpenseCategorySelected
        ) {
          this.setState({ submitLoading: false });
          return message.error(
            "Please select at least one item from Base pay, Earninglines, Pay category and Expenses"
          );
        }
        if (
          form.getFieldValue("applyStateRate") &&
          _.find(
            updatedStateRate,
            (o) => _.isNaN(o.rate) || Number(o.rate) === 0
          )
        ) {
          return this.setState({
            submitLoading: false,
            checkStateRateConfirmation: true,
          });
        }
      }

      this.submitProcessingFees();
    });
  };

  submitProcessingFees = () => {
    this.setState({ submitLoading: true, checkStateRateConfirmation: false });

    let updatedEmployeeRangeFactor = this.props.form.getFieldValue(
      "addEmployeeNumber"
    )
      ? _.map(this.state.employeeNumberRangeFactor, (o) => {
          return { ...o, rate: Number(o.rate) };
        })
      : [];
    if (
      _.last(mustBeArray(updatedEmployeeRangeFactor)) &&
      !_.last(mustBeArray(updatedEmployeeRangeFactor)).to
    ) {
      updatedEmployeeRangeFactor[updatedEmployeeRangeFactor.length - 1].to =
        END_EMPLOYEE_NUMBER;
    }

    let updatedStateRate = _.map(this.state.states, (o) => {
      return {
        rate: _.isNaN(Number(o.rate)) ? 0 : Number(o.rate),
        stateName: o.value,
        stateId: o.id,
      };
    });

    let updatedAmendments = _.map(this.state.amendments, (o) => {
      return {
        ...o,
        checked: getOptionalParameter({
          value1: mustBeArray(
            this.props.form.getFieldValue("payCategory")
          ).includes(o.id),
          value2: mustBeArray(
            this.props.form.getFieldValue("payCategory")
          ).includes("all"),
        }),
      };
    });

    let updatedEarninglines = _.map(this.state.earningLines, (o) => {
      return {
        ...o,
        checked: mustBeArray(
          this.props.form.getFieldValue("earningLines")
        ).includes(o.id),
      };
    });
    let updatedExpenses = _.map(this.state.expenses, (o) => {
      return {
        ...o,
        checked: mustBeArray(
          this.props.form.getFieldValue("expenseCategory")
        ).includes(o.id),
      };
    });
    const { form } = this.props;
    let selectedFieldOption = Number(form.getFieldValue("feeOption"));
    let newOption = {
      name: _.find(
        this.state.feeOptions,
        (option) => option.id === selectedFieldOption
      )?.name,
      feeOptionId: selectedFieldOption,
      rateOrPercentage: getConditionalResponse({
        condition:
          [1, 2].includes(selectedFieldOption) &&
          form.getFieldValue("applyStateRate"),
        resp1: 0,
        resp2: form.getFieldValue("feeValue"),
      }),
      invoiceDescription: form.getFieldValue("invoiceDescription"),
      taxFeeId: form.getFieldValue("tax"),
      taxFeeName: _.find(
        this.props.taxFeeList,
        (option) => option.id === form.getFieldValue("tax")
      )?.value,
      isActive: true,
      feeOption: {
        basePayChecked: getConditionalResponse({
          condition:
            [1].includes(selectedFieldOption) &&
            this.props.form.getFieldValue("basePayChecked"),
          resp1: true,
          resp2: false,
        }),
        earningLines: getConditionalResponse({
          condition: [1].includes(selectedFieldOption),
          resp1: updatedEarninglines,
          resp2: [],
        }),
        amendments: [],
        payCategory: getConditionalResponse({
          condition: [1].includes(selectedFieldOption),
          resp1: updatedAmendments,
          resp2: [],
        }),
        expenseCategory: getConditionalResponse({
          condition: [1].includes(selectedFieldOption),
          resp1: updatedExpenses,
          resp2: [],
        }),
      },

      employeeNumberRangeFactor:
        [1, 2].includes(selectedFieldOption) &&
        !_.isEmpty(this.state.employeeNumberRangeFactor)
          ? updatedEmployeeRangeFactor
          : [],
      stateRate: getConditionalResponse({
        condition:
          [1, 2].includes(selectedFieldOption) &&
          form.getFieldValue("applyStateRate"),
        resp1: updatedStateRate,
        resp2: [],
      }),
    };

    this.props.addOption(newOption).then((resp) => {
      this.setState({ submitLoading: false });

      if (resp.status) {
        message.success("Processing fee added successfully");

        this.setState((prevState) => {
          prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
          prevState.earningLines = [
            ...mustBeArray(prevState.defaultEarningLines),
          ];
          prevState.states = _.map(prevState.states, (o) => {
            return { ...o, rate: "" };
          });
          prevState.employeeNumberRangeFactor = [];
          return prevState;
        });

        this.props.form.resetFields();
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    });
  };

  removeRow = (rowKey) => {
    this.setState((prevState) => {
      _.remove(prevState.selectedOptions, function (n) {
        return Number(n) === Number(rowKey.feeOptionId);
      });
      return prevState;
    });

    this.props.removeOption(rowKey);
  };

  disableExpenses = () => {
    let totalExpenseitem = _.find(
      mustBeArray(this.state.earningLines),
      (o) => o.name === "Total Employee Expense"
    );

    if (
      totalExpenseitem &&
      mustBeArray(this.props.form.getFieldValue("earningLines")).includes(
        totalExpenseitem.id
      )
    ) {
      this.props.form.resetFields(["expenseCategory"]);
      return true;
    }
    return false;
  };

  cancelConfirmation = () => {
    this.setState({ checkStateRateConfirmation: false });
  };

  handleOnClose = () => {
    this.cancelConfirmation();
    delay(100).then(() => {
      this.props.onClose();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { earningLines, amendments, states, employeeNumberRangeFactor } =
      this.state;
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.handleOnClose}
        width={1100}
        closable={true}
        title="Payschedule Processing Fee"
        footer={[
          <Button key="back" onClick={this.handleOnClose}>
            Close
          </Button>,
        ]}
      >
        <Form
          className="pay-schedule-fees default-form-margin"
          layout="vertical"
          onSubmit={this.handleSubmit}
          hideRequiredMark={false}
          autoComplete="off"
        >
          <Row gutter={20}>
            <Col span={12}>
              <FormItem {...formItemLayout} label="Fee Option:">
                {getFieldDecorator("feeOption", {
                  rules: [
                    {
                      required: true,
                      message: "Please select a fee option",
                    },
                  ],
                })(
                  <Select placeholder="Select fee option">
                    {mustBeArray(this.state.feeOptions).map((fee) => (
                      <Option key={fee.id} value={fee.id}>
                        {fee.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="Invoice Description:">
                {getFieldDecorator("invoiceDescription", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter invoice description",
                    },
                  ],
                })(<Input placeholder="Invoice description" />)}
              </FormItem>
            </Col>
          </Row>
          {[1].includes(Number(this.props.form.getFieldValue("feeOption"))) && (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <FormItem {...formItemLayout} className="pb-0" label=" ">
                    {getFieldDecorator("basePayChecked", {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      valuePropName: "checked",
                    })(<Checkbox>Base Pay</Checkbox>)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <ApplicableFeesOptions
                    form={this.props.form}
                    getFieldDecorator={this.props.form.getFieldDecorator}
                    applicableFeesOptions={earningLines}
                    editPayschedule={true}
                    decorator="earningLines"
                    label="Earningline(s)"
                  />
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <PayCategory
                    payCategory={amendments}
                    getFieldDecorator={this.props.form.getFieldDecorator}
                    form={this.props.form}
                    formLayout={formItemLayout}
                    editMode={true}
                    selectedBaseSalary={
                      this.props.payschedule &&
                      this.props.payschedule.basePayMapping
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <BaseSalary
                    baseSalary={this.state.expenses}
                    getFieldDecorator={this.props.form.getFieldDecorator}
                    form={this.props.form}
                    formLayout={formItemLayout}
                    loading={this.state.expensesLoading}
                    required={false}
                    label="Expense(s)"
                    name="expenseCategory"
                    disabled={this.disableExpenses()}
                    handleFormValueChange={() => {
                      console.log("");
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}

          <Row gutter={20}>
            <Col span={12}>
              <FormItem
                className="pb-0"
                {...formItemLayout}
                label={
                  this.props.form.getFieldValue("feeOption") &&
                  Number(this.props.form.getFieldValue("feeOption")) === 1
                    ? "Percentage:"
                    : "Rate:"
                }
              >
                {getFieldDecorator("feeValue", {
                  rules: [
                    {
                      required: isRateRequired({
                        feeOption: Number(
                          this.props.form.getFieldValue("feeOption")
                        ),
                        applyStateRate:
                          this.props.form.getFieldValue("applyStateRate"),
                      }),
                      message: "You need to enter fee value",
                    },
                    {
                      pattern: /^[0-9.]+$/,
                      message:
                        "Please enter only positive numbers and decimals",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Fee option value"
                    min={0}
                    className="w-full  hideNumberPointers"
                    onChange={this.cancelConfirmation}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem className="pb-0" {...formItemLayout} label="Tax:">
                {getFieldDecorator("tax", {
                  rules: [
                    {
                      required: true,
                      message: "You need to select taxes",
                    },
                  ],
                })(
                  <Select placeholder="select tax">
                    {this.props.taxFeeList &&
                      this.props.taxFeeList.map((tax) => (
                        <Option key={tax.id} value={tax.id}>
                          {tax.value}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          {[1, 2].includes(
            Number(this.props.form.getFieldValue("feeOption"))
          ) && (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <FormItem {...formItemLayout} className="pb-0" label=" ">
                    {getFieldDecorator("applyStateRate", {
                      valuePropName: "checked",
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        onChange={(e) =>
                          this.handleChangeCheckbox(
                            e.target.value,
                            "applyStateRate"
                          )
                        }
                      >
                        Apply State Rate
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              {this.props.form.getFieldValue("applyStateRate") && (
                <Row>
                  <Col span={24}>
                    <StateRate
                      form={this.props.form}
                      getFieldDecorator={this.props.form.getFieldDecorator}
                      updateStatesRate={this.updateStatesRate}
                      editPayschedule={true}
                      states={states}
                      decorator="stateFeeOptions"
                      label="State Rate"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={12}>
                  <FormItem {...formItemLayout} className="pb-0" label=" ">
                    {getFieldDecorator("addEmployeeNumber", {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        onChange={(e) =>
                          this.handleChangeCheckbox(
                            e.target.value,
                            "addEmployeeNumber"
                          )
                        }
                      >
                        Employee Number Applicable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              {this.props.form.getFieldValue("addEmployeeNumber") && (
                <Row>
                  <Col span={24}>
                    <EmployeeRate
                      form={this.props.form}
                      getFieldDecorator={this.props.form.getFieldDecorator}
                      employeeNumberRangeFactor={employeeNumberRangeFactor}
                      addEmployeeRange={this.addEmployeeRange}
                      deleteEmployeeRange={this.deleteEmployeeRange}
                      updateEmployeeNumber={this.updateEmployeeNumber}
                      updateIsValid={this.updateIsValid}
                      editPayschedule={true}
                      decorator="employeeFeeOptions"
                      label="Employee Number Applicable"
                    />
                  </Col>
                </Row>
              )}
            </React.Fragment>
          )}

          <Row className="mb-4">
            <Col span={12} offset={5}>
              <Popconfirm
                title="Some states are assigned 0 rate! Are you sure you want to continue?"
                visible={this.state.checkStateRateConfirmation}
                onConfirm={this.submitProcessingFees}
                onCancel={this.cancelConfirmation}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  htmlType="submit"
                  theme="filled"
                  type="primary"
                  loading={this.state.submitLoading}
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Add
                </Button>
              </Popconfirm>
              <Button
                theme="filled"
                onClick={() => this.cancelProcessingFees()}
                className="mt-4 text-[15px] ml-4"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(paySchedule);
