import { ChangeEvent, useCallback, useRef } from 'react';
import { useComboBox } from '@react-aria/combobox';
import { useFilter } from '@react-aria/i18n';
import { useComboBoxState } from '@react-stately/combobox';
import classnames from 'classnames';

import { DawnArrowDown16, DawnArrowUp16 } from '@xemplo/icons';
import {
  InputFieldSize,
  LabelHelperText,
  MaybeErrorOrDescription,
} from '@xemplo/input-utils';
import { ListBox } from '@xemplo/listbox';
import { Popover } from '@xemplo/popover';

import * as S from './text-dropdown.style';
import { TextDropdownProps } from './text-dropdown.types';

export const TextDropdownTestId = {
  container: (testId?: string) => `textdropdown-container-${testId}`,
  input: (testId?: string) => `textdropdown-input-${testId}`,
  label: (testId?: string) => `textdropdown-label-${testId}`,
  trailingIcon: (testId?: string) => `textdropdown-trailing-icon-${testId}`,
  labelHelperText: (testId?: string) => `textdropdown-label-helper-text-${testId}`,
};

/**
 * @deprecated Use `TextDropdownV2` from `@xemplo/text-dropdown` instead. Deprecated since FD-1227
 * More info here https://expedo.atlassian.net/wiki/spaces/CL/pages/2008186924/Forms+and+Inputs
 */
export function TextDropdown<T extends object>(props: TextDropdownProps<T>) {
  const {
    label,
    formSubmitting,
    leadingIcon,
    testId = '',
    id,
    inputSize = InputFieldSize.Standard,
    description,
    width = '272px',
    onChange,
    menuTrigger = 'focus',
    searchable = false,
    isDisabled,
    isReadOnly,
    isRequired,
  } = props;
  let { errorMessage } = props;

  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({
    ...props,
    menuTrigger,
    defaultFilter: contains,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { inputProps, listBoxProps, labelProps, errorMessageProps, descriptionProps } =
    useComboBox(
      {
        ...props,
        inputRef,
        listBoxRef,
        popoverRef,
      },
      state
    );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      inputProps.onChange?.(e);
      inputRef.current?.setAttribute('value', e.target?.value);
      onChange?.(e);
    },
    [inputProps, onChange]
  );

  const handleInputClick = () => {
    if (!state.isOpen) {
      state.open();
    }
  };

  const isValid = () => {
    if (!inputRef.current || !formSubmitting) return true;
    // If no error message is passed in, use the browser's default validation message
    if (!errorMessage) {
      errorMessage = inputRef.current.validationMessage;
    }
    return inputRef.current.validity.valid;
  };

  const canShowOptionalLabel =
    !isRequired && !isDisabled && !isReadOnly && inputSize === InputFieldSize.Large;
  return (
    <>
      <S.Wrapper $width={width}>
        <S.Container
          ref={containerRef}
          className={classnames({
            'input-disabled': isDisabled,
            'input-has-error': !isValid() && !isDisabled,
            'input-readonly': isReadOnly,
            'has-value': !!inputProps?.value,
            'has-label': !!label,
          })}
          data-testid={TextDropdownTestId.container(testId)}
          $inputSize={inputSize}
          onClick={() => inputRef.current?.focus()}
        >
          {leadingIcon}
          <S.Input
            id={id}
            {...inputProps}
            className={classnames({
              searchable: searchable,
              'has-label': !!label,
              'has-value': !!inputProps?.value,
            })}
            ref={inputRef}
            required={isRequired}
            onChange={handleChange}
            data-testid={TextDropdownTestId.input(testId)}
            onClick={handleInputClick}
            onKeyDown={(e) => !searchable && e.preventDefault()}
          />
          <S.Label
            {...labelProps}
            className={classnames({
              searchable: searchable,
              'has-label': !!label,
              'has-value': !!inputProps?.value,
              'has-leading-icon': !!leadingIcon,
            })}
            data-testid={TextDropdownTestId.label(testId)}
          >
            {label}

            {canShowOptionalLabel && (
              <LabelHelperText
                data-testid={TextDropdownTestId.labelHelperText(testId)}
                className="label-helper-text"
              >
                (optional)
              </LabelHelperText>
            )}
          </S.Label>
          {state.isOpen ? (
            <DawnArrowUp16
              data-testid={TextDropdownTestId.trailingIcon(testId)}
              height="16px"
              width="16px"
            />
          ) : (
            <DawnArrowDown16
              data-testid={TextDropdownTestId.trailingIcon(testId)}
              height="16px"
              width="16px"
            />
          )}
        </S.Container>
        <MaybeErrorOrDescription
          error={!isValid()}
          errorMessage={errorMessage}
          errorMessageProps={errorMessageProps}
          description={description}
          descriptionProps={descriptionProps}
          testId={testId}
        />
      </S.Wrapper>
      {state.isOpen && (
        <Popover
          ref={popoverRef}
          triggerRef={containerRef}
          state={state}
          placement="bottom"
          testId={testId}
          portalContainer={containerRef.current as Element | undefined}
          containerPadding={-10}
          isNonModal
        >
          <ListBox
            {...listBoxProps}
            ref={listBoxRef}
            state={state}
            width={`${containerRef.current?.clientWidth ?? 0}px`}
            testId={testId}
          />
        </Popover>
      )}
    </>
  );
}
