//@ts-nocheck
import { Row, Col } from "antd";
import React from "react";
import {
  PayrunBeingProcessedIcon,
  QuestionIcon,
} from "../../../Common/customicons";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import InformationDisplayCard from "../common/informationDisplaycard";
const HeaderInformationDisplay = (props: any) => {
  const {
    DisplayCardOneIcon,
    DisplayCardOneTitle,
    DisplayCarOneDescription,
    DisplayCardOneActionComponent,
    DisplayCardTwoIcon,
    DisplayCardTwoTitle,
    DisplayCarTwoDescription,
    DisplayCardTwoActionComponent,
  } = props;
  return (
    <Row gutter={DEFAULT_GUTTER}>
      <Col md={24} lg={16} className="mb-6">
        <InformationDisplayCard
          IconComponent={DisplayCardOneIcon || PayrunBeingProcessedIcon}
          title={
            DisplayCardOneTitle ||
            "Your Pay Run is currently being prepared by your payroll manager"
          }
          description={
            <React.Fragment>
              {DisplayCarOneDescription || (
                <React.Fragment>
                  <div>
                    Any amendments submitted are being reviewed and incorporated
                    into the pay run.
                  </div>
                  <div>
                    You will be notified when the pay run is ready for your
                    review and approval.
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          }
          ActionComponent={DisplayCardOneActionComponent}
        />
      </Col>
      <Col md={24} lg={8} className="mb-6">
        <InformationDisplayCard
          IconComponent={DisplayCardTwoIcon || QuestionIcon}
          title={DisplayCardTwoTitle || "Have a question?"}
          cardClassName="min-h-[158px]"
          description={
            <React.Fragment>
              <div>
                {DisplayCarTwoDescription ||
                  "Made a mistake, needs to add more amendments or make changes? Use the 'Comments' functionality above."}
              </div>
            </React.Fragment>
          }
          ActionComponent={DisplayCardTwoActionComponent}
        />
      </Col>
    </Row>
  );
};
export default HeaderInformationDisplay;
