import { ErrorBoundary } from "@xemplo/error-boundary";

import { useAppSelector } from "../../../hooks";
import { activeDashboardIsDefault, isUserCompanyAdmin } from "../../../libs";
import DefaultDashboard from "./DefaultCompanyUserDashboard";
import CustomDashboard from "./CustomDashboard";

const DashboardIndex = (props: any) => {
  const member = useAppSelector((state) => state.member);
  return (
    <ErrorBoundary>
      {isUserCompanyAdmin({ member }) && activeDashboardIsDefault() ? (
        <DefaultDashboard {...props} />
      ) : (
        <CustomDashboard {...props} />
      )}
    </ErrorBoundary>
  );
};

export default DashboardIndex;
