import { useEffect } from 'react';

import { LayoutWithRouting } from '@xemplo/gp-layout';
import { XemploLoader } from '@xemplo/loader';
import { PayrunHeader } from '@xemplo/payrun-header';
import { TabMenu, TabMenuProps, TabMenuProvider, useTabMenu } from '@xemplo/tab-menu';

import * as S from './payrun.style';
import { PayrunActionButtons } from './payrun-action-buttons';
import { useValidPayrun } from './use-valid-payrun';

export const Payrun = () => {
  const { showLoader } = useValidPayrun();
  if (showLoader) {
    return <XemploLoader />;
  }

  return (
    <LayoutWithRouting
      header={<PayrunHeader />}
      navigation={
        <S.NavigationWrapper>
          <TabMenuProvider>
            <Navigation />
          </TabMenuProvider>
          <S.ActionBar>
            <PayrunActionButtons />
          </S.ActionBar>
        </S.NavigationWrapper>
      }
      onCloseRoute={'/payrun'}
    />
  );
};

function Navigation(props: TabMenuProps) {
  const { configTabMenu } = useTabMenu();
  useEffect(() => {
    configTabMenu({
      items: [
        { id: 'workers', label: 'Workers', url: '' },
        { id: 'amendments', label: 'Amendments', url: 'amendments' },
        // { id: 'overview', label: 'Overview', url: 'overview' },
        // { id: 'audit', label: 'Audit', url: 'audit' },
        { id: 'changelog', label: 'Changelog', url: 'changelog' },
      ],
    });
  }, [configTabMenu, props]);
  return <TabMenu />;
}
