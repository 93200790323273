export const FILTER_ACTIVE_INACTIVE_OPTIONS = [
  { id: 0, value: "All" },
  { id: 1, value: "Active" },
  { id: 2, value: "Inactive" },
];

export const PAYRUN_TYPES_OPTIONS = [
  { name: "All", value: 3 },
  { name: "Ad-hoc", value: 2 },
  { name: "Scheduled", value: 1 },
];

export const DEVICE_ENROLMENT_OPTIONS = [
  { name: "Optional", value: 1 },
  { name: "Mandatory", value: 2 },
  { name: "Disable", value: 0 },
];

export const ACTIVE_FILTER_ID = 1;
export const INACTIVE_FILTER_ID = 2;
