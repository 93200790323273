import { Form, Radio, Select, Tooltip } from "antd";
import { DawnCircleInformation } from "@xemplo/icons";
import { LumpSumOptions } from "../../../../types";

type Props = {
  handleChangeValue: ({
    label,
    value,
    resetFields,
  }: {
    label: string;
    value: any;
    resetFields?: string[];
  }) => void;
  required?: boolean;
};

const TOOLTIP_TEXT =
  " Payments for which the PAYG obligation is spread across multiple pay periods. Refer to ATO Schedule 5 - Tax Table for Back Payments, Commissions, Bonuses and Similar Payments";
const lumpSumLabel = (
  <span className="flex items-center gap-1">
    Lump Sum
    <Tooltip title={TOOLTIP_TEXT}>
      <DawnCircleInformation />
    </Tooltip>
  </span>
);

export const LumpSumField = ({ handleChangeValue, required }: Props) => {
  return (
    <Form.Item
      label={lumpSumLabel}
      className="mb-3  xl:row-start-3 xl:col-start-2 "
      name="lumpSum"
      rules={[
        {
          required,
        },
      ]}
      initialValue={LumpSumOptions.No}
    >
      <Select
        onChange={(e) =>
          handleChangeValue({
            label: "lumpSum",
            value: false,
            resetFields: ["payPeriods", "taxCalculationMethod"],
          })
        }
      >
        <Select.Option value={LumpSumOptions.No}>No</Select.Option>
        <Select.Option value={LumpSumOptions.Yes}>Yes</Select.Option>
      </Select>
    </Form.Item>
  );
};
