import styled, { css } from 'styled-components';

import {
  BodySmallMedium,
  BodySmallRegular,
  BodySmallSemiBold,
  Colour,
  Shadow,
} from '@xemplo/style-constants';

import { ToastTextWeight, ToastType } from './toast.types';

const ToastTextWeightMap = {
  [ToastTextWeight.Regular]: css`
    ${BodySmallRegular};
  `,
  [ToastTextWeight.Medium]: css`
    ${BodySmallMedium};
  `,
  [ToastTextWeight.SemiBold]: css`
    ${BodySmallSemiBold};
  `,
};

export const ToastText = styled.span<{ $weight: ToastTextWeight }>`
  ${({ $weight }) => ToastTextWeightMap[$weight]};
`;

export const ToastContainer = styled.div<{ $type?: ToastType }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  max-width: 300px;
  padding: 8px 16px;
  ${({ $type }) => $type && `padding-left: 12px`};
  background-color: ${Colour.Gray[800]};
  border-radius: 5px;
  ${Shadow.Medium3};
  color: ${Colour.White[100]};

  &:not(last-child) {
    margin-bottom: 16px;
  }

  svg {
    flex-shrink: 0;
  }
`;
