import { Pagination } from "antd";
import classNames from "classnames";
import React from "react";
import GeneralGrid from "../../../../Common/ClaimComponents/TableGrid/GeneralGrid";
import { If } from "../../../../Common/ui";
import { NO_RECORDS_FOUND_MESSAGE } from "../../../../constants/errors";
import { isEmpty, mustBeArray } from "../../../../libs";

type Props = {
  claim: any;
  name: string;
  dashboardView?: boolean;
  match?: any;
  data: any;
  handleGridAction?: (e: any) => void;
  handleGridChange?: (e: any) => void;
  GridCardComponent: any;
  displayActionIcons?: boolean;
  listLoading?: boolean;
  actionComponentName?: string;
  total: number;
  grid: any;
  cardGrid?: number;
  moduleType?: string;
  rowIdParam?: string;
};
const GridComponent = ({
  claim,
  name,
  dashboardView,
  data,
  GridCardComponent,
  displayActionIcons,
  listLoading,
  handleGridAction,
  handleGridChange,
  actionComponentName,
  total,
  grid,
  moduleType,
  cardGrid,
  rowIdParam,
}: Props) => {
  return (
    <If
      condition={isEmpty(mustBeArray(data)) && !listLoading}
      then={<div>{NO_RECORDS_FOUND_MESSAGE}</div>}
      else={
        <GeneralGrid name={name} claims={claim} dashboardView={dashboardView}>
          <div className="grid-paginated">
            <div className="grid grid-cols-24 gap-6">
              {mustBeArray(data).map((ct: any, index: number) => {
                return (
                  <div
                    className={classNames(
                      "gutter-row pb-6 md:col-span-12 sm: col-span-12",
                      `lg:col-span-${cardGrid || 6}`
                    )}
                    key={index}
                    id={rowIdParam ? ct[rowIdParam] : index}
                  >
                    <GridCardComponent
                      type={moduleType}
                      handleAction={handleGridAction}
                      content={ct}
                      component={actionComponentName}
                      claims={claim}
                      displayActionIcons={displayActionIcons}
                      dashboardView={dashboardView}
                    />
                  </div>
                );
              })}
              <div className="clearfix"></div>
            </div>
            <If
              condition={!isEmpty(mustBeArray(data)) && !dashboardView}
              then={
                <React.Fragment>
                  <div className="float-right mr-4 mb-6">
                    <Pagination
                      total={total}
                      current={grid?.page}
                      pageSize={grid?.perPage}
                      onChange={handleGridChange}
                      hideOnSinglePage
                    />
                  </div>
                  <div className="clearfix"></div>
                </React.Fragment>
              }
            />
          </div>
        </GeneralGrid>
      }
    />
  );
};

export default GridComponent;
