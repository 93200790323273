import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { formatToCurrency } from '@xemplo/common-util';
import { PayrunTypes, PreviousAndYtdPayItem } from '@xemplo/gp-types';
import { DawnArrowLongLeft16 } from '@xemplo/icons';
import { CenteredLoaderDots } from '@xemplo/loader';
import {
  useGetPayrunByIdQuery,
  useGetPayrunWorkerDetailsQuery,
} from '@xemplo/payrun-query';
import {
  PayrunAmendmentDetailWrapper,
  PayrunWorkerAmendments,
} from '@xemplo/payrun-worker-amendments';

import * as S from './payrun-worker-detail.styles';
import { PayrunWorkerDetailBodyProps } from './payrun-worker-detail.types';

export function PayrunWorkerDetailBody(props: Readonly<PayrunWorkerDetailBodyProps>) {
  const { workerId, payrunId } = props;
  const { amendmentId } = useParams();

  const navigate = useNavigate();

  const { data, isLoading } = useGetPayrunWorkerDetailsQuery({
    id: payrunId ?? null,
    workerId: workerId ?? null,
  });

  const { data: payrunData } = useGetPayrunByIdQuery({ id: payrunId ?? null });

  function parseData(data?: PreviousAndYtdPayItem[]) {
    if (!data) return [];
    const result: PreviousAndYtdPayItem[][] = [];
    let subArray: PreviousAndYtdPayItem[] = [];
    data.forEach((item) => {
      if (item.payItemId === null) {
        if (subArray.length > 0) {
          result.push(subArray);
        }
        subArray = [item];
      } else {
        subArray.push(item);
      }
    });
    if (subArray.length > 0) {
      result.push(subArray);
    }
    return result;
  }

  const parsedData = parseData(data?.result?.previousAndYtdPayItems);

  const showLastPay = payrunData?.result.payrunTypeID === PayrunTypes.SCHEDULED;

  if (isLoading) return <CenteredLoaderDots />;

  if (amendmentId)
    return (
      <>
        <S.GoBackLink
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <DawnArrowLongLeft16 />
          Back
        </S.GoBackLink>
        <PayrunAmendmentDetailWrapper amendmentId={amendmentId} />
      </>
    );

  return (
    <S.DetailContainer>
      {parsedData.map((item, index) => {
        return (
          <S.Table key={index}>
            {index === 0 && (
              <thead>
                <tr>
                  <th></th>
                  {showLastPay && <th>Last Pay</th>}
                  <th>YTD</th>
                </tr>
              </thead>
            )}
            <tbody>
              {item.map((subItem, index) => {
                return (
                  <tr
                    key={subItem.payItemName}
                    className={classNames({ heading: index === 0 })}
                  >
                    <td>{subItem.payItemName}</td>
                    {showLastPay && (
                      <td>{formatToCurrency({ value: subItem.lastPayAmount })}</td>
                    )}
                    {index === 0 && (
                      <td>{formatToCurrency({ value: subItem.yearToDateAmount })}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </S.Table>
        );
      })}

      <PayrunWorkerAmendments workerId={workerId} payrunId={payrunId} />
    </S.DetailContainer>
  );
}
