export interface IconButtonProps {
  id: string;
  size?: IconButtonSize;
  naked?: boolean;
  variant?: IconButtonVariant;
  className?: string;
  disabled?: boolean;
  /** The icon name is used as ARIA-LABEL for accessibility, give a sensible name for it */
  ariaLabel: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export enum IconButtonSize {
  Small = 'small',
  Medium = 'medium',
  Standard = 'standard',
  Large = 'large',
}

export enum IconButtonVariant {
  Darker = 'darker',
  Lighter = 'lighter',
  Default = 'default',
}
