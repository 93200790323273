const rgba = (color: string, pc: number) => `rgba(${color},${pc})`;

export const HexColour = {
  Gray: {
    50: '#F9F9FB',
    100: '#EFF1F5',
    200: '#DCDFEA',
    300: '#B9C0D4',
    400: '#7D89B0',
    500: '#5D6B98',
    600: '#4A5578',
    700: '#404968',
    800: '#30374F',
    900: '#111322',
  },
  Blue: {
    50: '#F2F8FF',
    100: '#D5E8FF',
    200: '#9EC9FF',
    300: '#67ABFF',
    400: '#3991FF',
    500: '#0B78FF',
    600: '#0060D7',
    700: '#0049A4',
    800: '#003271',
    900: '#001C3E',
  },
  Purple: {
    50: '#F5F3FF',
    100: '#ECE9FE',
    200: '#DDD6FE',
    300: '#C3B5FE',
    400: '#A48AFB',
    500: '#875BF7',
    600: '#7839EE',
    700: '#6927DA',
    800: '#5720B7',
    900: '#491C96',
  },
  Red: {
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FFCDCA',
    300: '#FDA29B',
    400: '#FA7066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  Orange: {
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  Green: {
    50: '#ECF9F7',
    100: '#CCFBEF',
    200: '#99F6E0',
    300: '#5FE9D0',
    400: '#2ED3B7',
    500: '#15B79E',
    600: '#0E9384',
    700: '#107569',
    800: '#125D56',
    900: '#134E48',
  },
} as const;
export type HexColourPalette = keyof typeof HexColour;
export type HexShade = keyof (typeof HexColour)[HexColourPalette];

export const RgbaColour = {
  White: {
    5: rgba('255,255,255', 0.05),
    10: rgba('255,255,255', 0.1),
    20: rgba('255,255,255', 0.2),
    30: rgba('255,255,255', 0.3),
    40: rgba('255,255,255', 0.4),
    50: rgba('255,255,255', 0.5),
    60: rgba('255,255,255', 0.6),
    70: rgba('255,255,255', 0.7),
    80: rgba('255,255,255', 0.8),
    90: rgba('255,255,255', 0.9),
    100: rgba('255,255,255', 1),
  },
  Black: {
    5: rgba('0,0,0', 0.05),
    10: rgba('0,0,0', 0.1),
    20: rgba('0,0,0', 0.2),
    30: rgba('0,0,0', 0.3),
    40: rgba('0,0,0', 0.4),
    50: rgba('0,0,0', 0.5),
    60: rgba('0,0,0', 0.6),
    70: rgba('0,0,0', 0.7),
    80: rgba('0,0,0', 0.8),
    90: rgba('0,0,0', 0.9),
    100: rgba('0,0,0', 1),
  },
} as const;
export type RgbaColourPalette = keyof typeof RgbaColour;
export type RgbaShade = keyof (typeof RgbaColour)[RgbaColourPalette];

export const GradientColour = {
  Gradient: {
    Linear45: 'linear-gradient(45deg, #3991FF 0%, #A48AFB 50.15%, #FA7066 100%)',
    Linear90: 'linear-gradient(90deg, #3991FF 0%, #A48AFB 50.15%, #FA7066 100%)',
    Linear180: 'linear-gradient(180deg, #3991FF 0%, #A48AFB 50.15%, #FA7066 100%)',
    Linear135: 'linear-gradient(135deg, #3991FF 0%, #A48AFB 50.15%, #FA7066 100%)',
  },
} as const;
export type GradientColourPalette = keyof typeof GradientColour;
export type GradientShade = keyof (typeof GradientColour)[GradientColourPalette];

export const Colour = {
  ...HexColour,
  ...RgbaColour,
  ...GradientColour,
};

export type ColourPalette = keyof typeof Colour;
export type Shade = HexShade | RgbaShade | GradientShade;
