//@ts-nocheck
import React from "react";
import AdminView from "./Admin/index";

class PayscheduleIndex extends React.Component {
  render() {
    return (
      <AdminView
        businessunit={this.props.businessunit}
        payschedules={this.props.payschedules}
        listPayschedules={this.props.listPayschedules}
        loading={this.props.loading}
        totalPages={this.props.totalPages}
        signal={this.props.signal}
        {...this.props}
      />
    );
  }
}

export default PayscheduleIndex;
