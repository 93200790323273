import React from "react";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import If from "../../If";

type Props = {
  name: string;
  index: number;
  isLast: boolean;
  addQuestion: (field: string) => void;
  removeQuestion: (field: string, index: number) => void;
  total: number;
};

const Buttons = ({
  isLast,
  index,
  name,
  addQuestion,
  removeQuestion,
  total,
}: Props) => (
  <div className="ml-2">
    <If
      condition={isLast}
      then={
        <button
          className="mr-2"
          type="button"
          onClick={() => addQuestion(name)}
        >
          <PlusCircleOutlined />
          <span className="sr-only">Add</span>
        </button>
      }
    />
    <If
      condition={index !== 0 || total > 1}
      then={
        <button type="button" onClick={() => removeQuestion(name, index)}>
          <CloseCircleOutlined />
          <span className="sr-only">remove</span>
        </button>
      }
    />
  </div>
);

export default Buttons;
