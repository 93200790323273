import React from "react";
import { getClaimAndStatus, mustBeArray } from "../../../libs/utilities";
import { Select } from "antd";

const Option = Select.Option;

export interface SingleFieldDropdownFilterProps {
  name?: string;
  claims?: any;
  className?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  value?: any;
  defaultValue?: any;
  options?: any;
  onSelect?: (e: any) => void;
  filterOption?: boolean;
  searchBy?: string;
  valueParam?: string;
  showSearch?: boolean;
  nameParam?: string;
  hasNoClaims?: boolean;
  canBeEmpty?: boolean;
  valueIsNumber?: boolean;
  loading?: boolean;
}

const SingleFieldDropdownFilter = ({
  name,
  claims,
  className,
  placeholder,
  onSelect,
  filterOption,
  value,
  defaultValue,
  searchBy = "",
  showSearch,
  valueParam,
  nameParam,
  options,
  hasNoClaims,
  canBeEmpty,
  valueIsNumber,
  loading = false,
}: SingleFieldDropdownFilterProps) => {
  const getValue = (v: any) => {
    if (Number(v) === 0 && canBeEmpty) return undefined;
    if (valueIsNumber && !isNaN(v)) return Number(v);
    else return v;
  };
  const singleFieldClaimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });

  return (
    <Select
      placeholder={placeholder}
      onSelect={onSelect}
      filterOption={filterOption || false}
      value={value || value === 0 ? getValue(value) : getValue(defaultValue)}
      className={`rounded-[20px] w-[150px] ${className} ${
        hasNoClaims ? "" : singleFieldClaimAndStatus.status
      }`}
      showSearch={showSearch || false}
      optionFilterProp={"name"}
      loading={loading}
    >
      {mustBeArray(options).map((s: any, key: number) => (
        <Option key={key} value={s[valueParam || "id"]} name={s[searchBy]}>
          {s[nameParam || "value"]}
        </Option>
      ))}
    </Select>
  );
};

export default SingleFieldDropdownFilter;
