import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFieldV2 } from '@xemplo/text-field';

import * as S from '../../modal.styles';

export const PaymentPeriods = () => {
  const { register, watch, unregister } = useFormContext();
  const watchLumpSum = watch('lumpSum');
  const watchTaxCalculationmMethod = watch('taxCalculationMethod');
  const watchPaymentPeriods = watch('paymentPeriods');
  const showPaymentPeriods = watchLumpSum === 'Yes' && watchTaxCalculationmMethod === 'A';

  useEffect(() => {
    if (showPaymentPeriods) {
      register('paymentPeriods', {
        required: 'This field is required',
        valueAsNumber: true,
      });
    } else {
      unregister('paymentPeriods');
    }
  }, [register, unregister, showPaymentPeriods]);

  if (showPaymentPeriods) {
    return (
      <S.FormLabel>
        Payment Periods
        <TextFieldV2
          placeholder="Enter payment periods"
          defaultValue={watchPaymentPeriods}
          aria-label="Payment periods field"
          testId="paymentPeriods"
          type="number"
          name="paymentPeriods"
        />
      </S.FormLabel>
    );
  }
};
