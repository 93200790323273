import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { BodySmallMedium, Colour } from '@xemplo/style-constants';

export const DrawerHeaderWrapper = styled.div`
  box-sizing: border-box;
  box-shadow: 0px 0px 0px rgba(21, 59, 106, 0);
  transition: 0.2s box-shadow ease-out;

  &.keyline {
    border-bottom: 1px solid ${Colour.Black[10]};
  }

  &.shadow {
    box-shadow: 0px 4px 8px rgba(21, 59, 106, 0.04);
  }
`;

export const DrawerHeaderContent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 24px;
`;

export const Title = styled.span`
  ${BodySmallMedium};
`;

export const BackButton = styled(IconButton)`
  margin-right: 4px;
`;

export const EditorBtnWrapper = styled.div`
  margin-left: auto;
  display: flex;
  gap: 16px;
  margin-right: 8px;
`;
