//@ts-nocheck
import React from "react";
import { Button, Input, Modal, Row, Col, message } from "antd";

type State = any;

class AddXeroMapping extends React.Component<{}, State> {
  UNSAFE_componentWillMount() {
    this.setState({
      name: "",
      xeroReferenceCode: "",
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (
      newProps &&
      newProps.visible &&
      newProps.visible !== this.props.visible
    ) {
      const { activeRecord } = newProps;
      this.setState({
        name: activeRecord.name,
        xeroReferenceCode: activeRecord.xeroReferenceCode,
      });
    }
  }

  handleSubmit = () => {
    const { xeroReferenceCode, name } = this.state;
    if (
      !this.state.xeroReferenceCode ||
      !this.state.xeroReferenceCode.trim(" ")
    )
      return message.error("Please enter XERO code.");
    this.props.handleSubmit({
      payload: {
        xeroReferenceCode,
        name,
        id: this.props.activeRecord && this.props.activeRecord.id,
      },
    });
  };

  handleCancel = () => {
    this.setState({
      name: "",
      xeroReferenceCode: "",
    });
    this.props.handleCancel();
  };

  updatexeroReferenceCode = (xeroReferenceCode: any) => {
    this.setState({ xeroReferenceCode });
  };
  render() {
    const { saveXeroLoading } = this.props;
    const { name, xeroReferenceCode } = this.state;
    return (
      <Modal
        visible={this.props.visible}
        title={"Edit XERO Code"}
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={saveXeroLoading}
            onClick={this.handleSubmit}
          >
            Save
          </Button>,

          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Row className="mb-4" align="middle">
          <Col span={6}>Payment Item:</Col>
          <Col span={18}>{name}</Col>
        </Row>
        <Row className="mb-4" align="middle">
          <Col span={6}>XERO Code:</Col>
          <Col span={18}>
            <Input
              placeholder="Enter Xero Code"
              value={xeroReferenceCode}
              onChange={(e) =>
                this.updatexeroReferenceCode(e.currentTarget.value)
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default AddXeroMapping;
