//@ts-nocheck
import React, { Component } from "react";
import { ChangePassword, UserInfo } from "../components";
import { message, Tabs } from "antd";
import {
  errorDisplay,
  mustBeArray,
  delay,
  getClaimAndStatus,
  isComponentVisible,
} from "../../libs/utilities";
import {
  CHANGE_PASSWORD_WARNING_LIMIT,
  CHANGE_PASSWORD_LOCKOUT_LIMIT,
} from "../../constants/errors";
import _ from "lodash";
import MultiTabContainer from "../../Common/ClaimContainers/MultiTabContainer";
import PersonaSettings from "../components/PersonaSettings";
import PersonalizationSettings from "../components/PersonalizationSettings";
import { USER_PERSONALIZATION_OPTIONS } from "../../constants/user";
import GeneralContainer from "../../Common/ClaimContainers/GeneralContainer";
import ChildTabContainer from "../../Common/ClaimContainers/ChildTabContainer";
import {
  GLOBAL_PAYROLL_APPLICATION_NAME,
  GOOGLE_ANALYTICS_PAGE_TITLE,
  USER_PROFILE_COMPONENT_NAME,
  USER_PROFILE_DETAIL_CONTAINER,
  USER_PROFILE_MULTI_TAB_CONTAINER,
} from "../../constants";
import { withRouter } from "../../hooks";
import store from "../../store";

type State = any;

class UserProfile extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      changePasswordLoading: false,
      activeKey: "1",
      userPersonaOptions: [],
      roleSettingsLoading: false,
      userPersonalizationOptions: [],
      pageClaimsAndStatus: { claim: {}, status: "" },
    };
  }
  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
    const { member } = this.props;
    const authorizationDOM = mustBeArray(member?.details?.authorizationDOM);
    const expedoDOM = _.find(
      authorizationDOM,
      (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
    );
    const userProfileClaims = _.find(
      mustBeArray(expedoDOM?.components),
      (o) => o.name === USER_PROFILE_COMPONENT_NAME
    );
    const claimsAndStatus = getClaimAndStatus({
      claims: userProfileClaims,
      hasComponents: false,
    });
    this.setState({ pageClaimsAndStatus: claimsAndStatus });
    let userPersonalizationOptions = [];
    if (expedoDOM?.personalizationSettings) {
      USER_PERSONALIZATION_OPTIONS.forEach((option) => {
        userPersonalizationOptions.push({
          ...option,
          value: expedoDOM.personalizationSettings[option.name],
        });
        return option;
      });
    } else {
      userPersonalizationOptions = [...USER_PERSONALIZATION_OPTIONS];
    }
    this.setState({ userPersonalizationOptions });
  }
  componentDidMount() {
    this.props.updateHeader({
      header: {
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.UserProfile,
        title: "User Profile",
        module: "",
        enableBack: false,
        entity: "",
        action: "",
      },
    });
    this.getRoleSettings();
  }

  getRoleSettings = () => {
    const details = store.getState()?.member?.details;
    return this.setState({ userPersonaOptions: details?.userPersona ?? [] });
  };

  handleResetCancel = () => {
    this.setState({ changePasswordLoading: false });
  };

  handleSavePersonaCancel = () => {
    this.setState({ savePersonaLoading: false });
  };

  handleResetSubmit = (payload) => {
    this.setState({ changePasswordLoading: true });

    return this.props.updatePassword({ payload }).then((resp) => {
      this.setState({ changePasswordLoading: false });

      if (resp.status) {
        message.success("Password updated successfully");
      } else {
        let attemptedLogin = _.find(
          mustBeArray(resp?.data?.errors),
          (o) => o.propertyName === "AccessFailedCount"
        );

        let errors = _.filter(
          mustBeArray(resp?.data?.errors),
          (o) => o.propertyName !== "AccessFailedCount"
        );
        errorDisplay({ errors }, false, true);
        if (
          attemptedLogin &&
          attemptedLogin.attemptedValue === CHANGE_PASSWORD_WARNING_LIMIT
        ) {
          message.error(
            "Your account will be locked after another unsuccessful attempt."
          );
        } else if (
          attemptedLogin &&
          attemptedLogin.attemptedValue === CHANGE_PASSWORD_LOCKOUT_LIMIT
        ) {
          message.error("Your account has been temporarily locked.");
          delay(200).then(() => {
            this.props.router.navigate("/logout");
          });
        }
      }

      return resp;
    });
  };
  handleTabChange = (activeKey) => {
    this.setState({ activeKey });
  };

  handlePersonaSubmit = (values) => {
    const payload = {
      userRoleDefault: values?.defaultRole ? values.defaultRole : null,
      lastSelectedAsDefault: !!values?.selectLastLoggedRole,
    };

    this.setState({ roleSettingsLoading: true });

    this.props
      .handleRoleSettings({
        payload: payload,
        action: "updatePersona",
      })
      .finally(() => this.setState({ roleSettingsLoading: false }));
  };

  handlePersonalizationSubmit = ({ values, reset }) => {
    const { member } = this.props;
    const userPersonaId = member?.details?.selectedRole?.id;
    const payload = { ...values, resetPersonalization: reset, userPersonaId };
    this.setState({
      [reset ? "resetPersonalizationLoading" : "savePersonalizationLoading"]:
        true,
    });
    const authorizationDOM = mustBeArray(member?.details?.authorizationDOM);
    this.props
      .handlePersonaSettings({
        action: "updatesettings",
        payload,
        authorizationDOM,
      })
      .then((resp) => {
        this.setState({
          savePersonalizationLoading: false,
          resetPersonalizationLoading: false,
        });
        if (resp.status) {
          message.success(
            `Personalisation settings ${
              reset ? "reset" : "updated"
            } successfully.`
          );
        } else {
          message.error(resp?.message);
        }
      });
  };
  render() {
    const {
      changePasswordLoading,
      activeKey,
      roleSettingsLoading,
      userPersonaOptions,
      savePersonalizationLoading,
      userPersonalizationOptions,
      resetPersonalizationLoading,
      pageClaimsAndStatus,
    } = this.state;

    const pageClaims = pageClaimsAndStatus?.claim;

    // Multi tab settings
    const userProfileMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === USER_PROFILE_MULTI_TAB_CONTAINER
    );

    // Security Settings
    const userProfileSecuritySettingsTabContainer = _.find(
      mustBeArray(userProfileMultiTabContainer?.components),
      (o) => o.name === "userProfileSecuritySettingsTabContainer"
    );
    const userProfileChangePasswordChildTabContainer = _.find(
      mustBeArray(userProfileSecuritySettingsTabContainer?.components),
      (o) => o.name === "userProfileChangePasswordChildTabContainer"
    );

    // Role Settings
    const userProfileRoleSettingsTabContainer = _.find(
      mustBeArray(userProfileMultiTabContainer?.components),
      (o) => o.name === "userProfileRoleSettingsTabContainer"
    );
    const userProfileRoleSettingsChildTabContainer = _.find(
      mustBeArray(userProfileRoleSettingsTabContainer?.components),
      (o) => o.name === "userProfileRoleSettingsChildTabContainer"
    );

    // Personalization Settings
    const userProfilePersonalizationSettingsTabContainer = _.find(
      mustBeArray(userProfileMultiTabContainer?.components),
      (o) => o.name === "userProfilePersonalizationSettingsTabContainer"
    );
    const userProfilePersonalizationSettingsChildTabContainer = _.find(
      mustBeArray(userProfilePersonalizationSettingsTabContainer?.components),
      (o) => o.name === "userProfilePersonalizationSettingsChildTabContainer"
    );
    return (
      <React.Fragment>
        <GeneralContainer
          name={USER_PROFILE_DETAIL_CONTAINER}
          claims={pageClaims}
          md={24}
          sm={24}
          lg={24}
        >
          <UserInfo />
        </GeneralContainer>
        {isComponentVisible({ claim: userProfileMultiTabContainer }) && (
          <MultiTabContainer
            name={USER_PROFILE_MULTI_TAB_CONTAINER}
            claims={pageClaims}
            activeKey={activeKey}
            handleTabChange={this.handleTabChange}
            ignoreClaims={true}
          >
            {isComponentVisible({
              claim: userProfileSecuritySettingsTabContainer,
            }) && (
              <Tabs.TabPane
                tab="Security Settings"
                key={"1"}
                className="form-label-left"
              >
                <ChildTabContainer
                  name="userProfileChangePasswordChildTabContainer"
                  claims={userProfileSecuritySettingsTabContainer}
                >
                  <GeneralContainer
                    md={12}
                    sm={24}
                    lg={8}
                    claims={userProfileChangePasswordChildTabContainer}
                    name="userProfileChangePasswordFormContainer"
                  >
                    <ChangePassword
                      loading={changePasswordLoading}
                      handleCancel={this.handleResetCancel}
                      handleSubmit={this.handleResetSubmit}
                    />
                  </GeneralContainer>
                </ChildTabContainer>
              </Tabs.TabPane>
            )}
            {userPersonaOptions.length > 1 &&
              isComponentVisible({
                claim: userProfileRoleSettingsTabContainer,
              }) && (
                <Tabs.TabPane tab="Role Settings" key={"2"}>
                  <ChildTabContainer
                    name="userProfileRoleSettingsChildTabContainer"
                    claims={userProfileRoleSettingsTabContainer}
                  >
                    <GeneralContainer
                      md={12}
                      sm={24}
                      lg={8}
                      claims={userProfileRoleSettingsChildTabContainer}
                      name="userProfileRoleSettingsFormContainer"
                    >
                      <PersonaSettings
                        handleCancel={this.handleResetCancel}
                        handlePersonaSubmit={this.handlePersonaSubmit}
                        userPersonaOptions={userPersonaOptions}
                        loading={roleSettingsLoading}
                      />
                    </GeneralContainer>
                  </ChildTabContainer>
                </Tabs.TabPane>
              )}

            {isComponentVisible({
              claim: userProfilePersonalizationSettingsTabContainer,
            }) && (
              <Tabs.TabPane
                tab="Personalisation Settings"
                key={"3"}
                className="form-label-left"
              >
                <ChildTabContainer
                  name="userProfilePersonalizationSettingsChildTabContainer"
                  claims={userProfilePersonalizationSettingsTabContainer}
                >
                  <GeneralContainer
                    md={12}
                    sm={24}
                    lg={8}
                    claims={userProfilePersonalizationSettingsChildTabContainer}
                    name="userProfilePersonalizationSettingsFormContainer"
                  >
                    <PersonalizationSettings
                      loading={savePersonalizationLoading}
                      handleCancel={this.handleResetCancel}
                      handleSubmit={this.handleResetSubmit}
                      handlePersonalizationSubmit={
                        this.handlePersonalizationSubmit
                      }
                      userPersonalizationOptions={userPersonalizationOptions}
                      resetPersonalizationLoading={resetPersonalizationLoading}
                    />
                  </GeneralContainer>
                </ChildTabContainer>
              </Tabs.TabPane>
            )}
          </MultiTabContainer>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(UserProfile);
