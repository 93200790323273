//@ts-nocheck
import React, { Component } from "react";
import {
  CloseOutlined,
  PlusCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, InputNumber, Tooltip, Avatar } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import _ from "lodash";
import { END_EMPLOYEE_NUMBER } from "../../../constants/payrun";

type RateRowState = any;

class RateRow extends Component<{}, RateRowState> {
  state = {
    to: 0,
    isValid: true,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.employee.to !== this.state.to && this.state.isValid) {
      this.setState({ ...nextProps.employee });
    }
  }

  onFromChange = (value) => {
    const { index, min, max } = this.props;
    this.setState({ to: value });
    if (min <= value && value < max) {
      this.props.updateEmployeeNumber("to", index, value);
      this.props.updateIsValid(true);
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
      this.props.updateIsValid(false);
    }
  };

  render() {
    const { employee, index, employeeNumberRangeFactor } = this.props;
    const addIcon = (
      <React.Fragment>
        <Tooltip title="Add" placement="bottom">
          <Avatar size="small">
            <PlusOutlined
              className="hand color-primary"
              onClick={() => this.props.addEmployeeRange()}
            />
          </Avatar>
        </Tooltip>
      </React.Fragment>
    );
    const deleteIcon = (
      <React.Fragment>
        <Tooltip title="Delete" placement="bottom">
          <Avatar size="small" className="ml-3.5">
            <CloseOutlined
              className="hand color-danger"
              onClick={() => this.props.deleteEmployeeRange()}
            />
          </Avatar>
        </Tooltip>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Col span={6} className="mb-4">
          <InputNumber
            value={employee.from}
            disabled
            className="w-4/5 hideNumberPointers"
            placeholder="From"
          />
        </Col>
        <Col span={6} className="mb-4">
          <InputNumber
            value={this.state.to}
            placeholder="To"
            onChange={(value) => this.onFromChange(value)}
            formatter={(value) =>
              value ? `${value}`.replace(/\D+/g, "") : value
            }
            parser={(value) => (value ? `${value}`.replace(/\D+/g, "") : value)}
            className={`w-4/5 hideNumberPointers ${
              !this.state.isValid ? "border-color-danger" : ""
            }`}
          />
        </Col>
        <Col span={4} className="hideNumberPointers mb-4">
          <InputNumber
            value={employee.rate}
            placeholder="Rate"
            className="w-4/5 hideNumberPointers"
            onChange={(e) => this.props.updateEmployeeNumber("rate", index, e)}
          />
        </Col>
        <Col span={4} className="mb-4">
          {Number(index) === Number(employeeNumberRangeFactor.length) - 1 &&
            employee.to &&
            employee.rate !== "" &&
            !_.isUndefined(employee.rate) &&
            addIcon}
          {Number(index) === Number(employeeNumberRangeFactor.length) - 1 &&
            deleteIcon}
        </Col>
      </React.Fragment>
    );
  }
}

type EmployeeRateState = any;

class EmployeeRate extends Component<{}, EmployeeRateState> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      label,
      decorator,
      employeeNumberRangeFactor,
      getFieldDecorator,
      addEmployeeRange,
      deleteEmployeeRange,
      editPayschedule,
    } = this.props;
    const formLayout = {
      labelCol: { span: editPayschedule ? 5 : 6 },
      wrapperCol: { span: editPayschedule ? 19 : 18 },
    };

    return (
      <React.Fragment>
        <Form.Item label={`${label}:`} {...formLayout}>
          {getFieldDecorator(decorator, {
            initialValue: [],
          })(
            <Row className="ml-0">
              {!_.isEmpty(employeeNumberRangeFactor) ? (
                mustBeArray(employeeNumberRangeFactor).map((employee, i) => {
                  return (
                    <RateRow
                      employee={employee}
                      key={i}
                      updateIsValid={(value) =>
                        this.props.updateIsValid(value, i)
                      }
                      addEmployeeRange={addEmployeeRange}
                      index={i}
                      employeeNumberRangeFactor={employeeNumberRangeFactor}
                      updateEmployeeNumber={this.props.updateEmployeeNumber}
                      min={Number(employee.from) + 1}
                      max={
                        employeeNumberRangeFactor[i + 1] &&
                        employeeNumberRangeFactor[i + 1].to
                          ? Number(employeeNumberRangeFactor[i + 1].to - 1)
                          : END_EMPLOYEE_NUMBER
                      }
                      deleteEmployeeRange={deleteEmployeeRange}
                    />
                  );
                })
              ) : (
                <Col span={8} className="mb-4">
                  <span
                    onClick={() => addEmployeeRange()}
                    className="color-primary hand"
                  >
                    <PlusCircleFilled className="text-[17px]" />
                    <span className="text-[17px] ml-1">Add</span>
                  </span>
                </Col>
              )}
            </Row>
          )}
        </Form.Item>
      </React.Fragment>
    );
  }
}

export default EmployeeRate;
