import { PropsWithChildren } from 'react';

import { TabMenuContext } from './tab-menu.context';
import { useTabMenuInit } from './tab-menu.hooks';

/**
 * The tab menu component required a complex set of states.
 * Changes on the state happens in different sub components.
 * so it was a lot easier to debug and maintain the code
 * by adding a provider that creates a single state for the whole component.
 */
export const TabMenuProvider = ({ children }: PropsWithChildren) => {
  const context = useTabMenuInit();
  return <TabMenuContext.Provider value={context}>{children}</TabMenuContext.Provider>;
};
