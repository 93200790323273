import _ from "lodash";
import userRoles from "../constants/userRoles";
import {
  getUserArea,
  getSelectedRole,
  mustBeArray,
  updateAuthorizationClaims,
} from "./utilities";

export const isCompanyUser = () => {
  return userRoles.CompanyArea === getUserArea();
};

export const isUserPSPAdmin = ({ member }: any) => {
  return member?.details?.selectedRole?.role?.name === userRoles.PSPAdmin;
};

export const isUserCompanyAdmin = ({ member }: any) => {
  return member?.details?.selectedRole?.role?.name === userRoles.CompanyAdmin;
};

export const isUserPSPManager = ({ member }: any) => {
  return member?.details?.selectedRole?.role?.name === userRoles.PayrollManager;
};

export const isUserPSPUser = ({ member }: any) => {
  return [userRoles.PSPAdmin, userRoles.PayrollManager].includes(
    getSelectedRole({ member })?.role?.name
  );
};

export const updateDomChanges = ({
  update,
  condition,
  updatedComponent,
  updatedComponentPersonalization,
}: any) => {
  if (!_.isEmpty(updatedComponent)) return update;
  if (condition && !_.isEmpty(mustBeArray(updatedComponentPersonalization)))
    return true;
  return false;
};

export const getRecentPersonalizations = ({
  personalizations,
}: {
  personalizations: Array<any>;
}) => {
  // Reverse the array to get the recent changes first and remove the duplications
  return _.uniqBy(mustBeArray(personalizations).reverse(), (o) => o.name);
};

export const updateComponentPersonalizations = ({
  personalizations,
  claims,
}: {
  personalizations: Array<any>;
  claims: any;
}) => {
  let updatedDOM = structuredClone(claims);
  personalizations.forEach((personalization: any) => {
    const { name, value, label } = personalization;
    updatedDOM = updateAuthorizationClaims({
      claims: updatedDOM,
      value,
      label,
      name,
    });
  });
  return updatedDOM;
};

export const getPersonaUpdatePayload = ({
  personalizations,
}: {
  personalizations: any;
}) => {
  const payload = mustBeArray(personalizations).reduce((a, b) => {
    if (!(b.name && b.id)) return a;
    let personalization: any = {
      id: b.id,
      name: b.name,
      personalization: b.value ?? null,
      personalizations: [],
    };
    if (b.label === "personalizations") {
      personalization = {
        ...personalization,
        personalizations: b.value,
        personalization: null,
      };
    }
    return [...a, personalization];
  }, []);
  return payload;
};
