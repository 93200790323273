export const EditButtonTypes = {
  amendment: 'amendment',
  termination: 'termination',
  payInstruction: 'payInstruction',
} as const;

export type EditButtonTypes = (typeof EditButtonTypes)[keyof typeof EditButtonTypes];

export interface EditButtonFactoryProps {
  amendmentId: string;
  type: EditButtonTypes | null;
}
