//@ts-nocheck
import React from 'react';
import { Row, Col, Card } from 'antd';
import SummaryGrid from './SummaryGrid';
 import { onlyDateFormater } from '../../../../libs';
const AuditReportPayrunSummary = (props: any) => {
    const { payPeriodStarting, payPeriodEnding, datePaid, payscheduleName,
        frequency, paySlipMessage, employeesPaid } = props;
    return (

        <Card
            title="Pay Run Summary"
        >
                <Row gutter={24}>
                <Col span={12}>
                        <SummaryGrid
                        label="Pay Period Starting"
                        value={payPeriodStarting && onlyDateFormater(payPeriodStarting)}
                    />
                        <SummaryGrid
                        label="Pay Period Ending"
                        value={payPeriodEnding && onlyDateFormater(payPeriodEnding)}
                    />
                        <SummaryGrid
                        label="Date Paid"
                        value={datePaid && onlyDateFormater(datePaid)}
                    />
                        <SummaryGrid
                        label="Employees Paid"
                        value={employeesPaid}
                    />
                </Col>
                <Col span={12}>
                        <SummaryGrid
                        label="Pay Schedule"
                        value={payscheduleName}
                    />
                        <SummaryGrid
                        label="Frequency"
                        value={frequency}
                    />
                        <SummaryGrid
                        label="Pay Slip Message"
                        value={paySlipMessage}
                    />
                </Col>
            </Row>
        </Card>
    )
}
export default AuditReportPayrunSummary;
