// @ts-nocheck
import "@ant-design/compatible/assets/index.css";

import { Form } from "@ant-design/compatible";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMemberApi } from "@xemplo/gp-api";
import { memberActions } from "@xemplo/gp-member-store";
import { Button, Checkbox, Col, Input, Row } from "antd";
import React, { SyntheticEvent } from "react";

import { TermsAndConditions } from "../../Common/ui";
import { DEFAULT_HOME_ROUTE } from "../../constants";
import {
  dispatchHook,
  goToRoute,
  memberApi,
  withRouter,
  httpClient,
} from "../../hooks";
import { trimWhiteSpaces } from "../../libs/utilities";
import store from "../../store";
import { BaseErrorHandler } from "../actions/member";
import { ErrorBoundary } from "@xemplo/error-boundary";
import { CancelTokenSource } from "axios";

const FormItem = Form.Item;

type State = any;

type MemberApiType = ReturnType<typeof useMemberApi>;

const VerifyDetails = (props: any) => {
  return (
    <ErrorBoundary>
      <VerifyDetailsInternal {...props} />
    </ErrorBoundary>
  );
};

class VerifyDetailsInternal extends React.Component<any, State> {
  activateUserProfile!: MemberApiType["activateUserProfile"];
  getProfile!: MemberApiType["getProfile"];
  cancelSignal: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = { submitLoading: false };
  }

  componentDidMount() {
    const { cancelSignal } = httpClient();
    const { activateUserProfile, getProfile } = memberApi();
    this.activateUserProfile = activateUserProfile;
    this.getProfile = getProfile;
    this.cancelSignal = cancelSignal;

    const { details } = store.getState().member;
    if (details) {
      this.props.form.setFieldsValue({
        firstName: details.firstName,
        lastName: details.lastName,
        emailAddress: details.username,
      });
    }
  }

  componentWillUnmount(): void {
    this.cancelSignal.cancel();
  }

  onSuccess = (response: any) => {
    // the response data is irrelevant
    this.getProfile(this.cancelSignal.token)
      .then((profile) => {
        dispatchHook()(memberActions.setDetails(profile));
        dispatchHook()({
          type: "UPDATE_DASHBOARD_LIST",
          data: profile.dashboards,
        });
        goToRoute(DEFAULT_HOME_ROUTE);
      })
      .catch(BaseErrorHandler)
      .finally(() => this.setState({ submitLoading: false }));
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (err) return;
      this.setState({ submitLoading: true });
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
      };

      this.activateUserProfile(payload, this.cancelSignal.token)
        .then(this.onSuccess)
        .catch(BaseErrorHandler);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { submitLoading } = this.state;

    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={false}
        className="mr-4 form-label-left"
        autoComplete="off"
      >
        <FormItem>
          <Button
            size={"large"}
            className={`mt-4 text-left w-full ${
              this.props.error ? "block" : "display-none"
            }`}
          >
            {this.props.error}
          </Button>
        </FormItem>

        <Row gutter={16}>
          <Col span={6}>
            <FormItem colon={false} label={"First Name"}>
              {getFieldDecorator("firstName", {
                rules: [
                  { required: true, message: "Please input your first name" },
                ],
              })(
                <Input
                  placeholder="Enter first name"
                  size="large"
                  className="pl-[11px] pr-0 py-0"
                />
              )}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem colon={false} label={"Last Name"}>
              {getFieldDecorator("lastName", {
                rules: [
                  { required: true, message: "Please input your last name" },
                ],
              })(
                <Input
                  placeholder="Enter last name"
                  size="large"
                  className="pl-[11px] pr-0 py-0"
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem colon={false} label={"Email"}>
              {getFieldDecorator("emailAddress", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ],
                normalize: (value) => trimWhiteSpaces(value),
              })(
                <Input
                  placeholder="Enter email address"
                  size="large"
                  disabled={true}
                  className="verify-details-input-disabled"
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <div className="mb-3 p-4 verify-details-terms">
          <TermsAndConditions />
        </div>
        <FormItem colon={false} label={""} className="mb-0.5">
          {getFieldDecorator("acceptTermsAndCondition", {
            rules: [
              {
                required: true,
                message: "Please accept our terms and conditon",
              },
            ],
            valuePropName: "checked",
          })(
            <Checkbox onChange={this.acceptTerms}>
              Click here to confirm you have read and accepted our Terms and
              Conditions and Privacy Policy.
            </Checkbox>
          )}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          theme="filled"
          loading={submitLoading}
          disabled={!this.props.form.getFieldValue("acceptTermsAndCondition")}
          className="mb-5 rounded-[20px]"
        >
          <span>{"CONTINUE"}</span>
          <ArrowRightOutlined size="large" />
        </Button>

        <div className="clearfix" />
      </Form>
    );
  }
}

export default withRouter(Form.create()(VerifyDetails));
