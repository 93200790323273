import * as React from 'react';

import Toast from '../toast';

import { ToastWrapper } from './toast-provider.style';
import {
  AddToastProps,
  ToastHorizontalPosition,
  ToastVerticalPosition,
} from './toast-provider.types';

const DEFAULT_TOAST_DURATION = 2500;

export const useToast = () => {
  return React.useContext(ToastContext);
};

export const ToastContext = React.createContext({
  addToast: (props: AddToastProps): void => {
    throw new Error('Render ToastProvider to add toasts.');
  },
});

export function ToastProvider({ children }: Readonly<React.PropsWithChildren>) {
  const [toasts, setToasts] = React.useState([] as Array<AddToastProps>);

  const addToast = React.useCallback((toast: AddToastProps) => {
    const newToast = {
      ...toast,
      position: {
        vertical: toast?.position?.vertical ?? ToastVerticalPosition.Bottom,
        horizontal: toast?.position?.horizontal ?? ToastHorizontalPosition.Right,
      },
    };
    setToasts((toasts) => [...toasts, newToast]);
    setTimeout(
      () => setToasts((toasts) => toasts.slice(1)),
      toast.duration ?? DEFAULT_TOAST_DURATION
    );
  }, []);

  const contextValue = React.useMemo(
    () => ({
      addToast,
    }),
    [addToast]
  );

  const verticalPositions = Object.values(ToastVerticalPosition);
  const horizontalPositions = Object.values(ToastHorizontalPosition);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {verticalPositions.map((vPos) =>
        horizontalPositions.map((hPos) => (
          <ToastWrapper
            key={`toast-${hPos}_${vPos}`}
            $position={{ vertical: vPos, horizontal: hPos }}
          >
            {toasts
              .filter(
                (props) =>
                  props?.position?.vertical === vPos && props.position.horizontal === hPos
              )
              .map((props) => (
                <Toast key={props.text} {...props} />
              ))}
          </ToastWrapper>
        ))
      )}
    </ToastContext.Provider>
  );
}
