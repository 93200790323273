import { SVGProps } from 'react';
const DawnPeopleGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.887 3.5a4.57 4.57 0 0 0-4.57 4.567v.002h.75l-.75-.003v.001a4.554 4.554 0 0 0 4.54 4.57h.03a4.569 4.569 0 1 0 0-9.137Zm-3.07 4.569a3.07 3.07 0 1 1 3.07 3.069h-.027a3.054 3.054 0 0 1-3.042-3.066v-.003Zm9.258-3.59a.75.75 0 1 0-.243 1.48 2.125 2.125 0 0 1 1.778 2.096 2.126 2.126 0 0 1-1.826 2.099.75.75 0 0 0 .208 1.485 3.625 3.625 0 0 0 3.118-3.582v-.001a3.625 3.625 0 0 0-3.035-3.576Zm1.765 9.03a.75.75 0 1 0-.222 1.483c.635.095 1.082.243 1.353.42.233.153.302.298.302.488a.477.477 0 0 1-.124.343c-.094.106-.265.227-.556.338a.75.75 0 1 0 .535 1.401c.427-.163.837-.397 1.145-.746.322-.364.5-.817.5-1.336 0-.784-.403-1.364-.98-1.742-.538-.353-1.238-.542-1.953-.65Zm-12.376.93c1.208-.405 2.8-.525 4.423-.525 1.632 0 3.224.124 4.432.534.608.207 1.172.504 1.592.943a2.43 2.43 0 0 1 .684 1.722c0 .684-.25 1.265-.69 1.72-.421.438-.988.732-1.596.935-1.209.404-2.8.523-4.422.523-1.633 0-3.225-.124-4.432-.533-.608-.206-1.173-.503-1.593-.942a2.426 2.426 0 0 1-.684-1.72c0-.685.25-1.265.689-1.721.422-.438.988-.732 1.597-.936Zm-.517 1.977a.918.918 0 0 0-.27.68c0 .29.094.5.27.684.192.201.511.395.99.557.964.327 2.351.454 3.95.454 1.591 0 2.98-.122 3.946-.446.48-.16.8-.352.993-.552a.917.917 0 0 0 .269-.68.932.932 0 0 0-.27-.686c-.192-.202-.51-.396-.989-.558-.964-.328-2.35-.455-3.95-.455-1.59 0-2.978.123-3.945.447-.48.161-.8.354-.994.555ZM6.559 5.098a.75.75 0 0 1-.619.862 2.125 2.125 0 0 0-1.777 2.095 2.125 2.125 0 0 0 1.826 2.099.75.75 0 0 1-.208 1.485 3.625 3.625 0 0 1-3.118-3.582v-.001A3.625 3.625 0 0 1 5.698 4.48a.75.75 0 0 1 .861.618Zm-2.404 9.894a.75.75 0 0 0-.222-1.483c-.716.107-1.415.296-1.954.65-.576.377-.979.957-.979 1.741 0 .519.178.972.5 1.336.308.349.718.583 1.145.746a.75.75 0 1 0 .534-1.401c-.29-.111-.462-.232-.555-.338A.477.477 0 0 1 2.5 15.9c0-.19.069-.335.302-.487.27-.178.718-.326 1.353-.42Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnPeopleGroup;
