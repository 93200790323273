import {
  QuerySessionStatusArgs,
  RevokeTokensTypes,
  SessionStatus,
  SigninPopupArgs,
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutPopupArgs,
  SignoutRedirectArgs,
  User,
  UserManagerEvents,
  UserManagerSettings,
} from 'oidc-client-ts';

export enum ActiveNavigator {
  signinRedirect = 'signinRedirect',
  signinPopup = 'signinPopup',
  signinSilent = 'signinSilent',
  signoutRedirect = 'signoutRedirect',
  signoutPopup = 'signoutPopup',
  empty = '',
}

export interface AuthState {
  /** See [User](https://authts.github.io/oidc-client-ts/classes/User.html) for more details. */
  user?: User | null;

  /** True when the library has been initialized and no navigator request is in progress. */
  isLoading: boolean;

  /** True while the user has a valid access token. */
  isAuthenticated: boolean;

  /** Tracks the status of most recent signin/signout request method. */
  activeNavigator?: ActiveNavigator;

  /** Was there a signin or silent renew error? */
  error?: Error;

  /** When the user was previously logged in and session expired, it should have a return url */
  returnUrl?: string;
}

export interface UserManagerContext {
  readonly config: UserManagerSettings;
  readonly events: UserManagerEvents;
  clearStaleState(): Promise<void>;
  removeUser(): Promise<void>;
  signinPopup(args?: SigninPopupArgs): Promise<User>;
  signinSilent(args?: SigninSilentArgs): Promise<User | null>;
  signinRedirect(args?: SigninRedirectArgs): Promise<void>;
  signoutRedirect(args?: SignoutRedirectArgs): Promise<void>;
  signoutPopup(args?: SignoutPopupArgs): Promise<void>;
  signoutCallback(): Promise<void>;
  querySessionStatus(args?: QuerySessionStatusArgs): Promise<SessionStatus | null>;
  revokeTokens(types?: RevokeTokensTypes): Promise<void>;
  startSilentRenew(): void;
  stopSilentRenew(): void;
  getUser(): Promise<User | null>;
  getAccessToken(): Promise<string | undefined>;
  completeSignout(): Promise<void>;
}

export interface AuthProviderProps extends UserManagerSettings {
  /**
   * On sign in callback hook. Can be a async function.
   * Here you can remove the code and state parameters from the url when you are redirected from the authorize page.
   *
   * ```jsx
   * const onSigninCallback = (_user: User | void): void => {
   *     window.history.replaceState(
   *         {},
   *         document.title,
   *         window.location.pathname
   *     )
   * }
   * ```
   */
  onSigninCallback?: (user: User | void) => Promise<void> | void;

  /**
   * On remove user hook. Can be a async function.
   * Here you can change the url after the user is removed.
   *
   * ```jsx
   * const onRemoveUser = (): void => {
   *     // go to home after logout
   *     window.location.pathname = ""
   * }
   * ```
   */
  onRemoveUser?: () => Promise<void> | void;
}

export type NavigationArgs =
  | SigninPopupArgs
  | SigninSilentArgs
  | SigninRedirectArgs
  | SignoutRedirectArgs
  | SignoutPopupArgs;

export enum AuthActions {
  Initialized = 'INITIALISED',
  UserLoaded = 'USER_LOADED',
  UserUnloaded = 'USER_UNLOADED',
  GetPersona = 'GET_PERSONA',
  ReturnUrl = 'ReturnUrl',
  NavigatorInit = 'NAVIGATOR_INIT',
  NavigatorClosed = 'NAVIGATOR_CLOSED',
  Error = 'ERROR',
}

export type Action =
  | { type: AuthActions.Initialized | AuthActions.UserLoaded; user: User | null }
  | { type: AuthActions.UserUnloaded }
  | { type: AuthActions.GetPersona }
  | { type: AuthActions.ReturnUrl; returnUrl: string }
  | { type: AuthActions.NavigatorInit; method: NonNullable<AuthState['activeNavigator']> }
  | { type: AuthActions.NavigatorClosed }
  | { type: AuthActions.Error; error: Error };
