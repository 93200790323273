import { useCallback, useEffect, useReducer } from 'react';

import {
  defaultState,
  reducer,
  setContentVisible,
  setDrawerConfig,
  setDrawerContent,
  setIsOpen,
  setMode,
  setPosition,
  setState,
} from '../drawer.store';
import {
  DrawerConfig,
  DrawerContent,
  DrawerMode,
  DrawerPosition,
  DrawerProps,
  DrawerState,
} from '../drawer.types';

export const useDrawerInit = (props: DrawerProps) => {
  const initialState: DrawerState = {
    ...defaultState,
    isOpen: props.isOpen ?? defaultState.isOpen,
    config: {
      ...defaultState.config,
      position: props.position ?? defaultState.config.position,
      mode: props.mode ?? defaultState.config.mode,
    },
  };

  const [drawerState, dispatch] = useReducer(reducer, initialState);
  const { config } = drawerState;
  const { onClose } = config;

  const setConfig = useCallback(
    (config: Partial<DrawerConfig>) => {
      dispatch(setDrawerConfig(config));
    },
    [dispatch]
  );

  const toggle = useCallback(
    (value?: boolean) => {
      dispatch(setIsOpen(value));
    },
    [dispatch]
  );

  const toggleMode = useCallback(
    (mode?: DrawerMode) => {
      dispatch(setMode(mode));
    },
    [dispatch]
  );

  const togglePosition = useCallback(
    (position?: DrawerPosition) => {
      dispatch(setPosition(position));
    },
    [dispatch]
  );

  const setContent = useCallback(
    (content: Partial<DrawerContent>) => {
      dispatch(setDrawerContent(content));
    },
    [dispatch]
  );

  const configureDrawer = useCallback(
    (state: Partial<DrawerState>) => {
      dispatch(setState(state));
    },
    [dispatch]
  );

  const handleCloseClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      dispatch(setIsOpen(false));
      onClose?.(e);
    },
    [onClose]
  );

  // When setting the content visible, we immediately set it to true so the content
  // flows along with the drawer. If the drawer is closing, we want to wait for the
  // content to be removed so the drawer can animate properly.
  // Otherwise, the drawer animation kicks in, but the content is already gone from the DOM.
  useEffect(() => {
    if (drawerState.isOpen) {
      // setDrawerState((state) => ({ ...state, contentVisible: true }));
      dispatch(setContentVisible(true));
    } else {
      setTimeout(() => dispatch(setContentVisible(true)), 1000);
    }
  }, [drawerState.isOpen]);

  return {
    drawerState,
    toggle,
    toggleMode,
    togglePosition,
    configureDrawer,
    setConfig,
    setContent,
    handleCloseClick,
  };
};
