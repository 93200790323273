import styled from 'styled-components';

import { HeadingStandardSemiBold } from '@xemplo/style-constants';

export const PayrunEmployeeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PayrunEmployeeListTitle = styled.h1`
  ${HeadingStandardSemiBold};
`;
