import { SVGProps } from 'react';
const DawnArrowUp16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.167 10.04a.75.75 0 1 1-1.045-1.077l5.355-5.201a.75.75 0 0 1 1.047.002l5.353 5.2a.75.75 0 0 1-1.045 1.075L8 5.346l-4.833 4.693Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowUp16;
