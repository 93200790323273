import { Dispatch, useMemo } from 'react';
import { UserManager } from 'oidc-client-ts';

import {
  Action,
  ActiveNavigator,
  AuthActions,
  AuthState,
  NavigationArgs,
  UserManagerContext,
} from '../auth-provider.types';
import { UNSUPPORTED_ENV } from '../constants/error.constant';

const userManagementKeys = [
  'clearStaleState',
  'querySessionStatus',
  'revokeTokens',
  'startSilentRenew',
  'stopSilentRenew',
  'signoutCallback',
  'removeUser',
  'getUser',
  'removeUser',
] as const;

const navigatorKeys = [
  ActiveNavigator.signinPopup,
  ActiveNavigator.signinSilent,
  ActiveNavigator.signinRedirect,
  ActiveNavigator.signoutPopup,
  ActiveNavigator.signoutRedirect,
] as const;

const unsupportedEnvironment = (fnName: string) => () => {
  throw new Error(UNSUPPORTED_ENV(fnName));
};

/** Hook that provides overrides with state management actions and returns a new api with the same names */
export const useUserManager = (
  userManager: UserManager,
  dispatch: Dispatch<Action>,
  state: AuthState,
  onRemoveUser?: () => void | Promise<void>
) =>
  useMemo(() => {
    // Whenever a navigation function is invoked, we dispatch a state change on start and on its completion
    const navigation = Object.fromEntries(
      navigatorKeys.map((key) => [
        key,
        userManager[key]
          ? async (...args: NavigationArgs[]) => {
              dispatch({ type: AuthActions.NavigatorInit, method: key });
              try {
                return await userManager[key]({ state, ...args });
              } finally {
                dispatch({ type: AuthActions.NavigatorClosed });
              }
            }
          : unsupportedEnvironment(key),
      ])
    ) as Pick<UserManagerContext, (typeof navigatorKeys)[number]>;

    // We still need to bind the rest of the functions that remains the same
    const userManagement = Object.fromEntries(
      userManagementKeys.map((key) => [
        key,
        userManager[key]?.bind(userManager) ?? unsupportedEnvironment(key),
      ])
    ) as Pick<UserManagerContext, (typeof userManagementKeys)[number]>;

    // Manually creating override as
    const removeUser = async () => {
      await userManager.removeUser();
      onRemoveUser && (await onRemoveUser());
    };

    const getAccessToken = () => userManager.getUser().then((user) => user?.access_token);

    return Object.assign(
      { config: userManager.settings, events: userManager.events },
      userManagement,
      navigation,
      { getAccessToken, removeUser }
    ) as UserManagerContext;
  }, [dispatch, onRemoveUser, state, userManager]);
