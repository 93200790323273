import { useCallback, useEffect } from 'react';

import { DragEventHandlers } from './dropzone.types';

export const useDropzone = (eventHandlers: DragEventHandlers) => {
  const { onDragEnd, onDragEnter, onDragLeave, onDragOver, onDrop } = eventHandlers;

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDrop(e);
    },
    [onDrop]
  );

  const handleDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDragEnter(e);
    },
    [onDragEnter]
  );

  const handleDragEnd = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDragEnd(e);
    },
    [onDragEnd]
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDragLeave(e);
    },
    [onDragLeave]
  );

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onDragOver(e);
    },
    [onDragOver]
  );

  useEffect(() => {
    window.addEventListener('onDrop', () => handleDrop);
    window.addEventListener('onDragEnter', () => handleDragEnter);
    window.addEventListener('onDragEnd', () => handleDragEnd);
    window.addEventListener('onDragLeave', () => handleDragLeave);
    window.addEventListener('onDragOver', () => handleDragOver);

    return () => {
      window.removeEventListener('onDrop', () => handleDrop);
      window.removeEventListener('onDragEnter', () => handleDragEnter);
      window.removeEventListener('onDragEnd', () => handleDragEnd);
      window.removeEventListener('onDragLeave', () => handleDragLeave);
      window.removeEventListener('onDragOver', () => handleDragOver);
    };
  }, []);

  return {
    handleDrop,
    handleDragEnter,
    handleDragEnd,
    handleDragLeave,
    handleDragOver,
  };
};
