// @ts-nocheck
import * as React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import TemplateNoSidebarAndNavbar from "../Layout/TemplateNoSidebarAndNavbar";
import { Loading } from "../Common/ui";

type InfiniteScrollState = any;

type InfiniteScrollProps = typeof InfiniteScroll.defaultProps & {
  children: React.ReactNode;
  throttle: number;
  threshold: number;
  loading: boolean;
  onLoadMore: (options: object) => void;
  hasMoreData: boolean;
};

class InfiniteScroll extends React.Component<
  InfiniteScrollProps,
  InfiniteScrollState
> {
  static defaultProps = {
    threshold: 100,
    throttle: 64,
  };

  resizeHandler: any;
  scrollHandler: any;
  sentinel: any;

  constructor() {
    super(...arguments);
    this.sentinel = React.createRef();
    this.checkWindowScroll = this.checkWindowScroll.bind(this);
  }

  checkWindowScroll = () => {
    if (this.props.loading) {
      return <Loading />;
    }
    if (
      this.props.hasMoreData &&
      this.sentinel.getBoundingClientRect().top - window.innerHeight <
        this.props.threshold
    ) {
      this.props.onLoadMore({ pageLoad: false });
    }
  };
  componentDidMount() {
    this.scrollHandler = _.throttle(
      this.checkWindowScroll,
      this.props.throttle
    );
    this.resizeHandler = _.throttle(
      this.checkWindowScroll,
      this.props.throttle
    );
    window.addEventListener("scroll", this.scrollHandler, true);
    window.addEventListener("resize", this.resizeHandler);
    window.addEventListener("mousewheel", this.scrollHandler);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler, true);
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("mousewheel", this.scrollHandler);
  }
  render() {
    const sentinel = <div ref={(r) => (this.sentinel = r)} />;
    return (
      <div>
        {this.props.children}
        {sentinel}
      </div>
    );
  }
}

TemplateNoSidebarAndNavbar.propTypes = {
  isloading: PropTypes.bool,
};

export default InfiniteScroll;
