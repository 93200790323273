import { SVGProps } from 'react';
const DawnBuildingHost = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 21a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.12 3.564c.742-.86 1.876-1.314 3.38-1.314h9c1.503 0 2.638.454 3.38 1.314.725.839.97 1.944.97 3.036V21a.75.75 0 0 1-.75.75H3.9a.75.75 0 0 1-.75-.75V6.6c0-1.092.245-2.197.97-3.036Zm1.135.98c-.4.464-.605 1.16-.605 2.056v13.65h14.7V6.6c0-.897-.205-1.592-.605-2.056-.383-.443-1.049-.794-2.245-.794h-9c-1.196 0-1.862.351-2.245.794Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 16.05a.75.75 0 0 1 .75-.75h2.7a.75.75 0 0 1 0 1.5H7.5a.75.75 0 0 1-.75-.75ZM13.05 16.05a.75.75 0 0 1 .75-.75h2.7a.75.75 0 0 1 0 1.5h-2.7a.75.75 0 0 1-.75-.75ZM6.75 12a.75.75 0 0 1 .75-.75h2.7a.75.75 0 0 1 0 1.5H7.5a.75.75 0 0 1-.75-.75ZM13.05 12a.75.75 0 0 1 .75-.75h2.7a.75.75 0 0 1 0 1.5h-2.7a.75.75 0 0 1-.75-.75ZM6.75 7.95a.75.75 0 0 1 .75-.75h2.7a.75.75 0 0 1 0 1.5H7.5a.75.75 0 0 1-.75-.75ZM13.05 7.95a.75.75 0 0 1 .75-.75h2.7a.75.75 0 0 1 0 1.5h-2.7a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnBuildingHost;
