import {
  DawnBuildingCompany,
  DawnBuildingHost,
  DawnCalendar,
  DawnCalendarSearch,
  DawnCashPlus,
  DawnCashStack,
  DawnChartDashboard,
  DawnChartGraph,
  DawnHandMoney,
  DawnInvoice,
  DawnMail,
  DawnPeopleGroup,
  DawnPeopleTick,
  DawnReceipt,
} from "@xemplo/icons";
import { SidebarItem } from "@xemplo/sidebar";

import { SCOPES } from "./authorizationClaims";
import { DEFAULT_HOME_ROUTE } from "./routes";

export const DEFAULT_MENU_ITEMS: SidebarItem[] = [
  {
    label: "Payroll",
    isHeader: true,
    id: "d68ee9ad-2814-4654-b465-a88a31c4d00d",
    route: "",
  },
  {
    label: "Dashboard",
    isHeader: false,
    icon: <DawnChartDashboard />,
    id: SCOPES.DASHBOARD,
    route: DEFAULT_HOME_ROUTE,
  },
  {
    label: "Companies",
    isHeader: false,
    icon: <DawnBuildingCompany />,
    id: SCOPES.COMPANY,
    route: "/company",
  },
  {
    label: "Business Unit",
    isHeader: false,
    icon: <DawnBuildingHost />,
    id: SCOPES.BUSINESSUNIT,
    route: "/businessunit",
  },
  {
    label: "Payroll Providers",
    isHeader: false,
    icon: <DawnHandMoney />,
    id: SCOPES.PAYROLLPROVIDER,
    route: "/payrollprovider",
  },
  {
    label: "Billing Providers",
    isHeader: false,
    icon: <DawnReceipt />,
    id: SCOPES.BILLINGSERVICEPROVIDER,
    route: "/billingserviceproviders",
  },
  {
    label: "Pay schedules",
    isHeader: false,
    icon: <DawnCalendar />,
    id: SCOPES.PAYSCHEDULE,
    route: "/payschedules",
  },
  {
    label: "Pay runs",
    isHeader: false,
    icon: <DawnCashStack />,
    id: SCOPES.PAYRUN,
    route: "/payrun",
  },
  {
    label: "Amendments",
    isHeader: false,
    icon: <DawnCashPlus />,
    id: SCOPES.GENERALAMENDMENT,
    route: "/generalAmendments",
  },
  {
    label: "Invoices",
    isHeader: false,
    icon: <DawnInvoice />,
    id: SCOPES.INVOICE,
    route: "/invoices",
  },
  {
    label: "Variance",
    isHeader: false,
    icon: <DawnCalendarSearch />,
    id: SCOPES.VARIANCE,
    route: "/variances",
  },
  {
    label: "Users",
    isHeader: false,
    icon: <DawnPeopleGroup />,
    id: SCOPES.USER,
    route: "/users",
  },
  {
    label: "Administration",
    isHeader: true,
    id: SCOPES.SETTINGS,
    route: "",
  },
  {
    label: "Email templates",
    isHeader: false,
    icon: <DawnMail />,
    id: `${SCOPES.SETTINGS_EMAIL}`,
    route: "/emailtemplate",
  },
  {
    label: "Authorization Management",
    isHeader: false,
    icon: <DawnPeopleTick />,
    id: `${SCOPES.SETTINGS_AUTH_MGMT}`,
    route: "/personaSettings",
  },
  {
    label: "Reports",
    isHeader: false,
    icon: <DawnChartGraph />,
    id: `${SCOPES.REPORTS}`,
    route: "/reports",
  },
];
