export interface TabMenuProps {
  items?: MenuItem[];
  size?: TabMenuSize;
  isPrimary?: boolean;
  useTruncate?: boolean;
  className?: string;
  isHire?: boolean;
}

export type SharedStateProps = {
  state: TabMenuState;
  dispatch: React.Dispatch<TabMenuAction>;
};

export type MenuItem = {
  id: string;
  label: string;
  url: string;
  icon?: JSX.Element;
  target?: string;
  disabled?: boolean;
  callback?: (item: MenuItem) => void | Promise<void>;

  /** Internal use only! Do not set this prop outside of the tab menu component */
  role?: string;

  /** Internal use only! Do not set this prop outside of the tab menu component */
  isSubMenuItem?: boolean;
};

export interface TabMenuState {
  items: MenuItem[];
  visibleItems: MenuItem[];
  hiddenItems: MenuItem[];
  subMenuOpened: boolean;
  windowWidth: number;
  useTruncate?: boolean;
  size: TabMenuSize;
  isPrimary?: boolean;
  needResize?: boolean;
  isHire?: boolean;
}

export enum TabMenuSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum TabMenuActions {
  SetItems = 'SetItems',
  ToggleMenu = 'ToggleMenu',
  SetPropsChanged = 'SetPropsChanged',
  SetNeedResize = 'SetNeedResize',
  SetSubMenuClicked = 'SetSubMenuClicked',
}

export type SetItemProps = Pick<
  TabMenuState,
  'visibleItems' | 'hiddenItems' | 'needResize'
>;

export type TabMenuAction =
  | { type: TabMenuActions.ToggleMenu; payload?: boolean }
  | { type: TabMenuActions.SetSubMenuClicked; payload?: boolean }
  | { type: TabMenuActions.SetItems; payload: SetItemProps }
  | { type: TabMenuActions.SetPropsChanged; payload: Partial<TabMenuProps> }
  | { type: TabMenuActions.SetNeedResize; payload: boolean };
