import styled from 'styled-components';

import {
  BodySmallMedium,
  BodySmallRegular,
  BodyStandardMedium,
  BodyStandardRegular,
  BodyXSmallMedium,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';

import { SummaryTableData } from './bulk-upload-summary.types';

export const VerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 769px;
  gap: 32px;
  min-height: 600px;
  box-sizing: border-box;
`;
export const SummaryTable = styled(XemploTable<SummaryTableData>)`
  background: ${Colour.White[100]};
  border-radius: 8px;
  border: 1px solid ${Colour.Gray[200]};
  margin-bottom: 32px;
  ${BodySmallRegular};
  color: ${Colour.Gray[600]};
  min-width: 100%;

  & .action-bar-container {
    display: none;
  }
  table {
    background: transparent;

    thead {
      border: none;
      color: ${Colour.Gray[700]};
      ${BodyXSmallMedium};
    }

    tbody {
      background: transparent;

      tr {
        box-sizing: border-box;
        border: 1px solid ${Colour.Black[5]};
        background: ${Colour.White[100]};
      }
    }
  }
`;

export const MoreLines = styled.div`
  color: ${Colour.Gray[500]};
  ${BodySmallMedium};
  margin-left: 0;
  margin-top: 12px;
`;

export const WarningBanner = styled.div`
  border: 1px solid ${Colour.Orange[300]};
  background: ${Colour.Orange[50]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  ${BodyXSmallRegular};
  padding: 8px 16px;
  & svg {
    font-size: 24px;
    color: ${Colour.Orange[500]};
    margin-right: 16px;
  }
  & .bold {
    ${BodyXSmallSemiBold};
    margin-right: 4px;
  }
`;

export const WarningLine = styled.li`
  color: ${Colour.Orange[500]};
  ${BodySmallMedium};
`;

export const Details = styled.div`
  display: flex;
  gap: 32px;
  & :not(:first-child) {
    border-left: 1px solid ${Colour.Gray[100]};
  }
  margin-bottom: 32px;
`;

export const DetailItem = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  gap: 12px;
  ${BodyStandardRegular};
  color: ${Colour.Gray[400]};
  & :first-child {
    ${BodyStandardMedium};
    color: ${Colour.Gray[700]};
  }
`;
