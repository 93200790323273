import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AmendmentTypes, ChangeTaskTypes } from '@xemplo/gp-types';
import { RadioGroupV2 } from '@xemplo/radio-button';

import * as S from '../../../modal.styles';

import { BulkAmendmentUploader } from './bulk-amendment-uploader';
import { AmountMethodOptions, ParsedCsvItem } from './bulk-upload.types';
import { DownloadTemplateButton } from './download-template-button';

export type AmountMethodProps = {
  setCsvData: (data: ParsedCsvItem[]) => void;
  setCsvDataWarnings: (data: string[]) => void;
};
export const AmountMethod = (props: AmountMethodProps) => {
  const { setCsvData, setCsvDataWarnings } = props;

  const { register, watch, setValue, resetField } = useFormContext();

  const watchAmountMethod = watch('amountMethod');
  const watchChangeType = watch('changeType');
  const watchAppliesTo = watch('appliesTo');
  const watchTerminatedWorkers = watch('terminatedWorkers');
  const watchSubType = watch('changeTaskSubType');
  const watchChangeTask = watch('changeTask');
  const isPayInstruction = watchChangeTask === ChangeTaskTypes.PAY_INSTRUCTION;

  const multipleWorkersAreSelected =
    (watchAppliesTo?.length ?? 0) + (watchTerminatedWorkers?.length ?? 0) > 1;

  const showAmountMethod =
    watchChangeType === AmendmentTypes.PAYRUN &&
    multipleWorkersAreSelected &&
    !!watchSubType &&
    !isPayInstruction;

  useEffect(() => {
    if (showAmountMethod) {
      register('amountMethod', {
        required: 'Please select an amount method',
        value: AmountMethodOptions.Fixed,
      });
    } else {
      setValue('amountMethod', AmountMethodOptions.Fixed);
    }
  }, [register, resetField, setValue, showAmountMethod]);

  useEffect(() => {
    if (watchAmountMethod === AmountMethodOptions.Fixed) {
      setCsvData([]);
      setCsvDataWarnings([]);
    }
  }, [watchAmountMethod, setCsvData, setCsvDataWarnings]);

  if (showAmountMethod) {
    return (
      <div>
        <S.FormLabel>
          How will the amendment be applied?
          <RadioGroupV2
            name="amountMethod"
            showLabel
            options={amountMethodItems}
            ariaLabel="Amount method radio group"
          />
        </S.FormLabel>

        {watchAmountMethod === AmountMethodOptions.Variable && (
          <>
            <DownloadTemplateButton />
            <BulkAmendmentUploader
              setCsvData={setCsvData}
              setCsvDataWarnings={setCsvDataWarnings}
            />
          </>
        )}
      </div>
    );
  }
};
const amountMethodItems = [
  {
    value: AmountMethodOptions.Fixed,
    label: 'Same amount for all selected employees',
  },
  { value: AmountMethodOptions.Variable, label: 'Variable amounts' },
];
