//@ts-nocheck
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";
import { Input } from "antd";
import { buttonSizeType } from "../../../constants/paramsTypes";

const Search = Input.Search;

export interface SingleFieldTextBoxFilterProps {
  componentName?: string;
  name?: string;
  claims?: any;
  className?: string;
  onSearch: (e: any) => void;
  onChange: (e: any) => void;
  checked?: boolean;
  placeholder?: string;
  ignoreStatus?: boolean;
  size?: buttonSizeType;
  value?: any;
}

const SingleFieldTextBoxFilter: React.FC<SingleFieldTextBoxFilterProps> = ({
  name,
  claims,
  placeholder,
  value,
  onChange,
  onSearch,
  className,
  ignoreStatus,
  size,
}) => {
  const SingleFieldClaimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <Search
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      onSearch={(e) => onSearch(e)}
      size={size || "default"}
      className={` rounded-[20px] w-[210px] singleFieldTextBoxFilter ${className}
                ${ignoreStatus ? "" : SingleFieldClaimAndStatus.status}`}
    />
  );
};

export default SingleFieldTextBoxFilter;
