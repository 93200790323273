//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  getBusinessUnit,
  updateBusinessUnit,
  saveBusinessUnit,
  listBusinessUnits,
  listTaxFees,
  handleAirmaxAccount,
  listBillingServiceProviders,
  listBillingAccounts,
  listCurrency,
  importKeypayEmployees,
  businessunitHandler,
} from "../actions/businessunits"; // fetch business unit data later
import {
  getPayschedule,
  getPayScheduleFees,
  addProcessingFee,
  removeProcessingFee,
  updatePaySchedule,
  getProcessingFee,
  payscheduleHandler,
} from "../actions/payschedule";
import {
  earningLinesLookup,
  listAmendmentTypes,
  externalPayCategoryLookup,
  payCategoryLookUp,
} from "../actions/payrun";
import {
  companyLookup,
  listCompanyBusinessUnits,
  getCompany,
  handleEmailTemplatesActions,
  listCompanies,
  listCompanyUsers,
  handleCompanyUserActions,
} from "../actions/companies";
import {
  getPayrollProvider,
  payrollProviderLookup,
  listPayrollProviders,
} from "../actions/payrollprovider";
import {
  connectPayProcessor,
  listPSPUsers,
  fetchPayrollConnectionName,
  fetchExternalBusinessunit,
} from "../actions/payrollprocessor";
import {
  listUserRoles,
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import {
  populateStates,
  countriesLookup,
  timezoneLookup,
  cdnLookUpHandler,
  lookUpHandler,
} from "../actions/lookup";
import axios from "axios";
import {
  findAuthorizationClaims,
  getCurrentAuthorizationDom,
  mustBeArray,
} from "../../libs/utilities";
import {
  BUSINESS_UNIT_BREAD_CRUMB_CONTAINER,
  PAYSCHEDULE_BREAD_CRUMB_CONTAINER,
} from "../../constants";
import { withRouter } from "../../hooks";

class BusinessUnitDetail extends SwiftContainer {
  signal = axios.CancelToken.source();

  componentDidMount() {
    const { index, member } = this.props;
    const header = {
      routeComponentName:
        index === "payschedules"
          ? "payScheduleBreadCrumb"
          : "businessUnitBreadCrumb",
      routesContainer: findAuthorizationClaims({
        claims:
          index === "payschedules"
            ? member.details?.authorizationDOM
            : this.state.pageClaims?.components,
        name:
          index === "payschedules"
            ? PAYSCHEDULE_BREAD_CRUMB_CONTAINER
            : BUSINESS_UNIT_BREAD_CRUMB_CONTAINER,
      }),
    };
    this.props.updateHeader({ header });
  }
  UNSAFE_componentWillReceiveProps(newProps: any) {
    const { index, member } = newProps;
    if (index !== this.props.index) {
      const header = {
        routeComponentName:
          index === "payschedules"
            ? "payScheduleBreadCrumb"
            : "businessUnitBreadCrumb",
        routesContainer: findAuthorizationClaims({
          claims:
            index === "payschedules"
              ? getCurrentAuthorizationDom(member)
              : this.state.pageClaims?.components,
          name:
            index === "payschedules"
              ? PAYSCHEDULE_BREAD_CRUMB_CONTAINER
              : BUSINESS_UNIT_BREAD_CRUMB_CONTAINER,
        }),
      };
      newProps.updateHeader({ header });
    }
  }

  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        {...this.props}
        loading={this.state.loading}
        match={{ params: this.props.router.params }}
        getCompany={(data: any) =>
          this.executeAction(this.props.getCompany, data)
        }
        getBusinessUnit={(data: any) =>
          this.executeAction(this.props.getBusinessUnit, data)
        }
        getPayrollProvider={(data: any) =>
          this.executeAction(this.props.getPayrollProvider, data)
        }
        connectPayProcessor={(data: any) =>
          this.executeAction(this.props.connectPayProcessor, data)
        }
        getPayschedule={(data: any) =>
          this.executeAction(this.props.getPayschedule, data)
        }
        updateBusinessUnit={(data: any) =>
          this.executeAction(this.props.updateBusinessUnit, data)
        }
        signal={this.signal}
        pageClaims={pageClaims}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  businessunit: state.businessUnit.detail || {},
  payscheduleUsers: mustBeArray(state.payschedule.users),
  payschedules: mustBeArray(state.payschedule.list),
  payschedulesCount: state.payschedule.count || 0,
  company: state.company.detail || {},
  userMode: "",
  member: state.member,
});

const mapDispatchToProps = {
  getBusinessUnit,
  getPayschedule,
  getPayrollProvider,
  payrollProviderLookup,
  connectPayProcessor,
  getCompany,
  updateBusinessUnit,
  getPayScheduleFees,
  addProcessingFee,
  removeProcessingFee,
  companyLookup,
  listCompanyBusinessUnits,
  updatePaySchedule,
  listTaxFees,
  listBusinessUnits,
  handleAirmaxAccount,
  listUserRoles,
  handleEmailTemplatesActions,
  listPSPUsers,
  populateStates,
  earningLinesLookup,
  listAmendmentTypes,
  getProcessingFee,
  listBillingServiceProviders,
  listBillingAccounts,
  listCurrency,
  countriesLookup,
  timezoneLookup,
  listPayrollProviders,
  listCompanies,
  saveBusinessUnit,
  externalPayCategoryLookup,
  fetchPayrollConnectionName,
  fetchExternalBusinessunit,
  payCategoryLookUp,
  importKeypayEmployees,
  payscheduleHandler,
  businessunitHandler,
  updateHeader,
  updateAuthorizationClaim,
  cdnLookUpHandler,
  lookUpHandler,
  listCompanyUsers,
  handleCompanyUserActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessUnitDetail)
);
