export const PAYRUN_QUERY_KEYS = {
  GET_PAYRUN_AMENDMENTS: 'get-payrun-amendments',
  GET_PAYRUN_WORKERS: 'get-payrun-workers',
  GET_PAYRUN_DETAIL: 'get-payrun-detail',
  GET_PAYRUN_MILESTONES: 'get-payrun-milestones',
  GET_PAYRUN_HEADER: 'get-payrun-header',
  GET_PAYRUN_CHANGE_LOG: 'get-payrun-change-log',
  GET_PAYRUN_EARNING_LINES: 'get-payrun-earning-lines',
  GET_PAYRUN_SUMMARY: 'get-payrun-summary',
  GET_PAYRUN_FILES: 'get-payrun-files',
  GET_PAYRUN_INVOICE: 'get-payrun-invoice',
  GET_PAYRUN_WORKER_DETAILS: 'get-payrun-worker-details',
  GET_PAYRUN_EARNING_LINE_DETAILS_VARIANCE: 'get-payrun-earning-line-details-variance',
  GET_PAYRUN_LIST: 'get-payrun-list',
};

export const BUSINESS_UNIT_QUERY_KEYS = {
  GET_BUSINESS_UNIT: 'get-business-unit',
};
export const AMENDMENT_QUERY_KEYS = {
  GET_AMENDMENTS: 'get-amendments',
  GET_AMENDMENT_BY_ID: 'get-amendment-by-id',
};
