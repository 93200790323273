import styled, { css } from 'styled-components';

import { Body2XSmallMedium, BodySmallRegular, Colour } from '@xemplo/style-constants';

export const TagGroupWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  background: ${Colour.White[100]};
  margin-left: 16px;
  margin-right: 36px;
  z-index: 2;
  justify-content: space-between;

  &.has-leading-icon {
    margin-left: 48px;
  }
`;

const hidden = css`
  &.is-hidden {
    opacity: 0;
  }
`;

const hasSiblings = css`
  &.has-siblings {
    align-self: flex-end;
    padding-bottom: 12px;
  }
`;

export const TagGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;

  ${hasSiblings};
  ${hidden};
`;

export const Tag = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  padding: 0px 6px;
  height: 20px;
  border-radius: 4px;
  background: ${Colour.Gray[100]};
  user-select: none;
  z-index: 3;

  ${Body2XSmallMedium};
  color: ${Colour.Gray[500]};

  > svg {
    cursor: pointer;
  }
`;

export const SelectedOverflow = styled.span`
  ${BodySmallRegular};
  position: absolute;
  left: 0;
  right: 0;
  color: ${Colour.Gray[800]};
  user-select: none;
  ${hidden};
`;

export const SelectedText = styled.span`
  &.has-siblings {
    position: relative;
    top: 8px; /* align with input placeholder */
  }
`;

export const ClearTags = styled.button`
  z-index: 3;
  margin-left: auto;
  margin-right: 12px;
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${Colour.Gray[400]};
  font-size: 16px;
`;
