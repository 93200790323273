//@ts-nocheck
import React from "react";
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listBusinessUnits,
  businessunitHandler,
} from "../actions/businessunits";
import {
  listPSPUsers,
  fetchExternalBusinessunit,
} from "../actions/payrollprocessor";
import { earningLinesLookup, listAmendmentTypes } from "../actions/payrun";
import axios from "axios";
import {
  populateStates,
  cdnLookUpHandler,
  lookUpHandler,
} from "../actions/lookup";
import {
  updateAuthorizationClaim,
  updateHeader,
} from "../../User/actions/member";
import { withRouter } from "../../hooks";

class BusinessUnit extends SwiftContainer {
  signal = axios.CancelToken.source();
  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        {...this.props}
        userMode={this.props.userMode}
        businessunit={this.props.businessunit}
        totalPages={this.props.totalPages}
        loading={this.state.loading}
        signal={this.signal}
        listBusinessUnits={(data: any) =>
          this.executeAction(this.props.listBusinessUnits, data)
        }
        pageClaims={pageClaims}
        member={this.props.member}
        listPSPUsers={this.props.listPSPUsers}
        populateStates={this.props.populateStates}
        earningLinesLookup={this.props.earningLinesLookup}
        listAmendmentTypes={this.props.listAmendmentTypes}
        fetchExternalBusinessunit={this.props.fetchExternalBusinessunit}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        updateHeader={this.props.updateHeader}
        match={{ params: this.props.router.params }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  businessunit: state.businessUnit.list || [],
  totalPages: state.businessUnit.total,
  userMode: "",
  member: state.member,
});

const mapDispatchToProps = {
  listBusinessUnits,
  listPSPUsers,
  populateStates,
  updateAuthorizationClaim,
  earningLinesLookup,
  listAmendmentTypes,
  fetchExternalBusinessunit,
  updateHeader,
  cdnLookUpHandler,
  lookUpHandler,
  businessunitHandler,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessUnit)
);
