//@ts-nocheck
import React from "react";
import { Card } from "antd";
import { tableColumnGenerator } from "../../../../libs/table";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import { currencyFormater, formatByUnit } from "../../../../libs/utilities";

class AuditReportEarningList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  render() {
    const { data, loading } = this.props;
    let colTemplate = [
      {
        title: "Employee",
        dataIndex: "employeeName",
        sorter: false,
        width: "12%",
      },
      {
        title: "Pay Category",
        dataIndex: "payCategoryName",
        sorter: false,
        width: "12%",
      },
      {
        title: "Units",
        dataIndex: "units",
        sorter: false,
        width: "10%",
        render: (record: any) => {
          return formatByUnit(record, "hour/s");
        },
      },
      {
        title: "Location",
        dataIndex: "locationName",
        sorter: false,
        width: "12%",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        sorter: false,
        width: "12%",
      },
      {
        title: "Rate",
        dataIndex: "rate",
        sorter: false,
        width: "12%",
        render: (record: any) => {
          return `${currencyFormater(record)} per Hour`;
        },
      },
      {
        title: "Gross Earnings",
        dataIndex: "grossEarnings",
        sorter: false,
        align: "right",
        dataType: "currency",
        width: "10%",
      },
      {
        title: "Taxable Earnings",
        dataIndex: "taxableEarnings",
        sorter: false,
        align: "right",
        dataType: "currency",
        width: "10%",
      },
      {
        title: "SG Super",
        dataIndex: "superContribution",
        sorter: false,
        align: "right",
        dataType: "currency",
        width: "10%",
      },
    ];
    const column = tableColumnGenerator(colTemplate);
    return (
      <Card
        title={<div className="pt-3 pl-3">Earnings</div>}
        className="card-space-0"
      >
        <SimpleTableContainer
          columns={column}
          dataSource={data}
          loading={loading}
          onChange={this.handleTableChange}
          pagination={false}
          size="small"
        />
      </Card>
    );
  }
}

export default AuditReportEarningList;
