// @ts-nocheck
import React from "react";
import { ContainerOutlined, FormOutlined } from "@ant-design/icons";
import { Menu, Dropdown, message, Modal, Button as AntdButton } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  findAuthorizationClaims,
  getCurrentAuthorizationDom,
  isComponentVisibleEnabled,
} from "../../../libs";
import {
  PAYRUN_AUTHORIZATION_CLAIMS,
  PAYRUN_MENU_ITEM,
  PAYRUN_STATUS_IDS,
} from "../../../constants";
import { useAppSelector } from "../../../hooks";
import PayrunChangeLogModal from "./ChangeLogModal";
import {
  DawnArrowLongRight24,
  DawnDownload24,
  DawnMenuMore24,
  DawnPencilEdit24,
} from "@xemplo/icons";
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
} from "@xemplo/button";
import { useModal } from "@xemplo/modal";
import { EditMilestonesContent } from "./EditMilestones";
import { AuthorizationDOM } from "@xemplo/gp-types";
import {
  Milestone,
  MilestoneState,
} from "./EditMilestones/EditMilestones.types";
import {
  updatePayrunDates,
  skipPayruns,
  updateReimportEarninglines,
} from "../../actions/payrun";
import { useHttpClient } from "@xemplo/http";

import moment from "moment";
import { getPayrun } from "../../actions/payrun";
import { useDispatch } from "react-redux";
import {
  SkipPayrunModalHeader,
  SkipPayrunModalContent,
} from "./SkipPayrunModal";

export const TIME_DATE_FORMAT = "YYYY-MM-DD HH:mm:00.000";
export const InitialState = {
  [Milestone.AMENDMENT_DUE]: {
    isDisabled: false,
    newValue: null,
    newValueIsWeekend: false,
  },
  [Milestone.PREPARING_PAYRUN]: {
    isDisabled: false,
    newValue: null,
    newValueIsWeekend: false,
  },
  [Milestone.APPROVAL_DUE]: {
    isDisabled: false,
    newValue: null,
    newValueIsWeekend: false,
  },
  [Milestone.PAY_DATE]: {
    isDisabled: false,
    newValue: null,
    newValueIsWeekend: false,
  },
};

const PayrunViewOptions = (props: any) => {
  const [viewEditModal, setViewEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const member = useAppSelector((state) => state.member);
  const navigate = useNavigate();
  const { showAuditReport, payrun } = props;
  const [payrunDatesState, setPayrunDatesState] =
    useState<MilestoneState>(InitialState);
  const [payrunDatesLoading, setPayrunDatesLoading] = useState(false);
  const dispatchAction = useDispatch();
  const handleMenuClick = (value: any) => {
    switch (value && value.key) {
      case "detail":
        return navigate(`/payrun/${payrun?.payrunId}/detail`);
      case "auditReport":
        return navigate(`/payrun/${payrun?.payrunId}/details/auditReport`);
      case "changeLog":
        return handleChangeLogClick();
      case "editMilestones":
        return setViewEditModal(true);
      case "skipPayrun":
        return handleSkipButton();
      case "reimport":
        return dispatchAction(updateReimportEarninglines(true));
      default:
        break;
    }
  };

  const { cancelSignal } = useHttpClient();
  const claims = getCurrentAuthorizationDom(member);
  const payrunClaims = findAuthorizationClaims({
    claims,
    name: PAYRUN_MENU_ITEM,
  });

  /** Change Log Modal Stuff  */
  const [changeLogModalVisible, setChangeLogModalVisible] = useState(false);
  const handleChangeLogClick = () => {
    setChangeLogModalVisible(true);
  };
  const handleChangeLogModalClose = () => {
    setChangeLogModalVisible(false);
  };

  /** Update payrun Milestones */
  const handleSave = () => {
    setPayrunDatesLoading(true);
    const payload = createPayload();
    updatePayrunDates({
      payload: payload,
      cancelToken: cancelSignal.token,
    }).then((resp) => {
      setPayrunDatesLoading(false);
      if (resp?.status === 200) {
        // No error close modal
        setViewEditModal(false);
        // Refetch the payrun data to update the page data
        dispatchAction(
          getPayrun({
            id: payrun.payrunId,
            options: {},
            cancelToken: cancelSignal.token,
          })
        );
        message.success("Pay Run dates updated successfully");
      }
    });
  };
  const { configureModal, toggleModal } = useModal();

  // TODO:
  // Use this when edit milestone modal is replaced with xemplo modal
  //Edit milestones modal
  // const handleModalOpen = useCallback(() => {
  //   configureModal({
  //     open: true,
  //     header: {
  //       customHeader: (
  //         <EditMilestonesHeader
  //           handleSave={handleSave}
  //           errorMessage={errorMessage}
  //           handleCancel={handleModalClose}
  //           payrunDatesLoading={payrunDatesLoading}
  //         />
  //       ),
  //     },
  //     body: {
  //       content: (
  //         <EditMilestonesContent
  //           payrun={payrun}
  //           errorMessage={errorMessage}
  //           setPayrunDatesState={setPayrunDatesState}
  //         />
  //       ),
  //     },
  //     width: undefined,
  //   });
  // }, [payrun]);

  // useEffect(() => {
  //   configureModal({
  //     header: {
  //       customHeader: (
  //         <EditMilestonesHeader
  //           handleSave={handleSave}
  //           errorMessage={errorMessage}
  //           handleCancel={handleModalClose}
  //           payrunDatesLoading={payrunDatesLoading}
  //         />
  //       ),
  //     },
  //     body: {
  //       content: (
  //         <EditMilestonesContent
  //           payrun={payrun}
  //           errorMessage={errorMessage}
  //           setPayrunDatesState={setPayrunDatesState}
  //         />
  //       ),
  //     },
  //   });
  // }, [payrunDatesState, errorMessage, payrunDatesLoading]);

  const createPayload = () => {
    const payload = {
      payrunId: payrun.payrunId,
      amendmentDueDateTime:
        payrunDatesState[Milestone.AMENDMENT_DUE].newValue ??
        moment(payrun.ammendmentDueDateTime).format(TIME_DATE_FORMAT),
      preparationDueDateTime:
        payrunDatesState[Milestone.PREPARING_PAYRUN].newValue ??
        moment(payrun.preparationDueDateTime).format(TIME_DATE_FORMAT),
      approvalDueDateTime:
        payrunDatesState[Milestone.APPROVAL_DUE].newValue ??
        moment(payrun.approvalDueDateTime).format(TIME_DATE_FORMAT),
      payDateTime:
        payrunDatesState[Milestone.PAY_DATE].newValue ??
        moment(payrun.payDate).format(TIME_DATE_FORMAT),
    };
    return payload;
  };

  useEffect(() => {
    return () => {
      toggleModal(false);
    };
  }, []);

  useEffect(() => {
    checkDatesAreSequential();
  }, [payrunDatesState]);

  // Check if there are new dates
  const hasNewDate = () => {
    return !!(
      payrunDatesState[Milestone.AMENDMENT_DUE].newValue ||
      payrunDatesState[Milestone.PREPARING_PAYRUN].newValue ||
      payrunDatesState[Milestone.APPROVAL_DUE].newValue ||
      payrunDatesState[Milestone.AMENDMENT_DUE].newValue ||
      payrunDatesState[Milestone.PAY_DATE].newValue
    );
  };

  //Check the dates in the state are sequential and set the error message if not
  const checkDatesAreSequential = () => {
    const amendmentDueDateTime =
      payrunDatesState[Milestone.AMENDMENT_DUE].newValue ??
      payrun.ammendmentDueDateTime;
    const preparationDueDateTime =
      payrunDatesState[Milestone.PREPARING_PAYRUN].newValue ??
      payrun.preparationDueDateTime;
    const approvalDueDateTime =
      payrunDatesState[Milestone.APPROVAL_DUE].newValue ??
      payrun.approvalDueDateTime;
    const payDateTime =
      payrunDatesState[Milestone.PAY_DATE].newValue ?? payrun.payDate;
    if (
      // Check if there is new value
      hasNewDate() &&
      !(
        moment(amendmentDueDateTime).isSameOrBefore(preparationDueDateTime) &&
        moment(preparationDueDateTime).isSameOrBefore(approvalDueDateTime) &&
        moment(approvalDueDateTime).isSameOrBefore(payDateTime)
      )
    ) {
      setErrorMessage("Dates must be sequential");
    } else {
      setErrorMessage(null);
    }
  };

  /** Skip Payrun */
  const handleSkipPayrun = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      skipPayruns({
        payload: {
          payrunIds: [payrun.payrunId],
        },
        cancelToken: cancelSignal.token,
      }).then((resp) => {
        // If the payrun has already been skipped, the response will be 204 in that case redirect anyway
        if (resp?.status === 200 || resp?.status === 204) {
          message.success("Pay Run successfully skipped");
          toggleModal(false);
          navigate("/payrun");
        } else {
          reject("Error skipping Pay Run");
          message.error("Error skipping Pay Run, please try again");
        }
      });
    });
  };

  const handleSkipButton = () => {
    //TODO: Replace modal with prompt component
    configureModal({
      open: true,
      header: {
        customHeader: <SkipPayrunModalHeader />,
      },
      body: {
        content: (
          <SkipPayrunModalContent
            handleSkipPayrun={handleSkipPayrun}
            handleCancelSkipPayrun={() => toggleModal(false)}
          />
        ),
      },
      footer: {
        customFooter: <div />,
      },
      width: 400,
    });
  };

  const payrunSkipClaim = payrunClaims?.components?.find(
    (o: AuthorizationDOM) =>
      o.name === PAYRUN_AUTHORIZATION_CLAIMS.payrunSkipButton
  );

  const payrunEditClaim = payrunClaims?.components?.find(
    (o: AuthorizationDOM) =>
      o.name === PAYRUN_AUTHORIZATION_CLAIMS.payrunEditButton
  );
  const userCanEdit =
    isComponentVisibleEnabled({ claim: payrunEditClaim }) &&
    ![PAYRUN_STATUS_IDS.COMPLETED, PAYRUN_STATUS_IDS.PROCESSED].includes(
      payrun.payrunStatusID
    );

  //User can skip if payrun is in Pending Amendment, Pending Preparation, Pending Approval
  const userCanSkip =
    isComponentVisibleEnabled({ claim: payrunSkipClaim }) &&
    [
      PAYRUN_STATUS_IDS.GENERATED,
      PAYRUN_STATUS_IDS.DETAILS_VIEWED,
      PAYRUN_STATUS_IDS.AMENDMENTS_ADDED,
      PAYRUN_STATUS_IDS.AMENDEMENTS_SUBMITTED_SYSTEM_TRIGGERED,
      PAYRUN_STATUS_IDS.AMENDEMENTS_SUBMITTED_USER_TRIGGERED,
      PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_SYSTEM_TRIGGERED,
      PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED,
      PAYRUN_STATUS_IDS.PREPARED_SYSTEM_TRIGGERED,
      PAYRUN_STATUS_IDS.PREPARED_USER_TRIGGERED,
      PAYRUN_STATUS_IDS.IMPORTED_EARNING_LINES_SYSTEM_TRIGGERED,
      PAYRUN_STATUS_IDS.IMPORTED_EARNING_LINES_USER_TRIGGERED,
      PAYRUN_STATUS_IDS.IMPORTING_EARNING_LINES_SYSTEM_TRIGGERED,
      PAYRUN_STATUS_IDS.IMPORTING_EARNING_LINES_USER_TRIGGERED,
    ].includes(payrun.payrunStatusID);

  const handleEditModalClose = () => {
    setViewEditModal(false);
  };

  const canReimportEarnings = useMemo(() => {
    if (
      payrun.payrunStatusID <=
      PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED
    )
      return false;
    const payrunReimportClaim = payrunClaims?.components?.find(
      (o: AuthorizationDOM) =>
        o.name === PAYRUN_AUTHORIZATION_CLAIMS.payrunReimportButton
    );
    return isComponentVisibleEnabled({ claim: payrunReimportClaim });
  }, [payrun.payrunStatusID, payrunClaims?.components]);

  /** Drop down Menu options */
  const menu = (
    <Menu onClick={handleMenuClick} className="user-authentication">
      <Menu.Item key="detail">
        <ContainerOutlined /> Pay Run Detail
      </Menu.Item>
      {(payrun?.auditReportGenerated || showAuditReport) && (
        <Menu.Item key="auditReport">
          <FormOutlined /> Audit Report
        </Menu.Item>
      )}
      <Menu.Item key="changeLog">
        <ContainerOutlined /> Change Log
      </Menu.Item>
      {userCanEdit && (
        <Menu.Item key="editMilestones">
          <div className="flex ">
            <DawnPencilEdit24 className="mt-1 mr-3" /> Edit
          </div>
        </Menu.Item>
      )}
      {userCanSkip && (
        <Menu.Item key="skipPayrun">
          <div className="flex">
            <DawnArrowLongRight24 className="mt-1 mr-3" /> Skip
          </div>
        </Menu.Item>
      )}
      {canReimportEarnings && (
        <Menu.Item key="reimport">
          <div className="flex">
            <DawnDownload24 className="mt-1 mr-3" /> Reimport Earnings
          </div>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <Dropdown trigger={["click"]} overlay={menu}>
        <Button
          type={ButtonType.Secondary}
          className="ml-2"
          size={ButtonSize.Small}
          ariaLabel="options-button"
          icon={<DawnMenuMore24 />}
          iconPosition={ButtonIconPosition.Trailing}
        >
          Options
        </Button>
      </Dropdown>
      {/** TODO: Convert this modal to @xemplo/modal  */}
      <PayrunChangeLogModal
        visible={changeLogModalVisible}
        handleClose={handleChangeLogModalClose}
        title={payrun?.name}
        payrun={payrun}
        claims={payrunClaims}
        viewChangeLog
      />

      <Modal
        closable
        width="720px"
        onCancel={handleEditModalClose}
        title="Edit Pay Run Milestones"
        visible={viewEditModal}
        footer={[
          <AntdButton
            className="rounded-lg"
            type="ghost"
            onClick={handleEditModalClose}
          >
            Cancel
          </AntdButton>,
          <AntdButton
            loading={payrunDatesLoading}
            className="ml-2 rounded-lg"
            type="primary"
            onClick={handleSave}
            disabled={!!errorMessage}
          >
            Save
          </AntdButton>,
        ]}
      >
        <EditMilestonesContent
          payrun={payrun}
          errorMessage={errorMessage}
          setPayrunDatesState={setPayrunDatesState}
        />
      </Modal>
    </>
  );
};

export default PayrunViewOptions;
