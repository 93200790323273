import { BaseUser, BaseUserExtended } from "@xemplo/gp-types";

import { OrganizationCommon, OrganizationAddress } from "../common";

export type BusinessUnitAccountDetails = {
  accountingSystem: number;
  addressCountryID: number;
  addressCountryName: string | null;
  billingCurrencyID: number;
  billingServiceProviderAccountID: number;
  billingServiceProviderID: string;
  billingServiceProviderName: string | null;
  companyId: string;
  companyName: string;
  convertFrom: number;
  convertTo: number;
  externalBusinessUnitId: null;
  fixedConversionRate: number;
  invoicePrefixCode: string;
  isBusinessUnitSameAsCompany: boolean;
  isInvoiceCompany: boolean;
  isInvoiceNeeded: boolean;
  paySchedules: number;
  paymentMandatory: boolean;
  payrollServiceProviderId: string;
  payrollSystemConnection: string;
  payrollSystemConnectionId: number;
  percentConversionRate: number;
  referenceName: string | null;
  registeredBusinessName: string;
  assignedUsers: BusinessUnitAssignedUsers[];
  companyUsers: BusinessUnitCompanyUsersType[];
};

export type BusinessUnitType = OrganizationCommon &
  OrganizationAddress &
  BusinessUnitAccountDetails;

export type BusinessUnitAssignedUsers = BaseUser & {
  assigneeType: AssigneeType;
  businessUnitId: string;
};

export type BusinessUnitCompanyUsersType = BaseUser & {
  companyId: string;
  payrollServiceProviderId: string;
};

export type AssignedUserType = BaseUser & {
  assigneeType: AssigneeType;
  businessUnitId: string;
};

export type PayrollManagerUser = BaseUserExtended & {
  isUserAssignedAsPayrollManager: boolean;
  isPrimary: boolean;
};

export enum AssigneeType {
  primary = 1,
  secondary = 2,
}
