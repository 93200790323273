import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { Colour, MinBreakpoint, Shadow } from '@xemplo/style-constants';
import { zIndex } from '@xemplo/zindex';

import { ModalBodyOverflow } from './body';
import { ModalSize, ModalTheme } from './modal.types';

export const CustomOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${Colour.Gray[900]};
  opacity: 0.6;
  transition: all 0.1s ease-out;
  z-index: ${zIndex.ModalOverlay};

  &[data-theme=${ModalTheme.Dark}] {
    background: ${Colour.White[60]};
  }
`;

export const StyledModal = styled.dialog`
  --min-width: 100vw;
  --min-height: 100vh;
  --max-height: 100vh;
  --max-width: 100vw;
  --dialog-margin-top: auto;
  --dialog-margin-bottom: auto;
  --dialog-background: ${Colour.White[100]};

  border: none;
  position: absolute;
  background: transparent;
  min-width: var(--min-width);
  min-height: var(--min-height);
  max-height: var(--max-height);
  max-width: var(--max-width);
  width: 100%;
  padding: 0;
  overflow: hidden;
  z-index: ${zIndex.Modal};

  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;

  margin-top: var(--dialog-margin-top);
  margin-bottom: var(--dialog-margin-bottom);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &[open] {
    opacity: 1;
    visibility: visible;
  }

  &::backdrop {
    display: none;
  }

  @media ${MinBreakpoint.medium} {
    ${Shadow.Medium3};

    &[data-size=${ModalSize.Responsive}] {
      --min-width: none;
      --min-height: none;
      --max-height: calc(100vh - 96px);
      --max-width: calc(100vw - 64px);
    }
  }

  @media (min-width: 920px) {
    ${Shadow.Medium3};

    &[data-size=${ModalSize.Responsive}] {
      --min-width: 320px;
      --max-width: 856px;
    }
  }

  &[data-overflow=${ModalBodyOverflow.Extended}] {
    --max-height: 100vh;
    --min-height: 100vh;
    --dialog-margin-top: none;
    --dialog-margin-bottom: none;

    @media ${MinBreakpoint.medium} {
      --dialog-margin-top: 48px;
      --dialog-margin-bottom: 48px;
      top: 0;
      left: 0;
      transform: unset;

      &[data-contentoverflow='true'] {
        --max-height: none;
        --min-height: none;
      }

      &[data-contentoverflow='false'] {
        --max-height: calc(100vh - 96px);
        --min-height: none;
      }
    }
  }
`;

export const StyledDialogContent = styled.div<{ $width?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};

  min-height: var(--min-height);
  max-width: var(--max-width);
  max-height: var(--max-height);

  margin-bottom: var(--dialog-margin-bottom);
  background: var(--dialog-background);

  @media ${MinBreakpoint.medium} {
    border-radius: 8px;
  }

  &[data-theme=${ModalTheme.Dark}] {
    --dialog-background: ${Colour.Gray[800]};
    --dialog-font-color: ${Colour.White[100]};
  }
`;

export const StyledCloseButton = styled(IconButton)<{ $marginTop: number }>`
  position: absolute;
  right: 8px;
  top: ${({ $marginTop }) => `${$marginTop}px`};
  z-index: ${zIndex.ModalCloseButton};

  > svg:focus {
    outline: none;
  }

  @media ${MinBreakpoint.medium} {
    right: 10px;
    top: 10px;
  }
`;
