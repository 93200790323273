import React from "react";
import { getClaimAndStatus, mustBeArray } from "../../../libs/utilities";
import { Card, Empty } from "antd";
import SimpleButton from "../Button/SimpleButton";
import _ from "lodash";
import classNames from "classnames";
import CreateSimpleButton from "../Button/CreateSimpleButton";
import { If } from "../../ui";

const SingleLineGridCard = (props: any) => {
  const {
    claims,
    name,
    DisplayComponent,
    list,
    title,
    ignoreStatus,
    handleAction,
    dashboardView,
    viewMore,
    handleCreate,
    noDataDescription,
  } = props;
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  const cardTitle = dashboardView ? "" : title;
  const listIsEmpty = _.isEmpty(mustBeArray(list));
  return (
    <Card
      title={cardTitle}
      className={classNames(
        "rounded-[12px] no-shadow card-extra-remove-padding",
        {
          [claimAndStatus.status]: !ignoreStatus,
        }
      )}
      extra={
        <If
          condition={viewMore && !listIsEmpty}
          then={
            <div className="flex">
              <SimpleButton
                href={viewMore}
                className="ml-auto"
                type="primary"
                buttonText="View More"
                ignoreStatus
              />
            </div>
          }
          else={null}
        />
      }
    >
      <If
        condition={listIsEmpty}
        then={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={noDataDescription ?? "No Data"}
          />
        }
        else={mustBeArray(list).map((content: any, index: number) => {
          return (
            <DisplayComponent
              content={content}
              key={index}
              handleAction={handleAction}
              {...props}
            />
          );
        })}
      />
      <If
        condition={handleCreate}
        then={
            <div className="text-right mt-4">
              <CreateSimpleButton
                ignoreStatus={true}
                addModuleName="Amendment"
                onClick={handleCreate}
              />
            </div>
        }
      />
    </Card>
  );
};

export default SingleLineGridCard;
