import { PushpinOutlined } from "@ant-design/icons";
import { Card } from "antd";
import React from "react";
import { EmptyDashboardIcon } from "../../../Common/customicons";

const DashboardEmptyCard = () => {
  return (
    <Card className="w-3/5" size="small">
      <div className="py-[60px]">
        <div className="flex flex-col items-center text-center space-y-4 text-base ">
          <EmptyDashboardIcon />
          <p className="font-bold">No pages have been pinned</p>
          <p>Pin pages to your dashboard to start customising this space</p>
          <p>
            To pin pages to your Dashboard, navigate to the applicable page and
            click on {<PushpinOutlined />}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default DashboardEmptyCard;
