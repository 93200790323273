import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { BodySmallRegular } from '@xemplo/style-constants';

import { RadioButtonSize } from '../v1/radio-button.types';

import RadioButtonV2 from './radio-button-v2';
import { RadioGroupV2Props } from './radio-button-v2.types';

const StyledLabel = styled.label`
  cursor: pointer;
  &[data-disabled='true'] {
    pointer-events: none;
  }
  display: flex;
  align-items: center;
  gap: 12px;
  ${BodySmallRegular};
`;

export function RadioGroupV2({
  onChange,
  name,
  options,
  ariaLabel,
  size,
  showLabel,
  rules,
}: Readonly<RadioGroupV2Props>) {
  const { getValues } = useFormContext();
  const currentValue = getValues(name);

  // Note: When the options changed, the group lost the styles for the checked radio button.
  // Solution was to re render the entire list of radio buttons when the options changed.

  const renderRadioGroup = useCallback(() => {
    return options.map(({ id, label, value, disabled }, i) => {
      // Had to convert values toString because value can be a number or a string
      // and the comparison was failing, so to make it reliable, I converted both to string.
      const isChecked =
        currentValue != null ? currentValue.toString() === value.toString() : i === 0;

      return (
        <StyledLabel key={`${name}-${value}-${options.length}`} data-disabled={disabled}>
          <RadioButtonV2
            checked={isChecked}
            id={id}
            size={size ?? RadioButtonSize.Medium}
            onChange={(e) => !disabled && onChange?.(e)}
            name={name}
            value={value}
            disabled={disabled}
            ariaLabel={`${ariaLabel} ${label}`}
            rules={rules}
          />{' '}
          {showLabel && label}
        </StyledLabel>
      );
    });
  }, [currentValue, name, onChange, options, ariaLabel, size, showLabel, rules]);

  return renderRadioGroup();
}
export default RadioGroupV2;
