//@ts-nocheck
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export interface SearchIconButtonProps {
  name?: string;
  claims?: any;
  className?: string;
  onClick: (e: any) => void;
  hasClaims?: boolean;
  loading?: boolean;
}

const SearchIconButton: React.FC<SearchIconButtonProps> = ({
  name,
  claims,
  className,
  loading,
  onClick,
  hasClaims,
}) => {
  const claimAndStatus: any = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <Button
      type="primary"
      icon={<SearchOutlined />}
      className={`rounded-[4px] ${className ? className : ""} ${
        hasClaims ? claimAndStatus.status : ""
      }`}
      loading={loading}
      onClick={onClick}
      disabled={claimAndStatus.status === "Visible-Disabled"}
    />
  );
};

export default SearchIconButton;
