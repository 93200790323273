import requireAuth from "../../Common/requireAuth";
import { SCOPES } from "../authorizationClaims";
import PayrollProviderContainer from "../../Organization/containers/PayrollProvider";
import PayrollProviderDetailContainer from "../../Organization/containers/PayrollProviderDetail";
import PayrollIndex from "../../Organization/pages/PayrollProvider/Index";
import PayrollProviderDetail from "../../Organization/pages/PayrollProvider/Detail";
import CreatePSP from "../../Organization/pages/PayrollProvider/CreatePSP";

export const PayrollProviderRoutesWithSidebar = [
  {
    path: "payrollprovider",
    scope: SCOPES.PAYROLLPROVIDER,
    index: "payrollprovider",
    container: PayrollProviderContainer,
    componentPath: "../../Organization/pages/PayrollProvider/Index",
    element: (
      <PayrollProviderContainer
        scope={SCOPES.PAYROLLPROVIDER}
        Layout={requireAuth(PayrollIndex)}
      />
    ),
  },
  {
    path: "payrollprovider/:id",
    scope: SCOPES.PAYROLLPROVIDER,
    index: "payrollprovider",
    container: PayrollProviderDetailContainer,
    componentPath: "../../Organization/pages/PayrollProvider/Detail",
    element: (
      <PayrollProviderDetailContainer
        scope={SCOPES.PAYROLLPROVIDER}
        Layout={requireAuth(PayrollProviderDetail)}
      />
    ),
  },
];

export const PayrollProviderRoutesWithoutSidebar = [
  {
    path: "payrollprovider/create",
    scope: SCOPES.PAYROLLPROVIDER,
    index: "payrollprovider",
    container: PayrollProviderContainer,
    componentPath: "../../Organization/pages/PayrollProvider/CreatePSP",
    element: (
      <PayrollProviderContainer
        scope={SCOPES.PAYROLLPROVIDER}
        Layout={requireAuth(CreatePSP)}
      />
    ),
  },
  {
    path: "payrollprovider/:id/edit",
    scope: SCOPES.PAYROLLPROVIDER,
    index: "payrollprovider",
    container: PayrollProviderContainer,
    componentPath: "../../Organization/pages/PayrollProvider/CreatePSP",
    element: (
      <PayrollProviderContainer
        scope={SCOPES.PAYROLLPROVIDER}
        Layout={requireAuth(CreatePSP)}
      />
    ),
  },
];
