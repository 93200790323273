import { SCOPES } from "../authorizationClaims";
import CompanyContainer from "../../Organization/containers/Company";
import CompanyDetailContainer from "../../Organization/containers/CompanyDetail";
import CompanyIndex from "../../Organization/pages/Company/Index";
import CreateCompany from "../../Organization/pages/Company/CreateCompany";
import CompanyDetail from "../../Organization/pages/Company/Detail";
import requireAuth from "../../Common/requireAuth";

export const CompanyRoutesWithSidebar = [
  {
    path: "company",
    scope: SCOPES.COMPANY,
    index: "company",
    container: CompanyContainer,
    componentPath: "../../Organization/pages/Company/Index",
    element: (
      <CompanyContainer
        scope={SCOPES.COMPANY}
        Layout={requireAuth(CompanyIndex)}
      />
    ),
  },
  {
    path: "company/:id",
    scope: SCOPES.COMPANY,
    index: "company",
    componentPath: "../../Organization/pages/Company/Detail",
    container: CompanyDetailContainer,
    element: (
      <CompanyDetailContainer
        scope={SCOPES.COMPANY}
        Layout={requireAuth(CompanyDetail)}
      />
    ),
  },
];

export const CompanyRoutesWithoutSidebar = [
  {
    path: "company/create",
    scope: SCOPES.COMPANY,
    index: "company",
    componentPath: "../../Organization/pages/Company/CreateCompany",
    container: CompanyContainer,
    element: (
      <CompanyContainer
        scope={SCOPES.COMPANY}
        Layout={requireAuth(CreateCompany)}
      />
    ),
  },
  {
    path: "company/:id/edit",
    scope: SCOPES.COMPANY,
    index: "company",
    componentPath: "../../Organization/pages/Company/CreateCompany",
    container: CompanyContainer,
    element: (
      <CompanyContainer
        scope={SCOPES.COMPANY}
        Layout={requireAuth(CreateCompany)}
      />
    ),
  },
];
