import requireAuth from "../../Common/requireAuth";
import BillingServiceProviderContainer from "../../Organization/containers/BillingServiceProvider";
import BillingServiceProviderIndex from "../../Organization/pages/bsp";
import CreateBillingServiceProvider from "../../Organization/pages/bsp/create";
import CreateBillingServiceProviderAccount from "../../Organization/pages/bsp/addAccounts";
import BillingServiceProviderDetailIndex from "../../Organization/pages/bsp/detail";
import { SCOPES } from "../authorizationClaims";

export const BillingProviderRoutesWithSidebar = [
  {
    path: "billingserviceproviders",
    scope: SCOPES.BILLINGSERVICEPROVIDER,
    index: "billingserviceproviders",
    container: BillingServiceProviderContainer,
    componentPath: "../../Organization/pages/bsp",
    element: (
      <BillingServiceProviderContainer
        scope={SCOPES.BILLINGSERVICEPROVIDER}
        Layout={requireAuth(BillingServiceProviderIndex)}
      />
    ),
  },
  {
    path: "billingserviceproviders/:id",
    scope: SCOPES.BILLINGSERVICEPROVIDER,
    index: "billingserviceproviders",
    container: BillingServiceProviderContainer,
    componentPath: "../../Organization/pages/bsp/detail",
    element: (
      <BillingServiceProviderContainer
        scope={SCOPES.BILLINGSERVICEPROVIDER}
        Layout={requireAuth(BillingServiceProviderDetailIndex)}
      />
    ),
  },
];

export const BillingProviderRoutesWithoutSidebar = [
  {
    path: "billingserviceproviders/create",
    scope: SCOPES.BILLINGSERVICEPROVIDER,
    index: "billingserviceproviders",
    container: BillingServiceProviderContainer,
    componentPath: "../../Organization/pages/bsp/create",
    element: (
      <BillingServiceProviderContainer
        scope={SCOPES.BILLINGSERVICEPROVIDER}
        Layout={requireAuth(CreateBillingServiceProvider)}
      />
    ),
  },
  {
    path: "billingserviceproviders/:id/edit",
    scope: SCOPES.BILLINGSERVICEPROVIDER,
    index: "billingserviceproviders",
    container: BillingServiceProviderContainer,
    componentPath: "../../Organization/pages/bsp/create",
    element: (
      <BillingServiceProviderContainer
        scope={SCOPES.BILLINGSERVICEPROVIDER}
        Layout={requireAuth(CreateBillingServiceProvider)}
      />
    ),
  },
  {
    path: "billingserviceproviders/:id/account/create",
    scope: SCOPES.BILLINGSERVICEPROVIDER,
    index: "billingserviceproviders",
    container: BillingServiceProviderContainer,
    componentPath: "../../Organization/pages/bsp/addAccounts",
    element: (
      <BillingServiceProviderContainer
        scope={SCOPES.BILLINGSERVICEPROVIDER}
        Layout={requireAuth(CreateBillingServiceProviderAccount)}
      />
    ),
  },
  {
    path: "billingserviceproviders/:id/account/:accountId/edit",
    scope: SCOPES.BILLINGSERVICEPROVIDER,
    index: "billingserviceproviders",
    container: BillingServiceProviderContainer,
    componentPath: "../../Organization/pages/bsp/addAccounts",
    element: (
      <BillingServiceProviderContainer
        scope={SCOPES.BILLINGSERVICEPROVIDER}
        Layout={requireAuth(CreateBillingServiceProviderAccount)}
      />
    ),
  },
];
