import { useMemo } from "react";
import { Form, DatePicker } from "antd";
import { DISPLAY_DATE_FORMAT } from "../../../constants/formats";
import { disablePassedHolidaysNextYearDate } from "../../../libs";
import { cdnLookUpHandler } from "../../../Organization/actions/lookup";

type Props = {
  endDate: any;
  required?: boolean;
  handleChangeValue: (value: any) => void;
};

const EndDate = ({ required, endDate, handleChangeValue }: Props) => {
  const holidays = useMemo(async () => {
    const response = await cdnLookUpHandler({ type: "holidaysPeriod" });
    return response.data;
  }, []);

  return (
    <Form.Item
      label="End Date"
      className="mb-3  xl:row-start-3 xl:col-start-2"
      name="endDate"
      rules={[
        {
          required,
          message: "Please add an end date",
        },
      ]}
    >
      <DatePicker
        format={DISPLAY_DATE_FORMAT}
        value={endDate}
        onChange={(e) =>
          handleChangeValue({
            label: "endDate",
            value: e,
          })
        }
        showToday={false}
        disabledDate={(e) =>
          disablePassedHolidaysNextYearDate({
            selectedDate: e,
            holidays,
          })
        }
        className="w-full"
      />
    </Form.Item>
  );
};

export default EndDate;
