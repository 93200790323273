import styled from 'styled-components';

import { Button } from '@xemplo/button';
import { PayrunDetail } from '@xemplo/gp-types';
import {
  BodySmallMedium,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';

export const PayrunListTable = styled(XemploTable<PayrunDetail>)`
  background: transparent;
  .action-bar-container {
    display: none;
  }
  table {
    background: transparent;
    thead {
      display: none;
    }
    tbody {
      background: transparent;
      tr {
        box-sizing: border-box;
        background: ${Colour.White[100]};
        border: none;
        &:hover {
          box-shadow: none;
        }
      }
      .table-cell-wrapper {
        padding: 16px 0;
      }
    }
  }
`;

export const IconContainer = styled.div`
  border-radius: 8.889px;
  border: 1.111px solid ${Colour.Gray[50]};
  background: ${Colour.White[100]};
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const NameCellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewButton = styled(Button)`
  width: 120px;
`;
export const RightAlignedCell = styled.div`
  margin-left: auto;
`;

export const Name = styled.div`
  ${BodySmallMedium};
  color: ${Colour.Gray[600]};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-grow: 1;
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-grow: 1;
`;

export const DueDateContainer = styled.div`
  display: flex;
  align-items: center;
  ${BodyXSmallRegular};
  gap: 4px;
  color: ${Colour.Gray[400]};
`;

export const AmendmentCount = styled.div`
  pointer-events: none;
  &.has-amendments {
    pointer-events: auto;
    cursor: pointer;
    color: ${Colour.Blue[500]};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-block: 12px;
  justify-content: center;
  ${BodyXSmallRegular};
  color: ${Colour.Gray[400]};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${Colour.Blue[600]};
  }
`;

export const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;

  > svg {
    width: 240px;
  }
`;
export const NoDataLabel = styled.span`
  ${BodyXSmallSemiBold};
  color: ${Colour.Gray[300]};
`;
