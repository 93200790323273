//@ts-nocheck
import React from "react";
import { message, Spin, Row, Tabs } from "antd";
import ProcessingFees from "../../../components/processingFees/fees";
import ProcessingFeesModel from "../../../components/processingFees";
import SwiftMessage from "../../../../constants/message";
import _ from "lodash";
import CountryCoordinates from "../../../../Common/countries";
import {
  errorDisplay,
  mustBeArray,
  updateAuthorizationClaims,
  findAuthorizationClaims,
  delay,
  getCurrentCardGrid,
  isComponentVisible,
} from "../../../../libs/utilities";
import moment from "moment";
import CurrencyConversionFeesForm from "../../../components/paySchedule/currencyConversionFeesForm";
import ModuleInfoCard from "../../../components/common/moduleInfoCard";
import MultiTabContainer from "../../../../Common/ClaimContainers/MultiTabContainer";
import ChildTabContainer from "../../../../Common/ClaimContainers/ChildTabContainer";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import GeneralContainer from "../../../../Common/ClaimContainers/GeneralContainer";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import PayDates from "../../../components/paySchedule/payDates";
import PayScheduleBasicDetail from "../../../components/paySchedule/basicDetail";
import PayScheduleCurrencyConversionFees from "../../../components/paySchedule/currencyFees";
import PayScheduleExternalPayRun from "../../../components/paySchedule/payScheduleExternalPayrun";
import {
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_SCROLLING,
  SORTING_NOT_REQUIRED,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  MULTI_TAB_ACTIVE_KEY_INDEX,
  TABLE_PERSONALIZATIONS,
  GRID_PERSONALIZATIONS,
  PAGE_NUMBER_INDEX,
  GRID_ROW_INDEX,
  GRID_COLUMN_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  SORT_ORDER_INDEX,
  SORT_COLUMN_INDEX,
  PAGE_SIZE_INDEX,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  TABLE_VIEW_VALUE,
  GRID_VIEW_VALUE,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  LIST_PAGINATION_TYPE_INDEX,
  GRID_PAGE_NUMBER_INDEX,
} from "../../../../constants/defaultClaims";
import axios from "axios";
import ProcessingFeesDisplayCard from "../../../components/paySchedule/ProcessingFeesDisplayCard";
import ProcessingFeesView from "../../../components/processingFees/view";
import TabHeader from "../../../../Common/tabHeader";
import { PAYSCHEDULE_EDITABLE_PARAM } from "../../../../constants/paySchedule";
import { END_EMPLOYEE_NUMBER } from "../../../../constants/payrun";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER,
  PAYSCHEDULE_STATUS_CONTAINER,
} from "../../../../constants";
import { If } from "../../../../Common/ui";
import { getDefaultPage, getSearchParamsFromUrl } from "../../../../libs";
import { withRouter } from "../../../../hooks";
import {
  getPayScheduleDetailRoute,
  getPayScheduleEditRoute,
  getPayScheduleRoute,
} from "../../../../libs";

type State = any;

class PayScheduleDetail extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  state = {
    payschedule: {},
    feeDetails: {},
    showProcessingFeeDetails: false,
    visible: false,
    ps_edit_display: false,
    payscheduleFee: [],
    taxFeeList: [],
    payscheduleFeeCount: 0,
    pageLoading: true,
    countryCode: "",
    basePayLoading: true,
    saveDetailLoading: false,
    updateStatusLoading: false,
    current: 1,
    viewEditCurrencyFees: false,
    updateCurrencyLoading: false,
    actionList: [],
    // Processing Fees
    processingFeesPagination: 1,
    processingFeesPerPage: 10,
    processingFeesTotal: 0,
    processingFeesList: [],
    processingFeesListLoading: false,
    processingFeesAddable: false,
    processingFeesGridable: false,
    processingFeesGrid: {
      page: 1,
      col: 4,
      row: 5,
      sortInfo: {},
    },
    processingFeesGridView: false,
    processingFeesIsPinned: "false",
    processingFeesSortingType: "notrequired",
    processingFeesHasMoreData: false,
    processingFeesSortInfo: {},
    processingFeesGridSortOption: [],
    processingFeesSelectedGridSort: undefined,
    processingFeesFieldOptions: [],
    activeKey: "1",
    businessunitid: getSearchParamsFromUrl("businessunitid"),
    companyid: getSearchParamsFromUrl("companyid"),
  };

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }
  componentDidMount() {
    const { payscheduleid } = this.props.match.params;
    const { businessunitid } = this.state;
    this.listProcessingFees({ options: {} });
    this.props.updateHeader({
      header: {
        title: "",
        module: "Pay Schedule",
        enableBack: true,
        action: "",
        returnUrl: getPayScheduleRoute(),
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayScheduleDetail,
      },
    });
    this.props
      .getPayschedule({
        id: payscheduleid,
        options: {},
        cancelToken: this.signal.token,
      })
      .then((response) => {
        if (response.status && response.data) {
          this.setState({
            actionList: [
              {
                name: response.data.isActive ? "Active" : "Inactive",
                isActive: response.data.isActive,
                componentName: "payScheduleStatusSwitchButton",
              },
            ],
          });
          let countryCode = CountryCoordinates.getCode(
            response.data.countryName
          );
          this.setState({
            payschedule: response.data,
            payscheduleFeeCount: response.total,
            pageLoading: false,
            countryCode,
          });
          this.props.getBusinessUnit({
            id: response.data.businessUnitID,
            options: {},
            cancelToken: this.props.signal.cancelToken,
          });
          this.props.updateHeader({
            header: {
              title: response.data && response.data.payScheduleName,
              module: "Pay Schedule",
              enableBack: true,
              action: "",
              returnUrl: getPayScheduleRoute(),
            },
          });
          this.props
            .listAmendmentTypes({
              options: {},
              businessUnitID: response?.data?.businessUnitID,
              lookUp: true,
            })
            .then((resp) => {
              this.setState({ basePayLoading: false });

              if (resp.status && resp.data) {
                this.setState({
                  basePayList: mustBeArray(resp.data),
                  defaultBaseSalary: mustBeArray(resp.data),
                });
              } else {
                errorDisplay(resp?.data?.validationErrors);
              }
            });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });

    this.getCurrencyFees();

    this.props.listTaxFees({ id: businessunitid }).then((response) => {
      if (response.status && response.data) {
        this.setState({ taxFeeList: response.data });
      } else {
        errorDisplay(response?.data?.validationErrors);
      }
    });

    // Multi Tab
    const payScheduleMultiTabContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER,
    });
    const activeKey =
      payScheduleMultiTabContainer?.personalizations?.[
        MULTI_TAB_ACTIVE_KEY_INDEX
      ]?.value;

    if (activeKey) {
      this.setState({ activeKey });
    }
    // Processing Fees
    const processingFeesListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payScheduleProcessingFeesListContainer",
    });
    const processingFeesGeneralTable = findAuthorizationClaims({
      claims: processingFeesListContainer?.components,
      name: "payScheduleProcessingFeesGeneralTable",
    });
    const processingFeesGeneralGrid = findAuthorizationClaims({
      claims: processingFeesListContainer?.components,
      name: "payScheduleProcessingFeesGeneralGrid",
    });
    const processingFeesPaginationType =
      processingFeesListContainer?.personalizations?.[
        LIST_PAGINATION_TYPE_INDEX
      ]?.value;
    const processingFeesSortingType =
      processingFeesListContainer?.personalizations?.[
        LIST_SORTING_REQUIRED_INDEX
      ]?.value;
    const processingFeesIsPinned =
      processingFeesListContainer?.personalizations?.[LIST_PINNABLE_INDEX]
        ?.value;

    this.setState({
      processingFeesPaginationType,
      processingFeesSortingType,
      processingFeesIsPinned,
      processingFeesGridable: processingFeesListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: processingFeesGeneralTable })) {
      const processingFeesPagination = getDefaultPage({
        page: processingFeesGeneralTable?.personalizations?.[PAGE_NUMBER_INDEX]
          ?.value,
      });
      const pageSize = processingFeesGeneralTable.personalizations
        ? Number(
          processingFeesGeneralTable.personalizations[PAGE_SIZE_INDEX]
            .value || 20
        )
        : 20;
      const SortOrder = processingFeesGeneralTable.personalizations
        ? processingFeesGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = processingFeesGeneralTable.personalizations
        ? processingFeesGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          processingFeesPerPage:
            processingFeesPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          processingFeesPagination,
          processingFeesSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(processingFeesListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (processingFeesPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingProcessingFees({ pageLoad: true });
            } else {
              this.listProcessingFees({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: processingFeesGeneralGrid })) {
      const pageNumber = processingFeesGeneralGrid.personalizations
        ? Number(
          processingFeesGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
            .value
        )
        : 1;
      const row = processingFeesGeneralGrid.personalizations
        ? Number(
          processingFeesGeneralGrid.personalizations[GRID_ROW_INDEX].value
        )
        : 5;
      const col = processingFeesGeneralGrid.personalizations
        ? Number(
          processingFeesGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
        )
        : 4;
      const SortOrder = processingFeesGeneralGrid.personalizations
        ? processingFeesGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX]
          .value
        : null;
      const SortColumn = processingFeesGeneralGrid.personalizations
        ? processingFeesGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX]
          .value
        : null;

      this.setState(
        {
          processingFeesGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          processingFeesSelectedGridSort: _.find(
            this.state.processingFeesGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            mustBeArray(processingFeesListContainer?.personalizations)?.[0]
              ?.value === GRID_VIEW_VALUE
          ) {
            this.setState({ processingFeesGridView: true });
            if (processingFeesPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridProcessingFees({ pageLoad: true });
            } else {
              this.processingFeesFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  }

  udpateProcessingFee = () => {
    this.setState({ visible: true });
  };

  editPayScheduleDetail = () => {
    const { payscheduleid } = this.props.match.params;
    const { companyid, businessunitid } = this.state;

    this.props.updateHeader({
      header: {
        module: "Back to Pay Schedule Detail",
        enableBack: true,
        title: ``,
        entity: "pay schedule",
        returnUrl: getPayScheduleDetailRoute({
          id: payscheduleid,
          companyId: companyid,
          businessUnitId: businessunitid,
        }),
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayScheduleEdit,
      },
    });
    this.props.router.navigate(
      getPayScheduleEditRoute({
        id: payscheduleid,
        companyId: companyid,
        businessUnitId: businessunitid,
      })
    );
  };

  deletePaySchedule = () => {
    const { payscheduleid } = this.props.match.params;

    this.props
      .payscheduleHandler({ id: payscheduleid, action: "delete" })
      .then((resp) => {
        if (resp.status) {
          message.success("Pay Schedule deleted successfully.");
          this.props.router.navigate(getPayScheduleRoute());
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors, true);
        }
      });
  };

  onClose = () => {
    this.setState({
      visible: false,
      ps_edit_display: false,
      saveDetailLoading: false,
    });

    const { processingFeesPaginationType, processingFeesGridView } = this.state;
    if (processingFeesGridView) {
      if (processingFeesPaginationType === PAGINATION_SCROLLING) {
        this.listScrollingGridProcessingFees({ pageLoad: false });
      } else {
        this.processingFeesFetchMoreData({ isPaginated: true });
      }
    } else {
      if (processingFeesPaginationType === PAGINATION_SCROLLING) {
        this.listScrollingProcessingFees({ pageLoad: false });
      } else {
        this.listProcessingFees({ options: {} });
      }
    }
  };

  updatePaySchedule = (postData, successMessage) => {
    const { payscheduleid } = this.props.match.params;

    this.setState({ saveDetailLoading: true });
    postData.id = payscheduleid;
    postData.isActive = successMessage
      ? postData.isActive
      : this.state.payschedule?.isActive;

    this.props.updatePaySchedule(postData).then((response) => {
      this.setState({ saveDetailLoading: false, updateStatusLoading: false });

      if (response.status) {
        this.setState({ ps_edit_display: false });
        message.success(
          successMessage ? successMessage : SwiftMessage["save.success"]
        );

        this.props
          .getPayschedule({ id: payscheduleid, options: {} })

          .then((resp) => {
            if (resp.status && resp.data) {
              this.setState({
                actionList: [
                  {
                    name: resp.data.isActive ? "Active" : "Inactive",

                    isActive: resp.data.isActive,
                    componentName: "payScheduleStatusSwitchButton",
                  },
                ],
              });

              this.setState({ payschedule: resp.data });

              this.props.updateHeader({
                header: {
                  title: resp?.data?.payScheduleName,
                  module: "Pay Schedule",
                  enableBack: true,
                  action: "",
                  returnUrl: getPayScheduleRoute(),
                },
              });
            }
          });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
    });
  };

  removeProcessingFee = (fee) => {
    this.setState({ processingFeeTableLoading: true });

    this.props
      .removeProcessingFee({
        id: this.state.payschedule.id,
        processingFeeId: fee.id,
      })

      .then((resp) => {
        this.setState({ processingFeeTableLoading: false });

        if (resp.status) {
          message.success("Processing fee deleted successfully");

          const { processingFeesPaginationType, processingFeesGridView } =
            this.state;
          if (processingFeesGridView) {
            if (processingFeesPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridProcessingFees({ pageLoad: false });
            } else {
              this.processingFeesFetchMoreData({ isPaginated: true });
            }
          } else {
            if (processingFeesPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingProcessingFees({ pageLoad: false });
            } else {
              this.listProcessingFees({ options: {} });
            }
          }
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };

  addProcessingFee = (fee) => {
    let lastRangeFactor = _.last(fee?.employeeNumberRangeFactor);
    if (lastRangeFactor) {
      fee.employeeNumberRangeFactor = [
        ...mustBeArray(fee?.employeeNumberRangeFactor),
        {
          from: Number(lastRangeFactor?.to + 1),
          to: END_EMPLOYEE_NUMBER,
          rate: 0,
        },
      ];
    }

    const payscheduleid = this.props.match.params?.payscheduleid;
    const postData = { ...fee, payscheduleid };

    const { id } = this.state.payschedule;

    return this.props.addProcessingFee({ id, postData }).then((resp) => {
      return resp;
    });
  };

  viewEditCurrencyFees = () => {
    this.setState({ viewEditCurrencyFees: true });
  };
  getCurrencyFees = () => {
    const { payscheduleid } = this.props.match.params;
    if (payscheduleid) {
      this.props
        .payscheduleHandler({
          action: "getCurrencyFees",
          id: payscheduleid,
        })
        .then((resp) => {
          if (resp.status) {
            this.setState({ currencyFees: resp.data ? resp.data : {} });
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
    }
  };

  updateCurrencyFees = ({ payload }) => {
    this.setState({ updateCurrencyLoading: true });

    const { payscheduleid } = this.props.match.params;
    payload.payScheduleId = payscheduleid;

    this.props
      .payscheduleHandler({
        action: "updateCurrencyFees",
        id: payscheduleid,
        payload,
      })
      .then((resp) => {
        this.setState({ updateCurrencyLoading: false });

        if (resp.status) {
          this.setState({
            viewEditCurrencyFees: false,
            currencyFees: resp.data,
          });
          message.success("Currency fees updated successfully");

          this.getCurrencyFees();
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };

  cancelEditingCurrencyFees = () => {
    this.setState({
      viewEditCurrencyFees: false,
      updateCurrencyLoading: false,
    });
  };

  handleStatusChange = ({ value, name }) => {
    switch (name) {
      case "Active":
      case "Inactive":
        this.toggleStatus(value);
        break;
      default:
        break;
    }
  };

  toggleStatus = (value) => {
    const { payschedule } = this.state;

    this.setState({ updateStatusLoading: true }, () => {
      let payload = {
        businessUnitId: payschedule.businessUnitID,
        payScheduleName: payschedule.payScheduleName,
        payOccurenceId: payschedule.payOccurenceId,
        firstPayDate: moment(
          payschedule.firstPayDate,
          "MM/DD/YYYY h:mm:ss A"
        ).format("YYYY-MM-DD"),
        payRunGenerationDueInDay: payschedule.payRunGenerationDueInDays,
        payRunGenerationDueTime: payschedule.payRunGenerationDueTime,
        amendmentDueInDay: payschedule.amendmentDueInDays,
        amendmentDueTime: payschedule.amendmentDueTime,
        payRunPreparationDueInDay: payschedule.payRunPreparationDueInDays,
        payRunPreparationDueTime: payschedule.payRunPreparationDueTime,
        payRunApprovalDueInDay: payschedule.approvalDueInDays,
        payRunApprovalDueTime: payschedule.approvalDueTime,
        payrollModelId: payschedule.payrollModelID,
        isUploadBankFile:
          Number(payschedule.isUploadBankFile) === 1 ? true : false,
        bankFileUploadDueInDay: payschedule.bankFileUploadDueInDays,
        bankFileUploadDueTime: payschedule.bankFileUploadDueTime,
        isBankFileUploadDatePayDay: payschedule.isBankFileUploadDatePayDay,
        bankFileDateInDay: payschedule.bankFileDateInDays,
        paymentDueInDay: payschedule.paymentDueInDays,
        paymentDueTime: payschedule.paymentDueTime,
        isActive: value,
        reference: payschedule.reference,
        basePayMapping: payschedule.basePayMapping,
        isInvoiceNeeded: payschedule.isInvoiceNeeded,
        id: payschedule.id,
      };

      this.updatePaySchedule(
        payload,
        `${value ? "Activated" : "Deactivated"} successfully`
      );
    });
  };
  handleTabChange = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.updatePersonalization(PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER);
    });
  };
  updatePersonalization = (field, update = true) => {
    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member?.details?.authorizationDOM
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      let updatedDOM = [];
      const payScheduleProcessingFeesListContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "payScheduleProcessingFeesListContainer",
      });
      switch (field) {
        case PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER:
          if (
            payScheduleProcessingFeesListContainer.personalizable === "true"
          ) {
            personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

            personalizations[
              MULTI_TAB_ACTIVE_KEY_INDEX
            ].value = `${this.state.activeKey}`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleProcessingFeesGeneralTable":
          if (
            payScheduleProcessingFeesListContainer.personalizable === "true"
          ) {
            personalizations = [...TABLE_PERSONALIZATIONS];
            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.processingFeesPagination}`;
            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.processingFeesPerPage}`;
            personalizations[SORT_COLUMN_INDEX].value =
              this.state.processingFeesSortInfo?.field;
            personalizations[SORT_ORDER_INDEX].value =
              this.state.processingFeesSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleProcessingFeesGeneralGrid":
          if (
            payScheduleProcessingFeesListContainer.personalizable === "true"
          ) {
            const { processingFeesGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${processingFeesGrid?.page}`;
            personalizations[
              GRID_ROW_INDEX
            ].value = `${processingFeesGrid?.row}`;
            personalizations[
              GRID_COLUMN_INDEX
            ].value = `${processingFeesGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              processingFeesGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              processingFeesGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleProcessingFeesListContainer":
          if (
            payScheduleProcessingFeesListContainer.personalizable === "true"
          ) {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];
            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.processingFeesGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;
            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.processingFeesPaginationType;
            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.processingFeesSortingType;
            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.processingFeesIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      let updateChange = update;
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };
      } else {
        updateChange = false;
      }
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update: updateChange,
        });
      });
    } catch (err) {
      return err;
    }
  };

  // Processing Fees

  getProcessingFeesTableSortInfo = () => {
    if (
      this.state.processingFeesSortInfo?.field &&
      this.state.processingFeesSortInfo?.order &&
      this.state.processingFeesSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.processingFeesSortInfo.field]:
            this.state.processingFeesSortInfo.order,
        },
      };
    } else return {};
  };
  listScrollingGridProcessingFees = ({ options = {}, pageLoad = false }) => {
    const { businessunitid } = this.state;

    if (!pageLoad && !this.state.processingFeesHasMoreData) return false;

    this.setState(
      {
        processingFeesListLoading: true,
        processingFeesGrid: {
          ...this.state.processingFeesGrid,
          page: !pageLoad
            ? Number(
              this.state.processingFeesGrid &&
              this.state.processingFeesGrid.page
            ) + 1
            : Number(
              this.state.processingFeesGrid &&
              this.state.processingFeesGrid.page
            ),
        },
      },
      () => {
        const { processingFeesGrid } = this.state;
        const { page } = processingFeesGrid;

        const per_page = this.getProcessingFeesGridPageSize();

        let sortingParams = this.getProcessingFeesGridSortInfo();

        this.props
          .getProcessingFees({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { businessUnitID: businessunitid },
              ...sortingParams,
            },
          })

          .then((response) => {
            this.setState({ processingFeesListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { processingFeesGrid: { ...processingFeesGrid, page: 1 } },
                  () => {
                    this.listScrollingGridProcessingFees({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.processingFeesList = pageLoad
                      ? data
                      : prevState.processingFeesList.concat(data);

                    prevState.processingFeesHasMoreData =
                      response.total > prevState.processingFeesList.length
                        ? true
                        : false;

                    prevState.processingFeesTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        "payScheduleProcessingFeesGeneralGrid"
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listProcessingFees = ({ options }) => {
    const { payscheduleid } = this.props.match.params;

    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.processingFeesPagination = !_.isNaN(Number(options.page))
            ? Number(options.page)
            : 1;
          prevState.processingFeesSortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.processingFeesListLoading = true;
        }
        return prevState;
      },
      () => {
        let tempOptions = {
          page: !_.isNaN(Number(this.state.processingFeesPagination))
            ? Number(this.state.processingFeesPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.processingFeesPerPage))
            ? Number(this.state.processingFeesPerPage)
            : 10,

          ...this.getProcessingFeesTableSortInfo(),
        };

        this.props
          .getProcessingFee({ options: tempOptions, id: payscheduleid })
          .then((resp) => {
            this.setState({ processingFeesListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.processingFeesPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ processingFeesPagination: 1 }, () => {
                  this.listProcessingFees({ options: {} });
                });
              } else {
                this.setState({
                  processingFeesList: mustBeArray(resp.data),
                  processingFeesTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payScheduleProcessingFeesGeneralTable");
        });
      }
    );
  };

  processingFeesFetchMoreData = ({ isPaginated = false }) => {
    const { payscheduleid } = this.props.match.params;

    this.setState({ processingFeesListLoading: true });

    const { processingFeesGrid } = this.state;
    const { page } = processingFeesGrid;

    const per_page = this.getProcessingFeesGridPageSize();

    let sortingParams = this.getProcessingFeesGridSortInfo();

    this.props
      .getProcessingFee({
        options: { page, per_page, ...sortingParams },
        id: payscheduleid,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { processingFeesGrid: { ...processingFeesGrid, page: 1 } },
              () => {
                this.processingFeesFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.processingFeesList = data;
                } else {
                  prevState.processingFeesList =
                    prevState.processingFeesList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.processingFeesList.length <
                    this.state.processingFeesTotal
                  ) {
                    prevState.processingFeesHasMoreData = true;
                    prevState.processingFeesGrid = {
                      ...prevState.processingFeesGrid,
                      page: prevState.processingFeesGrid.page + 1,
                    };
                  } else {
                    prevState.processingFeesHasMoreData = false;
                  }
                }

                prevState.processingFeesTotal = response.total;
                prevState.processingFeesListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(
                    "payScheduleProcessingFeesGeneralGrid"
                  );
                });
              }
            );
          }
        }
      });
  };

  listProcessingFeesGrid = (page) => {
    const { payscheduleid } = this.props.match.params;

    this.setState(
      (prevState) => {
        prevState.processingFeesGrid.page = page;
        prevState.processingFeesListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.processingFeesGrid?.page,
          per_page: this.getProcessingFeesGridPageSize(),

          ...this.getProcessingFeesGridSortInfo(),
        };

        this.props
          .getProcessingFee({ options: tempOptions, id: payscheduleid })
          .then((resp) => {
            this.setState({ processingFeesListLoading: false });

            if (resp.status) {
              this.setState({
                processingFeesList: mustBeArray(resp.data),
                processingFeesTotal: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payScheduleProcessingFeesGeneralGrid");
        });
      }
    );
  };

  getProcessingFeesGridSortInfo = () => {
    if (
      this.state.processingFeesGrid?.sortInfo?.field &&
      this.state.processingFeesGrid?.sortInfo?.order &&
      this.state.processingFeesSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.processingFeesGrid.sortInfo.field]:
            this.state.processingFeesGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.processingFeesGrid?.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.processingFeesGrid.sortInfo.order,
        },
      };
    } else return {};
  };

  handleProcessingFeesGridChange = ({ gridView, isPaginated }) => {
    if (this.state.processingFeesGridView === gridView) {
      return false;
    }

    this.setState(
      {
        processingFeesGridView: gridView,
        processingFeesList: [],
        processingFeesListLoading: true,
      },
      () => {
        this.updatePersonalization("payScheduleProcessingFeesListContainer");
        if (!gridView) {
          if (
            this.state.processingFeesPaginationType === PAGINATION_SCROLLING
          ) {
            this.listScrollingProcessingFees({ pageLoad: true, options: {} });
          } else {
            this.listProcessingFees({ options: {} });
          }
        } else {
          if (
            this.state.processingFeesPaginationType === PAGINATION_SCROLLING
          ) {
            this.listScrollingGridProcessingFees({
              pageLoad: true,
              options: {},
            });
          } else {
            this.processingFeesFetchMoreData({ isPaginated });
          }
        }
      }
    );
  };

  processingFeesUpdatePageSize = (value) => {
    if (this.state.processingFeesGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.processingFeesGrid.row = Number(row);
          prevState.processingFeesGrid.col = Number(col);
          prevState.processingFeesGrid.page = 1;
          return prevState;
        },
        () => {
          if (
            this.state.processingFeesPaginationType === PAGINATION_SCROLLING
          ) {
            this.listScrollingGridProcessingFees({
              pageLoad: true,
              options: {},
            });
          } else {
            this.processingFeesFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState(
        { processingFeesPerPage: Number(value), current: 1 },
        () => {
          if (
            this.state.processingFeesPaginationType === PAGINATION_SCROLLING
          ) {
            this.listScrollingProcessingFees({ pageLoad: true, options: {} });
          } else {
            this.listProcessingFees({ options: {} });
          }
        }
      );
    }
  };

  processingFeesUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.processingFeesSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.processingFeesGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.processingFeesGrid = {
            ...prevState.processingFeesGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.processingFeesPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridProcessingFees({ pageLoad: true, options: {} });
        } else {
          this.processingFeesFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleProcessingFeesAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "delete":
        this.removeProcessingFee(data);
        break;
      case "view":
        this.setState({ feeDetails: data }, () => {
          this.setState({ showProcessingFeeDetails: true });
        });
        break;
      default:
        break;
    }
  };
  closeProcessingFeeModal = () => {
    this.setState({ showProcessingFeeDetails: false, feeDetails: {} });
  };

  processingFeesUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.processingFeesGrid.sortInfo[field] === value) {
          prevState.processingFeesGrid.sortInfo[field] = "";
        } else {
          prevState.processingFeesGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.processingFeesFetchMoreData({ isPaginated: true });
      }
    );
  };

  getProcessingFeesGridPageSize = () => {
    const { row, col } = this.state.processingFeesGrid;

    return this.state.processingFeesPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updateProcessingFeesGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.processingFeesGrid.sortInfo[field] === value) {
          prevState.processingFeesGrid.sortInfo[field] = "";
        } else {
          prevState.processingFeesGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.processingFeesFetchMoreData({ isPaginated: true });
      }
    );
  };

  getPayscheduleEditComponent = () => {
    if (
      this.state.payschedule &&
      !this.state.payschedule[PAYSCHEDULE_EDITABLE_PARAM]
    )
      return true;
    return false;
  };
  render() {
    const { userMode, businessunit, pageClaims } = this.props;
    const {
      updateStatusLoading,
      actionList,
      activeKey,
      viewEditCurrencyFees,
      payschedule,
      currencyFees,
      updateCurrencyLoading,
      processingFeesListLoading,
      processingFeesList,
      processingFeesPagination,
      processingFeesSortInfo,
      processingFeesPerPage,
      processingFeesTotal,
      processingFeesGrid,
      processingFeesGridable,
      processingFeesGridView,
      processingFeesFieldOptions,
      processingFeesSortingType,
      processingFeesPaginationType,
      processingFeesIsPinned,
      processingFeesHasMoreData,
    } = this.state;
    // Multi Tab
    const payScheduleDetailMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER
    );
    // Payschedule details
    const payScheduleDetailTabContainer = _.find(
      mustBeArray(payScheduleDetailMultiTabContainer?.components),
      (o) => o.name === "payScheduleGeneralDetailTabContainer"
    );
    const payScheduleCycleOccuranceChildTabContainer = _.find(
      mustBeArray(payScheduleDetailTabContainer?.components),
      (o) => o.name === "payScheduleCycleOccuranceChildTabContainer"
    );
    const payScheduleGeneralDetailChildTabContainer = _.find(
      mustBeArray(payScheduleDetailTabContainer?.components),
      (o) => o.name === "payScheduleGeneralDetailChildTabContainer"
    );
    const payScheduleExternalPayrunChildTabContainer = _.find(
      mustBeArray(payScheduleDetailTabContainer?.components),
      (o) => o.name === "payScheduleExternalPayrunChildTabContainer"
    );
    const payScheduleCurrencyConversionFeesChildTabContainer = _.find(
      mustBeArray(payScheduleDetailTabContainer?.components),
      (o) => o.name === "payScheduleCurrencyConversionFeesChildTabContainer"
    );
    // Payschedule processing fees
    const payScheduleProcessingFeesTabContainer = _.find(
      mustBeArray(payScheduleDetailMultiTabContainer?.components),
      (o) => o.name === "payScheduleProcessingFeesTabContainer"
    );
    const payScheduleProcessingFeesChildTabContainer = _.find(
      mustBeArray(payScheduleProcessingFeesTabContainer?.components),
      (o) => o.name === "payScheduleProcessingFeesChildTabContainer"
    );

    const processingFeesCardGrid = getCurrentCardGrid({
      grid: this.state.processingFeesGrid,
    });
    return (
      <If
        condition={this.state.pageLoading}
        then={<Spin />}
        else={
          <div className="module-detail">
            <ModuleInfoCard
              detail={payschedule}
              actionList={actionList}
              claims={pageClaims}
              handleStatusChange={this.handleStatusChange}
              updateStatusLoading={updateStatusLoading}
              type="paySchedule"
              name={PAYSCHEDULE_STATUS_CONTAINER}
            />
            <MultiTabContainer
              name={PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER}
              claims={pageClaims}
              activeKey={activeKey}
              handleTabChange={this.handleTabChange}
            >
              {isComponentVisible({ claim: payScheduleDetailTabContainer }) && (
                <Tabs.TabPane key={"1"} tab="Pay Schedule Details">
                  <Row>
                    <ChildTabContainer
                      name="payScheduleCycleOccuranceChildTabContainer"
                      claims={payScheduleDetailTabContainer}
                      md={24}
                      sm={24}
                      lg={24}
                      hasGrids={true}
                    >
                      <GeneralContainer
                        name={"payScheduleCycleOccuranceContainer"}
                        claims={payScheduleCycleOccuranceChildTabContainer}
                        noGrids={true}
                      >
                        <PayDates data={payschedule} />
                      </GeneralContainer>
                    </ChildTabContainer>
                  </Row>
                  <Row gutter={DEFAULT_GUTTER}>
                    <ChildTabContainer
                      name="payScheduleGeneralDetailChildTabContainer"
                      claims={payScheduleDetailTabContainer}
                      lg={!_.isEmpty(currencyFees) ? 10 : 16}
                      md={12}
                      sm={24}
                      hasGrids={true}
                    >
                      <GeneralContainer
                        name={"payScheduleGeneralDetailContainer"}
                        claims={payScheduleGeneralDetailChildTabContainer}
                        noGrids={true}
                      >
                        <PayScheduleBasicDetail
                          data={this.state.payschedule}
                          edit={this.editPayScheduleDetail}
                          userMode={userMode}
                          claimComponent={
                            this.getPayscheduleEditComponent()
                              ? "payScheduleEdit"
                              : ""
                          }
                          deleteComponent={
                            this.getPayscheduleEditComponent() ? true : false
                          }
                          hasNoComponents={false}
                          handleDelete={this.deletePaySchedule}
                        />
                      </GeneralContainer>
                    </ChildTabContainer>
                    <ChildTabContainer
                      name="payScheduleExternalPayrunChildTabContainer"
                      claims={payScheduleDetailTabContainer}
                      hasGrids={true}
                      md={12}
                      sm={24}
                      lg={!_.isEmpty(currencyFees) ? 6 : 8}
                    >
                      <GeneralContainer
                        name={"payScheduleExternalPayrunContainer"}
                        claims={payScheduleExternalPayrunChildTabContainer}
                        noGrids={true}
                      >
                        <PayScheduleExternalPayRun
                          data={this.state.payschedule}
                        />
                      </GeneralContainer>
                    </ChildTabContainer>
                    {!_.isEmpty(currencyFees) && (
                      <ChildTabContainer
                        name="payScheduleCurrencyConversionFeesChildTabContainer"
                        claims={payScheduleDetailTabContainer}
                        hasGrids={true}
                        md={8}
                        sm={24}
                        lg={8}
                      >
                        <GeneralContainer
                          name={"payScheduleCurrencyConversionFeesContainer"}
                          claims={
                            payScheduleCurrencyConversionFeesChildTabContainer
                          }
                          noGrids={true}
                        >
                          <PayScheduleCurrencyConversionFees
                            currencyFees={currencyFees}
                            viewEditCurrencyFees={this.viewEditCurrencyFees}
                            claimComponent="payScheduleCurrencyConversionFeesEdit"
                            hasNoComponents={false}
                          />
                        </GeneralContainer>
                      </ChildTabContainer>
                    )}
                  </Row>
                </Tabs.TabPane>
              )}

              {isComponentVisible({
                claim: payScheduleProcessingFeesTabContainer,
              }) && (
                  <Tabs.TabPane
                    key={"2"}
                    tab={
                      <TabHeader
                        title="Processing Fees"
                        total={processingFeesTotal}
                      />
                    }
                  >
                    <ChildTabContainer
                      name="payScheduleProcessingFeesChildTabContainer"
                      claims={payScheduleProcessingFeesTabContainer}
                    >
                      <ListContainer
                        name="payScheduleProcessingFeesListContainer"
                        claims={payScheduleProcessingFeesChildTabContainer}
                        isPinned={processingFeesIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={processingFeesGridable}
                        gridView={processingFeesGridView}
                        grid={processingFeesGrid}
                        per_page={processingFeesPerPage}
                        GeneralTablePaginatedComponent={ProcessingFees}
                        GeneralTableScrollableComponent={ProcessingFees}
                        generalTableComponentName={
                          "payScheduleProcessingFeesGeneralTable"
                        }
                        gridComponentName={"payScheduleProcessingFeesGeneralGrid"}
                        createLinkButtonName={
                          this.getPayscheduleEditComponent()
                            ? "payScheduleProcessingFeesAddButton"
                            : ""
                        }
                        handleAddAction={this.udpateProcessingFee}
                        handleGridChange={this.handleProcessingFeesGridChange}
                        fieldOptions={processingFeesFieldOptions}
                        updateGridField={this.updateProcessingFeesGridField}
                        sortingType={processingFeesSortingType}
                        paginationType={processingFeesPaginationType}
                        list={processingFeesList}
                        listLoading={processingFeesListLoading}
                        listGrid={this.listProcessingFeesGrid}
                        listScrollingGrid={this.listScrollingGridProcessingFees}
                        hasMoreData={processingFeesHasMoreData}
                        gridActionComponentName={
                          "payScheduleProcessingFeesViewDetailLink"
                        }
                        handleGridAction={this.handleProcessingFeesAction}
                        listScrollingGeneralTable={
                          this.listScrollingProcessingFees
                        }
                        total={processingFeesTotal}
                        current={processingFeesPagination}
                        sortInfo={processingFeesSortInfo}
                        updatePageSize={this.processingFeesUpdatePageSize}
                        listGeneralTable={this.listProcessingFees}
                        tableActionComponentName={"processingFeesViewDetailLink"}
                        gridPageSize={this.getProcessingFeesGridPageSize()}
                        cardGrid={processingFeesCardGrid}
                        reminderComponent="payScheduleProcessingFeesSwitchButton"
                        editComponent={
                          this.getPayscheduleEditComponent()
                            ? "payScheduleProcessingFeesEdit"
                            : ""
                        }
                        pinnedComponentName="payScheduleProcessingFeesIsPinned"
                        showAddAndGrid={true}
                        addModuleName="Processing Fees"
                        DisplayCardComponent={ProcessingFeesDisplayCard}
                        showFeesDetails={true}
                        match={this.props.match}
                        listAmendmentTypes={this.props.listAmendmentTypes}
                        earningLinesLookup={this.props.earningLinesLookup}
                        getBusinessUnit={this.props.getBusinessUnit}
                        populateStates={this.props.populateStates}
                        getProcessingFee={this.props.getProcessingFee}
                        fetchProcessingFee={this.fetchProcessingFee}
                        countryCode={this.state.countryCode}
                        basePayMapping={
                          this.state.payschedule &&
                          this.state.payschedule.basePayMapping
                        }
                        removeProcessingFee={this.removeProcessingFee}
                        deleteComponent={
                          this.getPayscheduleEditComponent()
                            ? "payScheduleProcessingFeesDelete"
                            : ""
                        }
                        action={false}
                        userMode={userMode}
                        businessunitDetails={businessunit}
                        hideSorting={true}
                        isPayScheduleEditable={this.getPayscheduleEditComponent()}
                        rowIdParam="id"
                      />
                    </ChildTabContainer>
                  </Tabs.TabPane>
                )}
            </MultiTabContainer>

            <ProcessingFeesView
              visible={this.state.showProcessingFeeDetails}
              onCancel={this.closeProcessingFeeModal}
              feeDetails={this.state.feeDetails}
              businessunitDetails={this.props.businessunitDetails}
              countryCode={this.state.countryCode}
              basePayMapping={
                this.state.payschedule && this.state.payschedule.basePayMapping
              }
            />

            <ProcessingFeesModel
              visible={this.state.visible}
              addOption={this.addProcessingFee}
              removeOption={this.removeProcessingFee}
              businessunitDetails={businessunit}
              onClose={this.onClose}
              match={this.props.match}
              listAmendmentTypes={this.props.listAmendmentTypes}
              earningLinesLookup={this.props.earningLinesLookup}
              getBusinessUnit={this.props.getBusinessUnit}
              populateStates={this.props.populateStates}
              getProcessingFee={this.props.getProcessingFee}
              showFeesDetails={false}
              fetchProcessingFee={this.fetchProcessingFee}
              payCategoryLookUp={this.props.payCategoryLookUp}
              countryCode={this.state.countryCode}
              {...this.state}
            />

            <CurrencyConversionFeesForm
              visible={viewEditCurrencyFees}
              saveLoading={updateCurrencyLoading}
              detail={payschedule}
              cancelEditingCurrencyFees={this.cancelEditingCurrencyFees}
              updateCurrencyFees={this.updateCurrencyFees}
              currencyFees={currencyFees}
            />
          </div>
        }
      />
    );
  }
}

export default withRouter(PayScheduleDetail);
