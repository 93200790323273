//@ts-nocheck
import React from "react";
import { Card, Tooltip, Typography, Avatar } from "antd";
import { ContractIcon } from "../../../Common/customicons";
import TableActionComponent from "../common/tableActions";

const { Title } = Typography;

const BillingAccountsDisplayCard = ({
  handleAction,
  content,
  claims,
  gridEditComponent,
  gridDeleteComponent,
}: any) => {
  return (
    <Card
      className="card hand card-prop"
      bordered={false}
      onClick={(e) => handleAction({ data: content, action: "view" })}
    >
      <div className="position-relatvie pt-3 pb-3 pl-0 pr-0">
        <div className={"mb-2.5 px-[50px] position-relative"}>
          <div className="position-relative">
            <Tooltip placement="top" title={content && content.accountName}>
              <Title ellipsis className={"card-title"} level={3}>{`${
                content && content.accountName
              }`}</Title>
            </Tooltip>
            <Title
              ellipsis
              className={"card-title"}
              level={3}
            >{`Account Number: ${content && content.accountNumber}`}</Title>
          </div>
          {/**TODO: Replace with @xemplo/avatar lib  */}
          <Avatar
            className="position-absolute-top position-absolute-top-left"
            size={36}
          >
            <ContractIcon className="text-lg" />
          </Avatar>
        </div>
        <div className="flex justify-between mb-1 text-xs mt-4">
          <span>{`Bank: ${content.bank}`}</span>
        </div>
        <div className="flex justify-between mb-1 text-xs mt-3">
          <span>{`Currency: ${content.currency}`}</span>
        </div>
        <div className="flex justify-between mb-1 text-xs mt-3">
          <span>{`BSB: ${content.bsb}`}</span>
        </div>
        <div className="flex justify-between mb-1 text-xs mt-3">
          <span>{`Bank: ${content.bank}`}</span>
        </div>
      </div>
      <TableActionComponent
        classname="card-item mt-4 mr-4"
        buttonClassName="card-prop animated fadeIn position-absolute-top position-absolute-top-right"
        claims={claims}
        handleAction={(options: any) =>
          handleAction({ ...options, data: content, record: content })
        }
        editComponentName={gridEditComponent}
        deleteComponentName={gridDeleteComponent}
        viewComponentName={""}
      />
    </Card>
  );
};

export default BillingAccountsDisplayCard;
