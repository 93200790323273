// @ts-nocheck
import React from "react";
import { Modal } from "antd";
import SimpleButton from "../../ClaimComponents/Button/SimpleButton";

const DEFAULT_TEXT =
  "This may take a few minutes, to check on the progress please see the Amendment section on the right of the page";

const KeyPayLoadingModal = ({
  visible,
  handleClose,
  applyingToPayrollText,
}: {
  visible: boolean;
  handleClose: () => void;
  applyingToPayrollText?: string | null;
}) => (
  <Modal
    visible={visible}
    title="Applying changes to Payroll"
    width={810}
    onCancel={handleClose}
    footer={[
      <SimpleButton
        ignoreStatus
        buttonText="Ok"
        type="primary"
        onClick={handleClose}
        key="primary"
      />,
    ]}
  >
    <p className="text-center">{applyingToPayrollText || DEFAULT_TEXT}</p>
  </Modal>
);

export default KeyPayLoadingModal;
