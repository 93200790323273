import React, { Key } from "react";
import { If } from "../../../../Common/ui";
import {
  currencyFormater,
  getDifferenceAndVariance,
  getVarianceRowClassName,
  isEmpty,
  mustBeArray,
} from "../../../../libs";

type Props = {
  data: any;
  previousFees: any;
  itemKey: Key;
  highLightVarianceValue: number;
  columnClassName?: string;
  showVariance: boolean;
  invoice: any;
  businessunitDetails: any;
};
const EarningLinesFees = ({
  data,
  itemKey,
  previousFees,
  highLightVarianceValue,
  columnClassName,
  showVariance,
  invoice,
  businessunitDetails,
}: Props) => {
  return (
    <div>
      {mustBeArray(data).map((fee: any, r: any) => {
        const previousFee = mustBeArray(previousFees).find(
          (o: any) => o.id === fee.id
        );
        const feesVariance = getDifferenceAndVariance(
          previousFee?.amount,
          fee?.amount
        ).variance;
        return Number(fee.taxFeeAmount) === 0 &&
          Number(fee.amount === 0) ? null : (
          <div className="approveTable-expandSection" key={r}>
            <div
              key={itemKey}
              className={`process-table ${getVarianceRowClassName({
                data: feesVariance,
                highLightVarianceValue,
              })}`}
            >
              <div
                className={`${columnClassName}-processing-description pl-11`}
              >
                {" "}
                {fee?.feeOptionDescription}
              </div>
              <If
                condition={showVariance && !isEmpty(invoice)}
                then={
                  <div
                    className={`${columnClassName}-processing-previousTaxes text-right`}
                  >
                    <If
                      condition={
                        !previousFee || Number(previousFee?.taxFeeAmount) === 0
                      }
                      then={<React.Fragment />}
                      else={currencyFormater(
                        previousFee?.taxFeeAmount,
                        businessunitDetails?.country
                      )}
                    />
                  </div>
                }
              />
              <If
                condition={showVariance}
                then={
                  <div
                    className={`${columnClassName}-processing-previousAmount text-right`}
                  >
                    {currencyFormater(
                      previousFee?.amount,
                      businessunitDetails?.country
                    )}
                  </div>
                }
              />
              <div className={`${columnClassName}-processing-taxes text-right`}>
                <If
                  condition={Number(fee?.taxFeeAmount) === 0}
                  then={<React.Fragment />}
                  else={currencyFormater(
                    fee?.taxFeeAmount,
                    businessunitDetails?.country
                  )}
                />
              </div>
              <div
                className={`${columnClassName}-processing-amount text-right`}
              >
                {currencyFormater(fee?.amount, businessunitDetails?.country)}
              </div>
              <If
                condition={showVariance}
                then={
                  <div
                    className={`${columnClassName}-processing-variance text-right`}
                  ></div>
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default EarningLinesFees;
