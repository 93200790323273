import { Row } from '@tanstack/react-table';

import { formatToCurrency } from '@xemplo/common-util';
import { Amendment, AmendmentKeypayStatusIds, ChangeTaskTypes } from '@xemplo/gp-types';
import { DawnCircleInformation } from '@xemplo/icons';
import { Tooltip, TooltipPlacement } from '@xemplo/tooltip';

import * as S from '../amendments-table.styles';
export function TotalAmountCell({ row }: Readonly<{ row: Row<Amendment> }>) {
  const { units, amount, isIntegrated, amendmentKeypayStatusId } = row.original;

  const isPayInstruction =
    row.original.amendmentTaskTypeID === ChangeTaskTypes.PAY_INSTRUCTION;

  if (isPayInstruction) {
    return amount ? formatToCurrency({ value: amount }) : '-';
  }

  //TODO: Replace this with the value to be returned from BE https://expedo.atlassian.net/browse/GC-870
  const totalAmount = (units ?? 1) * (amount ?? 0);

  if (totalAmount) return formatToCurrency({ value: totalAmount });

  //We only need to show pending state for amounts if the amendment is integrated and has not yet been applied to keypay
  if (
    isIntegrated &&
    amendmentKeypayStatusId !== AmendmentKeypayStatusIds.Success &&
    !totalAmount
  ) {
    return (
      <S.Pending>
        Pending
        <Tooltip
          text="Pending amounts will be available once amendment is applied in PPS (Payroll Processing Service) "
          placement={TooltipPlacement.BottomLeft}
        >
          <DawnCircleInformation height={16} width={16} />
        </Tooltip>
      </S.Pending>
    );
  }
  return formatToCurrency({ value: totalAmount });
}
