type MessagesType = {
  "pendingAmendments.noData": string;
  "payrollprovider.update": string;
  "company.update": string;
  "businessunit.update": string;
  "payschedule.create": string;
  "save.success": string;
  "delete.success": string;
  "update.success": string;
  "approve.success": string;
  "decline.success": string;
  "submit.success": string;
  "processingPayment.success": string;
  "complete.success": string;
  "process.success": string;
  "confirmPayment.success": string;
  "confirmProcessing.success": string;
  "server.error": string;
  // Payruns
  "payRuns.noData": string;
  "payRuns.partialChangesAppliedToPayroll": string;
  "payRuns.noChangesAppliedToPayroll": string;
  "payRuns.allChangesAppliedToPayroll": string;
  "paySchedule.editNotAllowed": string;
};
const messages: MessagesType = {
  "pendingAmendments.noData": "No amendments are pending approval",
  "payrollprovider.update": "Payroll service provider updated successfully",
  "company.update": "Compay updated successfully",
  "businessunit.update": "Business unit updated successfully",
  "payschedule.create": "New payschedule created successfully",
  "save.success": "Saved successfully",
  "delete.success": "Deleted successfully",
  "update.success": "Updated successfully",
  "approve.success": "Approved successfully",
  "decline.success": "Declined successfully",
  "submit.success": "Submitted successfully",
  "processingPayment.success": "Processing Payment",
  "complete.success": "Completed Successfully",
  "process.success": "Processing",
  "confirmPayment.success": "Payment Successful",
  "confirmProcessing.success": "Finalizing Successful",
  "server.error":
    "We encountered error exeduting your request.Please check console for detail error logs",

  //  Pay run messages
  "payRuns.noData": "No pay runs available",
  "payRuns.partialChangesAppliedToPayroll":
    "Some of the change/s could not be applied to Payroll. Please manually apply change/s",
  "payRuns.noChangesAppliedToPayroll":
    "All changes could not be applied to Payroll. Please manually apply change/s",
  "payRuns.allChangesAppliedToPayroll":
    "Changes have been successfully applied to Payroll.",

  // Pay Schedule message
  "paySchedule.editNotAllowed":
    "Pay Schedule has already been processed and cannot be edited.",
};

export default messages;
