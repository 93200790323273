import { PropsWithChildren } from 'react';

import { XemploApiContext } from './xemplo-api.context';
import { XemploApiProps } from './xemplo-api.types';

/**
 * This provider is meant to be applied at the root of the application.
 *
 * @example
 * import XemploApiProvider from '@xemplo/xemplo-api-provider';
 *
 * const App = () => {
 *     <XemploApiProvider applicationApiUrl={environment.baseUrl} eimsUrl={environment.eimsUrl} dsUrl={environment.dsUrl}>
 *       <MyComponent />
 *     </XemploApiProvider>
 *   );
 * };
 *
 * @param children The rest of the react nodes
 * @returns
 */
export function XemploApiProvider(props: PropsWithChildren<XemploApiProps>) {
  return (
    <XemploApiContext.Provider value={props}>{props.children}</XemploApiContext.Provider>
  );
}

export default XemploApiProvider;
