import { SVGProps } from 'react';
const DawnCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.791 2.745a.75.75 0 0 0-1.5 0v.373c-1.453.13-2.611.63-3.434 1.493C2.894 5.619 2.5 7.01 2.5 8.542v8.005c0 1.532.394 2.923 1.357 3.932.969 1.015 2.402 1.527 4.232 1.527h7.744c1.83 0 3.263-.512 4.232-1.527.963-1.01 1.357-2.4 1.357-3.932V8.542c0-1.532-.394-2.923-1.357-3.931-.823-.863-1.981-1.362-3.434-1.493v-.373a.75.75 0 0 0-1.5 0v.339h-6.34v-.34Zm-1.5 1.88v1.06a.75.75 0 1 0 1.5 0V4.584h6.34v1.1a.75.75 0 0 0 1.5 0V4.626c1.107.123 1.856.504 2.35 1.021.61.64.941 1.604.941 2.896v.4H4v-.4c0-1.292.332-2.256.942-2.896.493-.517 1.242-.898 2.35-1.02ZM4 10.443v6.104c0 1.293.332 2.256.942 2.896.604.633 1.59 1.063 3.147 1.063h7.744c1.556 0 2.543-.43 3.147-1.063.61-.64.942-1.603.942-2.896v-6.104H4Zm9.05 3.19a1.089 1.089 0 1 1-2.178 0 1.089 1.089 0 0 1 2.178 0ZM11.96 17.99a1.089 1.089 0 1 0 0-2.178 1.089 1.089 0 0 0 0 2.178Zm4.356-4.356a1.089 1.089 0 1 1-2.178 0 1.089 1.089 0 0 1 2.178 0Zm-1.09 4.356a1.089 1.089 0 1 0 0-2.178 1.089 1.089 0 0 0 0 2.178Zm-5.444-4.356a1.089 1.089 0 1 1-2.177 0 1.089 1.089 0 0 1 2.177 0ZM8.694 17.99a1.089 1.089 0 1 0 0-2.178 1.089 1.089 0 0 0 0 2.178Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCalendar;
