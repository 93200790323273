import styled from 'styled-components';

import { Amendment } from '@xemplo/gp-types';
import {
  BodySmallMedium,
  BodyXSmallMedium,
  BodyXSmallRegular,
  Colour,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';

export const AmendmentListTable = styled(XemploTable<Amendment>)`
  background: transparent;
  ${BodyXSmallRegular};
  color: ${Colour.Gray[500]};

  .action-bar-container {
    border-radius: 8px;
    position: relative;
    box-sizing: content-box;
  }
  &#select-all-rows {
    height: 76px;
  }

  table {
    background: transparent;
    border-collapse: separate;
    thead {
      border: none;
      color: ${Colour.Gray[500]};
      ${BodyXSmallMedium};
    }

    tbody {
      background: transparent;

      tr {
        box-sizing: border-box;
        border: 1px solid transparent;
        background: ${Colour.White[100]};
        margin-bottom: 8px;
        border-radius: 8px;
        height: 76px;
        cursor: pointer;
      }
      td {
        &:first-child {
          color: ${Colour.Gray[700]};
          ${BodySmallMedium};
        }
      }
    }
  }
`;
export const WorkerCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const WorkerName = styled.span`
  ${BodySmallMedium};
  color: ${Colour.Gray[700]};
`;

export const LeadIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: ${Colour.Blue[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 20px;
    height: 20px;
    color: ${Colour.Blue[500]};
  }
`;

export const Pending = styled.span`
  color: ${Colour.Gray[300]};
  display: flex;
  align-items: center;
  gap: 4px;
`;
