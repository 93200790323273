import { Key } from "react";
import { If } from "../../../../Common/ui";
import {
  currencyFormater,
  getDifferenceAndVariance,
  getVarianceRowClassName,
  isEmpty,
  mustBeArray,
} from "../../../../libs";

type Props = {
  data: any;
  previousPayItems: any;
  itemKey: Key;
  highLightVarianceValue: number;
  columnClassName?: string;
  showVariance: boolean;
  invoice: any;
  businessunitDetails: any;
};
const EarningLinesPayItems = ({
  data,
  itemKey,
  previousPayItems,
  highLightVarianceValue,
  columnClassName,
  showVariance,
  invoice,
  businessunitDetails,
}: Props) => {
  return (
    <div>
      {mustBeArray(data).map((eachPayItem: any) => {
        const previousPayItem = previousPayItems.find(
          (o: any) => o.id === eachPayItem.id
        );
        const payItemVariance = getDifferenceAndVariance(
          previousPayItem?.["amount"],
          eachPayItem["amount"]
        ).variance;
        return (
          eachPayItem &&
          Number(eachPayItem.amount) !== 0 &&
          !eachPayItem.isTax && (
            <div className="approveTable-expandSection" key={eachPayItem.id}>
              <div
                key={itemKey}
                className={`process-table ${getVarianceRowClassName({
                  data: payItemVariance,
                  highLightVarianceValue,
                })}`}
              >
                <div
                  className={`${columnClassName}-processing-description pl-11`}
                >
                  {" "}
                  {eachPayItem["paymentItemName"]}
                </div>
                <If
                  condition={showVariance && !isEmpty(invoice)}
                  then={
                    <div
                      className={`${columnClassName}-processing-previousTaxes text-right`}
                    ></div>
                  }
                />
                <If
                  condition={showVariance}
                  then={
                    <div
                      className={`${columnClassName}-processing-previousAmount text-right`}
                    >
                      {`${currencyFormater(
                        previousPayItem?.["amount"],
                        businessunitDetails?.country
                      )}`}
                    </div>
                  }
                />
                <If
                  condition={!isEmpty(invoice)}
                  then={
                    <div
                      className={`${columnClassName}-processing-taxes text-right`}
                    ></div>
                  }
                />
                <div
                  className={`${columnClassName}-processing-amount text-right`}
                >
                  {currencyFormater(
                    eachPayItem["amount"],
                    businessunitDetails?.country
                  )}
                </div>
                <If
                  condition={showVariance}
                  then={
                    <div
                      className={`${columnClassName}-processing-variance text-right`}
                    ></div>
                  }
                />
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};
export default EarningLinesPayItems;
