import { useEffect, useState } from 'react';

import { PAYRUN_STATUS_IDS, PayrunsTableType, PayrunSteps } from '@xemplo/gp-types';
import { getFilterStatusId, transformStatus } from '@xemplo/gp-utils';
import { usePayrunsType } from '@xemplo/use-payruns-type';

import { activeStatusFilter } from './payrun-base-table.helper';

export interface UsePayrunTableFilterProps {
  filter?: { [key: string]: string };
}

export const usePayrunTableFilters = (props: UsePayrunTableFilterProps) => {
  const { filter } = props;

  const { payrunsType } = usePayrunsType();

  const getStatusId = () => {
    switch (payrunsType) {
      case PayrunsTableType.SystemGenerated:
        return transformStatus([
          PAYRUN_STATUS_IDS.DRAFT_SYSTEM_TRIGGERED,
          PAYRUN_STATUS_IDS.DRAFT_USER_TRIGGERED,
        ]).toString();
      case PayrunsTableType.Complete:
        return getFilterStatusId(PayrunSteps.Complete).toString();
      default: // PayrunTableType.active
        return activeStatusFilter.toString();
    }
  };
  const [queryFilter, setQueryFilter] = useState<{ statusID: string } | null>({
    statusID: getStatusId(),
    ...filter,
  });

  useEffect(() => {
    setQueryFilter({
      statusID: getStatusId(),
      ...filter,
    });
  }, [filter, payrunsType]);

  return { queryFilter, setQueryFilter };
};
