export enum ToastType {
  Confirmation = 'Confirmation',
  Warning = 'Warning',
  Alert = 'Alert',
}

export enum ToastTextWeight {
  Regular = 'regular',
  Medium = 'medium',
  SemiBold = 'semibold',
}

export type ToastProps = {
  weight?: ToastTextWeight;
  type?: ToastType;
  text: string;
};
