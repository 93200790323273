import { SVGProps } from 'react';
const DawnInvoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99 3.189C5.827 2.219 7.15 2 8.773 2h6.46c1.625 0 2.946.218 3.785 1.19.807.934.985 2.376.98 4.223v10.421c0 .67-.082 1.267-.265 1.753-.181.484-.49.927-.975 1.154-.498.233-1.03.165-1.497-.053-.457-.213-.9-.588-1.32-1.073v-.001c-.218-.252-.446-.324-.624-.313-.18.01-.403.11-.597.389l-.863 1.248-.002.003C13.4 21.593 12.739 22 12 22c-.739 0-1.4-.407-1.856-1.059l-.002-.003-.863-1.248c-.194-.28-.418-.379-.597-.39-.178-.01-.406.062-.623.314l-.001.001c-.418.483-.86.857-1.318 1.07-.467.217-.997.284-1.496.051-.485-.226-.794-.668-.977-1.154C4.084 19.095 4 18.498 4 17.825V7.412c0-1.847.181-3.289.99-4.223Zm1.113.99c-.401.464-.622 1.353-.622 3.233v10.413c0 .561.072.962.17 1.222.099.262.196.317.212.324.005.003.071.036.26-.052.201-.094.482-.303.82-.694l.558.495-.557-.496c.483-.56 1.14-.863 1.824-.823.684.04 1.301.417 1.723 1.026l.861 1.245.001.002c.236.336.48.424.647.424.167 0 .411-.088.647-.424v-.002l.861-1.245c.423-.609 1.04-.986 1.724-1.026.684-.04 1.34.263 1.824.824l-.557.495.558-.494v-.001c.34.393.622.604.825.699.19.088.255.054.258.052.014-.007.111-.061.21-.323.097-.26.168-.66.168-1.219V7.41c.005-1.88-.214-2.768-.614-3.231-.369-.428-1.064-.677-2.67-.677h-6.46c-1.606 0-2.3.249-2.67.677Zm2.215 3.81c0-.414.332-.75.74-.75h5.926c.41 0 .74.336.74.75a.746.746 0 0 1-.74.751H9.06a.746.746 0 0 1-.741-.75Zm.74 2.254c-.409 0-.74.336-.74.75 0 .415.332.752.74.752h4.939c.409 0 .74-.336.74-.751a.746.746 0 0 0-.74-.751H9.059Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnInvoice;
