import { displayDates, getFormattedDate } from "../../../libs";

const dateFormat = "MM/DD/YYYY hh:mm:ss A";
const timeFormat = "HH:mm";

type Props = {
  value: any;
  className?: string;
};

const DateAndTimeDisplay = ({ value, className }: Props) => (
  <time dateTime={value} className={className || ""}>
    {`${displayDates({ format: dateFormat, value })} At ${getFormattedDate({
      format: timeFormat,
      date: value,
      currentFormat: dateFormat,
    })}`}
  </time>
);

export default DateAndTimeDisplay;
