import { SVGProps } from 'react';
const DawnChartDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.766 3.65a1.01 1.01 0 1 0 0 2.02 1.01 1.01 0 0 0 0-2.02Zm-2.66 1.01a2.66 2.66 0 1 1 5.32 0 2.66 2.66 0 0 1-5.32 0ZM17.01 9.35c.36.279.426.797.147 1.157l-2.797 3.61a.825.825 0 0 1-1.162.143l-2.605-2.046-2.35 3.054a.825.825 0 1 1-1.308-1.006l2.859-3.714a.825.825 0 0 1 1.163-.146l2.607 2.048 2.288-2.954a.825.825 0 0 1 1.158-.146ZM7.984 2.804c-1.643 0-3.036.588-4.013 1.642C3 5.493 2.5 6.934 2.5 8.54v7.718c0 1.603.489 3.043 1.457 4.09.975 1.055 2.37 1.638 4.027 1.638H16.2c1.641 0 3.034-.584 4.012-1.636.971-1.046 1.471-2.485 1.471-4.092V9.538a.825.825 0 1 0-1.65 0v6.72c0 1.27-.391 2.282-1.03 2.97-.633.68-1.57 1.11-2.803 1.11H7.984c-1.254 0-2.189-.432-2.815-1.11-.634-.684-1.019-1.696-1.019-2.97V8.542c0-1.268.391-2.284 1.031-2.973.633-.683 1.57-1.114 2.803-1.114h6.94a.825.825 0 1 0 0-1.65h-6.94Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnChartDashboard;
