import { RefObject, useCallback, useEffect } from 'react';

import { useDebounceCallback } from '@xemplo/hooks';

import { setItems, toggleMenu } from './tab-menu.store';
import { useTabMenu } from './use-tab-menu';

const MINIMUM_GAP = 24;

type UseMenuTruncateProps = {
  menuRef: RefObject<HTMLUListElement>;
};

export const useMenuTruncate = ({ menuRef }: UseMenuTruncateProps) => {
  const { state, dispatch } = useTabMenu();
  const { items, needResize, useTruncate = true } = state;

  /**
   * The onResize is triggered based on the window resize event
   * and it is used to reset the menu items to the original state
   * when the window is resized. Then the menu items are recalculated
   * using the useEffect below.
   *
   * Also, we are not using the windowWidth values to calculate, but
   * to control when things needs to be re-calculated. Using the
   * effects on items causes massive re-renders and performance issues.
   * so instead, we are using the windowWidth to control render cycles
   * in conjunction with the needResize flag.
   */
  const onResize = useCallback(() => {
    if (state.windowWidth !== window.innerWidth) {
      // When the resize happens, we need to find the number of items based on its width
      // This requires the menu to be rendered with all items so the individual elements
      // can be measured and calculated within the screen size.
      dispatch(setItems({ visibleItems: items, hiddenItems: [], needResize: true }));
    }
  }, [dispatch, items, state.windowWidth]);

  const debouncedOnResize = useDebounceCallback(onResize, 200);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const ellipsis = menuRef.current?.querySelector('#ellipsis');
      !ellipsis?.contains(e.target as Node) && dispatch(toggleMenu());
    },
    [dispatch, menuRef]
  );

  /**
   * Effect to handle menu items truncation, it pre-renders the layout so it can
   * calculate the sizes to define whether they fit in the container or not.
   * When it doesn't fit, the items are added to the hiddenItems array.
   * The hiddenItems are then rendered in the sub menu.
   *
   * The timeout is needed so the first render with all elements can be calculated
   * properly. The timeout is set to 80ms, but it can be adjusted based on the
   * performance of the menu.
   */
  useEffect(() => {
    setTimeout(() => {
      const ellipsisPadding = 24;
      const menuEl = menuRef.current;
      if (!menuEl || !items.length || !needResize) return;

      const ellipsis = menuEl.querySelector<HTMLSpanElement>('#ellipsis');
      const containerHtml = menuEl.parentNode as HTMLElement;
      const containerWidth = containerHtml.offsetWidth;
      const ellipsisWidth = (ellipsis?.offsetWidth ?? 0) + ellipsisPadding;

      let totalWidth = ellipsisWidth;
      let numVisible = 0;
      let gap = 0;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const itemElement = menuEl.querySelector<HTMLLIElement>(
          `#item-wrapper-${item.id}`
        );
        if (!itemElement) continue;

        totalWidth += itemElement.offsetWidth;
        gap = containerWidth - totalWidth;

        if (totalWidth > containerWidth || gap < MINIMUM_GAP) {
          break;
        }

        numVisible++;
      }

      const newState = {
        visibleItems: items.slice(0, numVisible),
        hiddenItems: items.slice(numVisible),
        needResize: false,
      };

      dispatch(setItems(newState));
    }, 80);
  }, [dispatch, items, menuRef, needResize]);

  /** Effect to handle outside clicks when sub menu is opened */
  useEffect(() => {
    if (state.subMenuOpened) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => void document.removeEventListener('click', handleOutsideClick);
  }, [handleOutsideClick, state.subMenuOpened]);

  /** Effect to enable menu truncate functionality */
  useEffect(() => {
    if (!useTruncate) return;
    window.addEventListener('resize', debouncedOnResize);
    return () => void window.removeEventListener('resize', debouncedOnResize);
  }, [debouncedOnResize, useTruncate]);
};
