import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChangeTaskTypes } from '@xemplo/gp-types';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';

export const LumpSum = () => {
  const { register, watch, unregister } = useFormContext();
  const watchChangeTask = watch('changeTask');

  const showLumpSum = watchChangeTask === ChangeTaskTypes.ALLOWANCE;
  useEffect(() => {
    if (showLumpSum) {
      register('lumpSum', {
        required: 'This field is required',
        validate: (value) => {
          if (value === PLACEHOLDER_OPTION) {
            return 'This field is required';
          }
          return true;
        },
      });
    } else {
      unregister('lumpSum');
    }
  }, [register, showLumpSum, unregister]);

  if (showLumpSum) {
    return (
      <S.FormLabel>
        Lump Sum Payments?
        <S.LabelExtraInfo>
          Payments for which the PAYG obligation is spread across multiple pay periods.
          Refer to ATO Schedule 5 - Tax Table for Back Payments, Commissions, Bonuses and
          Similar Payments
        </S.LabelExtraInfo>
        <TextDropdownMultiV2
          items={YesNoOptions}
          name="lumpSum"
          isDropdownMulti={false}
          testId="lumpSum"
          aria-label="Lump sum field"
          selectProps={{
            placeholder: 'Select an option',
          }}
        />
      </S.FormLabel>
    );
  }
};

export const YesNoOptions = [
  { key: PLACEHOLDER_OPTION, value: 'Select an option' },
  { key: 'No', value: 'No' },
  { key: 'Yes', value: 'Yes' },
];
