import { Row } from '@tanstack/react-table';

import { Avatar, AvatarSize } from '@xemplo/avatar';
import { Amendment } from '@xemplo/gp-types';
import { getAssignedWorker } from '@xemplo/gp-utils';
import { DawnChartPath } from '@xemplo/icons';
import { NameCell } from '@xemplo/table';

import * as S from '../amendments-table.styles';

export function AssignToCell({ row }: Readonly<{ row: Row<Amendment> }>) {
  const assignTo = row.original.assignTo;
  if (assignTo === 'All') return assignTo;

  const worker = getAssignedWorker(assignTo);
  if (!worker || worker.length === 0) {
    return (
      <S.WorkerCellContainer>
        <S.LeadIconWrapper>
          <DawnChartPath />
        </S.LeadIconWrapper>
        No Workers attached
      </S.WorkerCellContainer>
    );
  }

  if (worker.length > 1) {
    return (
      <S.WorkerCellContainer>
        <S.LeadIconWrapper>
          <Avatar
            user={worker[0]}
            customText={worker.length.toString()}
            size={AvatarSize.s}
          />
        </S.LeadIconWrapper>
        {worker.length} Workers
      </S.WorkerCellContainer>
    );
  }
  return (
    <NameCell
      firstName={worker[0]?.firstName ?? ''}
      lastName={worker[0]?.lastName ?? ''}
    />
  );
}
