//@ts-nocheck
import React, { Component, Fragment } from "react";
import { Card, Tooltip } from "antd";
import {
  getAbnAndRegistrationText,
  isComponentVisible,
  mustBeArray,
} from "../../../libs/utilities";
import SwitchButton from "../../../Common/ClaimComponents/Filter/SwitchButton";
import _ from "lodash";
import UploadLogoAndProfileImage from "./UploadLogoAndProfileImage";
import { GeneratePayrunButton } from "../paySchedule/generate-payrun";

type State = any;

class ModuleInfoCard extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      detail,
      actionList,
      handleStatusChange,
      claims,
      type,
      name,
      className,
    } = this.props;
    const keyMapper = {
      businessUnit: {
        name: "registeredBusinessName",
        abn: "abn",
        status: "isActive",
        logoUrl: "logoURL",
      },
      company: {
        name: "registeredBusinessName",
        abn: "abn",
        status: "isActive",
        logoUrl: "logoURL",
      },
      payrollProvider: {
        name: "registeredBusinessName",
        abn: "abn",
        status: "isActive",
        logoUrl: "logoURL",
      },
      paySchedule: {
        name: "payScheduleName",
        abn: "abn",
        status: "isActive",
        logoUrl: "logoURL",
      },
      user: {
        name: "registeredBusinessName",
        abn: "abn",
        status: "isActive",
        logoUrl: "logoURL",
      },
      billingServiceProvider: {
        name: "registeredBusinessName",
        abn: "abn",
        status: "isActive",
        logoUrl: "logoURL",
      },
    };
    const registeredBusinessName = keyMapper[type]["name"];
    const logoUrl = keyMapper[type]["logoUrl"];
    const abn = keyMapper[type]["abn"];
    const claim = _.find(
      mustBeArray(claims && claims.components),
      (o) => o.name === name
    );
    return (isComponentVisible({ claim }) || !claim) && detail ? (
      <Card bordered={false} className={`w-full ${className} card`}>
        <div className="flex flex-start">
          <div className="flex flex-start">
            <UploadLogoAndProfileImage
              name={detail[registeredBusinessName]}
              profilePhoto={detail[logoUrl]}
              showUploadAndDelete={false}
              handleUpload={() => {
                console.log("");
              }}
            />
            <div className="pl-7 pt-2">
              {detail[registeredBusinessName] && (
                <Fragment>
                  <label className="ant-card-body-label">Registered as</label>
                  <div className="ant-card-body-value">
                    {detail[registeredBusinessName]}
                  </div>
                </Fragment>
              )}
              {detail[abn] && (
                <Fragment>
                  <label className="ant-card-body-label">
                    {getAbnAndRegistrationText({
                      countryId:
                        detail[
                          type === "businessUnit"
                            ? "addressCountryID"
                            : "countryId"
                        ],
                    })}
                  </label>
                  <div className="ant-card-body-value">{detail[abn]}</div>
                </Fragment>
              )}

              {mustBeArray(actionList).map((action: any, index: any) => {
                return (
                  <Tooltip
                    title={action.isActive ? "Deactivate" : "Activate"}
                    key={index}
                  >
                    <SwitchButton
                      handleChangeSwitch={(e: any) =>
                        handleStatusChange({ value: e, name: action.name })
                      }
                      name={action.name}
                      checked={action.isActive}
                      claims={claim}
                      componentName={action.componentName}
                    />
                  </Tooltip>
                );
              })}
            </div>
          </div>
          {type === "paySchedule" && detail?.isActive && (
            <GeneratePayrunButton payScheduleId={detail.id} />
          )}
        </div>
      </Card>
    ) : (
      <Card bordered={false} className={`w-full ${className} card`} />
    );
  }
}

export default ModuleInfoCard;
