import React from "react";
import RateField from "./Rate";

type Props = {
  question?: any;
  handleChange: (value: any) => void;
  required: boolean;
};

const MaxAmount = ({ question, handleChange, required }: Props) => (
  <RateField
    className="xl:row-start-3 xl:col-start-2 "
    label="Maximum Amount"
    name="recurringMaximumAmount"
    question={question}
    handleChange={handleChange}
    required={required}
  />
);

export default MaxAmount;
