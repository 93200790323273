import { Badge } from "../../ui";
import {
  REOCCURRING_STATUS_APPLIED_ID,
  REOCCURRING_STATUS_APPLIED_PARTIAL_ID,
  REOCCURRING_STATUS_APPLIED_SUCCESS_ID,
} from "../../../constants";

type Props = {
  statusInPPSID?: number;
};

const YesNoPartialStatus = ({ statusInPPSID }: Props) => {
  switch (statusInPPSID) {
    case REOCCURRING_STATUS_APPLIED_SUCCESS_ID: // Applied by Intergation settings
    case REOCCURRING_STATUS_APPLIED_ID:   // Applied manually
      return <Badge.Success>YES</Badge.Success>;
    case REOCCURRING_STATUS_APPLIED_PARTIAL_ID:
      return <Badge.Warning>PARTIAL</Badge.Warning>;
    default:
      return <Badge.Danger>NO</Badge.Danger>;
  }
};

export default YesNoPartialStatus;
