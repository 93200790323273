import styled from 'styled-components';

import { TextEllipsis } from '@xemplo/style-constants';

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  padding-right: 0;

  text-align: left;
  ${TextEllipsis};

  > svg {
    margin-left: 8px;
  }

  span {
    ${TextEllipsis};
  }
`;

export const CellContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  ${TextEllipsis};
`;
