import { IconButtonProps, IconButtonSize } from '@xemplo/icon-button';

import { ModalBodyProps } from './body';
import { ModalFooterProps } from './footer';
import { ModalHeaderProps } from './header';

export type ModalState = {
  open: boolean;
  asModal?: boolean; // decide whether to open the modal at the top layer (https://developer.mozilla.org/en-US/docs/Glossary/Top_layer)
  width?: number;
  theme?: ModalTheme;
  size?: ModalSize;
  onCloseCallback?: ModalOnCloseFn;
  scrollAnimation?: boolean;
  closeButton?: CloseButtonProps;
  /** Disabled the close modal action when clicking outside the modal element */
  disableOutsideClick?: boolean;
  // Content props
  header?: ModalHeaderProps;
  body?: ModalBodyProps;
  footer?: ModalFooterProps;
};

export type CloseButtonProps = Partial<IconButtonProps> & {
  hide?: boolean;
  dynamicSize?: () => IconButtonSize;
};

export type ModalCloseEvent =
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLDialogElement, MouseEvent>
  | MouseEvent;
export type ModalOnCloseFn = (event: ModalCloseEvent) => void;

/*State types */
export type ModalProps = Partial<ModalState>;

/*Modal Action Types*/
export enum ModalActions {
  SetModalOpen = 'SetModalOpen',
  SetModalConfig = 'SetModalConfig',
  SetModalFooterPrimaryButton = 'SetModalFooterPrimaryButton',
}

export type ModalAction =
  | { type: ModalActions.SetModalOpen; payload: boolean }
  | { type: ModalActions.SetModalConfig; payload: ModalProps };

export const enum ModalTheme {
  Dark = 'dark',
  Light = 'light',
}

export const enum ModalSize {
  Small = 'small',
  Large = 'large',
  Responsive = 'responsive', // default
}
