//@ts-nocheck
import React, { Component } from 'react';
import { Modal, Button } from 'antd';
 import { mustBeArray } from '../../../libs/utilities';
class EmployeeListDifference extends Component {
    mapArrayToString = (receivedArray: any) => {
        return mustBeArray(receivedArray).reduce((a: any, b: any) => {
            return a === "" ? `${b.id}` :
                `${a}, ${b.id}`
        }, "");
    }
    render() {
        const { visible, employeeList, fetchEarningLines, cancelImportEarninglines } = this.props;
        return (

            <Modal
                visible={visible}
                width={800}
                title="Missing Employees in Business Unit"
                // className="modal-padding-0"
                footer={[

                    <Button
                        onClick={cancelImportEarninglines}
                        key={1}
                    >
                        No
                    </Button>,

                    <Button key={2}
                        type="primary"
                        onClick={fetchEarningLines}>Yes</Button>
                ]}
            >
                <p>
                    Following employee IDs are missing in business unit.
                </p>
                <p>
                    {
                        this.mapArrayToString(employeeList)
                    }
                </p>
                <p>
                    Do you want to import the missing employees and continue?
                </p>

            </Modal>
        );
    }
}

export default EmployeeListDifference;
