//@ts-nocheck
import React from "react";
import { Card } from "antd";
import { tableColumnGenerator } from "../../../../libs/table";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";

class AuditReportEmployerLiabilitiesList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  render() {
    const { data, loading } = this.props;
    let colTemplate = [
      {
        title: "Employee",
        dataIndex: "employeeName",
        sorter: false,
        width: "25%",
      },
      {
        title: "Liability Category",
        dataIndex: "employerLiabilityCategoryName",
        sorter: false,
        width: "25%",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        sorter: false,
        width: "25%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: false,
        align: "right",
        dataType: "currency",
        width: "11%",
      },
    ];
    const column = tableColumnGenerator(colTemplate);
    return (
      <Card
        title={<div className="pt-3 pl-3">Employer Liabilities</div>}
        className="card-space-0"
      >
        <SimpleTableContainer
          columns={column}
          dataSource={data}
          loading={loading}
          onChange={this.handleTableChange}
          pagination={false}
          size="small"
          className="table-showing-total"
        />
      </Card>
    );
  }
}

export default AuditReportEmployerLiabilitiesList;
