import styled from 'styled-components';

import { PayrunDetail } from '@xemplo/gp-types';
import {
  BodySmallMedium,
  BodyXSmallMedium,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
  TextEllipsis,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';

export const Container = styled.div`
  padding: 32px;
`;

export const PayrunListTable = styled(XemploTable<PayrunDetail>)`
  background: transparent;
  ${BodyXSmallRegular};
  color: ${Colour.Gray[500]};
  .action-bar-container {
    border-radius: 8px;
    position: relative;
    box-sizing: content-box;
  }
  table {
    background: transparent;
    thead {
      border: none;
      color: ${Colour.Gray[400]};
      ${BodyXSmallMedium};
      th {
        & > div {
          white-space: normal;
        }
      }
    }

    tbody {
      background: transparent;

      ${BodyXSmallRegular};
      tr {
        box-sizing: border-box;
        background: ${Colour.White[100]};
        margin-bottom: 8px;
        border-radius: 8px;
        height: 76px;
        border: 1px solid transparent;
        cursor: pointer;
        &.active {
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid ${Colour.Blue[500]};
          background: rgba(11, 120, 255, 0.08);
        }
      }
      td {
        &:first-child {
          color: ${Colour.Gray[700]};
          ${BodySmallMedium};
        }
      }
    }
  }
`;

export const PayrunIcon = styled.div`
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${Colour.Purple[50]};
  flex-grow: 0;
  flex-shrink: 0;
  > svg {
    width: 20px;
    height: 20px;
    color: ${Colour.Purple[500]};
  }
`;

export const TrunctatedText = styled.div`
  ${TextEllipsis};
`;

export const PayrunNameCell = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  ${BodySmallMedium};
  width: 100%;
  & > div {
    ${TextEllipsis};
  }
`;

export const PayDateCell = styled.div`
  display: flex;
  flex-direction: column;
  ${BodyXSmallRegular};
`;

export const MilestoneDueDate = styled.div`
  ${BodyXSmallSemiBold};
  color: ${Colour.Gray[400]};
  &.due {
    color: ${Colour.Orange[500]};
  }
  &.overdue {
    color: ${Colour.Red[500]};
  }
`;
