/** React Aria */
import { Dispatch, SetStateAction } from 'react';
import type { ListProps, ListState } from '@react-stately/list';
import type { Node } from '@react-types/shared';

export interface ListItemProps {
  isFocused?: boolean;
  isSelected?: boolean;
}

export enum SelectionStyle {
  Checkbox = 'checkbox',
  Highlight = 'highlight',
}

export enum SelectionMode {
  None = 'none',
  Single = 'single',
  Multiple = 'multiple',
}

export interface ListItem {
  key: string | number;
  value: string;
}

export interface ListBoxProps<T> extends ListProps<T> {
  width?: string;
  maxHeight?: string;
  testId?: string;
  selectionStyle?: SelectionStyle;
  items?: Iterable<T>;
  state: ListState<T>;
  searchValue?: string;
  setSearchValue?: Dispatch<SetStateAction<string>>;
  onListClick?: (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => void;
  onItemClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, item?: Node<T>) => void;
}

export interface OptionProps<T> {
  item: Node<T>;
  state: ListState<T>;
  selectionStyle?: SelectionStyle;
  onItemClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, item?: Node<T>) => void;
}
