import { SortingState } from '@tanstack/react-table';

import { SortDirection } from '@xemplo/gp-types';

import { EncodeURIComponent, PagedRequest } from './api-query.types';

type WindowExtended = Window &
  typeof globalThis & {
    env: {
      REACT_APP_BASE_URL: string;
      REACT_APP_IDENTITY_URL: string;
    };
  };

/** @deprecated use the same function from @xemplo/url-helper */
export const getUserArea = () => {
  const userArea = localStorage.getItem('userArea');
  return userArea || '';
};

const tryFromEnv = (key: keyof WindowExtended['env']) => {
  try {
    return (window as WindowExtended).env[key];
  } catch (error) {
    console.log(`Failed to get ${key} from window.env`);
    return undefined;
  }
};

/** @deprecated use the same function from @xemplo/url-helper */
export const BASE_URL = () => {
  const local = 'https://globalpayroll-api-dev-fe.xemplo.team';
  return tryFromEnv('REACT_APP_BASE_URL') ?? local;
};

/** @deprecated use the same function from @xemplo/url-helper */
export const EIMS_URL = () => {
  const local = 'https://id-dev.xemplo.team';
  return tryFromEnv('REACT_APP_IDENTITY_URL') ?? local;
};

/** @deprecated use the same function from @xemplo/url-helper */
export const getPayrollApiUrl = (endpoint: ApiEndpoint) => {
  const userArea = getUserArea();
  return `${BASE_URL()}/${endpoint}`.replace('<%userArea%>', userArea);
};

/** @deprecated use the same function from @xemplo/url-helper */
export const getEimsApiUrl = (endpoint: ApiEndpoint) => {
  const userArea = getUserArea();
  return `${EIMS_URL()}/${endpoint}`.replace('<%userArea%>', userArea);
};

/** @deprecated use the same function from @xemplo/url-helper */
export enum ApiEndpoint {
  Callback = 'api/callback',
  PersonaSwitch = 'api/personaswitch',
  ActivateUser = '<%userArea%>/users/terms',
  UserRoles = 'api/userrole',
  UpdateUserRoles = 'api/userrole/settings',
  ListUsers = '<%userArea%>/users',
  ListUserRoles = '<%userArea%>/lookups/roles',
  UpdatePassword = 'api/v1/users/password-change',
  RoleAuthorisation = 'api/authorization',
}

/** @deprecated use the same function from @xemplo/url-helper */
export enum EimsEndpoint {
  UpdatePassword = 'api/v1/users/password-change',
}

/** @deprecated use the same function from @xemplo/url-helper */
export const apiUrlBuilder = (
  apiUrl: string,
  baseUrl?: string,
  params?: PagedRequest
) => {
  const localUrl = baseUrl ?? 'https://globalpayroll-api-dev-fe.xemplo.team';
  const formattedApiUrl = apiUrl.includes('<%userArea%>')
    ? apiUrl.replace('<%userArea%>', getUserArea())
    : apiUrl;
  const url = `${localUrl}/${formattedApiUrl}`;
  if (!params) return url;
  const paramSting = buildQueryParams(params);
  return `${url}?${paramSting}`;
};

/** @deprecated use the same function from @xemplo/url-helper */
export const buildQueryParams = <T extends object>(params: T) => {
  const esc = encodeURIComponent;
  return (Object.keys(params) as Array<keyof T>)
    .map((k) => {
      const queryString =
        typeof params[k] === 'object'
          ? buildFilterParams(params[k] as object)
          : esc(params[k] as EncodeURIComponent);
      return esc(k as EncodeURIComponent) + '=' + queryString;
    })
    .join('&');
};

/** @deprecated use the same function from @xemplo/url-helper */
export const buildFilterParams = <T extends object>(params: T) => {
  const esc = encodeURIComponent;
  return (Object.keys(params) as Array<keyof T>)
    .map((k) => {
      const queryString =
        typeof params[k] === 'object'
          ? JSON.stringify(params[k])
          : esc(params[k] as EncodeURIComponent);
      return esc(k as EncodeURIComponent) + ':' + queryString;
    })
    .join(',');
};

/** @deprecated use the same function from @xemplo/url-helper */
export const builSortParams = (sortParams: SortingState) => {
  if (sortParams.length === 0) return {};
  return {
    sort: {
      [sortParams[0].id]: sortParams[0].desc ? SortDirection.DESC : SortDirection.ASC,
    },
  };
};
