import { DatePicker } from "antd";
import { DateAndTimeDisplay, If } from "../../../../Common/ui";

type MilestoneFieldProps = {
  isDisabled: boolean;
  format?: string;
  defaultValue: any;
  value?: any;
  showTime?: boolean;
  disabledDate?: (date: any) => boolean;
  showNow?: boolean;
  onChange?: (value: any) => void;
  title: string;
};
const MilestoneField = ({
  isDisabled,
  format = "DD/MM/YYYY HH:mm",
  defaultValue,
  showTime = true,
  disabledDate,
  title,
  showNow = false,
  value,
  onChange,
}: MilestoneFieldProps) => {
  return (
    <div className="grid grid-cols-3 gap-2 mb-2">
      <div>{title}</div>
      <div>
        <DateAndTimeDisplay value={defaultValue} />
      </div>
      <If
        condition={!isDisabled}
        then={
          <DatePicker
            value={value}
            format={format}
            showTime={showTime}
            disabledDate={disabledDate}
            className="w-[170px]"
            showNow={showNow}
            onChange={onChange}
          />
        }
        else={<DateAndTimeDisplay value={defaultValue} className="mb-2" />}
      />
    </div>
  );
};

export default MilestoneField;
