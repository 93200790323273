import SliceListProvider from "../../../../Common/SliceListProvider";
import List from "./List"

type Props = {
  visible?: boolean;
  payrun?: any;
};
const PayrunChangeLog = ({ visible, payrun }: Props) => (
  <SliceListProvider>
    <List visible={visible} payrun={payrun} />
  </SliceListProvider>
);
export default PayrunChangeLog;