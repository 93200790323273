import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";

const UploadReportsButton = ({
  handleUploadClick,
  claims,
  componentName,
}: {
  handleUploadClick: () => void;
  claims: any;
  componentName: string;
}) => {
  return (
    <SimpleButton
      claims={claims}
      hasNoComponents={false}
      name={componentName}
      icon={<UploadOutlined />}
      buttonText="Upload Reports"
      onClick={handleUploadClick}
    />
  );
};

export default UploadReportsButton;
