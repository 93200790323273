import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MAX_NUMBER_VALUE } from '@xemplo/gp-constants';
import { ChangeTaskTypes } from '@xemplo/gp-types';
import { TextFieldV2 } from '@xemplo/text-field';

import * as S from '../../modal.styles';

import { AmountMethodOptions } from './bulk-upload/bulk-upload.types';

export const Units = () => {
  const { register, watch, unregister } = useFormContext();

  const watchChangeTask = watch('changeTask');
  const watchAmountMethod = watch('amountMethod');
  const watchUnits = watch('units');
  const isPayInstruction = watchChangeTask === ChangeTaskTypes.PAY_INSTRUCTION;
  const isTermination = watchChangeTask === ChangeTaskTypes.TERMINATION;
  const showUnitsField =
    watchAmountMethod !== AmountMethodOptions.Variable &&
    !isPayInstruction &&
    !isTermination;

  useEffect(() => {
    if (showUnitsField) {
      register('units', {
        required: 'Units field is required',
      });
    } else {
      unregister('units');
    }
  }, [register, showUnitsField, unregister]);

  if (!showUnitsField) {
    return null;
  }
  return (
    <S.FormLabel>
      Unit/s
      <TextFieldV2
        defaultValue={watchUnits ?? 1}
        placeholder="Enter units"
        type="number"
        name="units"
        aria-label="Units field"
        testId="units"
        onWheel={(e) => e.currentTarget.blur()}
        max={MAX_NUMBER_VALUE}
        min={1}
      />
    </S.FormLabel>
  );
};
