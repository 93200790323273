//@ts-nocheck
import React, { Component } from "react";
import axios from "axios";
import PayrunList from "../../../components/payrun/list";
import PayrunStatus from "../../../../Common/payrunStatus";
import {
  removeUndefined,
  errorDisplay,
  mustBeArray,
  delay,
  updateAuthorizationClaims,
  findAuthorizationClaims,
  getPersonalizationData,
  setPersonalizationData,
  filteredByLabel,
  requestUrlBuilder,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  checkClaimsAuthorization,
  getConditionalResponse,
  moduleHasMoreData,
} from "../../../../libs/utilities";
import _ from "lodash";
import NoRecord from "../../../../Common/NoRecord";
import ContentEmpty from "../../../components/common/contentListEmpty";
import GridTableViewOption from "../../../components/common/gridTableViewOption";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import FilterContainer from "../../../../Common/ClaimContainers/FilterContainer";
import SearchIconButton from "../../../../Common/ClaimComponents/IconButton/SearchIconButton";
import SingleFieldDropdownFilter from "../../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import LinkButton from "../../../../Common/ClaimComponents/Button/LinkButton";
import { PAYRUN_STAGES_OPTIONS } from "../../../../constants/payrun";
import {
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  INTEGER_PERSONALIZATION,
  GRID_VIEW_VALUE,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
} from "../../../../constants/defaultClaims";
import PayRunDisplayCard from "../../../components/payrun/PayRunDisplayCard";
import ScrollingPayrunList from "../../../components/payrun/scrollingPayrunList";

import APIHandler from "../../../../constants/apiUrl";
import {
  PAYRUN_FIELD_OPTIONS,
  PAYRUN_GRID_OPTIONS,
} from "../../../../constants/sortingOptions";
import AmendmentSummaryList from "../../../components/payrun/Amendments/AmendmentSummaryList";
import { updateDomChanges, validateUsersAccessRights } from "../../../../libs";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  PAYRUN_CREATE_SIMPLE_BUTTON,
  PAYRUN_LIST_CONTAINER,
} from "../../../../constants";
import { If } from "../../../../Common/ui";
import { withRouter } from "../../../../hooks";

type State = any;

class AdminPayrunIndex extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      visible: false,
      filters: {},
      companyList: [],
      businessUnitList: [],
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: PAYRUN_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: PAYRUN_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
      activePayrun: {},
      viewAmendmentSummary: false,
      amendmentSummaryList: [],
    };
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, pageClaims } = this.props;
    if (dashboardView) {
      const payrunPersonalization = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      if (!payrunPersonalization || !payrunPersonalization.gridView) {
        this.setState({ gridView: false });
        if (
          payrunPersonalization &&
          payrunPersonalization.paginationType === PAGINATION_SCROLLING
        ) {
          this.listScrollingPayruns({ pageLoad: true, notUpdate: true });
        } else {
          this.listPayruns({ options: {}, notUpdate: true });
        }
      } else {
        this.setState({ gridView: true });
        if (
          payrunPersonalization &&
          payrunPersonalization.paginationType === PAGINATION_SCROLLING
        ) {
          this.listScrollingGridPayruns({ pageLoad: true, notUpdate: true });
        } else {
          this.fetchMoreData({ isPaginated: true, notUpdate: true });
        }
      }
    } else {
      //TODO: Replace with requireSecureComponent higher order component
      if (!validateUsersAccessRights({ claim: pageClaims })) return;
      this.props.updateHeader({
        header: {
          title: "Pay Runs",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayRunList,
          module: "",
          enableBack: false,
          entity: "payrun",
          action: checkClaimsAuthorization({
            component: PAYRUN_CREATE_SIMPLE_BUTTON,
            claims: this.props.pageClaims?.components,
          }),
        },
      });
      this.fetchCompany("");
      const payrunGeneralTable = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "payrunGeneralTable",
      });
      const payrunGeneralGrid = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "payrunGeneralGrid",
      });
      const payrunCompanyDropdownFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "payrunCompanyDropdownFilter",
      });
      this.fetchBusinessUnits(
        payrunCompanyDropdownFilter &&
          payrunCompanyDropdownFilter.personalization &&
          payrunCompanyDropdownFilter.personalization.value
      );
      const payrunBusinessUnitDropdownFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "payrunBusinessUnitDropdownFilter",
      });
      const payrunStatusDropdownFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "payrunStatusDropdownFilter",
      });
      const payrunListContainer = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: PAYRUN_LIST_CONTAINER,
      });
      const payrunPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: payrunListContainer?.personalizations,
      });
      const companyPersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData:
          payrunCompanyDropdownFilter &&
          payrunCompanyDropdownFilter.personalization,
      });
      const businessUnitPersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData:
          payrunBusinessUnitDropdownFilter &&
          payrunBusinessUnitDropdownFilter.personalization,
      });
      const isActivePersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData:
          payrunStatusDropdownFilter &&
          payrunStatusDropdownFilter.personalization,
      });
      let payrunStatuID = "";
      if (
        isActivePersonalizations &&
        Number(isActivePersonalizations.value) !== 0
      ) {
        payrunStatuID = payrunStatusDropdownFilter.personalization.value;
      }
      this.setState({
        filters: {
          businessunitID:
            businessUnitPersonalizations && businessUnitPersonalizations.value,
          companyID: companyPersonalizations && companyPersonalizations.value,
          StatusID: payrunStatuID,
        },
        paginationType:
          payrunPersonalizations && payrunPersonalizations.paginationType,
        sortingType:
          payrunPersonalizations && payrunPersonalizations.sortingType,
        isPinned: payrunPersonalizations && payrunPersonalizations.isPinned,
        gridable: payrunListContainer?.gridable,
      });
      if (
        payrunGeneralGrid &&
        [1, 2].includes(Number(payrunGeneralGrid.authorizationStatusId))
      ) {
        const payrunGridPersonalizations = getPersonalizationData({
          type: "grid",
          personalizationData: payrunGeneralGrid.personalizations,
        });
        const pageNumber =
          payrunGridPersonalizations && payrunGridPersonalizations.pageNumber
            ? Number(payrunGridPersonalizations.pageNumber)
            : 1;
        const row =
          payrunGridPersonalizations && payrunGridPersonalizations.row
            ? Number(payrunGridPersonalizations.row)
            : 5;
        const col =
          payrunGridPersonalizations && payrunGridPersonalizations.col
            ? Number(payrunGridPersonalizations.col)
            : 4;
        const SortOrder =
          payrunGridPersonalizations && payrunGridPersonalizations.sortOrder
            ? payrunGridPersonalizations.sortOrder
            : null;
        const SortColumn =
          payrunGridPersonalizations && payrunGridPersonalizations.sortColumn
            ? payrunGridPersonalizations.sortColumn
            : null;

        this.setState(
          {
            grid: {
              page: pageNumber ? Number(pageNumber) : 1,
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },
            selectedGridSort:
              _.find(
                this.state.gridSortOption,
                (o) => o.label === SortColumn
              ) &&
              _.find(this.state.gridSortOption, (o) => o.label === SortColumn)
                .id,
          },
          () => {
            if (payrunPersonalizations && payrunPersonalizations.gridView) {
              this.setState({ gridView: true });
              if (
                payrunPersonalizations &&
                payrunPersonalizations.paginationType === PAGINATION_SCROLLING
              ) {
                this.listScrollingGridPayruns({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.fetchMoreData({ isPaginated: true, notUpdate: true });
              }
            }
          }
        );
      }

      if (
        payrunGeneralTable &&
        [1, 2].includes(Number(payrunGeneralTable.authorizationStatusId))
      ) {
        const payrunTablePersonalizations = getPersonalizationData({
          type: "table",
          personalizationData: payrunGeneralTable.personalizations,
        });
        const pageNumber =
          payrunTablePersonalizations && payrunTablePersonalizations.pageNumber
            ? Number(payrunTablePersonalizations.pageNumber)
            : 1;
        const pageSize =
          payrunTablePersonalizations && payrunTablePersonalizations.pageSize
            ? Number(payrunTablePersonalizations.pageSize)
            : 20;
        const SortOrder =
          payrunTablePersonalizations && payrunTablePersonalizations.sortOrder
            ? payrunTablePersonalizations.sortOrder
            : null;
        const SortColumn =
          payrunTablePersonalizations && payrunTablePersonalizations.sortColumn
            ? payrunTablePersonalizations.sortColumn
            : null;
        this.setState(
          {
            per_page:
              payrunPersonalizations &&
              payrunPersonalizations.paginationType === PAGINATION_SCROLLING
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : pageSize
                ? Number(pageSize)
                : 20,
            current: pageNumber ? Number(pageNumber) : 1,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (!payrunPersonalizations.gridView) {
              if (
                payrunPersonalizations &&
                payrunPersonalizations.paginationType === PAGINATION_SCROLLING
              ) {
                this.listScrollingPayruns({ pageLoad: true, notUpdate: true });
              } else {
                this.listPayruns({ options: {}, notUpdate: true });
              }
            }
          }
        );
      }
    }
  }

  handleChangeCompany = (value) => {
    this.fetchBusinessUnits(value);
  };

  fetchBusinessUnits = (value) => {
    this.props
      .listCompanyBusinessUnits({
        id: value,
        options: { per_page: 500 },
        cancelToken: this.signal.token,
      })

      .then((response) => {
        if (response.status) {
          this.setState({ businessUnitList: mustBeArray(response.data) });
        }
      });
  };

  updateAllPersonalization = () => {
    this.updatePersonalization("payrunCompanyDropdownFilter", false);
    delay(300).then(() => {
      this.updatePersonalization("payrunBusinessUnitDropdownFilter", false);
    });
    delay(600).then(() => {
      this.updatePersonalization("payrunStatusDropdownFilter", false);
    });
    delay(900).then(() => {
      this.updatePersonalization("payrunGeneralTable", false);
    });
    delay(1200).then(() => {
      this.updatePersonalization("payrunGeneralGrid");
    });
  };

  handleChange = (field, value) => {
    this.setState((prevState) => {
      prevState.filters[field] = value;
      if (field === "companyID") {
        prevState.filters["businessunitID"] = undefined;

        this.handleChangeCompany(value);
      }
      return prevState;
    });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {},
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.fetchBusinessUnits("");

        this.props
          .listPayrunTotal({
            options: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                    paginationType: this.state.paginationType,
                    grid: this.state.grid,
                  })
                : this.state.per_page,
            },
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.current = 1;
        prevState.listLoading = true;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };

        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }

        this.updateAllPersonalization();

        this.props
          .listPayrunTotal({
            options,
          })
          .then((resp) => {
            this.setState({ filterLoading: false, listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'listPayruns'.
  listPayruns = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,

          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .listPayrun({
            options: tempOptions,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                _.isEmpty(mustBeArray(resp.data)) &&
                Number(this.state.current) !== 1
              ) {
                this.setState({ current: 1 }, () => {
                  this.listPayruns({ options });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("payrunGeneralTable");
          }
        });
      }
    );
  };
  // @ts-expect-error ts-migrate(2552) FIXME: Cannot find name 'listScrollingPayruns'. Did you m... Remove this comment to see the full error message
  listScrollingPayruns = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            this.state.total === mustBeArray(this.state.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .listPayrun({
            options: tempOptions,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                _.isEmpty(mustBeArray(resp.data)) &&
                Number(this.state.current) !== 1
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingPayruns({ pageLoad: true, options });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],
                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("payrunGeneralTable");
          }
        });
      }
    );
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'listScrollingGridPayruns'.
  listScrollingGridPayruns = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      {
        listLoading: true,
        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid && this.state.grid.page) + 1
            : Number(this.state.grid && this.state.grid.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;

        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });

        let filterOptions = this.getFilters();

        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });

        this.props
          .listPayrun({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
            },

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              this.checkIfDeafultListEmpty({ list: response.data });

              if (_.isEmpty(mustBeArray(response.data)) && Number(page) !== 1) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridPayruns({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization("payrunGeneralGrid");
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'listPayrunGrid'.
  listPayrunGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid && this.state.grid.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .listPayrun({
            options: tempOptions,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrunGeneralGrid");
        });
      }
    );
  };

  //
  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };
  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };
  //
  updatePersonalization = (field, update = true) => {
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member?.details?.authorizationDOM
      );

      const payrunListContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === PAYRUN_LIST_CONTAINER
      );

      const payrunFilterContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payrunFilterContainer"
      );

      const payrunGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payrunGeneralTable"
      );

      const payrunGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payrunGeneralGrid"
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];
      switch (field) {
        case "payrunGeneralTable":
          if (payrunListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,

              sortOrder: this.state.sortInfo && this.state.sortInfo.order,

              sortColumn: this.state.sortInfo && this.state.sortInfo.field,

              pageSize: `${this.state.per_page}`,
              personalizationData:
                payrunGeneralTable && payrunGeneralTable.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunGeneralGrid":
          if (payrunListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid && grid.page}`,
              row: `${grid && grid.row}`,
              col: `${grid && grid.col}`,
              sortOrder: grid && grid.sortInfo && grid.sortInfo.order,
              sortColumn: grid && grid.sortInfo && grid.sortInfo.field,
              personalizationData:
                payrunGeneralGrid && payrunGeneralGrid.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunCompanyDropdownFilter":
          if (payrunFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters && this.state.filters.companyID
                ? this.state.filters.companyID
                : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunBusinessUnitDropdownFilter":
          if (payrunFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters && this.state.filters.businessunitID
                ? this.state.filters.businessunitID
                : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrunStatusDropdownFilter":
          if (payrunFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters && this.state.filters.StatusID
                ? this.state.filters.StatusID
                : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case PAYRUN_LIST_CONTAINER:
          if (payrunListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData: payrunListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent && updatedComponent.id,
          name: updatedComponent && updatedComponent.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }
      const updateChanges = updateDomChanges({
        update,
        condition: field === "payrunGeneralGrid",
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    if (Number(tempFilters.companyID) === 0) {
      delete tempFilters.companyID;
    }
    if (Number(tempFilters.businessunitID) === 0) {
      delete tempFilters.businessunitID;
    }
    if (Number(tempFilters.StatusID) === 0) {
      delete tempFilters.StatusID;
    }
    if (tempFilters.StatusID) {
      tempFilters.StatusID =
        tempFilters.StatusID === "invoicePaid"
          ? PayrunStatus.getPaidStatusID()
          : PayrunStatus.getStatusID({
              status: tempFilters.StatusID,

              userMode: this.props.userMode,
            });
    }
    return tempFilters;
  };

  fetchMoreData = ({ isPaginated = false, notUpdate }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props
      .listPayrunTotal({
        options: { page, per_page, q: { ...options }, ...sortingParams },
      })

      .then((response) => {
        if (response.status) {
          if (_.isEmpty(mustBeArray(response.data)) && Number(page) !== 1) {
            this.setState({ grid: { ...grid, page: 1 } }, () => {
              this.fetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.list = data;
                } else {
                  prevState.list = prevState.list.concat(data);

                  prevState.hasMoreData = moduleHasMoreData({
                    data,
                    page_size: this.state.page_size,
                  });

                  prevState.grid = getConditionalResponse({
                    condition: moduleHasMoreData({
                      data,
                      page_size: this.state.page_size,
                    }),
                    resp1: { ...prevState.grid, page: prevState.grid.page + 1 },
                    resp2: prevState.grid,
                  });
                }

                prevState.total = response.total;
                prevState.listLoading = false;
                return prevState;
              },
              () => {
                this.updateWithDelay({
                  timeDelay: 300,
                  component: "payrunGeneralGrid",
                  notUpdate,
                });
              }
            );
          }
        }
      });
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'updateWithDelay'.
  updateWithDelay = ({ timeDelay, component, notUpdate }) => {
    if (!notUpdate) {
      delay(timeDelay).then(() => {
        this.updateAllPersonalization(component);
      });
    }
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization(PAYRUN_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingPayruns({ pageLoad: true, options: {} });
        } else {
          this.listPayruns({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPayruns({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPayruns({ pageLoad: true, options: {} });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingPayruns({ pageLoad: true, options: {} });
        } else {
          this.listPayruns({ options: {} });
        }
      });
    }
  };
  updateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.selectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.gridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.grid = {
            ...prevState.grid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPayruns({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/payrun/${data && data.payrunId}`);
        break;
      case "edit":
        this.props.router.navigate(`/payrun/${data && data.payrunId}`);
        break;
      case "delete":
        break;
      default:
        break;
    }
  };

  handleEdit = (data) => {
    this.props.router.navigate(`/payrun/${data && data.payrunId}`);
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  updatePinnedComponent = () => {
    this.setState(
      { isPinned: this.state.isPinned === "true" ? "false" : "true" },
      () => {
        this.updatePersonalization(PAYRUN_LIST_CONTAINER);
      }
    );
  };

  // Filters
  fetchCompany = (value) => {
    this.setState({ fetching: true });

    this.props
      .companyLookup({
        options: value
          ? { q: { CompanyName: value }, per_page: 500 }
          : { per_page: 500 },
      })

      .then((response) => {
        if (response.status) {
          this.setState({ companyList: mustBeArray(response.data) });
        }
      });
  };
  checkIfDeafultListEmpty = ({ list }) => {
    const { current, grid } = this.state;

    if (
      _.isEmpty(mustBeArray(list)) &&
      _.isEmpty(this.getFilters()) &&
      checkClaimsAuthorization({
        component: PAYRUN_CREATE_SIMPLE_BUTTON,

        claims: this.props.pageClaims?.components,
      }) &&
      Number(current) === 1 &&
      Number(grid && grid.page) === 1
    ) {
      return this.setState({ isDefaultListEmpty: true });
    }

    return this.setState({ isDefaultListEmpty: false });
  };
  getActiveFilters = () => {
    let activeFilters = [];

    let filtersApplied = this.getFilters();
    if (filtersApplied?.businessunitID) {
      let selectedBusinessUnit = _.find(
        this.state.businessUnitList,
        (o) => o.id === filtersApplied.businessunitID
      );
      activeFilters.push(selectedBusinessUnit?.name);
    }
    if (filtersApplied?.companyID) {
      let selectedCompany = _.find(
        this.state.companyList,
        (o) => o.id === filtersApplied.companyID
      );
      activeFilters.push(selectedCompany && selectedCompany.value);
    }

    if (this.state.filters && this.state.filters.StatusID) {
      activeFilters.push(`${this.state.filters.StatusID} status`);
    }
    return activeFilters;
  };

  handleViewAmendmentSummary = (data) => {
    this.setState({ activePayrun: data });

    this.props
      .listAmendments({
        id: data && data.payrunId,

        options: { per_page: 500, page: 1 },
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            amendmentSummaryList: mustBeArray(resp.data),
            viewAmendmentSummary: true,
          });
        }
      });
  };

  handleAmendmentSummaryClose = () => {
    this.setState({
      amendmentSummaryList: [],
      viewAmendmentSummary: false,
      activePayrun: {},
    });
  };

  render() {
    const { pageClaims, dashboardView } = this.props;
    const {
      sortInfo,
      filterLoading,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      hasMoreData,
      isPinned,
      filters,
      paginationType,
      gridable,
      fieldOptions,
      sortingType,
      current,

      companyList,
      businessUnitList,
      isDefaultListEmpty,
    } = this.state;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });

    const activeFilters = this.getActiveFilters();
    const payrunBaseUrl = APIHandler.constructEndpoint({
      endpoint: `PAYRUN_URL`,
    });
    const payrunTargetEndpoint = requestUrlBuilder(payrunBaseUrl, {
      q: {
        ...this.getFilters(),
      },
    });
    return (
      <React.Fragment>
        <AmendmentSummaryList
          visible={this.state.viewAmendmentSummary}
          list={this.state.amendmentSummaryList}
          payrun={this.state.activePayrun}
          handleAmendmentSummaryClose={this.handleAmendmentSummaryClose}
        />
        <If
          condition={dashboardView}
          then={
            <ListContainer
              name={PAYRUN_LIST_CONTAINER}
              claims={pageClaims}
              isPinned={isPinned}
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={gridable}
              gridView={gridView}
              grid={grid}
              per_page={per_page}
              GeneralTablePaginatedComponent={PayrunList}
              GeneralTableScrollableComponent={ScrollingPayrunList}
              generalTableComponentName={"payrunGeneralTable"}
              gridComponentName={"payrunGeneralGrid"}
              createLinkButtonName={""}
              handleAddAction={() => {
                console.log("");
              }}
              handleGridChange={this.handleGridChange}
              fieldOptions={fieldOptions}
              updateGridField={this.updateGridField}
              sortingType={sortingType}
              paginationType={paginationType}
              list={list}
              listLoading={listLoading}
              listGrid={this.listPayrunGrid}
              listScrollingGrid={this.listScrollingGridPayruns}
              hasMoreData={hasMoreData}
              gridActionComponentName={"payrunViewDetailLink"}
              moduleType={"payrun"}
              handleGridAction={this.handleAction}
              listScrollingGeneralTable={this.listScrollingPayruns}
              total={total}
              current={current}
              sortInfo={sortInfo}
              listGeneralTable={this.listPayruns}
              tableActionComponentName={"payrunViewDetailLink"}
              viewComponent="payrunViewDetailLink"
              gridPageSize={getCurrentGridPageSize({
                paginationType: this.state.paginationType,
                grid: this.state.grid,
              })}
              cardGrid={cardGrid}
              showAddAndGrid={false}
              handleEdit={this.handleEdit}
              DisplayCardComponent={PayRunDisplayCard}
              dashboardView={this.props.dashboardView}
              componentTitle={this.props.componentTitle}
              filterApplied={this.props.filterApplied}
              targetEndpoint={this.props.targetEndpoint}
              handleViewAmendmentSummary={this.handleViewAmendmentSummary}
              rowIdParam="payrunId"
            />
          }
          else={
            <If
              condition={isDefaultListEmpty}
              then={
                <NoRecord>
                  <ContentEmpty link="/payrun/create" page="Pay Run" />
                </NoRecord>
              }
              else={
                <React.Fragment>
                  <div className="flex justify-between items-center flex-wrap">
                    <FilterContainer
                      name="payrunFilterContainer"
                      claims={pageClaims}
                    >
                      <SingleFieldDropdownFilter
                        options={companyList}
                        name="payrunCompanyDropdownFilter"
                        value={filters && filters.companyID}
                        defaultValue={undefined}
                        onSelect={(value) =>
                          this.handleChange("companyID", value)
                        }
                        className="mb-6 w-150"
                        claims={this.props.claims}
                        placeholder="Company"
                      />
                      <SingleFieldDropdownFilter
                        options={businessUnitList}
                        name="payrunBusinessUnitDropdownFilter"
                        value={filters && filters.businessunitID}
                        defaultValue={undefined}
                        onSelect={(value) =>
                          this.handleChange("businessunitID", value)
                        }
                        className="mb-6 ml-4 w-150"
                        claims={this.props.claims}
                        placeholder="Business Unit"
                        nameParam="name"
                        searchBy="name"
                      />
                      <SingleFieldDropdownFilter
                        options={PAYRUN_STAGES_OPTIONS}
                        name="payrunStatusDropdownFilter"
                        value={filters && filters.StatusID}
                        defaultValue={undefined}
                        onSelect={(value) =>
                          this.handleChange("StatusID", value)
                        }
                        className="mb-6 ml-4 w-150"
                        claims={this.props.claims}
                        placeholder="Status"
                        valueParam="value"
                        nameParam="name"
                      />
                      <SearchIconButton
                        loading={filterLoading}
                        onClick={this.handleFilter}
                        name="payrunSearchButton"
                        claims={this.props.claims}
                        className="ml-4"
                      />
                      <LinkButton
                        buttonText="Reset Filter"
                        className="color-primary hand"
                        onClick={() => this.resetFilters()}
                        name="payrunResetFilter"
                        claims={this.props.claims}
                      />
                    </FilterContainer>
                    <div className="mb-6">
                      {
                        <GridTableViewOption
                          gridable={gridable}
                          handleGridChange={this.handleGridChange}
                          gridView={gridView}
                          fieldOptions={fieldOptions}
                          updateGridField={this.updateGridField}
                          gridSortInfo={
                            this.state.grid && this.state.grid.sortInfo
                          }
                          tableSortInfo={this.state.sortInfo}
                          updatePageSize={this.updatePageSize}
                          grid={grid}
                          per_page={per_page}
                          paginationType={paginationType}
                          sortingType={sortingType}
                        />
                      }
                    </div>
                  </div>
                  <div className="mb-4">
                    <ListContainer
                      name={PAYRUN_LIST_CONTAINER}
                      claims={pageClaims}
                      isPinned={isPinned}
                      updatePinnedComponent={this.updatePinnedComponent}
                      gridable={gridable}
                      gridView={gridView}
                      grid={grid}
                      per_page={per_page}
                      GeneralTablePaginatedComponent={PayrunList}
                      GeneralTableScrollableComponent={ScrollingPayrunList}
                      generalTableComponentName={"payrunGeneralTable"}
                      gridComponentName={"payrunGeneralGrid"}
                      createLinkButtonName={""}
                      handleAddAction={() => {
                        console.log("");
                      }}
                      handleGridChange={this.handleGridChange}
                      fieldOptions={fieldOptions}
                      updateGridField={this.updateGridField}
                      sortingType={sortingType}
                      paginationType={paginationType}
                      list={list}
                      listLoading={listLoading}
                      listGrid={this.listPayrunGrid}
                      listScrollingGrid={this.listScrollingGridPayruns}
                      hasMoreData={hasMoreData}
                      gridActionComponentName={"payrunViewDetailLink"}
                      moduleType={"payrun"}
                      handleGridAction={this.handleAction}
                      listScrollingGeneralTable={this.listScrollingPayruns}
                      total={total}
                      current={current}
                      sortInfo={sortInfo}
                      listGeneralTable={this.listPayruns}
                      tableActionComponentName={"payrunViewDetailLink"}
                      viewComponent="payrunViewDetailLink"
                      // editComponent="payrunViewDetailLink"
                      gridPageSize={getCurrentGridPageSize({
                        paginationType: this.state.paginationType,
                        grid: this.state.grid,
                      })}
                      cardGrid={cardGrid}
                      showAddAndGrid={false}
                      handleEdit={this.handleEdit}
                      DisplayCardComponent={PayRunDisplayCard}
                      componentTitle="Pay Runs"
                      hasNoDuplicateEntity={true}
                      filterApplied={filteredByLabel({
                        filters: activeFilters,
                      })}
                      targetEndpoint={payrunTargetEndpoint}
                      handleViewAmendmentSummary={
                        this.handleViewAmendmentSummary
                      }
                      rowIdParam="payrunId"
                    />
                  </div>
                </React.Fragment>
              }
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(AdminPayrunIndex);
