export const getPayScheduleRoute = () => {
  return "/payschedules";
};

export const getPayScheduleDetailRoute = ({
  id,
  companyId,
  businessUnitId,
}: {
  id: string;
  companyId: string;
  businessUnitId: string;
}) => {
  return `/payschedule/${id}?companyid=${companyId}&businessunitid=${businessUnitId}`;
};

export const getPayScheduleEditRoute = ({
  id,
  companyId,
  businessUnitId,
}: {
  id: string;
  companyId: string;
  businessUnitId: string;
}) => {
  return `/payschedule/${id}/edit?companyid=${companyId}&businessunitid=${businessUnitId}`;
};

export const getPayScheduleCreateRoute = ({
  companyId,
  businessUnitId,
}: {
  companyId?: string;
  businessUnitId?: string;
}) => {
  if (companyId && businessUnitId)
    return `/payschedule/create?companyid=${companyId}&businessunitid=${businessUnitId}`;
  if (companyId) return `/payschedule/create?companyid=${companyId}`;
  return "/payschedule/create";
};
