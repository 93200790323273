import React from "react";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import { PAYRUN_AMENDMENT_LIST_CONTAINER } from "../../../../constants";
import AmendmentDetailList from "../../../components/payrun/amendmentDetailList";
import AmendmentDisplayCard from "../../../components/payrun/amendmentDisplayCard";
import AmendmentSingleGridDisplay from "../../../components/payrun/amendmentSingleGridDisplay";

const AmendmentListContainer = (props: any) => {
  return (
    <ListContainer
      {...props}
      GeneralTablePaginatedComponent={AmendmentDetailList}
      GeneralTableScrollableComponent={AmendmentDetailList}
      DisplayCardComponent={AmendmentDisplayCard}
      SingleLineGridDisplayComponent={AmendmentSingleGridDisplay}
      name={PAYRUN_AMENDMENT_LIST_CONTAINER}
      generalTableComponentName="payrunAmendmentGeneralTable"
      gridComponentName="payrunAmendmentGeneralGrid"
      createLinkButtonName="payrunAmendmentAddButton"
      gridActionComponentName="payrunAmendmentViewDetailLink"
      tableActionComponentName="payrunAmendmentViewDetailLink"
      component="payrunAmendmentViewDetailLink"
      pinnedComponentName="payrunAmendmentIsPinned"
      addModuleName="Amendment"
      singleLineGridTitle="Amendments"
      rowIdParam="amendmentId"
      hideSorting
      showAddAndGrid
    />
  );
};

export default AmendmentListContainer;
