import styled from 'styled-components';

import {
  BodySmallRegular,
  BodyXSmallMedium,
  Colour,
  MinBreakpoint,
  TextEllipsis,
} from '@xemplo/style-constants';

/**
 * There are two types of expansion.
 * 1. when viewport is small, the sidebar turns into a hidden state.
 *    this is controlled by the class `responsive-expanded`.
 * 2. when viewport is large, the sidebar can horizontally expand/collapse.
 */
export const Wrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  height: 0;

  &.responsive-expanded {
    visibility: visible;
    opacity: 1;
    height: 100%;
    width: 100%;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  @media ${MinBreakpoint.mediumV2} {
    visibility: visible;
    opacity: 1;
    height: 100%;
    display: block;
  }
`;

/** The wrapper for all items */
export const Items = styled.ul`
  list-style: none;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 100%;

  @media ${MinBreakpoint.mediumV2} {
    max-height: calc(100vh - var(--sidenav-offset));
    overflow: hidden auto;
  }
`;

/** Common styling for individual menu items */
export const Item = styled.li`
  ${BodySmallRegular};
  color: ${Colour.Gray[500]};
  position: relative;

  &[data-heading='true'] {
    ${BodyXSmallMedium};
    display: flex;
    color: ${Colour.Gray[300]};
    text-transform: uppercase;
    padding: 12px 16px;
    align-items: flex-start;

    @media ${MinBreakpoint.mediumV2} {
      padding: 12px 32px;
      &[data-expanded='false']:not(:first-of-type) {
        opacity: 1;
        justify-content: center;
        padding: 12px;
      }
    }
  }

  &:first-of-type {
    padding-top: 20px;

    @media ${MinBreakpoint.mediumV2} {
      padding-top: 48px;
      &[data-expanded='false'][data-heading='true'] {
        padding: 48px 12px 12px;
        justify-content: center;
      }
    }

    &[data-heading='true'] > span {
      padding-top: 0;
    }
  }

  & > a {
    display: flex;
    align-items: center;
    flex-direction: row;

    cursor: pointer;
    text-decoration: none;
    color: ${Colour.Gray[500]};
    padding: 12px 16px;
    gap: 12px;
    transition: 0.3s all ease-out;

    @media ${MinBreakpoint.mediumV2} {
      padding: 12px 32px;
    }

    ${BodySmallRegular};

    &:hover {
      color: ${Colour.Gray[800]};
      background: ${Colour.Gray[50]};

      & svg {
        color: ${Colour.Gray[700]};
      }
    }

    &.active {
      background: ${Colour.Blue[50]};
      color: ${Colour.Blue[600]};
      font-weight: 500;

      & path {
        fill: url(#primaryGradient);
      }

      &::before {
        position: absolute;
        content: '';
        width: 3px;
        height: 32px;
        left: 0px;
        background: ${Colour.Gradient.Linear180};
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
`;

export const Label = styled.span`
  ${TextEllipsis};
  transition: 0.3s all ease-out;

  @media ${MinBreakpoint.mediumV2} {
    &[data-expanded='false']:not([data-header='true']) {
      opacity: 0;
    }
  }
`;

/** Styling specific for the icon wrapper */
export const Icon = styled.span`
  font-size: 24px;
  color: ${Colour.Gray[400]};
  transition: 0.3s margin ease-out;

  @media ${MinBreakpoint.mediumV2} {
    &[data-expanded='false'] {
      margin-right: 0;
    }
  }
`;
