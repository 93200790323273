// @ts-nocheck
import { requestBuilder } from "../../libs/utilities";
import { ResponseHandler } from "../../libs";
import APIHandler from "../../constants/apiUrl";

export function varianceHandler({
  id,
  cancelToken,
  options,
  payload,
  action,
  targetEndpoint,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let queryOptions = {
        page: 1,
        ...options,
      };
      let response = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "get":
          url = APIHandler.constructEndpoint({
            endpoint: "VARIANCE_URL",
            options: {
              id,
            },
          });
          response = await requestBuilder(id, url, queryOptions, cancelToken);
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "VARIANCE_DETAIL";
          break;
        case "list":
          url = APIHandler.constructEndpoint({
            endpoint: "VARIANCE_URL",
          });
          response = await requestBuilder(
            null,
            targetEndpoint ? targetEndpoint : url,
            targetEndpoint ? {} : queryOptions,
            cancelToken
          );
          dispatch_type = "LIST_VARIANCE";
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
