import {
  Amendment,
  AmendmentStatusIds,
  AmendmentTypes,
  ChangeTaskTypes,
} from '@xemplo/gp-types';

import { EditButtonTypes } from './edit-button.types';

// Common status arrays
const commonValidStatuses = [AmendmentStatusIds.CREATED, AmendmentStatusIds.UPDATED];
const validAmendmentStatuses = [
  ...commonValidStatuses,
  AmendmentStatusIds.SUBMITTED,
  AmendmentStatusIds.RESUBMITTED,
  AmendmentStatusIds.DRAFT,
];
//Rejected amendments can only be edited if they are payrun amendments
const validPayrunStatuses = [...validAmendmentStatuses, AmendmentStatusIds.REJECTED];

const EditButtonConfig = {
  [ChangeTaskTypes.TERMINATION]: {
    button: EditButtonTypes.termination,
    isValid: (amendment: Amendment) => {
      return commonValidStatuses.includes(amendment.amendmentStatusID);
    },
  },
  [ChangeTaskTypes.PAY_INSTRUCTION]: {
    button: EditButtonTypes.payInstruction,
    isValid: (amendment: Amendment) =>
      validPayrunStatuses.includes(amendment.amendmentStatusID),
  },
  [ChangeTaskTypes.ALLOWANCE]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) =>
      amendment.amendmentTypeId === AmendmentTypes.PAYRUN
        ? validPayrunStatuses.includes(amendment.amendmentStatusID)
        : validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
  [ChangeTaskTypes.DEDUCTION]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) =>
      amendment.amendmentTypeId === AmendmentTypes.PAYRUN
        ? validPayrunStatuses.includes(amendment.amendmentStatusID)
        : validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
  [ChangeTaskTypes.SALARY]: {
    button: EditButtonTypes.amendment,
    isValid: (amendment: Amendment) =>
      amendment.amendmentTypeId === AmendmentTypes.PAYRUN
        ? validPayrunStatuses.includes(amendment.amendmentStatusID)
        : validAmendmentStatuses.includes(amendment.amendmentStatusID),
  },
};

export const getEditButtonType = (amendment: Amendment) => {
  const config = EditButtonConfig[amendment.amendmentTaskTypeID];
  if (config?.isValid(amendment)) {
    return config.button;
  }

  return null;
};
