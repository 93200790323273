import React from "react";

const MultipleUserIcon = (props: any) => {
  return (
    <svg
      width={30}
      height={28}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        stroke="#CCC"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.863 21v-2c0-1.06-.473-2.078-1.313-2.828S16.569 15 15.38 15H6.414c-1.189 0-2.329.421-3.17 1.172-.84.75-1.313 1.767-1.313 2.828v2M10.897 11c2.476 0 4.483-1.79 4.483-4s-2.007-4-4.483-4C8.421 3 6.414 4.79 6.414 7s2.007 4 4.483 4ZM26.587 21v-2c0-.886-.331-1.747-.94-2.448-.608-.7-1.46-1.2-2.422-1.422M18.742 3.13c.964.22 1.82.72 2.43 1.422.61.702.941 1.565.941 2.453 0 .888-.331 1.751-.942 2.453-.61.701-1.465 1.202-2.429 1.422" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.81)" d="M0 0h26.897v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MultipleUserIcon;
