import ReactGA from "react-ga4";

import environment from "../../constants/environment";
import usePageViews from "../usePageViews";

export const useAppInit = () => {
  const TRACKING_ID = environment.google_analytics_key; // GOOGLE ANALYTICS TRACKING ID
  ReactGA.initialize(TRACKING_ID); //Initialize google analytics
  usePageViews(); //Track changes in the url update GA
};
