//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import TableActionComponent from "../common/tableActions";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import { getBspAccountListColumns } from "../../../libs/utilities";

class BillinServiceProviderAccountList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    switch (sorter.field) {
      case "accountNumber":
        field = "accountnumber";
        break;
      case "accountName":
        field = "accountname";
        break;
      default:
        break;
    }
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order
            ? sorter.order === "ascend"
              ? "asc"
              : "desc"
            : sorter.order,
          field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options });
  };

  handleAction = ({ record, action }: any) => {
    switch (action) {
      case "edit":
      case "view":
        return this.props.handleEdit({ record, label: "account" });
      case "delete":
        return this.props.handleDelete({ record, label: "account" });
      default:
        break;
    }
  };
  render() {
    const pagination = {
      total: this.props.totalPages,
      current: this.props.current,
      pageSize: this.props.per_page ? this.props.per_page : 10,
    };
    const { sortInfo, editComponent, claims, deleteComponent } = this.props;
    const colTemplate = getBspAccountListColumns({ sortInfo });
    const column = [
      ...tableColumnGenerator(colTemplate),
      {
        title: "Action",
        key: "action",
        render: (record: any) => {
          return (
            record &&
            record.enabled && (
              <React.Fragment>
                <TableActionComponent
                  record={record}
                  handleAction={this.handleAction}
                  editComponentName={editComponent}
                  deleteComponentName={deleteComponent}
                  claims={claims}
                />
              </React.Fragment>
            )
          );
        },
      },
    ];
    return (
      <SimpleTableContainer
        columns={column}
        dataSource={this.props.data}
        pagination={pagination}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        rowIdParam="id"
      />
    );
  }
}

export default BillinServiceProviderAccountList;
