export const NoAccessBtnText = {
  Logout: 'Log out',
  LogoutBtnAria: 'Log out button',
  SwitchPersona: 'Switch Persona',
  SwitchPersonaBtnAria: 'Switch Persona button',
};

export const NoAccessText = {
  Heading: 'No Access',
  Logout: 'Log out',
  SwitchPersona: 'Switch Persona',
  Default:
    'Your sign-in was successful but you don’t have permission to access this module.',
  SelectPersona: 'You can either log out or choose a different persona to continue.',
};
