export enum LoginActions {
  Login = 'login',
  LoginCallback = 'login-callback',
}

export enum LogoutActions {
  Logout = 'logout',
  LogoutCallback = 'logout-callback',
}

/**
 * NOTE: This is a subset of the OIDC configuration options that are supported by the EIMSClient.
 * However, in order to keep the compatibility with the mobile project, we cannot consume the types
 * from the oidc-client-ts library directly. Therefore, we have to define our own types here.
 */
export type EIMSClientConfig = {
  authority: string;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri?: string;
  response_type?: string;
  scope?: string;
  automaticSilentRenew?: boolean;
  includeIdTokenInSilentRenew?: boolean;
};
