//@ts-nocheck
import { Outlet } from "react-router-dom";
import { LogoSize, LogoXemplo } from "@xemplo/icons";

const TemplateNoSidebarAndNavbar = ({
  children,
}: {
  children?: JSX.Element;
}) => {
  return (
    <div className="template-nothing">
      <div id="particle-container">
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/men/1.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/women/1.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/men/2.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/women/2.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/men/3.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/women/3.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/men/4.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/women/4.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/men/5.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/women/5.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/men/6.jpg"
        />
        <img
          className="particle particle-img wh-100"
          alt=""
          src="https://randomuser.me/api/portraits/women/6.jpg"
        />

        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
      <div className="panel">
        <div className="application-logo">
          <span className="hand color-primary">
            <LogoXemplo size={LogoSize.s} />
          </span>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default TemplateNoSidebarAndNavbar;
