import classnames from 'classnames';

import { useTabMenu } from '../use-tab-menu';

import * as S from './sub-menu.styles';

export const TabMenuSubMenuTestId = {
  showMore: 'tab-menu-show-more',
  panelWrapper: 'tab-menu-sub-menu-panel-wrapper',
  panel: 'tab-menu-sub-menu-panel',
};

export const SubMenu = () => {
  const { state } = useTabMenu();
  const { size, hiddenItems, subMenuOpened, isPrimary } = state;

  return (
    <S.SubMenuPanelWrapper
      role="presentation"
      className={classnames(
        {
          primary: !!isPrimary,
          invisible: !subMenuOpened,
        },
        [size.toLowerCase()]
      )}
      data-testid={TabMenuSubMenuTestId.panelWrapper}
    >
      <S.SubMenuPanel data-testid={TabMenuSubMenuTestId.panel} role="presentation">
        {hiddenItems.map((item) => (
          <S.SubMenuItem key={item.id} {...item} role="presentation" isSubMenuItem />
        ))}
      </S.SubMenuPanel>
    </S.SubMenuPanelWrapper>
  );
};
