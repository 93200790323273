// @ts-nocheck
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  baseUrlMatches,
  componentIsPinned,
  delay,
  findAuthorizationClaims,
  getActiveDashboard,
  getCurrentAuthorizationDom,
  getPinnedComponentSize,
  handleRequest,
  isEmpty,
  isPinnedComponentResizeable,
  isUserCompanyAdmin,
  mustBeArray,
  removeDefaultDashboard,
  trimWhiteSpaces,
  updateAuthorizationClaims,
} from "../../../../libs";
import { dashboardActionHandler } from "../../../actions/dashboard";
import { updateAuthorizationClaim } from "../../../../User/actions/member";
import { Menu, Dropdown, Tooltip, message } from "antd";
import {
  CheckOutlined,
  EditOutlined,
  PlusOutlined,
  PushpinOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import DashboardNameForm from "../../dashboard/DashboardNameForm";
import { If } from "../../../../Common/ui";
import { GLOBAL_PAYROLL_APPLICATION_NAME } from "../../../../constants";
import { useAppSelector } from "../../../../hooks";
import { RootState } from "../../../../store";
import { ErrorBoundary } from "@xemplo/error-boundary";
import { DashboardItem } from "@xemplo/gp-types";

type Props = {
  claim: any;
  targetEndpoint?: string;
  pageClaims: any;
  hasNoDuplicateEntity?: boolean;
  componentTitle?: string;
  filterApplied?: string;
};
const GeneralPinComponent = ({
  claim,
  targetEndpoint,
  pageClaims,
  hasNoDuplicateEntity,
  componentTitle,
  filterApplied,
}: Props) => {
  const dispatchAction: any = useDispatch();
  const { member, dashboard } = useAppSelector((state: RootState) => state);
  const dashboardList = dashboard.list ?? [];
  // Active dashboard to ensure changes are applied to components in correct dashboard
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [activeDashboard, setActiveDashboard] = useState<any>({});
  const [showDashboardForm, setShowDashboardForm] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [updatedComponents, setUpdatedComponents] = useState<Array<any>>([]);

  useEffect(() => {
    setActiveDashboard(getActiveDashboardComponents());
  }, [dashboardList]);

  //   Get the list of components from the active dashboard
  const getActiveDashboardComponents = () => {
    const activeDash = getActiveDashboard();
    const selectedDashboardList = dashboardList.find(
      (o: any) => trimWhiteSpaces(o?.name) === trimWhiteSpaces(activeDash)
    );
    return selectedDashboardList;
  };

  //   update postion
  const getUpdatedComponentPosition = ({ value, lastItem }: any) => {
    return isEmpty(value?.pinnedContainers)
      ? { row: 0, col: 0 }
      : {
          row: lastItem?.position?.row + 1,
          col: 1,
        };
  };

  //   Update dashboard
  const updateSelectedDashboard = ({
    value,
    select,
  }: {
    value: any;
    select: boolean;
  }) => {
    if (select) {
      const lastItem = mustBeArray(value?.pinnedContainers)[
        mustBeArray(value?.pinnedContainers).length - 1
      ];
      const position = getUpdatedComponentPosition({ value, lastItem });
      let newDashboardItem = {
        name: claim?.name,
        id: claim?.id,
        size: getPinnedComponentSize({ claim }),
        resizeable: isPinnedComponentResizeable({ claim }),
        draggable: true,
        position,
        personalizations: claim?.personalizations,
        targetEndpoint,
        componentTitle,
        filterApplied,
      };
      const payload = {
        ...value,
        pinnedContainers: [...value.pinnedContainers, newDashboardItem],
      };
      const tempPersonalizations = structuredClone(claim?.personalizations);
      tempPersonalizations[1].pinnedTo = [
        ...mustBeArray(tempPersonalizations[1].pinnedTo),
        value?.id,
      ];
      handleClaimsUpdate({ value: tempPersonalizations });

      dispatchAction(
        dashboardActionHandler({
          action: "update",
          id: value?.id,
          payload,
        })
      ).then((resp: any) => {
        handleRequest({
          response: resp,
          successMessage: "Component pinned to the dashboard successfully.",
        });
      });
    } else {
      let newPinnedContainersList = [];
      let payload = {};
      let dashboardId;
      newPinnedContainersList = value?.pinnedContainers.filter((o: any) =>
        hasNoDuplicateEntity
          ? o.name !== claim?.name
          : o.targetEndpoint !== targetEndpoint
      );
      payload = { ...value, pinnedContainers: newPinnedContainersList };
      dashboardId = value?.id;

      dispatchAction(
        dashboardActionHandler({
          action: "update",
          id: dashboardId,
          payload,
        })
      ).then((resp: any) => {
        handleRequest({
          response: resp,
          successMessage: "Component unpinned from the dashboard successfully.",
        });
      });
      const tempPersonalizations = structuredClone(claim?.personalizations);
      tempPersonalizations[1].pinnedTo = mustBeArray(
        tempPersonalizations[1].pinnedTo
      ).filter((o: any) => o !== value?.id);

      handleClaimsUpdate({ value: tempPersonalizations });
    }
  };

  //   Update DOM
  const handleClaimsUpdate = ({
    value,
    label = "personalizations",
  }: {
    value: any;
    label?: string;
  }) => {
    const currentAuthorizationDOM = getCurrentAuthorizationDom({ member });
    const updatedDOM = updateAuthorizationClaims({
      claims: currentAuthorizationDOM,
      value,
      label,
      name: claim?.name,
    });
    const updatedComponent = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: claim?.name,
    });

    dispatchAction(
      updateAuthorizationClaim({
        claim: updatedDOM,
        payload: {
          personaComponents: [updatedComponent],
        },
        update: true,
      })
    );
  };

  //   Display menu options
  const handleVisibleChange = ({ event }: { event: MouseEvent }) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setMenuVisible(!menuVisible);
  };

  const hideMenu = () => {
    setMenuVisible(false);
    hideDashboardForm();
  };

  //   Check if component is pinned
  const componentPinned = () => {
    let isPinned = false;
    dashboardList.forEach((item: any) => {
      const isComponentPinned = mustBeArray(item?.pinnedContainers).some(
        (o) =>
          o?.name === claim?.name &&
          baseUrlMatches({ url1: o.targetEndpoint, url2: targetEndpoint })
      );
      if (isComponentPinned) {
        isPinned = true;
      }
    });

    return isPinned;
  };

  //   Add a divider after the dashboard list
  const showDividerAfterDashboardList = ({ list }: any) => {
    return !(isUserCompanyAdmin({ member }) && mustBeArray(list).length === 1);
  };

  //   Display add dashboard form
  const handleDashboardFormVisibility = () => {
    setShowDashboardForm(true);
    setSubmitLoading(false);
    setEditMode(false);
  };

  const hideDashboardForm = () => {
    setShowDashboardForm(false);
    setSubmitLoading(false);
    setEditMode(false);
  };

  const editDashboard = ({ event, activeDashboard }: any) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setActiveDashboard(activeDashboard);
    setEditMode(true);
    setShowDashboardForm(true);
    setSubmitLoading(false);
  };

  //   Add/Edit Dashboard
  const createNewDashboard = (name: string) => {
    setSubmitLoading(true);
    let updatedDashboardList: DashboardItem[] = [];
    let payload = {};
    if (editMode) {
      updatedDashboardList = dashboardList.map((item: any) => {
        if (item.id === activeDashboard.id) {
          return {
            ...item,
            name: name,
          };
        }
        return item;
      });

      payload = { ...activeDashboard, name };
    } else {
      updatedDashboardList = [
        ...dashboardList,
        {
          ...dashboardList[1],
          id: dashboardList.length + 1,
          name,
        },
      ];
      payload = {
        name,
        pinnedContainers: [],
      };
    }

    dispatchAction(
      dashboardActionHandler({
        data: updatedDashboardList,
        action: editMode ? "update" : "create",
        id: activeDashboard?.id,
        payload,
      })
    ).then((resp: any) => {
      setSubmitLoading(false);
      if (resp.status) {
        message.success(
          `Dashboard ${editMode ? "updated" : "created"} successfully.`
        );
        hideDashboardForm();
        setActiveDashboard({});
      }
    });
  };

  // Delete dashboard
  const deleteDashboard = () => {
    let currentDashboard = { ...activeDashboard };
    setDeleteLoading(true);
    dispatchAction(
      dashboardActionHandler({
        id: activeDashboard?.id,
        action: "delete",
      })
    ).then((resp: any) => {
      setDeleteLoading(false);

      if (resp.status) {
        message.success("Dashboard deleted successfully.");
        hideDashboardForm();
        setActiveDashboard({});
        handleDashboardFormVisibility();
      }
    });
    /**
     * TODO: the below code is not related with the component presentation and must be moved outside of it
     */
    // Update all the components pinned in that dashboard
    mustBeArray(activeDashboard?.pinnedContainers).forEach(
      (container, index) => {
        delay(400).then(() => {
          const currentAuthorizationDOM = getCurrentAuthorizationDom({
            member,
          });
          const currentDOMComponents = currentAuthorizationDOM.find(
            (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
          );
          const claim = findAuthorizationClaims({
            claims: currentDOMComponents?.components,
            name: container?.name,
          });
          const tempPersonalizations = structuredClone(claim?.personalizations);
          tempPersonalizations[1].pinnedTo = mustBeArray(
            tempPersonalizations[1].pinnedTo
          ).filter((o: any) => o !== (currentDashboard && currentDashboard.id));
          const updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: tempPersonalizations,
            label: "personalizations",
            name: claim?.name,
          });
          if (index === mustBeArray(activeDashboard).length - 1) {
            dispatchAction(
              updateAuthorizationClaim({
                claim: updatedDOM,
                payload: {
                  personaComponents: [
                    ...updatedComponents,
                    { ...claim, personalizations: tempPersonalizations },
                  ],
                },
                update: true,
              })
            );
          } else {
            setUpdatedComponents([
              ...updatedComponents,
              { ...claim, personlizations: tempPersonalizations },
            ]);
            dispatchAction(
              updateAuthorizationClaim({
                claim: updatedDOM,
                payload: {
                  personaComponents: [
                    ...updatedComponents,
                    { ...claim, personalizations: tempPersonalizations },
                  ],
                },
                update: false,
              })
            );
          }
        });
      }
    );
  };

  //   Dashboard menu
  const menu = (
    <ErrorBoundary>
      <Menu className="custom-pin-option">
        {removeDefaultDashboard({ list: dashboardList, member }).map(
          (option: any, i: number) => {
            const isPinned = componentIsPinned({
              pinnedContainers: option.pinnedContainers,
              name: claim?.name,
              targetEndpoint: targetEndpoint,
            });
            return (
              <Menu.Item key={i}>
                <div
                  className="grid grid-cols-12"
                  onClick={() =>
                    updateSelectedDashboard({
                      value: option,
                      select: !isPinned,
                    })
                  }
                >
                  <div className="col-span-1">
                    <If
                      condition={isPinned}
                      then={<CheckOutlined className="text-xs" />}
                    />
                  </div>
                  <div className="col-span-10 overflow-x-hidden">
                    {option.name}
                  </div>
                  <div className="col-span-1">
                    <If
                      condition={option?.editable}
                      then={
                        <EditOutlined
                          className="text-xs color-primary"
                          onClick={(e: any) =>
                            editDashboard({
                              event: e,
                              activeDashboard: option,
                            })
                          }
                        />
                      }
                    />
                  </div>
                </div>
              </Menu.Item>
            );
          }
        )}
        <If
          condition={showDividerAfterDashboardList({ list: dashboardList })}
          then={<Menu.Divider />}
        />
        <Menu.Item key="create" onClick={handleDashboardFormVisibility}>
          <PlusOutlined /> &nbsp; Create new dashboard
        </Menu.Item>
        <Menu.Item key="share">
          <ShareAltOutlined /> &nbsp; Share
        </Menu.Item>
        <If condition={showDashboardForm} then={<Menu.Divider />} />

        {/* Form to create new dashboard */}
        <DashboardNameForm
          visible={showDashboardForm}
          onCancel={hideDashboardForm}
          onCreate={createNewDashboard}
          submitLoading={submitLoading}
          editMode={editMode}
          activeDashboard={activeDashboard}
          onDelete={deleteDashboard}
          dashboardList={dashboardList}
          deleteLoading={deleteLoading}
        />
      </Menu>
    </ErrorBoundary>
  );

  return (
    <ErrorBoundary>
      <div className="flex justify-end items-center">
        <Dropdown
          overlay={menu}
          onVisibleChange={hideMenu}
          visible={menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          className="pin-option-dropdown"
          overlayClassName="pin-menu-option-dropdown"
        >
          <Fragment>
            <div onClick={(event: any) => handleVisibleChange({ event })}>
              <Tooltip placement="bottomLeft" title={"Pin"}>
                <PushpinOutlined
                  className={classNames("text-lg hand", {
                    "color-primary transform-minus-45": componentPinned(),
                  })}
                />
              </Tooltip>
            </div>
          </Fragment>
        </Dropdown>
      </div>
    </ErrorBoundary>
  );
};

export default GeneralPinComponent;
