//@ts-nocheck
import React, { Component } from "react";
import { Button, message, Card, Modal } from "antd";
import { PayrunSteps, AttachAmendmentModal } from "../../../../Common/payrun";
import AddAmendment from "../addAmendment";
import SwiftMessage from "../../../../constants/message";
import _ from "lodash";
import {
  errorDisplay,
  mustBeArray,
  findAuthorizationClaims,
  delay,
  updateAuthorizationClaims,
  getPersonalizationData,
  getCurrentCardGrid,
  conditionalParameter,
  getConditionalResponse,
  getOptionalParameter,
  getMatchParams,
  listHasMoreData,
  getCurrentAuthorizationDom,
  errorHandler,
  amendmentHasQuestionSection,
  handleRequest,
  hasDueDatePassed,
  isComponentVisible,
  getDefaultPage,
  getDefaultPerPage,
  payrunIsSkipped,
} from "../../../../libs";
import {
  PAGE_NUMBER_INDEX,
  LIST_PINNABLE_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PAGINATION_TYPE_INDEX,
  PAGE_SIZE_INDEX,
  SORT_ORDER_INDEX,
  SORT_COLUMN_INDEX,
  PAGINATION_NOT_REQUIRED,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  TABLE_VIEW_VALUE,
  GRID_VIEW_VALUE,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_SCROLLING,
  GRID_PAGE_NUMBER_INDEX,
  GRID_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_ROW_INDEX,
  SORTING_NOT_REQUIRED,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  GRID_PERSONALIZATIONS,
  TABLE_PERSONALIZATIONS,
  SINGLE_LINE_GRID_VALUE,
} from "../../../../constants/defaultClaims";
import {
  AMENDMENT_GRID_OPTIONS,
  AMENDMENT_FIELD_OPTIONS,
} from "../../../../constants/sortingOptions";
import axios from "axios";
import { DEFAULT_UNATHORIZED_ACTION_MESSAGE } from "../../../../constants/errors";
import APIHandler from "../../../../constants/apiUrl";
import SimpleButton from "../../../../Common/ClaimComponents/Button/SimpleButton";
import { Loading, If } from "../../../../Common/ui";
import moment from "moment";
import ReSubmitAmendment from "../ReSubmitAmendment";
import { AMENDMENT_DECLINED_STATUS } from "../../../../constants/payrun";
import { AmendmentDetailsModal } from "../../../../Common/amendments";
import AmendmentListContainer from "./amendmentListContainer";
import {
  DEFAULT_ROUTES,
  GOOGLE_ANALYTICS_PAGE_TITLE,
  MAX_AMENDMENT_PER_PAGE,
  PAYRUN_AMENDMENT_LIST_CONTAINER,
  PAYRUN_AMENDMENT_STATUS_SUBMIT_BUTTON,
  PAYRUN_ATTACH_AMENDMENT_CONTAINER,
} from "../../../../constants";
import { withRouter } from "../../../../hooks";
import { PayrunEmployeeList } from "@xemplo/payrun-employee-list";

type State = any;

class PayrunDetail extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      steps: [],
      visible: false,
      submitLoading: false,
      amendmentPagination: 1,
      amendmentPerPage: 10,
      amendmentTotal: 0,
      amendmentList: [],
      amendmentListLoading: false,
      amendmentAddable: false,
      amendmentGridable: false,
      amendmentGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      amendmentGridView: false,
      amendmentIsPinned: "false",
      amendmentSortingType: "notrequired",
      amendmentHasMoreData: false,
      amendmentSortInfo: {},
      amendmentGridSortOption: AMENDMENT_GRID_OPTIONS,
      amendmentSelectedGridSort: undefined,
      amendmentFieldOptions: AMENDMENT_FIELD_OPTIONS,
      amendmentHistory: [],
      historyLoading: false,
      totalActions: 0,
      viewDetail: false,
      singleLineGridView: false,
      pageLoad: true,
      activeAmendment: {},
      resubmitLoading: false,
      showAttachAmendmentModal: true,
      nextPayrunAmendmentList: [],
      payrunAttachNextPayrunAmendmentModalClaim: {},
      attachLoading: false,
    };
  }

  componentDidMount() {
    const { dashboardView, dashboardClaims } = this.props;
    if (dashboardView) {
      const amendmentPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      if (!amendmentPersonalizations || !amendmentPersonalizations.gridView) {
        this.setState({ amendmentGridView: false });
        this.listAmendments({ options: { page: 1 } });
      } else if (
        amendmentPersonalizations &&
        amendmentPersonalizations.gridView === SINGLE_LINE_GRID_VALUE
      ) {
        this.setState({ singleLineGridView: true });
        this.getAmendmentList();
      } else {
        this.setState({ amendmentGridView: true });
        this.listAmendmentGrid(1);
      }
      this.setState({ pageLoad: false });
    } else {
      this.getTablePersonalization();
      this.getNextPayrunAmendments();
    }
  }

  // Get next payrun amendment list
  getNextPayrunAmendments = () => {
    // Check in the claims if user has access to include the next payrun amendments and then call the endpoint
    const { pageClaims } = this.props;
    const payrunAttachNextPayrunAmendmentModalClaim = mustBeArray(
      pageClaims?.components
    ).find((o) => o.name === PAYRUN_ATTACH_AMENDMENT_CONTAINER);
    this.setState({ payrunAttachNextPayrunAmendmentModalClaim });
    if (
      isComponentVisible({ claim: payrunAttachNextPayrunAmendmentModalClaim })
    ) {
      this.props
        .generalAmendmentHandler({
          action: "list",
          options: {
            per_page: MAX_AMENDMENT_PER_PAGE,
            page: 1,
            q: {
              businessUnitId: this.props.payrun?.businessUnitID,
              showComplete: false,
              showDraftOnly: true,
            },
          },
        })
        .then((resp: any) => {
          if (handleRequest({ response: resp, hasValidationErrors: true })) {
            this.setState(
              { nextPayrunAmendmentList: mustBeArray(resp?.data) },
              () => {
                // Check if we need to render the modal to attach next payrun amendments - condition - list shouldn't be empty
                this.setState({
                  viewAttachAmendmentModal:
                    this.state.nextPayrunAmendmentList.length !== 0,
                });
              }
            );
          }
        });
    }
  };

  getTablePersonalization() {
    const { pageClaims } = this.props;
    const payrunAmendmentListContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === PAYRUN_AMENDMENT_LIST_CONTAINER
    );
    if (
      mustBeArray(payrunAmendmentListContainer?.personalizations)?.[
        LIST_PERSONALIZATION_GRID_TABLE_INDEX
      ]?.value === SINGLE_LINE_GRID_VALUE
    ) {
      this.getAmendmentList();
      this.setState({ singleLineGridView: true }, () => {
        this.setState({ pageLoad: false });
      });
    } else {
      this.setState({ pageLoad: false });
      const amendmentGeneralTable = findAuthorizationClaims({
        claims: payrunAmendmentListContainer?.components,
        name: "payrunAmendmentGeneralTable",
      });
      const amendmentGeneralGrid = findAuthorizationClaims({
        claims: payrunAmendmentListContainer?.components,
        name: "payrunAmendmentGeneralGrid",
      });

      const amendmentPaginationType =
        payrunAmendmentListContainer?.personalizations?.[
          LIST_PAGINATION_TYPE_INDEX
        ]?.value;
      const amendmentSortingType =
        payrunAmendmentListContainer?.personalizations?.[
          LIST_SORTING_REQUIRED_INDEX
        ]?.value;
      const amendmentIsPinned =
        payrunAmendmentListContainer?.personalizations?.[LIST_PINNABLE_INDEX]
          ?.value;
      this.setState({
        amendmentPaginationType,
        amendmentSortingType,
        amendmentIsPinned,
        amendmentGridable: payrunAmendmentListContainer?.gridable,
      });

      // If Table
      if (isComponentVisible({ claim: amendmentGeneralTable })) {
        const amendmentPagination = getDefaultPage({
          page: amendmentGeneralTable?.personalizations?.[PAGE_NUMBER_INDEX]
            ?.value,
        });
        const pageSize = amendmentGeneralTable.personalizations
          ? Number(
              amendmentGeneralTable.personalizations[PAGE_SIZE_INDEX].value ||
                20
            )
          : 20;
        const SortOrder = amendmentGeneralTable.personalizations
          ? amendmentGeneralTable.personalizations[SORT_ORDER_INDEX].value
          : null;
        const SortColumn = amendmentGeneralTable.personalizations
          ? amendmentGeneralTable.personalizations[SORT_COLUMN_INDEX].value
          : null;
        this.setState(
          {
            amendmentPerPage:
              amendmentPaginationType === PAGINATION_NOT_REQUIRED
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : getDefaultPerPage({ perPage: pageSize }),
            amendmentPagination,
            amendmentSortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (
              mustBeArray(payrunAmendmentListContainer?.personalizations)?.[
                LIST_PERSONALIZATION_GRID_TABLE_INDEX
              ]?.value === TABLE_VIEW_VALUE
            ) {
              if (amendmentPaginationType === PAGINATION_SCROLLING) {
                this.listScrollingAmendment({ pageLoad: true });
              } else {
                this.listAmendments({ options: {} });
              }
            }
          }
        );
      }
      // If Grid
      if (isComponentVisible({ claim: amendmentGeneralGrid })) {
        const pageNumber = amendmentGeneralGrid.personalizations
          ? Number(
              amendmentGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
                .value
            )
          : 1;
        const row = amendmentGeneralGrid.personalizations
          ? Number(amendmentGeneralGrid.personalizations[GRID_ROW_INDEX].value)
          : 5;
        const col = amendmentGeneralGrid.personalizations
          ? Number(
              amendmentGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
            )
          : 4;
        const SortOrder = amendmentGeneralGrid.personalizations
          ? amendmentGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
          : null;
        const SortColumn = amendmentGeneralGrid.personalizations
          ? amendmentGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
          : null;

        this.setState(
          {
            amendmentGrid: {
              page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row: _.isNaN(row) ? 5 : row,
              col: _.isNaN(col) ? 4 : col,
            },
            amendmentSelectedGridSort:
              _.find(
                this.state.amendmentGridSortOption,
                (o) => o.label === SortColumn
              ) &&
              _.find(
                this.state.amendmentGridSortOption,
                (o) => o.label === SortColumn
              ).id,
          },
          () => {
            if (
              mustBeArray(payrunAmendmentListContainer?.personalizations)?.[0]
                ?.value === GRID_VIEW_VALUE
            ) {
              this.setState({ amendmentGridView: true });
              if (amendmentPaginationType === PAGINATION_SCROLLING) {
                this.listScrollingGridAmendment({ pageLoad: true });
              } else {
                this.amendmentFetchMoreData({ isPaginated: true });
              }
            }
          }
        );
      }
    }
  }

  getAmendmentList = () => {
    const id =
      this.props.match && this.props.match.params && this.props.match.params.id;
    this.props
      .listPayrunAmendments({
        options: { per_page: MAX_AMENDMENT_PER_PAGE },
        id,
        cancelToken: this.signal.token,
        targetEndpoint: this.props.dashboardView
          ? this.props.targetEndpoint
          : null,
      })
      .then((resp) => {
        this.setState({ amendmentListLoading: false });

        if (resp.status) {
          this.setState({ amendmentList: resp.data });
        }
      });
  };

  listAmendments = ({ options }) => {
    const id =
      this.props.match && this.props.match.params && this.props.match.params.id;

    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.amendmentPagination = options.page;
          prevState.amendmentSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.amendmentListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.amendmentPagination))
            ? Number(this.state.amendmentPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.amendmentPerPage))
            ? Number(this.state.amendmentPerPage)
            : 10,

          ...this.getAmendmentTableSortInfo(),
        };

        this.props
          .listPayrunAmendments({
            options: newOptions,
            id,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.dashboardView
              ? this.props.targetEndpoint
              : null,
          })
          .then((resp) => {
            this.setState({ amendmentListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.amendmentPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ amendmentPagination: 1 }, () => {
                  this.listAmendments({ options: {} });
                });
              } else {
                this.setState({
                  amendmentList: mustBeArray(resp.data),
                  amendmentTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrunAmendmentGeneralTable");
        });
      }
    );
  };

  getAmendmentTableSortInfo = () => {
    if (
      this.state.amendmentSortInfo?.field &&
      this.state.amendmentSortInfo.order &&
      this.state.amendmentSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.amendmentSortInfo.field]:
            this.state.amendmentSortInfo.order,
        },
      };
    } else return {};
  };

  listScrollingGridAmendment = ({ options = {}, pageLoad = false }) => {
    if (!pageLoad && !this.state.amendmentHasMoreData) return false;

    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState(
      {
        amendmentListLoading: true,
        amendmentGrid: {
          ...this.state.amendmentGrid,
          page: getConditionalResponse({
            condition: !pageLoad,

            resp1:
              Number(
                conditionalParameter({
                  data: this.state.amendmentGrid,
                  field: "page",
                })
              ) + 1,

            resp2: Number(
              conditionalParameter({
                data: this.state.amendmentGrid,
                field: "page",
              })
            ),
          }),
        },
      },
      () => {
        const { amendmentGrid } = this.state;
        const { page } = amendmentGrid;

        const per_page = this.getAmendmentGridPageSize();

        let sortingParams = this.getAmendmentGridSortInfo();

        this.props
          .listPayrunAmendments({
            options: {
              page: getConditionalResponse({
                condition: pageLoad,
                resp1: 1,
                resp2: page,
              }),
              per_page: getConditionalResponse({
                condition: pageLoad,
                resp1: Number(page) * per_page,
                resp2: per_page,
              }),
              ...sortingParams,
            },

            cancelToken: this.signal.token,
            id,

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ amendmentListLoading: false });
            this.handleScrollingListResponse({ response });
          });
      }
    );
  };

  handleScrollingListResponse = ({ response }) => {
    if (response.status) {
      if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
        this.setState({ amendmentGrid: { ...amendmentGrid, page: 1 } }, () => {
          this.listScrollingGridAmendment({ pageLoad: true });
        });
      } else {
        this.setState(
          (prevState) => {
            const data = mustBeArray(response.data);
            prevState.amendmentList = pageLoad
              ? data
              : prevState.amendmentList.concat(data);
            prevState.amendmentHasMoreData =
              response.total > prevState.amendmentList.length;
            prevState.amendmentTotal = response.total;
            return prevState;
          },
          () => {
            delay(300).then(() => {
              this.updatePersonalization("payrunAmendmentGeneralGrid");
            });
          }
        );
      }
    }
  };

  amendmentFetchMoreData = ({ isPaginated = false }) => {
    this.setState({ amendmentListLoading: true });

    const id = getMatchParams({ match: this.props.match, field: "id" });

    const { amendmentGrid } = this.state;
    const { page } = amendmentGrid;

    const per_page = this.getAmendmentGridPageSize();

    let sortingParams = this.getAmendmentGridSortInfo();

    this.props
      .listPayrunAmendments({
        options: { page, per_page, ...sortingParams },
        id,

        cancelToken: this.signal.token,

        targetEndpoint: getConditionalResponse({
          condition: this.props.dashboardView,
          resp1: this.props.targetEndpoint,
          resp2: null,
        }),
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { amendmentGrid: { ...amendmentGrid, page: 1 } },
              () => {
                this.amendmentFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.amendmentList = data;
                } else {
                  prevState.amendmentList =
                    prevState.amendmentList.concat(data);
                  prevState.amendmentHasMoreData = getConditionalResponse({
                    condition: listHasMoreData({
                      data,
                      currentList: prevState.amendmentList,
                      currentTotal: prevState.amendmentTotal,
                    }),
                    resp1: true,
                    resp2: false,
                  });
                  prevState.amendmentGrid = getConditionalResponse({
                    condition: listHasMoreData({
                      data,
                      currentList: prevState.amendmentList,
                      currentTotal: prevState.amendmentTotal,
                    }),
                    resp1: {
                      ...prevState.amendmentGrid,
                      page: prevState.amendmentGrid.page + 1,
                    },
                    resp2: prevState.amendmentGrid,
                  });
                }

                prevState.amendmentTotal = response.total;
                prevState.amendmentListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization("payrunAmendmentGeneralGrid");
                });
              }
            );
          }
        }
      });
  };

  listAmendmentGrid = (page) => {
    const id = this.props?.match?.params?.id;

    this.setState(
      (prevState) => {
        prevState.amendmentGrid.page = page;
        prevState.amendmentListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.amendmentGrid?.page,
          per_page: this.getAmendmentGridPageSize(),

          ...this.getAmendmentGridSortInfo(),
        };

        this.props
          .listPayrunAmendments({
            options: tempOptions,
            id,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.dashboardView
              ? this.props.targetEndpoint
              : null,
          })
          .then((resp) => {
            this.setState({ amendmentListLoading: false });

            if (resp.status) {
              this.setState({
                amendmentList: mustBeArray(resp.data),
                amendmentTotal: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrunAmendmentGeneralGrid");
        });
      }
    );
  };

  getAmendmentGridSortInfo = () => {
    if (
      this.state.amendmentGrid.sortInfo?.field &&
      this.state.amendmentGrid.sortInfo.order &&
      this.state.amendmentSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.amendmentGrid.sortInfo.field]:
            this.state.amendmentGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.amendmentGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.amendmentGrid.sortInfo.order,
        },
      };
    } else return {};
  };

  handleAmendmentGridChange = ({ gridView, isPaginated }) => {
    if (this.state.amendmentGridView === gridView) {
      return false;
    }

    this.setState(
      {
        amendmentGridView: gridView,
        amendmentList: [],
        amendmentListLoading: true,
      },
      () => {
        this.updatePersonalization(PAYRUN_AMENDMENT_LIST_CONTAINER);
        if (!gridView) {
          if (this.state.amendmentPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingAmendment({ pageLoad: true, options: {} });
          } else {
            this.listAmendments({ options: {} });
          }
        } else {
          if (this.state.amendmentPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridAmendment({ pageLoad: true, options: {} });
          } else {
            this.amendmentFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  amendmentUpdatePageSize = (value) => {
    if (this.state.amendmentGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.amendmentGrid.row = Number(row);
          prevState.amendmentGrid.col = Number(col);
          prevState.amendmentGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.amendmentPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridAmendment({ pageLoad: true, options: {} });
          } else {
            this.amendmentFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState(
        { amendmentPerPage: Number(value), amendmentPagination: 1 },
        () => {
          if (this.state.amendmentPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingAmendment({ pageLoad: true, options: {} });
          } else {
            this.listAmendments({ options: {} });
          }
        }
      );
    }
  };

  amendmentUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.amendmentSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.amendmentGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.amendmentGrid = {
            ...prevState.amendmentGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.amendmentPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridAmendment({ pageLoad: true, options: {} });
        } else {
          this.amendmentFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAmendmentAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.viewDetails(data);
        break;
      case "edit":
        this.props.updateHeader({
          header: {
            module: "Back to Pay Run Detail",
            enableBack: true,
            entity: "generalAmendment",
            returnUrl: `/payrun/${this.props.payrun?.payrunId}`,
            gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.AmendmentEdit,
          },
        });
        this.props.router.navigate(
          `/payrun/${data.payrunId}/amendment/${data.amendmentId}`
        );
        break;
      case "delete":
        this.handleDelete(data);
        break;
      case "reSubmit":
        this.showReSubmitForm(data);
        break;
      default:
        break;
    }
  };

  handleResubmit = (values) => {
    this.setState({ resubmitLoading: true });
    this.props
      .reSubmitAmendment({
        id: this.state.activeAmendment?.amendmentId,

        options: {
          payrunID: this.state.activeAmendment?.payrunId,
        },
        payload: values,
      })

      .then((response) => {
        this.setState({ resubmitLoading: false });

        if (response.status) {
          this.setState({ showReSubmitForm: false });
          message.success("Resubmitted successfully");

          this.listAmendmentGrid(this.state.amendmentGrid?.page);
        } else {
          errorDisplay(response?.data?.validationErrors);
          if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };
  viewDetails = (record) => {
    this.setState({ amendment: record }, () => {
      this.setState({ viewDetail: true });
    });
  };
  handleDelete = (record) => {
    this.props
      .deleteAmendment({
        id: record.amendmentId,
        options: { payrunID: record.payrunId },
      })

      .then((response) => {
        if (response.status) {
          message.success("Amendment deleted successfully");

          if (this.state.singleLineGridView) {
            return this.getAmendmentList();
          }

          this.amendmentFetchMoreData({ isPaginated: true });
        } else {
          errorDisplay(response?.data?.validationErrors);
          if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
            return;
          }
        }
      });
  };

  amendmentUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.amendmentGrid.sortInfo[field] === value) {
          prevState.amendmentGrid.sortInfo[field] = "";
        } else {
          prevState.amendmentGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.amendmentFetchMoreData({ isPaginated: true });
      }
    );
  };

  getAmendmentGridPageSize = () => {
    const { row, col } = this.state.amendmentGrid;

    return this.state.amendmentPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updateAmendmentGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.amendmentGrid.sortInfo[field] === value) {
          prevState.amendmentGrid.sortInfo[field] = "";
        } else {
          prevState.amendmentGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.amendmentFetchMoreData({ isPaginated: true });
      }
    );
  };
  //
  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;

    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = getCurrentAuthorizationDom(member);
      let updatedDOM = [];
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const payrunAmendmentListContainer = findAuthorizationClaims({
        claims: conditionalParameter({ data: pageClaims, field: "components" }),
        name: PAYRUN_AMENDMENT_LIST_CONTAINER,
      });
      switch (field) {
        case "payrunAmendmentGeneralTable":
          if (payrunAmendmentListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.amendmentPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.amendmentPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value = conditionalParameter({
              data: this.state.amendmentSortInfo,
              field: "field",
            });

            personalizations[SORT_ORDER_INDEX].value = conditionalParameter({
              data: this.state.amendmentSortInfo,
              field: "order",
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case "payrunAmendmentGeneralGrid":
          if (payrunAmendmentListContainer.personalizable === "true") {
            const { amendmentGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${conditionalParameter({
              data: amendmentGrid,
              field: "page",
            })}`;
            personalizations[GRID_ROW_INDEX].value = `${conditionalParameter({
              data: amendmentGrid,
              field: "row",
            })}`;
            personalizations[GRID_COLUMN_INDEX].value = `${conditionalParameter(
              { data: amendmentGrid, field: "col" }
            )}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              amendmentGrid &&
              amendmentGrid.sortInfo &&
              amendmentGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              amendmentGrid &&
              amendmentGrid.sortInfo &&
              amendmentGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        case PAYRUN_AMENDMENT_LIST_CONTAINER:
          if (payrunAmendmentListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value =
              getConditionalResponse({
                condition: this.state.amendmentGridView,
                resp1: GRID_VIEW_VALUE,
                resp2: TABLE_VIEW_VALUE,
              });

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.amendmentPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.amendmentSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.amendmentIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: conditionalParameter({
                data: pageClaims,
                field: "components",
              }),
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: conditionalParameter({ data: updatedComponent, field: "id" }),
          name: conditionalParameter({ data: updatedComponent, field: "name" }),
          personalizations,
          personalization: getConditionalResponse({
            condition: _.isEmpty(personalization),
            resp1: null,
            resp2: personalization,
          }),
        };
      }

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: getConditionalResponse({
              condition: payload,
              resp1: [payload],
              resp2: [],
            }),
          },
          update,
        });
      });
    } catch (err) {
      return err;
    }
  };
  //

  onCancel = () => {
    this.setState({ visible: false });
  };

  addAmendment = async (values) => {
    values.payrunId = this.props.payrun?.payrunId;
    values.assignTo = _.isArray(values.assignTo)
      ? JSON.stringify(values.assignTo)
      : 0;
    const response = values.isReoccurring
      ? await this.props.generalAmendmentHandler({
          payload: values,
          action: "create",
          cancelToken: this.signal.token,
        })
      : await this.props.addAmendment(this.props.payrun?.payrunId, values);
    if (
      handleRequest({
        response,
        successMessage: "Amendment created successfully",
        hasValidationErrors: true,
      })
    ) {
      this.handleAmendmentCreationRedirection({
        values,
        response,
      });
    } else {
      if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
        this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
      }
    }
    return response;
  };

  handleAmendmentCreationRedirection = ({ values, response }) => {
    this.setState({ visible: false });
    if (amendmentHasQuestionSection({ id: values?.changeTaskValueId || 0 })) {
      this.props.updateHeader({
        header: {
          module: "Back to Pay Run Detail",
          enableBack: true,
          entity: "generalAmendment",
          returnUrl: `/payrun/${this.props.payrun?.payrunId}`,
        },
      });
      this.props.router.navigate(
        !values?.isReoccurring
          ? `/payrun/${this.props.payrun?.payrunId}/amendment/${response.data?.result}`
          : `/payrun/amendment/${response.data}`
      );
    } else {
      this.listAmendments({ options: {} });
    }
  };

  submitAmendment = () => {
    this.setState({ submitLoading: true, viewConfirm: false });

    this.props

      .submitAmendment({ id: this.props.payrun?.payrunId, options: {} })

      .then((resp) => {
        this.setState({ submitLoading: false });

        if (resp.status) {
          message.success(SwiftMessage["submit.success"]);

          this.props.getPayrun({
            id: this.props.payrun?.payrunId,
            options: {},
          });

          this.props.listPayrunAmendments({
            id: this.props.payrun.payrunId,
            options: { per_page: MAX_AMENDMENT_PER_PAGE },
          });
        } else {
          if (resp?.message === "Forbidded") {
            message.error(DEFAULT_UNATHORIZED_ACTION_MESSAGE);
            return;
          }
          errorHandler({
            response: resp,
            hasValidationErrors: true,
          });
          // If the error code includes that of skipped pay run, redirect the user to the dashboard
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  handleAmendmentCreate = () => {
    this.setState({ visible: true });
  };
  hideModal = () => {
    this.setState({ viewDetail: false });
  };

  amendmentDueDatePassed = () => {
    const { payrun } = this.props;
    const dueDate = payrun?.ammendmentDueDateTime;
    return hasDueDatePassed({ date: dueDate });
  };

  confirmSubmitAmendment = () => {
    this.setState({ viewConfirm: true });
  };

  closeConfirm = () => {
    this.setState({ viewConfirm: false });
  };
  showReSubmitForm = (record) => {
    this.setState({ activeAmendment: record, showReSubmitForm: true });
  };

  hideReSubmitForm = () => {
    this.setState({
      showReSubmitForm: false,
      activeAmendment: {},
      resubmitLoading: false,
    });
  };

  hideAttachAmendmentModal = () => {
    this.setState({ showAttachAmendmentModal: false });
  };

  checkAmendmentSubmitDisabled = () => {
    const { amendmentList } = this.state;

    const { payrun } = this.props;
    const declinedAmendment = _.find(
      mustBeArray(amendmentList),
      (o) => o.amendmentStatusID === AMENDMENT_DECLINED_STATUS
    );
    if (_.isUndefined(declinedAmendment)) return false;
    else {
      const payrunLastActivityDate = payrun && payrun.lastActivityDate;
      // check if any action done after this date
      const recentlyActionedAmendment = _.find(
        mustBeArray(amendmentList),
        (o) =>
          payrunLastActivityDate &&
          o.lastActivityDate &&
          moment(o.lastActivityDate).isAfter(moment(payrunLastActivityDate))
      );
      if (_.isUndefined(recentlyActionedAmendment)) {
        return true;
      }
      return false;
    }
  };

  // Attach amendments to the payrun
  handleAttachAmendment = ({ checkedList }) => {
    this.setState({ attachLoading: true });
    this.props
      .attachAmendments({
        id: this.props.payrun?.payrunId,
        payload: checkedList,
      })
      .then((resp) => {
        this.setState({ attachLoading: false });
        if (resp.status) {
          message.success("Amendments attached successfully.");
          this.handleAttachmentSuccess();
        } else {
          errorHandler({
            response: resp,
            hasValidationErrors: true,
          });
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  handleAttachmentSuccess = () => {
    // Refetch amendment list to display newly attached amendments
    this.setState({
      amendmentListLoading: true,
      showAttachAmendmentModal: false,
    });
    //  If grid view reload table else reload grid
    if (this.state.amendmentGridView) {
      this.listAmendments({ options: {} });
    } else {
      this.listAmendmentGrid(this.state.amendmentGrid?.page);
    }
  };

  render() {
    const {
      visible,
      submitLoading,
      amendmentListLoading,
      amendmentList,
      amendmentPagination,
      amendmentSortInfo,
      amendmentPerPage,
      amendmentTotal,
      amendmentGrid,
      amendmentGridable,
      amendmentGridView,
      amendmentFieldOptions,
      amendmentSortingType,
      amendmentPaginationType,
      amendmentIsPinned,
      amendmentHasMoreData,
      singleLineGridView,
      pageLoad,
      payrunAttachNextPayrunAmendmentModalClaim,
      attachLoading,
    } = this.state;

    const amendmentCardGrid = getCurrentCardGrid({
      grid: this.state.amendmentGrid,
    });

    const { businessunitDetails, dashboardView, match, pageClaims } =
      this.props;
    const targetEndpoint = APIHandler.constructEndpoint({
      endpoint: "PAYRUN_AMENDMENTS_URL",
      options: {
        id: getMatchParams({ match, field: "id" }),
      },
    });
    const listProps = {
      isPinned: amendmentIsPinned,
      updatePinnedComponent: this.updatePinnedComponent,
      gridable: amendmentGridable,
      gridView: amendmentGridView,
      grid: amendmentGrid,
      per_page: amendmentPerPage,
      handleAddAction: this.handleAmendmentCreate,
      handleGridChange: this.handleAmendmentGridChange,
      fieldOptions: amendmentFieldOptions,
      updateGridField: this.updateAmendmentGridField,
      sortingType: amendmentSortingType,
      paginationType: amendmentPaginationType,
      list: amendmentList,
      listLoading: amendmentListLoading,
      listGrid: this.listAmendmentGrid,
      listScrollingGrid: this.listScrollingGridAmendment,
      hasMoreData: amendmentHasMoreData,
      handleGridAction: this.handleAmendmentAction,
      listScrollingGeneralTable: this.listScrollingAmendment,
      total: amendmentTotal,
      current: amendmentPagination,
      sortInfo: amendmentSortInfo,
      updatePageSize: this.amendmentUpdatePageSize,
      listGeneralTable: ({ options }) => this.listAmendments({ options }),
      gridPageSize: this.getAmendmentGridPageSize(),
      cardGrid: amendmentCardGrid,
      payrun: this.props.payrun,
      handleActions: this.handleAmendmentAction,
      handleEdit: (record) => this.handleEdit(record, "company"),
      handleDelete: (record) => this.handleDelete(record, "company"),
      getAmendment: this.props.getAmendment,
      rejectPayrunAmendment: this.props.rejectPayrunAmendment,
      deleteAmendment: this.props.deleteAmendment,
      approveAmendment: this.props.approveAmendment,
      hideAddButton: getConditionalResponse({
        condition: [1, 2, 3].includes(
          conditionalParameter({
            data: this.props.payrun,
            field: "payrunStatusID",
          })
        ),
        resp1: false,
        resp2: true,
      }),
      singleLineGridView: singleLineGridView,
      handleSingleGridAction: this.handleAmendmentAction,
      ...this.props,
    };

    return (
      <If
        condition={pageLoad}
        then={<Loading />}
        else={
          <If
            condition={dashboardView}
            then={
              <React.Fragment>
                <AmendmentListContainer
                  {...listProps}
                  dashboardView={dashboardView}
                  componentTitle={this.props.componentTitle}
                  targetEndpoint={this.props.targetEndpoint}
                  editComponent="" //"payrunAmendmentEdit"
                  deleteComponent="payrunAmendmentDelete"
                  claims={pageClaims}
                />

                <AmendmentDetailsModal
                  amendmentId={this.state.amendment?.amendmentId}
                  handleClose={this.hideModal}
                  visible={this.state.viewDetail}
                  historyLoading={this.state.historyLoading}
                  historyList={this.state.amendmentHistory}
                  totalActions={this.state.totalActions}
                  businessunitDetails={businessunitDetails}
                  viewAmendmentHistory={this.state.viewAmendmentHistory}
                  activeAmendment={this.state.activeAmendment}
                  handleTableChange={this.handleActionTableChange}
                  getAmendmentHistory={this.props.getAmendmentHistory}
                />
              </React.Fragment>
            }
            else={
              <React.Fragment>
                <PayrunSteps
                  claims={this.props.pageClaims}
                  invoice={this.state.invoice}
                />
                <React.Fragment>
                  <Card
                    className={`${getConditionalResponse({
                      condition: getOptionalParameter({
                        value1: singleLineGridView,
                        value2: !amendmentGridView,
                      }),
                      resp1: "bg-f9f9f9 card-space-0",
                      resp2:
                        "ant-card card-space-0 card-space-h-16 bg-transparent",
                    })}`}
                  />
                  <div className="mt-6">
                    <AmendmentListContainer
                      {...listProps}
                      handleAmendmentActions={this.handleAmendmentAction}
                      targetEndpoint={targetEndpoint}
                      componentTitle="Amendments"
                      disableCreateButton={this.amendmentDueDatePassed()}
                      dueDatePassed={this.amendmentDueDatePassed()}
                      editComponent=""
                      deleteComponent={getConditionalResponse({
                        condition: this.amendmentDueDatePassed(),
                        resp1: "",
                        resp2: "payrunAmendmentDelete",
                      })}
                      claims={pageClaims}
                    />
                  </div>
                  {[1, 2, 3].includes(this.props.payrun.payrunStatusID) && (
                    <div className="flex justify-end">
                      <SimpleButton
                        name={PAYRUN_AMENDMENT_STATUS_SUBMIT_BUTTON}
                        claims={this.props.pageClaims}
                        className={`${getConditionalResponse({
                          condition: this.checkAmendmentSubmitDisabled(),
                          resp1: "",
                          resp2: "bg-success",
                        })} float-right mr-4`}
                        loading={submitLoading}
                        onClick={() => this.confirmSubmitAmendment()}
                        buttonText="Submit"
                        buttonDisabled={this.checkAmendmentSubmitDisabled()}
                      />
                    </div>
                  )}
                  <PayrunEmployeeList payrunId={this.props.payrun?.payrunId} />
                  <Modal
                    title="Are you sure you want to submit?"
                    width={700}
                    visible={this.state.viewConfirm}
                    onCancel={this.closeConfirm}
                    footer={[
                      <Button
                        className="rounded-[20px] bg-success"
                        onClick={this.submitAmendment}
                        key={"a"}
                      >
                        Yes
                      </Button>,
                      <Button
                        className="rounded-[20px] "
                        onClick={this.closeConfirm}
                        key="b"
                      >
                        No
                      </Button>,
                    ]}
                  >
                    Once you submit the pay run, you will not be able to edit or
                    delete the amendments.
                  </Modal>
                </React.Fragment>
                <AddAmendment
                  visible={visible}
                  payrun={this.props.payrun}
                  {...this.props}
                  onCancel={this.onCancel.bind(this)}
                  onOk={this.addAmendment.bind(this)}
                />
                <AmendmentDetailsModal
                  amendmentId={this.state.amendment?.amendmentId}
                  handleClose={this.hideModal}
                  amendmentActionsVisible={this.state.visible}
                  visible={this.state.viewDetail}
                  payRunID={this.props.payrun?.payrunId}
                  businessunitDetails={businessunitDetails}
                  viewAmendmentHistory={this.state.viewAmendmentHistory}
                  activeAmendment={this.state.activeAmendment}
                  handleTableChange={this.handleActionTableChange}
                  getAmendmentHistory={this.props.getAmendmentHistory}
                />
                <ReSubmitAmendment
                  visible={this.state.showReSubmitForm}
                  handleCancel={this.hideReSubmitForm}
                  submitAmendment={this.handleResubmit}
                  resubmitLoading={this.state.resubmitLoading}
                />

                <If
                  condition={
                    this.state.nextPayrunAmendmentList.length > 0 &&
                    isComponentVisible({
                      claim: payrunAttachNextPayrunAmendmentModalClaim,
                    })
                  }
                  then={
                    <AttachAmendmentModal
                      visible={this.state.showAttachAmendmentModal}
                      handleClose={this.hideAttachAmendmentModal}
                      data={this.state.nextPayrunAmendmentList}
                      claim={payrunAttachNextPayrunAmendmentModalClaim}
                      handleAttachAmendment={this.handleAttachAmendment}
                      attachLoading={attachLoading}
                    />
                  }
                />
              </React.Fragment>
            }
          />
        }
      />
    );
  }
}

export default withRouter(PayrunDetail);
