//@ts-nocheck
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { countriesLookup } from "../../actions/lookup";
import _ from "lodash";
import { Select } from "antd";
import { errorDisplay, mustBeArray } from "../../../libs/utilities";
import axios from "axios";
import MultiFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/MultiFieldDropdownFilter";

const Option = Select.Option;

interface CountryFilterProps {
  name?: string;
  claims?: any;
  className?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  handleClear?: (e: any) => void;
  value?: any;
  defaultValue?: any;
  onSelect?: (e: any) => void;
  filterOption?: boolean;
  searchBy: string;
  valueParam?: string;
  showSearch?: boolean;
  nameParam?: string;
  ignoreStatus?: boolean;
  canBeEmpty?: boolean;
  updateCountryList?: (e: any) => void;
  selectMultiple?: boolean;
  selectedCountry?: string | undefined | null;
  selectedCountries?: Array<any>;
}

const CountryFilters = (props: CountryFilterProps) => {
  const signal = axios.CancelToken.source();
  const {
    countriesLookup,
    updateCountryList,
    selectMultiple,
    onChange,
    handleClear,
    selectedCountries,
    selectedCountry,
    claims,
    name,
    className,
  } = props;

  const [countries, setCountries] = useState<Array<any>>([]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = () => {
    countriesLookup({ options: {}, cancelToken: signal.token }).then(
      (response) => {
        if (response.status) {
          setCountries(mustBeArray(response.data));
          if (updateCountryList) {
            updateCountryList(response.data);
          }
        } else {
          errorDisplay(
            response && response.data && response.data.validationErrors
          );
        }
      }
    );
  };
  return selectMultiple ? (
    <MultiFieldDropdownFilter
      className={`${className || ""} w-[300px] mr-2.5`}
      placeholder={"Country"}
      onChange={onChange}
      filterOption={true}
      value={selectedCountries}
      searchBy={"value"}
      showSearch={true}
      ignoreStatus={true}
      valueParam={"id"}
      nameParam={"value"}
      options={mustBeArray(countries)}
    />
  ) : (
    <Select
      placeholder="Country"
      showSearch
      onSelect={onChange}
      value={selectedCountry}
      className={`rounded-[20px] ${className || ""} w-[200px] mr-2.5`}
      optionFilterProp="name"
      autoComplete="none"
    >
      {mustBeArray(countries).map((country, key) => (
        <Option key={key} value={country.id} name={country.value}>
          {country.value}
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { countriesLookup };

export default connect(mapStateToProps, mapDispatchToProps)(CountryFilters);
