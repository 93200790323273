//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import { generalAmendmentHandler } from "../actions/generalAmendment";
import { cdnLookUpHandler } from "../actions/lookup";
import {
  listAmendmentTypes,
  getAmendment,
  updateAmendment,
  addAmendment,
  getAmendmentHistory,
  deleteAmendment,
  listPayrun,
  getPayrun,
} from "../actions/payrun";
import { getPayschedule } from "../actions/payschedule";
import {
  listClientsEmployees,
  listBusinessUnits,
} from "../actions/businessunits";
import axios from "axios";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import PayRunStatus from "../../Common/payrunStatus";
import { withRouter } from "../../hooks";

class GeneralAmendmentContainer extends SwiftContainer {
  signal = axios.CancelToken.source();

  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        pageClaims={pageClaims}
        {...this.props}
        match={{ params: this.props.router.params }}
        listPayrunAmendments={({ options }: any) => {
          return this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "amendments",
                StatusID: PayRunStatus.getStatusID({
                  status: "amendments",
                }),
              },
            },
          });
        }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payrunAmendmentList:
    (state.generalAmendment &&
      state.generalAmendment.payrunAmendment &&
      state.generalAmendment.payrunAmendment.list) ||
    [],

  payrunAmendmentCount:
    (state.generalAmendment &&
      state.generalAmendment.payrunAmendment &&
      state.generalAmendment.payrunAmendment.count) ||
    [],

  adhocAmendmentList:
    (state.generalAmendment &&
      state.generalAmendment.adhocAmendment &&
      state.generalAmendment.adhocAmendment.list) ||
    [],

  adhocAmendmentCount:
    (state.generalAmendment &&
      state.generalAmendment.adhocAmendment &&
      state.generalAmendment.adhocAmendment.count) ||
    [],

  reoccurringAmendmentList:
    (state.generalAmendment &&
      state.generalAmendment.reoccurringAmendment &&
      state.generalAmendment.reoccurringAmendment.list) ||
    [],

  reoccurringAmendmentCount:
    (state.generalAmendment &&
      state.generalAmendment.reoccurringAmendment &&
      state.generalAmendment.reoccurringAmendment.count) ||
    [],

  member: state.member,
});

const mapDispatchToProps = {
  generalAmendmentHandler,
  updateHeader,
  listAmendmentTypes,
  listClientsEmployees,
  updateAuthorizationClaim,
  listBusinessUnits,
  getAmendment,
  updateAmendment,
  getAmendmentHistory,
  deleteAmendment,
  listPayrun,
  getPayrun,
  getPayschedule,
  cdnLookUpHandler,
  addAmendment,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralAmendmentContainer)
);
