import { PersonaRole } from '@xemplo/gp-types';

export const AuthorisationModule = {
  menu: '0',
  dashboard: '1',
  company: '2',
  businessUnit: '3',
  payrun: '4',
  paySchedule: '5',
  payrollProvider: '6',
  user: '7',
  billingProvider: '8',
  invoice: '9',
  variance: '10',
  amendment: '11',
  emailTemplate: '12',
} as const;

export type AuthorizationModule =
  (typeof AuthorisationModule)[keyof typeof AuthorisationModule];

export type ModuleAuthorisationInfo = {
  id: string;
  componentId: string;
  name: string;
  visible: boolean;
  disabled: boolean;
};

export type ModuleAuthorisation = {
  roleName: PersonaRole;
  auth: ModuleAuthorisationInfo[];
};
