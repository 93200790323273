//@ts-nocheck
import React, { Component } from "react";
import { mustBeArray } from "../../../libs/utilities";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Row, Col, Select } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

class BillingServiceProviderAccountForm extends Component {
  render() {
    const { form, currencyList, bankList } = this.props;
    //activeIndex, fetchCurrency, fetchBank,
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label="Account Name">
              {getFieldDecorator("accountName", {
                rules: [
                  {
                    required: true,
                    message: "Please enter account name",
                  },
                ],
              })(
                <Input
                  placeholder={`Please enter account name`}
                  maxLength={128}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label="Bank">
              {getFieldDecorator("bankID", {
                rules: [
                  {
                    required: true,
                    message: "Please select bank",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select bank"
                  filterOption={true}
                  optionFilterProp={"name"}
                  // onSearch={fetchBank}
                  className="w-full "
                >
                  {mustBeArray(bankList).map((d: any) => (
                    <Option key={d.value} name={d.value} value={d.id}>
                      {d.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem label="BSB">
              {getFieldDecorator("bsb", {
                rules: [
                  {
                    required: true,
                    message: "Please enter bsb",
                  },
                ],
              })(<Input placeholder={`Please enter bsb`} maxLength={10} />)}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Account Number">
              {getFieldDecorator("accountNumber", {
                rules: [
                  {
                    required: true,
                    message: "Please enter account number",
                  },
                ],
              })(
                <Input
                  placeholder={`Please enter account number`}
                  maxLength={10}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}></Row>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label="Currency">
              {getFieldDecorator("currencyID", {
                rules: [
                  {
                    required: true,
                    message: "Please select currency types",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select currency"
                  filterOption={true}
                  optionFilterProp="name"
                  // onSearch={fetchCurrency}
                  className="w-full "
                >
                  {mustBeArray(currencyList).map((d: any) => (
                    <Option key={d.id} name={d.value} value={d.id}>
                      {d.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem label="IBAN">
              {getFieldDecorator("iban", {
                rules: [
                  {
                    required: false,
                    message: "Please enter IBAN",
                  },
                ],
              })(<Input placeholder={`Please enter IBAN`} maxLength={34} />)}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="SWIFT">
              {getFieldDecorator("swift", {
                rules: [
                  {
                    required: false,
                    message: "Please enter SWIFT",
                  },
                ],
              })(<Input placeholder={`Please enter SWIFT`} maxLength={10} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default BillingServiceProviderAccountForm;
