import { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { AriaButtonProps, useButton } from '@react-aria/button';
import { useSearchField } from '@react-aria/searchfield';
import { useSearchFieldState } from '@react-stately/searchfield';
import classnames from 'classnames';

import { DawnCross16, DawnSearch24 } from '@xemplo/icons';
import { InputFieldSize } from '@xemplo/input-utils';

import * as S from './search-field.styles';
import { SearchFieldProps, SearchFieldRef } from './search-field.types';

export const SearchFieldTestId = {
  container: (testId?: string) => `search-field-container-${testId}`,
  input: (testId?: string) => `search-field-input-${testId}`,
  clearButton: (testId?: string) => `search-field-clear-button-${testId}`,
};

/** SearchField size to iconSize (px) mapping */
export const SearchIconSizeMap = {
  [InputFieldSize.Standard]: 24,
  [InputFieldSize.Small]: 16,
  [InputFieldSize.Medium]: 20,
};

/** @deprecated Use 'SearchFieldV2' from '@xemplo/search-field' instead. Deprecated since FD-1230 */
export const SearchField = forwardRef(
  (props: SearchFieldProps, ref: ForwardedRef<SearchFieldRef>) => {
    const {
      clearButton = true,
      width = '272px',
      inputSize = InputFieldSize.Standard,
      isDisabled,
      isReadOnly,
      className,
      testId,
      leadingIcon = (
        <DawnSearch24
          height={SearchIconSizeMap[inputSize]}
          width={SearchIconSizeMap[inputSize]}
        />
      ),
    } = props;
    const state = useSearchFieldState(props);
    const inputRef = useRef<HTMLInputElement>(null);
    const { inputProps, clearButtonProps } = useSearchField(props, state, inputRef);

    /** Trailing icon is converted to a clear field button */
    const trailingIcon = useMemo(() => {
      if (!clearButton || !state.value) return null;
      return (
        <ClearButton
          {...clearButtonProps}
          data-testid={SearchFieldTestId.clearButton(testId)}
        />
      );
    }, [clearButton, state.value, clearButtonProps, testId]);

    // Since the state is managed by react-aria, we don't have control over the input value
    // So we expose a clearField function to give the ability clear the input field from a parent component.
    useImperativeHandle(ref, () => ({ clearField: () => state.setValue('') }));

    return (
      <S.Container
        data-testid={SearchFieldTestId.container(testId)}
        $inputSize={inputSize}
        $width={width}
        className={classnames(
          className,
          { 'input-disabled': isDisabled },
          { 'input-readonly': isReadOnly }
        )}
        onClick={() => inputRef.current?.focus()}
      >
        {leadingIcon}
        <S.Input
          {...inputProps}
          ref={inputRef}
          data-testid={SearchFieldTestId.input(testId)}
        />
        {trailingIcon}
      </S.Container>
    );
  }
);

export default SearchField;

/** Clear Search field button component */
function ClearButton(props: AriaButtonProps<'button'>) {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <S.ClearButton {...buttonProps} ref={ref}>
      <DawnCross16 width={16} height={16} />
    </S.ClearButton>
  );
}
