//@ts-nocheck
import React from "react";
import { Row, Col, Card, Button, Popconfirm } from "antd";
import _ from "lodash";
import EditLinkButton from "../../../Common/ClaimComponents/Button/EditLinkButton";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { formatDisplayTime } from "../../../libs";
import { CloseOutlined } from "@ant-design/icons";
import { If } from "../../../Common/ui";

const PayScheduleBasicDetails = (props: any) => {
  const {
    data,
    edit,
    claims,
    claimComponent,
    hasNoComponents,
    handleDelete,
    deleteComponent,
  } = props;
  return (
    data && (
      <Card
        title={`Pay Schedule Details`}
        bordered={false}
        extra={
          <If
            condition={claimComponent}
            then={
              <React.Fragment>
                <EditLinkButton
                  onClick={edit}
                  name={claimComponent}
                  claims={claims}
                  hasNoComponents={
                    _.isUndefined(hasNoComponents) ? true : hasNoComponents
                  }
                />
                <If
                  condition={deleteComponent}
                  then={
                    <Popconfirm
                      placement="bottomLeft"
                      title={
                        "Are you sure you want to delete this pay schedule?"
                      }
                      onConfirm={() => handleDelete()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="link"
                        className={`color-danger button-link rounded-[20px]`}
                      >
                        <CloseOutlined type={"delete"} />
                        Delete
                      </Button>
                    </Popconfirm>
                  }
                />
              </React.Fragment>
            }
            else={<React.Fragment />}
          />
        }
        className="card"
      >
        <Row className="p-1" gutter={DEFAULT_GUTTER}>
          <Col md={14}>
            <ul className="list-unstyled mb-0">
              <li>
                <b>Amendments Due</b> <br />
                {data.amendmentDueInDays} days before the pay day by{" "}
                {formatDisplayTime(data.amendmentDueTime)}
              </li>
              <li>
                <b>Pay Run Preparation Due</b> <br />
                {data.payRunPreparationDueInDays} days before the pay day by{" "}
                {formatDisplayTime(data.payRunPreparationDueTime)}
              </li>
              <li>
                <b>Approval Due</b> <br />
                {data.approvalDueInDays} days before the pay day by{" "}
                {formatDisplayTime(data.approvalDueTime)}
              </li>
            </ul>
          </Col>
          <Col md={10}>
            <ul className="list-unstyled mb-0">
              <li>
                <b>Business Model:</b> <br />
                {data.payrollModel}
              </li>
              <li>
                <b>Payment Due:</b> <br />
                {Math.abs(Number(data.paymentDueInDays))} days{" "}
                {Number(data.paymentDueInDays) < 0 ? "after" : "before"} the pay
                day by {formatDisplayTime(data.paymentDueTime)}
              </li>
            </ul>
          </Col>
        </Row>
      </Card>
    )
  );
};

export default PayScheduleBasicDetails;
