import React from "react";
import { Tooltip } from "antd";
import { mustBeArray, parseItems, isEmpty, getFullName } from "../../../libs";

type Props = {
  assignTo?: "All" | string; // JSON stringified array of user objects
};

const AppliesTo = ({ assignTo }: Props) => {
  if (assignTo === "All") return <div>All</div>;
  let userLists = mustBeArray(parseItems(assignTo));

  if (isEmpty(userLists)) return <div>-</div>;
  if (userLists.length === 1) {
    return (
      <div>
        {getFullName({
          name: userLists?.[0],
        })}
      </div>
    );
  }
  let allUsers: any = [];
  userLists.forEach((o) =>
    allUsers.push(
      getFullName({
        name: o,
      })
    )
  );
  let userTooltip = allUsers.join(", ");

  return (
    <Tooltip title={userTooltip} placement="bottom">
      <div>Multiple</div>
    </Tooltip>
  );
};

export default AppliesTo;
