import { PropsWithChildren } from 'react';
import { createCalendar } from '@internationalized/date';
import { AriaButtonProps } from '@react-aria/button';
import { AriaCalendarProps, DateValue, useCalendar } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { useCalendarState } from '@react-stately/calendar';
import type { PressEvent } from '@react-types/shared';

import { IconButton, IconButtonSize } from '@xemplo/icon-button';
import { DawnArrowLeft16, DawnArrowRight16 } from '@xemplo/icons';

import * as S from './calendar.styles';
import { CalendarGrid } from './grid';

export enum CalendarTestId {
  Container = 'calendar-container',
  Grid = 'calendar-grid',
  Cell = 'calendar-cell',
  RightArrow = 'calendar-right-arrow',
  LeftArrow = 'calendar-left-arrow',
  Title = 'calendar-title',
}

export function Calendar<T extends DateValue>(props: AriaCalendarProps<T>) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(
    props,
    state
  );

  return (
    <S.Container {...calendarProps} tabIndex={0} data-testid={CalendarTestId.Container}>
      <S.Header>
        <NavigationButton {...prevButtonProps} side="left">
          <DawnArrowLeft16 />
        </NavigationButton>
        <S.Title data-testid={CalendarTestId.Title}>{title}</S.Title>
        <NavigationButton {...nextButtonProps} side="right">
          <DawnArrowRight16 />
        </NavigationButton>
      </S.Header>
      <CalendarGrid state={state} />
    </S.Container>
  );
}
export default Calendar;

function NavigationButton({
  side,
  children,
  ...props
}: PropsWithChildren<AriaButtonProps & { side: 'left' | 'right' }>) {
  return (
    <IconButton
      {...props}
      size={IconButtonSize.Small}
      naked
      ariaLabel={`Calendar ${side} arrow`}
      id={side === 'left' ? CalendarTestId.LeftArrow : CalendarTestId.RightArrow}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.onPress?.(e as unknown as PressEvent);
      }}
    >
      {children}
    </IconButton>
  );
}
