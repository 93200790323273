//@ts-nocheck
import React from "react";
import InputTypes from "./inputTypes";
import { ArrowRightOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";

const questionDetails = ({
  question,
  index,
  inputValueChange,
  total,
  updateActiveKey,
  uploadAmendmentFile,
  dropDownWithTextChange,
  holidays,
}: any) => {
  return question ? (
    <React.Fragment>
      <Card className="card card-space-24">
        <div className="font-bold text-lg mb-8">{`Question ${index + 1}: ${
          question && question.label
        }`}</div>
        <div>
          <div className="font-medium mb-3">{question && question.label}</div>
          <InputTypes
            uploadAmendmentFile={uploadAmendmentFile}
            inputValueChange={inputValueChange}
            dropDownWithTextChange={dropDownWithTextChange}
            question={question}
            index={index}
            holidays={holidays}
          />
        </div>
      </Card>
      <div className="mt-4">
        {index !== 0 && (
          <Button
            icon={<LeftOutlined />}
            className="float-left rounded-[20px]"
            onClick={() => updateActiveKey("back")}
          >
            Back
          </Button>
        )}
        {index < total - 1 && (
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() => updateActiveKey("next")}
            className="bg-success button-right-icon float-right rounded-[20px]"
          >
            Continue &nbsp;{" "}
          </Button>
        )}
        <div className="clearfix" />
      </div>
    </React.Fragment>
  ) : (
    <div />
  );
};
export default questionDetails;
