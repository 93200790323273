import { SVGProps } from 'react';
const DawnAddPlus16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 2.75a.75.75 0 0 0-1.5 0V7h-4.5a.75.75 0 0 0 0 1.5h4.5v4.75a.75.75 0 0 0 1.5 0V8.5h4.5a.75.75 0 0 0 0-1.5h-4.5V2.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnAddPlus16;
