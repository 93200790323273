import styled from 'styled-components';

import { BodyStandardMedium, Colour, Shadow } from '@xemplo/style-constants';

export const Container = styled.div`
  background: ${Colour.White[100]};
  border-radius: 8px;
  ${Shadow.Medium1};
  border: 1px solid ${Colour.Gray[100]};
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 272px;
  outline: none;
  user-select: none;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0px;
  justify-content: space-between;
  padding: 16px 8px 0;
`;

export const Title = styled.span`
  ${BodyStandardMedium};
  color: ${Colour.Gray[700]};
`;
