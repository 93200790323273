import React from "react";
import { Tabs, TabsProps } from "antd";
import { getClaimAndStatus } from "../../libs/utilities";

export interface MultiTabContainerProps {
  activeKey?: string;
  name?: string;
  claims?: any;
  className?: string;
  handleTabChange: (e: any) => void;
  ignoreClaims?: boolean;
  children?: React.ReactNode;
  animated?: boolean;
  extraContent?: TabsProps["tabBarExtraContent"];
}

const MultiTabContainer: React.FC<MultiTabContainerProps> = ({
  claims,
  activeKey,
  children,
  handleTabChange,
  name,
  className,
  animated,
  ignoreClaims,
  extraContent,
}) => {
  const multitabClaimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <Tabs
      size="small"
      activeKey={activeKey}
      onChange={handleTabChange}
      animated={animated || false}
      className={`${className} ${
        ignoreClaims ? "" : multitabClaimAndStatus.status
      }`}
      tabBarExtraContent={extraContent}
    >
      {children}
    </Tabs>
  );
};

export default MultiTabContainer;
