import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import HandleProgressBar from "../Common/progressBar";
import { LazyNavbar } from "../Organization/components/common/Header";
import { AppSidebar } from "../User/components";
import styled from "styled-components";
import { Colour, MinBreakpoint } from "@xemplo/style-constants";

const StyledWrapper = styled.div`
  background: ${Colour.White[100]};
  height: 100vh;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  @media ${MinBreakpoint.mediumV2} {
    flex-direction: row;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const V2FeatureTemplate = () => {
  return (
    <Fragment>
      <HandleProgressBar />
      <StyledWrapper>
        <LazyNavbar />
        <ContentWrapper>
          <AppSidebar />
          <MainWrapper>
            <main
              id="body-wrapper"
              className="overflow-y-auto bg-gray-100 flex-1 content-height"
            >
              <Outlet />
            </main>
          </MainWrapper>
        </ContentWrapper>
      </StyledWrapper>
    </Fragment>
  );
};
