import { useParams } from 'react-router';

import { Button, ButtonSize, ButtonType } from '@xemplo/button';
import { CreateAmendment } from '@xemplo/create-amendment';
import { isPastDate } from '@xemplo/date-time';
import { PAYRUN_COMPONENTS, ROLE_AUTHORISATION_MODULE_IDS } from '@xemplo/gp-api';
import {
  PAYRUN_STATUS_IDS,
  pendingAmendmentsStatuses,
  reviewStatuses,
} from '@xemplo/gp-types';
import { DawnAddPlus16 } from '@xemplo/icons';
import { PayrunApproveReject } from '@xemplo/payrun-approve-reject';
import { PayrunDownloadReports } from '@xemplo/payrun-download-reports';
import { PayrunPayInvoice, useShowPayInvoice } from '@xemplo/payrun-pay-invoice';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';
import { PayrunSubmitAmendments } from '@xemplo/payrun-submit-amendments';
import { useRoleAuthorisation } from '@xemplo/roles-query';

export const PayrunActionButtons = () => {
  const { id: payrunId } = useParams();
  const { data: payrunData, isLoading } = useGetPayrunByIdQuery({ id: payrunId ?? null });
  const { componentVisible } = useRoleAuthorisation(ROLE_AUTHORISATION_MODULE_IDS.payrun);
  const { ammendmentDueDateTime, payrunStatusID, businessUnitID } =
    payrunData?.result ?? {};
  const showCreateAmendment =
    !isPastDate(ammendmentDueDateTime) &&
    !isLoading &&
    componentVisible(PAYRUN_COMPONENTS.AMENDMENT_CREATE) &&
    pendingAmendmentsStatuses.includes(payrunStatusID as PAYRUN_STATUS_IDS);

  const showSubmitAmendments =
    pendingAmendmentsStatuses.includes(payrunStatusID as PAYRUN_STATUS_IDS) && !isLoading;

  const showApproveReject =
    reviewStatuses.includes(payrunStatusID as PAYRUN_STATUS_IDS) && !isLoading;

  const { showPayInvoice, isLoading: invoiceLoading } = useShowPayInvoice();
  return (
    <>
      {showCreateAmendment && (
        <CreateAmendment
          payrunModeValues={{ payrunId: payrunId, businessUnitId: businessUnitID }}
          renderButton={(handleClick) => (
            <Button
              ariaLabel="Add new amendment"
              type={ButtonType.Secondary}
              size={ButtonSize.Small}
              icon={<DawnAddPlus16 />}
              onClick={handleClick}
            >
              New Amendment
            </Button>
          )}
        />
      )}
      {showSubmitAmendments && <PayrunSubmitAmendments />}
      {showApproveReject && <PayrunApproveReject />}
      {!invoiceLoading && (
        <>
          <PayrunDownloadReports
            secondary={showPayInvoice}
            payrunStatusId={payrunData?.result?.payrunStatusID}
          />
          {showPayInvoice && <PayrunPayInvoice />}
        </>
      )}
    </>
  );
};
