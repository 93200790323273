import { ChangeEvent, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTextField } from '@react-aria/textfield';
import classNames from 'classnames';

import { DawnTriangleAlert } from '@xemplo/icons';
import {
  InputFieldSize,
  LabelHelperText,
  MaybeErrorOrDescription,
} from '@xemplo/input-utils';

import * as S from './text-area-v2.styles';
import { TextAreaV2Props } from './text-area-v2.types';
export const TextAreaV2TestId = {
  container: (testId?: string) => `textarea-container-${testId}`,
  input: (testId?: string) => `textarea-input-${testId}`,
  label: (testId?: string) => `textarea-label-${testId}`,
  labelHelperText: (testId?: string) => `textarea-label-helper-text-${testId}`,
};

export function TextAreaV2(props: TextAreaV2Props) {
  const {
    width,
    label,
    inputSize = InputFieldSize.Standard,
    leadingIcon,
    trailingIcon,
    description,
    isDisabled,
    isReadOnly,
    testId,
    onChange,
    rows,
    stretch,
    maxHeight,
    name,
    rules,
  } = props;

  const { register, watch, getFieldState, formState, control } = useFormContext();

  const isRequired = !!control._fields[name]?._f.required;

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const reactHookFormProps = register(name, {
    ...rules,
    disabled: isDisabled,
    onChange,
  });
  const inputValue = watch(name);

  const { invalid, error } = getFieldState(name, formState);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(
    { ...props, inputElementType: 'textarea', isRequired },
    inputRef
  );

  if (inputRef.current) {
    inputRef.current.style.maxHeight = maxHeight ?? '200px';
  }

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    stretch && resizeTextarea();
  };

  const resizeTextarea = () => {
    const textarea = inputRef.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <S.Wrapper $width={width}>
      <S.Container
        className={classNames(
          { 'input-disabled': isDisabled },
          { 'input-readonly': isReadOnly },
          { 'input-has-error': invalid && !isDisabled },
          { 'has-label': !!label },
          { 'has-value': !!inputValue }
        )}
        data-testid={TextAreaV2TestId.container(testId)}
        $inputSize={inputSize}
        onClick={() => inputRef.current?.focus()}
      >
        {leadingIcon}

        <S.TextArea
          {...inputProps}
          className={classNames(
            { 'has-label': !!label },
            { 'has-value': !!inputValue },
            { 'input-readonly': isReadOnly }
          )}
          {...reactHookFormProps}
          ref={(e) => {
            reactHookFormProps.ref(e);
            inputRef.current = e;
          }}
          onChange={(e) => {
            reactHookFormProps.onChange(e);
            inputProps.onChange?.(e);
          }}
          readOnly={isReadOnly}
          onInput={handleInput}
          data-size={inputSize}
          data-testid={TextAreaV2TestId.input(testId)}
          rows={rows}
        />
        <S.Label
          {...labelProps}
          className={classNames(
            { 'has-label': !!label },
            { 'has-value': !!inputValue },
            { 'has-leading-icon': !!leadingIcon }
          )}
          data-testid={TextAreaV2TestId.label(testId)}
        >
          {label}
          {!isRequired && (
            <LabelHelperText
              className="label-helper-text"
              data-testid={TextAreaV2TestId.labelHelperText(testId)}
            >
              (optional)
            </LabelHelperText>
          )}
        </S.Label>
        {invalid ? <DawnTriangleAlert /> : trailingIcon}
      </S.Container>
      {/**Error message takes precedence over helper text / description */}
      <MaybeErrorOrDescription
        error={invalid}
        errorMessage={error?.message ?? inputRef.current?.validationMessage}
        errorMessageProps={errorMessageProps}
        description={description}
        descriptionProps={descriptionProps}
        testId={testId}
      />
    </S.Wrapper>
  );
}
