export interface ButtonProps {
  size?: ButtonSize;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  ariaLabel: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  variant?: ButtonVariant;
  type: ButtonType;
  underline?: boolean;
  testId?: string;
}
export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Destructive = 'destructive',
  Tertiary = 'tertiary',
}

export enum ButtonVariant {
  Dark = 'dark',
  Light = 'light',
  Blue = 'blue',
  Error = 'error',
}
export const validButtonTypes = {
  [ButtonType.Primary]: [ButtonVariant.Light],
  [ButtonType.Secondary]: [ButtonVariant.Dark, ButtonVariant.Light],
  [ButtonType.Destructive]: [ButtonVariant.Light],
  [ButtonType.Tertiary]: [
    ButtonVariant.Dark,
    ButtonVariant.Blue,
    ButtonVariant.Error,
    ButtonVariant.Light,
  ],
};
export interface ButtonWithStateProps extends ButtonProps {
  onClickAsync?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>;
}

export const ButtonSize = {
  Large: 'large',
  Standard: 'standard',
  Medium: 'medium',
  Small: 'small',
} as const;

export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize];
export enum ButtonIconPosition {
  Leading = 'leading',
  Trailing = 'trailing',
}
