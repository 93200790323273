import React from "react";
import { Card } from "antd";
import classNames from "classnames";
import {
  displayDates,
  nullFunction,
  getPayrunStages,
  getPayrunDueDays,
  getDueDaysText,
} from "../../../libs";
import { SCHEDULED_PAYRUN_ID } from "../../../constants/payrun";

type PayRunSingleLineGridProps = {
  content: any;
  handleAction: (options: any) => void;
};

const PayRunSingleLineGrid: React.FC<PayRunSingleLineGridProps> = ({
  content,
  handleAction,
}) => {
  const dueDates: any = getPayrunDueDays({
    payrun: content,
  });

  return (
    <Card
      className="bg-default hand rounded-[12px]"
      onClick={(event) =>
        handleAction({ data: content, action: "view", event })
      }
    >
      <div className="flex justify-between">
        <div className="flex-1-1">
          <div className="text-[15px] font-bold">{content?.name}</div>
          <div className="color-disabled text-sm">
            {content?.payrunTypeName}
          </div>
          {content.payrunTypeID === SCHEDULED_PAYRUN_ID && (
            <div className="color-disabled text-sm">
              {`${displayDates({
                value: content?.payCycleStartDate,
              })} - ${displayDates({ value: content?.payCycleEndDate })}`}
            </div>
          )}
        </div>
        <div className="text-sm flex-1-1">
          <div>
            <span className="font-bold">Pay Date: </span>
            <span>{displayDates({ value: content?.payDate })}</span>
          </div>
          <div>
            <span className="font-bold">Amendments: </span>
            <span
              className={classNames({
                "color-primary hand": content?.ammendmentCount,
              })}
              onClick={(event) => {
                content?.ammendmentCount
                  ? handleAction({
                      event,
                      data: content,
                      action: "viewAmendmentSummary",
                    })
                  : nullFunction();
              }}
            >
              {content?.ammendmentCount}
            </span>
          </div>
        </div>
        <div className="text-right text-sm flex-1-1">
          <div>{getPayrunStages({ payrun: content })}</div>
          {dueDates.days && (
            <div className="font-bold text-[15px]">
              {getDueDaysText({ days: dueDates.days })}
            </div>
          )}
          {dueDates.date && (
            <div className="font-bold text-[13px] color-disabled">
              {dueDates.date}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default PayRunSingleLineGrid;
