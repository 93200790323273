//@ts-nocheck
import React from "react";
import PayrollProviderList from "../../components/payrollProviderList";
import CountryLookup from "../../components/lookup/country";
import { message } from "antd";
import _ from "lodash";
import {
  mustBeArray,
  findAuthorizationClaims,
  removeUndefined,
  errorDisplay,
  delay,
  updateAuthorizationClaims,
  setPersonalizationData,
  getPersonalizationData,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  checkClaimsAuthorization,
  trimWhiteSpaces,
  isComponentVisible,
} from "../../../libs/utilities";
import {
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
  GRID_VIEW_VALUE,
  INTEGER_PERSONALIZATION,
  STRING_PERSONALIZATION,
} from "../../../constants/defaultClaims";
import NoRecord from "../../../Common/NoRecord";
import ContentEmpty from "../../components/common/contentListEmpty";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import { FILTER_ACTIVE_INACTIVE_OPTIONS } from "../../../constants/options";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import GridTableViewOption from "../../components/common/gridTableViewOption";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import axios from "axios";
import {
  PAYROLL_PROVIDER_FIELD_OPTIONS,
  PAYROLL_PROVIDER_GRID_OPTIONS,
} from "../../../constants/sortingOptions";
import ScrollablePayrollProviderTable from "../../components/payrollProvider/ScrollablePayrollProviderTable";
import { updateDomChanges, validateUsersAccessRights } from "../../../libs";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  PAYROLL_PROVIDER_CREATE_SIMPLE_BUTTON,
  PAYROLL_PROVIDER_FILTER_CONTAINER,
  PAYROLL_PROVIDER_LIST_CONTAINER,
} from "../../../constants";
import { withRouter } from "../../../hooks";

type State = any;

class PayrollIndex extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: PAYROLL_PROVIDER_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: PAYROLL_PROVIDER_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
  }

  componentDidMount() {
    const { pageClaims } = this.props;
    //validate if user has access to page
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    this.props.updateHeader({
      header: {
        title: "Payroll Service Providers",
        module: "",
        enableBack: false,
        entity: "payroll service provider",
        action: checkClaimsAuthorization({
          component: PAYROLL_PROVIDER_CREATE_SIMPLE_BUTTON,
          claims: this.props.pageClaims?.components,
        }),
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderList,
      },
    });
    const payrollProviderGeneralTable = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: "payrollProviderGeneralTable",
    });
    const payrollProviderGeneralGrid = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: "payrollProviderGeneralGrid",
    });
    const payrollProviderCountryDropdownFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: "payrollProviderCountryDropdownFilter",
    });
    const payrollProviderNameTextboxFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: "payrollProviderNameTextboxFilter",
    });
    const payrollProviderStatusDropdownFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: "payrollProviderStatusDropdownFilter",
    });
    const payrollProviderListContainer = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: PAYROLL_PROVIDER_LIST_CONTAINER,
    });
    // Get personalization
    const payrollProviderPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: payrollProviderListContainer?.personalizations,
    });
    const nameTextBoxPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: payrollProviderNameTextboxFilter?.personalization,
    });
    const countryPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData:
        payrollProviderCountryDropdownFilter?.personalization,
    });
    const isActivePersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: payrollProviderStatusDropdownFilter?.personalization,
    });

    this.setState({
      filters: {
        name: nameTextBoxPersonalizations?.value,
        countryId: countryPersonalizations?.value
          ? Number(countryPersonalizations.value)
          : undefined,
        isActive: isActivePersonalizations?.value
          ? Number(isActivePersonalizations.value)
          : 0,
      },
      paginationType: payrollProviderPersonalizations?.paginationType,
      sortingType: payrollProviderPersonalizations?.sortingType,
      isPinned: payrollProviderPersonalizations?.isPinned,
      gridable: payrollProviderListContainer?.gridable,
    });
    // if grid
    if (isComponentVisible({ claim: payrollProviderGeneralGrid })) {
      const payrollProviderGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: payrollProviderGeneralGrid.personalizations,
      });
      const pageNumber = payrollProviderGridPersonalizations?.pageNumber
        ? Number(payrollProviderGridPersonalizations.pageNumber)
        : 1;
      const row = payrollProviderGridPersonalizations?.row
        ? Number(payrollProviderGridPersonalizations.row)
        : 5;
      const col = payrollProviderGridPersonalizations?.col
        ? Number(payrollProviderGridPersonalizations.col)
        : 4;
      const SortOrder = payrollProviderGridPersonalizations?.sortOrder ?? null;
      const SortColumn =
        payrollProviderGridPersonalizations?.sortColumn ?? null;

      this.setState(
        {
          grid: {
            page: pageNumber ? Number(pageNumber) : 1,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          selectedGridSort:
            _.find(this.state.gridSortOption, (o) => o.label === SortColumn) &&
            _.find(this.state.gridSortOption, (o) => o.label === SortColumn).id,
        },
        () => {
          if (payrollProviderPersonalizations?.gridView) {
            this.setState({ gridView: true });
            this.fetchMoreData({ isPaginated: true });
            if (
              payrollProviderGridPersonalizations?.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridPayrollProvider({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.fetchMoreData({ isPaginated: true, notUpdate: true });
            }
          }
        }
      );
    }
    // if table
    if (isComponentVisible({ claim: payrollProviderGeneralTable })) {
      const payrollProviderTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: payrollProviderGeneralTable.personalizations,
      });
      const pageNumber = payrollProviderTablePersonalizations?.pageNumber
        ? Number(payrollProviderTablePersonalizations.pageNumber)
        : 1;
      const pageSize = payrollProviderTablePersonalizations?.pageSize
        ? Number(payrollProviderTablePersonalizations.pageSize)
        : 20;
      const SortOrder = payrollProviderTablePersonalizations?.sortOrder ?? null;
      const SortColumn =
        payrollProviderTablePersonalizations?.sortColumn ?? null;
      this.setState(
        {
          per_page:
            payrollProviderPersonalizations?.paginationType ===
            PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : pageSize
              ? Number(pageSize)
              : 20,
          current: pageNumber ? Number(pageNumber) : 1,
          sortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!payrollProviderPersonalizations.gridView) {
            this.setState({ gridView: false });
            if (
              payrollProviderPersonalizations?.paginationType ===
              PAGINATION_NOT_REQUIRED
            ) {
              this.listScrollingPayrollProvider({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.listPayrollProviders({ options: {}, notUpdate: true });
            }
          }
        }
      );
    }
  }

  listScrollingGridPayrollProvider = ({ pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;
    this.setState(
      {
        listLoading: true,
        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid?.page) + 1
            : Number(this.state.grid?.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;
        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });
        let filterOptions = this.getFilters();
        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });
        this.props
          .listPayrollProviders({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
            },
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridPayrollProvider({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length;
                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization(
                          "payrollProviderGeneralGrid"
                        );
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.current = 1;
        prevState.listLoading = true;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };

        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }

        this.updateAllPersonalization();

        this.props
          .listPayrollProviders({
            options,
          })
          .then((resp) => {
            this.setState({ filterLoading: false, listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    if (Number(tempFilters.countryId) === 0) {
      delete tempFilters.countryId;
    }
    tempFilters.name = trimWhiteSpaces(tempFilters.name);
    switch (Number(tempFilters.isActive)) {
      case 0:
        delete tempFilters.isActive;
        break;
      case 1:
        tempFilters.isActive = 1;
        break;
      case 2:
        tempFilters.isActive = 0;
        break;
      default:
        break;
    }
    return tempFilters;
  };

  handleChange = (index, e) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [index]: e,
      },
    });
  };

  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  listScrollingPayrollProvider = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            prevState.total === mustBeArray(prevState.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .listPayrollProviders({ options: tempOptions })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingBusinessUnits({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],
                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("payrollProviderGeneralTable");
          }
        });
      }
    );
  };

  listPayrollProviders = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .listPayrollProviders({ options: tempOptions })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listPayrollProviders({ options: {} });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("payrollProviderGeneralTable");
          }
        });
      }
    );
  };

  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", countryId: 0, isActive: 0 },
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.props
          .listPayrollProviders({
            options: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                    paginationType: this.state.paginationType,
                    grid: this.state.grid,
                  })
                : this.state.per_page,
            },
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization("payrollProviderNameTextboxFilter", false);
    delay(300).then(() => {
      this.updatePersonalization("payrollProviderCountryDropdownFilter", false);
    });
    delay(600).then(() => {
      this.updatePersonalization("payrollProviderStatusDropdownFilter", false);
    });
    delay(900).then(() => {
      this.updatePersonalization("payrollProviderGeneralTable", false);
    });
    delay(1200).then(() => {
      this.updatePersonalization("payrollProviderGeneralGrid");
    });
  };

  fetchMoreData = ({ isPaginated = false, notUpdate }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props
      .listPayrollProviders({
        options: { page, per_page, q: { ...options }, ...sortingParams },
      })

      .then((response) => {
        if (response.status) {
          this.checkIfDeafultListEmpty({ list: response.data });

          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ grid: { ...grid, page: 1 } }, () => {
              this.fetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.list = data;
                } else {
                  prevState.list = prevState.list.concat(data);

                  if (
                    data.length !== 0 &&
                    data.length === this.state.page_size
                  ) {
                    prevState.hasMoreData = true;
                    prevState.grid = {
                      ...prevState.grid,
                      page: prevState.grid.page + 1,
                    };
                  } else {
                    prevState.hasMoreData = false;
                  }
                }

                prevState.total = response.total;
                prevState.listLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  if (!notUpdate) {
                    this.updatePersonalization("payrollProviderGeneralGrid");
                  }
                });
              }
            );
          }
        }
      });
  };

  listPayrollProviderGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid?.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .listPayrollProviders({ options: tempOptions })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrollProviderGeneralGrid");
        });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member?.details?.authorizationDOM
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];

      const payrollProviderListContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === PAYROLL_PROVIDER_LIST_CONTAINER
      );

      const payrollProviderFilterContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === PAYROLL_PROVIDER_FILTER_CONTAINER
      );

      const payrollProviderGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payrollProviderGeneralTable"
      );

      const payrollProviderGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payrollProviderGeneralGrid"
      );
      switch (field) {
        case "payrollProviderGeneralTable":
          if (payrollProviderListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,
              sortOrder: this.state.sortInfo?.order,
              sortColumn: this.state.sortInfo?.field,
              pageSize: `${this.state.per_page}`,
              personalizationData:
                payrollProviderGeneralTable &&
                payrollProviderGeneralTable.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderGeneralGrid":
          if (payrollProviderListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid?.page}`,
              row: `${grid?.row}`,
              col: `${grid?.col}`,
              sortOrder: grid?.sortInfo?.order,
              sortColumn: grid?.sortInfo?.field,
              personalizationData: payrollProviderGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderCountryDropdownFilter":
          if (payrollProviderFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters?.countryId ? this.state.filters.countryId : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderStatusDropdownFilter":
          if (payrollProviderFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = this.state.filters?.isActive;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderNameTextboxFilter":
          if (payrollProviderFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.state.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case PAYROLL_PROVIDER_LIST_CONTAINER:
          if (payrollProviderListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData:
                payrollProviderListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }

      const updateChanges = updateDomChanges({
        update,
        condition: field === "payrollProviderGeneralGrid",
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization(PAYROLL_PROVIDER_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingPayrollProvider({ pageLoad: true, options: {} });
        } else {
          this.listPayrollProviders({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPayrollProvider({
            pageLoad: true,
            options: {},
          });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPayrollProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingPayrollProvider({ pageLoad: true, options: {} });
        } else {
          this.listPayrollProviders({ options: {} });
        }
      });
    }
  };
  updateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.selectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.gridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.grid = {
            ...prevState.grid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPayrollProvider({
            pageLoad: true,
            options: {},
          });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/payrollprovider/${data && data.id}`);
        break;
      case "edit":
        break;
      case "delete":
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };
  updatePinnedComponent = () => {
    this.setState(
      { isPinned: this.state.isPinned === "true" ? "false" : "true" },
      () => {
        this.updatePersonalization(PAYROLL_PROVIDER_LIST_CONTAINER);
      }
    );
  };
  handleCreate = () => {
    this.setState({ viewPayrollProviderModal: true });
  };
  onSubmit = (values) => {
    return this.props.savePayrollProvider(values).then((resp) => {
      if (resp.status) {
        message.success("Payrollservice provider created successfully.");

        this.setState({ viewPayrollProviderModal: false });

        this.listPayrollProviders({ options: {} });
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }

      return resp;
    });
  };
  onCancel = () => {
    this.setState({ viewPayrollProviderModal: false });
  };
  checkIfDeafultListEmpty = ({ list }) => {
    const { current, grid } = this.state;

    if (
      _.isEmpty(mustBeArray(list)) &&
      _.isEmpty(this.getFilters()) &&
      checkClaimsAuthorization({
        component: PAYROLL_PROVIDER_CREATE_SIMPLE_BUTTON,

        claims: this.props.pageClaims?.components,
      }) &&
      Number(current) === 1 &&
      Number(grid?.page) === 1
    ) {
      return this.setState({ isDefaultListEmpty: true });
    }

    return this.setState({ isDefaultListEmpty: false });
  };
  render() {
    const {
      sortInfo,
      filterLoading,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      paginationType,
      current,

      gridable,
      fieldOptions,
      isPinned,
      sortingType,
      hasMoreData,
      isDefaultListEmpty,
    } = this.state;

    const { pageClaims } = this.props;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });

    return isDefaultListEmpty ? (
      <NoRecord>
        <ContentEmpty
          link="/payrollprovider/create"
          page="Payroll Service Provider"
        />
      </NoRecord>
    ) : (
      <React.Fragment>
        <div className="flex justify-between items-center flex-wrap">
          <FilterContainer
            name={PAYROLL_PROVIDER_FILTER_CONTAINER}
            claims={this.props.pageClaims}
          >
            <SingleFieldTextBoxFilter
              placeholder="Search"
              name="payrollProviderNameTextboxFilter"
              claims={this.props.claims}
              value={this.state.filters?.name}
              onChange={(value) => this.handleChange("name", value)}
              onSearch={(value) => this.handleFilter("name", value)}
              className="mb-[15px] w-[200px]"
            />
            <CountryLookup
              selectedValue={this.state.filters?.countryId}
              onSelect={(value) => this.handleChange("countryId", value)}
              name="payrollProviderCountryDropdownFilter"
              claims={this.props.claims}
              className="-ml-5"
            />
            <SingleFieldDropdownFilter
              name="payrollProviderStatusDropdownFilter"
              claims={this.props.claims}
              options={FILTER_ACTIVE_INACTIVE_OPTIONS}
              value={this.state.filters.isActive}
              defaultValue={undefined}
              onSelect={(value) => this.handleChange("isActive", value)}
              className="mb-[15px]"
            />
            <SearchIconButton
              loading={filterLoading}
              onClick={this.handleFilter}
              name="payrollProviderSearchButton"
              claims={this.props.claims}
              className="ml-3"
            />
            <LinkButton
              buttonText="Reset Filter"
              className="color-primary hand"
              onClick={() => this.resetFilters()}
              name="payrollProviderResetFilter"
              claims={this.props.claims}
            />
          </FilterContainer>
          <div className={"mb-6"}>
            <GridTableViewOption
              gridable={gridable}
              handleGridChange={this.handleGridChange}
              gridView={gridView}
              fieldOptions={fieldOptions}
              updateGridField={this.updateGridField}
              gridSortInfo={this.state.grid?.sortInfo}
              tableSortInfo={sortInfo}
              updatePageSize={this.updatePageSize}
              grid={grid}
              per_page={per_page}
            />
          </div>
        </div>
        <ListContainer
          name={PAYROLL_PROVIDER_LIST_CONTAINER}
          claims={pageClaims}
          isPinned={isPinned}
          updatePinnedComponent={this.updatePinnedComponent}
          gridable={gridable}
          gridView={gridView}
          grid={grid}
          per_page={per_page}
          GeneralTablePaginatedComponent={PayrollProviderList}
          GeneralTableScrollableComponent={ScrollablePayrollProviderTable}
          generalTableComponentName={"payrollProviderGeneralTable"}
          gridComponentName={"payrollProviderGeneralGrid"}
          createLinkButtonName={""}
          handleAddAction={() => {
            console.log("");
          }}
          handleGridChange={this.handleGridChange}
          fieldOptions={fieldOptions}
          updateGridField={this.updateGridField}
          sortingType={sortingType}
          paginationType={paginationType}
          list={list}
          listLoading={listLoading}
          listGrid={this.listPayrollProviderGrid}
          listScrollingGrid={this.listScrollingGridPayrollProvider}
          hasMoreData={hasMoreData}
          gridActionComponentName={"payrollProviderViewDetailLink"}
          viewComponent="payrollProviderViewDetailLink"
          moduleType={"payrollProvider"}
          handleGridAction={this.handleAction}
          listScrollingGeneralTable={this.listScrollingPayrollProvider}
          total={total}
          current={current}
          sortInfo={sortInfo}
          listGeneralTable={this.listPayrollProviders}
          tableActionComponentName={"payrollProviderViewDetailLink"}
          gridPageSize={getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          })}
          cardGrid={cardGrid}
          showAddAndGrid={false}
          rowIdParam="id"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PayrollIndex);
