//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Select, Spin } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import EmptyContent from "./emptyContent";

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class BaseSalary extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    const { baseSalary, formLayout, loading, label, name, required, disabled } =
      this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <FormItem label={label ? `${label}:` : "Base Pay:"} {...formLayout}>
        {getFieldDecorator(`${name ? name : "baseSalary"}`, {
          rules: [
            {
              required: required,
              message: "Base Pay is required",
            },
          ],
        })(
          <Select
            placeholder="Select"
            showSearch
            optionFilterProp={"name"}
            mode="multiple"
            notFoundContent={loading ? <Spin /> : <EmptyContent />}
            disabled={disabled}
            onChange={(e) =>
              this.props.handleFormValueChange({ value: e, field: "name" })
            }
          >
            {mustBeArray(baseSalary).map((category: any) => {
              return (
                <Option
                  key={category.id}
                  name={category.name}
                  value={category.id}
                >
                  {category.name}
                </Option>
              );
            })}
          </Select>
        )}
      </FormItem>
    );
  }
}

export default BaseSalary;
