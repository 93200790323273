//@ts-nocheck
import React, { Component } from "react";
import {
  conditionalParameter,
  currencyFormater,
  getConditionalResponse,
  isEmpty,
  mustBeArray,
} from "../../../../libs/utilities";
import { Card, List } from "antd";
import _ from "lodash";
import SummaryView from "../summaryView";
import { PREVIOUS_EXTERNAL_EARLINGLINES_INDEX } from "../../../../constants/payrun";
import { If } from "../../../../Common/ui";
import classNames from "classnames";
import PayrunSummaryFooter from "../Summary/payRunSummaryFooter";
import PayrunSummaryTotalFees from "../Summary/payrunSummaryTotalFees";
import PayrunSummaryGrossIncome from "../Summary/payrunSummaryGrossIncome";
import PayrunSummaryDeduction from "../Summary/payrunSummaryDeductions";

type State = any;

class Summary extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      summary: [],
      totalFees: [],
      grossEarnings: "",
      totalEarnings: 0,
      previousSummary: [],
      previousTotalFees: [],
      previousGrossEarnings: "",
      previousTotalEarnings: 0,
    };
  }
  componentDidMount() {
    if (this.props.summary) {
      this.getSummary(this.props.summary);
    }
  }

  getPreviousPayItems = (earningLines: any, previousTemp: any) => {
    if (
      earningLines?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
        ?.payItems
    ) {
      previousTemp = previousTemp.concat(
        earningLines.payrunSummary[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
          .payItems
      );
    }
    return previousTemp;
  };

  getTempFees = (earningLines: any, tempFees: any) => {
    if (earningLines?.fees) {
      tempFees = tempFees.concat(earningLines.fees);
    }
    return tempFees;
  };
  getPreviousTempFees = (earningLines: any, tempFees: any) => {
    if (
      earningLines?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]?.fees
    ) {
      tempFees = tempFees.concat(
        earningLines.payrunSummary[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX].fees
      );
    }
    return tempFees;
  };
  getTempSubPayItems = (earningLines: any, tempSubPayItems: any) => {
    if (earningLines?.payrollProcessing?.earningsLine) {
      tempSubPayItems = tempSubPayItems.concat(
        mustBeArray(earningLines.payrollProcessing.earningsLine)
      );
    }
    return tempSubPayItems;
  };

  getPreviousTempSubPayItems = (earningLines: any, tempSubPayItems: any) => {
    if (
      earningLines?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
        ?.payrollProcessing?.earningsLine
    ) {
      tempSubPayItems = tempSubPayItems.concat(
        earningLines.payrunSummary[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
          .payrollProcessing.earningsLine
      );
    }
    return tempSubPayItems;
  };
  getSummary = (summary: any) => {
    let temp: Array<any> = [];
    let tempFees: Array<any> = [];
    let tempSummary: Array<any> = [];
    let totalFees: Array<any> = [];
    let tempSubPayItems: Array<any> = [];
    let subPayItemList: Array<any> = [];
    let previousTemp: Array<any> = [];
    let previousTempFees: Array<any> = [];
    let previousTempSummary: Array<any> = [];
    let previousTotalFees: Array<any> = [];
    let previousTempSubPayItems: Array<any> = [];
    let previousSubPayItemList: Array<any> = [];
    if (summary) {
      summary.forEach((earningLines: any, i: any) => {
        // Pay Items
        temp = getConditionalResponse({
          condition: earningLines.payItems,
          resp1: temp.concat(earningLines.payItems),
          resp2: temp,
        });
        previousTemp = this.getPreviousPayItems(earningLines, previousTemp);
        tempFees = this.getTempFees(earningLines, tempFees);
        previousTempFees = this.getPreviousTempFees(
          earningLines,
          previousTempFees
        );
        // Sub Pay Items
        tempSubPayItems = this.getTempSubPayItems(
          earningLines,
          tempSubPayItems
        );

        previousTempSubPayItems = this.getPreviousTempSubPayItems(
          earningLines,
          previousTempSubPayItems
        );
      });
      // group by payment item name
      let groupItems = getConditionalResponse({
        condition: !isEmpty(temp),
        resp1: _.groupBy(temp, (i) => i.paymentItemName),
        resp2: {},
      });

      let previousGroupItems = getConditionalResponse({
        condition: !isEmpty(previousTemp),
        resp1: _.groupBy(previousTemp, (i) => i.paymentItemName),
        resp2: {},
      });

      // group b fees description

      let groupFees = getConditionalResponse({
        condition: !isEmpty(tempFees),
        resp1: _.groupBy(tempFees, (i) => i.feeOptionDescription),
        resp2: {},
      });

      let previousGroupFees = getConditionalResponse({
        condition: !isEmpty(previousTempFees),
        resp1: _.groupBy(previousTempFees, (i) => i.feeOptionDescription),
        resp2: {},
      });
      // group by sub pay items
      let groupSubItems = getConditionalResponse({
        condition: !isEmpty(tempSubPayItems),
        resp1: _.groupBy(tempSubPayItems, (i) => i.name),
        resp2: {},
      });

      let previousGroupSubItems = getConditionalResponse({
        condition: !isEmpty(previousTempSubPayItems),
        resp1: _.groupBy(previousTempSubPayItems, (i) => i.name),
        resp2: {},
      });
      // update sub pay items list
      Object.keys(groupSubItems).forEach((keys, j) => {
        return subPayItemList.push({
          id: j,
          category: keys,
          amount: _.sumBy(groupSubItems[keys], (o) => {
            return o.amount;
          }),
        });
      });
      // Sub pay items for previous period
      Object.keys(previousGroupSubItems).forEach((keys, j) => {
        return previousSubPayItemList.push({
          id: j,
          category: keys,
          amount: _.sumBy(previousGroupSubItems[keys], (o) => {
            return o.amount;
          }),
        });
      });
      // update temp summary list
      Object.keys(groupItems).forEach((keys, j) => {
        return tempSummary.push({
          id: j,
          category: keys,
          amount: _.sumBy(groupItems[keys], (o) => {
            return o.amount;
          }),
        });
      });
      Object.keys(previousGroupItems).forEach((keys, j) => {
        return previousTempSummary.push({
          id: j,
          category: keys,
          amount: _.sumBy(previousGroupItems[keys], (o) => {
            return o.amount;
          }),
        });
      });

      // update total fees list

      Object.keys(groupFees).forEach((keys, j) => {
        return totalFees.push({
          id: j,
          category: keys,
          amount: _.sumBy(groupFees[keys], (o) => {
            return o.amount;
          }),
          taxFeeAmount: _.sumBy(groupFees[keys], (o) => {
            return o.taxFeeAmount;
          }),
        });
      });
      Object.keys(previousGroupFees).forEach((keys, j) => {
        return previousTotalFees.push({
          id: j,
          category: keys,
          amount: _.sumBy(previousGroupFees[keys], (o) => {
            return o.amount;
          }),
          taxFeeAmount: _.sumBy(previousGroupFees[keys], (o) => {
            return o.taxFeeAmount;
          }),
        });
      });
      // earnings
      let grossEarnings = _.sumBy(summary, (o) => {
        return o.employeeNetEarnings;
      });
      let previousGrossEarnings = _.sumBy(mustBeArray(summary), (o) => {
        return o?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
          ?.employeeNetEarnings;
      });
      let totalEarnings = _.sumBy(summary, (o) => {
        return conditionalParameter({
          data: o.payrollProcessing,
          field: "total",
        });
      });
      let previousTotalEarnings = _.sumBy(mustBeArray(summary), (o) => {
        return o?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
          ?.payrollProcessing?.total;
      });
      this.setState({
        summary: tempSummary,
        previousSummary: previousTempSummary,
        totalFees,
        previousTotalFees,
        grossEarnings,
        previousGrossEarnings,
        totalEarnings,
        previousTotalEarnings,
        subPayItemList,
        previousSubPayItemList,
      });
    }
  };
  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (newProps.summary !== this.props.summary && newProps.summary !== []) {
      this.getSummary(newProps.summary);
    }
  }
  getVariance = (previousAmount: any, currentAmount: any) => {
    if (Number(currentAmount) === 0) return "N/A";
    let difference =
      getConditionalResponse({
        condition: previousAmount === undefined,
        resp1: 0,
        resp2: previousAmount,
      }) - currentAmount;
    const variance = ((difference / currentAmount) * 100).toFixed(2);
    return variance;
  };

  getTotalTaxFees = ({
    previousTotalFees = [],
    totalFees = [],
    type,
    invoice,
    businessunitDetails,
  }) => {
    switch (type) {
      case "previous":
        return currencyFormater(
          _.sumBy(previousTotalFees, (o) =>
            Number(parseFloat(o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(
              invoice?.payrunFeeSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                ?.fees,
              (o) => Number(parseFloat(o.taxFeeAmount).toFixed(2))
            ),
          businessunitDetails?.country,
          true
        );
      case "current":
        return currencyFormater(
          _.sumBy(totalFees, (o) =>
            Number(parseFloat(o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(invoice?.fees, (o) =>
              Number(parseFloat(o.taxFeeAmount).toFixed(2))
            ),
          businessunitDetails?.country
        );
      case "variance":
        return this.getVariance(
          _.sumBy(previousTotalFees, (o) =>
            Number(parseFloat(o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(
              invoice?.payrunFeeSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                ?.fees,
              (o) => Number(parseFloat(o.taxFeeAmount).toFixed(2))
            ),
          _.sumBy(totalFees, (o) =>
            Number(parseFloat(o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(invoice?.fees, (o) =>
              Number(parseFloat(o.taxFeeAmount).toFixed(2))
            )
        );
      default:
        return currencyFormater(o);
    }
  };

  getTotalFees = ({
    previousTotalFees = [],
    totalFees = [],
    type,
    invoice,
    businessunitDetails,
  }) => {
    switch (type) {
      case "previous":
        return currencyFormater(
          _.sumBy(previousTotalFees, (o) =>
            Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(
              invoice?.payrunFeeSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                ?.fees,
              (o) => Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
            ),
          businessunitDetails?.country,
          true
        );
      case "current":
        return currencyFormater(
          _.sumBy(totalFees, (o) =>
            Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(invoice?.fees, (o) =>
              Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
            ),
          businessunitDetails?.country
        );
      case "variance":
        return this.getVariance(
          _.sumBy(previousTotalFees, (o) =>
            Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(
              invoice?.payrunFeeSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
                ?.fees,
              (o) => Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
            ),
          _.sumBy(totalFees, (o) =>
            Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
          ) +
            _.sumBy(invoice?.fees, (o) =>
              Number(parseFloat(o.amount - o.taxFeeAmount).toFixed(2))
            )
        );
      default:
        return currencyFormater(o);
    }
  };

  render() {
    const {
      title,
      summaryLoading,
      classname,
      invoice,
      businessunitDetails,
      showVariance,
    } = this.props;
    const {
      previousSummary,
      previousTotalFees,
      previousGrossEarnings,
      previousTotalEarnings,
      previousSubPayItemList,
      totalFees,
      subPayItemList,
      totalEarnings,
    } = this.state;
    return (
      <Card className="card card-space-0 mb-[15px] overflow-x-auto rounded-[12px] invoice-summary">
        <div
          className={classNames("pl-1 pr-2", {
            "overflow-x-auto min-w-[600px]": showVariance,
          })}
        >
          <List
            header={
              <div className="pb-0">
                <div className="text-base font-bold mb-3 ml-2">{title}</div>
                <If
                  condition={!isEmpty(invoice) && totalEarnings && showVariance}
                  then={
                    <div className="bg-default py-4 pl-4 ml-1 card-radius-top">
                      <SummaryView
                        colOneValue=""
                        colTwoValue="Previous Period"
                        colThreeValue="Current Period"
                        colFourValue={"Variance %"}
                        showVariance={showVariance}
                      />
                    </div>
                  }
                />
              </div>
            }
            footer={
              <PayrunSummaryFooter
                invoice={invoice}
                showVariance={showVariance}
                businessunitDetails={businessunitDetails}
              />
            }
            itemLayout="horizontal"
            loading={summaryLoading}
            dataSource={mustBeArray(this.state.summary)}
            bordered={false}
            className={`${classname} defaultListHeader maximumHeight payrunSummary border-0`}
            renderItem={() => <React.Fragment />}
          >
            <div className="bg-default pr-1">
              <PayrunSummaryGrossIncome
                businessunitDetails={businessunitDetails}
                showVariance={showVariance}
                previousTotalEarnings={previousTotalEarnings}
                totalEarnings={totalEarnings}
                subPayItemList={subPayItemList}
                previousSubPayItemList={previousSubPayItemList}
                key="grossIncome"
              />
              <PayrunSummaryDeduction
                summary={this.state.summary}
                businessunitDetails={businessunitDetails}
                showVariance={showVariance}
                previousSummary={previousSummary}
              />
              <SummaryView
                rowClassName="ml-0 mr-0 pl-4"
                colOneClassName="font-bold"
                colTwoClassName="font-bold"
                colThreeClassName="font-bold"
                colFourClassName="font-bold"
                colOneValue="Net Wage"
                colTwoValue={currencyFormater(
                  previousGrossEarnings,
                  businessunitDetails?.country
                )}
                colThreeValue={currencyFormater(
                  this.state.grossEarnings,
                  businessunitDetails?.country
                )}
                colFourValue={this.getVariance(
                  previousGrossEarnings,
                  this.state.grossEarnings
                )}
                showVariance={showVariance}
              />
              <PayrunSummaryTotalFees
                invoice={invoice}
                businessunitDetails={businessunitDetails}
                showVariance={showVariance}
                getTotalFees={this.getTotalFees}
                getTotalTaxFees={this.getTotalTaxFees}
                previousTotalFees={previousTotalFees}
                totalFees={totalFees}
              />
            </div>
          </List>
        </div>
      </Card>
    );
  }
}

export default Summary;
