import styled from 'styled-components';

import { DawnCircleTick } from '@xemplo/icons';
import {
  Body2XSmallMedium,
  BodySmallMedium,
  BodyXSmallRegular,
  Colour,
} from '@xemplo/style-constants';

/** LAYOUT */
export const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-block: 8px;
  border: 1px solid ${Colour.Gray[100]};
  border-radius: 8px;
  background: ${Colour.White[100]};
  width: max-content;
  & > *:not(:last-child) {
    border-right: 1px solid ${Colour.Gray[100]};
  }
  &.stacked {
    border: none;
    border-radius: 0;
    background: none;
    & > div {
      background: ${Colour.White[100]};
    }

    & > :last-child {
      padding-right: 0;
    }
  }
`;

/** CONTENT */
export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  width: fit-content;
  min-width: fit-content;

  padding-inline: 12px 16px;
`;

export const Step = styled.div`
  ${BodySmallMedium};
  flex-shrink: 0;
  color: ${Colour.Gray[800]};
`;

export const StepDate = styled.div`
  ${BodyXSmallRegular};
  color: ${Colour.Gray[400]};
`;

/** STEP ICON */

export const Badge = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  ${Body2XSmallMedium};
  background: ${Colour.Gray[100]};
  color: ${Colour.Gray[500]};
  &.active {
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
  }
`;

export const CompletedStepBadge = styled(DawnCircleTick)`
  color: ${Colour.Green[500]};
  width: 24px;
  height: 24px;
`;
