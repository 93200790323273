import { BadgeColour, BadgeSize, BadgeType } from '@xemplo/badge';
import { DawnAddPlus16 } from '@xemplo/icons';
import { Colour } from '@xemplo/style-constants';

import * as S from './dropzone.style';
import { DropzoneProps } from './dropzone.types';
import { FileIcon, FileIconProps } from './file-icon';
import { useDropzone } from './use-dropzone';

export function Dropzone(props: Readonly<DropzoneProps>) {
  const {
    actionMessage = <p>Drag and drop files</p>,
    actionMessageHelperText = 'PNG, JPG or PDF (max. 5MB)',
    optionalHelperText,
    variant = 'default',
    testId,
    dragEventHandlers,
  } = props;

  const iconColours: FileIconProps = {
    stroke: variant === 'hover' ? Colour.Blue[300] : undefined,
    altStroke: variant === 'hover' ? Colour.Blue[400] : undefined,
  };

  const { handleDragEnd, handleDragEnter, handleDragLeave, handleDragOver, handleDrop } =
    useDropzone(dragEventHandlers);

  return (
    <S.Wrapper
      data-testid={testId}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      <S.Dropzone $variant={variant}>
        <div>
          <S.IconContainer>
            <S.PlusBadge
              leadingIcon={<DawnAddPlus16 />}
              value=""
              type={BadgeType.Round}
              color={BadgeColour.Blue}
              size={BadgeSize.Small}
            />
            <FileIcon {...iconColours} />
          </S.IconContainer>

          <S.ActionMessage>{actionMessage}</S.ActionMessage>
          <S.ActionMessageHelper>{actionMessageHelperText}</S.ActionMessageHelper>
        </div>
      </S.Dropzone>
      <S.HelperText>{optionalHelperText}</S.HelperText>
    </S.Wrapper>
  );
}
