import { BaseUser } from '../member';

type BaseWorker = {
  startDate: Date | null;
  primaryLocation: string;
  employmentType: string | null;
  activePayrunAmendmentsCount: number;
};

export type GpWorker = BaseWorker &
  Omit<
    BaseUser,
    | 'directoryServiceUserId'
    | 'area'
    | 'selectedRole'
    | 'profilePhoto'
    | 'mobile'
    | 'username'
  >;

export interface GpWorkerDetail {
  payrunId: string;
  previousPayrunId: string;
  externalEmployeeId: string;
  externalEmployeeName: string;
  previousAndYtdPayItems: PreviousAndYtdPayItem[];
}
export type GpEarningLineDetailVariance = GpEarningLineDetailVarianceResponse & {
  parsedRows: EarningLineDetailVarianceItem[][];
};
export interface GpEarningLineDetailVarianceResponse {
  payrunId: string;
  externalEmployeeId: string;
  externalEmployeeName: string | null;
  payrunEmployeeEarninglineItems: EarningLineDetailVarianceItem[];
  totalPayable: {
    previous: number;
    current: number;
    variancePercent: number;
    varianceAmount: number;
  };
}

export interface EarningLineDetailVarianceItem {
  payCategoryId: number | null;
  payCategoryName: string;
  current: number;
  currentRate: number;
  currentUnit: number;
  previous: number;
  varianceAmount: number;
  variancePercent: number;
}

export interface PreviousAndYtdPayItem {
  payItemId: number | null;
  payItemName: string;
  lastPayAmount: number;
  yearToDateAmount: number;
}

export enum PayrunWorkerWarningType {
  PreviouslyTerminated = 'PreviouslyTerminated',
}
