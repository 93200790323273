import { useFeatureFlag } from "@xemplo/feature-flag";
import { useMemo } from "react";
import { Route, RouteObject } from "react-router-dom";

import { ErrorBoundary } from "@xemplo/error-boundary";
import { Features } from "../constants";
import { useIsUserInRole } from "../hooks/useAuthDom/use-check-role";
import { PersonaRole } from "@xemplo/gp-types";

export const V2FeatureRoute = (
  routes: RouteObject[],
  feature: keyof typeof Features,
  roles?: PersonaRole[]
) => {
  const { isEnabled } = useFeatureFlag();
  const featureIsEnabled = feature && isEnabled(feature);
  const isUserInRole = useIsUserInRole(roles);
  const allowRoute = featureIsEnabled && isUserInRole;

  return useMemo(() => {
    if (!allowRoute) {
      return null;
    }

    return (
      <>
        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              Component={route.Component}
              errorElement={<ErrorBoundary />}
            >
              {route.children?.map((subRoute) => {
                if (subRoute.index) {
                  return (
                    <Route
                      index
                      Component={subRoute.Component}
                      errorElement={<ErrorBoundary />}
                      key={route.path}
                    />
                  );
                }
                return (
                  <Route
                    key={subRoute.path ?? route.path}
                    path={subRoute.path ?? route.path}
                    Component={subRoute.Component}
                    errorElement={<ErrorBoundary />}
                  >
                    {subRoute.children?.map((child) => {
                      return (
                        <Route
                          key={child.path}
                          path={child.path}
                          Component={child.Component}
                          errorElement={<ErrorBoundary />}
                        />
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </>
    );
  }, [allowRoute]);
};
