import { SVGProps } from 'react';
const DawnSignOut24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.998 2.004a.75.75 0 1 1 0 1.5c-2.385 0-4.503.808-6.02 2.25-1.512 1.435-2.474 3.544-2.474 6.244 0 2.7.962 4.809 2.473 6.245 1.518 1.441 3.636 2.249 6.02 2.249a.75.75 0 1 1 0 1.5c-2.722 0-5.225-.926-7.053-2.662-1.834-1.742-2.94-4.255-2.94-7.332s1.106-5.59 2.94-7.332c1.828-1.736 4.33-2.662 7.054-2.662Zm5.755 6.575a.75.75 0 0 1 1.06 0l2.959 2.958a.75.75 0 0 1 0 1.06l-2.958 2.959a.75.75 0 0 1-1.06-1.061l1.677-1.678H9.41a.75.75 0 0 1 0-1.5h10.02L17.754 9.64a.75.75 0 0 1 0-1.061Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnSignOut24;
