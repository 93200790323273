import { useState } from "react";
import YesNoModal from "../YesNoModal";

type Props = {
  title: string;
  children: React.ReactNode;
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel?: () => void;
};

const YesNoModalTrigger = ({ children, title, onOk, onCancel }: Props) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
    onCancel?.();
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <span tabIndex={0} role="button" onClick={showModal}>
        {children}
      </span>
      <YesNoModal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default YesNoModalTrigger;
