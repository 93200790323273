//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Modal, Input, message, Select } from "antd";
import _ from "lodash";
import { parseItems, mustBeArray } from "../../../libs/utilities";

const Option = Select.Option;

type State = any;

class RejectPayrun extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      errorSource: undefined,
      errorReasons: [],
    };
  }
  componentDidMount() {
    this.props.getPayrunRejectionReasons().then((resp) => {
      if (resp.status) {
        this.setState({ errorReasons: mustBeArray(resp.data) });
      }
    });
  }
  handleChange = ({ field, value }) => {
    this.setState({ [field]: value });
  };

  submitRejection = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { description } = values;
      if (_.isNull(description) || description.trim() === "") {
        message.error("Please enter a reason");
      } else {
        const errorSource =
          _.find(
            this.state.errorReasons,
            (o) => o.errorCategory === values.errorCategory
          ) &&
          _.find(
            this.state.errorReasons,
            (o) => o.errorCategory === values.errorCategory
          ).errorSource;

        this.props.submitRejection({ ...values, errorSource });
      }
    });
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    const { rejectionReason, visible } = newProps;
    let description;
    if (visible && !this.props.visible && !_.isEmpty(rejectionReason)) {
      try {
        if (
          newProps.amendment &&
          newProps.amendment.rejectDescription &&
          parseItems(newProps.amendment.rejectDescription, {}) &&
          parseItems(newProps.amendment.rejectDescription, {}).RejectionReason
        ) {
          description = parseItems(
            newProps.amendment.rejectDescription,
            {}
          ).RejectionReason;

          this.setState({ description });
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (!visible && this.props.visible) {
      this.setState({ description: "" });
    }
  }

  render() {
    const { visible, cancelRejection, submitRejectionLoading } = this.props;

    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={`Are you sure you want to reject this pay run?`}
        visible={visible}
        width={700}
        okText="Yes"
        cancelText="No"
        onCancel={cancelRejection}
        onOk={this.submitRejection}
        okButtonProps={{ loading: submitRejectionLoading }}
      >
        <Form className="form-label-left" autoComplete="off">
          <Form.Item label="Category">
            {getFieldDecorator("errorCategory", {
              rules: [
                {
                  required: true,
                  message: "Please select a category.",
                },
              ],
            })(
              <Select
                placeholder={"Select"}
                filterOption={false}
                showSearch={false}
              >
                {mustBeArray(this.state.errorReasons).map((item, key) => (
                  <Option key={item.index} value={item.errorCategory}>
                    {item.errorCategory}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Please enter a reason.",
                },
              ],
            })(
              <Input.TextArea
                placeholder="Please enter a reason"
                rows={4}
                maxLength={1000}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "rejectPayrunForm" })(RejectPayrun);
