//@ts-nocheck
import React from "react";
import { Input, Switch } from "antd";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";

const ShowVariance = (props: any) => {
  const {
    showVariance,
    highLightVarianceValue,
    updateVarianceValue,
    toggleShowVariance,
    filterApplied,
    handleFilterChange,
  } = props;
  return (
    <React.Fragment>
      <div className={`mb-3 flex justify-between items-center`}>
        <div>
          <SingleFieldTextBoxFilter
            placeholder="Name"
            name="companyNameTextboxFilter"
            value={filterApplied}
            onChange={(value: any) =>
              handleFilterChange({ label: "filterApplied", value })
            }
            ignoreStatus={true}
            className="rounded-[20px] filter-radius"
          />
        </div>
        <div>
          {showVariance && (
            <span className="mr-4">
              Highlight variance %{" "}
              <span>
                <Input
                  placeholder="Enter"
                  value={highLightVarianceValue}
                  onChange={(e) => updateVarianceValue(e.currentTarget.value)}
                  className="w-[100px] ml-2.5 rounded-[20px]"
                />
              </span>
            </span>
          )}
          <span className="">
            Show Variance
            <Switch
              onChange={(e) => toggleShowVariance({ showVariance: e })}
              checked={showVariance}
              className="ml-1.5"
            />
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ShowVariance;
