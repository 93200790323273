import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../../hooks";
import {
  payrunChangeLogColumns,
  mustBeArray,
  payrunIsSkipped,
} from "../../../../libs";
import axios from "axios";
import {
  useSliceActions,
  useSliceSelector,
} from "../../../../Common/SliceListProvider";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import { getPayrunChangeLog } from "../../../actions/payrun";
import { useNavigate } from "react-router-dom";
import { DEFAULT_ROUTES } from "../../../../constants";

type Props = {
  visible?: boolean;
  id?: string;
  payrun?: any;
};

const PayrunChangeLogList = ({
  visible, //Track the changes in the modal visibility to refetch the data
  payrun,
}: Props) => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const signal = axios.CancelToken.source();
  //Get list state
  const payrunChangeLogState = useSliceSelector();
  // Get list actions
  const { dataLoaded, setLoading } = useSliceActions();

  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [visible]);

  const getData = () => {
    setLoading({ loading: true });
    dispatch(
      getPayrunChangeLog({
        id: payrun?.payrunId,
        cancelToken: signal.token,
      })
    ).then((resp: any) => {
      dataLoaded({ data: mustBeArray(resp?.data), total: resp?.total });
      if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
        navigate(DEFAULT_ROUTES.PAYRUN);
      }
    });
  };

  const columns = useMemo(() => {
    return payrunChangeLogColumns;
  }, [visible, payrunChangeLogState?.data]);

  return (
    <SimpleTableContainer
      dataSource={mustBeArray(payrunChangeLogState?.data)}
      columns={columns}
      size="small"
      loading={!!payrunChangeLogState?.loading}
      pagination={{ pageSize: 5, hideOnSinglePage: true }}
    />
  );
};

export default PayrunChangeLogList;
