import userRoles from "../../constants/userRoles";
import { getSelectedRole, getUserArea } from "../../libs";
import { useAppSelector } from "../useStore";

/**
 * We use this hook to get the member data from the store
 * and return the methods without the need to pass the member
 * */

export const useAuthDom = () => {
  const member = useAppSelector((state: any) => state.member);

  const isCompanyUser = () => {
    return userRoles.CompanyArea === getUserArea();
  };

  const isUserPSPAdmin = () => {
    return member?.details?.selectedRole?.role?.name === userRoles.PSPAdmin;
  };

  const isUserCompanyAdmin = () => {
    return member?.details?.selectedRole?.role?.name === userRoles.CompanyAdmin;
  };

  const isUserPSPManager = () => {
    return (
      member?.details?.selectedRole?.role?.name === userRoles.PayrollManager
    );
  };

  const isUserSystemAdmin = () => {
    return member?.details?.selectedRole?.role?.name === userRoles.SystemAdmin;
  };

  const isUserPSPUser = () => {
    return [userRoles.PSPAdmin, userRoles.PayrollManager].includes(
      getSelectedRole({ member })?.role?.name
    );
  };

  const isUserAccountant = () => {
    return (
      member?.details?.selectedRole?.role?.name === userRoles.CompanyAccountant
    );
  };

  return {
    isCompanyUser,
    isUserPSPAdmin,
    isUserCompanyAdmin,
    isUserPSPManager,
    isUserPSPUser,
    isUserSystemAdmin,
    isUserAccountant,
  };
};
