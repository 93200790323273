import React, { ReactElement } from 'react';

export interface ActionSheetPopoverProps {
  testId?: string;
  children: ReactElement;
  placement: ActionSheetPlacement;
  customButton?: ReactElement;
}

export type ActionSheetListItemTypes =
  | ActionSheetLink
  | ActionSheetButton
  | ActionSheetDivider;

export type ActionSheetListItems = Array<ActionSheetListItemTypes | undefined>;

export interface ActionSheetProps {
  items: ActionSheetListItems;
  placement?: ActionSheetPlacement;
  maxWidth?: number;
  customButton?: ReactElement;
}

export interface ActionSheetListItem {
  id: number | string;
  type: ActionSheetItemTypes;
  variant?: ActionSheetItemVariant;
}

export interface ActionSheetLink extends ActionSheetListItem {
  type: ActionSheetItemTypes.Link;
  label: string;
  to: string;
  isExternal?: boolean;
}
export interface ActionSheetButton extends ActionSheetListItem {
  type: ActionSheetItemTypes.Button;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export interface ActionSheetDivider extends ActionSheetListItem {
  type: ActionSheetItemTypes.Divider;
}

export enum ActionSheetItemTypes {
  Link = 'link',
  File = 'file',
  Button = 'button',
  Divider = 'divider',
}

export enum ActionSheetItemVariant {
  Default = 'default',
  Secondary = 'secondary',
  Destructive = 'destructive',
}

export enum ActionSheetPlacement {
  Left = 'left',
  Right = 'right',
}
