import { useMemo } from 'react';
import styled from 'styled-components';

import { DawnArrowLongDown16, DawnArrowLongUp16 } from '@xemplo/icons';
import { BodyXSmallRegular, Colour, TextEllipsis } from '@xemplo/style-constants';

import { MonetaryCellProps } from './cell.types';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  padding-inline: 8px;
  color: ${Colour.Gray[800]};
  background: ${Colour.Green[50]};
  border-radius: 4px;
  width: fit-content;
  margin-left: auto;
  ${BodyXSmallRegular};

  &[data-negative='true'] {
    background: ${Colour.Red[50]};
  }
`;

const StyledValue = styled.span`
  ${TextEllipsis};
`;

export const VarianceCellTestId = {
  icon: 'variance-cell-icon',
  value: 'variance-cell-value',
};

export type VarianceCellProps = MonetaryCellProps & {
  percentage?: boolean;
};

export const VarianceCell = (props: VarianceCellProps) => {
  const { currency = 'AUD', locale = 'en-AU', value = 0, percentage } = props;
  const isNegative = useMemo(() => props.value && props.value < 0, [props.value]);

  const formattedNumber = useMemo(() => {
    if (percentage) {
      return new Intl.NumberFormat(locale, {
        style: 'percent',
        maximumFractionDigits: 2,
        signDisplay: 'never',
      }).format(value ? value / 100 : 0);
    } else {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 2,
        signDisplay: 'never',
      }).format(value ?? 0);
    }
  }, [currency, locale, percentage, value]);

  return (
    <StyledWrapper
      className={props.className}
      data-negative={isNegative}
      data-testid={VarianceCellTestId.value}
    >
      {isNegative ? (
        <DawnArrowLongDown16
          fontSize={16}
          color={Colour.Red[500]}
          data-testid={VarianceCellTestId.icon}
        />
      ) : (
        <DawnArrowLongUp16
          fontSize={16}
          color={Colour.Green[600]}
          data-testid={VarianceCellTestId.icon}
        />
      )}
      <StyledValue>{formattedNumber}</StyledValue>
    </StyledWrapper>
  );
};
