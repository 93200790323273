//@ts-nocheck
import React, { Component } from 'react';
import { tableColumnGenerator } from '../../../libs/table';
import { Table } from 'antd';
 import { mustBeArray, findScrollableTableHeight, getPayScheduleListColumns } from '../../../libs/utilities';

type State = any;

class ScrollablePayScheduleTable extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        document.querySelector('.ant-table-body') && document.querySelector('.ant-table-body').addEventListener('scroll', (event) => {
            let maxScroll = (event.target).scrollHeight - (event.target).clientHeight;
            let currentScroll = (event.target).scrollTop;
            if (((currentScroll >= maxScroll) || (maxScroll - currentScroll < 0.5)) && this.props.hasMoreData) {
                this.props.handleTableChange({ options: {}, pageLoad: false });
            }
        })

    }
    componentWillUnmount() {
        document.querySelector('.ant-table-body') && document.querySelector('.ant-table-body').removeEventListener('scroll', () => {console.log('') });
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        let options = sorter.field ? {
            page: pagination.current,
            order: sorter.order === "ascend" ? "asc" : "desc",
            field: sorter.field === "payOccurence" ? "payoccurrencename" : sorter.field,
        }
            :
            {
                page: pagination.current
            };
        this.props.handleTableChange({ options, pageLoad: true });
    };

    render() {
        const { sortInfo, view,
            loading, data, per_page, claims, component } = this.props;
        let scrollablePayScheduleColTemplate = getPayScheduleListColumns({ view, claims, component, sortInfo });
        const tableColumn = tableColumnGenerator(scrollablePayScheduleColTemplate);
        return (

            <Table
                columns={tableColumn}
                dataSource={data}
                pagination={false}
                loading={loading}
                scroll={{ y: findScrollableTableHeight({ per_page, total: mustBeArray(data).length }) }}
                rowKey={(record, index) => index}
                onChange={this.handleTableChange}
            />
        );
    }
}

export default ScrollablePayScheduleTable;
