//@ts-nocheck
import React from "react";
import PayrollProviderView from "./PayrollProvider/index";
import AdminView from "./Admin/index";
import userRoles from "../../../constants/userRoles";
import { getUserArea } from "../../../libs";
import { useAppSelector } from "../../../hooks";

const PayrunIndex = (props) => {
  const { details } = useAppSelector((state) => state.member);
  const userArea = details?.area ?? getUserArea();

  switch (userArea) {
    case userRoles.SystemAdminArea:
      return <AdminView {...props} />;
    case userRoles.PSPArea:
    case userRoles.CompanyArea:
      return (
        <PayrollProviderView
          loading={props.loading}
          listPayrunsforPayrollAdmin={props.listPayrunsforPayrollAdmin}
          payrunPrepare={props.payrunPrepare}
          payrunPrepareCount={props.payrunPrepareCount}
          payrunProcess={props.payrunProcess}
          payrunProcessCount={props.payrunProcessCount}
          {...props}
        />
      );
    default:
      return <React.Fragment />;
  }
};

export default PayrunIndex;
