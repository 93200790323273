import classNames from 'classnames';

import { Button, ButtonType, ButtonVariant } from '@xemplo/button';
import { DawnCircleStop } from '@xemplo/icons';

import { NoAccessBtnText, NoAccessText } from './no-access.constants';
import * as S from './no-access.styles';
import { NoAccessProps } from './no-access.types';
import { useNoAccess } from './use-no-access';

export function NoAccess({ centered, message, onClick }: Readonly<NoAccessProps>) {
  const { showExtras, text, handleGoToPersonas } = useNoAccess(message);

  return (
    <S.Wrapper className={classNames({ centered })}>
      <S.NoAccessContainer>
        <DawnCircleStop />
        <S.Header>{NoAccessText.Heading}</S.Header>
        {text}
        <S.LogoutButton
          type={ButtonType.Secondary}
          ariaLabel={NoAccessBtnText.LogoutBtnAria}
          onClick={onClick}
        >
          {NoAccessText.Logout}
        </S.LogoutButton>
        {showExtras && (
          <Button
            type={ButtonType.Tertiary}
            variant={ButtonVariant.Dark}
            ariaLabel={NoAccessBtnText.SwitchPersonaBtnAria}
            onClick={handleGoToPersonas}
          >
            {NoAccessText.SwitchPersona}
          </Button>
        )}
      </S.NoAccessContainer>
    </S.Wrapper>
  );
}

export default NoAccess;
