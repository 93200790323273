export const Environment = {
  Dev: 'dev',
  QA: 'qa',
  Staging: 'staging',
  Prod: 'production',
} as const;
export type Environment = (typeof Environment)[keyof typeof Environment];

export const Context = {
  Advisory: 'advisory',
  Hire: 'hire',
  GP: 'gp',
  HR: 'hr',
  AppStart: 'app-start',
} as const;
export type Context = (typeof Context)[keyof typeof Context];
