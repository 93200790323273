//@ts-nocheck
import React from "react";
import { Table, Card } from "antd";
import { currencyFormater, mustBeArray } from "../../../../libs/utilities";

const { Column, ColumnGroup } = Table;

class AuditReportPayrunTotalList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  getPaygPercentage = (data: any) => {
    if (!data || !data.taxableEarnings || data.taxableEarnings === 0)
      return "N/A";
    return `${((data.paygWithheld / data.taxableEarnings) * 100).toFixed(2)}%`;
  };
  render() {
    const { data } = this.props;
    return (
      <Card
        title={<div className="pt-3 pl-3">Pay Run Total Summary</div>}
        className="card-space-0"
      >
        <Table
          dataSource={data}
          size="small"
          pagination={false}
          bordered={false}
          rowKey={(record, i) => i}
          scroll={{ x: "max-content" }}
          className="table-showing-total"
        >
          <Column
            title="Employee"
            dataIndex="employeeName"
            key="employeeName"
          />
          <Column
            title="Total Hours"
            dataIndex="totalHours"
            key="totalHours"
            align="right"
          />
          <Column
            title="Gross Earnings"
            dataIndex="grossEarnings"
            key="grossEarnings"
            align="right"
            render={(record) => {
              return currencyFormater(record);
            }}
          />
          <Column
            title="Pre-Tax Deduction"
            dataIndex="preTaxDeductions"
            key="preTaxDeductionAmount"
            align="right"
            render={(record) => {
              return currencyFormater(record);
            }}
          />
          <Column
            title="Taxable Earnings"
            dataIndex="taxableEarnings"
            key="taxableEarnings"
            align="right"
            render={(record) => {
              return currencyFormater(record);
            }}
          />
          <Column
            title="Post-Tax Deduction"
            dataIndex="postTaxDeductions"
            key="postTaxDeductionAmount"
            align="right"
            render={(record) => {
              return currencyFormater(record);
            }}
          />
          <ColumnGroup title="Withheld Amounts" key="withheldAmounts">
            <Column
              title="PAYG"
              dataIndex="paygWithheld"
              key="paygWithholdingAmount"
              align="right"
              render={(record) => {
                return currencyFormater(record);
              }}
            />
            <Column
              title="PAYG%"
              dataIndex=""
              key="paygWithholdingAmount%"
              align="right"
              render={(record, rowData, index) => {
                return index === mustBeArray(data).length - 1
                  ? "N/A"
                  : this.getPaygPercentage(record);
              }}
            />
            <Column
              title="STSL"
              dataIndex="helpWithheld"
              key="helpAmount"
              align="right"
              render={(record) => {
                return currencyFormater(record);
              }}
            />
          </ColumnGroup>
          <ColumnGroup title="Super Contributions" key="superContributions">
            <Column
              title="SG"
              dataIndex="superContribution"
              key="superContribution"
              align="right"
              render={(record) => {
                return currencyFormater(record);
              }}
            />
            <Column
              title="EC"
              dataIndex="employerContribution"
              key="employerContribution"
              align="right"
              render={(record) => {
                return currencyFormater(record);
              }}
            />
            <Column
              title="MV"
              dataIndex="postTaxDeductions"
              key="personalContribution"
              align="right"
              render={(record) => {
                return currencyFormater(record);
              }}
            />
            <Column
              title="SS"
              dataIndex="preTaxDeductions"
              key="socialSecurityContribution"
              align="right"
              render={(record) => {
                return currencyFormater(record);
              }}
            />
          </ColumnGroup>
          <Column
            title="Net Earnings"
            dataIndex="netEarnings"
            key="netEarnings"
            align="right"
            render={(record) => {
              return currencyFormater(record);
            }}
          />
        </Table>
      </Card>
    );
  }
}

export default AuditReportPayrunTotalList;
