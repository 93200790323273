import { Modal } from "antd";

type Props = {
  visible: boolean;
  title: string;
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: () => void;
};

const YesNoModal = ({ visible, title, onOk, onCancel }: Props) => (
  <Modal
    title={title}
    visible={visible}
    width={800}
    okText="Yes"
    cancelText="No"
    onCancel={onCancel}
    onOk={onOk}
    className="modal-title-lh-30"
  ></Modal>
);

export default YesNoModal;
