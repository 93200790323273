//@ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import { parseItems } from "../../../libs/utilities";
import { If } from "../../../Common/ui";

let label = 6;
let value = 18;

const ReSubmitDetails = ({ description, type }: any) => {
  let reSubmitDescription = {};
  let errorDescription = "";
  try {
    if (description) {
      reSubmitDescription = parseItems(description);
      if (type === "payrun" && reSubmitDescription.RejectionReason) {
        errorDescription = reSubmitDescription.RejectionReason?.Description;
      }
    }
  } catch (err) {
    console.log(err);
  }
  label = 8;
  value = 16;
  // }

  return (
    <React.Fragment>
      <Row className="mb-1" gutter={8}>
        <Col span={label}>
          <b>Resubmitted by:</b>
        </Col>
        <Col span={value}>{reSubmitDescription?.User?.UserName}</Col>
      </Row>
      <Row className="mb-1" gutter={8}>
        <Col span={label}>
          <b>Date:</b>
        </Col>
        <Col span={value}>
          {reSubmitDescription?.User?.Date &&
            moment(reSubmitDescription.User.Date).format("DD/MM/YYYY hh:mm A")}
        </Col>
      </Row>
      <Row>
        <Col span={label}>
          <b>Reason:</b>
        </Col>
        <Col span={value}>
          <If
            condition={type === "payrun"}
            then={errorDescription}
            else={reSubmitDescription?.RejectionReason}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default ReSubmitDetails;
