import { Dispatch, useEffect } from 'react';
import { User, UserManager } from 'oidc-client-ts';

import { Action, AuthActions } from '../auth-provider.types';

/**
 * Register oidc-client-ts.userManager.events
 *
 */
export const useUserManagerEvents = (
  userManager: UserManager,
  dispatch: Dispatch<Action>
) => {
  useEffect(() => {
    // event UserLoaded (e.g. initial load, silent renew success)
    const handleUserLoaded = (user: User) => {
      dispatch({ type: AuthActions.UserLoaded, user });
    };
    userManager.events.addUserLoaded(handleUserLoaded);

    // event UserUnloaded (e.g. removeUser)
    const handleUserUnloaded = () => {
      dispatch({ type: AuthActions.UserUnloaded });
    };
    userManager.events.addUserUnloaded(handleUserUnloaded);

    // event SilentRenewError (silent renew error)
    const handleSilentRenewError = (error: Error) => {
      dispatch({ type: AuthActions.Error, error });
    };
    userManager.events.addSilentRenewError(handleSilentRenewError);

    userManager.events.addUserSignedOut(async () => {
      await userManager.removeUser();
    });

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeSilentRenewError(handleSilentRenewError);
    };
  }, [dispatch, userManager]);
};
