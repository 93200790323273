import { PageHeader } from "@xemplo/gp-types";
import { createSlice } from "@reduxjs/toolkit";

/** TODO: Move this to @xemplo/gp-types */
type PageHeaderExtended = PageHeader & {
  returnUrl?: string;
};

type PageHeaderState = { header: PageHeaderExtended };

const initialState: PageHeaderState = {
  header: {},
};

const pageHeaderSlice = createSlice({
  name: "pageHeader",
  initialState: { ...initialState },
  reducers: {
    setPageHeader: (state, action: { payload: PageHeaderExtended }) => {
      state.header = { ...state.header, ...action.payload };
    },
    clearPageHeader: (state) => {
      state.header = {};
    },
  },
});

export const { reducer, actions: pageHeaderActions } = pageHeaderSlice;
/** @alias */
export default reducer;
