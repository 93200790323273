import { payRollProviderAction } from "../constants/action-type/index";

const initialState = {
  list: [],
  connectionsList: [],
  users: { list: [], count: 0 },
  userDetails: {},
};
const {
  PAYROLL_PROVIDER_LIST,
  PAYROLL_PROVIDER_DETAIL,
  LIST_PSP_USER_DETAILS,
  LIST_PSP_USERS,
} = payRollProviderAction;

export default function payrollProviderReducer(
  state = initialState,
  action: any
) {
  const { data, total } = action;
  switch (action.type) {
    case PAYROLL_PROVIDER_LIST: {
      return {
        ...state,
        list: data,
        total,
      };
    }

    case PAYROLL_PROVIDER_DETAIL: {
      return {
        ...state,
        detail: data,
      };
    }

    case "CONNECTIONS_LIST": {
      return {
        ...state,
        connectionsList: data,
      };
    }

    case LIST_PSP_USERS: {
      return {
        ...state,
        users: {
          list: data ? data : [],
          count: total ? total : 0,
        },
      };
    }

    case "LIST_PSP_USERS_CLEAR": {
      return {
        ...state,
        users: {
          list: [],
          count: 0,
        },
      };
    }

    case LIST_PSP_USER_DETAILS: {
      return {
        ...state,
        userDetails: data,
      };
    }

    case "CLEAR_PAYROLLPROVIDER": {
      return {
        ...state,
        list: [],
        total: 0,
        detail: {},
        users: {
          list: [],
          count: 0,
        },
        userDetails: {},
        connectionsList: [],
      };
    }
    default:
      return state;
  }
}
