import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MAX_NUMBER_VALUE } from '@xemplo/gp-constants';
import { TextFieldV2 } from '@xemplo/text-field';

import * as S from '../../modal.styles';

import { RecurringTypeValues } from './recurring-type';

export const RecurringMaxAmount = () => {
  const { register, watch, unregister } = useFormContext();

  const watchRecurringType = watch('recurringType');
  const showMaxAmount = watchRecurringType === RecurringTypeValues.MAXIMUM_AMOUNT;

  useEffect(() => {
    if (showMaxAmount) {
      register('recurringMaxAmount', {
        required: 'This field is required',
        valueAsNumber: true,
      });
    } else {
      unregister('recurringMaxAmount');
    }
  }, [register, showMaxAmount, unregister]);

  if (showMaxAmount) {
    return (
      <S.FormLabel>
        Maximum Amount
        <TextFieldV2
          placeholder="Enter maximum amount"
          type="number"
          name="recurringMaxAmount"
          testId="recurringMaxAmount"
          aria-label="Recurring maximum amount field"
          min={0}
          max={MAX_NUMBER_VALUE}
          step={0.01}
        />
      </S.FormLabel>
    );
  }
};

export const RecurringTypeOptions = [
  { key: 1, value: 'Maximum Amount' },
  { key: 2, value: 'End Date' },
];
