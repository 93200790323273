//@ts-nocheck
import { payscheduleAction } from "../constants/action-type/index";
import _ from "lodash";
import { mustBeArray } from "../libs/utilities";

const initialState = {
  list: [],
  count: null,
  active: { list: [], count: "" },
  deactive: { list: [], count: "" },
};
const {
  LIST_BUSINESS_PAYSCHEDULE,
  LIST_BUSINESS_PAYSCHEDULE_ACTIVE,
  LIST_BUSINESS_PAYSCHEDULE_DEACTIVE,
  PAYSCHEDULE_USER_LIST,
  PAYSCHEDULE_USER_ADD,
  PAYSCHEDULE_USER_REMOVE,
  LIST_PAYSCHEDULE_DETAILS,
} = payscheduleAction;

export default function payscheduleReducer(state = initialState, action: any) {
  const { data, total } = action;
  switch (action.type) {
    case LIST_BUSINESS_PAYSCHEDULE: {
      return {
        ...state,
        list: data,
        count: total,
      };
    }

    case "LIST_BUSINESS_PAYSCHEDULE_CLEAR": {
      return {
        ...state,
        list: [],
        count: 0,
      };
    }

    case LIST_BUSINESS_PAYSCHEDULE_ACTIVE: {
      return {
        ...state,
        active: {
          list: data,
          count: action.status ? total : 0,
        },
      };
    }

    case LIST_BUSINESS_PAYSCHEDULE_DEACTIVE: {
      return {
        ...state,
        deactive: {
          list: data,
          count: action.status ? total : 0,
        },
      };
    }

    case LIST_PAYSCHEDULE_DETAILS: {
      return {
        ...state,
        detail: data,
      };
    }

    case PAYSCHEDULE_USER_LIST: {
      return {
        ...state,
        users: data,
      };
    }

    case PAYSCHEDULE_USER_ADD: {
      return {
        ...state,
        users: [...mustBeArray(state.users), data],
      };
    }

    case PAYSCHEDULE_USER_REMOVE: {
      _.remove(state.users, (user) => user.id === data.id);
      return {
        ...state,
      };
    }

    case "CLEAR_PAYSCHEDULE": {
      return {
        ...state,
        users: [],
        detail: {},
        list: [],
        count: 0,
      };
    }

    default:
      return state;
  }
}
