import styled from 'styled-components';

import {
  BodyLargeSemiBold,
  BodySmallMedium,
  BodySmallRegular,
  BodySmallSemiBold,
  Colour,
} from '@xemplo/style-constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
`;

export const Header = styled.span`
  color: ${Colour.Gray[800]};
  ${BodyLargeSemiBold};
  margin-bottom: 16px;
`;

export const LineItem = styled.span`
  color: ${Colour.Gray[400]};
  ${BodySmallRegular};
  display: grid;
  column-gap: 32px;
  grid-template-columns: 1fr 1fr;
  & span.item-value {
    color: ${Colour.Gray[700]};
    ${BodySmallMedium};
  }
  &.header {
    color: ${Colour.Gray[800]};
    ${BodySmallSemiBold};
  }
`;

export const QuestionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.extra-margin {
    margin-top: 8px;
  }
`;
