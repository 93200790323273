//@ts-nocheck
import { InputNumber, Form } from "antd";
import React from "react";
import {
  AMENDMENT_AMOUNT_CURRENCY_TYPE,
  AMENDMENT_AMOUNT_DECIMAL_TYPE,
  AMENDMENT_AMOUNT_WHOLENUMBER_TYPE,
} from "../constants/payrun";
import numeral from "numeral";
import {
  conditionalParameter,
  getConditionalResponse,
  getLowerCase,
  getOptionalParameter,
  manageDecimalPlaces,
  getMaxAmount,
  hasMaxAmount,
  hasMinAmount,
  validateInputValueIncludesOnlyNumbers,
  numberFormatter,
} from "../libs";

type InputTypeNumberProps = {
  handleChange: (value: any) => void;
  question: any;
  className?: string;
  placeholder?: string;
  name?: string;
  label?: string;
  formItemClassName?: string;
  rules?: any;
  useFormItem?: boolean;
  disabled?: boolean;
};

const strgnDefault = "";

const InputTypeNumber = ({
  handleChange,
  question,
  name,
  label,
  rules,
  useFormItem,
  className = strgnDefault,
  placeholder = strgnDefault,
  formItemClassName = strgnDefault,
  disabled = false,
}: InputTypeNumberProps) => {
  const numberType = getLowerCase(
    conditionalParameter({ data: question, field: "type" })
  );

  const handleBlur = ({ value }: any) => {
    if (hasMinAmount({ question })) {
      if (value && numeral(value)["_value"] < question.threshold.minAmount) {
        return handleChange(question.threshold.minAmount);
      }
    }
    if (
      value &&
      hasMaxAmount({ question }) &&
      numeral(value)["_value"] > question.threshold.maxAmount
    ) {
      return handleChange(question.threshold.maxAmount);
    }
  };

  const onChange = ({ value }: any) => {
    return handleChange(
      value &&
        conditionalParameter({ data: question, field: "type" }) ===
          AMENDMENT_AMOUNT_WHOLENUMBER_TYPE
        ? Number(value)
        : manageDecimalPlaces({ value })
    );
  };

  const decimalOrCurrency =
    numberType == AMENDMENT_AMOUNT_CURRENCY_TYPE ||
    numberType == AMENDMENT_AMOUNT_DECIMAL_TYPE;

  const props = {
    onChange,
    onBlur: (e) =>
      handleBlur({
        value: e.currentTarget.value,
      }),
    value: getConditionalResponse({
      condition: question.value,
      resp1: Number(question.value),
      resp2: "",
    }),
    onKeyDown: (e) =>
      validateInputValueIncludesOnlyNumbers({
        e,
        maxAmount: getMaxAmount({ question }),
        hasDecimal: !decimalOrCurrency,
        isPositive: true,
      }),
    className: `min-w-[50%] w-3/6 hideNumberPointers ${className}`,
    placeholder,
  };

  if (decimalOrCurrency) {
    props.formatter = numberFormatter;
    props.parser = (value) => value.replace(/\$\s?|(,*)/g, "");
    props.disabled = getOptionalParameter({
      value1: !question,
      value2: !question.enabled,
    });
  }

  const WrapperTag = getConditionalResponse({
    condition: useFormItem,
    resp1: Form.Item,
    resp2: React.Fragment,
  });

  return (
    <WrapperTag
      className={formItemClassName}
      name={name}
      label={label}
      rules={rules}
    >
      <InputNumber {...props} />
    </WrapperTag>
  );
};
export default InputTypeNumber;
