import { Action, ActiveNavigator, AuthActions, AuthState } from './auth-provider.types';

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  activeNavigator: ActiveNavigator.signinSilent,
};

/**
 * Handles how that state changes in the `useAuth` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case AuthActions.Initialized:
    case AuthActions.UserLoaded:
      return {
        ...state,
        user: action.user,
        isLoading: false,
        isAuthenticated: action.user ? !action.user.expired : false,
        error: undefined,
        activeNavigator: ActiveNavigator.empty,
      };
    case AuthActions.UserUnloaded:
      return {
        ...state,
        user: undefined,
        isAuthenticated: false,
        activeNavigator: ActiveNavigator.empty,
      };
    case AuthActions.ReturnUrl:
      return {
        ...state,
        returnUrl: action.returnUrl,
      };
    case AuthActions.NavigatorInit:
      return {
        ...state,
        isLoading: true,
        activeNavigator: action.method,
      };
    case AuthActions.NavigatorClosed:
      // we intentionally don't handle cases where multiple concurrent navigators are open
      return {
        ...state,
        isLoading: false,
        activeNavigator: ActiveNavigator.empty,
      };
    case AuthActions.Error:
      return {
        ...state,
        isLoading: false,
        activeNavigator: ActiveNavigator.empty,
        error: action.error,
      };
    default:
      return {
        ...state,
        isLoading: false,
        activeNavigator: ActiveNavigator.empty,
        error: new Error(`unknown type ${action['type']}`),
      };
  }
};
