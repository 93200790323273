import React from "react";
import { Form, Select, Spin } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import { ADHOC_PAYRUN_ID } from "../../../constants";

type Option = any;

type Props = {
  label: string;
  name: string;
  required: boolean;
  loading?: boolean;
  onChange?: (e: any) => void;
  list: Option[];
  ruleMsg?: string;
  className?: string;
  idKey?: string;
  nameKey?: string;
  value?: string;
  disabled?: boolean;
  seperateAdhoc?: boolean;
  showPayDate?: boolean;
};

const classNameDefault = "";
const idKeyDefault = "id";
const nameKeyDefault = "name";

const ListSelectField = ({
  loading,
  value,
  label,
  name,
  ruleMsg,
  required,
  onChange,
  className = classNameDefault,
  idKey = idKeyDefault,
  nameKey = nameKeyDefault,
  list,
  disabled,
  seperateAdhoc,
  showPayDate,
}: Props) => (
  <Form.Item
    label={label}
    name={name}
    className={className}
    rules={[
      {
        required: required,
        message: ruleMsg,
      },
    ]}
  >
    <Select
      loading={loading}
      value={value}
      placeholder="Select"
      showSearch
      onChange={(e) => onChange?.(e)}
      optionFilterProp="name"
      filterOption
      notFoundContent={loading ? <Spin size="small" /> : null}
      disabled={disabled}
    >
      {mustBeArray(list).map((item: any) => {
        return item?.enabled || !Reflect.has(item, "enabled") ? ( // Some list could have the key enabled to dissable options
          <Select.Option
            key={item[idKey]}
            value={item[idKey]}
            name={item[nameKey]}
          >
            {item[nameKey]}
            {showPayDate && item.payDate && (
              <>
                {" "}
                -<i className="text-gray-700"> {item.payDate}</i>
              </>
            )}
            {seperateAdhoc && item?.payrunTypeID === ADHOC_PAYRUN_ID
              ? " (Adhoc)"
              : ""}
          </Select.Option>
        ) : null;
      })}
    </Select>
  </Form.Item>
);

export default ListSelectField;
