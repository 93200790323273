export const SYSTEM_ADMIN_USERNAME = "systemadmin@expedo.co";

// Authorized routes
export const SYSTEM_ADMIN_AUTHORIZED_ROUTES = [
  "/company",
  "/businessunit",
  "/payrollprovider",
  "/billingserviceproviders",
  "/payschedules",
  "/payrun",
  "/invoices",
  "/users",
  "/emailTemplates",
];
export const PSP_USER_AUTHORIZED_ROUTES = [
  "/company",
  "/businessunit",
  "/payschedules",
  "/payrun",
  "/invoices",
  "/users",
];
export const COMPANY_USER_AUTHORIZED_ROUTES = [
  "/payrun",
  "/invoices",
  "/users",
];

//Authorisation DOM component names
export const GLOBAL_PAYROLL_APPLICATION_NAME = "expedoGlobaldash";
export const USER_PROFILE_COMPONENT_NAME = "userProfileMenuItem";
export const USER_PROFILE_DETAIL_CONTAINER =
  "userProfileGeneralDetailContainer";
export const USER_PROFILE_MULTI_TAB_CONTAINER = "userProfileMultiTabContainer";
export const HR_MENU_ITEM = "hrMenuItem";

// Dashboard
const DASHBOARD_MENU_ITEM = "dashboardMenuItem";
// Company
export const COMPANY_CREATE_SIMPLE_BUTTON = "companyCreateSimpleButton";
export const COMPANY_MENU_ITEM = "companyMenuItem";
export const COMPANY_FILTER_CONTAINER = "companyFilterContainer";
export const COMPANY_LIST_CONTAINER = "companyListContainer";
export const COMPANY_DETAIL_MULTI_TAB_CONTAINER =
  "companyDetailMultiTabContainer";
export const COMPANY_BREAD_CRUMB_CONTAINER = "companyBreadCrumbContainer";

// Business Unit
export const BUSINESS_UNIT_CREATE_SIMPLE_BUTTON =
  "businessUnitCreateSimpleButton";
export const BUSINESS_UNIT_MENU_ITEM = "businessUnitMenuItem";
export const BUSINESS_UNIT_FILTER_CONTAINER = "businessUnitFilterContainer";
export const BUSINESS_UNIT_LIST_CONTAINER = "businessUnitListContainer";
export const BUSINESS_UNIT_DETAIL_MULTI_TAB_CONTAINER =
  "businessUnitDetailMultiTabContainer";
export const BUSINESS_UNIT_BREAD_CRUMB_CONTAINER =
  "businessUnitBreadCrumbContainer";
export const BUSINESS_UNIT_ASSIGN_PAYROLL_MANAGER_MODAL_CONTAINER =
  "businessUnitAssignPayrollManagerModalContainer";

export const BUSINESS_UNIT_PAYRUN_INTEGRATION_SETTING =
  "businessUnitPayrunSettingSwitchButton";

export const BUSINESS_UNIT_AMENDMENT_INTEGRATION_SETTING =
  "businessUnitAmendmentSettingSwitchButton";

export const BUSINESS_UNIT_REPORTS_INTEGRATION_SETTING =
  "businessUnitReportsSettingSwitchButton";

// Payroll Provider
export const PAYROLL_PROVIDER_CREATE_SIMPLE_BUTTON =
  "payrollProviderCreateSimpleButton";
export const PAYROLL_PROVIDER_MENU_ITEM = "payrollProviderMenuItem";
export const PAYROLL_PROVIDER_LIST_CONTAINER = "payrollProviderListContainer";
export const PAYROLL_PROVIDER_FILTER_CONTAINER =
  "payrollProviderFilterContainer";
export const PAYROLL_PROVIDER_MULTI_TAB_CONTAINER =
  "payrollProviderMultiTabContainer";
export const PAYROLL_PROVIDER_BREAD_CRUMB_CONTAINER =
  "payrollProviderBreadCrumbContainer";

// Billing Service Provider
export const BILLING_PROVIDER_MENU_ITEM = "billingServiceProviderMenuItem";
export const BILLING_PROVIDER_CREATE_SIMPLE_BUTTON =
  "billingServiceProviderCreateSimpleButton";

export const BILLING_PROVIDER_LIST_CONTAINER =
  "billingServiceProviderListContainer";
export const BILLING_PROVIDER_GENERAL_GRID =
  "billingServiceProviderGeneralGrid";
export const BILLING_PROVIDER_GENERAL_TABLE =
  "billingServiceProviderGeneralTable";

// export const BILLING_PROVIDER_SUMMARY_CONTAINER =
//   "billingServiceProviderSummaryContainer";
export const BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER =
  "billingServiceProviderDetailMultiTabContainer";

export const BILLING_PROVIDER_FILTER_CONTAINER =
  "billingServiceProviderFilterContainer";
export const BILLING_PROVIDER_NAME_TEXT_BOX_FILTER =
  "billingServiceProviderNameTextboxFilter";
export const BILLING_PROVIDER_COUNTRY_DROPDOWN_FILTER =
  "billingServiceProviderCountryDropdownFilter";
export const BILLING_PROVIDER_SEARCH_BUTTON =
  "billingServiceProviderSearchButton";
export const BILLING_PROVIDER_RESET_FILTER =
  "billingServiceProviderResetFilter";

export const BILLING_PROVIDER_BREAD_CRUMB_CONTAINER =
  "billingServiceProviderBreadCrumbContainer";
export const BILLING_PROVIDER_VIEW_DETAIL_LINK =
  "billingServiceProviderViewDetailLink";
export const BILLING_PROVIDER_DETAIL_TAB_CONTAINER =
  "billingServiceProviderDetailTabContainer";
export const BILLING_PROVIDER_DETAIL_CHILD_TAB_CONTAINER =
  "billingServiceProviderBasicDetailChildTabContainer";
export const BILLING_PROVIDER_DETAIL_GENERAL_CONTAINER =
  "billingServiceProviderDetailGeneralContainer";
export const BILLING_PROVIDER_EDIT = "billingServiceProviderEdit";
export const BILLING_PROVIDER_LOGO_CHILD_TAB_CONTAINER =
  "billingServiceProviderLogoChildTabContainer";
export const BILLING_PROVIDER_LOGO_GENERAL_CONTAINER =
  "billingServiceProviderLogoGeneralContainer";

export const BILLING_PROVIDER_XERO_MAPPING_TAB_CONTAINER =
  "billingServiceProviderXeroMappingTabContainer";
export const BILLING_PROVIDER_XERO_MAPPING_CHILD_TAB_CONTAINER =
  "billingServiceProviderXeroMappingChildTabContainer";
export const BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER =
  "billingServiceProviderXeroMappingListContainer";
export const BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID =
  "billingServiceProviderXeroMappingGeneralGrid";
// export const BILLING_PROVIDER_XERO_MAPPING_EDIT =
//   "billingServiceProviderXeroMappingEdit";
export const BILLING_PROVIDER_XERO_MAPPING_GENERAL_TABLE =
  "billingServiceProviderXeroMappingGeneralTable";
export const BILLING_PROVIDER_XERO_MAPPING_ADD_BUTTON =
  "billingServiceProviderXeroMappingAddButton";
export const BILLING_PROVIDER_ACCOUNT_TAB_CONTAINER =
  "billingServiceProviderAccountTabContainer";
export const BILLING_PROVIDER_ACCOUNT_CHILD_TAB_CONTAINER =
  "billingServiceProviderAccountChildTabContainer";
export const BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER =
  "billingServiceProviderAccountListContainer";
export const BILLING_PROVIDER_ACCOUNT_GENERAL_GRID =
  "billingServiceProviderAccountGeneralGrid";
export const BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE =
  "billingServiceProviderAccountGeneralTable";
export const BILLING_PROVIDER_ACCOUNT_EDIT =
  "billingServiceProviderAccountEdit";
export const BILLING_PROVIDER_ACCOUNT_DELETE =
  "billingServiceProviderAccountDelete";
export const BILLING_PROVIDER_ACCOUNT_ADD =
  "billingServiceProviderAccountAddButton";

// Pay Run
export const PAYRUN_CREATE_SIMPLE_BUTTON = "payrunCreateSimpleButton";
export const PAYRUN_MENU_ITEM = "payrunMenuItem";
export const PAYRUN_EXTERNAL_LIST_CONTAINER = "payrunExternalListContainer";
export const PAYRUN_EARNINGLINES_EXPANDABLE_GENERAL_CONTAINER =
  "payrunEarninglinesExpandableGeneralContainer";
export const PAYRUN_EARNINGLINES_CLEAR_BUTTON = "payrunEarninglinesClearButton";
export const PAYRUN_PREPARE_BUTTON = "payrunPrepareButton";
export const PAYRUN_REJECT_BUTTON = "payrunRejectButton";
export const PAYRUN_APPROVE_BUTTON = "payrunApproveButton";
export const PAYRUN_SUMMARY_GENERAL_CONTAINER = "payrunSummaryGeneralContainer";
export const PAYRUN_EXPORT_TO_XERO_BUTTON = "payrunExportToXeroSimpleButton";
export const PAYRUN_FILTER_CONTAINER = "payrunFilterContainer";
export const PAYRUN_MAKE_PAYMENT_BUTTON = "payrunMakePaymentSimpleButton";
export const PAYRUN_CONFIRM_PAYMENT_BUTTON = "payrunConfirmPaymentSimpleButton";
export const PAYRUN_PROCESS_BUTTON = "payrunProcessSimpleButton";
export const PAYRUN_COMPLETE_BUTTON = "payrunCompleteSimpleButton";
export const PAYRUN_MULTI_TAB_CONTAINER = "payrunMultiTabContainer";
export const PAYRUN_INVOICE_DETAIL_CONTAINER =
  "payrunInvoiceDetailsGeneralContainer";
export const GENERATE_ABA_SIMPLE_BUTTON = "payrunGenerateABASimpleButton";
export const DOWNLOAD_PAYRUN_PDF_BUTTON = "payrunDownloadPdf";
export const PAYMENT_IN_PROGRESS_BUTTON = "payrunPaymentInProcessSimpleButton";
export const PAYRUN_LIST_CONTAINER = "payrunListContainer";

// Currency conversion fees update
export const PAYRUN_CURRENCY_CONVERSION_FEES = "payrunCurrencyConversionFees";
export const PAYRUN_CURRENCY_CONVERSION_FEES_CANCEL =
  "payrunCurrencyConversionFeesCancel";
export const PAYRUN_CURRENCY_CONVERSION_FEES_SAVE =
  "payrunCurrencyConversionFeesSave";
export const PAYRUN_BREAD_CRUMB_CONTAINER = "payrunBreadCrumbContainer";
export const PAYRUN_STATIC_CONTENT_SHOW_BUTTON =
  "payrunStaticContentShowButton";
export const PAYRUN_STATIC_CONTENT_HIDE_BUTTON =
  "payrunStaticContentHideButton";
export const PAYRUN_REPORT_UPLOAD_BUTTON = "payrunReportUploadButton";
export const PAYRUN_ATTACH_AMENDMENT_CONTAINER =
  "payrunAttachNextPayrunAmendmentModalContainer";
//export const PAYRUN_GENERAL_DETAIL_CONTAINER = "payrunGeneralDetailContainer";
export const PAYRUN_STATUS_STEPS_CONTAINER =
  "payrunStatusStepsGeneralContainer";
export const PAYRUN_AMENDMENT_LIST_CONTAINER = "payrunAmendmentListContainer";
export const PAYRUN_AMENDMENT_STATUS_SUBMIT_BUTTON =
  "payrunAmendmentSubmitButton";
export const PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER =
  "payrunAmendmentApproveRejectListContainer";

export const PAYRUN_CHANGELOG_MODAL_CONTAINER = "payrunChangeLogModalContainer";
// Pay Schedules
export const CREATE_PAY_SCHEDULE = "createPaySchedule";
export const PAYSCHEDULE_MENU_ITEM = "payScheduleMenuItem";
export const PAYSCHEDULE_DETAIL_MULTI_TAB_CONTAINER =
  "payScheduleDetailMultiTabContainer";
export const PAYSCHEDULE_BREAD_CRUMB_CONTAINER =
  "payScheduleBreadCrumbContainer";
export const PAYSCHEDULE_FILTER_CONTAINER = "payScheduleFilterContainer";
export const PAYSCHEDULE_LIST_CONTAINER = "payScheduleListContainer";
export const PAYSCHEDULE_STATUS_CONTAINER = "payScheduleStatusContainer";

// Invoice
export const INVOICE_MENU_ITEM = "invoiceMenuItem";
export const INVOICE_FILTER_CONTAINER = "invoiceFilterContainer";

// Varinace
export const VARIANCE_MENU_ITEM = "varianceMenuItem";

export const VARIANCE_FILTER_CONTAINER = "varianceFilterContainer";
export const VARIANCE_COMPANY_DROPDOWN_FILTER = "varianceCompanyDropdownFilter";
export const VARIANCE_PAYSCHEDULE_DROPDOWN_FILTER =
  "variancePayScheduleDropdownFilter";
export const VARIANCE_DATE_RANGE_DROPDOWN_FILTER = "varianceDateRangeFilter";
export const VARIANCE_SEARCH_BUTTON = "varianceSearchButton";
export const VARIANCE_RESET_FILTER = "varianceResetFilter";

export const VARIANCE_LIST_CONTAINER = "varianceListContainer";
export const VARIANCE_GENERAL_GRID = "varianceGeneralGrid";
export const VARIANCE_GENERAL_TABLE = "varianceGeneralTable";

// General Amendments
export const GENERAL_AMENDMENT_MENU_ITEM = "generalAmendmentMenuItem";
export const GENERAL_AMENDMENT_CREATE_SIMPLE_BUTTON =
  "generalAmendmentCreateSimpleButton";

export const GENERAL_AMENDMENT_FILTER_CONTAINER =
  "generalAmendmentFilterContainer";
export const GENERAL_AMENDMENT_SEARCH_BUTTON = "generalAmendmentSearchButton";
export const GENERAL_AMENDMENT_RESET_FILTER = "generalAmendmentResetFilter";
export const GENERAL_AMENDMENT_FILTER_TEXTBOX =
  "generalAmendmentNameTextboxFilter";
export const GENERAL_AMENDMENT_FILTER_AMENDMENT_TYPE =
  "generalAmendmentTypeDropdownFilter";
export const GENERAL_AMENDMENT_FILTER_CHANGE_TYPE =
  "generalAmendmentChangeTypeDropdownFilter";
export const GENERAL_AMENDMENT_FILTER_DATE_RANGE =
  "generalAmendmentDateRangeFilter";
export const GENERAL_AMENDMENT_FILTER_BUSINESS_UNIT =
  "generalAmendmentBusinessUnitFilter";
export const GENERAL_AMENDMENT_FILTER_REQUESTED_BY =
  "generalAmendmentRequestedByFilter";

export const GENERAL_AMENDMENT_PENDING_DETAIL_VIEW =
  "generalAmendmentPendingDetailView";
export const GENERAL_AMENDMENT_PENDING_REJECT = "generalAmendmentPendingReject";
export const GENERAL_AMENDMENT_PENDING_APPROVE =
  "generalAmendmentPendingApprove";
export const GENERAL_AMENDMENT_PENDING_APPLY_IN_KEYPAY =
  "generalAmendmentPendingApplyInKepay";
export const GENERAL_AMENDMENT_PENDING_DELETE = "generalAmendmentPendingDelete";

export const GENERAL_AMENDMENT_PENDING_LIST_CONTAINER =
  "generalAmendmentPendingListContainer";
export const GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT =
  "generalAmendmentPendingGeneralTable";
export const GENERAL_AMENDMENT_PENDING_GRID_COMPONENT =
  "generalAmendmentPendingGridGeneralGrid";

export const GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER =
  "generalAmendmentCompletedListContainer";
export const GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT =
  "generalAmendmentCompletedGeneralTable";
export const GENERAL_AMENDMENT_COMPLETED_GRID_COMPONENT =
  "generalAmendmentCompletedGridGeneralGrid";

export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_TAB_CONTAINER =
  "generalAmendmentPayrunAmendmentTabContainer";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_CHILD_TAB_CONTAINER =
  "generalAmendmentPayrunAmendmentChildTabContainer";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_LIST_CONTAINER =
  "generalAmendmentPayrunAmendmentListContainer";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_GRID =
  "generalAmendmentPayrunAmendmentGeneralGrid";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_GENERAL_TABLE =
  "generalAmendmentPayrunAmendmentGeneralTable";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_EDIT =
  "generalAmendmentPayrunAmendmentEdit";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DELETE =
  "generalAmendmentPayrunAmendmentDelete";
export const GENERAL_AMENDMENT_PAYRUN_AMENDMENT_DETAIL_VIEW =
  "generalAmendmentPayrunAmendmentDetailView";

export const GENERAL_AMENDMENT_ADHOC_AMENDMENT_TAB_CONTAINER =
  "generalAmendmentAdhocAmendmentTabContainer";
//Payrun Amendments
export const PAYRUN_AMENDMENT_DELETE = "payrunAmendmentDelete";
export const PAYRUN_AMENDMENT_RESUBMIT = "payrunAmendmentResubmit";
// Users
export const USER_MENU_ITEM = "userMenuItem";
export const USER_DETAIL_GENERAL_CONTAINER = "usersDetailGeneralContainer";
export const USER_MULTI_TAB_CONTAINER = "userMultiTabContainer";
export const USER_BREAD_CRUMB_CONTAINER = "userBreadCrumbContainer";
export const USER_FILTER_CONTAINER = "userFilterContainer";

export const USER_COMPANY_GENERAL_ADD_BUTTON = "userCompanyGeneralAddButton";

export const USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON =
  "userPayrollServiceProviderAddButton";

// Email Templates
const EMAIL_TEMPLATE_MENU_ITEM = "emailTemplate";

// Authorization Management
const AUTHORIZATION_MANAGEMENT_MENU_ITEM = "authorizationManagement";

//Settings
const SETTINGS_MENU_ITEM = "settingsMenuItem";

//Reports
export const REPORTS_MENU_ITEM = "reportsMenuItem";

// Scopes
export const SCOPES = {
  DASHBOARD: DASHBOARD_MENU_ITEM,
  COMPANY: COMPANY_MENU_ITEM,
  BUSINESSUNIT: BUSINESS_UNIT_MENU_ITEM,
  PAYSCHEDULE: PAYSCHEDULE_MENU_ITEM,
  PAYRUN: PAYRUN_MENU_ITEM,
  USER: USER_MENU_ITEM,
  PAYROLLPROVIDER: PAYROLL_PROVIDER_MENU_ITEM,
  INVOICE: INVOICE_MENU_ITEM,
  EMAIL: "emailTemplate",
  BILLINGSERVICEPROVIDER: BILLING_PROVIDER_MENU_ITEM,
  VARIANCE: VARIANCE_MENU_ITEM,
  GENERALAMENDMENT: GENERAL_AMENDMENT_MENU_ITEM,
  SETTINGS: SETTINGS_MENU_ITEM,
  SETTINGS_EMAIL: EMAIL_TEMPLATE_MENU_ITEM,
  SETTINGS_AUTH_MGMT: AUTHORIZATION_MANAGEMENT_MENU_ITEM,
  REPORTS: REPORTS_MENU_ITEM,
};


