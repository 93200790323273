import requireAuth from "../../Common/requireAuth";
import { SCOPES } from "../authorizationClaims";
import VarianceIndex from "../../Organization/pages/variance";
import VarianceContainer from "../../Organization/containers/Variance";

export const VarianceRoutesWithSidebar = [
  {
    path: "variances",
    scope: SCOPES.VARIANCE,
    index: "variances",
    container: VarianceContainer,
    componentPath: "../../Organization/pages/variance",
    element: (
      <VarianceContainer
        scope={SCOPES.VARIANCE}
        Layout={requireAuth(VarianceIndex)}
      />
    ),
  },
];
