import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { Colour, Shadow } from '@xemplo/style-constants';
import { zIndex } from '@xemplo/zindex';

import { DrawerPosition } from './drawer.types';

export { DrawerHeaderWrapper } from './header/drawer-header.styles';

export const DrawerWrapper = styled.aside<{ $width: number }>`
  --drawer-scroll-shadown-bottom: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${({ $width }) => $width}px;
  ${Shadow.Medium2};
  background: ${Colour.White[100]};
  border-left: 1px solid ${Colour.Gray[200]};

  @media (max-width: ${({ $width }) => $width}px) {
    width: 100vw;
  }

  &[data-position=${DrawerPosition.Right}] {
    right: ${({ $width }) => -$width}px;
    transition: right 0.3s ease-out;

    &[data-open='true'] {
      right: 0;
    }
  }

  &[data-position=${DrawerPosition.Left}] {
    left: ${({ $width }) => -$width}px;
    transition: left 0.3s ease-out;

    &[data-open='true'] {
      left: 0;
    }
  }
`;

export const DrawerContentWrapper = styled.div<{ $offset?: number }>`
  display: flex;
  flex-direction: column;
  top: ${({ $offset }) => ($offset ? `${$offset}px` : undefined)};
  height: 100%;
  width: inherit;
  position: fixed;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 24px;
  z-index: ${zIndex.ModalCloseButton};
`;
