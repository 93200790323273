type Id = string | number | null;

export const AmendmentAPI = {
  amendments: '<%userArea%>/payruns/amendments',
  businessUnits: '<%userArea%>/businessUnits',
  amendmentById: (amendmentId: Id) => `<%userArea%>/payruns/amendment/${amendmentId}`,
  changeLogById: (amendmentId: Id) =>
    `<%userArea%>/payruns/amendment/${amendmentId}/history`,
  bulkCreateAmendment: '<%userArea%>/payruns/amendment/bulkcreate',
  changeTasks: '<%userArea%>/lookups/changeTasks',
  changeTaskSubTypes: '<%userArea%>/changeTaskSubTypes',
  filesByAmendmnetId: (amendmentId: Id) =>
    `<%userArea%>/payruns/amendment/${amendmentId}/files`,
  downloadFileByFileId: (fileId: Id) =>
    `<%userArea%>/payruns/amendment/file/${fileId}/download`,
  deletePayrunAmendmentById: (payrunId: Id, amendmentId: Id) =>
    `<%userArea%>/payruns/${payrunId}/amendment/${amendmentId}`,
  resubmitPayrunAmendmentById: (payrunId: Id, amendmentId: Id) =>
    `<%userArea%>/payruns/${payrunId}/amendment/${amendmentId}/resubmit`,
  createPayInstruction: '<%userArea%>/payruns/amendment/payInstruction',
};
