//@ts-nocheck
import React, { Component } from "react";
import CreateBasicDetailForm from "../../../Common/createBasicDetailForm";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Layout,
  message,
  Card,
  Modal,
  Row,
  Col,
  Checkbox,
  Switch,
  Radio,
} from "antd";
import NoRecord from "../../../Common/NoRecord";
import CreateSuccess from "../../../Common/CreateSuccess";
import {
  errorDisplay,
  delay,
  mustBeArray,
  filterGeoAddressLocation,
  isAddressSame,
  errorHandler,
  conditionalParameter,
  getOptionalParameter,
  getMatchParams,
  getClassNameForActiveInactiveComponent,
  findAuthorizationClaims,
  validateUsersAccessRights,
} from "../../../libs/";
import _ from "lodash";
import {
  AUSTRALIA_COUNTRY_ID,
  GOOGLE_MAP_URL,
} from "../../../constants/entities";
import axios from "axios";
import FormNavbar from "../../../Layout/FormNavbar";
import { PAYROLL_PROCESSING_SYSTEMS } from "../../../constants/payroll";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { isABNValidated } from "../../../libs/validations";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import UserBasicDetailsForm from "../../components/payrollProvider/UserBasicDetailsForm";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import AssignPayrollAdminForm from "../../components/payrollProvider/AssignPayrollAdminForm";
import { FormStepsCol } from "../../../Common/ui";
import { PAYROLL_PROVIDER_CREATE_SIMPLE_BUTTON } from "../../../constants/authorizationClaims";
import { withRouter } from "../../../hooks";
import { connect } from "react-redux";
import { PayrollInstanceLookup } from "../../components/payrollProvider/create";

const { Header, Content } = Layout;

const PayrollAdminFormLabels = {
  id: 1,
  label: "Admin Details",
  description:
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
};

type State = any;

class CreatePSP extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);

    this.state = {
      isSameAddress: false,
      disableField: false,
      fetching: false,
      confirmLoading: false,
      states: [],
      countries: [],
      timezone: [],
      editMode: false,
      newPSPId: "",
      processingSystem: PAYROLL_PROCESSING_SYSTEMS,
      enabledProcessingSystems: [],
      checkedProcessing: false,
      activeIndex: 1,
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
        },
      ],
      logo: "",
      uploadLoading: false,
      createNewPayrollAdmin: true,
      assignedPayrollAdminList: [],
      registeredAddress1: "",
      postalAddress1: "",
      directoryServiceInstanceId: null,
    };
    this.fetchCountries = _.debounce(this.fetchCountries);
    this.fetchTimezone = _.debounce(this.fetchTimezone);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.toggleSystems = this.toggleSystems.bind(this);
  }
  loadGoogleMaps = (callBack) => {
    const existingScript = document.getElementById("googlePlacesScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAP_URL;
      script.id = "googlePlacesScript";
      document.body.appendChild(script);
      //action to do after a script is loaded in our case setState
      script.onload = () => {
        if (callBack) callBack();
      };
    }
    if (existingScript && callBack) callBack();
  };
  unloadGoogleMaps = () => {
    let googlePlacesScript = document.getElementById("googlePlacesScript");
    if (googlePlacesScript) {
      googlePlacesScript.remove();
    }
  };
  componentWillUnmount() {
    this.unloadGoogleMaps();
  }
  componentDidMount() {
    const { match, pageClaims } = this.props;
    const editMode = getMatchParams({ match, field: "id" }) ? true : false;
    // Check if user has access to create company. If not redirect to home.
    const createButton = findAuthorizationClaims({
      claims: pageClaims.components,
      name: PAYROLL_PROVIDER_CREATE_SIMPLE_BUTTON,
    });
    if (!validateUsersAccessRights({ claim: createButton })) return;

    this.loadGoogleMaps(() => {
      this.setState({ mapLoaded: true });
    });
    this.fetchTimezone();
    if (editMode) {
      this.props
        .getPayrollProvider({
          id: match.params.id,
          cancelToken: this.signal.token,
        })
        .then((resp) => {
          if (resp && resp.status && resp.data) {
            this.props.updateHeader({
              header: {
                enableBack: true,
                title: `${resp.data.name}`,
              },
            });
            const payrollprovider = resp.data;
            this.fetchCountries(payrollprovider.countryId);

            this.props.form.setFieldsValue({
              postalAddress2: payrollprovider.postalAddressLine2,
              postalSuburb: payrollprovider.postalSuburb,
              postalPostCode: payrollprovider.postalPostalCode,
              postalState: Number(payrollprovider.postalAddressStateId),
              registeredAddress2: payrollprovider.registeredAddressLine2,
              registeredSuburb: payrollprovider.registeredSuburb,
              registeredPostCode: payrollprovider.registeredPostalCode,
              registeredState: Number(payrollprovider.registeredAddressStateId),
              name: payrollprovider.name,
              country: Number(payrollprovider.countryId),
              registeredName: payrollprovider.registeredBusinessName,
              abn: payrollprovider.abn,
              isNameSameAsRegisteredBusinessName:
                payrollprovider?.isNameSameAsRegisteredBusinessName,
            });
            this.setState((prevState) => {
              prevState.logo = payrollprovider?.logoURL;
              prevState.isSameAddress = isAddressSame(resp.data);
              prevState.editMode = true;
              prevState.checkedProcessing = true;
              prevState.processingSystem[0].checked = true;
              prevState.postalAddress1 = payrollprovider.postalAddressLine1;
              prevState.registeredAddress1 =
                payrollprovider.registeredAddressLine1;
              prevState.directoryServiceInstanceId =
                payrollprovider.directoryServiceInstanceId;
              return prevState;
            });
            delay(300).then(() => {
              this.props.form.setFieldsValue({
                operatingTimezone: payrollprovider.operatingTimeZoneID,
                directoryServiceInstanceId:
                  payrollprovider.directoryServiceInstanceId,
              });
            });
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
    } else {
      this.setState({
        formLabels: [...this.state.formLabels, PayrollAdminFormLabels],
      });
      this.fetchCountries("");
    }
  }

  fetchCountries = (val) => {
    this.props.countriesLookup({ options: { name: val } }).then((response) => {
      if (response.status) {
        this.setState({ countries: response.data }, () => {
          const defaultCountryID = val ? val : AUSTRALIA_COUNTRY_ID;
          this.updateLocationFields(Number(defaultCountryID), "id");
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
    });
  };

  updateLocationFields = (defaultCountryID, key, registeredAddressSelect) => {
    return new Promise((resolve, rej) => {
      const selectedCountry = _.find(
        this.state.countries,
        (country) => country[key] === defaultCountryID
      );
      if (selectedCountry && selectedCountry.id) {
        if (
          registeredAddressSelect &&
          selectedCountry.id !== this.props.form.getFieldValue("country")
        ) {
          this.props.form.resetFields(["abn", "operatingTimezone"]);
        }

        this.props.form.setFieldsValue({ country: selectedCountry.id });

        this.populateStates(selectedCountry.id, true).then((res) => {
          if (res.status) {
            resolve(true);
          }
        });
      } else {
        this.props.form.resetFields(["country"]);
      }
    });
  };

  fetchTimezone = () => {
    this.props.timezoneLookup({ options: {} }).then((resp) => {
      let timezone = mustBeArray(resp && resp.data);
      this.setState({ timezone }, () => {
        if (!_.isUndefined(this.props.model)) {
          this.props.form.setFieldsValue({
            operatingTimezone: this.props.model.operatingTimeZoneID,
          });
        }
      });
    });
  };

  populateStates = (val, select) => {
    let params = {};
    params.countryId = val;

    return this.props.populateStates({ options: params }).then((response) => {
      if (response.status) {
        this.setState({ states: response.data });
        delay(1000).then(() => {
          if (!_.isUndefined(this.props.model) && select) {
            let postalIDs = _.map(response.data, (state) => state.id);
            if (
              postalIDs.includes(Number(this.props.model.postalAddressStateId))
            ) {
              this.props.form.setFieldsValue({
                postalState: Number(this.props.model.postalAddressStateId),
              });
            }
            if (
              postalIDs.includes(
                Number(this.props.model.registeredAddressStateId)
              )
            ) {
              this.props.form.setFieldsValue({
                registeredState: Number(
                  this.props.model.registeredAddressStateId
                ),
              });
            }
          }
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }

      return response;
    });
  };

  onAddressSelect = (addressObject, mode) => {
    return new Promise((resolve, rej) => {
      var address = filterGeoAddressLocation(addressObject);
      if (mode === "registered") {
        this.props.form.resetFields(["registeredState"]);

        this.props.form.setFieldsValue({
          registeredSuburb: address.city,
          registeredPostCode: address.postalcode,
        });
        this.setState({ registeredAddress1: address.address1 });
        if (this.state.isSameAddress) {
          this.props.form.resetFields(["postalState"]);

          this.props.form.setFieldsValue({
            postalSuburb: address.city,
            postalPostCode: address.postalcode,
          });
          this.setState({ postalAddress1: address.address1 });
        }
      } else if (mode === "postal") {
        this.props.form.resetFields(["postalState"]);
        this.setState({ postalAddress1: address.address1 });
        this.props.form.setFieldsValue({
          postalSuburb: address.city,
          postalPostCode: address.postalcode,
        });
      }

      this.updateLocationFields(
        address.country_code,
        "code",
        mode === "registered"
      ).then((res) => {
        if (!_.isUndefined(address.state)) {
          const selectedState = _.find(
            this.state.states,
            (st) => st.code.toLowerCase() === address.state.toLowerCase()
          );
          if (mode === "postal") {
            this.props.form.setFieldsValue({
              postalState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });
          } else {
            this.props.form.setFieldsValue({
              registeredState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });

            if (this.state.isSameAddress) {
              this.props.form.setFieldsValue({
                postalState: conditionalParameter({
                  data: selectedState,
                  field: "id",
                }),
              });
            }
          }
        }
      });
      resolve(true);
    });
  };

  onSameAddressChange = (val) => {
    this.setState({
      isSameAddress: val,
    });
    if (val) {
      this.props.form.setFieldsValue({
        postalAddress: this.props.form.getFieldValue("registeredAddress"),

        postalAddress2: this.props.form.getFieldValue("registeredAddress2"),

        postalSuburb: this.props.form.getFieldValue("registeredSuburb"),

        postalPostCode: this.props.form.getFieldValue("registeredPostCode"),

        postalState: this.props.form.getFieldValue("registeredState"),
      });
      this.setState({ postalAddress1: this.state.registeredAddress1 });
    }
  };

  handleSelectChange = ({ key, value }) => {
    this.props.form.setFieldsValue({
      key: value,
    });
  };

  handleContinue = (err, values) => {
    if (!this.requiredFieldsAreFilled(err, values)) {
      return;
    }
    if (!this.state.registeredAddress1) {
      return message.error("Please enter physical address line 1.");
    }
    if (!this.state.postalAddress1) {
      return message.error("Please enter postal address line 1.");
    }
    if (
      !err ||
      (!err.name &&
        !err.operatingTimezone &&
        !err.registeredName &&
        !err.abn &&
        !err.country &&
        !err.registeredPostCode &&
        !err.registeredState &&
        !err.registeredSuburb &&
        !err.registeredPostCode &&
        !err.postalPostCode &&
        !err.postalState &&
        !err.postalSuburb &&
        !err.postalPostCode)
    ) {
      this.setState({ activeIndex: 2 });
    }
  };
  userDetailsPopulated = (values, err) => {
    if (err) {
      return false;
    }
    if (
      !this.state.createNewPayrollAdmin &&
      _.isEmpty(this.state.assignedPayrollAdminList)
    ) {
      message.error("Please select a Payroll Admin.");
      return false;
    }
    if (
      this.state.createNewPayrollAdmin &&
      (!values.firstName ||
        !values.lastName ||
        !values.emailAddress ||
        !values.mobilePhone)
    ) {
      message.error("Please enter values for required fields.");
      return false;
    }
    return true;
  };
  handleSubmit = () => {
    const { form, match } = this.props;
    form.validateFields((err, values) => {
      if ((this.state.editMode && err) || (this.state.activeKey === 2 && err)) {
        return this.setState({ confirmLoading: false });
      }
      if (this.state.activeIndex === 1 && !this.state.editMode) {
        this.setState({ confirmLoading: false });
        return this.handleContinue(err, values);
      }
      if (
        this.state.activeIndex === 2 &&
        !this.userDetailsPopulated(values, err)
      ) {
        return this.setState({ confirmLoading: false });
      }

      this.setState({ confirmLoading: true });
      let finalValues = {
        companyName: values.name,
        operatingTimeZone: values.operatingTimezone,
        registeredBusinessName: values.registeredName,
        abn: getOptionalParameter({ value1: values.abn, value2: "" }),
        directoryServiceInstanceId: values.newDirectoryServiceInstance
          ? null
          : values.directoryServiceInstanceId,
        address: {
          address1: this.state.registeredAddress1,
          address2: getOptionalParameter({
            value1: values.registeredAddress2,
            value2: "NA",
          }),
          suburb: values.registeredSuburb,
          state: values.registeredState.toString(),
          postCode: values.registeredPostCode,
          isActive: true,
        },
        postalAddress: {
          address1: this.state.postalAddress1,
          address2: getOptionalParameter({
            value1: values.postalAddress2,
            value2: "NA",
          }),
          suburb: values.postalSuburb,
          state: values.postalState.toString(),
          postCode: values.postalPostCode,
          isActive: true,
        },
        country: values.country,
        isActive: true,
        logoURL: this.state.logo,
        manualProcessing: false,
        expectedProcess: null,
      };
      finalValues["payrollSystem"] = _.map(
        this.state.processingSystem,
        (ps) => ps.id
      ).toString();

      if (this.state.editMode) {
        finalValues.id = getMatchParams({ match, field: "id" });
        this.props
          .updatePayrollServiceProvider(finalValues)
          .then((response) => {
            this.setState({ confirmLoading: false });

            if (response.status) {
              message.success("Payroll Service Provider updated successfully");

              this.handleRedirection();
            } else {
              errorHandler({ response });
            }
          });
      } else {
        finalValues.pspAdmin = {
          existingPSPUserId: this.state.assignedPayrollAdminList[0] || "",
          newPspAdmin: {
            firstName: values?.firstName,
            lastName: values?.lastName,
            emailAddress: values?.emailAddress,
            mobilePhone: values?.mobilePhone,
            phone: values?.phone || "",
            getNotifications: Number(values?.getNotifications) === 1,
            mfaEnable: Number(values?.mfaEnable) === 1,
            deviceEnrolmentEnable: values?.deviceEnrolmentEnable,
          },
        };
        this.props.savePayrollServiceProvider(finalValues).then((response) => {
          this.setState({ confirmLoading: false });

          if (response.status && response.data) {
            this.setState({
              actionOneLink: `/payrollprovider/${response.data.result}`,

              newPSPId: response.data.result,
            });
          } else {
            errorHandler({ response });
          }
        });
      }
    });
  };

  requiredFieldsAreFilled = (err, values) => {
    if (!isABNValidated({ values })) {
      this.setState({ confirmLoading: false });
      return false;
    }
    const selectedProcessingSystem = _.find(
      mustBeArray(this.state.processingSystem),
      (system) => system.checked
    );
    if (!this.state.checkedProcessing || !selectedProcessingSystem) {
      this.setState({ confirmLoading: false });
      Modal.warning({
        title: "Processing systems missing",
        content: "Please select at least one processing system",
        width: 700,
      });
      return false;
    }
    return true;
  };

  onCancel = () => {
    this.setState({
      disableField: false,
      isSameAddress: false,
    });

    this.props.form.resetFields();
    this.handleRedirection();
  };

  populateSameAddressField = (value, field) => {
    if (this.state.isSameAddress) {
      this.props.form.setFieldsValue({ [field]: value });
    }
  };
  handleRedirection = () => {
    const { editMode } = this.state;
    if (editMode) {
      this.props.router.navigate(
        `/payrollprovider/${this.props.match.params.id}`
      );
    } else {
      this.props.router.navigate("/payrollprovider");
    }
  };
  onChangeCountry = () => {
    this.props.form.resetFields([
      "postalState",
      "registeredState",
      "postalSuburb",
      "postalPostCode",
      "registeredSuburb",
      "registeredPostCode",
      "registeredAddress2",
      "postalAddress2",
      "abn",
      "operatingTimezone",
    ]);

    this.setState({
      isSameAddress: false,
      postalAddress1: "",
      registeredAddress1: "",
    });
  };

  toggleSwitch = (checked, system) => {
    this.setState((prevState) => {
      let found = _.find(
        prevState.processingSystem,
        (ps) => ps.name === system
      );
      found.checked = !found.checked;
      return prevState;
    });
  };

  toggleSystems = () => {
    this.setState((prevState) => {
      _.each(prevState.processingSystem, (ps) => {
        ps.checked = false;
      });
      prevState.checkedProcessing = !prevState.checkedProcessing;
      return prevState;
    });
  };
  updateActiveStep = (activeIndex) => {
    if (activeIndex < this.state.activeIndex) {
      this.setState({ activeIndex });
    }
  };
  showStepToCreatePayrollAdmin = () => {
    const { editMode } = this.state;
    return !editMode;
  };

  uploadLogo = (file) => {
    let imageType = ["image/jpg", "image/jpeg", "image/png"];
    if (!imageType.includes(file && file.type))
      return message.error("Please upload images only.");
    this.setState({ uploadLoading: true });
    this.props
      .payrollProviderUploadLogo({
        payload: { file },
        options: {},
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        this.setState({ uploadLoading: false });
        if (resp.status) {
          message.success("Logo uploaded successfully.");
          this.setState({
            logo: resp?.data,
          });
        } else {
          this.setState({ logo: "" });
          errorDisplay(resp && resp.data && resp.data.validationErrors, true);
        }
      });
    return false;
  };
  handleDelete = () => {
    this.setState({ logo: "" });
    message.success("Logo deleted successfully.");
  };
  nameSameAsRegisteredNameChange = (value) => {
    this.props.form.setFieldsValue({
      isNameSameAsRegisteredBusinessName: value,
    });
    if (value) {
      this.props.form.setFieldsValue({
        name: this.props.form.getFieldValue("registeredName"),
      });
    }
  };
  handleRegisteredNameChange = (value) => {
    this.props.form.setFieldsValue({ registeredName: value });
    if (this.props.form.getFieldValue("isNameSameAsRegisteredBusinessName")) {
      this.props.form.setFieldsValue({ name: value });
    }
  };

  toggleAddingNewAndExistingPayrollAdmin = () => {
    this.setState({
      createNewPayrollAdmin: !this.state.createNewPayrollAdmin,
      assignedPayrollAdminList: [],
    });
  };

  handleAssignChange = ({ record }) => {
    const { assignedPayrollAdminList } = this.state;
    if (assignedPayrollAdminList.includes(record?.id)) {
      this.setState({ assignedPayrollAdminList: [] });
    } else {
      this.setState({ assignedPayrollAdminList: [record?.id] });
    }
  };

  onAddressChange = (value, type) => {
    if (type === "registered") {
      this.setState({ registeredAddress1: value });
      if (this.state.isSameAddress) {
        this.setState({ postalAddress1: value });
      }
    } else {
      this.setState({ postalAddress1: value });
    }
  };
  handleBack = () => {
    this.updateActiveStep(1);
  };

  render() {
    const { form, member, header, match } = this.props;
    const { getFieldDecorator } = form;
    const {
      disableField,
      countries,
      timezone,
      states,
      isSameAddress,
      confirmLoading,
      activeIndex,
      newPSPId,
      actionOneLink,
      formLabels,
      createNewPayrollAdmin,
      editMode,
    } = this.state;
    return (
      <Form
        hideRequiredMark={false}
        autoComplete="off"
        className="defaultFormMargin"
        colon={false}
      >
        <Layout className="h-[100vh]">
          {newPSPId ? (
            <NoRecord>
              <CreateSuccess
                page="Payroll Service Provider"
                actionOneLabel="View Payroll Service Provider"
                actionOneLink={actionOneLink}
              />
            </NoRecord>
          ) : (
            <React.Fragment>
              <Header className="bg-white w-full position-absolute-top">
                <FormNavbar
                  handleCancel={this.onCancel}
                  handleSave={this.handleSubmit}
                  htmlType="submit"
                  loading={confirmLoading}
                  enableBack={true}
                  header={header}
                  disabled={
                    this.showStepToCreatePayrollAdmin() && activeIndex !== 2
                  }
                />
              </Header>
              <Layout className="rounded-[5px] w-full create-screen-body p-6 overflow-y-auto">
                <Content>
                  <Row gutter={DEFAULT_GUTTER} className="form-label-left">
                    <FormStepsCol data={formLabels} activeIndex={activeIndex} />
                    <Col md={18} sm={24}>
                      <Card>
                        <div
                          className={getClassNameForActiveInactiveComponent({
                            activeIndex,
                            componentIndex: 1,
                          })}
                        >
                          <CreateBasicDetailForm
                            form={form}
                            getFieldDecorator={getFieldDecorator}
                            disableField={disableField}
                            entity={"payroll provider"}
                            populateStates={this.populateStates}
                            countries={countries}
                            timezone={timezone}
                            states={states}
                            onSameAddressChange={this.onSameAddressChange}
                            onAddressSelect={this.onAddressSelect}
                            isSameAddress={isSameAddress}
                            populateSameAddressField={this.populateSameAddressField.bind(
                              this
                            )}
                            onChangeCountry={this.onChangeCountry.bind(this)}
                            mapLoaded={this.state.mapLoaded}
                            fieldRequired={activeIndex === 1}
                            showUploadLogo={true}
                            imageUrl={this.state.logo}
                            uploadLoading={this.state.uploadLoading}
                            handleUpload={this.uploadLogo}
                            handleDelete={this.handleDelete}
                            nameSameAsRegisteredNameChange={
                              this.nameSameAsRegisteredNameChange
                            }
                            handleRegisteredNameChange={
                              this.handleRegisteredNameChange
                            }
                            editMode={editMode}
                            registeredAddress1={this.state.registeredAddress1}
                            postalAddress1={this.state.postalAddress1}
                            onAddressChange={this.onAddressChange}
                          />

                          <div className="flex w-full mt-4">
                            <div className="flex-1-1-18">
                              <span> </span>{" "}
                            </div>

                            <div className="flex-1-1-82">
                              <div className="text-lg font-normal mb-3">
                                Pay Run Processing Options
                              </div>
                              <Row gutter={DEFAULT_GUTTER}>
                                <Col span={12}>
                                  <Checkbox
                                    checked={this.state.checkedProcessing}
                                    onChange={this.toggleSystems}
                                  >
                                    Supported Processing Systems
                                  </Checkbox>
                                  <Row>
                                    {this.state.checkedProcessing &&
                                      this.state.processingSystem.map(
                                        (system) => {
                                          return (
                                            <Col
                                              span={22}
                                              offset={1}
                                              key={system.id}
                                              className="pt-4"
                                            >
                                              <Row>
                                                <Col span={8}>
                                                  <span>{system.name}</span>
                                                </Col>
                                                <Col span={8}>
                                                  <Switch
                                                    checked={system.checked}
                                                    onChange={(checked) =>
                                                      this.toggleSwitch(
                                                        checked,
                                                        system.name
                                                      )
                                                    }
                                                    disabled={
                                                      !this.state
                                                        .checkedProcessing
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                          );
                                        }
                                      )}
                                  </Row>
                                </Col>
                              </Row>
                              <Row gutter={DEFAULT_GUTTER} className="mt-4">
                                {!editMode && (
                                  <Col span={12}>
                                    <Form.Item label="Create New Directory Service Instance">
                                      {getFieldDecorator(
                                        "newDirectoryServiceInstance",
                                        {
                                          initialValue: true,
                                        }
                                      )(
                                        <Radio.Group>
                                          <Radio value={true}>Yes</Radio>
                                          <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                      )}
                                    </Form.Item>
                                  </Col>
                                )}
                                {(!form?.getFieldValue(
                                  "newDirectoryServiceInstance"
                                ) ||
                                  match?.params?.id) && (
                                  <Col span={12}>
                                    <Form.Item label="Directory Service Instance">
                                      {getFieldDecorator(
                                        "directoryServiceInstanceId",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Please select an instance.",
                                            },
                                          ],
                                        }
                                      )(
                                        <PayrollInstanceLookup
                                          disabled={
                                            !!(
                                              editMode &&
                                              this.state
                                                .directoryServiceInstanceId
                                            )
                                          }
                                          handleChange={(e) =>
                                            this.props.form.setFieldsValue({
                                              directoryServiceInstanceId: e,
                                            })
                                          }
                                        />
                                      )}
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>

                          {!this.state.editMode && (
                            <SimpleButton
                              ignoreStatus={true}
                              className="bg-success float-right mt-4"
                              buttonText="Continue"
                              htmlType="submit"
                              onClick={this.handleSubmit}
                              secondaryIcon={<RightOutlined />}
                            />
                          )}
                          <div className="clearfix" />
                        </div>

                        <div
                          className={getClassNameForActiveInactiveComponent({
                            activeIndex,
                            componentIndex: 2,
                          })}
                        >
                          <SimpleButton
                            buttonText={
                              createNewPayrollAdmin
                                ? "Select Existing Payroll Admin"
                                : "Create New Payroll Admin"
                            }
                            className="float-right mb-6"
                            ignoreStatus={true}
                            type="primary"
                            onClick={
                              this.toggleAddingNewAndExistingPayrollAdmin
                            }
                          />
                          <div className="clearfix"></div>
                          {createNewPayrollAdmin ? (
                            <UserBasicDetailsForm
                              form={form}
                              fieldRequired={
                                activeIndex === 2 &&
                                this.state.createNewPayrollAdmin
                              }
                              member={member}
                            />
                          ) : (
                            <AssignPayrollAdminForm
                              listPSPUsers={this.props.listPSPUsers}
                              assignedPayrollAdminList={
                                this.state.assignedPayrollAdminList
                              }
                              handleAssignChange={this.handleAssignChange}
                            />
                          )}

                          <SimpleButton
                            ignoreStatus={true}
                            className="mt-4"
                            buttonText="Previous Step"
                            onClick={() => this.handleBack()}
                            icon={<LeftOutlined />}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </React.Fragment>
          )}
        </Layout>
      </Form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    header: state.pageHeader.header,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(Form.create({ name: "createPsp" })(CreatePSP)));
