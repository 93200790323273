import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { Avatar, List } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import QuestionListAnswer from "./questionListAnswer";

const QuestionList = ({
  toggleActiveKey,
  activeQuestion,
  listLoading,
  businessunitDetails,
  questionList,
}: any) => {
  return (
    <div className="list-title-description list-meta-padding">
      <List
        header={<div className="text-lg pl-3 font-bold">Questions</div>}
        itemLayout="horizontal"
        loading={listLoading}
        size="small"
        dataSource={mustBeArray(questionList)}
        renderItem={(item, i) => (
          <List.Item
            key={i}
            className="px-4 hand"
            onClick={() => toggleActiveKey(i)}
          >
            <List.Item.Meta
              title={
                <div
                  className={
                    Number(activeQuestion) === Number(i) ? "color-primary" : ""
                  }
                >
                  <Avatar
                    className={
                      Number(activeQuestion) === Number(i)
                        ? "bg-primary"
                        : "color-dark"
                    }
                  >
                    {i + 1}
                  </Avatar>{" "}
                  &nbsp; {item && item.label}
                </div>
              }
              description={
                <div
                  className={`pl-10 ${
                    Number(activeQuestion) === Number(i) ? "color-primary" : ""
                  } overflow-x-hidden`}
                >
                  <QuestionListAnswer
                    questionList={item}
                    businessunitDetails={businessunitDetails}
                  />
                </div>
              }
            />
            <div>
              <RightOutlined />
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};
export default QuestionList;
