import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import CreateSimpleButton from "../../../../Common/ClaimComponents/Button/CreateSimpleButton";
import { If } from "../../../../Common/ui";
import {
  isComponentVisible,
  showCreateAndGridTableToggleOption,
} from "../../../../libs";
import GridTableViewOption from "../gridTableViewOption";

type Props = {
  claim: any;
  componentTitle?: string;
  hideAddButton?: boolean;
  dashboardView?: boolean;
  listContainerTitle?: string;
  addModuleName?: string;
  handleAddAction?: () => void;
  handleGridViewChange?: (e: any) => void;
  handleGridChange?: (e: any) => void;
  handleTableChange?: (e: any) => void;
  CustomActions: any;
  displayActionIcons?: boolean;
  showAddAndGrid?: boolean;
  filterApplied?: string;
  total: number;
  hideSorting?: boolean;
  grid: any;
  table: any;
  createLinkButtonComponentName?: string;
  disableCreateButton?: boolean;
  gridable: boolean;
  gridView: boolean;
  fieldOptions?: any;
  singleLineGridView?: boolean;
};
const ListHeaderAndActions = ({
  claim,
  componentTitle,
  hideAddButton,
  dashboardView,
  listContainerTitle,
  addModuleName,
  CustomActions,
  createLinkButtonComponentName,
  showAddAndGrid,
  handleAddAction,
  filterApplied,
  hideSorting,
  table,
  grid,
  disableCreateButton,
  gridable,
  handleGridViewChange,
  handleGridChange,
  handleTableChange,
  gridView,
  fieldOptions,
  singleLineGridView,
}: Props) => {
  const member = useSelector(({ member }: any) => member);
  return (
    <React.Fragment>
      <If
        condition={!!(isComponentVisible({ claim }) || dashboardView)}
        then={
          <div className={classNames({ "mb-2 pl-1.5": dashboardView })}>
            <If
              condition={CustomActions && !dashboardView}
              then={<div className="float-left"> {CustomActions} </div>}
            />
            <If
              condition={!!listContainerTitle}
              then={
                <span className="text-base font-semibold">
                  {listContainerTitle}
                </span>
              }
            />
            <If
              condition={!!(dashboardView && componentTitle)}
              then={
                <span>
                  <span className="text-base font-semibold">
                    {componentTitle}
                  </span>
                  {filterApplied && (
                    <span className="text-[10px] font-normal ml-2.5">
                      {filterApplied}
                    </span>
                  )}
                </span>
              }
            />
            <If
              condition={showCreateAndGridTableToggleOption({
                claim,
                showAddAndGrid,
              })}
              then={
                <div className="mb-4 float-right flex items-center">
                  {/* Display Create button */}
                  <If
                    condition={
                      claim.addable === "true" &&
                      !hideAddButton &&
                      !dashboardView
                    }
                    then={
                      <CreateSimpleButton
                        onClick={handleAddAction}
                        name={createLinkButtonComponentName}
                        claims={claim}
                        hasNoComponents={false}
                        addModuleName={addModuleName}
                        disableCreateButton={disableCreateButton}
                      />
                    }
                  />
                  {/* Grid Table toggle option */}
                  <If
                    condition={
                      claim?.gridable === "true" && !singleLineGridView
                    }
                    then={
                      <GridTableViewOption
                        gridable={gridable}
                        handleGridChange={handleGridViewChange}
                        gridView={gridView}
                        fieldOptions={fieldOptions} //Sorting fields for grid view
                        updateGridField={handleGridChange}
                        gridSortInfo={grid?.sortInfo}
                        updatePageSize={handleTableChange}
                        grid={grid}
                        per_page={table?.perPage}
                        hideSorting={hideSorting}
                        dashboardView={dashboardView}
                      />
                    }
                  />
                </div>
              }
            />
            <div className="clearfix"></div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default ListHeaderAndActions;
