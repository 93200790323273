import { useCallback, useMemo } from 'react';

import { CrossAppFeature } from '@xemplo/cross-app-flags';
import { useGetNavigationQueryV2 } from '@xemplo/directory-services-query';
import { useFeatureFlag } from '@xemplo/feature-flag';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { NoAccessText } from './no-access.constants';

export function useNoAccess(message?: string) {
  const { data } = useGetNavigationQueryV2();
  const { startAppUrl } = useXemploApiContext();
  const { isEnabled } = useFeatureFlag();

  const showExtras = useMemo(() => {
    // In order to show the extras, we must have a few elements:
    // 1. The feature must be enabled
    // 2. We must have personas
    // 3. We must have a startAppUrl defined on the app init
    return (
      isEnabled(CrossAppFeature.NoAccessOptions) &&
      !!data?.personas?.length &&
      !!startAppUrl
    );
  }, [data?.personas?.length, isEnabled, startAppUrl]);

  const text = useMemo(() => {
    if (message) return message;

    let msg = NoAccessText.Default;
    if (showExtras) {
      msg += `\n${NoAccessText.SelectPersona}`;
    }

    return msg;
  }, [showExtras, message]);

  const handleGoToPersonas = useCallback(() => {
    // Since this is a different app context, we need to redirect to the startAppUrl
    // but first, need to validate if the url is valid
    if (!startAppUrl) return;

    // Check if it's a valid URL
    const url = new URL(startAppUrl);
    if (!url.protocol || !url.hostname) return;

    window.location.href = startAppUrl;
  }, [startAppUrl]);

  return { showExtras, text, handleGoToPersonas };
}
