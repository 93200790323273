//@ts-nocheck
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const ContentEmpty = (props: any) => {
  const { page, link, handleAction } = props;
  const navigate = useNavigate();
  return (
    <div className="text-center empty-content">
      <div>
        <span className={"title text-2xl font-normal"}>No {page} found</span>
        <p className={"mt-4 mb-3 text-sm"}>
          Let's get started and create new {page && page.toLowerCase()}!
        </p>
      </div>
      <div>
        <Button
          type="success"
          className="ant-btn-lg rounded-[20px]"
          onClick={() => (handleAction ? handleAction() : navigate(link))}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
export default ContentEmpty;
