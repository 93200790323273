//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Modal, Input, message } from "antd";
import _ from "lodash";

class ReSubmitAmendment extends Component {
  submitAmendment = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { description } = values;
      if (_.isNull(description) || description.trim() === "") {
        return message.error("Please enter a reason.");
      }
      this.props.submitAmendment(values);
    });
  };
  handleCancel = () => {
    this.props.form.resetFields();
    this.props.handleCancel();
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps && newProps.visible && !this.props.visible) {
      this.props.form.resetFields();
    }
  }

  render() {
    const { visible, title, resubmitLoading } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={
          title ? title : `Are you sure you want to resubmit this amendment?`
        }
        visible={visible}
        width={700}
        okText="Yes"
        cancelText="No"
        onCancel={this.handleCancel}
        onOk={this.submitAmendment}
        okButtonProps={{
          loading: resubmitLoading,
        }}
      >
        <Form className="form-label-left" autoComplete="off">
          <Form.Item label="Description">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Please enter a reason.",
                },
              ],
            })(
              <Input.TextArea
                placeholder="Please enter a reason"
                rows={4}
                maxLength={1000}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "amendmentsubmitform" })(ReSubmitAmendment);
