//@ts-nocheck
import React from "react";
import { Card, Tooltip, Typography, Avatar, Badge } from "antd";
import Flag from "react-world-flags";
import { ContractIcon } from "../../../Common/customicons";
import CountryCoordinates from "../../../Common/countries";
import TableActionComponent from "../common/tableActions";
const { Title } = Typography;

const PayScheduleDisplayCard = ({
  type,
  handleAction,
  content,
  claims,
  editComponent,
  viewComponent,
}: any) => {
  const country =
    content &&
    content.countryName &&
    CountryCoordinates.getCode(content.countryName);
  return (
    content && (
      <Card
        className="card hand card-prop h-full pb-0"
        bordered={false}
        onClick={(e) => handleAction({ data: content, action: "view" })}
      >
        <div className="position-relative py-3 px-0">
          <div className={"mb-2.5 px-[50px] position-relative"}>
            <div className={"position-relative"}>
              <Tooltip placement="top" title={`${content.payScheduleName}`}>
                <Title
                  ellipsis
                  className="card-title"
                  level={3}
                >{`${content.payScheduleName}`}</Title>
              </Tooltip>
              <Title ellipsis className="card-subtitle" level={4}>
                {`Company: ${content.companyName}`}
              </Title>
            </div>

            <Avatar
              className="position-absolute-top position-absolute-top-left"
              size={36}
            >
              <ContractIcon className="text-lg" />
            </Avatar>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-3">
            <span>{`Business Unit: ${content.businessUnitName}`}</span>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-3">
            <span>{`Payroll Model: ${content.payrollModel}`}</span>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-3">
            <span>{`Pay Occurence: ${content.payOccurence}`}</span>
          </div>
          <div>
            {content.isActive ? (
              <Badge
                count={"Active"}
                className={"text-xs badge badge-success"}
              />
            ) : (
              <Badge
                count={"InActive"}
                className={"text-xs badge badge-danger"}
              />
            )}
          </div>
        </div>
        {country && (
          <div className={"flags"}>
            <Tooltip placement="topLeft" title={content.countryName}>
              <Flag
                code={country}
                width={18}
                height={14}
                className="float-left"
              />
            </Tooltip>
          </div>
        )}
        <TableActionComponent
          classname="card-item mt-4 mr-4"
          buttonClassName="card-prop animated fadeIn position-absolute-top position-absolute-top-right"
          claims={claims}
          handleAction={(options: any) =>
            handleAction({ ...options, data: content, record: content })
          }
          editComponentName={editComponent}
          viewComponentName={viewComponent}
        />
      </Card>
    )
  );
};

export default PayScheduleDisplayCard;
