import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { DawnCross16 } from '@xemplo/icons';
import { ListItem } from '@xemplo/listbox';

import * as S from './tags.styles';
import { TagsProps } from './tags.types';

export const TagsTestId = {
  tagGroup: (testId?: string) => `tag-group-${testId}`,
  tagClass: 'text-dropdown-tag',
  overflow: (testId?: string) => `tag-overflow-${testId}`,
  clearTags: (testId?: string) => `tag-clear-all-tags-${testId}`,
};

export function Tags<T extends ListItem>({
  state,
  hasLeadingIcon,
  testId,
  items,
  hasSiblings,
}: Readonly<TagsProps<T>>) {
  const tagGroupRef = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState(false);

  const { selectionManager } = state;
  const { selectedKeys } = selectionManager;

  useEffect(() => {
    const groupEl = tagGroupRef.current;
    if (!groupEl) return;
    setOverflow(groupEl.clientWidth < groupEl.scrollWidth);
  }, [selectedKeys.size]);

  if (selectedKeys.size === 0 || !items?.length) return null;
  const hasItems = selectedKeys.size >= 1;

  return (
    <S.TagGroupWrapper className={classNames({ 'has-leading-icon': !!hasLeadingIcon })}>
      <S.SelectedOverflow
        data-testid={TagsTestId.overflow(testId)}
        className={classNames({ 'is-hidden': !overflow })}
      >
        <S.SelectedText
          className={classNames({ 'has-siblings': hasItems && hasSiblings })}
        >{`${selectedKeys.size} options selected`}</S.SelectedText>
      </S.SelectedOverflow>
      <S.TagGroup
        ref={tagGroupRef}
        data-testid={TagsTestId.tagGroup(testId)}
        className={classNames({
          'is-hidden': overflow,
          'has-siblings': hasItems && hasSiblings,
        })}
      >
        {Array.from(selectedKeys.keys()).map((key) => (
          <S.Tag key={key} className={TagsTestId.tagClass}>
            {items.find((item) => item.key.toString() === key.toString())?.value}
            <DawnCross16
              onClick={(e) => {
                e.stopPropagation();
                selectionManager.toggleSelection(key);
              }}
            />
          </S.Tag>
        ))}
      </S.TagGroup>
      <S.ClearTags
        id="clear-tags"
        aria-label="Clear tags button"
        data-testid={TagsTestId.clearTags(testId)}
        onClick={(e) => {
          e.stopPropagation();
          selectionManager.clearSelection();
        }}
      >
        <DawnCross16 />
      </S.ClearTags>
    </S.TagGroupWrapper>
  );
}
