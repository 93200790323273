// @ts-nocheck
import { message } from "antd";
import _ from "lodash";

import { memberAction } from "../../constants/action-type";
import APIHandler from "../../constants/apiUrl";
import { GLOBAL_PAYROLL_APPLICATION_NAME } from "../../constants/authorizationClaims";
import { httpClient, memberApi } from "../../hooks";
import {
  errorHandler,
  getPersonaUpdatePayload,
  getRecentPersonalizations,
  http,
  ResponseHandler,
  updateComponentPersonalizations,
} from "../../libs";
import { isEmpty, mustBeArray, requestBuilder } from "../../libs/utilities";
import { pageHeaderActions } from "../../slices/pageHeader";
import store from "../../store";
import env from "../../constants/environment";

const { LIST_USERS } = memberAction;

export function listUsers({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({ endpoint: "USERS" });
      const response = await requestBuilder(id, url, queryOptions, cancelToken);
      dispatch({ type: "UNSET_PROGRESS_BAR" });

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response.data, LIST_USERS))
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listUserRoles({ area, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve) => {
      const queryOptions = {
        ...options,
        per_page: 0,
        q: area ? { rolePrefix: area } : {},
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "ROLES_LOOKUP_URL",
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              "LIST_USERS_ROLES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updateAuthorizationClaim({
  claim,
  payload,
  update = true,
  updateLocalDom = true,
}) {
  return (dispatch) =>
    new Promise(async (resolve) => {
      const payloadArray = mustBeArray(payload?.personaComponents);
      if (update && !isEmpty(payloadArray)) {
        const newPayload = payloadArray.filter((o) => {
          return _.isObject(o) && o.name && o.id;
        });
        http.post(`${env.baseurl}/api/persona`, {
          personaComponents: newPayload,
        });
      }
      return resolve(
        dispatch({
          data: claim,
          type: updateLocalDom
            ? "UPDATE_AUTHORIZATION_CLAIM"
            : "NOT_UPDATE_AUTHORIZATION_CLAIM",
        })
      );
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updateHeader({ header }) {
  return (dispatch) => void dispatch(pageHeaderActions.setPageHeader(header));
}

/**
 * This is actually being used within the Profile.tsx file.
 * @knipignore
 */
export const updatePassword = ({ payload }) => {
  return (dispatch) =>
    new Promise(async (resolve) => {
      const url = `${env.identity_baseurl}/api/v1/users/password-change`;
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data, "CHANGE_PASSWORD")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
};

/** TODO: Need to find a better location for this */
export const BaseErrorHandler = (error) => message.error(error.message);

/**
 * This is actually being used within the Profile.tsx file.
 * @knipignore
 */
export function handleRoleSettings({ payload, action, cancelToken }) {
  const memberState = store.getState().member;
  const { getRoleSettings } = memberApi;
  const { client } = httpClient();
  let actionType, actionData, response, url;

  return async (dispatch) => {
    switch (action && action.toLowerCase()) {
      case "updatepersona":
        url = APIHandler.constructEndpoint({
          endpoint: "ROLE_SETTINGS_URL",
        });
        response = await client
          .post(url, payload, cancelToken)
          .catch(BaseErrorHandler);

        if (memberState.details?.selectedRole) {
          actionType = "member/setDefaultChanged";
          actionData = {
            ...memberState.details.selectedRole,
            default: payload.lastSelectedAsDefault,
          };
        }
        break;

      case "get":
        response = await getRoleSettings(cancelToken).catch(BaseErrorHandler);
        actionType = "member/setUserPersona";
        actionData = response.data;
        break;
    }

    return (
      actionData &&
      actionType &&
      dispatch({ type: actionType, payload: actionData })
    );
  };
}

/**
 * This is actually being used within the Profile.tsx file.
 * @knipignore
 */
export function handlePersonaSettings({
  authorizationDOM,
  payload,
  action,
  cancelToken,
}) {
  return (dispatch) =>
    new Promise(async (resolve) => {
      let response = "";
      let url = APIHandler.constructEndpoint({
        endpoint: "PERSONA_SETTINGS_URL",
      });
      let tempPayload = {};
      let tempDOM = [];
      switch (action?.toLowerCase()) {
        case "updatesettings":
        case "reset":
          response = await http.post(url, payload, cancelToken);
          tempPayload = { ...response?.data?.personalizationSettings };
          tempDOM = authorizationDOM.map((option) => {
            if (option.name === GLOBAL_PAYROLL_APPLICATION_NAME) {
              return { ...option, personalizationSettings: tempPayload };
            }
            return option;
          });
          break;
        case "clearSetings":
          break;
        default:
          break;
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(tempDOM, "UPDATE_AUTHORIZATION_CLAIM")
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "USER_ACTIONS",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

/**
 * This is actually being used within the Profile.tsx file.
 * @knipignore
 */
export function handlePersonaSettingsTemplate({
  id,
  payload,
  action,
  cancelToken,
}) {
  return (dispatch) =>
    new Promise(async (resolve) => {
      let response = "";
      let url = APIHandler.constructEndpoint({
        endpoint: `PERSONA_SETTINGS_TEMPLATE_URL`,
        options: { id },
      });
      switch (action && action.toLowerCase()) {
        case "get":
          response = await http.get(url);
          break;
        case "update":
          response = await http.put(url, payload, cancelToken);
          break;
        default:
          break;
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response, "UPDATE_AUTHORIZATIO"))
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "USER_ACTIONS",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// Update local dom for personalizations
export function savePersonalizations({
  personalizations,
  update,
}: {
  personalizations: Array<any>;
  update: boolean;
}) {
  return (dispatch) =>
    new Promise(async (resolve) => {
      // Read the exisitng list of personalization in the store
      const currentPersonalizations = structuredClone(
        store.getState()?.member?.personalizations
      );
      // Add the new set of personalizations to the exisiting list
      const updatedPersonalizations = [
        ...mustBeArray(currentPersonalizations),
        ...mustBeArray(personalizations),
      ];
      // Not update means just save the new personalization list in the store
      if (!update) {
        return resolve(
          dispatch({
            type: "SAVE_PERSONALIZATIONS",
            data: updatedPersonalizations,
          })
        );
      }
      // If Update save personalization in the db and clear the list in the store.
      const recentPersonalizations = getRecentPersonalizations({
        personalizations: updatedPersonalizations,
      });
      let updatedDom = updateComponentPersonalizations({
        claims: structuredClone(
          store.getState()?.member?.details?.authorizationDOM
        ),
        personalizations: recentPersonalizations,
      });
      // Clear stored personalizations
      dispatch({
        type: "SAVE_PERSONALIZATIONS",
        data: [],
      });

      // Update local dom with new personlizations
      dispatch({ type: "UPDATE_AUTHORIZATION_CLAIM", data: updatedDom });

      //  Call api to update
      const payload = getPersonaUpdatePayload({
        personalizations: recentPersonalizations,
      });
      http.post(`${env.baseurl}/api/persona`, {
        personaComponents: payload,
      });
    }).catch(async (err) => {
      throw err.message;
    });
}

export const getKeypaySsoSingleUrl = async (id: string) => {
  const url = APIHandler.constructEndpoint({
    endpoint: "GENERATE_KEYPAY_SSO_URL",
    options: {
      id,
    },
  });
  const response = await http.post(url);
  if (!ResponseHandler.isValidStatus(response)) {
    errorHandler({ response: response, hasValidationErrors: true });
  }
  return response;
};
