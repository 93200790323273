import env from "./environment";

export const AUTH_CONFIG = {
  authority: env.identity_baseurl,
  client_id: "expedo.globaldash",
  redirect_uri: env.callback_url,
  post_logout_redirect_uri: env.redirect_url,
  response_type: "code",
  scope: "openid profile email address standard_api IdentityServerApi",
  automaticSilentRenew: false,
};
