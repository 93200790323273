import React from "react";
import FormSteps from "../FormSteps";
import { Col } from "antd";

type Props = {
  data: any;
  activeIndex: number;
  md?: number;
  sm?: number;
};

const smDefault = 24;
const mdDefault = 6;

const FormStepsCol = ({
  data,
  activeIndex,
  md = mdDefault,
  sm = smDefault,
}: Props) => (
  <Col md={md} sm={sm}>
    <FormSteps data={data} activeIndex={activeIndex} />
  </Col>
);

export default FormStepsCol;
