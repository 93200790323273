import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";

const CustomDashboardSelector = ({ selected }: { selected: string }) => {
  const navigate = useNavigate();
  const { list } = useAppSelector((state) => state.dashboard);
  const dashboardList = list ?? [];
  const shouldRender = dashboardList.length > 0;

  return shouldRender ? (
    <select
      className="mb-2"
      name="dashboardSelect"
      onChange={(e) => navigate(`/home?dashboard=${e.currentTarget.value}`)}
      defaultValue={selected}
    >
      {dashboardList.map((dashboard) => {
        return (
          <option value={dashboard.name} key={dashboard.name}>
            {dashboard.name}
          </option>
        );
      })}
    </select>
  ) : null;
};

export default CustomDashboardSelector;
