import { Form, Input } from "antd";

type Props = {
  required?: boolean;
  className?: string;
  label?: string;
  name?: string;
  placeholder?: string;
};

const classNameDefault = "";
const placeholdeDefault = "Please enter a note";
const labelDefault = "Notes";
const nameDefault = "note";

const TextAreaField = ({
  required,
  name = nameDefault,
  label = labelDefault,
  placeholder = placeholdeDefault,
  className = classNameDefault,
}: Props) => (
  <Form.Item
    label={label}
    className={className}
    name={name}
    rules={[
      {
        required: required,
        message: `${placeholder}.`,
      },
    ]}
  >
    <Input.TextArea
      placeholder={placeholder}
      className="w-full "
      rows={4}
      maxLength={2000}
      showCount
    />
  </Form.Item>
);

export default TextAreaField;
