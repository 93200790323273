import styled, { css } from 'styled-components';

import {
  BodySmallRegular,
  BodySmallSemiBold,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
  Shadow,
} from '@xemplo/style-constants';

import { InputFieldSize } from './input-utils.types';

const sizePaddingMap = {
  [InputFieldSize.Large]: '20px 16px',
  [InputFieldSize.Standard]: '12px 16px',
  [InputFieldSize.Medium]: '10px 16px',
  [InputFieldSize.Small]: '4px 12px',
};

const sizeHeightMap = {
  [InputFieldSize.Large]: '64px',
  [InputFieldSize.Standard]: '48px',
  [InputFieldSize.Medium]: '40px',
  [InputFieldSize.Small]: '32px',
};

/** WRAPPER  */

/**
 * Base styles for a wrapper element.
 *
 * @param {string} width - The width of the wrapper element.
 */
export const wrapperBase = css<{ $width?: string }>`
  width: ${({ $width }) => {
    return $width ?? 'auto';
  }};
  min-width: min-content;
`;

/** CONTAINER  */
/**
 * Base styles for a container element.
 *
 * @param {InputFieldSize} inputSize - The size of the container.
 */
export const containerBase = css<{ $inputSize: InputFieldSize }>`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border: 1px solid ${Colour.Gray[200]};
  border-radius: 8px;
  background: ${Colour.White[100]};
  position: relative;
  padding: ${({ $inputSize }) => sizePaddingMap[$inputSize]};
  height: ${({ $inputSize }) => sizeHeightMap[$inputSize]};
  & > svg {
    flex: 0 0 auto;
    font-size: ${({ $inputSize }) =>
      $inputSize === InputFieldSize.Medium ? '20px' : '24px'};
    color: ${Colour.Gray[400]};
  }
`;

export const focusState = css`
  border: 1px solid ${Colour.Blue[500]};
  box-shadow: 0px 0px 0px 2px rgba(11, 120, 255, 0.2);

  label {
    color: ${Colour.Blue[600]};
  }
`;

/**
 * Update container border and box-shadow when input has focus.
 */
export const containerFocus = css`
  &:focus-within {
    ${focusState};
  }
`;
/**
 * Styles to apply when the container is disabled.
 */
export const containerDisabled = css`
  &.input-disabled {
    border: 1px solid ${Colour.Gray[200]};
    pointer-events: none;
    background: ${Colour.Gray[100]};
    label,
    svg {
      color: ${Colour.Gray[300]};
    }
  }
`;

export const containerReadonly = css`
  &.input-readonly {
    border: 1px solid ${Colour.Gray[200]};
    pointer-events: none;
    background: ${Colour.Gray[100]};

    label,
    svg {
      color: ${Colour.Gray[400]};
    }
  }
`;

/**
 * Styles to apply when there is an error in the container.
 */
export const containerError = css`
  &.input-has-error {
    border: 1px solid ${Colour.Red[500]};
    box-shadow: ${Shadow.Error};
    & > svg:last-child {
      color: ${Colour.Red[500]};
    }
    & > label {
      color: ${Colour.Red[600]};
    }
    & > input:focus + label {
      color: ${Colour.Red[600]};
    }
  }
`;

/**
 * Update container padding when label is active (input had focus or has value).
 *
 * @param {InputFieldSize} inputSize - The size of the input field.
 */
export const containerHasLabelPadding = css<{ $inputSize: InputFieldSize }>`
  &.has-value,
  &.has-label {
    padding: ${({ $inputSize }) => $inputSize === InputFieldSize.Large && '12px 16px'};
  }
`;

/** LABEL  */
/**
 * Base styles for a hidden label (All sizes except large).
 */
export const hiddenLabelBase = css`
  display: none;
`;

/**
 * Styles for a visible label (Only applicable for large size).
 */
export const labelVisible = css`
  &.has-label {
    display: flex;
    align-items: center;
    ${BodySmallSemiBold};
    position: absolute;
    color: ${Colour.Gray[500]};
    left: 16px;
    top: 20.5px;
    z-index: 1;
    transition: all 0.1s ease-out;

    &:hover {
      cursor: text;
    }

    &.has-leading-icon {
      left: 48px;
    }
  }
`;

export const labelHelperText = css`
  ${BodySmallRegular};
  color: ${Colour.Gray[400]};
  text-overflow: ellipsis;
  margin-left: 4px;
`;

export const labelMovedUp = css`
  ${BodyXSmallSemiBold};
  top: 10px;
  z-index: 3;

  & .label-helper-text {
    ${BodyXSmallRegular};
  }
`;
/**
 * Styles for a label when the input field has a value or is focus.
 */
export const labelInputHasValue = css`
  &.has-value {
    ${labelMovedUp};
  }
`;
/**
 * Shift label above input (should be applied to input element).
 */
export const labelInputHasFocus = css`
  &:focus + label {
    ${labelMovedUp};
  }
`;

/** INPUT  */
/**
 * Base styles for an input field.
 */
export const inputBase = css`
  ${BodySmallRegular};
  width: 100%;
  display: block;
  padding: 0;
  border: none;
  align-self: center;
  color: ${Colour.Gray[800]};
  &::placeholder {
    color: ${Colour.Gray[400]};
  }
  &:focus-visible {
    outline: none;
  }
`;
/**
 * Styles for an input field that has a label (only applicable for fields with Large size).
 */
export const inputWithLabel = css`
  &.has-label {
    align-self: flex-end;
  }
`;
/**
 * Styles for a disabled input field and placeholder pseudo element.
 */
export const inputDisabled = css`
  &:disabled {
    background: ${Colour.Gray[100]};
    color: ${Colour.Gray[300]};
    &::placeholder {
      color: ${Colour.Gray[300]};
    }
  }
`;

export const inputReadonly = css`
  &:read-only {
    background: ${Colour.Gray[100]};
    color: ${Colour.Gray[500]};
  }
`;

export const LabelHelperText = styled.span`
  ${labelHelperText};
`;
