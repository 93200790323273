import { useCallback, useReducer } from 'react';

import { defaultState, reducer, setPropsChanged } from './tab-menu.store';
import { TabMenuProps } from './tab-menu.types';

/** An aggregator hook that is used to compose the context api  */
export const useTabMenuInit = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const configTabMenu = useCallback((props: TabMenuProps) => {
    dispatch(setPropsChanged(props));
  }, []);

  return { state, dispatch, configTabMenu };
};
