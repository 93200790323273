import { css } from 'styled-components';

import { Colour } from './colours';

function emToPx(em: number, fontSize: number): string {
  const emInPx = em * fontSize;
  return `${emInPx}px`;
}

/**styleName: body-large/regular; */
export const BodyLargeRegular = css`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0;
`;
/**styleName: body-large/medium; */
export const BodyLargeMedium = css`
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
`;
/**styleName: body-large/semibold; */
export const BodyLargeSemiBold = css`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0;
`;
/**styleName: body-standard/regular; */
export const BodyStandardRegular = css`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
`;
/**styleName: body-standard/medium; */
export const BodyStandardMedium = css`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
`;
/**styleName: body-standard/semibold; */
export const BodyStandardSemiBold = css`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
`;
/**styleName: body-small/regular; */
export const BodySmallRegular = css`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: ${emToPx(0.01, 14)};
`;
/**styleName: body-small/medium; */
export const BodySmallMedium = css`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: ${emToPx(0.01, 14)};
`;
/**styleName: body-small/semibold; */
export const BodySmallSemiBold = css`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: ${emToPx(0.01, 14)};
`;
/**styleName: body-xsmall/regular; */
export const BodyXSmallRegular = css`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: ${emToPx(0.02, 12)};
`;
/**styleName: body-xsmall/medium; */
export const BodyXSmallMedium = css`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: ${emToPx(0.02, 12)};
`;
/**styleName: body-xsmall/semibold; */
export const BodyXSmallSemiBold = css`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: ${emToPx(0.02, 12)};
`;
/**styleName: body-2xsmall/regular; */
export const Body2XSmallRegular = css`
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: ${emToPx(0.02, 10)};
`;
/**styleName: body-2xsmall/medium; */
export const Body2XSmallMedium = css`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: ${emToPx(0.02, 10)};
`;
/**styleName: body-2xsmall/semibold; */
export const Body2XSmallSemiBold = css`
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: ${emToPx(0.02, 10)};
`;

/**Headings */
/**styleName: heading-5xlarge/regular;*/
export const Heading5XLargeRegular = css`
  font-family: Inter;
  font-size: 72px;
  font-weight: 400;
  line-height: 94px;
  letter-spacing: ${emToPx(-0.02, 72)};
`;
/**styleName: heading-5xlarge/medium; */
export const Heading5XLargeMedium = css`
  font-family: Inter;
  font-size: 72px;
  font-weight: 500;
  line-height: 94px;
  letter-spacing: ${emToPx(-0.02, 72)};
`;
/**styleName: heading-5xlarge/semibold;*/
export const Heading5XLargeSemiBold = css`
  font-family: Inter;
  font-size: 72px;
  font-weight: 600;
  line-height: 94px;
  letter-spacing: ${emToPx(-0.02, 72)};
`;
/**styleName: heading-4xlarge/regular;*/
export const Heading4XLargeRegular = css`
  font-family: Inter;
  font-size: 60px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: ${emToPx(-0.02, 60)};
`;
/**styleName: heading-4xlarge/medium; */
export const Heading4XLargeMedium = css`
  font-family: Inter;
  font-size: 60px;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: ${emToPx(-0.02, 60)};
`;
/**styleName: heading-4xlarge/semibold;*/
export const Heading4XLargeSemiBold = css`
  font-family: Inter;
  font-size: 60px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: ${emToPx(-0.02, 60)};
`;
/**styleName: heading-3xlarge/regular; */
export const Heading3XLargeRegular = css`
  font-family: Inter;
  font-size: 48px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: ${emToPx(-0.02, 48)};
`;
/**styleName: heading-3xlarge/medium;*/
export const Heading3XLargeMedium = css`
  font-family: Inter;
  font-size: 48px;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: ${emToPx(-0.02, 48)};
`;
/**styleName: heading-3xlarge/semibold; */
export const Heading3XLargeSemiBold = css`
  font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: ${emToPx(-0.02, 48)};
`;
/**styleName: heading-2xlarge/regular;*/
export const Heading2XLargeRegular = css`
  font-family: Inter;
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: ${emToPx(-0.02, 40)};
`;
/**styleName: heading-2xlarge/medium;*/
export const Heading2XLargeMedium = css`
  font-family: Inter;
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: ${emToPx(-0.02, 40)};
`;
/**styleName: heading-2xlarge/semibold;*/
export const Heading2XLargeSemiBold = css`
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: ${emToPx(-0.02, 40)};
`;
/**styleName: heading-xlarge/regular;*/
export const HeadingXLargeRegular = css`
  font-family: Inter;
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: ${emToPx(-0.01, 32)};
`;
/**styleName: heading-xlarge/medium;*/
export const HeadingXLargeMedium = css`
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: ${emToPx(-0.01, 32)};
`;
/**styleName: heading-xlarge/semibold;*/
export const HeadingXLargeSemiBold = css`
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: ${emToPx(-0.01, 32)};
`;
/**styleName: heading-large/regular;*/
export const HeadingLargeRegular = css`
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0;
`;
/**styleName: heading-large/medium;*/
export const HeadingLargeMedium = css`
  font-family: Inter;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
`;
/**styleName: heading-large/semibold;*/
export const HeadingLargeSemiBold = css`
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
`;
/**styleName: heading-standard/regular;*/
export const HeadingStandardRegular = css`
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0;
`;
/**styleName: heading-standard/medium;*/
export const HeadingStandardMedium = css`
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0;
`;
/**styleName: heading-standard/semibold;*/
export const HeadingStandardSemiBold = css`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0;
`;
/**styleName: heading-small/regular;*/
export const HeadingSmallRegular = css`
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
`;
/**styleName: heading-small/medium; */
export const HeadingSmallMedium = css`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
`;
/**styleName: heading-small/semibold;*/
export const HeadingSmallSemiBold = css`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
`;

/**
 * Link Styling
 * https://www.figma.com/design/46zj5HhFyRjL3veNSC9Q35/%E2%9A%99%EF%B8%8F-Dawn-UI?node-id=2954-32396&m=dev
 */

/** Simple underline variation */
export const LinkUnderline = css`
  text-decoration: underline;
`;

/** Variation with no underline for light background */
export const LinkLight = css`
  color: ${Colour.Blue[500]};
  &:visited {
    color: inherit;
  }
`;

/** Variation with no underline for dark background */
export const LinkDark = css`
  color: ${Colour.Blue[200]};
  &:visited {
    color: inherit;
  }
`;

/**
 * Link hover effect
 * https://www.figma.com/design/46zj5HhFyRjL3veNSC9Q35/%E2%9A%99%EF%B8%8F-Dawn-UI?node-id=2961-32529&m=dev
 */

/** Hover on light background */
export const LinkHoverLight = css`
  &:hover {
    color: ${Colour.Blue[600]};
  }
`;

/** Hover on dark background */
export const LinkHoverDark = css`
  &:hover {
    color: ${Colour.Blue[100]};
  }
`;

/**
 * Text ellipsis
 * NOTE: The container must have a fixed max-width
 */
export const TextEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
