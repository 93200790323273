import React from "react";
import { AppstoreOutlined, TableOutlined } from "@ant-design/icons";
import { If } from "../../../Common/ui";

const GridAndTableView = ({ view, updateView, iconClassName }: any) => {
  const isGridView = view === "grid";
  return (
    <div
      onClick={() => updateView(isGridView ? "list" : "grid")}
      className="bold flex items-center"
    >
      <If
        condition={isGridView}
        then={
          <>
            <TableOutlined className={iconClassName} /> <span>List View</span>
          </>
        }
        else={
          <>
            <AppstoreOutlined className={iconClassName} /> <span>Map View</span>
          </>
        }
      />
    </div>
  );
};

export default GridAndTableView;
