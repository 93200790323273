import {
  GeneralRoutesWithoutSidebarAndNavbar,
  GeneralRoutesWithSidebar,
} from "./generalWithLayout";
import {
  PayrunRoutesWithoutSidebar,
  PayrunRoutesWithSidebar,
} from "./payrunWithLayout";
import {
  PayrunRoutesFullScreen,
  PayrunRouteWithTemplate,
} from "@xemplo/payrun-router";
import { AmendmentsScreenRoute } from "@xemplo/amendments-router";
import { DashboardRoutes } from "@xemplo/gp-dashboard";
import { UsersScreenRoute } from "@xemplo/users-router";
import {
  AmendmentRoutesWithoutSidebar,
  AmendmentRoutesWithSidebar,
} from "./amendmentWithLayout";
import {
  BillingProviderRoutesWithoutSidebar,
  BillingProviderRoutesWithSidebar,
} from "./billingProviderWithLayout";
import {
  BusinessUnitRoutesWithoutSidebar,
  BusinessUnitRoutesWithSidebar,
} from "./businessUnitWithLayout";
import {
  CompanyRoutesWithoutSidebar,
  CompanyRoutesWithSidebar,
} from "./companyWithLayout";
import {
  EmailRoutesWithoutSidebar,
  EmailRoutesWithSidebar,
} from "./emailWithLayout";
import {
  PayrollProviderRoutesWithoutSidebar,
  PayrollProviderRoutesWithSidebar,
} from "./payrollProviderWithLayout";
import {
  PayscheduleRoutesWithoutSidebar,
  PayscheduleRoutesWithSidebar,
} from "./payscheduleWithLayout";
import {
  UserRoutesWithoutSidebar,
  UserRoutesWithSidebar,
} from "./userWithLayout";
import { InvoiceRoutesWithSidebar } from "./invoiceWithLayout";
import { VarianceRoutesWithSidebar } from "./varianceWithLayout";
import { ReportsRoutesWithSidebar } from "./reports";
// TODO: Replace DEFAULT_HOME_ROUTE with DEFAULT_ROUTES.HOME
export const DEFAULT_HOME_ROUTE = "/home";

export enum DEFAULT_ROUTES {
  HOME = "/home",
  PAYRUN = "/payrun",
}
export * from "./public";

export const RoutesWithoutSidebarAndNavbar = [
  ...GeneralRoutesWithoutSidebarAndNavbar,
];

export const PayrunV2Routes = [...PayrunRoutesFullScreen];

export const V2RoutesWithTemplate = [...PayrunRouteWithTemplate];
export const V2DashboardRoute = [...DashboardRoutes];
export const V2AmendmentsScreenRoute = [...AmendmentsScreenRoute];
export const V2UsersScreenRoute = [...UsersScreenRoute];

const LegacyPayrunRoutes = {
  withSidebar: PayrunRoutesWithSidebar,
  withoutSidebar: PayrunRoutesWithoutSidebar,
};

export const RoutesWithoutSidebar = [
  ...AmendmentRoutesWithoutSidebar,
  ...BillingProviderRoutesWithoutSidebar,
  ...BusinessUnitRoutesWithoutSidebar,
  ...CompanyRoutesWithoutSidebar,
  ...EmailRoutesWithoutSidebar,
  ...PayrollProviderRoutesWithoutSidebar,
  ...PayscheduleRoutesWithoutSidebar,
  ...UserRoutesWithoutSidebar,
  ...LegacyPayrunRoutes.withoutSidebar,
];

export const RoutesWithSidebar = [
  ...AmendmentRoutesWithSidebar,
  ...BillingProviderRoutesWithSidebar,
  ...BusinessUnitRoutesWithSidebar,
  ...CompanyRoutesWithSidebar,
  ...EmailRoutesWithSidebar,
  ...GeneralRoutesWithSidebar,
  ...InvoiceRoutesWithSidebar,
  ...PayrollProviderRoutesWithSidebar,
  ...PayscheduleRoutesWithSidebar,
  ...UserRoutesWithSidebar,
  ...VarianceRoutesWithSidebar,
  ...ReportsRoutesWithSidebar,
  ...LegacyPayrunRoutes.withSidebar,
];
