//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../../libs/table";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import { Card } from "antd";

class AuditReportBankRecordList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  render() {
    const { data, loading } = this.props;
    let colTemplate = [
      {
        title: "Employee Name",
        dataIndex: "employeeName",
        sorter: false,
        width: "20%",
      },
      {
        title: "Account Name",
        dataIndex: "accountName",
        sorter: false,
        width: "20%",
      },
      {
        title: "Account Details",
        dataIndex: "",
        sorter: false,
        width: "15%",
        render: (record: any) => {
          return (
            <React.Fragment>
              {`${record && record.bsb ? `${record.bsb} - ` : ""}${
                record.accountNumber ? record.accountNumber : ""
              }`}
            </React.Fragment>
          );
        },
      },
      {
        title: "Account Type",
        dataIndex: "accountType",
        sorter: false,
        width: "15%",
      },
      {
        title: "Lodgement Reference",
        dataIndex: "lodgementReference",
        sorter: false,
        width: "15%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: false,
        align: "right",
        dataType: "currency",
        width: "15%",
      },
    ];
    const column = tableColumnGenerator(colTemplate);
    return (
      <Card
        title={<div className="pt-3 pl-3">Bank Payments</div>}
        className="card-space-0"
      >
        <SimpleTableContainer
          columns={column}
          dataSource={data}
          loading={loading}
          onChange={this.handleTableChange}
          pagination={false}
          size="small"
          className="table-showing-total"
        />
      </Card>
    );
  }
}

export default AuditReportBankRecordList;
