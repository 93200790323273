// @ts-nocheck
import _ from "lodash";
import { mustBeArray } from "../libs/utilities";

class CountryCoordinates {
  _data: any;
  constructor() {
    if (!CountryCoordinates.instance) {
      this._data = [
        {
          id: 1,
          country: "Australia",
          value: [133.77513599999997, -25.274398],
          code: "AU",
        },
        {
          id: 2,
          country: "New Zealand",
          value: [174.763336, -36.848461],
          code: "NZ",
        },
        {
          id: 3,
          country: "Singapore",
          value: [103.851959, 1.29027],
          code: "SG",
        },
        {
          id: 4,
          country: "China",
          value: [121.522179, 31.267401],
          code: "CN",
        },
        {
          id: 5,
          country: "Indonesia",
          value: [116.825264, -1.26916],
          code: "ID",
        },
        {
          id: 6,
          country: "France",
          value: [2.618787, 47.824905],
          code: "FR",
        },
        {
          id: 7,
          country: "United Kingdom",
          value: [-0.118092, 51.509865],
          code: "GB",
        },
        {
          id: 8,
          country: "Great Britain",
          value: [-0.118092, 51.509865],
          code: "GB",
        },
        {
          id: 9,
          country: "United States",
          value: [-120.740135, 47.751076],
          code: "US",
        },
        {
          id: 10,
          country: "Argentina",
          value: [-58.381592, -34.603722],
          code: "AR",
        },
        {
          id: 11,
          country: "Belgium",
          value: [-58.381592, -34.603722],
          code: "BE",
        },
        {
          id: 12,
          country: "Canada",
          value: [-58.381592, -34.603722],
          code: "Ca",
        },
        {
          id: 13,
          country: "France",
          value: [-58.381592, -34.603722],
          code: "FR",
        },
        {
          id: 14,
          country: "Thailand",
          value: [-58.381592, -34.603722],
          code: "TH",
        },
        {
          id: 15,
          country: "Philippines",
          value: [-58.381592, -34.603722],
          code: "PH",
        },
        {
          id: 16,
          country: "Malaysia",
          value: [-58.381592, -34.603722],
          code: "MY",
        },
        {
          id: 17,
          country: "Italy",
          value: [-58.381592, -34.603722],
          code: "IT",
        },
        {
          id: 20,
          country: "Israel",
          value: [-58.381592, -34.603722],
          code: "IL",
        },
        {
          id: 21,
          country: "Indonesia",
          value: [-58.381592, -34.603722],
          code: "ID",
        },
        {
          id: 22,
          country: "Hong Kong",
          value: [-58.381592, -34.603722],
          code: "HK",
        },
        {
          id: 23,
          country: "Gibraltar",
          value: [-58.381592, -34.603722],
          code: "GI",
        },
        {
          id: 24,
          country: "Switzerland",
          value: [-58.381592, -34.603722],
          code: "CH",
        },
        {
          id: 25,
          country: "Anguilla",
          value: [-58.381592, -34.603722],
          code: "AI",
        },
      ];
    }
    return CountryCoordinates.instance;
  }

  getCoordinates(country: any) {
    let data = _.find(this._data, (o) => o.country === country);
    return mustBeArray(data && data.value);
  }

  getCode(country: any) {
    let data = _.find(this._data, (o) => o.country === country);
    return data && data.code;
  }
}

const instance = new CountryCoordinates();
Object.freeze(instance);

export default instance;
