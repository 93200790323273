import { SVGProps } from 'react';
const DawnSearch24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 10.5a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0Zm6.75-8.25a8.25 8.25 0 1 0 5.28 14.59l4.887 4.888a.75.75 0 1 0 1.061-1.06l-4.888-4.889A8.25 8.25 0 0 0 10.5 2.25Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnSearch24;
