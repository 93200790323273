//@ts-nocheck
import React from "react";
import { message } from "antd";
import { tableColumnGenerator } from "../../../libs/table";
import {
  errorDisplay,
  errorHandler,
  getDeviceEnrolmentId,
  getFullName,
  getMatchParams,
  getUserListColumns,
  handleAssignBusinessUnitNavigation,
} from "../../../libs/utilities";
import _ from "lodash";
import TableActionComponent from "../common/tableActions";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import {
  handleUserUnassignBusinessUnitRedirection,
  showAssignBusinessUnit,
  userCanEdit,
} from "../../../libs/validations";
import DisablePspUserConfirmationModal from "./DisablePspUserConfirmationModal";

type State = any;

class CompanyUserList extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      userDeleteLoading: false,
      activeUser: {},
      viewDisablePspUserConfirmation: false,
    };
  }

  getSortingField = (field) => {
    switch (field) {
      case "emailAddress":
        return "emailaddress";
      case "addedByUser":
        return "addedBy";
      default:
        return field;
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    let options = {
      page: pagination.current,
      order: sorter.order
        ? sorter.order === "ascend"
          ? "asc"
          : "desc"
        : sorter.order,
      field: this.getSortingField(sorter?.field),
    };
    this.props.handleTableChange({
      options,
      userMode: this.props.userMode,
    });
  };

  changeStatus = (user) => {
    const data = { ...user, enabled: !user.enabled };
    this.props
      .handleCompanyUserActions({
        id: user.id,
        data,
        action: "status",
        userMode: this.props.userMode,
      })
      .then((resp) => {
        if (resp.status) {
          message.success(
            `User ${data.enabled ? "enabled" : "disabled"} successfully`
          );
          this.props.handleTableChange({
            options: {},
          });
        } else {
          errorHandler({
            hasValidationErrors: false,
            response: resp,
            oldModel: true,
          });
        }
      });
  };

  sendMail = (user) => {
    this.props
      .handleCompanyUserActions({
        id: user.id,
        data: user,
        action: "sendreminder",

        userMode: this.props.userMode,
      })

      .then((resp) => {
        if (resp.status) {
          message.success(`Reminder sent successfully`);

          this.props.handleTableChange({
            options: {},
          });
        } else {
          errorHandler({ response: resp });
        }
      });
  };

  handleAction = ({ record, action }) => {
    switch (action) {
      case "edit":
        return this.props.handleEdit(record);
      case "view":
        return;
      case "sendReminder":
        return this.sendMail(record);
      case "disableDeviceEnrolment":
      case "optionalDeviceEnrolment":
      case "mandatoryDeviceEnrolment":
        message.info(`Your request to ${
          action === "disableDeviceEnrolment"
            ? "disable"
            : action === "mandatoryDeviceEnrolment"
            ? "enable mandatory"
            : "enable optional"
        } device enrolment for the user has been submitted.
        Please refresh the screen after two minutes.`);

        this.props.handleCompanyUserActions({
          action,
          data: {
            mfaEnable: record.mfaEnable,
            requestType: "deviceEnrolment",
            deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
          },
          id: record.id,
        });
        break;
      case "assignBusinessUnit":
        handleAssignBusinessUnitNavigation({
          user: record,
          member: this.props.member,
          pspId: this.props.pspId,
        });
        break;
      default:
        break;
    }
  };

  updateAuthentication = (user) => {
    this.props.handleCompanyUserActions({
      id: user.id,
      data: {
        mfaEnable: !user.mfaEnable,
        requestType: "mfa",
        deviceEnrolmentEnable: user.deviceEnrolmentEnable,
      },
      action: "updatemultifactorauthentication",

      userMode: this.props.userMode,
    });
    message.info(
      `Your request to ${
        user.mfaEnable ? "disable" : "enable"
      } multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
    );
  };

  getEditComponent = ({ user }) => {
    const { member, userType, editComponent } = this.props;
    if (userType === "psp") {
      return userCanEdit({ user, member }) ? editComponent : "";
    }
    return editComponent;
  };

  handleDisablePspCancel = () => {
    this.setState({ activeUser: {}, viewDisablePspUserConfirmation: false });
  };
  handleUserUnassignBusinessUnit = ({ member, match }) => {
    this.setState({ viewDisablePspUserConfirmation: false });
    handleUserUnassignBusinessUnitRedirection({
      user: this.state.activeUser,
      pspId: getMatchParams({ match: match, field: "id" }),
      member,
    });
  };
  render() {
    const size = this.props.size || "default";
    const {
      sortInfo,
      claims,
      component,
      toggleComponent,
      reminderComponent,
      match,
      per_page,
      userMode,
      data,
      member,
      userType,
      dashboardView,
      assignBusinessUnit,
      hideUserStatus,
    } = this.props;
    const pagination = {
      total: this.props.total,
      current: this.props.current,
      pageSize: per_page ? per_page : 20,
      hideOnSinglePage: true,
    };
    const actions = this.props.dashboardView
      ? []
      : [
          {
            title: "Actions",
            key: "action",
            width: "6%",
            render: (record) => (
              <React.Fragment>
                <TableActionComponent
                  record={record}
                  handleAction={this.handleAction}
                  editComponentName={this.getEditComponent({ user: record })}
                  viewComponentName={""}
                  sendReminderComponentName={
                    !record.activated ? reminderComponent : ""
                  }
                  claims={this.props.claims}
                  mfaEnabled={record && record.mfaEnable}
                  deviceEnrolmentValue={record && record.deviceEnrolmentEnable}
                  member={member}
                  showAssignBusinessUnits={showAssignBusinessUnit({
                    user: record,
                    member,
                    userType,
                    pspId: this.props.pspId,
                  })}
                  assignBusinessUnit={
                    userType === "psp" ? assignBusinessUnit : ""
                  }
                />
              </React.Fragment>
            ),
          },
        ];
    const column = _.concat(
      tableColumnGenerator(
        getUserListColumns({
          userType,
          updateAuthentication: this.updateAuthentication,
          claims,
          member,
          hideUserStatus,
          sortInfo,
          component,
          changeStatus: this.changeStatus,
          userMode,
          toggleComponent,
          dashboardView,
        })
      ),
      actions
    );
    return (
      <React.Fragment>
        <SimpleTableContainer
          columns={column}
          size={size}
          dataSource={data}
          pagination={pagination}
          loading={this.props.loading}
          onChange={this.handleTableChange}
          dashboardView={dashboardView}
        />
        <DisablePspUserConfirmationModal
          visible={this.state.viewDisablePspUserConfirmation}
          handleCancel={this.handleDisablePspCancel}
          name={getFullName({ name: this.state.activeUser })}
          handleUserUnassignBusinessUnit={this.handleUserUnassignBusinessUnit}
          match={match}
          member={member}
        />
      </React.Fragment>
    );
  }
}

export default CompanyUserList;
