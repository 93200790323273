import React from "react";
import { mustBeArray } from "../../libs/utilities";
import { Table } from "antd";
import { sizeType } from "../../constants/paramsTypes";

interface SimpleTableProps {
  dataSource: any;
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void;
  rowKeyClassName?: (e: any) => string;
  loading?: boolean;
  columns: any;
  pagination?: any;
  className?: string;
  rowIdParam?: string;
  dashboardView?: boolean;
  bordered?: boolean;
  size?: sizeType;
}

const SimpleTableContainer = (props: SimpleTableProps) => {
  const {
    dataSource,
    pagination,
    onChange,
    loading,
    columns,
    bordered,
    size,
    className,
    rowKeyClassName,
    rowIdParam,
    dashboardView,
  } = props;
  return (
    <Table
      columns={columns}
      rowKey={(record) => record[rowIdParam || "id"]}
      dataSource={mustBeArray(dataSource)}
      bordered={bordered ?? false}
      pagination={
        pagination && !dashboardView
          ? { ...pagination, hideOnSinglePage: true }
          : false
      }
      onChange={onChange}
      loading={loading}
      size={size || "middle"}
      className={className}
      rowClassName={(record) =>
        rowKeyClassName ? rowKeyClassName(record) : ""
      }
    />
  );
};

export default SimpleTableContainer;
