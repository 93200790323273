import { SVGProps } from 'react';
const DawnCross16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.915 4.147a.75.75 0 1 0-1.061-1.06L8 6.938 4.148 3.086a.75.75 0 1 0-1.06 1.06L6.94 8l-3.853 3.853a.75.75 0 1 0 1.06 1.06l3.854-3.852 3.853 3.853a.75.75 0 1 0 1.06-1.06L9.063 8l3.853-3.853Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCross16;
