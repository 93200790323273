//@ts-nocheck
import React, { Component } from "react";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import { FilterFilled } from "@ant-design/icons";
import { Card, Tree, Tooltip, message } from "antd";
import _ from "lodash";
import { errorDisplay, mustBeArray } from "../../../libs/utilities";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import {
  HIDDEN_ID,
  VISIBLE_ENABLED_ID,
} from "../../../constants/defaultClaims";
import { GOOGLE_ANALYTICS_PAGE_TITLE } from "../../../constants";

type State = any;

class PersonaSettings extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      userPersona: [],
      hasCustomCheckbox: false,
      selectedKeys: [],
      checkedKeys: [],
      expandedKeys: [],
      intermediateKeys: [],
      visible: true,
      selectedPersona: undefined,
      activeIndex: 1,
      userPersonaOptions: [],
      authorizationRoleDOM: [],
      indeterminateKeys: [],
    };
  }
  componentDidMount() {
    this.props.updateHeader({
      header: {
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PersonaSetting,
        title: "Roles",
        module: "",
        enableBack: false,
        entity: "persona",
        action: "",
      },
    });

    this.props.listUserRoles({ options: {} }).then((resp) => {
      if (resp.status) {
        let filteredRoles = _.filter(
          mustBeArray(resp.data),
          (o) => o.name !== "SystemAdmin"
        );
        this.setState({ userPersonaOptions: filteredRoles });
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    });
  }
  handleChange = (label, value) => {
    this.setState({
      [label]: value,
    });
  };
  handleNext = () => {
    const { selectedPersona, userPersonaOptions } = this.state;

    this.setState({ expandedKeys: [], selectedKeys: [], checkedKeys: [] });
    const selectedRole = _.find(
      userPersonaOptions,
      (o) => o.id === selectedPersona
    );

    this.props.updateHeader({
      header: {
        title: selectedRole && selectedRole.description,
        module: "",
        enableBack: false,
        entity: "persona",
        action: "",
      },
    });

    this.props
      .handlePersonaSettingsTemplate({ action: "get", id: selectedPersona })
      .then((resp) => {
        if (resp.status) {
          let isAdmin = false;
          if (
            ["PSPAdmin", "CompanyAdmin"].includes(
              selectedRole && selectedRole.name
            )
          ) {
            isAdmin = true;
          }

          const result =
            resp.data &&
            resp.data.data &&
            resp.data.data.result &&
            resp.data.data.result;
          const authorizationDOM = result && result.authorizationDOM;
          let defaultChecked = mustBeArray(
            result && result.authorizationRoleDOM
          );

          this.setState({ authorizationRoleDOM: [...defaultChecked] });
          let updatedRoleDOM = defaultChecked.reduce((a, b) => {
            return _.concat(a, b.statusId === 1 ? [b.componentId] : []);
          }, []);

          let selectedComponents = this.updateSelectedKeys({
            selectedComponents: updatedRoleDOM,
            authorizationDOM,
          });
          let counter = 0;
          while (counter < 8) {
            selectedComponents = this.updateSelectedKeys({
              selectedComponents,
              authorizationDOM,
            });
            counter = counter + 1;
          }
          let intermediateKeys = _.difference(
            updatedRoleDOM,
            selectedComponents
          );

          let userPersona = this.updatePersonaData({
            data: mustBeArray(authorizationDOM),
            isAdmin,
            currrentSelectedKeys: updatedRoleDOM,
          });

          this.setState(
            {
              activeIndex: this.state.activeIndex + 1,
              defaultChecked: selectedComponents,
              selectedKeys: selectedComponents,
              checkedKeys: selectedComponents,
              indeterminateKeys: intermediateKeys,
            },
            () => {
              this.setState({ userPersona });
            }
          );
        }
      });
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'updateSelectedKeys'.
  updateSelectedKeys = ({ selectedComponents, authorizationDOM }) => {
    let updatedSelectedComponents = mustBeArray(selectedComponents).reduce(
      (a, b) => {
        let hasUnselectedChild = false;
        let parentComponent;
        switch (b) {
          case "0.1":
            parentComponent = authorizationDOM[0];
            break;
          case "0.2":
            parentComponent = authorizationDOM[1];
            break;
          case "0.3":
            parentComponent = authorizationDOM[2];
            break;
          case "0.4":
            parentComponent = authorizationDOM[3];
            break;
          case "0.5":
            parentComponent = authorizationDOM[4];
            break;
          default:
            parentComponent = this.findAuthorizationClaim({
              id: b,
              authorizationDOM:
                authorizationDOM[0] && authorizationDOM[0].components,
            });
            break;
        }
        if (
          parentComponent &&
          !_.isEmpty(mustBeArray(parentComponent.components))
        ) {
          hasUnselectedChild = _.find(
            parentComponent.components,
            (o) => !selectedComponents.includes(o.id)
          );
        }
        return a.concat(hasUnselectedChild ? [] : [b]);
      },
      []
    );
    return updatedSelectedComponents;
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'findAuthorizationClaim'.
  findAuthorizationClaim = ({ id, authorizationDOM }) => {
    return mustBeArray(authorizationDOM).reduce((a, claim) => {
      if (a) return a;
      if (claim.id === id) return claim;

      if (claim["components"])
        return this.findAuthorizationClaim({
          authorizationDOM: claim.components,
          id,
        });
      return null;
    }, null);
  };

  handleBack = () => {
    this.setState({ activeIndex: this.state.activeIndex - 1 });

    this.props.updateHeader({
      header: {
        title: "Roles",
        module: "",
        enableBack: false,
        entity: "persona",
        action: "",
      },
    });
  };

  checkIfDisabled = (isAdmin, assigneable, parent) => {
    if (parent && parent.disabled) {
      return true;
    }
    if (isAdmin && assigneable === 0) return true;
    else if (!isAdmin && assigneable !== 2) return true;
    return false;
  };

  updatePersonaData = ({ data, isAdmin, parent = {} }) => {
    return _.each(mustBeArray(data), (persona) => {
      const components = mustBeArray(persona.components);
      persona.children = components;
      persona.key = persona.id;

      persona.title = (
        <span className="flex">
          {persona.friendlyName || persona.name}
          <Tooltip
            className="tooltip-reference-screen"
            title={
              <img
                src={persona.displayHelperUrl}
                alt="reference screen"
                width="100%"
                height="100%"
              />
            }
          >
            {persona.iconUrl ? (
              <img
                src={persona.iconUrl}
                alt={persona.friendlyName}
                width="10px"
                height="10px"
                className="ml-2"
              />
            ) : (
              <FilterFilled className="text-[10px] ml-2" />
            )}
          </Tooltip>
        </span>
      );

      persona.disabled = this.checkIfDisabled(
        isAdmin,
        persona.assigneable,
        parent
      );
      if (!_.isEmpty(components)) {
        return this.updatePersonaData({
          data: components,
          isAdmin,
          parent: persona,
        });
      }
    });
  };

  onSelect = (selectedKeys) => {
    this.setState({ selectedKeys });
  };

  onCheck = (checkedKeys, e) => {
    this.setState({
      checkedKeys,
      indeterminateKeys: mustBeArray(e && e.halfCheckedKeys),
    });
  };

  onExpand = (expandedKeys) => {
    this.setState({ expandedKeys });
  };
  handleSave = () => {
    this.setState({ saveLoading: true });

    const {
      checkedKeys,
      selectedPersona,
      authorizationRoleDOM,
      indeterminateKeys,
    } = this.state;
    let payload = [...authorizationRoleDOM];
    const totalCheckedKeys = [...checkedKeys, ...indeterminateKeys];

    payload = payload.map((data) => {
      return {
        ...data,
        statusId: totalCheckedKeys.includes(data.componentId)
          ? VISIBLE_ENABLED_ID
          : Number(data.statusId) === VISIBLE_ENABLED_ID
            ? HIDDEN_ID
            : data.statusId,
      };
    });

    this.props
      .handlePersonaSettingsTemplate({
        action: "update",
        id: selectedPersona,
        payload: { authorizationRoleDOM: payload },
      })
      .then((resp) => {
        this.setState({ saveLoading: false });

        if (resp.status) {
          message.success("Persona updated successfully.");
          this.setState({
            defaultChecked: [...this.state.checkedKeys],
          });
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };

  checkSaveDisabled = () => {
    const { defaultChecked, checkedKeys } = this.state;
    if (
      _.isEmpty(_.difference(defaultChecked, checkedKeys)) &&
      _.isEmpty(_.difference(checkedKeys, defaultChecked))
    )
      return true;
    return false;
  };

  render() {
    const { claims } = this.props;
    const {
      checkedKeys,
      hasCustomCheckbox,
      selectedPersona,

      selectedKeys,
      expandedKeys,
      userPersona,
      activeIndex,
      saveLoading,
    } = this.state;
    return (
      <React.Fragment>
        {activeIndex === 1 && (
          <React.Fragment>
            <Card className="card">
              <div className="mb-2.5">User Role</div>
              <SingleFieldDropdownFilter
                name="personaTypeDropdownFilter"
                claims={claims}
                options={this.state.userPersonaOptions}
                value={selectedPersona}
                onSelect={(value) =>
                  this.handleChange("selectedPersona", value)
                }
                className="mb-4 w-[300px]"
                hasNoClaims={true}
                nameParam="description"
                valueParam="id"
                placeholder="Select"
              />
            </Card>
            <SimpleButton
              ignoreStatus={true}
              className={selectedPersona ? "bg-success" : ""}
              buttonText="Next"
              onClick={this.handleNext}
              buttonDisabled={selectedPersona ? false : true}
            />
          </React.Fragment>
        )}

        {activeIndex === 2 && (
          <React.Fragment>
            <Card className="card">
              <Tree
                checkable={!hasCustomCheckbox}
                showIcon={true}
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={false}
                onCheck={this.onCheck}
                checkedKeys={checkedKeys}
                onSelect={this.onSelect}
                selectedKeys={selectedKeys}
                treeData={userPersona}
                defaultCheckedKeys={this.state.defaultChecked}
                defaultSelectedKeys={this.state.defaultSelectedKeys}
              />
            </Card>
            <SimpleButton
              ignoreStatus={true}
              className="mr-4"
              buttonText="Back"
              onClick={this.handleBack}
            />
            <SimpleButton
              ignoreStatus={true}
              className={this.checkSaveDisabled() ? "" : "bg-success"}
              buttonText="Save Changes"
              onClick={this.handleSave}
              loading={saveLoading}
              buttonDisabled={this.checkSaveDisabled()}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default PersonaSettings;
