export const NoResultImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 245 152">
    <path
      stroke="#DCDFEA"
      strokeDasharray="2.26 2.26"
      strokeWidth={0.566}
      d="M206.714 30.05c12.015 16.007 21.967 48.546-11.581 67.118M119.406 36.32c7.615-13.016 29.354-31.362 58.722-19.839M37.372 116.73c-12.112-15.335-22.729-47.312 9.173-67.739M127.172 110.578c-9.616 16.629-36.638 39.476-72.32 22.817"
    />
    <circle
      cx={192.843}
      cy={22.829}
      r={8.657}
      fill="#F9F9FB"
      transform="rotate(-10 192.843 22.829)"
    />
    <path
      fill="#fff"
      d="m196.003 24.457-1.776.313-1.744 1.663a.45.45 0 0 1-.754-.247l-.166-.946c-1.333.235-2.377-.496-2.612-1.829l-.47-2.664c-.235-1.332.497-2.377 1.829-2.612l4.441-.783c1.332-.235 2.377.497 2.611 1.829l.47 2.664c.235 1.332-.496 2.377-1.829 2.612Z"
    />
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M193.033 23.854a.213.213 0 1 0 .073.418h.005a.213.213 0 0 0-.074-.418h-.004Z"
      clipRule="evenodd"
    />
    <path
      stroke="#DCDFEA"
      strokeWidth={0.566}
      d="M203.99 20.864a11.323 11.323 0 0 0-19.022-6.169m-3.412 7.23a11.323 11.323 0 0 0 2.216 7.682m6.739 4.301a11.321 11.321 0 0 0 13.023-7.36"
    />
    <circle
      cx={43.556}
      cy={125.664}
      r={6.06}
      fill="#F9F9FB"
      transform="rotate(-30 43.556 125.664)"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m45.697 123.074.002-.001-.06-.104-.602-1.043-.06-.104-.003.001a2.528 2.528 0 0 0-4.373 2.525l-.002.002.06.104.602 1.042.06.105.002-.002a2.527 2.527 0 0 0 4.374-2.525ZM46.574 130.099a5.992 5.992 0 0 0 2.862-3.905 3.363 3.363 0 0 0-2.296.399l-3.578 2.065a3.494 3.494 0 0 0-1.604 2.026c1.5.418 3.16.255 4.616-.585Z"
      clipRule="evenodd"
    />
    <path
      fill="#DCDFEA"
      fillRule="evenodd"
      d="M41.589 122.205a2.22 2.22 0 0 1 3.027.83l.438.757c.618 1.072.256 2.43-.796 3.037A2.22 2.22 0 0 1 41.23 126l-.437-.758a2.22 2.22 0 0 1 .796-3.037Zm-.283-.49c1.326-.766 3.028-.301 3.8 1.037l.438.757c.773 1.339.324 3.045-1.003 3.81-1.326.766-3.028.302-3.8-1.036l-.438-.758c-.773-1.338-.324-3.044 1.003-3.81Zm.635 9.761a3.319 3.319 0 0 1 1.527-1.936l3.578-2.065a3.193 3.193 0 0 1 2.357-.34l.133-.55a3.76 3.76 0 0 0-2.773.399l-3.578 2.066a3.885 3.885 0 0 0-1.787 2.265l.543.161Z"
      clipRule="evenodd"
    />
    <path
      stroke="#DCDFEA"
      strokeWidth={0.566}
      d="M50.42 121.701a7.924 7.924 0 0 0-13.99.496m-.513 5.573a7.924 7.924 0 0 0 3.297 4.522m5.462 1.217a7.928 7.928 0 0 0 6.804-7.96"
    />
    <circle
      cx={181.245}
      cy={133.622}
      r={2.308}
      fill="#fff"
      transform="rotate(30 181.245 133.622)"
    />
    <circle
      cx={180.239}
      cy={133.593}
      r={2.096}
      stroke="#DCDFEA"
      strokeWidth={0.425}
      transform="rotate(30 180.239 133.593)"
    />
    <circle
      cx={218.504}
      cy={62.646}
      r={4.245}
      fill="#fff"
      stroke="#DCDFEA"
      strokeWidth={0.566}
    />
    <path
      fill="#DCDFEA"
      fillRule="evenodd"
      d="M218.686 61.135a.212.212 0 1 0-.425 0v1.203h-1.274a.212.212 0 0 0 0 .424h1.274v1.345a.212.212 0 1 0 .425 0v-1.345h1.273a.212.212 0 0 0 0-.424h-1.273v-1.203Z"
      clipRule="evenodd"
    />
    <g filter="url(#b)">
      <path
        fill="#fff"
        d="M55.444 44.81a6.226 6.226 0 0 1 6.226-6.226h118.868a6.226 6.226 0 0 1 6.226 6.226v76.982H55.444V44.81Z"
      />
    </g>
    <path
      fill="#F9F9FB"
      d="M84.876 38.584h95.661a6.226 6.226 0 0 1 6.226 6.226v76.982H84.876V38.584Z"
    />
    <path
      stroke="#DCDFEA"
      strokeWidth={0.566}
      d="M55.444 85.416v36.376h8.207m-8.207-66.373V45.377a6.792 6.792 0 0 1 6.792-6.793h58.868m12.182 0h40.109m-85.121 83.208h49.245m49.245-41.604V48.985m0 52.005v20.802h-32.83"
    />
    <path
      stroke="#EFF1F5"
      strokeLinecap="round"
      strokeWidth={0.566}
      d="M84.833 38.936v70.755M84.833 68.37H58.795M84.833 79.69H62.758M84.833 102.332H67.286M83.135 113.653H59.928M76.343 91.011H58.795M76.343 57.049H58.795M64.456 102.332h-6.793"
    />
    <rect
      width={45.283}
      height={1.741}
      x={111.481}
      y={91.699}
      fill="#DCDFEA"
      opacity={0.6}
      rx={0.867}
    />
    <rect
      width={3.708}
      height={1.741}
      x={122.261}
      y={91.699}
      fill="#B9C0D4"
      opacity={0.3}
      rx={0.867}
    />
    <rect
      width={6.159}
      height={1.741}
      x={136.119}
      y={91.699}
      fill="#B9C0D4"
      opacity={0.3}
      rx={0.867}
    />
    <rect
      width={33.219}
      height={1.741}
      x={150.181}
      y={98.012}
      fill="#DCDFEA"
      opacity={0.6}
      rx={0.867}
      transform="rotate(-180 150.181 98.012)"
    />
    <rect
      width={11.998}
      height={1.741}
      x={156.764}
      y={98.012}
      fill="#B9C0D4"
      opacity={0.15}
      rx={0.867}
      transform="rotate(-180 156.764 98.012)"
    />
    <rect
      width={11.998}
      height={1.741}
      x={123.479}
      y={98.012}
      fill="#B9C0D4"
      opacity={0.15}
      rx={0.867}
      transform="rotate(-180 123.479 98.012)"
    />
    <rect
      width={28.302}
      height={2.83}
      x={119.972}
      y={82.944}
      fill="#B9C0D4"
      opacity={0.2}
      rx={1.415}
    />
    <path
      fill="#DCDFEA"
      fillRule="evenodd"
      d="M129.453 68.209a3.82 3.82 0 1 1 7.64 0 3.82 3.82 0 0 1-7.64 0Zm3.821-4.67a4.67 4.67 0 1 0 2.988 8.258l2.767 2.767a.424.424 0 1 0 .6-.6l-2.767-2.767a4.67 4.67 0 0 0-3.588-7.658Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M48.651 118.303c0-.625.507-1.132 1.132-1.132h142.641c.625 0 1.132.507 1.132 1.132v3.396a4.529 4.529 0 0 1-4.528 4.529H53.179a4.529 4.529 0 0 1-4.528-4.529v-3.396Z"
    />
    <path
      stroke="#DCDFEA"
      strokeWidth={0.566}
      d="M48.652 122.268a3.96 3.96 0 0 0 3.96 3.96h5.097m-9.057-5.481v-2.444c0-.625.507-1.132 1.132-1.132h71.321m13.442 0h44.257m-93.925 9.057h54.339m54.339-3.831v-2.264m-36.226 6.095H189.029"
    />
    <g filter="url(#c)">
      <path
        fill="#fff"
        d="M27.141 36.32a3.396 3.396 0 0 1 3.397-3.396h65.094a3.396 3.396 0 0 1 3.396 3.396v11.32a3.396 3.396 0 0 1-3.396 3.397H30.538a3.396 3.396 0 0 1-3.397-3.396V36.32Z"
      />
    </g>
    <path
      stroke="#DCDFEA"
      strokeWidth={0.566}
      d="M99.028 40.842V36.32a3.396 3.396 0 0 0-3.396-3.396h-9.266m12.662 14.448v.269a3.396 3.396 0 0 1-3.396 3.396H63.085m12.253-18.113H54.1M27.14 42.695v3.814m0-9.057V36.32a3.396 3.396 0 0 1 3.397-3.396h14.575"
    />
    <path
      fill="#F9F9FB"
      d="M29.971 38.492a2.264 2.264 0 0 1 2.265-2.264h60.566a2.264 2.264 0 0 1 2.264 2.264v6.792a2.264 2.264 0 0 1-2.264 2.264H32.236a2.264 2.264 0 0 1-2.265-2.264v-6.792Z"
    />
    <rect
      width={33.219}
      height={1.698}
      x={83.955}
      y={43.112}
      fill="#DCDFEA"
      opacity={0.6}
      rx={0.849}
      transform="rotate(-180 83.955 43.112)"
    />
    <rect
      width={11.998}
      height={1.698}
      x={90.538}
      y={43.112}
      fill="#B9C0D4"
      opacity={0.15}
      rx={0.849}
      transform="rotate(-180 90.538 43.112)"
    />
    <rect
      width={11.998}
      height={1.698}
      x={57.252}
      y={43.112}
      fill="#B9C0D4"
      opacity={0.15}
      rx={0.849}
      transform="rotate(-180 57.252 43.112)"
    />
    <path
      fill="#DCDFEA"
      fillRule="evenodd"
      d="M33.792 41.322a2.406 2.406 0 1 1 4.812 0 2.406 2.406 0 0 1-4.812 0Zm2.406-3.255a3.255 3.255 0 1 0 1.982 5.837l1.755 1.755a.425.425 0 1 0 .6-.6l-1.755-1.755a3.255 3.255 0 0 0-2.582-5.236Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M192.793 88.618a.566.566 0 0 1-.102.975l-9.537 4.247a.567.567 0 0 1-.794-.577l1.092-10.382a.566.566 0 0 1 .895-.4l8.446 6.137Z"
    />
    <path
      stroke="#DCDFEA"
      strokeWidth={0.566}
      d="M192.282 88.228a.283.283 0 0 1-.086.483l-9.81 3.57a.283.283 0 0 1-.375-.315l1.813-10.28a.282.282 0 0 1 .46-.168l7.998 6.71Z"
    />
    <path
      fill="url(#d)"
      d="M128.317 40.314a.566.566 0 0 1-.895.399l-4.004-2.909a.566.566 0 0 1 .103-.975l4.52-2.012a.566.566 0 0 1 .793.576l-.517 4.921Z"
      opacity={0.2}
    />
    <path
      stroke="url(#e)"
      strokeWidth={0.566}
      d="M128.911 40.854a.282.282 0 0 1-.46.167l-3.791-3.18a.283.283 0 0 1 .085-.483l4.65-1.692c.205-.075.414.1.376.315l-.86 4.873Z"
    />
    <circle
      cx={55.019}
      cy={70.792}
      r={2.308}
      fill="#fff"
      transform="rotate(30 55.019 70.792)"
    />
    <circle
      cx={54.012}
      cy={70.763}
      r={2.096}
      stroke="#DCDFEA"
      strokeWidth={0.425}
      transform="rotate(30 54.012 70.763)"
    />
    <circle
      cx={41.626}
      cy={100.87}
      r={3.334}
      fill="url(#f)"
      opacity={0.2}
      transform="rotate(60 41.626 100.87)"
    />
    <path
      stroke="url(#g)"
      strokeWidth={0.425}
      d="M41.883 102.697a3.122 3.122 0 1 1-3.121-5.408 3.122 3.122 0 0 1 3.12 5.408Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={192.897}
        x2={193.241}
        y1={24.309}
        y2={23.813}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3991FF" />
        <stop offset={0.502} stopColor="#A48AFB" />
        <stop offset={1} stopColor="#FA7066" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={128.614}
        x2={124.571}
        y1={32.379}
        y2={42.912}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3991FF" />
        <stop offset={0.502} stopColor="#A48AFB" />
        <stop offset={1} stopColor="#FA7066" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={130.04}
        x2={125.271}
        y1={33.007}
        y2={43.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3991FF" />
        <stop offset={0.502} stopColor="#A48AFB" />
        <stop offset={1} stopColor="#FA7066" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={38.292}
        x2={44.959}
        y1={104.204}
        y2={97.536}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3991FF" />
        <stop offset={0.502} stopColor="#A48AFB" />
        <stop offset={1} stopColor="#FA7066" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={35.768}
        x2={44.876}
        y1={98.773}
        y2={101.214}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3991FF" />
        <stop offset={0.502} stopColor="#A48AFB" />
        <stop offset={1} stopColor="#FA7066" />
      </linearGradient>
      <filter
        id="b"
        width={153.962}
        height={105.849}
        x={44.123}
        y={31.792}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4.528} />
        <feGaussianBlur stdDeviation={5.66} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.25098 0 0 0 0 0.286275 0 0 0 0 0.407843 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7758_117527" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_7758_117527" result="shape" />
      </filter>
      <filter
        id="c"
        width={94.528}
        height={40.755}
        x={15.821}
        y={26.131}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4.528} />
        <feGaussianBlur stdDeviation={5.66} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.25098 0 0 0 0 0.286275 0 0 0 0 0.407843 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7758_117527" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_7758_117527" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default NoResultImage;
