import { Company, Instance, Persona, User } from './identity.types';

export type Navigation = {
  user: User;
  personas: PersonaExtended[];
  personaLookup: Record<string, PersonaExtended>;
  instances: Instance[];
  instancesLookup: Record<string, Instance>;
  companies: Company[];
  companiesLookup: Record<string, Company>;
};

export type PersonaExtended = Persona & {
  instance: Instance;
  company?: Company | null;
  items: NavItem[];
  isAdmin: boolean;
  defaultUrl?: string;
};

export type NavItem = {
  id: string;
  label: string;
  url: string;
};

export enum GlobalNavQueryParam {
  Persona = 'dsPersonaId',
  Company = 'dsCompanyId',
  Referrer = 'referrer',
}
