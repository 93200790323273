//@ts-nocheck
import { Component } from "react";
import EmailTemplatesList from "../../components/email/list";
import {
  errorDisplay,
  delay,
  findAuthorizationClaims,
  mustBeArray,
  updateAuthorizationClaims,
  getPersonalizationData,
  setPersonalizationData,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  isComponentVisible,
} from "../../../libs/utilities";
import _ from "lodash";
import {
  EMAIL_TEMPLATES_FIELD_OPTIONS,
  EMAIL_TEMPLATES_GRID_OPTIONS,
} from "../../../constants/sortingOptions";
import {
  GRID_VIEW_VALUE,
  PAGINATION_SCROLLING,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  TABLE_VIEW_VALUE,
} from "../../../constants/defaultClaims";
import axios from "axios";
import GridTableViewOption from "../../components/common/gridTableViewOption";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import EmailDisplayCard from "../../components/email/emailDisplayCard";
import ScrollableEmailList from "../../components/email/scrollableEmailList";
import { updateDomChanges, validateUsersAccessRights } from "../../../libs";
import { GOOGLE_ANALYTICS_PAGE_TITLE } from "../../../constants";
import { withRouter } from "../../../hooks";

type State = any;

class EmailIndex extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      loading: true,
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: EMAIL_TEMPLATES_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: EMAIL_TEMPLATES_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
    };
  }
  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
  }

  componentDidMount() {
    const { pageClaims } = this.props;
    //validate if user has access to page
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    if (this.props.dashboardView) {
      console.log("");
    } else {
      this.props.updateHeader({
        header: {
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.EmailTemplateList,
          title: "Email Templates",
          module: "",
          enableBack: false,
          entity: "email",
          action: "",
        },
      });
      const { pageClaims } = this.props;
      const emailTemplateGeneralTable = findAuthorizationClaims({
        claims: pageClaims && pageClaims.components,
        name: "emailTemplateGeneralTable",
      });
      const emailTemplateGeneralGrid = findAuthorizationClaims({
        claims: pageClaims && pageClaims.components,
        name: "emailTemplateGeneralGrid",
      });
      const emailTemplateListContainer = findAuthorizationClaims({
        claims: this.props.pageClaims && this.props.pageClaims.components,
        name: "emailTemplateListContainer",
      });
      const emailTemplatePersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: emailTemplateListContainer?.personalizations,
      });
      this.setState({
        filters: {},
        paginationType: emailTemplatePersonalizations?.paginationType,
        sortingType: emailTemplatePersonalizations?.sortingType,
        isPinned: emailTemplatePersonalizations?.isPinned,
        gridable: emailTemplateListContainer?.gridable,
      });

      // if grid
      if (isComponentVisible({ claim: emailTemplateGeneralGrid })) {
        const emailTemplateGridPersonalizations = getPersonalizationData({
          type: "grid",
          personalizationData: emailTemplateGeneralGrid.personalizations,
        });
        const pageNumber = emailTemplateGridPersonalizations?.pageNumber
          ? Number(emailTemplateGridPersonalizations.pageNumber)
          : 1;
        const row = emailTemplateGridPersonalizations?.row
          ? Number(emailTemplateGridPersonalizations.row)
          : 5;
        const col = emailTemplateGridPersonalizations?.col
          ? Number(emailTemplateGridPersonalizations.col)
          : 4;
        const SortOrder = emailTemplateGridPersonalizations?.sortOrder ?? null;
        const SortColumn =
          emailTemplateGridPersonalizations?.sortColumn ?? null;
        this.setState(
          {
            grid: {
              page: pageNumber ? Number(pageNumber) : 1,
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },
            selectedGridSort:
              _.find(
                this.state.gridSortOption,
                (o) => o.label === SortColumn
              ) &&
              _.find(this.state.gridSortOption, (o) => o.label === SortColumn)
                .id,
          },
          () => {
            if (emailTemplatePersonalizations?.gridView) {
              this.setState({ gridView: true });
              this.fetchMoreData({ isPaginated: true });
              if (
                emailTemplatePersonalizations.paginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingGridEmailTemplate({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.fetchMoreData({ isPaginated: true, notUpdate: true });
              }
            }
          }
        );
      }
      // if table
      if (isComponentVisible({ claim: emailTemplateGeneralTable })) {
        const emailTemplateTablePersonalizations = getPersonalizationData({
          type: "table",
          personalizationData: emailTemplateGeneralTable.personalizations,
        });
        const pageNumber = emailTemplateTablePersonalizations?.pageNumber
          ? Number(emailTemplateTablePersonalizations.pageNumber)
          : 1;
        const pageSize = emailTemplateTablePersonalizations?.pageSize
          ? Number(emailTemplateTablePersonalizations.pageSize)
          : 20;
        const SortOrder = emailTemplateTablePersonalizations?.sortOrder ?? null;
        const SortColumn =
          emailTemplateTablePersonalizations?.sortColumn ?? null;
        this.setState(
          {
            per_page:
              emailTemplatePersonalizations?.paginationType ===
                PAGINATION_SCROLLING
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : pageSize
                  ? Number(pageSize)
                  : 20,
            current: pageNumber ? Number(pageNumber) : 1,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (!emailTemplatePersonalizations.gridView) {
              if (
                emailTemplatePersonalizations?.paginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingEmailTemplate({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.listEmailTemplates({ options: {}, notUpdate: true });
              }
            }
          }
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.signal.cancel("Api is being canceled");
  }

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };
  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.current = 1;
        prevState.listLoading = true;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };
        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }
        this.updateAllPersonalization();
        this.props
          .emailTemplatesHandler({
            queryParams: options,
            cancelToken: this.signal.token,
            action: "list",
          })
          .then((resp) => {
            this.setState({ filterLoading: false, listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };
  handleChange = (label, value) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [label]: value,
      },
    });
  };
  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };
  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", countryId: 0, isActive: 0 },
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.props
          .emailTemplatesHandler({
            queryParams: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                  paginationType: this.state.paginationType,
                  grid: this.state.grid,
                })
                : this.state.per_page,
            },
            action: "list",

            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };
  updateAllPersonalization = () => {
    delay(100).then(() => {
      this.updatePersonalization("emailTemplateGeneralTable", false);
    });
    delay(400).then(() => {
      this.updatePersonalization("emailTemplateGeneralGrid");
    });
  };

  listScrollingEmailTemplate = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            this.state.total === mustBeArray(this.state.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .emailTemplatesHandler({
            queryParams: tempOptions,
            action: "list",
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingEmailTemplate({
                    options: {},
                    pageLoad: true,
                  });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],
                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("emailTemplateGeneralTable");
          }
        });
      }
    );
  };

  listScrollingGridEmailTemplate = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      {
        listLoading: true,
        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid && this.state.grid.page) + 1
            : Number(this.state.grid && this.state.grid.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;

        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });

        let filterOptions = this.getFilters();

        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });

        this.props
          .emailTemplatesHandler({
            queryParams: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
            },

            cancelToken: this.signal.token,
            action: "list",
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridEmailTemplate({
                    options: {},
                    pageLoad: true,
                  });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization("emailTempateGeneralGrid");
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listEmailTemplateGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid && this.state.grid.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .emailTemplatesHandler({
            queryParams: tempOptions,
            action: "list",
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("emailTempateGeneralGrid");
        });
      }
    );
  };

  listEmailTemplates = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .emailTemplatesHandler({
            queryParams: tempOptions,

            action: "list",
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listEmailTemplates({ options: {} });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("emailTemplateGeneralTable");
          }
        });
      }
    );
  };

  emailTemplatesHandler = ({ options }) => {
    this.setState(
      {
        current: options.page,
        sortInfo: { field: options.field, order: options.order },
      },
      () => {
        this.listEmailTemplates();
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member &&
        this.props.member.details &&
        this.props.member.details.authorizationDOM
      );

      const emailTemplateListContainer = _.find(
        mustBeArray(this.props.pageClaims && this.props.pageClaims.components),
        (o) => o.name === "emailTemplateListContainer"
      );

      const emailTemplateGeneralTable = _.find(
        mustBeArray(this.props.pageClaims && this.props.pageClaims.components),
        (o) => o.name === "emailTemplateGeneralTable"
      );

      const emailTemplateGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims && this.props.pageClaims.components),
        (o) => o.name === "emailTemplateGeneralGrid"
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];
      switch (field) {
        case "emailTemplateGeneralTable":
          if (emailTemplateListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,

              sortOrder: this.state.sortInfo && this.state.sortInfo.order,

              sortColumn: this.state.sortInfo && this.state.sortInfo.field,

              pageSize: `${this.state.per_page}`,
              personalizationData:
                emailTemplateGeneralTable &&
                emailTemplateGeneralTable.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims && this.props.pageClaims.components,
              name: field,
            });
          }
          break;
        case "emailTemplateGeneralGrid":
          if (emailTemplateListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid && grid.page}`,
              row: `${grid && grid.row}`,
              col: `${grid && grid.col}`,
              sortOrder: grid && grid.sortInfo && grid.sortInfo.order,
              sortColumn: grid && grid.sortInfo && grid.sortInfo.field,
              personalizationData:
                emailTemplateGeneralGrid &&
                emailTemplateGeneralGrid.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims && this.props.pageClaims.components,
              name: field,
            });
          }
          break;
        case "emailTemplateListContainer":
          if (emailTemplateListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData:
                emailTemplateListContainer &&
                emailTemplateListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims && this.props.pageClaims.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent && updatedComponent.id,
          name: updatedComponent && updatedComponent.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }
      const updateChanges = updateDomChanges({
        update,
        condition: field === "emailTemplateGeneralGrid",
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };
  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    return tempFilters;
  };

  fetchMoreData = ({ isPaginated = false }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props
      .emailTemplatesHandler({
        queryParams: {
          page,
          per_page,
          q: { ...options },
          ...sortingParams,
        },

        action: "list",
        cancelToken: this.signal.token,
      })

      .then((response) => {
        if (response.status) {
          this.setState(
            (prevState) => {
              const data = mustBeArray(response.data);
              if (isPaginated) {
                prevState.list = data;
              } else {
                prevState.list = prevState.list.concat(data);

                if (data.length !== 0 && data.length === this.state.page_size) {
                  prevState.hasMoreData = true;
                  prevState.grid = {
                    ...prevState.grid,
                    page: prevState.grid.page + 1,
                  };
                } else {
                  prevState.hasMoreData = false;
                }
              }

              prevState.total = response.total;
              prevState.listLoading = false;
              return prevState;
            },
            () => {
              delay(300).then(() => {
                this.updatePersonalization("emailTemplateGeneralGrid");
              });
            }
          );
        }
      });
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization("emailTemplateListContainer");
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingEmailTemplate({ pageLoad: true, options: {} });
        } else {
          this.listEmailTemplates({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridEmailTemplate({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridEmailTemplate({
              pageLoad: true,
              options: {},
            });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingEmailTemplate({ pageLoad: true, options: {} });
        } else {
          this.listEmailTemplates({ options: {} });
        }
      });
    }
  };
  updateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.selectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.gridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.grid = {
            ...prevState.grid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridEmailTemplate({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
      case "edit":
        this.props.updateHeader({
          header: {
            gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.EmailTemplateEdit,
          },
        });
        this.props.router.navigate(`/emailtemplate/${data && data.id}`);
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  updatePinnedComponent = () => {
    this.setState(
      { isPinned: this.state.isPinned === "true" ? "false" : "true" },
      () => {
        this.updatePersonalization("emailTemplateListContainer");
      }
    );
  };
  render() {
    const { pageClaims } = this.props;
    const {
      sortInfo,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      hasMoreData,
      isPinned,

      paginationType,
      gridable,
      fieldOptions,
      sortingType,
      current,
    } = this.state;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });
    return (
      <div>
        <div className={"mb-6 float-right"}>
          <GridTableViewOption
            gridable={gridable}
            handleGridChange={this.handleGridChange}
            gridView={gridView}
            fieldOptions={fieldOptions}
            updateGridField={this.updateGridField}
            gridSortInfo={this.state.grid && this.state.grid.sortInfo}
            tableSortInfo={this.state.sortInfo}
            updatePageSize={this.updatePageSize}
            grid={grid}
            per_page={per_page}
          />
        </div>
        <div className="clearfix"></div>
        <ListContainer
          name="emailTemplateListContainer"
          claims={pageClaims}
          isPinned={isPinned}
          updatePinnedComponent={this.updatePinnedComponent}
          gridable={gridable}
          gridView={gridView}
          grid={grid}
          per_page={per_page}
          GeneralTablePaginatedComponent={EmailTemplatesList}
          GeneralTableScrollableComponent={ScrollableEmailList}
          generalTableComponentName={"emailTemplateGeneralTable"}
          gridComponentName={"emailTemplateGeneralGrid"}
          createLinkButtonName={""}
          handleAddAction={() => {
            console.log("");
          }}
          handleGridChange={this.handleGridChange}
          fieldOptions={fieldOptions}
          updateGridField={this.updateGridField}
          sortingType={sortingType}
          paginationType={paginationType}
          list={list}
          listLoading={listLoading}
          listGrid={this.listEmailTemplateGrid}
          listScrollingGrid={this.listScrollingGridEmailTemplate}
          hasMoreData={hasMoreData}
          gridActionComponentName={"emailTemplateViewDetailLink"}
          moduleType={"emailTemplate"}
          handleGridAction={this.handleAction}
          listScrollingGeneralTable={this.listScrollingEmailTemplate}
          total={total}
          current={current}
          sortInfo={sortInfo}
          listGeneralTable={this.listEmailTemplates}
          tableActionComponentName={"emailTemplateViewDetailLink"}
          gridPageSize={getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          })}
          cardGrid={cardGrid}
          showAddAndGrid={false}
          DisplayCardComponent={EmailDisplayCard}
          editComponent="emailTemplateEdit"
          updateHeader={this.props.updateHeader}
        />
      </div>
    );
  }
}

export default withRouter(EmailIndex);
