//@ts-nocheck
import React, { Component } from "react";
import { Modal, Input, message } from "antd";
import _ from "lodash";

type State = any;
type Props = any;
class RejectAmendments extends Component<
  Props,
  State
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      description: "",
    };
  }

  handleChange = (description: any) => {
    this.setState({ description });
  };
  submitRejection = () => {
    if (
      _.isNull(this.state.description) ||
      this.state.description.trim() === ""
    ) {
      message.error("Please enter a reason");
    } else {
      this.props.submitRejection(
        this.props && this.props.amendment,
        this.state.description
      );
    }
  };
  componentDidMount() {
    const { decliningPayrun, payrun } = this.props;
    if (decliningPayrun) {
      this.handlePayrunRejectDescription({ payrun });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    const { decliningPayrun, payrun, visible, amendment } = newProps;
    if (decliningPayrun) {
      if (visible && !this.props.visible) {
        this.handlePayrunRejectDescription({ payrun });
      }
      if (!visible && this.props.visible) {
        this.setState({ description: "" });
      }
    } else {
      if (
        (!visible && this.props.visible) ||
        (visible && !this.props.visible)
      ) {
        this.setState({ description: "" });
      }
    }
  }

  handlePayrunRejectDescription = ({ payrun }: any) => {
    try {
      if (
        payrun.rejectDescription &&
        JSON.parse(payrun.rejectDescription) &&
        JSON.parse(payrun.rejectDescription).RejectionReason
      ) {
        const description = JSON.parse(
          payrun.rejectDescription
        ).RejectionReason;
        this.setState({ description });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { visible, cancelRejection, submitRejectionLoading, title } =
      this.props;
    return (
      <Modal
        title={title || `Are you sure you want to reject this amendment?`}
        visible={visible}
        width={800}
        okText="Yes"
        cancelText="No"
        onCancel={cancelRejection}
        onOk={() => this.submitRejection()}
        okButtonProps={{ loading: submitRejectionLoading }}
        className="modal-title-lh-30"
      >
        <Input.TextArea
          placeholder="Please enter a reason"
          onChange={(e) => this.handleChange(e.currentTarget.value)}
          value={this.state.description}
          maxLength={1000}
          rows={4}
        />
      </Modal>
    );
  }
}

export default RejectAmendments;
