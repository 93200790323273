import { PropsWithChildren } from 'react';

import { FeatureFlagContext } from './feature-flag.context';
import { FeatureProviderProps } from './feature-flag.types';
import { useFeatureInit } from './use-feature-init';

/**
 * This feature provider is meant to be applied at the root of the application.
 *
 * @example
 * import FeatureProvider from '@xemplo/feature-flag';
 *
 * const App = () => {
 *    // define the feature flag mapping object
 *
 *    const featureMap: FeatureFlag = {
 *      [Feature.AmendmentUpload]: {
 *        name: 'Amendment Upload',
 *        enabled: {
 *          value: true,
 *          environment: ['dev'],
 *          context: ['gp'],
 *        },
 *      },
 *    };
 *
 *    const props = {
 *      featureMap,
 *      environment: environment.instance,
 *      appContext: environment.appContext
 *    }
 *
 *     <FeatureProvider {...propss}>
 *       <MyComponent />
 *     </FeatureProvider>
 *   );
 * };
 *
 * @param featureMap The object map that describes the features to be checked.
 * @param children The rest of the react nodes
 * @returns
 */
export function FeatureProvider(props: PropsWithChildren<FeatureProviderProps>) {
  return (
    <FeatureFlagContext.Provider value={useFeatureInit(props)}>
      {props.children}
    </FeatureFlagContext.Provider>
  );
}
