// @ts-nocheck
import { validateInputValueIncludesOnlyNumbers } from "./timeHandlers";

export const abnValidator = (value: any) => {
  validateInputValueIncludesOnlyNumbers({
    e: value,
    maxAmount: 99999999999,
    hasDecimal: false,
    isPositive: true,
  });
};

export const mobileNumberValidator = (value: "string" | "number") => {
  validateInputValueIncludesOnlyNumbers({
    e: value,
    maxAmount: 99999999999,
    hasDecimal: false,
    isPositive: true,
  });
};
