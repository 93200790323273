// @ts-nocheck
import { Card } from "antd";
import React from "react";
const InformationDisplayCard = (props: any) => {
  const { IconComponent, title, description, cardClassName, ActionComponent } =
    props;
  return (
    <Card className={`rounded-[12px] p-6 ${cardClassName} min-h-[158px]`}>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center">
          <div className="mr-8">
            <IconComponent />
          </div>
          <div>
            <div className="text-base font-bold mb-3">{title}</div>
            <div className="text-sm font-normal">{description}</div>
          </div>
        </div>
        {ActionComponent && (
          <div>
            <ActionComponent {...props} />
          </div>
        )}
      </div>
    </Card>
  );
};
export default InformationDisplayCard;
