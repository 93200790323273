import * as S from './modal.styles';

export const ModalHeaderIcon = () => {
  return <S.IconContainer>{HeaderIcon}</S.IconContainer>;
};

const HeaderIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0924 4.95417C19.6989 4.56662 19.0658 4.57143 18.6782 4.96491C18.2907 5.35839 18.2955 5.99153 18.689 6.37908L21.63 9.27584H14.7109C13.2094 9.27584 11.7664 9.86317 10.7001 10.9134C9.63334 11.9641 9.03119 13.3924 9.03119 14.885V20.7987H4.18164C3.62936 20.7987 3.18164 21.2464 3.18164 21.7987C3.18164 22.351 3.62936 22.7987 4.18164 22.7987H10.0208L10.0312 22.7988L10.0416 22.7987H26.3097L23.3687 25.6954C22.9752 26.0829 22.9704 26.7161 23.358 27.1095C23.7455 27.503 24.3787 27.5078 24.7721 27.1203L29.4518 22.5111L29.4518 22.5111C29.4764 22.4869 29.4997 22.4614 29.5217 22.4348C29.6465 22.2835 29.7278 22.0949 29.7461 21.8883C29.7488 21.8586 29.7501 21.8287 29.7501 21.7987C29.7501 21.7658 29.7485 21.7332 29.7453 21.7008C29.7281 21.5235 29.6646 21.3598 29.567 21.2218C29.5329 21.1736 29.4945 21.1282 29.4518 21.0862L24.7721 16.477C24.3787 16.0895 23.7455 16.0943 23.358 16.4878C22.9704 16.8813 22.9752 17.5144 23.3687 17.9019L26.3098 20.7987H11.0312V14.885C11.0312 13.9327 11.4151 13.0163 12.1036 12.3383C12.7925 11.6597 13.7301 11.2758 14.7109 11.2758H21.63L18.689 14.1725C18.2955 14.5601 18.2907 15.1932 18.6782 15.5867C19.0658 15.9802 19.6989 15.985 20.0924 15.5974L24.7721 10.9883C24.8044 10.9564 24.8344 10.9225 24.8618 10.887C24.9713 10.7454 25.0437 10.5736 25.0643 10.3862C25.0683 10.3497 25.0704 10.3129 25.0704 10.2758C25.0704 10.2366 25.0681 10.1977 25.0636 10.1592C25.0448 9.99761 24.9875 9.84775 24.9011 9.71907C24.8637 9.66335 24.8206 9.61113 24.7721 9.56334L20.0924 4.95417Z"
      fill="url(#paint0_linear_3479_15662)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3479_15662"
        x1="3.18164"
        y1="27.4078"
        x2="25.6505"
        y2="1.15756"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3991FF" />
        <stop offset="0.501502" stopColor="#A48AFB" />
        <stop offset="1" stopColor="#FA7066" />
      </linearGradient>
    </defs>
  </svg>
);
