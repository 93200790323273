// @ts-nocheck
import { http, ResponseHandler } from "../../libs";
import { requestBuilder } from "../../libs/utilities";
import APIHandler from "../../constants/apiUrl";

export function emailTemplatesHandler({
  id,
  queryParams = {},
  payload = {},
  action,
  cancelToken,
}) {
  return (dispatch, store) =>
    new Promise(async (resolve, reject) => {
      let action_type = "";
      let actionMatch = true;
      let response = { status: 200 };
      let url = "";
      const queryOptions = {
        page: 1,
        ...queryParams,
      };
      switch (action) {
        case "list":
          url = APIHandler.constructEndpoint({
            endpoint: "EMAIL_TEMPLATES_URL",
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          action_type = "LIST_EMAIL_TEMPLATES";
          break;
        case "get":
          url = APIHandler.constructEndpoint({
            endpoint: "EMAIL_TEMPLATES_ACTION_URL",
            options: { id },
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);

          action_type = "GET_EMAIL_TEMPLATE";
          break;
        case "update":
          url = APIHandler.constructEndpoint({
            endpoint: "EMAIL_TEMPLATES_ACTION_URL",
            options: { id },
          });
          response = await http.put(`${url}/update`, payload);
          action_type = "UPDATE_EMAIL_TEMPLATES";
          break;
        default:
          console.log("unregonized action");
          break;
      }

      // case "delete":
      //   url = APIHandler.constructEndpoint({
      //     endpoint: "WORKFLOW_STEP_URL",
      //     options: { ...urlParams },
      //     isMocked: false
      //   });
      //   response = await http.del(`${url}/${id}`);
      //   action_type = DELETE_WORKFLOW_STEP;
      //   break;

      //   default:
      //     actionMatch = false;
      //     console.log("unrecognized action");
      // }
      if (actionMatch) {
        if (ResponseHandler.isValidStatus(response)) {
          return resolve(
            dispatch(
              ResponseHandler.validObject(response.data.result, action_type)
            )
          );
        } else {
          return resolve(ResponseHandler.inValidObject(response));
        }
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
