import { SVGProps } from 'react';
const DawnArrowLongUp16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.25c.21 0 .399.086.535.224l2.662 2.662a.75.75 0 1 1-1.061 1.061L8.75 3.811V14a.75.75 0 0 1-1.5 0V3.81L5.863 5.198a.75.75 0 1 1-1.06-1.06L7.469 1.47A.748.748 0 0 1 8 1.25Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLongUp16;
