//@ts-nocheck
import React, { Component } from "react";
import { DownCircleOutlined } from "@ant-design/icons";
import { List, Tooltip } from "antd";
import { mustBeArray } from "../../../../libs/utilities";
import { onlyDateFormater } from "../../../../libs";
import ClaimWrapper from "../../../../Common/claimWrapper";
import { getPayrunColumnTitle } from "../../../../libs";
import SimpleButton from "../../../../Common/ClaimComponents/Button/SimpleButton";

type State = any;

class ExternalPayrunList extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      externalPayruns,
      title,
      importing,
      payrun,
      claims,
      showRejectionReason,
      keypayIntergrationLoading,
    } = this.props;
    return (
      <List
        header={getPayrunColumnTitle({ title, showRejectionReason, payrun })}
        itemLayout="horizontal"
        loading={importing}
        dataSource={mustBeArray(externalPayruns)}
        bordered={false}
        className={`${this.props.className} defaultListHeader  `}
        renderItem={(data) => (
          <List.Item key={data.id} className="p-4">
            <List.Item.Meta
              title={
                <React.Fragment>
                  <span className="text-base">
                    {onlyDateFormater(data.payPeriodStarting)} to{" "}
                    {onlyDateFormater(data.payPeriodEnding)}
                  </span>
                  <span className="color-danger text-[13px] italic">
                    {" "}
                    Pay date: {onlyDateFormater(data.datePaid)}
                  </span>
                </React.Fragment>
              }
            />
            <ClaimWrapper name="payrunExternalImportIconButton" claims={claims}>
              <Tooltip title="Import" placement="bottom">
                <SimpleButton
                  type="primary"
                  onClick={() => this.props.importEarlinglines(data)}
                  className="rounded-lg"
                  buttonDisabled={keypayIntergrationLoading}
                  ignoreStatus={true}
                  icon={<DownCircleOutlined />}
                  buttonText="Import"
                />
              </Tooltip>
            </ClaimWrapper>
          </List.Item>
        )}
      />
    );
  }
}

export default ExternalPayrunList;
