import { DawnTriangleAlert } from "@xemplo/icons";
import { Colour } from "@xemplo/style-constants";
import React from "react";

export const SkipPayrunModalHeader = () => {
  return (
    <div className="flex justify-center items-center flex-col pt-3 ">
      <DawnTriangleAlert color={Colour.Red[500]} fontSize="2em" />
      <div className="text-2xl p-1">Confirm Skip</div>
    </div>
  );
};
