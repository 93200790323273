import { SVGProps } from 'react';
const DawnPencilEdit24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.544 3.51a4.888 4.888 0 0 0-6.856-.057L5.08 11.78a9.587 9.587 0 0 0-2.797 5.351l-.272 1.672c-.28 1.723 1.079 3.206 2.726 3.194A.8.8 0 0 0 4.8 22h16.426a.75.75 0 1 0 0-1.5H9.696a9.567 9.567 0 0 0 1.918-1.438l8.872-8.583a4.888 4.888 0 0 0 .058-6.97ZM14.73 4.53a3.388 3.388 0 0 1 4.712 4.87l-1.485 1.437-4.792-4.792 1.565-1.514Zm-2.643 2.558L6.123 12.86a8.087 8.087 0 0 0-2.36 4.514l-.271 1.672a1.25 1.25 0 0 0 1.52 1.417l1.8-.423a8.068 8.068 0 0 0 3.76-2.055l6.308-6.104-4.792-4.792Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnPencilEdit24;
