const initialState = {
  progressBarStatus: false,
  fullscreenLoader: false,
};

export default function uiReducer(state = initialState, action: any) {
  const { type } = action;
  switch (type) {
    case "SET_PROGRESS_BAR":
      return {
        ...state,
        progressBarStatus: true,
      };
    case "UNSET_PROGRESS_BAR":
      return {
        ...state,
        progressBarStatus: false,
      };
    case "SET_FULLSCREEEN_LOADER":
      return {
        ...state,
        fullscreenLoader: true,
      };
    case "UNSET_FULLSCREEEN_LOADER":
      return {
        ...state,
        fullscreenLoader: false,
      };
    default:
      return state;
  }
}
