//@ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import numeral from "numeral";
import classNames from "classnames";

type State = any;
type Props = any;

class SummaryView extends React.Component<Props, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  getVarianceClassName = (value: any) => {
    if (numeral(value)._value > 0)
      return { bg: "bg-list-success", color: "color-success" };
    if (numeral(value)._value < 0)
      return { bg: "bg-list-warning", color: "color-danger" };
    return { bg: "", color: "" };
  };
  render() {
    const {
      rowClassName,
      colOneValue,
      colTwoValue,
      colThreeValue,
      colOneClassName,
      colTwoClassName,
      colThreeClassName,
      colFourClassName,
      colFourValue,
      showVariance,
      subType,
    } = this.props;
    return (
      <Row
        className={`${rowClassName} w-full ${
          showVariance ? this.getVarianceClassName(colFourValue).bg : ""
        }`}
        gutter={16}
      >
        <Col span={showVariance ? 9 : 14} className={colOneClassName}>
          <div className={classNames({ "pl-4": subType })}>{colOneValue}</div>
        </Col>
        {showVariance && (
          <Col
            span={5}
            className={classNames(colTwoClassName, "pr-0")}
            align={"right"}
          >
            {colTwoValue}
          </Col>
        )}
        <Col
          span={showVariance ? 5 : 10}
          className={classNames(colThreeClassName, "pr-0")}
          align="right"
        >
          {colThreeValue}
        </Col>
        {showVariance && (
          <Col
            span={5}
            className={`${colFourClassName} ${
              this.getVarianceClassName(colFourValue).color
            }`}
            align="right"
          >
            {Number(colFourValue) < 0
              ? `(${Math.abs(colFourValue)})`
              : colFourValue}
          </Col>
        )}
      </Row>
    );
  }
}

export default SummaryView;
