export const currentStatus = (payrun: any) => {
  if ([1].includes(payrun.payrunStatusID)) return 0;
  else if ([2, 3].includes(payrun.payrunStatusID)) return 1;
  else if ([4, 5, 6, 7].includes(payrun.payrunStatusID)) return 2;
  else if ([8, 9].includes(payrun.payrunStatusID)) return 3;
  else if ([10, 11, 12, 13, 14].includes(payrun.payrunStatusID)) return 4;
  else if ([15, 16, 17].includes(payrun.payrunStatusID)) {
    if (payrun.payrollModelId === 1) return 5;
    return 4;
  } else if ([18].includes(payrun.payrunStatusID)) return 6;
};
