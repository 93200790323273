import { AxiosError, isAxiosError } from 'axios';

const DEFAULT_ERROR_MESSAGE = 'An error occurred';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getSimpleErrorMessage(error: AxiosError<unknown>) {
  const simpleError = error.message ?? DEFAULT_ERROR_MESSAGE;
  if (!isAxiosError(error)) return simpleError;

  const data = error.response?.data;
  if (!data) return simpleError;

  // when data is a string, we return it as is. We shouldn't have
  // this stuff anymore but lets keep it just in case
  if (typeof data === 'string' && data.trim() !== '') return simpleError;

  // when data is any other primitive type or array, we ignore it
  if (typeof data !== 'object') return simpleError;

  // we are looking specifically for errors from API response
  if (!('errors' in data)) return simpleError;

  const errors = data.errors;

  // in case the error exist but is a simple string
  if (typeof errors === 'string' && errors.trim() !== '') return errors;

  // the errors can also be an array of strings
  if (Array.isArray(errors)) {
    const mergedMsgs = errors.join(', ').trim();
    return mergedMsgs !== '' ? mergedMsgs : simpleError;
  }

  // the errors can also be an object with key-value pairs
  // this check is to ensure that if an api returns some
  // weird response on error, we safely cater for it by ignoring it
  if (typeof errors === 'object') {
    const mergedMsgs = Object.values(errors ?? {})
      .flat()
      .join(', ')
      .trim();

    return mergedMsgs !== '' ? mergedMsgs : simpleError;
  }

  // safe fallback
  return simpleError;
}
