import { useMutation } from '@tanstack/react-query';

import { useHttpClient } from '@xemplo/http';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { PayScheduleAPI } from './pay-schedule-query.constants';
import { GeneratePayrunMutationProps } from './pay-schedule-query.types';

/** Manually generate a payrun for a pay schedule  */
export const useGeneratePayrun = (props: GeneratePayrunMutationProps) => {
  const { payScheduleId, ...mutationOptions } = props;
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const url = apiUrlBuilder(
    PayScheduleAPI.generatePayrun(payScheduleId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: () => client.post(url).then((resp) => resp.data),
    ...mutationOptions,
  });
};
