import { CenteredLoaderDots } from "@xemplo/loader";
import { useAppSelector } from "../../../../hooks";
import { RootState } from "../../../../store";
import styled from "styled-components";
import { Colour } from "@xemplo/style-constants";

const StyledLoader = styled.div`
  position: fixed;
  background-color: ${Colour.Black[100]};
  opacity: 0.6;
  width: 100dvw;
  height: 100dvh;
  z-index: 1000;
`;

export const FullscreenLoader = () => {
  const fullscreenLoading = useAppSelector(
    (state: RootState) => state.ui.fullscreenLoader
  );

  if (!fullscreenLoading) return null;

  return (
    <StyledLoader>
      <CenteredLoaderDots />
    </StyledLoader>
  );
};
