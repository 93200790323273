import { List } from "antd";
import { If } from "../../../../Common/ui";
import {
  currencyFormater,
  getDifferenceAndVariance,
  mustBeArray,
} from "../../../../libs";
import SummaryView from "../summaryView";

type Props = {
  invoice: any;
  businessunitDetails: any;
  showVariance?: boolean;
  previousTotalEarnings?: number;
  totalEarnings?: number;
  subPayItemList?: Array<any>;
  previousSubPayItemList?: Array<any>;
};
const PayrunSummaryGrossIncome = ({
  businessunitDetails,
  showVariance,
  previousTotalEarnings,
  subPayItemList,
  totalEarnings,
  previousSubPayItemList,
}: Props) => {
  return (
    <List.Item key={"grossIncome"} className="flex-col flex-align-normal">
      <SummaryView
        colOneValue="Gross Income"
        colTwoValue={currencyFormater(
          previousTotalEarnings,
          businessunitDetails?.country
        )}
        colThreeValue={currencyFormater(
          totalEarnings,
          businessunitDetails?.country
        )}
        colFourValue={
          getDifferenceAndVariance(previousTotalEarnings, totalEarnings)
            .variance
        }
        showVariance={showVariance}
        colTwoClassName="pr-0 bold"
        colThreeClassName="pr-0 bold"
        colFourClassName="pr-0 bold pr-2"
        colOneClassName="bold"
      />

      {mustBeArray(subPayItemList).map((data: any, b: any) => {
        let previousData = previousSubPayItemList?.find(
          (o) => o.id === data.id
        );
        return (
          <If
            key={`${b}subPayItems`}
            condition={data.amount !== 0}
            then={
              //   <div className="pl-4">
              <SummaryView
                key={b}
                rowClassName="ml-0 mr-0"
                colOneClassName="pl-3 font-medium"
                colTwoClassName="font-medium"
                colThreeClassName="font-medium"
                colFourClassName="font-medium"
                colOneValue={data.category}
                colTwoValue={currencyFormater(
                  previousData?.amount,
                  businessunitDetails?.country,
                  true
                )}
                colThreeValue={currencyFormater(
                  data.amount,
                  businessunitDetails?.country
                )}
                colFourValue={
                  getDifferenceAndVariance(previousData?.amount, data.amount)
                    .variance
                }
                showVariance={showVariance}
                subType
              />
              //   </div>
            }
          />
        );
      })}
    </List.Item>
  );
};
export default PayrunSummaryGrossIncome;
