import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChangeTaskTypes } from '@xemplo/gp-types';
import { useGetBusinessUnitWorkersQuery } from '@xemplo/lookups-query';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';
import { Toggle, ToggleSize } from '@xemplo/switch';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';
import { FUTURE_SCHEDULED_PAYRUN } from '../page-1/select-payrun';

import { TerminatedWorkers } from './terminated-workers';

export const AppliesTo = ({ selectedWorkers }: { selectedWorkers?: string[] }) => {
  const { watch, resetField } = useFormContext();

  const watchBusinessUnit = watch('businessUnit');
  const watchPayrun = watch('payrun');
  const watchChangeTask = watch('changeTask');
  const watchTerminatedWorkers = watch('terminatedWorkers');
  const [showTerminatedWorkers, setShowTerminatedWorkers] = useState(false);
  const isPayInstruction = watchChangeTask === ChangeTaskTypes.PAY_INSTRUCTION;
  const isTermination = watchChangeTask === ChangeTaskTypes.TERMINATION;
  const payrunId =
    watchPayrun === FUTURE_SCHEDULED_PAYRUN || watchPayrun === PLACEHOLDER_OPTION
      ? null
      : watchPayrun;
  const { data: payrunData } = useGetPayrunByIdQuery({
    id: payrunId,
  });
  const watchBUValue =
    watchBusinessUnit === PLACEHOLDER_OPTION ? null : watchBusinessUnit;
  const { data, isLoading } = useGetBusinessUnitWorkersQuery({
    id: payrunData?.result?.businessUnitID ?? watchBUValue ?? null,
    requestParams: { per_page: 0 },
  });

  const parsedData = useMemo(() => {
    if (!data) return [];
    return data?.result?.map((item) => {
      return { key: item.id, value: `${item.firstName} ${item.lastName}` };
    }, []);
  }, [data]);

  useEffect(() => {
    if (watchChangeTask === ChangeTaskTypes.TERMINATION) {
      resetField('appliesTo');
    }
  }, [resetField, watchChangeTask]);

  const showTerminatedWorkersToggle = !isPayInstruction && !isTermination;
  return (
    <S.FormLabel>
      <S.AppliesToLabelWrapper>
        <div>
          Applies to {isPayInstruction && <S.OptionalLabel>(optional)</S.OptionalLabel>}
        </div>
        {showTerminatedWorkersToggle && (
          <S.TerminatedToggle>
            Include Terminated Workers
            <Toggle
              size={ToggleSize.Small}
              ariaLabel="toggle terminated workers"
              id="toggle-terminated-workers"
              value="toggle-terminated-workers"
              onChange={(e) => setShowTerminatedWorkers(e.target.checked)}
            />
          </S.TerminatedToggle>
        )}
      </S.AppliesToLabelWrapper>

      <TextDropdownMultiV2
        name="appliesTo"
        isDropdownMulti={!isTermination}
        items={parsedData}
        defaultSelectedKeys={selectedWorkers}
        testId="appliesTo"
        itemsLoading={isLoading || !parsedData.length}
        displayProps={{
          isDisabled: isLoading || !parsedData.length,
        }}
        selectProps={{
          placeholder: isTermination ? 'Select worker' : 'Select worker(s)',
          label: 'Applies to',
        }}
        rules={{
          required:
            isPayInstruction || watchTerminatedWorkers?.length > 0
              ? false
              : 'This field is required',
        }}
      />
      {showTerminatedWorkers && showTerminatedWorkersToggle && <TerminatedWorkers />}
    </S.FormLabel>
  );
};
