import { SyntheticEvent, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { MenuItem } from '../tab-menu.types';
import { useTabMenu } from '../use-tab-menu';

import * as S from './menu-item.styles';

export const TabMenuItemTestId = {
  listItem: (itemId: string) => `tab-menu-list-item-${itemId}`,
  link: (itemId: string) => `tab-menu-link-${itemId}`,
};

type TabMenuItemProps = MenuItem & {
  className?: string;
};

export const TabMenuItem = ({ className, ...item }: TabMenuItemProps) => {
  const { id, role } = item;
  const { state } = useTabMenu();
  const { size, isPrimary } = state;

  return (
    <S.MenuItem
      key={id}
      id={`item-wrapper-${id}`}
      role={role ?? 'menuitem'}
      data-testid={TabMenuItemTestId.listItem(id)}
      className={classnames(
        {
          primary: !!isPrimary,
          disabled: item.disabled,
        },
        [size.toLowerCase()],
        className
      )}
    >
      <MenuLinkItem {...item} />
    </S.MenuItem>
  );
};

const MenuLinkItem = (item: MenuItem) => {
  const { id, label, target, disabled, url, callback } = item;
  const { state } = useTabMenu();

  const sharedProps = {
    id: id,
    title: label,
    target: target,
    'aria-label': `${label} link`,
    'data-testid': TabMenuItemTestId.link(id),
    className: 'tab-menu-item-link',
  };

  const handleClick = useCallback(
    (e: SyntheticEvent, item: MenuItem) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      callback?.(item);
    },
    [callback, disabled]
  );

  if (state.isHire) {
    return (
      <a
        {...sharedProps}
        href={url}
        onClick={(e) => handleClick(e, item)}
        data-sub-menu={item.isSubMenuItem}
      >
        {label}
      </a>
    );
  }

  return (
    <NavLink
      {...sharedProps}
      to={url}
      onClick={(e) => handleClick(e, item)}
      data-sub-menu={item.isSubMenuItem}
    >
      {label}
    </NavLink>
  );
};
