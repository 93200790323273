import { SVGProps } from 'react';
const DawnPeopleTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05 3C6.267 3 4 5.24 4 8.017c-.01 2.768 2.242 5.01 5.018 5.02h.032c2.784 0 5.05-2.242 5.05-5.019S11.835 3 9.05 3ZM4 8.017v.001h.75L4 8.015v.002Zm1.5.001C5.5 6.08 7.084 4.5 9.05 4.5c1.967 0 3.55 1.58 3.55 3.518 0 1.938-1.583 3.518-3.55 3.518h-.028C7.064 11.528 5.493 9.95 5.5 8.021v-.003Zm3.7 6.457c-1.755 0-3.47.13-4.77.57-.654.222-1.257.54-1.704 1.007A2.58 2.58 0 0 0 2 17.882c0 .727.26 1.344.721 1.832.445.47 1.046.79 1.7 1.014 1.297.446 3.014.582 4.78.582 1.756 0 3.471-.131 4.77-.571.654-.222 1.258-.539 1.704-1.007a2.58 2.58 0 0 0 .726-1.83c0-.726-.26-1.343-.72-1.83-.445-.47-1.047-.791-1.7-1.015-1.298-.446-3.014-.582-4.78-.582Zm-5.7 3.408c0-.336.11-.584.311-.795.22-.23.576-.444 1.1-.622 1.056-.357 2.566-.491 4.29-.491 1.731 0 3.24.139 4.293.5.522.18.878.396 1.096.627.202.213.311.463.311.8 0 .336-.11.583-.311.794-.22.23-.576.444-1.1.622-1.056.357-2.566.492-4.29.492-1.73 0-3.24-.14-4.293-.5-.522-.18-.877-.396-1.096-.627a1.097 1.097 0 0 1-.311-.8ZM21.988 7.43a.75.75 0 0 1 .082 1.058l-4.286 5a.75.75 0 0 1-1.064.076l-1.714-1.5a.75.75 0 0 1 .988-1.129l1.144 1.001 3.793-4.424a.75.75 0 0 1 1.057-.082Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnPeopleTick;
