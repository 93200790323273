export const DISABLE_DEVICE_ENROLMENT_ID = 0;
export const OPTIONAL_DEVICE_ENROLMENT_ID = 1;
export const MANDATORY_DEVICE_ENROLMENT_ID = 2;

export const USER_PERSONALIZATION_OPTIONS = [
  {
    name: "saveGridTableSwap",
    value: "true",
    label: "Save Grid Table Swap",
  },
  {
    name: "saveLastPageVisited",
    value: "true",
    label: "Save Last Page Visited",
  },
  {
    name: "savePageSize",
    value: "true",
    label: "Save Page Size",
  },
  {
    name: "saveSearchHistory",
    value: "true",
    label: "Save Search History",
  },
  {
    name: "saveSortOrder",
    value: "true",
    label: "Save Sort Order",
  },
];

export const PRIMARY_PAYROLL_MANAGER_ID = 1;
export const SECONDARY_PAYROLL_MANAGER_ID = 2;
