import React from "react";
import { DurationTypeField, MaxAmountField, EndDateField } from "./";
import { If } from "../../ui";

type Props = {
  fields: any;
  question: object;
  onDurationTypeChange: (e: any) => void;
  handleChangeValue: (e: any) => void;
  getType?: boolean;
  getValue?: boolean;
};

const RecurringEspecialFields = ({
  fields,
  question,
  onDurationTypeChange,
  handleChangeValue,
  getValue,
  getType,
}: Props) => (
  <React.Fragment>
    {getType && <DurationTypeField required onChange={onDurationTypeChange} />}
    {getValue && (
      <If
        condition={fields.durationType === 1}
        elseCondition={fields.durationType === 2}
        then={
          <MaxAmountField
            required
            question={question}
            handleChange={handleChangeValue}
          />
        }
        else={
          <EndDateField
            endDate={fields.endDate}
            handleChangeValue={handleChangeValue}
          />
        }
      />
    )}
  </React.Fragment>
);

export default RecurringEspecialFields;
