import React from "react";
import SingleLineGrid from "../../../Common/ClaimComponents/TableGrid/SingleLineGrid";
import { listInterface } from "../../../constants/paramsTypes";

interface Props extends listInterface {
  handleAction: (options: any) => void;
  viewMore?: string;
  title: string;
  icon: React.ReactNode;
  DisplayComponent: React.ReactNode | React.FC<any>;
  handleCreate?: () => void;
  noDataDescription?: string;
}

const CompanyUserSingleLineGridView = ({
  list,
  loading,
  handleAction,
  viewMore,
  title,
  icon,
  DisplayComponent,
  handleCreate,
  noDataDescription,
}: Props) => (
  <SingleLineGrid
    ignoreStatus={true}
    list={list}
    loading={loading}
    handleAction={handleAction}
    title={
      <div className="flex items-start">
        {icon}
        <span className="font-bold text-base ml-2">{title}</span>
      </div>
    }
    viewMore={viewMore}
    DisplayComponent={DisplayComponent}
    handleCreate={handleCreate}
    noDataDescription={noDataDescription}
  />
);

export default CompanyUserSingleLineGridView;
