//@ts-nocheck
import React from "react";
import { getClaimAndStatus, isComponentVisible } from "../../libs/utilities";
import { Col } from "antd";
import { displayPinComponent, ignoreClaimValidation } from "../../libs";
import { PinTooltip } from "../ui";

class GeneralContainer extends React.Component {
  render() {
    const {
      hasNoComponents,
      claims,
      name,
      isPinned,
      md,
      sm,
      itemKey,
      lg,
      ignoreStatus,
      noGrids,
    } = this.props;
    const claimAndStatus = getClaimAndStatus({
      claims,
      componentName: name,
      hasComponents: !hasNoComponents,
    });
    const claim = claimAndStatus.claim;
    const childrenWithProps = this.props.children ? (
      React.Children.map(this.props.children, (child) =>
        React.cloneElement(child, { ...this.props, claims: claim })
      )
    ) : (
      <React.Fragment />
    );
    return noGrids ? (
      <div
        className={`${this.props.className} ${ignoreClaimValidation({
          ignoreStatus,
          claimAndStatus,
        })} general-container`}
      >
        {displayPinComponent({ claim }) && (
          <PinTooltip
            isPinned={isPinned}
            onClick={this.props.updatePinnedComponent}
          />
        )}
        {(isComponentVisible({ claim }) || ignoreStatus) && childrenWithProps}
      </div>
    ) : (
      <Col
        lg={lg}
        key={itemKey}
        md={md}
        sm={sm}
        className={`${this.props.className} ${ignoreClaimValidation({
          ignoreStatus,
          claimAndStatus,
        })} general-container`}
      >
        {displayPinComponent({ claim }) && (
          <PinTooltip
            isPinned={isPinned}
            onClick={this.props.updatePinnedComponent}
          />
        )}

        {(isComponentVisible({ claim }) || ignoreStatus) && childrenWithProps}
      </Col>
    );
  }
}

export default GeneralContainer;
