import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDrawer } from '@xemplo/drawer';
import { TabMenuProvider } from '@xemplo/tab-menu';

import * as S from '../payrun-earning-line-detail.styles';
import { PayrunEarningLineDetailHeader } from '../payrun-earning-line-detail-header';

import { NavMenu } from './details-variance-nav-menu';
import { PayrunEarningLineDetailBodyDetail } from './payrun-earning-line-detail-values';
import { PayrunEarningLineDetailBodyVariance } from './payrun-earning-line-details-variance';

export const EarningLineDetails = () => {
  const { setContent, toggle, setConfig } = useDrawer();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    setConfig({ onClose: () => navigate('..', { replace: false }), headerKeyline: true });
    setContent({
      header: (
        <>
          <PayrunEarningLineDetailHeader />
          <TabMenuProvider>
            <S.TabMenuWrapper>
              <NavMenu />
            </S.TabMenuWrapper>
          </TabMenuProvider>
        </>
      ),
    });
    return () => {
      toggle(false);
    };
  }, [navigate, setConfig, setContent, toggle]);

  useEffect(() => {
    if (pathname.includes('/variance')) {
      setContent({
        body: <PayrunEarningLineDetailBodyVariance />,
      });
    } else {
      setContent({
        body: <PayrunEarningLineDetailBodyDetail />,
      });
    }
    toggle(true);
  }, [pathname, setContent, toggle]);

  return null;
};
