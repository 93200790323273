import { SVGProps } from 'react';
const DawnCircleInformation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.001 12c0 5.501 4.498 9.999 9.999 9.999S21.999 17.5 21.999 12s-4.498-9.999-10-9.999C6.5 2.001 2.002 6.5 2.002 12ZM12 20.499C7.327 20.499 3.5 16.673 3.5 12S7.327 3.501 12 3.501 20.499 7.327 20.499 12s-3.826 8.499-8.5 8.499Zm-.004-3.749a.75.75 0 0 0 .75-.75v-4.887a.75.75 0 1 0-1.5 0V16c0 .414.336.75.75.75ZM11.99 9a1 1 0 1 1 0-2h.008a1 1 0 1 1 0 2h-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCircleInformation;
