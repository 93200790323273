export enum AmendmentStatusIds {
  CREATED = 1,
  SUBMITTED = 2,
  APPROVED = 3,
  REJECTED = 4,
  PREPARED = 5,
  UPDATED = 6,
  DUE = 7,
  RESUBMITTED = 8,
  ARCHIVED = 9,
  DRAFT = 10,
  COMPLETE = 11,
}

export type AmendmentStatusProps = {
  testId?: string;
  status: AmendmentStatusIds;
  statusName: string;
  className?: string;
};
