import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  ActionSheet,
  ActionSheetItemTypes,
  ActionSheetListItems,
} from '@xemplo/action-sheet';
import { Button, ButtonIconPosition, ButtonSize, ButtonType } from '@xemplo/button';
import { handleDownloadFile } from '@xemplo/common-util';
import { useHttpClient } from '@xemplo/http';
import { DawnArrowDown24 } from '@xemplo/icons';
import { useDownloadableFiles } from '@xemplo/payrun-query';

export interface PayrunDownloadReportsProps {
  secondary?: boolean;
  payrunStatusId?: number | null;
}

export function PayrunDownloadReports(props: Readonly<PayrunDownloadReportsProps>) {
  const { secondary = false, payrunStatusId = null } = props;
  const { client } = useHttpClient();
  const { id } = useParams();
  const files = useDownloadableFiles(id ?? null, payrunStatusId);

  const items: ActionSheetListItems = useMemo(() => {
    return files.map((item) => ({
      id: item.id,
      label: `${item.type}.${item.extension}`,
      type: ActionSheetItemTypes.Button,
      onClick: () => handleDownloadFile(item, client),
    }));
  }, [client, files]);

  if (items.length === 0) return null;
  return (
    <ActionSheet
      items={items}
      customButton={
        <Button
          type={secondary ? ButtonType.Secondary : ButtonType.Primary}
          ariaLabel="download-reports-button"
          size={ButtonSize.Small}
          iconPosition={ButtonIconPosition.Trailing}
          icon={<DawnArrowDown24 />}
        >
          Download
        </Button>
      }
    />
  );
}

export default PayrunDownloadReports;
