import { SVGProps } from 'react';
const DawnCashPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.42 6.536c-.544.49-.92 1.29-.92 2.582v5.764c0 1.292.376 2.093.92 2.583.559.502 1.414.785 2.58.785h8.5c.835 0 1.515-.146 2.037-.414a.75.75 0 1 1 .686 1.334c-.793.407-1.725.58-2.723.58H6c-1.384 0-2.654-.335-3.583-1.17C1.474 17.73 1 16.474 1 14.882V9.118c0-1.592.474-2.85 1.417-3.698.93-.835 2.199-1.17 3.583-1.17h8.5c1.384 0 2.654.335 3.583 1.17.943.849 1.417 2.106 1.417 3.698v2.388a.75.75 0 0 1-1.5 0V9.118c0-1.291-.376-2.093-.92-2.582-.559-.503-1.414-.786-2.58-.786H6c-1.166 0-2.021.283-2.58.786Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.875 12a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0Zm3.375-1.875a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Z"
      fill="currentColor"
    />
    <path
      d="M5.313 9.813a.75.75 0 0 0-1.5 0v4.374a.75.75 0 0 0 1.5 0V9.814ZM15.938 9.063a.75.75 0 0 1 .75.75v4.374a.75.75 0 0 1-1.5 0V9.814a.75.75 0 0 1 .75-.75ZM21.313 13.675a.75.75 0 0 0-1.5 0v1.745H18.25a.75.75 0 0 0 0 1.5h1.563v1.5a.75.75 0 0 0 1.5 0v-1.5h1.437a.75.75 0 0 0 0-1.5h-1.438v-1.745Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCashPlus;
