import { SVGProps } from 'react';
const DawnTrash16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.717 2.983-.043.254a62.135 62.135 0 0 1 2.654.024l-.046-.271v-.005a2.608 2.608 0 0 0-.061-.306.42.42 0 0 0-.016-.044c-.038-.017-.147-.05-.398-.05H7.192c-.246 0-.357.03-.4.047a2.788 2.788 0 0 0-.076.351h.001Zm-1.48-.249-.094.564c-.503.03-1.006.07-1.508.121l-1.257.123a.75.75 0 1 0 .146 1.493l.515-.05.39 7.174v.02c.017.274.035.582.084.87.05.297.14.628.336.931.436.676 1.193.935 2.172.935h3.957c.979 0 1.736-.26 2.172-.935.195-.303.286-.634.336-.931.05-.288.067-.596.083-.87l.001-.017v-.003l.39-7.173c.171.016.343.032.514.05a.75.75 0 1 0 .148-1.494 64.386 64.386 0 0 0-2.761-.214l-.1-.586-.006-.043c-.04-.245-.111-.7-.418-1.053-.37-.427-.919-.56-1.53-.56H7.192c-.6 0-1.15.121-1.524.545-.308.35-.382.804-.422 1.053l-.008.05Zm6.227 2.134a61.358 61.358 0 0 0-4.093-.137c-.946 0-1.891.037-2.837.113l.392 7.232c.018.296.032.525.066.723.033.194.076.303.118.368.038.06.169.248.91.248h3.958c.742 0 .873-.188.912-.248.041-.065.085-.174.117-.368.034-.198.048-.426.066-.723l.39-7.208ZM6.772 2.642l.006-.004a.021.021 0 0 1-.006.004Zm2.45.003-.007-.005c.006.003.008.005.007.005ZM6.511 5.746a.705.705 0 0 1 .772.687l.161 4.942a.798.798 0 0 1-.723.812.705.705 0 0 1-.772-.687L5.788 6.56a.798.798 0 0 1 .723-.813Zm3.7.813a.798.798 0 0 0-.723-.813.705.705 0 0 0-.772.687l-.16 4.942a.798.798 0 0 0 .722.812.705.705 0 0 0 .772-.687l.161-4.941Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnTrash16;
