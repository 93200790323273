import { ColumnDef } from '@tanstack/react-table';

import { PayrunDetail } from '@xemplo/gp-types';

import { PayrunWidgetCell } from './payrun-list-widget-cell';
export const columns: ColumnDef<PayrunDetail>[] = [
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => <PayrunWidgetCell {...row} />,
  },
];
