import { ButtonIconPosition, ButtonSize, ButtonType } from "@xemplo/button";
import { CreateAmendment } from "@xemplo/create-amendment";
import { DawnAddPlus24 } from "@xemplo/icons";
import { useNavigate } from "react-router-dom";

export function CreateAmedmentButton() {
  const navigate = useNavigate();
  return (
    <span className="flex items-center justify-end flex-grow mb-4 -mt-12">
      <CreateAmendment
        buttonChildren="New amendment"
        buttonProps={{
          icon: <DawnAddPlus24 />,
          iconPosition: ButtonIconPosition.Leading,
          ariaLabel: "Create new amendment",
          type: ButtonType.Primary,
          size: ButtonSize.Medium,
        }}
        onSuccess={() => {
          navigate(0);
        }}
      />
    </span>
  );
}
