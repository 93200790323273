//@ts-nocheck
import React, { Component } from "react";
import {
  mustBeArray,
  disableBrowserAutocomplete,
  getTimeZoneFilterName,
  reOrderTimeZoneList,
  getConditionalResponse,
  getAbnAndRegistrationText,
} from "../../../libs/utilities";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Switch, Row, Col, Select, Spin } from "antd";
import LocationSearchInput from "../../../Common/LocationSearchInput";
import _ from "lodash";
import { GOOGLE_MAP_URL } from "../../../constants/entities";
import { onPasteValidateNumber, abnValidator } from "../../../libs";
import { isCountryAustralia } from "../../../libs/validations";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { RequiredSign } from "../../../Common/ui";

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class BasicDetailBusinessUnitForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      mapLoaded: false,
    };
  }
  componentDidMount() {
    this.loadGoogleMaps(() => {
      this.setState({ mapLoaded: true });
    });
  }
  componentWillUnmount() {
    this.unloadGoogleMaps();
  }
  loadGoogleMaps = (callBack) => {
    const existingScript = document.getElementById("googlePlacesScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAP_URL;
      script.id = "googlePlacesScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callBack) callBack();
      };
    }
    if (existingScript && callBack) callBack();
  };
  unloadGoogleMaps = () => {
    let googlePlacesScript = document.getElementById("googlePlacesScript");
    if (googlePlacesScript) {
      googlePlacesScript.remove();
    }
  };

  render() {
    const {
      form,
      companyList,
      disableField,
      fetchingCompany,
      activeIndex,
      disableCompany,
      handleChangeCompany,
      useCompanyData,
      isSameCompany,
      updateReferenceCode,
      populateStates,
      onChangeCountry,
      timezone,
      onSameAddressChange,
      isSameAddress,
      onAddressChange,
      onAddressSelect,
      populateSameAddressField,
      states,
      editMode,
      countries,
      registeredAddress1,
      postalAddress1,
    } = this.props;
    const { getFieldDecorator } = form;
    const { mapLoaded } = this.state;
    return (
      <div className="form-label-left">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Company">
              {getFieldDecorator("company", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition: Number(activeIndex) === 1,
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select company name",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select company"
                  notFoundContent={getConditionalResponse({
                    condition: fetchingCompany,
                    resp1: <Spin size="small" />,
                    resp2: null,
                  })}
                  disabled={getConditionalResponse({
                    condition: editMode,
                    resp1: editMode,
                    resp2: disableCompany,
                  })}
                  optionFilterProp="name"
                  onSelect={handleChangeCompany}
                  className="w-full "
                >
                  {mustBeArray(companyList).map((d) => (
                    <Option
                      key={d.id}
                      value={d.id}
                      name={`${getConditionalResponse({
                        condition: d.name,
                        resp1: d.name,
                        resp2: "",
                      })} ${getConditionalResponse({
                        condition: d.value,
                        resp1: d.value,
                        resp2: "",
                      })}`}
                    >
                      {d.name || d.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          {form.getFieldValue("company") ? (
            <Col span={12} className="no-shadow">
              <FormItem label="Business Unit same as company">
                {getFieldDecorator("sameBusinessUnitCompany", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Switch
                    disabled={editMode}
                    checked={isSameCompany}
                    onChange={(val) => useCompanyData(val)}
                  />
                )}
              </FormItem>
            </Col>
          ) : null}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Country">
              {getFieldDecorator("country", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition: Number(activeIndex) === 1,
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select your country.",
                  },
                ],
              })(
                <Select
                  placeholder="Please select a country"
                  showSearch
                  optionFilterProp="name"
                  onFocus={() => disableBrowserAutocomplete()}
                  onSelect={(val) => populateStates(val, false)}
                  disabled={disableField}
                  autoComplete="none"
                  onChange={(value) => onChangeCountry(value)}
                >
                  {mustBeArray(countries).map((country, key) => (
                    <Option key={key} value={country.id} name={country.value}>
                      {country.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={getAbnAndRegistrationText({
                countryId: Number(this.props.form.getFieldValue("country")),
              })}
            >
              {getFieldDecorator("abn", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        isCountryAustralia({
                          country: this.props.form.getFieldValue("country"),
                        }) && Number(activeIndex) === 1,
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please input ABN",
                  },
                ],
              })(
                <Input
                  onKeyDown={(e) => abnValidator(e, 10)}
                  placeholder={`Please input ${getAbnAndRegistrationText({
                    countryId: Number(this.props.form.getFieldValue("country")),
                  })}`}
                  disabled={disableField}
                  className="w-full  hideNumberPointers"
                  onPaste={(e) => onPasteValidateNumber(e)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Registered Business Name">
              {getFieldDecorator("registeredName", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition: Number(activeIndex) === 1,
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Registered Business Name",
                  },
                ],
              })(
                <Input
                  placeholder="Please input registered business name"
                  maxLength={60}
                  disabled={disableField}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition: Number(activeIndex) === 1,
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please input name",
                  },
                ],
              })(
                <Input
                  placeholder={`Please input business unit name`}
                  maxLength={40}
                  disabled={disableField}
                  onChange={(e) => updateReferenceCode(e.currentTarget.value)}
                  autoComplete="none"
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Operating Timezone">
              {getFieldDecorator("operatingTimezone", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition: Number(activeIndex) === 1,
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select your timezone!",
                  },
                ],
              })(
                <Select
                  placeholder="Please select a timezone"
                  showSearch
                  defaultActiveFirstOption={true}
                  disabled={disableField}
                  optionFilterProp="name"
                >
                  {reOrderTimeZoneList({
                    timezone,
                    countries,
                    selectedCountry: form.getFieldValue("country"),
                  }).map((time, key) => {
                    return (
                      time.enabled && (
                        <Option
                          value={time.id}
                          name={getTimeZoneFilterName(time)}
                          key={key}
                        >
                          {time.value}
                        </Option>
                      )
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row justify="end" type="flex" className="-mb-6 mt-3" gutter={16}>
          <Col span={12}>
            <strong>Physical Address</strong>
          </Col>
          <Col span={6}>
            <strong>Postal Address</strong>
          </Col>
          <Col span={6}>
            <FormItem
              labelCol={{ span: 19 }}
              wrapperCol={{ span: 5 }}
              label="Same as Physical Address"
            >
              {getFieldDecorator("isSameAddress", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Switch
                  onChange={(val) => onSameAddressChange(val)}
                  checked={isSameAddress}
                  disabled={disableField}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label={
                <span>
                  <RequiredSign />
                  &nbsp;Address 1
                </span>
              }
            >
              {getFieldDecorator("registeredAddress1", {
                initialValue: {
                  placeholder: "Address 1",
                  className: "registered-address-input",
                },
              })(
                <LocationSearchInput
                  disabled={getConditionalResponse({
                    condition: editMode,
                    resp1: false,
                    resp2: disableField,
                  })}
                  onChange={(res) => onAddressSelect(res, "registered")}
                  mapLoaded={mapLoaded}
                  onAddressChange={(value) =>
                    onAddressChange(value, "registered")
                  }
                  address={registeredAddress1}
                  editMode={editMode}
                  type="registered"
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label={
                <span>
                  <RequiredSign />
                  &nbsp;Address 1
                </span>
              }
            >
              {getFieldDecorator("postalAddress1", {
                initialValue: {
                  placeholder: "Address 1",
                  className: "postal-address-input",
                },
              })(
                <LocationSearchInput
                  disabled={getConditionalResponse({
                    condition: isSameAddress,
                    resp1: isSameAddress,
                    resp2: disableField,
                  })}
                  onChange={(res) => onAddressSelect(res, "postal")}
                  mapLoaded={mapLoaded}
                  onAddressChange={(value) => onAddressChange(value, "postal")}
                  address={postalAddress1}
                  editMode={editMode}
                  type="postal"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Address 2">
              {getFieldDecorator("registeredAddress2", {
                rules: [
                  {
                    required: false,
                    message: "Address 2",
                  },
                ],
                defaultValue: " ",
              })(
                <Input
                  placeholder="Physical Address 2"
                  maxLength={40}
                  disabled={disableField}
                  onChange={(e) =>
                    populateSameAddressField(
                      e.currentTarget.value,
                      "postalAddress2"
                    )
                  }
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Address 2">
              {getFieldDecorator("postalAddress2", {
                rules: [
                  {
                    required: false,
                    message: "Address 2",
                  },
                ],
                defaultValue: " ",
              })(
                <Input
                  disabled={getConditionalResponse({
                    condition: isSameAddress,
                    resp1: isSameAddress,
                    resp2: disableField,
                  })}
                  placeholder="Postal Address 2"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={15}>
                <FormItem label="City / Town">
                  {getFieldDecorator("registeredSuburb", {
                    rules: [
                      {
                        required: getConditionalResponse({
                          condition: Number(activeIndex) === 1,
                          resp1: true,
                          resp2: false,
                        }),
                        message: "Please input city / town",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Please input city / town"
                      maxLength={30}
                      disabled={disableField}
                      onChange={(e) =>
                        populateSameAddressField(
                          e.currentTarget.value,
                          "postalSuburb"
                        )
                      }
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={9}>
                <FormItem label="Postcode">
                  {getFieldDecorator("registeredPostCode", {
                    rules: [
                      {
                        required: getConditionalResponse({
                          condition: Number(activeIndex) === 1,
                          resp1: true,
                          resp2: false,
                        }),
                        message: "Please input postcode",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Please input postcode"
                      className="w-full "
                      disabled={disableField}
                      onChange={(e) =>
                        populateSameAddressField(
                          e.currentTarget.value,
                          "postalPostCode"
                        )
                      }
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={15}>
                <FormItem label="City / Town">
                  {getFieldDecorator("postalSuburb", {
                    rules: [
                      {
                        required: getConditionalResponse({
                          condition: Number(activeIndex) === 1,
                          resp1: true,
                          resp2: false,
                        }),
                        message: "Please input city / town",
                      },
                    ],
                  })(
                    <Input
                      disabled={getConditionalResponse({
                        condition: isSameAddress,
                        resp1: isSameAddress,
                        resp2: disableField,
                      })}
                      placeholder="Please input city / town"
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={9}>
                <FormItem label="Postcode">
                  {getFieldDecorator("postalPostCode", {
                    rules: [
                      {
                        required: getConditionalResponse({
                          condition: Number(activeIndex) === 1,
                          resp1: true,
                          resp2: false,
                        }),
                        message: "Please input postcode",
                      },
                    ],
                  })(
                    <Input
                      disabled={getConditionalResponse({
                        condition: isSameAddress,
                        resp1: isSameAddress,
                        resp2: disableField,
                      })}
                      placeholder="Please input postcode"
                      className="w-full "
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Province / State">
              {getFieldDecorator("registeredState", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        Number(activeIndex) === 1 &&
                        !_.isEmpty(mustBeArray(states)),
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select your Province / State!",
                  },
                ],
              })(
                <Select
                  autoComplete="none"
                  showSearch={true}
                  optionFilterProp="name"
                  placeholder="Please select province / state"
                  disabled={disableField}
                  onChange={(e) => populateSameAddressField(e, "postalState")}
                >
                  {mustBeArray(states).map((state, index) => (
                    <Option key={index} value={state.id} name={state.value}>
                      {state.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Province / State">
              {getFieldDecorator("postalState", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        Number(activeIndex) === 1 &&
                        !_.isEmpty(mustBeArray(states)),
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select your Province / State!",
                  },
                ],
              })(
                <Select
                  autoComplete="none"
                  disabled={getConditionalResponse({
                    condition: isSameAddress,
                    resp1: isSameAddress,
                    resp2: disableField,
                  })}
                  placeholder="Please select Province / State"
                  showSearch={true}
                  optionFilterProp="name"
                >
                  {mustBeArray(states).map((state, index) => (
                    <Option key={index} value={state.id} name={state.value}>
                      {state.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BasicDetailBusinessUnitForm;
