import React from "react";

const HeaderDataView = (props: any) => {
  const { title, value } = props;
  return value && title ? (
    <div className="pl-8">
      <div className="text-xs">{title}</div>
      <span className="bold text-lg">{value}</span>
    </div>
  ) : (
    <React.Fragment />
  );
};

/** @alias */
export default HeaderDataView;
