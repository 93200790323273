// @ts-nocheck
import React, { Component } from "react";
import {
  AuditOutlined,
  BankOutlined,
  DollarOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

import { Modal, Button, Spin, Row, Col, Tooltip, Card, List } from "antd";
import _ from "lodash";
import {
  mustBeArray,
  currencyFormater,
  mapNameFromArray,
} from "../../../libs/utilities";
import { Chart } from "react-google-charts";
import { colors } from "../../../constants/colors";
import { PercentIcon } from "../../../Common/customicons";
import GridAndTableView from "../common/gridAndTableView";
import { END_EMPLOYEE_NUMBER } from "../../../constants/payrun";
import { GOOGLE_MAP_URL } from "../../../constants/entities";
import environment from "../../../constants/environment";

type State = any;

class ProcessingFeesView extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      options: {
        region: "AU",
        resolution: "provinces",
        displayMode: "markers",
        title: "test",
        colorAxis: { colors: [colors.success, colors.success] },
        sizeAxis: { minSize: 4, maxSize: 4 },
        tooltip: { trigger: "focus", isHtml: true },
        defaultColor: "#f5f5f5",
        magnifyingGlass: { enable: true, zoomFactor: 3 },
        legend: "none",
      },
      view: "grid",
      mapLoaded: false,
    };
  }
  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (this.props.visible !== newProps.visible) {
      this.setState({
        options: { ...this.state.options, region: newProps.countryCode },
      });
      this.loadGoogleMaps(() => {
        this.setState({ mapLoaded: true });
      });
    }
  }
  componentWillUnmount() {
    this.unloadGoogleMaps();
  }
  loadGoogleMaps = (callBack: any) => {
    const existingScript = document.getElementById("googleMapScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAP_URL;
      script.id = "googleMapScript";
      document.body.appendChild(script);
      //action to do after a script is loaded in our case setState
      script.onload = () => {
        if (callBack) callBack();
      };
    }
    if (existingScript && callBack) callBack();
  };
  unloadGoogleMaps = () => {
    let googleMapScript = document.getElementById("googleMapScript");
    if (googleMapScript) {
      googleMapScript.remove();
    }
  };
  payCategoryDefaultView = () => {
    const { feeDetails } = this.props;
    if (_.isEmpty(mustBeArray(feeDetails && feeDetails.amendments)))
      return "N/A";
    let displayCategories = _.take(
      mustBeArray(feeDetails && feeDetails.amendments),
      5
    ).reduce((a, b) => {
      return `${a}${a.length === 0 ? "" : ", "}${b.name}`;
    }, "");
    displayCategories =
      mustBeArray(feeDetails && feeDetails.amendments).length <= 5
        ? displayCategories
        : `${displayCategories} ...`;
    return displayCategories;
  };
  getStateData = (stateRate: any) => {
    let stateData = [["City", "value", { role: "tooltip", p: { html: true } }]];
    let newStateRate = _.filter(stateRate, (o) => Number(o.rate) !== 0);
    return mustBeArray(newStateRate).reduce((a: any, b: any) => {
      return [
        ...a,
        [
          b.stateName,
          b.rate,
          Number(this.props.feeDetails.feeOptionId) === 1
            ? `<div className="w-min-60 word-none">${b.rate}%</div>`
            : `<div className="w-min-60 word-none" >
                    ${currencyFormater(
                      b.rate,
                      this.props.businessunitDetails &&
                        this.props.businessunitDetails.country
                    )}</div>`,
        ],
      ];
    }, stateData);
  };
  getEmployeeRangeData = (employeeRangeFactor: any) => {
    let employeeRange = [
      [
        "Employee Range",
        Number(this.props.feeDetails.feeOptionId) === 1 ? `Percentage` : "Rate",
        { type: "string", role: "annotation" },
      ],
    ];
    return mustBeArray(employeeRangeFactor).reduce((a: any, b: any) => {
      return [
        ...a,
        [
          Number(b.to) === END_EMPLOYEE_NUMBER
            ? `Greater than ${b.from - 1}`
            : `${b.from}-${b.to}`,
          b.rate,
          `${
            Number(this.props.feeDetails.feeOptionId) === 1
              ? `+${b.rate}%`
              : `+${currencyFormater(
                  b.rate,
                  this.props.businessunitDetails &&
                    this.props.businessunitDetails.country
                )}`
          }`,
        ],
      ];
    }, employeeRange);
  };
  updateView = (view: any) => {
    this.setState({ view });
  };
  render() {
    const {
      visible,
      onCancel,
      feeDetails,
      businessunitDetails,
      viewPayrun,
      basePayMapping,
    } = this.props;
    if (!_.isEmpty(feeDetails)) {
      feeDetails.earningLines = viewPayrun
        ? _.filter(
            mustBeArray(feeDetails.processingFeePayItem),
            (o) => o.checked
          )
        : feeDetails.payItemDto
        ? _.filter(mustBeArray(feeDetails.payItemDto), (o) => o.checked)
        : feeDetails.earningLines
        ? _.filter(mustBeArray(feeDetails.earningLines), (o) => o.checked)
        : [];

      feeDetails.amendments = viewPayrun
        ? _.filter(
            mustBeArray(feeDetails.processingFeeExternalPayCategory),
            (o) => o.checked
          )
        : feeDetails.externalPayCategoryDto
        ? _.filter(
            mustBeArray(feeDetails.externalPayCategoryDto),
            (o) => o.checked
          )
        : feeDetails.amendments
        ? _.filter(mustBeArray(feeDetails.amendments), (o) => o.checked)
        : [];

      feeDetails.employeeNumberRangeFactor = viewPayrun
        ? feeDetails.processingFeeEmployeeNumberRange
        : feeDetails.employeeNumberRangeDto
        ? feeDetails.employeeNumberRangeDto
        : feeDetails.employeeNumberRangeFactor;

      feeDetails.stateRate = viewPayrun
        ? feeDetails.processingFeeStateRate
        : feeDetails.stateRateDto
        ? feeDetails.stateRateDto
        : feeDetails.stateRate;

      feeDetails.expenses = viewPayrun
        ? _.filter(
            mustBeArray(feeDetails.processingFeeExternalExpenseCategory),
            (o) => o.checked
          )
        : feeDetails.externalExpenseCategoryDto
        ? _.filter(
            mustBeArray(feeDetails.externalExpenseCategoryDto),
            (o) => o.checked
          )
        : feeDetails.expenses
        ? _.filter(mustBeArray(feeDetails.expenses), (o) => o.checked)
        : [];
    }
    const stateData = this.getStateData(feeDetails.stateRate);
    const employeeRangeData = _.isEmpty(
      mustBeArray(feeDetails.employeeNumberRangeFactor)
    )
      ? []
      : this.getEmployeeRangeData(feeDetails.employeeNumberRangeFactor);
    const selectedBasePay = mapNameFromArray(basePayMapping, "name");

    return (
      <Modal
        title="Processing Fees Details"
        visible={visible}
        closable={true}
        onCancel={() => onCancel()}
        width={1200}
        footer={[
          <div key={"1"}>
            <Button onClick={() => onCancel()} className="rounded-[20px]">
              Close
            </Button>
          </div>,
        ]}
      >
        {!_.isEmpty(feeDetails) ? (
          <React.Fragment>
            <Row className="bold text-base items-center">
              <Tooltip title="Invoice Description">
                <ProfileOutlined className="mr-1 text-lg" />
              </Tooltip>{" "}
              {feeDetails.invoiceDescription}
            </Row>
            <Card className="my-3.5">
              <Row className="bold text-base items-center" gutter={8}>
                <Col
                  span={_.isEmpty(mustBeArray(feeDetails.stateRate)) ? 6 : 8}
                >
                  <Tooltip title="Fee Option Type">
                    <BankOutlined className="mr-1 text-lg" />
                  </Tooltip>
                  {feeDetails.feeOption}
                </Col>
                {feeDetails.basePay ? (
                  <Col
                    span={_.isEmpty(mustBeArray(feeDetails.stateRate)) ? 6 : 8}
                  >
                    <Tooltip title={selectedBasePay} placement="bottomLeft">
                      <AuditOutlined className="text-lg mr-1" />
                      {"Base Pay"}
                    </Tooltip>
                  </Col>
                ) : (
                  <React.Fragment />
                )}

                <Col
                  span={_.isEmpty(mustBeArray(feeDetails.stateRate)) ? 6 : 8}
                >
                  <Tooltip title="Tax">
                    <ReconciliationOutlined className="text-lg mr-1" />
                  </Tooltip>
                  {feeDetails.taxFeeName ? feeDetails.taxFeeName : "None"}
                </Col>
                {_.isEmpty(mustBeArray(feeDetails.stateRate)) && (
                  <Col span={6}>
                    <Tooltip
                      title={
                        Number(feeDetails.feeOptionId) === 1
                          ? "Fee Percentage"
                          : "Fee Rate"
                      }
                    >
                      {Number(feeDetails.feeOptionId) === 1 ? (
                        <PercentIcon className="mr-1" />
                      ) : (
                        <DollarOutlined className="text-lg mr-1" />
                      )}
                    </Tooltip>
                    {Number(feeDetails.feeOptionId) === 1
                      ? `${feeDetails.rateOrPercentage}%`
                      : currencyFormater(
                          feeDetails.rateOrPercentage,
                          businessunitDetails && businessunitDetails.country
                        )}
                  </Col>
                )}
              </Row>
            </Card>
            <Row className="mb-3.5" gutter={8}>
              {!_.isEmpty(feeDetails.earningLines) ? (
                <Col span={8}>
                  <List
                    header={
                      <div className="bold text-base">Earningline(s)</div>
                    }
                    bordered
                    dataSource={mustBeArray(feeDetails.earningLines)}
                    renderItem={(item) => <List.Item>{item.name}</List.Item>}
                    className="feeViewList"
                  />
                </Col>
              ) : (
                <React.Fragment />
              )}

              {!_.isEmpty(feeDetails.amendments) ? (
                <Col span={8}>
                  <List
                    header={
                      <div className="bold text-base">Pay Category(ies)</div>
                    }
                    bordered
                    dataSource={mustBeArray(feeDetails.amendments)}
                    renderItem={(item) => <List.Item>{item.name}</List.Item>}
                    className="feeViewList"
                  />
                </Col>
              ) : (
                <React.Fragment />
              )}
              {!_.isEmpty(mustBeArray(feeDetails.expenses)) ? (
                <Col span={8}>
                  <List
                    header={
                      <div className="bold text-base">
                        Expense Category(ies)
                      </div>
                    }
                    bordered
                    dataSource={mustBeArray(feeDetails.expenses)}
                    renderItem={(item) => <List.Item>{item.name}</List.Item>}
                    className="feeViewList"
                  />
                </Col>
              ) : (
                <React.Fragment />
              )}
            </Row>

            <Row gutter={8} className="mb-3.5">
              {feeDetails &&
              !_.isEmpty(mustBeArray(feeDetails.employeeNumberRangeFactor)) ? (
                <Col span={10}>
                  <Card className="h-330 overflow-y-auto">
                    <div>
                      <Chart
                        width={"350px"}
                        height={"270px"}
                        chartType="BarChart"
                        loader={<Spin />}
                        data={mustBeArray(employeeRangeData)}
                        options={{
                          title: `Employee Range ${
                            Number(feeDetails.feeOptionId) === 1
                              ? "Percentage"
                              : "Rate"
                          }`,
                          chartArea: { width: "40%" },
                          hAxis: {
                            title:
                              Number(feeDetails.feeOptionId) === 1
                                ? `Percentage`
                                : "Rate",
                            minValue: 0,
                          },
                          vAxis: {
                            title: "Employee Range",
                          },
                        }}
                        rootProps={{ "data-testid": "4" }}
                      />
                    </div>
                  </Card>
                </Col>
              ) : (
                <React.Fragment />
              )}
              {feeDetails && !_.isEmpty(mustBeArray(feeDetails.stateRate)) ? (
                <Col span={14}>
                  {this.state.view === "list" ? (
                    <React.Fragment>
                      <List
                        header={
                          <div className="bold text-base">State Rate(s)</div>
                        }
                        bordered
                        dataSource={mustBeArray(feeDetails.stateRate)}
                        renderItem={(item) =>
                          Number(item.rate) !== 0 ? (
                            <List.Item className="spaceBetweenListItems">
                              <span>{item.stateName}</span>
                              <div>
                                {Number(feeDetails.feeOptionId) === 1
                                  ? `${item.rate}%`
                                  : currencyFormater(
                                      item.rate,
                                      this.props.businessunitDetails &&
                                        this.props.businessunitDetails.country
                                    )}
                              </div>
                            </List.Item>
                          ) : (
                            <React.Fragment />
                          )
                        }
                        className="feeViewList"
                      />
                    </React.Fragment>
                  ) : (
                    <Card
                      className={`overflow-hidden h-330 min-h-[330px]
                                                    ${
                                                      this.props.countryCode ===
                                                      "NZ"
                                                        ? "scale21 w-full"
                                                        : this.props
                                                            .countryCode ===
                                                          "AU"
                                                        ? "scale4 w-full"
                                                        : this.props
                                                            .countryCode ===
                                                          "GB"
                                                        ? "scale23 w-full"
                                                        : "scale1 w-full"
                                                    }`}
                    >
                      {this.state.mapLoaded ? (
                        <Chart
                          width={"100%"}
                          height={
                            this.props.countryCode === "GB" ? "200px" : "300px"
                          }
                          chartType="GeoChart"
                          data={stateData}
                          options={this.state.options}
                          mapsApiKey={environment.google_api_key}
                          rootProps={{ "data-testid": "1" }}
                          graphID="countrySvgChart"
                        />
                      ) : (
                        <Spin />
                      )}
                    </Card>
                  )}
                  <div className="hand position-absolute position-absolute-top-10 position-absolute-right-12">
                    <GridAndTableView
                      view={this.state.view}
                      updateView={this.updateView}
                      iconClassName="text-base mr-1.5"
                    />
                  </div>
                </Col>
              ) : (
                <React.Fragment />
              )}
            </Row>
          </React.Fragment>
        ) : (
          <Spin />
        )}
      </Modal>
    );
  }
}

export default ProcessingFeesView;
