import { ColumnDef } from '@tanstack/react-table';

import { Amendment } from '@xemplo/gp-types';

import { AmendmentListWidgetCell } from './amendment-list-widget-cell';
export const columns: ColumnDef<Amendment>[] = [
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => <AmendmentListWidgetCell {...row} />,
  },
];
