//@ts-nocheck
import React from "react";
import moment from "moment";
import numeral from "numeral";
import {
  DEFAULT_STRINGIFIED_DATE_TIME_FORMAT_IN_FORM,
  DISPLAY_DATE_FORMAT,
} from "../constants/formats";
import { Weekends } from "../constants/entities";
import {
  mustBeArray,
  currencyFormater,
  isNullEmptyUndefined,
} from "./utilities";
import _ from "lodash";

export const formatTime = (date: any, fallbackTime: any) => {
  return date ? moment(date).local().format("HH:mm") : fallbackTime;
};

export const getDisabledDates = ({ selectionDate, validations }: any) => {
  const maxDay = validations?.maxDay;
  const minDay = validations?.minDay;
  let allowedMinDay;
  let allowedMaxDay;
  if (!minDay && !maxDay) return false;
  if (minDay || minDay === 0) {
    allowedMinDay = moment().add(minDay, "days");
  }
  if (maxDay) {
    allowedMaxDay = moment().add(maxDay, "days");
  }
  if (allowedMinDay && moment(selectionDate).isBefore(allowedMinDay))
    return true;
  if (allowedMaxDay && moment(selectionDate).isAfter(allowedMaxDay))
    return true;
  return false;
};

export const validateInputValueIncludesOnlyNumbers = ({
  e,
  maxAmount,
  hasDecimal,
  isPositive,
  minAmount,
}: any) => {
  const testCase = hasDecimal
    ? isPositive
      ? /^([a-zA-Z(),{}\[\]!@#\\\|`~_$%^&*<>/?+\:\;\'\"=\s-])$/
      : /^([a-zA-Z(),{}\[\]!@#\\\|`~_$%^&*<>/?+\:\;\'\"=\s])$/
    : isPositive
    ? /^([a-zA-Z(),{}\[\]!.@#\\\|`~_$%^&*<>/?+\:\;\'\"=\s-])$/
    : /^([a-zA-Z(),{}\[\]!.@#\\\|`~_$%^&*<>/?+\:\;\'\"\s=])$/;
  if (testCase.test(e.key)) {
    return e.preventDefault();
  }

  if (!isNaN(Number(e && e.key)) && maxAmount) {
    const newValue = numeral(`${e.currentTarget.value}${e.key}`)._value;
    if (newValue > Number(maxAmount)) return e.preventDefault();
  }
  if (!isNaN(Number(e && e.key)) && minAmount) {
    const newValue = numeral(`${e.currentTarget.value}${e.key}`)._value;
    if (newValue < Number(minAmount)) return e.preventDefault();
  }
};

export const populateDateFields = (date) => {
  return date ? moment(date, "MM/DD/YYYY") : "";
};

export const populateDateAndTimeFields = (data, format) => {
  return data ? moment(data, "MM/DD/YYYY HH:mm:ss A") : "";
};

export const formatDisplayTime = (data) => {
  return moment(data, "HH:mm").format("h:mm a");
};

export const onlyDateFormater = (date) => {
  return moment(date).format(DISPLAY_DATE_FORMAT);
};

export const dateFormaterPayload = (date, fallbackDate) => {
  return date ? moment(date).format("YYYY-MM-DD") : fallbackDate;
};

export const monthFormater = (month) => {
  if (month.length <= 1) {
    return moment(`0${month}`, "MM").format("MMMM");
  } else return moment(month, "MM").format("MMMM");
};

export const disablePassedHolidaysNextYearDate = ({
  selectedDate,
  holidays = {},
}) => {
  if (!selectedDate) return false;
  const newHolidayList = mustBeArray(holidays && holidays.holidays).reduce(
    (a, b) => {
      return a.concat(b.date);
    },
    []
  );
  if (
    holidays &&
    !holidays.includeHoliday &&
    newHolidayList.includes(moment(selectedDate).format("YYYY-MM-DD"))
  )
    return true;

  // weekends
  if (
    holidays &&
    !holidays.includeWeekend &&
    Weekends.includes(selectedDate.format("dddd"))
  )
    return true;

  // passed date validation
  if (moment(selectedDate).isBefore(moment().endOf("day"))) return true;

  // next year date validation
  const nextYearsDate = moment().add(1, "year");
  if (moment(selectedDate).isAfter(nextYearsDate)) return true;
  return false;
};

export const hasDueDatePassed = ({ date = null }) => {
  if (
    date &&
    moment.utc(date, "MM/DD/YYYY hh:mm:ss A").local().isBefore(moment())
  )
    return true;
  return false;
};

// Verify if date is past date
export const isPastDate = ({ date = null }) => {
  return !!(
    date && moment(date, "MM/DD/YYYY hh:mm:ss A").local().isBefore(moment())
  );
};

export const renderReoccurringEndDate = (data) => {
  if (data?.recurringExpiryDate) {
    return (
      <React.Fragment>
        {displayDates({ value: data.recurringExpiryDate })}
      </React.Fragment>
    );
  }
  if (isNullEmptyUndefined(data?.recurringMaximumAmount)) {
    return <React.Fragment>N/A</React.Fragment>;
  }
  return (
    <React.Fragment>
      {currencyFormater(data?.recurringMaximumAmount)}
    </React.Fragment>
  );
};

export const getDifferenceInDays = ({ value, format }) => {
  return moment(value, format).diff(moment(), "days") + 1;
};

export const displayDates = ({
  value,
  format,
}: {
  value: any;
  format?: string;
}) => {
  if (!value) return "";
  return format
    ? moment(value, format).format(DISPLAY_DATE_FORMAT)
    : moment(value).format(DISPLAY_DATE_FORMAT);
};

export const displayDateAndTime = ({ value, format }) => {
  if (!value) return "";
  if (format) {
    return moment(value, format).format(
      DEFAULT_STRINGIFIED_DATE_TIME_FORMAT_IN_FORM
    );
  }
  return moment(value).format(DEFAULT_STRINGIFIED_DATE_TIME_FORMAT_IN_FORM);
};

export const getIsoDateFormat = ({ date, format = null }: any) => {
  if (!date) return "";
  return format ? moment(date, format).format() : moment(date).format();
};

export const formatDateRange = ({ value }) => {
  const isDateRange = _.includes(value, "-");
  if (isDateRange) {
    const dateRanges = value.split("-");
    return `${displayDates({
      value: dateRanges[0],
      format: "MM/DD/YYYY",
    })} - ${displayDates({
      value: dateRanges[1],
      format: "MM/DD/YYYY",
    })}`;
  } else {
    return value;
  }
};

export const subtractDays = (currentDate, days) => {
  return currentDate
    ? moment(currentDate).subtract(days, "days").format("YYYY/MM/DD")
    : null;
};

export const getFormattedDate = ({
  date,
  currentFormat = undefined,
  format,
}: {
  date?: string;
  currentFormat?: string;
  format: string;
}) => {
  if (isNullEmptyUndefined(date)) return "";
  return currentFormat
    ? moment(date, currentFormat).format(format)
    : moment(date).format(format);
};

// Convert string dates to moment
export const convertDatestoMoment = ({
  date,
  format = DISPLAY_DATE_FORMAT,
}: {
  date?: string | null;
  format?: string;
}) => {
  return !date ? null : moment(date, format);
};

// Convert date to utc and format
export const convertDateToUtc = ({
  date,
  currentFormat,
  outputFormat,
}: {
  date: string;
  currentFormat?: string;
  outputFormat?: string;
}) => {
  if (!date) return "";
  if (currentFormat) {
    return outputFormat
      ? moment(date, currentFormat).utc().format(outputFormat)
      : moment(date, currentFormat).utc().format("DD/MM/YYYY");
  }
  return outputFormat
    ? moment(date).utc().format(outputFormat)
    : moment(date).utc().format("DD/MM/YYYY");
};
