import styled from 'styled-components';

import { Avatar, AvatarSize } from '@xemplo/avatar';
import { Colour, TextEllipsis } from '@xemplo/style-constants';

import { CellContent } from './cell.style';
import { NameCellProps } from './cell.types';

const StyledNameWrapper = styled.span`
  display: inline-block;
  ${TextEllipsis};
  color: ${Colour.Gray[600]};
`;

export const NameCellTestId = {
  name: 'name-cell-wrapper',
};

export const NameCell = (props: NameCellProps) => {
  const { avatarColor, firstName, lastName } = props;

  // FD-1504:
  // some endpoints only return a full name, so use the first character after splitting it
  // to render the initials
  const remapUserName = (user: NameCellProps) => ({
    ...user,
    firstName,
    lastName: lastName ?? firstName?.split(' ')[1] ?? '',
  });

  return (
    <CellContent className={props.className}>
      <Avatar user={remapUserName(props)} size={AvatarSize.s} color={avatarColor} />
      <StyledNameWrapper data-testid={NameCellTestId.name}>
        {props.firstName} {props.lastName}
      </StyledNameWrapper>
    </CellContent>
  );
};
