import { RefObject, useCallback } from 'react';
import { ListState } from '@react-stately/list';
import { OverlayTriggerState } from '@react-stately/overlays';

import { ListItem } from '@xemplo/listbox';

import { TextDropdownMultiProps } from '../v1/text-dropdown-multi.types';
import { TextDropdownMultiV2Props } from '../v2/text-dropdown-multi-v2.types';

type UseSelectPropsProps<T extends ListItem> = {
  selectRef: RefObject<HTMLSelectElement>;
  listRef: RefObject<HTMLUListElement>;
  popoverState: OverlayTriggerState;
  listState: ListState<T>;
  originalProps: TextDropdownMultiProps<T> | TextDropdownMultiV2Props<T>;
};

export const useSelectProps = <T extends ListItem>(props: UseSelectPropsProps<T>) => {
  const { originalProps, selectRef, listRef, popoverState, listState } = props;
  const { isDropdownMulti = true } = originalProps;
  const { label, ...baseProps } = originalProps.selectProps ?? {};
  const { selectionManager, collection } = listState;

  const focusedItem =
    selectionManager.focusedKey != null && popoverState.isOpen
      ? collection.getItem(selectionManager.focusedKey)
      : undefined;

  const getActiveDescendant = useCallback(() => {
    if (!focusedItem || !listRef.current) return undefined;

    const active = listRef.current.querySelector(`[data-key="${focusedItem.key}"]`);
    return active?.id;
  }, [focusedItem, listRef]);

  return {
    ...baseProps,
    'aria-activedescendant': getActiveDescendant(),
    'aria-controls': popoverState.isOpen ? listRef.current?.id : undefined,
    'aria-disabled': originalProps.selectProps?.disabled,
    'aria-expanded': popoverState.isOpen,
    // 'aria-haspopup': 'listbox', // It works when populating directly into the element but not from here.
    'aria-invalid': selectRef.current?.checkValidity(),
    'aria-label': label,
    'aria-labelledby': label,
    'aria-multiselectable': true,
    'aria-required': originalProps.selectProps?.required,
    multiple: isDropdownMulti,
  };
};
