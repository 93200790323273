import { Button, ButtonType } from '@xemplo/button';
import { Prompt } from '@xemplo/prompts';

import { TestIds } from './create-amendment.helper';
import { CreateAmendmentProps } from './create-amendment.types';
import { useCreateAmendment } from './use-create-amendment';

export function CreateAmendment(props: Readonly<CreateAmendmentProps>) {
  const { renderButton, buttonProps, buttonChildren } = props;

  const { handleClick, promptProps, showButton } = useCreateAmendment(props);

  if (!showButton) {
    return null;
  }

  return (
    <>
      {renderButton ? (
        renderButton(() => handleClick())
      ) : (
        <Button
          ariaLabel="Create Amendment"
          type={ButtonType.Primary}
          testId={TestIds.NewAmendmentButton}
          {...buttonProps}
          onClick={() => handleClick()}
        >
          {buttonChildren ?? 'New Amendment'}
        </Button>
      )}
      <Prompt {...promptProps} />
    </>
  );
}

export default CreateAmendment;
