import { useGetPayrunWorkersQuery } from '@xemplo/payrun-query';
import { useServerSideState, XemploTable } from '@xemplo/table';

import { payrunEmployeeListColumns } from './payrun-employee-list.helpers';
import * as S from './payrun-employee-list.styles';
import { PayrunEmployeeListProps } from './payrun-employee-list.types';

export const PayrunEmployeeListTestId = {
  container: 'payrun-employee-list-container',
  title: 'payrun-employee-list-title',
};

export function PayrunEmployeeList({
  payrunId,
  maxHeight,
}: Readonly<PayrunEmployeeListProps>) {
  const { pagination, onPaginationChange } = useServerSideState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { pageIndex, pageSize } = pagination;
  const { data: employeeList, isLoading } = useGetPayrunWorkersQuery({
    id: payrunId ?? null,
    requestParams: {
      page: pageIndex + 1,
      per_page: pageSize,
    },
  });

  return (
    <S.PayrunEmployeeListContainer data-testid={PayrunEmployeeListTestId.container}>
      <S.PayrunEmployeeListTitle data-testid={PayrunEmployeeListTestId.title}>
        Employee List
      </S.PayrunEmployeeListTitle>
      <XemploTable
        data={employeeList?.result?.rows ?? []}
        columns={payrunEmployeeListColumns}
        onPaginationChange={onPaginationChange}
        state={{ pagination }}
        isLoading={isLoading}
        pageCount={employeeList?.result?.total_Pages ?? 0}
        maxHeight={maxHeight}
        enableRowSelection={false}
        enableTotals={false}
        manualPagination
        enablePagination
      />
    </S.PayrunEmployeeListContainer>
  );
}

export default PayrunEmployeeList;
