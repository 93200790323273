//@ts-nocheck
import React from "react";
import { message, Table } from "antd";
import {
  tableColumnGenerator,
  renderReoccurringEndDate,
  errorDisplay,
  getAmendmentTypeName,
  getColumnSortOrder,
  getConditionalResponse,
  getGeneralAmendmentApplyCancelClaimName,
  getGeneralAmendmentApplyEditedClaimName,
  getGeneralAmendmentApplyInKeypayClaimName,
  getGeneralAmendmentDeleteComponentName,
  getGeneralAmendmentEditComponentName,
  getGeneralAmendmentRequestCancelClaimName,
  getGeneralAmendmentResubmitClaimName,
  showDeleteGeneralAmendment,
} from "../../../libs";
import _ from "lodash";
import TableActionComponent from "../common/tableActions";
import {
  PERMANENT_AMENDMENT_TYPE_ID,
  REOCCURRING_AMENDMENT_TYPE_ID,
} from "../../../constants";
import ReSubmitAmendment from "./ReSubmitAmendment";
import { AmendmentStatusBadge } from "../../../Common/amendments";
import { withRouter } from "../../../hooks";
import { PendingAmountValue } from "./Amendments/pending-amount-value";
type State = any;

class AmendmentDetailList extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: [],
      visible: false,
      amendment: {},
      confirmLoading: false,
      declineLoading: false,
      current: 1,
      activeAmendment: {},
    };
  }

  handleDelete = (record) => {
    this.setState({ declineLoading: true });
    this.props
      .deleteAmendment({
        id: record.amendmentId,
        options: { payrunID: record.payrunId },
      })

      .then((response) => {
        this.setState({ declineLoading: false });

        if (response.status) {
          message.success("Deleted successfully");
          this.setState({ visible: false });
          this.props.handleTableChange({ options: {} });
        } else {
          errorDisplay(
            response && response.data && response.data.validationErrors
          );
        }
      });
  };

  handleResubmit = (values) => {
    this.props
      .reSubmitAmendment({
        id: this.state.amendment?.amendmentId,

        options: {
          payrunID: this.state.amendment?.payrunId,
        },
        payload: values,
      })

      .then((response) => {
        this.setState({ declineLoading: false });

        if (response.status) {
          message.success("Resubmitted successfully");

          this.hideReSubmitForm();

          this.props.handleTableChange({ options: {} });
        } else {
          errorDisplay(
            response && response.data && response.data.validationErrors
          );
        }
      });
  };

  hideModal = () => {
    this.setState({ visible: false });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        current: pagination.current,
      },
      () => {
        let options = {
          page: pagination.current,
          field: sorter.field,
          order: sorter.order
            ? sorter.order === "ascend"
              ? "asc"
              : "desc"
            : sorter.order,
        };

        this.props.handleTableChange({
          options: { ...options },
        });
      }
    );
  };

  closeActions = () => {
    this.setState({
      amendmentHistory: [],
      viewAmendmentHistory: false,
      historyLoading: false,
      activeAmendment: {},
      totalActions: 0,
    });
  };

  handleActionTableChange = (options) => {
    this.setState({ historyLoading: true });

    this.props.getAmendmentHistory(options).then((response) => {
      if (response.status) {
        this.setState({
          amendmentHistory: response.data,
          historyLoading: false,
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
    });
  };
  handleAction = ({ record, action }) => {
    switch (action) {
      case "edit":
        return [
          PERMANENT_AMENDMENT_TYPE_ID,
          REOCCURRING_AMENDMENT_TYPE_ID,
        ].includes(record?.amendmentTypeId)
          ? this.props.handleAmendmentActions({ data: record, action })
          : this.props.router.navigate(
              `/payrun/${record.payrunId}/amendment/${record.amendmentId}`
            );
      case "view":
        return this.props.handleAmendmentActions({
          data: record,
          action: "view",
        });
      case "delete":
        return [
          PERMANENT_AMENDMENT_TYPE_ID,
          REOCCURRING_AMENDMENT_TYPE_ID,
        ].includes(record?.amendmentTypeId)
          ? this.props.handleAmendmentActions({ data: record, action })
          : this.handleDelete(record);
      case "reSubmit":
        return this.showReSubmitForm(record);
      default:
        this.props.handleAmendmentActions({ data: record, action });
        break;
    }
  };

  showReSubmitForm = (record) => {
    this.setState({ amendment: record, showReSubmitForm: true });
  };

  hideReSubmitForm = () => {
    this.setState({ showReSubmitForm: false, amendment: {} });
  };

  getPageSize = () => {
    const { payrun, per_page } = this.props;
    if (payrun && ![1, 2, 3].includes(payrun.payrunStatusID)) return 50;
    else {
      return per_page ? per_page : 20;
    }
  };

  render() {
    const {
      dashboardView,
      amendmentType,
      payrun,
      total,
      generalAmendmentView,
      sortInfo,
    } = this.props;
    const pagination = {
      total: total,

      current: this.state.current,

      pageSize: this.getPageSize(),
      hideOnSinglePage: true,
    };

    let colTemplate = [
      {
        title: "Type",
        dataIndex: "amendmentTaskTypeName",
        sorter: true,
        sortOrder: getColumnSortOrder({
          sortInfo,
          name: "amendmentTaskTypeName",
        }),
      },
      {
        title: "Sub Type",
        dataIndex: "amendmentSubTypeName",
        sorter: false,
        sortOrder: getColumnSortOrder({
          sortInfo,
          name: "amendmentSubTypeName",
        }),
      },
      {
        title: "Business Unit",
        dataIndex: "businessUnitName",
        sorter: true,
        sortOrder: getColumnSortOrder({ sortInfo, name: "businessUnitName" }),
      },
      { title: "Applies To", dataIndex: "assignTo" },
      {
        title: "Requested Date",
        dataType: "date",
        dataIndex: "requestedDate",
        sorter: true,
        sortOrder: getColumnSortOrder({ sortInfo, name: "requestedDate" }),
      },
      {
        title: "Requested By",
        dataIndex: "requestedBy",
        dataType: "requestedBy",
        sorter: true,
        sortOrder: getColumnSortOrder({ sortInfo, name: "requestedBy" }),
      },
      {
        title: "Total Amount",
        dataIndex: "totalAmount",
        dataType: "currency",
        ellipsis: true,
        render: (data: number | null, record: AmendmentType) => {
          //TODO: Replace calculated value with BE returned totalAmount https://expedo.atlassian.net/browse/GC-870
          const totalAmount = (record.amount ?? 0) * (record.units ?? 0);
          return (
            <PendingAmountValue
              value={totalAmount}
              isIntegrated={record.isIntegrated}
              amendmentTaskTypeID={record.amendmentTaskTypeID}
              amendmentKeypayStatusId={record.amendmentKeypayStatusId}
            />
          );
        },
      },
      {
        title: "Status",
        dataIndex: "amendmentStatusID",
        render: (data) => {
          return <AmendmentStatusBadge statusID={data} />;
        },
      },
    ];
    if (amendmentType === "reoccurringAmendment" || generalAmendmentView) {
      colTemplate = _.concat(colTemplate, [
        {
          title: "Expiry",
          dataIndex: "schedule",
          render: (_data, record) => {
            return renderReoccurringEndDate(record);
          },
        },
      ]);
    }
    if (generalAmendmentView) {
      colTemplate = _.concat(colTemplate, [
        {
          title: "Amendment Type",
          dataIndex: "amendmentType",
          render: (record) => {
            return getAmendmentTypeName(record);
          },
        },
      ]);
    }
    const actions = dashboardView
      ? []
      : [
          {
            key: "action",
            align: "center",
            render: (record) => (
              <TableActionComponent
                record={record}
                handleAction={this.handleAction}
                editComponentName={getGeneralAmendmentEditComponentName({
                  content: record,
                  editComponent: this.props.editComponent,
                })}
                viewComponentName={this.props.component}
                deleteComponentName={
                  showDeleteGeneralAmendment({ content: record })
                    ? getGeneralAmendmentDeleteComponentName({
                        content: record,
                        deleteComponent: this.props.deleteComponent,
                      })
                    : ""
                }
                claims={this.props.claims}
                resubmitComponentName={getGeneralAmendmentResubmitClaimName({
                  content: record,
                  payrun,
                  generalAmendmentView,
                })}
                appliedInKeypay={getGeneralAmendmentApplyInKeypayClaimName({
                  content: record,
                  generalAmendmentView,
                  amendmentType,
                })}
                requestCancel={getGeneralAmendmentRequestCancelClaimName({
                  content: record,
                  generalAmendmentView,
                  amendmentType,
                })}
                applyCancel={getGeneralAmendmentApplyCancelClaimName({
                  content: record,
                  generalAmendmentView,
                })}
                applyEditedInKeypay={getGeneralAmendmentApplyEditedClaimName({
                  content: record,
                  generalAmendmentView,
                })}
              />
            ),
          },
        ];
    const column = _.concat(tableColumnGenerator(colTemplate), actions);
    return (
      <React.Fragment>
        <Table
          columns={column}
          rowKey={(rowKey) => rowKey.amendmentId}
          dataSource={this.props.data}
          pagination={getConditionalResponse({
            condition: dashboardView,
            resp1: false,
            resp2: pagination,
          })}
          loading={this.props.loading}
          onChange={this.handleTableChange}
        />
        <ReSubmitAmendment
          visible={this.state.showReSubmitForm}
          handleCancel={this.hideReSubmitForm}
          submitAmendment={this.handleResubmit}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(AmendmentDetailList);
