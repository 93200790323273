import { RefObject } from 'react';
import { useOverlayTrigger } from '@react-aria/overlays';
import { OverlayTriggerProps, useOverlayTriggerState } from '@react-stately/overlays';

type UsePopoverStateProps = OverlayTriggerProps & {
  ref?: RefObject<Element>;
  /** Type of overlay that is opened by the trigger. */
  type: 'dialog' | 'menu' | 'listbox' | 'tree' | 'grid';
};

export const usePopoverState = (props: UsePopoverStateProps) => {
  const { ref, type, ...rest } = props;
  const state = useOverlayTriggerState(rest);
  const { triggerProps, overlayProps } = useOverlayTrigger({ type }, state, ref);
  return { state, triggerProps, overlayProps };
};
