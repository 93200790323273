import { PropsWithChildren, useMemo } from 'react';

import { useRoleAuthorisation } from '@xemplo/roles-query';

import { RoleRendererProps } from './role-renderer.types';

/**
 * RoleRenderer component
 *
 * This component is used to render children based on the user's role.
 * By default, it checks the visibility from the backend.
 * One may also pass explicit roles to show that the component is only visible to roles[].
 * In addition, one may pass additional rules to combine other external rules to determine
 * whether the component should be rendered.
 */
export function RoleRenderer({
  children,
  module,
  component,
  roles,
  additionalRules,
}: PropsWithChildren<RoleRendererProps>) {
  const { isInRole, componentVisible, isLoading, data } = useRoleAuthorisation(module);

  const shouldRender = useMemo(() => {
    if (isLoading) return false;

    let result = component ? componentVisible(component) : true;
    if (roles) {
      result = result && isInRole(roles);
    }

    if (additionalRules) {
      result = result && additionalRules.every((rule) => rule(data?.result));
    }

    return result;
  }, [
    isLoading,
    componentVisible,
    component,
    roles,
    additionalRules,
    isInRole,
    data?.result,
  ]);

  return shouldRender ? children : null;
}
