export const ClientInformationCardMessage = {
  prepareTitle:
    "Your Pay Run is currently being prepared by your payroll manager",
  prepareSubTitle:
    "Any amendments submitted are being reviewed incorporated into the pay run.",
  prepareDescription:
    "You will be notified when the pay run is ready for your review and approval.",
  approvalTitle: "Review And Approve Your Pay Run",
  approvalSubTitle: "Approve and decline pay run accordingly.",
  approvalDescription:
    "If you note down mistakes don't forget to give reasons on why items are rejected.",
};

export const ManagerInformationCardMessage = {
  prepareTitle: "Prepare pay run",
  prepareDescription: "Client will be notified when it has been prepared.",
  approvalTitle: "Pay Run Being Reviewed",
  approvalDescription: "You will be notified when it has been approved.",
};
