//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Card, message, Layout, Row, Col } from "antd";
import { errorDisplay } from "../../../libs/utilities";
import { headerAction } from "../../../libs";
import BillingServiceProviderAccountForm from "../../components/bsp/accountForm";
import axios from "axios";
import FormNavbar from "../../../Layout/FormNavbar";
import { FormStepsCol } from "../../../Common/ui";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { withRouter } from "../../../hooks";

const { Content, Header } = Layout;

type State = any;

class AddBillingServiceProviderAccounts extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      currencyList: [],
      bankList: [],
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        },
      ],
      activeIndex: "1",
    };
  }

  componentDidMount() {
    const { match, billingServiceProviderDetail } = this.props;
    const countryId =
      billingServiceProviderDetail &&
      billingServiceProviderDetail.serviceCountryId;
    const { id, accountId } = match && match.params && match.params;
    this.listCurrency();
    this.listBanks(countryId);
    if (accountId) {
      this.props
        .billingServiceProviderHandler({
          id,
          accountId,
          action: "getAccount",
          options: {},
        })
        .then((resp) => {
          if (resp.status) {
            headerAction.set({
              title:
                resp.data && resp.data.accountName
                  ? `Edit: ${resp.data.accountName}`
                  : "Edit:",
              action: "",
              entity: "billingserviceprovider",
              enableBack: true,
            });
            this.setState((prevState) => {
              prevState.editMode = true;
              return prevState;
            });

            this.props.form.setFieldsValue({
              accountName: resp.data && resp.data.accountName,
              bankID: resp.data && resp.data.bankID,

              currencyID: resp.data && resp.data.currencyID,
              accountNumber: resp.data && resp.data.accountNumber,

              bsb: resp.data && resp.data.bsb,

              iban: resp.data && resp.data.iban,

              swift: resp.data && resp.data.swift,
            });
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
    } else {
      headerAction.set({
        title: "New Account",
        action: "",
        entity: "billingserviceprovider",
        enableBack: true,
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  listCurrency = () => {
    this.props.listCurrency({ options: {} }).then((response) => {
      if (response.status && response.data) {
        this.setState({ currencyList: response.data });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
    });
  };

  listBanks = (countryId) => {
    this.props
      .banksLookup({ options: { q: { countryId } } })
      .then((response) => {
        if (response.status && response.data) {
          this.setState({ bankList: response.data });
        } else {
          errorDisplay(
            response && response.data && response.data.validationErrors
          );
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const form = this.props.form;

    this.setState({ submitLoading: true });

    const { match } = this.props;
    const id = match && match.params && match.params.id;
    const accountId = match && match.params && match.params.accountId;
    form.validateFields((err, values) => {
      if (err) {
        return this.setState({ submitLoading: false });
      }
      let finalValues = {
        ...values,
        billingServiceProviderID: id,
      };

      if (accountId) {
        finalValues = { ...finalValues, id: this.props.match.params.accountId };

        this.props
          .billingServiceProviderHandler({
            payload: finalValues,
            action: "updateAccount",
            id,
            accountId,
          })
          .then((response) => {
            this.setState({ submitLoading: false });

            if (response.status) {
              message.success("Account details updated successfully");

              this.onCancel();
            } else {
              errorDisplay(response && response.data, true);
            }
          });
      } else {
        this.props
          .billingServiceProviderHandler({
            payload: finalValues,
            action: "createAccount",
            id,
          })
          .then((response) => {
            this.setState({ submitLoading: false });

            if (response.status) {
              message.success("Account created successfully");

              this.onCancel();
            } else {
              errorDisplay(response && response.data, true);
            }
          });
      }
    });
  };

  onCancel = () => {
    this.setState({ submitLoading: false });

    const { match } = this.props;
    const id = match && match.params && match.params.id;

    this.props.form.resetFields();
    this.props.router.navigate(`/billingserviceproviders/${id}`);
  };

  render() {
    const { form } = this.props;
    const { submitLoading, bankList, currencyList, activeIndex, formLabels } =
      this.state;
    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={false}
        autoComplete="off"
      >
        <Layout>
          <Header className="bg-white">
            <FormNavbar
              title="Accounts"
              handleCancel={this.onCancel}
              handleSave={this.handleSubmit}
              loading={submitLoading}
            />
          </Header>
          <Layout className="p-6 rounded-[5px] form-label-left overflow-y-auto">
            <Content>
              <Row gutter={DEFAULT_GUTTER}>
                <FormStepsCol data={formLabels} activeIndex={activeIndex} />
                <Col md={18} sm={24}>
                  {
                    <div
                      className={
                        Number(activeIndex) === 1 ? "block" : "display-none"
                      }
                    >
                      <Card>
                        <BillingServiceProviderAccountForm
                          form={form}
                          currencyList={currencyList}
                          bankList={bankList}
                        />
                      </Card>
                    </div>
                  }
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </Form>
    );
  }
}

export default withRouter(
  Form.create({ name: "billingServiceProviderAccountForm" })(
    AddBillingServiceProviderAccounts
  )
);
