import React from "react";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { getClaimAndStatus } from "../../../libs/utilities";

interface CreateLinkButtonProps {
  name?: string;
  claims?: any;
  className?: string;
  loading?: boolean;
  onClick?: (e: any) => void;
  hasNoComponents?: boolean;
}

const classNameDefault = "";

const CreateLinkButton = ({
  name,
  claims,
  className = classNameDefault,
  loading,
  onClick,
  hasNoComponents,
}: CreateLinkButtonProps) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: !hasNoComponents,
  });
  return (
    <Button
      type="link"
      className={`${className} ${claimAndStatus.status} color-primary button-link`}
      loading={loading}
      onClick={onClick}
      disabled={claimAndStatus.status === "Visible-Disabled"}
    >
      <PlusCircleFilled />
      New
    </Button>
  );
};

export default CreateLinkButton;
