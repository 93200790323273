import { SVGProps } from 'react';
const DawnTriangleAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.996 3.821c-.71 0-1.588.488-2.337 1.838v.001l-2.715 4.876-2.882 5.19c-.696 1.257-.66 2.233-.296 2.851.362.618 1.195 1.125 2.633 1.125h5.597c.04 0 .08.003.118.01h5.48c1.432 0 2.266-.508 2.63-1.126.363-.619.402-1.595-.294-2.85l-2.881-5.19-2.716-4.887c-.749-1.35-1.627-1.838-2.337-1.838Zm-.118 17.381h-5.48c-1.766 0-3.206-.638-3.926-1.865-.72-1.227-.577-2.795.278-4.338l2.882-5.191L8.349 4.93c.895-1.615 2.188-2.61 3.648-2.61s2.753.995 3.649 2.61l2.715 4.886 2.882 5.191c.856 1.544.996 3.112.274 4.338-.721 1.227-2.16 1.865-3.923 1.865h-5.597a.754.754 0 0 1-.118-.009Zm.118-13.45a.75.75 0 0 1 .75.75v4.887a.75.75 0 0 1-1.5 0V8.502a.75.75 0 0 1 .75-.75Zm-.005 7.627a1 1 0 1 0 0 2h.008a1 1 0 1 0 0-2h-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnTriangleAlert;
