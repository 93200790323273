import { useEffect } from "react";
import { useAuth } from "@xemplo/auth-provider";
import { useNavigate } from "react-router-dom";

const requireAuth = (WrappedComponent: any) => (props: any) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    !isAuthenticated && navigate("/login");
  }, [isAuthenticated]);

  return isAuthenticated ? <WrappedComponent {...props} /> : null;
};

export default requireAuth;
