import styled from 'styled-components';

import {
  containerBase,
  containerDisabled,
  containerError,
  containerFocus,
  containerHasLabelPadding,
  containerReadonly,
  hiddenLabelBase,
  inputBase,
  inputDisabled,
  InputFieldSize,
  inputReadonly,
  inputWithLabel,
  labelInputHasFocus,
  labelInputHasValue,
  labelVisible,
  wrapperBase,
} from '@xemplo/input-utils';

export const Wrapper = styled.div<{ $width?: string }>`
  ${wrapperBase};
  position: relative;
`;

export const Container = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerFocus};
  ${containerDisabled};
  ${containerError};
  ${containerHasLabelPadding};
  ${containerReadonly};
`;

export const Input = styled.input`
  ${inputBase};
  ${inputDisabled};
  ${inputReadonly};
  ${inputWithLabel};
  ${labelInputHasFocus};
  caret-color: transparent;
  cursor: default;
  &.searchable {
    cursor: text;
    caret-color: auto;
  }
`;

export const Label = styled.label`
  ${hiddenLabelBase};
  ${labelVisible};
  ${labelInputHasValue};
`;
