import { NO_VALUE, YES_VALUE } from "../constants";

type YesNo = typeof YES_VALUE | typeof NO_VALUE;
type TypeYesNoText = {
  value?: YesNo;
  defaultValue?: string;
};

export const getYesNoText = ({
  value,
  defaultValue = "N/A",
}: TypeYesNoText) => {
  switch (Number(value)) {
    case YES_VALUE:
      return "Yes";
    case NO_VALUE:
      return "No";
    default:
      return defaultValue;
  }
};
