import { RefObject, useCallback, useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { TableProps } from '../table.types';

import { BodyProps } from './body.types';

type HookProps<TData> = BodyProps<TData> & {
  bodyRef: RefObject<HTMLDivElement>;
};

export const useVirtualRows = <TData>({ table, bodyRef, theadRef }: HookProps<TData>) => {
  const { rows } = table.getRowModel();
  const { pagination } = table.getState();
  const { enablePagination, overscan } = table.options as TableProps<TData>;

  const defaultOverscan = enablePagination ? pagination.pageSize : 50;
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => bodyRef.current,
    estimateSize: () => 50, // Estimated pixel height of each row
    count: rows.length,
    overscan: overscan ?? defaultOverscan,
  });

  const { getTotalSize, getVirtualItems } = rowVirtualizer;
  const virtualRows = getVirtualItems();
  const totalSize = getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  // Scroll to top on pagination change
  // NOTE: on jsdom (unit testing), scrollTo is not implemented, so we need to short-circuit it here
  useEffect(() => {
    if (!bodyRef.current || !enablePagination) return;
    bodyRef.current.scrollTo?.(0, 0);
  }, [bodyRef, enablePagination, pagination]);

  // Controls header shadow and toggling keyline when reaching end of scroll
  const onContainerScroll = useCallback(() => {
    if (!bodyRef.current || !theadRef.current) return;

    const bodyEl = bodyRef.current;
    const theadEl = theadRef.current;

    const { scrollTop, scrollHeight, clientHeight } = bodyEl;

    if (enablePagination) {
      const hasKeyline = scrollTop + clientHeight < scrollHeight;
      bodyEl.classList.toggle('keyline', hasKeyline);
    }

    theadEl.classList.toggle('scroll-shadow', scrollTop > 0);
  }, [bodyRef, theadRef, enablePagination]);

  // Handles add/removing the scroll events
  useEffect(() => {
    if (!bodyRef.current) return;

    const bodyEl = bodyRef.current;
    bodyEl.addEventListener('scroll', onContainerScroll);
    return () => bodyEl.removeEventListener('scroll', onContainerScroll);
  }, [bodyRef, onContainerScroll]);

  // Overflow Side Effects
  // The scroll has a 4px width, so we need to add padding to the header to
  // avoid the content to be misaligned when the scroll is visible.
  // Also, the keyline should be present to indicate there's more content to scroll to.
  useEffect(() => {
    if (!bodyRef.current || !theadRef.current) return;

    const isOverflowing = bodyRef.current.offsetHeight < bodyRef.current.scrollHeight;
    const hasKeyline = enablePagination && isOverflowing;

    bodyRef.current.classList.toggle('keyline', hasKeyline);
    theadRef.current.classList.toggle('overflow-padding', isOverflowing);
  }, [theadRef, bodyRef, pagination, enablePagination]);

  return { rows, paddingTop, paddingBottom, virtualRows };
};
