import { RouteObject } from 'react-router';

import { requireAuth } from '@xemplo/auth-provider';
import { ErrorBoundary } from '@xemplo/error-boundary';
import { PayrollRoutes } from '@xemplo/gp-routes';
import { withLazyComponent } from '@xemplo/lazy-component';

import GpDashboard from './gp-dashboard';

export const DashboardRoutes: RouteObject[] = [
  {
    path: PayrollRoutes.Home,
    Component: requireAuth(withLazyComponent(GpDashboard)),
    errorElement: <ErrorBoundary />,
  },
];
