import { SVGProps } from 'react';
const DawnArrowDown24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.47 8.47a.75.75 0 1 1 1.06 1.06l-8 8a.75.75 0 0 1-1.06 0l-8-8a.75.75 0 0 1 1.06-1.06L12 15.94l7.47-7.47Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowDown24;
