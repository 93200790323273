//@ts-nocheck
import React, { Component } from "react";
import { Table } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import EditIconButton from "../../../Common/ClaimComponents/IconButton/EditIconButton";

class XeroMAppingList extends Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order === "ascend" ? "asc" : "desc",
          field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options });
  };
  render() {
    const { loading, data, handleEdit, editComponent, claims, per_page } =
      this.props;
    const colTemplate = [
      { title: "Payment Item", dataIndex: "name", width: "50%" },
      {
        title: "Code",
        dataIndex: "xeroReferenceCode",
        width: "40%",
        render: (record: any) => {
          return record ? <span>{record}</span> : "-";
        },
      },
      {
        title: "",
        key: "action",
        width: "10%",
        render: (record: any) => {
          return (
            <React.Fragment>
              <EditIconButton
                handleEdit={() => handleEdit({ record, label: "account" })}
                name={editComponent}
                claims={claims}
                hasNoComponents={true}
                avatarClassName="color-primary"
              />
            </React.Fragment>
          );
        },
      },
    ];
    const pagination = {
      total: this.props.totalPages,
      current: this.props.current,
      pageSize: per_page ? per_page : 10,
      hideOnSinglePage: true,
    };
    return (
      <Table
        loading={loading}
        pagination={pagination}
        dataSource={mustBeArray(data)}
        columns={colTemplate}
        rowKey={(record) => record.id}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default XeroMAppingList;
