import React from "react";

const FormResponsiveGridWrapper = ({
  componentOne,
  componentTwo,
}: {
  componentOne?: React.ReactNode;
  componentTwo?: React.ReactNode;
}) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
        <div className="lg:col-span-1">{componentOne}</div>
        <div className="lg:col-span-1">{componentTwo}</div>
      </div>
    </React.Fragment>
  );
};

export default FormResponsiveGridWrapper;
