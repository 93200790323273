import { useMemo } from 'react';
import styled from 'styled-components';

import { formatToCurrency } from '@xemplo/common-util';
import { Colour, TextEllipsis } from '@xemplo/style-constants';

import { MonetaryCellProps } from './cell.types';

const StyledValue = styled.span`
  color: ${(props) => props.color};
  ${TextEllipsis};
`;

export const MonetaryCell = (props: MonetaryCellProps) => {
  const color = useMemo(() => {
    if (props.value && props.value < 0) {
      return props.negativeColor;
    }

    return Colour.Gray[600];
  }, [props.negativeColor, props.value]);

  return (
    <StyledValue className={props.className} color={color}>
      {formatToCurrency(props)}
    </StyledValue>
  );
};
