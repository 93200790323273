import styled from 'styled-components';

import { Colour, HeadingStandardMedium, MinBreakpoint } from '@xemplo/style-constants';
export const Container = styled.div`
  margin-block: 48px;
  margin-inline: 32px;
  @media ${MinBreakpoint.xlarge} {
    margin-inline: 48px;
  }
  @media ${MinBreakpoint.xxlarge} {
    margin-inline: 72px;
  }
`;
export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media ${MinBreakpoint.xlarge} {
    grid-template-columns: 2fr 1fr;
  }
  @media ${MinBreakpoint.xxlarge} {
  }
`;

export const Heading = styled.div`
  color: ${Colour.Gray[800]};
  ${HeadingStandardMedium};
  margin-bottom: 32px;
`;
