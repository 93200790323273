//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  getPayschedule,
  savePaySchedule,
  getProcessingFee,
  payscheduleHandler,
  updatePaySchedule,
} from "../actions/payschedule";
import {
  connectPayProcessor,
  fetchPayrollConnectionName,
  fetchExternalBusinessunit,
  fetchExternalPayschedules,
  configurePayscheduleConnection,
  confirmPayrollConnection,
} from "../actions/payrollprocessor";
import {
  companyLookup,
  listCompanyBusinessUnits,
  getCompany,
} from "../actions/companies";
import {
  getBusinessUnit,
  listTaxFees,
  businessunitHandler,
} from "../actions/businessunits";
import axios from "axios";
import { populateStates, cdnLookUpHandler } from "../actions/lookup";
import {
  earningLinesLookup,
  listAmendmentTypes,
  payCategoryLookUp,
} from "../actions/payrun";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { withRouter } from "../../hooks";

class Payschedule extends SwiftContainer {
  signal = axios.CancelToken.source();
  render() {
    const { Layout } = this.props;
    const { pageClaims, loading } = this.state;
    return (
      <Layout
        userMode={this.props.userMode}
        businessUnit={this.props.businessUnit}
        memberDetails={this.props.memberDetails}
        company={this.props.company}
        loading={loading}
        payschedules={this.props.payschedules}
        payschedulesActive={this.props.payschedulesActive}
        payschedulesDeactive={this.props.payschedulesDeactive}
        payschedulesActiveCount={this.props.payschedulesActiveCount}
        payschedulesDeactiveCount={this.props.payschedulesDeactiveCount}
        total={this.props.payschedulesCount}
        companyLookup={this.props.companyLookup}
        listCompanyBusinessUnits={this.props.listCompanyBusinessUnits}
        savePaySchedule={this.props.savePaySchedule}
        match={{ params: this.props.router.params }}
        getCompany={this.props.getCompany}
        getBusinessUnit={this.props.getBusinessUnit}
        signal={this.signal}
        listPayschedules={(data: any) =>
          this.executeAction(this.props.getPayschedule, data)
        }
        connectPayProcessor={(data: any) =>
          this.executeAction(this.props.connectPayProcessor, data)
        }
        fetchPayrollConnectionName={(data: any) =>
          this.executeAction(this.props.fetchPayrollConnectionName, data)
        }
        fetchExternalBusinessunit={(data: any) =>
          this.executeAction(this.props.fetchExternalBusinessunit, data)
        }
        fetchExternalPayschedules={(data: any) =>
          this.executeAction(this.props.fetchExternalPayschedules, data)
        }
        configurePayscheduleConnection={(data: any) =>
          this.executeAction(this.props.configurePayscheduleConnection, data)
        }
        listTaxFees={this.props.listTaxFees}
        businessunitDetails={this.props.businessunitDetails}
        pageClaims={pageClaims}
        member={this.props.member}
        populateStates={this.props.populateStates}
        earningLinesLookup={this.props.earningLinesLookup}
        listAmendmentTypes={this.props.listAmendmentTypes}
        getProcessingFee={this.props.getProcessingFee}
        confirmPayrollConnection={this.props.confirmPayrollConnection}
        payCategoryLookUp={this.props.payCategoryLookUp}
        payscheduleHandler={this.props.payscheduleHandler}
        businessunitHandler={this.props.businessunitHandler}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        updatePaySchedule={this.props.updatePaySchedule}
        cdnLookUpHandler={this.props.cdnLookUpHandler}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payschedules: state.payschedule.list || [],
  payschedulesCount: state.payschedule.count || 0,
  payschedulesActive: state.payschedule.active.list || [],
  payschedulesDeactive: state.payschedule.deactive.list || [],
  payschedulesActiveCount: state.payschedule.active.count || 0,
  payschedulesDeactiveCount: state.payschedule.deactive.count || 0,
  company: state.company,
  businessunit: state.businessunit,
  memberDetails: state.member.details || {},
  userMode: "",

  // state.member.details &&
  // state.member.details.roles &&
  // state.member.details.roles[0].name,
  businessUnit: state.businessUnit && state.businessUnit.detail,

  businessunitDetails: state.businessUnit.detail || {},
  member: state.member,
});

const mapDispatchToProps = {
  getPayschedule,
  listCompanyBusinessUnits,
  savePaySchedule,
  getCompany,
  getBusinessUnit,
  connectPayProcessor,
  companyLookup,
  fetchPayrollConnectionName,
  listTaxFees,
  fetchExternalBusinessunit,
  fetchExternalPayschedules,
  configurePayscheduleConnection,
  populateStates,
  earningLinesLookup,
  listAmendmentTypes,
  getProcessingFee,
  confirmPayrollConnection,
  payCategoryLookUp,
  payscheduleHandler,
  businessunitHandler,
  updateHeader,
  updateAuthorizationClaim,
  updatePaySchedule,
  cdnLookUpHandler,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payschedule)
);
