export const ROLE_AUTHORISATION_ENDPOINT = 'api/authorization';

export enum ROLE_AUTHORISATION_MODULE_IDS {
  menu = '0',
  dashboard = '1',
  company = '2',
  businessUnit = '3',
  payrun = '4',
  paySchedule = '5',
  payrollProvider = '6',
  user = '7',
  billingProvider = '8',
  invoice = '9',
  variance = '10',
  amendment = '11',
  emailTemplate = '12',
}

export const PAYRUN_COMPONENTS = {
  AMENDMENT_CREATE: 'payrunAmendmentCreate',
  AMENDMENT_SUBMIT: 'payrunAmendmentSubmit',
  AMENDMENT_DELETE: 'payrunAmendmentDelete',
  APPROVE: 'payrunApprove',
  REJECT: 'payrunReject',
};
