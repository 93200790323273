//@ts-nocheck
import React from "react";
import BusinessUnitList from "../../components/businessUnitList";
import {
  mustBeArray,
  updateAuthorizationClaims,
  findAuthorizationClaims,
  removeUndefined,
  delay,
  errorDisplay,
  getPersonalizationData,
  setPersonalizationData,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  checkClaimsAuthorization,
  requestUrlBuilder,
  filteredByLabel,
  errorHandler,
  formatArrayQueryParams,
  getSelectedRoleTenantId,
  updateMultiSelectFilters,
  trimWhiteSpaces,
} from "../../../libs/utilities";
import _ from "lodash";
import {
  INTEGER_PERSONALIZATION,
  STRING_PERSONALIZATION,
  TABLE_VIEW_VALUE,
  GRID_VIEW_VALUE,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_NOT_REQUIRED,
  PAGINATION_SCROLLING,
} from "../../../constants/defaultClaims";
import NoRecord from "../../../Common/NoRecord";
import ContentEmpty from "../../components/common/contentListEmpty";
import GridTableViewOption from "../../components/common/gridTableViewOption";
import ScrollableBusinessUnitTable from "../../components/businessunit/scrollableList";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import axios from "axios";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import { FILTER_ACTIVE_INACTIVE_OPTIONS } from "../../../constants/options";
import {
  BUSINESSUNIT_GRID_OPTIONS,
  BUSINESSUNIT_FIELD_OPTIONS,
} from "../../../constants/sortingOptions";
import APIHandler from "../../../constants/apiUrl";
import AssignPayrollManagerModal from "../../components/businessunit/AssignPayrollManagerModal";
import { message, Select, Checkbox, Tooltip } from "antd";
import CountryFilter from "../../components/common/CountryFilter";
import { CLEAR_SELECTION_VALUE } from "../../../constants/ui";
import {
  userCanAssignPayrollManager,
  validateUsersAccessRights,
} from "../../../libs/validations";
import {
  getBusinessUnitDetailRoute,
  isUserPSPManager,
  updateDomChanges,
} from "../../../libs";
import {
  PRIMARY_PAYROLL_MANAGER_ID,
  SECONDARY_PAYROLL_MANAGER_ID,
} from "../../../constants/user";
import {
  BUSINESS_UNIT_CREATE_SIMPLE_BUTTON,
  BUSINESS_UNIT_FILTER_CONTAINER,
  BUSINESS_UNIT_LIST_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { withRouter } from "../../../hooks";

type State = any;

class BusinessUnitIndex extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: BUSINESSUNIT_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: BUSINESSUNIT_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
      isDefaultListEmpty: false,
      countryList: [],
      showAssignPayrollManagerModal: false,
      payrollProviderId: "",
      activeBusinessUnit: {},
      assignedPayrollManagers: [],
      saveLoading: false,
      companyList: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, pageClaims, member } = this.props;
    //validate if user has access to page
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    if (dashboardView) {
      const businessUnitPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      if (
        !businessUnitPersonalizations ||
        !businessUnitPersonalizations.gridView
      ) {
        this.setState({ gridView: false });
      } else {
        this.setState({ gridView: true });
      }
      this.fetchBusinessUnitList({ options: {} });
    } else {
      if (
        userCanAssignPayrollManager({ claims: pageClaims }) &&
        !isUserPSPManager({ member })
      ) {
        this.listPayrollManagers();
      }
      this.listCompanies();
      this.loadBusinessUnitComponents();
    }
  }

  // list companiess
  listCompanies = () => {
    this.props
      .lookUpHandler({
        options: {},
        cancelToken: this.signal.token,
        type: "company",
      })
      .then((resp) => {
        this.setState({ companyList: mustBeArray(resp?.data) });
      });
  };

  loadBusinessUnitComponents = () => {
    this.props.updateHeader({
      header: {
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BusinessUnitList,
        title: "Business Unit",
        module: "",
        enableBack: false,
        entity: "business unit",
        action: checkClaimsAuthorization({
          component: BUSINESS_UNIT_CREATE_SIMPLE_BUTTON,
          claims: this.props.pageClaims?.components,
        }),
      },
    });
    const businessunitGeneralTable = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitGeneralTable",
    });
    const businessunitGeneralGrid = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitGeneralGrid",
    });
    const businessUnitCountryDropdownFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitCountryDropdownFilter",
    });
    const businessUnitNameTextboxFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitNameTextboxFilter",
    });
    const businessUnitStatusDropdownFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitStatusDropdownFilter",
    });
    const businessUnitListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: BUSINESS_UNIT_LIST_CONTAINER,
    });
    const businessUnitPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: businessUnitListContainer?.personalizations,
    });
    const nameTextBoxPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: businessUnitNameTextboxFilter?.personalization,
    });
    const countryPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: businessUnitCountryDropdownFilter?.personalization,
    });
    const isActivePersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: businessUnitStatusDropdownFilter?.personalization,
    });
    this.setState({
      filters: {
        name: nameTextBoxPersonalizations?.value,
        countryId: countryPersonalizations?.value
          ? Number(countryPersonalizations.value)
          : undefined,
        isActive: isActivePersonalizations?.value
          ? Number(isActivePersonalizations.value)
          : 0,
      },
      paginationType: businessUnitPersonalizations?.paginationType,
      sortingType: businessUnitPersonalizations?.sortingType,
      isPinned: businessUnitPersonalizations?.isPinned,
      gridable: businessUnitListContainer?.gridable,
    });
    if (
      businessunitGeneralGrid &&
      [1, 2].includes(Number(businessunitGeneralGrid.authorizationStatusId))
    ) {
      const businessUnitGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: businessunitGeneralGrid.personalizations,
      });
      const pageNumber = businessUnitGridPersonalizations?.pageNumber
        ? Number(businessUnitGridPersonalizations.pageNumber)
        : 1;
      const row = businessUnitGridPersonalizations?.row
        ? Number(businessUnitGridPersonalizations.row)
        : 5;
      const col = businessUnitGridPersonalizations?.col
        ? Number(businessUnitGridPersonalizations.col)
        : 4;
      const SortOrder =
        businessUnitGridPersonalizations &&
        businessUnitGridPersonalizations.sortOrder
          ? businessUnitGridPersonalizations.sortOrder
          : null;
      const SortColumn =
        businessUnitGridPersonalizations &&
        businessUnitGridPersonalizations.sortColumn
          ? businessUnitGridPersonalizations.sortColumn
          : null;

      this.setState(
        {
          grid: {
            page: pageNumber ? Number(pageNumber) : 1,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          selectedGridSort:
            _.find(this.state.gridSortOption, (o) => o.label === SortColumn) &&
            _.find(this.state.gridSortOption, (o) => o.label === SortColumn).id,
        },
        () => {
          if (
            businessUnitPersonalizations &&
            businessUnitPersonalizations.gridView
          ) {
            this.setState({ gridView: true });
            if (
              businessUnitPersonalizations &&
              businessUnitPersonalizations.paginationType ===
                PAGINATION_SCROLLING
            ) {
              this.listScrollingGridBusinessUnits({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.fetchMoreData({ isPaginated: true, notUpdate: true });
            }
          }
        }
      );
    }

    if (
      businessunitGeneralTable &&
      [1, 2].includes(Number(businessunitGeneralTable.authorizationStatusId))
    ) {
      const businessUnitTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: businessunitGeneralTable.personalizations,
      });
      const pageNumber =
        businessUnitTablePersonalizations &&
        businessUnitTablePersonalizations.pageNumber
          ? Number(businessUnitTablePersonalizations.pageNumber)
          : 1;
      const pageSize =
        businessUnitTablePersonalizations &&
        businessUnitTablePersonalizations.pageSize
          ? Number(businessUnitTablePersonalizations.pageSize)
          : 20;
      const SortOrder =
        businessUnitTablePersonalizations &&
        businessUnitTablePersonalizations.sortOrder
          ? businessUnitTablePersonalizations.sortOrder
          : null;
      const SortColumn =
        businessUnitTablePersonalizations &&
        businessUnitTablePersonalizations.sortColumn
          ? businessUnitTablePersonalizations.sortColumn
          : null;
      this.setState(
        {
          per_page:
            businessUnitPersonalizations &&
            businessUnitPersonalizations.paginationType ===
              PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : pageSize
              ? Number(pageSize)
              : 20,
          current: pageNumber ? Number(pageNumber) : 1,
          sortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!businessUnitPersonalizations.gridView) {
            if (
              businessUnitPersonalizations &&
              businessUnitPersonalizations.paginationType ===
                PAGINATION_SCROLLING
            ) {
              this.listScrollingBusinessUnits({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.listBusinessUnits({ options: {}, notUpdate: true });
            }
          }
        }
      );
    }
  };

  fetchBusinessUnitList = ({ options = {} }) => {
    const { targetEndpoint } = this.props;
    this.setState({ listLoading: true });
    this.props
      .listBusinessUnits({
        options,
        targetEndpoint,
      })
      .then((resp) => {
        this.setState({ filterLoading: false, listLoading: false });

        if (resp.status) {
          this.setState({ list: mustBeArray(resp.data), total: resp.total });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  listPayrollManagers = () => {
    this.props
      .listPSPUsers({
        options: {
          per_page: 0,
          q: {
            serviceProviderId: getSelectedRoleTenantId({
              member: this.props.member,
            }),
            isActive: 1,
            isEnabled: 1,
          },
        },
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        this.setState({ payrollManagers: mustBeArray(resp.data) });
      });
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.current = 1;
        prevState.listLoading = true;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };

        if (this.state.gridView) {
          options = {
            ...options,

            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),

            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }
        this.updateAllPersonalization();

        this.fetchBusinessUnitList({ options });
      }
    );
  };

  handleChange = (label, value) => {
    switch (label) {
      case "countryId":
      case "assigneduserids":
        return this.setState({
          filters: {
            ...this.state.filters,
            [label]: updateMultiSelectFilters({ newValue: value }),
          },
        });
      default:
        return this.setState({
          ...this.state,
          filters: { ...this.state.filters, [label]: value },
        });
    }
  };

  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", countryId: 0, isActive: 0, companyids: "" },
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.fetchBusinessUnitList({
          options: {
            page: 1,
            per_page: this.state.gridView
              ? getCurrentGridPageSize({
                  paginationType: this.state.paginationType,
                  grid: this.state.grid,
                })
              : this.state.per_page,
          },
        });
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization("businessUnitNameTextboxFilter", false);
    delay(300).then(() => {
      this.updatePersonalization("businessUnitCountryDropdownFilter", false);
    });
    delay(600).then(() => {
      this.updatePersonalization("businessUnitStatusDropdownFilter", false);
    });
    delay(900).then(() => {
      this.updatePersonalization("businessUnitGeneralTable", false);
    });
    delay(1200).then(() => {
      this.updatePersonalization("businessUnitGeneralGrid");
    });
  };

  listBusinessUnits = ({ options = {}, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .listBusinessUnits({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listBusinessUnits({ options: {} });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("businessUnitGeneralTable");
          }
        });
      }
    );
  };

  listScrollingBusinessUnits = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            prevState.total === mustBeArray(prevState.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .listBusinessUnits({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingBusinessUnits({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],
                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("businessUnitGeneralTable");
          }
        });
      }
    );
  };

  listScrollingGridBusinessUnits = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      {
        listLoading: true,

        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid && this.state.grid.page) + 1
            : Number(this.state.grid && this.state.grid.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;

        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });

        let filterOptions = this.getFilters();

        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });

        this.props
          .listBusinessUnits({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
            },

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              this.checkIfDeafultListEmpty({ list: response.data });

              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridBusinessUnits({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization("businessUnitGeneralGrid");
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listBusinessUnitGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid && this.state.grid.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .listBusinessUnits({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("businessUnitGeneralGrid");
        });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member?.details?.authorizationDOM
      );

      const businessUnitListContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === BUSINESS_UNIT_LIST_CONTAINER
      );

      const businessUnitFilterContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === BUSINESS_UNIT_FILTER_CONTAINER
      );

      const businessUnitGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "businessUnitGeneralTable"
      );

      const businessUnitGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "businessUnitGeneralGrid"
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];
      switch (field) {
        case "businessUnitGeneralTable":
          if (businessUnitListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,

              sortOrder: this.state.sortInfo && this.state.sortInfo.order,

              sortColumn: this.state.sortInfo && this.state.sortInfo.field,

              pageSize: `${this.state.per_page}`,
              personalizationData:
                businessUnitGeneralTable &&
                businessUnitGeneralTable.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "businessUnitGeneralGrid":
          if (businessUnitListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid && grid.page}`,
              row: `${grid && grid.row}`,
              col: `${grid && grid.col}`,
              sortOrder: grid && grid.sortInfo && grid.sortInfo.order,
              sortColumn: grid && grid.sortInfo && grid.sortInfo.field,
              personalizationData:
                businessUnitGeneralGrid &&
                businessUnitGeneralGrid.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "businessUnitCountryDropdownFilter":
          if (businessUnitFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters && this.state.filters.countryId
                ? this.state.filters.countryId
                : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "businessUnitStatusDropdownFilter":
          if (businessUnitFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value =
              this.state.filters && this.state.filters.isActive;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "businessUnitNameTextboxFilter":
          if (businessUnitFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value =
              this.state.filters && this.state.filters.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case BUSINESS_UNIT_LIST_CONTAINER:
          if (businessUnitListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData: businessUnitListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent && updatedComponent.id,
          name: updatedComponent && updatedComponent.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }

      const updateChanges = updateDomChanges({
        update,
        condition: field === "businessUnitGeneralGrid",
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    if (Number(tempFilters.countryId) === 0) {
      delete tempFilters.countryId;
    }
    tempFilters.name = trimWhiteSpaces(tempFilters.name);
    tempFilters.countryIds = formatArrayQueryParams({
      data: tempFilters.countryId,
    });
    delete tempFilters.countryId;
    tempFilters.assigneduserids = formatArrayQueryParams({
      data: tempFilters.assigneduserids,
    });
    if (trimWhiteSpaces(tempFilters.companyids)) {
      tempFilters.companyids = formatArrayQueryParams({
        data: [trimWhiteSpaces(tempFilters.companyids)],
      });
    } else {
      delete tempFilters.companyids;
    }
    switch (Number(tempFilters.isActive)) {
      case 0:
        delete tempFilters.isActive;
        break;
      case 1:
        tempFilters.isActive = 1;
        break;
      case 2:
        tempFilters.isActive = 0;
        break;
      default:
        break;
    }
    return tempFilters;
  };

  fetchMoreData = ({ isPaginated = false }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props

      .listBusinessUnits({
        options: { page, per_page, q: { ...options }, ...sortingParams },
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          this.setState(
            (prevState) => {
              const data = mustBeArray(response.data);
              if (isPaginated) {
                prevState.list = data;
              } else {
                prevState.list = prevState.list.concat(data);

                if (data.length !== 0 && data.length === this.state.page_size) {
                  prevState.hasMoreData = true;
                  prevState.grid = {
                    ...prevState.grid,
                    page: prevState.grid.page + 1,
                  };
                } else {
                  prevState.hasMoreData = false;
                }
              }

              prevState.total = response.total;
              prevState.listLoading = false;
              return prevState;
            },
            () => {
              delay(300).then(() => {
                this.updatePersonalization("businessUnitGeneralGrid");
              });
            }
          );
        }
      });
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization(BUSINESS_UNIT_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingBusinessUnits({ pageLoad: true, options: {} });
        } else {
          this.listBusinessUnits({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridBusinessUnits({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridBusinessUnits({
              pageLoad: true,
              options: {},
            });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingBusinessUnits({ pageLoad: true, options: {} });
        } else {
          this.listBusinessUnits({ options: {} });
        }
      });
    }
  };
  updateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.selectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.gridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.grid = {
            ...prevState.grid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridBusinessUnits({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { id, companyId } = data;
    switch (action) {
      case "view":
        this.props.router.navigate(
          getBusinessUnitDetailRoute({ id, companyId })
        );
        break;
      case "edit":
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  updatePinnedComponent = () => {
    this.setState(
      { isPinned: this.state.isPinned === "true" ? "false" : "true" },
      () => {
        this.updatePersonalization(BUSINESS_UNIT_LIST_CONTAINER);
      }
    );
  };
  checkIfDeafultListEmpty = ({ list }) => {
    const { current, grid } = this.state;

    const { pageClaims } = this.props;

    if (
      _.isEmpty(mustBeArray(list)) &&
      _.isEmpty(this.getFilters()) &&
      checkClaimsAuthorization({
        component: BUSINESS_UNIT_CREATE_SIMPLE_BUTTON,
        claims: pageClaims && pageClaims.components,
      }) &&
      Number(current) === 1 &&
      Number(grid && grid.page) === 1
    ) {
      return this.setState({ isDefaultListEmpty: true });
    }

    return this.setState({ isDefaultListEmpty: false });
  };
  getActiveFilters = () => {
    let activeFilters = [];

    let filtersApplied = this.getFilters();
    if (filtersApplied && filtersApplied.name) {
      activeFilters.push(filtersApplied.name);
    }
    if (filtersApplied && [1, 2].includes(filtersApplied.isActive)) {
      activeFilters.push(
        filtersApplied.isActive === FILTER_ACTIVE_INACTIVE_OPTIONS
          ? "Inactive"
          : "Active"
      );
    }
    if (filtersApplied && filtersApplied.countryId) {
      let country = _.find(
        mustBeArray(this.state.countryList),
        (o) => o.id === filtersApplied.countryId
      );
      activeFilters.push(country && country.value);
    }
    return activeFilters;
  };

  handleAssign = ({ event, record }: any) => {
    const { assignedPayrollManagers } = this.state;
    if (_.find(assignedPayrollManagers, (o) => o.userId === record?.id)) {
      let newManagersList = _.filter(
        assignedPayrollManagers,
        (o) => o.userId !== record.id
      );
      this.setState({
        assignedPayrollManagers: newManagersList,
      });
    } else {
      this.setState({
        assignedPayrollManagers: [
          ...assignedPayrollManagers,
          { userId: record?.id, isPrimary: false },
        ],
      });
    }
  };

  handleChangePrimary = ({ event, record }: any) => {
    this.setState((prevState) => {
      prevState.assignedPayrollManagers =
        prevState.assignedPayrollManagers.reduce((a, b) => {
          return b.userId === record.id
            ? [...a, { ...b, isPrimary: !b.isPrimary }]
            : [...a, { ...b, isPrimary: false }];
        }, []);
      return prevState;
    });
  };

  displayPayrollManagerAssignModal = ({ e, record }) => {
    const assignedPayrollManagers = mustBeArray(record.assignedUsers).reduce(
      (a, b) => {
        return a.concat({
          userId: b.id,
          isPrimary: b.assigneeType === PRIMARY_PAYROLL_MANAGER_ID,
          firstName: b.firstName,
          lastName: b.lastName,
          profilePhoto: b.profilePhoto,
        });
      },
      []
    );
    this.setState(
      {
        activeBusinessUnit: record,
        payrollProviderId: record?.payrollServiceProviderId,
        assignedPayrollManagers,
      },
      () => {
        this.setState({ showAssignPayrollManagerModal: true });
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      showAssignPayrollManagerModal: false,
      activeBusinessUnit: {},
      payrollProviderId: null,
      assignedPayrollManagers: [],
    });
  };

  handleSaveAssignedPayrollManager = () => {
    const { assignedPayrollManagers, activeBusinessUnit } = this.state;
    if (
      !_.isEmpty(assignedPayrollManagers) &&
      !_.find(assignedPayrollManagers, (o) => o.isPrimary)
    ) {
      return message.error("Please select a primary payroll manager.");
    }
    const payload = mustBeArray(assignedPayrollManagers).reduce((a, b) => {
      return a.concat({
        userId: b.userId,
        assigneeType: b.isPrimary
          ? PRIMARY_PAYROLL_MANAGER_ID
          : SECONDARY_PAYROLL_MANAGER_ID,
      });
    }, []);
    this.setState({ saveLoading: true });
    this.props
      .businessunitHandler({
        id: activeBusinessUnit?.id,
        action: "assignPayrollManager",
        cancelToken: this.signal.token,
        payload,
      })
      .then((resp) => {
        this.setState({ saveLoading: false });
        if (resp.status) {
          message.success(`Payroll Managers updated successfully.`);
          this.handleCloseModal();
          this.listBusinessUnits({ options: {}, notUpdate: true });
        } else {
          errorHandler({ response: resp });
        }
      });
  };
  render() {
    const {
      sortInfo,
      filterLoading,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      hasMoreData,
      isPinned,
      payrollProviderId,
      activeBusinessUnit,
      assignedPayrollManagers,
      paginationType,
      gridable,
      fieldOptions,
      sortingType,
      current,
      isDefaultListEmpty,
      showAssignPayrollManagerModal,
      filters,
    } = this.state;

    const { pageClaims, dashboardView, filterApplied, member } = this.props;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });
    const businessunitUrl = APIHandler.constructEndpoint({
      endpoint: "BUSINESS_UNIT_URL",
    });
    const businessunitTargetEndpoint = requestUrlBuilder(businessunitUrl, {
      q: {
        ...this.getFilters(),
      },
    });

    let activeFilters = this.getActiveFilters();
    return isDefaultListEmpty && !dashboardView ? (
      <NoRecord>
        <ContentEmpty link="/businessunit/create" page="Business Unit" />
      </NoRecord>
    ) : (
      <React.Fragment>
        {!dashboardView && (
          <div className="flex justify-between items-center flex-wrap">
            <FilterContainer
              name={BUSINESS_UNIT_FILTER_CONTAINER}
              className="flex flex-wrap items-center mb-2"
              claims={pageClaims}
            >
              <SingleFieldTextBoxFilter
                name="businessUnitNameTextboxFilter"
                claims={this.props.claims}
                placeholder="Business Units"
                value={filters?.name}
                onChange={(value) => this.handleChange("name", value)}
                onSearch={() => this.handleFilter()}
                className="w-[150px]  mb-2"
              />
              <SingleFieldDropdownFilter
                options={this.state.companyList}
                value={filters?.companyids}
                onSelect={(value) => this.handleChange("companyids", value)}
                className="w-150 mr-4 -ml-5 mb-2"
                placeholder="Company"
                hasNoClaims={true}
              />
              <CountryFilter
                selectedCountries={filters?.countryId}
                onChange={(value) => this.handleChange("countryId", value)}
                name="businessUnitCountryDropdownFilter"
                claims={this.props.claims}
                updateCountryList={(countryList) =>
                  this.setState({ countryList })
                }
                selectMultiple={true}
                className="mb-2"
              />
              {userCanAssignPayrollManager({ claims: pageClaims }) &&
              !isUserPSPManager({ member }) ? (
                <Select
                  showArrow
                  placeholder="Payroll Managers"
                  onChange={(value) =>
                    this.handleChange("assigneduserids", value)
                  }
                  mode="multiple"
                  filterOption={true}
                  value={mustBeArray(this.state.filters?.assigneduserids)}
                  className={`w-[300px] ml-2.5 mr-2.5 custom-multi-selectbox  mb-2`}
                  showSearch={true}
                  optionFilterProp={"searchby"}
                  maxTagCount="responsive"
                  autoComplete="none"
                  size="large"
                  maxTagCount="responsive"
                >
                  {mustBeArray(this.state.payrollManagers).map(
                    (s: any, key: number) => (
                      <Select.Option
                        key={key}
                        value={s.id}
                        searchby={`${s.firstName} ${s.lastName}`}
                        className="custom-multi-select-dropdown"
                      >
                        <Checkbox
                          checked={mustBeArray(
                            this.state.filters?.assigneduserids
                          ).includes(s.id)}
                          className="mr-3"
                        />
                        <Tooltip
                          title={s.firstName + " " + s.lastName}
                          placement="topRight"
                        >
                          {s.firstName} {s.lastName}
                        </Tooltip>
                      </Select.Option>
                    )
                  )}
                  {!_.isEmpty(mustBeArray(this.state.payrollManagers)) && (
                    <Select.Option
                      key="clearSelection"
                      value={CLEAR_SELECTION_VALUE}
                    >
                      Clear Selection
                    </Select.Option>
                  )}
                </Select>
              ) : (
                <React.Fragment />
              )}
              <SingleFieldDropdownFilter
                options={FILTER_ACTIVE_INACTIVE_OPTIONS}
                name="businessUnitStatusDropdownFilter"
                value={Number(
                  (this.state.filters && this.state.filters.isActive) || 0
                )}
                defaultValue={"0"}
                onSelect={(value) => this.handleChange("isActive", value)}
                claims={this.props.claims}
                valueIsNumber={true}
                className="ml-2 w-[100px]  mb-2"
              />
              <SearchIconButton
                loading={filterLoading}
                onClick={this.handleFilter}
                name="businessUnitSearchButton"
                claims={this.props.claims}
                className="ml-4 mb-2"
              />
              <LinkButton
                buttonText="Reset"
                className="color-primary hand  mb-2"
                onClick={() => this.resetFilters()}
                name="businessUnitResetFilter"
                claims={this.props.claims}
              />
            </FilterContainer>
            <div className="mb-2">
              {
                <GridTableViewOption
                  gridable={gridable}
                  handleGridChange={this.handleGridChange}
                  gridView={gridView}
                  fieldOptions={fieldOptions}
                  updateGridField={this.updateGridField}
                  gridSortInfo={this.state.grid && this.state.grid.sortInfo}
                  tableSortInfo={this.state.sortInfo}
                  updatePageSize={this.updatePageSize}
                  grid={grid}
                  per_page={per_page}
                  paginationType={paginationType}
                  sortingType={sortingType}
                />
              }
            </div>
          </div>
        )}
        <ListContainer
          name={BUSINESS_UNIT_LIST_CONTAINER}
          claims={pageClaims}
          isPinned={isPinned}
          updatePinnedComponent={this.updatePinnedComponent}
          gridable={gridable}
          gridView={gridView}
          grid={grid}
          per_page={per_page}
          GeneralTablePaginatedComponent={BusinessUnitList}
          GeneralTableScrollableComponent={ScrollableBusinessUnitTable}
          generalTableComponentName={"businessUnitGeneralTable"}
          gridComponentName={"businessUnitGeneralGrid"}
          createLinkButtonName={""}
          handleAddAction={() => {
            console.log("");
          }}
          handleGridChange={this.handleGridChange}
          fieldOptions={fieldOptions}
          updateGridField={this.updateGridField}
          sortingType={sortingType}
          paginationType={paginationType}
          list={list}
          listLoading={listLoading}
          listGrid={this.listBusinessUnitGrid}
          listScrollingGrid={this.listScrollingGridBusinessUnits}
          hasMoreData={hasMoreData}
          gridActionComponentName={"businessUnitViewDetailLink"}
          moduleType={"businessUnit"}
          handleGridAction={this.handleAction}
          listScrollingGeneralTable={this.listScrollingBusinessUnits}
          total={total}
          current={current}
          sortInfo={sortInfo}
          listGeneralTable={this.listBusinessUnits}
          tableActionComponentName={"businessUnitViewDetailLink"}
          viewComponent="businessUnitViewDetailLink"
          gridPageSize={getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          })}
          cardGrid={cardGrid}
          showAddAndGrid={this.props.dashboardView ? true : false}
          targetEndpoint={
            this.props.targetEndpoint || businessunitTargetEndpoint
          }
          hasNoDuplicateEntity={true}
          componentTitle={
            dashboardView ? this.props.componentTitle : "Business Units"
          }
          filterApplied={
            dashboardView
              ? filterApplied
              : filteredByLabel({ filters: activeFilters })
          }
          rowIdParam="id"
          dashboardView={dashboardView}
          handleAssignPayrollManager={this.displayPayrollManagerAssignModal}
          member={member}
        />
        {(userCanAssignPayrollManager({ claims: pageClaims }) ||
          isUserPSPManager({ member })) && (
          <AssignPayrollManagerModal
            visible={showAssignPayrollManagerModal}
            listPSPUsers={this.props.listPSPUsers}
            payrollProviderId={payrollProviderId}
            handleClose={this.handleCloseModal}
            activeBusinessUnit={activeBusinessUnit}
            assignedPayrollManagers={assignedPayrollManagers}
            handleAssign={this.handleAssign}
            handleChangePrimary={this.handleChangePrimary}
            handleSaveAssignedPayrollManager={
              this.handleSaveAssignedPayrollManager
            }
            saveLoading={this.state.saveLoading}
            member={member}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(BusinessUnitIndex);
