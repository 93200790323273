import { Row } from '@tanstack/react-table';
import classNames from 'classnames';

import { formatDate } from '@xemplo/date-time';
import { PayrunDetail, PayrunSteps } from '@xemplo/gp-types';

import { getMilestoneDateObj } from '../payrun-base-table.helper';
import * as S from '../payrun-base-table.styles';

export const PayDateCell = (props: Row<PayrunDetail>) => {
  const { original } = props;
  const payDate = formatDate({ value: original.payDate });
  const { dateDiff, activeStep, dueTime } = getMilestoneDateObj(original);

  if (activeStep === PayrunSteps.Complete || activeStep === 'Draft')
    return <S.PayDateCell>{payDate}</S.PayDateCell>;

  return (
    <S.PayDateCell>
      {getMilestoneText(dateDiff, dueTime)}
      <div>Pay date: {payDate}</div>
    </S.PayDateCell>
  );
};

export const getMilestoneText = (dateDiff: number, dueTime: string) => {
  const isDueToday = dateDiff === 0;
  const isDue = dateDiff > 0;
  const isDueSoon = dateDiff > 0 && dateDiff < 7;
  const isOverdue = dateDiff < 0;
  if (isDueToday) {
    return (
      <S.MilestoneDueDate className="due">
        Milestone due today at {dueTime}
      </S.MilestoneDueDate>
    );
  }
  if (isDue) {
    return (
      <S.MilestoneDueDate className={classNames({ due: isDueSoon })}>
        Milestone due in {dateDiff} days at {dueTime}
      </S.MilestoneDueDate>
    );
  }
  if (isOverdue)
    return (
      <S.MilestoneDueDate className="overdue">
        Milestone due {Math.abs(dateDiff)} days ago
      </S.MilestoneDueDate>
    );
  return null;
};
