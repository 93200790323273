import { LoaderContainer } from './loader.styles';
import { LoaderDotsProps } from './loader.types';
import { LoaderDots } from './loader-dots';

export function CenteredLoaderDots(props: Readonly<LoaderDotsProps>) {
  return (
    <LoaderContainer>
      <LoaderDots {...props} />
    </LoaderContainer>
  );
}
