//@ts-nocheck
import React from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Input, Button, Tag, Tooltip, Switch, Popconfirm } from "antd";
import {
  templateEngine,
  currencyFormater,
  getAmendmentTypeName,
  mustBeArray,
  parseItems,
  getUserRoles,
  isUndefined,
  getClaimAndStatus,
} from "./utilities";
import { getAmendmentAssignedToText } from "./validations";
import moment from "moment";
import { colors } from "../constants/colors";
import { IconWithTooltip, AppliesTo } from "../Common/ui";
import TableSteps from "../Common/tableSteps";
import { currentStatus } from "../Common/currentStatusSteps";
import numeral from "numeral";
import PayRunStatus from "../Common/payrunStatus";
import { VISIBLE_ENABLED_ID } from "../constants/defaultClaims";
import ClaimWrapper from "../Common/claimWrapper";
import { DEFAULT_FALLBACK_DATE, PEO_MODEL_ID } from "../constants/payrun";
import { goToRoute } from "../hooks";
import { Avatar } from "@xemplo/avatar";

export const tableColumnGenerator = (cols: any) => {
  return cols.map((col) => {
    let columnConfig = {
      title: col.title,
      dataIndex: col.dataIndex,
      align: col.align,
      sorter: col.sorter,
      sortOrder: col.sortOrder,
      ellipsis: col.ellipsis || false,
      className: col.className || "",
      render: (record: any) => {
        if (record?.isArray) return record.join(",");
        else return record;
      },
    };
    if (col.width) {
      columnConfig["width"] = col.width;
    }

    const navigateUser = (url: any) => {
      return goToRoute(url);
    };

    if (!isUndefined(col.link)) {
      columnConfig["render"] = (Name: any, obj: any) => {
        const linkUrl = templateEngine(col.link.url, obj);
        let claim = mustBeArray(col.claims?.components).find(
          (o) => o.name === col.component
        );
        if (
          claim &&
          Number(claim.authorizationStatusId) !== VISIBLE_ENABLED_ID
        ) {
          claim = null;
        }
        return claim || col.ignoreStatus || col.dashboardView ? (
          <span
            className="color-primary hand"
            onClick={() => navigateUser(linkUrl)}
          >
            {Name}
          </span>
        ) : (
          <span>{Name}</span>
        ); //
      };
    }
    if (col.title === "Status") {
      columnConfig["render"] = (record: any, rowValue: any) => {
        if (rowValue.amendmentStatusName) {
          switch (rowValue.amendmentStatusName) {
            case "Rejected":
              return <span className="color-danger">Rejected</span>;
            case "Approved":
              return <span className="color-success">Approved</span>;
            default:
              return record;
          }
        } else {
          return rowValue.isActive ? (
            <Tag color={colors.success} className="modifiedTag">
              Active
            </Tag>
          ) : (
            <Tag color={colors.danger} className="modifiedTag">
              Inactive
            </Tag>
          );
        }
      };
    }
    if (col.dataType === "active/inactive") {
      columnConfig["render"] = (record: any, rowValue: any) => {
        return record ? (
          <Tag color={colors.success} className="modifiedTag">
            Active
          </Tag>
        ) : (
          <Tag color={colors.danger} className="modifiedTag">
            Inactive
          </Tag>
        );
      };
    }
    if (col.title === "Requested By" || col.title === "Action By") {
      columnConfig["render"] = (record) => {
        return (
          <div>
            {record && parseItems(record) && parseItems(record).userName}
          </div>
        );
      };
    }
    if (col.title === "Applies To") {
      columnConfig["render"] = (record) => <AppliesTo assignTo={record} />;
    }

    if (col.title === "Type") {
      columnConfig["render"] = (record: any, rows: any) => {
        if (rows.payrunTypeID) {
          if (record === 1) {
            return (
              <IconWithTooltip
                letter="S"
                title="Scheduled"
                className="bg-primary"
                color="#fff"
              />
            );
          } else if (record === 2) {
            return (
              <IconWithTooltip
                letter="A"
                title="Ad-hoc"
                className="bg-success"
                color="#fff"
              />
            );
          }
        } else return record;
      };
    }

    if (col.dataType === "date") {
      columnConfig["render"] = (record) => {
        return record !== DEFAULT_FALLBACK_DATE
          ? moment(record).format("DD/MM/YYYY")
          : "";
      };
    }

    if (col.dataType === "assignTo") {
      columnConfig["render"] = (text, record) => {
        // If its all users
        if (text === "All") return text;
        // get user list
        const userList = getAmendmentAssignedToText({
          amendment: record,
          returnLength: true,
        });

        switch (userList.usersLength) {
          case 0:
            // No users were selected
            return "";
          case 1:
            // if its a just one user
            return userList.users;
          default:
            // if its multiple users
            return (
              <Tooltip title={userList.users} placement="bottom">
                Multiple
              </Tooltip>
            );
        }
      };
    }

    if (col.dataType === "taskType") {
      columnConfig["render"] = (record) => {
        return getAmendmentTypeName(record);
      };
    }

    if (col.dataType === "stages") {
      columnConfig["render"] = (record) => {
        switch (
          PayRunStatus.getStatus({
            id: record.payrunStatusID,
            userMode: "SystemAdmin",
          })
        ) {
          case "amendments":
            return (
              <Tag color={colors.neutral} className="modifiedTag">
                Amendment
              </Tag>
            );
          case "prepare":
            return (
              <Tag color={colors.primary} className="modifiedTag">
                Prepare
              </Tag>
            );
          case "approval":
            return (
              <Tag color={colors.borderOrange} className="modifiedTag">
                Approval
              </Tag>
            );
          case "payment":
            return (
              <Tag color={colors.success} className="modifiedTag">
                Payment
              </Tag>
            );
          case "invoiceUnpaid":
            return (
              <Tag color={colors.warning} className="modifiedTag">
                Invoice Unpaid
              </Tag>
            );
          case "invoicePaid":
            return (
              <Tag color={colors.neutral} className="modifiedTag">
                Invoice Paid
              </Tag>
            );
          case "process":
            return (
              <Tag color={colors.primary} className="modifiedTag">
                Process
              </Tag>
            );
          case "completed":
            return (
              <Tag color={colors.success} className="modifiedTag">
                Completed
              </Tag>
            );
          default:
            return <React.Fragment> </React.Fragment>;
        }
      };
    }

    if (col.dataType === "invoiceDueDate") {
      columnConfig["render"] = (record: any, rowKey: any) => {
        if (rowKey.invoiceDate)
          return moment(rowKey.invoiceDate)
            .add(14, "days")
            .format("DD/MM/YYYY");
        else return "N/A";
      };
    }

    if (col.dataType === "numeral") {
      columnConfig["render"] = (record) => {
        return numeral(record).format("0,0.00");
      };
    }
    //TODO: Check if this is used
    if (col.dataType === "flag") {
      columnConfig["render"] = (record: any, rowKey: any) => {
        return (
          <React.Fragment>
            <Avatar
              user={{ firstName: "", lastName: "" }}
              src={require(`../assets/countries/${rowKey.code}.svg`)}
            />{" "}
            &nbsp; {record}{" "}
          </React.Fragment>
        );
      };
    }

    if (col.dataType === "currency") {
      columnConfig["render"] = (record) => {
        return currencyFormater(record);
      };
    }

    if (col.dataType === "progressSteps") {
      columnConfig["render"] = (record: any, rowKey: any) => {
        return <TableSteps payrun={rowKey} current={currentStatus(rowKey)} />;
      };
    }

    if (col.dataType === "combinedName") {
      columnConfig["render"] = (record: any, rowKey: any) => {
        const linkUrl = templateEngine(col.link?.url, rowKey);

        return (
          <span
            className={`hand ${linkUrl ? "color-primary" : ""}`}
            onClick={() => navigateUser(linkUrl)}
          >{`${rowKey.firstName} ${rowKey.lastName}`}</span>
        );
      };
    }

    if (col.dataType === "nameAndImage") {
      columnConfig["render"] = (record: any, rowKey: any) => {
        const linkUrl = templateEngine(col.link?.url, rowKey);
        const claimsAndStatus = getClaimAndStatus({
          claims: col?.claims,
          componentName: col?.component,
          hasComponents: true,
        });
        return (
          <div className="flex items-center">
            <Avatar user={rowKey} />
            <div
              className={`hand ${linkUrl ? "color-primary" : ""} ml-2  ${
                claimsAndStatus.status === "Visible-Disabled"
                  ? "Visible-Disabled"
                  : ""
              }`}
              onClick={() => navigateUser(linkUrl)}
            >{`${rowKey.firstName} ${rowKey.lastName}`}</div>
          </div>
        );
      };
    }

    if (col.dataType === "actionedBy") {
      columnConfig["render"] = (record) => {
        try {
          let userRecord = parseItems(record?.actionedBy);
          return userRecord?.UserName ? (
            <React.Fragment>
              <span
                className="hand color-primary"
                onClick={() => col.onclick(record)}
              >
                {record?.amendmentStatusName
                  ? `${record.amendmentStatusName} by `
                  : ""}
                {userRecord?.UserName ?? ""}
              </span>{" "}
            </React.Fragment>
          ) : (
            ""
          );
        } catch (err) {
          return "";
        }
      };
    }
    if (col.dataType === "requestedBy") {
      columnConfig["render"] = (record) => {
        let userRecord = parseItems(record);
        return userRecord?.UserName ? (
          <React.Fragment>{userRecord.UserName}</React.Fragment>
        ) : (
          ""
        );
      };
    }
    if (col.dataType === "userPersona") {
      columnConfig["render"] = (record: any, rowKey: any) => {
        let roles = getUserRoles({
          member: col.member,
          userType: col.userType,
          record,
        });
        return <React.Fragment>{roles}</React.Fragment>;
      };
    }
    if (col.dataType === "toggleStatus") {
      columnConfig["render"] = (enabled: any, record: any) => {
        return (
          <ClaimWrapper
            name={col.toggleComponent}
            claims={col.claims}
            displayComponent={col.displayComponent}
          >
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              cancelText="No"
              key="decline"
              title={`Are you sure you want to ${
                enabled ? "disable" : "enable"
              } ${col.toggleTitle ? col.toggleTitle : "this user"}?`}
              onConfirm={() => col.action(record)}
              className={`${col.dashboardView ? "Visible-Disabled" : ""}`}
            >
              <div
                className={`${col.classname} ${
                  col.dashboardView ? "Visible-Disabled" : ""
                }`}
              >
                <Switch checked={enabled} disabled={col.dashboardView} />
              </div>
            </Popconfirm>
          </ClaimWrapper>
        );
      };
    }

    if (col.filterType) {
      columnConfig["filterDropdown"] = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div className="custom-filter-dropdown">
          <Input
            placeholder="Search .."
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={col.event["search"](selectedKeys, confirm)}
          />
          <Button
            type="primary"
            onClick={col.event["search"](selectedKeys, confirm)}
          >
            Filter
          </Button>

          <Button onClick={col.event["reset"](clearFilters)}>Reset</Button>
        </div>
      );

      columnConfig["filterIcon"] = (filtered: any) => {
        return <FilterOutlined />;
      };
    }
    if (col.dataType === "payrunModel") {
      columnConfig["render"] = (record) => {
        if (Number(record) === PEO_MODEL_ID)
          return (
            <Tooltip title="Professional Employer Organization (PEO)">
              <Avatar
                user={{ firstName: "P", lastName: "" }}
                className="bg-primary"
              />
            </Tooltip>
          );

        return (
          <Tooltip title="Outsource">
            <Avatar
              user={{ firstName: "P", lastName: "" }}
              className="bg-success"
            />
          </Tooltip>
        );
      };
    }

    if (col.dataType === "yesno") {
      columnConfig["render"] = (record) => {
        return <React.Fragment>{record ? "Yes" : "No"}</React.Fragment>;
      };
    }

    if (col.render) {
      columnConfig["render"] = col.render;
    }

    return columnConfig;
  });
};
