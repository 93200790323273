import React from "react";
import {
  CloseOutlined,
  DownloadOutlined,
  EditFilled,
  EyeFilled,
  MailFilled,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { getClaimAndStatus, isComponentVisible } from "../../../libs/utilities";
import { displayTableGridActionComponent } from "../../../libs";
import classNames from "classnames";
import { If } from "../../../Common/ui";

const TableActionsIcons = (props: any) => {
  const {
    record,
    handleAction,
    editComponentName,
    viewComponentName,
    sendReminderComponentName,
    deleteComponentName,
    downloadPdfComponentName,
    downloadSummaryComponentName,
    claims,
    hasNoComponents,
    showDeleteOption,
    showEditOption,
    showDownloadPdf,
    showDownloadSummary,

    downloadSummaryStatus,
    downloadPdfStatus,
    deleteStatus,
    sendReminderStatus,
    viewStatus,
    editStatus,
  } = props;

  const editClaim = getClaimAndStatus({
    claims,
    componentName: editComponentName,
    hasComponents: !hasNoComponents,
  });
  const viewClaim = getClaimAndStatus({
    claims,
    componentName: viewComponentName,
    hasComponents: !hasNoComponents,
  });
  const deleteClaim = getClaimAndStatus({
    claims,
    componentName: deleteComponentName,
    hasComponents: !hasNoComponents,
  });
  const sendReminderClaim = getClaimAndStatus({
    claims,
    componentName: sendReminderComponentName,
    hasComponents: !hasNoComponents,
  });

  const downloadPdfClaim = getClaimAndStatus({
    claims,
    componentName: downloadPdfComponentName,
    hasComponents: !hasNoComponents,
  });
  const downloadSummaryClaim = getClaimAndStatus({
    claims,
    componentName: downloadSummaryComponentName,
    hasComponents: !hasNoComponents,
  });

  return (
    <React.Fragment>
      <If
        condition={displayTableGridActionComponent({
          claim: viewClaim.claim,
          defaultDisplay: showEditOption,
        })}
        then={
          <Tooltip title="View">
            <EyeFilled
              className={`mr-3 ${viewStatus}`}
              onClick={(e) =>
                handleAction({ event: e, record, action: "view" })
              }
            />
          </Tooltip>
        }
      />

      <If
        condition={displayTableGridActionComponent({
          claim: editClaim.claim,
          defaultDisplay: showEditOption,
        })}
        then={
          <Tooltip title="Edit">
            <EditFilled
              className={`mr-3 ${classNames({
                [editStatus]: !showEditOption,
              })} color-primary`}
              onClick={(e) =>
                handleAction({ event: e, record, action: "edit" })
              }
            />
          </Tooltip>
        }
      />

      <If
        condition={displayTableGridActionComponent({
          claim: deleteClaim.claim,
          defaultDisplay: showDeleteOption,
        })}
        then={
          <Tooltip title="Delete">
            <CloseOutlined
              className={`mr-3 ${classNames({
                [deleteStatus]: !showDeleteOption,
              })}`}
              onClick={(e) =>
                handleAction({ event: e, record, action: "delete" })
              }
            />
          </Tooltip>
        }
      />

      <If
        condition={isComponentVisible({ claim: sendReminderClaim.claim })}
        then={
          <Tooltip title="Send Reminder">
            <MailFilled
              className={`mr-3 ${sendReminderStatus} color-primary`}
              onClick={(e) =>
                handleAction({ event: e, record, action: "sendReminder" })
              }
            />
          </Tooltip>
        }
      />

      <If
        condition={displayTableGridActionComponent({
          claim: downloadPdfClaim.claim,
          defaultDisplay: showDownloadPdf,
        })}
        then={
          <Tooltip title="Download Invoice">
            <DownloadOutlined
              className={`mr-3 ${classNames({
                [downloadPdfStatus]: !showDownloadPdf,
              })} color-primary`}
              onClick={(e) =>
                handleAction({ event: e, record, action: "downloadPdf" })
              }
            />
          </Tooltip>
        }
      />

      <If
        condition={displayTableGridActionComponent({
          claim: downloadSummaryClaim.claim,
          defaultDisplay: showDownloadSummary,
        })}
        then={
          <Tooltip title="Download Summary">
            <DownloadOutlined
              className={`mr-3 ${classNames({
                [downloadSummaryStatus]: !showDownloadSummary,
              })} color-primary`}
              onClick={(e) =>
                handleAction({ event: e, record, action: "downloadSummary" })
              }
            />
          </Tooltip>
        }
      />
    </React.Fragment>
  );
};
export default TableActionsIcons;
