import { useEffect, useState } from 'react';

export function useSidebarHandle() {
  const [isHandleHidden, setIsHandleHidden] = useState(true);

  useEffect(() => {
    const sidebarContainer = document.getElementById('sidebar-nav');
    if (!sidebarContainer) return;

    sidebarContainer.addEventListener('mouseenter', () => setIsHandleHidden(false));
    sidebarContainer.addEventListener('mouseleave', () => setIsHandleHidden(true));

    return () => {
      sidebarContainer.removeEventListener('mouseenter', () => setIsHandleHidden(false));
      sidebarContainer.removeEventListener('mouseleave', () => setIsHandleHidden(true));
    };
  }, []);

  return { isHandleHidden, setIsHandleHidden };
}
