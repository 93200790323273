import { useEffect, useState } from 'react';
import { Row } from '@tanstack/react-table';

import { useDrawer } from '@xemplo/drawer';
import { Amendment } from '@xemplo/gp-types';

import { PayrunAmendmentDetail } from '../payrun-amendment-detail';
import { PayrunAmendmentDetailFooter } from '../payrun-amendment-detail-footer';
import { PayrunAmendmentDetailHeader } from '../payrun-amendment-detail-header';

export const useAmendmentDetailDrawer = () => {
  const { setContent, toggle, setConfig, drawerState } = useDrawer({ isOpen: false });
  const [amendment, setAmendment] = useState<Amendment | null>(null);
  const [activeRowId, setActiveRowId] = useState<string | null>(null);

  /** Reset Drawer to empty closed state */
  const resetDrawer = () => {
    toggle(false);
    setAmendment(null);
  };

  /** Handle opening and updating drawer details when amendment is selected
   * @param row - the row that was selected
   */
  const updateDrawer = (row: Row<Amendment>) => {
    // If the row is already active, toggle the drawer and reset the active row
    if (row.id === activeRowId) {
      setActiveRowId(null);
      toggle(false);
      return;
    }
    // Otherwise, set the row as active
    setActiveRowId(row.id);
    setAmendment(row.original);
    // If the drawer is already open, leave it open
    if (drawerState.isOpen) return;
    // Otherwise, open the drawer
    toggle();
  };

  //If the amendment changes, update the drawer
  useEffect(() => {
    setContent({
      header: amendment ? (
        <PayrunAmendmentDetailHeader assignTo={amendment.assignTo} />
      ) : null,
      body: amendment ? <PayrunAmendmentDetail amendment={amendment} /> : null,
      footer: amendment ? (
        <PayrunAmendmentDetailFooter
          amendment={amendment}
          payrunId={amendment.payrunId ?? ''}
          onSuccess={resetDrawer}
        />
      ) : null,
    });
  }, [amendment, setContent]);

  return {
    toggle,
    resetDrawer,
    setContent,
    setConfig,
    drawerState,
    updateDrawer,
  };
};
