import { lazy, Suspense } from "react";
import { Loading } from "../../../../Common/ui";

const Navbar = lazy(() => import("./Navbar"));

export const LazyNavbar = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Navbar />
    </Suspense>
  );
};
