//@ts-nocheck
import React from "react";
import BasicDetails from "../../components/basicDetails";
import BusinessUnitList from "../../components/businessUnitList";
import ScrollableBusinessUnitTable from "../../components/businessunit/scrollableList";
import CompanyUserList from "../../components/users/company";
import SwiftMessage from "../../../constants/message";
import { Card, Col, message, Row, Tabs } from "antd";
import {
  errorDisplay,
  mustBeArray,
  findAuthorizationClaims,
  updateAuthorizationClaims,
  delay,
  getDeviceEnrolmentId,
  getCurrentCardGrid,
  listHasMoreData,
  getConditionalResponse,
  conditionalParameter,
  getMatchParams,
  getDefaultQueryPage,
  getScrollingListDefaultPerPage,
  errorHandler,
  getUserArea,
  getPersonalizationData,
  getOptionalParameter,
  handleAssignBusinessUnitNavigation,
  getFullName,
  isComponentVisible,
} from "../../../libs/utilities";
import axios from "axios";
import ModuleInfoCard from "../../components/common/moduleInfoCard";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import ChildTabContainer from "../../../Common/ClaimContainers/ChildTabContainer";
import GeneralContainer from "../../../Common/ClaimContainers/GeneralContainer";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import {
  MULTI_TAB_ACTIVE_KEY_INDEX,
  LIST_PAGINATION_TYPE_INDEX,
  PAGE_SIZE_INDEX,
  SORT_ORDER_INDEX,
  SORT_COLUMN_INDEX,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGE_NUMBER_INDEX,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  TABLE_PERSONALIZATIONS,
  GRID_PAGE_NUMBER_INDEX,
  GRID_ROW_INDEX,
  GRID_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  GRID_SORT_COLUMN_INDEX,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  GRID_VIEW_VALUE,
  TABLE_VIEW_VALUE,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  PAGINATION_SCROLLING,
  GRID_PERSONALIZATIONS,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  SORTING_NOT_REQUIRED,
  VISIBLE_CLAIMS_ID,
} from "../../../constants/defaultClaims";
import _ from "lodash";
import ConnectionList from "../../components/payrun/payrollprovider/ConnectionList";
import AddPayrollConnection from "../../components/payrun/payrollprovider/addPayrollConnection";
import {
  PSP_USER_GRID_OPTIONS,
  PSP_USER_FIELD_OPTIONS,
  BUSINESSUNIT_GRID_OPTIONS,
  BUSINESSUNIT_FIELD_OPTIONS,
} from "../../../constants/sortingOptions";
import ScrollableUserList from "../../components/users/ScrollableUserList";
import TabHeader from "../../../Common/tabHeader";
import ScrollableConnectionList from "../../components/payrun/payrollprovider/scrollableConnectionList";
import UserAuthenticationUpdate from "../../components/users/authenticationUpdate";
import userRoles from "../../../constants/userRoles";
import APIHandler from "../../../constants/apiUrl";
import DisablePspUserConfirmationModal from "../../components/users/DisablePspUserConfirmationModal";
import AssignPayrollManagerModal from "../../components/businessunit/AssignPayrollManagerModal";
import PayrollUserDisplayCard from "../../components/users/PayrollUserDisplayCard";
import {
  PRIMARY_PAYROLL_MANAGER_ID,
  SECONDARY_PAYROLL_MANAGER_ID,
} from "../../../constants/user";
import PayrollConnectionDisplayCard from "../../components/payrollProvider/PayrollConnectionDisplayCard";
import {
  DEFAULT_GUID,
  PAYRUN_INTEGRATION_SETTING_SUCCESS,
  AMENDMENT_INTEGRATION_SETTING_SUCCESS,
  PAYROLL_PROVIDER_MULTI_TAB_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
  REPORTS_INTEGRATION_SETTING_SUCCESS,
} from "../../../constants";
import { getBusinessUnitDetailRoute, handleRequest } from "../../../libs";
import { withRouter } from "../../../hooks";

const { TabPane } = Tabs;

type State = any;

class PayrollProviderDetail extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      businessunits: [],
      bu_loading: false,
      cu_loading: false,
      organisation: {},
      bu_visible: false,
      entity: "businessunit",
      viewRegisterUser: false,
      editMode: false,
      user: {},

      // Business unit
      businessUnitPagination: 1,
      businessUnitPerPage: 10,
      businessUnitTotal: 0,
      businessUnitList: [],
      businessUnitListLoading: false,
      businessUnitAddable: false,
      businessUnitGridable: false,
      businessUnitGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      businessUnitGridView: false,
      businessUnitSortingType: "notrequired",
      businessUnitHasMoreData: false,
      businessUnitSortInfo: {},
      businessUnitGridSortOption: BUSINESSUNIT_GRID_OPTIONS,
      businessUnitSelectedGridSort: undefined,
      businessUnitFieldOptions: BUSINESSUNIT_FIELD_OPTIONS,
      businessUnitIsPinned: "false",

      // User
      userPagination: 1,
      userPerPage: 10,
      userTotal: 0,
      userList: [],
      userListLoading: false,
      userAddable: false,
      userGridable: false,
      userGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      userGridView: false,
      userIsPinned: "false",
      userSortingType: "notrequired",
      userHasMoreData: false,
      userSortInfo: {},
      userGridSortOption: PSP_USER_GRID_OPTIONS,
      userSelectedGridSort: undefined,
      userFieldOptions: PSP_USER_FIELD_OPTIONS,

      // Connection
      connectionPagination: 1,
      connectionPerPage: 10,
      connectionTotal: 0,
      connectionList: [],
      connectionListLoading: false,
      connectionAddable: false,
      connectionGridable: false,
      connectionGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      connectionGridView: false,
      connectionIsPinned: "false",
      connectionSortingType: "notrequired",
      connectionHasMoreData: false,
      connectionSortInfo: {},
      connectionGridSortOption: [],
      connectionSelectedGridSort: undefined,
      connectionFieldOptions: [],
      action: "create",

      activeKey: "1",
      enableMFALoading: false,
      disableMFALoading: false,
      visible: false,
      assignedPayrollManagers: [],
    };
  }

  componentDidMount() {
    const { pageClaims, type, dashboardClaims, dashboardView } = this.props;
    const payrollDetailPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: dashboardClaims,
    });
    if (dashboardView) {
      switch (type) {
        case "connection":
          this.setState({
            connectionGridView: getConditionalResponse({
              condition: getOptionalParameter({
                value1: !payrollDetailPersonalizations,
                value2: !payrollDetailPersonalizations.gridView,
              }),
              resp1: false,
              resp2: true,
            }),
          });
          this.listConnections({ options: {} });
          break;
        case "businessUnit":
          break;
        case "users":
          break;
        default:
          break;
      }
    } else {
      this.props.updateHeader({
        header: {
          title: "",
          module: "Payroll Service Provider",
          enableBack: true,
          action: "",
          returnUrl: "/payrollprovider",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderDetail,
        },
      });
      const id = getMatchParams({ match: this.props.match, field: "id" });
      this.props
        .getPayrollProvider({ id, cancelToken: this.signal.token })

        .then((res) => {
          if (res.status && res.data) {
            this.props.updateHeader({
              header: {
                title: res.data.name,
                module: "Payroll Service Provider",
                enableBack: true,
                action: "",
                returnUrl: "/payrollprovider",
              },
            });
          } else {
            errorDisplay(res?.data?.validationErrors);
          }
        });
      // get active key tab
      const payrollProviderMultiTabContainer = _.find(
        mustBeArray(pageClaims?.components),
        (o) => o.name === PAYROLL_PROVIDER_MULTI_TAB_CONTAINER
      );
      const activeKey =
        payrollProviderMultiTabContainer?.personalizations?.[
          MULTI_TAB_ACTIVE_KEY_INDEX
        ]?.value;

      if (activeKey) {
        this.setState({ activeKey });
      }
      // Connection
      this.loadBusinessUnitComponent({ id });
      this.loadUserComponent({ id });
      this.loadConnectionComponent({ id });
    }
  }

  loadBusinessUnitComponent = ({ id }) => {
    // Business Unit
    const businessUnitListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payrollProviderBusinessUnitListContainer",
    });
    const businessUnitGeneralTable = findAuthorizationClaims({
      claims: businessUnitListContainer?.components,
      name: "payrollProviderBusinessUnitGeneralTable",
    });
    const businessUnitGeneralGrid = findAuthorizationClaims({
      claims: businessUnitListContainer?.components,
      name: "payrollProviderBusinessUnitGeneralGrid",
    });
    const businessUnitPaginationType =
      businessUnitListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
        ?.value;
    const businessUnitSortingType =
      businessUnitListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]
        ?.value;
    const businessUnitIsPinned =
      businessUnitListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      businessUnitPaginationType,
      businessUnitSortingType,
      businessUnitIsPinned,
      businessUnitGridable: businessUnitListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: businessUnitGeneralTable })) {
      const businessUnitPagination = businessUnitGeneralTable.personalizations
        ? !_.isNaN(
            Number(
              businessUnitGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
            )
          )
          ? Number(
              businessUnitGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
            )
          : 1
        : 1;
      const pageSize = businessUnitGeneralTable.personalizations
        ? Number(
            businessUnitGeneralTable.personalizations[PAGE_SIZE_INDEX].value ||
              20
          )
        : 20;

      const SortOrder =
        businessUnitGeneralTable?.personalizations?.[SORT_ORDER_INDEX]?.value ??
        null;
      const SortColumn =
        businessUnitGeneralTable?.personalizations?.[SORT_COLUMN_INDEX]
          ?.value ?? null;

      this.setState(
        {
          businessUnitPerPage:
            businessUnitPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
              ? Number(pageSize)
              : 10,
          businessUnitPagination,
          businessUnitSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(businessUnitListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (businessUnitPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingBusinessUnit({ pageLoad: true });
            } else {
              this.listBusinessUnits({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: businessUnitGeneralGrid })) {
      const pageNumber = businessUnitGeneralGrid.personalizations
        ? Number(
            businessUnitGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
              .value
          )
        : 1;
      const row = businessUnitGeneralGrid.personalizations
        ? Number(businessUnitGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = businessUnitGeneralGrid.personalizations
        ? Number(
            businessUnitGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
          )
        : 4;

      const SortOrder =
        businessUnitGeneralGrid?.personalizations?.[GRID_SORT_ORDER_INDEX]
          ?.value ?? null;
      const SortColumn =
        businessUnitGeneralGrid?.personalizations?.[GRID_SORT_COLUMN_INDEX]
          ?.value ?? null;

      this.setState(
        {
          businessUnitGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          businessUnitSelectedGridSort:
            _.find(
              this.state.businessUnitGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.businessUnitGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            mustBeArray(businessUnitListContainer.personalizations)?.[0]
              ?.value === GRID_VIEW_VALUE
          ) {
            this.setState({ businessUnitGridView: true });
            if (businessUnitPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridBusinessUnit({ pageLoad: true });
            } else {
              this.businessUnitFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  loadUserComponent = ({ id }) => {
    // Users
    const userListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payrollProviderUserListContainer",
    });
    const userGeneralTable = findAuthorizationClaims({
      claims: userListContainer?.components,
      name: "payrollProviderUserGeneralTable",
    });
    const userGeneralGrid = findAuthorizationClaims({
      claims: userListContainer?.components,
      name: "payrollProviderUserGeneralGrid",
    });
    const userPaginationType =
      userListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]?.value;
    const userSortingType =
      userListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]?.value;
    const userIsPinned =
      userListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      userPaginationType,
      userSortingType,
      userIsPinned,
      userGridable: userListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: userGeneralTable })) {
      const userPagination = userGeneralTable.personalizations
        ? !_.isNaN(
            Number(userGeneralTable.personalizations[PAGE_NUMBER_INDEX].value)
          )
          ? Number(userGeneralTable.personalizations[PAGE_NUMBER_INDEX].value)
          : 1
        : 1;
      const pageSize = userGeneralTable.personalizations
        ? Number(userGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20)
        : 20;
      const SortOrder = userGeneralTable.personalizations
        ? userGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = userGeneralTable.personalizations
        ? userGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          userPerPage:
            userPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
              ? Number(pageSize)
              : 10,
          userPagination,
          userSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(userListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (userPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingUser({ pageLoad: true });
            } else {
              this.listUsers({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: userGeneralGrid })) {
      const pageNumber = userGeneralGrid.personalizations
        ? Number(userGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value)
        : 1;
      const row = userGeneralGrid.personalizations
        ? Number(userGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = userGeneralGrid.personalizations
        ? Number(userGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = userGeneralGrid.personalizations
        ? userGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = userGeneralGrid.personalizations
        ? userGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          userGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          userSelectedGridSort:
            _.find(
              this.state.userGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(this.state.userGridSortOption, (o) => o.label === SortColumn)
              .id,
        },
        () => {
          if (
            mustBeArray(userListContainer?.personalizations)?.[0]?.value ===
            GRID_VIEW_VALUE
          ) {
            this.setState({ userGridView: true });
            // this.userFetchMoreData({ userIsPaginated: true });
            if (userPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridUser({ pageLoad: true });
            } else {
              this.userFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  loadConnectionComponent = ({ id }) => {
    const connectionListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payrollProviderPayrollConnectionListContainer",
    });
    const connectionGeneralTable = findAuthorizationClaims({
      claims: connectionListContainer?.components,
      name: "payrollProviderPayrollConnectionGeneralTable",
    });
    const connectionGeneralGrid = findAuthorizationClaims({
      claims: connectionListContainer?.components,
      name: "payrollProviderPayrollConnectionGeneralGrid",
    });
    const connectionPaginationType =
      connectionListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
        ?.value;
    const connectionSortingType =
      connectionListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]
        ?.value;
    const connectionIsPinned =
      connectionListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      connectionPaginationType,
      connectionSortingType,
      connectionIsPinned,
      connectionGridable: connectionListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: connectionGeneralTable })) {
      const connectionPagination = connectionGeneralTable.personalizations
        ? !_.isNaN(
            Number(
              connectionGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
            )
          )
          ? Number(
              connectionGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
            )
          : 1
        : 1;
      const pageSize = connectionGeneralTable.personalizations
        ? Number(
            connectionGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
          )
        : 20;
      const SortOrder = connectionGeneralTable.personalizations
        ? connectionGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = connectionGeneralTable.personalizations
        ? connectionGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          connectionPerPage:
            connectionPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
              ? Number(pageSize)
              : 10,
          connectionPagination,
          connectionSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(connectionListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (connectionPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingConnection({ pageLoad: true });
            } else {
              this.listConnections({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: connectionGeneralGrid })) {
      const pageNumber = connectionGeneralGrid.personalizations
        ? Number(
            connectionGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value
          )
        : 1;
      const row = connectionGeneralGrid.personalizations
        ? Number(connectionGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = connectionGeneralGrid.personalizations
        ? Number(
            connectionGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
          )
        : 4;
      const SortOrder = connectionGeneralGrid.personalizations
        ? connectionGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = connectionGeneralGrid.personalizations
        ? connectionGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          connectionGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          connectionSelectedGridSort:
            _.find(
              this.state.connectionGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.connectionGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            mustBeArray(connectionListContainer?.personalizations)?.[0]
              ?.value === GRID_VIEW_VALUE
          ) {
            this.setState({ connectionGridView: true });
            // this.connectionFetchMoreData({ connectionIsPaginated: true });
            if (connectionPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridConnection({ pageLoad: true });
            } else {
              this.connectionFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  onActionClicked = () => {
    const { match } = this.props;
    const tempHeader = {
      module: "Back to Payroll Service Provider Detail",
      enableBack: true,
      title: ``,
      entity: "payroll service provider",
      returnUrl: `/payrollprovider/${match?.params?.id}`,
    };

    this.props.updateHeader({ header: tempHeader });
    this.props.router.navigate(`/payrollprovider/${match?.params?.id}/edit`);
  };

  setActionHeader = ({ title }) => {
    if (!this.props.dashboardView) {
      this.props.updateHeader({
        header: {
          title: title,
          action: "",
          entity: "payroll service provider",
          enableBack: true,
          module: "Payroll Service Provider",
        },
      });
    }
  };

  addBusinessUnit = () => {
    const { payrollprovider } = this.props;

    this.props.updateHeader({
      header: {
        title: "New Business Unit",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BusinessUnitCreate,
        action: "",
        entity: "payroll service provider",
        enableBack: true,
        module: "Back to Payroll Service Provider Detail",
        returnUrl: `/payrollprovider/${payrollprovider?.id}`,
      },
    });
    this.props.router.navigate(
      `/payrollprovider/${payrollprovider?.id}/businessunit/create`
    );
  };

  onCancel = () => {
    this.setState({ bu_visible: false, editMode: false });
  };

  onSubmit = (value) => {
    switch (this.state.entity) {
      case "businessunit":
        return this.props.saveBusinessUnit(value).then((response) => {
          if (response.status) {
            message.success(SwiftMessage["save.success"]);
          }

          this.props.listBusinessUnits({
            id: this.props.payrollprovider.id,
            options: {},

            cancelToken: this.signal.token,
          });

          this.setState({ bu_visible: false });

          return response;
        });

      case "payrollserviceprovider":
        return this.props
          .updatePayrollServiceProvider(value)
          .then((response) => {
            if (response.status) {
              message.success(SwiftMessage["payrollprovider.update"]);

              this.props.listPayrollProviders({
                options: {},

                cancelToken: this.signal.token,
              });

              this.props
                .getPayrollProvider({
                  id: getMatchParams({ match: this.props.match, field: "id" }),

                  cancelToken: this.signal.token,
                })

                .then((res) => {
                  if (res.status && res.data) {
                    this.setActionHeader({ title: res.data.name });
                  }
                });

              this.setState({ bu_visible: false });
            } else {
              message.error(SwiftMessage["server.error"]);
            }
            return response;
          });
      default:
        console.log("unrecognized action");
    }
  };

  closeUserForm = () => {
    // this.setState({ viewRegisterUser: false, user: {}, editMode: false });
  };

  onAddUser = () => {
    this.props.updateHeader({
      header: {
        module: "Back to Payroll Service Provider Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderUserCreate,
        entity: "user",
        returnUrl: `/payrollprovider/${getMatchParams({
          match: this.props.match,
          field: "id",
        })}`,
      },
    });
    this.props.router.navigate(
      `/payrollprovider/${getMatchParams({
        match: this.props.match,
        field: "id",
      })}/user/create`
    );
  };
  handleEdit = (record) => {
    this.props.updateHeader({
      header: {
        module: "Back to Payroll Service Provider Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderUserEdit,
        entity: "user",
        returnUrl: `/payrollprovider/${getMatchParams({
          match: this.props.match,
          field: "id",
        })}`,
      },
    });
    this.props.router.navigate(
      `/user/psp/${record?.id}/edit?serviceProviderId=${getMatchParams({
        match: this.props.match,
        field: "id",
      })}`
    );
  };
  handleDelete = (record) => {
    this.props
      .handlePSPUserActions({
        id: record.id,
        action: "delete",

        cancelToken: this.signal.cancelToken,
        options: {},
      })

      .then((resp) => {
        if (resp.status) {
          message.success("User disabled successfully");
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  registerPSPUsers = (options) => {
    if (!this.state.editMode) {
      return this.props.registerPSPUsers(options).then((resp) => {
        if (resp.status) {
          this.setState({ viewRegisterUser: false });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }

        return resp;
      });
    }
    return this.props
      .handlePSPUserActions({
        ...options,
        id: this.state.user.id,
        action: "update",
        options: {},
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({ user: {}, editMode: false, viewRegisterUser: false });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
        return resp;
      });
  };

  // Business Unit
  listScrollingBusinessUnit = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.businessUnitHasMoreData) return false;

    const { id } = getMatchParams({ match: this.props.match, field: "id" });

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.businessUnitPagination = getConditionalResponse({
            condition:
              prevState.businessUnitTotal ===
              mustBeArray(prevState.businessUnitList).length,
            resp1: Number(prevState.businessUnitPagination),
            resp2: Number(prevState.businessUnitPagination) + 1,
          });
        }
        prevState.businessUnitListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = { ...this.getBusinessUnitTableSortInfo() };
        tempOptions = getConditionalResponse({
          condition: pageLoad,

          resp1: (tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.businessUnitPagination) *
              Number(this.state.businessUnitPerPage),
          }),

          resp2: {
            ...tempOptions,
            page: this.state.businessUnitPagination,
            per_page: this.state.businessUnitPerPage,
          },
        });

        this.props
          .listBusinessUnits({ options: tempOptions, id })
          .then((resp) => {
            this.setState({ businessUnitListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.businessUnitPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ businessUnitPagination: 1 }, () => {
                  this.listScrollingBusinessUnit({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    businessUnitList: getConditionalResponse({
                      condition: pageLoad,

                      resp1: (tempOptions = mustBeArray(resp.data)),

                      resp2: [
                        ...this.state.businessUnitList,
                        ...mustBeArray(resp.data),
                      ],
                    }),

                    businessUnitTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      businessUnitHasMoreData: getConditionalResponse({
                        condition:
                          this.state.businessUnitList.length < resp.total,
                        resp1: true,
                        resp2: false,
                      }),
                    });
                  }
                );
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(
              "payrollProviderBusinessUnitGeneralTable"
            );
          }
        });
      }
    );
  };
  listBusinessUnits = (businessUnitOptions) => {
    this.setState(
      (prevState) => {
        if (businessUnitOptions?.options?.page) {
          const { options } = businessUnitOptions;
          prevState.businessUnitPagination = options.page;
          prevState.businessUnitSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.businessUnitListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.businessUnitPagination))
            ? Number(this.state.businessUnitPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.businessUnitPerPage))
            ? Number(this.state.businessUnitPerPage)
            : 10,

          ...this.getBusinessUnitTableSortInfo(),
        };

        this.props
          .listBusinessUnits({
            options: newOptions,
            id: getMatchParams({ match: this.props.match, field: "id" }),
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ businessUnitListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.businessUnitPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ businessUnitPagination: 1 }, () => {
                  this.listBusinessUnits({ options: {} });
                });
              } else {
                this.setState({
                  businessUnitList: mustBeArray(resp.data),
                  businessUnitTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrollProviderBusinessUnitGeneralTable");
        });
      }
    );
  };
  getBusinessUnitTableSortInfo = () => {
    if (
      this.state.businessUnitSortInfo?.field &&
      this.state.businessUnitSortInfo?.order &&
      this.state.businessUnitSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.businessUnitSortInfo.field]:
            this.state.businessUnitSortInfo.order,
        },
      };
    } else return {};
  };
  listScrollingGridBusinessUnit = ({ options = {}, pageLoad = false }) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    if (!pageLoad && !this.state.businessUnitHasMoreData) return false;

    this.setState(
      {
        businessUnitListLoading: true,
        businessUnitGrid: {
          ...this.state.businessUnitGrid,
          page: getConditionalResponse({
            condition: !pageLoad,

            resp1:
              Number(
                conditionalParameter({
                  data: this.state.businessUnitGrid,
                  field: "page",
                })
              ) + 1,

            resp2: Number(
              conditionalParameter({
                data: this.state.businessUnitGrid,
                field: "page",
              })
            ),
          }),
        },
      },
      () => {
        const { businessUnitGrid } = this.state;
        const { page } = businessUnitGrid;

        const per_page = this.getBusinessUnitGridPageSize();

        let sortingParams = this.getBusinessUnitGridSortInfo();

        this.props
          .listBusinessUnits({
            options: {
              page: getDefaultQueryPage({ page, pageLoad }),
              per_page: getScrollingListDefaultPerPage({
                page,
                pageLoad,
                per_page,
              }),
              ...sortingParams,
            },
            id,
          })

          .then((response) => {
            this.setState({ businessUnitListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { businessUnitGrid: { ...businessUnitGrid, page: 1 } },
                  () => {
                    this.listScrollingGridBusinessUnit({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.businessUnitList = getConditionalResponse({
                      condition: pageLoad,
                      resp1: data,
                      resp2: prevState.businessUnitList.concat(data),
                    });
                    prevState.businessUnitHasMoreData = getConditionalResponse({
                      condition:
                        response.total > prevState.businessUnitList.length,
                      resp1: true,
                      resp2: false,
                    });

                    prevState.businessUnitTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        "payrollProviderBusinessUnitGeneralGrid"
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };
  businessUnitFetchMoreData = ({ isPaginated = false }) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState({ businessUnitListLoading: true });

    const { businessUnitGrid } = this.state;
    const { page } = businessUnitGrid;

    const per_page = this.getBusinessUnitGridPageSize();

    let sortingParams = this.getBusinessUnitGridSortInfo();

    this.props
      .listBusinessUnits({ options: { page, per_page, ...sortingParams }, id })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { businessUnitGrid: { ...businessUnitGrid, page: 1 } },
              () => {
                this.businessUnitFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.businessUnitList = data;
                } else {
                  prevState.businessUnitList =
                    prevState.businessUnitList.concat(data);
                  prevState.businessUnitHasMoreData = listHasMoreData({
                    data,
                    currentList: prevState.businessUnitList,
                    currentTotal: prevState.businessUnitTotal,
                  });
                  prevState.businessUnitGrid = getConditionalResponse({
                    condition: listHasMoreData({
                      data,
                      currentList: prevState.businessUnitList,
                      currentTotal: prevState.businessUnitTotal,
                    }),
                    resp1: {
                      ...prevState.businessUnitGrid,
                      page: prevState.businessUnitGrid.page + 1,
                    },
                    resp2: prevState.businessUnitGrid,
                  });
                }

                prevState.businessUnitTotal = response.total;
                prevState.businessUnitListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(
                    "payrollProviderBusinessUnitGeneralGrid"
                  );
                });
              }
            );
          }
        }
      });
  };
  listBusinessUnitGrid = (page) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState(
      (prevState) => {
        prevState.businessUnitGrid.page = page;
        prevState.businessUnitListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.businessUnitGrid?.page,

          per_page: this.getBusinessUnitGridPageSize(),

          ...this.getBusinessUnitGridSortInfo(),
        };

        this.props
          .listBusinessUnits({ options: tempOptions, id })
          .then((resp) => {
            this.setState({ businessUnitListLoading: false });

            if (resp.status) {
              if (
                Number(
                  this.state.businessUnitGrid &&
                    this.state.businessUnitGrid.page
                ) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState(
                  {
                    businessUnitGrid: {
                      ...this.state.businessUnitGrid,
                      page: 1,
                    },
                  },
                  () => {
                    this.listBusinessUnitGrid(1);
                  }
                );
              } else {
                this.setState({
                  businessUnitList: mustBeArray(resp.data),
                  businessUnitTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrollProviderBusinessUnitGeneralGrid");
        });
      }
    );
  };
  getBusinessUnitGridSortInfo = () => {
    if (
      this.state.businessUnitGrid.sortInfo?.field &&
      this.state.businessUnitGrid.sortInfo?.order &&
      this.state.businessUnitSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.businessUnitGrid.sortInfo.field]:
            this.state.businessUnitGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.businessUnitGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.businessUnitGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  handleBusinessUnitGridChange = ({ gridView, isPaginated }) => {
    if (this.state.businessUnitGridView === gridView) {
      return false;
    }

    this.setState(
      {
        businessUnitGridView: gridView,
        businessUnitList: [],
        businessUnitListLoading: true,
      },
      () => {
        this.updatePersonalization("payrollProviderBusinessUnitListContainer");
        if (!gridView) {
          if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingBusinessUnit({ pageLoad: true, options: {} });
          } else {
            this.listBusinessUnits({ options: {} });
          }
        } else {
          if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridBusinessUnit({ pageLoad: true, options: {} });
          } else {
            this.businessUnitFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };
  businessUnitUpdatePageSize = (value) => {
    if (this.state.businessUnitGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.businessUnitGrid.row = Number(row);
          prevState.businessUnitGrid.col = Number(col);
          prevState.businessUnitGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridBusinessUnit({ pageLoad: true, options: {} });
          } else {
            this.businessUnitFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ businessUnitPerPage: Number(value), current: 1 }, () => {
        if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingBusinessUnit({ pageLoad: true, options: {} });
        } else {
          this.listBusinessUnits({ options: {} });
        }
      });
    }
  };
  businessUnitUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.businessUnitSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.businessUnitGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.businessUnitGrid = {
            ...prevState.businessUnitGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridbusinessUnit({ pageLoad: true, options: {} });
        } else {
          this.businessUnitFetchMoreData({ isPaginated: true });
        }
      }
    );
  };
  handleBusinessUnitAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { id, companyId } = data;
    switch (action) {
      case "view":
        this.props.router.navigate(
          getBusinessUnitDetailRoute({ id, companyId })
        );
        break;
      case "edit":
        break;
      default:
        break;
    }
  };
  businessUnitUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.businessUnitGrid.sortInfo[field] === value) {
          prevState.businessUnitGrid.sortInfo[field] = "";
        } else {
          prevState.businessUnitGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.businessUnitFetchMoreData({ isPaginated: true });
      }
    );
  };

  getBusinessUnitGridPageSize = () => {
    const { row, col } = this.state.businessUnitGrid;

    return this.state.businessUnitPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updateBusinessUnitGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.businessUnitGrid.sortInfo[field] === value) {
          prevState.businessUnitGrid.sortInfo[field] = "";
        } else {
          prevState.businessUnitGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.businessUnitFetchMoreData({ isPaginated: true });
      }
    );
  };
  // User
  listScrollingUser = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.userHasMoreData) return false;

    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.userPagination = getConditionalResponse({
            condition:
              prevState.userTotal === mustBeArray(prevState.userList).length,
            resp1: Number(prevState.userPagination),
            resp2: Number(prevState.userPagination) + 1,
          });
        }
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          ...this.getUserTableSortInfo(),
        };
        tempOptions = getConditionalResponse({
          condition: pageLoad,

          resp1: {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.userPagination) *
              Number(this.state.userPerPage),
          },

          resp2: {
            ...tempOptions,
            page: this.state.userPagination,
            per_page: this.state.userPerPage,
          },
        });

        this.props
          .listPSPUsers({ options: tempOptions, serviceProviderId: id })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.userPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ userPagination: 1 }, () => {
                  this.listScrollingUser({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    userList: getConditionalResponse({
                      condition: pageLoad,

                      resp1: mustBeArray(resp.data),

                      resp2: [
                        ...this.state.userList,
                        ...mustBeArray(resp.data),
                      ],
                    }),

                    userTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      userHasMoreData: getConditionalResponse({
                        condition: this.state.userList.length < resp.total,
                        resp1: true,
                        resp2: false,
                      }),
                    });
                  }
                );
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });

        this.handleUpdatePersonalization({
          notUpdate,
          updateClaimName: "payrollProviderUserGeneralTable",
        });
      }
    );
  };

  handleUpdatePersonalization = ({
    notUpdate,
    updateClaimName,
    hasDelay = true,
  }) => {
    if (hasDelay) {
      delay(300).then(() => {
        if (!notUpdate) {
          this.updatePersonalization(updateClaimName);
        }
      });
    } else {
      if (!notUpdate) {
        this.updatePersonalization(updateClaimName);
      }
    }
  };

  listUsers = (userOptions) => {
    this.setState(
      (prevState) => {
        if (userOptions?.options?.page) {
          const { options } = userOptions;
          prevState.userPagination = options.page;
          prevState.userSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.userPagination))
            ? Number(this.state.userPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.userPerPage))
            ? Number(this.state.userPerPage)
            : 10,

          ...this.getUserTableSortInfo(),
        };

        this.props
          .listPSPUsers({
            options: newOptions,
            serviceProviderId: getMatchParams({
              match: this.props.match,
              field: "id",
            }),
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.userPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ userPagination: 1 }, () => {
                  this.listUsers({ options: {} });
                });
              } else {
                this.setState({
                  userList: mustBeArray(resp.data),
                  userTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrollProviderUserGeneralTable");
        });
      }
    );
  };
  getUserTableSortInfo = () => {
    if (
      this.state.userSortInfo?.field &&
      this.state.userSortInfo?.order &&
      this.state.userSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userSortInfo.field]: this.state.userSortInfo.order,
        },
      };
    } else return {};
  };
  listScrollingGridUser = ({ options = {}, pageLoad = false }) => {
    const serviceProviderId = getMatchParams({
      match: this.props.match,
      field: "id",
    });

    if (!pageLoad && !this.state.userHasMoreData) return false;

    this.setState(
      {
        userListLoading: true,
        userGrid: {
          ...this.state.userGrid,
          page: getConditionalResponse({
            condition: !pageLoad,

            resp1: Number(this.state.userGrid?.page) + 1,

            resp2: Number(this.state.userGrid?.page),
          }),
        },
      },
      () => {
        const { userGrid } = this.state;
        const { page } = userGrid;

        const per_page = this.getUserGridPageSize();

        let sortingParams = this.getUserGridSortInfo();

        this.props
          .listPSPUsers({
            options: {
              page: getConditionalResponse({
                condition: pageLoad,
                resp1: 1,
                resp2: page,
              }),
              per_page: getScrollingListDefaultPerPage({
                pageLoad,
                page,
                per_page,
              }),
              ...sortingParams,
            },
            serviceProviderId,
          })

          .then((response) => {
            this.setState({ userListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ userGrid: { ...userGrid, page: 1 } }, () => {
                  this.listScrollingGridUser({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.userList = getConditionalResponse({
                      condition: pageLoad,
                      resp1: data,
                      resp2: prevState.userList.concat(data),
                    });
                    prevState.userHasMoreData = getConditionalResponse({
                      condition: response.total > prevState.userList.length,
                      resp1: true,
                      resp2: false,
                    });

                    prevState.userTotal = response.total;
                    return prevState;
                  },
                  () => {
                    this.handleUpdatePersonalization({
                      notUpdate: false,
                      updateClaimName: "payrollProviderUserGeneralGrid",
                    });
                  }
                );
              }
            }
          });
      }
    );
  };
  userFetchMoreData = ({ isPaginated = false }) => {
    const serviceProviderId = getMatchParams({
      match: this.props.match,
      field: "id",
    });

    this.setState({ userListLoading: true });

    const { userGrid } = this.state;
    const { page } = userGrid;

    const per_page = this.getUserGridPageSize();

    let sortingParams = this.getUserGridSortInfo();

    this.props
      .listPSPUsers({
        options: {
          page,
          per_page,
          ...sortingParams,
        },
        serviceProviderId,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ userGrid: { ...userGrid, page: 1 } }, () => {
              this.userFetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.userList = data;
                } else {
                  prevState.userList = prevState.userList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.userList.length < this.state.userTotal
                  ) {
                    prevState.userHasMoreData = true;
                    prevState.userGrid = {
                      ...prevState.userGrid,
                      page: prevState.userGrid.page + 1,
                    };
                  } else {
                    prevState.userHasMoreData = false;
                  }
                }

                prevState.userTotal = response.total;
                prevState.userListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization("payrollProviderUserGeneralGrid");
                });
              }
            );
          }
        }
      });
  };

  listUserGrid = (page) => {
    const serviceProviderId = getMatchParams({
      match: this.props.match,
      field: "id",
    });

    this.setState(
      (prevState) => {
        prevState.userGrid.page = page;
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.userGrid?.page,
          per_page: this.getUserGridPageSize(),
          ...this.getUserGridSortInfo(),
        };

        this.props
          .listPSPUsers({ options: tempOptions, serviceProviderId })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              this.setState({
                userList: mustBeArray(resp.data),
                userTotal: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payrollProviderUserGeneralGrid");
        });
      }
    );
  };

  getUserGridSortInfo = () => {
    if (
      this.state.userGrid.sortInfo?.field &&
      this.state.userGrid.sortInfo?.order &&
      this.state.userSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userGrid.sortInfo.field]:
            this.state.userGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.userGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.userGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  handleUserGridChange = ({ gridView, isPaginated }) => {
    if (this.state.userGridView === gridView) {
      return false;
    }

    this.setState(
      { userGridView: gridView, userList: [], userListLoading: true },
      () => {
        this.updatePersonalization("payrollProviderUserListContainer");
        if (!gridView) {
          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingUser({ pageLoad: true, options: {} });
          } else {
            this.listUsers({ options: {} });
          }
        } else {
          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };
  userUpdatePageSize = (value) => {
    if (this.state.userGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.userGrid.row = Number(row);
          prevState.userGrid.col = Number(col);
          prevState.userGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ userPerPage: Number(value), current: 1 }, () => {
        if (this.state.userPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingUser({ pageLoad: true, options: {} });
        } else {
          this.listUsers({ options: {} });
        }
      });
    }
  };
  userUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.userSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.userGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.userGrid = {
            ...prevState.userGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.userPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridUser({ pageLoad: true, options: {} });
        } else {
          this.userFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  changeStatus = (user) => {
    user.enabled = !user.enabled;
    this.props
      .handlePSPUserActions({
        id: user && user.id,
        data: user,
        action: "status",

        userMode: this.props.userMode,
      })

      .then((resp) => {
        if (resp.status) {
          message.success(
            `User ${user.enabled ? "enabled" : "disabled"} successfully`
          );

          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        } else {
          errorHandler({
            hasValidationErrors: false,
            response: resp,
            oldModel: true,
          });
        }
      });
  };
  updateAuthentication = (user) => {
    this.props.handlePSPUserActions({
      id: user.id,
      data: { mfaEnable: user.mfaEnable ? false : true },
      action: "updatemultifactorauthentication",

      userMode: this.props.userMode,
    });
    message.info(
      `Your request to ${
        !user.mfaEnable ? "enable" : "disable"
      } multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
    );
  };

  handleUserAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/user/psp/${data && data.id}`);
        break;
      case "edit":
        this.handleEdit(data);
        break;
      case "sendReminder":
        this.sendMail({ user: data });
        break;
      case "enableDisable":
        this.changeStatus(data);
        break;
      case "enableDisableMfa":
        this.updateAuthentication(data);
        break;
      case "disableDeviceEnrolment":
      case "optionalDeviceEnrolment":
      case "mandatoryDeviceEnrolment":
        message.info(`Your request to ${
          action === "disableDeviceEnrolment"
            ? "disable"
            : action === "mandatoryDeviceEnrolment"
            ? "enable mandatory"
            : "enable optional"
        } device enrolment for the user has been submitted.
        Please refresh the screen after two minutes.`);

        this.props.handleCompanyUserActions({
          action,
          data: {
            mfaEnable: data.mfaEnable,
            requestType: "deviceEnrolment",
            deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
          },
          id: data.id,
        });
        break;
      case "assignBusinessUnit":
        handleAssignBusinessUnitNavigation({
          user: data,
          member: this.props.member,
          pspId: getMatchParams({ match: this.props.match, field: "id" }),
        });
        break;
      default:
        break;
    }
  };
  sendMail = ({ user }) => {
    this.props
      .handleCompanyUserActions({
        id: user.id,
        data: user,
        action: "sendreminder",

        userMode: this.props.userMode,
      })

      .then((resp) => {
        if (resp.status) {
          message.success(`Reminder sent successfully`);

          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        } else {
          errorHandler({ response: resp });
        }
      });
  };
  userUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.userGrid.sortInfo[field] === value) {
          prevState.userGrid.sortInfo[field] = "";
        } else {
          prevState.userGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userFetchMoreData({ isPaginated: true });
      }
    );
  };
  getUserGridPageSize = () => {
    const { row, col } = this.state.userGrid;

    return this.state.userPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };
  updateUserGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.userGrid.sortInfo[field] === value) {
          prevState.userGrid.sortInfo[field] = "";
        } else {
          prevState.userGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userFetchMoreData({ isPaginated: true });
      }
    );
  };
  handleUserCreate = () => {
    this.props.updateHeader({
      header: {
        module: "Back to Payroll Service Provider Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderUserCreate,
        entity: "user",
        returnUrl: `/payrollprovider/${getMatchParams({
          match: this.props.match,
          field: "id",
        })}`,
      },
    });
    this.props.router.navigate(
      `/payrollprovider/${getMatchParams({
        match: this.props.match,
        field: "id",
      })}/user/create`
    );
  };

  updatePersonalization = (field, update = true) => {
    const { member, pageClaims, dashboardView } = this.props;
    if (dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member?.details?.authorizationDOM
      );
      let updatedComponent = {};
      let updatedDOM = [];
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const payrollProviderBusinessUnitListContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "payrollProviderBusinessUnitListContainer",
      });
      const payrollProviderUserListContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "payrollProviderUserListContainer",
      });
      const payrollProviderPayrollConnectionListContainer =
        findAuthorizationClaims({
          claims: pageClaims?.components,
          name: "payrollProviderPayrollConnectionListContainer",
        });
      switch (field) {
        case PAYROLL_PROVIDER_MULTI_TAB_CONTAINER:
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims?.components,
            name: field,
          });
          break;
        case "payrollProviderUserGeneralTable":
          if (payrollProviderUserListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.userPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.userPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.userSortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.userSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderUserGeneralGrid":
          if (payrollProviderUserListContainer.personalizable === "true") {
            const { userGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${userGrid?.page}`;
            personalizations[GRID_ROW_INDEX].value = `${userGrid?.row}`;
            personalizations[GRID_COLUMN_INDEX].value = `${userGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              userGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              userGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderUserListContainer":
          if (payrollProviderUserListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.userGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.userPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.userSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.userIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderBusinessUnitGeneralTable":
          if (
            payrollProviderBusinessUnitListContainer.personalizable === "true"
          ) {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.businessUnitPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.businessUnitPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.businessUnitSortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.businessUnitSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderBusinessUnitGeneralGrid":
          if (
            payrollProviderBusinessUnitListContainer.personalizable === "true"
          ) {
            const { businessUnitGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${businessUnitGrid?.page}`;
            personalizations[GRID_ROW_INDEX].value = `${businessUnitGrid?.row}`;
            personalizations[
              GRID_COLUMN_INDEX
            ].value = `${businessUnitGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              businessUnitGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              businessUnitGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderBusinessUnitListContainer":
          if (
            payrollProviderBusinessUnitListContainer.personalizable === "true"
          ) {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.businessUnitGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.businessUnitPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.businessUnitSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.businessUnitIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        // Connection
        case "payrollProviderConnectionGeneralTable":
          if (
            payrollProviderPayrollConnectionListContainer.personalizable ===
            "true"
          ) {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.connectionPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.connectionPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.connectionSortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.connectionSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderConnectionGeneralGrid":
          if (
            payrollProviderPayrollConnectionListContainer.personalizable ===
            "true"
          ) {
            const { connectionGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${connectionGrid?.page}`;
            personalizations[GRID_ROW_INDEX].value = `${connectionGrid?.row}`;
            personalizations[
              GRID_COLUMN_INDEX
            ].value = `${connectionGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              connectionGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              connectionGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payrollProviderConnectionListContainer":
          if (
            payrollProviderPayrollConnectionListContainer.personalizable ===
            "true"
          ) {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.connectionGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.connectionPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.connectionSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.connectionIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };
      }

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update,
        });
      });
    } catch (err) {
      return err;
    }
  };
  handleTabChange = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.updatePersonalization(PAYROLL_PROVIDER_MULTI_TAB_CONTAINER);
    });
  };

  // Payroll Connection
  addNewPayrollConnection = () => {
    this.setState({ visible: true, title: "Add Payroll Connection" });
  };

  handleNewConnectionCancel = () => {
    this.setState({
      visible: false,
      action: "create",
      connection: {},
      title: "",
    });
  };

  addPayrollConnection = (values) => {
    const id = this.props.match?.params?.id;

    return this.props
      .addPayrollConnection({
        values: {
          ...values,
          payrollServiceProviderId: id,
          isActive: true,
        },

        action: this.state.action,
        id,

        connectionId: this.state.connection?.id,
      })

      .then((resp) => {
        if (resp.status) {
          message.success(
            `Payroll Connection ${
              this.state.action === "edit" ? "updated" : "added"
            } successfully.`
          );

          this.setState({
            visible: false,
            connectionListLoading: true,
            action: "create",
          });

          this.listConnections({ options: {} });
        } else {
          errorDisplay(resp?.data?.validationErrors, true);
        }

        return resp;
      });
  };
  editConnection = (data) => {
    this.setState(
      {
        action: "edit",
        connection: { ...data },
        title: "Edit Payroll Connection",
      },
      () => {
        this.setState({ visible: true });
      }
    );
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'listScrollingConnection'.
  listScrollingConnection = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.connectionHasMoreData) return false;

    const { id } = this.props.match && this.props.match.params;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.connectionPagination =
            prevState.connectionTotal ===
            mustBeArray(prevState.connectionList).length
              ? prevState.connectionPagination
              : Number(prevState.connectionPagination) + 1;
        }
        prevState.connectionListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = { ...this.getConnectionTableSortInfo() };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.connectionPagination) *
              Number(this.state.connectionPerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.connectionPagination,
            per_page: this.state.connectionPerPage,
          };
        }

        this.props
          .fetchPayrollConnectionName({
            options: tempOptions,
            id,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ connectionListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.connectionPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ connectionPagination: 1 }, () => {
                  this.listScrollingConnection({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    connectionList: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                          ...this.state.connectionList,
                          ...mustBeArray(resp.data),
                        ],

                    connectionTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      connectionHasMoreData:
                        this.state.connectionList.length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(
              "payrollProviderPayrollConnectionGeneralTable"
            );
          }
        });
      }
    );
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'listConnections'.
  listConnections = (connectionOptions) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState(
      (prevState) => {
        if (
          connectionOptions &&
          connectionOptions.options &&
          connectionOptions.options.page
        ) {
          const { options } = connectionOptions;
          prevState.connectionPagination = options && options.page;
          prevState.connectionSortInfo = {
            field: options && options.field,
            order: options && options.order,
          };
        }
        prevState.connectionListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.connectionPagination))
            ? Number(this.state.connectionPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.connectionPerPage))
            ? Number(this.state.connectionPerPage)
            : 10,

          ...this.getConnectionTableSortInfo(),
        };

        this.props
          .fetchPayrollConnectionName({
            options: newOptions,
            id,
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ connectionListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.connectionPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ connectionPagination: 1 }, () => {
                  this.listConnections({ options: {} });
                });
              } else {
                this.setState({
                  connectionList: mustBeArray(resp.data),
                  connectionTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(
            "payrollProviderPayrollConnectionGeneralTable"
          );
        });
      }
    );
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'getConnectionTableSortInfo'.
  getConnectionTableSortInfo = () => {
    if (
      this.state.connectionSortInfo &&
      this.state.connectionSortInfo.field &&
      this.state.connectionSortInfo.order &&
      this.state.connectionSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.connectionSortInfo.field]:
            this.state.connectionSortInfo.order,
        },
      };
    } else return {};
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'listScrollingGridConnection'.
  listScrollingGridConnection = ({ options = {}, pageLoad = false }) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    if (!pageLoad && !this.state.connectionHasMoreData) return false;

    this.setState(
      {
        connectionListLoading: true,
        connectionGrid: {
          ...this.state.connectionGrid,
          page: !pageLoad
            ? Number(
                this.state.connectionGrid && this.state.connectionGrid.page
              ) + 1
            : Number(
                this.state.connectionGrid && this.state.connectionGrid.page
              ),
        },
      },
      () => {
        const { connectionGrid } = this.state;
        const { page } = connectionGrid;

        const per_page = this.getConnectionGridPageSize();

        let sortingParams = this.getConnectionGridSortInfo();

        this.props
          .fetchPayrollConnectionName({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              ...sortingParams,
            },
            id,
            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ connectionListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { connectionGrid: { ...connectionGrid, page: 1 } },
                  () => {
                    this.listScrollingGridConnection({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.connectionList = pageLoad
                      ? data
                      : prevState.connectionList.concat(data);

                    prevState.connectionHasMoreData =
                      response.total > prevState.connectionList.length
                        ? true
                        : false;

                    prevState.connectionTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        "payrollProviderPayrollConnectionGeneralGrid"
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  connectionFetchMoreData = ({ isPaginated = false }) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState({ connectionListLoading: true });

    const { connectionGrid } = this.state;
    const { page } = connectionGrid;

    const per_page = this.getConnectionGridPageSize();

    let sortingParams = this.getConnectionGridSortInfo();

    this.props
      .fetchPayrollConnectionName({
        options: { page, per_page, ...sortingParams },
        id,
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { connectionGrid: { ...connectionGrid, page: 1 } },
              () => {
                this.connectionFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.connectionList = data;
                } else {
                  prevState.connectionList =
                    prevState.connectionList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.connectionList.length < this.state.connectionTotal
                  ) {
                    prevState.connectionHasMoreData = true;
                    prevState.connectionGrid = {
                      ...prevState.connectionGrid,
                      page: prevState.connectionGrid.page + 1,
                    };
                  } else {
                    prevState.connectionHasMoreData = false;
                  }
                }

                prevState.connectionTotal = response.total;
                prevState.connectionListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(
                    "payrollProviderPayrollConnectionGeneralGrid"
                  );
                });
              }
            );
          }
        }
      });
  };

  listConnectionGrid = (page) => {
    const id = getMatchParams({ match: this.props.match, field: "id" });

    this.setState(
      (prevState) => {
        prevState.connectionGrid.page = page;
        prevState.connectionListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.connectionGrid && this.state.connectionGrid.page,
          per_page: this.getConnectionGridPageSize(),

          ...this.getConnectionGridSortInfo(),
        };

        this.props
          .fetchPayrollConnectionName({
            options: tempOptions,
            id,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ connectionListLoading: false });

            if (resp.status) {
              this.setState({
                connectionList: mustBeArray(resp.data),
                connectionTotal: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(
            "payrollProviderPayrollConnectionGeneralGrid"
          );
        });
      }
    );
  };

  getConnectionGridSortInfo = () => {
    if (
      this.state.connectionGrid.sortInfo &&
      this.state.connectionGrid.sortInfo.field &&
      this.state.connectionGrid.sortInfo.order &&
      this.state.connectionSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.connectionGrid.sortInfo.field]:
            this.state.connectionGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.connectionGrid.sortInfo &&
      this.state.connectionGrid.sortInfo.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.connectionGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'handleConnectionGridChange'.
  handleConnectionGridChange = ({ gridView, isPaginated }) => {
    if (this.state.connectionGridView === gridView) {
      return false;
    }

    this.setState(
      {
        connectionGridView: gridView,
        connectionList: [],
        connectionListLoading: true,
      },
      () => {
        this.updatePersonalization("payrollProviderConnectionListContainer");
        if (!gridView) {
          if (this.state.ConnectionPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingConnection({ pageLoad: true, options: {} });
          } else {
            this.listConnections({ options: {} });
          }
        } else {
          if (this.state.connectionPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridConnection({ pageLoad: true, options: {} });
          } else {
            this.connectionFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  connectionUpdatePageSize = (value) => {
    if (this.state.connectionGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.connectionGrid.row = Number(row);
          prevState.connectionGrid.col = Number(col);
          prevState.connectionGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.connectionPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridConnection({ pageLoad: true, options: {} });
          } else {
            this.connectionFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ connectionPerPage: Number(value), current: 1 }, () => {
        if (this.state.connectionPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingConnection({ pageLoad: true, options: {} });
        } else {
          this.listConnections({ options: {} });
        }
      });
    }
  };

  connectionUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.connectionSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.connectionGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.connectionGrid = {
            ...prevState.connectionGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.connectionPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridConnection({ pageLoad: true, options: {} });
        } else {
          this.connectionFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleConnectionAction = ({ event, data, action, type, value }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        break;
      case "edit":
        this.setState({ connection: data, action: "edit" }, () => {
          this.setState({ visible: true });
        });
        break;
      case "integrationSettings":
        this.handleChangeSwitch({
          value,
          record: data.integrationSettings,
          type: type,
        });
        break;
      default:
        break;
    }
  };

  connectionUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.connectionGrid.sortInfo[field] === value) {
          prevState.connectionGrid.sortInfo[field] = "";
        } else {
          prevState.connectionGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.connectionFetchMoreData({ isPaginated: true });
      }
    );
  };

  getConnectionGridPageSize = () => {
    const { row, col } = this.state.connectionGrid;

    return this.state.connectionPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updateConnectionGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.connectionGrid.sortInfo[field] === value) {
          prevState.connectionGrid.sortInfo[field] = "";
        } else {
          prevState.connectionGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.connectionFetchMoreData({ isPaginated: true });
      }
    );
  };
  handleUsersMFA = ({ action }) => {
    const { id } = this.props.match && this.props.match.params;
    if (action === "enableAll") {
      this.setState({ enableMFALoading: true, disableMFADisabled: true });
    } else {
      this.setState({ disableMFALoading: true, enableMFADisabled: true });
    }
    message.info(
      `Your request to ${
        action === "enableAll" ? "enable" : "disable"
      } multi-factor authentication for all users has been submitted. Please refresh the screen after two minutes.`
    );

    this.props.handlePSPUserActions({
      action,
      id,
      data: {
        mfaEnable: action === "enableAll" ? true : false,
        requestType: "mfa",
        deviceEnrolmentEnable: -1,
      },
    });
  };

  handleUsersDeviceEnrolment = ({ action }) => {
    const { id } = this.props.match && this.props.match.params;
    message.info(`Your request to ${
      action === "mandatoryAllDeviceEnrolment"
        ? "enable mandatory"
        : action === "optionalAllDeviceEnrolment"
        ? "enable optional"
        : "disable"
    }
    device enrolment for all users has been submitted. Please refresh the screen after two minutes.`);

    this.props.handlePSPUserActions({
      action,
      id,
      data: {
        requestType: "deviceEnrolment",
        mfaEnable: true,
        deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
      },
    });
  };

  handleDisablePspCancel = () => {
    this.setState({ activeUser: {}, viewDisablePspUserConfirmation: false });
  };
  handleUserUnassignBusinessUnit = ({ member, match }) => {
    this.setState({ viewDisablePspUserConfirmation: false });
    handleUserUnassignBusinessUnitRedirection({
      user: this.state.activeUser,
      pspId: getMatchParams({ match: match, field: "id" }),
      member,
    });
  };

  handleAssign = ({ event, record }: any) => {
    const { assignedPayrollManagers } = this.state;
    if (_.find(assignedPayrollManagers, (o) => o.userId === record?.id)) {
      let newManagersList = _.filter(
        assignedPayrollManagers,
        (o) => o.userId !== record.id
      );
      this.setState({
        assignedPayrollManagers: newManagersList,
      });
    } else {
      this.setState({
        assignedPayrollManagers: [
          ...assignedPayrollManagers,
          { userId: record?.id, isPrimary: false },
        ],
      });
    }
  };

  handleChangePrimary = ({ event, record }: any) => {
    this.setState((prevState) => {
      prevState.assignedPayrollManagers =
        prevState.assignedPayrollManagers.reduce((a, b) => {
          return b.userId === record.id
            ? [...a, { ...b, isPrimary: !b.isPrimary }]
            : [...a, { ...b, isPrimary: false }];
        }, []);
      return prevState;
    });
  };

  displayPayrollManagerAssignModal = ({ e, record }) => {
    const assignedPayrollManagers = mustBeArray(record.assignedUsers).reduce(
      (a, b) => {
        return a.concat({
          userId: b.id,
          isPrimary: b.assigneeType === PRIMARY_PAYROLL_MANAGER_ID,
        });
      },
      []
    );
    this.setState(
      {
        activeBusinessUnit: record,
        payrollProviderId: record?.payrollServiceProviderId,
        assignedPayrollManagers,
      },
      () => {
        this.setState({ showAssignPayrollManagerModal: true });
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      showAssignPayrollManagerModal: false,
      activeBusinessUnit: {},
      payrollProviderId: null,
      assignedPayrollManager: {},
    });
  };

  handleSaveAssignedPayrollManager = () => {
    const { assignedPayrollManagers, activeBusinessUnit } = this.state;
    if (
      !_.isEmpty(assignedPayrollManagers) &&
      !_.find(assignedPayrollManagers, (o) => o.isPrimary)
    ) {
      return message.error("Please select a primary payroll manager.");
    }
    const payload = mustBeArray(assignedPayrollManagers).reduce((a, b) => {
      return a.concat({
        userId: b.userId,
        assigneeType: b.isPrimary
          ? PRIMARY_PAYROLL_MANAGER_ID
          : SECONDARY_PAYROLL_MANAGER_ID,
      });
    }, []);
    this.setState({ saveLoading: true });
    this.props
      .businessunitHandler({
        id: activeBusinessUnit?.id,
        action: "assignPayrollManager",
        cancelToken: this.signal.token,
        payload,
      })
      .then((resp) => {
        this.setState({ saveLoading: false });
        if (resp.status) {
          message.success(`Payroll Managers updated successfully.`);
          this.handleCloseModal();
          this.listBusinessUnits({ options: {} });
        } else {
          errorHandler({ response: resp });
        }
      });
  };

  handleChangeSwitch = ({ value, type, record }) => {
    this.setState({ connectionListLoading: true });

    const data = mustBeArray(record).find((o) => o.settingName === type);
    const payload = {
      id: data.id !== DEFAULT_GUID ? data.id : DEFAULT_GUID,
      payrollServiceProviderId: data.payrollServiceProviderId,
      payrollConnectionId: data.payrollConnectionId,
      settingName: type,
      isIntegrated: value,
    };
    let successMessage = "";
    switch (type) {
      case "payrun":
        successMessage = PAYRUN_INTEGRATION_SETTING_SUCCESS;
        break;
      case "amendment":
        successMessage = AMENDMENT_INTEGRATION_SETTING_SUCCESS;
        break;
      case "keypayreport":
        successMessage = REPORTS_INTEGRATION_SETTING_SUCCESS;
        break;
      default:
        break;
    }
    this.props.updatePSPIntegrationSettings(payload).then((resp) => {
      this.setState({ connectionListLoading: false });
      if (handleRequest({ response: resp, successMessage: successMessage }))
        this.getPayrollConnectionData();
      return;
    });
  };

  getPayrollConnectionData = () => {
    if (this.state.connectionGridView) {
      this.listConnectionGrid(this.state.connectionGrid.page);
    } else {
      this.listConnections({ options: {} });
    }
  };

  render() {
    const {
      businessUnitListLoading,
      businessUnitList,
      businessUnitPagination,
      businessUnitSortInfo,
      businessUnitIsPinned,
      businessUnitPerPage,
      businessUnitTotal,
      businessUnitGrid,
      businessUnitGridable,
      businessUnitGridView,
      businessUnitFieldOptions,
      businessUnitSortingType,
      businessUnitPaginationType,
      businessUnitHasMoreData,
      userListLoading,
      userList,
      userPagination,
      userSortInfo,
      userPerPage,
      userTotal,
      userGrid,
      userGridable,
      userGridView,
      userFieldOptions,
      userSortingType,
      userPaginationType,
      userIsPinned,
      userHasMoreData,
      connectionListLoading,
      connectionList,
      connectionPagination,
      connectionSortInfo,
      connectionPerPage,
      connectionTotal,
      connectionGrid,
      connectionGridable,
      connectionGridView,
      connectionFieldOptions,
      connectionSortingType,
      connectionPaginationType,
      connectionIsPinned,
      connectionHasMoreData,
      activeKey,
      connection,
      enableMFALoading,
      disableMFALoading,
    } = this.state;

    const { payrollprovider, pageClaims, member, dashboardView, type, match } =
      this.props;
    const id = getMatchParams({ match: this.props.match, field: "id" });
    // Multi Tab
    const payrollProviderMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === PAYROLL_PROVIDER_MULTI_TAB_CONTAINER
    );
    // Basic Details
    const payrollProviderBasicDetailTabContainer = _.find(
      mustBeArray(payrollProviderMultiTabContainer?.components),
      (o) => o.name === "payrollProviderBasicDetailTabContainer"
    );
    const payrollProviderBasicDetailChildTabContainer = _.find(
      mustBeArray(payrollProviderBasicDetailTabContainer?.components),
      (o) => o.name === "payrollProviderBasicDetailChildTabContainer"
    );
    // Business Unit
    const payrollProviderBusinessUnitTabContainer = _.find(
      mustBeArray(payrollProviderMultiTabContainer?.components),
      (o) => o.name === "payrollProviderBusinessUnitTabContainer"
    );
    const payrollProviderBusinessUnitChildTabContainer = _.find(
      mustBeArray(payrollProviderBusinessUnitTabContainer?.components),
      (o) => o.name === "payrollProviderBusinessUnitChildTabContainer"
    );

    const businessUnitCardGrid = getCurrentCardGrid({
      grid: this.state.businessUnitGrid,
    });

    // Users
    const payrollProviderUserTabContainer = _.find(
      mustBeArray(payrollProviderMultiTabContainer?.components),
      (o) => o.name === "payrollProviderUserTabContainer"
    );
    const payrollProviderUserChildTabContainer = _.find(
      mustBeArray(payrollProviderUserTabContainer?.components),
      (o) => o.name === "payrollProviderUserChildTabContainer"
    );

    const userCardGrid = getCurrentCardGrid({ grid: this.state.userGrid });

    // connections
    const payrollProviderPayrollConnectionTabContainer = _.find(
      mustBeArray(payrollProviderMultiTabContainer?.components),
      (o) => o.name === "payrollProviderPayrollConnectionTabContainer"
    );
    const payrollProviderPayrollConnectionChildTabContainer = _.find(
      mustBeArray(payrollProviderPayrollConnectionTabContainer?.components),
      (o) => o.name === "payrollProviderPayrollConnectionChildTabContainer"
    );
    let payrollConnectionUrl = {
      endpoint:
        userRoles.SystemAdminArea === getUserArea()
          ? "PAYROLL_CONNECTION_URL"
          : "PAYROLL_CONNECTION_LOOKUP_URL",
      options: { id },
    };
    payrollConnectionUrl = APIHandler.constructEndpoint(payrollConnectionUrl);

    const connectionCardGrid = getCurrentCardGrid({
      grid: this.state.connectionGrid,
    });

    const enableMfaClaims = findAuthorizationClaims({
      claims: payrollProviderUserChildTabContainer?.components,
      name: "payrollProviderUserEnableMfaAllButton",
    });
    const disableMfaClaims = findAuthorizationClaims({
      claims: payrollProviderUserChildTabContainer?.components,
      name: "payrollProviderUserDisableMfaAllButton",
    });
    const mandatoryDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: payrollProviderUserChildTabContainer?.components,
      name: "payrollProviderUserMandatoryDeviceEnrolmentAllButton",
    });
    const optionalDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: payrollProviderUserChildTabContainer?.components,
      name: "payrollProviderUserOptionalDeviceEnrolmentAllButton",
    });
    const disableDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: payrollProviderUserChildTabContainer?.components,
      name: "payrollProviderUserDisableDeviceEnrolmentAllButton",
    });
    const CustomUserActions = () => {
      return _.filter(
        [
          disableMfaClaims,
          enableMfaClaims,
          mandatoryDeviceEnrolmentClaims,
          optionalDeviceEnrolmentClaims,
          disableDeviceEnrolmentClaims,
        ],
        (o) => {
          return VISIBLE_CLAIMS_ID.includes(
            Number(o && o.authorizationStatusId)
          );
        }
      ) ? (
        <React.Fragment>
          <UserAuthenticationUpdate
            handleMFA={this.handleUsersMFA}
            handleDeviceEnrolment={this.handleUsersDeviceEnrolment}
            enableMfaClaims={enableMfaClaims}
            disableMfaClaims={disableMfaClaims}
            mandatoryDeviceEnrolmentClaims={mandatoryDeviceEnrolmentClaims}
            optionalDeviceEnrolmentClaims={optionalDeviceEnrolmentClaims}
            disableDeviceEnrolmentClaims={disableDeviceEnrolmentClaims}
            showMandatoryDeviceEnrolmentOption
            showOptionalDeviceEnrolmentOption
            showDisableDeviceEnrolmentOption
          />
        </React.Fragment>
      ) : (
        <React.Fragment />
      );
    };
    return dashboardView && type === "connection" ? (
      <ListContainer
        name="payrollProviderPayrollConnectionListContainer"
        claims={payrollProviderPayrollConnectionChildTabContainer}
        isPinned={connectionIsPinned}
        updatePinnedComponent={this.updatePinnedComponent}
        gridable={connectionGridable}
        gridView={connectionGridView}
        grid={connectionGrid}
        per_page={connectionPerPage}
        GeneralTablePaginatedComponent={ConnectionList}
        GeneralTableScrollableComponent={ScrollableConnectionList}
        DisplayCardComponent={PayrollConnectionDisplayCard}
        generalTableComponentName={
          "payrollProviderPayrollConnectionGeneralTable"
        }
        gridComponentName={"payrollProviderPayrollConnectionGeneralGrid"}
        createLinkButtonName={"payrollProviderPayrollConnectionAddButton"}
        handleAddAction={this.addNewPayrollConnection}
        handleGridChange={this.handleConnectionGridChange}
        fieldOptions={connectionFieldOptions}
        updateGridField={this.updateConnectionGridField}
        sortingType={connectionSortingType}
        paginationType={connectionPaginationType}
        list={connectionList}
        listLoading={connectionListLoading}
        listGrid={this.listConnectionGrid}
        listScrollingGrid={this.listScrollingGridConnection}
        hasMoreData={connectionHasMoreData}
        gridActionComponentName={"payrollProviderUsersViewDetailLink"}
        moduleType={"payrollConnection"}
        handleGridAction={this.handleConnectionAction}
        listScrollingGeneralTable={this.listScrollingConnection}
        total={connectionTotal}
        current={connectionPagination}
        sortInfo={connectionSortInfo}
        updatePageSize={this.connectionUpdatePageSize}
        listGeneralTable={this.listConnections}
        tableActionComponentName={"payrollProviderUsersViewDetailLink"}
        gridPageSize={this.getConnectionGridPageSize()}
        cardGrid={connectionCardGrid}
        editComponent=""
        pinnedComponentName="connectionIsPinned"
        showAddAndGrid={true}
        addModuleName="Payroll Connection"
        handleEdit={this.editConnection}
        dashboardView={true}
        componentTitle={this.props.componentTitle}
        filterApplied={this.props.filterApplied}
        targetEndpoint={this.props.targetEndpoint}
      />
    ) : dashboardView && type === "businessUnit" ? (
      <ListContainer
        name="payrollProviderBusinessUnitListContainer"
        claims={payrollProviderBusinessUnitChildTabContainer}
        isPinned={businessUnitIsPinned}
        updatePinnedComponent={this.updatePinnedComponent}
        gridable={businessUnitGridable}
        gridView={businessUnitGridView}
        grid={businessUnitGrid}
        per_page={businessUnitPerPage}
        GeneralTablePaginatedComponent={BusinessUnitList}
        GeneralTableScrollableComponent={ScrollableBusinessUnitTable}
        generalTableComponentName={"payrollProviderBusinessUnitGeneralTable"}
        gridComponentName={"payrollProviderBusinessUnitGeneralGrid"}
        createLinkButtonName={"payrollProviderBusinessUnitAddButton"}
        handleAddAction={this.addBusinessUnit}
        handleGridChange={this.handleBusinessUnitGridChange}
        fieldOptions={businessUnitFieldOptions}
        updateGridField={this.updateBusinessUnitGridField}
        sortingType={businessUnitSortingType}
        paginationType={businessUnitPaginationType}
        list={businessUnitList}
        listLoading={businessUnitListLoading}
        listGrid={this.listBusinessUnitGrid}
        listScrollingGrid={this.listScrollingGridBusinessUnit}
        hasMoreData={businessUnitHasMoreData}
        gridActionComponentName={"payrollProviderBusinessUnitViewDetailLink"}
        moduleType={"businessUnit"}
        handleGridAction={this.handleBusinessUnitAction}
        listScrollingGeneralTable={this.listScrollingBusinessUnit}
        total={businessUnitTotal}
        current={businessUnitPagination}
        sortInfo={businessUnitSortInfo}
        updatePageSize={this.businessUnitUpdatePageSize}
        listGeneralTable={({ options }) =>
          this.listBusinessUnits({
            options: {
              ...options,
            },
          })
        }
        tableActionComponentName={"payrollProviderBusinessUnitViewDetailLink"}
        gridPageSize={this.getBusinessUnitGridPageSize()}
        cardGrid={businessUnitCardGrid}
        reminderComponent="payrollProviderBusinessUnitSendReminderButton"
        editComponent="payrollProviderBusinessUnitEditIconButton"
        toggleComponent="payrollProviderBusinessUnitStatusSwitchButton"
        pinnedComponentName="businessUnitIsPinned"
        showAddAndGrid={true}
        addModuleName="Business Unit"
        dashboardView={true}
        componentTitle={this.props.componentTitle}
        filterApplied={this.props.filterApplied}
        targetEndpoint={this.props.targetEndpoint}
      />
    ) : (
      <div className="module-detail">
        <DisablePspUserConfirmationModal
          visible={this.state.viewDisablePspUserConfirmation}
          handleCancel={this.handleDisablePspCancel}
          name={getFullName({ name: this.state.activeUser })}
          handleUserUnassignBusinessUnit={this.handleUserUnassignBusinessUnit}
          match={match}
          member={member}
        />
        <ModuleInfoCard detail={payrollprovider} type="payrollProvider" />
        <MultiTabContainer
          name={PAYROLL_PROVIDER_MULTI_TAB_CONTAINER}
          claims={pageClaims}
          activeKey={activeKey}
          handleTabChange={this.handleTabChange}
        >
          {isComponentVisible({
            claim: payrollProviderBasicDetailTabContainer,
          }) && (
            <TabPane key={"1"} tab="Payroll Service Provider Details">
              <ChildTabContainer
                name="payrollProviderBasicDetailChildTabContainer"
                claims={payrollProviderBasicDetailTabContainer}
              >
                <GeneralContainer
                  name={"payrollProviderGeneralDetailContainer"}
                  claims={payrollProviderBasicDetailChildTabContainer}
                  md={12}
                  sm={24}
                  lg={6}
                >
                  <BasicDetails
                    organization={payrollprovider}
                    edit={this.onActionClicked}
                    entity="payrollprovider"
                    claimComponent="payrollProviderEdit"
                    hasNoComponents={false}
                  />
                </GeneralContainer>
                <GeneralContainer
                  name="payrollProviderProcessingSystemGeneralContainer"
                  claims={payrollProviderBasicDetailChildTabContainer}
                >
                  <Card
                    title={"Pay Run Processing"}
                    className="card card-space-24 shadow-s-0"
                  >
                    <Row>
                      <Col span={12}>
                        <h2>Supported Processing System</h2>
                        <span>{this.props.payrollprovider.payrollSystem}</span>
                      </Col>
                      <Col span={12}>
                        <h2>Supports Manual Processing</h2>
                      </Col>
                    </Row>
                  </Card>
                </GeneralContainer>
              </ChildTabContainer>
            </TabPane>
          )}
          {isComponentVisible({
            claim: payrollProviderPayrollConnectionTabContainer,
          }) && (
            <TabPane
              key={"2"}
              tab={
                <TabHeader
                  title="Payroll Connections"
                  total={connectionTotal}
                />
              }
            >
              <ChildTabContainer
                name="payrollProviderPayrollConnectionChildTabContainer"
                claims={payrollProviderPayrollConnectionTabContainer}
              >
                <ListContainer
                  name="payrollProviderPayrollConnectionListContainer"
                  claims={payrollProviderPayrollConnectionChildTabContainer}
                  isPinned={connectionIsPinned}
                  updatePinnedComponent={this.updatePinnedComponent}
                  gridable={connectionGridable}
                  gridView={connectionGridView}
                  grid={connectionGrid}
                  per_page={connectionPerPage}
                  GeneralTablePaginatedComponent={ConnectionList}
                  GeneralTableScrollableComponent={ScrollableConnectionList}
                  DisplayCardComponent={PayrollConnectionDisplayCard}
                  generalTableComponentName="payrollProviderPayrollConnectionGeneralTable"
                  gridComponentName="payrollProviderPayrollConnectionGeneralGrid"
                  payrunIntegrationSettingComponent="payrollProviderPayrunIntegrationSettingSwitch"
                  amendmentIntegrationSettingComponent="payrollProviderAmendmentIntegrationSettingSwitch"
                  reportIntegrationSettingComponent="payrollProviderReportIntegrationSettingSwitch"
                  createLinkButtonName="payrollProviderPayrollConnectionAddButton"
                  handleAddAction={this.addNewPayrollConnection}
                  handleGridChange={this.handleConnectionGridChange}
                  fieldOptions={connectionFieldOptions}
                  updateGridField={this.updateConnectionGridField}
                  sortingType={connectionSortingType}
                  paginationType={connectionPaginationType}
                  list={connectionList}
                  listLoading={connectionListLoading}
                  listGrid={this.listConnectionGrid}
                  listScrollingGrid={this.listScrollingGridConnection}
                  hasMoreData={connectionHasMoreData}
                  gridActionComponentName={"payrollProviderUsersViewDetailLink"}
                  moduleType={"payrollConnection"}
                  handleGridAction={this.handleConnectionAction}
                  listScrollingGeneralTable={this.listScrollingConnection}
                  total={connectionTotal}
                  current={connectionPagination}
                  sortInfo={connectionSortInfo}
                  updatePageSize={this.connectionUpdatePageSize}
                  listGeneralTable={this.listConnections}
                  tableActionComponentName={
                    "payrollProviderUsersViewDetailLink"
                  }
                  gridPageSize={this.getConnectionGridPageSize()}
                  cardGrid={connectionCardGrid}
                  editComponent="payrollProviderPayrollConnectionEdit"
                  pinnedComponentName="connectionIsPinned"
                  showAddAndGrid={true}
                  addModuleName="Payroll Connection"
                  handleEdit={this.editConnection}
                  targetEndpoint={payrollConnectionUrl}
                  hasNoDuplicateEntity={false}
                  componentTitle="Payroll Connections"
                  filterApplied={`Filtered by ${this.props.payrollprovider?.name}`}
                  handleIntegrationSettingChange={this.handleChangeSwitch}
                />
              </ChildTabContainer>
            </TabPane>
          )}
          {isComponentVisible({
            claim: payrollProviderBusinessUnitTabContainer,
          }) && (
            <TabPane
              key={"3"}
              tab={
                <TabHeader title="Business Units" total={businessUnitTotal} />
              }
            >
              <ChildTabContainer
                name="payrollProviderBusinessUnitChildTabContainer"
                claims={payrollProviderBusinessUnitTabContainer}
              >
                <ListContainer
                  name="payrollProviderBusinessUnitListContainer"
                  claims={payrollProviderBusinessUnitChildTabContainer}
                  isPinned={businessUnitIsPinned}
                  updatePinnedComponent={this.updatePinnedComponent}
                  gridable={businessUnitGridable}
                  gridView={businessUnitGridView}
                  grid={businessUnitGrid}
                  per_page={businessUnitPerPage}
                  GeneralTablePaginatedComponent={BusinessUnitList}
                  GeneralTableScrollableComponent={ScrollableBusinessUnitTable}
                  generalTableComponentName={
                    "payrollProviderBusinessUnitGeneralTable"
                  }
                  gridComponentName={"payrollProviderBusinessUnitGeneralGrid"}
                  createLinkButtonName={"payrollProviderBusinessUnitAddButton"}
                  handleAddAction={this.addBusinessUnit}
                  handleGridChange={this.handleBusinessUnitGridChange}
                  fieldOptions={businessUnitFieldOptions}
                  updateGridField={this.updateBusinessUnitGridField}
                  sortingType={businessUnitSortingType}
                  paginationType={businessUnitPaginationType}
                  list={businessUnitList}
                  listLoading={businessUnitListLoading}
                  listGrid={this.listBusinessUnitGrid}
                  listScrollingGrid={this.listScrollingGridBusinessUnit}
                  hasMoreData={businessUnitHasMoreData}
                  gridActionComponentName={
                    "payrollProviderBusinessUnitViewDetailLink"
                  }
                  moduleType={"businessUnit"}
                  handleGridAction={this.handleBusinessUnitAction}
                  listScrollingGeneralTable={this.listScrollingBusinessUnit}
                  total={businessUnitTotal}
                  current={businessUnitPagination}
                  sortInfo={businessUnitSortInfo}
                  updatePageSize={this.businessUnitUpdatePageSize}
                  listGeneralTable={({ options }) =>
                    this.listBusinessUnits({
                      options: {
                        ...options,
                      },
                    })
                  }
                  tableActionComponentName={
                    "payrollProviderBusinessUnitViewDetailLink"
                  }
                  gridPageSize={this.getBusinessUnitGridPageSize()}
                  cardGrid={businessUnitCardGrid}
                  reminderComponent="payrollProviderBusinessUnitSendReminderButton"
                  editComponent="payrollProviderBusinessUnitEditIconButton"
                  toggleComponent="payrollProviderBusinessUnitStatusSwitchButton"
                  pinnedComponentName="businessUnitIsPinned"
                  showAddAndGrid={true}
                  addModuleName="Business Unit"
                  handleAssignPayrollManager={
                    this.displayPayrollManagerAssignModal
                  }
                />
              </ChildTabContainer>
            </TabPane>
          )}
          {isComponentVisible({ claim: payrollProviderUserTabContainer }) && (
            <TabPane
              key={"4"}
              tab={<TabHeader title="Users" total={userTotal} />}
            >
              <ChildTabContainer
                name="payrollProviderUserChildTabContainer"
                claims={payrollProviderUserTabContainer}
              >
                <ListContainer
                  name="payrollProviderUserListContainer"
                  claims={payrollProviderUserChildTabContainer}
                  isPinned={userIsPinned}
                  updatePinnedComponent={this.updatePinnedComponent}
                  gridable={userGridable}
                  gridView={userGridView}
                  grid={userGrid}
                  per_page={userPerPage}
                  GeneralTablePaginatedComponent={CompanyUserList}
                  GeneralTableScrollableComponent={ScrollableUserList}
                  generalTableComponentName={"payrollProviderUserGeneralTable"}
                  gridComponentName={"payrollProviderUserGeneralGrid"}
                  createLinkButtonName={"payrollProviderUserAddButton"}
                  handleAddAction={this.onAddUser}
                  handleGridChange={this.handleUserGridChange}
                  fieldOptions={userFieldOptions}
                  updateGridField={this.updateUserGridField}
                  sortingType={userSortingType}
                  paginationType={userPaginationType}
                  list={userList}
                  listLoading={userListLoading}
                  listGrid={this.listUserGrid}
                  listScrollingGrid={this.listScrollingGridUser}
                  hasMoreData={userHasMoreData}
                  gridActionComponentName={"payrollProviderUsersViewDetailLink"}
                  moduleType={"user"}
                  handleGridAction={this.handleUserAction}
                  listScrollingGeneralTable={this.listScrollingUser}
                  total={userTotal}
                  current={userPagination}
                  sortInfo={userSortInfo}
                  updatePageSize={this.userUpdatePageSize}
                  listGeneralTable={({ options }) =>
                    this.listUsers({
                      options: {
                        ...options,
                      },
                    })
                  }
                  tableActionComponentName={
                    "payrollProviderUsersViewDetailLink"
                  }
                  gridPageSize={this.getUserGridPageSize()}
                  cardGrid={userCardGrid}
                  reminderComponent="payrollProviderUsersSendReminder"
                  toggleComponent="payrollProviderUserSwitchButton"
                  pinnedComponentName="userIsPinned"
                  showAddAndGrid={true}
                  addModuleName="User"
                  userType="psp"
                  handleCompanyUserActions={this.props.handlePSPUserActions}
                  editComponent="payrollProviderUsersEdit"
                  handleEdit={this.handleEdit}
                  DisplayCardComponent={PayrollUserDisplayCard}
                  member={member}
                  CustomActions={<CustomUserActions />}
                  enableMFALoading={enableMFALoading}
                  disableMFALoading={disableMFALoading}
                  enableDisableComponentNameGrid="payrollProviderUserSwitchButton"
                  enableDisableMFAComponentNameGrid="payrollProviderUserEnableDisableMfa"
                  enableDisableMFAComponentName="payrollProviderUserEnableDisableMfa"
                  pspId={getMatchParams({
                    match: this.props.match,
                    field: "id",
                  })}
                  assignBusinessUnit="payrollProviderUserAssignBusinessUnit"
                  assignBusinessUnitGrid="payrollProviderUserAssignBusinessUnit"
                />
              </ChildTabContainer>
            </TabPane>
          )}
        </MultiTabContainer>
        <AddPayrollConnection
          visible={this.state.visible}
          title={this.state.title || "Add Payroll Connection"}
          handleCancel={this.handleNewConnectionCancel}
          addPayrollConnection={this.addPayrollConnection.bind(this)}
          action={this.state.action}
          connection={connection}
        />
        <AssignPayrollManagerModal
          visible={this.state.showAssignPayrollManagerModal}
          listPSPUsers={this.props.listPSPUsers}
          payrollProviderId={this.state.payrollProviderId}
          handleClose={this.handleCloseModal}
          activeBusinessUnit={this.state.activeBusinessUnit}
          assignedPayrollManagers={this.state.assignedPayrollManagers}
          handleAssign={this.handleAssign}
          handleChangePrimary={this.handleChangePrimary}
          handleSaveAssignedPayrollManager={
            this.handleSaveAssignedPayrollManager
          }
          saveLoading={this.state.saveLoading}
          member={member}
        />
      </div>
    );
  }
}

export default withRouter(PayrollProviderDetail);
