//@ts-nocheck
import React, { Component } from "react";
import { tableColumnGenerator } from "../../../libs/table";
import { Table } from "antd";
import {
  mustBeArray,
  findScrollableTableHeight,
  getBillingProviderListColumns,
} from "../../../libs/utilities";

class ScrollableBillingServiceProviderTable extends Component {
  componentDidMount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .addEventListener("scroll", (event) => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          if (
            (currentScroll >= maxScroll || maxScroll - currentScroll < 0.5) &&
            this.props.hasMoreData
          ) {
            this.props.handleTableChange({ options: {}, pageLoad: false });
          }
        });
  }
  componentWillUnmount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .removeEventListener("scroll", () => {
          console.log("");
        });
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order === "ascend" ? "asc" : "desc",
          field:
            sorter.field === "businessUnitCount"
              ? "businessNumber"
              : sorter.field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options, pageLoad: true });
  };

  render() {
    const { sortInfo, loading, data, per_page, claims, component } = this.props;
    const colTemplate = getBillingProviderListColumns({
      sortInfo,
      claims,
      component,
    });
    const tableColumn = tableColumnGenerator(colTemplate);
    return (
      <Table
        columns={tableColumn}
        dataSource={data}
        pagination={false}
        loading={loading}
        scroll={{
          y: findScrollableTableHeight({
            per_page,
            total: mustBeArray(data).length,
          }),
        }} //{{ y: "70vh" }}
        rowKey={(record, index) => index}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default ScrollableBillingServiceProviderTable;
