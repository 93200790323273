import { PAYRUN_STATUS_IDS, PayrunSteps, StatusToStepMap } from '@xemplo/gp-types';

export function getPayrunStep(payrunStatusId: number, isInvoiceNeeded: boolean) {
  if (payrunStatusId === PAYRUN_STATUS_IDS.COMPLETED) {
    return PayrunSteps.Complete;
  }
  const step = Object.entries(StatusToStepMap(isInvoiceNeeded)).find(([_, statusIds]) =>
    statusIds.includes(payrunStatusId)
  );
  return step ? step[0] : null;
}
