import { useMemo } from 'react';
import { Row } from '@tanstack/react-table';

import { Avatar, AvatarSize, randomColor } from '@xemplo/avatar';
import { formatDate, WIDGET_DATE_FORMAT } from '@xemplo/date-time';
import { Amendment } from '@xemplo/gp-types';
import { getAssignedWorker, getAssignedWorkerDisplayName } from '@xemplo/gp-utils';
import { DawnChartPath } from '@xemplo/icons';

import * as S from './amendment-list-widget.styles';

export const AmendmentListWidgetCell = (prop: Row<Amendment>) => {
  const { original } = prop;
  const user = getAssignedWorker(original.assignTo);

  const color = useMemo(() => randomColor(), []);

  return (
    <S.CellContainer>
      {user?.[0] ? (
        <Avatar
          size={AvatarSize.s}
          color={color}
          user={user?.[0]}
          customText={user?.length > 1 ? user.length.toString() : undefined}
        />
      ) : (
        <S.LeadIconWrapper>
          <DawnChartPath />
        </S.LeadIconWrapper>
      )}
      <S.CellDetails>
        <S.NameAndType>
          <span>{getAssignedWorkerDisplayName(original.assignTo)}</span>
          <span>({original.amendmentTaskTypeName})</span>
        </S.NameAndType>

        <S.AmendmentDate>
          Modified:{' '}
          {formatDate({ value: original.lastActivityDate, format: WIDGET_DATE_FORMAT })}
        </S.AmendmentDate>
      </S.CellDetails>
      <S.AmendmentBadge
        status={original.amendmentStatusID}
        statusName={original.amendmentStatusName}
      />
    </S.CellContainer>
  );
};
