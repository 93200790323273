import { List } from "antd";
import classNames from "classnames";
import React from "react";
import { If } from "../../../../Common/ui";
import { PREVIOUS_EXTERNAL_EARLINGLINES_INDEX } from "../../../../constants";
import {
  currencyFormater,
  getDifferenceAndVariance,
  isEmpty,
  mustBeArray,
} from "../../../../libs";
import SummaryView from "../summaryView";

type Props = {
  invoice: any;
  businessunitDetails: any;
  showVariance?: boolean;
  getTotalFees: (e: any) => string | number;
  getTotalTaxFees: (e: any) => string | number;
  previousTotalFees: Array<any>;
  totalFees?: number;
};
const PayrunSummaryTotalFees = ({
  invoice,
  businessunitDetails,
  showVariance,
  getTotalFees,
  getTotalTaxFees,
  previousTotalFees,
  totalFees,
}: Props) => {
  return (
    <div
      className={classNames({
        "card-radius-bottom": Number(invoice?.totalTaxFees) === 0,
      })}
    >
      <If
        condition={!isEmpty(invoice)}
        then={
          <React.Fragment>
            <List.Item
              key={"invoice-fees"}
              className="flex-col flex-align-normal"
            >
              <SummaryView
                colOneClassName="font-bold"
                colTwoClassName="font-bold"
                colThreeClassName="font-bold"
                colFourClassName="font-bold"
                colOneValue="Total Fees"
                colTwoValue={getTotalFees({
                  previousTotalFees,
                  totalFees,
                  type: "previous",
                  invoice,
                  businessunitDetails,
                })}
                colThreeValue={getTotalFees({
                  previousTotalFees,
                  totalFees,
                  type: "current",
                  invoice,
                  businessunitDetails,
                })}
                colFourValue={getTotalFees({
                  previousTotalFees,
                  totalFees,
                  type: "variance",
                  invoice,
                  businessunitDetails,
                })}
                showVariance={showVariance}
              />

              {/* Total Fees */}
              {mustBeArray(totalFees).map((fee: any, i: any) => {
                let previousFees = previousTotalFees.find(
                  (o: any) => o.category === fee.category
                );
                return (
                  <If
                    key={`${i}total-fees`}
                    condition={fee && Number(fee.amount) !== 0}
                    then={
                      <SummaryView
                        key={i}
                        colOneClassName="font-medium"
                        colTwoClassName="font-medium"
                        colThreeClassName="font-medium"
                        colFourClassName="font-medium"
                        colOneValue={fee && fee.category}
                        colTwoValue={currencyFormater(
                          previousFees?.amount - previousFees?.taxFeeAmount,
                          businessunitDetails?.country
                        )}
                        colThreeValue={currencyFormater(
                          fee?.amount - fee?.taxFeeAmount,
                          businessunitDetails?.country
                        )}
                        colFourValue={
                          getDifferenceAndVariance(
                            previousFees?.amount - previousFees?.taxFeeAmount,
                            fee?.amount - fee?.taxFeeAmount
                          ).variance
                        }
                        showVariance={showVariance}
                        subType
                      />
                    }
                  />
                );
              })}

              {/* Invoice Fees */}
              {mustBeArray(invoice?.fees).map((fees: any, i: any) => {
                let previousInvoiceFees = invoice?.payrunFeeSummary?.[
                  PREVIOUS_EXTERNAL_EARLINGLINES_INDEX
                ]?.fees.find(
                  (o: any) =>
                    o.feeOptionDescription === fees.feeOptionDescription
                );
                return (
                  <If
                    key={`${i}invoice-fees`}
                    condition={fees && fees.amount !== 0}
                    then={
                      <SummaryView
                        key={i}
                        colOneClassName="pl-3 font-normal"
                        colTwoClassName="font-normal"
                        colThreeClassName="font-normal"
                        colFourClassName="font-normal"
                        colOneValue={fees.feeOptionDescription}
                        colTwoValue={currencyFormater(
                          previousInvoiceFees?.amount -
                            previousInvoiceFees?.taxFeeAmount,
                          businessunitDetails?.country
                        )}
                        colThreeValue={currencyFormater(
                          fees?.amount - fees?.taxFeeAmount,
                          businessunitDetails?.country
                        )}
                        colFourValue={
                          getDifferenceAndVariance(
                            previousInvoiceFees &&
                              previousInvoiceFees.amount -
                                previousInvoiceFees.taxFeeAmount,
                            fees?.amount - fees?.taxFeeAmount
                          ).variance
                        }
                        showVariance={showVariance}
                        subType
                      />
                    }
                  />
                );
              })}
            </List.Item>

            {/* Total Tax Fees */}
            <If
              condition={Number(invoice?.totalTaxFees) !== 0}
              then={
                <List.Item
                  key={"a"}
                  className={`flex-col flex-align-normal card-radius-bottom`}
                >
                  <SummaryView
                    colOneClassName="font-bold"
                    colTwoClassName="font-bold"
                    colThreeClassName="font-bold"
                    colFourClassName="font-bold"
                    colOneValue="Total GST Fees"
                    colTwoValue={getTotalTaxFees({
                      previousTotalFees,
                      totalFees,
                      type: "previous",
                      invoice,
                      businessunitDetails,
                    })}
                    colThreeValue={getTotalTaxFees({
                      previousTotalFees,
                      totalFees,
                      type: "current",
                      invoice,
                      businessunitDetails,
                    })}
                    colFourValue={getTotalTaxFees({
                      previousTotalFees,
                      totalFees,
                      type: "variance",
                      invoice,
                      businessunitDetails,
                    })}
                    showVariance={showVariance}
                  />

                  {/* Total Fees */}
                  {mustBeArray(totalFees).map((fee: any, i: any) => {
                    let previousFees = previousTotalFees.find(
                      (o) => o.category === fee.category
                    );
                    return (
                      <If
                        key={`${i}total-fee`}
                        condition={fee && Number(fee.taxFeeAmount) !== 0}
                        then={
                          <SummaryView
                            key={i}
                            colOneClassName="pl-3 font-medium"
                            colTwoClassName="font-medium"
                            colThreeClassName="font-medium"
                            colFourClassName="font-medium"
                            colOneValue={fee && fee.category}
                            colTwoValue={currencyFormater(
                              previousFees?.taxFeeAmount,
                              businessunitDetails?.country,
                              true
                            )}
                            colThreeValue={currencyFormater(
                              fee?.taxFeeAmount,
                              businessunitDetails?.country
                            )}
                            colFourValue={
                              getDifferenceAndVariance(
                                previousFees?.taxFeeAmount,
                                fee?.taxFeeAmount
                              ).variance
                            }
                            showVariance={showVariance}
                            subType
                          />
                        }
                      />
                    );
                  })}

                  {/* Fees */}
                  {mustBeArray(invoice?.fees).map((fees: any, i: any) => {
                    let previousFees = invoice?.payrunFeeSummary?.[
                      PREVIOUS_EXTERNAL_EARLINGLINES_INDEX
                    ]?.fees.find(
                      (o: any) =>
                        o.feeOptionDescription === fees.feeOptionDescription
                    );
                    return (
                      <If
                        condition={fees && Number(fees.taxFeeAmount) !== 0}
                        key={`${i}payrunSummaryFees`}
                        then={
                          <SummaryView
                            key={i}
                            colOneClassName="pl-3 font-medium"
                            colTwoClassName="font-medium"
                            colThreeClassName="font-medium"
                            colFourClassName="font-medium"
                            colOneValue={fees?.feeOptionDescription}
                            colTwoValue={currencyFormater(
                              previousFees?.taxFeeAmount,
                              businessunitDetails?.country,
                              true
                            )}
                            colThreeValue={currencyFormater(
                              fees?.taxFeeAmount,
                              businessunitDetails?.country
                            )}
                            colFourValue={
                              getDifferenceAndVariance(
                                previousFees?.taxFeeAmount,
                                fees?.taxFeeAmount
                              ).variance
                            }
                            showVariance={showVariance}
                            subType
                          />
                        }
                      />
                    );
                  })}
                </List.Item>
              }
            />
          </React.Fragment>
        }
      />
    </div>
  );
};
export default PayrunSummaryTotalFees;
