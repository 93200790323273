import { useMemo } from 'react';

import { StyledBadge, StyledPip } from './badge.style';
import { BadgeColour, BadgeProps, BadgeSize, BadgeType } from './badge.types';

export const BadgeTestIds = {
  badge: (value: string) => `badge-${value}`,
};

export function Badge(props: Readonly<BadgeProps>) {
  const { value, color, type, size, onClick, leadingIcon, ...rest } = props;

  const hasPip = useMemo(
    () => type && [BadgeType.DefaultPip, BadgeType.PillPip].includes(type),
    [type]
  );

  const styleColor = useMemo(() => {
    if (hasPip) return BadgeColour.LightWhite;
    return color ?? BadgeColour.LightGray;
  }, [hasPip, color]);

  return (
    <StyledBadge
      data-testid={BadgeTestIds.badge(value)}
      data-clickable={!!onClick}
      aria-label={value}
      color={styleColor}
      type={type ?? BadgeType.Default}
      size={size ?? BadgeSize.Medium}
      onClick={onClick}
      {...rest}
    >
      {leadingIcon}
      {hasPip && (
        <StyledPip color={color ?? BadgeColour.Blue} size={size ?? BadgeSize.Medium} />
      )}
      {value}
    </StyledBadge>
  );
}

export default Badge;
