//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Card } from "antd";

type State = any;

class ChangePassword extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
  }
  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({
      confirmDirty: this.state.confirmDirty || value ? true : false,
    });
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rule' implicitly has an 'any' type.
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rule' implicitly has an 'any' type.
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };
  submitForm = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }

      this.props.handleSubmit(values).then((resp) => {
        if (resp.status) {
          this.props.form.resetFields();
        }
      });
    });
  };
  handleCancel = () => {
    this.setState({ confirmDirty: false });

    this.props.form.resetFields();

    this.props.handleCancel();
  };
  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Card className="card-body-overflow-hidden">
        <Form onSubmit={this.submitForm} colon={false} autoComplete="off">
          <Form.Item label="Current Password">
            {getFieldDecorator("currentPassword", {
              rules: [
                {
                  required: true,
                  message: "Please input your password!",
                },
              ],
            })(<Input.Password visibilityToggle={false} />)}
          </Form.Item>
          <Form.Item label="New Password">
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: "Please enter your new password!",
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(
              <Input.Password
                onBlur={this.handleConfirmBlur}
                visibilityToggle={false}
              />
            )}
          </Form.Item>
          <Form.Item label="Confirm New Password">
            {getFieldDecorator("confirmPassword", {
              rules: [
                {
                  required: true,

                  message: "Please confirm your new password!",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input.Password
                onBlur={this.handleConfirmBlur}
                visibilityToggle={false}
              />
            )}
          </Form.Item>

          <Form.Item>
            <div className="flex justify-between flex-wrap">
              {/* @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
              <Button
                className="rounded-[20px] mr-2 mb-4"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
              {/* @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
              <Button
                className="bg-success rounded-[20px] mb-4"
                htmlType="submit"
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'loading'.
                loading={loading}
                onClick={this.submitForm}
              >
                Save Changes
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default Form.create({ name: "register" })(ChangePassword);
