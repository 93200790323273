import { Table } from '@tanstack/react-table';

import { formatNumber } from './table.helper';
import { TableProps } from './table.types';

type DefaultItemCounterProps<TData> = {
  table: Table<TData>;
  testId: string;
  className?: string;
};

export function DefaultItemCounter<TData>(props: DefaultItemCounterProps<TData>) {
  const { table, testId, className } = props;
  const { manualPagination, totalRowCount } = table.options as TableProps<TData>;
  const { pagination } = table.getState();
  const { pageIndex, pageSize } = pagination;

  const isLastPage = !table.getCanNextPage();
  const first = pageIndex * pageSize + 1;
  const defaultTotalRows = table.getCoreRowModel().rows.length;
  const rowCount = manualPagination ? totalRowCount : defaultTotalRows;
  const last = isLastPage ? rowCount : (pageIndex + 1) * pageSize;

  const formattedTotal = formatNumber({ value: rowCount });
  const formattedFirst = formatNumber({ value: first });
  const formattedLast = formatNumber({ value: last });

  return (
    <span data-testid={testId} className={className}>
      {`${formattedFirst} - ${formattedLast} of ${formattedTotal}`}
    </span>
  );
}
