//@ts-nocheck
import React, { Component } from "react";
import { Popconfirm, Button, Modal, message } from "antd";
import { errorDisplay } from "../../libs/utilities";
import SimpleButton from "../../Common/ClaimComponents/Button/SimpleButton";
import { GoBackIcon } from "../../Common/customicons";
import { colors } from "../../constants/colors";
import { withRouter } from "../../hooks";
import { LogoSize, LogoXemplo } from "@xemplo/icons";

type State = any;
type CustomNavbarProps = {
  handleCancel: () => void;
  handleSave: () => void;
  loading: boolean;
  handleDelete: () => void;
  enableBack?: boolean;
  pageTitle?: string;
  header?: any;
};

class CustomNavbar extends Component<CustomNavbarProps, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      viewModal: false,
      loadingDelete: false,
    };
  }

  closeModal = () => {
    this.setState({ viewModal: false });
  };
  handleDelete = () => {
    this.setState({ loadingDelete: true });

    this.props.handleDelete().then((resp) => {
      this.setState({ loadingDelete: false });

      if (resp.status) {
        message.success("Amendment deleted successfully");
        this.setState({ viewModal: false });
        this.props.handleCancel();
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    });
  };

  confirmCancel = () => {
    if (!this.props.editor) {
      this.setState({ viewModal: true });
    } else {
      this.props.handleCancel();
    }
  };

  render() {
    const { handleCancel, handleSave, loading, enableBack, header, router } =
      this.props;
    return (
      <React.Fragment>
        <div className="flex justify-between items-centered px-4 w-full items-center">
          <div className="flex flex-start items-centered items-center">
            <LogoXemplo size={LogoSize.s} />
            <div className="flex ml-4">
              {enableBack && (
                <span
                  className="hand mr-[5px] color-primary"
                  onClick={() => router.navigate(-1)}
                >
                  <GoBackIcon fill={colors.primary} />
                </span>
              )}
              <div className="flex flex-col">
                <span
                  className="hand color-primary"
                  onClick={() => router.navigate(-1)}
                >
                  {header?.module}
                </span>
                <div className="text-2xl font-semibold">{header?.title}</div>
              </div>
            </div>
          </div>
          <div>
            <SimpleButton
              type="primary"
              className="mx-2.5"
              loading={loading}
              onClick={() => handleSave()}
              buttonText="Save"
              ignoreStatus={true}
            />
            <SimpleButton
              className="ml-2.5 bg-e5e5"
              onClick={() => this.confirmCancel()}
              buttonText="Cancel"
              ignoreStatus={true}
            />
          </div>
        </div>
        <Modal
          width={700}
          visible={this.state.viewModal}
          title="Are you sure you want to cancel?"
          onCancel={this.closeModal}
          footer={[
            <React.Fragment key="a">
              <Popconfirm
                placement="topLeft"
                key="decline"
                title={"Are you sure you want to delete this amendment?"}
                onConfirm={this.handleDelete}
                loading={this.state.declineLoading}
              >
                <Button
                  loading={this.state.loadingDelete}
                  className="bg-danger float-left color-white rounded-[20px]"
                >
                  {" "}
                  Delete
                </Button>
              </Popconfirm>
              <div className="float-right" key="c">
                <Button
                  key="Ok"
                  onClick={handleCancel}
                  type="primary"
                  className="rounded-[20px]"
                >
                  Ok
                </Button>
                <Button
                  key="cancel"
                  onClick={this.closeModal}
                  className="rounded-[20px]"
                >
                  Cancel
                </Button>
              </div>
              <div className="clearfix" key="d" />
            </React.Fragment>,
          ]}
        >
          <div>Any changes you have made will not be saved</div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(CustomNavbar);
