import { Card } from "antd";
import React, { useState } from "react";
import CompletedGeneralAmendments from "../../components/generalAmendment/CompletedAmendments";
import PendingGeneralAmendments from "../../components/generalAmendment/PendingGeneralAmendments";

type Props = {
  pageClaims: any;
  viewAmendmentDetail: any;
};

const GeneralAmendmentPSPUserView = ({
  pageClaims,
  viewAmendmentDetail,
}: Props) => {
  const [reloadPending, setReloadPending] = useState<boolean>(false);
  const [reloadCompleted, setReloadCompleted] = useState<boolean>(false);
  return (
    <Card className="px-4 py-2">
      <PendingGeneralAmendments
        pageClaims={pageClaims}
        viewAmendmentDetail={viewAmendmentDetail}
        reloadPending={reloadPending}
        reloadCompleted={() => setReloadCompleted(!reloadCompleted)}
      />
      <div className="mt-4">
        <CompletedGeneralAmendments
          pageClaims={pageClaims}
          viewAmendmentDetail={viewAmendmentDetail}
          reloadCompleted={reloadCompleted}
          reloadPending={() => setReloadPending(!reloadPending)}
        />
      </div>
    </Card>
  );
};

export default GeneralAmendmentPSPUserView;
