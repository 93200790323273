export interface AuthorizationDOM {
  name: string;
  id: string;
  type: string;
  typeId: string;
  authorizationStatus: AuthorizationStatus;
  authorizationStatusId: string;
  personalizationSettings?: PersonalizationSettings;
  components: AuthorizationDOM[];
}

export enum AuthorizationStatus {
  Hidden = 'Hidden',
  VisibleEnabled = 'Visible - Enabled',
  VisibleDisabled = 'Visible - Disabled',
}

export interface PersonalizationSettings {
  saveSearchHistory: string;
  saveLastPageVisited: string;
  savePageSize: string;
  saveSortOrder: string;
  saveGridTableSwap: string;
}

export enum LoginActions {
  Login = 'Login',
  LoginCallback = 'LoginCallback',
}

export enum LogoutActions {
  Logout = 'Logout',
  LogoutCallback = 'LogoutCallback',
}
