import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  amendmentIsAssociatedWithPayrun,
  findAuthorizationClaims,
  getAppliedFilters,
  getFilterLabel,
  getGeneralGridPersonalizations,
  getGeneralTablePersonalizations,
  getGridRequestOptions,
  getListContainerPersonalizations,
  getTableRequestOptions,
  getTargetEndpoint,
  getUpdateAmendmentPayloads,
  handleRequest,
  isComponentVisible,
  isEmpty,
  isUndefined,
  mustBeArray,
  setListContainerPersonalizationData,
  setTablePersonalizationData,
} from "../../../../libs";
import {
  selectFilterIsloading,
  selectFilters,
  setFilterLoading,
} from "../../../../slices/generalAmendment";
import { ListPersonalizationType } from "../../../../types";
import { getGeneralAmendmentList } from "../../../actions/generalAmendment";
import DefaultListContainer from "../../common/ListContainerComponents/DefaultListContainer";
import axios from "axios";
import {
  useSliceActions,
  useSliceSelector,
} from "../../../../Common/SliceListProvider";
import {
  GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER,
  GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT,
  GENERAL_AMENDMENT_COMPLETED_GRID_COMPONENT,
  REOCCURRING_STATUS_UNAPPLIED_ID,
} from "../../../../constants";
import { updateAmendment } from "../../../actions/payrun";
import CompletedGeneralAmendmentTable from "../CompletedGeneralAmendmentTable";
import { savePersonalizations } from "../../../../User/actions/member";
import {
  selectAmendmentChangeTypeData,
  selectAmendmentRequestedByData,
  selectAmendmentTypeData,
} from "../../../../slices/lookups";

type Props = {
  pageClaims?: any;
  viewAmendmentDetail?: any;
  reloadCompleted: boolean;
  reloadPending: () => void;
};

const CompletedGeneralAmendments = ({
  pageClaims, //DOM components status
  viewAmendmentDetail,
  reloadCompleted,
  reloadPending,
}: Props) => {
  const filters = useAppSelector(selectFilters);
  const filterIsloading = useAppSelector(selectFilterIsloading);
  const dispatch: any = useAppDispatch();
  const signal = axios.CancelToken.source();
  const [targetEndpoint, setTargetEndpoint] = useState("");
  const [filterApplied, setFilterApplied] = useState("");

  // Get list state
  const completedAmendmentState = useSliceSelector();

  // Lookup data for amendment types and change types
  const changeTypeList = useAppSelector(selectAmendmentChangeTypeData);
  const amendmentTypeList = useAppSelector(selectAmendmentTypeData);
  const requestedByList = useAppSelector(selectAmendmentRequestedByData);

  // Get list actions
  const {
    initialPersonalization,
    dataLoaded,
    gridView,
    updateGrid,
    updateTable,
    setLoading,
  } = useSliceActions();

  useEffect(() => {
    if (isUndefined(completedAmendmentState)) return;
    if (!completedAmendmentState?.claimsUpdated) getInitialPersonalizations();
  }, [completedAmendmentState?.claimsUpdated]);

  // Update filter applied and target endpoint
  const updateFilterAppliedAndTargetEndpoint = () => {
    // It might change depending on the change in filters
    const appliedFilters = getAppliedFilters(filters);
    const newAppliedFilters = getFilterLabel({
      ...appliedFilters,
      changeTypeList,
      amendmentTypeList,
      requestedByList,
    });
    setFilterApplied(newAppliedFilters);

    // Get target endpoint
    // It might change depending on the change in filters, table or grid component
    const targetEndpoint = getTargetEndpoint({
      gridView: completedAmendmentState?.container?.gridView,
      table: completedAmendmentState?.table,
      grid: completedAmendmentState?.grid,
      appliedFilters: appliedFilters,
      isPending: false,
    });

    setTargetEndpoint(targetEndpoint);
  };

  // Get the initial sorting and pagination values from the claims
  const getInitialPersonalizations = () => {
    // List Personalization - grid or table view
    const listContainer = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER,
    });
    const listPersonalization: ListPersonalizationType =
      getListContainerPersonalizations(listContainer);

    // Table personalizations
    const generalTable = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT,
    });
    const tablePersonalization: any =
      getGeneralTablePersonalizations(generalTable);

    // Grid personalizations
    const generalGrid = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_COMPLETED_GRID_COMPONENT,
    });
    const gridPersonalization: any =
      getGeneralGridPersonalizations(generalGrid);

    updateInitialClaimsAndPersonalizations({
      listPersonalization,
      gridPersonalization: {
        ...gridPersonalization,
        isVisible: isComponentVisible({ claim: generalGrid }),
      },
      tablePersonalization: {
        ...tablePersonalization,
        isVisible: isComponentVisible({ claim: generalTable }),
      },
    });
  };

  // Update state for initial claims and personalizations on page load
  const updateInitialClaimsAndPersonalizations = ({
    listPersonalization,
    gridPersonalization,
    tablePersonalization,
  }: {
    listPersonalization: ListPersonalizationType;
    gridPersonalization: any;
    tablePersonalization: any;
  }) => {
    // Update initial personalization
    initialPersonalization({
      container: { ...listPersonalization },
      table: { ...tablePersonalization },
      grid: { ...gridPersonalization },
    });
  };

  // Refetch the list when user toggles between grid and table view
  useEffect(() => {
    // get list
    if (completedAmendmentState?.claimsUpdated) {
      getData();
    }
  }, [
    completedAmendmentState?.table,
    completedAmendmentState?.grid,
    completedAmendmentState?.container?.gridView,
  ]);

  useEffect(() => {
    if (filterIsloading && completedAmendmentState?.claimsUpdated) {
      // If current page is higher than 1, reset the page to 1 while filtering
      if (
        !completedAmendmentState?.container?.gridView &&
        completedAmendmentState?.table.page !== 1
      ) {
        updateTable({ value: { page: 1 } });
      } else {
        getData();
      }
    }
  }, [filterIsloading]);

  // Reload data if an amendment is applied to keypay
  useEffect(() => {
    if (completedAmendmentState?.claimsUpdated) getData();
  }, [reloadCompleted]);

  const getData = () => {
    let options: any = { page: 1, per_page: 20 };
    let userHasAccess: boolean = false;
    updateFilterAppliedAndTargetEndpoint();
    // If grid view and component is visible to the user fetch data
    if (
      completedAmendmentState?.container?.gridView &&
      completedAmendmentState?.grid?.isVisible
    ) {
      // Get grid current personalization settings
      options = getGridRequestOptions({ grid: completedAmendmentState?.grid });
      userHasAccess = true;
    }
    // If table view and component is visible to the user fetch data
    if (
      !completedAmendmentState?.container?.gridView &&
      completedAmendmentState?.table?.isVisible
    ) {
      // Get Table current personalization settings
      options = getTableRequestOptions(completedAmendmentState?.table);
      userHasAccess = true;
    }

    const appliedFilters = getAppliedFilters(filters);
    // Add applied filters
    options.q = { ...appliedFilters };

    // Call the endpoint if user has access to view the component/data
    if (userHasAccess) {
      setLoading({ loading: true });
      dispatch(
        getGeneralAmendmentList({
          options: { ...options, q: { ...options.q, isPending: false } },
          cancelToken: signal.token,
        })
      ).then((response: any) => {
        setLoading({ loading: false });
        dispatch(setFilterLoading(false));
        if (handleRequest({ response, hasValidationErrors: true })) {
          handleDataAndPageVerification(
            mustBeArray(response?.data),
            response?.total
          );
        }
      });
    }
  };

  const updateTablePersonalization = () => {
    let personalizations = setTablePersonalizationData({
      pageNumber: completedAmendmentState?.table?.page,
      pageSize: completedAmendmentState?.table?.perPage,
      sortColumn: completedAmendmentState?.table?.sortInfo?.field,
      sortOrder: completedAmendmentState?.table?.sortInfo?.order,
    });

    let updatedComponent = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT,
    });
    // Breaks the DOM if updatedComponent doesn't exist or if there is an error
    if (updatedComponent?.id) {
      updateLocalDOM({
        personalizations,
        name: updatedComponent.name,
        id: updatedComponent.id,
      });
    }
  };

  const updateListContainerPersonalization = () => {
    let personalizations = setListContainerPersonalizationData({
      gridView: completedAmendmentState?.container?.gridView,
      isPinned: false,
    });

    let updatedComponent = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER,
    });
    // Breaks the DOM if updatedComponent doesn't exist or if there is an error
    if (updatedComponent?.id) {
      updateLocalDOM({
        personalizations,
        name: updatedComponent.name,
        id: updatedComponent.id,
      });
    }
  };

  // Update local dom
  const updateLocalDOM = ({ personalizations, name, id }: any) => {
    dispatch(
      savePersonalizations({
        personalizations: [
          {
            value: personalizations,
            label: "personalizations",
            name,
            id,
          },
        ],
        update: true,
      })
    );
  };

  // Check if the user is not in the first page and the data is empty - change the page to 1 and re fetch the data
  const handleDataAndPageVerification = (data: any, total: number) => {
    if (isEmpty(data)) {
      if (
        completedAmendmentState?.container?.gridView &&
        completedAmendmentState?.grid?.page !== 1
      ) {
        // update the current page to 1
        return updateGrid({ value: { page: 1 } });
      }
      if (
        !completedAmendmentState?.container?.gridView &&
        completedAmendmentState?.table?.page !== 1
      ) {
        // update the current page to 1
        return updateTable({ value: { page: 1 } });
      }
    }
    dataLoaded({ data, total });
    updateTablePersonalization();
  };

  // LIST CONTAINER
  const handleGridViewChange = (view: boolean) => {
    if (completedAmendmentState?.container?.gridView === view) return;
    gridView({ value: view });
    updateListContainerPersonalization();
  };

  // TABLE
  // On sorting Change
  const handleTableChange = (options: {
    sortInfo: any;
    page: number;
    perPage: number;
  }) => {
    updateTable({ value: options });
  };

  // GRID
  const handleGridChange = (options: {
    row: number;
    col: number;
    page: number;
    sortInfo: any;
  }) => {
    updateGrid({ value: options });
  };

  // Handle action events like view, edit and delete
  const handleGridTableAction = ({
    action,
    record,
  }: {
    action: string;
    record?: any;
  }) => {
    switch (action) {
      case "unapply":
        return handleUnappliedInKeyPay(record);
      case "view":
        viewAmendmentDetail(record);
        break;
      default:
        break;
    }
  };

  const handleUnappliedInKeyPay = (record: any) => {
    dispatch(
      updateAmendment({
        id: record?.amendmentId,
        values: {
          ...getUpdateAmendmentPayloads(record),
          amendmentStatusInPPSID: REOCCURRING_STATUS_UNAPPLIED_ID,
        },
        payrunId: amendmentIsAssociatedWithPayrun({ amendment: record })
          ? record?.payrunId
          : null,
      })
    ).then((response: any) => {
      if (
        handleRequest({
          response,
          successMessage: "Amendment unapplied successfully.",
          hasValidationErrors: true,
        })
      ) {
        reloadPending();
        getData();
      }
    });
  };

  return (
    <>
      <div className="bold text-md mt-2 mb-4">Completed</div>
      <DefaultListContainer
        pageClaims={pageClaims}
        name={GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER}
        container={completedAmendmentState?.container}
        grid={completedAmendmentState?.grid}
        table={completedAmendmentState?.table}
        listLoading={completedAmendmentState?.loading}
        filterApplied={filterApplied}
        handleAction={handleGridTableAction}
        handleGridChange={handleGridChange}
        handleGridViewChange={handleGridViewChange}
        handleTableChange={handleTableChange}
        tableComponentName={GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT}
        gridComponentName={GENERAL_AMENDMENT_COMPLETED_GRID_COMPONENT}
        total={completedAmendmentState?.total}
        componentTitle="Completed Amendments"
        GeneralTablePaginatedComponent={CompletedGeneralAmendmentTable}
        targetEndpoint={targetEndpoint}
        rowIdParam="amendmentId"
        data={completedAmendmentState?.data}
        hasNoDuplicateEntity
      />
    </>
  );
};

export default CompletedGeneralAmendments;
