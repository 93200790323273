import { ChangeEvent, useRef, useState } from 'react';
import { useTextField } from '@react-aria/textfield';
import classNames from 'classnames';

import { DawnTriangleAlert } from '@xemplo/icons';
import {
  InputFieldSize,
  LabelHelperText,
  MaybeErrorOrDescription,
} from '@xemplo/input-utils';

import * as S from './text-area.styles';
import { TextAreaProps } from './text-area.types';
export const TextAreaTestId = {
  container: (testId?: string) => `textarea-container-${testId}`,
  input: (testId?: string) => `textarea-input-${testId}`,
  label: (testId?: string) => `textarea-label-${testId}`,
  labelHelperText: (testId?: string) => `textarea-label-helper-text-${testId}`,
};
/**
 * @deprecated Use `TextAreaV2` from `@xemplo/text-area` instead. Deprecated since FD-1230
 */
export const TextArea = (props: TextAreaProps) => {
  const {
    width,
    label,
    inputSize = InputFieldSize.Standard,
    leadingIcon,
    trailingIcon,
    description,
    isDisabled,
    isReadOnly,
    testId,
    onChange,
    value,
    defaultValue,
    rows,
    stretch,
    maxHeight,
    isRequired,
    formSubmitting,
  } = props;

  let { errorMessage } = props;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(
    { ...props, inputElementType: 'textarea' },
    inputRef
  );
  const [inputValue, setInputValue] = useState(value ?? defaultValue);

  if (inputRef.current) {
    inputRef.current.style.maxHeight = maxHeight ?? '200px';
  }

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    stretch && resizeTextarea();
    setInputValue(e.target?.value);
    inputProps?.onChange?.(e);
    onChange?.(e.target?.value);
  };

  const resizeTextarea = () => {
    const textarea = inputRef.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const isValid = () => {
    if (!inputRef.current || !formSubmitting) return true;
    // If no error message is passed in, use the browser's default validation message
    if (!errorMessage) {
      errorMessage = inputRef.current.validationMessage;
    }
    return inputRef.current.validity.valid;
  };

  return (
    <S.Wrapper $width={width}>
      <S.Container
        className={classNames(
          { 'input-disabled': isDisabled },
          { 'input-readonly': isReadOnly },
          { 'input-has-error': !isValid() && !isDisabled },
          { 'has-label': !!label },
          { 'has-value': !!(inputProps?.value ?? inputValue) }
        )}
        data-testid={TextAreaTestId.container(testId)}
        $inputSize={inputSize}
        onClick={() => inputRef.current?.focus()}
      >
        {leadingIcon}

        <S.TextArea
          {...inputProps}
          className={classNames(
            { 'has-label': !!label },
            { 'has-value': !!(inputProps?.value ?? inputValue) },
            { 'input-readonly': isReadOnly }
          )}
          ref={inputRef}
          required={isRequired}
          readOnly={isReadOnly}
          onInput={handleInput}
          data-size={inputSize}
          data-testid={TextAreaTestId.input(testId)}
          rows={rows}
        />
        <S.Label
          {...labelProps}
          className={classNames(
            { 'has-label': !!label },
            { 'has-value': !!(inputProps?.value ?? inputValue) },
            { 'has-leading-icon': !!leadingIcon }
          )}
          data-testid={TextAreaTestId.label(testId)}
        >
          {label}{' '}
          {!isRequired && (
            <LabelHelperText
              className="label-helper-text"
              data-testid={TextAreaTestId.labelHelperText(testId)}
            >
              (optional)
            </LabelHelperText>
          )}
        </S.Label>
        {isValid() ? trailingIcon : <DawnTriangleAlert />}
      </S.Container>
      {/**Error message takes precedence over helper text / description */}
      <MaybeErrorOrDescription
        error={!isValid()}
        errorMessage={errorMessage}
        errorMessageProps={errorMessageProps}
        description={description}
        descriptionProps={descriptionProps}
        testId={testId}
      />
    </S.Wrapper>
  );
};

export default TextArea;
