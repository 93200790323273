//@ts-nocheck
import axios from "axios";
import { message } from "antd";
import { handleUnAthorizedRequests } from "./validations";
import { saveAs } from "file-saver";
import { goToRoute, getAccessToken } from "../hooks";

const defaultAjaxTimeout = 30000;

const axiosBase = axios.create({
  timeout: defaultAjaxTimeout,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});
axiosBase.defaults.headers.common["Accept"] = "application/json";
axiosBase.defaults.headers.post["Content-Type"] = "application/json";
axiosBase.defaults.headers.post["X-Content-Type-Options"] = "nosniff";

const requestPayloadWrapper = function (method: any) {
  return async function (url: any, data: any) {
    const access_token = await getAccessToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    return axios({
      method,
      url,
      headers,
      data,
      timeout: defaultAjaxTimeout,
      validateStatus: (status) => {
        if (status === 401) {
          message.error("Your session has expired. Please login.");
          goToRoute("/logout");
        }
        if (status === 403) {
          handleUnAthorizedRequests();
        }
        return status >= 200 && status < 500;
      },
    });
  };
};

const requestWrapper = function (method: any) {
  return async function (url: any, cancelToken: any, validateStatus = true) {
    const access_token = await getAccessToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    return axios({
      method,
      url,
      headers,
      cancelToken,
      timeout: defaultAjaxTimeout,
      validateStatus: (status) => {
        if (status === 401 && validateStatus) {
          goToRoute("/logout");
        }
        if (status === 403) {
          handleUnAthorizedRequests();
        }
        return status >= 200 && status < 500;
      },
    });
  };
};

const requestFileWrapper = function (method: any) {
  return async function (url: any, payload: any, name: any) {
    const access_token = await getAccessToken();

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      const formData = new FormData();
      const { file } = payload;

      formData.append(name ? name : "file", file);
      req.open(method, url, true);
      if (url.includes("profile-photo")) {
        req.setRequestHeader("Authorization", `Sass-Key abcd`);
      } else {
        req.setRequestHeader("Authorization", `bearer ${access_token}`);
      }
      req.send(formData);
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
          return resolve(req);
        }
      };
    });
  };
};

const downloadWrapper = function () {
  return async function (url: string, filename: string) {
    const access_token = await getAccessToken();

    const headers = {
      Authorization: "Bearer " + access_token,
    };
    return axios({
      url: url,
      method: "GET",
      headers,
      responseType: "blob",
    }).then((response) => {
      saveAs(response.data, filename);
    });
  };
};

const requuestAllWrapper = function (method: any) {
  return async function (requests: any) {
    return axios[method](requests);
  };
};

/**
 * We had to change this to a var because the transpiler was not able to handle const
 * due to hoisting issues. Unsure what's the real cause of it, but this is a workaround
 * and in order to prevent unwanted changes to the object, we are freezing it.
 */
var _http = {
  get: requestWrapper("get"),
  put: requestPayloadWrapper("put"),
  patch: requestPayloadWrapper("patch"),
  post: requestPayloadWrapper("post"),
  del: requestWrapper("delete"),
  postFile: requestFileWrapper("post"),
  downloadFile: downloadWrapper(),
  all: requuestAllWrapper("all"),
};

export var http = Object.freeze(_http);
