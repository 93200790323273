import * as S from '../modal.styles';

import { AppliesTo } from './page-2/applies-to';
import { AmountMethod } from './page-2/bulk-upload/amount-method';
import { ParsedCsvItem } from './page-2/bulk-upload/bulk-upload.types';
import { BusinessUnit } from './page-2/business-unit';
import { ChangeTask } from './page-2/change-task';
import { LumpSum } from './page-2/lump-sum';
import { Notes } from './page-2/notes';
import { Attachment } from './page-2/pay-instruction';
import { PaymentPeriods } from './page-2/payment-periods';
import { Rate } from './page-2/rate';
import { RecurringEndDate } from './page-2/recurring-expiry-date';
import { RecurringMaxAmount } from './page-2/recurring-max-amount';
import { RecurringType } from './page-2/recurring-type';
import { SubType } from './page-2/sub-type';
import { TaxCalculationMethod } from './page-2/tax-calculation-method';
import { Units } from './page-2/units';

export interface StepTwoProps {
  setCsvData: (data: ParsedCsvItem[]) => void;
  setCsvDataWarnings: (data: string[]) => void;
  selectedWorkers?: string[];
}

export function StepTwo(props: Readonly<StepTwoProps>) {
  return (
    <>
      <BusinessUnit />
      <ChangeTask />
      <SubType />
      <RecurringType />
      <RecurringMaxAmount />
      <RecurringEndDate />
      <AppliesTo selectedWorkers={props.selectedWorkers} />
      <AmountMethod {...props} />
      <S.SingleLine>
        <Rate />
        <Units />
      </S.SingleLine>

      <LumpSum />
      <TaxCalculationMethod />
      <PaymentPeriods />
      <Notes />
      <Attachment />
    </>
  );
}
