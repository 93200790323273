import styled from 'styled-components';

import { Colour, MinBreakpoint } from '@xemplo/style-constants';
import { zIndex } from '@xemplo/zindex';

/** The main container for the sidebar */
export const Sidenav = styled.aside<{ $offset?: number }>`
  --sidenav-offset: ${({ $offset }) => $offset || 0}px;
  position: relative;
  width: 100%;
  max-height: 60%;

  background: ${Colour.White[100]};
  border-right: 1px solid ${Colour.Gray[200]};
  transition: 0.3s width cubic-bezier(0.4, 0, 0.2, 1);

  &.responsive-expanded {
    z-index: ${zIndex.Modal};
    position: fixed;
    min-height: 200px;
    width: 100%;
    overflow: hidden auto;
  }

  @media ${MinBreakpoint.mediumV2} {
    width: 88px;
    height: calc(100vh - var(--sidenav-offset));
    left: unset;
    top: unset;
    max-height: unset;

    &[data-expanded='true'] {
      width: 272px;
      overflow: unset;
    }
  }
`;

export const Overlay = styled.div<{ $offset?: number }>`
  position: fixed;
  top: ${({ $offset }) => $offset ?? 0}px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.ModalOverlay};
  background-color: ${Colour.Gray[900]};
  opacity: 0.6;

  @media ${MinBreakpoint.mediumV2} {
    display: none;
  }
`;
