//@ts-nocheck
import React from "react";
import Icon from "@ant-design/icons";

const dashboardSvg = () => {
  return (
    <svg width="1.28em" height="1.28em" viewBox="0 0 33 32" fill="currentColor">
      <path
        d="M16.0146,0 C7.17009,0 0,7.16384 0,15.9997 C0,24.8359 7.17009,32 16.0146,32 C24.8591,32 32.0301,24.8359 32.0301,15.9997 C32.0301,7.16341 24.8591,0 16.0146,0 Z M9.64293,21.8094 C9.03147,21.8094 8.53671,21.315 8.53671,20.7039 C8.53671,20.0933 9.03158,19.5989 9.64293,19.5989 C10.2541,19.5989 10.7492,20.0933 10.7492,20.7039 C10.7492,21.315 10.2541,21.8094 9.64293,21.8094 Z M9.64293,17.1052 C9.03147,17.1052 8.53671,16.6108 8.53671,15.9994 C8.53671,15.3888 9.03158,14.8944 9.64293,14.8944 C10.2541,14.8944 10.7492,15.3888 10.7492,15.9994 C10.7492,16.6109 10.2541,17.1052 9.64293,17.1052 Z M9.64293,12.4011 C9.03147,12.4011 8.53671,11.9067 8.53671,11.2956 C8.53671,10.685 9.03158,10.1906 9.64293,10.1906 C10.2541,10.1906 10.7492,10.685 10.7492,11.2956 C10.7492,11.9067 10.2541,12.4011 9.64293,12.4011 Z M23.4935,21.7402 L13.1217,21.7402 C12.5101,21.7402 12.0141,21.2447 12.0141,20.6336 C12.0141,20.0225 12.5101,19.527 13.1217,19.527 L23.4935,19.527 C24.1051,19.527 24.6011,20.0225 24.6011,20.6336 C24.6011,21.2447 24.1051,21.7402 23.4935,21.7402 Z M23.4935,17.0718 L13.1217,17.0718 C12.5101,17.0718 12.0141,16.5763 12.0141,15.9652 C12.0141,15.3541 12.5101,14.8587 13.1217,14.8587 L23.4935,14.8587 C24.1051,14.8587 24.6011,15.3541 24.6011,15.9652 C24.6011,16.5762 24.1051,17.0718 23.4935,17.0718 Z M23.4935,12.4033 L13.1217,12.4033 C12.5101,12.4033 12.0141,11.9078 12.0141,11.2967 C12.0141,10.6856 12.5101,10.1902 13.1217,10.1902 L23.4935,10.1902 C24.1051,10.1902 24.6011,10.6856 24.6011,11.2967 C24.6011,11.9078 24.1051,12.4033 23.4935,12.4033 Z"
        id="Shape"
      />
    </svg>
  );
};

export const DashboardIcon = (props: any) => {
  return <Icon component={dashboardSvg} {...props} />;
};

export const PayrunIcon = (props: any) => {
  return <Icon component={PayrunSvg} {...props} />;
};

export const ProcessIcon = (props: any) => {
  return <Icon component={ProcessSvg} {...props} />;
};

export const ApprovalIcon = (props: any) => {
  return <Icon component={ApprovalSvg} {...props} />;
};

export const PaymentIcon = (props: any) => {
  return <Icon component={PaymentSvg} {...props} />;
};

export const PercentIcon = (props: any) => {
  return <Icon component={percentImg} {...props} />;
};

export const GoBackIcon = (props: any) => {
  return <Icon component={goBackSvg} {...props} />;
};

// Payrun
const PayrunSvg = () => {
  return (
    <svg width="1em" height="1.28em" viewBox="0 0 25 32" fill="currentColor">
      <path
        d="M12.4527,25.2082 C17.4441,25.2082 21.4989,21.0873 21.4989,16 C21.4989,10.9192 17.4505,6.79184 12.4527,6.79184 C9.84789,6.79184 7.49973,7.9151 5.85089,9.70449 C4.33678,11.3502 3.40649,13.5641 3.40649,15.9935 C3.40649,21.0873 7.46124,25.2082 12.4527,25.2082 Z M9.29613,19.6441 C9.37954,19.3437 9.45653,19.0367 9.55276,18.7429 C9.66183,18.3967 9.75165,18.351 10.066,18.5208 C10.6049,18.8082 11.1695,18.9649 11.7662,19.0433 C12.1511,19.089 12.5297,19.0498 12.8825,18.8931 C13.5498,18.5992 13.6524,17.8155 13.0878,17.3388 C12.8954,17.1755 12.6772,17.058 12.4527,16.96 C11.8688,16.6988 11.2593,16.5029 10.714,16.1633 C9.82222,15.6212 9.25122,14.8702 9.3218,13.7665 C9.39879,12.5127 10.0917,11.7355 11.2209,11.3176 C11.6828,11.1478 11.6892,11.1543 11.6892,10.658 C11.6892,10.4882 11.6892,10.3249 11.6892,10.1551 C11.6956,9.78286 11.7598,9.71755 12.1255,9.70449 C12.241,9.69796 12.35,9.70449 12.4655,9.70449 C13.2418,9.70449 13.2418,9.70449 13.2482,10.4947 C13.2482,11.0563 13.2482,11.0563 13.8,11.1412 C14.217,11.2065 14.6212,11.3306 15.0061,11.5069 C15.2179,11.6049 15.3013,11.7551 15.2371,11.9837 C15.1409,12.3233 15.051,12.6694 14.942,13.009 C14.8393,13.329 14.7431,13.3747 14.4416,13.2245 C13.8321,12.9241 13.1969,12.8 12.5297,12.8392 C12.3564,12.8457 12.1832,12.8718 12.0164,12.9437 C11.439,13.1984 11.3428,13.8514 11.8368,14.2563 C12.087,14.4588 12.3693,14.609 12.6644,14.7331 C13.1777,14.9486 13.6909,15.1576 14.1785,15.4318 C15.7311,16.3069 16.1481,18.2857 15.0575,19.6375 C14.6597,20.1273 14.1464,20.4539 13.5498,20.6237 C13.2867,20.6955 13.1712,20.8392 13.1841,21.1135 C13.1969,21.3878 13.1841,21.6555 13.1841,21.9298 C13.1841,22.1714 13.0622,22.302 12.8248,22.3086 C12.5361,22.3151 12.2474,22.3151 11.9651,22.3086 C11.7149,22.302 11.593,22.1584 11.593,21.9102 C11.593,21.7143 11.5865,21.5118 11.5865,21.3159 C11.5801,20.8784 11.5673,20.8588 11.1567,20.7935 C10.6242,20.7086 10.1045,20.5845 9.62334,20.3429 C9.22556,20.1469 9.18707,20.049 9.29613,19.6441 Z"
        id="Shape"
        fillRule="nonzero"
      />
      <path
        d="M22.2498,25.9788 C17.0273,31.2947 8.64199,31.471 3.20786,26.5208 L4.95294,26.5208 L4.95294,24.627 L1.28315,24.627 L1.23824,24.627 L0,24.627 L0,25.907 L0,25.9331 L0,29.6686 L1.86056,29.6686 L1.86056,27.8335 C4.85029,30.609 8.6484,32 12.4465,32 C16.4756,32 20.4983,30.4392 23.565,27.3176 L24.2258,26.6449 L22.9106,25.3061 L22.2498,25.9788 Z"
        id="Path"
      />
      <path
        d="M23.0458,2.33143 L23.0458,4.16653 C20.0561,1.39102 16.258,0 12.4599,0 C8.4308,0 4.40814,1.56082 1.34142,4.68245 L0.687012,5.34857 L2.00224,6.68734 L2.66306,6.01469 C7.88546,0.698775 16.2708,0.522449 21.7049,5.47265 L19.9599,5.47265 L19.9599,7.36653 L23.6297,7.36653 L23.6746,7.36653 L24.9128,7.36653 L24.9128,6.08653 L24.9128,6.06041 L24.9128,2.33143 L23.0458,2.33143 Z"
        id="Path"
      />
    </svg>
  );
};

//Process
const ProcessSvg = () => {
  return (
    <svg width="1.8em" height="1.28em" viewBox="0 0 45 32" fill="currentColor">
      <path
        d="M26.4057,14.6315 L28.7921,12.248 L26.358,9.81296 L23.9774,12.2021 C22.9791,11.5217 21.8394,11.0496 20.6137,10.8106 L20.6137,7.4332 L17.1813,7.4332 L17.1813,10.8125 C15.9518,11.0495 14.8179,11.5197 13.8233,12.202 L11.437,9.81287 L9.00876,12.2479 L11.3874,14.6314 C10.7174,15.6311 10.2458,16.7645 10.0071,17.9993 L6.62817,17.9993 L6.62817,21.434 L10.0071,21.434 C10.2457,22.6611 10.7154,23.7964 11.3874,24.7961 L9.00876,27.1852 L11.4351,29.6165 L13.8213,27.2311 C14.8159,27.9078 15.9518,28.3799 17.1813,28.6188 L17.1813,32 L20.6157,32 L20.6157,28.6188 C21.8412,28.3837 22.9771,27.9134 23.9794,27.235 L26.36,29.6165 L28.794,27.1852 L26.4078,24.7961 C27.0836,23.8003 27.557,22.6611 27.7861,21.434 L31.1689,21.434 L31.1689,17.9993 L27.7841,17.9993 C27.5531,16.7646 27.0835,15.6312 26.4057,14.6315 Z M18.8956,23.4716 C16.8282,23.4716 15.1501,21.7838 15.1501,19.71 C15.1501,17.6419 16.8282,15.9581 18.8956,15.9581 C20.9689,15.9581 22.6488,17.642 22.6488,19.71 C22.6506,21.7838 20.9707,23.4716 18.8956,23.4716 Z"
        id="Shape"
        fillRule="nonzero"
      />
      <path
        d="M29.7428,3.06586 C26.7322,1.13729 23.1718,0 19.3367,0 C8.65742,8.86088e-05 0,8.6662 0,19.3584 C0,23.8271 1.52726,27.9232 4.06246,31.203 L6.49075,28.7737 C4.55877,26.1342 3.38286,22.9059 3.38286,19.3737 C3.38286,10.5528 10.5245,3.40621 19.3347,3.40621 C22.2651,3.40621 24.9682,4.25295 27.3297,5.64828 L25.4665,7.63613 L33.0396,8.32417 L32.2264,0.407334 L29.7428,3.06586 Z"
        id="Path"
      />
      <polygon
        id="Path"
        points="44.4864 19.7654 39.7425 15.0177 39.7425 17.6764 33.498 17.6764 33.498 21.8545 39.7425 21.8545 39.7425 24.519"
      />
    </svg>
  );
};

// Approval

const ApprovalSvg = () => {
  return (
    <svg width="1.12em" height="1.2em" viewBox="0 0 28 30" fill="currentColor">
      <path
        d="M21.643,17.2493 C18.1322,17.2493 15.2861,20.1046 15.2861,23.6247 C15.2861,27.1447 18.1332,30 21.643,30 C25.1527,30 27.9998,27.1447 27.9998,23.6247 C27.9998,20.1046 25.1527,17.2493 21.643,17.2493 Z M20.2788,27.5465 L16.8841,24.1418 L18.2171,22.8049 L20.2788,24.8727 L24.7643,20.3741 L26.0974,21.711 L20.2788,27.5465 Z"
        id="Shape"
        fillRule="nonzero"
      />
      <path
        d="M1.76102,26.4885 L1.76102,8.43744 L8.41386,8.43744 L8.41386,1.76521 L21.0106,1.76521 L21.0106,16.336 C21.2189,16.318 21.4282,16.3038 21.6422,16.3038 C22.0269,16.3038 22.403,16.3426 22.7707,16.4003 L22.7707,0 L7.29672,0 L0,7.7255 L0,28.2528 L15.9934,28.2528 C15.5607,27.7214 15.1987,27.1286 14.9262,26.4876 L1.76102,26.4876 L1.76102,26.4885 Z M6.65284,3.24866 L6.65284,6.67223 L3.42022,6.67223 L6.65284,3.24866 Z"
        id="Shape"
        fillRule="nonzero"
      />
      <polygon
        id="Path"
        points="18.7905 13.4012 3.87183 13.4012 3.87183 15.3904 18.7905 15.3904"
      />
      <path
        d="M17.9317,17.3316 L3.87183,17.3316 L3.87183,19.3209 L15.7521,19.3209 C16.3357,18.5182 17.0776,17.8412 17.9317,17.3316 Z"
        id="Path"
      />
      <path
        d="M3.87183,23.428 L14.354,23.428 C14.3729,22.7378 14.4794,22.0684 14.6764,21.4388 L3.87183,21.4388 L3.87183,23.428 Z"
        id="Path"
      />
    </svg>
  );
};

//Payment

const PaymentSvg = () => {
  return (
    <svg width="1.32em" height="1.28em" viewBox="0 0 33 32" fill="currentColor">
      <path
        d="M16.015,0 C7.1708,0 0,7.16406 0,16 C0,24.8359 7.1708,32 16.015,32 C24.8612,32 32.0301,24.8359 32.0301,16 C32.0301,7.16406 24.8612,0 16.015,0 Z M20.3824,22.1777 C19.5945,23.0606 18.4568,23.5801 16.9691,23.7364 L16.9691,26 L15.0727,26 L15.0727,23.7481 C12.5919,23.4942 11.0572,22.0527 10.4649,19.4277 L13.3973,18.6641 C13.6691,20.3144 14.5684,21.1387 16.0952,21.1387 C16.8087,21.1387 17.3366,20.9629 17.6728,20.6094 C18.0091,20.2558 18.1772,19.8301 18.1772,19.3301 C18.1772,18.8125 18.009,18.4199 17.6728,18.1542 C17.3366,17.8867 16.5878,17.5487 15.4285,17.1386 C14.3866,16.7774 13.5713,16.4218 12.9848,16.0664 C12.3983,15.7149 11.9213,15.2207 11.5557,14.5859 C11.1901,13.9492 11.0064,13.207 11.0064,12.3632 C11.0064,11.2558 11.3348,10.2577 11.9878,9.37106 C12.6408,8.48631 13.6691,7.94525 15.0728,7.748 L15.0728,6 L16.9691,6 L16.9691,7.74806 C19.0883,8.002 20.4607,9.19925 21.0843,11.3418 L18.4725,12.4121 C17.9622,10.9434 17.1763,10.209 16.1089,10.209 C15.5732,10.209 15.1432,10.3731 14.8206,10.7012 C14.496,11.0293 14.3338,11.4277 14.3338,11.8946 C14.3338,12.3711 14.4902,12.7364 14.803,12.9922 C15.1139,13.2462 15.7844,13.5606 16.8088,13.9376 C17.9349,14.3477 18.8185,14.7364 19.4578,15.1016 C20.0991,15.4669 20.6093,15.9727 20.9925,16.6153 C21.3737,17.2599 21.5652,18.0119 21.5652,18.8732 C21.5652,20.1953 21.1703,21.2969 20.3824,22.1777 Z"
        id="Shape"
      />
    </svg>
  );
};

const DashSvg = () => {
  return (
    <svg width="1em" height="1.1em" viewBox="0 0 32 26" fill="currentColor">
      <path
        d="M30.7499789,0 C29.1818105,0 2.18701474,0 1.25000084,0 C0.559570526,0 0,0.548990316 0,1.22636632 L0,24.0367158 C0,24.7141053 0.559570526,25.2631579 1.25000084,25.2631579 L30.7499789,25.2631579 C31.4405053,25.2631579 32,24.7141053 32,24.0367158 C32,23.6202105 32,1.98229895 32,1.22636632 C32,0.548990316 31.4405053,0 30.7499789,0 Z M12.3332547,2.45273263 L19.6665263,2.45273263 L19.6665263,8.52397474 L12.3332547,8.52397474 L12.3332547,2.45273263 Z M2.50000842,2.45273263 L9.83324632,2.45273263 L9.83324632,8.52397474 L2.50000842,8.52397474 L2.50000842,2.45273263 Z M29.4999579,22.8104421 L2.50000842,22.8104421 L2.50000842,10.9767074 L29.4999579,10.9767074 L29.4999579,22.8104421 Z M29.4999579,8.52397474 L22.1667368,8.52397474 L22.1667368,2.45273263 L29.4999579,2.45273263 L29.4999579,8.52397474 Z M17.3108211,17.1770947 L21.9555368,12.6200758 C22.4436211,12.1412716 23.2350316,12.1412716 23.7234526,12.6200758 C24.2113684,13.0991326 24.2113684,13.8754358 23.7234526,14.3544758 L18.1945263,19.7785263 C17.7066105,20.2573474 16.9148632,20.2570105 16.4267621,19.7785263 L13.9445726,17.3433263 L10.0444295,21.1696842 C9.55616,21.6485053 8.76488421,21.6485053 8.27661474,21.1696842 C7.78858105,20.6906947 7.78858105,19.9141053 8.27661474,19.4352842 L13.0607832,14.7415579 C13.5488337,14.2627368 14.3408168,14.2629895 14.8286147,14.7415579 L17.3108211,17.1770947 Z"
        id="Shape"
      />
    </svg>
  );
};

const HomeImg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M14.8705882,5.36470588 L8.28235294,0.658823529 C8,0.470588235 7.52941176,0.470588235 7.15294118,0.658823529 L0.564705882,5.36470588 C0.188235294,5.64705882 -5.66213743e-15,6.21176471 0.376470588,6.68235294 C0.752941176,7.15294118 1.22352941,7.24705882 1.69411765,6.87058824 L2.07058824,6.58823529 L2.07058824,14.5882353 C2.07058824,15.1529412 2.44705882,15.5294118 3.01176471,15.5294118 L12.4235294,15.5294118 C12.9882353,15.5294118 13.3647059,15.1529412 13.3647059,14.5882353 L13.3647059,6.58823529 C13.6470588,6.87058824 13.9294118,6.96470588 14.3058824,7.05882353 C14.8705882,7.05882353 15.2470588,6.68235294 15.2470588,6.11764706 C15.2470588,5.83529412 15.0588235,5.55294118 14.8705882,5.36470588 Z M11.4823529,13.6470588 L9.6,13.6470588 L9.6,10.8235294 C9.6,9.78823529 8.75294118,8.94117647 7.71764706,8.94117647 C6.68235294,8.94117647 5.83529412,9.78823529 5.83529412,10.8235294 L5.83529412,13.6470588 L3.95294118,13.6470588 L3.95294118,5.27058824 L7.71764706,2.54117647 L11.4823529,5.27058824 L11.4823529,13.6470588 Z"
        id="Path_357"
      />
    </svg>
  );
};

const percentImg = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="https://www.w3.org/2000/svg"
      height="21"
      width="21"
      viewBox="0 0 488.027 488.027"
    >
      <g>
        <g>
          <circle cx="179.163" cy="202.563" r="20.4" />
          <circle cx="308.763" cy="285.363" r="20.4" />
          <path
            d="M476.963,270.963l-7.4-10.1c-8-11-8.2-25.9-0.3-37.1l7.2-10.2c11-15.6,5.9-37.3-11-46.4l-11-5.9
			c-12-6.4-18.6-19.8-16.3-33.3l2.1-12.3c3.2-18.8-10.9-36.2-30-37l-12.5-0.5c-13.6-0.6-25.4-9.8-29.2-22.9l-3.5-12
			c-5.3-18.4-25.5-27.9-43.1-20.3l-11.5,4.9c-12.5,5.4-27.1,2.2-36.2-8l-8.3-9.3c-12.7-14.2-35-14.1-47.6,0.4l-8.2,9.4
			c-9,10.3-23.5,13.7-36.1,8.5l-11.6-4.8c-17.7-7.3-37.7,2.6-42.8,21l-3.3,12.1c-3.6,13.2-15.2,22.6-28.8,23.3l-12.5,0.7
			c-19.1,1.1-32.9,18.7-29.4,37.5l2.3,12.3c2.5,13.4-3.9,26.9-15.8,33.5l-10.9,6.1c-16.7,9.3-21.5,31.1-10.2,46.5l7.4,10.1
			c8,11,8.2,25.9,0.3,37.1l-7.2,10.2c-11,15.6-5.9,37.3,11,46.4l11,5.9c12,6.4,18.6,19.8,16.3,33.3l-2.1,12.3
			c-3.2,18.8,10.9,36.2,30,37l12.5,0.5c13.6,0.6,25.4,9.8,29.2,22.9l3.5,12c5.3,18.4,25.5,27.9,43.1,20.3l11.5-4.9
			c12.5-5.4,27.1-2.2,36.2,8l8.3,9.3c12.8,14.2,35.1,14.1,47.6-0.4l8.2-9.4c9-10.3,23.5-13.7,36.1-8.5l11.6,4.8
			c17.7,7.3,37.7-2.6,42.8-21l3.3-12.1c3.6-13.2,15.2-22.6,28.8-23.3l12.5-0.7c19.1-1.1,32.9-18.7,29.4-37.5l-2.3-12.3
			c-2.5-13.4,3.9-26.9,15.8-33.5l10.9-6.1C483.463,308.263,488.263,286.463,476.963,270.963z M129.863,202.563
			c0-27.2,22-49.2,49.2-49.2s49.2,22,49.2,49.2s-22,49.2-49.2,49.2S129.863,229.663,129.863,202.563z M188.563,329.463
			c-4.2,5.1-11.8,5.8-16.9,1.6l0,0c-5.1-4.2-5.8-11.8-1.6-16.9l129.1-155.5c4.2-5.1,11.8-5.8,16.9-1.6s5.8,11.8,1.6,16.9
			L188.563,329.463z M308.763,334.563c-27.2,0-49.2-22-49.2-49.2s22-49.2,49.2-49.2s49.2,22,49.2,49.2
			S335.963,334.563,308.763,334.563z"
          />
        </g>
      </g>
    </svg>
  );
};

const goBackSvg = (props) => {
  const { height, className, fill } = props;
  return (
    <svg
      width="10"
      height={height ? height : "16"}
      viewBox="0 0 10 16"
      xmlns="https://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M0.160081 8.36879L7.6312 15.8396C7.73812 15.9464 7.86115 16 8.00002 16C8.13894 16 8.26192 15.9464 8.36878 15.8396L9.17025 15.0379C9.27728 14.9311 9.33057 14.8083 9.33057 14.6692C9.33057 14.53 9.27728 14.4073 9.17025 14.3004L2.86964 7.9998L9.17025 1.69908C9.27728 1.59222 9.33057 1.46925 9.33057 1.33049C9.33057 1.1914 9.27728 1.06843 9.17025 0.961566L8.36872 0.160204C8.26186 0.0532322 8.13888 -5.72205e-05 7.99996 -5.72205e-05C7.86109 -5.72205e-05 7.73812 0.0533447 7.6312 0.160204L0.160475 7.63105C0.0536718 7.73785 0.000213623 7.86088 0.000213623 7.9998C0.000213623 8.13873 0.053278 8.2617 0.160081 8.36879Z"
        fill={fill ? fill : "black"}
      />
    </svg>
  );
};

export const LgEllipsis = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="20"
      height="4"
      viewBox="0 0 20 4"
    >
      <circle cx="2" cy="2" r="2"></circle>
      <circle cx="10" cy="2" r="2"></circle>
      <circle cx="18" cy="2" r="2"></circle>
    </svg>
  );
};

const ContractSVG = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="file-contract"
      className="prefix__svg-inline--fa prefix__fa-file-contract prefix__fa-w-12"
      width="20px"
      height="20px"
      viewBox="0 0 384 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 64c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16zm192.81 248H304c8.84 0 16 7.16 16 16s-7.16 16-16 16h-47.19c-16.45 0-31.27-9.14-38.64-23.86-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34a15.986 15.986 0 01-14.31 8.84c-.38 0-.75-.02-1.14-.05-6.45-.45-12-4.75-14.03-10.89L144 354.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.77-16.19 54.05-9.7 66 14.16 2.02 4.06 5.96 6.5 10.16 6.5zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
      />
    </svg>
  );
};
export const ContractIcon = (props) => {
  return <Icon component={ContractSVG} {...props} />;
};

export const PayRunIconCustom = (props) => {
  return (
    <svg
      width={28}
      height={24}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.668 14.733v6.347c0 .42.246.825.683 1.122.438.298 1.031.465 1.65.465h14c.62 0 1.213-.167 1.65-.465.438-.298.684-.701.684-1.122v-6.347M14 2.328v14.544M17.307 4.972H12.35a2.314 2.314 0 000 4.628h3.306a2.314 2.314 0 010 4.628h-5.62"
        stroke="#BDBDBD"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const HelpHubIcon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 19.5A2.5 2.5 0 016.5 17H20"
        stroke="#E0E0E0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 2H20v20H6.5A2.5 2.5 0 014 19.5v-15A2.5 2.5 0 016.5 2v0z"
        stroke="#E0E0E0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PayrunBeingProcessedIcon = (props) => {
  return (
    <svg
      width={51}
      height={60}
      viewBox="0 0 51 60"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.99 30.68V17.621L22.37 1H5.749A4.749 4.749 0 001 5.749v37.99a4.749 4.749 0 004.749 4.75h14.246"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.37 1v16.621H38.99"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.797 24.556H9.417M23.898 35.747H9.416M14.682 13.366H9.416"
        stroke="#FFA412"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.395 58.786c6.726 0 12.178-5.453 12.178-12.178 0-6.726-5.452-12.178-12.178-12.178-6.726 0-12.178 5.452-12.178 12.178 0 6.726 5.453 12.178 12.178 12.178z"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.724 39.696v6.912l4.608 2.304"
        stroke="#FFA412"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PayrunBeingPreparedIcon = (props) => {
  return (
    <svg
      width={51}
      height={51}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.577 22.265a3.51 3.51 0 114.965 4.965L27.857 47.915l-6.62 1.655 1.655-6.62 20.685-20.685zM33.38 26.297v-11.13L19.214 1H5.048A4.048 4.048 0 001 5.047v32.38a4.048 4.048 0 004.048 4.048H17.19"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.214 1v14.166H33.38"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.249 21.077H8.173M27.249 30.615H8.173M12.662 11.54H8.173"
        stroke="#FFA412"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const QuestionIcon = (props) => {
  return (
    <svg
      width={66}
      height={62}
      viewBox="0 0 66 62"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.878 40.533a18.055 18.055 0 001.94 8.187A18.312 18.312 0 0044.19 58.846a18.054 18.054 0 008.187-1.94L64.658 61l-4.093-12.28a18.054 18.054 0 001.94-8.187 18.313 18.313 0 00-10.127-16.374 18.055 18.055 0 00-8.187-1.94h-1.077a18.27 18.27 0 00-17.236 17.236v1.078z"
        fill="#fff"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={50.207}
        cy={40.842}
        r={1.793}
        transform="rotate(-180 50.207 40.842)"
        fill="#FFA412"
      />
      <circle
        cx={44.061}
        cy={40.842}
        r={1.793}
        transform="rotate(-180 44.061 40.842)"
        fill="#FFA412"
      />
      <circle
        cx={37.915}
        cy={40.842}
        r={1.793}
        transform="rotate(-180 37.915 40.842)"
        fill="#FFA412"
      />
      <path
        d="M44.903 21.732A20.439 20.439 0 0142.707 31a20.732 20.732 0 01-18.536 11.464 20.44 20.44 0 01-9.269-2.196L1 44.903 5.634 31a20.439 20.439 0 01-2.195-9.268A20.732 20.732 0 0114.902 3.195 20.44 20.44 0 0124.171 1h1.22a20.683 20.683 0 0119.512 19.512v1.22z"
        fill="#fff"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.366 13.74a7.022 7.022 0 0113.645 2.34c0 4.681-7.022 7.022-7.022 7.022v5.337"
        stroke="#FFA412"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.176 32.464h.024"
        stroke="#FFA412"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReviewAndApproveIcon = (props) => {
  return (
    <svg
      width={51}
      height={60}
      viewBox="0 0 51 60"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.513 31.088V17.85L22.663 1H5.814A4.814 4.814 0 001 5.814v38.513a4.814 4.814 0 004.814 4.814h14.442"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.664 1v16.85h16.849"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.22 24.88H9.532M24.213 36.224H9.532M14.87 13.536H9.532"
        stroke="#FFA412"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.559 58.912c6.633 0 12.011-5.377 12.011-12.011 0-6.634-5.378-12.012-12.011-12.012-6.634 0-12.012 5.378-12.012 12.012s5.378 12.011 12.012 12.011z"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.559 51.572h.006M37.559 40.228v8.007"
        stroke="#FFA412"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PayrunCompletedIcon = (props) => {
  return (
    <svg
      width={67}
      height={67}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M66 30.529v2.99A32.5 32.5 0 1146.727 3.814"
        stroke="#25282B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#prefix__filter0_d)">
        <path
          d="M66 6L32.923 39 23 29.11"
          stroke="#25282B"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="prefix__filter0_d"
          x={22}
          y={5}
          width={45}
          height={38}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.643137 0 0 0 0 0.0705882 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export const FlagIcon = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.6665 17.4997C4.6665 17.4997 5.83317 16.333 9.33317 16.333C12.8332 16.333 15.1665 18.6663 18.6665 18.6663C22.1665 18.6663 23.3332 17.4997 23.3332 17.4997V3.49967C23.3332 3.49967 22.1665 4.66634 18.6665 4.66634C15.1665 4.66634 12.8332 2.33301 9.33317 2.33301C5.83317 2.33301 4.6665 3.49967 4.6665 3.49967V17.4997Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 25.6667V17.5"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EmptyDashboardIcon = (props: any) => {
  return (
    <svg
      width={180}
      height={180}
      viewBox="0 0 85 85"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.161 19.78c0 16.33 8.46 27.546 8.46 35.416 0 7.87-4.524 24.397-40.727 24.397-36.203 0-41.518-22.235-41.518-30.89 0-40.533 73.785-57.848 73.785-28.924Z"
        fill="#EFEFEF"
      />
      <path
        d="M20.66 69.063a4.736 4.736 0 0 1-4.723-4.723V27.743a4.736 4.736 0 0 1 4.723-4.722h29.514a4.736 4.736 0 0 1 4.722 4.722V64.34a4.72 4.72 0 0 1-4.722 4.723H20.66Z"
        fill="#F3F3F1"
      />
      <path
        d="M23.021 37.188h24.792v8.263H23.02v-8.264ZM45.451 20.66v4.722a2.362 2.362 0 0 1-2.36 2.361H27.742a2.362 2.362 0 0 1-2.361-2.361V20.66h5.312a4.736 4.736 0 0 1 4.723-4.723 4.736 4.736 0 0 1 4.722 4.723h5.312Z"
        fill="#B3B3B3"
      />
      <path
        d="M54.306 64.34c5.542 0 10.034-4.492 10.034-10.034 0-5.542-4.492-10.035-10.034-10.035-5.542 0-10.035 4.493-10.035 10.035S48.764 64.34 54.306 64.34Z"
        fill="#DDD"
      />
      <path
        d="M23.021 37.188h5.313v8.263H23.02v-8.264ZM38.073 16.764a4.683 4.683 0 0 0-2.656-.826 4.736 4.736 0 0 0-4.722 4.722h5.312c0-1.613.822-3.044 2.066-3.896ZM30.695 25.382V20.66h-5.313v4.722a2.362 2.362 0 0 0 2.361 2.361h5.313a2.362 2.362 0 0 1-2.361-2.361Z"
        fill="#999"
      />
      <path
        d="M49.584 54.306c0-4.616 3.135-8.465 7.378-9.634-.852-.233-1.73-.401-2.656-.401-5.542 0-10.035 4.493-10.035 10.035 0 5.541 4.493 10.034 10.035 10.034.925 0 1.804-.165 2.656-.4-4.243-1.17-7.379-5.018-7.379-9.634Z"
        fill="#CCC"
      />
      <path
        d="M21.25 64.34V27.743a4.736 4.736 0 0 1 4.722-4.722H20.66a4.736 4.736 0 0 0-4.723 4.722V64.34a4.736 4.736 0 0 0 4.723 4.723h5.312a4.736 4.736 0 0 1-4.722-4.723Z"
        fill="#DDD"
      />
      <path
        d="M39.69 47.222H23.02c-.977 0-1.77-.793-1.77-1.77v-8.265c0-.977.793-1.77 1.77-1.77h24.793c.977 0 1.77.793 1.77 1.77v1.275h-1.77v.496H24.791v4.722H39.69v3.542ZM21.25 50.764h17.803v3.542H21.25v-3.542ZM21.25 57.847h18.582v3.542H21.25v-3.542ZM56.667 37.211h-3.542v-9.468a2.955 2.955 0 0 0-2.951-2.951h-4.44V21.25h4.44a6.5 6.5 0 0 1 6.493 6.493v9.468Z"
        fill="#373737"
      />
      <path
        d="M45.687 70.833H20.66a6.498 6.498 0 0 1-6.494-6.493V27.743a6.498 6.498 0 0 1 6.494-6.493h4.438v3.542H20.66a2.955 2.955 0 0 0-2.952 2.951V64.34a2.956 2.956 0 0 0 2.952 2.952h25.027v3.541Z"
        fill="#373737"
      />
      <path
        d="M43.09 29.514H27.743a4.136 4.136 0 0 1-4.132-4.132V20.66c0-.978.794-1.771 1.771-1.771h3.787c.775-2.723 3.282-4.723 6.248-4.723 2.965 0 5.473 2 6.247 4.723h3.788c.977 0 1.77.793 1.77 1.77v4.723a4.136 4.136 0 0 1-4.132 4.132ZM27.154 22.43v2.952c0 .326.264.59.59.59h15.348a.59.59 0 0 0 .59-.59V22.43h-3.542c-.977 0-1.77-.793-1.77-1.77a2.956 2.956 0 0 0-2.952-2.952 2.956 2.956 0 0 0-2.952 2.952c0 .977-.793 1.77-1.77 1.77h-3.542Z"
        fill="#373737"
      />
      <path
        d="m62.358 51.591-4.95-4.95a.664.664 0 0 0-.472-.195.665.665 0 0 0-.472.195l-3.236 3.239a6.62 6.62 0 0 0-4.888 1.412.67.67 0 0 0-.054.992l3.65 3.65-4.327 4.324a.317.317 0 0 0-.092.197l-.069.747a.32.32 0 0 0 .32.35c.01 0 .02 0 .03-.002l.747-.069a.317.317 0 0 0 .197-.092l4.327-4.327 3.65 3.65c.131.13.302.195.473.195a.665.665 0 0 0 .52-.25 6.636 6.636 0 0 0 1.41-4.89l3.236-3.236a.666.666 0 0 0 0-.94Zm-4.26 3.158-.493.492.077.692a5.221 5.221 0 0 1-.611 3.091l-7.093-7.097a5.195 5.195 0 0 1 3.092-.61l.69.075.492-.492 2.686-2.686 3.847 3.847-2.687 2.688Z"
        fill="#555"
      />
    </svg>
  );
};

export const DragAndDropIcon = (props) => (
  <svg
    width={55}
    height={55}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.208 21H25.583A4.583 4.583 0 0 0 21 25.583v20.625a4.583 4.583 0 0 0 4.583 4.584h20.625a4.583 4.583 0 0 0 4.584-4.584V25.583A4.583 4.583 0 0 0 46.208 21ZM36 28v16M28 36h16"
      stroke="#25282B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.459 34.375H9.167a4.583 4.583 0 0 1-4.583-4.583V9.167a4.583 4.583 0 0 1 4.583-4.584h20.625a4.583 4.583 0 0 1 4.583 4.584v2.291"
      stroke="#25282B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IntegrationSettingsIcon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.04 0H.96A.959.959 0 0 0 0 .96v22.08c0 .531.429.96.96.96h22.08c.531 0 .96-.429.96-.96V.96a.959.959 0 0 0-.96-.96Zm-1.2 21.84H2.16V2.16h19.68v19.68ZM5.784 12.36h1.521c.111 0 .204-.09.204-.204V9.789c0-.591.477-1.068 1.065-1.068h6.171v1.602c0 .171.195.264.327.159l3.273-2.571a.203.203 0 0 0 0-.321l-3.273-2.571a.202.202 0 0 0-.327.159V6.78H8.571A2.996 2.996 0 0 0 5.58 9.783v2.367c0 .12.09.21.204.21Zm-.126 4.047 3.273 2.571a.202.202 0 0 0 .327-.159v-1.602h6.171a2.996 2.996 0 0 0 2.991-3.003v-2.367a.204.204 0 0 0-.204-.204h-1.521a.204.204 0 0 0-.204.204v2.367c0 .591-.477 1.068-1.065 1.068H9.258V13.68a.201.201 0 0 0-.327-.159l-3.273 2.571a.197.197 0 0 0 0 .315Z"
        fill="#666"
      />
    </svg>
  );
};
