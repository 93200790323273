import { Button, ButtonSize, ButtonType } from '@xemplo/button';
import {
  PAYRUN_COMPONENTS,
  ROLE_AUTHORISATION_MODULE_IDS,
  useRoleAuthorisation,
} from '@xemplo/gp-api';
import { PAYRUN_STATUS_IDS } from '@xemplo/gp-types';
import { DawnTrash24 } from '@xemplo/icons';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';
import { PayrunResubmitAmendment } from '@xemplo/payrun-resubmit-amendment';
import { Prompt } from '@xemplo/prompts';

import { useConfirmDeletePrompt } from './hooks/use-confirm-delete-prompt';
import * as S from './payrun-amendment-detail.styles';
import { PayrunAmendmentDetailFooterProps } from './payrun-amendment-detail.types';

const amendmentStageStatusIds = [
  PAYRUN_STATUS_IDS.GENERATED,
  PAYRUN_STATUS_IDS.DETAILS_VIEWED,
  PAYRUN_STATUS_IDS.AMENDMENTS_ADDED,
];

export const PayrunAmendmentDetailFooter = (props: PayrunAmendmentDetailFooterProps) => {
  const { promptProps, promptVisible, setPromptVisible } = useConfirmDeletePrompt(props);
  const { data: payrunDetails } = useGetPayrunByIdQuery({ id: props.payrunId });

  const { componentVisible, componentDisabled } = useRoleAuthorisation(
    ROLE_AUTHORISATION_MODULE_IDS.payrun
  );

  const isAmendmentStage = amendmentStageStatusIds.includes(
    payrunDetails?.result.payrunStatusID as PAYRUN_STATUS_IDS
  );

  return isAmendmentStage ? (
    <>
      <S.Footer>
        {componentVisible(PAYRUN_COMPONENTS.AMENDMENT_DELETE) && (
          <Button
            ariaLabel="delete amendment"
            type={ButtonType.Secondary}
            size={ButtonSize.Medium}
            onClick={() => setPromptVisible(true)}
            icon={<DawnTrash24 />}
            disabled={componentDisabled(PAYRUN_COMPONENTS.AMENDMENT_DELETE)}
          >
            Remove
          </Button>
        )}

        <PayrunResubmitAmendment
          onSuccess={props.onSuccess}
          amendment={props.amendment}
        />
      </S.Footer>
      <Prompt {...promptProps} open={promptVisible} />
    </>
  ) : null;
};
