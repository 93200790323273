import { useEffect, useState } from "react";
import { Button } from "antd";
import { getModuleType, getPayScheduleCreateRoute } from "../../libs";
import { GoBackIcon } from "../../Common/customicons";
import { If } from "../../Common/ui";
import { GOOGLE_ANALYTICS_PAGE_TITLE } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks";
import { RootState } from "../../store";
import { pageHeaderActions } from "../../slices/pageHeader";

const Navbar = () => {
  const navigate = useNavigate();
  const [moduleType, setModuleType] = useState("");
  const { header } = useSelector((state: RootState) => state.pageHeader);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setModuleType(getModuleType(header?.entity ?? ""));
  }, [header]);

  const onActionClicked = () => {
    let tempHeader = {};
    switch (header?.entity) {
      case "pay schedule":
        tempHeader = {
          module: "Back to Pay Schedules",
          enableBack: true,
          title: "New Pay Schedule",
          entity: "pay schedule",
          returnUrl: "/payschedules",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayScheduleCreate,
        };
        navigate(getPayScheduleCreateRoute({}));
        break;
      case "business unit":
        tempHeader = {
          module: "Back to Business Unit",
          enableBack: true,
          title: "New Business Unit",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BusinessUnitCreate,
          entity: "business unit",
          returnUrl: "/businessunit",
        };
        navigate("/businessunit/create");
        break;
      case "billingserviceprovider":
        tempHeader = {
          module: "Back to Billing Service Provider",
          enableBack: true,
          title: `New Billing Service Provider`,
          entity: "billing service provider",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BillingServiceProviderCreate,
          returnUrl: "",
        };
        navigate("/billingserviceproviders/create");
        break;
      case "payrun":
        tempHeader = {
          module: "Back to Pay Run",
          enableBack: true,
          title: "New Pay Run",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayRunCreate,
          entity: "pay run",
          returnUrl: "/payrun",
        };
        navigate("/payrun/create");
        break;
      case "company":
        tempHeader = {
          module: "Back to Company",
          enableBack: true,
          title: "New Company",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyCreate,
          entity: "company",
          returnUrl: "/company",
        };
        navigate("/company/create");
        break;
      case "payroll service provider":
        tempHeader = {
          module: "Back to Payroll Service Provider",
          enableBack: true,
          title: "New Payroll Service Provider",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderCreate,
          entity: "payroll service provider",
          returnUrl: "/payrollprovider",
        };
        navigate("/payrollprovider/create");
        break;
      case "generalAmendment":
        break;
      default:
        console.log("unrecognized ");
        break;
    }
    dispatch(pageHeaderActions.setPageHeader(tempHeader));
  };

  const backHistory = () => {
    if (header?.returnUrl) {
      return navigate(header.returnUrl);
    }
    navigate(-1);
  };
  return (
    <div className="max-w-screen-1920 mb-2">
      <div className="mx-auto flex justify-between items-center px-3 py-3 dark:bg-darkSecondary">
        <div className="header-bar-actions w-full">
          <div className="flex justify-between items-center w-full">
            <div>
              <If
                condition={!!header?.enableBack}
                then={
                  <span onClick={backHistory} className="hand mr-[5px] mt-0.5">
                    <GoBackIcon />
                  </span>
                }
              />

              <span className="text-2xl font-medium title">
                {header?.title}
              </span>

              <If
                condition={!!header?.module}
                then={
                  <span className="text-[15px] mb-0 block color-dark">
                    {header?.module}
                  </span>
                }
              />
            </div>
          </div>

          <If
            condition={!!header?.action}
            then={
              <Button
                onClick={onActionClicked}
                type="primary"
                className="rounded-[40px]"
              >
                New {moduleType}
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
