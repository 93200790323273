import SimpleBar from 'simplebar-react';
import styled, { css } from 'styled-components';

import { Checkbox } from '@xemplo/checkbox';
import {
  BodySmallRegular,
  BodySmallSemiBold,
  Colour,
  Shadow,
  TextEllipsis,
} from '@xemplo/style-constants';

export const ScrollBar = styled(SimpleBar)<{ $width?: string; $maxheight?: string }>`
  max-height: ${({ $maxheight }) => $maxheight ?? '300px'};
  width: ${({ $width }) => $width || '272px'};
  background: transparent;
  ${Shadow.Medium1};
  border: 1px solid ${Colour.Gray[200]};
  border-radius: 8px;

  .simplebar-content-wrapper {
    border-radius: 8px;
  }
  .simplebar-scrollbar:before {
    background: ${Colour.Gray[300]};
    border-radius: 2px;
  }
  .simplebar-vertical .simplebar-scrollbar:before {
    width: 4px;
  }
  .simplebar-horizontal .simplebar-scrollbar:before {
    height: 4px;
  }

  .simplebar-track {
    &.simplebar-vertical {
      width: 9px;
    }

    &.simplebar-horizontal {
      height: 9px;
    }
  }
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  transition: box-shadow 0.2s ease-in-out;
  &.shadow {
    ${Shadow.Light1};
  }
`;

export const List = styled.ul<{ $width?: string }>`
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: ${({ $width }) => $width || '272px'};
  border: none;
  border-radius: 8px;
  & > :last-child {
    border: none;
  }

  &.has-search {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const _listItem = css`
  background: ${Colour.White[100]};
  color: ${Colour.Gray[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  height: 48px;
  padding: 0px 24px;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${Colour.Gray[200]};

  &.item-selected {
    color: ${Colour.Blue[600]};
    background: ${Colour.Blue[50]};
    font-weight: 500;
  }

  &:hover:not(.search-item, .item-selected) {
    background: ${Colour.Gray[50]};
  }
`;

export const NonListItem = styled.span`
  ${_listItem};
  &.search-item {
    box-sizing: content-box; /** In GP, AntD forces everything to be border-box */
    padding: 8px;
    background: ${Colour.Gray[50]};
    cursor: default;
  }
`;

export const ListItem = styled.li`
  ${_listItem};
  & > svg {
    flex: 0 0 auto;
  }
`;

export const ItemContent = styled.span`
  ${BodySmallRegular};
  ${TextEllipsis};

  &.item-selected {
    font-weight: 500;
  }

  &.bold {
    ${BodySmallSemiBold};
  }
`;

export const CheckboxSelection = styled(Checkbox)`
  margin-left: auto;
`;
