import { SVGProps } from 'react';
const DawnReceipt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.995 3.504c-.25 0-.543.112-.793.44l-.008.01-.955 1.207v.001c-.977 1.23-2.74 1.35-3.884.241L6.34 5.39c-.497-.51-.885-.71-1.12-.76-.148-.032-.225-.01-.296.06-.25.553-.374 1.39-.374 2.721v9.18c0 1.343.127 2.183.38 2.736.072.064.149.081.287.05.234-.051.62-.257 1.12-.773a2.602 2.602 0 0 1 3.9.234l.002.002.955 1.206.003.005c.257.33.555.446.798.446.25 0 .542-.112.792-.44l.008-.01.972-1.233.003-.003c.987-1.27 2.779-1.334 3.89-.2.62.633 1.043.774 1.237.774.078 0 .127-.016.17-.054.255-.552.383-1.394.383-2.74V7.41c0-1.333-.125-2.17-.375-2.724a.23.23 0 0 0-.178-.07c-.194 0-.617.14-1.238.773l-.562-.518.562.519c-1.119 1.143-2.894 1.049-3.885-.195l-.003-.003-.976-1.238-.003-.004c-.257-.33-.555-.446-.797-.446Zm6.972.79.001.04c-.003-.035-.001-.053-.001-.04ZM4.28 19.748l-.642.421-.001-.002-.002-.001-.003-.005-.008-.013a1.143 1.143 0 0 1-.085-.159C3.124 19.12 3 17.991 3 16.59V7.41c0-1.395.123-2.52.534-3.388a.759.759 0 0 1 .188-.284c.464-.528 1.128-.728 1.83-.578.661.14 1.307.576 1.905 1.185.48.459 1.147.41 1.553-.1l.95-1.201C10.478 2.368 11.215 2 11.994 2c.789 0 1.522.386 2.033 1.042l.002.001L15 4.275l.001.001c.413.518 1.073.549 1.534.078l.001-.001c.717-.731 1.524-1.24 2.361-1.24a1.8 1.8 0 0 1 1.357.597.773.773 0 0 1 .224.336c.401.865.522 1.982.522 3.364v9.18c0 1.386-.121 2.505-.525 3.37a.803.803 0 0 1-.19.305c-.359.418-.855.623-1.388.623-.837 0-1.643-.51-2.36-1.24l-.002-.002c-.469-.479-1.133-.44-1.529.071l-.004.005-.973 1.234C13.511 21.63 12.774 22 11.995 22c-.789 0-1.521-.386-2.033-1.041l-.001-.003-.95-1.2H9.01c-.407-.513-1.086-.557-1.552-.113-.595.612-1.236 1.053-1.895 1.2-.728.162-1.414-.056-1.886-.622a.772.772 0 0 1-.04-.052l.643-.421Zm3.562-9.174c0-.415.347-.752.775-.752h7.746c.428 0 .775.337.775.752a.764.764 0 0 1-.775.752H8.617a.764.764 0 0 1-.775-.752Zm.775 2.488a.764.764 0 0 0-.775.752c0 .415.347.752.775.752h5.81a.764.764 0 0 0 .774-.752.764.764 0 0 0-.775-.752h-5.81Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnReceipt;
