import { css } from 'styled-components';

const shadow = (x: string, y: string, alpha: number) => css`
  box-shadow: 0px ${x} ${y} rgba(64, 73, 104, ${alpha});
`;

export class Shadow {
  public static readonly Light1 = shadow('4px', '4px', 0.06);
  public static readonly Light2 = shadow('8px', '12px', 0.06);
  public static readonly Light3 = shadow('16px', '24px', 0.06);
  public static readonly Light4 = shadow('24px', '40px', 0.06);
  public static readonly Medium1 = shadow('4px', '4px', 0.12);
  public static readonly Medium2 = shadow('8px', '12px', 0.12);
  public static readonly Medium3 = shadow('16px', '24px', 0.12);
  public static readonly Medium4 = shadow('24px', '40px', 0.12);
  public static readonly Bold1 = shadow('4px', '4px', 0.24);
  public static readonly Bold2 = shadow('8px', '12px', 0.24);
  public static readonly Bold3 = shadow('16px', '24px', 0.24);
  public static readonly Bold4 = shadow('24px', '40px', 0.24);
  public static readonly Error = '0 0 0 2px rgba(240, 68, 56, 0.2)';
}
