//@ts-nocheck
import React, { Component } from "react";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, message, Row, Card, Modal } from "antd";
import Summary from "../payrollprovider/summary";
import _ from "lodash";
import { If, Loading, LoadingModal } from "../../../../Common/ui";
import SwiftMessage from "../../../../constants/message";
import AmendmentSummary from "../payrollprovider/amendmentSummary";
import InvoiceDetails from "../invoiceDetails";
import ClaimWrapper from "../../../../Common/claimWrapper";
import {
  PayrunSteps,
  ToogleStaticContent,
  ExportToXero,
} from "../../../../Common/payrun";
import { INVOICE_ALREADY_EXPORTED_TO_XERO } from "../../../../constants/errors";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import {
  ADHOC_PAYRUN_ID,
  OUTSOURCE_MODEL_ID,
  PAID_INVOICE_STATUS,
  PAID_PAYRUN_STATUS,
  PEO_MODEL_ID,
} from "../../../../constants/payrun";
import {
  PayrunBeingProcessedIcon,
  PayrunCompletedIcon,
  QuestionIcon,
} from "../../../../Common/customicons";
import InformationDisplayCard from "../../common/informationDisplaycard";
import PayrunDownloadOptions from "../downloadOptions";
import ShowVariance from "../showVariance";
import {
  showPayInvoice,
  showPayrunComplete,
  showPayrunFinalize,
  showConfirmPayment,
  displayStaticContent,
  showFinalizePayrun,
  showCompletePayrun,
  showInvoiceDetails,
  canClearInvoiceInterval,
  showProcesssingPayment,
  isScheduledPayrun,
  handleRequest,
  errorHandler,
  getConditionalResponse,
  getFileExtension,
  mustBeArray,
  isPayrunCompleted,
  canClearFilesInterval,
  autoGenerateAbaFile,
} from "../../../../libs";
import SimpleButton from "../../../../Common/ClaimComponents/Button/SimpleButton";
import UploadReportsButton from "../uploadReportsButton";
import UploadReportsModal from "../uploadReportsModal";
import { EarningLinesTable } from "../EarningLines";
import {
  PAYMENT_IN_PROGRESS_BUTTON,
  PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER,
  PAYRUN_COMPLETE_BUTTON,
  PAYRUN_CONFIRM_PAYMENT_BUTTON,
  PAYRUN_INVOICE_DETAIL_CONTAINER,
  PAYRUN_MAKE_PAYMENT_BUTTON,
  PAYRUN_PROCESS_BUTTON,
  PAYRUN_REPORT_UPLOAD_BUTTON,
  PAYRUN_SUMMARY_GENERAL_CONTAINER,
} from "../../../../constants";
import { withRouter } from "../../../../hooks";

type State = any;
class PayrunProcessDetail extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      tableLoading: true,
      summaryLoading: false,
      summary: [],
      total: <Loading />,
      bu_loading: false,
      externalEarninglines: [],
      invoice: {},
      feesDetails: [],
      exportLoading: false,
      processLoading: false,
      clearLoading: false,
      loadingText: "Exporting to XERO",
      viewLoadingModal: false,
      showVariance: true,
      highLightVarianceValue: 5,
      filterApplied: "",
      hideStaticContent: false,
      paymentLoading: false,
      showPaymentModal: false,
      paymentConfirmLoading: false,
      viewUploadReportsModal: false,
      payrunReports: [],
      payrunReportDownloadClaim: {},
      enableDownloadOptions: false,
      viewLoadingModal: false,
    };
  }
  componentDidMount() {
    const { payrun } = this.props;
    this.updateHeader(payrun);
    this.setState({ tableLoading: true, summaryLoading: true });

    // Get the invoice data and repeat until data is returned
    this.getInvoiceDetails(true);
    this.getInvoiceInterval = setInterval(
      this.getInvoiceDetails.bind(this),
      10000
    );

    // get the paryun files (invoice, summary, reports) every 10 seconds until invoice and summary are returned
    this.getFiles(true);
    this.getFilesInterval = setInterval(this.getFiles.bind(this), 10000);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps?.payrun !== this.props.payrun) {
      this.updateHeader(newProps.payrun);
    }
    if (newProps.reimportEarninglines) {
      this.handleReimportEarnings();
    }
  }

  // This section is a duplication from prepare stage
  // Should be fine in the new version. Couldn't find a better way to implement this in a class component
  handleReimportEarnings = () => {
    this.props.updateReimportEarninglines(false);
    if (!this.props.payrun) return;
    const {
      payrunId,
      payrollSystemConnectionId,
      externalBusinessUnitId,
      externalPayRunId,
    } = this.props.payrun;
    this.setState({
      viewLoadingModal: true,
      loadingText: "Reimporting Earninglines in progress",
    });
    this.props
      .reimportEarnings({
        id: payrunId,
        values: {
          externalBusinessUnitId,
          externalPayRunId,
          payrollSystemConnectionID: payrollSystemConnectionId,
          payRunID: payrunId,
        },
      })
      .then((resp) => {
        if (!resp.status) {
          errorHandler({ response: resp, hasValidationErrors: true });
          this.setState({ viewLoadingModal: false });
          return;
        }
        this.getInvoiceDetails();
      });
  };

  // Cancel all repeating API calls on unmount
  componentWillUnmount() {
    clearInterval(this.getFilesInterval);
    clearInterval(this.getInvoiceInterval);
  }

  // fetch files from API (Invoice pdf, summary pdf, uploaded reports) This is called every 10s until the interval is cleared
  getFiles = (pageLoad) => {
    const { payrun } = this.props;
    this.props.getPayrunReports(payrun.payrunId).then((resp: any) => {
      if (handleRequest({ response: resp })) {
        this.setState({ payrunReports: resp.data });
        if (
          pageLoad &&
          autoGenerateAbaFile({ payrun, payrunReports: resp.data })
        ) {
          this.exportAba();
        }
      }
      // Check if interval can be cleared (reponse has both invoice and summary files)
      if (canClearFilesInterval({ response: resp, payrun })) {
        clearInterval(this.getFilesInterval);
        this.setState({ enableDownloadOptions: true });
      }
    });
  };

  updateHeader = (payrun) => {
    const status = getConditionalResponse({
      condition: isPayrunCompleted(payrun?.payrunStatusID),
      resp1: "Completed",
      resp2: "Finalised",
    });

    this.props.updateHeader({
      header: {
        title: `Pay Run : ${payrun?.name}`,
        action: "",
        entity: "payrun",
        enableBack: true,
      },
    });
  };

  checkXeroProcessing = () => {
    const searchLocation = window?.location.search;
    if (searchLocation === "?xerologin=success") {
      this.setState(
        { loadingText: "Successfully logged into XERO. Exporting!" },
        () => {
          this.exportToXero();
        }
      );
    }
  };

  handleFilterChange = ({ label, value }) => {
    this.setState({ [label]: value });
  };

  //Get the details/data from the invoice API. This is called every 10s until the interval is cleared
  getInvoiceDetails = (initialLoad = false) => {
    const { payrun, getInvoice, getPayrun } = this.props;
    getInvoice({ id: payrun?.payrunId }).then((response) => {
      this.setState(
        {
          externalEarninglines: response.data?.invoiceEarningLines,
          invoice: response.data,
          viewLoadingModal: false,
        },
        () => {
          if (initialLoad) {
            this.checkXeroProcessing(); // Only called on first page load
          }
        }
      );
      if (canClearInvoiceInterval({ response, payrun })) {
        getPayrun({
          id: payrun?.payrunId,
          options: {},
        });
        this.checkIfVarianceIsEnabled(
          payrun,
          response.data,
          response.data?.invoiceEarningLines
        );
        clearInterval(this.getInvoiceInterval);
        this.setState({ tableLoading: false });
      }
    });
  };

  processPayRun = (
    processAfterConfirmation = () => this.processAfterConfirmation()
  ) => {
    if (
      this.props.payrun &&
      Number(this.props.payrun.payrollModelId) === 1 &&
      this.props.businessunitDetails &&
      !this.props.businessunitDetails.paymentMandatory &&
      [1, 2].includes(Number(this.state.invoice?.invoiceStatusId))
    ) {
      Modal.confirm({
        title: "Payment hasn't been completed!",
        content: "Are you sure you want to continue finalizing the payrun?",
        onOk() {
          processAfterConfirmation();
        },
        onCancel() {
          console.log("");
        },
      });
    } else {
      processAfterConfirmation();
    }
  };

  processAfterConfirmation() {
    this.setState({ processLoading: true, bu_loading: true });

    this.props

      .confirmProcessingPayrun({ id: this.props.payrun?.payrunId })

      .then((response) => {
        this.setState({ processLoading: false, bu_loading: false });

        if (response.status) {
          message.success(SwiftMessage["confirmProcessing.success"]);

          this.props.getPayrun({
            id: this.props.payrun?.payrunId,
            options: {},

            cancelToken: this.signal.token,
          });

          this.props

            .getInvoice({ id: this.props.payrun?.payrunId })

            .then((resp) => {
              if (resp.status && resp.data) {
                this.setState((prevState) => {
                  prevState.externalEarninglines =
                    resp.data.invoiceEarningLines;

                  prevState.invoice = resp.data;
                  return prevState;
                });
              } else {
                errorHandler({ response: res, hasValidationErrors: true });
              }
            });
        } else {
          errorHandler({ response: res, hasValidationErrors: true });
        }
      });
  }

  completePayrun() {
    this.setState({ bu_loading: true });

    this.props

      .completePayrun({ id: this.props.payrun?.payrunId })

      .then((response) => {
        this.setState({ bu_loading: false });

        if (response.status) {
          this.exportAba(true);
          message.success(SwiftMessage["complete.success"]);
          this.props.router.navigate("/payrun");
        } else {
          errorHandler({ response: res, hasValidationErrors: true });
        }
      });
  }

  exportToXero = (newContact) => {
    if (!_.isEmpty(this.state?.invoice?.invoiceExportDetails))
      return message.error("Invoice has already been exported to Xero");

    this.setState({ exportLoading: true, viewLoadingModal: true });

    this.props
      .exportTo({
        id: this.props?.payrun?.payrunId,
        invoiceId: this.state?.invoice?.id,
        action: "exportToXero",
        newContact,
      })

      .then((response) => {
        this.setState({ exportLoading: false });
        if (!response.status) {
          switch (response?.data?.validationErrors?.errors?.[0].code) {
            case INVOICE_ALREADY_EXPORTED_TO_XERO:
              this.handleInvoiceExportedToXero();
              break;
            case "EXPIRED-TOKEN-VALIDATION-ERROR-00076":
              this.handleXeroExpiredToken();
              break;
            case "MISSING-XERO-CUSTOMER-ERROR-00090":
              this.handleMissingCustomerInXero();
              break;
            case "XERO-EXCEPTION-VALIDATION-ERROR-00081":
              this.handleInvalidXeroConfig();
              break;
            default:
              this.setState({
                viewLoadingModal: false,
                loadingText: "Exporting to XERO",
              });
              message.error(
                "Unable to export to XERO. Please contact your System Admin."
              );
              break;
          }
        } else {
          this.setState({
            viewLoadingModal: false,
            loadingText: "Exporting to XERO",
          });
          message.success("Exported to XERO successfully");

          this.props

            .getInvoice({ id: this.props.payrun?.payrunId })

            .then((resp) => {
              if (resp.status && resp.data) {
                this.setState({
                  externalEarninglines: resp.data.invoiceEarningLines,

                  invoice: resp.data,
                });
              } else {
                errorHandler({ response: res, hasValidationErrors: true });
              }
            });
        }
        this.handleRedirectionFromXero();
      });
  };

  // Invoice is already exported to xero
  handleInvoiceExportedToXero = () => {
    this.setState({
      exportLoading: false,
      viewLoadingModal: false,
    });

    this.props.getInvoice({ id: this.props?.payrun?.payrunId }).then((resp) => {
      if (resp.status && resp.data) {
        this.setState({
          externalEarninglines: resp.data.invoiceEarningLines,
          invoice: resp.data,
        });
      } else {
        errorHandler({ response: res, hasValidationErrors: true });
      }
    });
    message.error("Invoice has already been exported to Xero");
  };

  // Xero configuration has error
  handleInvalidXeroConfig = () => {
    this.setState(
      { viewLoadingModal: false, loadingText: "Exporting to XERO" },
      () => {
        Modal.warning({
          title: "Invalid XERO Configuration",
          content:
            "Please contact your system admin to check your Billing Service Provider Setting",
          okText: "Close",
          onOk: () => {
            console.log("");
          },
        });
      }
    );
  };

  // If record doesn't exist in xero
  handleMissingCustomerInXero = () => {
    this.setState(
      { viewLoadingModal: false, loadingText: "Exporting to XERO" },
      () => {
        Modal.confirm({
          title: "Contact doesn't exist in Xero",

          icon: <ExclamationCircleOutlined />,
          content: "Would you like to create a new contact in Xero?",
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            return this.exportToXero(true);
          },
          onCancel: () => {
            console.log("");
          },
        });
      }
    );
  };

  // If the token has expired in xero
  handleXeroExpiredToken = () => {
    this.setState({ loadingText: "Redirecting to XERO" });
    this.props
      .xeroCallback({
        returnUrl: `${window.location?.href}?xerologin=success`,
      })
      .then((resp) => {
        if (resp.status) {
          window.open(resp.data?.result, "_self", false);
        }
      });
  };

  // If redirected from xero - remove the search param in the url
  handleRedirectionFromXero = () => {
    if (window?.location?.search) {
      this.props.router.navigate(`/payrun/${this.props?.payrun?.payrunId}`);
    }
  };

  // Generates an ABA Bank file for the payrun
  exportAba = (payrunCompleted = false) => {
    return this.props
      .exportABA({ id: this.props.payrun?.payrunId, data: {} })
      .then((response) => {
        if (response?.status && !payrunCompleted) {
          this.getFiles();
        }
      });
  };

  paymentOption = (payrun) => {
    if (
      this.state.invoice &&
      ![1, 2, 4].includes(Number(this.state.invoice.invoiceStatusId))
    ) {
      this.setState({ paymentLoading: true, paymentConfirmLoading: true });

      this.props.confirmPayment({ id: payrun?.payrunId }).then((response) => {
        if (response.status) {
          this.setState({
            paymentLoading: false,
            showPaymentModal: false,
            paymentConfirmLoading: false,
          });
          message.success(SwiftMessage["confirmPayment.success"]);

          this.props.getPayrun({ id: this.props.payrun?.payrunId });

          this.props

            .getInvoice({ id: this.props.payrun?.payrunId })

            .then((resp) => {
              if (resp.status && resp.data) {
                this.setState((prevState) => {
                  prevState.externalEarninglines =
                    resp.data.invoiceEarningLines;

                  prevState.invoice = resp.data;
                  return prevState;
                });
              }
            });
        } else {
          this.setState({
            paymentLoading: false,
            paymentConfirmLoading: false,
          });

          errorHandler({ response: res, hasValidationErrors: true });
        }
      });
    } else {
      this.makePayment();
    }
  };

  makePayment = () => {
    this.setState({ paymentLoading: true, paymentConfirmLoading: true });

    this.props

      .makePayment({ id: this.props.payrun?.payrunId })

      .then((response) => {
        if (response.status) {
          this.setState({
            paymentLoading: false,
            showPaymentModal: false,
            paymentConfirmLoading: false,
          });
          message.success(SwiftMessage["processingPayment.success"]);

          this.props.getPayrun({ id: this.props.payrun?.payrunId });

          this.props
            .getInvoice({ id: this.props.payrun?.payrunId })
            .then((resp) => {
              if (resp.status && resp.data) {
                this.setState((prevState) => {
                  prevState.externalEarninglines =
                    resp.data.invoiceEarningLines;

                  prevState.invoice = resp.data;
                  return prevState;
                });
              } else {
                errorHandler({ response: res, hasValidationErrors: true });
              }
            });
        } else {
          this.setState({ paymentLoading: false });

          errorHandler({ response: res, hasValidationErrors: true });
        }
      });
  };

  toggleShowVariance = ({ showVariance }) => {
    this.setState({ showVariance });
  };
  // checks if variance info should be shown and sets local state showVariance<boolean>
  checkIfVarianceIsEnabled = (payrun, invoice, processTableList) => {
    if (
      payrun?.payrunTypeID === ADHOC_PAYRUN_ID ||
      (payrun?.payrollModelId === PEO_MODEL_ID &&
        PAID_PAYRUN_STATUS.includes(Number(payrun?.payrunStatusID))) ||
      (payrun?.payrollModelId === OUTSOURCE_MODEL_ID &&
        PAID_INVOICE_STATUS.includes(Number(invoice.invoiceStatusId)))
    ) {
      this.setState({ showVariance: false });
    } else {
      let showVariance = false;
      mustBeArray(processTableList).forEach((list) => {
        if (list?.payrunSummary?.[1]) {
          showVariance = true;
        }
      });
      this.setState({ showVariance });
    }
  };

  updateVarianceValue = (value) => {
    this.setState({ highLightVarianceValue: value });
  };

  handleIconClick = () => {
    this.setState({ hideStaticContent: !this.state.hideStaticContent });
  };

  handleUploadReportsClick = () => {
    this.setState({ viewUploadReportsModal: true });
  };

  closeUploadReportsModal = () => {
    this.setState({ viewUploadReportsModal: false });
  };

  render() {
    const {
      loadingText,
      viewLoadingModal,
      viewUploadReportsModal,
      payrunReports,
    } = this.state;

    return (
      <React.Fragment>
        <PayrunSteps
          claims={this.props.pageClaims}
          invoice={this.state.invoice}
        />
        {getConditionalResponse({
          condition: showInvoiceDetails({
            payrun: this.props.payrun,
            invoice: this.state.invoice,
          }),
          resp1: (
            <div>
              <ClaimWrapper
                name={PAYRUN_INVOICE_DETAIL_CONTAINER}
                claims={this.props.pageClaims}
              >
                <Card className="rounded-[12px]">
                  <InvoiceDetails
                    invoice={this.state.invoice}
                    payrun={this.props.payrun}
                  />
                </Card>
              </ClaimWrapper>
            </div>
          ),
          resp2: null,
        })}
        <Row gutter={DEFAULT_GUTTER} className="ml-0">
          <Col
            className="mb-4 pl-0"
            lg={{ span: 16, order: 1 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
          >
            {!this.state.hideStaticContent && (
              <InformationDisplayCard
                title={getConditionalResponse({
                  condition: isPayrunCompleted(
                    this.props.payrun?.payrunStatusID
                  ),
                  resp1: "Your Pay Run is now Complete.",
                  resp2: "Your Pay Run is being Finalised.",
                })}
                description={getConditionalResponse({
                  condition: isPayrunCompleted(
                    this.props.payrun?.payrunStatusID
                  ),
                  resp1: (
                    <React.Fragment>
                      <div>You have successfully completed your pay run.</div>
                      <div>
                        You can create the next pay run from your dashboard.
                      </div>
                    </React.Fragment>
                  ),
                  resp2: (
                    <React.Fragment>
                      <div>Your pay run is currently being finalised.</div>
                      <div>
                        You will be notified when it has been completed.
                      </div>
                    </React.Fragment>
                  ),
                })}
                ActionComponent={() => (
                  <React.Fragment>
                    <PayrunDownloadOptions
                      claims={this.props.pageClaims}
                      classname="mt-4 mr-4"
                      disabled={!this.state.enableDownloadOptions}
                      payrunReports={payrunReports}
                      handleReportDownload={this.props.downloadPayrunReports}
                      title={
                        !this.state.enableDownloadOptions
                          ? "Generating Invoice/Summary PDF"
                          : ""
                      }
                    />
                    <UploadReportsButton
                      handleUploadClick={this.handleUploadReportsClick}
                      claims={this.props.pageClaims}
                      componentName={PAYRUN_REPORT_UPLOAD_BUTTON}
                    />
                  </React.Fragment>
                )}
                IconComponent={getConditionalResponse({
                  condition: isPayrunCompleted(
                    this.props.payrun?.payrunStatusID
                  ),
                  resp1: PayrunCompletedIcon,
                  resp2: PayrunBeingProcessedIcon,
                })}
                payrun={this.props.payrun}
                invoice={this.state.invoice}
                claims={this.props.pageClaims}
              />
            )}
            <ToogleStaticContent
              hideStaticContent={this.state.hideStaticContent}
              claims={this.props.pageClaims}
              handleIconClick={this.handleIconClick}
            />
            <If
              condition={isScheduledPayrun({ payrun: this.props.payrun })}
              then={
                <ShowVariance
                  showVariance={this.state.showVariance}
                  highLightVarianceValue={this.state.highLightVarianceValue}
                  updateVarianceValue={this.updateVarianceValue}
                  toggleShowVariance={this.toggleShowVariance}
                  filterApplied={this.state.filterApplied}
                  handleFilterChange={this.handleFilterChange}
                />
              }
            />
            <EarningLinesTable
              className="invoice-list"
              pageClaims={this.props.pageClaims}
              loading={this.state.tableLoading}
              list={this.state.externalEarninglines}
              businessunitDetails={this.props.businessunitDetails}
              invoice={this.state.invoice}
              payrun={this.props.payrun}
              toggleShowVariance={this.toggleShowVariance}
              showVariance={this.state.showVariance}
              highLightVarianceValue={this.state.highLightVarianceValue}
              filterApplied={this.state.filterApplied}
            />

            <div>
              <ExportToXero
                pageClaims={this.props.pageClaims}
                invoice={this.state.invoice}
                loading={this.state.exportLoading}
                onClick={this.exportToXero}
              />

              <div className="flex justify-end mt-4">
                <If
                  condition={this.state.hideStaticContent}
                  then={
                    <React.Fragment>
                      <PayrunDownloadOptions
                        payrun={this.props.payrun}
                        invoice={this.state.invoice}
                        claims={this.props.pageClaims}
                        classname="mr-2"
                        disabled={!this.state.enableDownloadOptions}
                        payrunReports={payrunReports}
                        handleReportDownload={this.handleReportDownload}
                      />
                      <If
                        condition={isPayrunCompleted(
                          this.props.payrun?.payrunStatusID
                        )}
                        then={
                          <UploadReportsButton
                            handleUploadClick={this.handleUploadReportsClick}
                            claims={this.props.pageClaims}
                            componentName={PAYRUN_REPORT_UPLOAD_BUTTON}
                          />
                        }
                      />
                    </React.Fragment>
                  }
                />

                <If
                  condition={showPayrunFinalize({
                    payrun: this.props.payrun,
                    businessunitDetails: this.props.businessunitDetails,
                  })}
                  then={
                    <SimpleButton
                      className="bg-success ml-4 float-right rounded-lg"
                      onClick={() => this.processPayRun()}
                      buttonText="Finalise"
                      claims={this.props.pageClaims}
                      loading={this.state.bu_loading}
                      name={PAYRUN_PROCESS_BUTTON}
                    />
                  }
                />

                {getConditionalResponse({
                  condition: showPayrunComplete({
                    payrun: this.props.payrun,
                    businessunitDetails: this.props.businessunitDetails,
                  }),
                  resp1: (
                    <SimpleButton
                      className="bg-success ml-2.5 rounded-lg float-right"
                      onClick={() => this.completePayrun()}
                      buttonText="Complete"
                      claims={this.props.pageClaims}
                      loading={this.state.bu_loading}
                      name={PAYRUN_COMPLETE_BUTTON}
                    />
                  ),
                  resp2: <React.Fragment />,
                })}

                {getConditionalResponse({
                  condition: showPayInvoice({
                    payrun: this.props.payrun,
                    invoice: this.state.invoice,
                    businessunitDetails: this.props.businessunitDetails,
                  }),
                  resp1: (
                    <SimpleButton
                      className="float-right mb-4 ml-4 rounded-lg"
                      onClick={() => this.paymentOption(this.props.payrun)}
                      buttonText="Pay Invoice"
                      claims={this.props.pageClaims}
                      loading={this.state.paymentLoading}
                      name={PAYRUN_MAKE_PAYMENT_BUTTON}
                      type="primary"
                    />
                  ),
                  resp2: <React.Fragment />,
                })}

                {getConditionalResponse({
                  condition: showConfirmPayment({
                    payrun: this.props.payrun,
                    invoice: this.state.invoice,
                    businessunitDetails: this.props.businessunitDetails,
                  }),
                  resp1: (
                    <SimpleButton
                      className="bg-success ml-4 rounded-lg"
                      onClick={() => this.paymentOption(this.props.payrun)}
                      buttonText="Confirm Payment"
                      claims={this.props.pageClaims}
                      loading={this.state.paymentLoading}
                      name={PAYRUN_CONFIRM_PAYMENT_BUTTON}
                    />
                  ),
                  resp2: <React.Fragment />,
                })}

                <If
                  condition={this.props.status === "invoiceUnpaid"}
                  then={
                    <div className="float-right">
                      {getConditionalResponse({
                        condition: showProcesssingPayment({
                          payrun: this.props.payrun,
                          businessunitDetails: this.props.businessunitDetails,
                        }),
                        resp1: (
                          <SimpleButton
                            className="approveContainer-content__paymentOption ml-4 rounded-lg"
                            onClick={() =>
                              this.paymentOption(this.props.payrun)
                            }
                            buttonText="Processing Payment"
                            claims={this.props.pageClaims}
                            loading={this.state.paymentConfirmLoading}
                            name={PAYMENT_IN_PROGRESS_BUTTON}
                          />
                        ),
                        resp2: null,
                      })}
                    </div>
                  }
                />

                {/* Display button to finalise pay run */}
                <If
                  condition={showFinalizePayrun({
                    payrun: this.props.payrun,
                    businessunitDetails: this.props.businessunitDetails,
                  })}
                  then={
                    <SimpleButton
                      className="bg-success ml-4 rounded-lg"
                      onClick={() => this.processPayRun()}
                      buttonText="Finalise"
                      claims={this.props.pageClaims}
                      loading={this.state.bu_loading}
                      name={PAYRUN_PROCESS_BUTTON}
                    />
                  }
                  else={<React.Fragment />}
                />

                {/* Display Button to complete pay run */}
                <If
                  condition={showCompletePayrun({
                    payrun: this.props.payrun,
                    businessunitDetails: this.props.businessunitDetails,
                  })}
                  then={
                    <SimpleButton
                      className="bg-success ml-4 rounded-lg"
                      onClick={() => this.completePayrun()}
                      buttonText="Complete"
                      claims={this.props.pageClaims}
                      loading={this.state.bu_loading}
                      name={PAYRUN_COMPLETE_BUTTON}
                    />
                  }
                  else={<React.Fragment />}
                />
              </div>
              <div className="clearfix" />
            </div>
          </Col>
          <Col
            className="mb-4"
            lg={{ span: 8, order: 2 }}
            md={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
          >
            {getConditionalResponse({
              condition: displayStaticContent({
                payrun: this.props.payrun,
                hideStaticContent: this.state.hideStaticContent,
              }),
              resp1: (
                <InformationDisplayCard
                  IconComponent={QuestionIcon}
                  title={"Have a question?"}
                  cardClassName="min-h-[158px]"
                  description={
                    <React.Fragment>
                      Made a mistake, needs to add more amendments or make
                      changes? Use the 'Comments' functionality above.
                    </React.Fragment>
                  }
                />
              ),
              resp2: <React.Fragment />,
            })}

            <Row gutter={DEFAULT_GUTTER}>
              <Col md={12} sm={24} lg={24}>
                <ClaimWrapper
                  name={PAYRUN_SUMMARY_GENERAL_CONTAINER}
                  claims={this.props.pageClaims}
                >
                  <Summary
                    title="Summary"
                    loading={this.state.loading}
                    summary={this.state.externalEarninglines}
                    feesDetails={this.state.feesDetails}
                    invoice={this.state.invoice}
                    classname="defaultListHeader"
                    businessunitDetails={this.props.businessunitDetails}
                    showVariance={this.state.showVariance}
                  />
                </ClaimWrapper>
              </Col>
              <Col md={12} sm={24} lg={24}>
                <ClaimWrapper
                  name={PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER}
                  claims={this.props.pageClaims}
                >
                  <ClaimWrapper
                    name="payrunAmendmentApproveRejectCustomTable"
                    claims={this.props.claims}
                  >
                    <AmendmentSummary
                      title="Amendments"
                      amendmentSummary={[...this.props.amendments]}
                      getAmendmentHistory={this.props.getAmendmentHistory}
                      className="mb-4 mt-4"
                      payrun={this.props.payrun}
                    />
                  </ClaimWrapper>
                </ClaimWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
        <LoadingModal visible={viewLoadingModal} loadingText={loadingText} />
        <UploadReportsModal
          isModalVisible={viewUploadReportsModal}
          handleCancel={this.closeUploadReportsModal}
          payrun={this.props.payrun}
          refreshPayrunReportList={() =>
            this.getFiles(this.props.payrun?.payrunId)
          }
          payrunReports={this.state.payrunReports}
        />
        <LoadingModal
          visible={this.state.viewLoadingModal}
          loadingText={this.state.loadingText}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PayrunProcessDetail);
