//@ts-nocheck
import React from "react";
import { Card } from "antd";
import { tableColumnGenerator } from "../../../../libs/table";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import _ from "lodash";
import { mustBeArray } from "../../../../libs/utilities";

class AuditReportDeductionList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  render() {
    const { data, loading } = this.props;
    let colTemplate = [
      {
        title: "Employee Name",
        dataIndex: "employeeName",
        sorter: false,
        width: "20%",
      },
      {
        title: "Deduction Type",
        dataIndex: "deductionCategoryName",
        sorter: false,
        width: "20%",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        sorter: false,
        width: "20%",
      },
      {
        title: "Payment Reference",
        dataIndex: "paymentReference",
        sorter: false,
        width: "25%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: false,
        align: "right",
        dataType: "currency",
        width: "15%",
      },
    ];
    const column = tableColumnGenerator(colTemplate);
    const finalData = _.filter(mustBeArray(data), (o) => o.paymentReference);
    return (
      <Card
        title={
          <div className="pt-3 pl-3">
            Deductions
            <div className="float-right pr-3 text-xs font-normal">
              Note that super deductions are listed with super payments
            </div>
            <div className="clearfix"></div>
          </div>
        }
        className="card-space-0"
      >
        <SimpleTableContainer
          columns={column}
          dataSource={finalData}
          loading={loading}
          onChange={this.handleTableChange}
          pagination={false}
          size="small"
          className="table-showing-total"
        />
      </Card>
    );
  }
}

export default AuditReportDeductionList;
