import React from "react";
import SliceListProvider from "../../../../Common/SliceListProvider";
import List from "./List";

type Props = {
  visible?: boolean;
  id?: string;
  payrunID?: string;
};

const AmendmentActionHistory = ({ visible, id, payrunID }: Props) => (
  <React.Fragment>
    <SliceListProvider>
      <List visible={visible} id={id} payrunID={payrunID} />
    </SliceListProvider>
  </React.Fragment>
);

export default AmendmentActionHistory;
