import { PayrunStepper } from '@xemplo/payrun-stepper';
import { PayrunTitle } from '@xemplo/payrun-title';

import * as S from './payrun-header.styles';

export function PayrunHeader() {
  return (
    <S.HeaderContainer>
      <PayrunTitle />
      <PayrunStepper />
    </S.HeaderContainer>
  );
}

export default PayrunHeader;
