//@ts-nocheck
import { Component } from "react";
import AuditReportLeaveList from "../../components/payrun/invoice/AuditReportLeaveList";
import AuditReportPAYGAdjustmentList from "../../components/payrun/invoice/AuditReportPAYGAdjustmentList";
import AuditReportSuperSummaryList from "../../components/payrun/invoice/AuditReportSuperSummaryList";
import AuditReportBankRecordList from "../../components/payrun/invoice/AuditReportBankRecordsList";
import AuditReportPayrunTotalList from "../../components/payrun/invoice/AuditReportPayrunTotalList";
import AuditReportDeductionList from "../../components/payrun/invoice/AuditReportDeductionList";
import AuditReportEmployerLiabilities from "../../components/payrun/invoice/AuditReportEmpoyerLiabilities";
import AuditReportExpenseReimbursementList from "../../components/payrun/invoice/AuditReportExpenseReimbursementList";
import AuditReportPayrunSummary from "../../components/payrun/invoice/AuditReportPayrunSummary";
import _ from "lodash";
import {
  mustBeArray,
  errorDisplay,
  parseItems,
  delay,
} from "../../../libs/utilities";
import axios from "axios";
import { Spin } from "antd";
import AuditReportEarningList from "../../components/payrun/invoice/AuditReportEarningList";
import {
  DEFAULT_ROUTES,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { errorHandler, payrunIsSkipped } from "../../../libs";
import { withRouter } from "../../../hooks";

type State = any;

class PayrunAuditReport extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      pageLoading: true,
      payrunTotalList: [],
      leaveAccruedList: [],
      leaveTakenList: [],
      paygAdjustmentList: [],
      bankPaymentList: [],
      deductionList: [],
      expenseReimbursementList: [],
      employerLiabilitesList: [],
      earningList: [],
      payPeriodStarting: "",
      payPeriodEnding: "",
      datePaid: "",
      payscheduleName: "",
      frequency: "",
      paySlipMessage: "",
      employeesPaid: 0,
    };
  }
  componentDidMount() {
    const { id } = this.props.match && this.props.match.params;
    this.props.updateHeader({
      header: {
        title: "",
        module: "Pay Run",
        enableBack: true,
        action: "",
        returnUrl: `/payrun/${id}`,
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayRunAuditReport,
      },
    });
    this.props
      .getPayrun({ id, options: {} })

      .then((resp) => {
        if (resp.status && resp.data) {
          this.setState({ loading: false, feesLoading: false });
          this.props.updateHeader({
            header: {
              title: resp.data.name,
              enableBack: true,
              action: "",
            },
          });
          this.props.getBusinessUnit({
            id: resp.data.businessUnitID,
            cancelToken: this.signal.token,
          });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
    let auditReport;

    this.props
      .getAuditReport({ id, options: {}, cancelToken: this.signal.token })
      .then((resp) => {
        delay(200).then(() => {
          this.setState({ pageLoading: false });
        });
        if (resp.status) {
          auditReport = resp.data;
          let employeesReportArray = [];
          let employerLiabilitesArray = [];
          let deductionArray = [];
          let totalsArray = [];
          let totalsObject =
            auditReport &&
            parseItems(auditReport.auditReport) &&
            parseItems(parseItems(auditReport.auditReport).totals);
          const paySlips =
            auditReport &&
            parseItems(auditReport.auditReport) &&
            parseItems(parseItems(auditReport.auditReport).paySlips);
          try {
            employeesReportArray = mustBeArray(Object.values(paySlips));
            deductionArray = mustBeArray(
              Object.values(
                auditReport &&
                  parseItems(auditReport.auditReport) &&
                  parseItems(parseItems(auditReport.auditReport).deductions)
              )
            );
            employerLiabilitesArray = mustBeArray(
              Object.values(
                auditReport &&
                  parseItems(auditReport.auditReport) &&
                  parseItems(
                    parseItems(auditReport.auditReport).employerLiabilities
                  )
              )
            );

            // Append employee name in totals json
            _.forEach(Object.keys(paySlips), (index) => {
              totalsObject[index] = {
                ...totalsObject[index],
                employeeName: paySlips[index].employeeName,
              };
            });
            totalsArray = mustBeArray(Object.values(totalsObject));
          } catch (error) {
            console.log(error);
          }
          // Earnings

          const earningList = this.getEarningList(employeesReportArray);
          // Expense reimbursement

          const expenseReimbursementList =
            this.getExpenseReimbursementList(employeesReportArray);
          // Liabilities list

          const liabilitiesList = this.getLiabilitiesList(
            employerLiabilitesArray
          );
          // Deduction List

          const deductionList = this.getDeductionList(deductionArray);
          // Accrued Leave

          const leaveAccruedList =
            this.getLeaveAccruedList(employeesReportArray);
          // Leave taken

          const leaveTakenList = this.getLeaveTakenList(employeesReportArray);
          // PAYG Adjustments

          const paygAdjustmentList =
            this.getPaygAdjustmentList(employeesReportArray);
          // Super Summary List

          let superSummaryList = this.getSuperSummaryList(employeesReportArray);
          // Bank Payment List

          const bankPaymentList = this.getBankPaymentList(employeesReportArray);
          // Payrun total list

          let updatedPayrunTotalList = this.getPayrunTotalList(totalsArray);

          this.setState({
            leaveTakenList,
            leaveAccruedList,
            paygAdjustmentList,
            superSummaryList,
            bankPaymentList,
            payrunTotalList: updatedPayrunTotalList,
            employeesPaid: mustBeArray(employeesReportArray).length,
            deductionList,
            employerLiabilitesList: liabilitiesList,
            expenseReimbursementList,
            earningList,
          });
          if (auditReport) {
            this.setState({
              payPeriodStarting: auditReport.payPeriodStart,
              payPeriodEnding: auditReport.payPeriodEnd,
              datePaid: auditReport.datePaid,
              payscheduleName: auditReport.payscheduleName,
              frequency: auditReport.payOccurenceName,
              paySlipMessage: auditReport.paySlipMessage,
            });
          }
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  }

  getEarningList = (employeesReportArray) => {
    let tempEarningList = employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.earningsLines
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
    return tempEarningList;
  };

  getExpenseReimbursementList = (employeesReportArray) => {
    let tempExpensesList = employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.employeeExpenses
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
    const amount = _.sumBy(tempExpensesList, (o) => o.amount);
    return _.isEmpty(tempExpensesList)
      ? []
      : [...tempExpensesList, { employeeName: "Total", amount }];
  };

  getLiabilitiesList = (employerLiabilitesArray) => {
    let liabilitiesList = employerLiabilitesArray.reduce((a, eachEmployee) => {
      return a.concat([...eachEmployee]);
    }, []);
    const amount = _.sumBy(liabilitiesList, (o) => o.amount);
    liabilitiesList = _.isEmpty(liabilitiesList)
      ? []
      : [
          ...liabilitiesList,
          {
            employeeName: "Total",
            amount,
          },
        ];
    return liabilitiesList;
  };

  getDeductionList = (employeesReportArray) => {
    let deductionList = employeesReportArray.reduce((a, eachEmployee) => {
      return a.concat([...eachEmployee]);
    }, []);
    const amount = _.sumBy(deductionList, (o) => o.amount);
    deductionList = _.isEmpty(deductionList)
      ? []
      : [
          ...deductionList,
          {
            employeeName: "Total",
            amount,
          },
        ];
    return deductionList;
  };

  getSuperSummaryList = (employeesReportArray) => {
    let superSummaryList = employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.superPayments
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
    const superAmountTotal = _.sumBy(superSummaryList, (o) => o.amount);
    superSummaryList = _.isEmpty(superSummaryList)
      ? []
      : [
          ...superSummaryList,
          {
            employeeName: "Total",
            amount: superAmountTotal,
            paymentType: "",
            fundName: "",
            memberNumber: "",
          },
        ];
    return superSummaryList;
  };

  getPayrunTotalList = (employeesReportArray) => {
    const totalHours = _.sumBy(employeesReportArray, (o) => o.totalHours);
    const grossEarnings = _.sumBy(employeesReportArray, (o) => o.grossEarnings);
    const preTaxDeductions = _.sumBy(
      employeesReportArray,
      (o) => o.preTaxDeductions
    );
    const postTaxDeductions = _.sumBy(
      employeesReportArray,
      (o) => o.postTaxDeductions
    );
    const paygWithheld = _.sumBy(employeesReportArray, (o) => o.paygWithheld);
    const taxableEarnings = _.sumBy(
      employeesReportArray,
      (o) => o.taxableEarnings
    );
    const netEarnings = _.sumBy(employeesReportArray, (o) => o.netEarnings);
    const helpWithheld = _.sumBy(employeesReportArray, (o) => o.helpWithheld);
    const superContribution = _.sumBy(
      employeesReportArray,
      (o) => o.superContribution
    );
    const employerContribution = _.sumBy(
      employeesReportArray,
      (o) => o.employerContribution
    );
    return _.isEmpty(employeesReportArray)
      ? []
      : [
          ...employeesReportArray,
          {
            employeeName: "Total",
            totalHours,
            grossEarnings,
            preTaxDeductions,
            postTaxDeductions,
            taxableEarnings,
            paygWithheld,
            netEarnings,
            helpWithheld,
            superContribution,
            employerContribution,
          },
        ];
  };

  getBankPaymentList = (employeesReportArray) => {
    let tempBankPaymentList = employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.bankPayments
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
    const amount = _.sumBy(tempBankPaymentList, (o) => o.amount);
    return _.isEmpty(tempBankPaymentList)
      ? []
      : [...tempBankPaymentList, { employeeName: "Total", amount }];
  };

  getPaygAdjustmentList = (employeesReportArray) => {
    let paygAdjustmentList = employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.paygAdjustments
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
    const amount = _.sumBy(paygAdjustmentList, (o) => o.amount);
    return _.isEmpty(paygAdjustmentList)
      ? []
      : [...paygAdjustmentList, { amount, employeeName: "Total" }];
  };

  getLeaveTakenList = (employeesReportArray) => {
    return employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.leaveTaken
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
  };

  getLeaveAccruedList = (employeesReportArray) => {
    return employeesReportArray.reduce((a, eachEmployee) => {
      const reducedEmployeeLeaveList = mustBeArray(
        eachEmployee.accruedLeave
      ).reduce((b, data) => {
        return b.concat([{ ...data, employeeName: eachEmployee.employeeName }]);
      }, []);
      return a.concat(reducedEmployeeLeaveList);
    }, []);
  };
  render() {
    const {
      leaveAccruedList,
      leaveTakenList,
      paygAdjustmentList,
      superSummaryList,
      bankPaymentList,
      payrunTotalList,
      payPeriodStarting,
      payPeriodEnding,
      datePaid,
      payscheduleName,
      frequency,
      paySlipMessage,
      employeesPaid,
      pageLoading,
      deductionList,
      expenseReimbursementList,
      employerLiabilitesList,
      earningList,
      // taskList, warningList
    } = this.state;
    // <AuditReportPayrunTaskList data={taskList} />
    //         <AuditReportWarningList data={warningList} />
    return pageLoading ? (
      <Spin />
    ) : (
      <div className="mb-6">
        <AuditReportPayrunSummary
          payPeriodStarting={payPeriodStarting}
          payPeriodEnding={payPeriodEnding}
          datePaid={datePaid}
          payscheduleName={payscheduleName}
          frequency={frequency}
          paySlipMessage={paySlipMessage}
          employeesPaid={employeesPaid}
        />
        <AuditReportPayrunTotalList data={payrunTotalList} />
        <AuditReportSuperSummaryList data={superSummaryList} />
        <AuditReportDeductionList data={deductionList} />
        <AuditReportPAYGAdjustmentList data={paygAdjustmentList} />
        <AuditReportExpenseReimbursementList data={expenseReimbursementList} />
        <AuditReportEmployerLiabilities data={employerLiabilitesList} />
        <AuditReportBankRecordList data={bankPaymentList} />
        <AuditReportLeaveList data={leaveAccruedList} />
        <AuditReportLeaveList data={leaveTakenList} leaveType="taken" />
        <AuditReportEarningList data={earningList} />
      </div>
    );
  }
}

export default withRouter(PayrunAuditReport);
