// @ts-nocheck
import { parseResponseData, ResponseHandler } from "../../libs";
import { lookupAction } from "../../constants/action-type/index";
import { mustBeArray, requestBuilder } from "../../libs/utilities";
import APIHandler from "../../constants/apiUrl";
import axios, { CancelToken } from "axios";
import { setLookupData } from "../../slices/lookups";
import environment from "../../constants/environment";
import { BusinessUnitType, ReduxPromiseResponse } from "../../types";

const { COUNTRIES, STATES, OPERATIONTIMEZONE } = lookupAction;

export function countriesLookup({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        // page: 0,
        per_page: 0,
        ...options,
      };
      const url = APIHandler.constructEndpoint({
        endpoint: "COUNTRIES_LOOKUP",
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, COUNTRIES)
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function populateStates({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "STATE_LOOKUP",
        options: { id: options.countryId },
      });
      const response = await requestBuilder(
        null,
        url,
        { per_page: 0 },
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, STATES)
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// Lookup Amendment types
export function amendmentTypeLookup() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_TYPE_URL",
        options: {},
      });
      const response = await requestBuilder(null, url);
      if (ResponseHandler.isValidStatus(response)) {
        let data = mustBeArray(parseResponseData(response));
        return resolve(
          dispatch(
            setLookupData({
              name: "amendmentType",
              data,
            })
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// returns array of amendment change types based on supplied amendment type ID.
///return all change types with no changeTypeId param
export function amendmentChangeTypeLookup({ options = {}, changeTypeId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        ...options,
        q: {},
      };

      if (changeTypeId) {
        queryOptions.q = { ...queryOptions.q, changeTypeId };
      }
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_TASK_BY_TYPE_URL",
        options: {},
      });
      const response = await requestBuilder(null, url, queryOptions);
      if (ResponseHandler.isValidStatus(response)) {
        let data = mustBeArray(parseResponseData(response));
        return resolve(
          dispatch(
            setLookupData({
              name: "amendmentChangeType",
              data,
            })
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function timezoneLookup({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        per_page: 0,
        ...options,
      };
      const url = APIHandler.constructEndpoint({ endpoint: "TIMEZONE_LOOKUP" });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, OPERATIONTIMEZONE)
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function banksLookup({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        per_page: 0,
        ...options,
      };
      const url = APIHandler.constructEndpoint({
        endpoint: "BANKS_LOOKUP",
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, COUNTRIES)
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export const lookUpHandler = ({ options = {}, cancelToken, type }) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        per_page: 500,
        ...options,
      };
      let url = "";
      let response = {};
      switch (type) {
        case "company":
          url = APIHandler.constructEndpoint({
            endpoint: "COMPANY_LOOKUP_URL",
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          break;
        case "paySchedule":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYSCHEDULE_LOOKUP_URL",
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          break;
        case "pspUsers":
          url = APIHandler.constructEndpoint({ endpoint: "PSP_USERS_URL" });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, "LOOKUPS")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
};

type LookupType =
  | "reoccurringAmendmentPeriod"
  | "holidaysPeriod"
  | "timeZone"
  | "templateUrl";

type LookupOptions = {
  type: LookupType;
  cdn_url?: string;
};

const getUrl = ({ type, cdn_url }: LookupOptions) => {
  const url = cdn_url || environment.cdn_url;
  switch (type) {
    case "reoccurringAmendmentPeriod":
      return `${url}/reoccuringPeriodOptions.json?timestamp=${new Date().getTime()}`;
    case "holidaysPeriod":
      return `${url}/calendarSettings.json?timestamp=${new Date().getTime()}`;
    case "timeZone":
      return `${url}/operatingTimeZone.json?timestamp=${new Date().getTime()}`;
    case "templateUrl":
      return `${url}?timestamp=${new Date().getTime()}`;
    default:
      return "";
  }
};

export const cdnLookUpHandler = async (
  options: LookupOptionsm,
  cancelToken?: CancelToken
) => {
  // The cache lib seems to be broken with new version of axios
  // Need to revisit this later

  // const cache = setupCache({
  //   maxAge: 0,
  //   exclude: {
  //     query: false,
  //   },
  // });

  const client = axios.create({
    // adapter: cache.adapter,
    headers: { "Cache-Control": "no-cache" },
  });

  const url = getUrl(options);
  const response = await client.get(url);
  const result = ResponseHandler.isValidStatus({ status: response.status })
    ? ResponseHandler.validObject(response.data, "LOOKUPS")
    : ResponseHandler.inValidObject(response);

  return result;
};

// Amendment Requested By Users List
export function amendmentRequestedByLookup() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "GENERAL_AMENDMENTS_REQUESTED_BY_URL",
        options: {},
      });
      const response = await requestBuilder(null, url);
      if (ResponseHandler.isValidStatus(response)) {
        let data = mustBeArray(parseResponseData(response));
        return resolve(
          dispatch(
            setLookupData({
              name: "amendmentRequestedBy",
              data,
            })
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// Business Unit Lookup
export function businessUnitLookup({
  options = { per_page: 0 },
  companyId,
}: {
  options?: { q?: any; per_page?: number };
  companyId?: string;
}): ReduxPromiseResponse<BusinessUnitType[]> {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "BUSINESS_UNIT_URL",
        options: {},
      });
      const response = await requestBuilder(companyId, url, options);
      if (ResponseHandler.isValidStatus(response)) {
        let data = mustBeArray(parseResponseData(response));
        return resolve(
          dispatch(
            setLookupData({
              name: "businessUnits",
              data,
            })
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// Lookup for business units available with reports access enabled
export const getReportBusinessUnitList = async ({ options = {} }) => {
  const url: string = APIHandler.constructEndpoint({
    endpoint: "REPORT_BUSINESS_UNIT_URL",
    options: {},
  });
  const response = await requestBuilder(null, url, options);
  return response;
};
