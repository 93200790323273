//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Card, message, Layout, Row, Col } from "antd";
import _ from "lodash";
import {
  mustBeArray,
  getUserArea,
  errorDisplay,
  parseItems,
  isUserSystemAdmin,
  getMatchParams,
  getSelectedRoleTenantId,
  errorHandler,
} from "../../../libs";
import FormNavbar from "../../../Layout/FormNavbar";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import axios from "axios";
import AssignRoleCompanyUser from "../../components/users/assignRoleCompanyUserForm";
import SystemAdminAssignRolesUserForm from "../../components/users/systemAdminAssignUserRolesForm";
import AssignedRolesList from "../../components/users/assignedRolesList";
import userRoles from "../../../constants/userRoles";
import UserBasicDetailsForm from "../../components/payrollProvider/UserBasicDetailsForm";
import { FormStepsCol } from "../../../Common/ui";
import { withRouter } from "../../../hooks";
import { connect } from "react-redux";

const { Header, Content } = Layout;

type State = any;

class PSPUserCreate extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      userRolesList: [],
      payrollProviderList: [],
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        },
        {
          id: 2,
          label: "Assign Roles",
          description:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
        },
      ],
      activeIndex: "1",
      userDetails: {},
      assignedRolesList: [],
      editMode: false,
      userDetail: {},
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.fetchPayrollProviders();
    this.fetchUserRoles();
    if (params && params.id) {
      this.setState({ editMode: true });
      this.getUserDetail(params.id, params.payrollProviderId);
    }
  }
  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  getServiceProviderId = () => {
    let searchPrarams =
      window.location.search && window.location.search.split("=");
    return searchPrarams && searchPrarams[1];
  };
  getUserDetail = (id) => {
    const { member } = this.props;
    let payrollProviderId =
      getSelectedRoleTenantId({ member }) || this.getServiceProviderId();
    let queryParams = payrollProviderId
      ? { serviceProviderId: payrollProviderId }
      : {};
    this.props
      .listPSPUsers({
        id,
        serviceProviderId: payrollProviderId,
        options: { q: queryParams },
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        if (resp.status && resp.data) {
          this.setState({ userDetail: resp.data });
          this.props.updateHeader({
            header: {
              title: `${resp.data.firstName} ${resp.data.lastName}`,
            },
          });
          const {
            mobilePhone,
            firstName,
            lastName,
            getNotifications,
            emailAddress,
            mfaEnable,
            deviceEnrolmentEnable,
            phone,
            profilePhoto,
          } = resp.data;
          this.setState({ profilePhoto });
          this.props.form.setFieldsValue({
            mobilePhone,
            firstName,
            lastName,
            getNotifications: getNotifications ? 1 : 2,
            emailAddress,
            phone,
          });
          if (isUserSystemAdmin({ member: this.props.member })) {
            this.props.form.setFieldsValue({
              mfaEnable: mfaEnable ? 1 : 2,
              deviceEnrolmentEnable,
            });
          }

          let userPersona = mustBeArray(resp.data.userPersona);
          const assignedRolesList = userPersona.reduce((a, b) => {
            const tenant = parseItems(b.tenant);
            return [
              ...a,
              {
                roleName: b.roleName,
                roleId: b.roleId,
                payrollProviderName: tenant && tenant.tenantName,
                payrollServiceProviderId: tenant && tenant.tenantId,
              },
            ];
          }, []);
          if (this.displayViewForSystemAdmin()) {
            this.setState({ assignedRolesList });
          } else {
            this.setState((prevState) => {
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
              prevState.userRolesList.map((role, i) => {
                const selectedPersona = _.find(
                  userPersona,
                  (o) =>
                    o.roleId === role.id &&
                    parseItems(o.tenant) &&
                    parseItems(o.tenant).tenantId ===
                      getSelectedRoleTenantId({ member })
                );
                role.isSelected = selectedPersona ? true : false;
                return role;
              });
              return prevState;
            });
            this.forceUpdate();
          }
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'displayViewForSystemAdmin'.
  displayViewForSystemAdmin = () => {
    const id = getMatchParams({ match: this.props.match, field: "id" });
    const payrollProviderId = getMatchParams({
      match: this.props.match,
      field: "payrollProviderId",
    });
    if (id && [userRoles.SystemAdminArea].includes(getUserArea())) return true;
    if (
      [userRoles.SystemAdminArea].includes(getUserArea()) &&
      !payrollProviderId
    )
      return true;
    return false;
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fetchUserRoles'.
  fetchUserRoles = () => {
    this.props
      .listUserRoles({
        area: "PS",
        options: {},

        cancelToken: this.signal.token,
      })

      .then((resp) => {
        if (resp.status) {
          this.setState({ userRolesList: mustBeArray(resp.data) });
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fetchPayrollProviders'.
  fetchPayrollProviders = (value) => {
    this.props
      .listPayrollProviders({
        options: { per_page: 500 },
        cancelToken: this.signal.token,
      })

      .then((resp) => {
        if (resp.status) {
          this.setState({ payrollProviderList: resp.data });
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const form = this.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (Number(this.state.activeIndex) === 1) {
        return this.setState(
          {
            activeIndex: "2",
            userDetails: { ...values },
          },
          () => {
            if (isUserSystemAdmin({ member: this.props.member })) {
              this.setState({
                userDetails: {
                  ...this.state.userDetails,
                  mfaEnable: Number(values.mfaEnable) === 1,
                },
              });
            }
          }
        );
      }
      let userPersona = [];

      const { match } = this.props;
      const payrollProviderId = getMatchParams({
        match,
        field: "payrollProviderId",
      });

      if (this.displayViewForSystemAdmin()) {
        userPersona = this.state.assignedRolesList.reduce((a, b) => {
          return [
            ...a,
            {
              roleId: b.roleId,
              payrollServiceProviderId: b.payrollServiceProviderId,
            },
          ];
        }, []);
      } else {
        let selectedRoles = _.filter(
          this.state.userRolesList,
          (o) => o.isSelected
        );
        userPersona = mustBeArray(selectedRoles).reduce((a, b) => {
          return [
            ...a,
            {
              roleId: b.id,
              payrollServiceProviderId: payrollProviderId
                ? payrollProviderId
                : getSelectedRoleTenantId({ member: this.props.member }),
            },
          ];
        }, []);
      }
      if (_.isEmpty(userPersona)) {
        return message.error("Please select a user role");
      }

      this.setState({ submitLoading: true });
      let payload = {
        ...this.state.userDetails,
        addressId: 0,
        getNotifications:
          Number(
            this.state.userDetails && this.state.userDetails.getNotifications
          ) === 1,
        userPersona,
      };

      let actionName = this.props.registerPSPUsers;
      let finalPayLoad = { data: payload };

      if (this.state.editMode) {
        actionName = this.props.handlePSPUserActions;

        payload.id = getMatchParams({ match: this.props.match, field: "id" });
        finalPayLoad = {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ data: any; id: any; action: string; }' is ... Remove this comment to see the full error message
          data: payload,
          id: getMatchParams({ match: this.props.match, field: "id" }),
          action: "update",
        };
      }

      actionName(finalPayLoad).then((resp) => {
        this.setState({ submitLoading: false });

        if (resp.status) {
          message.success(
            `User ${
              this.state.editMode ? "updated" : "registered"
            } successfully`
          );

          this.handleCancel();
        } else {
          errorDisplay(resp && resp.data, true);
        }
      });
    });
  };

  handleCancel = () => {
    const { header } = this.props;

    this.props.form.resetFields();

    this.setState({ activeIndex: "1" });
    if (header.returnUrl) {
      this.props.router.navigate(header.returnUrl);
    } else {
      this.props.router.navigate("/users");
    }
  };

  handleDelete = ({ record }) => {
    this.setState((prevState) => {
      prevState.assignedRolesList = _.filter(
        prevState.assignedRolesList,
        (o) => o !== record
      );
      return prevState;
    });
  };

  checkSubmitDisabled = () => {
    if (Number(this.state.activeIndex) === 1) return true;
    return false;
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'handleRoleChange'.
  handleRoleChange = ({ value, record }) => {
    this.setState((prevState) => {
      prevState.userRolesList = prevState.userRolesList.map((data) => {
        return {
          ...data,
          isSelected: value && data.id === record.id,
        };
      });
      return prevState;
    });
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'displaySystemAdminUserForm'.
  displaySystemAdminUserForm = () => {
    return true;
  };
  handleBack = () => {
    this.setState({ activeIndex: `${Number(this.state.activeIndex) - 1}` });
  };
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'addRoles'.
  addRoles = () => {
    const { form } = this.props;
    const payrollServiceProviderId = form.getFieldValue("serviceProviderId");

    const payrollProviderName =
      _.find(
        this.state.payrollProviderList,
        (o) => o.id === payrollServiceProviderId
      ) &&
      _.find(
        this.state.payrollProviderList,
        (o) => o.id === payrollServiceProviderId
      ).name;
    const roleId = form.getFieldValue("roleId");

    const roleName =
      _.find(this.state.userRolesList, (o) => o.id === roleId) &&
      _.find(this.state.userRolesList, (o) => o.id === roleId).name;
    if (!payrollServiceProviderId)
      return message.error("Please select a payroll service provider");
    if (!roleId) return message.error("Please select a role");

    if (
      _.find(
        this.state.assignedRolesList,
        (o) =>
          o.roleId === roleId &&
          o.payrollServiceProviderId === payrollServiceProviderId
      )
    ) {
      return message.error(
        `User has already been assigned ${roleName} role for ${payrollProviderName}`
      );
    }

    this.setState(
      {
        assignedRolesList: [
          ...this.state.assignedRolesList,
          {
            payrollServiceProviderId,
            payrollProviderName,
            roleId,
            roleName,
          },
        ],
      },
      () => {
        form.resetFields(["serviceProviderId", "roleId"]);
      }
    );
  };
  render() {
    const { form, member, header } = this.props;
    const getFieldDecorator = form && form.getFieldDecorator;
    const {
      submitLoading,
      activeIndex,
      formLabels,
      userRolesList,
      payrollProviderList,
      assignedRolesList,
      editMode,
      userDetail,
      profilePhoto,
    } = this.state;
    return (
      <Form autoComplete="off">
        <Layout className="h-[100vh]">
          {
            <React.Fragment>
              <Header className="bg-white w-full position-absolute-top">
                <FormNavbar
                  handleCancel={this.handleCancel}
                  handleSave={this.handleSubmit}
                  htmlType="submit"
                  loading={submitLoading}
                  disabled={this.checkSubmitDisabled()}
                  enableBack
                  header={header}
                />
              </Header>
              <Layout className="p-6 rounded-[5px] create-screen-body overflow-y-auto w-full">
                <Content>
                  <Row gutter={DEFAULT_GUTTER} className="form-label-left">
                    <FormStepsCol data={formLabels} activeIndex={activeIndex} />
                    <Col md={18} sm={24}>
                      {
                        <div
                          className={
                            Number(activeIndex) === 1 ? "block" : "display-none"
                          }
                        >
                          <Card>
                            <UserBasicDetailsForm
                              profilePhoto={profilePhoto}
                              form={form}
                              fieldRequired={Number(activeIndex) !== 2}
                              editMode={editMode}
                              member={member}
                              type={"psp"}
                              userDetail={userDetail}
                            />
                            <div>
                              <Button
                                onClick={this.handleSubmit}
                                // type="primary"
                                className="float-right bg-success rounded-[20px]"
                                loading={submitLoading}
                              >
                                Continue
                              </Button>
                              <div className="clearfix"></div>
                            </div>
                          </Card>
                        </div>
                      }
                      {
                        <div
                          className={
                            Number(activeIndex) === 2 ? "block" : "display-none"
                          }
                        >
                          {this.displayViewForSystemAdmin() ? (
                            <React.Fragment>
                              <Card>
                                <SystemAdminAssignRolesUserForm
                                  userRolesList={userRolesList}
                                  handleRoleChange={this.handleRoleChange}
                                  getFieldDecorator={getFieldDecorator}
                                  form={this.props.form}
                                  type="psp"
                                  payrollProviderList={payrollProviderList}
                                  addRoles={this.addRoles}
                                  assignedRolesList={assignedRolesList}
                                />
                              </Card>
                              <Card className="ant-card card-space-0">
                                <AssignedRolesList
                                  type={"psp"}
                                  data={assignedRolesList}
                                  handleDelete={this.handleDelete}
                                />
                              </Card>
                              <Button
                                onClick={this.handleBack}
                                className="mb-4 rounded-[20px]"
                              >
                                Back
                              </Button>
                            </React.Fragment>
                          ) : (
                            <Card>
                              <AssignRoleCompanyUser
                                userRolesList={userRolesList}
                                handleRoleChange={this.handleRoleChange}
                                getFieldDecorator={getFieldDecorator}
                                form={this.props.form}
                                type="psp"
                                handleDelete={this.handleDelete}
                                displaySwitch={true}
                              />
                              <Button
                                onClick={this.handleBack}
                                className="mb-4 rounded-[20px]"
                              >
                                Back
                              </Button>
                            </Card>
                          )}
                        </div>
                      }
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </React.Fragment>
          }
        </Layout>
      </Form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    header: state.pageHeader.header,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(Form.create({ name: "companyUserForm" })(PSPUserCreate)));
