import {
  VISIBLE_DISABLED_STATUS_NAME,
  VISIBLE_ENABLED_ID,
  VISIBLE_STATUS_NAME,
} from "../constants/defaultClaims";
import { ComponentClaimType, ContainerClaimType } from "../types/claims";
import { isComponentVisible } from "./utilities";

export const getPinnedClassName = ({ isPinned }: any) => {
  return isPinned === "true" ? "pinned" : "";
};

export const getPinnableComponentTitle = ({ isPinned }: any) => {
  return isPinned === "true"
    ? "Unpin from the dashboard"
    : "Pin in the dashboard";
};

export const displayPinComponent = ({ claim }: any) => {
  return claim &&
    Number(claim.authorizationStatusId) === VISIBLE_ENABLED_ID &&
    claim.pinnable === "true"
    ? true
    : false;
};

export const ignoreClaimValidation = ({
  ignoreStatus,
  claimAndStatus = {},
}: any) => {
  return ignoreStatus ? "" : claimAndStatus?.status;
};

export const displayActionComponent = ({
  claim = {},
  dashboardView = false,
}: any) => {
  return claim &&
    Number(claim.authorizationStatusId) === VISIBLE_ENABLED_ID &&
    claim.pinnable === "true" &&
    !dashboardView
    ? true
    : false;
};

export const checkTargetEndpoint = ({ targetEndpoint = "", url }: any) => {
  return targetEndpoint ? targetEndpoint : url;
};

export const showCreateAndGridTableToggleOption = ({
  claim,
  showAddAndGrid,
}: any) => {
  return claim &&
    (claim.addable === "true" || claim.gridable === "true") &&
    showAddAndGrid
    ? true
    : false;
};

export const isComponentVisibleEnabled = ({ claim }: any) => {
  return Number(claim?.authorizationStatusId) === VISIBLE_ENABLED_ID;
};

export const displayTableGridActionComponent = ({
  claim,
  defaultDisplay,
}: {
  claim: any;
  defaultDisplay: boolean;
}) => {
  return isComponentVisible({ claim }) || defaultDisplay;
};

export const getStatusClassName = ({
  claim,
}: {
  claim?: ContainerClaimType | ComponentClaimType;
}) => {
  switch (claim && claim.authorizationStatus) {
    case VISIBLE_STATUS_NAME:
      return "";
    case VISIBLE_DISABLED_STATUS_NAME:
      return "Visible-Disabled";
    default:
      return "Hidden";
  }
};
