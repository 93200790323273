import { Prettify } from '@xemplo/types';

import { ToastProps } from '../toast.types';

export enum ToastVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum ToastHorizontalPosition {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export type ToastPosition = {
  vertical: ToastVerticalPosition;
  horizontal: ToastHorizontalPosition;
};

export type AddToastProps = Prettify<
  ToastProps & { position?: ToastPosition; duration?: number }
>;
