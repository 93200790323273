import styled, { css } from 'styled-components';

import { zIndex } from '@xemplo/zindex';

import {
  ToastHorizontalPosition,
  ToastPosition,
  ToastVerticalPosition,
} from './toast-provider.types';

const ToastHorizontalPositionMap = {
  [ToastHorizontalPosition.Left]: css`
    left: 32px;
  `,
  [ToastHorizontalPosition.Center]: css`
    left: 50%;
    transform: translateX(-50%);
  `,
  [ToastHorizontalPosition.Right]: css`
    right: 32px;
  `,
};

const ToastVerticalPositionMap = {
  [ToastVerticalPosition.Top]: css`
    top: 32px;
  `,
  [ToastVerticalPosition.Bottom]: css`
    bottom: 32px;
  `,
};

const ToastAlignItemsMap = {
  [ToastHorizontalPosition.Left]: 'start',
  [ToastHorizontalPosition.Center]: 'center',
  [ToastHorizontalPosition.Right]: 'end',
};

export const ToastWrapper = styled.div<{ $position: ToastPosition }>`
  position: absolute;
  z-index: ${zIndex.ToastMessage};
  display: flex;
  flex-direction: column;
  align-items: ${({ $position }) => ToastAlignItemsMap[$position.horizontal]};
  ${({ $position }) => ToastHorizontalPositionMap[$position.horizontal]};
  ${({ $position }) => ToastVerticalPositionMap[$position.vertical]};
`;
