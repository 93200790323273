import React, { useMemo } from "react";
import { If } from "../../../../Common/ui";
import { CURRENT_EXTERNAL_EARLINGLINES_INDEX } from "../../../../constants";
import {
  currencyFormater,
  getVarianceRowClassName,
  isEmpty,
  mustBeArray,
} from "../../../../libs";
import EarningLinesFees from "./EarningLinesFees";
import EarningLinesPayItems from "./EarningLinesPayItems";
import EarningLinesSubPayItems from "./EarningLinesSubPayItems";

type Props = {
  showVariance: boolean;
  invoice: any;
  currentPayrunSummary: any;
  itemKey: any;
  record: any;
  columnClassName: string;
  highLightVarianceValue: number;
  businessunitDetails: any;
  expandedRows: Array<any>;
  processingVariance: any;
  previousPayrollProcessingTotal: number;
  payrollProcessingTotal: number;
  netWageVariance: any;
  previousPayItems: any;
  previousPayrunSummary: any;
  previousFees: any;
};
const EarningLinesExtendedRowData = ({
  showVariance,
  invoice,
  currentPayrunSummary,
  itemKey,
  record,
  columnClassName,
  highLightVarianceValue,
  businessunitDetails,
  expandedRows,
  processingVariance,
  previousPayrollProcessingTotal,
  payrollProcessingTotal,
  netWageVariance,
  previousPayItems,
  previousPayrunSummary,
  previousFees,
}: Props) => {
  const currentDeductions: Array<any> = useMemo(() => {
    return record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
      ?.payItems;
  }, [record]);
  const currentFees: Array<any> = useMemo(() => {
    return record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]?.fees;
  }, [record]);
  return (
    <div key={itemKey}>
      <div className="approveTable-expandSection">
        <If
          condition={Number(currentPayrunSummary?.employeeNetEarnings) !== 0}
          then={
            <React.Fragment>
              <div
                className={`process-table ${getVarianceRowClassName({
                  data: processingVariance,
                  highLightVarianceValue,
                  showVariance,
                })}`}
              >
                <div
                  className={`bold ${columnClassName}-processing-description pl-10`}
                >
                  &nbsp;Gross Income
                </div>
                <If
                  condition={showVariance && !isEmpty(invoice)}
                  then={
                    <div
                      className={`bold ${columnClassName}-processing-previousTaxes text-right`}
                    ></div>
                  }
                />
                <If
                  condition={showVariance}
                  then={
                    <div
                      className={`bold ${columnClassName}-processing-previousAmount text-right`}
                    >
                      {currencyFormater(
                        previousPayrollProcessingTotal,
                        businessunitDetails?.country
                      )}
                    </div>
                  }
                />
                <If
                  condition={!isEmpty(invoice)}
                  then={
                    <div
                      className={`bold ${columnClassName}-processing-taxes text-right`}
                    ></div>
                  }
                />
                <div
                  className={`bold ${columnClassName}-processing-amount text-right`}
                >
                  {currencyFormater(
                    payrollProcessingTotal,
                    businessunitDetails?.country
                  )}
                </div>
                <If
                  condition={showVariance}
                  then={
                    <div
                      className={`bold ${columnClassName}-processing-variance text-right`}
                    ></div>
                  }
                />
              </div>
              <EarningLinesSubPayItems
                itemKey={itemKey}
                currentPayrunSummary={currentPayrunSummary}
                highLightVarianceValue={highLightVarianceValue}
                columnClassName={columnClassName}
                showVariance={showVariance}
                invoice={invoice}
                businessunitDetails={businessunitDetails}
                expandedRows={expandedRows}
                previousPayrunSummary={previousPayrunSummary}
                netWageVariance={netWageVariance}
              />
            </React.Fragment>
          }
        />
      </div>
      {/* Deductions */}
      <If
        key={`currentDeductions`}
        condition={!isEmpty(mustBeArray(currentDeductions))}
        then={
          <EarningLinesPayItems
            data={currentDeductions}
            itemKey="deduction"
            previousPayItems={previousPayItems}
            highLightVarianceValue={highLightVarianceValue}
            columnClassName={columnClassName}
            showVariance={showVariance}
            invoice={invoice}
            businessunitDetails={businessunitDetails}
          />
        }
      />
      {/* Fees */}
      <If
        condition={!isEmpty(mustBeArray(currentFees))}
        key="currentFees"
        then={
          <EarningLinesFees
            data={currentFees}
            itemKey="fees"
            previousFees={previousFees}
            highLightVarianceValue={highLightVarianceValue}
            columnClassName={columnClassName}
            showVariance={showVariance}
            invoice={invoice}
            businessunitDetails={businessunitDetails}
          />
        }
      />
      <div
        className={`process-table ${getVarianceRowClassName({
          data: netWageVariance,
          highLightVarianceValue,
        })}`}
      >
        <div
          className={`${columnClassName}-processing-description pl-[46px] bold`}
        >
          Net Wage
        </div>
        <If
          condition={showVariance}
          then={
            <div
              className={`${columnClassName}-processing-previousTaxes text-right`}
            ></div>
          }
        />
        <If
          condition={showVariance}
          then={
            <div
              className={`${columnClassName}-processing-previousAmount text-right bold`}
            >
              {currencyFormater(
                previousPayrunSummary?.employeeNetEarnings,
                businessunitDetails?.country
              )}
            </div>
          }
        />
        <div className={`${columnClassName}-processing-taxes text-right`}></div>
        <div className={`${columnClassName}-processing-amount text-right bold`}>
          {currencyFormater(
            currentPayrunSummary?.employeeNetEarnings,
            businessunitDetails?.country
          )}
        </div>
        <If
          condition={showVariance}
          then={
            <div
              className={`bold ${columnClassName}-processing-variance text-right`}
            ></div>
          }
        />
      </div>
    </div>
  );
};
export default EarningLinesExtendedRowData;
