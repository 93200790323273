import { ReactNode } from 'react';

export type HeaderIconProps = {
  icon: ReactNode;
  mode: HeaderIconMode;
  isFullScreenModalIcon?: boolean;
};

export const HeaderIconMode = {
  Admin: 'admin',
  Worker: 'worker',
  Simple: 'simple',
  Shadowed: 'shadowed',
} as const;

export type HeaderIconMode = (typeof HeaderIconMode)[keyof typeof HeaderIconMode];
