import { RefObject, useCallback, useEffect, useState } from 'react';

import { useDrawer } from '../hooks/use-drawer';

export const useBodyScroll = (bodyRef: RefObject<HTMLDivElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const {
    drawerState: {
      config: { headerKeyline },
    },
  } = useDrawer();

  // The scroll event handler
  const onScroll = useCallback(
    (body: HTMLDivElement) => {
      const headerEl = document.querySelector('#drawer-header');
      const fadeEl = body.querySelector('#scroll-fade');
      const { scrollTop, scrollHeight, clientHeight } = body;

      // Add shadow to header element
      if (headerEl) {
        headerEl.classList.toggle('shadow', scrollTop > 0);
        if (headerKeyline === false) {
          headerEl.classList.toggle('keyline', scrollTop > 0);
        }
      }

      // Add/remove fade to bottom of scroll
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      fadeEl?.classList.toggle('hidden', isAtBottom);
    },
    [headerKeyline]
  );

  // Add scroll event listener to body
  useEffect(() => {
    const body = bodyRef.current;
    if (!body) return;

    body.addEventListener('scroll', () => onScroll(body), false);
    return () => {
      body.removeEventListener('scroll', () => onScroll(body), false);
    };
  }, [onScroll, bodyRef]);

  // This is to handle the initial rendering of the drawer
  // and check if the rendered content will overflow.
  useEffect(() => {
    if (!bodyRef.current) return;
    const { offsetHeight, scrollHeight } = bodyRef.current;
    setIsOverflowing(offsetHeight < scrollHeight);
  }, [bodyRef]);

  return { isOverflowing };
};
