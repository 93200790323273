import { Amendment } from '@xemplo/gp-types';
import { useModal } from '@xemplo/modal';

import { AmendmentDetailsModalBody } from './modal/components/body/amendment-details-modal-body';
import { AmendmentDetailModalFooter } from './modal/components/footer/amendment-details-modal-footer';
import { ModalHeader } from './modal/components/header/amendment-details-modal-header';

export const amendmentDetailsModalTestIds = {
  body: 'amendment-details-modal',
  footer: 'amendment-details-modal-footer',
  header: 'amendment-details-modal-header',
};

export const useAmendmentDetailsModal = () => {
  const { toggleModal, configureModal } = useModal();

  const updateModal = (amendment: Amendment) => {
    configureModal({
      open: true,
      width: 856,
      header: {
        customHeader: <ModalHeader amendment={amendment} />,
      },
      body: {
        content: <AmendmentDetailsModalBody amendment={amendment} />,
      },
      footer: {
        customFooter: <AmendmentDetailModalFooter amendment={amendment} />,
      },
      disableOutsideClick: true,
      onCloseCallback: () => toggleModal(false),
    });
  };

  return { updateModal };
};
