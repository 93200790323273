import * as S from './payrun-title.styles';

export const PayrunTitleSkeleton = () => (
  <S.TitleContainer>
    <S.NameContainer>
      <S.BusinessUnitName>
        <S.SkeletonLoader $width={200} />
      </S.BusinessUnitName>
      <S.PayrunName>
        <S.SkeletonLoader $width={300} />
      </S.PayrunName>
    </S.NameContainer>
    <S.Details>
      <S.DetailsItem>
        <S.DetailsItemLabel>Schedule</S.DetailsItemLabel>
        <S.DetailsItemValue>
          <S.SkeletonLoader />
        </S.DetailsItemValue>
      </S.DetailsItem>
      <S.DetailsItem>
        <S.DetailsItemLabel>Pay Cycle</S.DetailsItemLabel>
        <S.SkeletonLoader />
        <S.DetailsItemValue></S.DetailsItemValue>
      </S.DetailsItem>
      <S.DetailsItem>
        <S.DetailsItemLabel>Pay Date</S.DetailsItemLabel>
        <S.SkeletonLoader />
        <S.DetailsItemValue></S.DetailsItemValue>
      </S.DetailsItem>
    </S.Details>
  </S.TitleContainer>
);
