import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetTerminatedBusinessUnitWorkersQuery } from '@xemplo/lookups-query';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';

interface TerminatedWorkerOption {
  key: number | string;
  value: string;
}
export const TerminatedWorkers = () => {
  const { watch, resetField } = useFormContext();
  const watchBusinessUnit = watch('businessUnit');
  const watchAppliesTo = watch('appliesTo');
  const watchBUValue =
    watchBusinessUnit === PLACEHOLDER_OPTION ? null : watchBusinessUnit;
  const { data, isLoading } = useGetTerminatedBusinessUnitWorkersQuery({
    id: watchBUValue ?? null,
    requestParams: { per_page: 0 },
  });

  const formattedData = useMemo(() => {
    if (!data) return [];
    return data?.result?.rows.reduce((a: TerminatedWorkerOption[], b) => {
      return [...a, { ...b, key: b.employeeId, value: `${b.firstName} ${b.lastName}` }];
    }, []);
  }, [data]);

  useEffect(() => {
    return () => {
      resetField('terminatedWorkers');
    };
  }, [resetField]);

  return (
    <TextDropdownMultiV2
      items={formattedData}
      testId="terminatedWorkers"
      itemsLoading={isLoading}
      name="terminatedWorkers"
      displayProps={{
        isDisabled: isLoading || !formattedData.length,
      }}
      selectProps={{
        placeholder: 'Terminated worker(s)',
        label: 'Terminated worker(s)',
      }}
      rules={{
        required: watchAppliesTo?.length > 0 ? false : 'This field is required',
      }}
    />
  );
};
