//@ts-nocheck
import React from "react";
import { Table } from "antd";
import { tableColumnGenerator } from "../../../libs/table";
import {
  getPayScheduleListColumns,
  mustBeArray,
} from "../../../libs/utilities";

type State = any;

class PayScheduleList extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: [],
    };
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    this.props.handleTableChange({
      options: {
        page: pagination.current,
        order: sorter.order
          ? sorter.order === "ascend"
            ? "asc"
            : "desc"
          : sorter.order,
        field:
          sorter.field === "payOccurence" ? "payoccurrencename" : sorter.field,
      },
    });
  };

  render() {
    const { sortInfo, claims, component, per_page, data, view, dashboardView } =
      this.props;
    const pagination = {
      total: this.props.total,
      current: this.props.current,
      pageSize: per_page ? per_page : 20,
      hideOnSinglePage: true,
    };
    let colTemplate = getPayScheduleListColumns({
      view,
      sortInfo,
      claims,
      component,
      dashboardView
    });
    const tableColumn = tableColumnGenerator(colTemplate);
    return (
      <Table
        columns={tableColumn}
        rowKey={(record) => record.id}
        dataSource={mustBeArray(data)}
        pagination={dashboardView ? false : pagination}
        loading={this.props.loading}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default PayScheduleList;
