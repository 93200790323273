export const colors = {
  primary: "#0b78ff",
  danger: "#e6413d",
  warning: "#f3c200",
  success: "#47C144",
  defaultBackground: "#f0f2f5",
  borderOrange: "#FF7700",
  borderBlue: "#0B78FF",
  borderGreen: "#53D100",
  borderRed: "#E85656",
  neutral: "#373737",
  white: "#FFF",
  lightGray: "#ddd",
};
