import { CURRENCY_DIGIT_FORMAT } from "../constants/currency";
import { onlyDateFormater } from "./timeHandlers";
import { currencyFormater, mustBeArray } from "./utilities";
import numeral from "numeral";

// Variance Column
export const getVarianceColWidth = ({
  totalPeriod,
}: {
  totalPeriod?: number;
}): number => {
  switch (totalPeriod) {
    case 1:
      return 8;
    case 3:
      return 15;
    case 4:
      return 9;
    case 2:
    case 5:
    case 6:
      return 12;
    default:
      return 6;
  }
};

// Col displaying Standard deviation
export const getVarianceSDColWidth = ({
  totalPeriod,
}: {
  totalPeriod?: number;
}): number => {
  switch (totalPeriod) {
    case 1:
      return 10;
    case 2:
      return 14;
    case 3:
      return 10;
    case 4:
      return 5;
    case 5:
      return 11;
    default:
      return 12;
  }
};

// Label column
export const getVarianceLabelClassname = ({
  totalAvailablePeriod,
}: {
  totalAvailablePeriod: number;
}) => {
  switch (totalAvailablePeriod) {
    case 0:
    case 1:
      return "flex-1-1-60";
    case 2:
    case 3:
    case 4:
      return "flex-1-1-30";
    case 5:
      return "flex-1-1-38";
    default:
      return "flex-1-1-28";
  }
};

// Amount for each periods
export const getVarianceAmountColWidth = ({
  position,
  totalAvailablePeriod,
}: any) => {
  switch (position) {
    case 4:
      return 8;
    case 3:
      return 10;
    case 2:
      return totalAvailablePeriod === 3 ? 22 : 9;
    case 1:
      return totalAvailablePeriod === 2
        ? 30
        : totalAvailablePeriod === 3
        ? 20
        : 8;
    case 0:
      switch (totalAvailablePeriod) {
        case 2:
          return 22;
        case 3:
          return 15;
        case 4:
          return 10;
        case 5:
        case 6:
          return 10;
        default:
          return 14;
      }
    default:
      return 16;
  }
};

// Period Start and End date
export const getVarianceStartEndDate = ({ summaryIndex, periods }: any) => {
  let activePeriod = mustBeArray(periods);
  const startDate = activePeriod[summaryIndex - 1]?.startDate;
  const endDate = activePeriod[summaryIndex - 1]?.endDate;
  const period = `${onlyDateFormater(startDate)} - ${onlyDateFormater(
    endDate
  )}`;
  return period;
};

// Variance and Standard deviation calculation for each fields
export const calculateVarianceAndSD = ({ title, field, record, type }: any) => {
  let sumTotal, meanValue: any, rowVariance, rowSD, sumOfSquare;
  switch (type) {
    case "payItem":
      sumTotal = mustBeArray(record?.payrunSummary).reduce((a: any, b: any) => {
        let itemAmount =
          mustBeArray(b.payItems).find((o: any) => o.paymentItemId === title)
            ?.amount || 0;
        return a + itemAmount;
      }, 0);
      meanValue = (
        sumTotal / mustBeArray(record?.payrunSummary).length
      ).toFixed(2);
      sumOfSquare = mustBeArray(record?.payrunSummary).reduce(
        (a: any, b: any) => {
          let differenceValue =
            (mustBeArray(b.payItems).find((o: any) => o.paymentItemId === title)
              ?.amount || 0) - meanValue;
          let squaredMeanValue = Math.pow(differenceValue, 2);
          return a + squaredMeanValue;
        },
        0
      );
      break;
    case "payrollProcessing":
      sumTotal = mustBeArray(record?.payrunSummary).reduce((a: any, b: any) => {
        let itemAmount = b.payrollProcessing?.total;
        return a + itemAmount;
      }, 0);
      meanValue = (
        sumTotal / mustBeArray(record?.payrunSummary).length
      ).toFixed(2);
      sumOfSquare = mustBeArray(record?.payrunSummary).reduce(
        (a: any, b: any) => {
          let differenceValue = b.payrollProcessing?.total
            ? b.payrollProcessing?.total - meanValue
            : meanValue;
          let squaredMeanValue = Math.pow(differenceValue, 2);
          return a + squaredMeanValue;
        },
        0
      );

      break;
    case "netWage":
      sumTotal = mustBeArray(record?.payrunSummary).reduce((a: any, b: any) => {
        let itemAmount = b?.employeeNetEarnings;
        return a + itemAmount;
      }, 0);
      meanValue = (
        sumTotal / mustBeArray(record?.payrunSummary).length
      ).toFixed(2);
      sumOfSquare = mustBeArray(record?.payrunSummary).reduce(
        (a: any, b: any) => {
          let differenceValue = b?.employeeNetEarnings
            ? b?.employeeNetEarnings - meanValue
            : meanValue;
          let squaredMeanValue = Math.pow(differenceValue, 2);
          return a + squaredMeanValue;
        },
        0
      );
      break;
    case "subPayItem":
      sumTotal = mustBeArray(record?.payrunSummary).reduce((a: any, b: any) => {
        let itemAmount =
          mustBeArray(b.payrollProcessing?.subPayItems).find(
            (o) => o.name === title
          )?.amount || 0;
        return a + itemAmount;
      }, 0);
      meanValue = (
        sumTotal / mustBeArray(record?.payrunSummary).length
      ).toFixed(2);
      sumOfSquare = mustBeArray(record?.payrunSummary).reduce(
        (a: any, b: any) => {
          let differenceValue =
            (mustBeArray(b.payrollProcessing?.subPayItems).find(
              (o) => o.name === title
            )?.amount || 0) - meanValue;
          let squaredMeanValue = Math.pow(differenceValue, 2);
          return a + squaredMeanValue;
        },
        0
      );
      break;
    case "fees":
      sumTotal = mustBeArray(record?.payrunSummary).reduce((a: any, b: any) => {
        let itemAmount =
          mustBeArray(b?.fees).find((o: any) => o.feeOptionId === title)
            ?.amount || 0;
        return a + itemAmount;
      }, 0);
      meanValue = (
        sumTotal / mustBeArray(record?.payrunSummary).length
      ).toFixed(2);
      sumOfSquare = mustBeArray(record?.payrunSummary).reduce(
        (a: any, b: any) => {
          let differenceValue =
            (mustBeArray(b?.fees).find((o: any) => o.feeOptionId === title)
              ?.amount || 0) - meanValue;
          let squaredMeanValue = Math.pow(differenceValue, 2);
          return a + squaredMeanValue;
        },
        0
      );
      break;
    default:
      sumTotal = mustBeArray(record?.payrunSummary).reduce((a: any, b: any) => {
        return a + b[field];
      }, 0);
      meanValue = (
        sumTotal / mustBeArray(record?.payrunSummary).length
      ).toFixed(2);
      sumOfSquare = mustBeArray(record?.payrunSummary).reduce(
        (a: any, b: any) => {
          let differenceValue = b[field] - meanValue;
          let squaredMeanValue = Math.pow(differenceValue, 2);
          return a + squaredMeanValue;
        },
        0
      );
      break;
  }
  rowVariance =
    sumOfSquare.toFixed(2) / (mustBeArray(record?.payrunSummary).length - 1);
  rowSD = Math.sqrt(Number(rowVariance.toFixed(2)));
  return {
    variance: numeral(rowVariance).format(CURRENCY_DIGIT_FORMAT),
    sd: currencyFormater(rowSD),
  };
};

// Get amount and tax value for each items
export const getVarianceAmountAndTax = ({
  record,
  position,
  field,
  paymentItemName,
  subPayItemName,
  feeIndex,
  type,
  showTax,
}: any): any => {
  let amount;
  let taxAmount;
  let activePayrunSummary = record?.payrunSummary?.[position];
  switch (type) {
    case "paymentItemName":
      amount =
        mustBeArray(activePayrunSummary?.payItems).find(
          (o: any) => o.paymentItemName === paymentItemName
        )?.amount || 0;
      break;
    case "subPayItemName":
      amount = mustBeArray(
        activePayrunSummary?.payrollProcessing?.earningsLine
      ).reduce((a, b) => {
        return b.name === subPayItemName ? b.amount + a : a;
      }, 0);
      break;
    case "feeOptionName":
      let activeFeeItem = activePayrunSummary?.fees?.[feeIndex];
      amount = activeFeeItem?.amount || 0;
      if (showTax) {
        taxAmount = activeFeeItem?.taxFeeAmount || 0;
      }
      break;
    case "payrollProcessing":
      amount = activePayrunSummary?.payrollProcessing?.total;
      break;
    case "netWage":
      amount = activePayrunSummary?.employeeNetEarnings;
      break;
    default:
      amount = activePayrunSummary?.[field];
      break;
  }
  return { amount, taxAmount };
};
