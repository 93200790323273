//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import { getBillingProviderListColumns } from "../../../libs/utilities";

class BillinServiceProviderList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order
            ? sorter.order === "ascend"
              ? "asc"
              : "desc"
            : sorter.order,
          field:
            sorter.field === "businessUnitCount"
              ? "businessNumber"
              : sorter.field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options });
  };

  render() {
    const pagination = {
      total: this.props.totalPages,
      current: this.props.current,
      pageSize: this.props.per_page ? this.props.per_page : 20,
      hideOnSinglePage: true,
    };
    const { sortInfo, claims, component, dashboardView } = this.props;
    const colTemplate = getBillingProviderListColumns({
      sortInfo,
      claims,
      component,
      dashboardView
    });
    const column = tableColumnGenerator(colTemplate);
    return (
      <SimpleTableContainer
        columns={column}
        dataSource={this.props.data}
        pagination={pagination}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        dashboardView={dashboardView}
        rowIdParam="id"
      />
    );
  }
}

export default BillinServiceProviderList;
