import requireAuth from "../../Common/requireAuth";
import MemberContainer from "../../User/containers/Member";
import TermsAndConditions from "../../User/pages/verifyDetails";
import PersonaSettings from "../../Organization/pages/User/PersonaSettings";
import DashboardContainer from "../../Organization/containers/Dashboard";
import DashboardIndex from "../../Organization/pages/Dashboard/DashboardIndex";
import { SCOPES } from "../authorizationClaims";

export const GeneralRoutesWithSidebar = [
  {
    path: "home",
    element: (
      <DashboardContainer
        scope={SCOPES.DASHBOARD}
        Layout={requireAuth(DashboardIndex)}
      />
    ),
  },
  {
    path: "personaSettings",
    scope: "",
    index: "personaSetting",
    container: MemberContainer,
    componentPath: "../../Organization/pages/User/PersonaSettings",
    element: <MemberContainer Layout={requireAuth(PersonaSettings)} />,
  },
];

export const GeneralRoutesWithoutSidebarAndNavbar = [
  {
    path: "verifyDetails",
    scope: "",
    index: "",
    container: MemberContainer,
    componentPath: "../../User/pages/verifyDetails",
    element: <MemberContainer Layout={TermsAndConditions} />,
  },
];
