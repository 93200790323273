//@ts-nocheck
import React, { Component, Fragment } from "react";
import { Button, Card, Col, message, Row } from "antd";
import Summary from "../payrollprovider/summary";
import { If, Loading, LoadingModal } from "../../../../Common/ui";
import { PayrunSteps, ToogleStaticContent } from "../../../../Common/payrun";
import AmendmentSummary from "../payrollprovider/amendmentSummary";
import InvoiceDetails from "../invoiceDetails";
import RejectPayrun from "../rejectPayrun";
import ClaimWrapper from "../../../../Common/claimWrapper";
import {
  errorDisplay,
  mustBeArray,
  isInvoiceGenerationRequired,
  delay,
  errorHandler,
} from "../../../../libs/utilities";
import _ from "lodash";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import { ReviewAndApproveIcon } from "../../../../Common/customicons";
import ShowVariance from "../showVariance";
import {
  ADHOC_PAYRUN_ID,
  PAID_PAYRUN_STATUS,
  PAYRUN_STATUS_IDS,
  PEO_MODEL_ID,
} from "../../../../constants/payrun";
import {
  handleRequest,
  isCompanyUser,
  isScheduledPayrun,
  payrunIsSkipped,
} from "../../../../libs";
import classNames from "classnames";
import { EarningLinesTable } from "../EarningLines";
import {
  DEFAULT_ROUTES,
  PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER,
  PAYRUN_APPROVE_BUTTON,
  PAYRUN_INVOICE_DETAIL_CONTAINER,
  PAYRUN_REJECT_BUTTON,
  PAYRUN_REPORT_UPLOAD_BUTTON,
  PAYRUN_SUMMARY_GENERAL_CONTAINER,
} from "../../../../constants";
import UploadReportsModal from "../uploadReportsModal";
import PayrunDownloadOptions from "../downloadOptions";
import UploadReportsButton from "../uploadReportsButton";
import InformationDisplayCard from "../../common/informationDisplaycard";
import {
  ClientInformationCardMessage,
  ManagerInformationCardMessage,
} from "./information-card.const";

type State = any;

class PayrunApproveDetail extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      summaryLoading: false,
      summary: [],

      total: <Loading />,
      bu_loading: false,
      listLoading: true,
      externalEarninglines: [],
      approveLoading: false,
      clearLoading: false,
      viewRejectionModal: false,
      rejectionLoading: false,
      approveText: "APPROVE",
      invoice: {},
      disableApprovePayrun: false,
      showVariance: false,
      highLightVarianceValue: 5,
      filterApplied: "",
      hideStaticContent: false,
      approveRejectionInProcess: false,
      viewLoadingModal: false,
      loadingText: "Reimporting Earninglines in progress",
      viewUploadReportsModal: false,
      payrunReports: [],
    };
  }

  componentDidMount() {
    this.props.updateHeader({
      header: {
        title: `Pay Run : ${this.props.payrun?.name}`,
        action: "",
        entity: "payrun",
        enableBack: true,
      },
    });

    this.getFiles();

    this.setState({
      summaryLoading: true,
      listLoading: true,
    });

    this.getInvoiceDetailsInterval = setInterval(
      this.getInvoiceDetails.bind(this),
      10000
    );

    if ([8, 9].includes(this.props.payrun?.payrunStatusID)) {
      this.props
        .fetchExternalEarnings({
          id: this.props.payrun?.payrunId,
          options: {},
          cancelToken: this.props.signal.token,
        })
        .then((response) => {
          this.setState({ importing: false, listLoading: false });
          this.checkIfVarianceIsEnabled(
            this.props.payrun,
            mustBeArray(response.data)
          );

          if (response.status && response.data !== null) {
            this.setState({ externalEarninglines: response.data });
            clearInterval(this.getInvoiceDetailsInterval);
          } else {
            if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
              this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
            }
          }
        });
    } else {
      this.getInvoiceDetails();
    }
  }

  checkIfVarianceIsEnabled = (payrun, processTableList) => {
    if (
      payrun?.payrunTypeID === ADHOC_PAYRUN_ID ||
      (payrun?.payrollModelId === PEO_MODEL_ID &&
        PAID_PAYRUN_STATUS.includes(Number(payrun?.payrunStatusID)))
    ) {
      this.setState({ showVariance: false });
    } else {
      let showVariance = false;
      mustBeArray(processTableList).forEach((list) => {
        if (list && list.payrunSummary && list.payrunSummary[1]) {
          showVariance = true;
        }
      });

      this.setState({ showVariance });
    }
  };

  getInvoiceDetails = () => {
    this.props

      .getInvoice({ id: this.props.payrun?.payrunId, options: {} })

      .then((resp) => {
        this.setState({ importing: false, listLoading: false });

        if (resp.status && resp.data) {
          this.setState({
            externalEarninglines: resp.data.invoiceEarningLines,

            invoice: resp.data,
          });
          if (
            resp.data.summaryDownloadURL &&
            (!isInvoiceGenerationRequired({
              payrollModelId: this.props?.payrun?.payrollModelId,

              isInvoiceNeeded: this.props?.payrun?.isInvoiceNeeded,
            }) ||
              resp.data.pdfDownloadUrl ||
              _.isEmpty(mustBeArray(this.props.payrun.processingFees)))
          ) {
            this.props.updateHeader({
              header: {
                title: `Pay Run : ${this.props?.payrun?.name}`,
                action: "",
                entity: "payrun",
                enableBack: true,
              },
            });

            clearInterval(this.getInvoiceDetailsInterval);

            this.props.getPayrun({
              id: this.props.payrun.payrunId,
              options: {},
            });
          } else {
            this.props.updateHeader({
              header: {
                title: `Pay Run : ${this.props?.payrun?.name}`,
                action: "",
                entity: "payrun",
                enableBack: true,
              },
            });
          }
        } else {
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  componentWillUnmount() {
    clearInterval(this.getInvoiceDetailsInterval);
  }

  handleFetchExternalEarnings = () => {
    const { payrunId } = this.props.payrun;
    this.props
      .fetchExternalEarnings({
        id: payrunId,
        options: {},
        cancelToken: this.props.signal.token,
      })
      .then((response) => {
        this.setState({
          importing: false,
          listLoading: false,
          viewLoadingModal: false,
        });
        this.checkIfVarianceIsEnabled(
          this.props.payrun,
          mustBeArray(response.data)
        );
        if (!response.status) {
          errorDisplay(response?.data?.validationErrors);
          return;
        }
        this.setState({
          externalEarninglines: response.data ?? [],
        });
      });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.payrun !== this.props.payrun) {
      this.props.updateHeader({
        header: {
          title: `Pay Run : ${newProps.payrun?.name}`,
          action: "",
          entity: "payrun",
          enableBack: true,
        },
      });

      this.setState({
        summaryLoading: true,
        listLoading: true,
      });
      if ([8, 9].includes(newProps.payrun?.payrunStatusID)) {
        this.handleFetchExternalEarnings();
        return;
      }
      this.props
        .getInvoice({ id: newProps.payrun?.payrunId, options: {} })

        .then((resp) => {
          this.setState({ importing: false, listLoading: false });
          this.setState({
            externalEarninglines: resp.data?.invoiceEarningLines,

            invoice: resp.data,
          });
        });
    }
    if (newProps.reimportEarninglines) {
      this.handleReimportEarnings();
    }
  }

  // This section is a duplication from prepare stage
  // Should be fine in the new version. Couldn't find a better way to implement this in a class component
  handleReimportEarnings = () => {
    this.props.updateReimportEarninglines(false);
    if (!this.props.payrun) return;
    const {
      payrunId,
      payrollSystemConnectionId,
      externalBusinessUnitId,
      externalPayRunId,
    } = this.props.payrun;
    this.setState({
      viewLoadingModal: true,
    });
    this.props
      .reimportEarnings({
        id: payrunId,
        values: {
          externalBusinessUnitId,
          externalPayRunId,
          payrollSystemConnectionID: payrollSystemConnectionId,
          payRunID: payrunId,
        },
      })
      .then((resp) => {
        if (!resp.status) {
          errorHandler({ response: resp, hasValidationErrors: true });
          this.setState({ listLoading: false, viewLoadingModal: false });
          return;
        }
        this.handleFetchExternalEarnings();
      });
  };

  approvePayrun = () => {
    this.setState({
      approveLoading: true,
      loading: true,
      approveRejectionInProcess: true,
    });

    this.props
      .approvePayrun({ id: this.props.payrun.payrunId })
      .then((response) => {
        if (response.status) {
          message.info(
            "Your request has been received successfully and will be processed shortly."
          );
          this.setState({ disableApprovePayrun: true });
          this.props
            .getPayrun({
              id: this.props.payrun?.payrunId,
              options: {},
            })
            .then(() => this.setState({ approveLoading: false }));
          this.setState({
            loading: false,
            approveRejectionInProcess: false,
          });

          this.props.updateHeader({
            header: {
              title: `Pay Run : ${this.props.payrun?.name}`,
              action: "",
              entity: "payrun",
              enableBack: true,
            },
          });
        } else {
          this.setState({
            approveRejectionInProcess: false,
            approveLoading: false,
          });
          errorHandler({ response, hasValidationErrors: true });
          if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  makePayment = () => {
    this.setState({ approveLoading: true });
  };

  addClearingReason = () => {
    this.setState({ viewRejectionModal: true });
  };

  onClose = () => {
    this.setState({
      viewRejectionModal: false,
      clearLoading: false,
      rejectionLoading: false,
    });
  };

  clearApprovedPayrun = (values) => {
    this.setState({
      clearLoading: true,
      rejectionLoading: true,
      approveRejectionInProcess: true,
    });

    this.props
      .clearApprovedPayrun({
        id: this.props.payrun?.payrunId,
        data: { ...values, rejectionReasonID: 0 },
      })

      .then((resp) => {
        this.setState({
          clearLoading: false,
          rejectionLoading: false,
          approveRejectionInProcess: false,
        });

        if (resp.status) {
          this.props.getPayrun({ id: this.props.payrun?.payrunId });
          delay(100).then(() => {
            this.setState({ viewRejectionModal: false });
          });
          message.success("Pay Run rejected successfully");
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };
  toggleShowVariance = ({ showVariance }) => {
    this.setState({ showVariance });
  };
  updateVarianceValue = (value) => {
    this.setState({ highLightVarianceValue: value });
  };

  handleFilterChange = ({ label, value }) => {
    this.setState({ [label]: value });
  };
  handleIconClick = () => {
    this.setState({ hideStaticContent: !this.state.hideStaticContent });
  };

  // Upload/Download reports and files
  closeUploadReportsModal = () =>
    this.setState({ viewUploadReportsModal: false });

  getFiles = () => {
    this.props.getPayrunReports(this.props.payrun.payrunId).then((resp) => {
      if (handleRequest({ response: resp })) {
        this.setState({ payrunReports: resp.data });
      }
    });
  };

  render() {
    const { disableApprovePayrun, payrunReports } = this.state;
    return (
      <React.Fragment>
        <PayrunSteps
          claims={this.props.pageClaims}
          invoice={this.state.invoice}
        />
        <If
          condition={
            this.props.payrun?.isInvoiceNeeded && !_.isEmpty(this.state.invoice)
          }
          then={
            <Card className="rounded-[12px]">
              <div>
                <ClaimWrapper
                  name={PAYRUN_INVOICE_DETAIL_CONTAINER}
                  claims={this.props.pageClaims}
                >
                  <InvoiceDetails
                    invoice={this.state.invoice}
                    payrun={this.props.payrun}
                  />
                </ClaimWrapper>
              </div>
            </Card>
          }
        />
        <Row gutter={DEFAULT_GUTTER}>
          <Col
            className="mb-4"
            lg={{ span: 16, order: 1 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
          >
            {!this.state.hideStaticContent && (
              <InformationDisplayCard
                title={
                  isCompanyUser()
                    ? ClientInformationCardMessage.approvalTitle
                    : ManagerInformationCardMessage.approvalTitle
                }
                description={
                  isCompanyUser() ? (
                    <Fragment>
                      <div>{ClientInformationCardMessage.approvalSubTitle}</div>
                      {ClientInformationCardMessage.approvalDescription}
                    </Fragment>
                  ) : (
                    ManagerInformationCardMessage.approvalDescription
                  )
                }
                ActionComponent={() => (
                  <Fragment>
                    <PayrunDownloadOptions
                      claims={this.props.pageClaims}
                      classname="mr-4"
                      disabled={!payrunReports?.length}
                      payrunReports={payrunReports}
                      handleReportDownload={this.props.downloadPayrunReports}
                      title={
                        !payrunReports?.length ? "No Reports Available" : ""
                      }
                    />
                    <UploadReportsButton
                      handleUploadClick={() =>
                        this.setState({ viewUploadReportsModal: true })
                      }
                      claims={this.props.pageClaims}
                      componentName={PAYRUN_REPORT_UPLOAD_BUTTON}
                    />
                  </Fragment>
                )}
                IconComponent={ReviewAndApproveIcon}
                payrun={this.props.payrun}
                claims={this.props.pageClaims}
              />
            )}
            <ToogleStaticContent
              hideStaticContent={this.state.hideStaticContent}
              claims={this.props.pageClaims}
              handleIconClick={this.handleIconClick}
            />
            <If
              condition={isScheduledPayrun({ payrun: this.props.payrun })}
              then={
                <ShowVariance
                  showVariance={this.state.showVariance}
                  highLightVarianceValue={this.state.highLightVarianceValue}
                  updateVarianceValue={this.updateVarianceValue}
                  toggleShowVariance={this.toggleShowVariance}
                  filterApplied={this.state.filterApplied}
                  handleFilterChange={this.handleFilterChange}
                />
              }
            />

            <EarningLinesTable
              className="invoice-list"
              pageClaims={this.props.pageClaims}
              loading={this.state.listLoading}
              list={this.state.externalEarninglines}
              businessunitDetails={this.props.businessunitDetails}
              invoice={this.state.invoice}
              payrun={this.props.payrun}
              toggleShowVariance={this.toggleShowVariance}
              showVariance={this.state.showVariance}
              highLightVarianceValue={this.state.highLightVarianceValue}
              filterApplied={this.state.filterApplied}
            />
            <If
              condition={[
                PAYRUN_STATUS_IDS.PREPARED_SYSTEM_TRIGGERED,
                PAYRUN_STATUS_IDS.PREPARED_USER_TRIGGERED,
              ].includes(this.props.payrun.payrunStatusID)}
              then={
                <React.Fragment>
                  <div className="float-right mb-4">
                    <ClaimWrapper
                      name={PAYRUN_APPROVE_BUTTON}
                      claims={this.props.pageClaims}
                    >
                      <Button
                        className={`mt-4 rounded-[20px] ${
                          disableApprovePayrun ? "" : "bg-success"
                        }`}
                        onClick={() => this.approvePayrun()}
                        loading={this.state.approveLoading}
                        disabled={
                          disableApprovePayrun ||
                          this.state.approveRejectionInProcess
                        }
                      >
                        APPROVE
                      </Button>
                    </ClaimWrapper>
                  </div>
                  <div>
                    <ClaimWrapper
                      name={PAYRUN_REJECT_BUTTON}
                      claims={this.props.pageClaims}
                    >
                      <Button
                        className="approveContainer-content__paymentOption float-right mr-4 bg-danger rounded-[20px] mt-4"
                        loading={this.state.clearLoading}
                        onClick={() => this.addClearingReason()}
                        disabled={
                          this.state.approveRejectionInProcess ||
                          this.state.approveLoading
                        }
                      >
                        REJECT
                      </Button>
                    </ClaimWrapper>
                  </div>
                </React.Fragment>
              }
            />
            <div className="clearfix" />
          </Col>
          <Col
            className="mb-4"
            lg={{ span: 8, order: 2 }}
            md={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
          >
            <Row gutter={DEFAULT_GUTTER}>
              <If
                condition={!_.isEmpty(this.state.invoice)}
                then={
                  <Col md={12} sm={24} lg={24}>
                    <ClaimWrapper
                      name={PAYRUN_SUMMARY_GENERAL_CONTAINER}
                      claims={this.props.pageClaims}
                    >
                      <Summary
                        title="Summary"
                        loading={this.state.summaryLoading}
                        summary={this.state.externalEarninglines}
                        invoice={this.state.invoice}
                        businessunitDetails={this.props.businessunitDetails}
                        showVariance={this.state.showVariance}
                      />
                    </ClaimWrapper>
                  </Col>
                }
              />

              <Col md={12} sm={24} lg={24}>
                <ClaimWrapper
                  name={PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER}
                  claims={this.props.pageClaims}
                >
                  <ClaimWrapper
                    name="payrunAmendmentApproveRejectCustomTable"
                    claims={this.props.claims}
                  >
                    <AmendmentSummary
                      className="mb-4 mt-4"
                      title="Amendments"
                      loading={this.state.listLoading}
                      amendmentSummary={[...this.props.amendments]}
                      payrun={this.props.payrun}
                      businessunitDetails={this.props.businessunitDetails}
                      getAmendmentHistory={this.props.getAmendmentHistory}
                      approveComponent="payrunAmendmentApproveIconButton"
                      rejectComponent="payrunAmendmentRejectIconButton"
                    />
                  </ClaimWrapper>
                </ClaimWrapper>
              </Col>
            </Row>
          </Col>
          <RejectPayrun
            visible={this.state.viewRejectionModal}
            cancelRejection={() => this.onClose()}
            submitRejection={(values) => this.clearApprovedPayrun(values)}
            submitRejectionLoading={this.state.rejectionLoading}
            title="Are you sure you want to reject this payrun?"
            decliningPayrun={true}
            payrun={this.props.payrun}
            getPayrunRejectionReasons={this.props.getPayrunRejectionReasons}
          />
        </Row>
        <LoadingModal
          visible={this.state.viewLoadingModal}
          loadingText={this.state.loadingText}
        />
        <UploadReportsModal
          isModalVisible={this.state.viewUploadReportsModal}
          handleCancel={this.closeUploadReportsModal}
          payrun={this.props.payrun}
          refreshPayrunReportList={this.getFiles}
          payrunReports={payrunReports}
        />
      </React.Fragment>
    );
  }
}

export default PayrunApproveDetail;
