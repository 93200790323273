import { SVGProps } from 'react';
const DawnArrowLeftEnd16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 2.531c0-.431-.336-.781-.75-.781s-.75.35-.75.781V13.47c0 .431.336.781.75.781s.75-.35.75-.781V2.53ZM12.78 2.113a.75.75 0 0 1 0 1.06L7.933 8.022l4.847 4.848a.75.75 0 0 1-1.06 1.06L6.342 8.551a.75.75 0 0 1 0-1.06l5.378-5.378a.75.75 0 0 1 1.06 0Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLeftEnd16;
