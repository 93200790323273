import { useHttpClient } from "@xemplo/http";
import { useEffect } from "react";

import {
  useSliceActions,
  useSliceSelector,
} from "../../../../Common/SliceListProvider";
import {
  GENERAL_AMENDMENT_PENDING_GRID_COMPONENT,
  GENERAL_AMENDMENT_PENDING_LIST_CONTAINER,
  GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT,
} from "../../../../constants";
import { useAppDispatch } from "../../../../hooks";
import {
  getPersonalizationData,
  isUndefined,
  mustBeArray,
} from "../../../../libs";
import { getGeneralAmendmentList } from "../../../actions/generalAmendment";
import DefaultListContainer from "../../common/ListContainerComponents/DefaultListContainer";
import PendingGeneralAmendmentTable from "../PendingGeneralAmendmentTable";

type Props = {
  dashboardClaims: any;
  pageClaims?: any;
  targetEndpoint: string;
  componentTitle: string;
  filterApplied?: string;
};

const PendingGeneralAmendmentsDash = ({
  dashboardClaims, //Stored Claims when component was pinned to the dashboard
  pageClaims, //DOM components status
  targetEndpoint, //Fixed endpoint for pinned components
  componentTitle,
  filterApplied,
}: Props) => {
  // TODO: The effect should be part of this httpClient hook. Once that's done, the unmount effect can be dumped
  const { cancelSignal } = useHttpClient();
  const dispatch: any = useAppDispatch();
  // Get list state
  const pendingAmendmentState = useSliceSelector();
  // Get list actions
  const { dataLoaded, gridView, initialDashPersonalization, setLoading } =
    useSliceActions();

  useEffect(() => {
    if (
      isUndefined(pendingAmendmentState) ||
      pendingAmendmentState?.claimsUpdated
    ) {
      return;
    }
    //Get default view (list or grid)
    const gridView = getPersonalizationData({
      type: "listContainer",
      personalizationData: dashboardClaims,
    })?.gridView;
    initialDashPersonalization({ value: gridView });
  }, [pendingAmendmentState?.claimsUpdated]);

  useEffect(() => {
    if (pendingAmendmentState?.claimsUpdated) {
      getData();
    }
  }, [pendingAmendmentState?.claimsUpdated]);

  const getData = () => {
    setLoading({ loading: true });
    dispatch(
      getGeneralAmendmentList({
        options: { page: 1, per_page: 5 },
        cancelToken: cancelSignal.token,
        targetEndpoint: targetEndpoint,
      })
    ).then((resp: any) => {
      setLoading({ loading: false });
      dataLoaded({ data: mustBeArray(resp?.data), total: resp?.total });
    });
  };

  // LIST CONTAINER
  const handleGridViewChange = (view: boolean) => {
    if (pendingAmendmentState?.container?.gridView === view) return;
    gridView({ value: view });
  };

  useEffect(() => {
    return () =>
      void cancelSignal.cancel(
        "PendingGeneralAmendmentsDash unmounted: Cancelling any pending API requests"
      );
  }, []);

  return (
    <DefaultListContainer
      dashboardView
      pageClaims={pageClaims}
      name={GENERAL_AMENDMENT_PENDING_LIST_CONTAINER}
      handleGridViewChange={handleGridViewChange}
      container={pendingAmendmentState?.container}
      grid={pendingAmendmentState?.grid}
      table={pendingAmendmentState?.table}
      listLoading={pendingAmendmentState?.loading}
      tableComponentName={GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT}
      gridComponentName={GENERAL_AMENDMENT_PENDING_GRID_COMPONENT}
      total={pendingAmendmentState?.total}
      componentTitle={componentTitle}
      filterApplied={filterApplied}
      GeneralTablePaginatedComponent={PendingGeneralAmendmentTable}
      targetEndpoint={targetEndpoint}
      rowIdParam="amendmentId"
      data={pendingAmendmentState?.data}
      hasNoDuplicateEntity
    />
  );
};

export default PendingGeneralAmendmentsDash;
