import { useEffect } from "react";

import { useAuth } from "@xemplo/auth-provider";
import { memberActions } from "@xemplo/gp-member-store";
import { LogoutActions } from "@xemplo/gp-types";
import { XemploLoader } from "@xemplo/loader";

import { useAppDispatch } from "../../hooks";

type LogoutProps = { action: LogoutActions };

export const Logout = ({ action }: LogoutProps) => {
  const { isLoading, user, signinRedirect, signoutCallback, signoutRedirect } =
    useAuth();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading) return;
    // Empty user means not authenticated
    // The isAuthenticated also considers the user expiration, which is
    // wrong in this case, so we need to check the user directly.
    if (!user) {
      signinRedirect();
      return;
    }

    // Starts the process to logout the user
    if (action === LogoutActions.Logout) {
      signoutRedirect();
      // Looks like the issue was with not resetting the members store when user manually logs out
      dispatch(memberActions.reset());
      return;
    }

    // LogoutCallback is the final step of the logout process
    // Instead of redirecting to login page, we call signoutCallback
    // to clear the user session and then redirect the user
    // directly to Xemplo Identity instead of the local login page.
    if (action === LogoutActions.LogoutCallback) {
      signoutCallback()
        .then(() => dispatch(memberActions.reset()))
        .then(() => signinRedirect());
    }
  }, [
    action,
    user,
    isLoading,
    signinRedirect,
    signoutCallback,
    signoutRedirect,
  ]);

  return <XemploLoader />;
};
export default Logout;
