import { RefObject, useRef } from 'react';
import { createCalendar } from '@internationalized/date';
import { useDateField, useDateSegment } from '@react-aria/datepicker';
import { useLocale } from '@react-aria/i18n';
import {
  DateFieldState,
  DateSegment,
  useDateFieldState,
} from '@react-stately/datepicker';

import { useDateFieldSegment } from '../hook';

import * as S from './date-field.styles';
import { DateFieldProps } from './date-field.types';

export function DateField(props: DateFieldProps) {
  const { locale } = useLocale();
  const ref = useRef<HTMLInputElement>(null);
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const { segments } = useDateFieldSegment(state.segments, props.format);
  const { fieldProps } = useDateField(props, state, ref as RefObject<HTMLElement>);

  return (
    <S.DateField
      {...fieldProps}
      ref={ref}
      className={props.className}
      data-testid={props.testId}
      onKeyUp={props.onKeyUp}
    >
      {segments.map((segment, i) => (
        <DateSegments key={i} segment={segment} state={state} />
      ))}
    </S.DateField>
  );
}

function DateSegments({
  segment,
  state,
}: {
  segment: DateSegment;
  state: DateFieldState;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);
  return (
    <div {...segmentProps} ref={ref}>
      {segment.text}
    </div>
  );
}
