const CALLBACK_IN_PROGRESS = "CALLBACK_IN_PROGRESS";
const CALLBACK_COMPLETE = "CALLBACK_COMPLETE";
const CLEARDOWN = "CLEARDOWN";

const callBackAction = {
  CALLBACK_IN_PROGRESS,
  CALLBACK_COMPLETE,
  CLEARDOWN,
};

export default callBackAction;
