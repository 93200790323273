//@ts-nocheck
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Card, Checkbox, Radio } from "antd";
import React, { useEffect } from "react";
import { mustBeArray } from "../../libs/utilities";
import SimpleButton from "../../Common/ClaimComponents/Button/SimpleButton";
import _ from "lodash";

const PersonaSettings = (props) => {
  const { form, loading, userPersonaOptions, handlePersonaSubmit } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    populateDefaultValues();
  }, []);

  const populateDefaultValues = () => {
    let defaultRole = _.find(userPersonaOptions, (o) => o.default);
    let selectedRole = _.find(userPersonaOptions, (o) => o.selected);
    if (defaultRole) {
      form.setFieldsValue({ selectLastLoggedRole: false });
      handleChange({ value: false, type: "lastLoggedRole" });
      return form.setFieldsValue({ defaultRole: defaultRole.id });
    } else {
      form.setFieldsValue({
        selectLastLoggedRole: selectedRole ? true : false,
      });
      handleChange({
        value: selectedRole ? true : false,
        type: "lastLoggedRole",
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    populateDefaultValues();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      handlePersonaSubmit(values);
    });
  };
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleChange = ({ value, type }) => {
    if (type === "defaultRole") {
      form.resetFields(["selectLastLoggedRole"]);
    } else {
      form.resetFields("defaultRole");
    }
  };

  return (
    <Card className="card-body-overflow-hidden">
      <Form colon={false} autoComplete="off">
        <Form.Item label=" ">
          {getFieldDecorator(
            "defaultRole",
            {}
          )(
            <Radio.Group
              className="block h-30 leading-[30px]"
              onChange={(e) =>
                handleChange({ value: e.target.value, type: "defaultRole" })
              }
            >
              {
                // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
                mustBeArray(userPersonaOptions).map((role) => {
                  const userRole = role.role && role.role.name;
                  const company = role.tenant && role.tenant.name;
                  return (
                    <Radio value={role.id} key={role.id}>
                      {`${userRole} at ${company}`}
                    </Radio>
                  );
                })
              }
            </Radio.Group>
          )}
        </Form.Item>
        {!_.isEmpty(mustBeArray(userPersonaOptions)) && (
          <Form.Item>
            <SimpleButton
              buttonText="Clear Default"
              ignoreStatus={true}
              className="rounded-[20px]"
              onClick={() =>
                handleChange({ value: "", type: "selectLastLoggedRole" })
              }
            />
          </Form.Item>
        )}

        <Form.Item label="">
          {getFieldDecorator("selectLastLoggedRole", {
            valuePropName: "checked",
          })(
            <Checkbox
              onChange={(e) =>
                handleChange({ value: e.target.value, type: "lastLoggedRole" })
              }
            >
              Always select the last logged-in as default
            </Checkbox>
          )}
        </Form.Item>

        <Form.Item>
          <div className="flex justify-between flex-wrap">
            {/* @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
            <SimpleButton
              buttonText="Cancel"
              ignoreStatus={true}
              className="rounded-[20px] mb-4"
              onClick={handleCancel}
            />
            {/* @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
            <SimpleButton
              buttonText="Save Changes"
              ignoreStatus={true}
              className="rounded-[20px] bg-success mb-4"
              // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'loading'.
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Form.create({ name: "personaForm" })(PersonaSettings);
