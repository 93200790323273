//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import { countriesLookup } from "../../actions/lookup";
import _ from "lodash";
import { Select } from "antd";
import { errorDisplay, mustBeArray } from "../../../libs/utilities";
import axios from "axios";

const Option = Select.Option;

type CountryLookupState = any;

class CountryLookup extends React.Component<{}, CountryLookupState> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      countries: [],
    };
    this.fetchCountries = _.debounce(this.fetchCountries);
  }

  UNSAFE_componentWillMount() {
    this.fetchCountries();
  }

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  fetchCountries = () => {
    this.props
      .countriesLookup({ options: {}, cancelToken: this.signal.token })
      .then((response) => {
        if (response.status) {
          this.setState({ countries: response.data });
          if (this.props.updateCountryList) {
            this.props.updateCountryList(response.data);
          }
        } else {
          errorDisplay(
            response && response.data && response.data.validationErrors
          );
        }
      });
  };

  render() {
    const { onSelect, className } = this.props;

    const { countries } = this.state;
    let selectedCountry = _.find(
      mustBeArray(countries),

      (country) => country.id === Number(this.props.selectedValue)
    );
    selectedCountry = _.isUndefined(selectedCountry) ? {} : selectedCountry;
    return (
      <Select
        placeholder="Select country"
        showSearch
        onSelect={onSelect}
        value={selectedCountry.value}
        className={`rounded-[20px] ${
          className ? className : ""
        } w-[200px] mr-2.5`}
        optionFilterProp="name"
        autoComplete="none"
      >
        {countries &&
          countries.map((country, key) => (
            <Option key={key} value={country.id} name={country.value}>
              {country.value}
            </Option>
          ))}
      </Select>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { countriesLookup };

export default connect(mapStateToProps, mapDispatchToProps)(CountryLookup);
