export const defaultCurrencyList = [
  {
    id: 0,
    value: "Undefined",
    code: "UN",
    countryID: 0,
  },
  {
    id: 1,
    value: "AUD",
    code: "AUD",
    countryID: 13,
  },
  {
    id: 2,
    value: "USD",
    code: "USD",
    countryID: 228,
  },
  {
    id: 3,
    value: "NZD",
    code: "NZD",
    countryID: 156,
  },
  {
    id: 4,
    value: "GBP",
    code: "GBP",
    countryID: 227,
  },
];
export const CURRENCY_DIGIT_FORMAT = "0,0.00";
