//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  getPayrun,
  addAmendment,
  processPayrun,
  approvePayrun,
  completePayrun,
  confirmPayrun,
  makePayment,
  confirmPayment,
  exportTo,
  xeroCallback,
  confirmProcessingPayrun,
  listAmendmentTypes,
  exportABA,
  listPayrunAmendments,
  approveAmendment,
  uploadAmendmentFile,
  rejectPayrunAmendment,
  preparePayrun,
  payInvoice,
  submitAmendment,
  deleteAmendment,
  getAmendment,
  updateAmendment,
  clearApprovedPayrun,
  getAmendmentHistory,
  payCategoryLookUp,
  payrunHandler,
  getPayrunRejectionReasons,
  listInvoices,
  getAuditReport,
  reSubmitAmendment,
  submitAmendments,
  getPayrunReports,
  downloadPayrunReports,
  attachAmendments,
  linkPayrunToPayroll,
  reimportEarnings,
  updateReimportEarninglines,
} from "../actions/payrun";

import {
  fetchExternalPayrun,
  fetchExternalEarnings,
  postExternalEarnings,
  clearExternalEarnings,
  getInvoice,
  getMissingPayCategories,
} from "../actions/payrollprocessor";
import {
  listClientsEmployees,
  getBusinessUnit,
  getIntegrationSettings,
} from "../actions/businessunits";
import axios from "axios";
import { populateStates, cdnLookUpHandler } from "../actions/lookup";
import { generalAmendmentHandler } from "../actions/generalAmendment";
import {
  updateAuthorizationClaim,
  updateHeader,
} from "../../User/actions/member";
import { findAuthorizationClaims, mustBeArray } from "../../libs/utilities";
import { PAYRUN_BREAD_CRUMB_CONTAINER } from "../../constants";
import { withRouter } from "../../hooks";

class PayrunDetail extends SwiftContainer {
  signal = axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
    this.props.updateHeader({
      header: {
        headerClassname: "",
      },
    });
  }

  componentDidMount() {
    const routesContainer = findAuthorizationClaims({
      claims: this.state.pageClaims?.components,
      name: PAYRUN_BREAD_CRUMB_CONTAINER,
    });
    this.props.updateHeader({
      header: {
        routeComponentName: "payrunBreadCrumb",
        routesContainer,
      },
    });
  }

  render() {
    const { Layout } = this.props;

    const { pageClaims } = this.state;
    return (
      <Layout
        {...this.props}
        loading={this.state.loading}
        getPayrun={(data) => this.executeAction(this.props.getPayrun, data)}
        processTableList={this.props.processDetail}
        listPayrunAmendments={(data) =>
          this.executeAction(this.props.listPayrunAmendments, data)
        }
        submitAmendment={(data) =>
          this.executeAction(this.props.submitAmendment, data)
        }
        submitAmendments={this.props.submitAmendments}
        deleteAmendment={(data) =>
          this.executeAction(this.props.deleteAmendment, data)
        }
        fetchExternalPayrun={(data) =>
          this.executeAction(this.props.fetchExternalPayrun, data)
        }
        fetchExternalEarnings={(data) =>
          this.executeAction(this.props.fetchExternalEarnings, data)
        }
        postExternalEarnings={(data) =>
          this.executeAction(this.props.postExternalEarnings, data)
        }
        clearExternalEarnings={(data) =>
          this.executeAction(this.props.clearExternalEarnings, data)
        }
        confirmPayrun={(data) =>
          this.executeAction(this.props.confirmPayrun, data)
        }
        getInvoice={(data) => this.executeAction(this.props.getInvoice, data)}
        makePayment={(data) => this.executeAction(this.props.makePayment, data)}
        confirmPayment={(data) =>
          this.executeAction(this.props.confirmPayment, data)
        }
        confirmProcessingPayrun={(data) =>
          this.executeAction(this.props.confirmProcessingPayrun, data)
        }
        signal={this.signal}
        pageClaims={pageClaims}
        match={{ params: this.props.router.params }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  payrun: state.payrun.detail || {},
  amendments: [...mustBeArray(state.payrun.amendmentList)],
  amendmentCount: state.payrun.amendment.count,
  amendmentListCount: state.payrun.amendmentListCount,
  processDetail: state.payrun.processDetail,
  summary: state.payrun.summary,
  externalEarningList: [...mustBeArray(state.payrun.externalEarningList)],
  memberDetails: state.member.details || {},
  userMode: "",
  businessunitDetails: state.businessUnit.detail || {},
  member: state.member,
  claims: (state.member && state.member.claims) || [],
  reimportEarninglines: state.payrun.reimportEarninglines,
});

const mapDispatchToProps = {
  getPayrun,
  addAmendment,
  processPayrun,
  listPayrunAmendments,
  exportTo,
  xeroCallback,
  approveAmendment,
  rejectPayrunAmendment,
  preparePayrun,
  approvePayrun,
  completePayrun,
  payInvoice,
  confirmPayrun,
  getInvoice,
  getAmendment,
  submitAmendment,
  deleteAmendment,
  fetchExternalPayrun,
  fetchExternalEarnings,
  postExternalEarnings,
  updateAmendment,
  listClientsEmployees,
  uploadAmendmentFile,
  exportABA,
  clearExternalEarnings,
  makePayment,
  confirmPayment,
  confirmProcessingPayrun,
  listAmendmentTypes,
  clearApprovedPayrun,
  getBusinessUnit,
  getAmendmentHistory,
  populateStates,
  getMissingPayCategories,
  payCategoryLookUp,
  payrunHandler,
  updateAuthorizationClaim,
  updateHeader,
  getPayrunRejectionReasons,
  listInvoices,
  getAuditReport,
  reSubmitAmendment,
  submitAmendments,
  generalAmendmentHandler,
  cdnLookUpHandler,
  getIntegrationSettings,
  getPayrunReports,
  downloadPayrunReports,
  attachAmendments,
  linkPayrunToPayroll,
  reimportEarnings,
  updateReimportEarninglines,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayrunDetail)
);
