import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetChangeTasksQuery } from '@xemplo/amendment-query';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';

export const ChangeTask = () => {
  const { register, watch } = useFormContext();

  const watchChangeType = watch('changeType');
  const watchChangeTask = watch('changeTask');

  useEffect(() => {
    register('changeTask', {
      required: 'Change task is required',
      valueAsNumber: true,
      validate: (value) => {
        if (value === PLACEHOLDER_OPTION) {
          return 'This field is required';
        }
        return true;
      },
    });
  }, [register]);

  const { data, isLoading } = useGetChangeTasksQuery({
    requestParams: {
      q: {
        changeTypeId: watchChangeType ?? null,
      },
    },
  });

  const parsedData =
    data?.result?.rows.map((item) => {
      return {
        key: item.changeTaskValueId,
        value: item.name,
      };
    }) ?? [];

  const getItems = () => {
    if (watchChangeTask) {
      return parsedData;
    }
    return [{ key: PLACEHOLDER_OPTION, value: 'Select task' }, ...parsedData];
  };
  return (
    <S.FormLabel>
      Change task
      <TextDropdownMultiV2
        items={getItems()}
        name="changeTask"
        defaultSelectedKeys={watchChangeTask && [watchChangeTask]}
        selectProps={{
          placeholder: 'Select task',
        }}
        displayProps={{
          isDisabled: isLoading,
        }}
        aria-label="Change task field"
        testId="changeTask"
        isDropdownMulti={false}
      />
    </S.FormLabel>
  );
};
