import { SVGProps } from 'react';
const DawnCross24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.155 5.216a.75.75 0 1 1 1.06-1.06L12 10.938l6.784-6.784a.75.75 0 0 1 1.06 1.061L13.06 12l6.784 6.784a.75.75 0 0 1-1.06 1.06L12 13.062l-6.784 6.784a.75.75 0 1 1-1.06-1.061L10.938 12 4.155 5.216Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCross24;
