import { Colour } from '@xemplo/style-constants';

export interface FileIconProps {
  stroke?: string;
  altStroke?: string;
  width?: number;
  height?: number;
}

export const FileIcon = (props: FileIconProps) => {
  const {
    stroke = Colour.Gray[200],
    altStroke = Colour.Gray[300],
    width = 63,
    height = 60,
  } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <g filter="url(#a)">
        <path
          fill="#fff"
          stroke={stroke}
          d="M18.77 6.5h19.31l14.19 12.642v28.73c0 3.608-3.303 6.628-7.5 6.628h-26c-4.198 0-7.5-3.02-7.5-6.627V13.127c0-3.607 3.302-6.627 7.5-6.627Z"
        />
        <rect
          width="10.227"
          height="1.67"
          x="17.809"
          y="22.048"
          fill={stroke}
          opacity=".63"
          rx=".835"
        />
        <rect
          width="2.882"
          height="1.67"
          x="19.645"
          y="22.048"
          fill={altStroke}
          opacity=".4"
          rx=".835"
        />
        <rect
          width="3.936"
          height="1.534"
          x="35.055"
          y="22"
          fill={altStroke}
          opacity=".4"
          rx=".767"
        />
        <rect
          width="8.847"
          height="1.67"
          x="27.25"
          y="22.048"
          fill={stroke}
          opacity=".63"
          rx=".835"
        />
        <rect
          width="27"
          height="1.67"
          x="17.769"
          y="27.797"
          fill={stroke}
          opacity=".6"
          rx=".835"
        />
        <rect
          width="2.211"
          height="1.67"
          x="24.196"
          y="27.797"
          fill={altStroke}
          opacity=".3"
          rx=".835"
        />
        <rect
          width="3.672"
          height="1.67"
          x="32.459"
          y="27.797"
          fill={altStroke}
          opacity=".3"
          rx=".835"
        />
        <rect
          width="15.763"
          height="1.67"
          x="17.809"
          y="33.546"
          fill={stroke}
          opacity=".6"
          rx=".835"
        />
        <rect
          width="3.672"
          height="1.67"
          x="21.481"
          y="33.546"
          fill={altStroke}
          opacity=".15"
          rx=".835"
        />
        <rect
          width="3.374"
          height="1.534"
          x="39.836"
          y="33.761"
          fill={stroke}
          opacity=".6"
          rx=".767"
        />
        <rect
          width="6.522"
          height="1.67"
          x="17.769"
          y="39.33"
          fill={stroke}
          opacity=".6"
          rx=".835"
        />
        <path stroke={stroke} d="M37.684 7v4a8 8 0 0 0 8 8h6.5" />
      </g>
    </svg>
  );
};
