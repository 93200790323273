export interface RadioButtonProps {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string | number;
  disabled?: boolean;
  id?: string;
  ariaLabel: string;
  size?: RadioButtonSize;
  error?: boolean;
}

export enum RadioButtonSize {
  Large = 'large',
  Small = 'small',
  Medium = 'medium',
}

//Radio Group Types
export type RadioOption = {
  id?: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  error?: boolean;
};

export type RadioGroupProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  options: RadioOption[];
  id?: string;
  ariaLabel: string;
  size?: RadioButtonSize;
  showLabel?: boolean;
};
