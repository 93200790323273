//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listPayrollProviders,
  getPayrollProvider,
  updatePayrollServiceProvider,
  savePayrollServiceProvider,
  payrollProviderUploadLogo,
} from "../actions/payrollprovider";
import { listPSPUsers } from "../actions/payrollprocessor";
import axios from "axios";
import {
  populateStates,
  countriesLookup,
  timezoneLookup,
  cdnLookUpHandler,
} from "../actions/lookup";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { withRouter } from "../../hooks";

class PayrollProvider extends SwiftContainer {
  signal = axios.CancelToken.source();

  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        payrollproviders={this.props.payrollproviders}
        totalPages={this.props.totalPages}
        getPayrollProvider={(data: any) =>
          this.executeAction(this.props.getPayrollProvider, data)
        }
        loading={this.state.loading}
        listPayrollProviders={(data: any) =>
          this.executeAction(this.props.listPayrollProviders, data)
        }
        match={{ params: this.props.router.params }}
        signal={this.signal}
        pageClaims={pageClaims}
        member={this.props.member}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payrollproviders: state.payrollProvider.list || [],
  totalPages: state.payrollProvider.total,
  member: state.member,
});

const mapDispatchToProps = {
  listPayrollProviders,
  getPayrollProvider,
  updateHeader,
  updateAuthorizationClaim,
  countriesLookup,
  timezoneLookup,
  populateStates,
  updatePayrollServiceProvider,
  savePayrollServiceProvider,
  cdnLookUpHandler,
  payrollProviderUploadLogo,
  listPSPUsers,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayrollProvider)
);
