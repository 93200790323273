//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import { listUsers } from "../../User/actions/member";
import axios from "axios";
import {
  listCompanyUsers,
  companyLookup,
  listCompanyBusinessUnits,
  handleCompanyUserActions,
  registerCompanyUsers,
  handleEmailTemplatesActions,
} from "../actions/companies";
import {
  listUserRoles,
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import {
  listPSPUsers,
  handlePSPUserActions,
  registerPSPUsers,
} from "../actions/payrollprocessor";
import { listPayrollProviders } from "../actions/payrollprovider";
import { mustBeArray } from "../../libs";
import { withRouter } from "../../hooks";

class UserContainer extends SwiftContainer {
  signal = axios.CancelToken.source();

  render() {
    const { Layout } = this.props;
    const { pageClaims, loading } = this.state;
    return (
      <Layout
        users={this.props.users}
        listPSPUsers={this.props.listPSPUsers}
        listCompanyUsers={this.props.listCompanyUsers}
        listUserRoles={this.props.listUserRoles}
        userMode={this.props.userMode}
        loading={loading}
        listUsers={(data: any) =>
          this.executeAction(this.props.listUsers, data)
        }
        signal={this.signal}
        match={{ params: this.props.router.params }}
        companyUsersCount={this.props.companyUsersCount}
        companyUsersList={this.props.companyUsersList}
        pspUsersList={this.props.pspUsersList}
        pspUsersCount={this.props.pspUsersCount}
        companyLookup={this.props.companyLookup}
        listCompanyBusinessUnits={this.props.listCompanyBusinessUnits}
        handleCompanyUserActions={this.props.handleCompanyUserActions}
        handlePSPUserActions={this.props.handlePSPUserActions}
        listPayrollProviders={this.props.listPayrollProviders}
        registerCompanyUsers={this.props.registerCompanyUsers}
        registerPSPUsers={this.props.registerPSPUsers}
        handleEmailTemplatesActions={this.props.handleEmailTemplatesActions}
        userDetails={this.props.userDetails}
        pageClaims={pageClaims}
        member={this.props.member}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  users: state.member.users || [],
  userMode: "",
  userDetails: state.member.details,

  companyUsersCount: state.company.users ? state.company.users.count : 0,
  companyUsersList: [...mustBeArray(state.company?.users?.list)],
  pspUsersList: [...mustBeArray(state.payrollProvider?.users?.list)],

  pspUsersCount:
    state.payrollProvider.users && state.payrollProvider.users.count,

  member: state.member,
});

const mapDispatchToProps = {
  listUsers,
  listPSPUsers,
  listCompanyUsers,
  companyLookup,
  listCompanyBusinessUnits,
  handleCompanyUserActions,
  handlePSPUserActions,
  listPayrollProviders,
  registerCompanyUsers,
  registerPSPUsers,
  listUserRoles,
  handleEmailTemplatesActions,
  updateHeader,
  updateAuthorizationClaim,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserContainer)
);
