import { RefObject, useCallback, useEffect, useState } from 'react';
import { Node, Selection } from '@react-types/shared';

import { Item, ListItem, SelectionMode, useListState } from '@xemplo/listbox';
import { usePopoverState } from '@xemplo/popover';

import { TextDropdownMultiProps } from '../v1/text-dropdown-multi.types';
import { TextDropdownMultiV2Props } from '../v2/text-dropdown-multi-v2.types';

import { useSelectProps } from './use-select-props';

type UseMultiStateProps<T extends ListItem> = {
  originalProps: TextDropdownMultiProps<T> | TextDropdownMultiV2Props<T>;
  containerRef: RefObject<HTMLElement>;
  selectRef: RefObject<HTMLSelectElement>;
  listRef: RefObject<HTMLUListElement>;
  singleValueRef?: RefObject<HTMLDivElement> | null;
};

export const useMultiState = <T extends ListItem>(props: UseMultiStateProps<T>) => {
  const { originalProps, containerRef, selectRef, singleValueRef } = props;
  const {
    disabledKeys,
    selectedKeys,
    items,
    defaultSelectedKeys,
    itemsLoading,
    isDropdownMulti = true,
  } = originalProps;
  const [searchValue, setSearchValue] = useState('');
  const { state: popoverState } = usePopoverState({
    ref: containerRef,
    type: 'listbox',
  });

  const listState = useListState<T>({
    selectionMode: isDropdownMulti ? SelectionMode.Multiple : SelectionMode.Single,
    disabledKeys,
    disallowEmptySelection: !isDropdownMulti,
    selectedKeys,
    defaultSelectedKeys,
    children: items
      ?.filter((item) => item.key !== '-1' && item.key !== -1)
      .map((item) => <Item key={item.key}>{item.value}</Item>),
    filter: (nodes) => filterFn(nodes, searchValue),
    onSelectionChange: (keys: Selection) => {
      const selectEl = selectRef.current;
      if (!selectEl) return;
      const options = Array.from(selectEl.options);

      if (keys === 'all') {
        options.forEach((option) => option.setAttribute('selected', 'true'));
      } else {
        options.forEach((option) => {
          const selected = keys.has(option.value);
          option.toggleAttribute('selected', selected);
          if (selected && !isDropdownMulti) {
            selectEl.value = option.value;
          }
        });
      }
      const changeEvent = new Event('change', { bubbles: true });
      selectEl.dispatchEvent(changeEvent);
      if (!isDropdownMulti) popoverState.close();
    },
  });

  /** Parse the default selected keys prop and triggers a change event so the hidden select element is in sync */
  const handleDefaultSelectedKeys = useCallback(() => {
    if (!selectRef.current || itemsLoading || !defaultSelectedKeys) return;
    const options = Array.from(selectRef.current.options);
    const setOfKeysAsStrings = new Set(
      Array.from(defaultSelectedKeys ?? []).map((key) => key.toString())
    );
    options.forEach((option) => {
      option.toggleAttribute('selected', setOfKeysAsStrings.has(option.value));
    });
    const changeEvent = new Event('change', { bubbles: true });
    selectRef.current.dispatchEvent(changeEvent);
    listState.selectionManager.setSelectedKeys(setOfKeysAsStrings);
  }, [selectRef, itemsLoading, defaultSelectedKeys, listState.selectionManager]);

  useEffect(() => {
    handleDefaultSelectedKeys();
  }, [itemsLoading]);

  const searchState = { searchValue, setSearchValue };
  const selectProps = useSelectProps({
    ...props,
    listState,
    popoverState,
  });

  const handleContainerClick = useCallback(() => {
    if (!popoverState.isOpen) {
      popoverState.open();
      if (isDropdownMulti) {
        selectRef.current?.focus();
      } else {
        singleValueRef?.current?.focus();
      }
    } else {
      popoverState.close();
      if (isDropdownMulti) {
        selectRef.current?.blur();
      } else {
        singleValueRef?.current?.blur();
      }
    }
  }, [popoverState, selectRef, singleValueRef, isDropdownMulti]);

  return {
    listState,
    popoverState,
    searchState,
    selectProps,
    handleContainerClick,
  };
};

function filterFn<T>(nodes: Iterable<Node<T>>, search: string) {
  return Array.from(nodes).filter((node) => {
    const text = node.textValue?.toLowerCase();
    return text?.includes(search.toLowerCase());
  }) as Iterable<Node<T>>;
}
