//@ts-nocheck
import React from "react";
import { Table } from "antd";
import _ from "lodash";
 import EditIconButton from "../../../../Common/ClaimComponents/IconButton/EditIconButton";
 import { tableColumnGenerator } from "../../../../libs/table";
 import { mustBeArray, findScrollableTableHeight } from "../../../../libs/utilities";

class ScrollableConnectionList extends React.Component {
    componentDidMount() {
        document.querySelector('.ant-table-body') && document.querySelector('.ant-table-body').addEventListener('scroll', (event) => {
            let maxScroll = (event.target).scrollHeight - (event.target).clientHeight;
            let currentScroll = (event.target).scrollTop;
            if (((currentScroll >= maxScroll) || (maxScroll - currentScroll < 0.5)) && this.props.hasMoreData) {
                this.props.handleTableChange({ options: {}, pageLoad: false });
            }
        })

    }
    componentWillUnmount() {
        document.querySelector('.ant-table-body') && document.querySelector('.ant-table-body').removeEventListener('scroll', () => {console.log('')});
    }
    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        let options = sorter.field ? {
            page: pagination.current,
            order: sorter.order === "ascend" ? "asc" : "desc",
            field: sorter.field,
        }
            :
            {
                page: pagination.current
            };
        this.props.handleTableChange({ options });
    };

    render() {
        let { sortInfo, per_page, data, editComponent, claims, handleEdit } = this.props;
        const colTemplate = [
            {
                title: "Name",
                dataIndex: "name",
                sorter: false,
                sortOrder: sortInfo && sortInfo.field === 'name' && sortInfo.order ?
                    sortInfo.order === "asc" ? "ascend" : "descend" : false,
            }
        ];
        const action = [
            {
                title: "Action",
                dataIndex: "",
                render: (record: any) => {
                    return (

                        <EditIconButton
                            handleEdit={() => handleEdit(record)}
                            name={editComponent}
                            claims={claims}
                            hasNoComponents={true}
                            avatarClassName="color-primary"
                        />
                    )
                }
            }
        ]
        const tableColumn = _.concat(tableColumnGenerator(colTemplate), action);
        return (

            <Table
                columns={tableColumn}
                 rowKey={(record, id) => id}
                dataSource={mustBeArray(data)}
                pagination={false}
                loading={this.props.loading}
                scroll={{ y: findScrollableTableHeight({ per_page, total: mustBeArray(data).length }) }} //{{ y: "70vh" }}
                onChange={this.handleTableChange}
            />
        );
    }
}

export default ScrollableConnectionList;
