import { Fragment, useEffect, useMemo, useReducer } from "react";
import moment from "moment";
import MilestoneField from "./MileStoneFields";
import {
  Action,
  ActionType,
  EditMileStonesProps,
  Milestone,
  MilestoneState,
  MilestoneTypes,
} from "./EditMilestones.types";
import { InitialState, TIME_DATE_FORMAT } from "../viewOptions";
import { PAYRUN_STATUS_IDS } from "../../../../constants";
import { useModal } from "@xemplo/modal";

const EditMilestones = ({
  payrun,
  errorMessage,
  setPayrunDatesState,
}: EditMileStonesProps) => {
  const modalIsVisible = useModal().state.open;
  const reducer = (state: MilestoneState, action: Action) => {
    let updatedState = structuredClone(state);
    switch (action.type) {
      // Disable the field for the given milestone
      case ActionType.DISABLE_FIELD:
        action.payload.milestones.forEach((milestone) => {
          updatedState[milestone] = {
            ...InitialState[milestone],
            isDisabled: true,
          };
        });
        setPayrunDatesState(updatedState);
        return updatedState;
      // Set the newValue (Date) for the given milestone
      case ActionType.SET_NEW_VALUE:
        updatedState = {
          ...state,
          [action.payload.milestone]: {
            ...state[action.payload.milestone],
            newValue: action.payload.newValue,
            newValueIsWeekend: action.payload.newValueIsWeekend,
          },
        };
        setPayrunDatesState(updatedState);
        return updatedState;
      // Reset an array of milestones to their initial state
      case ActionType.RESET_MILESTONES:
        action.payload?.milestones.forEach((milestone) => {
          updatedState[milestone] = {
            ...InitialState[milestone],
          };
        }, {});
        setPayrunDatesState(updatedState);
        return updatedState;
      case ActionType.RESET:
        return InitialState;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, InitialState);
  // Create a common function to check if the selected date falls on the weekends and throw warning
  const dateFallsOnTheWeekend = (date: any) => {
    return moment(date).day() === 0 || moment(date).day() === 6;
  };

  const validateDate = (value: moment.Moment, milestone: Milestone) => {
    let resetArray: any = [];
    const mileStoneValues = Object.values(Milestone);
    const valueIndex = mileStoneValues.findIndex((o) => o === milestone);
    mileStoneValues.forEach((data: MilestoneTypes) => {
      if (data === milestone) {
        return;
      }
      const itemIndex = mileStoneValues.findIndex((o) => o === data);
      if (itemIndex < valueIndex) {
        if (
          state[data].newValue &&
          value.isBefore(moment(state[data].newValue), "minute")
        ) {
          resetArray.push(data);
        }
      } else {
        if (
          state[data].newValue &&
          value.isAfter(moment(state[data].newValue), "minute")
        ) {
          resetArray.push(data);
        }
      }
    });
    dispatch({
      type: ActionType.RESET_MILESTONES,
      payload: { milestones: resetArray },
    });
  };

  useEffect(() => {
    if (!modalIsVisible) {
      dispatch({
        type: ActionType.RESET_MILESTONES,
        payload: {
          milestones: [
            Milestone.AMENDMENT_DUE,
            Milestone.PREPARING_PAYRUN,
            Milestone.APPROVAL_DUE,
            Milestone.PAY_DATE,
          ],
        },
      });
    }
  }, [modalIsVisible]);

  useEffect(() => {
    if (modalIsVisible) {
      disablePastMilestones();
    }
  }, [modalIsVisible, payrun]);

  //check each date and if in the past, dispatch action to disable field
  const disablePastMilestones = () => {
    //For each milestone, check if it's in the past and if it is, dispatch action to disable field
    // Amendment Due
    let disabledFields: Milestone[] = [];
    const amendmentDueDateIsDisabled =
      payrun.payrunStatusID >=
      PAYRUN_STATUS_IDS.AMENDEMENTS_SUBMITTED_SYSTEM_TRIGGERED;
    const prepareDueDateIsDisabled =
      payrun.payrunStatusID >= PAYRUN_STATUS_IDS.PREPARED_SYSTEM_TRIGGERED &&
      payrun.payrunStatusID <= PAYRUN_STATUS_IDS.COMPLETED;
    const approvalDueDateIsDisabled =
      payrun.payrunStatusID >= PAYRUN_STATUS_IDS.APPROVED_SYSTEM_TRIGGERED &&
      payrun.payrunStatusID <= PAYRUN_STATUS_IDS.COMPLETED;
    const payDateIsDisabled =
      payrun.payrunStatusID >= PAYRUN_STATUS_IDS.PROCESSING &&
      payrun.payrunStatusID <= PAYRUN_STATUS_IDS.COMPLETED;

    if (amendmentDueDateIsDisabled) {
      disabledFields.push(Milestone.AMENDMENT_DUE);
    }
    if (prepareDueDateIsDisabled) {
      disabledFields.push(Milestone.PREPARING_PAYRUN);
    }
    if (approvalDueDateIsDisabled) {
      disabledFields.push(Milestone.APPROVAL_DUE);
    }
    if (payDateIsDisabled) {
      disabledFields.push(Milestone.PAY_DATE);
    }
    dispatch({
      type: ActionType.DISABLE_FIELD,
      payload: { milestones: disabledFields },
    });
  };

  /** When a date/time is picked save it in state and check if it falls on a weekend */
  const handleDateTimeChange = (value: moment.Moment, milestone: Milestone) => {
    // Check if newly selected date is within allowed bounds

    let newValue = null;
    if (moment(value).isValid()) {
      validateDate(value, milestone);
      newValue = moment(value).format(TIME_DATE_FORMAT);
    }
    dispatch({
      type: ActionType.SET_NEW_VALUE,
      payload: {
        milestone,
        newValue,
        newValueIsWeekend: dateFallsOnTheWeekend(newValue),
      },
    });
  };

  /** DatePicker function for disabling date ranges - forces sequential dates */
  const disableDates = (current: moment.Moment, milestone: Milestone) => {
    if (!current) return false;
    switch (milestone) {
      case Milestone.AMENDMENT_DUE:
        return current.isBefore(moment());
      case Milestone.PREPARING_PAYRUN:
        return current.isBefore(
          state[Milestone.AMENDMENT_DUE].newValue
            ? moment(state[Milestone.AMENDMENT_DUE].newValue)
            : moment.max(
                moment().startOf("day"),
                moment(payrun.ammendmentDueDateTime)
              )
        );
      case Milestone.APPROVAL_DUE:
        return current.isBefore(
          state[Milestone.PREPARING_PAYRUN].newValue
            ? moment(state[Milestone.PREPARING_PAYRUN].newValue)
            : moment.max(
                moment().startOf("day"),
                moment(payrun.preparationDueDateTime)
              )
        );
      case Milestone.PAY_DATE:
        return current.isBefore(
          state[Milestone.APPROVAL_DUE].newValue
            ? moment(state[Milestone.APPROVAL_DUE].newValue)
            : moment.max(
                moment().startOf("day"),
                moment(payrun.approvalDueDateTime)
              )
        );
      default:
        return current < moment().startOf("day");
    }
  };

  const dateFallsOnWeekend: boolean = useMemo(
    () =>
      Object.values(state).some(
        (milestone: any) => milestone.newValueIsWeekend
      ),
    [state]
  );

  return (
    <Fragment>
      <div className="grid grid-cols-3 mb-3 gap-2">
        <div className="font-bold">Milestone</div>
        <div className="font-bold">Current Date/Time</div>
        <div className="font-bold">New Date/Time</div>
      </div>

      <MilestoneField
        isDisabled={state[Milestone.AMENDMENT_DUE].isDisabled}
        defaultValue={payrun.ammendmentDueDateTime}
        disabledDate={(current) =>
          disableDates(current, Milestone.AMENDMENT_DUE)
        }
        value={
          state[Milestone.AMENDMENT_DUE].newValue
            ? moment(state[Milestone.AMENDMENT_DUE].newValue)
            : state[Milestone.AMENDMENT_DUE].newValue
        }
        title="Amendments Due"
        onChange={(value) =>
          handleDateTimeChange(value, Milestone.AMENDMENT_DUE)
        }
      />
      <MilestoneField
        isDisabled={state[Milestone.PREPARING_PAYRUN].isDisabled}
        defaultValue={payrun.preparationDueDateTime}
        disabledDate={(current) =>
          disableDates(current, Milestone.PREPARING_PAYRUN)
        }
        title="Preparing Payrun"
        onChange={(value) =>
          handleDateTimeChange(value, Milestone.PREPARING_PAYRUN)
        }
        value={
          state[Milestone.PREPARING_PAYRUN].newValue
            ? moment(state[Milestone.PREPARING_PAYRUN].newValue)
            : state[Milestone.PREPARING_PAYRUN].newValue
        }
      />

      <MilestoneField
        isDisabled={state[Milestone.APPROVAL_DUE].isDisabled}
        defaultValue={payrun.approvalDueDateTime}
        disabledDate={(current) =>
          disableDates(current, Milestone.APPROVAL_DUE)
        }
        title="Review and Approve"
        onChange={(value) =>
          handleDateTimeChange(value, Milestone.APPROVAL_DUE)
        }
        value={
          state[Milestone.APPROVAL_DUE].newValue
            ? moment(state[Milestone.APPROVAL_DUE].newValue)
            : state[Milestone.APPROVAL_DUE].newValue
        }
      />
      <MilestoneField
        isDisabled={state[Milestone.PAY_DATE].isDisabled}
        defaultValue={payrun.payDate}
        disabledDate={(current) => disableDates(current, Milestone.PAY_DATE)}
        title="Pay Date"
        onChange={(value) => handleDateTimeChange(value, Milestone.PAY_DATE)}
        value={
          state[Milestone.PAY_DATE].newValue
            ? moment(state[Milestone.PAY_DATE].newValue)
            : state[Milestone.PAY_DATE].newValue
        }
      />

      <Fragment>
        {dateFallsOnWeekend && (
          <div className="col-span-3 color-warning">
            One or more dates fall on the weekend
          </div>
        )}
        {errorMessage && (
          <div className="col-span-3 color-danger">{errorMessage}</div>
        )}
      </Fragment>
    </Fragment>
  );
};

export default EditMilestones;
