import React, { useEffect } from "react";
import { Form } from "antd";
import { Input, message } from "antd";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import { trimWhiteSpaces } from "../../../libs/utilities";
import classNames from "classnames";

type Props = {
  visible: boolean;
  editMode: boolean;
  submitLoading: boolean;
  deleteLoading?: boolean;
  hideDelete?: boolean;
  sidebarView?: boolean;
  activeDashboard: any;
  dashboardList: any;
  onCreate: (values: any) => void;
  onCancel: () => void;
  onDelete: () => void;
};

const NewDashboardForm = ({
  visible,
  editMode,
  activeDashboard,
  dashboardList,
  onCancel,
  submitLoading,
  deleteLoading,
  onDelete,
  hideDelete,
  sidebarView,
  onCreate,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (visible && editMode) {
      form.setFieldsValue({
        name: activeDashboard?.name,
      });
    }
  }, [visible, editMode, activeDashboard]);

  const handleCreate = (values: any) => {
    const name = trimWhiteSpaces(values.name);
    // check if there is a dashboard with the same name
    const duplicateName = dashboardList.find((d: any) => d.name === name);

    if (duplicateName) {
      message.error("Please enter a unique dashboard name.");
      return;
    }
    onCreate(name);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={classNames("bg-white px-3 py-4", { hidden: !visible })}
      autoComplete="off"
      onFinish={handleCreate}
    >
      <Form.Item
        label="Name"
        className="pb-0"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input the name for the dashboard",
          },
        ]}
      >
        <Input placeholder="Enter" maxLength={32} />
      </Form.Item>
      <SimpleButton
        type="primary"
        buttonText="Save"
        ignoreStatus
        loading={submitLoading}
        htmlType="submit"
      />
      {editMode && !hideDelete && !sidebarView && (
        <SimpleButton
          className="bg-danger ml-2.5"
          buttonText="Delete"
          ignoreStatus
          onClick={onDelete}
          loading={deleteLoading}
        />
      )}

      <SimpleButton
        className="float-right mb-4"
        buttonText="Cancel"
        ignoreStatus
        onClick={onCancel}
      />

      <div className="clearfix"></div>
      {sidebarView && (
        <SimpleButton
          className="bg-danger"
          buttonText="Delete"
          ignoreStatus
          onClick={onDelete}
          loading={deleteLoading}
          block
        />
      )}
    </Form>
  );
};

export default NewDashboardForm;
