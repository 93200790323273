import { SVGProps } from 'react';
const DawnArrowLongLeft16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.25 8a.747.747 0 0 1 .22-.53l4-4a.75.75 0 0 1 1.06 1.06L3.81 7.25H14a.75.75 0 0 1 0 1.5H3.81l2.72 2.72a.75.75 0 1 1-1.06 1.06L1.472 8.533A.748.748 0 0 1 1.25 8Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLongLeft16;
