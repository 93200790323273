import { ReactNode } from 'react';

export type ModalBodyProps = {
  content?: ReactNode;
  overflow?: ModalBodyOverflow;
  color?: string;
};

export enum ModalBodyOverflow {
  Standard = 'standard',
  Extended = 'extended',
}
