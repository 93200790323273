export const ApiEndpoint = {
  Callback: 'api/callback',
  PersonaSwitch: 'api/personaswitch',
  ActivateUser: '<%userArea%>/users/terms',
  UserRoles: 'api/userrole',
  UpdateUserRoles: 'api/userrole/settings',
  ListUsers: '<%userArea%>/users',
  ListUserRoles: '<%userArea%>/lookups/roles',
  UpdatePassword: 'api/v1/users/password-change',
  RoleAuthorisation: 'api/authorization',
} as const;

export const EimsEndpoint = {
  UpdatePassword: 'api/v1/users/password-change',
} as const;
