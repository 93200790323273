// @ts-nocheck
import { Tabs } from "antd";
import React from "react";
import GeneralTable from "../../../Common/ClaimComponents/TableGrid/GeneralTable";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import TabHeader from "../../../Common/tabHeader";
import ConflictList from "./conflictList";

const { TabPane } = Tabs;

const ConflictsTab = (props: any) => {
  const {
    activeKey,
    unresolvedConflictsTotal,
    resolvedConflictsTotal,
    unresolvedConflictsList,
    resolvedConflictsList,
    pageClaims,
    handleTabChange,
    handleResolveConflict,
  } = props;
  return (
    <React.Fragment>
      <MultiTabContainer
        name="billingServiceProviderConflictsMultiTabContainer"
        claims={pageClaims}
        activeKey={activeKey}
        handleTabChange={handleTabChange}
        ignoreClaims={true}
      >
        <TabPane
          key={"1"}
          tab={
            <TabHeader
              title="Unresolved Conflicts"
              total={unresolvedConflictsTotal}
            />
          }
        >
          <GeneralTable
            claims={pageClaims}
            hasNoTitle={true}
            requiresCard={true}
            ignoreStatus={true}
          >
            <ConflictList
              data={unresolvedConflictsList}
              type="unresolved"
              handleResolveConflict={handleResolveConflict}
            />
          </GeneralTable>
        </TabPane>
        <TabPane
          key={"2"}
          tab={
            <TabHeader
              title="Resolved Conflicts"
              total={resolvedConflictsTotal}
            />
          }
        >
          <GeneralTable
            claims={pageClaims}
            hasNoTitle={true}
            requiresCard={true}
            ignoreStatus={true}
          >
            <ConflictList data={resolvedConflictsList} type={"resolved"} />
          </GeneralTable>
        </TabPane>
      </MultiTabContainer>
    </React.Fragment>
  );
};
export default ConflictsTab;
