import React from "react";
import { Table } from "antd";
import AmendmentReviewTableActions from "./AmendmentReviewTableActions";
import { tableColumnGenerator } from "../../../libs/table";
import { mustBeArray } from "../../../libs/utilities";

type Props = {
  amendmentSummary: object;
  handleDecline: (data: any, e: any) => void;
  approveAmendment: (data: any, e: any) => void;
};

const AmendmentModalTable = ({
  amendmentSummary,
  handleDecline,
  approveAmendment,
}: Props) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "assignTo",
      dataType: "assignTo",
    },
    {
      title: "Amendment Type",
      dataIndex: "amendmentTaskTypeName",
      key: "amendmentTaskTypeName",
    },
    {
      title: "Task Type",
      dataIndex: "amendmentType",
      dataType: "taskType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      dataType: "currency",
      align: "right",
    },
    {
      title: "Integrated",
      dataIndex: "isIntegrated",
      dataType: "yesno",
      align: "center",
    },
    {
      title: "Action",
      align: "right",
      key: "actions",
      render: (text: any, record: any) => {
        return (
          <AmendmentReviewTableActions
            data={record}
            handleDecline={handleDecline}
            approveAmendment={approveAmendment}
          />
        );
      },
    },
  ];

  const columnsGenareted = tableColumnGenerator(columns);
  return (
    <Table
      rowKey="amendmentId"
      columns={columnsGenareted}
      dataSource={mustBeArray(amendmentSummary)}
      pagination={{ pageSize: 10, hideOnSinglePage: true }}
    />
  );
};

export default AmendmentModalTable;
