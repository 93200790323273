import { useGetAmendmentByIdQuery } from '@xemplo/amendment-query';
import { CenteredLoaderDots } from '@xemplo/loader';
import { PayrunAmendmentDetail } from '@xemplo/payrun-amendment-detail';

export function PayrunAmendmentDetailWrapper(props: Readonly<{ amendmentId: string }>) {
  const { data } = useGetAmendmentByIdQuery({ id: props.amendmentId });

  if (!data) return <CenteredLoaderDots />;

  return <PayrunAmendmentDetail amendment={data.result} />;
}
