import styled, { css } from 'styled-components';

import {
  BodySmallMedium,
  BodySmallRegular,
  BodyStandardMedium,
  BodyStandardRegular,
  BodyXSmallMedium,
  BodyXSmallRegular,
  Colour,
} from '@xemplo/style-constants';

export const FontDef = css`
  &.small {
    ${BodyXSmallMedium};
    &.primary {
      ${BodyXSmallRegular};
    }
  }

  &.medium {
    ${BodySmallMedium};
    &.primary {
      ${BodySmallRegular};
    }
  }

  &.large {
    ${BodyStandardMedium};
    &.primary {
      ${BodyStandardRegular};
    }
  }
`;

export const MenuItem = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-out;
  ${FontDef};

  &:not(:first-child) > a {
    margin-left: 32px;
  }

  & > a {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: ${Colour.Gray[500]};
  }

  & > a,
  span {
    &.active,
    &:hover:not(.active),
    &:focus {
      color: ${Colour.Gray[800]};
    }
  }

  &.primary {
    > a {
      padding: 0 12px;

      &.active {
        color: ${Colour.Blue[600]};
      }
    }

    &:not(:first-child) > a {
      margin-left: 16px;
    }
  }

  &.disabled {
    > a {
      pointer-events: none;
      color: ${Colour.Gray[300]};
    }
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
    width: 0;
    margin-left: 0;
  }
`;
