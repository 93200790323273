import { ButtonSize, ButtonType, ButtonVariant } from '@xemplo/button';
import {
  PAYRUN_COMPONENTS,
  ROLE_AUTHORISATION_MODULE_IDS,
  useRoleAuthorisation,
} from '@xemplo/gp-api';
import { Prompt } from '@xemplo/prompts';

import * as S from './payrun-approve-reject.styles';
import { useConfirmPrompt } from './useConfirmPrompt';
import { useRejectPrompt } from './useRejectPrompt';

export const PayrunApproveRejectTestId = {
  approve: 'approve-button',
  reject: 'reject-button',
};
export function PayrunApproveReject() {
  const { promptProps, setPromptVisible, promptVisible } = useRejectPrompt();
  const {
    promptProps: approvePromptProps,
    setPromptVisible: setApprovePromptVisible,
    promptVisible: approvePromptVisible,
  } = useConfirmPrompt();

  const { componentVisible } = useRoleAuthorisation(ROLE_AUTHORISATION_MODULE_IDS.payrun);

  return (
    <>
      <S.ButtonContainer>
        {componentVisible(PAYRUN_COMPONENTS.REJECT) && (
          <S.FixedButton
            ariaLabel="Reject amendment"
            testId={PayrunApproveRejectTestId.reject}
            onClick={() => {
              setPromptVisible(true);
            }}
            variant={ButtonVariant.Light}
            type={ButtonType.Destructive}
            size={ButtonSize.Small}
            disabled={false}
          >
            Reject
          </S.FixedButton>
        )}
        {componentVisible(PAYRUN_COMPONENTS.APPROVE) && (
          <S.FixedButton
            ariaLabel="Approve amendment"
            testId={PayrunApproveRejectTestId.approve}
            onClick={() => {
              setApprovePromptVisible(true);
            }}
            variant={ButtonVariant.Light}
            type={ButtonType.Primary}
            size={ButtonSize.Small}
            disabled={false}
          >
            Approve
          </S.FixedButton>
        )}
      </S.ButtonContainer>
      <Prompt
        {...promptProps}
        open={promptVisible}
        onCloseCallback={() => setPromptVisible(false)}
      />
      <Prompt
        {...approvePromptProps}
        open={approvePromptVisible}
        onCloseCallback={() => setApprovePromptVisible(false)}
      />
    </>
  );
}

export default PayrunApproveReject;
