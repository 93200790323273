export default {
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Authentication Required",
  "408": "Request Timeout",
};

export const MISSING_EMPLOYEE_ERROR_CODE =
  "KEYPAY-EMPLOYEE-PAYSCHEDULE-NOT-MATCH-00092";
export const INVOICE_ALREADY_EXPORTED_TO_XERO =
  "XERO-EXCEPTION-VALIDATION-ERROR-00097";
export const INVALID_KEYPAY_CONNECTION_CODE =
  "KEYPAY-AUTHENTICATION-ERROR-00095";
export const NO_RECORDS_FOUND_MESSAGE =
  "There is no record qualifying your filter settings.";

export const CHANGE_PASSWORD_WARNING_LIMIT = 4;
export const CHANGE_PASSWORD_LOCKOUT_LIMIT = 5;

export const DEFAULT_UNATHORIZED_ACTION_MESSAGE =
  "You are not authorized to perform this action.";

// Errors
export const DISABLED_USER_ERROR =
  "Your account has been disabled. Please see your payroll/system administrator.";
export const UNATHORIZED_ACCESS_ERRORS =
  "You do not have access to the page. Please check with your System Administrator.";
export const FORBIDDEN_ACTION_ERROR =
  "You do not have permission to perform this action. Please check with your System Administrator.";
export const DEFAULT_UNASSIGNED_MSG =
  "There was an error when unassigning these Business Units.";

export enum CUSTOM_ERRORS_CODE {
  SKIPPED_PAY_RUN = "PAYRUN-ERROR-00132",
}
