import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { isPastDate } from '@xemplo/date-time';
import { PayrunSteps, PayrunTypes } from '@xemplo/gp-types';
import { getFilterStatusId } from '@xemplo/gp-utils';
import { useGetPayrunListQuery } from '@xemplo/payrun-query';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';

export const FUTURE_SCHEDULED_PAYRUN = '1';

export function SelectPayrun() {
  const { register, unregister, watch } = useFormContext();

  const watchPayrunType = watch('payrunType');
  const watchPayrun = watch('payrun');
  const showSelectPayrun = watchPayrunType === PayrunTypes.SCHEDULED;

  const { data: payrunList, isLoading } = useGetPayrunListQuery({
    requestParams: {
      q: { StatusID: getFilterStatusId(PayrunSteps.Submit) },
      per_page: 1000,
    },
  });
  useEffect(() => {
    if (showSelectPayrun) {
      register('payrun', {
        required: 'This field is required',
        validate: (value) => {
          if (value === PLACEHOLDER_OPTION) {
            return 'This field is required';
          }
          return true;
        },
      });
    } else {
      unregister('payrun');
    }
  }, [register, unregister, showSelectPayrun]);

  const parsedData = () => {
    const filteredList =
      payrunList?.result?.rows
        ?.filter((item) => !isPastDate(item.ammendmentDueDateTime))
        .map((item) => {
          return {
            key: item.payrunId,
            value: item.name,
          };
        }) ?? [];
    return [...filteredList, { key: FUTURE_SCHEDULED_PAYRUN, value: 'Future payrun' }];
  };

  const getItems = () => {
    if (watchPayrun) {
      return parsedData();
    }
    return [{ key: PLACEHOLDER_OPTION, value: 'Select pay run' }, ...parsedData()];
  };

  if (showSelectPayrun) {
    return (
      <S.FormLabel>
        Select pay run
        <TextDropdownMultiV2
          name="payrun"
          defaultSelectedKeys={watchPayrun && [watchPayrun]}
          items={getItems()}
          itemsLoading={isLoading}
          isDropdownMulti={false}
          selectProps={{
            placeholder: 'Select payrun',
          }}
          displayProps={{
            isDisabled: isLoading,
          }}
        />
      </S.FormLabel>
    );
  }
}
