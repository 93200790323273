import { useFormContext } from 'react-hook-form';
import { MutationFunction } from '@tanstack/react-query';
import styled from 'styled-components';

import {
  AmendmentAssigneeAndAmount,
  ChangeTaskTypes,
  PayInstructionPayload,
} from '@xemplo/gp-types';
import { MaybeErrorOrDescription } from '@xemplo/input-utils';
import {
  BodySmallRegular,
  BodyStandardMedium,
  BodyStandardSemiBold,
  Colour,
} from '@xemplo/style-constants';

import { FormFields } from '../../../create-amendment.types';
import * as S from '../../modal.styles';
import { FUTURE_SCHEDULED_PAYRUN } from '../page-1/select-payrun';

const FILE_SIZE_LIMIT = 20 * 1024 * 1024;

export function Attachment() {
  const { watch, register, getFieldState } = useFormContext();

  const watchChangeTask = watch('changeTask');

  if (watchChangeTask !== ChangeTaskTypes.PAY_INSTRUCTION) {
    return null;
  }
  //TODO: This input will be replaced with a fileUploader component once upgraded to be RHF compatible
  return (
    <InlineUploader>
      <UploadLabel htmlFor="attachment">
        Attachment
        <S.OptionalLabel> (optional)</S.OptionalLabel>
      </UploadLabel>
      <StyledInput
        type="file"
        {...register('attachment', {
          validate: (value) => {
            if (value && value[0]?.size > FILE_SIZE_LIMIT) {
              return 'File size must be less than 20MB';
            }
            return true;
          },
        })}
        accept=".csv, .xlsx, .txt, .pdf, .zip"
      />

      <MaybeErrorOrDescription
        error={getFieldState('attachment')?.invalid}
        errorMessage={getFieldState('attachment')?.error?.message}
      />
    </InlineUploader>
  );
}

export function handlePayInstruction({
  data,
  createPayInstruction,
}: {
  data: FormFields;
  createPayInstruction: MutationFunction<any, PayInstructionPayload>;
}) {
  const payInstruction: PayInstructionPayload = {
    amendmentTypeId: data.changeType,
    businessUnitId: data.businessUnit ?? '',
    amendmentTaskTypeId: data.changeTask,
    amendmentAssigneeAndAmountString: getAmendmentAssigneeAndAmount({
      assignTo: data.appliesTo,
      amount: data.rate,
    }),
    note: data.notes ?? '',
    attachment: data.attachment?.[0],
    ...(data.adhocEffectiveDate && { adhocEffectiveDate: data.adhocEffectiveDate }),
    ...(data.adhocPayDate && { adhocPayDate: data.adhocPayDate }),
    ...(data.payrun &&
      data.payrun !== FUTURE_SCHEDULED_PAYRUN && { payrunId: data.payrun }),
  };

  return createPayInstruction(payInstruction);
}

function getAmendmentAssigneeAndAmount({
  assignTo = [],
  amount = 0,
}: {
  assignTo?: string[] | string;
  amount?: number;
}) {
  if (!assignTo?.length && !amount) {
    return '';
  }
  const assignToArray = Array.isArray(assignTo) ? assignTo : [assignTo];
  const workers = assignToArray.length > 0 ? assignToArray : ['0']; //If there are no workers selected, we send a zero to the BE so we can attack the amount value
  const amendmentAssigneeAndAmount: AmendmentAssigneeAndAmount[] = [];
  workers.forEach((assignee: string) => {
    amendmentAssigneeAndAmount.push({
      assignToId: assignee,
      amount: amount,
      units: 0,
    });
  });
  return JSON.stringify(amendmentAssigneeAndAmount);
}

const UploadLabel = styled.label`
  color: ${Colour.Gray[700]};
  margin: 0;
  ${BodyStandardMedium};
`;

const InlineUploader = styled.div`
  ${BodyStandardMedium};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledInput = styled.input`
  color: ${Colour.Gray[400]};
  ${BodySmallRegular};
  &::file-selector-button {
    color: ${Colour.Gray[500]};
    ${BodyStandardSemiBold};
    border: 1px solid ${Colour.Gray[200]};
    border-radius: 24px;
    background: none;
    padding: 8px 16px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: ${Colour.Gray[600]};
      background: ${Colour.Gray[50]};
    }
  }
`;
