//@ts-nocheck
import React from "react";
import { Card, Col, Row } from "antd";
import { If, Loading } from "../../../Common/ui";
import ClaimWrapper from "../../../Common/claimWrapper";
import { getFullName, mustBeArray } from "../../../libs/utilities";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  USER_DETAIL_GENERAL_CONTAINER,
} from "../../../constants";

class userDetail extends React.Component {
  state = {
    loading: true,
    user: {},
  };

  componentDidMount() {
    const { id } = this.props.match?.params;
    const { listPSPUsers, listCompanyUsers } = this.props;
    this.props.updateHeader({
      header: {
        title: "Users",
        module: "Users",
        enableBack: true,
        action: "",
        returnUrl: "/users",
      },
    });
    switch (this.props.userType) {
      case "company":
        listCompanyUsers({
          id,
          options: {},
          cancelToken: this.props.signal.cancelToken,
          userMode: this.props.userMode,
        }).then((resp) => {
          this.setState({ loading: false });

          if (resp && resp.data) {
            this.props.updateHeader({
              header: {
                gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserDetail,
                title: `${resp.data.firstName} ${resp.data.lastName}`,
              },
            });
          }
        });
        break;
      case "psp":
        listPSPUsers({
          id,
          options: {},
          cancelToken: this.props.signal.cancelToken,
        }).then((resp) => {
          this.setState({ loading: false });

          if (resp && resp.data) {
            this.props.updateHeader({
              header: {
                gaTitle:
                  GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderUserDetail,
                title: `${resp.data.firstName} ${resp.data.lastName}`,
              },
            });
          }
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { companyUserDetails, pspUserDetails, userType, pageClaims } =
      this.props;
    const user = userType === "company" ? companyUserDetails : pspUserDetails;
    const roles = mustBeArray(user?.userPersona).reduce((a, b) => {
      return `${a ? `${a}, ${b.roleName}` : b.roleName}`;
    }, "");
    return (
      <If
        condition={this.state.loading}
        then={<Loading />}
        else={
          <ClaimWrapper
            name={USER_DETAIL_GENERAL_CONTAINER}
            claims={pageClaims}
          >
            <Card title="User Profile" className="card shadow-s-0">
              <Row>
                <Col span={2}>Name: </Col>
                <Col span={12}>{getFullName({ name: user })}</Col>
              </Row>
              <Row>
                <Col span={2}>Email: </Col>
                <Col span={12}>{user?.emailAddress}</Col>
              </Row>
              <Row>
                <Col span={2}>Mobile: </Col>
                <Col span={12}>{user?.mobilePhone}</Col>
              </Row>
              <Row>
                <Col span={2}>Roles:</Col>
                <Col span={12}>{roles}</Col>
              </Row>
            </Card>
          </ClaimWrapper>
        }
      />
    );
  }
}

export default userDetail;
