import { AvatarSize } from '@xemplo/avatar';
import { DisplayDateFormat } from '@xemplo/date-time';
import { Amendment } from '@xemplo/gp-types';
import { getAssignedWorker, getAssignedWorkerDisplayName } from '@xemplo/gp-utils';
import { DawnChartPath } from '@xemplo/icons';

import { amendmentDetailsModalTestIds } from '../../../use-amendment-deatils-modal';
import { formatDate } from '../../amendment-details-modal.helper';
import * as S from '../../amendment-details-modal-styles';

export interface AmendmentDetailModalHeaderProps {
  amendment: Amendment;
}

export function ModalHeader(props: AmendmentDetailModalHeaderProps) {
  const { amendment } = props;
  const amendmentTaskTypeName = amendment.amendmentTaskTypeName;
  const worker = getAssignedWorker(amendment.assignTo);

  const createdDate = formatDate({
    value: amendment.requestedDate,
    format: DisplayDateFormat.Medium,
  });
  return (
    <S.Header data-testid={amendmentDetailsModalTestIds.header}>
      {worker?.[0] ? (
        <S.Avatar
          user={worker?.[0]}
          size={AvatarSize.l}
          data-chromatic="ignore"
          customText={worker?.length > 1 ? worker.length.toString() : undefined}
        />
      ) : (
        <S.LeadIconWrapper>
          <DawnChartPath />
        </S.LeadIconWrapper>
      )}
      <div>
        <S.WorkerName>
          {getAssignedWorkerDisplayName(amendment.assignTo)}
          <S.TaskType>({amendmentTaskTypeName})</S.TaskType>
        </S.WorkerName>

        <S.DateText>Created {createdDate}</S.DateText>
      </div>
    </S.Header>
  );
}
