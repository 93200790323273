const PAYROLL_PROVIDER_LIST = "PAYROLL_PROVIDER_LIST";
const PAYROLL_PROVIDER_DETAIL = "PAYROLL_PROVIDER_DETAIL";
const LIST_PSP_USERS = "LIST_PSP_USERS";
const LIST_PSP_USER_DETAILS = "LIST_PSP_USER_DETAILS";

const payrollProviderAction = {
  PAYROLL_PROVIDER_LIST,
  PAYROLL_PROVIDER_DETAIL,
  LIST_PSP_USER_DETAILS,
  LIST_PSP_USERS,
};

export default payrollProviderAction;
