import styled, { css } from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { Colour, MinBreakpoint } from '@xemplo/style-constants';
import { zIndex } from '@xemplo/zindex';

export const widthBreakPoints = css`
  padding-inline: 32px;
  @media ${MinBreakpoint.xxlarge} {
    padding-inline: 48px;
  }
  @media ${MinBreakpoint.xxxlarge} {
    padding-inline: 72px;
  }
`;

export const LayoutContainer = styled.div`
  background: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
`;

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-block: 54px 32px;
  ${widthBreakPoints};
`;

export const NavigationContainer = styled.div`
  position: sticky;
  height: 72px;
  border-bottom: 1px solid ${Colour.Black[10]};
  display: flex;
  align-items: center;
  background: white;
  flex: 0 0 auto;
  top: 0;
  ${widthBreakPoints};
  z-index: ${zIndex.StickyHeader};
`;

export const MainSectionContainer = styled.main`
  display: flex;
  background: ${Colour.Gray[50]};
  flex-grow: 1;
  position: relative;
`;

export const Divider = styled.div`
  background: ${Colour.Black[5]};
  height: 1px;
  margin-inline: 32px;
  @media ${MinBreakpoint.xxlarge} {
    margin-inline: 48px;
  }
  @media ${MinBreakpoint.xxxlarge} {
    margin-inline: 72px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;
