import { BadgeSize, BadgeType } from '@xemplo/badge';
import { DawnTick16 } from '@xemplo/icons';

import { getAmendmentStatusBadgeColor } from './amendment-status.helper';
import * as S from './amendment-status.styles';
import { AmendmentStatusProps } from './amendment-status.types';

export function AmendmentStatus({
  status,
  statusName,
  testId,
  className,
}: Readonly<AmendmentStatusProps>) {
  const color = getAmendmentStatusBadgeColor(status);
  return (
    <S.AmendmentBadge
      className={className}
      type={BadgeType.Square}
      color={color}
      value={statusName}
      leadingIcon={statusName === 'Approved' ? <DawnTick16 /> : undefined}
      size={BadgeSize.Medium}
      data-testid={testId}
    />
  );
}
