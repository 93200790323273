import { Colour } from '@xemplo/style-constants';

import * as S from './loader.styles';
import { LoaderDotsProps, LoaderDotsVariant } from './loader.types';

const colourMap = {
  [LoaderDotsVariant.Light]: Colour.White[100],
  [LoaderDotsVariant.Dark]: Colour.Gray[200],
};

export const LoadingDotTestIds = {
  dot: (index: number) => `loading-dot-${index}`,
};

export function LoaderDots(props: LoaderDotsProps) {
  const { baseSize = 4, variant = LoaderDotsVariant.Dark } = props;
  return Array.from({ length: 3 }).map((_, i) => (
    <S.Dot
      key={`loading-dot-${i}`}
      data-testid={LoadingDotTestIds.dot(i)}
      $colour={colourMap[variant]}
      $baseSize={baseSize}
    />
  ));
}
