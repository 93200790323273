import "../src/styles/custom.css";
import "../src/styles/index.css";
import "../src/styles/index.less";

import { ReactHooksWrapper } from "react-hooks-outside";
import { BrowserRouter } from "react-router-dom";

import { RouteInit } from "./routes";
import { ErrorBoundary } from "@xemplo/error-boundary";

import { ModalProvider, Modal } from "@xemplo/modal";
import { ToastProvider } from "@xemplo/toast";

import { FullscreenLoader } from "./Organization/components/common/Loader/FullscreenLoader";
/**
 * TODO: Convert the Browser Router to RouterProvider
 * The RouterProvider should be initialized within the index.tsx file
 * The App Component should be part of the route definition and init the global stuff
 *
 */
export const App = () => {
  return (
    <BrowserRouter>
      <ModalProvider>
        <ToastProvider>
          <FullscreenLoader />
          <Modal />
          <RouteInit />
        </ToastProvider>
      </ModalProvider>
      <ErrorBoundary>
        <ReactHooksWrapper />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

/** @alias */
export default App;
