import { SVGProps } from 'react';
const DawnCalendarSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.25a.75.75 0 0 1 .75.75v.75h6.5V2a.75.75 0 0 1 1.5 0v.78c1.536.128 2.754.66 3.612 1.585.984 1.06 1.388 2.52 1.388 4.135V13a.75.75 0 0 1-1.5 0V9.84H3.75V17c0 1.385.346 2.425.987 3.115.631.68 1.656 1.135 3.263 1.135h5.37a.75.75 0 0 1 0 1.5H8c-1.893 0-3.368-.545-4.362-1.615-.984-1.06-1.388-2.52-1.388-4.135V8.5c0-1.615.404-3.075 1.388-4.135.858-.925 2.076-1.457 3.612-1.585V2A.75.75 0 0 1 8 1.25ZM7.25 5v-.714c-1.185.12-1.986.532-2.513 1.1-.616.663-.96 1.649-.985 2.954h16.496c-.025-1.305-.37-2.291-.985-2.955-.527-.567-1.328-.978-2.513-1.099V5a.75.75 0 0 1-1.5 0v-.75h-6.5V5a.75.75 0 0 1-1.5 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2 14.25a3.95 3.95 0 1 0 2.216 7.22c.017.021.035.041.054.06l1 1a.75.75 0 1 0 1.06-1.06l-1-1a.757.757 0 0 0-.06-.054 3.95 3.95 0 0 0-3.27-6.166Zm-2.45 3.95a2.45 2.45 0 1 1 4.9 0 2.45 2.45 0 0 1-4.9 0Z"
      fill="currentColor"
    />
    <path
      d="M11.995 12.7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM7.294 13.7a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM8.294 15.7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCalendarSearch;
