import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../hooks/use-auth';

export const requireAuth =
  <P extends object>(WrappedComponent: React.ComponentType<P>, shouldNavigate = true) =>
  (props: P) => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      const navigateTo = shouldNavigate ? () => navigate('/login') : undefined;
      !isAuthenticated && navigateTo && navigateTo();
    }, [isAuthenticated, navigate]);

    return isAuthenticated ? <WrappedComponent {...(props as P)} /> : null;
  };

export default requireAuth;
