//@ts-nocheck
import React, { Component } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Row, Col } from "antd";
import {
  conditionalParameter,
  getConditionalResponse,
  mustBeArray,
} from "../../../libs/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faThLarge } from "@fortawesome/free-solid-svg-icons";
import {
  GRID_SIZE_OPTIONS,
  TABLE_SIZE_OPTIONS,
} from "../../../constants/defaultClaims";
import _ from "lodash";

type GridTableViewOptionState = any;

type GridTableViewOptionProps = {
  gridable: boolean;
  handleGridChange?: (options: boolean) => void;
  gridView: boolean;
  fieldOptions: any;
  updateGridField?: (fields: object) => void;
  gridSortInfo: any;
  updatePageSize?: (value: any) => void;
  grid: object;
  per_page?: number;
  hideSorting?: boolean;
  dashboardView?: boolean;
};

class GridTableViewOption extends Component<
  GridTableViewOptionProps,
  GridTableViewOptionState
> {
  constructor(props: GridTableViewOptionProps) {
    super(props);
    this.state = {
      gridMenuVisible: false,
      tableMenuVisible: false,
      gridOrderOptions: [
        { id: 1, value: "asc", field: "Ascending" },
        { id: 2, value: "desc", field: "Descending" },
      ],
    };
  }
  handleVisibleChange = ({ label, event }: any) => {
    const { gridView } = this.props;
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState((prevState: any) => {
      switch (label) {
        case "gridMenuVisible":
          if (gridView) {
            prevState[label] = !prevState[label];
          }
          break;
        case "tableMenuVisible":
          if (!gridView) {
            prevState[label] = !prevState[label];
          }
          break;
        default:
          break;
      }
      return prevState;
    });
  };
  hideMenu = ({ label }: any) => {
    this.setState((prevState: any) => {
      prevState[label] = false;
      return prevState;
    });
  };
  viewSorting = () => {
    return !!this.props.hideSorting;
  };
  viewPagination = () => {
    return true;
  };
  gridViewClassName = (gridView: any) => {
    return gridView ? "color-primary" : "";
  };
  getGridMenuOption = ({ dashboardView, gridMenuOption, gridable }: any) => {
    if (gridable !== "false" && dashboardView) return gridMenuOption;

    return <React.Fragment />;
  };
  getGridDropdownOption = ({
    dashboardView,
    gridMenuOption,
    gridable,
  }: any) => {
    if (gridable !== "false" && !dashboardView) return gridMenuOption;

    return <React.Fragment />;
  };
  getTableDropdownOption = ({
    tableMenuOption,
    tableDropdownOption,
    dashboardView,
  }: any) => {
    if (dashboardView) return tableMenuOption;
    return tableDropdownOption;
  };
  render() {
    const {
      gridable,
      fieldOptions,
      handleGridChange,
      gridView,
      updateGridField,
      gridSortInfo,
      updatePageSize,
      grid,
      per_page,
      dashboardView,
    } = this.props;
    const { gridMenuVisible, tableMenuVisible } = this.state;
    const viewSorting = this.viewSorting();
    const viewPagination = this.viewPagination();
    const gridMenu = (
      <Menu className="custom-grid-option">
        {viewSorting ? (
          <React.Fragment>
            {mustBeArray(fieldOptions).map((option: any, i: any) => {
              return (
                <Menu.Item
                  key={`${i}a`}
                  onClick={() =>
                    updateGridField({ value: option.value, field: "field" })
                  }
                >
                  {gridSortInfo.field === option.value ? (
                    <Row gutter={24}>
                      <Col span={4}>
                        <CheckOutlined them="filled" className="text-xs" />
                      </Col>
                      <Col span={20}>{option.field}</Col>
                    </Row>
                  ) : (
                    <Row gutter={24}>
                      <Col span={20} offset={4}>
                        {option.field}
                      </Col>
                    </Row>
                  )}
                </Menu.Item>
              );
            })}
            <Menu.Divider key="b" />
            {!_.isEmpty(mustBeArray(this.state.gridOrderOptions)) &&
              mustBeArray(this.state.gridOrderOptions).map(
                (option: any, i: any) => {
                  return (
                    <Menu.Item
                      key={i}
                      onClick={(field) =>
                        updateGridField({ value: option.value, field: "order" })
                      }
                    >
                      {gridSortInfo.order === option.value ? (
                        <Row gutter={24}>
                          <Col span={4}>
                            <CheckOutlined them="filled" className="text-xs" />
                          </Col>
                          <Col span={20}>{option.field}</Col>
                        </Row>
                      ) : (
                        <Row gutter={24}>
                          <Col span={20} offset={4}>
                            {option.field}
                          </Col>
                        </Row>
                      )}
                    </Menu.Item>
                  );
                }
              )}
          </React.Fragment>
        ) : (
          <React.Fragment key="c" />
        )}
        {viewPagination &&
          viewSorting &&
          !_.isEmpty(mustBeArray(this.state.gridOrderOptions)) && (
            <Menu.Divider />
          )}
        {viewPagination &&
          mustBeArray(GRID_SIZE_OPTIONS).map((option: any, i: any) => {
            return (
              <Menu.Item
                key={option.value}
                onClick={(field) =>
                  updatePageSize(
                    conditionalParameter({ data: field, field: "key" })
                  )
                }
              >
                {`${conditionalParameter({
                  data: grid,
                  field: "row",
                })}X${conditionalParameter({ data: grid, field: "col" })}` ===
                option.value ? (
                  <Row gutter={24}>
                    <Col span={4}>
                      <CheckOutlined them="filled" className="text-xs" />
                    </Col>
                    <Col span={20}>{option.name}</Col>
                  </Row>
                ) : (
                  <Row gutter={24}>
                    <Col span={20} offset={4}>
                      {option.name}
                    </Col>
                  </Row>
                )}
              </Menu.Item>
            );
          })}
      </Menu>
    );
    const tableMenu = (
      <Menu className="custom-table-option">
        {viewPagination &&
          mustBeArray(TABLE_SIZE_OPTIONS).map((option: any, i: any) => {
            return (
              <Menu.Item
                key={option.value}
                onClick={(field) =>
                  updatePageSize(
                    Number(conditionalParameter({ data: field, field: "key" }))
                  )
                }
              >
                {Number(per_page) === Number(option.value) ? (
                  <Row gutter={24}>
                    <Col span={4}>
                      <CheckOutlined them="filled" className="text-xs" />
                    </Col>
                    <Col span={20}>{option.name}</Col>
                  </Row>
                ) : (
                  <Row gutter={24}>
                    <Col span={20} offset={4}>
                      {option.name}
                    </Col>
                  </Row>
                )}
              </Menu.Item>
            );
          })}
      </Menu>
    );

    const TableMenuOption = (
      <span
        className={`btn-ghost-link px-0 mr-[15px] no-border ${this.gridViewClassName(
          gridView
        )}`}
      >
        <FontAwesomeIcon
          className={`mr-1 hand ${getConditionalResponse({
            condition: !gridView,
            resp1: "color-primary",
            resp2: "",
          })} fa-w-16`}
          icon={faTable}
          onClick={(event) =>
            this.handleVisibleChange({ event, label: "tableMenuVisible" })
          }
          height={16}
          width={16}
        />
        <span
          onClick={() => handleGridChange({ gridView: false })}
          className="hand"
        >
          Table View
        </span>
      </span>
    );

    const GridMenuOption = (
      <span
        className={`btn-ghost-link px-0 mr-[15px] no-border ${this.gridViewClassName(
          gridView
        )}`}
      >
        <FontAwesomeIcon
          className={`mr-1 hand ${this.gridViewClassName(gridView)} fa-w-16`}
          icon={faThLarge}
          onClick={(event) =>
            this.handleVisibleChange({ event, label: "gridMenuVisible" })
          }
          height={16}
          width={16}
        />
        <span
          onClick={() => handleGridChange({ gridView: true })}
          className="hand"
        >
          Grid View
        </span>
      </span>
    );
    return (
      <div className="grid-table-option-dropdown ml-4">
        {this.getGridDropdownOption({
          gridable,
          dashboardView,
          gridMenuOption: (
            <Dropdown
              overlay={gridMenu}
              onVisibleChange={(value) =>
                this.hideMenu({ label: "gridMenuVisible", value })
              }
              visible={gridMenuVisible}
              trigger={["click"]}
              placement="bottomLeft"
            >
              {GridMenuOption}
            </Dropdown>
          ),
        })}

        {this.getGridMenuOption({
          gridable,
          dashboardView,
          gridMenuOption: GridMenuOption,
        })}
        {this.getTableDropdownOption({
          tableMenuOption: TableMenuOption,
          dashboardView,
          tableDropdownOption: (
            <Dropdown
              overlay={tableMenu}
              onVisibleChange={(value) =>
                this.hideMenu({ label: "tableMenuVisible", value })
              }
              visible={tableMenuVisible}
              trigger={["click"]}
              placement="bottomLeft"
            >
              {TableMenuOption}
            </Dropdown>
          ),
        })}
      </div>
    );
  }
}

export default GridTableViewOption;
