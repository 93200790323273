import classNames from "classnames";
import { If } from "../../../Common/ui";
import { MAX_VARIANCE_PERIODS } from "../../../constants/variance";
import {
  calculateVarianceAndSD,
  getVarianceColWidth,
  getVarianceSDColWidth,
} from "../../../libs";

type Props = {
  data: any;
  totalAvailablePeriod: number;
  record: any;
  getAmount: any;
  field: any;
  labelClassName: string;
};
const VariancePayItems = ({
  totalAvailablePeriod,
  record,
  getAmount,
  field = "",
  data,
  labelClassName,
}: Props) => {
  return (
    <If
      condition={!isNaN(totalAvailablePeriod)}
      then={
        <div className="flex">
          <div className={classNames(labelClassName, "pl-12")}>
            {data?.paymentItemName}
          </div>
          {MAX_VARIANCE_PERIODS.map((value, index) => {
            return (
              <If
                key={index}
                condition={totalAvailablePeriod >= value}
                then={getAmount({
                  field: "",
                  record,
                  position: value - 1,
                  type: "paymentItemName",
                  paymentItemName: data?.paymentItemName,
                })}
              />
            );
          })}
          <div
            className={`flex-1-1-${getVarianceColWidth({
              totalPeriod: totalAvailablePeriod,
            })} text-right`}
          >
            {
              calculateVarianceAndSD({
                title: data?.paymentItemId,
                field,
                record,
                type: "payItem",
              }).variance
            }
          </div>
          <div
            className={`flex-1-1-${getVarianceSDColWidth({
              totalPeriod: totalAvailablePeriod,
            })} text-right`}
          >
            {
              calculateVarianceAndSD({
                title: data?.paymentItemId,
                field,
                record,
                type: "payItem",
              }).sd
            }
          </div>
        </div>
      }
    />
  );
};

export default VariancePayItems;
