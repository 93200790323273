export const BreakpointSize = {
  small: 320,
  medium: 720,
  mediumV2: 1024,
  large: 922,

  /**
   * @deprecated do not use this. According to the new Dawn UI Specs
   * 1024px is the new medium breakpoint.
   */
  largeV2: 1024,
  xlarge: 1168,
  xxlarge: 1376,
  xxxlarge: 1648,
  xxxxlarge: 1919,
};

export enum Breakpoint {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
  XXLarge = 'xxlarge',
  XXXLarge = 'xxxlarge',
}

export const MinBreakpoint = {
  small: `(min-width: ${BreakpointSize.small}px)`,
  medium: `(min-width: ${BreakpointSize.medium}px)`,
  mediumV2: `(min-width: ${BreakpointSize.mediumV2}px)`,
  large: `(min-width: ${BreakpointSize.large}px)`,

  /**
   * @deprecated do not use this. According to the new Dawn UI Specs
   * 1024px is the new medium breakpoint.
   */
  largeV2: `(min-width: ${BreakpointSize.largeV2}px)`,
  xlarge: `(min-width: ${BreakpointSize.xlarge}px)`,
  xxlarge: `(min-width: ${BreakpointSize.xxlarge}px)`,
  xxxlarge: `(min-width: ${BreakpointSize.xxxlarge}px)`,
  xxxxlarge: `(min-width: ${BreakpointSize.xxxxlarge}px)`,
};

export const MaxBreakpoint = {
  small: `(max-width: ${BreakpointSize.small}px)`,
  medium: `(max-width: ${BreakpointSize.medium}px)`,
  large: `(max-width: ${BreakpointSize.large}px)`,
  largeV2: `(max-width: ${BreakpointSize.largeV2}px)`,
  xlarge: `(max-width: ${BreakpointSize.xlarge}px)`,
  xxlarge: `(max-width: ${BreakpointSize.xxlarge}px)`,
  xxxlarge: `(max-width: ${BreakpointSize.xxxlarge}px)`,
  xxxxlarge: `(max-width: ${BreakpointSize.xxxxlarge}px)`,
};
