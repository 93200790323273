import { DashboardItem } from "@xemplo/gp-types";

type DashboardState = {
  list: DashboardItem[];
  activeDashboard: string;
};

const initialState: DashboardState = {
  list: [],
  activeDashboard: "Default",
};

export default function dashboardReducer(
  state = initialState,
  action: { type: string; data: DashboardItem[] }
) {
  const { data, type } = action;
  switch (type) {
    case "UPDATE_DASHBOARD_LIST": {
      return {
        ...state,
        list: data,
      };
    }
    case "CLEAR_DASHBOARD_LIST": {
      return {
        ...state,
        list: [],
      };
    }
    default:
      return state;
  }
}
