import { useContext } from 'react';

import { ModalContext } from '../modal.context';

/** The context hook used to access the state values and functions */
export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};
