import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import { BodyXSmallRegular, Colour, Shadow } from '@xemplo/style-constants';

import { ActionSheetItemVariant } from './action-sheet.types';

export const PopoverContent = styled.div<{ $maxWidth: number | undefined }>`
  display: flex;
  width: ${(props) => (props.$maxWidth ? `${props.$maxWidth}px` : 'auto')};
  min-width: 200px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden auto;
  background: transparent;
  ${Shadow.Medium1};
  border: 1px solid ${Colour.Gray[200]};
  border-radius: 8px;
`;

export const List = styled.div`
  background: ${Colour.White[100]};
  overflow: auto;
  padding: 8px 0;
  width: 100%;
`;

export const ListItem = styled.div<{ $variant: ActionSheetItemVariant }>`
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  ${BodyXSmallRegular};

  background: ${Colour.White[100]};
  ${(props) => variant(props.$variant)};
`;

function variant(variant: ActionSheetItemVariant) {
  switch (variant) {
    case ActionSheetItemVariant.Secondary:
      return css`
        color: ${Colour.Blue[500]};

        &:hover {
          background: ${Colour.Gray[50]};
          color: ${Colour.Blue[600]};
        }
      `;

    case ActionSheetItemVariant.Destructive:
      return css`
        color: ${Colour.Red[500]};

        &:hover {
          background: ${Colour.Gray[50]};
          color: ${Colour.Red[600]};
        }
      `;

    case ActionSheetItemVariant.Default:
    default:
      return css`
        color: ${Colour.Gray[500]};

        &:hover {
          background: ${Colour.Gray[50]};
          color: ${Colour.Gray[700]};
        }
      `;
  }
}

export const ButtonItem = styled.button`
  box-sizing: border-box;
  color: inherit;
  background: transparent;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  align-self: stretch;
  border: none;
  width: 100%;
  text-align: left;
  ${BodyXSmallRegular};
`;

export const LinkItem = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ListDivider = styled.hr`
  margin: 8px 0;
  border: none;
  border-bottom: 1px solid ${Colour.Gray[200]};
`;

export const TriggerButton = styled(IconButton)<{ active: boolean }>`
  background: ${(props) => props.active && Colour.Black[5]};
`;
