import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { formatToCurrency } from '@xemplo/common-util';
import { CenteredLoaderDots } from '@xemplo/loader';
import {
  useGetPayrunEarningLineDetailsVarianceQuery,
  useGetPayrunMilestonesQuery,
} from '@xemplo/payrun-query';
import { formatNumberWithDecimals } from '@xemplo/table';

import * as S from '../payrun-earning-line-detail.styles';

export function PayrunEarningLineDetailBodyDetail() {
  const { workerId, id } = useParams();
  const { data, isLoading } = useGetPayrunEarningLineDetailsVarianceQuery({
    id: id ?? null,
    workerId: workerId ?? null,
  });
  const { data: milestoneData } = useGetPayrunMilestonesQuery({ id: id ?? null });

  const parsedRows = data?.result.parsedRows;

  if (isLoading) return <CenteredLoaderDots />;
  if (!data || !parsedRows) return null;

  return (
    <S.DetailContainer>
      {parsedRows.map((table, index) => (
        <S.Table key={`${table[0].payCategoryName}-${index}`}>
          {index === 0 && (
            <thead>
              <tr>
                <th></th>
                <th>Units</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
          )}
          <tbody>
            {table.map((line, index) => (
              <tr key={index} className={classNames({ heading: index === 0 })}>
                <td>{line.payCategoryName}</td>
                <td>
                  {line.currentUnit
                    ? `${formatNumberWithDecimals({ value: line.currentUnit })}`
                    : ''}
                </td>
                <td>
                  {line.currentRate ? formatToCurrency({ value: line.currentRate }) : ''}
                </td>
                <td>{formatToCurrency({ value: line.current })}</td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      ))}
      {milestoneData?.isInvoiceNeeded && (
        <S.TotalTable>
          <tbody>
            <tr>
              <td className="total"> Total Payable</td>
              <td>{formatToCurrency({ value: data?.result?.totalPayable?.current })}</td>
            </tr>
          </tbody>
        </S.TotalTable>
      )}
    </S.DetailContainer>
  );
}
