import styled from 'styled-components';

import { BodySmallMedium, Colour, MinBreakpoint } from '@xemplo/style-constants';

export const TooltipWrapper = styled.div`
  position: absolute;
  padding: 2px;
  background: ${Colour.Gradient.Linear135};
  border-radius: 8px;
  top: 2px;
  left: 84px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  display: none;

  filter: drop-shadow(0 8px 12px rgba(64, 73, 104, 0.12));
  transform: translate(-5px, 0);
  transition: transform 0.2s cubic-bezier(0, 0.55, 0.45, 1) 0.3s, opacity 0.2s ease 0.3s,
    visibility 0.2s ease 0.5s;

  &[aria-hidden='false'] {
    opacity: 1;
    visibility: visible;
  }

  &[data-index='0'] {
    top: 36px;
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-outside-1_10_1521' maskUnits='userSpaceOnUse' x='0' y='0' width='14' height='24' fill='black'%3E%3Crect fill='white' width='14' height='24'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.47607 8.21523C6.12859 6.61012 8 4.79241 8 2H12V22H8C8 19.2076 6.12859 17.3899 4.47607 15.7848C3.16963 14.5158 2 13.3797 2 12C2 10.6203 3.16963 9.48418 4.47607 8.21523Z'/%3E%3C/mask%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.47607 8.21523C6.12859 6.61012 8 4.79241 8 2H12V22H8C8 19.2076 6.12859 17.3899 4.47607 15.7848C3.16963 14.5158 2 13.3797 2 12C2 10.6203 3.16963 9.48418 4.47607 8.21523Z' fill='white'/%3E%3Cpath d='M8 2V0H6V2H8ZM4.47607 8.21523L3.08258 6.78059L3.08258 6.78059L4.47607 8.21523ZM12 2H14V0H12V2ZM12 22V24H14V22H12ZM8 22H6V24H8V22ZM4.47607 15.7848L3.08258 17.2194L3.08258 17.2194L4.47607 15.7848ZM6 2C6 3.82607 4.83236 5.08101 3.08258 6.78059L5.86955 9.64988C7.42482 8.13923 10 5.75875 10 2H6ZM8 4H12V0H8V4ZM10 2V22H14V2H10ZM12 20H8V24H12V20ZM3.08258 17.2194C4.83236 18.919 6 20.1739 6 22H10C10 18.2413 7.42482 15.8608 5.86955 14.3501L3.08258 17.2194ZM0 12C0 13.2872 0.553158 14.3309 1.1411 15.1245C1.71288 15.8963 2.46104 16.6157 3.08258 17.2194L5.86955 14.3501C5.18466 13.6849 4.69478 13.2018 4.35514 12.7433C4.03166 12.3067 4 12.0926 4 12H0ZM3.08258 6.78059C2.46104 7.3843 1.71288 8.10371 1.1411 8.87548C0.553158 9.66907 0 10.7128 0 12H4C4 11.9074 4.03166 11.6933 4.35514 11.2567C4.69478 10.7982 5.18466 10.3151 5.86955 9.64988L3.08258 6.78059Z' fill='url(%23paint0_linear_10_1521)' mask='url(%23path-1-outside-1_10_1521)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_10_1521' x1='1.99998' y1='-2.99999' x2='21.5254' y2='37.6128' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.809939' stop-color='%233991FF'/%3E%3Cstop offset='1' stop-color='%23A58AFF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    position: absolute;
    left: -6px;
    top: 9px;
  }

  @media ${MinBreakpoint.mediumV2} {
    display: block;
  }
`;

export const Tooltip = styled.div`
  ${BodySmallMedium};
  display: inline-block;
  background: ${Colour.White[100]};
  padding: 8px 12px;
  border-radius: 6px;
  white-space: nowrap;
  max-width: 200px;
  z-index: 1;
  text-transform: none;

  &::after {
    content: ' ';
    background-color: ${Colour.White[100]};
    position: absolute;
    width: 6px;
    height: 24px;
    left: 2px;
    top: 9px;
  }
`;
