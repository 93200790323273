import { GeneratePayrun } from "@xemplo/generate-payrun";
import styled from "styled-components";
import { useIsUserInRole } from "../../../hooks";
import { PersonaRole } from "@xemplo/gp-types";

interface GeneratePayrunButtonProps {
  payScheduleId?: string;
}

export function GeneratePayrunButton(
  props: Readonly<GeneratePayrunButtonProps>
) {
  const userHasPermission = useIsUserInRole([
    PersonaRole.PSPAdmin,
    PersonaRole.SystemAdmin,
  ]);
  if (!userHasPermission) {
    return null;
  }
  return (
    <Wrapper>
      <GeneratePayrun payScheduleId={props?.payScheduleId} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;
