import { useReducer, useState } from 'react';
import { UserManager } from 'oidc-client-ts';

import { initialAuthState, reducer } from '../auth-provider.reducer';
import { AuthProviderProps } from '../auth-provider.types';

import { useUserManagerEvents } from './use-manager-events';
import { useSigninCallback } from './use-signin-callback';
import { useUserManager } from './use-user-manager';

const useAuthState = (props: Omit<AuthProviderProps, 'children'>) => {
  const { onSigninCallback, onRemoveUser, ...config } = props;
  const [userManager] = useState(() => new UserManager(config));
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const api = useUserManager(userManager, dispatch, state, onRemoveUser);
  useSigninCallback({ userManager, dispatch, onSigninCallback });
  useUserManagerEvents(userManager, dispatch);

  return { ...state, ...api };
};

export default useAuthState;
