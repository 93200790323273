// @ts-nocheck
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Card, Switch } from "antd";
import React, { useEffect } from "react";
import SimpleButton from "../../Common/ClaimComponents/Button/SimpleButton";
import { delay } from "../../libs/utilities";

const PersonalizationSettings = (props) => {
  const {
    form,
    loading,
    handlePersonalizationSubmit,
    userPersonalizationOptions,
    resetPersonalizationLoading,
  } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    delay(100).then(() => {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
      userPersonalizationOptions.forEach((option) => {
        form.setFieldsValue({
          [option.name]: ["true", "True"].includes(option.value) ? true : false,
        });
      });
    });
  }, []);

  const handleReset = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      handlePersonalizationSubmit({ values, reset: true });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      handlePersonalizationSubmit({ values, reset: false });
    });
  };
  const formItemLayout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  };
  return (
    <Card className="antd-form-left-label card-body-overflow-hidden">
      <Form colon={false} {...formItemLayout} autoComplete="off">
        {userPersonalizationOptions.map((option, index) => {
          return (
            <Form.Item label={option.label} key={index} className="mb-3">
              {getFieldDecorator(option.name, {
                valuePropName: "checked",
                initialValue: option.value === "true",
              })(<Switch className="float-right" />)}
            </Form.Item>
          );
        })}
        <div className="flex justify-between flex-wrap">
          <SimpleButton
            buttonText="Clear Personalization"
            ignoreStatus={true}
            className="rounded-[20px] mb-4"
            onClick={handleReset}
            loading={resetPersonalizationLoading}
          />
          <SimpleButton
            buttonText="Save Changes"
            ignoreStatus={true}
            className="rounded-[20px] bg-success mb-4"
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </Form>
    </Card>
  );
};

export default Form.create({ name: "personalizationsForm" })(
  PersonalizationSettings
);
