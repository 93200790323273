import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';

import { useGetChangeTaskSubTypeQuery } from '@xemplo/amendment-query';
import { ChangeTaskTypes } from '@xemplo/gp-types';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';
import { FUTURE_SCHEDULED_PAYRUN } from '../page-1/select-payrun';

const changeTaskIdMap = {
  1: 'Allowance',
  2: 'Deduction',
  3: 'Salary',
  7: 'Termination',
};

export const SubType = () => {
  const { watch, register, resetField, unregister, setValue } = useFormContext();
  /**
   * There is an issue with the azure stoage version which causes a 403 if a token is provided.
   * We are using a fresh axios instance with none of out headers to get around this issue.
   */
  const client = axios.create({ headers: { 'Cache-Control': 'no-cache' } });
  const watchChangeTask = watch('changeTask');
  const watchBusinessUnit = watch('businessUnit');
  const watchPayrun = watch('payrun');
  const watchChangeTaskSubType = watch('changeTaskSubType');
  const isPayInstruction = watchChangeTask === ChangeTaskTypes.PAY_INSTRUCTION;
  const payrunId =
    watchPayrun === FUTURE_SCHEDULED_PAYRUN || watchPayrun === PLACEHOLDER_OPTION
      ? null
      : watchPayrun;
  const { data: payrunData } = useGetPayrunByIdQuery({
    id: payrunId,
  });

  const watchBUValue =
    watchBusinessUnit === PLACEHOLDER_OPTION ? null : watchBusinessUnit;
  const amendmentTaskTypeName =
    changeTaskIdMap[watchChangeTask as keyof typeof changeTaskIdMap];

  useEffect(() => {
    resetField('changeTaskSubType');
    register('changeTaskSubType', {
      valueAsNumber: true,
      required: isPayInstruction ? false : 'This field is required',
      validate: (value) => {
        if (isPayInstruction) {
          return true;
        }
        if (value === PLACEHOLDER_OPTION || !value || value === +PLACEHOLDER_OPTION) {
          return 'This field is required!';
        }
        return true;
      },
    });
  }, [isPayInstruction, register, resetField, watchChangeTask]);

  const { data, isLoading } = useGetChangeTaskSubTypeQuery({
    requestParams: {
      q: {
        changeTaskValueId:
          watchChangeTask === PLACEHOLDER_OPTION ? null : watchChangeTask,
        businessUnitId: payrunData?.result?.businessUnitID ?? watchBUValue ?? null,
      },
    },
  });

  //For termination amendments we need to fetch a json file to populate the amendment summary - no idea why.
  const handleTemplateUrl = useCallback(
    async (templateUrl: string) => {
      const summary = await client.get(templateUrl);
      setValue('amendmentSummary', JSON.stringify(summary.data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );
  useEffect(() => {
    const selectedSubType = data?.result?.rows?.find(
      (item) => item.id === watchChangeTaskSubType
    );
    if (selectedSubType?.templateUrl) {
      register('amendmentSummary');
      handleTemplateUrl(selectedSubType.templateUrl);
    } else {
      unregister('amendmentSummary');
    }
  }, [data, handleTemplateUrl, register, unregister, watchChangeTaskSubType]);

  const listItems = useMemo(() => {
    const parseData =
      data?.result?.rows?.map((item) => ({
        key: item.id,
        value: item.name,
      })) ?? [];
    parseData?.unshift({ key: +PLACEHOLDER_OPTION, value: 'Select type' });
    return parseData;
  }, [data]);

  const showSubType = watchChangeTask !== ChangeTaskTypes.PAY_INSTRUCTION;
  if (!showSubType) {
    return null;
  }
  return (
    <S.FormLabel>
      {amendmentTaskTypeName ?? 'Sub '} type
      <TextDropdownMultiV2
        isDropdownMulti={false}
        items={listItems}
        itemsLoading={isLoading}
        defaultSelectedKeys={watchChangeTaskSubType && [watchChangeTaskSubType]}
        selectProps={{
          placeholder: 'Select type',
        }}
        displayProps={{
          isDisabled: isLoading || !data?.result?.rows?.length,
        }}
        name="changeTaskSubType"
        aria-label="Change task sub type field"
        testId="changeTaskSubType"
      />
    </S.FormLabel>
  );
};
