import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonType } from '@xemplo/button';
import { DawnTriangleAlert } from '@xemplo/icons';
import { ModalBodyOverflow, ModalSize, ModalTheme } from '@xemplo/modal';
import { useApproveEarningLines } from '@xemplo/payrun-query';
import { PromptProps, PromptType } from '@xemplo/prompts';
import { ToastType, useToast } from '@xemplo/toast';

import * as S from './payrun-approve-reject.styles';
export const useConfirmPrompt = () => {
  const [promptVisible, setPromptVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addToast } = useToast();
  const { id } = useParams();
  const { mutateAsync: approvePayrun } = useApproveEarningLines({
    payrunId: id ?? null,
    onSuccess: () => {
      addToast({
        text: 'Pay run approved',
        type: ToastType.Confirmation,
      });
      setPromptVisible(false);
    },
    onError: (error) => {
      const errorMessage = error.message ?? 'Something went wrong, please try again.';
      setErrorMessage(errorMessage);
    },
  });

  //config for prompt modal
  const initialPromptProps = useMemo<PromptProps>(() => {
    return {
      open: false,
      type: PromptType.General,
      theme: ModalTheme.Light,
      size: ModalSize.Small,
      header: {
        customHeader: (
          <S.ApprovePromptHeader>
            <DawnTriangleAlert />
            <div>Ready to approve pay run?</div>
          </S.ApprovePromptHeader>
        ),
      },
      body: {
        content: (
          <S.ApprovePromptBody>
            By approving this pay run, you are confirming that all details are correct.
          </S.ApprovePromptBody>
        ),
        overflow: ModalBodyOverflow.Extended,
      },
      footer: {
        btnPrimary: {
          label: 'Approve',
          ariaLabel: 'Approve button',
          onClickAsync: approvePayrun,
          type: ButtonType.Primary,
        },
        btnSecondary: {
          label: 'Cancel',
          ariaLabel: 'Cancel button',
          type: ButtonType.Secondary,
          onClick: () => setPromptVisible(false),
        },
      },
    };
  }, [approvePayrun]);

  const [promptProps, setPromptProps] = useState<PromptProps>(initialPromptProps);

  useEffect(() => {
    if (errorMessage) {
      setPromptProps({
        ...initialPromptProps,
        body: {
          content: (
            <>
              <S.PromptBody>
                By approving this pay run, you are confirming that all details are
                correct.
              </S.PromptBody>
              <S.ErrorMessage>
                <DawnTriangleAlert />
                {errorMessage}
              </S.ErrorMessage>
            </>
          ),
          overflow: ModalBodyOverflow.Extended,
        },
      });
    } else {
      setPromptProps(initialPromptProps);
    }
  }, [errorMessage, initialPromptProps]);

  useEffect(() => {
    if (initialPromptProps.open) {
      setPromptVisible(true);
    }
  }, [initialPromptProps.open]);

  return { promptProps, promptVisible, setPromptVisible };
};
