// @ts-nocheck
import React, { useEffect, useMemo } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Switch, Row, Col, Select } from "antd";
import _ from "lodash";
import {
  disableBrowserAutocomplete,
  getConditionalResponse,
  getTimeZoneFilterName,
  mustBeArray,
  reOrderTimeZoneList,
} from "../libs/utilities";
import LocationSearchInput from "../Common/LocationSearchInput";
import { AUSTRALIA_COUNTRY_ID } from "../constants/entities";
import { DEFAULT_GUTTER } from "../constants/ui";
import { isCountryAustralia } from "../libs/validations";
import { onPasteValidateNumber, abnValidator } from "../libs";
import UploadLogoAndProfileImage from "../Organization/components/common/UploadLogoAndProfileImage";
import RequiredSign from "./ui/RequiredSign";
import { If } from "../Common/ui";
import { listPayrollProviders } from "../Organization/actions/payrollprovider";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../hooks";

const FormItem = Form.Item;
const Option = Select.Option;

const CreateBasicDetailForm = (props: any) => {
  const {
    form,
    disableField,
    entity,
    populateStates,
    countries,
    timezone,
    onSameAddressChange,
    states,
    uploadLoading,
    handleDelete,
    onAddressSelect,
    isSameAddress,
    onChangeCountry,
    showUploadLogo,
    imageUrl,
    handleUpload,
    nameSameAsRegisteredNameChange,
    editMode,
    populateSameAddressField,
    mapLoaded,
    fieldRequired,
    handleRegisteredNameChange,
    onAddressChange,
    postalAddress1,
    registeredAddress1,
  } = props;
  const { getFieldDecorator } = form;
  const dispatchAction = useDispatch();
  const state = useAppSelector((state) => state);

  const mapOptions = (states: { id: string; value: string }[]) => {
    return mustBeArray(states).map((state: any, index: any) => (
      <Option key={index} value={state.id} name={state.value}>
        {state.value}
      </Option>
    ));
  };

  // On page load fetch payroll providers - stored in redux store
  useEffect(() => {
    dispatchAction(listPayrollProviders({ options: { per_page: 1000 } }));
  }, []);

  const options = useMemo(() => {
    return mapOptions(states);
  }, [states]);

  return (
    <div className="flex w-full">
      <If
        condition={showUploadLogo}
        then={
          <div className="flex-1-1-18">
            <UploadLogoAndProfileImage
              name={form?.getFieldValue("name")}
              profilePhoto={imageUrl}
              showUploadAndDelete
              uploadLoading={uploadLoading}
              handleUpload={handleUpload}
              handleDelete={handleDelete}
            />
          </div>
        }
      />
      <div className="flex-1-1-82">
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Country">
              {getFieldDecorator("country", {
                rules: [
                  {
                    required: fieldRequired,
                    message: "Please select your country.",
                  },
                ],
              })(
                <Select
                  placeholder="Please select a country"
                  showSearch
                  optionFilterProp="name"
                  onFocus={() => disableBrowserAutocomplete()}
                  onSelect={(val) => populateStates(val, false)}
                  disabled={disableField}
                  onChange={onChangeCountry}
                >
                  {mustBeArray(countries).map((country: any, key: any) => (
                    <Option key={key} value={country.id} name={country.value}>
                      {country.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={getConditionalResponse({
                condition: isCountryAustralia({
                  country: form.getFieldValue("country"),
                }),
                resp2: "Registration Number",
                resp1: "ABN",
              })}
            >
              {getFieldDecorator("abn", {
                rules: [
                  {
                    required: [AUSTRALIA_COUNTRY_ID].includes(
                      Number(form.getFieldValue("country"))
                    ),
                    message: "Please enter ABN.",
                  },
                ],
              })(
                <Input
                  onKeyDown={(e) => abnValidator(e)}
                  placeholder={`Please input ${getConditionalResponse({
                    condition: isCountryAustralia({
                      country: form.getFieldValue("country"),
                    }),
                    resp2: "Registration Number",
                    resp1: "ABN",
                  })}`}
                  disabled={disableField}
                  className="w-full  hideNumberPointers"
                  onPaste={(e) => onPasteValidateNumber(e)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Registered Business Name">
              {getFieldDecorator("registeredName", {
                rules: [
                  {
                    required: fieldRequired,
                    message: "Registered Business Name",
                  },
                ],
              })(
                <Input
                  placeholder="Please input registered business name"
                  maxLength={60}
                  disabled={disableField}
                  onChange={(e) =>
                    handleRegisteredNameChange
                      ? handleRegisteredNameChange(e?.currentTarget?.value)
                      : form.setFieldsValue({
                          registeredName: e?.currentTarget?.value,
                        })
                  }
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Business Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: fieldRequired,
                    message: "Please enter name.",
                  },
                ],
              })(
                <Input
                  placeholder={`Please enter ${entity} name`}
                  maxLength={40}
                  disabled={
                    form?.getFieldValue("isNameSameAsRegisteredBusinessName")
                      ? true
                      : false
                  }
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Operating Timezone">
              {getFieldDecorator("operatingTimezone", {
                rules: [
                  {
                    required: fieldRequired,
                    message: "Please select your timezone!",
                  },
                ],
              })(
                <Select
                  placeholder="Please select a timezone"
                  showSearch
                  defaultActiveFirstOption={true}
                  disabled={disableField}
                  optionFilterProp="name"
                >
                  {reOrderTimeZoneList({
                    timezone,
                    countries,
                    selectedCountry: form.getFieldValue("country"),
                  }).map((time: any, key: any) => {
                    return (
                      time.enabled && (
                        <Option
                          value={time.id}
                          name={getTimeZoneFilterName(time)}
                          key={key}
                        >
                          {time.value}
                        </Option>
                      )
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
          {entity === "company" && (
            <Col span={12}>
              <FormItem label="Payroll Service Provider">
                {getFieldDecorator("payrollServiceProvider", {
                  rules: [
                    {
                      required: fieldRequired,
                      message: "Please select your Payroll Service Provider!",
                    },
                  ],
                })(
                  //can be edited if no existing psp
                  <Select
                    placeholder="Please select a Payroll Service Provider"
                    showSearch
                    defaultActiveFirstOption={true}
                    disabled={!!editMode && props?.disablePSP}
                    optionFilterProp="name"
                  >
                    {state?.payrollProvider?.list?.map(
                      (provider: any, key: any) => {
                        return (
                          <Option
                            value={provider.id}
                            name={provider.name}
                            key={key}
                          >
                            {provider.name}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                )}
              </FormItem>
            </Col>
          )}
          {entity === "payroll provider" && (
            <Col span={12}>
              <FormItem
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                label="Same as Registered Business Name"
              >
                {getFieldDecorator("isNameSameAsRegisteredBusinessName", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                  valuePropName: "checked",
                })(
                  <Switch
                    onChange={(val) => nameSameAsRegisteredNameChange(val)}
                    disabled={disableField}
                  />
                )}
              </FormItem>
            </Col>
          )}
        </Row>

        <Row
          justify="end"
          className="items-start mt-2 -mb-6"
          gutter={DEFAULT_GUTTER}
        >
          <Col span={12} className="pt-2">
            <strong>Physical Address</strong>
          </Col>
          <Col span={6} className="pt-2">
            <strong>Postal Address</strong>
          </Col>
          <Col span={6}>
            <FormItem
              labelCol={{ span: 19 }}
              wrapperCol={{ span: 5 }}
              label="Same as Physical Address"
              className="pt-1"
            >
              {getFieldDecorator("isSameAddress", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Switch
                  onChange={(val) => onSameAddressChange(val)}
                  checked={isSameAddress}
                  disabled={disableField}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem
              label={
                <span>
                  <RequiredSign />
                  &nbsp;Address 1
                </span>
              }
            >
              {getFieldDecorator("registeredAddress1", {
                initialValue: {
                  placeholder: "Address 1",
                  className: "registered-address-input",
                },
              })(
                <LocationSearchInput
                  disabled={editMode ? false : disableField}
                  onChange={(res: any) => {
                    return onAddressSelect(res, "registered");
                  }}
                  mapLoaded={mapLoaded}
                  onAddressChange={(value: any) =>
                    onAddressChange(value, "registered")
                  }
                  address={registeredAddress1}
                  editMode={editMode}
                  type="registered"
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label={
                <span>
                  <RequiredSign />
                  &nbsp;Address 1
                </span>
              }
            >
              {getFieldDecorator("postalAddress", {
                initialValue: {
                  placeholder: "Address 1",
                  className: "postal-address-input",
                },
              })(
                <LocationSearchInput
                  disabled={isSameAddress || disableField}
                  onChange={(res: any) => onAddressSelect(res, "postal")}
                  mapLoaded={mapLoaded}
                  onAddressChange={(value: any) =>
                    onAddressChange(value, "postal")
                  }
                  address={postalAddress1}
                  editMode={editMode}
                  type="postal"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Address Line 2">
              {getFieldDecorator("registeredAddress2", {
                rules: [
                  {
                    required: false,
                    message: "Address line 2",
                  },
                ],
                defaultValue: " ",
              })(
                <Input
                  placeholder="Address line 2"
                  maxLength={40}
                  disabled={disableField}
                  onChange={(e) =>
                    populateSameAddressField(
                      e.currentTarget.value,
                      "postalAddress2"
                    )
                  }
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Address Line 2">
              {getFieldDecorator("postalAddress2", {
                rules: [
                  {
                    required: false,
                    message: "Address line 2",
                  },
                ],
                defaultValue: " ",
              })(
                <Input
                  disabled={isSameAddress || disableField}
                  placeholder="Postal Address line 2"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <Row gutter={DEFAULT_GUTTER}>
              <Col span={14}>
                <FormItem label="City / Town">
                  {getFieldDecorator("registeredSuburb", {
                    rules: [
                      {
                        required: fieldRequired,
                        message: "Please input city / town",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Please input city / town "
                      maxLength={30}
                      disabled={disableField}
                      onChange={(e) =>
                        populateSameAddressField(
                          e.currentTarget.value,
                          "postalSuburb"
                        )
                      }
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="Postcode">
                  {getFieldDecorator("registeredPostCode", {
                    rules: [
                      {
                        required: fieldRequired,
                        message: "Please input postcode",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Please input postcode"
                      className="w-full "
                      disabled={disableField}
                      onChange={(e) =>
                        populateSameAddressField(
                          e.currentTarget.value,
                          "postalPostCode"
                        )
                      }
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={DEFAULT_GUTTER}>
              <Col span={14}>
                <FormItem label="City / Town">
                  {getFieldDecorator("postalSuburb", {
                    rules: [
                      {
                        required: fieldRequired,
                        message: "Please input city / town",
                      },
                    ],
                  })(
                    <Input
                      disabled={isSameAddress || disableField}
                      placeholder="Please input city / town"
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="Postcode">
                  {getFieldDecorator("postalPostCode", {
                    rules: [
                      {
                        required: fieldRequired,
                        message: "Please input postcode",
                      },
                    ],
                  })(
                    <Input
                      disabled={isSameAddress || disableField}
                      placeholder="Please input postcode"
                      className="w-full "
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Province / State">
              {getFieldDecorator("registeredState", {
                rules: [
                  {
                    required: !_.isEmpty(mustBeArray(states)),
                    message: "Please select Province / State!",
                  },
                ],
              })(
                <Select
                  showSearch={true}
                  optionFilterProp="name"
                  placeholder="Please select Province / State"
                  disabled={disableField}
                  onChange={(e) => populateSameAddressField(e, "postalState")}
                >
                  {options}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Province / State">
              {getFieldDecorator("postalState", {
                rules: [
                  {
                    required: !_.isEmpty(mustBeArray(states)),
                    message: "Please select your Province / State!",
                  },
                ],
              })(
                <Select
                  showSearch={true}
                  optionFilterProp="name"
                  disabled={isSameAddress || disableField}
                  placeholder="Please select a Province / State"
                >
                  {options}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateBasicDetailForm;
