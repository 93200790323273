import React from "react";
import { getClaimAndStatus, isComponentVisible } from "../../../libs/utilities";

type GeneralGridProps = {
  claims: object;
  name: string;
  dashboardView?: boolean;
  children: React.ReactNode;
  className?: string;
};

const classNameDefault = "";

const GeneralGrid = ({
  claims,
  name,
  dashboardView,
  children,
  className = classNameDefault,
}: GeneralGridProps) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  const claim = claimAndStatus.claim;
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { claims: claim })
  );

  return (
    <span
      className={`${className} ${dashboardView ? "" : claimAndStatus.status}`}
    >
      {isComponentVisible({ claim }) && childrenWithProps}
    </span>
  );
};

export default GeneralGrid;
