//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import * as member from "../actions/member";

class Member extends SwiftContainer {
  render() {
    const { Layout } = this.props;
    return <Layout loading={this.state.loading} {...this.props} />;
  }
}

const mapStateToProps = (state: any) => ({
  member: state.member || {},
});

export default connect(mapStateToProps, { ...member })(Member);
