//@ts-nocheck
import React from "react";
import { Row, Col, Card } from "antd";
import _ from "lodash";
import EditLinkButton from "../../../Common/ClaimComponents/Button/EditLinkButton";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { currencyFormaterByCode } from "../../../libs/utilities";

const PayScheduleCurrencyFees = (props: any) => {
  const {
    currencyFees,
    viewEditCurrencyFees,
    claims,
    claimComponent,
    hasNoComponents,
  } = props;
  return (
    currencyFees && (
      <Card
        title={`Currency Conversion Fees`}
        bordered={false}
        extra={
          claimComponent ? (
            <EditLinkButton
              onClick={viewEditCurrencyFees}
              name={claimComponent}
              claims={claims}
              hasNoComponents={
                _.isUndefined(hasNoComponents) ? true : hasNoComponents
              }
            />
          ) : (
            <React.Fragment />
          )
        }
      >
        <div className="p-10">
          <h3>
            {`From ${currencyFees && currencyFees.convertFromCurrency} to ${
              currencyFees && currencyFees.convertToCurrency
            }`}
          </h3>
          <Row gutter={DEFAULT_GUTTER} className="mb-4">
            <Col sm={24} md={12}>
              <b>Fixed Conversion Rate</b>
              <div>
                {currencyFormaterByCode(
                  currencyFees && currencyFees.fixedConversionRate,
                  currencyFees && currencyFees.convertFromCurrency
                )}
              </div>
            </Col>
            <Col sm={24} md={12}>
              <b>Percent Conversion Rate</b>
              <div>{`${
                currencyFees && currencyFees.percentConversionRate
              }%`}</div>
            </Col>
          </Row>
        </div>
      </Card>
    )
  );
};

export default PayScheduleCurrencyFees;
