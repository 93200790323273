import { SyntheticEvent, useCallback, useRef, useState } from 'react';

import { SidebarItem } from '../sidebar.types';

/**
 * This hook controls the visibility of the custom tooltip
 * that appears when the sidebar is collapsed. Due to overflow
 * issues, this element needs to be defined at the same level as the
 * items to acquire the correct positioning on hover.
 * Moving it to the parent makes the (already complex) calculations
 * even more complex. So decided to keep it here.
 */
export function useCustomTooltip(isExpanded: boolean) {
  const [tooltip, setTooltip] = useState('');
  const tooltipElement = useRef<HTMLDivElement>(null);

  const handleTooltipVisible = useCallback(
    (event: SyntheticEvent, item: SidebarItem) => {
      if (isExpanded || item.isHeader || !tooltipElement.current) return;

      const { current } = tooltipElement;

      // Find the top offset of the item
      const { top } = (event.target as HTMLElement).getBoundingClientRect();

      const sidebarContainer = document.getElementById('sidebar-nav');
      if (!sidebarContainer) return;

      // The offset will be different depending on the layout the sidebar
      // has been placed in. This is used to position the tooltip correctly.
      const sidebarOffset = sidebarContainer.offsetTop || 0;

      // Account for the sidebar offset to position the tooltip correctly
      // +3 is manually adjust alignment centered to the icon
      const offset = top - sidebarOffset + 3;

      current.setAttribute('aria-hidden', 'false');
      current.style['top'] = `${offset}px`;
      setTooltip(item.label);
    },
    [isExpanded]
  );

  const handleTooltipHidden = useCallback(() => {
    if (isExpanded) return;
    if (tooltipElement.current) {
      tooltipElement.current.setAttribute('aria-hidden', 'true');
    }
  }, [isExpanded]);

  return {
    tooltip,
    tooltipElement,
    handleTooltipVisible,
    handleTooltipHidden,
  };
}
