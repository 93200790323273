import { RefObject, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useResizeObserver } from '@react-aria/utils';

import { useDebounceCallback } from '@xemplo/hooks';

import { TabMenuSize } from '../tab-menu.types';
import { useTabMenu } from '../use-tab-menu';

type UseActiveIndicatorProps = {
  parentRef: RefObject<HTMLElement>;
  indicatorRef: RefObject<HTMLDivElement>;
};

export const useActiveIndicator = ({
  parentRef,
  indicatorRef,
}: UseActiveIndicatorProps) => {
  const {
    state: { size },
  } = useTabMenu();
  const { pathname, search } = useLocation();

  const getSearchParams = useCallback((href: string) => {
    return href.split('?').pop();
  }, []);

  const matchHrefWithLocation = useCallback(
    (href: string, hrefSearchParams?: string) => {
      if (hrefSearchParams && search) {
        return hrefSearchParams === search.split('?').pop();
      }

      // FQDN link
      if (href.startsWith('http')) {
        return new URL(href).pathname === pathname;
      }

      // relative link
      return href === pathname || href.startsWith(pathname);
    },
    [pathname, search]
  );

  const findActiveItem = useCallback(() => {
    const parent = parentRef.current;
    if (!parent) return null;

    const allLinks = parent.getElementsByClassName('tab-menu-item-link');
    const active = Array.from(allLinks).find((link) => {
      link.classList.remove('active');
      const href = link.getAttribute('href');
      if (!href) return null;

      const hrefSearchParams = getSearchParams(href);
      const isActive = matchHrefWithLocation(href, hrefSearchParams);

      link.classList.toggle('active', isActive);
      return isActive ? link : null;
    });
    return (active as HTMLAnchorElement) ?? null;
  }, [getSearchParams, matchHrefWithLocation, parentRef]);

  const calculateIndicator = useCallback(() => {
    // Recalculate the active indicator based on the new pathname
    const parent = parentRef.current;
    const indicator = indicatorRef.current;
    if (!parent || !indicator) return;

    // Find the active menu item
    const activeItem = findActiveItem();
    if (!activeItem) return;

    // Find the ellipsis menu item
    const ellipsis = parent.querySelector<HTMLSpanElement>('#ellipsis');

    const isSubMenuItem = activeItem.getAttribute('data-sub-menu');
    if (isSubMenuItem === 'true' && ellipsis) {
      indicator.style.transform = `translateX(${ellipsis.offsetLeft}px)`;
      indicator.style.width = `${ellipsis.clientWidth}px`;
    } else {
      indicator.style.transform = `translateX(${activeItem.offsetLeft}px)`;
      indicator.style.width = `${activeItem.clientWidth}px`;
    }

    indicator.style.height = `${size === TabMenuSize.Large ? 3 : 2}px`;
  }, [findActiveItem, indicatorRef, parentRef, size]);

  // Recalculate the active indicator when the pathname changes
  useEffect(() => calculateIndicator(), [calculateIndicator, pathname]);

  // Recalculate the active indicator when the window is resized
  const debouncedCalculation = useDebounceCallback(calculateIndicator, 150);
  useResizeObserver({ ref: parentRef, onResize: debouncedCalculation });
};
