//@ts-nocheck
import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Row, Col, Radio } from "antd";
import _ from "lodash";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import {
  getAssignedCompaniesAndBusinessUnits,
  getFullName,
  isUserSystemAdmin,
  trimWhiteSpaces,
} from "../../../libs/utilities";
import { MANDATORY_DEVICE_ENROLMENT_ID } from "../../../constants/user";
import { DEVICE_ENROLMENT_OPTIONS } from "../../../constants/options";
import {
  onPasteValidateNumber,
  mobileNumberValidator,
  displayDateAndTime,
} from "../../../libs";
import { Avatar, AvatarSize } from "@xemplo/avatar";

const FormItem = Form.Item;

interface UserBasicDetailsProps {
  form: any;
  fieldRequired: boolean;
  profilePhoto?: string | null;
  member: any;
  editMode?: boolean;
  type?: string;
  userDetail?: any;
}

const UserBasicDetailsForm: React.FC<UserBasicDetailsProps> = ({
  form,
  fieldRequired,
  profilePhoto,
  member,
  editMode,
  type,
  userDetail,
}) => {
  const { getFieldDecorator } = form;
  const firstName = form?.getFieldValue("firstName");
  const lastName = form?.getFieldValue("lastName");
  const avatarData = { firstName, lastName, profilePhoto };

  return (
    <div className="flex w-full">
      <div className="flex-1-1-18">
        <Avatar user={avatarData} size={AvatarSize.xxxxl} />
      </div>
      <div className="flex-1-1-82">
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="First Name:">
              {getFieldDecorator("firstName", {
                rules: [
                  {
                    required: fieldRequired,
                    message: "Please enter first name",
                  },
                  {
                    whitespace: true,
                  },
                ],
              })(<Input placeholder="First name" />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Last Name:">
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: fieldRequired,
                    message: "Please enter last name",
                  },
                  {
                    whitespace: true,
                  },
                ],
              })(<Input placeholder="Last name" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={DEFAULT_GUTTER}>
          <Col span={24}>
            <FormItem label="Email Address:">
              {getFieldDecorator("emailAddress", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: fieldRequired,
                    message: "Please enter email address",
                  },
                  {
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  type="email"
                  placeholder="Email address"
                  disabled={editMode}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Mobile Phone:">
              {getFieldDecorator("mobilePhone", {
                rules: [
                  { transform: (value) => trimWhiteSpaces(value) },
                  {
                    required: fieldRequired,
                    message: "Please enter a valid mobile number",
                  },
                  {
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  placeholder="Mobile number"
                  className="w-full "
                  onKeyDown={(e) => mobileNumberValidator(e)}
                  onPaste={(e) => onPasteValidateNumber(e)}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Phone:">
              {getFieldDecorator("phone", {
                rules: [
                  { transform: (value) => trimWhiteSpaces(value) },
                  {
                    required: false,
                  },
                  {
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  placeholder="Phone number"
                  className="w-full "
                  onKeyDown={(e) => mobileNumberValidator(e)}
                  onPaste={(e) => onPasteValidateNumber(e)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Receive Notifications:">
              {getFieldDecorator("getNotifications", {
                rules: [
                  {
                    required: fieldRequired,
                    message:
                      "Please select if you want to receive notifications",
                  },
                ],
                initialValue: 1,
              })(
                <Radio.Group
                  name="notifyUser"
                  className={
                    Number(form.getFieldValue("getNotifications")) === 1
                      ? "customRadioButtonYesSelected"
                      : "customRadioButtonNoSelected"
                  }
                >
                  <Radio.Button className="yesOption" value={1}>
                    Yes
                  </Radio.Button>
                  <Radio.Button className="noOption" value={2}>
                    No
                  </Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            {isUserSystemAdmin({ member }) ? (
              <FormItem label="Enable Multi-Factor Authentication:">
                {getFieldDecorator("mfaEnable", {
                  rules: [
                    {
                      required: fieldRequired,
                    },
                  ],
                  initialValue: 1,
                })(
                  <Radio.Group
                    name="notifyUser"
                    className={
                      Number(form.getFieldValue("mfaEnable")) === 1
                        ? "customRadioButtonYesSelected"
                        : "customRadioButtonNoSelected"
                    }
                  >
                    <Radio.Button value={1} className="yesOption">
                      Yes
                    </Radio.Button>
                    <Radio.Button value={2} className="noOption">
                      No
                    </Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            ) : (
              editMode &&
              type == "psp" && (
                <div>
                  <div className="ml-1 ant-legacy-form-item-label">
                    Last Logout Status:
                  </div>
                  <div className="mt-3 ml-1 bold">
                    {displayDateAndTime({ value: userDetail?.lastLogout }) ||
                      "N/A"}
                  </div>
                </div>
              )
            )}
          </Col>
        </Row>
        {isUserSystemAdmin({ member }) && (
          <Row gutter={DEFAULT_GUTTER}>
            <Col span={12}>
              <FormItem label="Device Enrolment:">
                {getFieldDecorator("deviceEnrolmentEnable", {
                  rules: [
                    {
                      required: fieldRequired,
                      message:
                        "Please select if you want to enable multi-factor authentication",
                    },
                  ],
                  initialValue: 1,
                })(
                  <Radio.Group name="enrolDevice">
                    {(form.getFieldValue("mfaEnable") === 1
                      ? _.filter(
                          DEVICE_ENROLMENT_OPTIONS,
                          (o) => o.value !== MANDATORY_DEVICE_ENROLMENT_ID
                        )
                      : DEVICE_ENROLMENT_OPTIONS
                    ).map((option, i) => {
                      return (
                        <Radio value={option.value} key={i}>
                          {option.name}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
            {editMode && type == "psp" && (
              <Col span={12}>
                <div className="ml-1 ant-legacy-form-item-label">
                  Last Logout Status:
                </div>
                <div className="mt-3 ml-1 bold">
                  {displayDateAndTime({ value: userDetail?.lastLogout }) ||
                    "N/A"}
                </div>
              </Col>
            )}
          </Row>
        )}
        {editMode && type === "psp" && (
          <React.Fragment>
            <Row gutter={DEFAULT_GUTTER} className="mb-8 pl-1">
              <Col md={12}>
                <div>Companies/Business Units Assigned To:</div>
                <div className="mt-3 bold">{`${getAssignedCompaniesAndBusinessUnits(
                  { data: userDetail }
                )}`}</div>
              </Col>
              <Col md={12}>
                <div>Added By:</div>
                <div className="mt-3 bold">
                  {getFullName({ name: userDetail?.addedByUser })}
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default UserBasicDetailsForm;
