//@ts-nocheck
import React, { Component } from "react";
import {
  CheckOutlined,
  StopOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Card, List, message, Modal, Tooltip } from "antd";
import { mustBeArray, parseItems } from "../../../../libs/utilities";
import SwiftMessage from "../../../../constants/message";
import _ from "lodash";
import RejectAmendments from "../rejectAmendments";
import AmendmentDeclineReason from "../amendmentDeclineReason";
import ReSubmitDetails from "../ReSubmitDetails";
import ClaimWrapper from "../../../../Common/claimWrapper";
import {
  AMENDMENT_APPROVED_ONLY_STATUS,
  AMENDMENT_APPROVED_STATUS,
  AMENDMENT_DECLINED_STATUS,
  AMENDMENT_PREPARED_STATUS,
  AMENDMENT_RESUBMITTED_STATUS,
} from "../../../../constants/payrun";
import AmendmentKeypayStatus from "./AmendmentKeypayStatus";
import {
  getAssignedToUsersText,
  isAmendmentAppliedToPayroll,
  isAmendmentAutomated,
  isCompanyUser,
} from "../../../../libs";
import { KEYPAY_AMENDMENT_CREATED } from "../../../../constants";
import classNames from "classnames";
import {
  AmendmentDetailsModal,
  ApprovedRejectedStatusBadge,
} from "../../../../Common/amendments";

type State = any;

class AmendmentSummary extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      visible: false,
      amendment: {},
      submitRejectionLoading: false,
    };
  }

  viewAmendment = (amendment) => {
    this.setState({ amendment }, () => {
      this.setState({ visible: true });
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      amendment: {},
      viewRejectModal: false,
      amendmentHistoryLoading: true,
    });
  };
  approveAmendment = (amendment) => {
    this.setState({ confirmLoading: true });

    this.props.approveAmendment({
      id: amendment.amendmentId,
      options: { payrunID: amendment.payrunId },
    });

    this.setState({ confirmLoading: false });
    message.success(SwiftMessage["approve.success"]);
  };

  handleDelete = (record, description) => {
    this.setState({ declineLoading: true, submitRejectionLoading: true });

    this.props.rejectPayrunAmendment({
      id: record.amendmentId,
      options: { payrunID: record.payrunId },
      data: { description, rejectionReasonID: 0 },
    });
    message.success(SwiftMessage["decline.success"]);

    this.setState({ submitRejectionLoading: false, viewRejectModal: false });
  };

  handleDecline = (amendment) => {
    this.setState({ amendment }, () => {
      this.setState({ viewRejectModal: true });
    });
  };

  showDeclineReason = (amendment) => {
    Modal.warning({
      title: "Amendment has been rejected",
      content: (
        <AmendmentDeclineReason description={amendment.rejectDescription} />
      ),
      width: 700,
    });
  };

  showReSubmittedReason = (amendment) => {
    Modal.warning({
      title: "Amendment has been resubmitted",
      content: (
        <ReSubmitDetails description={amendment.resubmissionDescription} />
      ),
      width: 600,
    });
  };

  renderCommonAmendmentFields = ({ data }: any) => {
    return (
      <React.Fragment>
        {data?.amendmentSubTypeName && (
          <div className="text-[13px]">
            Sub Type: {data.amendmentSubTypeName}
          </div>
        )}
        <div className="text-[13px]">
          Applies to: {getAssignedToUsersText({ content: data })}
        </div>
        <div className="text-xs">
          Requested by: {`${parseItems(data.requestedBy)?.UserName}`}
        </div>
        {data?.amendmentKeypayStatusId !== KEYPAY_AMENDMENT_CREATED && (
          <div className="text-xs">
            Applied to Payroll: <AmendmentKeypayStatus amendmentData={data} />
          </div>
        )}
      </React.Fragment>
    );
  };

  renderAmendmentStatusField = ({ data }) => {
    switch (data?.amendmentStatusID) {
      case AMENDMENT_APPROVED_ONLY_STATUS:
      case AMENDMENT_PREPARED_STATUS:
        return <span className="color-success text-sm italic"> Approved</span>;
      case AMENDMENT_DECLINED_STATUS:
        return (
          <span
            className="color-danger text-sm italic hand"
            onClick={() => this.showDeclineReason(data)}
          >
            {" "}
            Rejected
          </span>
        );
      case AMENDMENT_RESUBMITTED_STATUS:
        return (
          <span
            className="color-warning text-sm italic hand"
            onClick={() => this.showReSubmittedReason(data)}
          >
            {" "}
            Resubmitted
          </span>
        );
      default:
        return <React.Fragment />;
    }
  };

  renderAmendmentActionComponent = ({ data }) => {
    if (
      data?.amendmentStatusID &&
      _.isEmpty(this.props.externalEarninglines) &&
      (!isAmendmentAutomated({ amendment: data }) ||
        !isAmendmentAppliedToPayroll({ amendment: data }))
    ) {
      return (
        <React.Fragment>
          <ClaimWrapper
            className={classNames({
              "pointer-events-none": this.props.keypayIntergrationLoading,
            })}
            name={this.props.approveComponent}
            claims={this.props.claims}
          >
            <Tooltip title="Approve" placement="bottom">
              <CheckOutlined
                onClick={() => this.approveAmendment(data)}
                className={`${classNames({
                  "Visible-Disabled": AMENDMENT_APPROVED_STATUS.includes(
                    data.amendmentStatusID
                  ),
                })} color-success text-xl hand`}
              />{" "}
              &nbsp;
            </Tooltip>
          </ClaimWrapper>
          <ClaimWrapper
            className={classNames({
              "pointer-events-none": this.props.keypayIntergrationLoading,
            })}
            name={this.props.rejectComponent}
            claims={this.props.claims}
          >
            <Tooltip title="Reject" placement="bottom">
              <StopOutlined
                rotate={90}
                onClick={() => this.handleDecline(data)}
                className={`${classNames({
                  "Visible-Disabled":
                    data.amendmentStatusID === AMENDMENT_DECLINED_STATUS,
                })} text-xl hand color-danger`}
              />
            </Tooltip>
          </ClaimWrapper>
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  };

  renderAmendmentChangeTypeName = ({ data }) => {
    return (
      <span
        onClick={() => this.viewAmendment(data)}
        className="color-primary hand"
      >
        {data.amendmentTaskTypeName}
      </span>
    );
  };

  displayAmendmentActions = () => {
    return (
      !isCompanyUser() &&
      [4, 5, 6, 7].includes(this.props?.payrun?.payrunStatusID)
    );
  };

  render() {
    const {
      title,
      loading,
      amendmentSummary,
      bordered,
      payrun,
      handleAddAmendment,
      payrunSelected,
    } = this.props;
    return (
      <div className="amendment-summary">
        <Card
          className="card card-space-0 mb-[15px] rounded-[12px]"
          title={
            <div className="pl-3 font-bold text-sm">
              {<span>{title}</span>}
              {!payrunSelected &&
                [1, 2, 3, 4, 5, 6, 7].includes(payrun.payrunStatusID) &&
                !this.props.keypayIntergrationLoading && (
                  <div
                    className="mr-4 bold font-normal text-sm hand float-right flex items-center"
                    onClick={handleAddAmendment}
                  >
                    <PlusCircleOutlined className="color-primary mr-1" /> New
                  </div>
                )}
              <div className="clearfix" />
            </div>
          }
        >
          <List
            itemLayout="horizontal"
            loading={loading}
            dataSource={mustBeArray(amendmentSummary)}
            bordered={bordered || false}
            className={`portlet ${this.props.className} defaultListHeader maximumHeight list-meta-padding `}
            renderItem={(data) => (
              <List.Item key={data?.amendmentId} className="padding-1 px-4">
                <List.Item.Meta
                  title={
                    <React.Fragment>
                      <span className="text-[17px]">
                        {this.renderAmendmentChangeTypeName({ data })}
                        {this.displayAmendmentActions() &&
                          this.renderAmendmentStatusField({ data })}
                      </span>
                      {this.renderCommonAmendmentFields({ data })}
                    </React.Fragment>
                  }
                />
                <span className="pt-1">
                  {this.displayAmendmentActions() ? (
                    this.renderAmendmentActionComponent({ data })
                  ) : (
                    <ApprovedRejectedStatusBadge
                      status={data.amendmentStatusID}
                    />
                  )}
                </span>
              </List.Item>
            )}
          />
          <AmendmentDetailsModal
            amendmentId={this.state.amendment?.amendmentId}
            handleClose={this.onClose}
            visible={this.state.visible}
          />
          <RejectAmendments
            visible={this.state.viewRejectModal}
            cancelRejection={() => this.onClose()}
            submitRejection={this.handleDelete}
            amendment={this.state.amendment}
            submitRejectionLoading={this.state.submitRejectionLoading}
            maxCharacters={2048}
          />
        </Card>
      </div>
    );
  }
}

export default AmendmentSummary;
