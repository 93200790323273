import classNames from 'classnames';
import dayjs from 'dayjs';

import { STEPPER_DATE_FORMAT } from '@xemplo/date-time';

import * as S from './payrun-stepper.styles';
import { SingleStepProps, StepIconProps } from './payrun-stepper.types';
import { PayrunStepperTestId } from './test';

export const SingleStep = (props: SingleStepProps) => {
  const { step, date, ...rest } = props;

  return (
    <S.StepContainer data-testid={PayrunStepperTestId.SingleStep(step)}>
      <StepIcon {...rest} data-testid={PayrunStepperTestId.StepIcon(step)} />
      <div>
        <S.Step>{step}</S.Step>
        <S.StepDate>{dayjs(date).format(STEPPER_DATE_FORMAT)}</S.StepDate>
      </div>
    </S.StepContainer>
  );
};

const StepIcon = (props: StepIconProps) => {
  const { active, completed, index } = props;

  if (completed) return <S.CompletedStepBadge />;

  const position = (index + 1).toString();

  //TODO: Update with accessibility label https://expedo.atlassian.net/browse/CL-204
  return <S.Badge className={classNames({ active })}>{position}</S.Badge>;
};
