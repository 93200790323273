//@ts-nocheck
import React from "react";
import { Table } from "antd";
import { tableColumnGenerator } from "../../../libs/table";
import { mustBeArray } from "../../../libs/utilities";
import _ from "lodash";
import TableActionComponent from "../common/tableActions";
import { GOOGLE_ANALYTICS_PAGE_TITLE } from "../../../constants";
import { withRouter } from "../../../hooks";

class EmailTemplatesList extends React.Component {
  handleEdit = (record: any) => {
    this.props.updateHeader({
      header: {
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.EmailTemplateEdit,
      },
    });
    this.props.router.navigate(`/emailtemplate/${record.id}`);
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = {
      page: pagination.current,
      order: sorter.order
        ? sorter.order === "ascend"
          ? "asc"
          : "desc"
        : sorter.order,
      field: sorter.field === "templateName" ? "name" : sorter.field,
    };
    this.props.handleTableChange({
      options,
    });
  };
  handleAction = ({ record, action }: any) => {
    switch (action) {
      case "edit":
        return this.handleEdit(record);
      case "delete":
        break;
      default:
        break;
    }
  };

  render() {
    const {
      per_page,
      current,
      total,
      sortInfo,
      data,
      loading,
      editComponent,
      displayActionIcons,
    } = this.props;
    const size = this.props.size ? this.props.size : "default";
    const pagination = {
      total: total,
      current: current,
      pageSize: per_page ? per_page : 20,
      hideOnSinglePage: true,
    };
    const colTemplate = [
      {
        title: "Name",
        dataIndex: "templateName",
        filterType: 0,
        sorter: true,
        sortOrder:
          sortInfo && sortInfo.field === "name" && sortInfo.order
            ? sortInfo.order === "asc"
              ? "ascend"
              : "descend"
            : false,
      },
      {
        title: "Description",
        dataIndex: "description",
        filterType: 0,
        sorter: true,
        sortOrder:
          sortInfo && sortInfo.field === "description" && sortInfo.order
            ? sortInfo.order === "asc"
              ? "ascend"
              : "descend"
            : false,
      },
    ];
    const actions = [
      {
        title: "Actions",
        key: "action",
        width: "10%",

        render: (record: any) => (
          <TableActionComponent
            record={record}
            handleAction={this.handleAction}
            editComponentName={editComponent}
            claims={this.props.claims}
            displayActionIcons={displayActionIcons}
          />
        ),
      },
    ];
    const column = _.concat(tableColumnGenerator(colTemplate), actions);
    return (
      <Table
        columns={column}
        size={size}
        rowKey={(record) => record.id}
        dataSource={mustBeArray(data)}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default withRouter(EmailTemplatesList);
