import { Dispatch, forwardRef, SetStateAction } from 'react';
import { ListState } from '@react-stately/list';
import { OverlayTriggerState } from '@react-stately/overlays';
import classNames from 'classnames';

import { ListItem } from '@xemplo/listbox';

import * as S from './single-value.styles';
export interface SingleValueProps<T> {
  state: ListState<T>;
  hasLeadingIcon?: boolean;
  hasSiblings?: boolean; // eg a Label
  testId?: string;
  popoverState?: OverlayTriggerState;
  items: T[];
  searchValue?: string;
  setSearchValue?: Dispatch<SetStateAction<string>>;
}

//TODO: This needs to refactored to support searching the list of items The searchProps perform the filtering fine, its just rendering the correct value (search value vs selected value)
//Tech-debt ticket for this task: https://expedo.atlassian.net/browse/FD-1303
export function SingleValueInternal<T extends ListItem>(
  props: Readonly<SingleValueProps<T>>,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    state,
    hasLeadingIcon,
    hasSiblings,
    testId,
    items,
    popoverState,
    // searchValue,
    // setSearchValue,
  } = props;

  const { selectionManager } = state;
  const { selectedKeys } = selectionManager;
  if (selectedKeys.size === 0) return null;

  const selectedKey = Array.from(selectedKeys.keys())[0];
  const value = items.find(
    (item) => item.key.toString() === selectedKey?.toString()
  )?.value;

  //   useEffect(() => {
  //     setSearchValue?.(value ?? '');
  //   }, [value]);

  return (
    <S.ValueWrapper
      ref={ref}
      onClick={() => {
        popoverState?.open();
      }}
      data-testid={testId}
      className={classNames({
        'has-leading-icon': !!hasLeadingIcon,
        'has-siblings': hasSiblings,
      })}
    >
      {value}
    </S.ValueWrapper>
    // This feels really janky... needs a different approach before we can support filtering
    // <S.ValueWrapper
    //   ref={ref}
    //   type="search"
    //   value={searchValue ?? value}
    //   onChange={(e) => {
    //     setSearchValue?.(e.target.value);
    //   }}
    //   onClick={() => {
    //     popoverState?.open();
    //   }}
    //   data-testid={testId}
    //   className={classNames({
    //     'has-leading-icon': !!hasLeadingIcon,
    //     'has-siblings': hasSiblings,
    //     'is-hidden': false,
    //   })}
    // />
  );
}

export const SingleValue = forwardRef(SingleValueInternal) as <T extends ListItem>(
  props: SingleValueProps<T> & { ref?: React.Ref<HTMLDivElement> }
) => React.ReactElement;
