import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PayrollRoutes } from '@xemplo/gp-routes';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';
import {
  ToastHorizontalPosition,
  ToastType,
  ToastVerticalPosition,
  useToast,
} from '@xemplo/toast';

export function useValidPayrun() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { isLoading, isError } = useGetPayrunByIdQuery({ id: id ?? null });

  useEffect(() => {
    if (!isError) return;

    addToast({
      position: {
        vertical: ToastVerticalPosition.Top,
        horizontal: ToastHorizontalPosition.Right,
      },
      duration: 5000,
      text: 'The requested payrun does not exist or you do not have access to it',
      type: ToastType.Warning,
    });
    navigate(`/${PayrollRoutes.Payrun}`);
  }, [addToast, navigate, isError]);

  return { showLoader: isLoading || isError };
}
