import styled from 'styled-components';

import { Button } from '@xemplo/button';
import {
  BodyStandardMedium,
  BodyStandardRegular,
  BodyXSmallRegular,
  Colour,
  HeadingStandardMedium,
} from '@xemplo/style-constants';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const FixedButton = styled(Button)`
  width: 128px;
`;

/** Reject Payrun Prompt */

export const PromptHeader = styled.div`
  ${HeadingStandardMedium};
  display: flex;
  margin: 44px 40px 32px 44px;
  align-items: center;
  gap: 24px;
  & > svg {
    height: 40px;
    width: 40px;
    color: ${Colour.Red[500]};
  }
`;

export const PromptBody = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FormLabel = styled.div`
  ${BodyStandardMedium};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${Colour.Red[300]};
  background: ${Colour.Red[50]};
  color: ${Colour.Gray[600]};
  justify-content: flex-start;
  ${BodyXSmallRegular};
  & > svg {
    color: ${Colour.Red[500]};
    height: 24px;
    width: 24px;
  }
`;

/** Approve Prompt */

export const ApprovePromptBody = styled.div`
  text-align: center;
  margin-inline: auto;
  text-align: center;
  color: ${Colour.Gray[600]};
  ${BodyStandardRegular};
`;

export const ApprovePromptHeader = styled.div`
  display: flex;
  ${HeadingStandardMedium};
  margin: 40px 40px 0 40px;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  & > svg {
    height: 40px;
    width: 40px;
    color: ${Colour.Blue[400]};
  }
`;
