import styled from 'styled-components';

import { AmendmentChangeLog } from '@xemplo/gp-types';
import {
  BodyLargeSemiBold,
  BodySmallRegular,
  BodyXSmallMedium,
  Colour,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';

export const StyledTable = styled(XemploTable<AmendmentChangeLog>)`
  border-radius: 8px;
  border: 1px solid ${Colour.Gray[200]};
  ${BodySmallRegular};
  color: ${Colour.Gray[600]};
  .action-bar-container {
    display: none;
  }
  table {
    thead {
      border: none;
      color: ${Colour.Gray[700]};
      ${BodyXSmallMedium};
    }
    tbody {
      tr {
        &:hover {
          box-shadow: none;
        }
        box-sizing: border-box;
        border: 1px solid ${Colour.Black[5]};
      }
    }
  }
`;
export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const Header = styled.div`
  color: ${Colour.Gray[800]};
  ${BodyLargeSemiBold};
`;
