import styled from 'styled-components';

import {
  containerBase,
  containerDisabled,
  containerError,
  containerFocus,
  containerReadonly,
  focusState,
  hiddenLabelBase,
  InputFieldSize,
  labelInputHasFocus,
  labelMovedUp,
  labelVisible,
  wrapperBase,
} from '@xemplo/input-utils';
import { BodySmallRegular, Colour } from '@xemplo/style-constants';
import { zIndex } from '@xemplo/zindex';

export const Wrapper = styled.div<{ $width?: string }>`
  ${wrapperBase};
  position: relative;
  cursor: pointer;
`;

export const Container = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerFocus};
  ${containerDisabled};
  ${containerReadonly};
  ${containerError};
  & > svg {
    font-size: 16px;
    flex: unset;
  }

  &.focused {
    ${focusState};
  }
`;

export const LeadingIcon = styled.span`
  color: ${Colour.Gray[400]};
  z-index: ${zIndex.TextDropdownLeadingIcon};

  &.${InputFieldSize.Large} {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }
  &.${InputFieldSize.Standard}, &.${InputFieldSize.Medium} {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
`;

export const Select = styled.select`
  position: relative;
  width: 100%;
  appearance: none;
  outline: none;
  border: none;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  &::-ms-expand {
    display: none;
  }

  &.with-label {
    padding-top: 16px;
  }

  &.${InputFieldSize.Large} {
    height: 62px;
  }

  &.${InputFieldSize.Standard} {
    height: 46px;
  }

  &.${InputFieldSize.Medium} {
    height: 38px;
  }

  ${labelInputHasFocus};
`;

export const Label = styled.label`
  ${hiddenLabelBase};
  ${labelVisible};

  &.move-up {
    ${labelMovedUp};
  }
`;

export const Placeholder = styled.span`
  position: absolute;
  z-index: ${zIndex.TextDropdownPlaceholder};
  color: ${Colour.Gray[400]};
  transition: opacity 0.2s ease-out;
  ${BodySmallRegular};
  &.has-label {
    padding-top: 16px;
  }

  &.has-leading-icon {
    padding-left: 32px;
  }

  &.is-hidden {
    opacity: 0;
  }
`;

export const ArrowIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 4px;
  z-index: ${zIndex.TextDropdownSingleValue + 1};

  > svg {
    color: ${Colour.Gray[400]};
  }

  &.open {
    transform: rotate(180deg);
  }

  &.has-error {
    color: ${Colour.Red[500]};
  }
`;
