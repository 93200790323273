import { Radio } from "antd";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import {
  getSortOrder,
  mustBeArray,
  parseItems,
  ShowFullNameWithImage,
} from "../../../libs/utilities";

interface AssignPayrollAdminProps {
  list: Array<any>;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  loading: boolean;
  sortInfo?: {
    order?: string;
    field?: string;
  };
  assignedPayrollAdminList: Array<any>;
  handleAssignChange: (e: any) => void;
  currentPage?: number;
  pageSize?: number;
  total?: number;
}

const AssignPayrollAdminList = (props: AssignPayrollAdminProps) => {
  const {
    list,
    loading,
    handleTableChange,
    sortInfo,
    handleAssignChange,
    assignedPayrollAdminList,
    currentPage,
    total,
    pageSize,
  } = props;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      sortOrder: getSortOrder({ sortInfo, field: "name" }),
      render: (data: any, record: any) => {
        return ShowFullNameWithImage({
          firstName: record?.firstName,
          lastName: record?.lastName,
        });
      },
      width: "25%",
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: false,
      render: (data: any, record: any) => {
        return mustBeArray(record?.userPersona).reduce((a: any, b: any) => {
          return `${a ? `${a}, ${b.roleName}` : b.roleName}`;
        }, "");
      },
      width: "27%",
    },
    {
      title: "Payroll Service Provider",
      dataIndex: "payrollServiceProviderName",
      sorter: false,
      render: (data: any, record: any) => {
        return mustBeArray(record?.userPersona).reduce((a: any, b: any) => {
          return `${
            a
              ? `${a}, ${parseItems(b?.tenant)?.tenantName}`
              : parseItems(b?.tenant)?.tenantName
          }`;
        }, "");
      },
      width: "27%",
    },
    {
      title: "Select",
      dataIndex: "",
      sorter: false,
      render: (data: any, record: any) => {
        return (
          <Radio
            onChange={(e) => handleAssignChange({ record })}
            checked={mustBeArray(assignedPayrollAdminList).includes(record?.id)}
          />
        );
      },
      width: "21%",
    },
  ];
  return (
    <SimpleTableContainer
      dataSource={list}
      loading={loading}
      onChange={handleTableChange}
      columns={columns}
      pagination={
        currentPage
          ? {
              total,
              current: currentPage,
              pageSize,
            }
          : false
      }
    />
  );
};

export default AssignPayrollAdminList;
