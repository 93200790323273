import requireAuth from "../../Common/requireAuth";
import requireSecureComponent from "../../Common/requireSecureComponent";
import {
  GENERAL_AMENDMENT_CREATE_SIMPLE_BUTTON,
  SCOPES,
} from "../authorizationClaims";
import PayrunDetailContainer from "../../Organization/containers/payrunDetail";
import GeneralAmendmentcontainer from "../../Organization/containers/GeneralAmendment";
import GeneralAmendmentIndex from "../../Organization/pages/GeneralAmendment/Index";
import GeneralAmendmentCreate from "../../Organization/pages/GeneralAmendment/GeneralAmendmentCreate";
import AmendmentQuestions from "../../Organization/components/payrun/amendmentQuestion";
import AmendmentQuestionEditView from "../../Organization/pages/GeneralAmendment/AmendmentQuestionEditView";

export const AmendmentRoutesWithSidebar = [
  {
    path: "generalAmendments",
    scope: SCOPES.GENERALAMENDMENT,
    index: "generalAmendments",
    container: GeneralAmendmentcontainer,
    componentPath: "../../Organization/pages/GeneralAmendment/Index",
    element: (
      <GeneralAmendmentcontainer
        scope={SCOPES.GENERALAMENDMENT}
        Layout={requireAuth(GeneralAmendmentIndex)}
      />
    ),
  },
];

export const AmendmentRoutesWithoutSidebar = [
  {
    path: "payrun/:payrunId/amendment/:id",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunDetailContainer,
    componentPath: "../../Organization/components/payrun/amendmentQuestion",
    element: (
      <PayrunDetailContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(AmendmentQuestions)}
      />
    ),
  },
  {
    path: "payrun/amendment/:id",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunDetailContainer,
    componentPath:
      "../../Organization/pages/GeneralAmendment/AmendmentQuestionEditView",
    element: (
      <PayrunDetailContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(AmendmentQuestionEditView)}
      />
    ),
  },
];
