//@ts-nocheck
import { Card } from "antd";

const AmendmentDueMessage = (props: any) => {
  const { isAmendmentDueSoon } = props;
  const title = isAmendmentDueSoon
    ? "Act now - Your amendments are due."
    : "Please add amendments";
  const amendmentMessage =
    "In order to pay your people on time and accurately we need to make sure we collect any amendments you would like processed as part of your pay run.";
  return (
    <Card
      title={<div className="text-[28px] bold">{title}</div>}
      className="rounded-[12px] h-min-157"
    >
      <div className="flex flex-justify-space-end">
        <div className="text-base flex-1-1-75">{amendmentMessage}</div>
      </div>
    </Card>
  );
};
export default AmendmentDueMessage;
