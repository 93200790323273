import { useContext } from 'react';

import { TabMenuContext } from './tab-menu.context';

/** The context hook used to access the state values and functions */
export const useTabMenu = () => {
  const context = useContext(TabMenuContext);
  if (context === undefined) {
    throw new Error('useTabMenuContext must be used within a TabMenuProvider');
  }

  return context;
};
