import { useMemo } from 'react';

import { Button, ButtonSize, ButtonVariant, ButtonWithState } from '@xemplo/button';

import { ModalBodyOverflow } from '../body';
import { useModal } from '../hooks';
import { ModalTheme } from '../modal.types';

import { StyledFooter } from './footer.style';

export const ModalFooterTestId = {
  footer: 'modal-footer',
  extra: 'modal-footer-extra',
  primary: 'modal-footer-primary',
  secondary: 'modal-footer-secondary',
  tertiary: 'modal-footer-tertiary',
};

export const ModalFooter = ({ className }: { className?: string }) => {
  const { state, footerRef } = useModal();
  const { footer, body, theme = ModalTheme.Light } = state;

  const btnVariant = useMemo(
    () => (theme === ModalTheme.Dark ? ButtonVariant.Light : ButtonVariant.Dark),
    [theme]
  );

  if (!footer) return null;

  const {
    btnPrimary,
    btnSecondary,
    btnTertiary,
    extra: footerExtra,
    customFooter,
  } = footer;

  return (
    customFooter ?? (
      <StyledFooter
        ref={footerRef}
        data-testid={ModalFooterTestId.footer}
        data-overflow={body?.overflow || ModalBodyOverflow.Standard}
        className={className}
      >
        {footerExtra ? (
          <div className="extra" data-testid={ModalFooterTestId.extra}>
            {footerExtra}
          </div>
        ) : null}
        {btnPrimary ? (
          <ButtonWithState
            {...btnPrimary}
            size={btnPrimary?.size || ButtonSize.Medium}
            className="primaryBtn"
            testId={ModalFooterTestId.primary}
          >
            {btnPrimary.label}
          </ButtonWithState>
        ) : null}
        {btnSecondary ? (
          <Button
            {...btnSecondary}
            size={btnSecondary?.size || ButtonSize.Medium}
            className="secondaryBtn"
            testId={ModalFooterTestId.secondary}
            variant={btnVariant}
          >
            {btnSecondary.label}
          </Button>
        ) : null}
        {btnTertiary ? (
          <Button
            {...btnTertiary}
            size={btnTertiary?.size || ButtonSize.Medium}
            className="tertiaryBtn"
            testId={ModalFooterTestId.tertiary}
            variant={btnVariant}
          >
            {btnTertiary.label}
          </Button>
        ) : null}
      </StyledFooter>
    )
  );
};

export default ModalFooter;
