import { SVGProps } from 'react';
const DawnArrowRightEnd16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5 2.531c0-.431-.336-.781-.75-.781s-.75.35-.75.781V13.47c0 .431.336.781.75.781s.75-.35.75-.781V2.53ZM3.22 2.113a.75.75 0 0 0 0 1.06l4.847 4.848L3.22 12.87a.75.75 0 0 0 1.06 1.06l5.378-5.378a.749.749 0 0 0 0-1.06L4.28 2.113a.75.75 0 0 0-1.06 0Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowRightEnd16;
