import { ParsedCsvItem } from './page-2/bulk-upload/bulk-upload.types';
import { BulkUploadSummary } from './page-3/summary/bulk-upload-summary';
import { BulkUploadVerifyProps } from './page-3/summary/bulk-upload-summary.types';

export interface StepThreeProps {
  csvDataWarnings: string[];
  csvData: ParsedCsvItem[];
}
export const StepThree = (props: BulkUploadVerifyProps) => {
  return <BulkUploadSummary {...props} />;
};
