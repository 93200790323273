import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import {
  containerBase,
  containerDisabled,
  containerError,
  containerFocus,
  containerHasLabelPadding,
  containerReadonly,
  descriptionBase,
  errorMessageBase,
  focusState,
  hiddenLabelBase,
  inputBase,
  inputDisabled,
  InputFieldSize,
  inputReadonly,
  inputWithLabel,
  labelInputHasFocus,
  labelInputHasValue,
  labelVisible,
  wrapperBase,
} from '@xemplo/input-utils';
import { Colour } from '@xemplo/style-constants';

export const Wrapper = styled.div<{ $width?: string }>`
  ${wrapperBase};
`;

export const Container = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerDisabled};
  ${containerReadonly};
  ${containerError};
  ${containerHasLabelPadding};
  padding-right: 0;
  &.has-value,
  &.has-label {
    padding-right: 0;
  }
  &.input-focused {
    ${focusState};
    ${containerFocus};
  }
`;

export const Input = styled.input`
  ${inputBase};
  ${inputDisabled};
  ${inputReadonly};
  ${inputWithLabel};
  ${labelInputHasFocus};
`;

export const Label = styled.label`
  ${hiddenLabelBase};
  ${labelVisible};
  ${labelInputHasValue};
  left: 16px;
`;

export const ErrorMessage = styled.div`
  ${errorMessageBase};
`;

export const Description = styled.div`
  ${descriptionBase};
`;

export const CalendarIconContainer = styled(IconButton)`
  flex: 0 0 auto;
  border: none;
  border-left: 1px solid ${Colour.Gray[200]};
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 30px;
  width: 56px;

  &:focus-visible {
    outline: none;
  }
  &.medium {
    height: 38px;
  }
  &.standard {
    height: 46px;
  }
  &.large {
    height: 62px;
  }
  & > svg {
    color: ${Colour.Gray[400]};
  }

  &:hover {
    border-color: ${Colour.Gray[200]};
  }
`;

export const CustomInput = styled.input`
  display: none;
`;
