import styled, { css } from 'styled-components';

import {
  Body2XSmallMedium,
  BodySmallMedium,
  BodyXSmallMedium,
  Colour,
  TextEllipsis,
} from '@xemplo/style-constants';

import {
  BadgeColour,
  BadgeSize,
  BadgeStyleProps,
  BadgeType,
  PipStyleProps,
} from './badge.types';

const BadgeSizeForMinMap = {
  [BadgeSize.Small]: css`
    width: 20px;
    height: 20px;
  `,
  [BadgeSize.Medium]: css`
    width: 24px;
    height: 24px;
  `,
  [BadgeSize.Large]: css`
    width: 32px;
    height: 32px;
  `,
};

const BadgeSizeMap = {
  [BadgeSize.Small]: css`
    ${Body2XSmallMedium};
    height: 20px;
    width: fit-content;
    padding: 0 6px;
  `,
  [BadgeSize.Medium]: css`
    ${BodyXSmallMedium};
    height: 24px;
    width: fit-content;
    padding: 0 8px;
  `,
  [BadgeSize.Large]: css`
    ${BodySmallMedium};
    height: 32px;
    width: fit-content;
    padding: 0 12px;
  `,
};

const BadgeSizeForPipMap = {
  [BadgeSize.Small]: css`
    ${Body2XSmallMedium};
    height: 20px;
    width: fit-content;
    padding: 0 5px;
  `,
  [BadgeSize.Medium]: css`
    ${BodyXSmallMedium};
    height: 24px;
    width: fit-content;
    padding: 0 7px;
  `,
  [BadgeSize.Large]: css`
    ${BodySmallMedium};
    height: 32px;
    width: fit-content;
    padding: 0 7px;
  `,
};

const BadgeBorderRadiusMap = {
  [BadgeType.Default]: css`
    border-radius: 4px;
  `,
  [BadgeType.Pill]: css`
    border-radius: 24px;
  `,
  [BadgeType.Square]: css`
    border-radius: 4px;
    padding: 0;
  `,
  [BadgeType.Round]: css`
    border-radius: 24px;
    padding: 0;
  `,
  [BadgeType.DefaultPip]: css`
    border-radius: 4px;
  `,
  [BadgeType.PillPip]: css`
    border-radius: 24px;
  `,
};

const BadgeBgColourMap = {
  [BadgeColour.Blue]: css`
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
  `,
  [BadgeColour.Gray]: css`
    background: ${Colour.Gray[500]};
    color: ${Colour.White[100]};
  `,
  [BadgeColour.Green]: css`
    background: ${Colour.Green[500]};
    color: ${Colour.White[100]};
  `,
  [BadgeColour.Orange]: css`
    background: ${Colour.Orange[500]};
    color: ${Colour.White[100]};
  `,
  [BadgeColour.Purple]: css`
    background: ${Colour.Purple[500]};
    color: ${Colour.White[100]};
  `,
  [BadgeColour.Red]: css`
    background: ${Colour.Red[500]};
    color: ${Colour.White[100]};
  `,
  [BadgeColour.White]: css`
    background: ${Colour.White[100]};
    color: ${Colour.Gray[500]};
  `,
  [BadgeColour.LightBlue]: css`
    background: ${Colour.Blue[50]};
    color: ${Colour.Blue[500]};
  `,
  [BadgeColour.LightGray]: css`
    background: ${Colour.Gray[100]};
    color: ${Colour.Gray[500]};
  `,
  [BadgeColour.LightGreen]: css`
    background: ${Colour.Green[50]};
    color: ${Colour.Green[600]};
  `,
  [BadgeColour.LightOrange]: css`
    background: ${Colour.Orange[50]};
    color: ${Colour.Orange[500]};
  `,
  [BadgeColour.LightPurple]: css`
    background: ${Colour.Purple[50]};
    color: ${Colour.Purple[500]};
  `,
  [BadgeColour.LightRed]: css`
    background: ${Colour.Red[50]};
    color: ${Colour.Red[500]};
  `,
  [BadgeColour.LightWhite]: css`
    background: ${Colour.White[80]};
    color: ${Colour.Gray[500]};
    border: 1px solid ${Colour.Gray[200]};
  `,
};

const typeAndSize = ({ type, size }: BadgeStyleProps) => {
  let badgeSize;

  switch (type) {
    case BadgeType.Round:
    case BadgeType.Square:
      badgeSize = BadgeSizeForMinMap[size];
      break;
    case BadgeType.Default:
    case BadgeType.Pill:
      badgeSize = BadgeSizeMap[size];
      break;
    case BadgeType.DefaultPip:
    case BadgeType.PillPip:
      badgeSize = BadgeSizeForPipMap[size];
      break;
  }

  const badgeBorderRadius = BadgeBorderRadiusMap[type];

  return css`
    ${badgeSize};
    ${badgeBorderRadius};
  `;
};

export const StyledBadge = styled.span<BadgeStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  box-sizing: border-box;
  min-width: fit-content;
  ${TextEllipsis};
  ${({ color }) => BadgeBgColourMap[color]};
  ${(props) => typeAndSize(props)};

  &[data-clickable='true'] {
    cursor: pointer;
  }
`;

export const StyledPip = styled.span<PipStyleProps>`
  margin-left: 2px;
  ${({ size }) => size !== BadgeSize.Small && 'margin-right: 2px'};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  ${({ color }) => BadgeBgColourMap[color]};
`;
