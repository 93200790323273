//@ts-nocheck
import React from "react";
import BasicDetails from "../../components/basicDetails";
import { Col, message, Row, Spin, Tabs } from "antd";
import PayScheduleList from "../../components/paySchedule/list";
import {
  mustBeArray,
  findAuthorizationClaims,
  updateAuthorizationClaims,
  delay,
  getCurrentCardGrid,
  getCurrentTableSortInfo,
  getCurrentGridSortInfo,
  getConditionalResponse,
  conditionalParameter,
  requestUrlBuilder,
  getCurrentAuthorizationDom,
  getOptionalParameter,
  getMatchParams,
  getPersonalizationData,
  isComponentVisible,
  isUserPrimaryPayrollManager,
  getUserArea,
  getDefaultPage,
  getDefaultPerPage,
  errorHandler,
  isDataEmptyOnSelectedPage,
  getCurrentGridPageSize,
  isUserSystemAdmin,
  isListComponentGridable,
  getGridRowAndColumn,
  getDeviceEnrolmentActionText,
  setPersonalizationData,
  getAllUsersDeviceEnrolmentActionText,
  getDeviceEnrolmentId,
  isComponentPersonalizable,
  getSearchParamsFromUrl,
  getBusinessUnitEditRoute,
  getBusinessUnitDetailRoute,
  getPayScheduleCreateRoute,
  getPayScheduleDetailRoute,
} from "../../../libs";
import ServiceDetails from "../../components/businessunit/serviceDetails";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import _ from "lodash";
import {
  MULTI_TAB_ACTIVE_KEY_INDEX,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  TABLE_PERSONALIZATIONS,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  SORT_COLUMN_INDEX,
  SORT_ORDER_INDEX,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
  GRID_PERSONALIZATIONS,
  GRID_SORT_ORDER_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_COLUMN_INDEX,
  GRID_ROW_INDEX,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  GRID_VIEW_VALUE,
  SORTING_NOT_REQUIRED,
  GRID_PAGE_NUMBER_INDEX,
} from "../../../constants/defaultClaims";
import axios from "axios";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import GeneralContainer from "../../../Common/ClaimContainers/GeneralContainer";
import ChildTabContainer from "../../../Common/ClaimContainers/ChildTabContainer";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import PayScheduleDisplayCard from "../../components/paySchedule/PayScheduleDisplayCard";
import TabHeader from "../../../Common/tabHeader";
import ScrollablePayScheduleTable from "../../components/paySchedule/ScrollablePayScheduleTable";
import APIHandler from "../../../constants/apiUrl";
import OrganizationOverviewCard from "../../../Common/OrganizationOverviewCard";
import AssignedPayrollManagersCardView from "../../components/payrollProvider/AssignedPayrollManagersCardView";
import AssignPayrollManagerModal from "../../components/businessunit/AssignPayrollManagerModal";
import {
  PRIMARY_PAYROLL_MANAGER_ID,
  SECONDARY_PAYROLL_MANAGER_ID,
} from "../../../constants/user";
import UserAuthenticationUpdate from "../../components/users/authenticationUpdate";
import userRoles from "../../../constants/userRoles";
import {
  COMPANY_USER_FIELD_OPTIONS,
  COMPANY_USER_GRID_OPTIONS,
  PAYSCHEDULE_FIELD_OPTIONS,
  PAYSCHEDULE_GRID_OPTIONS,
} from "../../../constants/sortingOptions";
import CompanyUserList from "../../components/users/company";
import ScrollableUserList from "../../components/users/ScrollableUserList";
import UserDisplayCard from "../../components/users/userDisplayCard";
import IntegrationSettings from "../../components/businessunit/IntegrationSettings/IntegrationSettings";
import {
  BUSINESS_UNIT_DETAIL_MULTI_TAB_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { withRouter } from "../../../hooks";

const { TabPane } = Tabs;

type State = any;

class BusinessUnitDetail extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      pageLoading: true,
      visible: false,
      confirm_dialog: false,
      payroll_select: false,
      payrollproviderId: this.props.businessunit.payrollServiceProviderId,
      payrollProvider: {},
      paySchedulePagination: 1,
      paySchedulePerPage: 10,
      payScheduleTotal: 0,
      payScheduleList: [],
      payScheduleListLoading: false,
      payScheduleAddable: false,
      payScheduleGridable: false,
      payScheduleGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      payScheduleGridView: false,
      payScheduleIsPinned: "false",
      payScheduleSortingType: "notrequired",
      payScheduleHasMoreData: false,
      payScheduleSortInfo: {},
      payScheduleGridSortOption: PAYSCHEDULE_GRID_OPTIONS,
      payScheduleSelectedGridSort: undefined,
      payScheduleFieldOptions: PAYSCHEDULE_FIELD_OPTIONS,
      bspList: [],
      activeKey: "1",
      managePayrollManagersModalVisible: false,
      assignedPayrollManagers: [],
      saveLoading: false,
      // Users Tab
      userPagination: 1,
      userPerPage: 10,
      userTotal: 0,
      userList: [],
      userListLoading: false,
      userAddable: false,
      userGridable: false,
      userGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      userGridView: false,
      userIsPinned: "false",
      userSortingType: "notrequired",
      userHasMoreData: false,
      userSortInfo: {},
      userGridSortOption: COMPANY_USER_GRID_OPTIONS,
      userSelectedGridSort: undefined,
      userFieldOptions: COMPANY_USER_FIELD_OPTIONS,
      businessUnitId: "",
      companyid: getSearchParamsFromUrl("companyid"),
    };
  }

  componentDidMount() {
    const { dashboardClaims, dashboardView, match } = this.props;
    const businessUnitId = getMatchParams({ match, field: "businessunitid" });
    this.setState({ businessUnitId: businessUnitId });
    this.props
      .getBusinessUnit({
        id: businessUnitId,
      })

      .then((res) => {
        this.setState({ pageLoading: false });

        if (res.status) {
          this.setActionHeader(res.data?.name);
          this.props
            .listBillingServiceProviders({ options: {} })
            .then((response) => {
              if (response.status) {
                this.setState({ bspList: mustBeArray(response.data) });
              } else {
                errorHandler({ response, hasValidationErrors: true });
              }
            });
          this.props
            .getPayrollProvider({
              id: res?.data?.payrollServiceProviderId,
            })
            .then((response) => {
              this.setState({
                payrollProvider: response.data,
              });
            });
        } else {
          errorHandler({ response: res, hasValidationErrors: true });
        }
      });

    if (dashboardView) {
      const businessUnitPaySchedulePersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });

      this.setState({
        payScheduleGridView: getConditionalResponse({
          condition: getOptionalParameter({
            value1: !businessUnitPaySchedulePersonalizations,
            value2: !businessUnitPaySchedulePersonalizations.gridView,
          }),
          resp1: false,
          resp2: true,
        }),
      });

      this.getPayschedule({ options: {} });
    } else {
      this.loadBusinessUnitDetailComponents();
    }
  }

  loadBusinessUnitDetailComponents = () => {
    const { pageClaims } = this.props;

    this.props.updateHeader({
      header: {
        title: "",
        module: "Business Unit",
        enableBack: true,
        returnUrl: "/businessunit",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BusinessUnitDetail,
      },
    });
    const businessUnitDetailMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === BUSINESS_UNIT_DETAIL_MULTI_TAB_CONTAINER
    );
    const multiTabPersonalizations = getPersonalizationData({
      personalizationData:
        businessUnitDetailMultiTabContainer?.personalizations,
      type: "multiTabContainer",
    });
    const activeKey = multiTabPersonalizations?.activeKey;

    if (activeKey) {
      this.setState({ activeKey });
    }
    this.loadPayScheduleComponents();
    this.loadUserComponents();
  };

  setActionHeader = (businessName?: string) => {
    if (!this.props.dashboardView) {
      this.props.updateHeader({
        header: {
          title: businessName,
          module: "Business Unit",
          enableBack: true,
          action: "",
          returnUrl: "/businessunit",
        },
      });
    }
  };

  loadUserComponents = () => {
    this.setState({ userListLoading: true });
    const userListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitUserListContainer",
    });
    const userGeneralTable = findAuthorizationClaims({
      claims: userListContainer?.components,
      name: "businessUnitUserGeneralTable",
    });
    const userGeneralGrid = findAuthorizationClaims({
      claims: userListContainer?.components,
      name: "businessUnitUserGeneralGrid",
    });
    const userPersonalizations = getPersonalizationData({
      personalizationData: userListContainer?.personalizations,
      type: "listContainer",
    });
    const userPaginationType = userPersonalizations?.paginationType;
    const userSortingType = userPersonalizations?.sortingType;
    const userIsPinned = userPersonalizations?.isPinned;

    this.setState({
      userPaginationType,
      userSortingType,
      userIsPinned,
      userGridable: isListComponentGridable({ component: userListContainer }),
    });
    // If Table
    const userTablePersonalizations = getPersonalizationData({
      personalizationData: userGeneralTable?.personalizations,
      type: "table",
    });
    if (isComponentVisible({ claim: userGeneralTable })) {
      const userPagination = userTablePersonalizations?.pageNumber;
      const pageSize = userTablePersonalizations?.pageSize;
      const SortOrder = userTablePersonalizations?.sortOrder;
      const SortColumn = userTablePersonalizations?.sortColumn;

      this.setState(
        {
          userPerPage: getDefaultPerPage({ perPage: pageSize }),
          userPagination,
          userSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!userPersonalizations.gridView) {
            this.listBusinessUnitUsers({ options: {}, pageLoad: true });
          }
        }
      );
    }

    // If Grid
    const userGridPersonalizations = getPersonalizationData({
      personalizationData: userGeneralGrid?.personalizations,
      type: "grid",
    });
    if (isComponentVisible({ claim: userGeneralGrid })) {
      const pageNumber = userGridPersonalizations?.pageNumber;
      const row = userGridPersonalizations?.row;
      const col = userGridPersonalizations.col;
      const SortOrder = userGridPersonalizations?.sortOrder;
      const SortColumn = userGridPersonalizations?.sortColumn;

      this.setState(
        {
          userGrid: {
            page: pageNumber,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          userSelectedGridSort: _.find(
            this.state.userGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (userPersonalizations?.gridView) {
            this.setState({ userGridView: true });
            this.listGridBusinessUnitUsers({ options: {}, pageLoad: true });
          }
        }
      );
    }
  };

  loadPayScheduleComponents = () => {
    this.setState({ payScheduleListLoading: true });
    const payScheduleListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "businessUnitPayScheduleListContainer",
    });
    const payScheduleGeneralTable = findAuthorizationClaims({
      claims: payScheduleListContainer?.components,
      name: "businessUnitPayScheduleGeneralTable",
    });
    const payScheduleGeneralGrid = findAuthorizationClaims({
      claims: payScheduleListContainer?.components,
      name: "businessUnitPayScheduleGeneralGrid",
    });
    const paySchedulePersonalizations = getPersonalizationData({
      personalizationData: payScheduleListContainer?.personalizations,
      type: "listContainer",
    });
    const paySchedulePaginationType =
      paySchedulePersonalizations?.paginationType;
    const payScheduleSortingType = paySchedulePersonalizations?.sortingType;
    const payScheduleIsPinned = paySchedulePersonalizations?.isPinned;

    this.setState({
      paySchedulePaginationType,
      payScheduleSortingType,
      payScheduleIsPinned,
      payScheduleGridable: payScheduleListContainer?.gridable,
    });

    // If Table
    const payScheduleTablePersonalizations = getPersonalizationData({
      personalizationData: payScheduleGeneralTable?.personalizations,
      type: "table",
    });
    if (isComponentVisible({ claim: payScheduleGeneralTable })) {
      const paySchedulePagination =
        payScheduleTablePersonalizations?.pageNumber;
      const pageSize = payScheduleTablePersonalizations?.pageSize;
      const SortOrder = payScheduleTablePersonalizations?.sortOrder;
      const SortColumn = payScheduleTablePersonalizations?.sortColumn;

      this.setState(
        {
          paySchedulePerPage: getDefaultPerPage({ perPage: pageSize }),
          paySchedulePagination,
          payscheduleSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!paySchedulePersonalizations.gridView) {
            if (paySchedulePaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPaySchedule({ pageLoad: true });
            } else {
              this.listPaySchedules({ options: {} });
            }
          }
        }
      );
    }

    // If Grid
    const payScheduleGridPersonalizations = getPersonalizationData({
      personalizationData: payScheduleGeneralGrid?.personalizations,
      type: "grid",
    });
    if (isComponentVisible({ claim: payScheduleGeneralGrid })) {
      const pageNumber = payScheduleGridPersonalizations?.pageNumber;
      const row = payScheduleGridPersonalizations?.row;
      const col = payScheduleGridPersonalizations.col;
      const SortOrder = payScheduleGridPersonalizations?.sortOrder;
      const SortColumn = payScheduleGridPersonalizations?.sortColumn;

      this.setState(
        {
          payScheduleGrid: {
            page: pageNumber,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          payScheduleSelectedGridSort: _.find(
            this.state.payScheduleGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (paySchedulePersonalizations?.gridView) {
            this.setState({ payScheduleGridView: true });
            if (paySchedulePaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPaySchedule({ pageLoad: true });
            } else {
              this.payScheduleFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  listBusinessUnitUsers = ({ options = {}, pageLoad = false }) => {
    this.setState(
      (prevState) => {
        if (options?.page) {
          prevState.userPagination = options.page;
          prevState.userSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: getDefaultPage({ page: this.state.userPagination }),
          per_page: getDefaultPerPage({
            perPage: this.state.userPerPage,
            defaultPerPage: 10,
          }),
          ...getCurrentTableSortInfo({
            sortInfo: this.state.userSortInfo,
            sortingType: this.state.userSortingType,
          }),
        };
        this.props
          .listCompanyUsers({
            options: newOptions,
            companyId: this.state.companyid,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              if (
                isDataEmptyOnSelectedPage({
                  pagination: this.state.userPagination,
                  data: resp.data,
                  dashboardView: this.props.dashboardView,
                })
              ) {
                this.setState({ userPagination: 1 }, () => {
                  this.listBusinessUnitUsers({ options: {}, pageLoad });
                });
              } else {
                this.setState({
                  userList: mustBeArray(resp.data),
                  userTotal: resp.total,
                });
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        this.handleComponentUpdate({
          name: "businessUnitUserGeneralTable",
          pageLoad,
        });
      }
    );
  };

  handleComponentUpdate = ({ name, pageLoad, delayPeriod = 300 }) => {
    if (!pageLoad) {
      delay(delayPeriod).then(() => {
        this.updatePersonalization(name);
      });
    }
  };

  listGridBusinessUnitUsers = ({ page, pageLoad = false }) => {
    this.setState(
      (prevState) => {
        prevState.userGrid.page = getDefaultPage({ page });
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.userGrid.page,
          per_page: getCurrentGridPageSize({
            grid: this.state.userGrid,
            paginationType: this.state.userPaginationType,
          }),
          ...getCurrentGridSortInfo({
            grid: this.state.userGrid,
            sortingType: this.state.userSortingType,
          }),
        };

        this.props
          .listCompanyUsers({
            options: tempOptions,
            companyId: this.state.companyid,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userListLoading: false });
            if (resp.status) {
              if (
                isDataEmptyOnSelectedPage({
                  pagination: this.state.userGrid.page,
                  data: resp.data,
                  dashboardView: this.props.dashboardView,
                })
              ) {
                this.setState(
                  { userGrid: { ...this.state.userGrid, page: 1 } },
                  () => {
                    this.listGridBusinessUnitUsers({ page: 1, pageLoad });
                  }
                );
              } else {
                this.setState({
                  userList: mustBeArray(resp.data),
                  userTotal: resp.total,
                });
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        this.handleComponentUpdate({
          name: "businessUnitUserGeneralTable",
          pageLoad,
        });
      }
    );
  };

  handlePageHeaderUpdate = () => {
    const { businessunit, match } = this.props;
    const businessunitid = getMatchParams({ match, field: "businessunitid" });
    const tempHeader = {
      module: "Back to Business Unit",
      enableBack: true,
      title: `${businessunit?.name}`,
      entity: "business unit",
      returnUrl: getBusinessUnitDetailRoute({
        id: businessunitid,
        companyId: this.state.companyid,
      }),
    };
    this.props.updateHeader({ header: tempHeader });
  };

  onActionClicked = () => {
    const businessunitid = getMatchParams({
      match: this.props.match,
      field: "businessunitid",
    });
    this.handlePageHeaderUpdate();
    this.props.router.navigate(
      getBusinessUnitEditRoute({
        id: businessunitid,
        companyId: this.state.companyid,
      })
    );
  };

  handleServiceDetailEdit = () => {
    const id = getMatchParams({
      match: this.props.match,
      field: "businessunitid",
    });
    this.handlePageHeaderUpdate();
    this.props.router.navigate(
      `${getBusinessUnitEditRoute({
        id,
        companyId: this.state.companyid,
      })}?activeIndex=2`
    );
  };

  handlePayrollManagersEdit = () => {
    const { businessunit } = this.props;
    const assignedPayrollManagers = mustBeArray(
      businessunit?.assignedUsers
    ).reduce((a, b) => {
      return a.concat({
        userId: b.id,
        isPrimary: isUserPrimaryPayrollManager({ user: b }),
        firstName: b.firstName,
        lastName: b.lastName,
        profilePhoto: b.profilePhoto,
      });
    }, []);
    this.setState(
      {
        payrollProviderId: businessunit?.payrollServiceProviderId,
        assignedPayrollManagers,
      },
      () => {
        this.setState({ managePayrollManagersModalVisible: true });
      }
    );
  };

  handleAssign = ({ event, record }: any) => {
    const { assignedPayrollManagers } = this.state;
    //Unassign
    if (assignedPayrollManagers.find((o) => o.userId === record?.id)) {
      let newManagersList = assignedPayrollManagers.filter(
        (o) => o.userId !== record.id
      );
      this.setState({
        assignedPayrollManagers: newManagersList,
      });
      //assign
    } else {
      this.setState({
        assignedPayrollManagers: [
          ...assignedPayrollManagers,
          { userId: record?.id, isPrimary: false },
        ],
      });
    }
  };

  handleChangePrimary = ({ event, record }: any) => {
    this.setState((prevState) => {
      prevState.assignedPayrollManagers =
        prevState.assignedPayrollManagers.reduce((a, b) => {
          return b.userId === record.id
            ? [...a, { ...b, isPrimary: !b.isPrimary }]
            : [...a, { ...b, isPrimary: false }];
        }, []);
      return prevState;
    });
  };

  handleSaveAssignedPayrollManager = () => {
    const { assignedPayrollManagers } = this.state;
    const { businessunit, match } = this.props;
    if (
      !_.isEmpty(assignedPayrollManagers) &&
      !_.find(assignedPayrollManagers, (o) => o.isPrimary)
    ) {
      return message.error("Please select a primary payroll manager.");
    }
    const payload = mustBeArray(assignedPayrollManagers).reduce((a, b) => {
      return a.concat({
        userId: b.userId,
        assigneeType: b.isPrimary
          ? PRIMARY_PAYROLL_MANAGER_ID
          : SECONDARY_PAYROLL_MANAGER_ID,
      });
    }, []);
    this.setState({ saveLoading: true });
    this.props
      .businessunitHandler({
        id: businessunit?.id,
        action: "assignPayrollManager",
        cancelToken: this.signal.token,
        payload,
      })
      .then((resp) => {
        this.setState({ saveLoading: false });
        if (resp.status) {
          this.props.getBusinessUnit({
            id: getMatchParams({ match, field: "businessunitid" }),
          });
          message.success(`Payroll Managers updated successfully.`);
          this.handleManagePayrollManagersModalClose();
        } else {
          errorHandler({ response: resp });
        }
      });
  };

  handleManagePayrollManagersModalClose = () => {
    this.setState({
      managePayrollManagersModalVisible: false,
      assignedPayrollManagers: [],
    });
  };

  onCancel = () => {
    this.setState({
      visible: false,
      confirm_dialog: false,
      payroll_select: false,
    });
  };

  addPayschedule = () => {
    const id = getMatchParams({
      match: this.props.match,
      field: "businessunitid",
    });
    const tempHeader = {
      module: "Back to Business Unit",
      enableBack: true,
      title: `New Pay Schedule`,
      entity: "pay schedule",
      gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayScheduleCreate,
      returnUrl: getBusinessUnitDetailRoute({
        id,
        companyId: this.state.companyid,
      }),
    };

    this.props.updateHeader({ header: tempHeader });
    this.props.router.navigate(
      getPayScheduleCreateRoute({
        companyId: this.state.companyid,
        businessUnitId: id,
      })
    );
  };

  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
      this.signal.cancel("Api is being canceled");
    }
  }

  getPayschedule = ({ options }) => {
    this.setState(
      {
        paySchedulePagination: getDefaultPage({ page: options?.page }),
        payScheduleSortInfo: { field: options.field, order: options.order },
        payScheduleListLoading: true,
      },
      () => {
        let tempOptions = {
          page: this.state.paySchedulePagination,
          per_page: this.state.paySchedulePerPage,
          q: options.q,
        };
        if (options?.field && options?.order) {
          tempOptions.sort = { [options.field]: options.order };
        }

        tempOptions.q = {
          ...tempOptions.q,
          businessUnitID: getMatchParams({
            match: this.props.match,
            field: "businessunitid",
          }),
        };

        this.props
          .getPayschedule({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((response) => {
            this.setState({ payScheduleListLoading: false });

            if (response.status) {
              this.setState({
                payScheduleList: response.data,

                payScheduleTotal: response.total,
              });
            } else {
              errorHandler({ response, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("businessUnitPayScheduleGeneralTable");
        });
      }
    );
  };

  handleTabChange = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.updatePersonalization(BUSINESS_UNIT_DETAIL_MULTI_TAB_CONTAINER);
    });
  };

  updatePersonalization = (field, update = true) => {
    const { member, pageClaims, dashboardView } = this.props;
    if (getOptionalParameter({ value1: dashboardView, value2: !pageClaims }))
      return;
    try {
      let currentAuthorizationDOM = getCurrentAuthorizationDom(member);
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];
      const businessUnitPayScheduleListContainer = findAuthorizationClaims({
        claims: pageClaims.components,
        name: "businessUnitPayScheduleListContainer",
      });
      const businessUnitUserListContainer = findAuthorizationClaims({
        claims: pageClaims.components,
        name: "businessUnitUserListContainer",
      });
      const businessUnitUserGeneralTable = _.find(
        mustBeArray(businessUnitUserListContainer?.components),
        (o) => o.name === "businessUnitUserGeneralTable"
      );

      const businessUnitUserGeneralGrid = _.find(
        mustBeArray(businessUnitUserListContainer?.components),
        (o) => o.name === "businessUnitUserGeneralGrid"
      );
      switch (field) {
        case "businessUnitDetailMultiTabContainer":
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[MULTI_TAB_ACTIVE_KEY_INDEX].value =
            this.state.activeKey;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims?.components,
            name: field,
          });
          break;
        case "businessUnitPayScheduleGeneralTable":
          if (businessUnitPayScheduleListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.paySchedulePagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.paySchedulePerPage}`;

            personalizations[SORT_COLUMN_INDEX].value = conditionalParameter({
              data: this.state.payscheduleSortInfo,
              field: "field",
            });

            personalizations[SORT_ORDER_INDEX].value = conditionalParameter({
              data: this.state.payscheduleSortInfo,
              field: "order",
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims.components,
              name: field,
            });
          }
          break;
        case "businessUnitPayScheduleGeneralGrid":
          if (businessUnitPayScheduleListContainer.personalizable === "true") {
            const { payScheduleGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${conditionalParameter({
              data: payScheduleGrid,
              field: "page",
            })}`;
            personalizations[GRID_ROW_INDEX].value = `${conditionalParameter({
              data: payScheduleGrid,
              field: "row",
            })}`;
            personalizations[GRID_COLUMN_INDEX].value = `${conditionalParameter(
              { data: payScheduleGrid, field: "col" }
            )}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              payScheduleGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              payScheduleGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims.components,
              name: field,
            });
          }
          break;
        case "businessUnitPayScheduleListContainer":
          if (businessUnitPayScheduleListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];
            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value =
              getConditionalResponse({
                condition: this.state.payScheduleGridView,
                resp1: GRID_VIEW_VALUE,
                resp2: TABLE_VIEW_VALUE,
              });

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.paySchedulePaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.payScheduleSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.payScheduleIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims.components,
              name: field,
            });
          }
          break;
        case "businessUnitUserGeneralTable":
          if (
            isComponentPersonalizable({
              component: businessUnitUserListContainer,
            })
          ) {
            personalizations = setPersonalizationData({
              type: "table",
              pageNumber: this.state.userPagination,
              sortOrder: this.state.userSortInfo?.order,
              sortColumn: this.state.userSortInfo?.field,
              pageSize: this.state.userPerPage,
              personalizationData:
                businessUnitUserGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "businessUnitUserGeneralGrid":
          if (
            isComponentPersonalizable({
              component: businessUnitUserListContainer,
            })
          ) {
            const { userGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: userGrid?.page,
              row: userGrid?.row,
              col: userGrid?.col,
              sortOrder: userGrid?.sortInfo?.order,
              sortColumn: userGrid?.sortInfo?.field,
              personalizationData:
                businessUnitUserGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "businessUnitUserListContainer":
          if (businessUnitUserListContainer.personalizable === "true") {
            personalizations = personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.userGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,
              paginationType: this.state.userPaginationType,
              sortingType: this.state.userSortingType,
              isPinned: this.state.userIsPinned,
              personalizationData:
                businessUnitUserListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      let updateChange = update;
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent.id,
          name: updatedComponent.name,
          personalizations,
          personalization: getConditionalResponse({
            condition: _.isEmpty(personalization),
            resp1: null,
            resp2: personalization,
          }),
        };

        this.saveChangedPersonalizationClaims(payload);
      } else {
        updateChange = false;
      }
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChange,
        });
        if (updateChange) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };
  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === newClaim?.id
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...mustBeArray(prevState.updatedComponentPersonalization),
          newClaim,
        ];
      }
      return prevState;
    });
  };

  listScrollingGridPaySchedule = ({ pageLoad = false }) => {
    let currentGridPage = conditionalParameter({
      data: this.state.payScheduleGrid,
      field: "page",
    });

    if (!pageLoad && !this.state.payScheduleHasMoreData) return false;

    this.setState(
      {
        payScheduleListLoading: true,
        payScheduleGrid: {
          ...this.state.payScheduleGrid,
          page: getConditionalResponse({
            condition: !pageLoad,
            resp1: Number(currentGridPage) + 1,
            resp2: Number(currentGridPage),
          }),
        },
      },
      () => {
        const { payScheduleGrid } = this.state;
        const { page } = payScheduleGrid;

        const per_page = this.getPayScheduleGridPageSize();
        let sortingParams = getCurrentGridSortInfo({
          grid: payScheduleGrid,
          sortingType: payScheduleSortingType,
        });

        this.props
          .getPayschedule({
            options: {
              page: getConditionalResponse({
                condition: pageLoad,
                resp1: 1,
                resp2: page,
              }),
              per_page: getConditionalResponse({
                condition: pageLoad,
                resp1: Number(page) * per_page,
                resp2: per_page,
              }),

              q: {
                businessUnitID: getMatchParams({
                  match: this.props.match,
                  field: "businessunitid",
                }),
              },
              ...sortingParams,
            },

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ payScheduleListLoading: false });

            if (response.status) {
              if (_.isEmpty(mustBeArray(response.data)) && Number(page) !== 1) {
                this.setState(
                  {
                    payScheduleGrid: { ...this.state.payScheduleGrid, page: 1 },
                  },
                  () => {
                    this.listScrollingGridPaySchedule({
                      options: {},
                      pageLoad: true,
                    });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.payScheduleList = getConditionalResponse({
                      condition: pageLoad,
                      resp1: data,
                      resp2: prevState.payScheduleList.concat(data),
                    });

                    prevState.payScheduleHasMoreData = getConditionalResponse({
                      condition:
                        response.total > prevState.payScheduleList.length,
                      resp1: true,
                      resp2: false,
                    });

                    prevState.payScheduleTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        "businessUnitPayScheduleGeneralGrid"
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listScrollingPaySchedule = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.payScheduleHasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.paySchedulePagination =
            prevState.payScheduleTotal ===
            mustBeArray(prevState.payScheduleList).length
              ? prevState.paySchedulePagination
              : Number(prevState.paySchedulePagination) + 1;
        }
        prevState.payScheduleListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.payScheduleSortInfo,
            sortingType: this.state.payScheduleSortingType,
          }),

          q: {
            businessUnitID: getMatchParams({
              match: this.props.match,
              field: "businessunitid",
            }),
          },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.paySchedulePagination) *
              Number(this.state.paySchedulePerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.paySchedulePagination,
            per_page: this.state.paySchedulePerPage,
          };
        }

        this.props
          .getPayschedule({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ payScheduleListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.paySchedulePagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ paySchedulePagination: 1 }, () => {
                  this.listScrollingPaySchedule({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    payScheduleList: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                          ...this.state.payScheduleList,
                          ...mustBeArray(resp.data),
                        ],

                    payScheduleTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      payScheduleHasMoreData:
                        this.state.payScheduleList.length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("businessUnitPayScheduleGeneralTable");
          }
        });
      }
    );
  };
  listPaySchedules = ({ options }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.paySchedulePagination = getDefaultPage({
            page: options.page,
          });
          prevState.payScheduleSortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.payScheduleListLoading = true;
        }
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.paySchedulePagination,
          per_page: getDefaultPerPage({
            perPage: this.state.paySchedulePerPage,
          }),
          ...getCurrentTableSortInfo({
            sortInfo: this.state.payScheduleSortInfo,
            sortingType: this.state.payScheduleSortingType,
          }),

          q: {
            businessUnitID: getMatchParams({
              match: this.props.match,
              field: "businessunitid",
            }),
          },
        };

        this.props
          .getPayschedule({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ payScheduleListLoading: false });

            if (resp.status) {
              if (
                _.isEmpty(mustBeArray(resp.data)) &&
                Number(this.state.paySchedulePagination) !== 1
              ) {
                this.setState({ paySchedulePagination: 1 }, () => {
                  this.listPaySchedules({ options: {} });
                });
              } else {
                this.setState({
                  payScheduleList: mustBeArray(resp.data),
                  payScheduleTotal: resp.total,
                });
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("businessUnitPayScheduleGeneralTable");
        });
      }
    );
  };
  payScheduleFetchMoreData = ({ isPaginated = false }) => {
    this.setState({ payScheduleListLoading: true });

    const { payScheduleGrid } = this.state;
    const { page } = payScheduleGrid;
    const per_page = this.getPayScheduleGridPageSize();
    let sortingParams = this.getPayScheduleGridSortInfo();

    this.props
      .getPayschedule({
        options: {
          page,
          per_page,
          q: {
            businessUnitID: getMatchParams({
              match: this.props.match,
              field: "businessunitid",
            }),
          },
          ...sortingParams,
        },
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { payScheduleGrid: { ...payScheduleGrid, page: 1 } },
              () => {
                this.payScheduleFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.payScheduleList = data;
                } else {
                  prevState.payScheduleList =
                    prevState.payScheduleList.concat(data);
                  prevState.payScheduleHasMoreData = getConditionalResponse({
                    condition:
                      data.length !== 0 &&
                      prevState.payScheduleList.length <
                        this.state.payScheduleTotal,
                    resp1: true,
                    resp2: false,
                  });
                  prevState.payScheduleGrid = getConditionalResponse({
                    condition:
                      data.length !== 0 &&
                      prevState.payScheduleList.length <
                        this.state.payScheduleTotal,
                    resp1: {
                      ...prevState.payScheduleGrid,
                      page: prevState.payScheduleGrid.page + 1,
                    },
                    resp2: prevState.payScheduleGrid,
                  });
                }

                prevState.payScheduleTotal = response.total;
                prevState.payScheduleListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(
                    "businessUnitPayScheduleGeneralGrid"
                  );
                });
              }
            );
          }
        }
      });
  };
  listPayScheduleGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.payScheduleGrid.page = page;
        prevState.payScheduleListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.payScheduleGrid?.page,
          per_page: this.getPayScheduleGridPageSize(),

          ...this.getPayScheduleGridSortInfo(),
          q: {
            businessUnitID: getMatchParams({
              match: this.props.match,
              field: "businessunitid",
            }),
          },
        };

        this.props
          .getPayschedule({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ payScheduleListLoading: false });

            if (resp.status) {
              this.setState({
                payScheduleList: mustBeArray(resp.data),
                payScheduleTotal: resp.total,
              });
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("businessUnitPayScheduleGeneralGrid");
        });
      }
    );
  };

  getPayScheduleGridSortInfo = () => {
    if (
      this.state.payScheduleGrid.sortInfo?.field &&
      this.state.payScheduleGrid.sortInfo?.order &&
      this.state.payScheduleSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.payScheduleGrid.sortInfo.field]:
            this.state.payScheduleGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.payScheduleGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.payScheduleGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  handlePayScheduleGridChange = ({ gridView }) => {
    if (this.state.payScheduleGridView === gridView) {
      return false;
    }

    this.setState(
      {
        payScheduleGridView: gridView,
        payScheduleList: [],
        payScheduleListLoading: true,
      },
      () => {
        this.updatePersonalization("businessUnitPayScheduleListContainer");
        if (!gridView) {
          if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
            this.listScrollingPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.listPaySchedules({ options: {} });
          }
        } else {
          if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.payScheduleFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };
  payScheduleUpdatePageSize = (value) => {
    if (this.state.payScheduleGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.payScheduleGrid.row = Number(row);
          prevState.payScheduleGrid.col = Number(col);
          prevState.payScheduleGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.payScheduleFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ paySchedulePerPage: Number(value), current: 1 }, () => {
        if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
          this.listScrollingPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.listPaySchedules({ options: {} });
        }
      });
    }
  };
  payScheduleUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.payScheduleSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.payScheduleGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.payScheduleGrid = {
            ...prevState.payScheduleGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.payScheduleFetchMoreData({ isPaginated: true });
        }
      }
    );
  };
  handlePayScheduleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { id, businessUnitID, companyId } = data;
    switch (action) {
      case "view":
        this.props.router.navigate(
          getPayScheduleDetailRoute({
            id,
            companyId,
            businessUnitId: businessUnitID,
          })
        );
        break;
      case "edit":
        break;
      default:
        break;
    }
  };
  payScheduleUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.payScheduleGrid.sortInfo[field] === value) {
          prevState.payScheduleGrid.sortInfo[field] = "";
        } else {
          prevState.payScheduleGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.payScheduleFetchMoreData({ isPaginated: true });
      }
    );
  };
  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  getPayScheduleGridPageSize = () => {
    const { row, col } = this.state.payScheduleGrid;

    return this.state.paySchedulePaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updatePinnedComponent = () => {
    this.setState(
      {
        payScheduleIsPinned:
          this.state.payScheduleIsPinned === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization("businessUnitPayScheduleListContainer");
      }
    );
  };
  updatePayScheduleGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.payScheduleGrid.sortInfo[field] === value) {
          prevState.payScheduleGrid.sortInfo[field] = "";
        } else {
          prevState.payScheduleGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.payScheduleFetchMoreData({ isPaginated: true });
      }
    );
  };
  getPayScheduleQueryOptions = () => {
    let tempOptions = {
      q: {
        businessUnitID: getMatchParams({
          match: this.props.match,
          field: "businessunitid",
        }),
      },
    };

    if (this.state.payScheduleGridView) {
      const { payScheduleGrid } = this.state;
      const { page } = payScheduleGrid;
      const per_page = this.getPayScheduleGridPageSize();
      let sortingParams = this.getPayScheduleGridSortInfo();
      tempOptions = {
        ...tempOptions,
        page,
        per_page,
        ...sortingParams,
      };
    } else {
      tempOptions = {
        ...tempOptions,
        page: this.state.paySchedulePagination,
        per_page: this.state.paySchedulePerPage,
        ...getCurrentTableSortInfo({
          sortInfo: this.state.payScheduleSortInfo,
          sortingType: this.state.payScheduleSortingType,
        }),
      };
    }
    return tempOptions;
  };

  handleUserGridChange = ({ gridView }) => {
    if (this.state.userGridView === gridView) {
      return false;
    }

    this.setState(
      { userGridView: gridView, userList: [], userListLoading: true },
      () => {
        this.updatePersonalization("businessUnitUserListContainer");
        if (!gridView) {
          this.listBusinessUnitUsers({ options: {}, pageLoad: true });
        } else {
          this.listGridBusinessUnitUsers({ pageLoad: true, options: {} });
        }
      }
    );
  };
  userUpdatePageSize = (value) => {
    if (this.state.userGridView) {
      const rowAndCol = getGridRowAndColumn({ value });
      this.setState(
        (prevState) => {
          prevState.userGrid = { ...prevState.userGrid, ...rowAndCol, page: 1 };
          return prevState;
        },
        () => {
          this.listGridBusinessUnitUsers({ options: {} });
        }
      );
    } else {
      this.setState({ userPerPage: Number(value), current: 1 }, () => {
        this.listBusinessUnitUsers({ options: {} });
      });
    }
  };
  userUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.userSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.userGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.userGrid = {
            ...prevState.userGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        this.listGridBusinessUnitUsers({ options: {} });
      }
    );
  };

  handleUserEdit = (record) => {
    const { match } = this.props;
    const id = getMatchParams({
      match,
      field: "businessunitid",
    });
    this.props.updateHeader({
      header: {
        module: "Back to Bussiness Unit Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserEdit,
        entity: "user",
        returnUrl: getBusinessUnitDetailRoute({
          id,
          companyId: this.state.companyid,
        }),
      },
    });
    this.props.router.navigate(`/user/company/${record.id}/edit`);
  };

  handleUserCreate = () => {
    const { match } = this.props;
    const id = getMatchParams({
      match,
      field: "businessunitid",
    });
    this.props.updateHeader({
      header: {
        module: "Back to Business Unit Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserCreate,
        entity: "user",
        returnUrl: getBusinessUnitDetailRoute({
          id,
          companyId: this.state.companyid,
        }),
      },
    });
    this.props.router.navigate(`/company/${this.state.companyid}/user/create`);
  };

  changeUserStatus = (user) => {
    user.enabled = !user.enabled;

    this.props
      .handleCompanyUserActions({
        id: user.id,
        data: user,
        action: "status",
      })

      .then((resp) => {
        if (resp.status) {
          message.success(
            `User ${user.enabled ? "enabled" : "disabled"} successfully`
          );
          this.listGridBusinessUnitUsers({ options: {}, pageLoad: true });
        } else {
          errorHandler({
            hasValidationErrors: false,
            response: resp,
            oldModel: true,
          });
        }
      });
  };

  updateAuthentication = (user) => {
    this.props.handleCompanyUserActions({
      id: user.id,
      data: {
        mfaEnable: user.mfaEnable,
        requestType: "mfa",
        deviceEnrolmentEnable: user.deviceEnrolmentEnable,
      },
      action: "updatemultifactorauthentication",
    });
    message.info(
      `Your request to ${
        !user.mfaEnable ? "enable" : "disable"
      } multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
    );
  };

  sendReminder = ({ user }) => {
    this.props
      .handleCompanyUserActions({
        id: user.id,
        data: user,
        action: "sendreminder",
      })

      .then((resp) => {
        if (resp.status) {
          message.success(`Reminder sent successfully`);
          this.listGridBusinessUnitUsers({ options: {}, pageLoad: true });
        } else {
          errorHandler({ response: resp });
        }
      });
  };

  handleUserAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/user/company/${data && data.id}`);
        break;
      case "enableDisable":
        this.changeUserStatus(data);
        break;
      case "enableDisableMfa":
        this.updateAuthentication(data);
        break;
      case "edit":
        this.handleUserEdit(data);
        break;
      case "sendReminder":
        this.sendReminder({ user: data });
        break;
      case "optionalDeviceEnrolment":
      case "disableDeviceEnrolment":
      case "mandatoryDeviceEnrolment":
        message.info(`Your request to ${getDeviceEnrolmentActionText({
          action,
        })} device enrolment for the user has been submitted.
        Please refresh the screen after two minutes.`);

        this.props.handleCompanyUserActions({
          action,
          data: {
            mfaEnable: data.mfaEnable,
            requestType: "deviceEnrolment",
            deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
          },
          id: data.id,
        });
        break;
      default:
        break;
    }
  };

  handleUsersMFA = ({ action }) => {
    message.info(
      `Your request to ${
        action === "enableAll" ? "enable" : "disable"
      } multi-factor authentication for all users has been submitted. Please refresh the screen after two minutes.`
    );

    this.props.handleCompanyUserActions({
      action,
      id: this.state.companyid,
      data: {
        mfaEnable: action === "enableAll",
        requestType: "mfa",
        deviceEnrolmentEnable: -1,
      },
    });
  };

  handleDeviceEnrolment = ({ action }) => {
    message.info(`Your request to ${getAllUsersDeviceEnrolmentActionText({
      action,
    })}
    device enrolment for all users has been submitted. Please refresh the screen after two minutes.`);

    this.props.handleCompanyUserActions({
      action,
      id: this.state.companyid,
      data: {
        mfaEnable: true,
        requestType: "deviceEnrolment",
        deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
      },
    });
  };

  render() {
    const {
      pageLoading,
      activeKey,
      isPayScheduleListContainerPinned,
      payScheduleListLoading,
      payScheduleGridable,
      paySchedulePagination,
      payScheduleGrid,
      paySchedulePerPage,
      payScheduleSortInfo,
      payScheduleSortingType,
      payScheduleFieldOptions,
      payScheduleGridView,
      payScheduleHasMoreData,
      payScheduleList,
      payScheduleTotal,
      paySchedulePaginationType,
      userIsPinned,
      userListLoading,
      userGridable,
      userPagination,
      userGrid,
      userPerPage,
      userSortInfo,
      userSortingType,
      userFieldOptions,
      userGridView,
      userHasMoreData,
      userList,
      userTotal,
      userPaginationType,
      enableMFALoading,
      disableMFALoading,
      businessUnitId,
    } = this.state;

    const { pageClaims, dashboardView, targetEndpoint, member } = this.props;

    const businessUnitDetailMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === BUSINESS_UNIT_DETAIL_MULTI_TAB_CONTAINER
    );
    const businessUnitBasicDetailTabContainer = _.find(
      mustBeArray(businessUnitDetailMultiTabContainer?.components),
      (o) => o.name === "businessUnitBasicDetailTabContainer"
    );
    const businessUnitBasicDetailChildTabContainer = _.find(
      mustBeArray(businessUnitBasicDetailTabContainer?.components),
      (o) => o.name === "businessUnitBasicDetailChildTabContainer"
    );
    const businessUnitServiceDetailChildTabContainer = _.find(
      mustBeArray(businessUnitBasicDetailTabContainer?.components),
      (o) => o.name === "businessUnitServiceDetailChildTabContainer"
    );
    const businessUnitPayrollManagersChildTabContainer = _.find(
      mustBeArray(businessUnitBasicDetailTabContainer?.components),
      (o) => o.name === "businessUnitPayrollManagersChildTabContainer"
    );
    const businessUnitPayScheduleTabContainer = _.find(
      mustBeArray(businessUnitDetailMultiTabContainer?.components),
      (o) => o.name === "businessUnitPayScheduleTabContainer"
    );
    const businessUnitPayScheduleChildTabContainer = _.find(
      mustBeArray(businessUnitPayScheduleTabContainer?.components),
      (o) => o.name === "businessUnitPayScheduleChildTabContainer"
    );
    const businessUnitPayrollProviderTabContainer = _.find(
      mustBeArray(businessUnitDetailMultiTabContainer?.components),
      (o) => o.name === "businessUnitPayrollProviderTabContainer"
    );
    const businessUnitPayrollChildTabContainer = _.find(
      mustBeArray(businessUnitPayrollProviderTabContainer?.components),
      (o) => o.name === "businessUnitPayrollProviderChildTabContainer"
    );
    const payScheduleCardGrid = getCurrentCardGrid({
      grid: this.state.payScheduleGrid,
    });

    // Users Tab
    const businessUnitUserTabContainer = _.find(
      mustBeArray(businessUnitDetailMultiTabContainer?.components),
      (o) => o.name === "businessUnitUserTabContainer"
    );
    const businessUnitUserChildTabContainer = _.find(
      mustBeArray(businessUnitUserTabContainer?.components),
      (o) => o.name === "businessUnitUserChildTabContainer"
    );
    const businessUnitIntegrationSettingChildTabContainer = _.find(
      mustBeArray(businessUnitBasicDetailTabContainer?.components),
      (o) => o.name === "businessUnitIntegrationSettingChildTabContainer"
    );
    const businessUnitIntegrationSettingContainer = _.find(
      mustBeArray(businessUnitIntegrationSettingChildTabContainer?.components),
      (o) => o.name === "businessUnitIntegrationSettingContainer"
    );
    const userCardGrid = getCurrentCardGrid({ grid: this.state.userGrid });

    // Target endpoints
    let payScheduleTargetEndpoint = APIHandler.constructEndpoint({
      endpoint: `PAYSCHEDULE`,
    });
    payScheduleTargetEndpoint = requestUrlBuilder(
      payScheduleTargetEndpoint,
      this.getPayScheduleQueryOptions()
    );

    let businessUnitUserTargetEndpoint = APIHandler.constructEndpoint({
      endpoint: `COMPANY_USERS_URL${getConditionalResponse({
        condition: [userRoles.CompanyArea].includes(getUserArea()),
        resp1: "_COMPANYUSER",
        resp2: "",
      })}`,
    });

    businessUnitUserTargetEndpoint = requestUrlBuilder(
      businessUnitUserTargetEndpoint,
      this.getPayScheduleQueryOptions()
    );

    // Custom User Actions

    const enableMfaClaims = findAuthorizationClaims({
      claims: businessUnitUserChildTabContainer?.components,
      name: "businessUnitUserEnableMfaAllButton",
    });
    const disableMfaClaims = findAuthorizationClaims({
      claims: businessUnitUserChildTabContainer?.components,
      name: "businessUnitUserDisableMfaAllButton",
    });
    const mandatoryDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: businessUnitUserChildTabContainer?.components,
      name: "businessUnitUserMandatoryDeviceEnrolmentAllButton",
    });
    const optionalDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: businessUnitUserChildTabContainer?.components,
      name: "businessUnitUserOptionalDeviceEnrolmentAllButton",
    });
    const disableDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: businessUnitUserChildTabContainer?.components,
      name: "businessUnitUserDisableDeviceEnrolmentAllButton",
    });

    const CustomUserActions = () => {
      return _.filter(
        [
          disableMfaClaims,
          enableMfaClaims,
          mandatoryDeviceEnrolmentClaims,
          optionalDeviceEnrolmentClaims,
          disableDeviceEnrolmentClaims,
        ],
        (o) => {
          return isComponentVisible({ claim: o });
        }
      ) && isUserSystemAdmin({ member }) ? (
        <UserAuthenticationUpdate
          handleMFA={this.handleUsersMFA}
          handleDeviceEnrolment={this.handleDeviceEnrolment}
          enableMfaClaims={enableMfaClaims}
          disableMfaClaims={disableMfaClaims}
          mandatoryDeviceEnrolmentClaims={mandatoryDeviceEnrolmentClaims}
          optionalDeviceEnrolmentClaims={optionalDeviceEnrolmentClaims}
          disableDeviceEnrolmentClaims={disableDeviceEnrolmentClaims}
          showMandatoryDeviceEnrolmentOption={true}
          showOptionalDeviceEnrolmentOption={true}
          showDisableDeviceEnrolmentOption={true}
        />
      ) : (
        <React.Fragment />
      );
    };

    return pageLoading ? (
      <Spin />
    ) : dashboardView ? (
      <ListContainer
        name="businessUnitPayScheduleListContainer"
        claims={businessUnitPayScheduleChildTabContainer}
        isPinned={isPayScheduleListContainerPinned}
        updatePinnedComponent={this.updatePinnedComponent}
        gridable={payScheduleGridable}
        gridView={payScheduleGridView}
        grid={payScheduleGrid}
        per_page={paySchedulePerPage}
        GeneralTablePaginatedComponent={PayScheduleList}
        GeneralTableScrollableComponent={ScrollablePayScheduleTable}
        generalTableComponentName={"businessUnitPayScheduleGeneralTable"}
        gridComponentName={"businessUnitPayScheduleGeneralGrid"}
        createLinkButtonName={"businessUnitPayScheduleAddButton"}
        handleAddAction={this.addPayschedule}
        handleGridChange={this.handlePayScheduleGridChange}
        fieldOptions={payScheduleFieldOptions}
        updateGridField={this.updatePayScheduleGridField}
        sortingType={payScheduleSortingType}
        paginationType={paySchedulePaginationType}
        list={payScheduleList}
        listLoading={payScheduleListLoading}
        listGrid={this.listPayScheduleGrid}
        listScrollingGrid={this.listScrollingGridPaySchedule}
        hasMoreData={payScheduleHasMoreData}
        gridActionComponentName={"businessUnitPayScheduleViewDetailLink"}
        moduleType={"paySchedule"}
        handleGridAction={this.handlePayScheduleAction}
        listScrollingGeneralTable={this.listScrollingPaySchedule}
        total={payScheduleTotal}
        current={paySchedulePagination}
        sortInfo={payScheduleSortInfo}
        updatePageSize={this.payScheduleUpdatePageSize}
        listGeneralTable={this.getPayschedule}
        tableActionComponentName={"businessUnitPayScheduleViewDetailLink"}
        gridPageSize={this.getPayScheduleGridPageSize()}
        cardGrid={payScheduleCardGrid}
        showAddAndGrid={true}
        view="businessunit"
        businessunit={this.props.businessunit}
        addModuleName="Pay Schedule"
        DisplayCardComponent={PayScheduleDisplayCard}
        dashboardView={true}
        componentTitle={this.props.componentTitle}
        filterApplied={this.props.filterApplied}
        targetEndpoint={targetEndpoint}
      />
    ) : (
      <div className="module-detail">
        <MultiTabContainer
          name={BUSINESS_UNIT_DETAIL_MULTI_TAB_CONTAINER}
          claims={pageClaims}
          activeKey={activeKey}
          handleTabChange={this.handleTabChange}
        >
          {isComponentVisible({
            claim: businessUnitBasicDetailTabContainer,
          }) && (
            <TabPane tab="Overview" key={"1"}>
              <Row gutter={DEFAULT_GUTTER}>
                <Col lg={8} md={12} sm={24} className="mb-6">
                  <ChildTabContainer
                    name="businessUnitBasicDetailChildTabContainer"
                    claims={businessUnitBasicDetailTabContainer}
                  >
                    <GeneralContainer
                      name={"businessUnitGeneralDetailContainer"}
                      claims={businessUnitBasicDetailChildTabContainer}
                      md={24}
                      sm={24}
                      lg={24}
                    >
                      <OrganizationOverviewCard
                        data={this.props.businessunit}
                        handleEdit={this.onActionClicked}
                        claimComponent="businessUnitEdit"
                        hasNoComponents={false}
                      />
                    </GeneralContainer>
                  </ChildTabContainer>
                </Col>
                <Col lg={8} md={12} sm={24} className="mb-6">
                  <ChildTabContainer
                    name="businessUnitPayrollManagersChildTabContainer"
                    claims={businessUnitBasicDetailTabContainer}
                  >
                    <GeneralContainer
                      name={"businessUnitPayrollManagersListContainer"}
                      claims={businessUnitPayrollManagersChildTabContainer}
                      md={24}
                      sm={24}
                      lg={24}
                    >
                      <AssignedPayrollManagersCardView
                        organization={this.props.businessunit}
                        claimComponent="businessUnitPayrollManagersEdit"
                        hasNoComponents={false}
                        handleEdit={this.handlePayrollManagersEdit}
                        assignedPayrollManagers={mustBeArray(
                          this.props.businessunit?.assignedUsers
                        )}
                      />
                    </GeneralContainer>
                  </ChildTabContainer>
                  <ChildTabContainer
                    name="businessUnitIntegrationSettingChildTabContainer"
                    claims={businessUnitBasicDetailTabContainer}
                  >
                    <GeneralContainer
                      name={"businessUnitIntegrationSettingContainer"}
                      claims={businessUnitIntegrationSettingChildTabContainer}
                      md={24}
                      sm={24}
                      lg={24}
                      ignoreStatus
                    >
                      <IntegrationSettings
                        claims={businessUnitIntegrationSettingContainer}
                        businessUnitId={businessUnitId}
                      />
                    </GeneralContainer>
                  </ChildTabContainer>
                </Col>
                <Col lg={8} md={12} sm={24} className="mb-6">
                  <ChildTabContainer
                    name="businessUnitServiceDetailChildTabContainer"
                    claims={businessUnitBasicDetailTabContainer}
                  >
                    <GeneralContainer
                      name={"businessUnitServiceDetailContainer"}
                      claims={businessUnitServiceDetailChildTabContainer}
                      md={24}
                      sm={24}
                      lg={24}
                    >
                      <ServiceDetails
                        organization={this.props.businessunit}
                        claimComponent="businessUnitServiceDetailEdit"
                        hasNoComponents={false}
                        handleEdit={this.handleServiceDetailEdit}
                        match={this.props.match}
                      />
                    </GeneralContainer>
                  </ChildTabContainer>
                </Col>
              </Row>
            </TabPane>
          )}
          {isComponentVisible({
            claim: businessUnitPayrollChildTabContainer,
          }) && (
            <TabPane key={"2"} tab="Payroll Provider">
              <Row gutter={DEFAULT_GUTTER}>
                <Col lg={6} md={12} sm={24}>
                  <ChildTabContainer
                    name="businessUnitPayrollProviderDetailContainer"
                    claims={businessUnitPayrollChildTabContainer}
                  >
                    <GeneralContainer
                      name="businessUnitPayrollProviderDetailContainer"
                      claims={this.props.claims}
                      key={"a"}
                      lg={24}
                      md={24}
                      sm={24}
                      hasNoComponents={true}
                    >
                      <BasicDetails
                        organization={this.state.payrollProvider}
                        claims={this.props.claims}
                        entity="payrollprovider"
                        showCompleteDetail={true}
                      />
                    </GeneralContainer>
                  </ChildTabContainer>
                </Col>
              </Row>
            </TabPane>
          )}
          {isComponentVisible({
            claim: businessUnitPayScheduleChildTabContainer,
          }) && (
            <TabPane
              tab={<TabHeader title="Pay Schedules" total={payScheduleTotal} />}
              key={"3"}
            >
              <ListContainer
                name="businessUnitPayScheduleListContainer"
                claims={businessUnitPayScheduleChildTabContainer}
                isPinned={isPayScheduleListContainerPinned}
                updatePinnedComponent={this.updatePinnedComponent}
                gridable={payScheduleGridable}
                gridView={payScheduleGridView}
                grid={payScheduleGrid}
                per_page={paySchedulePerPage}
                GeneralTablePaginatedComponent={PayScheduleList}
                GeneralTableScrollableComponent={ScrollablePayScheduleTable}
                generalTableComponentName={
                  "businessUnitPayScheduleGeneralTable"
                }
                gridComponentName={"businessUnitPayScheduleGeneralGrid"}
                createLinkButtonName={"businessUnitPayScheduleAddButton"}
                handleAddAction={this.addPayschedule}
                handleGridChange={this.handlePayScheduleGridChange}
                fieldOptions={payScheduleFieldOptions}
                updateGridField={this.updatePayScheduleGridField}
                sortingType={payScheduleSortingType}
                paginationType={paySchedulePaginationType}
                list={payScheduleList}
                listLoading={payScheduleListLoading}
                listGrid={this.listPayScheduleGrid}
                listScrollingGrid={this.listScrollingGridPaySchedule}
                hasMoreData={payScheduleHasMoreData}
                gridActionComponentName={
                  "businessUnitPayScheduleViewDetailLink"
                }
                moduleType={"paySchedule"}
                handleGridAction={this.handlePayScheduleAction}
                listScrollingGeneralTable={this.listScrollingPaySchedule}
                total={payScheduleTotal}
                current={paySchedulePagination}
                sortInfo={payScheduleSortInfo}
                updatePageSize={this.payScheduleUpdatePageSize}
                listGeneralTable={this.getPayschedule}
                tableActionComponentName={
                  "businessUnitPayScheduleViewDetailLink"
                }
                gridPageSize={this.getPayScheduleGridPageSize()}
                cardGrid={payScheduleCardGrid}
                showAddAndGrid={true}
                view="businessunit"
                businessunit={this.props.businessunit}
                addModuleName="Pay Schedule"
                DisplayCardComponent={PayScheduleDisplayCard}
                filterApplied={`Filtered by ${conditionalParameter({
                  data: this.props.businessunit,
                  field: "name",
                })}`}
                targetEndpoint={payScheduleTargetEndpoint}
                hasNoDuplicateEntity={true}
                componentTitle="Pay Schedules"
              />
            </TabPane>
          )}
          {isComponentVisible({ claim: businessUnitUserChildTabContainer }) && (
            <TabPane
              key={"4"}
              tab={<TabHeader title="Users" total={userTotal} />}
            >
              <ChildTabContainer
                name="businessUnitUserChildTabContainer"
                claims={businessUnitUserTabContainer}
              >
                <ListContainer
                  name="businessUnitUserListContainer"
                  claims={businessUnitUserChildTabContainer}
                  isPinned={userIsPinned}
                  updatePinnedComponent={this.updatePinnedComponent}
                  gridable={userGridable}
                  gridView={userGridView}
                  grid={userGrid}
                  per_page={userPerPage}
                  GeneralTablePaginatedComponent={CompanyUserList}
                  GeneralTableScrollableComponent={ScrollableUserList}
                  generalTableComponentName={"businessUnitUserGeneralTable"}
                  gridComponentName={"businessUnitUserGeneralGrid"}
                  createLinkButtonName={"businessUnitUserAddButton"}
                  handleAddAction={this.handleUserCreate}
                  handleGridChange={this.handleUserGridChange}
                  fieldOptions={userFieldOptions}
                  updateGridField={this.updateUserGridField}
                  sortingType={userSortingType}
                  paginationType={userPaginationType}
                  list={userList}
                  listLoading={userListLoading}
                  listGrid={(page) =>
                    this.listGridBusinessUnitUsers({ options: { page } })
                  }
                  gridActionComponentName={"businessUnitUserViewDetailLink"}
                  moduleType={"user"}
                  handleGridAction={this.handleUserAction}
                  listScrollingGeneralTable={this.listScrollingUser}
                  total={userTotal}
                  current={userPagination}
                  sortInfo={userSortInfo}
                  updatePageSize={this.userUpdatePageSize}
                  listGeneralTable={({ options }) =>
                    this.listBusinessUnitUsers({
                      options: {
                        ...options,
                      },
                    })
                  }
                  tableActionComponentName={"businessUnitUserViewDetailLink"}
                  gridPageSize={getCurrentGridPageSize({
                    grid: this.state.userGrid,
                    paginationType: userPagination,
                  })}
                  cardGrid={userCardGrid}
                  reminderComponent="businessUnitUserSendReminderButton"
                  editComponent="businessUnitUserEditIconButton"
                  toggleComponent="businessUnitUserStatusSwitchButton"
                  pinnedComponentName="userIsPinned"
                  showAddAndGrid={true}
                  addModuleName="User"
                  userType="company"
                  handleEdit={this.handleUserEdit}
                  DisplayCardComponent={UserDisplayCard}
                  handleCompanyUserActions={this.props.handleCompanyUserActions}
                  member={member}
                  CustomActions={<CustomUserActions />}
                  enableMFALoading={enableMFALoading}
                  disableMFALoading={disableMFALoading}
                  enableDisableComponentNameGrid="businessUnitUserStatusSwitchButton"
                  enableDisableMFAComponentNameGrid="businessUnitUserEnableDisableMfa"
                  enableDisableMFAComponentName="businessUnitUserEnableDisableMfa"
                  targetEndpoint={businessUnitUserTargetEndpoint}
                  hasNoDuplicateEntity={false}
                  componentTitle="Business Unit Users"
                  filterApplied={`Filtered by ${this.props.businessunit?.name}`}
                />
              </ChildTabContainer>
            </TabPane>
          )}
        </MultiTabContainer>
        <AssignPayrollManagerModal
          visible={this.state.managePayrollManagersModalVisible}
          listPSPUsers={this.props.listPSPUsers}
          payrollProviderId={this.props.businessunit?.payrollServiceProviderId}
          handleClose={this.handleManagePayrollManagersModalClose}
          activeBusinessUnit={this.props.businessunit}
          assignedPayrollManagers={this.state.assignedPayrollManagers}
          handleAssign={this.handleAssign}
          handleChangePrimary={this.handleChangePrimary}
          handleSaveAssignedPayrollManager={
            this.handleSaveAssignedPayrollManager
          }
          saveLoading={this.state.saveLoading}
          member={this.props.member}
        />
      </div>
    );
  }
}

export default withRouter(BusinessUnitDetail);
