//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Radio, Row } from "antd";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { mustBeArray } from "../../../libs/utilities";

const FormItem = Form.Item;

type State = any;

class AssignRoleCompanyUser extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  render() {
    const { getFieldDecorator, handleRoleChange, userRolesList } = this.props;
    return (
      <React.Fragment>
        <Row gutter={DEFAULT_GUTTER}>
          <FormItem label="Role:">
            {getFieldDecorator("roleId", {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <React.Fragment>
                <div className="flex justify-between">
                  {mustBeArray(userRolesList).map((role: any, index: any) => {
                    return (
                      <Radio
                        onChange={(e: any) =>
                          handleRoleChange({ value: e, record: role })
                        }
                        name={role.name}
                        checked={role.isSelected}
                        key={index}
                      >
                        {role.name}
                      </Radio>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </FormItem>
        </Row>
      </React.Fragment>
    );
  }
}

export default AssignRoleCompanyUser;
