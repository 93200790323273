import styled from 'styled-components';

import { Badge } from '@xemplo/badge';
import { BodyXSmallMedium } from '@xemplo/style-constants';

export const AmendmentBadge = styled(Badge)`
  width: max-content;
  padding-inline: 8px;
  ${BodyXSmallMedium};
`;

export const ApprovedStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
