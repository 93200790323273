import { memberReducer } from "@xemplo/gp-member-store";
import { combineReducers } from "@reduxjs/toolkit";

import { generalAmendment, list, lookups, pageHeader } from "../slices";
import billingServiceProvider from "./billingServiceProvider";
import businessUnit from "./businessunit";
import callback from "./callback";
import company from "./company";
import dashboard from "./dashboard";
import email from "./email";
import payrollProvider from "./payrollprovider";
import payrun from "./payrun";
import payschedule from "./payschedule";
import ui from "./ui";

const rehydrated = (state = false, action: any) => {
  if (action && action.type === "persist/REHYDRATE") return true;
  return state;
};

const rootReducer = combineReducers({
  rehydrated,
  member: memberReducer,
  company,
  businessUnit,
  payrollProvider,
  payschedule,
  payrun,
  email,
  ui,
  billingServiceProvider,
  callback,
  dashboard,
  generalAmendment,
  list,
  lookups,
  pageHeader,
});

export default rootReducer;
