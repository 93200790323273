import requireAuth from "../../Common/requireAuth";
import { SCOPES } from "../authorizationClaims";
import PayrunContainer from "../../Organization/containers/Payrun";
import PayrunInvoice from "../../Organization/pages/PayRun/Supervisor/invoice";

export const InvoiceRoutesWithSidebar = [
  {
    path: "invoices",
    scope: SCOPES.INVOICE,
    index: "invoices",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun/Supervisor/invoice",
    element: (
      <PayrunContainer
        scope={SCOPES.INVOICE}
        Layout={requireAuth(PayrunInvoice)}
      />
    ),
  },
];
