//@ts-nocheck
import React, { Component } from "react";
import axios from "axios";
import {
  mustBeArray,
  errorDisplay,
  findAuthorizationClaims,
  delay,
  updateAuthorizationClaims,
  removeUndefined,
  setPersonalizationData,
  getPersonalizationData,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  checkClaimsAuthorization,
  filteredByLabel,
  requestUrlBuilder,
  displayNoRecordsInList,
  isComponentVisible,
} from "../../../libs/utilities";
import _ from "lodash";
import BillinServiceProviderList from "../../components/bsp/list";
import {
  PAGINATION_NOT_REQUIRED,
  PAGINATION_SCROLLING,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  GRID_VIEW_VALUE,
  TABLE_VIEW_VALUE,
  STRING_PERSONALIZATION,
  INTEGER_PERSONALIZATION,
} from "../../../constants/defaultClaims";
import ContentEmpty from "../../components/common/contentListEmpty";
import NoRecord from "../../../Common/NoRecord";
import {
  BILLING_PROVIDER_GRID_OPTIONS,
  BILLING_PROVIDER_FIELD_OPTIONS,
} from "../../../constants/sortingOptions";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import GridTableViewOption from "../../components/common/gridTableViewOption";
import BillingServiceProviderDisplayCard from "../../components/bsp/bspDisplayCard";
import ScrollableBillingServiceProviderTable from "../../components/bsp/ScrollableBillingServiceProviderTable";
import CountryLookup from "../../components/lookup/country";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import APIHandler from "../../../constants/apiUrl";
import {
  getDefaultPage,
  getDefaultPerPage,
  validateUsersAccessRights,
} from "../../../libs";
import {
  BILLING_PROVIDER_COUNTRY_DROPDOWN_FILTER,
  BILLING_PROVIDER_CREATE_SIMPLE_BUTTON,
  BILLING_PROVIDER_FILTER_CONTAINER,
  BILLING_PROVIDER_GENERAL_GRID,
  BILLING_PROVIDER_GENERAL_TABLE,
  BILLING_PROVIDER_LIST_CONTAINER,
  BILLING_PROVIDER_NAME_TEXT_BOX_FILTER,
  BILLING_PROVIDER_RESET_FILTER,
  BILLING_PROVIDER_SEARCH_BUTTON,
  BILLING_PROVIDER_VIEW_DETAIL_LINK,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { If } from "../../../Common/ui";
import { withRouter } from "../../../hooks";

type State = any;

class BillingServiceProviderIndex extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: BILLING_PROVIDER_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: BILLING_PROVIDER_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
      filterLoading: false,
      countryList: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, pageClaims } = this.props;
    //validate if user has access to page
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    if (dashboardView) {
      const bspPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      if (!bspPersonalizations || !bspPersonalizations.gridView) {
        this.setState({ gridView: false });
      } else {
        this.setState({ gridView: true });
      }
      this.getBillingServiceProvidersList();
    } else {
      this.loadBillingServiceProviderComponent();
    }
  }
  getBillingServiceProvidersList = () => {
    this.props
      .billingServiceProviderHandler({
        options: {},
        action: "list",
        cancelToken: this.signal.token,
        targetEndpoint: this.props.targetEndpoint,
      })
      .then((resp) => {
        this.setState({ filterLoading: false, listLoading: false });
        if (resp.status) {
          this.setState({ list: mustBeArray(resp.data), total: resp.total });
        }
      });
  };

  loadBillingServiceProviderComponent = () => {
    const { pageClaims } = this.props;
    this.props.updateHeader({
      header: {
        title: "Billing Service Providers",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BillingServiceProviderList,
        module: "",
        enableBack: false,
        entity: "billingserviceprovider",
        action: checkClaimsAuthorization({
          component: BILLING_PROVIDER_CREATE_SIMPLE_BUTTON,
          claims: pageClaims?.components,
        })
          ? "create"
          : "",
      },
    });
    const billingServiceProviderGeneralTable = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: BILLING_PROVIDER_GENERAL_TABLE,
    });
    const billingServiceProviderGeneralGrid = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: BILLING_PROVIDER_GENERAL_GRID,
    });
    const billingServiceProviderCountryDropdownFilter = findAuthorizationClaims(
      {
        claims: pageClaims?.components,
        name: BILLING_PROVIDER_COUNTRY_DROPDOWN_FILTER,
      }
    );
    const billingServiceProviderNameTextboxFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: BILLING_PROVIDER_NAME_TEXT_BOX_FILTER,
    });
    const billingServiceProviderListContainer = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: BILLING_PROVIDER_LIST_CONTAINER,
    });
    // Personalizations
    const billingServiceProviderPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData:
        billingServiceProviderListContainer?.personalizations,
    });
    const nameTextBoxPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData:
        billingServiceProviderNameTextboxFilter?.personalization,
    });
    const countryPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData:
        billingServiceProviderCountryDropdownFilter?.personalization,
    });
    //
    this.setState({
      filters: {
        name: nameTextBoxPersonalizations?.value,
        serviceCountryId: countryPersonalizations?.value
          ? Number(countryPersonalizations.value)
          : undefined,
      },
      paginationType: billingServiceProviderPersonalizations?.paginationType,
      sortingType: billingServiceProviderPersonalizations?.sortingType,
      isPinned: billingServiceProviderPersonalizations?.isPinned,
      gridable: billingServiceProviderListContainer?.gridable,
    });
    // if grid
    if (isComponentVisible({ claim: billingServiceProviderGeneralGrid })) {
      const billingServiceProviderGridPersonalizations = getPersonalizationData(
        {
          type: "grid",
          personalizationData:
            billingServiceProviderGeneralGrid.personalizations,
        }
      );
      const pageNumber = getDefaultPage({
        page: billingServiceProviderGridPersonalizations?.pageNumber,
      });
      const row = billingServiceProviderGridPersonalizations?.row
        ? Number(billingServiceProviderGridPersonalizations.row)
        : 5;
      const col = billingServiceProviderGridPersonalizations?.col
        ? Number(billingServiceProviderGridPersonalizations.col)
        : 4;
      const SortOrder =
        billingServiceProviderGridPersonalizations?.sortOrder ?? null;
      const SortColumn =
        billingServiceProviderGridPersonalizations?.sortColumn ?? null;

      this.setState(
        {
          grid: {
            page: pageNumber ? Number(pageNumber) : 1,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          selectedGridSort: _.find(
            this.state.gridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (billingServiceProviderPersonalizations?.gridView) {
            this.setState({ gridView: true });
            this.fetchMoreData({ isPaginated: true });
            if (
              billingServiceProviderPersonalizations?.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridBillingServiceProvider({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.fetchMoreData({ isPaginated: true, notUpdate: true });
            }
          }
        }
      );
    }
    // if table
    if (isComponentVisible({ claim: billingServiceProviderGeneralTable })) {
      const billingServiceProviderTablePersonalizations =
        getPersonalizationData({
          type: "table",
          personalizationData:
            billingServiceProviderGeneralTable.personalizations,
        });
      const pageNumber = getDefaultPage({
        page: billingServiceProviderTablePersonalizations?.pageNumber,
      });
      const pageSize = getDefaultPerPage({
        perPage: billingServiceProviderTablePersonalizations?.pageSize,
        defaultPerPage: 20,
      });
      const SortOrder =
        billingServiceProviderTablePersonalizations?.sortOrder ?? null;
      const SortColumn =
        billingServiceProviderTablePersonalizations?.sortColumn ?? null;
      this.setState(
        {
          per_page:
            billingServiceProviderPersonalizations?.paginationType ===
            PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : pageSize,
          current: pageNumber ? Number(pageNumber) : 1,
          sortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!billingServiceProviderPersonalizations.gridView) {
            if (
              billingServiceProviderPersonalizations?.paginationType ===
              PAGINATION_NOT_REQUIRED
            ) {
              this.listScrollingBillingServiceProvider({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.listBillingServiceProviders({
                options: {},
                notUpdate: true,
              });
            }
          }
        }
      );
    }
  };
  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };
  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.current = 1;
        prevState.listLoading = true;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };
        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }
        this.updateAllPersonalization();
        this.props
          .billingServiceProviderHandler({
            options,
            action: "list",
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ filterLoading: false, listLoading: false });
            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };
  handleChange = (label, value) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [label]: value,
      },
    });
  };
  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };
  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", serviceCountryId: 0 },
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.props
          .billingServiceProviderHandler({
            options: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                    paginationType: this.state.paginationType,
                    grid: this.state.grid,
                  })
                : this.state.per_page,
              targetEndpoint: this.props.targetEndpoint,
            },
            action: "list",
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization(BILLING_PROVIDER_NAME_TEXT_BOX_FILTER, false);
    delay(300).then(() => {
      this.updatePersonalization(
        BILLING_PROVIDER_COUNTRY_DROPDOWN_FILTER,
        false
      );
    });
    delay(600).then(() => {
      this.updatePersonalization(BILLING_PROVIDER_GENERAL_TABLE, false);
    });
    delay(900).then(() => {
      this.updatePersonalization(BILLING_PROVIDER_GENERAL_GRID);
    });
  };

  getBspQueryParams = () => {
    let tempFilters = this.getFilters();
    let tempOptions = {
      ...getCurrentTableSortInfo({
        sortInfo: this.state.sortInfo,
        sortingType: this.state.sortingType,
      }),
      q: { ...tempFilters },
    };
    tempOptions = this.state.gridView
      ? {
          ...tempOptions,
          page: this.state.grid?.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
        }
      : {
          ...tempOptions,
          page: this.state.current,
          per_page: this.state.per_page,
        };
    return tempOptions;
  };

  listBillingServiceProviders = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .billingServiceProviderHandler({
            options: tempOptions,
            action: "list",
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listBillingServiceProviders({ options: {} });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(BILLING_PROVIDER_GENERAL_TABLE);
          }
        });
      }
    );
  };

  listScrollingBillingServiceProvider = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            this.state.total === mustBeArray(this.state.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .billingServiceProviderHandler({
            options: tempOptions,
            action: "list",
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingBillingServiceProvider({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],

                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(BILLING_PROVIDER_GENERAL_TABLE);
          }
        });
      }
    );
  };

  listScrollingGridBillingServiceProvider = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      {
        listLoading: true,

        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid?.page) + 1
            : Number(this.state.grid?.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;

        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });

        let filterOptions = this.getFilters();

        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });

        this.props
          .billingServiceProviderHandler({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
              targetEndpoint: this.props.targetEndpoint,
            },
            action: "list",

            cancelToken: this.signal.token,
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridBillingServiceProvider({
                    pageLoad: true,
                  });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization(
                          BILLING_PROVIDER_GENERAL_GRID
                        );
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listBillingServiceProviderGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        const { grid } = this.state;

        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid?.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .billingServiceProviderHandler({
            options: tempOptions,
            action: "list",
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              if (
                Number(grid.page) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listBillingServiceProviderGrid(1);
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(BILLING_PROVIDER_GENERAL_GRID);
        });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member?.details?.authorizationDOM
      );

      const billingServiceProviderListContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === BILLING_PROVIDER_LIST_CONTAINER
      );

      const billingServiceProviderFilterContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === BILLING_PROVIDER_FILTER_CONTAINER
      );

      const billingServiceProviderGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === BILLING_PROVIDER_GENERAL_TABLE
      );

      const billingServiceProviderGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === BILLING_PROVIDER_GENERAL_GRID
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];
      switch (field) {
        case BILLING_PROVIDER_GENERAL_TABLE:
          if (billingServiceProviderListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",
              pageNumber: `${this.state.current}`,
              sortOrder: this.state.sortInfo?.order,
              sortColumn: this.state.sortInfo?.field,
              pageSize: `${this.state.per_page}`,
              personalizationData:
                billingServiceProviderGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_GENERAL_GRID:
          if (billingServiceProviderListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid?.page}`,
              row: `${grid?.row}`,
              col: `${grid?.col}`,
              sortOrder: grid?.sortInfo?.order,
              sortColumn: grid?.sortInfo?.field,
              personalizationData:
                billingServiceProviderGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_COUNTRY_DROPDOWN_FILTER:
          if (billingServiceProviderFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters?.serviceCountryId
                ? this.state.filters.serviceCountryId
                : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_NAME_TEXT_BOX_FILTER:
          if (billingServiceProviderFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.state.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_LIST_CONTAINER:
          if (billingServiceProviderListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData:
                billingServiceProviderListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      let updateChange = update;
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      } else if (
        field === BILLING_PROVIDER_GENERAL_GRID &&
        !_.isEmpty(mustBeArray(this.state.updatedComponentPersonalization))
      ) {
        updateChange = true;
      } else {
        updateChange = false;
      }

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChange,
        });
        if (updateChange) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    if (Number(tempFilters.serviceCountryId) !== 0) {
      tempFilters.countryId = tempFilters.serviceCountryId;
    }
    if (tempFilters.name) {
      tempFilters.name = tempFilters.name.trim(" ");
    }
    delete tempFilters.serviceCountryId;
    return tempFilters;
  };

  fetchMoreData = ({ isPaginated = false }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props
      .billingServiceProviderHandler({
        options: {
          page,
          per_page,
          q: { ...options },
          ...sortingParams,
        },
        action: "list",
        cancelToken: this.signal.token,
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ grid: { ...grid, page: 1 } }, () => {
              this.fetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.list = data;
                } else {
                  prevState.list = prevState.list.concat(data);

                  if (
                    data.length !== 0 &&
                    data.length === this.state.page_size
                  ) {
                    prevState.hasMoreData = true;
                    prevState.grid = {
                      ...prevState.grid,
                      page: prevState.grid.page + 1,
                    };
                  } else {
                    prevState.hasMoreData = false;
                  }
                }

                prevState.total = response.total;
                prevState.listLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(BILLING_PROVIDER_GENERAL_GRID);
                });
              }
            );
          }
        }
      });
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization(BILLING_PROVIDER_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingBillingServiceProvider({
            pageLoad: true,
            options: {},
          });
        } else {
          this.listBillingServiceProviders({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridBillingServiceProvider({
            pageLoad: true,
            options: {},
          });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };
  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridBillingServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingBillingServiceProvider({
            pageLoad: true,
            options: {},
          });
        } else {
          this.listBillingServiceProviders({ options: {} });
        }
      });
    }
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(
          `/billingserviceproviders/${data && data.id}`
        );
        break;
      case "edit":
        this.props.router.navigate(
          `/billingserviceproviders/${data && data.id}/edit`
        );
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  updatePinnedComponent = () => {
    this.setState(
      { isPinned: this.state.isPinned === "true" ? "false" : "true" },
      () => {
        this.updatePersonalization(BILLING_PROVIDER_LIST_CONTAINER);
      }
    );
  };

  getBSPActiveFilters = () => {
    let activeFilters = [];

    let filtersApplied = this.getFilters();
    if (filtersApplied?.name) {
      activeFilters.push(filtersApplied.name);
    }
    if (filtersApplied?.countryId) {
      let country = _.find(
        mustBeArray(this.state.countryList),
        (o) => o.id === filtersApplied.countryId
      );
      activeFilters.push(country?.value);
    }
    return activeFilters;
  };

  render() {
    const {
      sortInfo,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      hasMoreData,
      isPinned,

      paginationType,
      gridable,
      fieldOptions,
      sortingType,
      current,
      filterLoading,
    } = this.state;

    const { pageClaims, dashboardView } = this.props;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });
    const billingServiceProviderUrl = APIHandler.constructEndpoint({
      endpoint: "BILLING_PROVIDERS_URL",
    });
    const billingServiceProviderTargetEndpoint = requestUrlBuilder(
      billingServiceProviderUrl,
      { ...this.getBspQueryParams() }
    );

    let activeFilters = this.getBSPActiveFilters();
    return (
      <If
        condition={displayNoRecordsInList({
          list: list,
          loading: listLoading,
          current: current,
          gridCurrent: grid?.page,
          dashboardView: dashboardView,
          claims: pageClaims?.components,
          componentName: BILLING_PROVIDER_CREATE_SIMPLE_BUTTON,
          filters: this.getFilters(),
        })}
        then={
          <NoRecord>
            <ContentEmpty
              link="/billingserviceproviders/create"
              page="Billing Service Providers"
            />
          </NoRecord>
        }
        else={
          <React.Fragment>
            <If
              condition={!dashboardView}
              then={
                <div className="flex justify-between items-center flex-wrap">
                  <FilterContainer
                    name={BILLING_PROVIDER_FILTER_CONTAINER}
                    claims={this.props.pageClaims}
                  >
                    <SingleFieldTextBoxFilter
                      placeholder="Name"
                      name={BILLING_PROVIDER_NAME_TEXT_BOX_FILTER}
                      claims={this.props.claims}
                      value={this.state.filters?.name}
                      onChange={(value) => this.handleChange("name", value)}
                      onSearch={(value) => this.handleFilter("name", value)}
                      className="mb-[15px]"
                    />
                    <CountryLookup
                      selectedValue={this.state.filters?.serviceCountryId}
                      onSelect={(value) =>
                        this.handleChange("serviceCountryId", value)
                      }
                      name={BILLING_PROVIDER_COUNTRY_DROPDOWN_FILTER}
                      claims={this.props.claims}
                      updateCountryList={(countryList) =>
                        this.setState({ countryList })
                      }
                      className="-ml-5"
                    />
                    <SearchIconButton
                      loading={filterLoading}
                      onClick={this.handleFilter}
                      name={BILLING_PROVIDER_SEARCH_BUTTON}
                      claims={this.props.claims}
                    />
                    <LinkButton
                      buttonText="Reset Filter"
                      className="color-primary hand"
                      onClick={() => this.resetFilters()}
                      name={BILLING_PROVIDER_RESET_FILTER}
                      claims={this.props.claims}
                    />
                  </FilterContainer>
                  <div className={"mb-6"}>
                    <GridTableViewOption
                      gridable={gridable}
                      handleGridChange={this.handleGridChange}
                      gridView={gridView}
                      fieldOptions={fieldOptions}
                      updateGridField={this.updateGridField}
                      gridSortInfo={this.state.grid?.sortInfo}
                      tableSortInfo={this.state.sortInfo}
                      updatePageSize={this.updatePageSize}
                      grid={grid}
                      per_page={per_page}
                      dashboardView={dashboardView}
                    />
                  </div>
                </div>
              }
            />
            <ListContainer
              name={BILLING_PROVIDER_LIST_CONTAINER}
              claims={pageClaims}
              isPinned={isPinned}
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={gridable}
              gridView={gridView}
              grid={grid}
              per_page={per_page}
              GeneralTablePaginatedComponent={BillinServiceProviderList}
              GeneralTableScrollableComponent={
                ScrollableBillingServiceProviderTable
              }
              generalTableComponentName={BILLING_PROVIDER_GENERAL_TABLE}
              gridComponentName={BILLING_PROVIDER_GENERAL_GRID}
              createLinkButtonName={""}
              handleAddAction={() => {
                console.log("");
              }}
              handleGridChange={this.handleGridChange}
              fieldOptions={fieldOptions}
              updateGridField={this.updateGridField}
              sortingType={sortingType}
              paginationType={paginationType}
              list={list}
              listLoading={listLoading}
              listGrid={this.listBillingServiceProviderGrid}
              listScrollingGrid={this.listScrollingGridBillingServiceProvider}
              hasMoreData={hasMoreData}
              gridActionComponentName={BILLING_PROVIDER_VIEW_DETAIL_LINK}
              moduleType={"billingServiceProvider"}
              handleGridAction={this.handleAction}
              listScrollingGeneralTable={
                this.listScrollingBillingServiceProvider
              }
              total={total}
              current={current}
              sortInfo={sortInfo}
              listGeneralTable={this.listBillingServiceProviders}
              tableActionComponentName={BILLING_PROVIDER_VIEW_DETAIL_LINK}
              gridPageSize={getCurrentGridPageSize({
                paginationType: this.state.paginationType,
                grid: this.state.grid,
              })}
              cardGrid={cardGrid}
              showAddAndGrid={false}
              DisplayCardComponent={BillingServiceProviderDisplayCard}
              viewComponent={BILLING_PROVIDER_VIEW_DETAIL_LINK}
              rowIdParam="id"
              dashboardView={dashboardView}
              targetEndpoint={
                this.props.targetEndpoint ||
                billingServiceProviderTargetEndpoint
              }
              hasNoDuplicateEntity={true}
              componentTitle={
                dashboardView
                  ? this.props.componentTitle
                  : "Billing Service Providers"
              }
              filterApplied={
                dashboardView
                  ? this.props.filterApplied
                  : filteredByLabel({ filters: activeFilters })
              }
            />
          </React.Fragment>
        }
      />
    );
  }
}

export default withRouter(BillingServiceProviderIndex);
