import { Select } from "antd";
import { useGetCompaniesByInstanceIdQuery } from "@xemplo/directory-services-query";
import { useAuth } from "@xemplo/auth-provider";
import { useEffect, useState } from "react";

type CompanyInstanceLookupProps = {
  handleChange?: (e: any) => void;
  disabled: boolean;
  value?: string;
  instanceId?: number;
};

const CompanyInstanceLookup = ({
  handleChange,
  disabled,
  value,
  instanceId,
}: CompanyInstanceLookupProps) => {
  const { user } = useAuth();
  const [selectedInstance, setSelectedInstance] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!disabled && handleChange) {
      handleChange(undefined);
    }
  }, [instanceId]);

  useEffect(() => {
    setSelectedInstance(value);
  }, [value]);

  // onChange has value type defined as any
  const onChange = (e: any) => {
    if (handleChange) {
      handleChange(e);
    }
  };

  const { data, isLoading } = useGetCompaniesByInstanceIdQuery({
    authToken: user?.access_token,
    id: instanceId,
  });

  return (
    <Select
      placeholder="Select"
      optionFilterProp="name"
      disabled={disabled}
      onChange={onChange}
      loading={isLoading}
      showSearch
      filterOption
      value={selectedInstance}
    >
      {data
        ? data.map((company) => (
            <Select.Option
              key={company.id}
              value={company.id}
              name={company.name}
            >
              {company.name}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default CompanyInstanceLookup;
