import { SVGProps } from 'react';
const DawnLockUnlocked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.092 4.139c-.53.902-.717 2.227-.717 3.861v2.25H15.5c1.416 0 2.613.195 3.374 1.1.72.859.876 2.168.876 3.817v1.666c0 1.649-.155 2.958-.876 3.816-.76.906-1.958 1.101-3.374 1.101h-7c-1.416 0-2.613-.195-3.374-1.1-.72-.859-.876-2.168-.876-3.817v-1.666c0-1.649.155-2.958.876-3.816.447-.532 1.045-.82 1.749-.965V8c0-1.676.178-3.351.924-4.621A4.047 4.047 0 0 1 9.43 1.807c.712-.372 1.566-.557 2.569-.557 2.016 0 3.369.706 4.175 1.89.767 1.128.95 2.565.95 3.86a.75.75 0 0 1-1.5 0c0-1.205-.181-2.268-.69-3.016-.47-.69-1.305-1.234-2.935-1.234-.82 0-1.424.151-1.874.387a2.548 2.548 0 0 0-1.034 1.002Zm-2.818 8.177c-.33.392-.524 1.166-.524 2.85v1.667c0 1.685.195 2.459.524 2.851.29.344.842.566 2.226.566h7c1.384 0 1.937-.222 2.226-.566.33-.392.524-1.166.524-2.85v-1.667c0-1.685-.195-2.459-.524-2.851-.29-.344-.842-.566-2.226-.566h-7c-1.384 0-1.937.222-2.226.566Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnLockUnlocked;
