import { SVGProps } from 'react';
const DawnCircleAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.001 12c0-5.501 4.498-9.999 9.999-9.999S21.999 6.5 21.999 12s-4.498 9.999-10 9.999c-5.5 0-9.998-4.498-9.998-9.999ZM12 3.501C7.327 3.501 3.5 7.327 3.5 12S7.327 20.499 12 20.499s8.499-3.826 8.499-8.499-3.826-8.499-8.5-8.499Zm-.004 3.749a.75.75 0 0 1 .75.75v4.887a.75.75 0 1 1-1.5 0V8a.75.75 0 0 1 .75-.75ZM11.99 15a1 1 0 1 0 0 2h.008a1 1 0 1 0 0-2h-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCircleAlert;
