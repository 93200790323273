// @ts-nocheck
import { Steps, Card } from "antd";
import { mustBeArray } from "../../../libs/utilities";
const Step: any = Steps.Step;

type Props = { data: []; activeIndex: number };

const FormSteps = ({ data, activeIndex }: Props) => (
  <Card>
    <Steps progressDot current={activeIndex - 1} direction="vertical">
      {mustBeArray(data).map((x, i) => {
        return <Step title={x.label} key={i} />;
      })}
    </Steps>
  </Card>
);

export default FormSteps;
