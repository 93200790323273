import { useEffect, useState } from "react";
import { Button, Popconfirm } from "antd";
import { GoBackIcon } from "../Common/customicons";
import { connect } from "react-redux";
import { colors } from "../constants/colors";
import { useAnalyticsEventTracker, useAppSelector } from "../hooks";
import { getModuleType } from "../libs";

import { useNavigate } from "react-router-dom";
import { LogoSize, LogoXemplo } from "@xemplo/icons";

type Props = any;

const FormNavbar = ({
  handleCancel,
  handleSave,
  loading,
  disabled,
  isFooter,
  handleSaveAsDraft,
  showSaveAsDraft,
}: Props) => {
  const navigate = useNavigate();
  const { header } = useAppSelector((state) => state.pageHeader);
  const { enableBack, entity } = header;

  const [moduleType, setModuleType] = useState("");

  useEffect(() => {
    setModuleType(getModuleType(entity ?? ""));
  }, [header?.entity]);

  const gaEventTracker = useAnalyticsEventTracker("Header Form");

  const onSave = (e: any) => {
    gaEventTracker("Save Button Clicked", `${moduleType} form saved`);
    handleSave(e);
  };

  const onSaveDraft = () => {
    gaEventTracker(
      "Save Draft Button Clicked",
      `${moduleType} form saved as Draft`
    );
    handleSaveAsDraft();
  };

  const onCancel = (e: any) => {
    gaEventTracker("Cancel Button Clicked", `${moduleType} form cancelled`);
    handleCancel(e);
  };

  const backHistory = () => {
    gaEventTracker("Back Button Clicked", `${moduleType} form cancelled`);
    if (header?.returnUrl) {
      return navigate(header.returnUrl);
    }
    navigate(-1);
  };

  const goHome = () => {
    gaEventTracker("Home Clicked", `${moduleType} form cancelled`);
    navigate("/home");
  };

  return !isFooter ? (
    <div className="px-4 w-full">
      <div className="flex justify-start items-center">
        <span onClick={goHome}>
          {" "}
          <LogoXemplo size={LogoSize.s} />
        </span>
        <div className="flex justify-between items-center navbar-detail ml-6 w-full">
          <div className="flex fle-start">
            {enableBack && (
              <span
                className="hand mr-[5px] color-primary"
                onClick={backHistory}
              >
                <GoBackIcon fill={colors.primary} />
              </span>
            )}
            <div className="flex flex-col">
              <span
                className="hand color-primary text-base"
                onClick={backHistory}
              >
                {header?.module}
              </span>
              <div className="text-2xl font-medium">{header?.title}</div>
            </div>
          </div>
        </div>
        <div className="pb-2.5 justify-between flex pt-[15px]">
          {showSaveAsDraft && (
            <Button
              className="mr-4 rounded-[20px]"
              loading={loading}
              onClick={onSaveDraft}
            >
              Save As Draft
            </Button>
          )}
          <Button
            className="mr-4 rounded-[20px]"
            type="primary"
            loading={loading}
            onClick={onSave}
            disabled={disabled}
          >
            Save
          </Button>

          <Popconfirm
            placement="bottomLeft"
            title={"Are you sure you want to cancel?"}
            onConfirm={onCancel}
            okText="Yes"
            cancelText="No"
          >
            <Button className="rounded-[20px] bg-e5e5">Cancel</Button>
          </Popconfirm>
        </div>
        <div className={"clearfix"} />
      </div>
    </div>
  ) : (
    <div className="px-4 flex w-full justify-end">
      <div className="pb-2.5 pt-[15px]">
        <Button
          className="mx-2.5"
          type="primary"
          loading={loading}
          onClick={onSave}
          htmlType="submit"
          disabled={disabled}
          // ghost
        >
          Save
        </Button>
        <Button className="no-border" onClick={backHistory}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    header: state.pageHeader.header,
  };
}

export default connect(mapStateToProps)(FormNavbar);
