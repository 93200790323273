import styled, { css } from 'styled-components';

import {
  BodyLargeMedium,
  BodySmallMedium,
  BodySmallSemiBold,
  BodyStandardMedium,
  BodyStandardSemiBold,
  Colour,
  HeadingSmallSemiBold,
} from '@xemplo/style-constants';

import { ButtonSize, ButtonType, ButtonVariant } from './button.types';

const DefaultButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border: none;
  padding: 8px 16px 8px 16px;
  border-radius: 24px;
  transition: all 0.1s ease-out;

  &[data-size=${ButtonSize.Small}] {
    ${BodySmallSemiBold};
    height: 32px;
  }
  &[data-size=${ButtonSize.Medium}] {
    ${BodyStandardSemiBold}
    height: 40px;
  }
  &[data-size=${ButtonSize.Standard}] {
    ${BodyStandardSemiBold}
    height: 48px;
    padding: 12px 24px 12px 24px;
  }
  &[data-size=${ButtonSize.Large}] {
    ${HeadingSmallSemiBold}
    height: 64px;
    padding: 20px 24px 20px 24px;
    border-radius: 32px;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const StyledChildrenContainer = styled.div`
  padding: 0 4px 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled.button`
  ${DefaultButtonStyles};

  &[data-type=${ButtonType.Primary}] {
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
    &:hover {
      background: ${Colour.Blue[600]};
    }
  }
  &[data-type=${ButtonType.Secondary}] {
    &[data-variant=${ButtonVariant.Light}] {
      background: none;
      color: ${Colour.White[100]};
      border: 1px solid ${Colour.White[100]};
      &:hover {
        background: ${Colour.White[10]};
      }
    }
    &[data-variant=${ButtonVariant.Dark}] {
      background: none;
      color: ${Colour.Gray[500]};
      border: 1px solid ${Colour.Gray[200]};
      &:hover {
        background: rgba(93, 107, 152, 0.05);
        border: 1px solid ${Colour.Gray[300]};
      }
    }
  }

  &[data-type=${ButtonType.Destructive}] {
    background: ${Colour.Red[500]};
    color: ${Colour.White[100]};
    &:hover {
      background: ${Colour.Red[600]};
    }
  }

  &[data-type=${ButtonType.Tertiary}] {
    background: none;
    padding: 0px;
    gap: 6px;
    & > div {
      padding: 0px;
    }
    &[data-underline='true'] {
      text-decoration: underline;
    }

    ${BodyStandardMedium};
    &[data-size=${ButtonSize.Small}] {
      ${BodySmallMedium};
      gap: 4px;
    }
    &[data-size=${ButtonSize.Large}] {
      ${BodyLargeMedium};
      gap: 8px;
    }
    &[data-variant=${ButtonVariant.Light}] {
      color: ${Colour.White[80]};
      &:hover {
        color: ${Colour.White[100]};
      }
    }
    &[data-variant=${ButtonVariant.Dark}] {
      color: ${Colour.Gray[400]};
      &:hover {
        color: ${Colour.Gray[600]};
      }
    }
    &[data-variant=${ButtonVariant.Error}] {
      color: ${Colour.Red[500]};
      &:hover {
        color: ${Colour.Red[600]};
      }
    }
    &[data-variant=${ButtonVariant.Blue}] {
      color: ${Colour.Blue[500]};
      &:hover {
        color: ${Colour.Blue[600]};
      }
    }
  }

  &.as-link {
    width: max-content;
    text-decoration: none;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
