import { useMemo } from "react";
import { If } from "../../../Common/ui";
import { MAX_VARIANCE_PERIODS } from "../../../constants/variance";
import {
  calculateVarianceAndSD,
  getVarianceColWidth,
  getVarianceSDColWidth,
} from "../../../libs";

type Props = {
  totalAvailablePeriod: number;
  record: any;
  getAmount: any;
  field: any;
  title: string;
  labelClassName: string;
};
const VarianceGrossIncomeAndNetWage = ({
  totalAvailablePeriod,
  record,
  getAmount,
  field = "",
  title,
  labelClassName,
}: Props) => {
  const type = useMemo(() => {
    return title === "Gross Income" ? "payrollProcessing" : "netWage";
  }, [title]);
  const varianceAndSD = useMemo(() => {
    return calculateVarianceAndSD({
      title,
      field,
      record,
      type,
    });
  }, [title, field, record]);
  return (
    <If
      condition={!isNaN(totalAvailablePeriod)}
      then={
        <div className={`flex bold`}>
          <div className={`${labelClassName} pl-12`}>{title}</div>
          {MAX_VARIANCE_PERIODS.map((value, index) => {
            return (
              <If
                key={index}
                condition={totalAvailablePeriod >= value}
                then={getAmount({
                  field: "",
                  record,
                  position: value - 1,
                  type,
                })}
              />
            );
          })}
          <div
            className={`flex-1-1-${getVarianceColWidth({
              totalPeriod: totalAvailablePeriod,
            })} text-right`}
          >
            {varianceAndSD?.variance}
          </div>
          <div
            className={`flex-1-1-${getVarianceSDColWidth({
              totalPeriod: totalAvailablePeriod,
            })} text-right`}
          >
            {varianceAndSD?.sd}
          </div>
        </div>
      }
    />
  );
};

export default VarianceGrossIncomeAndNetWage;
