import { SVGProps } from 'react';
const DawnArrowDown16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.832 5.762a.75.75 0 1 1 1.045 1.076l-5.352 5.199a.746.746 0 0 1-.841.145.748.748 0 0 1-.21-.145L2.123 6.838a.75.75 0 0 1 1.045-1.076L8 10.456l4.832-4.694Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowDown16;
