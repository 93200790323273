import { message } from "antd";
import { ComponentType, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_HOME_ROUTE } from "../constants";
import { UNATHORIZED_ACCESS_ERRORS } from "../constants/errors";
import { findAuthorizationClaims, isComponentVisible } from "../libs";
import { AuthorizationDOM } from "../types/authorization";

type HasPageClaims = {
  pageClaims: AuthorizationDOM;
};

/**
 * High Order Component to check if user has access to a component based on its claims.
 * This is consumed at the moment so that we can check if user has access to a component
 * directly from the router specification. Some of the components won't have the pageClaims
 * as part of props, so we can use the useSecuredComponent hook instead.
 */
const requireSecureComponent =
  <T extends HasPageClaims>(
    SecuredComponent: ComponentType<T>,
    requiredClaim: string
  ) =>
  (props: T) => {
    const { canRender } = useSecuredComponent({
      claims: props.pageClaims,
      requiredClaim,
    });

    return canRender ? <SecuredComponent {...props} /> : null;
  };

type HookProps = {
  claims: AuthorizationDOM;
  requiredClaim: string;
  navigateOnFailure?: boolean;
};

/**
 * Not all secured components will have the pageClaims as part of props.
 * When this is the case, we can use this hook to check if user has access to a component based on its claims.
 *
 * In general, this hook is the preferred way of doing as we can get the data directly from the state
 * instead of passing it as props and relying on the parent component to pass it down.
 *
 * Finally, as a default behaviour, the hook will redirect to the home page if the user doesn't have access to the component.
 * This can be disabled by setting the navigateOnFailure to false.
 */
const useSecuredComponent = ({
  claims,
  requiredClaim,
  navigateOnFailure = true,
}: HookProps) => {
  const [canRender, setCanRender] = useState(false);
  const navigate = useNavigate();

  // Check if user has access to create amendments. If not redirect to home.
  useEffect(() => {
    //this currently doesn't work for menu items (main pages)
    const claim = findAuthorizationClaims({
      claims: claims.components,
      name: requiredClaim,
    });

    if (claim && isComponentVisible({ claim })) {
      setCanRender(true);
      return;
    }

    if (navigateOnFailure) {
      // If user doesn't have access to create amendments, redirect to home.
      message.error(UNATHORIZED_ACCESS_ERRORS);
      navigate(DEFAULT_HOME_ROUTE);
    }
  }, []);

  return { canRender };
};
export default requireSecureComponent;
