//@ts-nocheck
import React from "react";
import CompanyUserList from "../../components/users/company";
import { message, Tabs } from "antd";
import {
  errorDisplay,
  findAuthorizationClaims,
  mustBeArray,
  updateAuthorizationClaims,
  delay,
  removeUndefined,
  getDeviceEnrolmentId,
  getPersonalizationData,
  getUserArea,
  requestUrlBuilder,
  getCurrentCardGrid,
  getSelectedRoleTenantId,
  handleAssignBusinessUnitNavigation,
  getFullName,
  getMatchParams,
  errorHandler,
  trimWhiteSpaces,
  isComponentVisible,
} from "../../../libs/utilities";
import {
  PSP_USER_FIELD_OPTIONS,
  COMPANY_USER_GRID_OPTIONS,
  COMPANY_USER_FIELD_OPTIONS,
  PSP_USER_GRID_OPTIONS,
} from "../../../constants/sortingOptions";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import ChildTabContainer from "../../../Common/ClaimContainers/ChildTabContainer";
import {
  LIST_PAGINATION_TYPE_INDEX,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  SORT_ORDER_INDEX,
  SORT_COLUMN_INDEX,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_NOT_REQUIRED,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
  GRID_PAGE_NUMBER_INDEX,
  GRID_ROW_INDEX,
  GRID_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_VIEW_VALUE,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  SORTING_NOT_REQUIRED,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  MULTI_TAB_ACTIVE_KEY_INDEX,
  TABLE_PERSONALIZATIONS,
  GRID_PERSONALIZATIONS,
  PINNABLE_LIST_PERSONALIZATION,
  INTEGER_PERSONALIZATION,
  STRING_PERSONALIZATION,
} from "../../../constants/defaultClaims";
import _ from "lodash";
import axios from "axios";
import UserDisplayCard from "../../components/users/userDisplayCard";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import ScrollableUserList from "../../components/users/ScrollableUserList";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import {
  ACTIVE_FILTER_ID,
  INACTIVE_FILTER_ID,
} from "../../../constants/options";
import TabHeader from "../../../Common/tabHeader";
import APIHandler from "../../../constants/apiUrl";
import userRoles from "../../../constants/userRoles";
import { filteredByLabel } from "../../../libs/utilities";
import {
  handleUserUnassignBusinessUnitRedirection,
  validateUsersAccessRights,
} from "../../../libs/validations";
import DisablePspUserConfirmationModal from "../../components/users/DisablePspUserConfirmationModal";
import ToggleOptionRadioButton from "../../components/common/ToggleOptionRadioButton";
import PayrollUserDisplayCard from "../../components/users/PayrollUserDisplayCard";
import { updateDomChanges } from "../../../libs";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  USER_COMPANY_GENERAL_ADD_BUTTON,
  USER_FILTER_CONTAINER,
  USER_MULTI_TAB_CONTAINER,
  USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON,
} from "../../../constants";
import { If } from "../../../Common/ui";
import { withRouter } from "../../../hooks";

const { TabPane } = Tabs;

type State = any;

class CompanyUser extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      activeKey: "1",
      cu_loading: true,
      pu_loading: true,
      user: {},
      viewRegisterUser: false,
      newUserFormVisible: false,
      companySortInfo: {},
      pspSortInfo: {},

      // Company User
      userCompanyPagination: 1,
      userCompanyPerPage: 10,
      userCompanyTotal: 0,
      userCompanyList: [],
      userCompanyListLoading: false,
      userCompanyAddable: false,
      userCompanyGridable: false,
      userCompanyGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      userCompanyGridView: false,
      userCompanyIsPinned: "false",
      userCompanySortingType: "notrequired",
      userCompanyHasMoreData: false,
      userCompanySortInfo: {},
      userCompanyGridSortOption: COMPANY_USER_GRID_OPTIONS,
      userCompanySelectedGridSort: undefined,
      userCompanyFieldOptions: COMPANY_USER_FIELD_OPTIONS,

      // PSP User
      userPayrollServiceProviderPagination: 1,
      userPayrollServiceProviderPerPage: 10,
      userPayrollServiceProviderTotal: 0,
      userPayrollServiceProviderList: [],
      userPayrollServiceProviderListLoading: false,
      userPayrollServiceProviderAddable: false,
      userPayrollServiceProviderGridable: false,
      userPayrollServiceProviderGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      userPayrollServiceProviderGridView: false,
      userPayrollServiceProviderIsPinned: "false",
      userPayrollServiceProviderSortingType: "notrequired",
      userPayrollServiceProviderHasMoreData: false,
      userPayrollServiceProviderSortInfo: {},
      userPayrollServiceProviderGridSortOption: PSP_USER_GRID_OPTIONS,
      userPayrollServiceProviderSelectedGridSort: undefined,
      userPayrollServiceProviderFieldOptions: PSP_USER_FIELD_OPTIONS,
      updatedComponentPersonalization: [],
      viewDisablePspUserConfirmation: false,
      activeUser: {},
      pspUserFilter: {
        status: ACTIVE_FILTER_ID,
      },
      companyUserFilter: {
        status: ACTIVE_FILTER_ID,
      },
    };
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, type, pageClaims } = this.props;
    //Validate if user has access to /users page
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    if (dashboardView) {
      if (type === "company") {
        const companyPersonalizations = getPersonalizationData({
          type: "listContainer",
          personalizationData: dashboardClaims,
        });
        if (!companyPersonalizations || !companyPersonalizations.gridView) {
          this.setState({ userCompanyGridView: false });
          if (companyPersonalizations.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingUserCompany({ pageLoad: true, notUpdate: true });
          } else {
            this.listCompanyUsers({ options: {}, notUpdate: true });
          }
        } else {
          this.setState({ userCompanyGridView: true });
          if (companyPersonalizations.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUserCompany({
              pageLoad: true,
              notUpdate: true,
            });
          } else {
            this.userCompanyFetchMoreData({
              isPaginated: true,
              notUpdate: true,
            });
          }
        }
      } else if (type === "psp") {
        const pspPersonalization = {};
        if (pspPersonalization && !pspPersonalization.gridView) {
          this.setState({ userPayrollServiceProviderGridView: false });
          if (pspPersonalization.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingUserPayrollServiceProvider({
              pageLoad: true,
              notUpdate: true,
            });
          } else {
            this.listPSPUsers({ options: {}, notUpdate: true });
          }
        } else {
          if (pspPersonalization?.gridView) {
            this.setState({ userPayrollServiceProviderGridView: true });
            if (pspPersonalization.paginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridUserPayrollServiceProvider({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.userPayrollServiceProviderFetchMoreData({
                isPaginated: true,
                notUpdate: true,
              });
            }
          }
        }
      }
    } else {
      this.props.updateHeader({
        header: {
          title: "Users",
          module: "",
          enableBack: false,
          entity: "user",
          action: "",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.UserList,
        },
      });
      // Filters
      const userStatusDropdownFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "userStatusDropdownFilter",
      });
      const userNameTextboxFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "userNameTextboxFilter",
      });
      this.setState({
        filters: {
          name: userNameTextboxFilter?.personalization?.value,
          isActive: userStatusDropdownFilter?.personalization?.value
            ? Number(userStatusDropdownFilter.personalization.value)
            : 0,
        },
      });

      // Multi tab
      const userMultiTabContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === USER_MULTI_TAB_CONTAINER
      );
      const activeKey =
        userMultiTabContainer?.personalizations?.[MULTI_TAB_ACTIVE_KEY_INDEX]
          ?.value;

      if (activeKey) {
        this.setState({ activeKey });
      }

      // PSP Users
      const userPayrollServiceProviderListContainer = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "userPayrollServiceProviderListContainer",
      });
      const userPayrollServiceProviderGeneralTable = findAuthorizationClaims({
        claims: userPayrollServiceProviderListContainer?.components,
        name: "userPayrollServiceProviderGeneralTable",
      });
      const userPayrollServiceProviderGeneralGrid = findAuthorizationClaims({
        claims: userPayrollServiceProviderListContainer?.components,
        name: "userPayrollServiceProviderGeneralGrid",
      });
      const userPayrollServiceProviderPaginationType =
        userPayrollServiceProviderListContainer?.personalizations?.[
          LIST_PAGINATION_TYPE_INDEX
        ]?.value;
      const userPayrollServiceProviderSortingType =
        userPayrollServiceProviderListContainer?.personalizations?.[
          LIST_SORTING_REQUIRED_INDEX
        ]?.value;
      const userPayrollServiceProviderIsPinned =
        userPayrollServiceProviderListContainer?.personalizations?.[
          LIST_PINNABLE_INDEX
        ]?.value;
      this.setState({
        userPayrollServiceProviderPaginationType,
        userPayrollServiceProviderSortingType,
        userPayrollServiceProviderIsPinned,
        userPayrollServiceProviderGridable:
          userPayrollServiceProviderListContainer?.gridable,
      });

      // If Table
      if (
        isComponentVisible({ claim: userPayrollServiceProviderGeneralTable })
      ) {
        const userPayrollServiceProviderPagination =
          userPayrollServiceProviderGeneralTable.personalizations
            ? !_.isNaN(
                Number(
                  userPayrollServiceProviderGeneralTable.personalizations[
                    PAGE_NUMBER_INDEX
                  ].value
                )
              )
              ? Number(
                  userPayrollServiceProviderGeneralTable.personalizations[
                    PAGE_NUMBER_INDEX
                  ].value
                )
              : 1
            : 1;
        const pageSize = userPayrollServiceProviderGeneralTable.personalizations
          ? Number(
              userPayrollServiceProviderGeneralTable.personalizations[
                PAGE_SIZE_INDEX
              ].value || 20
            )
          : 20;
        const SortOrder =
          userPayrollServiceProviderGeneralTable?.personalizations?.[
            SORT_ORDER_INDEX
          ]?.value ?? null;
        const SortColumn =
          userPayrollServiceProviderGeneralTable?.personalizations?.[
            SORT_COLUMN_INDEX
          ]?.value ?? null;
        this.setState(
          {
            userPayrollServiceProviderPerPage:
              userPayrollServiceProviderPaginationType ===
              PAGINATION_NOT_REQUIRED
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
            userPayrollServiceProviderPagination,
            userPayrollServiceProviderSortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (
              mustBeArray(
                userPayrollServiceProviderListContainer?.personalizations
              )?.[LIST_PERSONALIZATION_GRID_TABLE_INDEX]?.value ===
              TABLE_VIEW_VALUE
            ) {
              if (
                userPayrollServiceProviderPaginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingUserPayrollServiceProvider({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.listPSPUsers({ options: {}, notUpdate: true });
              }
            }
          }
        );
      }
      // If Grid
      if (
        isComponentVisible({ claim: userPayrollServiceProviderGeneralGrid })
      ) {
        const pageNumber =
          userPayrollServiceProviderGeneralGrid.personalizations
            ? Number(
                userPayrollServiceProviderGeneralGrid.personalizations[
                  GRID_PAGE_NUMBER_INDEX
                ].value
              )
            : 1;
        const row = userPayrollServiceProviderGeneralGrid.personalizations
          ? Number(
              userPayrollServiceProviderGeneralGrid.personalizations[
                GRID_ROW_INDEX
              ].value
            )
          : 5;
        const col = userPayrollServiceProviderGeneralGrid.personalizations
          ? Number(
              userPayrollServiceProviderGeneralGrid.personalizations[
                GRID_COLUMN_INDEX
              ].value
            )
          : 4;
        const SortOrder =
          userPayrollServiceProviderGeneralGrid?.personalizations?.[
            GRID_SORT_ORDER_INDEX
          ]?.value ?? null;
        const SortColumn =
          userPayrollServiceProviderGeneralGrid?.personalizations?.[
            GRID_SORT_COLUMN_INDEX
          ]?.value ?? null;

        this.setState(
          {
            userPayrollServiceProviderGrid: {
              page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },
            userPayrollServiceProviderSelectedGridSort: _.find(
              this.state.userPayrollServiceProviderGridSortOption,
              (o) => o.label === SortColumn
            )?.id,
          },
          () => {
            if (
              mustBeArray(
                userPayrollServiceProviderListContainer?.personalizations
              )?.[0]?.value === GRID_VIEW_VALUE
            ) {
              this.setState({ userPayrollServiceProviderGridView: true });
              if (
                userPayrollServiceProviderPaginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingGridUserPayrollServiceProvider({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.userPayrollServiceProviderFetchMoreData({
                  isPaginated: true,
                  notUpdate: true,
                });
              }
            }
          }
        );
      }
      // Company Users
      const userCompanyListContainer = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "userCompanyListContainer",
      });
      const userCompanyGeneralTable = findAuthorizationClaims({
        claims: userCompanyListContainer?.components,
        name: "userCompanyGeneralTable",
      });
      const userCompanyGeneralGrid = findAuthorizationClaims({
        claims: userCompanyListContainer?.components,
        name: "userCompanyGeneralGrid",
      });
      const userCompanyPaginationType =
        userCompanyListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
          ?.value;
      const userCompanySortingType =
        userCompanyListContainer?.personalizations?.[
          LIST_SORTING_REQUIRED_INDEX
        ]?.value;
      const userCompanyIsPinned =
        userCompanyListContainer?.personalizations?.[LIST_PINNABLE_INDEX]
          ?.value;

      this.setState({
        userCompanyPaginationType,
        userCompanySortingType,
        userCompanyIsPinned,
        userCompanyGridable: userCompanyListContainer?.gridable,
      });

      // If Table
      if (isComponentVisible({ claim: userCompanyGeneralTable })) {
        const userCompanyPagination = userCompanyGeneralTable.personalizations
          ? !_.isNaN(
              Number(
                userCompanyGeneralTable.personalizations[PAGE_NUMBER_INDEX]
                  .value
              )
            )
            ? Number(
                userCompanyGeneralTable.personalizations[PAGE_NUMBER_INDEX]
                  .value
              )
            : 1
          : 1;
        const pageSize = userCompanyGeneralTable.personalizations
          ? Number(
              userCompanyGeneralTable.personalizations[PAGE_SIZE_INDEX].value ||
                20
            )
          : 20;
        const SortOrder =
          userCompanyGeneralTable?.personalizations?.[SORT_ORDER_INDEX]
            ?.value ?? null;
        const SortColumn =
          userCompanyGeneralTable?.personalizations?.[SORT_COLUMN_INDEX]
            ?.value ?? null;
        this.setState(
          {
            userCompanyPerPage:
              userCompanyPaginationType === PAGINATION_NOT_REQUIRED
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
            userCompanyPagination,
            userCompanySortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (
              mustBeArray(userCompanyListContainer?.personalizations)?.[
                LIST_PERSONALIZATION_GRID_TABLE_INDEX
              ]?.value === TABLE_VIEW_VALUE
            ) {
              if (userCompanyPaginationType === PAGINATION_SCROLLING) {
                this.listScrollingUserCompany({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.listCompanyUsers({ options: {}, notUpdate: true });
              }
            }
          }
        );
      }
      // If Grid
      if (isComponentVisible({ claim: userCompanyGeneralGrid })) {
        const pageNumber = userCompanyGeneralGrid.personalizations
          ? Number(
              userCompanyGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
                .value
            )
          : 1;
        const row = userCompanyGeneralGrid.personalizations
          ? Number(
              userCompanyGeneralGrid.personalizations[GRID_ROW_INDEX].value
            )
          : 5;
        const col = userCompanyGeneralGrid.personalizations
          ? Number(
              userCompanyGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
            )
          : 4;
        const SortOrder =
          userCompanyGeneralGrid?.personalizations?.[GRID_SORT_ORDER_INDEX]
            ?.value ?? null;
        const SortColumn =
          userCompanyGeneralGrid?.personalizations?.[GRID_SORT_COLUMN_INDEX]
            ?.value ?? null;

        this.setState(
          {
            userCompanyGrid: {
              page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },
            userCompanySelectedGridSort: _.find(
              this.state.userCompanyGridSortOption,
              (o) => o.label === SortColumn
            )?.id,
          },
          () => {
            if (
              mustBeArray(userCompanyListContainer?.personalizations)?.[0]
                ?.value === GRID_VIEW_VALUE
            ) {
              this.setState({ userCompanyGridView: true });
              if (userCompanyPaginationType === PAGINATION_SCROLLING) {
                this.listScrollingGridUserCompany({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.userCompanyFetchMoreData({
                  isPaginated: true,
                  notUpdate: true,
                });
              }
            }
          }
        );
      }
    }
  }

  //
  // Company User
  listCompanyUsers = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.userCompanyPagination = options.page;
          prevState.userCompanySortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.userCompanyListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.userCompanyPagination))
            ? Number(this.state.userCompanyPagination)
            : 1,
          per_page: !_.isNaN(Number(this.state.userCompanyPerPage))
            ? Number(this.state.userCompanyPerPage)
            : 10,
          ...this.getUserCompanyTableSortInfo(),
          q: { ...this.getFilters({ userType: "company" }) },
        };
        this.props
          .listCompanyUsers({
            options: newOptions,
            userMode: this.props.userMode,
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userCompanyListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.userCompanyPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ userCompanyPagination: 1 }, () => {
                  this.listCompanyUsers({ options: {} });
                });
              } else {
                this.setState({
                  userCompanyList: mustBeArray(resp.data),
                  userCompanyTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("userCompanyGeneralTable");
          }
        });
      }
    );
  };

  getUserCompanyTableSortInfo = () => {
    if (
      this.state.userCompanySortInfo?.field &&
      this.state.userCompanySortInfo?.order &&
      this.state.userCompanySortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userCompanySortInfo.field]:
            this.state.userCompanySortInfo.order,
        },
      };
    } else return {};
  };

  listScrollingGridUserCompany = ({ pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.userCompanyHasMoreData) return false;

    this.setState(
      {
        userCompanyListLoading: true,
        userCompanyGrid: {
          ...this.state.userCompanyGrid,
          page: !pageLoad
            ? Number(this.state.userCompanyGrid?.page) + 1
            : Number(this.state.userCompanyGrid?.page),
        },
      },
      () => {
        const { userCompanyGrid } = this.state;
        const { page } = userCompanyGrid;

        const per_page = this.getUserCompanyGridPageSize();

        let sortingParams = this.getUserCompanyGridSortInfo();

        this.props
          .listCompanyUsers({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              ...sortingParams,

              q: { ...this.getFilters({ userType: "company" }) },

              targetEndpoint: this.props.targetEndpoint,
            },

            userMode: this.props.userMode,

            cancelToken: this.signal.token,
          })

          .then((response) => {
            this.setState({ userCompanyListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { userCompanyGrid: { ...userCompanyGrid, page: 1 } },
                  () => {
                    this.listScrollingGridUserCompany({
                      options: {},
                      pageLoad: true,
                    });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.userCompanyList = pageLoad
                      ? data
                      : prevState.userCompanyList.concat(data);

                    prevState.userCompanyHasMoreData =
                      response.total > prevState.userCompanyList.length
                        ? true
                        : false;

                    prevState.userCompanyTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization("userCompanyGeneralGrid");
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  userCompanyFetchMoreData = ({ isPaginated = false, notUpdate }) => {
    this.setState({ userCompanyListLoading: true });

    const { userCompanyGrid } = this.state;
    const { page } = userCompanyGrid;

    const per_page = this.getUserCompanyGridPageSize();

    let sortingParams = this.getUserCompanyGridSortInfo();

    this.props
      .listCompanyUsers({
        options: {
          page,
          per_page,
          ...sortingParams,

          q: { ...this.getFilters({ userType: "company" }) },
        },

        userMode: this.props.userMode,

        cancelToken: this.signal.token,

        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { userCompanyGrid: { ...userCompanyGrid, page: 1 } },
              () => {
                this.userCompanyFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.userCompanyList = data;
                } else {
                  prevState.userCompanyList =
                    prevState.userCompanyList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.userCompanyList.length <
                      this.state.userCompanyTotal
                  ) {
                    prevState.userCompanyHasMoreData = true;
                    prevState.userCompanyGrid = {
                      ...prevState.userCompanyGrid,
                      page: prevState.userCompanyGrid.page + 1,
                    };
                  } else {
                    prevState.userCompanyHasMoreData = false;
                  }
                }

                prevState.userCompanyTotal = response.total;
                prevState.userCompanyListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  if (!notUpdate) {
                    this.updatePersonalization("userCompanyGeneralGrid");
                  }
                });
              }
            );
          }
        }
      });
  };

  listScrollingUserCompany = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.userCompanyHasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.userCompanyPagination =
            this.state.userCompanyTotal ===
            mustBeArray(this.state.userCompanyList).length
              ? prevState.userCompanyPagination
              : Number(prevState.userCompanyPagination) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters({ userType: "company" });

        let tempOptions = {
          ...this.getUserCompanyTableSortInfo(),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,

            per_page:
              Number(this.state.userCompanyPagination) *
              Number(this.state.userCompanyPerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.userCompanyPagination,

            per_page: this.state.userCompanyPerPage,
          };
        }

        this.props
          .listCompanyUsers({
            options: tempOptions,

            userMode: this.props.userMode,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userCompanyListLoading: false });

            if (resp.status) {
              if (
                _.isEmpty(mustBeArray(resp.data)) &&
                Number(this.state.userCompanyPagination) !== 1
              ) {
                this.setState({ userCompanyPagination: 1 }, () => {
                  this.listScrollingUserCompany({ pageLoad: true, options });
                });
              } else {
                this.setState(
                  {
                    userCompanyList: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                          ...this.state.userCompanyList,

                          ...mustBeArray(resp.data),
                        ],
                    userCompanyTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      userCompanyHasMoreData:
                        this.state.userCompanyList.length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("userCompanyGeneralTable");
          }
        });
      }
    );
  };

  listUserCompanyGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.userCompanyGrid.page = page;
        prevState.userCompanyListLoading = true;

        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.userCompanyGrid?.page,
          per_page: this.getUserCompanyGridPageSize(),

          ...this.getUserCompanyGridSortInfo(),

          q: { ...this.getFilters({ userType: "company" }) },
        };

        const { userCompanyGrid } = this.state;

        this.props
          .listCompanyUsers({
            options: tempOptions,

            userMode: this.props.userMode,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userCompanyListLoading: false });

            if (resp.status) {
              if (
                Number(userCompanyGrid.page) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState(
                  { userCompanyGrid: { ...userCompanyGrid, page: 1 } },
                  () => {
                    this.listUserCompanyGrid(1);
                  }
                );
              } else {
                this.setState({
                  userCompanyList: mustBeArray(resp.data),
                  userCompanyTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("userCompanyGeneralGrid");
        });
      }
    );
  };

  getUserCompanyGridSortInfo = () => {
    if (
      this.state.userCompanyGrid.sortInfo?.field &&
      this.state.userCompanyGrid.sortInfo?.order &&
      this.state.userCompanySortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userCompanyGrid.sortInfo.field]:
            this.state.userCompanyGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.userCompanyGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.userCompanyGrid.sortInfo.order,
        },
      };
    } else return {};
  };

  handleUserCompanyGridChange = ({ gridView, isPaginated }) => {
    if (this.state.userCompanyGridView === gridView) {
      return false;
    }

    this.setState(
      {
        userCompanyGridView: gridView,
        userCompanyList: [],
        userCompanyListLoading: true,
      },
      () => {
        this.updatePersonalization("userCompanyListContainer");

        if (!this.state.userCompanyGridView) {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingUserCompany({ pageLoad: true, options: {} });
          } else {
            this.listCompanyUsers({ options: {} });
          }
        } else {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUserCompany({ pageLoad: true, options: {} });
          } else {
            this.userCompanyFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  userCompanyUpdatePageSize = (value) => {
    if (this.state.userCompanyGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.userCompanyGrid.row = Number(row);
          prevState.userCompanyGrid.col = Number(col);
          prevState.userCompanyGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUserCompany({ pageLoad: true, options: {} });
          } else {
            this.userCompanyFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState(
        { userCompanyPerPage: Number(value), userCompanyPagination: 1 },
        () => {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingUserCompany({ pageLoad: true, options: {} });
          } else {
            this.listCompanyUsers({ options: {} });
          }
        }
      );
    }
  };

  userCompanyUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.userCompanySelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.userCompanyGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.userCompanyGrid = {
            ...prevState.userCompanyGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridUserCompany({ pageLoad: true, options: {} });
        } else {
          this.userCompanyFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  changeStatus = ({ user, type }) => {
    const data = { ...user, enabled: !user.enabled };
    if (type === "company") {
      this.props
        .handleCompanyUserActions({
          id: user.id,
          data,
          action: "status",

          userMode: this.props.userMode,
        })

        .then((resp) => {
          if (resp.status) {
            message.success(
              `User has been ${
                data.enabled ? "enabled" : "disabled"
              } successfully`
            );

            if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridUserCompany({
                pageLoad: true,
                options: {},
              });
            } else {
              this.userCompanyFetchMoreData({ isPaginated: true });
            }
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
    } else {
      this.props
        .handlePSPUserActions({
          id: user.id,
          data,
          action: "status",

          userMode: this.props.userMode,
        })

        .then((resp) => {
          if (resp.status) {
            message.success(
              `User has been ${
                data.enabled ? "enabled" : "disabled"
              } successfully`
            );

            if (
              this.state.userPayrollServiceProviderPaginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingUserPayrollServiceProvider({
                pageLoad: true,
                options: {},
              });
            } else {
              this.userPayrollServiceProviderFetchMoreData({
                isPaginated: true,
              });
            }
          } else {
            errorHandler({
              hasValidationErrors: false,
              response: resp,
              oldModel: true,
            });
          }
        });
    }
  };

  handleUserCompanyAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/user/company/${data && data.id}`);
        break;
      case "edit":
        this.handleEdit(data, "company");
        break;
      case "sendReminder":
        this.sendMail({ user: data, type: "company" });
        break;
      case "enableDisable":
        this.changeStatus({ user: data, type: "company" });
        break;
      case "enableDisableMfa":
        this.updateAuthentication({ user: data, type: "company" });
        break;
      case "disableDeviceEnrolment":
      case "mandatoryDeviceEnrolment":
      case "optionalDeviceEnrolment":
        this.updateDeviceEnrolment({ user: data, type: "company", action });
        break;
      default:
        break;
    }
  };

  updateAuthentication = ({ user, type }) => {
    if (type === "company") {
      this.props.handleCompanyUserActions({
        id: user.id,
        data: {
          mfaEnable: user.mfaEnable,
          requestType: "mfa",
          deviceEnrolmentEnable: user.deviceEnrolmentEnable,
        },
        action: "updatemultifactorauthentication",

        userMode: this.props.userMode,
      });
      message.info(
        `Your request to ${
          !user.mfaEnable ? "enable" : "disable"
        } multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
      );
    } else {
      this.props.handlePSPUserActions({
        id: user.id,
        data: {
          mfaEnable: user.mfaEnable,
          requestType: "mfa",
          deviceEnrolmentEnable: user.deviceEnrolmentEnable,
        },
        action: "updatemultifactorauthentication",

        userMode: this.props.userMode,
      });
      message.info(
        `Your request to ${
          !user.mfaEnable ? "enable" : "disable"
        } multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
      );
    }
  };

  updateDeviceEnrolment = ({ user, type, action }) => {
    if (type === "company") {
      this.props.handleCompanyUserActions({
        id: user.id,
        data: {
          deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
          mfaEnable: user && user.mfaEnable,
          requestType: "deviceEnrolment",
        },
        action: "updateDeviceEnrolment",

        userMode: this.props.userMode,
      });
      message.info(`Your request to ${
        action === "disableDeviceEnrolment"
          ? "disable"
          : action === "mandatoryDeviceEnrolment"
          ? "enable mandatory"
          : "enable optional"
      } device enrolment for the user has been submitted.
       Please refresh the screen after two minutes.`);
    } else {
      this.props.handlePSPUserActions({
        id: user.id,
        data: {
          deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
          mfaEnable: user && user.mfaEnable,
          requestType: "deviceEnrolment",
        },
        action: "updateDeviceEnrolment",

        userMode: this.props.userMode,
      });
      message.info(`Your request to ${
        action === "disableDeviceEnrolment"
          ? "disable"
          : action === "mandatoryDeviceEnrolment"
          ? "enable mandatory"
          : "enable optional"
      } device enrolment for the user has been submitted.
       Please refresh the screen after two minutes.`);
    }
  };

  sendMail = ({ user, type }) => {
    const request =
      type === "company"
        ? this.props.handleCompanyUserActions
        : this.props.handlePSPUserActions;
    request({
      id: user.id,
      data: user,
      action: "sendreminder",

      userMode: this.props.userMode,
    }).then((resp) => {
      if (resp.status) {
        message.success(`Reminder sent successfully`);
        if (type === "company") {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUserCompany({
              pageLoad: true,
              options: {},
            });
          } else {
            this.userCompanyFetchMoreData({ isPaginated: true });
          }
        } else {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingGridUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.userPayrollServiceProviderFetchMoreData({
              isPaginated: true,
            });
          }
        }
      } else {
        errorHandler({ response: resp });
      }
    });
  };

  userCompanyUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.userCompanyGrid.sortInfo[field] === value) {
          prevState.userCompanyGrid.sortInfo[field] = "";
        } else {
          prevState.userCompanyGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userCompanyFetchMoreData({ isPaginated: true });
      }
    );
  };

  getUserCompanyGridPageSize = () => {
    const { row, col } = this.state.userCompanyGrid;

    return this.state.userCompanyPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };

  updateUserCompanyGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.userCompanyGrid.sortInfo[field] === value) {
          prevState.userCompanyGrid.sortInfo[field] = "";
        } else {
          prevState.userCompanyGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userCompanyFetchMoreData({ isPaginated: true });
      }
    );
  };

  handleUserCompanyCreate = () => {
    this.setState({ viewRegisterUserCompany: true });
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;
    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = _.cloneDeep(
        member?.details?.authorizationDOM
      );
      let updatedDOM = [];
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const userPayrollProviderListContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "userPayrollServiceProviderListContainer",
      });
      const userCompanyListContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "userCompanyListContainer",
      });
      const userFilterContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: USER_FILTER_CONTAINER,
      });
      switch (field) {
        case USER_MULTI_TAB_CONTAINER:
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims?.components,
            name: field,
          });
          break;
        case "userPayrollServiceProviderGeneralTable":
          if (userPayrollProviderListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.userPayrollServiceProviderPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.userPayrollServiceProviderPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.userPayrollServiceProviderSortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.userPayrollServiceProviderSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "userPayrollServiceProviderGeneralGrid":
          if (userPayrollProviderListContainer.personalizable === "true") {
            const { userPayrollServiceProviderGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${userPayrollServiceProviderGrid?.page}`;
            personalizations[
              GRID_ROW_INDEX
            ].value = `${userPayrollServiceProviderGrid?.row}`;
            personalizations[
              GRID_COLUMN_INDEX
            ].value = `${userPayrollServiceProviderGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              userPayrollServiceProviderGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              userPayrollServiceProviderGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "userPayrollServiceProviderListContainer":
          if (userPayrollProviderListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.userPayrollServiceProviderGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.userPayrollServiceProviderPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.userPayrollServiceProviderSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.userPayrollServiceProviderIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        // Company
        case "userCompanyGeneralTable":
          if (userCompanyListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.userCompanyPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.userCompanyPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.userCompanySortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.userCompanySortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "userCompanyGeneralGrid":
          if (userCompanyListContainer.personalizable === "true") {
            const { userCompanyGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${userCompanyGrid?.page}`;
            personalizations[GRID_ROW_INDEX].value = `${userCompanyGrid.row}`;
            personalizations[
              GRID_COLUMN_INDEX
            ].value = `${userCompanyGrid.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              userCompanyGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              userCompanyGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "userCompanyListContainer":
          if (userCompanyListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.userCompanyGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.userCompanyPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.userCompanySortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.userCompanyIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "userStatusDropdownFilter":
          if (userFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = this.state.filters?.isActive;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "userNameTextboxFilter":
          if (userFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.state.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };
        this.saveChangedPersonalizationClaims(payload);
      }

      const updateChanges = updateDomChanges({
        update,
        condition: [
          "userCompanyGeneralGrid",
          "userPayrollServiceProviderGeneralGrid",
        ].includes(field),
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update,
        });
      });
    } catch (err) {
      return err;
    }
  };

  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === newClaim?.id
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };
  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  // PSP Users
  listScrollingUserPayrollServiceProvider = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.userPayrollServiceProviderHasMoreData)
      return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.userPayrollServiceProviderPagination =
            this.state.userPayrollServiceProviderTotal ===
            mustBeArray(this.state.userPayrollServiceProviderList).length
              ? prevState.userPayrollServiceProviderPagination
              : Number(prevState.userPayrollServiceProviderPagination) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = getSelectedRoleTenantId({ member: this.props.member })
          ? {
              ...this.getFilters({ userType: "psp" }),
              serviceProviderId: getSelectedRoleTenantId({
                member: this.props.member,
              }),
            }
          : { ...this.getFilters({ userType: "psp" }) };

        let tempOptions = {
          ...this.getUserPayrollServiceProviderTableSortInfo(),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,

            per_page:
              Number(this.state.userPayrollServiceProviderPagination) *
              Number(this.state.userPayrollServiceProviderPerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.userPayrollServiceProviderPagination,

            per_page: this.state.userPayrollServiceProviderPerPage,
          };
        }

        this.props
          .listPSPUsers({
            options: tempOptions,

            userMode: this.props.userMode,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userPayrollServiceProviderListLoading: false });

            if (resp.status) {
              if (
                _.isEmpty(mustBeArray(resp.data)) &&
                Number(this.state.userPayrollServiceProviderPagination) !== 1
              ) {
                this.setState(
                  { userPayrollServiceProviderPagination: 1 },
                  () => {
                    this.listScrollingUserPayrollServiceProvider({
                      pageLoad: true,
                      options,
                    });
                  }
                );
              } else {
                this.setState(
                  {
                    userPayrollServiceProviderList: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                          ...this.state.userPayrollServiceProviderList,

                          ...mustBeArray(resp.data),
                        ],
                    userPayrollServiceProviderTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      userPayrollServiceProviderHasMoreData:
                        this.state.userPayrollServiceProviderList.length <
                        resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(
              "userPayrollServiceProviderGeneralTable"
            );
          }
        });
      }
    );
  };

  listPSPUsers = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.userPayrollServiceProviderPagination = options.page;
          prevState.userPayrollServiceProviderSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.userPayrollServiceProviderListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(
            Number(this.state.userPayrollServiceProviderPagination)
          )
            ? Number(this.state.userPayrollServiceProviderPagination)
            : 1,

          per_page: !_.isNaN(
            Number(this.state.userPayrollServiceProviderPerPage)
          )
            ? Number(this.state.userPayrollServiceProviderPerPage)
            : 10,

          ...this.getUserPayrollServiceProviderTableSortInfo(),

          q: getSelectedRoleTenantId({ member: this.props.member })
            ? {
                ...this.getFilters({ userType: "psp" }),
                serviceProviderId: getSelectedRoleTenantId({
                  member: this.props.member,
                }),
              }
            : { ...this.getFilters({ userType: "psp" }) },
        };

        this.props
          .listPSPUsers({
            options: newOptions,

            userMode: this.props.userMode,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userPayrollServiceProviderListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.userPayrollServiceProviderPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState(
                  { userPayrollServiceProviderPagination: 1 },
                  () => {
                    this.listPSPUsers({ options: {} });
                  }
                );
              } else {
                this.setState({
                  userPayrollServiceProviderList: mustBeArray(resp.data),
                  userPayrollServiceProviderTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(200).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(
              "userPayrollServiceProviderGeneralTable"
            );
          }
        });
      }
    );
  };

  getUserPayrollServiceProviderTableSortInfo = () => {
    if (
      this.state.userPayrollServiceProviderSortInfo?.field &&
      this.state.userPayrollServiceProviderSortInfo?.order &&
      this.state.userPayrollServiceProviderSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userPayrollServiceProviderSortInfo.field]:
            this.state.userPayrollServiceProviderSortInfo.order,
        },
      };
    } else return {};
  };

  listScrollingGridUserPayrollServiceProvider = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.userPayrollServiceProviderHasMoreData)
      return false;

    this.setState(
      {
        userPayrollServiceProviderListLoading: true,
        userPayrollServiceProviderGrid: {
          ...this.state.userPayrollServiceProviderGrid,
          page: !pageLoad
            ? Number(this.state.userPayrollServiceProviderGrid?.page) + 1
            : Number(this.state.userPayrollServiceProviderGrid?.page),
        },
      },
      () => {
        const { userPayrollServiceProviderGrid } = this.state;
        const { page } = userPayrollServiceProviderGrid;

        const per_page = this.getUserPayrollServiceProviderGridPageSize();

        let sortingParams = this.getUserPayrollServiceProviderGridSortInfo();

        this.props
          .listPSPUsers({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              ...sortingParams,

              q: getSelectedRoleTenantId({ member: this.props.member })
                ? {
                    ...this.getFilters({ userType: "psp" }),
                    serviceProviderId: getSelectedRoleTenantId({
                      member: this.props.member,
                    }),
                  }
                : { ...this.getFilters({ userType: "psp" }) },
            },

            userMode: this.props.userMode,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ userPayrollServiceProviderListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  {
                    userPayrollServiceProviderGrid: {
                      ...userPayrollServiceProviderGrid,
                      page: 1,
                    },
                  },
                  () => {
                    this.listScrollingGridUserPayrollServiceProvider({
                      options: {},
                      pageLoad: true,
                    });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.userPayrollServiceProviderList = pageLoad
                      ? data
                      : prevState.userPayrollServiceProviderList.concat(data);

                    prevState.userPayrollServiceProviderHasMoreData =
                      response.total >
                      prevState.userPayrollServiceProviderList.length
                        ? true
                        : false;

                    prevState.userPayrollServiceProviderTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization(
                          "userPayrollServiceProviderGeneralGrid"
                        );
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  userPayrollServiceProviderFetchMoreData = ({
    isPaginated = false,
    notUpdate,
  }) => {
    this.setState({ userPayrollServiceProviderListLoading: true });

    const { userPayrollServiceProviderGrid } = this.state;
    const { page } = userPayrollServiceProviderGrid;

    const per_page = this.getUserPayrollServiceProviderGridPageSize();

    let sortingParams = this.getUserPayrollServiceProviderGridSortInfo();

    this.props
      .listPSPUsers({
        options: {
          page,
          per_page,
          ...sortingParams,

          q: getSelectedRoleTenantId({ member: this.props.member })
            ? {
                ...this.getFilters({ userType: "psp" }),
                serviceProviderId: getSelectedRoleTenantId({
                  member: this.props.member,
                }),
              }
            : { ...this.getFilters({ userType: "psp" }) },
        },

        userMode: this.props.userMode,

        cancelToken: this.signal.token,

        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              {
                userPayrollServiceProviderGrid: {
                  ...userPayrollServiceProviderGrid,
                  page: 1,
                },
              },
              () => {
                this.userPayrollServiceProviderFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.userPayrollServiceProviderList = data;
                } else {
                  prevState.userPayrollServiceProviderList =
                    prevState.userPayrollServiceProviderList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.userPayrollServiceProviderList.length <
                      this.state.userPayrollServiceProviderTotal
                  ) {
                    prevState.userPayrollServiceProviderHasMoreData = true;
                    prevState.userPayrollServiceProviderGrid = {
                      ...prevState.userPayrollServiceProviderGrid,
                      page: prevState.userPayrollServiceProviderGrid.page + 1,
                    };
                  } else {
                    prevState.userPayrollServiceProviderHasMoreData = false;
                  }
                }

                prevState.userPayrollServiceProviderTotal = response.total;
                prevState.userPayrollServiceProviderListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  if (!notUpdate) {
                    this.updatePersonalization(
                      "userPayrollServiceProviderGeneralGrid"
                    );
                  }
                });
              }
            );
          }
        }
      });
  };

  listUserPayrollServiceProviderGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.userPayrollServiceProviderGrid.page = page;
        prevState.userPayrollServiceProviderListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.userPayrollServiceProviderGrid?.page,

          per_page: this.getUserPayrollServiceProviderGridPageSize(),

          ...this.getUserPayrollServiceProviderGridSortInfo(),

          q: getSelectedRoleTenantId({ member: this.props.member })
            ? {
                ...this.getFilters({ userType: "psp" }),
                serviceProviderId: getSelectedRoleTenantId({
                  member: this.props.member,
                }),
              }
            : { ...this.getFilters({ userType: "psp" }) },
        };

        this.props
          .listPSPUsers({
            options: tempOptions,
            userMode: this.props.userMode,
            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userPayrollServiceProviderListLoading: false });

            if (resp.status) {
              this.setState({
                userPayrollServiceProviderList: mustBeArray(resp.data),
                userPayrollServiceProviderTotal: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });

        this.updatePersonalization("userPayrollServiceProviderGeneralGrid");
      }
    );
  };

  getUserPayrollServiceProviderGridSortInfo = () => {
    if (
      this.state.userPayrollServiceProviderGrid.sortInfo?.field &&
      this.state.userPayrollServiceProviderGrid.sortInfo?.order &&
      this.state.userPayrollServiceProviderSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userPayrollServiceProviderGrid.sortInfo.field]:
            this.state.userPayrollServiceProviderGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.userPayrollServiceProviderGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.userPayrollServiceProviderGrid.sortInfo.order,
        },
      };
    } else return {};
  };

  handleUserPayrollServiceProviderGridChange = ({ gridView, isPaginated }) => {
    if (this.state.userPayrollServiceProviderGridView === gridView) {
      return false;
    }

    this.setState(
      {
        userPayrollServiceProviderGridView: gridView,
        userPayrollServiceProviderList: [],
        userPayrollServiceProviderListLoading: true,
      },
      () => {
        this.updatePersonalization("userPayrollServiceProviderListContainer");

        if (!this.state.userPayrollServiceProviderGridView) {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.listPSPUsers({ options: {} });
          }
        } else {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingGridUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  userPayrollServiceProviderUpdatePageSize = (value) => {
    if (this.state.userPayrollServiceProviderGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.userPayrollServiceProviderGrid.row = Number(row);
          prevState.userPayrollServiceProviderGrid.col = Number(col);
          prevState.userPayrollServiceProviderGrid.page = 1;
          return prevState;
        },
        () => {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingGridUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState(
        {
          userPayrollServiceProviderPerPage: Number(value),
          userPayrollServiceProviderPagination: 1,
        },
        () => {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.listPSPUsers({ options: {} });
          }
        }
      );
    }
  };

  userPayrollServiceProviderUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.userPayrollServiceProviderSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.userPayrollServiceProviderGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.userPayrollServiceProviderGrid = {
            ...prevState.userPayrollServiceProviderGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (
          this.state.userPayrollServiceProviderPaginationType ===
          PAGINATION_SCROLLING
        ) {
          this.listScrollingGridUserPayrollServiceProvider({
            pageLoad: true,
            options: {},
          });
        } else {
          this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleUserPayrollServiceProviderAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { member } = this.props;
    switch (action) {
      case "view":
        this.props.router.navigate(`/user/psp/${data && data.id}`);
        break;
      case "edit":
        this.handleEdit(data, "psp");
        break;
      case "sendReminder":
        this.sendMail({ user: data, type: "psp" });
        break;
      case "enableDisable":
        this.changeStatus({ user: data, type: "psp" });
        break;
      case "enableDisableMfa":
        this.updateAuthentication({ user: data, type: "psp" });
        break;
      case "disableDeviceEnrolment":
      case "mandatoryDeviceEnrolment":
      case "optionalDeviceEnrolment":
        this.updateDeviceEnrolment({ user: data, type: "psp", action });
        break;
      case "assignBusinessUnit":
        handleAssignBusinessUnitNavigation({ user: data, member });
        break;
      default:
        break;
    }
  };

  handleDisablePspCancel = () => {
    this.setState({ activeUser: {}, viewDisablePspUserConfirmation: false });
  };
  handleUserUnassignBusinessUnit = ({ member, match }) => {
    this.setState({ viewDisablePspUserConfirmation: false });
    handleUserUnassignBusinessUnitRedirection({
      user: this.state.activeUser,
      pspId: getMatchParams({ match: match, field: "id" }),
      member,
    });
  };

  userPayrollServiceProviderUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (
          prevState.userPayrollServiceProviderGrid.sortInfo[field] === value
        ) {
          prevState.userPayrollServiceProviderGrid.sortInfo[field] = "";
        } else {
          prevState.userPayrollServiceProviderGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
      }
    );
  };

  getUserPayrollServiceProviderGridPageSize = () => {
    const { row, col } = this.state.userPayrollServiceProviderGrid;

    return this.state.userPayrollServiceProviderPaginationType ===
      PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updateUserPayrollServiceProviderGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (
          prevState.userPayrollServiceProviderGrid.sortInfo[field] === value
        ) {
          prevState.userPayrollServiceProviderGrid.sortInfo[field] = "";
        } else {
          prevState.userPayrollServiceProviderGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
      }
    );
  };

  handleUserPayrollServiceProviderCreate = () => {
    this.setState({ viewRegisterUserPayrollServiceProvider: true });
  };

  handleTabChange = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.updatePersonalization("userMultiTabContainer");
    });
  };

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  onAddUser = (type) => {
    switch (type) {
      case "company":
        this.props.updateHeader({
          header: {
            module: "Back to Company Users",
            enableBack: true,
            title: "New User",
            gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserCreate,
            entity: "user",
            returnUrl: "/users",
          },
        });
        this.props.router.navigate(`/users/company/create`);
        break;
      case "psp":
        this.props.updateHeader({
          header: {
            module: "Back to Payroll Service Provider Users",
            enableBack: true,
            title: "New User",
            gaTitle:
              GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderUserCreate,
            entity: "user",
            returnUrl: "/users",
          },
        });
        this.props.router.navigate(`/users/psp/create`);
        break;
      default:
        break;
    }
  };
  handleEdit = (record, type) => {
    switch (type) {
      case "company":
        this.props.updateHeader({
          header: {
            module: "Back to Company Users",
            enableBack: true,
            title: "New User",
            gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserEdit,
            entity: "user",
            returnUrl: "/users",
          },
        });
        this.props.router.navigate(`/user/company/${record && record.id}/edit`);
        break;
      case "psp":
        this.props.updateHeader({
          header: {
            module: "Back to Payroll Service Provider Users",
            enableBack: true,
            title: "New User",
            gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayrollServiceProviderUserEdit,
            entity: "user",
            returnUrl: "/users",
          },
        });
        this.props.router.navigate(`/user/psp/${record && record.id}/edit`);
        break;
      default:
        break;
    }
  };
  handleDelete = (record, type) => {
    switch (type) {
      case "company":
        this.props
          .handleCompanyUserActions({
            id: record.id,
            action: "delete",

            cancelToken: this.props.signal.cancelToken,
            options: {},

            userMode: this.props.userMode,
          })

          .then((resp) => {
            if (resp.status) {
              message.success(
                `Your request to disable multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
              );

              this.listCompanyUser();
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        break;
      case "psp":
        this.props
          .handlePSPUserActions({
            id: record.id,
            action: "delete",

            cancelToken: this.props.signal.cancelToken,
            options: {},
          })

          .then((resp) => {
            if (resp.status) {
              message.success(
                `Your request to disable multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
              );

              this.listPSPUser();
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        break;
      default:
        console.log("unrecognized type");
    }
  };

  closeUserForm = () => {
    this.setState({
      newUserFormVisible: false,
      user: {},
      editMode: false,
      viewRegisterUser: false,
    });
  };

  registerCompanyUsers = (options) => {
    if (!this.state.editMode) {
      return this.props

        .registerCompanyUsers({ ...options, userMode: this.props.userMode })

        .then((resp) => {
          if (resp.status) {
            this.setState({
              newUserFormVisible: false,
              user: {},
            });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }

          return resp;
        });
    }
    return this.props
      .handleCompanyUserActions({
        ...options,
        id: this.state.user.id,
        action: "update",
        userMode: this.props.userMode,
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            user: {},
            editMode: false,
            newUserFormVisible: false,
          });

          if (this.state.userCompanyGridView) {
            if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridUserCompany({ pageLoad: true });
            } else {
              this.userCompanyFetchMoreData({ pageLoad: true });
            }
          } else {
            if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingUserCompany({ pageLoad: true });
            } else {
              this.listCompanyUsers({ options: {} });
            }
          }
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
        return resp;
      });
  };

  registerPSPUsers = (options) => {
    if (!this.state.editMode) {
      return this.props.registerPSPUsers(options).then((resp) => {
        if (resp.status) {
          this.setState({
            newUserFormVisible: false,
            user: {},
          });

          if (this.state.userPayrollServiceProviderGridView) {
            if (
              this.state.userPayrollServiceProviderPaginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridUserPayrollServiceProvider({
                pageLoad: true,
              });
            } else {
              this.userPayrollServiceProviderFetchMoreData({ pageLoad: true });
            }
          } else {
            if (
              this.state.userPayrollServiceProviderPaginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingUserPayrollServiceProvider({ pageLoad: true });
            } else {
              this.listPSPUsers({ options: {} });
            }
          }
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }

        return resp;
      });
    }
    return this.props
      .handlePSPUserActions({
        ...options,
        id: this.state.user.id,
        action: "update",
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            user: {},
            editMode: false,
            newUserFormVisible: false,
          });

          if (this.state.userPayrollServiceProviderGridView) {
            if (
              this.state.userPayrollServiceProviderPaginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridUserPayrollServiceProvider({
                pageLoad: true,
              });
            } else {
              this.userPayrollServiceProviderFetchMoreData({ pageLoad: true });
            }
          } else {
            if (
              this.state.userPayrollServiceProviderPaginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingUserPayrollServiceProvider({ pageLoad: true });
            } else {
              this.listPSPUsers({ options: {} });
            }
          }
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
        return resp;
      });
  };

  getUserStatus = (value) => {
    switch (Number(value)) {
      case 1:
        return 1;
      case 2:
        return 0;
      default:
        return 1;
    }
  };

  getFilters = ({ userType }) => {
    const { filters, pspUserFilter, companyUserFilter } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    tempFilters.profilePhoto = true;
    if (userType === "psp") {
      tempFilters.isActive = this.getUserStatus(pspUserFilter?.status);
    } else {
      tempFilters.isActive = this.getUserStatus(companyUserFilter?.status);
    }
    tempFilters.name = trimWhiteSpaces(tempFilters.name);
    return tempFilters;
  };

  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", email: "", isActive: 0 },
        userCompanyPagination: 1,
        userCompanySortInfo: {},
        userCompanyListLoading: true,

        userCompanyGrid: {
          ...this.state.userCompanyGrid,
          page: 1,
          sortInfo: {},
        },
        userCompanySelectedGridSort: undefined,
        userPayrollServiceProviderPagination: 1,
        userPayrollServiceProviderSortInfo: {},
        userPayrollServiceProviderListLoading: true,

        userPayrollServiceProviderGrid: {
          ...this.state.userPayrollServiceProviderGrid,
          page: 1,
          sortInfo: {},
        },
        userPayrollServiceProviderSelectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        if (!this.state.userPayrollServiceProviderGridView) {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.listPSPUsers({ options: {} });
          }
        } else {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingGridUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
          }
        }

        if (!this.state.userCompanyGridView) {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingUserCompany({ pageLoad: true, options: {} });
          } else {
            this.listCompanyUsers({ options: {} });
          }
        } else {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUserCompany({ pageLoad: true, options: {} });
          } else {
            this.userCompanyFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization("userNameTextboxFilter", false);
    delay(300).then(() => {
      this.updatePersonalization("userStatusDropdownFilter", false);
    });
    delay(600).then(() => {
      this.updatePersonalization(
        "userPayrollServiceProviderGeneralTable",
        false
      );
    });
    delay(900).then(() => {
      this.updatePersonalization("userPayrollServiceProviderGeneralGrid");
    });
    delay(1200).then(() => {
      this.updatePersonalization("userCompanyGeneralTable", false);
    });
    delay(1800).then(() => {
      this.updatePersonalization("userCompanyGeneralGrid");
    });
  };

  handleChange = (index, value) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [index]: value,
      },
    });
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.userCompanyListLoading = true;
        prevState.userCompanyPagination = 1;
        prevState.userCompanyGrid = { ...prevState.userCompanyGrid, page: 1 };
        prevState.userPayrollServiceProviderListLoading = true;
        prevState.userPayrollServiceProviderPagination = 1;
        prevState.userPayrollServiceProviderGrid = {
          ...prevState.userPayrollServiceProviderGrid,
          page: 1,
        };
        return prevState;
      },
      () => {
        this.updateAllPersonalization();

        if (!this.state.userPayrollServiceProviderGridView) {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.listPSPUsers({ options: {} });
          }
        } else {
          if (
            this.state.userPayrollServiceProviderPaginationType ===
            PAGINATION_SCROLLING
          ) {
            this.listScrollingGridUserPayrollServiceProvider({
              pageLoad: true,
              options: {},
            });
          } else {
            this.userPayrollServiceProviderFetchMoreData({ isPaginated: true });
          }
        }

        if (!this.state.userCompanyGridView) {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingUserCompany({ pageLoad: true, options: {} });
          } else {
            this.listCompanyUsers({ options: {} });
          }
        } else {
          if (this.state.userCompanyPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUserCompany({ pageLoad: true, options: {} });
          } else {
            this.userCompanyFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  getActiveFilters = ({ userType }) => {
    let activeFilters = [];

    let filtersApplied = this.getFilters({ userType });
    if (filtersApplied && filtersApplied.name) {
      activeFilters.push(filtersApplied.name);
    }
    return activeFilters;
  };
  handleCancelDisablePspUser = () => {
    this.setState({ viewDisablePspUserConfirmation: false, activeUser: {} });
  };

  handleActiveInactiveChange = ({ value, type }) => {
    this.setState({ [`${type}UserFilter`]: { status: value } }, () => {
      this.handleFilter();
    });
  };
  render() {
    const {
      userMode,
      handleCompanyUserActions,
      handlePSPUserActions,
      match,
      member,
      pageClaims,
      type,
      dashboardView,
    } = this.props;
    const {
      userCompanyListLoading,
      userCompanyList,
      userCompanyPagination,
      userCompanySortInfo,
      userCompanyPerPage,
      userCompanyTotal,
      userCompanyGrid,
      userCompanyGridable,
      userCompanyGridView,
      userCompanyFieldOptions,
      userCompanySortingType,
      userCompanyPaginationType,
      userCompanyIsPinned,
      userCompanyHasMoreData,
      userPayrollServiceProviderListLoading,
      userPayrollServiceProviderList,
      userPayrollServiceProviderPagination,
      userPayrollServiceProviderSortInfo,
      userPayrollServiceProviderPerPage,
      userPayrollServiceProviderTotal,
      userPayrollServiceProviderGrid,
      userPayrollServiceProviderGridable,
      userPayrollServiceProviderGridView,
      userPayrollServiceProviderFieldOptions,
      userPayrollServiceProviderSortingType,
      userPayrollServiceProviderPaginationType,
      userPayrollServiceProviderIsPinned,
      userPayrollServiceProviderHasMoreData,
      filterLoading,
      activeKey,
    } = this.state;
    // Multi Tab
    const userMultiTabContainer = _.find(
      mustBeArray(pageClaims && pageClaims.components),
      (o) => o.name === USER_MULTI_TAB_CONTAINER
    );
    // Company Users
    const userCompanyTabContainer = _.find(
      mustBeArray(userMultiTabContainer?.components),
      (o) => o.name === "userCompanyTabContainer"
    );
    const userCompanyChildTabContainer = _.find(
      mustBeArray(userCompanyTabContainer?.components),
      (o) => o.name === "userCompanyChildTabContainer"
    );
    const userCompanyListContainer = _.find(
      mustBeArray(userCompanyChildTabContainer?.components),
      (o) => o.name === "userCompanyListContainer"
    );
    const userCompanyCardGrid = getCurrentCardGrid({
      grid: this.state.userCompanyGrid,
    });

    // User PSP
    const userPayrollServiceProviderTabContainer = _.find(
      mustBeArray(userMultiTabContainer?.components),
      (o) => o.name === "userPayrollServiceProviderTabContainer"
    );
    const userPayrollServiceProviderChildTabContainer = _.find(
      mustBeArray(userPayrollServiceProviderTabContainer?.components),
      (o) => o.name === "userPayrollServiceProviderChildTabContainer"
    );
    const userPayrollServiceProviderListContainer = _.find(
      mustBeArray(userPayrollServiceProviderChildTabContainer?.components),
      (o) => o.name === "userPayrollServiceProviderListContainer"
    );

    const userPayrollServiceProviderCardGrid = getCurrentCardGrid({
      grid: this.state.userPayrollServiceProviderGrid,
    });
    const userPayrollServiceProviderBaseUrl = APIHandler.constructEndpoint({
      endpoint: "PSP_USERS_URL",
    });
    const userCompanyBaseUrl = APIHandler.constructEndpoint({
      endpoint: `COMPANY_USERS_URL${
        [userRoles.CompanyArea].includes(getUserArea()) ? "_COMPANYUSER" : ""
      }`,
    });
    const userCompanyTargetEndpoint = requestUrlBuilder(userCompanyBaseUrl, {
      q: {
        ...this.getFilters({ userType: "company" }),
      },
    });
    const userPayrollServiceProviderTargetEndpoint = requestUrlBuilder(
      userPayrollServiceProviderBaseUrl,
      {
        q: {
          ...this.getFilters({ userType: "psp" }),
        },
      }
    );
    return (
      <React.Fragment>
        <If
          condition={dashboardView && type === "company"}
          then={
            <ListContainer
              name="userCompanyListContainer"
              claims={userCompanyChildTabContainer}
              isPinned={userCompanyIsPinned}
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={userCompanyGridable}
              gridView={userCompanyGridView}
              grid={userCompanyGrid}
              per_page={userCompanyPerPage}
              GeneralTablePaginatedComponent={CompanyUserList}
              GeneralTableScrollableComponent={ScrollableUserList}
              generalTableComponentName={"userCompanyGeneralTable"}
              gridComponentName={"userCompanyGeneralGrid"}
              createLinkButtonName={USER_COMPANY_GENERAL_ADD_BUTTON}
              handleAddAction={() => this.onAddUser("company")}
              handleGridChange={this.handleUserCompanyGridChange}
              fieldOptions={userCompanyFieldOptions}
              updateGridField={this.updateUserCompanyGridField}
              sortingType={userCompanySortingType}
              paginationType={userCompanyPaginationType}
              list={userCompanyList}
              listLoading={userCompanyListLoading}
              listGrid={this.listUserCompanyGrid}
              listScrollingGrid={this.listScrollingGridUserCompany}
              hasMoreData={userCompanyHasMoreData}
              gridActionComponentName={"UserCompanyViewDetailLink"}
              moduleType={"userCompany"}
              handleGridAction={this.handleUserCompanyAction}
              listScrollingGeneralTable={this.listScrollingUserCompany}
              total={userCompanyTotal}
              current={userCompanyPagination}
              sortInfo={userCompanySortInfo}
              updatePageSize={this.userCompanyUpdatePageSize}
              listGeneralTable={({ options }) =>
                this.listCompanyUsers({ options })
              }
              tableActionComponentName={"userCompanyViewDetailLink"}
              gridPageSize={this.getUserCompanyGridPageSize()}
              cardGrid={userCompanyCardGrid}
              reminderComponent="userCompanySendReminder"
              editComponent="userCompanyEdit"
              toggleComponent="userCompanyStatusSwitchButton"
              pinnedComponentName="userCompanyIsPinned"
              showAddAndGrid={true}
              addModuleName="User"
              userType="company"
              DisplayCardComponent={UserDisplayCard}
              handleActions={this.props.handleUserCompanyActions}
              handleEdit={(record) => this.handleEdit(record, "company")}
              handleDelete={(record) => this.handleDelete(record, "company")}
              userMode={userMode}
              handleCompanyUserActions={handleCompanyUserActions}
              enableDisableComponentNameGrid="userCompanyStatusSwitchButton"
              enableDisableMFAComponentNameGrid="userCompanyEnableDisableMfa"
              member={member}
              dashboardView={dashboardView}
              componentTitle={this.props.componentTitle}
              filterApplied={this.props.filterApplied}
              targetEndpoint={this.props.targetEndpoint}
              rowIdParam="id"
            />
          }
        />
        <If
          condition={dashboardView && type === "psp"}
          then={
            <ListContainer
              name="userPayrollServiceProviderListContainer"
              claims={userPayrollServiceProviderChildTabContainer}
              isPinned={userPayrollServiceProviderIsPinned}
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={userPayrollServiceProviderGridable}
              gridView={userPayrollServiceProviderGridView}
              grid={userPayrollServiceProviderGrid}
              per_page={userPayrollServiceProviderPerPage}
              GeneralTablePaginatedComponent={CompanyUserList}
              GeneralTableScrollableComponent={ScrollableUserList}
              generalTableComponentName={
                "userPayrollServiceProviderGeneralTable"
              }
              gridComponentName={"userPayrollServiceProviderGeneralGrid"}
              createLinkButtonName={USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON}
              handleAddAction={() => this.onAddUser("psp")}
              handleGridChange={this.handleUserPayrollServiceProviderGridChange}
              fieldOptions={userPayrollServiceProviderFieldOptions}
              updateGridField={this.updateUserPayrollServiceProviderGridField}
              sortingType={userPayrollServiceProviderSortingType}
              paginationType={userPayrollServiceProviderPaginationType}
              list={userPayrollServiceProviderList}
              listLoading={userPayrollServiceProviderListLoading}
              listGrid={this.listUserPayrollServiceProviderGrid}
              listScrollingGrid={
                this.listScrollingGridUserPayrollServiceProvider
              }
              hasMoreData={userPayrollServiceProviderHasMoreData}
              gridActionComponentName={
                "payrollProviderUserPayrollServiceProvidersViewDetailLink"
              }
              moduleType={"userPayrollServiceProvider"}
              handleGridAction={this.handleUserPayrollServiceProviderAction}
              listScrollingGeneralTable={
                this.listScrollingUserPayrollServiceProvider
              }
              total={userPayrollServiceProviderTotal}
              current={userPayrollServiceProviderPagination}
              sortInfo={userPayrollServiceProviderSortInfo}
              updatePageSize={this.userPayrollServiceProviderUpdatePageSize}
              listGeneralTable={({ options }) => this.listPSPUsers({ options })}
              tableActionComponentName={
                "userPayrollServiceProviderViewDetailLink"
              }
              gridPageSize={this.getUserPayrollServiceProviderGridPageSize()}
              cardGrid={userPayrollServiceProviderCardGrid}
              reminderComponent="userPayrollServiceProviderSendReminder"
              editComponent="userPayrollServiceProviderEdit"
              toggleComponent="userPayrollServiceProviderSwitchButton"
              pinnedComponentName="userPayrollServiceProviderIsPinned"
              showAddAndGrid={true}
              addModuleName="User"
              userType="psp"
              DisplayCardComponent={UserDisplayCard}
              handleEdit={(record) => this.handleEdit(record, "psp")}
              handleDelete={(record) => this.handleDelete(record, "psp")}
              handleCompanyUserActions={handlePSPUserActions}
              enableDisableComponentNameGrid="userPayrollServiceProviderSwitchButton"
              enableDisableMFAComponentNameGrid="userPayrollServiceProviderEnableDisableMfa"
              member={member}
              dashboardView={true}
              componentTitle={this.props.componentTitle}
              filterApplied={this.props.filterApplied}
              targetEndpoint={this.props.targetEndpoint}
              rowIdParam="id"
            />
          }
        />
        <If
          condition={!dashboardView}
          then={
            <React.Fragment>
              <DisablePspUserConfirmationModal
                visible={this.state.viewDisablePspUserConfirmation}
                handleCancel={this.handleCancelDisablePspUser}
                name={getFullName({ name: this.state.activeUser })}
                handleUserUnassignBusinessUnit={
                  this.handleUserUnassignBusinessUnit
                }
                match={match}
                member={member}
              />
              <FilterContainer
                name={USER_FILTER_CONTAINER}
                claims={this.props.pageClaims}
              >
                <SingleFieldTextBoxFilter
                  placeholder="Keywords"
                  name="userNameTextboxFilter"
                  claims={this.props.claims}
                  value={this.state.filters?.name}
                  onChange={(value) => this.handleChange("name", value)}
                  onSearch={(value) => this.handleFilter("name", value)}
                  className="mb-[15px] mr-2.5 w-[200px]"
                />
                <SearchIconButton
                  loading={filterLoading}
                  onClick={this.handleFilter}
                  name="userSearchButton"
                  claims={this.props.claims}
                  className="-ml-5"
                />
                <LinkButton
                  buttonText="Reset Filter"
                  className="color-primary hand"
                  onClick={() => this.resetFilters()}
                  name="userResetFilter"
                  claims={this.props.claims}
                />
              </FilterContainer>
              <div className="custom-tabs mt-4">
                <MultiTabContainer
                  name={USER_MULTI_TAB_CONTAINER}
                  claims={pageClaims}
                  activeKey={activeKey}
                  handleTabChange={this.handleTabChange}
                >
                  {isComponentVisible({
                    claim: userPayrollServiceProviderTabContainer,
                  }) && (
                    <TabPane
                      key={"2"}
                      tab={
                        <TabHeader
                          title="Payroll Managers"
                          total={userPayrollServiceProviderTotal}
                        />
                      }
                      className={
                        userPayrollServiceProviderGridView
                          ? ""
                          : "payroll-users-tab"
                      }
                    >
                      <ChildTabContainer
                        name="userPayrollServiceProviderChildTabContainer"
                        claims={userPayrollServiceProviderTabContainer}
                      >
                        <ListContainer
                          name="userPayrollServiceProviderListContainer"
                          claims={userPayrollServiceProviderChildTabContainer}
                          isPinned={userPayrollServiceProviderIsPinned}
                          updatePinnedComponent={this.updatePinnedComponent}
                          gridable={userPayrollServiceProviderGridable}
                          gridView={userPayrollServiceProviderGridView}
                          grid={userPayrollServiceProviderGrid}
                          per_page={userPayrollServiceProviderPerPage}
                          GeneralTablePaginatedComponent={CompanyUserList}
                          GeneralTableScrollableComponent={ScrollableUserList}
                          generalTableComponentName={
                            "userPayrollServiceProviderGeneralTable"
                          }
                          gridComponentName={
                            "userPayrollServiceProviderGeneralGrid"
                          }
                          createLinkButtonName={
                            USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON
                          }
                          handleAddAction={() => this.onAddUser("psp")}
                          handleGridChange={
                            this.handleUserPayrollServiceProviderGridChange
                          }
                          fieldOptions={userPayrollServiceProviderFieldOptions}
                          updateGridField={
                            this.updateUserPayrollServiceProviderGridField
                          }
                          sortingType={userPayrollServiceProviderSortingType}
                          paginationType={
                            userPayrollServiceProviderPaginationType
                          }
                          list={userPayrollServiceProviderList}
                          listLoading={userPayrollServiceProviderListLoading}
                          listGrid={this.listUserPayrollServiceProviderGrid}
                          listScrollingGrid={
                            this.listScrollingGridUserPayrollServiceProvider
                          }
                          hasMoreData={userPayrollServiceProviderHasMoreData}
                          gridActionComponentName={
                            "payrollProviderUserPayrollServiceProvidersViewDetailLink"
                          }
                          moduleType={"userPayrollServiceProvider"}
                          handleGridAction={
                            this.handleUserPayrollServiceProviderAction
                          }
                          listScrollingGeneralTable={
                            this.listScrollingUserPayrollServiceProvider
                          }
                          total={userPayrollServiceProviderTotal}
                          current={userPayrollServiceProviderPagination}
                          sortInfo={userPayrollServiceProviderSortInfo}
                          updatePageSize={
                            this.userPayrollServiceProviderUpdatePageSize
                          }
                          listGeneralTable={({ options }) =>
                            this.listPSPUsers({ options })
                          }
                          tableActionComponentName={
                            "userPayrollServiceProviderViewDetailLink"
                          }
                          gridPageSize={this.getUserPayrollServiceProviderGridPageSize()}
                          cardGrid={userPayrollServiceProviderCardGrid}
                          reminderComponent="userPayrollServiceProviderSendReminder"
                          editComponent="userPayrollServiceProviderEdit"
                          toggleComponent="userPayrollServiceProviderSwitchButton"
                          pinnedComponentName="userPayrollServiceProviderIsPinned"
                          showAddAndGrid={true}
                          addModuleName="User"
                          userType="psp"
                          DisplayCardComponent={PayrollUserDisplayCard}
                          handleEdit={(record) =>
                            this.handleEdit(record, "psp")
                          }
                          handleDelete={(record) =>
                            this.handleDelete(record, "psp")
                          }
                          handleCompanyUserActions={handlePSPUserActions}
                          enableDisableComponentNameGrid="userPayrollServiceProviderSwitchButton"
                          enableDisableMFAComponentNameGrid="userPayrollServiceProviderEnableDisableMfa"
                          member={member}
                          match={match}
                          targetEndpoint={
                            userPayrollServiceProviderTargetEndpoint
                          }
                          hasNoDuplicateEntity={true}
                          componentTitle="Payroll Service Provider Users"
                          filterApplied={filteredByLabel({
                            filters: this.getActiveFilters({
                              userType: "psp",
                            }),
                          })}
                          assignBusinessUnit="userPayrollServiceProviderAssignBusinessUnit"
                          assignBusinessUnitGrid="userPayrollServiceProviderAssignBusinessUnit"
                          rowIdParam="id"
                          CustomActions={
                            <ToggleOptionRadioButton
                              positiveId={ACTIVE_FILTER_ID}
                              positiveText="Active"
                              negativeId={INACTIVE_FILTER_ID}
                              negativeText="Inactive"
                              onChange={(value) =>
                                this.handleActiveInactiveChange({
                                  value,
                                  type: "psp",
                                })
                              }
                              value={Number(this.state.pspUserFilter?.status)}
                              claims={userPayrollServiceProviderListContainer}
                              name="userPayrollServiceProviderStatusToggleButton"
                              hasComponents={true}
                            />
                          }
                          hideUserStatus={true}
                        />
                      </ChildTabContainer>
                    </TabPane>
                  )}
                  {isComponentVisible({ claim: userCompanyTabContainer }) && (
                    <TabPane
                      key={"1"}
                      tab={
                        <TabHeader
                          title="Company Users"
                          total={userCompanyTotal}
                        />
                      }
                    >
                      <ChildTabContainer
                        name="userCompanyChildTabContainer"
                        claims={userCompanyTabContainer}
                      >
                        <ListContainer
                          name="userCompanyListContainer"
                          claims={userCompanyChildTabContainer}
                          isPinned={userCompanyIsPinned}
                          updatePinnedComponent={this.updatePinnedComponent}
                          gridable={userCompanyGridable}
                          gridView={userCompanyGridView}
                          grid={userCompanyGrid}
                          per_page={userCompanyPerPage}
                          GeneralTablePaginatedComponent={CompanyUserList}
                          GeneralTableScrollableComponent={ScrollableUserList}
                          generalTableComponentName={"userCompanyGeneralTable"}
                          gridComponentName={"userCompanyGeneralGrid"}
                          createLinkButtonName={USER_COMPANY_GENERAL_ADD_BUTTON}
                          handleAddAction={() => this.onAddUser("company")}
                          handleGridChange={this.handleUserCompanyGridChange}
                          fieldOptions={userCompanyFieldOptions}
                          updateGridField={this.updateUserCompanyGridField}
                          sortingType={userCompanySortingType}
                          paginationType={userCompanyPaginationType}
                          list={userCompanyList}
                          listLoading={userCompanyListLoading}
                          listGrid={this.listUserCompanyGrid}
                          listScrollingGrid={this.listScrollingGridUserCompany}
                          hasMoreData={userCompanyHasMoreData}
                          gridActionComponentName={"UserCompanyViewDetailLink"}
                          moduleType={"userCompany"}
                          handleGridAction={this.handleUserCompanyAction}
                          listScrollingGeneralTable={
                            this.listScrollingUserCompany
                          }
                          total={userCompanyTotal}
                          current={userCompanyPagination}
                          sortInfo={userCompanySortInfo}
                          updatePageSize={this.userCompanyUpdatePageSize}
                          listGeneralTable={({ options }) =>
                            this.listCompanyUsers({ options })
                          }
                          tableActionComponentName={"userCompanyViewDetailLink"}
                          gridPageSize={this.getUserCompanyGridPageSize()}
                          cardGrid={userCompanyCardGrid}
                          reminderComponent="userCompanySendReminder"
                          editComponent="userCompanyEdit"
                          toggleComponent="userCompanyStatusSwitchButton"
                          pinnedComponentName="userCompanyIsPinned"
                          showAddAndGrid={true}
                          addModuleName="User"
                          userType="company"
                          DisplayCardComponent={UserDisplayCard}
                          handleActions={this.props.handleUserCompanyActions}
                          handleEdit={(record) =>
                            this.handleEdit(record, "company")
                          }
                          handleDelete={(record) =>
                            this.handleDelete(record, "company")
                          }
                          userMode={userMode}
                          handleCompanyUserActions={handleCompanyUserActions}
                          enableDisableComponentNameGrid="userCompanyStatusSwitchButton"
                          enableDisableMFAComponentNameGrid="userCompanyEnableDisableMfa"
                          member={member}
                          match={match}
                          targetEndpoint={userCompanyTargetEndpoint}
                          hasNoDuplicateEntity={true}
                          componentTitle="Company Users"
                          filterApplied={filteredByLabel({
                            filters: this.getActiveFilters({
                              userType: "company",
                            }),
                          })}
                          rowIdParam="id"
                          CustomActions={
                            <ToggleOptionRadioButton
                              positiveId={ACTIVE_FILTER_ID}
                              positiveText="Active"
                              negativeId={INACTIVE_FILTER_ID}
                              negativeText="Inactive"
                              onChange={(value) =>
                                this.handleActiveInactiveChange({
                                  value,
                                  type: "company",
                                })
                              }
                              value={Number(
                                this.state.companyUserFilter?.status
                              )}
                              claims={userCompanyListContainer}
                              name="userCompanyStatusToggleButton"
                              hasComponents
                            />
                          }
                          hideUserStatus
                        />
                      </ChildTabContainer>
                    </TabPane>
                  )}
                </MultiTabContainer>
              </div>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(CompanyUser);
