import styled from 'styled-components';

import { BodySmallMedium, Colour } from '@xemplo/style-constants';

export const AmendmentFileItem = styled.button`
  border: none;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;
  padding: 12px 20px;
  height: 48px;
  background: ${Colour.Gray[50]};
  border-radius: 8px;
  color: ${Colour.Gray[500]};
  ${BodySmallMedium};
  text-decoration: underline;
  cursor: pointer;
  & svg {
    font-size: 16px;
  }
`;
