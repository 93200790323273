import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnChangeFn, SortingState } from '@tanstack/react-table';

import { PersonaRole } from '@xemplo/gp-types';
import { useGetPayrunListQuery } from '@xemplo/payrun-query';
import { useRoleAuthorisation } from '@xemplo/roles-query';
import { useServerSideState } from '@xemplo/table';
import { buildSortParams } from '@xemplo/url-helper';
import { usePayrunsType } from '@xemplo/use-payruns-type';

import { columns, SetColumnVisiblityByRoleCode } from './payrun-base-table.helper';
import * as S from './payrun-base-table.styles';
import { PayrunBaseTableProps } from './payrun-base-table.types';
import { usePayrunTableFilters } from './use-payrun-table-filters';

export function PayrunBaseTable(props: Readonly<PayrunBaseTableProps>) {
  const { filter } = props;
  const { payrunsType } = usePayrunsType();
  const navigate = useNavigate();
  const {
    data: roleAuthData,
    isLoading: roleAuthLoading,
    isInRole,
  } = useRoleAuthorisation();
  const role = roleAuthData?.result?.roleName;
  const [sorting, setSorting] = useState<SortingState>([{ desc: true, id: 'payDate' }]);

  const overflowControl = useMemo(() => {
    return isInRole([PersonaRole.CompanyAdmin, PersonaRole.CompanyAccountant])
      ? 800
      : 1450;
  }, [isInRole]);

  const { pagination, onPaginationChange } = useServerSideState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { pageIndex, pageSize } = pagination;

  const handleSortingChange: OnChangeFn<SortingState> = (sorting) => {
    setSorting(sorting);
  };

  const { queryFilter } = usePayrunTableFilters({ filter });

  useEffect(() => {
    pagination.pageIndex = 0;
  }, [payrunsType, filter]);

  const { data, isLoading } = useGetPayrunListQuery({
    requestParams: {
      page: pageIndex + 1,
      per_page: pageSize,
      ...buildSortParams(sorting),
      q: {
        ...queryFilter,
      },
    },
  });

  if (roleAuthLoading) return null;
  return (
    <S.PayrunListTable
      data={data?.result?.rows ?? []}
      tableOverflowControlMinWidth={overflowControl}
      columns={columns}
      enablePagination
      isListTable={true}
      manualPagination
      enableRowSelection={false}
      onPaginationChange={onPaginationChange}
      enableSorting
      pageCount={data?.result?.total_Pages ?? 0}
      onSortingChange={handleSortingChange}
      state={{
        pagination,
        sorting,
        columnVisibility: role ? SetColumnVisiblityByRoleCode(role) : {},
      }}
      totalRowCount={data?.result?.total ?? 0}
      isLoading={isLoading}
      onBodyRowClick={(e, row) => {
        navigate(row.original.payrunId);
      }}
    />
  );
}

export default PayrunBaseTable;
