import { Row } from '@tanstack/react-table';

import { Badge, BadgeColour } from '@xemplo/badge';
import { PayrunDetail, PayrunSteps } from '@xemplo/gp-types';
import { DawnTick16 } from '@xemplo/icons';

import { getMilestoneDateObj } from '../payrun-base-table.helper';

export const MilestoneCell = (props: Row<PayrunDetail>) => {
  const { original } = props;
  const { dateDiff, activeStep } = getMilestoneDateObj(original);

  const badgeProps = () => {
    const overdue = dateDiff < 0;
    const dueSoon = dateDiff >= 0 && dateDiff < 7;
    if (activeStep === 'Draft')
      return { color: BadgeColour.LightGray, value: activeStep };
    if (activeStep === PayrunSteps.Complete)
      return {
        color: BadgeColour.LightGreen,
        value: 'Completed',
        leadingIcon: <DawnTick16 />,
      };
    if (overdue) return { color: BadgeColour.Red, value: `${activeStep} overdue` };
    if (dueSoon) return { color: BadgeColour.Orange, value: `${activeStep} due` };
    return { color: BadgeColour.LightGray, value: activeStep ?? '' };
  };

  return <Badge {...badgeProps()} />;
};
