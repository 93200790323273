//@ts-nocheck
import React from "react";
import { getClaimAndStatus, mustBeArray } from "../../libs/utilities";
import { VISIBLE_CLAIMS_ID } from "../../constants/defaultClaims";
import { Breadcrumb } from "antd";
import _ from "lodash";
import {
  SYSTEM_ADMIN_USERNAME,
  SYSTEM_ADMIN_AUTHORIZED_ROUTES,
  PSP_USER_AUTHORIZED_ROUTES,
  COMPANY_USER_AUTHORIZED_ROUTES,
} from "../../constants/authorizationClaims";
import userRoles from "../../constants/userRoles";
import { Link } from "react-router-dom";

const BreadCrumbContainer = (props: any) => {
  const { claims, className, name, member } = props;
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  const claim = claimAndStatus && claimAndStatus.claim;
  let routes = _.sortBy(
    _.filter(mustBeArray(claim.routes), (o) => o.visible === "true"),
    (o) => o.orderId
  );
  let authorizedRoutes: any;
  let userArea =
    member &&
    member.details &&
    member.details.selectedRole &&
    member.details.selectedRole.area;
  if (
    member &&
    member.details &&
    member.details.username === SYSTEM_ADMIN_USERNAME
  ) {
    authorizedRoutes = SYSTEM_ADMIN_AUTHORIZED_ROUTES;
  } else {
    switch (`api/${userArea && userArea.toLowerCase()}`) {
      case userRoles.PSPArea:
      case "api/ps":
        authorizedRoutes = PSP_USER_AUTHORIZED_ROUTES;
        break;
      case userRoles.CompanyArea:
        authorizedRoutes = COMPANY_USER_AUTHORIZED_ROUTES;
        break;
      default:
        break;
    }
  }
  const filteredRoutes = _.filter(mustBeArray(routes), (o) =>
    mustBeArray(authorizedRoutes).includes(o.targetUrl)
  );
  return VISIBLE_CLAIMS_ID.includes(Number(claim.authorizationStatusId)) ? (
    <Breadcrumb className={`${className} ${claimAndStatus.status}`}>
      {mustBeArray(filteredRoutes).map((item: any, i: any) => {
        return (
          <Breadcrumb.Item key={i}>
            <Link to={item.targetUrl} className="color-primary">
              {item.title}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  ) : (
    <React.Fragment />
  );
};

export default BreadCrumbContainer;
