// @ts-nocheck
import { http, ResponseHandler, SimpleErrorHandler } from "../../libs";
import { requestBuilder } from "../../libs/utilities";
import { businessUnitAction } from "../../constants/action-type/index";
import APIHandler from "../../constants/apiUrl";

const { LIST_BUSINESS_UNIT, BUSINESS_UNIT_DETAIL } = businessUnitAction;

export function listBusinessUnits({
  id,
  options,
  cancelToken,
  targetEndpoint = null,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        profilePhoto: true,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url =
        targetEndpoint ||
        APIHandler.constructEndpoint({
          endpoint: "BUSINESS_UNIT_URL",
        });
      const response = await requestBuilder(
        targetEndpoint ? null : id,
        url,
        targetEndpoint ? {} : queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              LIST_BUSINESS_UNIT
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: LIST_BUSINESS_UNIT,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getBusinessUnit({
  id,
  cancelToken,
  options,
  targetEndpoint = null,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url =
        targetEndpoint ||
        APIHandler.constructEndpoint({
          endpoint: "BUSINESS_UNIT_URL",
        });
      const response = await requestBuilder(
        targetEndpoint ? null : id,
        url,
        targetEndpoint ? {} : queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              BUSINESS_UNIT_DETAIL
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function saveBusinessUnit(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "BUSINESS_UNIT_URL",
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "BUSINESSUNIT_SAVE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updateBusinessUnit(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "BUSINESS_UNIT_URL",
      });
      const response = await http.put(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "BUSINESSUNIT_UPDATE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listTaxFees({ id, cancelToken, options }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        // per_page: 0,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "TAX_FEE_URL",
        options: { id },
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, "TAX_FEES")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listBillingServiceProviders({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "BILLING_PROVIDERS_URL",
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "BILLING_PROVIDERS"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listBillingAccounts({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "BILLING_ACCOUNT_URL",
        options: { id },
      });
      queryOptions = { ...queryOptions, q: { enabled: true } };
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "BILLING_ACCOUNTS"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listCurrency({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        per_page: 0,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({ endpoint: "CURRENCY_URL" });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, "CURRENCY_LIST")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listClientsEmployees({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const tempUrl = APIHandler.constructEndpoint({
        endpoint: "CLIENT_EMPLOYEES_URL",
        options: { id },
      });
      const url = `${tempUrl}?per_page=500`;
      dispatch({ type: "SET_PROGRESS_BAR" });
      const response = await requestBuilder(null, url, {}, cancelToken);
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, "EMPLOYEES_LIST")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function handleAirmaxAccount({
  id,
  options,
  action,
  data,
  cancelToken,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: "SET_PROGRESS_BAR" });
      let url = "";
      let response = "";
      let action_type = "";
      switch (action) {
        case "get":
          action_type = "AIRMAX_ACCOUNT_GET";
          break;
        case "create":
          url = `${APIHandler.constructEndpoint({
            endpoint: "AIRWALLEX_URL",
            options: { id },
          })}/add`;
          response = await http.post(url, data, cancelToken);
          action_type = "AIRMAX_ACCOUNT_CREATE";
          break;
        case "delete":
          url = `${APIHandler.constructEndpoint({
            endpoint: "AIRWALLEX_URL",
            options: { id },
          })}/delete`;
          response = await http.del(url);
          action_type = "AIRMAX_ACCOUNT_DELETE";
          break;
        case "activate":
          action_type = "AIRMAX_ACCOUNT_ACTIVATE";
          break;
        case "deactivate":
          action_type = "AIRMAX_ACCOUNT_DEACTIVATE";
          break;
        default:
          break;
      }
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, action_type)
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function importKeypayEmployees({ payload }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "IMPORT_EMPLOYEE_URL",
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "IMPORT_EMPLOYEE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function businessunitHandler({ id, cancelToken, payload, action }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let response = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "getCurrencyFees":
          url = APIHandler.constructEndpoint({
            endpoint: "BUSINESSUNIT_CURRENCY_FEES_URL",
            options: { id },
          });
          response = await http.get(url, cancelToken).catch(SimpleErrorHandler);
          break;
        case "assignPayrollManager":
          url = APIHandler.constructEndpoint({
            endpoint: "BU_ASSIGN_PM_URL",
            options: { id },
          });
          response = await http
            .post(url, payload, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "assignPayrollManagerMultiple":
          url = APIHandler.constructEndpoint({
            endpoint: "PM_ASSIGN_MULTIPLE_BU_URL",
            options: { id },
          });
          response = await http
            .post(url, payload, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "unassignSinglePM":
          url = APIHandler.constructEndpoint({
            endpoint: "BU_UNASSIGN_USERS_URL",
          });
          response = await http
            .post(url, payload, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "unassignAllPMs":
          url = APIHandler.constructEndpoint({
            endpoint: "BU_UNASSIGN_ALL_USERS_URL",
          });
          response = await http
            .post(url, payload, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function syncEmployeesFromKeypay(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "SYNC_EMPLOYEES_FROM_KEYPAY",
        options: { id },
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function syncDeductionCategoriesFromKeypay(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "SYNC_DEDUCTION_CATEGORIES_FROM_KEYPAY",
        options: { id },
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function syncPayCategoriesFromKeypay(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "SYNC_PAY_CATEGORIES_FROM_KEYPAY",
        options: { id },
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getIntegrationSettings(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "GET_INTEGRATION_SETTINGS",
        options: { id },
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updateIntegrationSettings(payload) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "SET_INTEGRATION_SETTINGS",
      });
      const response = await http.put(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
