//@ts-nocheck
import React from "react";
import { Badge } from "antd";

const TabHeader = (props: any) => {
  const { title, total, badgeClassName, type, hideTotal } = props;
  let backgroundClassName;
  switch (type) {
    case "primary":
      backgroundClassName = "bg-badge-primary";
      break;
    case "danger":
      backgroundClassName = "bg-badge-danger";
      break;
    case "warning":
      backgroundClassName = "bg-badge-warning";
      break;
    case "disabled":
      backgroundClassName = "bg-badge-warning";
      break;
    default:
      backgroundClassName = "bg-badge-primary";
      break;
  }
  return (
    <div>
      <span>{title}</span>
      {total && !hideTotal ? (
        <Badge
          showZero
          count={total}
          className={`${
            badgeClassName ? badgeClassName : "color-primary"
          } ml-4 ${backgroundClassName}`}
          overflowCount={5000}
        />
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};
export default TabHeader;
