import {
  getLowerCase,
  getSearchParamName,
  isUserCompanyAdmin,
  mustBeArray,
} from "./index";
import { trimWhiteSpaces } from "./utilities";
import _ from "lodash";

export const getActiveDashboard = () => {
  const searchField = getSearchParamName(window.location?.search);
  const activeDashboard = searchField ? searchField["?dashboard"] : "Default";
  return activeDashboard;
};

export const activeDashboardIsDefault = () => {
  let activeDashboard = getActiveDashboard();
  return getLowerCase(activeDashboard) === "default";
};

export const removeDefaultDashboard = ({
  list,
  member,
}: {
  list: Array<any>;
  member: object;
}) => {
  if (isUserCompanyAdmin({ member })) {
    return mustBeArray(list).filter((o) => getLowerCase(o.name) !== "default");
  }
  return list ?? [];
};

export const getSelectedDashboardList = ({
  dashboardList,
}: {
  dashboardList: Array<any>;
}) => {
  const activeDashboard = getActiveDashboard();
  let selectedDashboardList = _.find(
    dashboardList,
    (o) =>
      getLowerCase(trimWhiteSpaces(o.name)) ===
      getLowerCase(trimWhiteSpaces(activeDashboard))
  );
  return selectedDashboardList;
};
