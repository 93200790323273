//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import _ from "lodash";
import TableActionComponent from "../common/tableActions";
import { Table } from "antd";
import {
  mustBeArray,
  findScrollableTableHeight,
} from "../../../libs/utilities";
import { withRouter } from "../../../hooks";

class ScrollableEmailList extends React.Component {
  componentDidMount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .addEventListener("scroll", (event) => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          if (
            (currentScroll >= maxScroll || maxScroll - currentScroll < 0.5) &&
            this.props.hasMoreData
          ) {
            this.props.handleTableChange({ options: {}, pageLoad: false });
          }
        });
  }
  componentWillUnmount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .removeEventListener("scroll", () => {
          console.log("     ");
        });
  }
  handleEdit = (record: any) => {
    this.props.router.navigate(`/emailtemplate/${record.id}`);
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: sorter.field === "templateName" ? "name" : sorter.field,
    };
    this.props.handleTableChange({
      options,
    });
  };
  handleAction = ({ record, action }: any) => {
    switch (action) {
      case "edit":
        return this.handleEdit(record);
      case "delete":
        break;
      default:
        break;
    }
  };

  render() {
    const {
      per_page,
      sortInfo,
      data,
      loading,
      editComponent,
      displayActionIcons,
    } = this.props;
    const size = this.props.size ? this.props.size : "default";

    const colTemplate = [
      {
        title: "Name",
        dataIndex: "templateName",
        sorter: true,
        sortOrder:
          sortInfo && sortInfo.field === "name" && sortInfo.order
            ? sortInfo.order === "asc"
              ? "ascend"
              : "descend"
            : false,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: true,
        sortOrder:
          sortInfo && sortInfo.field === "description" && sortInfo.order
            ? sortInfo.order === "asc"
              ? "ascend"
              : "descend"
            : false,
      },
    ];
    const actions = [
      {
        title: "Actions",
        key: "action",
        width: "10%",

        render: (record: any) => (
          <TableActionComponent
            record={record}
            handleAction={this.handleAction}
            editComponentName={editComponent}
            claims={this.props.claims}
            displayActionIcons={displayActionIcons}
          />
        ),
      },
    ];
    const column = _.concat(tableColumnGenerator(colTemplate), actions);
    return (
      <Table
        columns={column}
        size={size}
        rowKey={(record, index) => index}
        dataSource={mustBeArray(data)}
        pagination={false}
        loading={loading}
        scroll={{
          y: findScrollableTableHeight({
            per_page,
            total: mustBeArray(data).length,
          }),
        }}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default withRouter(ScrollableEmailList);
