import { DragEvent, useCallback, useRef, useState } from 'react';
import Papa from 'papaparse';

import { Dropzone } from '@xemplo/dropzone';
import { DawnCircleInformation } from '@xemplo/icons';

import { validateCsv } from './bulk-upload.helper';
import * as S from './bulk-upload.styles';
import { ParsedCsvItem, UploadedCsvItem } from './bulk-upload.types';
import { BulkUploadErrors } from './bulk-upload-errors';
import { FileItem } from './file-item';

export type BulkAmendmentUploaderProps = {
  setCsvData: (data: ParsedCsvItem[]) => void;
  setCsvDataWarnings: (data: string[]) => void;
};
export const BulkAmendmentUploader = (props: BulkAmendmentUploaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [csvErrors, setCsvErrors] = useState<string[]>([]);
  const { setCsvData, setCsvDataWarnings } = props;

  const handleValidatedData = useCallback(
    (validatedData: ParsedCsvItem[], errorList: string[], warningList: string[]) => {
      setCsvErrors(errorList);
      if (errorList.length === 0) {
        setCsvData(validatedData);
      } else {
        setCsvData([]);
      }
      setCsvDataWarnings(warningList);
    },
    [setCsvData, setCsvDataWarnings]
  );

  const handleFileUpload = useCallback(
    (files: FileList | null) => {
      if (!files) {
        return;
      }
      const file = files?.[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: 'greedy',
        complete: (results: { data: UploadedCsvItem[] }) => {
          const { validatedData, errorList, warningList } = validateCsv({
            data: results.data,
          });
          handleValidatedData(validatedData, errorList, warningList);
        },
      });
    },
    [handleValidatedData]
  );

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (inputRef?.current) {
      inputRef.current.files = files;
      handleFileUpload(files);
    }
  };

  const openFileDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const input = inputRef.current as HTMLInputElement;
    input.click();
  };

  const removeFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (inputRef?.current) {
      const dt = new DataTransfer();
      inputRef.current.files = dt.files;
      setCsvData([]);
      setCsvErrors([]);
    }
  };
  return (
    <S.UploaderContainer>
      <S.UploaderHeader>
        Upload Amendments <DawnCircleInformation />
      </S.UploaderHeader>
      <input
        ref={inputRef}
        hidden
        type="file"
        accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(e) => handleFileUpload(e.target.files)}
      />
      {inputRef?.current?.files?.length ? (
        <>
          <FileItem
            file={inputRef.current?.files[0]}
            error={csvErrors.length > 0}
            onCancel={removeFile}
          />
          <BulkUploadErrors errors={csvErrors} />
        </>
      ) : (
        <Dropzone
          dragEventHandlers={{
            onDrop: handleDrop,
            onDragEnd: (e) => e.preventDefault(),
            onDragEnter: (e) => e.preventDefault(),
            onDragLeave: (e) => e.preventDefault(),
            onDragOver: (e) => e.preventDefault(),
          }}
          actionMessage={
            <p>
              Drag and drop files, or{' '}
              <S.FileOpenButton onClick={openFileDialog}>Browse</S.FileOpenButton>
            </p>
          }
          actionMessageHelperText="CSV (max. 5MB)"
        />
      )}
    </S.UploaderContainer>
  );
};
