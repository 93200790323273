import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getKeypaySsoSingleUrl,
  updateHeader,
} from "../../../User/actions/member";
import { getReportBusinessUnitList } from "../../actions/lookup";
import { CenteredLoaderDots } from "@xemplo/loader";
import { IconButton, IconButtonSize } from "@xemplo/icon-button";
import { DawnArrowLongLeft24 } from "@xemplo/icons";
import {
  DEFAULT_HOME_ROUTE,
  GLOBAL_PAYROLL_APPLICATION_NAME,
  REPORTS_MENU_ITEM,
} from "../../../constants";
import { ResponseHandler, isComponentVisible } from "../../../libs";
import { UNATHORIZED_ACCESS_ERRORS } from "../../../constants/errors";
import { useNavigate } from "react-router";
import { message } from "antd";
import { TextDropdown, Item } from "@xemplo/text-dropdown";
import { BusinessUnitType } from "../../../types";
import { InputFieldSize } from "@xemplo/input-utils";

/** @knipignore */
export const Reports = () => {
  const dispatch = useAppDispatch();
  const [businessUnitList, setBusinessUnitList] = useState<BusinessUnitType[]>(
    []
  );
  const [businessUnitId, setBusinessUnitId] = useState<string>();
  const [url, setUrl] = useState<string>();
  const navigate = useNavigate();
  const authDom = useAppSelector(
    (state) => state?.member?.details?.authorizationDOM
  );
  //Check user has access to reports claim
  useEffect(() => {
    if (authDom) {
      const applicationDom = authDom?.find(
        (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
      );
      const reports = applicationDom?.components.find(
        (o) => o.name === REPORTS_MENU_ITEM
      );
      if (!isComponentVisible({ claim: reports })) {
        // If user doesn't have access , redirect to home.
        message.error(UNATHORIZED_ACCESS_ERRORS);
        navigate(DEFAULT_HOME_ROUTE);
      }
    }
  }, [authDom]);

  useEffect(() => {
    //Update page header
    dispatch(
      updateHeader({
        header: {
          title: "Reports",
          module: "",
          enableBack: false,
          entity: "reports",
        },
      })
    );

    //Fetch Bu List
    getReportBusinessUnitList({
      options: { per_page: 0, page: 1 },
    }).then((resp: any) => {
      if (resp?.data?.result?.rows?.length === 0 || resp?.status === 204) {
        message.warning(
          "Business Unit yet to be created. Please contact your Payroll Manager"
        );
        navigate(DEFAULT_HOME_ROUTE);
        return;
      }
      if (resp?.data?.result?.rows?.length >= 1) {
        setBusinessUnitList(resp?.data?.result?.rows);
        setBusinessUnitId(resp?.data?.result?.rows[0]?.id);
      }
    });
  }, []);

  /** Fetch the the reports url for iFrame rendering */
  const getUrl = async (businessUnitId: string) => {
    const response = await getKeypaySsoSingleUrl(businessUnitId);
    if (ResponseHandler.isValidStatus(response)) {
      setUrl(response?.data?.result?.url);
    }
  };

  useEffect(() => {
    if (businessUnitId) {
      setUrl(undefined);
      getUrl(businessUnitId);
    }
  }, [businessUnitId]);

  // keep iFrame alive
  //https://api.keypay.com.au/guides/iframe
  useEffect(() => {
    const keepIFrameAlive = setInterval(() => {
      (
        document.getElementById("keypay-reports-frame") as HTMLIFrameElement
      )?.contentWindow?.postMessage("session-keep-alive", "*");
    }, 3000);

    return () => clearInterval(keepIFrameAlive);
  }, []);

  return (
    <>
      <TextDropdown
        defaultItems={businessUnitList}
        inputSize={InputFieldSize.Medium}
        isDisabled={businessUnitList.length === 0}
        aria-label="Business unit dropdown menu"
        placeholder="Select a Business Unit"
        onSelectionChange={(id) => setBusinessUnitId(id?.toString())}
        selectedKey={businessUnitId}
      >
        {(item: BusinessUnitType) => (
          <Item textValue={item.name} key={item.id}>
            {item.name}
          </Item>
        )}
      </TextDropdown>

      <div className="h-[calc(100%-112px)] relative mt-2">
        {url ? (
          <>
            <div className="flex gap-2 mb-2">
              <IconButton
                ariaLabel="back-btn"
                id="back-btn"
                size={IconButtonSize.Small}
                onClick={() => history.back()}
              >
                <DawnArrowLongLeft24 />
              </IconButton>
            </div>
            <iframe
              id="keypay-reports-frame"
              src={url}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </>
        ) : (
          <CenteredLoaderDots />
        )}
      </div>
    </>
  );
};

/** @alias */
export default Reports;
