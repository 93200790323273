import styled from 'styled-components';

import {
  BodyStandardRegular,
  BodyXSmallRegular,
  Colour,
  HeadingSmallMedium,
} from '@xemplo/style-constants';

/** Confirm remove prompt */
export const PromptHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: ${Colour.Gray[800]};
  ${HeadingSmallMedium};
  & > svg {
    height: 40px;
    width: 40px;
    color: ${Colour.Red[500]};
  }
`;

export const PromptBody = styled.div`
  text-align: center;
  color: ${Colour.Gray[600]};
  ${BodyStandardRegular};
`;

export const ErrorMessage = styled.div`
  display: flex;
  padding: 8px;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${Colour.Red[300]};
  background: ${Colour.Red[50]};
  color: ${Colour.Gray[600]};
  justify-content: flex-start;
  ${BodyXSmallRegular};
  & > svg {
    color: ${Colour.Red[500]};
    height: 24px;
    width: 24px;
  }
`;
