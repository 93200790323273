import { SVGProps } from 'react';
import styled from 'styled-components';

/**
 * This is a special icon to be used in conjunction with other icons as a path filler.
 *
 * @example
 *
 * const StyledIcon = styled.span`
 * ...some other styling
 * $[data-active='true'] {
 *   & path {
 *     fill: url(#primaryGradient);
 *   }
 * }
 * `;
 *
 *
 * const Component = () => {
 *  const [active, setActive] = useState(false);
 *  ...some logic to setActive
 *
 *  return (
 *  <Wrapper>
 *   <StyledIcon data-active={active}><DawnCross16 /></StyledIcon>
 *   <IconGradient />
 *  </Wrapper>
 * );
 * }
 *
 */
export const IconGradient = styled(Gradient)`
  position: absolute;
`;

function Gradient(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="0"
      width="0"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          id="primaryGradient"
          x1="0"
          y1="7.62939e-06"
          x2="19.4905"
          y2="19.8362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3991FF"></stop>
          <stop offset="0.501502" stopColor="#A48AFB"></stop>
          <stop offset="1" stopColor="#FA7066"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
