import { createSlice } from '@reduxjs/toolkit';

import {
  AuthorizationDOM,
  ExpedoUserProfile,
  MemberState,
  PageHeader,
  UserPersona,
} from '@xemplo/gp-types';

const initialState: MemberState = {
  forgotMsg: '',
  error: '',
  timestamp: '',
  loadProfile: false,
};

/**
 * Creates a state slice and its reducers for member specific data
 *
 * NOTE: It's ok to set the value directly to the state object
 * under the hood, @reduxjs/toolkit uses immer to ensure the state is immutable
 * https://redux-toolkit.js.org/usage/immer-reducers
 */
const memberSlice = createSlice({
  name: 'member',
  initialState: { ...initialState },
  reducers: {
    loadProfile(state, action: { payload?: boolean }) {
      state.loadProfile = action.payload ?? true;
    },
    setDetails(state, action: { payload: ExpedoUserProfile }) {
      state.loadProfile = false;
      state.details = action.payload;
    },
    setClaims(state, action) {
      state.claims = action.payload;
    },
    setSelectedRole(
      state,
      action: {
        payload: { persona: UserPersona; authDom: AuthorizationDOM[]; default?: boolean };
      }
    ) {
      if (state.details) {
        state.details.selectedRole = action.payload.persona;
        state.details.authorizationDOM = action.payload.authDom;
        if (action.payload.default) {
          state.details.selectedRole.default = action.payload.default;
        }
      }
    },
    setDefaultChanged(state, action: { payload: boolean }) {
      if (state.details?.selectedRole) {
        state.details.selectedRole.default = action.payload;
      }
    },
    setPageHeader(state, action: { payload: PageHeader }) {
      state.header = action.payload;
    },
    setUserPersona(state, action: { payload: UserPersona[] }) {
      if (state.details) {
        state.details.userPersona = action.payload;
      }
    },
    reset(state) {
      state.details = undefined;
      state.claims = undefined;
      state.personalizations = undefined;
      state.header = undefined;
      state.loadProfile = false;
      state.error = '';
    },
  },
});

export const { actions: memberActions, reducer: memberReducer } = memberSlice;
export default memberReducer;
