//@ts-nocheck
import { Row, Col } from "antd";
import React, { Fragment } from "react";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import {
  PayrunBeingPreparedIcon,
  QuestionIcon,
} from "../../../../Common/customicons";
import {
  PAYRUN_AMENDMENT_LIST_CONTAINER,
  PAYRUN_REPORT_UPLOAD_BUTTON,
} from "../../../../constants";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import AmendmentSingleLineGridDisplay from "../amendmentSingleGridDisplay";
import { ToogleStaticContent } from "../../../../Common/payrun";
import InformationDisplayCard from "../../common/informationDisplaycard";
import { PAYRUN_STATUS_IDS } from "@xemplo/gp-types";
import PayrunDownloadOptions from "../downloadOptions";
import UploadReportsButton from "../uploadReportsButton";
import HeaderInformationDisplay from "../HeaderInformationDisplayPayrun";
import { ClientInformationCardMessage } from "./information-card.const";

const ClientPrepareView = (props: any) => {
  const {
    pageClaims,
    payrun,
    list,
    listLoading,
    handleAmendmentAction,
    dashboardView,
    targetEndpoint,
    hideStaticContent,
    handleToggle,
    payrunReports,
    handleUpload,
    downloadPayrunReports,
  } = props;
  return (
    <React.Fragment>
      {payrun.payrunStatusID <=
        PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED && (
        <HeaderInformationDisplay
          DisplayCardOneIcon={PayrunBeingPreparedIcon}
        />
      )}
      <Row gutter={DEFAULT_GUTTER}>
        <Col md={24} lg={16} className="mb-6">
          {payrun.payrunStatusID >
            PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED && (
            <Fragment>
              {!hideStaticContent && (
                <InformationDisplayCard
                  title={ClientInformationCardMessage.prepareTitle}
                  description={
                    <React.Fragment>
                      <div>{ClientInformationCardMessage.prepareSubTitle}</div>
                      {ClientInformationCardMessage.prepareDescription}
                    </React.Fragment>
                  }
                  ActionComponent={() => (
                    <Fragment>
                      <PayrunDownloadOptions
                        claims={pageClaims}
                        classname="mr-4"
                        disabled={!payrunReports?.length}
                        payrunReports={payrunReports}
                        handleReportDownload={downloadPayrunReports}
                        title={
                          !payrunReports?.length ? "No Reports Available" : ""
                        }
                      />
                      <UploadReportsButton
                        handleUploadClick={handleUpload}
                        claims={pageClaims}
                        componentName={PAYRUN_REPORT_UPLOAD_BUTTON}
                      />
                    </Fragment>
                  )}
                  IconComponent={PayrunBeingPreparedIcon}
                  payrun={payrun}
                  claims={pageClaims}
                />
              )}
              <ToogleStaticContent
                hideStaticContent={hideStaticContent}
                claims={pageClaims}
                handleIconClick={handleToggle}
              />
            </Fragment>
          )}
          <ListContainer
            name={PAYRUN_AMENDMENT_LIST_CONTAINER}
            claims={pageClaims}
            hideSorting
            hideAddButton={
              [1, 2, 3].includes(payrun?.payrunStatusID) || dashboardView
                ? false
                : true
            }
            singleLineGridView
            SingleLineGridDisplayComponent={AmendmentSingleLineGridDisplay}
            singleLineGridTitle="Amendments"
            handleSingleGridAction={handleAmendmentAction}
            payrun={payrun}
            list={list}
            listLoading={listLoading}
            targetEndpoint={targetEndpoint}
          />
        </Col>
        {payrun.payrunStatusID >
          PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED && (
          <Col md={24} lg={8} className="mb-6">
            <InformationDisplayCard
              IconComponent={QuestionIcon}
              title="Have a question?"
              cardClassName="min-h-[158px]"
              description="Made a mistake, needs to add more amendments or make changes? Use the 'Comments' functionality above."
            />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};
export default ClientPrepareView;
