import styled from 'styled-components';

import { Button } from '@xemplo/button';
import {
  BodySmallMedium,
  BodySmallRegular,
  BodyStandardMedium,
  Colour,
} from '@xemplo/style-constants';

export const RadioOption = styled.label`
  margin-top: 12px;
  display: flex;
  align-items: center;
  color: ${Colour.Gray[700]};
  ${BodySmallRegular};
  gap: 12px;
`;
export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UploaderHeader = styled.div`
  ${BodyStandardMedium};
  display: flex;
  align-items: center;
  gap: 8px;
  & svg {
    font-size: 24px;
  }
`;
export const FileItemWrapper = styled.div`
  background: ${Colour.Gray[50]};
  color: ${Colour.Gray[500]};
  &.error {
    color: ${Colour.Red[600]};
    background: ${Colour.Red[50]};
  }
  border-radius: 8px;
  ${BodySmallMedium};
`;

export const FileItemContent = styled.div`
  padding: 12px 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  & svg {
    font-size: 16px;
  }
`;
export const FileName = styled.span`
  flex: 1;
  color: ${Colour.Gray[500]};
`;

export const CancelButton = styled.button`
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  color: ${Colour.Gray[400]};
  padding-right: 0;
`;

export const FileOpenButton = styled.button`
  background: transparent;
  border: none;
  color: ${Colour.Blue[500]};
  margin: 0;
  padding: 0;
  ${BodySmallMedium};

  &:hover {
    cursor: pointer;
    color: ${Colour.Blue[600]};
    text-decoration: underline;
  }
`;

export const ErrorLine = styled.li`
  color: ${Colour.Red[500]};
  ${BodySmallMedium};
`;

export const DownloadTemplateButton = styled(Button)`
  width: fit-content;
  margin-block: 24px;
`;

export const MoreLines = styled.div`
  color: ${Colour.Gray[500]};
  ${BodySmallMedium};
  margin-left: 0;
  margin-top: 12px;
`;
