import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { formatToCurrency } from '@xemplo/common-util';
import { CenteredLoaderDots } from '@xemplo/loader';
import {
  useGetPayrunEarningLineDetailsVarianceQuery,
  useGetPayrunMilestonesQuery,
} from '@xemplo/payrun-query';

import * as S from '../payrun-earning-line-detail.styles';

export const PayrunEarningLineDetailBodyVariance = () => {
  const { workerId, id } = useParams();
  const { data, isLoading } = useGetPayrunEarningLineDetailsVarianceQuery({
    id: id ?? null,
    workerId: workerId ?? null,
  });

  const { data: milestoneData } = useGetPayrunMilestonesQuery({ id: id ?? null });

  const parsedRows = data?.result.parsedRows;
  if (isLoading) return <CenteredLoaderDots />;

  if (!data || !parsedRows) return null;

  return (
    <S.DetailContainer>
      {parsedRows.map((table, index) => (
        <S.VarianceTable key={`${table[0].payCategoryName}-${index}`}>
          {index === 0 && (
            <thead>
              <tr>
                <th></th>
                <th>Previous</th>
                <th>Current</th>
                <th>Variance %</th>
              </tr>
            </thead>
          )}
          <tbody>
            {table.map((line, index) => (
              <tr key={index} className={classNames({ heading: index === 0 })}>
                <td>{line?.payCategoryName}</td>
                <td>
                  {line?.previous ? formatToCurrency({ value: line?.previous }) : '-'}
                </td>
                <td>{formatToCurrency({ value: line?.current })}</td>
                <td>
                  {line.variancePercent ? (
                    <S.CustomVarianceCell value={line?.variancePercent} percentage />
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </S.VarianceTable>
      ))}

      {milestoneData?.isInvoiceNeeded && (
        <S.TotalTable>
          <tbody>
            <tr>
              <td className="total"> Total Payable</td>
              <td>
                {data?.result?.totalPayable?.previous
                  ? formatToCurrency({ value: data?.result?.totalPayable?.previous })
                  : '-'}
              </td>
              <td>{formatToCurrency({ value: data?.result?.totalPayable?.current })}</td>
              <td>
                {data?.result?.totalPayable?.variancePercent ? (
                  <S.CustomVarianceCell
                    className="total"
                    value={data?.result?.totalPayable?.variancePercent}
                    percentage
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          </tbody>
        </S.TotalTable>
      )}
    </S.DetailContainer>
  );
};
