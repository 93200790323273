import { businessUnitAction } from "../constants/action-type/index";

const initialState = { list: [] };
const { LIST_BUSINESS_UNIT, BUSINESS_UNIT_DETAIL } = businessUnitAction;

export default function businessUnitReducer(state = initialState, action: any) {
  const { data, total } = action;
  switch (action.type) {
    case LIST_BUSINESS_UNIT: {
      return {
        ...state,
        list: data,
        total,
      };
    }

    case BUSINESS_UNIT_DETAIL: {
      return {
        ...state,
        detail: data,
      };
    }
    case "CLEAR_BUSINESSUNIT": {
      return {
        ...state,
        list: [],
        total: 0,
        detail: {},
      };
    }
    default:
      return state;
  }
}
