import React from "react";
import { Input } from "antd";
import { TEXT_AND_NUMBER_REGEX } from "../../../constants/regex";
import { getMaxCharacters } from "../../../libs";

type Props = {
  handleChange: (value: any) => void;
  value: string;
  index: number;
  threshold:
    | {
        maxAmount: number;
        minAmount: number;
      }
    | undefined;
};

const InputTextAndNumberOnly = ({
  handleChange,
  value,
  index,
  threshold,
}: Props) => {
  return (
    <Input
      value={value}
      className="w-2/3"
      onChange={(e) => {
        const regEx = new RegExp(TEXT_AND_NUMBER_REGEX, "g");
        // clean the value to allow only text and numbers using regex
        const value = e.target.value.replace(regEx, "");

        handleChange({
          value: value,
          type: "name",
          index,
        });
      }}
      maxLength={getMaxCharacters({
        validation: threshold,
      })}
    />
  );
};

export default InputTextAndNumberOnly;
