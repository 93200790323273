//@ts-nocheck
import React from "react";
import VarianceList from "../../components/variance/list";
import {
  mustBeArray,
  updateAuthorizationClaims,
  findAuthorizationClaims,
  removeUndefined,
  delay,
  errorDisplay,
  getPersonalizationData,
  setPersonalizationData,
  parseItems,
  requestUrlBuilder,
  filteredByLabel,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  isComponentVisible,
} from "../../../libs/utilities";
import _ from "lodash";
import {
  INTEGER_PERSONALIZATION,
  STRING_PERSONALIZATION,
  TABLE_VIEW_VALUE,
  GRID_VIEW_VALUE,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_NOT_REQUIRED,
  PAGINATION_SCROLLING,
} from "../../../constants/defaultClaims";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import axios from "axios";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import moment from "moment";
import DateRangepickerFilter from "../../../Common/ClaimComponents/Filter/DateRangepickerFilters";
import SwitchButton from "../../../Common/ClaimComponents/Filter/SwitchButton";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import APIHandler from "../../../constants/apiUrl";
import {
  getDefaultPage,
  getDefaultPerPage,
  updateDomChanges,
  validateUsersAccessRights,
} from "../../../libs";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  VARIANCE_COMPANY_DROPDOWN_FILTER,
  VARIANCE_DATE_RANGE_DROPDOWN_FILTER,
  VARIANCE_FILTER_CONTAINER,
  VARIANCE_GENERAL_GRID,
  VARIANCE_GENERAL_TABLE,
  VARIANCE_LIST_CONTAINER,
  VARIANCE_PAYSCHEDULE_DROPDOWN_FILTER,
  VARIANCE_RESET_FILTER,
  VARIANCE_SEARCH_BUTTON,
} from "../../../constants";
import { If } from "../../../Common/ui";
import { withRouter } from "../../../hooks";

type State = any;

class VarianceIndex extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: {},
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: [],
      selectedGridSort: undefined,
      fieldOptions: [],
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
      payScheduleList: [],
      companyList: [],
    };
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, targetEndpoint, pageClaims } =
      this.props;
    //TODO: Replace with requireSecureComponent higher order component
    // Validate user access to /variances page
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    this.fetchLookups();
    if (dashboardView) {
      const variancePersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      if (!variancePersonalizations || !variancePersonalizations.gridView) {
        this.setState({ gridView: false });
      } else {
        this.setState({ varianceGridView: true });
      }
      this.props
        .varianceHandler({
          options: {},
          action: "list",
          cancelToken: this.signal.token,
          targetEndpoint: targetEndpoint,
        })
        .then((resp) => {
          this.setState({ listLoading: false });

          if (resp.status) {
            this.setState({ list: resp.data, total: resp.total });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
    } else {
      this.props.updateHeader({
        header: {
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.VarianceList,
          title: "Variance Report",
          module: "",
          enableBack: false,
          entity: "variance",
          action: "",
        },
      });
      const varianceListContainer = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: VARIANCE_LIST_CONTAINER,
      });
      const varianceGeneralTable = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: VARIANCE_GENERAL_TABLE,
      });
      const varianceGeneralGrid = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: VARIANCE_GENERAL_GRID,
      });
      const varianceCompanyDropdownFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: VARIANCE_COMPANY_DROPDOWN_FILTER,
      });
      const variancePayScheduleFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: VARIANCE_PAYSCHEDULE_DROPDOWN_FILTER,
      });
      const varianceDateRangeFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: VARIANCE_DATE_RANGE_DROPDOWN_FILTER,
      });

      const variancePersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: varianceListContainer?.personalizations,
      });
      const companyPersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData: varianceCompanyDropdownFilter?.personalization,
      });
      const paySchedulePersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData: variancePayScheduleFilter?.personalization,
      });
      const dateRangePersonalization = getPersonalizationData({
        type: "filterContainer",
        personalizationData: varianceDateRangeFilter?.personalization,
      });
      const dateRangeDefaultValue = mustBeArray(
        parseItems(dateRangePersonalization?.value)
      );

      this.setState({
        filters: {
          company: companyPersonalizations?.value ?? null,
          paySchedule: paySchedulePersonalizations?.value ?? undefined,
          startDate: dateRangeDefaultValue?.[0]
            ? moment(dateRangeDefaultValue[0], "DD/MM/YYYY")
            : moment().subtract(6, "months"),
          endDate: dateRangeDefaultValue?.[1]
            ? moment(dateRangeDefaultValue[1], "DD/MM/YYYY")
            : moment(),
        },
        paginationType: variancePersonalizations?.paginationType,
        sortingType: variancePersonalizations?.sortingType,
        isPinned: variancePersonalizations?.isPinned,
        gridable: varianceListContainer?.gridable,
      });
      if (isComponentVisible({ claim: varianceGeneralGrid })) {
        const varianceGridPersonalizations = getPersonalizationData({
          type: "grid",
          personalizationData: varianceGeneralGrid.personalizations,
        });
        const pageNumber = getDefaultPage({
          page: varianceGridPersonalizations?.pageNumber,
        });
        const row = varianceGridPersonalizations?.row
          ? Number(varianceGridPersonalizations.row)
          : 5;
        const col = varianceGridPersonalizations?.col
          ? Number(varianceGridPersonalizations.col)
          : 4;
        const SortOrder = varianceGridPersonalizations?.sortOrder ?? null;
        const SortColumn = varianceGridPersonalizations?.sortColumn ?? null;

        this.setState(
          {
            grid: {
              page: pageNumber ? Number(pageNumber) : 1,
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },

            selectedGridSort: _.find(
              this.state.gridSortOption,
              (o) => o.label === SortColumn
            )?.id,
          },
          () => {
            if (variancePersonalizations?.gridView) {
              this.setState({ gridView: true });
              if (
                variancePersonalizations.paginationType === PAGINATION_SCROLLING
              ) {
                this.listScrollingGridVariances({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.fetchMoreData({ isPaginated: true, notUpdate: true });
              }
            }
          }
        );
      }

      if (isComponentVisible({ claim: varianceGeneralTable })) {
        const varianceTablePersonalizations = getPersonalizationData({
          type: "table",
          personalizationData: varianceGeneralTable.personalizations,
        });
        const pageNumber = getDefaultPage({
          page: varianceTablePersonalizations?.pageNumber,
        });
        const pageSize = getDefaultPerPage({
          perPage: varianceTablePersonalizations?.pageSize,
          defaultPerPage: 20,
        });
        const SortOrder = varianceTablePersonalizations?.sortOrder ?? null;
        const SortColumn = varianceTablePersonalizations?.sortColumn ?? null;

        this.setState(
          {
            per_page:
              variancePersonalizations?.paginationType ===
              PAGINATION_NOT_REQUIRED
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : getDefaultPerPage({ perPage: pageSize, defaultPerPage: 20 }),
            current: getDefaultPage({ page: pageNumber }),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (!variancePersonalizations.gridView) {
              if (
                variancePersonalizations?.paginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingVariances({
                  pageLoad: true,
                  notUpdate: true,
                });
              } else {
                this.listVariances({ options: {}, notUpdate: true });
              }
            }
          }
        );
      }
    }
  }

  fetchLookups = () => {
    this.props
      .lookUpHandler({
        options: {},
        cancelToken: this.signal.token,
        type: "company",
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({ companyList: mustBeArray(resp.data) });
        }
      });

    this.fetchPayScheduleList({ companyId: "" });
  };

  fetchPayScheduleList = ({ companyId = "" }) => {
    this.props
      .lookUpHandler({
        options: companyId ? { q: { companyId } } : {},

        cancelToken: this.signal.token,
        type: "paySchedule",
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({ payScheduleList: mustBeArray(resp.data) });
        }
      });
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.current = 1;
        prevState.listLoading = true;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };

        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }

        this.updateAllPersonalization();

        this.props
          .varianceHandler({
            options,
            action: "list",

            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ filterLoading: false, listLoading: false });

            if (resp.status) {
              this.setState({ list: resp.data, total: resp.total });
            }
          });
      }
    );
  };

  handleChange = (label, value) => {
    if (label === "dateRange") {
      return this.setState({
        filters: {
          ...this.state.filters,
          startDate: mustBeArray(value)[0],
          endDate: mustBeArray(value)[1],
        },
      });
    }
    if (label === "company") {
      this.fetchPayScheduleList({ companyId: value });
    }

    this.setState({
      ...this.state,
      filters:
        label === "company"
          ? {
              ...this.state.filters,
              [label]: value,
              paySchedule: "",
            }
          : {
              ...this.state.filters,
              [label]: value,
            },
    });
  };

  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {},
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.setState({ listLoading: false, list: {} });

        this.props
          .varianceHandler({
            options: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                    paginationType: this.state.paginationType,
                    grid: this.state.grid,
                  })
                : this.state.per_page,
              q: {},
            },
            action: "list",

            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({ list: resp.data, total: resp.total });
            }
          });
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization(VARIANCE_COMPANY_DROPDOWN_FILTER, false);
    delay(300).then(() => {
      this.updatePersonalization(VARIANCE_PAYSCHEDULE_DROPDOWN_FILTER, false);
    });
    delay(600).then(() => {
      this.updatePersonalization(VARIANCE_DATE_RANGE_DROPDOWN_FILTER, false);
    });
    delay(900).then(() => {
      this.updatePersonalization(VARIANCE_GENERAL_TABLE, false);
    });
    delay(1200).then(() => {
      this.updatePersonalization(VARIANCE_GENERAL_GRID);
    });
  };

  listVariances = ({ options = {}, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        if (this.state.filters?.paySchedule) {
          this.props
            .varianceHandler({
              options: tempOptions,
              action: "list",
              cancelToken: this.signal.token,
            })
            .then((resp) => {
              this.setState({ listLoading: false });

              if (resp.status) {
                if (
                  Number(this.state.current) !== 1 &&
                  _.isEmpty(mustBeArray(resp.data))
                ) {
                  this.setState({ current: 1 }, () => {
                    this.listvariances({ options: {} });
                  });
                } else {
                  this.setState({ list: resp.data, total: resp.total });
                }
              } else {
                errorDisplay(resp?.data?.validationErrors);
              }
            });
          delay(300).then(() => {
            if (!notUpdate) {
              this.updatePersonalization(VARIANCE_GENERAL_TABLE);
            }
          });
        } else {
          this.setState({ listLoading: false });
        }
      }
    );
  };

  listScrollingVariances = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            prevState.total === mustBeArray(prevState.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .varianceHandler({
            options: tempOptions,
            action: "list",
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingVariances({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? resp.data
                      : [...this.state.list, ...resp.data],
                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(VARIANCE_GENERAL_TABLE);
          }
        });
      }
    );
  };

  listScrollingGridVariances = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      {
        listLoading: true,

        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid?.page) + 1
            : Number(this.state.grid?.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;

        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });

        let filterOptions = this.getFilters();

        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });

        this.props
          .varianceHandler({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
            },
            action: "list",

            cancelToken: this.signal.token,
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridVariances({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization(VARIANCE_GENERAL_GRID);
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listVarianceGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid?.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .varianceHandler({
            options: tempOptions,

            action: "list",
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({ list: resp.data, total: resp.total });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(VARIANCE_GENERAL_GRID);
        });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member?.details?.authorizationDOM
      );

      const varianceListContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === VARIANCE_LIST_CONTAINER
      );

      const varianceFilterContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === VARIANCE_FILTER_CONTAINER
      );

      const varianceGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === VARIANCE_GENERAL_TABLE
      );

      const varianceGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === VARIANCE_GENERAL_GRID
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];
      switch (field) {
        case VARIANCE_GENERAL_TABLE:
          if (varianceListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,

              sortOrder: this.state.sortInfo?.order,

              sortColumn: this.state.sortInfo?.field,

              pageSize: `${this.state.per_page}`,
              personalizationData: varianceGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case VARIANCE_GENERAL_GRID:
          if (varianceListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid?.page}`,
              row: `${grid?.row}`,
              col: `${grid?.col}`,
              sortOrder: grid?.sortInfo?.order,
              sortColumn: grid?.sortInfo?.field,
              personalizationData: varianceGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case VARIANCE_COMPANY_DROPDOWN_FILTER:
          if (varianceFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${this.state.filters?.company ?? ""}`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case VARIANCE_PAYSCHEDULE_DROPDOWN_FILTER:
          if (varianceFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters?.paySchedule
                ? this.state.filters.paySchedule
                : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case VARIANCE_DATE_RANGE_DROPDOWN_FILTER:
          if (varianceFilterContainer?.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = JSON.stringify([
              `${
                this.state.filters?.startDate
                  ? `${moment(this.state.filters.startDate).format(
                      "DD/MM/YYYY"
                    )}`
                  : ""
              }`,
              `${
                this.state.filters?.endDate
                  ? `${moment(this.state.filters.endDate).format("DD/MM/YYYY")}`
                  : ""
              }`,
            ]);
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case VARIANCE_LIST_CONTAINER:
          if (varianceListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData: varianceListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }
      const updateChanges = updateDomChanges({
        update,
        condition: field === VARIANCE_GENERAL_GRID,
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    delete tempFilters.company;
    if (tempFilters.paySchedule) {
      tempFilters.payscheduleId = tempFilters.paySchedule;
    }
    delete tempFilters.paySchedule;
    if (!tempFilters.startDate || !tempFilters.endDate) {
      delete tempFilters.startDate;
      delete tempFilters.endDate;
    } else {
      tempFilters.fromDate = moment(tempFilters.startDate).format("MM-DD-YYYY");
      tempFilters.toDate = moment(tempFilters.endDate).format("MM-DD-YYYY");
      delete tempFilters.startDate;
      delete tempFilters.endDate;
    }
    return tempFilters;
  };

  fetchMoreData = ({ isPaginated = false }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props
      .listvariances({
        options: { page, per_page, q: { ...options }, ...sortingParams },
      })

      .then((response) => {
        if (response.status) {
          this.setState(
            (prevState) => {
              const data = mustBeArray(response.data);
              if (isPaginated) {
                prevState.list = data;
              } else {
                prevState.list = prevState.list.concat(data);

                if (data.length !== 0 && data.length === this.state.page_size) {
                  prevState.hasMoreData = true;
                  prevState.grid = {
                    ...prevState.grid,
                    page: prevState.grid.page + 1,
                  };
                } else {
                  prevState.hasMoreData = false;
                }
              }

              prevState.total = response.total;
              prevState.listLoading = false;
              return prevState;
            },
            () => {
              delay(300).then(() => {
                this.updatePersonalization(VARIANCE_GENERAL_GRID);
              });
            }
          );
        }
      });
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: {}, listLoading: true }, () => {
      this.updatePersonalization(VARIANCE_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingvariances({ pageLoad: true, options: {} });
        } else {
          this.listvariances({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridvariances({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(
          `/company/${data?.companyId}/variance/${data?.id}`
        );
        break;
      case "edit":
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  updatePinnedComponent = () => {
    this.setState(
      { isPinned: this.state.isPinned === "true" ? "false" : "true" },
      () => {
        this.updatePersonalization(VARIANCE_LIST_CONTAINER);
      }
    );
  };

  refactorList = ({ list }) => {
    return mustBeArray(list && list.invoiceEarningLines);
  };

  handleChangeSwitch = (value) => {
    this.setState({ showTax: value });
  };

  downloadInvoice = () => {
    window.open(this.state.list && this.state.list.payrunVarianceReportUri);
  };
  render() {
    const {
      sortInfo,
      filterLoading,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      hasMoreData,
      isPinned,

      paginationType,
      gridable,
      fieldOptions,
      sortingType,
      current,
    } = this.state;

    const { pageClaims, dashboardView } = this.props;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });

    let finalList = this.refactorList({ list });
    const varianceBaseUrl = APIHandler.constructEndpoint({
      endpoint: "VARIANCE_URL",
    });
    const varianceReportTargetEndpoint = requestUrlBuilder(varianceBaseUrl, {
      q: {
        ...this.getFilters(),
      },
    });

    const currentFilters = this.getFilters();

    const selectedPaySchedule = _.find(
      this.state.payScheduleList,
      (o) => o.id === this.state.filters.paySchedule
    );
    const filterLabel = filteredByLabel({
      filters: currentFilters.payscheduleId ? [selectedPaySchedule?.name] : [],
      startDate: currentFilters?.fromDate ?? null,
      endDate: currentFilters.toDate,
    });
    return (
      <React.Fragment>
        <If
          condition={!dashboardView}
          then={
            <div className="flex justify-between items-center flex-wrap">
              <FilterContainer
                name={VARIANCE_FILTER_CONTAINER}
                claims={pageClaims}
              >
                <SingleFieldDropdownFilter
                  showSearch
                  searchBy="value"
                  filterOption="name"
                  options={this.state.companyList}
                  name={VARIANCE_COMPANY_DROPDOWN_FILTER}
                  value={this.state.filters?.company}
                  defaultValue={undefined}
                  onSelect={(value) => this.handleChange("company", value)}
                  className="mb-3 mr-2.5"
                  claims={this.props.claims}
                  valueIsNumber={false}
                  placeholder="Select Company"
                />
                <SingleFieldDropdownFilter
                  showSearch
                  searchBy="name"
                  filterOption="name"
                  options={this.state.payScheduleList}
                  name={VARIANCE_PAYSCHEDULE_DROPDOWN_FILTER}
                  value={this.state.filters?.paySchedule}
                  defaultValue={undefined}
                  onSelect={(value) => this.handleChange("paySchedule", value)}
                  className="mb-3 mr-2.5"
                  claims={this.props.claims}
                  valueIsNumber={false}
                  placeholder=" Select Pay Schedule"
                  nameParam="name"
                />
                <DateRangepickerFilter
                  placeholder="Start - End date"
                  name={VARIANCE_DATE_RANGE_DROPDOWN_FILTER}
                  claims={this.props.claims}
                  value={[
                    this.state.filters?.startDate,
                    this.state.filters?.endDate,
                  ]}
                  onChange={(value) => this.handleChange("dateRange", value)}
                  onSearch={this.handleFilter}
                  className="mb-3 w-250"
                />
                <SearchIconButton
                  loading={filterLoading}
                  onClick={this.handleFilter}
                  name={VARIANCE_SEARCH_BUTTON}
                  claims={this.props.claims}
                  className="ml-4 mb-3"
                />
                <LinkButton
                  buttonText="Reset Filter"
                  className="color-primary hand mb-3"
                  onClick={() => this.resetFilters()}
                  name={VARIANCE_RESET_FILTER}
                  claims={this.props.claims}
                />
              </FilterContainer>
              <div className="pr-[60px]">
                <SwitchButton
                  displaySwitch={true}
                  checked={this.state.showTax}
                  handleChangeSwitch={this.handleChangeSwitch}
                  name="Show Tax"
                />
                <SimpleButton
                  buttonText="Download Variance"
                  ignoreStatus={true}
                  className="bg-success ml-3"
                  onClick={this.downloadInvoice}
                />
              </div>
            </div>
          }
        />
        <ListContainer
          name={VARIANCE_LIST_CONTAINER}
          claims={pageClaims}
          isPinned={isPinned}
          updatePinnedComponent={this.updatePinnedComponent}
          gridable={gridable}
          gridView={gridView}
          grid={grid}
          per_page={per_page}
          GeneralTablePaginatedComponent={VarianceList}
          GeneralTableScrollableComponent={VarianceList}
          generalTableComponentName={VARIANCE_GENERAL_TABLE}
          gridComponentName={VARIANCE_GENERAL_GRID}
          createLinkButtonName={""}
          handleAddAction={() => {
            console.log("");
          }}
          handleGridChange={this.handleGridChange}
          fieldOptions={fieldOptions}
          updateGridField={this.updateGridField}
          sortingType={sortingType}
          paginationType={paginationType}
          list={finalList}
          listLoading={listLoading}
          listGrid={this.listVarianceGrid}
          listScrollingGrid={this.listScrollingGridvariances}
          hasMoreData={hasMoreData}
          gridActionComponentName={"varianceViewDetailLink"}
          moduleType={"variance"}
          handleGridAction={this.handleAction}
          listScrollingGeneralTable={this.listScrollingVariances}
          total={total}
          current={current}
          sortInfo={sortInfo}
          listGeneralTable={this.listVariances}
          tableActionComponentName={"varianceViewDetailLink"}
          viewComponent="varianceViewDetailLink"
          gridPageSize={getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          })}
          cardGrid={cardGrid}
          showAddAndGrid={false}
          showTax={this.state.showTax}
          dashboardView={dashboardView}
          targetEndpoint={
            this.props.targetEndpoint || varianceReportTargetEndpoint
          }
          hasNoDuplicateEntity={true}
          componentTitle={
            dashboardView ? this.props.componentTitle : "Variance Report"
          }
          filterApplied={dashboardView ? this.props.filterApplied : filterLabel}
          periods={list && list.periods}
          className="pr-[60px]"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(VarianceIndex);
