// @ts-nocheck
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Switch } from "antd";

import { activeDashboardIsDefault } from "../../../libs";
import CustomDashboardSelector from "../../pages/Dashboard/CustomDashboardSelector";

type DashboardSettingsProps = {
  handleAutoAdjust: () => void;
  autoAdjust: boolean;
  handleDelete: () => void;
  activeDashboardName: string;
};

const DashboardSettings = (props: DashboardSettingsProps) => {
  const { handleAutoAdjust, autoAdjust, handleDelete, activeDashboardName } =
    props;

  const settingsMenu = (
    <Menu className="custom-table-option">
      <Menu.Item key="autoAdjust" onClick={handleAutoAdjust}>
        <span>
          <Switch checked={autoAdjust} className="mr-2" />
        </span>{" "}
        Auto adjust
      </Menu.Item>
      {!activeDashboardIsDefault() && (
        <Menu.Item
          key="delete"
          onClick={handleDelete}
          className="flex items-center"
        >
          <CloseOutlined className="ml-4 mr-6 pr-5 font-extrabold " /> Delete
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <div className={`flex justify-between`}>
      <CustomDashboardSelector selected={activeDashboardName} />

      <Dropdown
        overlay={settingsMenu}
        trigger={["click"]}
        placement="bottomLeft"
      >
        <SettingOutlined className="text-xl" />
      </Dropdown>
    </div>
  );
};
export default DashboardSettings;
