const LIST_PAYRUN_AMENDMENTS = "LIST_PAYRUN_AMENDMENTS";
const LIST_PAYRUN_APPROVAL = "LIST_PAYRUN_APPROVAL";
const LIST_PAYRUN_COMPLETED = "LIST_PAYRUN_COMPLETED";
const PAYRUN_DETAIL = "PAYRUN_DETAIL";
const LIST_PAYRUN_TOTAL = "LIST_PAYRUN_TOTAL";
const LIST_PAYRUN_PREPARE = "LIST_PAYRUN_PREPARE";
const LIST_PAYRUN_PROCESS = "LIST_PAYRUN_PROCESS";
const GET_KEYPAY = "GET_KEYPAY";
const GET_SUMMARY = "GET_SUMMARY";
const LIST_PAYRUN_UNPAID = "LIST_PAYRUN_UNPAID";
const LIST_PAYRUN_PAID = "LIST_PAYRUN_PAID";
const LIST_EXTERNAL_EARNINGS = "LIST_EXTERNAL_EARNINGS";
const LIST_PAYRUN_AMENDMENTLIST = "LIST_PAYRUN_AMENDMENTLIST";
const PAYRUN_DETAIL_LOADING = "PAYRUN_DETAIL_LOADING";
const PAYRUN_EARNINGLINES_REIMPORT = "PAYRUN_EARNINGLINES_REIMPORT";

const payrunAction = {
  LIST_PAYRUN_AMENDMENTS,
  LIST_PAYRUN_COMPLETED,
  LIST_PAYRUN_APPROVAL,
  LIST_PAYRUN_TOTAL,
  LIST_PAYRUN_PROCESS,
  LIST_PAYRUN_PREPARE,
  PAYRUN_DETAIL,
  GET_KEYPAY,
  GET_SUMMARY,
  LIST_PAYRUN_UNPAID,
  LIST_PAYRUN_PAID,
  LIST_EXTERNAL_EARNINGS,
  LIST_PAYRUN_AMENDMENTLIST,
  PAYRUN_DETAIL_LOADING,
  PAYRUN_EARNINGLINES_REIMPORT,
};

export default payrunAction;
