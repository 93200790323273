import { MAX_NUMBER_VALUE } from '@xemplo/gp-constants';

import { ParsedCsvItem, UploadedCsvItem } from './bulk-upload.types';

export const isValidRateAndUnit = (rate: number, unit: number) => {
  if (unit === 0) return false;
  return !(
    isNaN(rate) ||
    isNaN(unit) ||
    rate < 0 ||
    unit < 0 ||
    rate > MAX_NUMBER_VALUE ||
    unit > MAX_NUMBER_VALUE ||
    !isPrecise(rate, 2) ||
    !isPrecise(unit, 2)
  );
};
/**
 * Check's if the number is precise to the given decimal places or less than the given decimal places
 * @param num String or Number to check the decimal precision of
 * @param precision Number of decimal places to check against
 * @returns boolean
 */
export const isPrecise = (num: number | string, precision: number) => {
  //if it cannot be converted to a number, return false
  if (isNaN(Number(num))) return false;
  const arr = String(num).split('.');
  // If there is no decimal point, return true
  if (arr.length === 1) return true;
  // If there is a decimal point, check the length of the decimal places
  return arr[1]?.length <= precision;
};

export const validateCsv = ({ data }: { data: UploadedCsvItem[] }) => {
  const errorList: string[] = []; // Array to contain errors found in the CSV
  const warningList: string[] = []; // Array to contain warnings found in the CSV
  const validatedData: ParsedCsvItem[] = [];

  data.forEach((row: UploadedCsvItem, index) => {
    validateRow(row, index, errorList, warningList, validatedData);
  });

  //After looping through the whole CSV, check if there was no data validated
  if (validatedData.length === 0) {
    errorList.push('No valid data was found in the file');
  }

  return { errorList, warningList, validatedData };
};

const validateRow = (
  row: UploadedCsvItem,
  index: number,
  errorList: string[],
  warningList: string[],
  validatedData: ParsedCsvItem[]
) => {
  const isValid = validateRateAndUnits(row, index, errorList, warningList);
  // If validation check passes, append row to validated data set
  if (isValid) {
    const id = Number(row.ID);
    validatedData.push({
      id: id,
      name: row.Name,
      rate: Number(row.Rate),
      units: Number(row.Unit),
    });
  }
};

export const validateRateAndUnits = (
  row: UploadedCsvItem,
  index: number,
  errorList: string[],
  warningList: string[]
) => {
  const rate = Number(row.Rate);
  const unit = Number(row.Unit);

  if (isValidRateAndUnit(rate, unit)) return true;

  if (rate === 0 && unit === 0) {
    warningList.push(`Row ${index + 1} - ${row.ID} ${row.Name}`);
    return false;
  }
  if (
    isNaN(rate) ||
    isNaN(unit) ||
    rate < 0 ||
    unit <= 0 ||
    rate > MAX_NUMBER_VALUE ||
    unit > MAX_NUMBER_VALUE
  ) {
    errorList.push(
      `Row ${index + 1} - Rate "${row.Rate}" or Unit "${row.Unit}" is not a valid number`
    );
    return false;
  }

  if (!isPrecise(rate, 2) || !isPrecise(unit, 2)) {
    errorList.push(
      `Row ${index + 1} - values must be precise to 2 or less decimal places Rate "${
        row.Rate
      }" or Unit ${row.Unit}`
    );
  }

  return false;
};
