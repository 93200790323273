import SliceListProvider from "../../../../Common/SliceListProvider";
import List from "./List";

type Props = {
  pageClaims: any;
  viewAmendmentDetail: any;
  reloadCompleted: boolean;
  reloadPending: () => void;
};

const CompletedGeneralAmendments = ({
  pageClaims,
  viewAmendmentDetail,
  reloadCompleted,
  reloadPending,
}: Props) => (
  <SliceListProvider>
    <List
      pageClaims={pageClaims}
      viewAmendmentDetail={viewAmendmentDetail}
      reloadCompleted={reloadCompleted}
      reloadPending={reloadPending}
    />
  </SliceListProvider>
);

export default CompletedGeneralAmendments;
