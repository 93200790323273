import requireAuth from "../../Common/requireAuth";
import { SCOPES } from "../authorizationClaims";
import PayrunContainer from "../../Organization/containers/Payrun";
import PayrunDetailContainer from "../../Organization/containers/payrunDetail";
import PayrunIndex from "../../Organization/pages/PayRun";
import CreateAdhocPayrun from "../../Organization/pages/PayRun/Admin/create";
import PayrunAuditReport from "../../Organization/pages/PayRun/AuditReport";
import PayrunDetailIndex from "../../Organization/pages/PayRun/detailIndex";
import PayrunDetailView from "../../Organization/pages/PayRun/detailView";

const LegacyPayrunRoutesWithSidebar = [
  {
    path: "payrun",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun",
    element: (
      <PayrunContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(PayrunIndex)}
      />
    ),
  },

  {
    path: "payrun/:id/details/auditReport",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun/AuditReport",
    element: (
      <PayrunDetailContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(PayrunAuditReport)}
      />
    ),
  },
  {
    path: "payrun/:id/detail",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun/detailView",
    element: (
      <PayrunDetailContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(PayrunDetailView)}
      />
    ),
  },
];
export const PayrunRoutesWithSidebar = [
  ...LegacyPayrunRoutesWithSidebar,
  {
    path: "payrun/:id",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun/detailIndex",
    element: (
      <PayrunDetailContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(PayrunDetailIndex)}
      />
    ),
  },
];

export const PayrunRoutesWithoutSidebar = [
  {
    path: "payrun/create",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun/Admin/create",
    element: (
      <PayrunContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(CreateAdhocPayrun)}
      />
    ),
  },
  {
    path: "company/:companyid/payrun/:payrunid/edit",
    scope: SCOPES.PAYRUN,
    index: "payrun",
    container: PayrunContainer,
    componentPath: "../../Organization/pages/PayRun/Admin/create",
    element: (
      <PayrunContainer
        scope={SCOPES.PAYRUN}
        Layout={requireAuth(CreateAdhocPayrun)}
      />
    ),
  },
];
