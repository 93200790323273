//@ts-nocheck
import React from 'react';
import { Row, Col } from 'antd';
const SummaryGrid = (props: any) => {
    const { label, value } = props
    return (

        <Row gutter={16}>
                <Col span={6} className="text-right">
                <b>{label}</b>
            </Col>
                <Col span={18}>
                {value}
            </Col>
        </Row>
    )
}
export default SummaryGrid;
