import React from "react";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import {
  displayUnapplyAmendmentInKeypay,
  getGeneralAmendmentColumns,
  getSortOrderRequestValue,
  tableColumnGenerator,
} from "../../../libs";
import TableActionComponent from "../common/tableActions";

type Props = {
  handleTableChange: (e: any) => void;
  table?: any;
  data?: Array<any>;
  loading?: boolean;
  rowIdParam?: string;
  dashboardView?: boolean;
  handleAction?: (e: any) => void;
  claims?: any;
  total?: number;
};
const CompletedGeneralAmendmentTable = ({
  handleTableChange,
  table,
  data,
  loading,
  rowIdParam,
  dashboardView,
  handleAction,
  claims,
  total,
}: Props) => {
  const columns = tableColumnGenerator(
    !dashboardView
      ? [
          ...getGeneralAmendmentColumns({
            sortInfo: table?.sortInfo,
            type: "completed",
            dashboardView,
          }),
          {
            key: "action",
            align: "center",
            render: (record: any) => (
              <React.Fragment>
                <div className="float-right pr-2" >
              <TableActionComponent
                record={record}
                handleAction={handleAction}
                viewComponentName="generalAmendmentCompletedDetailView"
                claims={claims}
                unApplyInKeyPay={
                  displayUnapplyAmendmentInKeypay({ record })
                    ? "generalAmendmentUnApply"
                    : ""
                }
              />
              </div>
              <div className="clearfix" />           
              </React.Fragment>
            ),
          },
        ]
      : [
          ...getGeneralAmendmentColumns({
            sortInfo: table?.sortInfo,
            type: "completed",
            dashboardView,
          }),
        ]
  );
  const onTableChange = (pagination: any, _filters: any, sorter: any) => {
    handleTableChange({
      page: pagination?.current,
      perPage: pagination?.pageSize,
      sortInfo: {
        field: sorter?.field,
        order: getSortOrderRequestValue({ sorter }),
      },
    });
  };

  return (
    <SimpleTableContainer
      dataSource={data}
      columns={columns}
      loading={loading}
      rowIdParam={rowIdParam}
      dashboardView={dashboardView}
      onChange={onTableChange}
      pagination={{
        total,
        current: table?.page,
        pageSize: table?.perPage,
        hideOnSinglePage: true,
      }}
    />
  );
};
export default CompletedGeneralAmendmentTable;
