import React from "react";
import {
  FileExcelOutlined,
  FilePdfOutlined,
  FileZipOutlined,
} from "@ant-design/icons";

export interface FileTypeIconProps {
  type?: string;
  className?: string;
}

const FileTypeIcon: React.FC<FileTypeIconProps> = ({ type, className }) => {
  switch (type) {
    case "pdf":
      return <FilePdfOutlined className={className} />;
    case "xlsx":
    case "xls":
    case "csv":
      return <FileExcelOutlined className={className} />;
    case "zip":
      return <FileZipOutlined className={className} />;
    default:
      return <React.Fragment />;
  }
};

export default FileTypeIcon;
