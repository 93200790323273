import React, { useState } from "react";
import { getKeypayAmendmentStatus } from "../../../../libs/amendment";

import BadgeStatus from "../../common/badgeStatus";
import AmendmentKeypayStatusModal from "./AmendmentKeypayStatusModal";

const AmendmentKeypayStatus = ({
  amendmentData,
  hideModal,
  status,
}: {
  amendmentData?: any;
  hideModal?: boolean;
  status?: number;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const keypayStatus = getKeypayAmendmentStatus(
    status || amendmentData?.amendmentKeypayStatusId
  );

  const showKeypayStatusModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseKeypayStatusModal = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <span className="cursor-pointer" onClick={showKeypayStatusModal}>
        <BadgeStatus badgeDetails={keypayStatus} />
      </span>
      {!hideModal && (
        <AmendmentKeypayStatusModal
          handleClose={handleCloseKeypayStatusModal}
          isModalVisible={isModalVisible}
          amendment={amendmentData}
        />
      )}
    </React.Fragment>
  );
};

export default AmendmentKeypayStatus;
