//@ts-nocheck
import React from "react";
import { ShareAltOutlined } from "@ant-design/icons";
import { Steps, Tooltip } from "antd";
import {
  ApprovalIcon,
  DashboardIcon,
  PaymentIcon,
  PayrunIcon,
  ProcessIcon,
} from "./customicons";

const Step = Steps.Step;

const TableSteps = ({ payrun, current, adhoc }: any) => {
  return (
    <React.Fragment>
      {!adhoc ? (
        <Steps current={current || 0}>
          <Step
            key="1"
            icon={
              <Tooltip title="Generated">
                <ShareAltOutlined />
              </Tooltip>
            }
          />
          <Step
            key="2"
            icon={
              <Tooltip title="Amendments">
                <DashboardIcon />
              </Tooltip>
            }
          />
          <Step
            key="3"
            icon={
              <Tooltip title="Pay Run Preparation">
                <PayrunIcon />
              </Tooltip>
            }
          />
          <Step
            key="4"
            icon={
              <Tooltip title="Approval">
                <ApprovalIcon />
              </Tooltip>
            }
          />
          {payrun && payrun.payrollModelId === 2 ? null : (
            <Step
              key="5"
              icon={
                <Tooltip title="Payment">
                  <PaymentIcon />
                </Tooltip>
              }
            />
          )}

          <Step
            key="6"
            icon={
              <Tooltip title="Processing">
                <ProcessIcon />
              </Tooltip>
            }
          />
        </Steps>
      ) : (
        <Steps current={current || 0}>
          <Step
            key="1"
            icon={
              <Tooltip title="Amendments">
                <DashboardIcon />
              </Tooltip>
            }
          />
          <Step
            key="2"
            icon={
              <Tooltip title="Pay Run Preparation">
                <PayrunIcon />
              </Tooltip>
            }
          />
          <Step
            key="3"
            icon={
              <Tooltip title="Approval">
                <ApprovalIcon />
              </Tooltip>
            }
          />
          {payrun && payrun.payrollModelId === 2 ? null : (
            <Step
              key="4"
              icon={
                <Tooltip title="Payment">
                  <PaymentIcon />
                </Tooltip>
              }
            />
          )}

          <Step
            key="5"
            icon={
              <Tooltip title="Processing">
                <ProcessIcon />
              </Tooltip>
            }
          />
        </Steps>
      )}
    </React.Fragment>
  );
};
export default TableSteps;
