import { Badge } from "antd";
import React from "react";

const BadgeStatus = ({ badgeDetails }: { badgeDetails: any }) => {
  return (
    <Badge count={badgeDetails?.text} className={badgeDetails?.appliedClass} />
  );
};

export default BadgeStatus;
