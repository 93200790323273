import React from "react";
import { Loading } from "../../ui";
import SingleLineGridCard from "./SingleLineGridCard";

const SingleLineGrid = (props: any) => {
  const { loading } = props;

  return loading ? (
    <div className="mt-12">
      <Loading />
    </div>
  ) : (
    <SingleLineGridCard {...props} />
  );
};

export default SingleLineGrid;
