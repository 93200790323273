//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Card, message, Layout, Row, Col } from "antd";
import _ from "lodash";
import {
  mustBeArray,
  errorDisplay,
  getUserArea,
  parseItems,
  isUserSystemAdmin,
  getMatchParams,
} from "../../../libs";
import FormNavbar from "../../../Layout/FormNavbar";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import axios from "axios";
import AssignRoleCompanyUser from "../../components/users/assignRoleCompanyUserForm";
import userRoles from "../../../constants/userRoles";
import AssignedRolesList from "../../components/users/assignedRolesList";
import SystemAdminAssignRolesUserForm from "../../components/users/systemAdminAssignUserRolesForm";
import UserBasicDetailsForm from "../../components/payrollProvider/UserBasicDetailsForm";
import { FormStepsCol } from "../../../Common/ui";
import { withRouter } from "../../../hooks";
import { connect } from "react-redux";

const { Header, Content } = Layout;

type State = any;

class CreateCompanyUser extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      userRolesList: [],
      companyList: [],
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        },
        {
          id: 2,
          label: "Assign Roles",
          description:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
        },
      ],
      activeIndex: "1",
      userDetails: {},
      assignedRolesList: [],
      profilePhoto: "",
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.fetchCompany();
    this.fetchUserRoles();
    if (params && params.id) {
      this.setState({ editMode: true });
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      this.getUserDetail(params.id, params.companyId);
    }
  }

  getUserDetail = (id) => {
    this.props
      .listCompanyUsers({
        id,
        options: {},
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        if (resp.status && resp.data) {
          this.props.updateHeader({
            header: {
              title: `${resp.data.firstName} ${resp.data.lastName}`,
            },
          });
          const {
            mobilePhone,
            firstName,
            lastName,
            getNotifications,
            emailAddress,
            mfaEnable,
            deviceEnrolmentEnable,
            phone,
            profilePhoto,
          } = resp.data;
          this.setState({ profilePhoto });
          this.props.form.setFieldsValue({
            mobilePhone,
            firstName,
            lastName,
            getNotifications: getNotifications ? 1 : 2,
            emailAddress,
            phone,
          });
          if (isUserSystemAdmin({ member: this.props.member })) {
            this.props.form.setFieldsValue({
              mfaEnable: mfaEnable ? 1 : 2,
              deviceEnrolmentEnable,
            });
          }

          let userPersona = mustBeArray(resp.data.userPersona);
          const assignedRolesList = userPersona.reduce((a, b) => {
            const tenant = parseItems(b.tenant);
            return [
              ...a,
              {
                roleName: b.roleName,
                roleId: b.roleId,
                companyName: tenant && tenant.tenantName,
                companyId: tenant && tenant.tenantId,
              },
            ];
          }, []);
          if (this.displayViewForSystemAdmin()) {
            this.setState({ assignedRolesList });
          } else {
            this.setState((prevState) => {
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
              prevState.userRolesList.map((role, i) => {
                const isSelected = _.find(
                  userPersona,
                  (o) => o.roleId === role.id
                )
                  ? true
                  : false;
                return (role.isSelected = isSelected);
              });
              return prevState;
            });
            this.forceUpdate();
          }
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'displayViewForSystemAdmin'.
  displayViewForSystemAdmin = () => {
    const { params } = this.props.match;
    if (
      params.id &&
      [userRoles.SystemAdminArea, userRoles.PSPArea].includes(getUserArea())
    )
      return true;
    if (
      [userRoles.SystemAdminArea, userRoles.PSPArea].includes(getUserArea()) &&
      !params.companyId
    )
      return true;
    return false;
  };

  componentWillUnmount() {
    this.props.signal.cancel("Api is being canceled");
  }

  fetchUserRoles = () => {
    this.props
      .listUserRoles({
        area: "CU",
        options: {},

        cancelToken: this.signal.token,
      })

      .then((resp) => {
        if (resp.status) {
          this.setState({ userRolesList: mustBeArray(resp.data) });
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
      });
  };

  fetchCompany = (value) => {
    this.setState({ fetching: true });
    if (value) {
      this.props
        .companyLookup({ options: { CompanyName: value, per_page: 500 } })

        .then((response) => {
          this.setState({ fetching: false });

          if (response.status) {
            this.setState({ companyList: mustBeArray(response.data) });
          } else {
            errorDisplay(
              response && response.data && response.data.validationErrors
            );
          }
        });
    } else {
      this.props
        .companyLookup({ options: { per_page: 500 } })
        .then((response) => {
          this.setState({ fetching: false });

          if (response.status) {
            this.setState({ companyList: mustBeArray(response.data) });
          } else {
            errorDisplay(
              response && response.data && response.data.validationErrors
            );
          }
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const form = this.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (Number(this.state.activeIndex) === 1) {
        return this.setState(
          {
            activeIndex: "2",
            userDetails: { ...values },
          },
          () => {
            if (isUserSystemAdmin({ member: this.props.member })) {
              this.setState({
                userDetails: {
                  ...this.state.userDetails,
                  mfaEnable: Number(values.mfaEnable) === 1,
                },
              });
            }
          }
        );
      }
      let userPersona = [];

      const { match } = this.props;
      const companyId = getMatchParams({ match, field: "companyId" });

      if (this.displayViewForSystemAdmin()) {
        userPersona = this.state.assignedRolesList.reduce((a, b) => {
          return [...a, { roleId: b.roleId, companyId: b.companyId }];
        }, []);
      } else {
        let selectedRoles = _.filter(
          this.state.userRolesList,
          (o) => o.isSelected
        );
        userPersona = mustBeArray(selectedRoles).reduce((a, b) => {
          return [
            ...a,
            {
              roleId: b.id,

              companyId: companyId
                ? companyId
                : this.props.member &&
                  this.props.member.details &&
                  this.props.member.details.selectedRole &&
                  this.props.member.details.selectedRole.tenant &&
                  this.props.member.details.selectedRole.tenant.id,
            },
          ];
        }, []);
      }
      if (_.isEmpty(userPersona)) {
        return message.error("Please select a user role");
      }

      this.setState({ submitLoading: true });
      let payload = {
        ...this.state.userDetails,
        addressId: 0,
        getNotifications:
          Number(
            this.state.userDetails && this.state.userDetails.getNotifications
          ) === 1
            ? true
            : false,
        userPersona,
      };

      let actionName = this.props.registerCompanyUsers;
      let finalPayLoad = { data: payload };

      if (this.state.editMode) {
        actionName = this.props.handleCompanyUserActions;

        payload.id =
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.id;
        finalPayLoad = {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ data: any; id: any; action: string; }' is ... Remove this comment to see the full error message
          data: payload,
          id:
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id,
          action: "update",
        };
      }

      actionName(finalPayLoad).then((resp) => {
        this.setState({ submitLoading: false });

        if (resp.status) {
          message.success(
            `User ${
              this.state.editMode ? "updated" : "registered"
            } successfully`
          );

          this.handleCancel();
        } else {
          errorDisplay(resp && resp.data, true);
        }
      });
    });
  };

  handleCancel = () => {
    const { header } = this.props;

    this.props.form.resetFields();

    this.setState({ activeIndex: "1" });
    if (header.returnUrl) {
      this.props.router.navigate(header.returnUrl);
    } else {
      this.props.router.navigate("/users");
    }
  };

  checkSubmitDisabled = () => {
    if (Number(this.state.activeIndex) === 1) return true;
    return false;
  };

  handleBack = () => {
    this.setState({ activeIndex: `${Number(this.state.activeIndex) - 1}` });
  };

  handleRoleChange = ({ value, record }) => {
    this.setState((prevState) => {
      prevState.userRolesList = prevState.userRolesList.map((data) => {
        return {
          ...data,
          isSelected: value && data.id === record.id,
        };
      });
      return prevState;
    });
  };

  addRoles = () => {
    const { form } = this.props;
    const companyId = form.getFieldValue("company");

    const companyName =
      _.find(this.state.companyList, (o) => o.id === companyId) &&
      _.find(this.state.companyList, (o) => o.id === companyId).value;
    const roleId = form.getFieldValue("roleId");

    const roleName =
      _.find(this.state.userRolesList, (o) => o.id === roleId) &&
      _.find(this.state.userRolesList, (o) => o.id === roleId).name;
    if (!companyId) return message.error("Please select a company");
    if (!roleId) return message.error("Please select a role");

    if (
      _.find(
        this.state.assignedRolesList,
        (o) => o.roleId === roleId && o.companyId === companyId
      )
    ) {
      return message.error(
        `User has already been assigned ${roleName} role for ${companyName}`
      );
    }

    this.setState(
      {
        assignedRolesList: [
          ...this.state.assignedRolesList,
          {
            companyId,
            companyName,
            roleId,
            roleName,
          },
        ],
      },
      () => {
        form.resetFields(["company", "roleId"]);
      }
    );
  };

  handleDelete = ({ record }) => {
    this.setState((prevState) => {
      prevState.assignedRolesList = _.filter(
        prevState.assignedRolesList,
        (o) => o !== record
      );
      return prevState;
    });
  };
  render() {
    const { form, member, header } = this.props;
    const getFieldDecorator = form && form.getFieldDecorator;
    const {
      submitLoading,
      activeIndex,
      formLabels,
      userRolesList,
      companyList,
      assignedRolesList,
      editMode,
      profilePhoto,
    } = this.state;
    return (
      <Form autoComplete="off">
        <Layout className="h-[100vh]">
          {
            <React.Fragment>
              <Header className="bg-white w-full position-absolute-top">
                <FormNavbar
                  handleCancel={this.handleCancel}
                  handleSave={this.handleSubmit}
                  htmlType="submit"
                  loading={submitLoading}
                  disabled={this.checkSubmitDisabled()}
                  enableBack={true}
                  header={header}
                />
              </Header>
              <Layout className="p-6 rounded-[5px] create-screen-body overflow-y-auto w-full">
                <Content>
                  <Row gutter={DEFAULT_GUTTER} className="form-label-left">
                    <FormStepsCol data={formLabels} activeIndex={activeIndex} />

                    <Col md={18} sm={24}>
                      {
                        <div
                          className={
                            Number(activeIndex) === 1 ? "block" : "display-none"
                          }
                        >
                          <Card>
                            <UserBasicDetailsForm
                              form={form}
                              fieldRequired={Number(activeIndex) !== 2}
                              editMode={editMode}
                              member={member}
                              profilePhoto={profilePhoto}
                            />
                            <div>
                              <Button
                                onClick={this.handleSubmit}
                                className="bg-success float-right rounded-[20px]"
                                loading={submitLoading}
                              >
                                Continue
                              </Button>
                              <div className="clearfix"></div>
                            </div>
                          </Card>
                        </div>
                      }
                      {
                        <div
                          className={
                            Number(activeIndex) === 2 ? "block" : "display-none"
                          }
                        >
                          {this.displayViewForSystemAdmin() ? (
                            <React.Fragment>
                              <Card>
                                <SystemAdminAssignRolesUserForm
                                  getFieldDecorator={getFieldDecorator}
                                  companyList={companyList}
                                  userRolesList={userRolesList}
                                  type="company"
                                  addRoles={this.addRoles}
                                  form={this.props.form}
                                  assignedRolesList={assignedRolesList}
                                />
                              </Card>
                              <Card className="card-space-0">
                                <AssignedRolesList
                                  data={assignedRolesList}
                                  handleDelete={this.handleDelete}
                                  type="company"
                                />
                              </Card>
                              <Button
                                onClick={this.handleBack}
                                className="mb-4 rounded-[20px]"
                              >
                                Back
                              </Button>
                            </React.Fragment>
                          ) : (
                            <Card>
                              <AssignRoleCompanyUser
                                getFieldDecorator={getFieldDecorator}
                                userRolesList={userRolesList}
                                handleRoleChange={this.handleRoleChange}
                                handleDelete={this.handleDelete}
                                displaySwitch={true}
                              />
                              <Button
                                onClick={this.handleBack}
                                className="mb-4 rounded-[20px]"
                              >
                                Back
                              </Button>
                            </Card>
                          )}
                        </div>
                      }
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </React.Fragment>
          }
        </Layout>
      </Form>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    header: state.pageHeader.header,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(Form.create({ name: "companyUserForm" })(CreateCompanyUser)));
