//@ts-nocheck
import React from "react";
import { Card } from "antd";
import { parseItems } from "../../../libs/utilities";

const PayScheduleExternalPayRun = (props: any) => {
  const { data } = props;
  return (
    data && (
      <Card
        title={`Pay Run Processing ${
          data.methodId ? `( ${data.methodId} )` : ""
        }`}
        bordered={false}
        className="card"
      >
        <div className="p-1">
          <ul className="list-unstyled mb-0 h-158 overflow-y-auto">
            <li>
              <b>Connected Instance:</b>{" "}
              {data &&
                parseItems(data.description, {}) &&
                parseItems(data.description, {}).Name}
            </li>
            <li>
              <b>Business Unit:</b>{" "}
              {data &&
                parseItems(data.description) &&
                parseItems(data.description).ExternalBusinessUnitName}
            </li>
            <li>
              <b>Pay Schedule:</b>{" "}
              {data &&
                parseItems(data.description) &&
                parseItems(data.description).ExternalPayScheduleName}
            </li>
          </ul>
        </div>
      </Card>
    )
  );
};

export default PayScheduleExternalPayRun;
