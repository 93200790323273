import styled from 'styled-components';

import { BodySmallRegular, BodySmallSemiBold, Colour } from '@xemplo/style-constants';

export const Cell = styled.td`
  ${BodySmallRegular};
  border-radius: 8px;
  width: 34px;
  height: 32px;
  color: ${Colour.Gray[500]};
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    color: ${Colour.Gray[700]};
  }

  &.today {
    border: 1px solid ${Colour.Gray[200]};
  }

  &.focused {
    border: 1px solid ${Colour.Blue[500]};
    outline: none;
  }

  &.selected {
    background: ${Colour.Blue[500]};
    border: none;
    color: ${Colour.White[100]};
    ${BodySmallSemiBold};
  }

  &.disabled {
    color: ${Colour.Gray[200]};
    background: ${Colour.White[100]};
    pointer-events: none;
  }
`;

export const CellInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: none;

  &:focus-visible {
    border: none;
    outline: none;
  }
`;
