// @ts-nocheck
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditFilled,
  EyeOutlined,
  MailFilled,
  RightSquareFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckSquareOutlined,
  LaptopOutlined,
  RobotOutlined,
  StopOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown, Button, Popconfirm } from "antd";
import { LgEllipsis } from "../../../Common/customicons";
import {
  DISABLE_DEVICE_ENROLMENT_ID,
  MANDATORY_DEVICE_ENROLMENT_ID,
  OPTIONAL_DEVICE_ENROLMENT_ID,
  PERMANENT_AMENDMENT_TYPE_ID,
} from "../../../constants";
import {
  getClaimAndStatus,
  isComponentVisible,
  isUserSystemAdmin,
  displayGeneralAmendmentActionOptions,
  displayTableGridActionComponent,
  getGeneralAmendmentActionText,
  getGeneralAmendmentStatusText,
  isAmendmentAutomated,
} from "../../../libs";
import classNames from "classnames";
import { If, YesNoModalTrigger } from "../../../Common/ui";

const TableActionsDropdown = (props: any) => {
  const {
    record,
    handleAction,
    editComponentName,
    viewComponentName,
    sendReminderComponentName,
    deleteComponentName,
    downloadPdfComponentName,
    downloadSummaryComponentName,
    claims,
    hasNoComponents,
    buttonClassName,
    classname,
    showDeleteOption,
    showEditOption,
    showViewOption,
    showDownloadPdf,
    showDownloadSummary,
    deviceEnrolmentValue,
    member,
    appliedInKeypay,
    requestCancel,
    applyCancel,
    applyEditedInKeypay,
    resubmitComponentName,
    showAssignBusinessUnits,
    assignBusinessUnit,
    downloadSummaryStatus,
    downloadPdfStatus,
    deleteStatus,
    sendReminderStatus,
    viewStatus,
    editStatus,
    approveComponentName,
    rejectComponentName,
    unApplyInKeyPay,
  } = props;

  const assignBusinessUnitClaim = getClaimAndStatus({
    claims,
    componentName: assignBusinessUnit,
    hasComponents: !hasNoComponents,
  });
  const editClaim = getClaimAndStatus({
    claims,
    componentName: editComponentName,
    hasComponents: !hasNoComponents,
  });
  const viewClaim = getClaimAndStatus({
    claims,
    componentName: viewComponentName,
    hasComponents: !hasNoComponents,
  });
  const deleteClaim = getClaimAndStatus({
    claims,
    componentName: deleteComponentName,
    hasComponents: !hasNoComponents,
  });
  const resubmitClaim = getClaimAndStatus({
    claims,
    componentName: resubmitComponentName,
    hasComponents: !hasNoComponents,
  });
  const sendReminderClaim = getClaimAndStatus({
    claims,
    componentName: sendReminderComponentName,
    hasComponents: !hasNoComponents,
  });

  const downloadPdfClaim = getClaimAndStatus({
    claims,
    componentName: downloadPdfComponentName,
    hasComponents: !hasNoComponents,
  });
  const downloadSummaryClaim = getClaimAndStatus({
    claims,
    componentName: downloadSummaryComponentName,
    hasComponents: !hasNoComponents,
  });

  const appliedInKeypayClaim = getClaimAndStatus({
    claims,
    componentName: appliedInKeypay,
    hasComponents: !hasNoComponents,
  });

  const requestCancelClaim = getClaimAndStatus({
    claims,
    componentName: requestCancel,
    hasComponents: !hasNoComponents,
  });

  const applyCancelClaim = getClaimAndStatus({
    claims,
    componentName: applyCancel,
    hasComponents: !hasNoComponents,
  });

  const applyEditedInKeypayClaim = getClaimAndStatus({
    claims,
    componentName: applyEditedInKeypay,
    hasComponents: !hasNoComponents,
  });

  const approveComponentClaim = getClaimAndStatus({
    claims,
    componentName: approveComponentName,
    hasComponents: !hasNoComponents,
  });

  const rejectComponentClaim = getClaimAndStatus({
    claims,
    componentName: rejectComponentName,
    hasComponents: !hasNoComponents,
  });

  const unApplyInKeyPayClaim = getClaimAndStatus({
    claims,
    componentName: unApplyInKeyPay,
    hasComponents: !hasNoComponents,
  });

  const assignBusinessUnitStatus = assignBusinessUnitClaim?.status;
  const resubmitStatus = resubmitClaim.status;
  const generalAmendmentCommonProps = {
    appliedInKeypayClaim: appliedInKeypayClaim.claim,
    applyCancelClaim: applyCancelClaim.claim,
    applyEditedInKeypayClaim: applyEditedInKeypayClaim.claim,
  };
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu className="table-actions">
          <If
            condition={displayTableGridActionComponent({
              claim: viewClaim.claim,
              defaultDisplay: showViewOption,
            })}
            then={
              <Menu.Item key="a">
                <div
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "view" })
                  }
                  className={classNames({ [viewStatus]: !showViewOption })}
                >
                  <EyeOutlined /> &nbsp;
                  <span> View</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={displayTableGridActionComponent({
              claim: editClaim.claim,
              defaultDisplay: showEditOption,
            })}
            then={
              <Menu.Item key="b">
                <div
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "edit" })
                  }
                  className={classNames({ [editStatus]: !showEditOption })}
                >
                  <EditFilled /> &nbsp;
                  <span> Edit</span>
                </div>
              </Menu.Item>
            }
          />
          <If
            condition={displayTableGridActionComponent({
              claim: approveComponentClaim.claim,
              defaultDisplay: false,
            })}
            then={
              <Menu.Item key="ab">
                <div
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "approve" })
                  }
                  className={classNames(approveComponentClaim.status)}
                >
                  <CheckOutlined /> &nbsp;
                  <span> Approve</span>
                </div>
              </Menu.Item>
            }
          />
          <If
            condition={isComponentVisible({
              claim: rejectComponentClaim.claim,
            })}
            then={
              <Menu.Item key="ac">
                <div
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "reject" })
                  }
                  className={classNames(rejectComponentClaim.status)}
                >
                  <StopOutlined rotate={90} /> &nbsp;
                  <span> Reject</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={isComponentVisible({ claim: resubmitClaim.claim })}
            then={
              <Menu.Item key="c">
                <div
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "reSubmit" })
                  }
                  className={resubmitStatus}
                >
                  <RightSquareFilled /> &nbsp;
                  <span> Resubmit</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={displayTableGridActionComponent({
              claim: deleteClaim.claim,
              defaultDisplay: showDeleteOption,
            })}
            then={
              <Menu.Item key="d">
                <YesNoModalTrigger
                  title="Are you sure you want to delete it?"
                  onOk={(e) =>
                    handleAction({ event: e, record, action: "delete" })
                  }
                >
                  <div
                    className={classNames({
                      [deleteStatus]: !showDeleteOption,
                    })}
                  >
                    <DeleteOutlined className="mr-2 text-sm" />
                    Delete
                  </div>
                </YesNoModalTrigger>
              </Menu.Item>
            }
          />

          {/* Unapply in Keypay */}
          <If
            condition={displayTableGridActionComponent({
              claim: unApplyInKeyPayClaim.claim,
              defaultDisplay: false,
            })}
            then={
              <Menu.Item key="ud">
                <Popconfirm
                  placement="bottomLeft"
                  title={"Are you sure you want unapply this amendment?"}
                  onConfirm={(e) =>
                    handleAction({ event: e, record, action: "unapply" })
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <div className={classNames(unApplyInKeyPayClaim.status)}>
                    <CloseOutlined /> &nbsp;
                    <span> Unapply in Keypay</span>
                  </div>
                </Popconfirm>
              </Menu.Item>
            }
          />

          <If
            condition={
              showAssignBusinessUnits &&
              isComponentVisible({
                claim: assignBusinessUnitClaim?.claim,
              })
            }
            then={
              <Menu.Item key="m">
                <div
                  onClick={(e) =>
                    handleAction({
                      event: e,
                      record,
                      action: "assignBusinessUnit",
                    })
                  }
                  className={assignBusinessUnitStatus}
                >
                  <CheckSquareOutlined /> &nbsp;
                  <span>Assign Business Unit</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={isComponentVisible({ claim: sendReminderClaim.claim })}
            then={
              <Menu.Item key="e">
                <div
                  onClick={(e) =>
                    handleAction({
                      event: e,
                      record,
                      action: "sendReminder",
                    })
                  }
                  className={sendReminderStatus}
                >
                  <MailFilled /> &nbsp;
                  <span> Send Reminder</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={displayTableGridActionComponent({
              claim: downloadPdfClaim.claim,
              defaultDisplay: showDownloadPdf,
            })}
            then={
              <Menu.Item key="f">
                <div
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "downloadPdf" })
                  }
                  className={classNames({
                    [downloadPdfStatus]: !showDownloadPdf,
                  })}
                >
                  <DownloadOutlined /> &nbsp;
                  <span> Download Invoice</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={displayTableGridActionComponent({
              claim: downloadSummaryClaim.claim,
              defaultDisplay: showDownloadSummary,
            })}
            then={
              <Menu.Item key="g">
                <div
                  onClick={(e) =>
                    handleAction({
                      event: e,
                      record,
                      action: "downloadSummary",
                    })
                  }
                  className={classNames({
                    [downloadSummaryStatus]: !showDownloadSummary,
                  })}
                >
                  <DownloadOutlined /> &nbsp;
                  <span> Download Summary</span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={isUserSystemAdmin({ member })}
            then={
              <>
                <Menu.Divider key="divider-1" />
                <Menu.Item key="h">
                  <div
                    onClick={(e) =>
                      handleAction({
                        event: e,
                        record,
                        action: "mandatoryDeviceEnrolment",
                      })
                    }
                  >
                    <LaptopOutlined /> &nbsp;
                    <span>Mandatory Device Enrolment</span>
                    {deviceEnrolmentValue === MANDATORY_DEVICE_ENROLMENT_ID && (
                      <CheckOutlined className="text-xs ml-1.5" />
                    )}
                  </div>
                </Menu.Item>
                <Menu.Item key="i">
                  <div
                    onClick={(e) =>
                      handleAction({
                        event: e,
                        record,
                        action: "optionalDeviceEnrolment",
                      })
                    }
                  >
                    <LaptopOutlined /> &nbsp;
                    <span>Optional Device Enrolment</span>
                    {deviceEnrolmentValue === OPTIONAL_DEVICE_ENROLMENT_ID && (
                      <CheckOutlined className="text-xs ml-1.5" />
                    )}
                  </div>
                </Menu.Item>
                <Menu.Item key="j">
                  <div
                    onClick={(e) =>
                      handleAction({
                        event: e,
                        record,
                        action: "disableDeviceEnrolment",
                      })
                    }
                  >
                    <RobotOutlined /> &nbsp;
                    <span>Disable Device Enrolment</span>
                    {deviceEnrolmentValue === DISABLE_DEVICE_ENROLMENT_ID && (
                      <CheckOutlined className="text-xs ml-1.5" />
                    )}
                  </div>
                </Menu.Item>
              </>
            }
          />

          <If
            condition={displayGeneralAmendmentActionOptions({
              ...generalAmendmentCommonProps,
            })}
            then={
              <Menu.Item key="k">
                <div
                  onClick={(e) =>
                    handleAction({
                      event: e,
                      record,
                      action: getGeneralAmendmentActionText({
                        ...generalAmendmentCommonProps,
                      }),
                    })
                  }
                >
                  <If
                    condition={
                      isAmendmentAutomated({ amendment: record }) ||
                      record?.amendmentTypeId === PERMANENT_AMENDMENT_TYPE_ID
                    }
                    then={<CheckOutlined />}
                    else={<CheckCircleOutlined />}
                  />
                  &nbsp;&nbsp;
                  <span>
                    {getGeneralAmendmentStatusText({
                      ...generalAmendmentCommonProps,
                      amendment: record,
                    })}
                  </span>
                </div>
              </Menu.Item>
            }
          />

          <If
            condition={isComponentVisible({ claim: requestCancelClaim })}
            then={
              <Menu.Item key="l">
                <div
                  onClick={(e) =>
                    handleAction({
                      event: e,
                      record,
                      action: "requestCancel",
                    })
                  }
                >
                  <CloseCircleOutlined /> &nbsp;
                  <span>Cancel Amendment</span>
                </div>
              </Menu.Item>
            }
          />
        </Menu>
      }
      placement="bottomRight"
      className={classname}
    >
      <Button
        onClick={(e) => e.stopPropagation()}
        className={`${buttonClassName} no-border btn-dropdown-action ellipsis flex items-center px-1.5 rounded-full`}
      >
        <LgEllipsis />
      </Button>
    </Dropdown>
  );
};
export default TableActionsDropdown;
