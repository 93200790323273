import { PayrollRoutes } from '@xemplo/gp-routes';
import { NoAccess } from '@xemplo/no-access';

/**
 * This component is a basic wrapper for routing.
 * All tests and logic are handled in the `NoAccess` component.
 */
export function NoAccessPage() {
  const handleClick = () => (window.location.pathname = `/${PayrollRoutes.Logout}`);
  return <NoAccess centered onClick={handleClick} />;
}
