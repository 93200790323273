import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import {
  containerBase,
  containerDisabled,
  containerFocus,
  containerHasLabelPadding,
  containerReadonly,
  descriptionBase,
  errorMessageBase,
  focusState,
  hiddenLabelBase,
  InputFieldSize,
  labelInputHasValue,
  labelVisible,
  wrapperBase,
} from '@xemplo/input-utils';
import { BodySmallRegular, Colour, Shadow } from '@xemplo/style-constants';

export const Wrapper = styled.div<{ $width?: string }>`
  ${wrapperBase};
  display: flex;
`;
export const InnerContainer = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerDisabled};
  ${containerReadonly};
  ${containerHasLabelPadding};
  flex-grow: 1;
  border: 1px solid ${Colour.Gray[200]};

  &.input-has-error {
    border-color: ${Colour.Red[500]};
    & > label {
      color: ${Colour.Red[600]};
    }
  }
  &.input-focused {
    ${focusState};
    ${containerFocus};
    z-index: 1;
    border-color: ${Colour.Blue[500]} !important;
  }

  &.from-date {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.to-date {
    border-radius: 0;
    border-inline-color: ${Colour.Gray[200]};
    margin-left: -1px;
    margin-right: -1px;
  }
  &.medium {
    height: 40px;
  }
  &.standard {
    height: 48px;
  }
  &.large {
    height: 64px;
  }
`;
export const Container = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerDisabled};
  ${containerReadonly};
  &.input-has-error {
    box-shadow: ${Shadow.Error};
  }
  padding: 0;
  border: none;
  gap: 0;
  flex-grow: 1;
`;

export const Label = styled.label`
  ${hiddenLabelBase};
  ${labelVisible};
  ${labelInputHasValue};
  left: 16px;
`;

export const ErrorMessage = styled.div`
  ${errorMessageBase};
`;

export const Description = styled.div`
  ${descriptionBase};
`;

export const CalendarIconContainer = styled(IconButton)`
  flex: 0 0 auto;
  border: 1px solid ${Colour.Gray[200]};
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 30px;
  width: 56px;
  &:hover {
    border-color: ${Colour.Gray[200]};
  }
  &.input-has-error {
    border-color: ${Colour.Red[500]};
    border-left: 0;
  }

  &:focus-visible {
    outline: none;
  }
  &.medium {
    height: 40px;
  }
  &.standard {
    height: 48px;
  }
  &.large {
    height: 64px;
  }
  & > svg {
    color: ${Colour.Gray[400]};
  }
`;

export const CustomInput = styled.input`
  display: none;
  &.show-placeholder {
    display: block;
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    width: fit-content;
    pointer-events: none;
    &::placeholder {
      ${BodySmallRegular};
      color: ${Colour.Gray[400]};
    }
  }
`;
