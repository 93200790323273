import { ConfigProvider } from "antd";
import { If } from "../../../Common/ui";
import { NONCE_ID } from "../../../constants";
import { getClaimAndStatus } from "../../../libs/utilities";
import TableActionsDropdown from "./TableActionsDropdown";
import TableActionsIcons from "./TableActionsIcons";

const TableActionComponent = (props: any) => {
  const {
    editComponentName,
    viewComponentName,
    sendReminderComponentName,
    deleteComponentName,
    downloadPdfComponentName,
    downloadSummaryComponentName,
    claims,
    hasNoComponents,
    displayActionIcons,
  } = props;

  const editClaim = getClaimAndStatus({
    claims,
    componentName: editComponentName,
    hasComponents: !hasNoComponents,
  });

  const viewClaim = getClaimAndStatus({
    claims,
    componentName: viewComponentName,
    hasComponents: !hasNoComponents,
  });

  const deleteClaim = getClaimAndStatus({
    claims,
    componentName: deleteComponentName,
    hasComponents: !hasNoComponents,
  });

  const sendReminderClaim = getClaimAndStatus({
    claims,
    componentName: sendReminderComponentName,
    hasComponents: !hasNoComponents,
  });

  const downloadPdfClaim = getClaimAndStatus({
    claims,
    componentName: downloadPdfComponentName,
    hasComponents: !hasNoComponents,
  });

  const downloadSummaryClaim = getClaimAndStatus({
    claims,
    componentName: downloadSummaryComponentName,
    hasComponents: !hasNoComponents,
  });

  const editStatus = editClaim.status;
  const viewStatus = viewClaim.status;
  const sendReminderStatus = sendReminderClaim.status;
  const deleteStatus = deleteClaim.status;
  const downloadPdfStatus = downloadPdfClaim.status;
  const downloadSummaryStatus = downloadSummaryClaim.status;

  return (
    <ConfigProvider csp={{ nonce: NONCE_ID.TABLE_ACTIONS }}>
      <If
        condition={displayActionIcons}
        then={
          <TableActionsIcons
            {...props}
            downloadSummaryStatus={downloadSummaryStatus}
            downloadPdfStatus={downloadPdfStatus}
            deleteStatus={deleteStatus}
            sendReminderStatus={sendReminderStatus}
            viewStatus={viewStatus}
            editStatus={editStatus}
          />
        }
        else={
          <TableActionsDropdown
            {...props}
            downloadSummaryStatus={downloadSummaryStatus}
            downloadPdfStatus={downloadPdfStatus}
            deleteStatus={deleteStatus}
            sendReminderStatus={sendReminderStatus}
            viewStatus={viewStatus}
            editStatus={editStatus}
          />
        }
      />
    </ConfigProvider>
  );
};
export default TableActionComponent;
