import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MockAdapter from 'axios-mock-adapter';

import { AuthProvider } from '@xemplo/auth-provider';
import { AxiosMockProvider } from '@xemplo/axios-mock';
import { ErrorBoundary } from '@xemplo/error-boundary';
import { RootStyle } from '@xemplo/root-style';
import { XemploApiProvider } from '@xemplo/xemplo-api-provider';

import { PayrunStepper } from '../payrun-stepper';
import { PayrunStepperProps } from '../payrun-stepper.types';

import { mockMilestoneTestDataBuilder } from './test.data';

const eimsTestConfig = {
  authority: 'https://id-dev.xemplo.team',
  client_id: 'expedo.globaldash',
  redirect_uri: 'http://localhost:3000/confirm-login',
  post_logout_redirect_uri: 'http://localhost:3000/confirm-logout',
  response_type: 'code',
  scope: 'openid profile email address standard_api IdentityServerApi',
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
};

const appApiUrl = 'http://api.com';
const client = new QueryClient();

export const Wrapper = ({ children }: PropsWithChildren) => {
  const mock = (adapter: MockAdapter) => {
    adapter
      .onGet(/milestones/)
      .reply(200, { result: { ...mockMilestoneTestDataBuilder(8) } });
  };

  return (
    <ErrorBoundary>
      <AuthProvider {...eimsTestConfig}>
        <XemploApiProvider applicationApiUrl={appApiUrl}>
          <QueryClientProvider client={client}>
            <AxiosMockProvider mock={mock}>{children}</AxiosMockProvider>
          </QueryClientProvider>
        </XemploApiProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export const StorybookRender = (args: PayrunStepperProps) => {
  return (
    <Wrapper>
      <RootStyle />
      <PayrunStepper {...args} />
    </Wrapper>
  );
};
