import { forwardRef, isValidElement, Ref, useMemo } from 'react';
import classnames from 'classnames';

import { IconButtonSize } from '@xemplo/icon-button';
import { DawnCross16 } from '@xemplo/icons';

import { DrawerBody } from './body/drawer-body';
import { DrawerHeader } from './header/drawer-header';
import { useDrawer } from './hooks/use-drawer';
import * as S from './drawer.styles';
import { DrawerHeaderProps, DrawerMode, DrawerProps } from './drawer.types';

export const DrawerTestId = {
  container: 'drawer-container',
  content: 'drawer-content',
};

const DEFAULT_WIDTH = 488;

export const Drawer = forwardRef<HTMLElement, DrawerProps>(
  (props: DrawerProps, ref?: Ref<HTMLElement>) => {
    const { customTop } = props;
    const { drawerState, handleCloseClick } = useDrawer();

    const { header, body, footer } = drawerState.content;
    const { mode, position, width, headerKeyline } = drawerState.config;

    const headerElement = useMemo(() => {
      if (!header) return <DrawerHeader />;
      if (isValidElement(header)) return header;
      return <DrawerHeader {...(header as DrawerHeaderProps)} />;
    }, [header]);

    return (
      <S.DrawerWrapper
        ref={ref}
        data-open={drawerState.isOpen ?? false}
        data-position={position}
        data-testid={DrawerTestId.container}
        $width={width ?? DEFAULT_WIDTH}
        className={props.className}
      >
        {drawerState.contentVisible && (
          <S.DrawerContentWrapper data-testid={DrawerTestId.content} $offset={customTop}>
            {mode === DrawerMode.Standard && (
              <S.CloseButton
                id="drawer-close-btn"
                ariaLabel="Button to close drawer"
                size={IconButtonSize.Small}
                naked
                onClick={handleCloseClick}
              >
                <DawnCross16 />
              </S.CloseButton>
            )}
            <S.DrawerHeaderWrapper
              id="drawer-header"
              className={classnames({ keyline: !!headerKeyline })}
            >
              {headerElement}
            </S.DrawerHeaderWrapper>
            <DrawerBody customTop={customTop}>{body}</DrawerBody>
            {mode === DrawerMode.Standard && footer}
          </S.DrawerContentWrapper>
        )}
      </S.DrawerWrapper>
    );
  }
);

export default Drawer;
