import { useEffect, useRef } from 'react';

/**
 * Since this is a generic function, we cannot limit what sort of argument types will be used
 * in the callback. Therefore, we need to disable the eslint rule for this line.
 *
 * Using unknown instead of any is not an option, since it will not allow us to use the spread operator
 * in the debouncedCallback function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CallbackArg = (...args: any[]) => void | unknown;

export function useDebounceCallback<T extends CallbackArg>(
  callback: T,
  delay: number
): T {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  function debouncedCallback(this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return debouncedCallback as T;
}
