// @ts-nocheck
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import { If, RequiredSign } from "../../../Common/ui";
import { handleRequest, mustBeArray, trimWhiteSpaces } from "../../../libs";
import { uploadPayrunReports } from "../../actions/payrun";
import DragAndDropUpload from "../common/dragAndDropUpload";
import FileNameInput from "../common/fileNameInput";

const UploadReportsModal = ({
  isModalVisible,
  handleCancel,
  payrun,
  refreshPayrunReportList,
  payrunReports,
}: {
  isModalVisible?: boolean;
  handleCancel: () => void;
  refreshPayrunReportList: () => void;
  payrun: any;
  payrunReports: Array<any>;
}) => {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [uploadReportsLoading, setUploadReportsLoading] = useState(false);
  const [uploadEnabled, setUploadEnabled] = useState(false);
  const dispatchAction: any = useDispatch();

  useEffect(() => {
    if (isModalVisible) {
      setFileList([]);
      setUploadEnabled(false);
    }
  }, [isModalVisible]);

  const onUploadFile = (data: any) => {
    setUploadEnabled(true);
    let updatedFileList = data.fileList.reduce((a: Array<any>, b: any) => {
      const customName =
        fileList.find((o) => o.uid === b.uid)?.customName ?? "";

      return [...a, { ...b, customName }];
    }, []);
    setFileList(updatedFileList);
  };

  const removeFile = (data: any) => {
    let filesToRemove = fileList.filter((file) => file.uid !== data.uid);
    filesToRemove = checkDuplicateFileNames(filesToRemove);
    setUploadEnabled(filesToRemove.length > 0);
    setFileList(filesToRemove);
  };

  const checkDuplicateFileNames = (newFileList: Array<any>) => {
    // Get all the file names
    let names = newFileList.map((o) => o.customName);
    // Get names from previously uploaded files
    const uploadedFileNames = [...mustBeArray(payrunReports)].map(
      (o) => o.displayName
    );
    const totalFiles = [...names, ...uploadedFileNames];
    // check for not empty string duplicated items in the array
    const duplicateNames: string[] = totalFiles.filter(
      (o, i) => o !== "" && totalFiles.indexOf(o) !== i
    );

    // toggle the upload button
    setUploadEnabled(duplicateNames.length === 0);

    // show error message if duplicate names are found
    newFileList = newFileList.map((file) => {
      return {
        ...file,
        errorMessage:
          file.customName !== "" && duplicateNames.includes(file.customName)
            ? "Please select a unique file name"
            : "",
      };
    });
    return newFileList;
  };

  const handleBlur = (e: any, uid: string) => {
    let fileName = trimWhiteSpaces(e.target.value);
    let newFileList = [];
    if (fileName) {
      newFileList = fileList.map((file) => {
        return file.uid === uid
          ? { ...file, customName: e.target.value }
          : { ...file };
      });

      newFileList = checkDuplicateFileNames(newFileList);
    } else {
      newFileList = fileList.map((file) => {
        return file.uid === uid
          ? {
              ...file,
              customName: fileName,
              errorMessage: "File Name is required",
            }
          : { ...file };
      });
    }
    setFileList([...newFileList]);
  };

  const uploadReports = async () => {
    if (validateFileList()) {
      return message.error("File Name is required");
    } else {
      const files = mustBeArray(fileList).reduce((a, b) => {
        return a.concat({
          file: b.originFileObj,
          displayName: b.customName,
        });
      }, []);

      setUploadReportsLoading(true);
      let pendingRequests: any = [];
      files.forEach((data: any) => {
        let request = dispatchAction(
          uploadPayrunReports({
            id: payrun.payrunId,
            payload: data.file,
            displayName: data.displayName,
          })
        );
        pendingRequests = [...pendingRequests, request];
      });
      const response = await Promise.all(pendingRequests);
      setUploadReportsLoading(false);
      handleUploadResponse(response);
      refreshPayrunReportList();
    }
  };

  const handleUploadResponse = (response: any) => {
    let failedResponse = mustBeArray(response).filter((o) => !o.status);
    switch (failedResponse.length) {
      case 0:
        handleCancel();
        return message.success("Files uploaded successfully");
      case response.length:
        handleCancel();
        message.error("Upload Failed");
        failedResponse.forEach((data: any) => {
          handleRequest({ response: data });
        });
        break;
      default:
        handleCancel();
        return message.success("Some of the files failed to upload");
    }
  };

  const validateFileList = () => {
    if (!_.isEmpty(fileList)) {
      const isNameEmpty = mustBeArray(fileList).find(
        (o) => trimWhiteSpaces(o.customName) === ""
      );
      if (isNameEmpty) return true;
      return false;
    }
  };

  return (
    <Modal
      width={800}
      visible={isModalVisible}
      footer={[
        <SimpleButton
          className={classNames({ "bg-success": uploadEnabled })}
          buttonText="Upload"
          ignoreStatus
          icon={<UploadOutlined />}
          key="Upload"
          onClick={uploadReports}
          loading={uploadReportsLoading}
          buttonDisabled={!uploadEnabled}
        />,
      ]}
      onCancel={handleCancel}
      title="Upload Reports"
    >
      <If
        condition={fileList.length >= 1}
        then={<div className="pb-2 font-bold">Uploaded</div>}
      />

      {fileList.map((data: any, key: any) => (
        <React.Fragment key={key}>
          <div className="grid grid-cols-10 mb-2 items-center">
            <div className="col-span-4">{data.name}</div>
            <div className="col-span-5 pl-2">
              <FileNameInput uid={data.uid} handleBlur={handleBlur} />
            </div>
            <div className="col-span-1">
              <CloseOutlined
                className="hand color-danger"
                onClick={() => removeFile(data)}
              />
            </div>
          </div>
          {data.errorMessage && (
            <div className="flex justify-end pr-28 color-danger mb-4">
              {data.errorMessage}
            </div>
          )}
        </React.Fragment>
      ))}

      <div className="pb-2 font-bold">
        <RequiredSign className="pr-1" />
        Upload Reports
      </div>

      <DragAndDropUpload
        fileList={fileList}
        onUpload={onUploadFile}
        accept=".pdf, .xlsx, .xls, .zip, .csv"
      />
    </Modal>
  );
};

export default UploadReportsModal;
