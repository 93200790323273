/* global: document*/
//@ts-nocheck
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Input } from "antd";
import { filterGeoAddressLocation } from "../libs/utilities";

type State = any;

type Props = any;

class LocationSearchInput extends React.Component<Props, State> {
  locationField: any;
  constructor(props: {}) {
    super(props);
    const value = props.value || {};
    this.state = {
      address: value.address || "",
      placeholder: value.placeholder,
      className: value.className,
    };
  }

  handleChange = (address: any) => {
    this.props.onAddressChange(address);
  };

  handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then((results: any) => {
        let addresses = filterGeoAddressLocation(results);
        this.setState({ address: addresses?.address1 });
        return this.props.onChange(results);
      })
      .catch((error: any) => console.error("Error", error));
  };

  UNSAFE_componentWillReceiveProps(newProps: any) {
    const { disabled, type } = newProps;
    if (disabled) {
      document.querySelector(`.${type}-address-input`) &&
        document
          .querySelector(`.${type}-address-input`)
          .setAttribute("disabled", "disabled");
    } else {
      document.querySelector(`.${type}-address-input`) &&
        document
          .querySelector(`.${type}-address-input`)
          .removeAttribute("disabled");
    }
  }

  render() {
    const searchOptions = {
      componentRestrictions: { country: [] }, //"sg", "au", "nz", "gb"
    };
    const { mapLoaded } = this.props;
    return mapLoaded ? (
      <PlacesAutocomplete
        value={this.props.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => (
          <div>
            <Input
              ref={(ref) => (this.locationField = ref)}
              disabled={this.props.disabled}
              {...getInputProps({
                placeholder: this.state.placeholder,
                className: this.state.className,
              })}
              autoComplete="nope"
            />
            <div className="autocomplete-dropdown-container location-search">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion: any, index: any) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const placesKey = suggestion && suggestion.placeId;
                return (
                  <div
                    key={placesKey}
                    {...getSuggestionItemProps(suggestion, {
                      className: `${className} location-search-${
                        suggestion.active ? "active" : "inactive"
                      }`,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    ) : (
      <React.Fragment />
    );
  }
}

export default LocationSearchInput;
