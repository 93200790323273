import { useEffect, useState } from 'react';

import { ButtonType } from '@xemplo/button';
import { ModalBodyOverflow, ModalSize, ModalTheme, useModal } from '@xemplo/modal';
import { PromptType } from '@xemplo/prompts';

export const useClosePrompt = () => {
  const { toggleModal } = useModal();

  const [promptVisible, setPromptVisible] = useState(false);

  const handlePromptConfirm = () => {
    setPromptVisible(false);
    return toggleModal(false);
  };

  const promptProps = {
    type: PromptType.General,
    theme: ModalTheme.Light,
    size: ModalSize.Small,
    closeButton: { hide: true },
    open: promptVisible,
    onCloseCallback: () => setPromptVisible(false),
    header: {
      title: 'Are you sure you want to cancel?',
    },
    body: {
      content: <div>You will lose any unsubmitted data.</div>,
      overflow: ModalBodyOverflow.Extended,
    },
    footer: {
      btnPrimary: {
        label: 'Yes',
        ariaLabel: 'Yes Btn',
        onClick: () => handlePromptConfirm(),
        type: ButtonType.Primary,
      },
      btnSecondary: {
        label: 'No',
        ariaLabel: 'No Btn',
        type: ButtonType.Secondary,
        onClick: () => setPromptVisible(false),
      },
    },
  };

  useEffect(() => {
    if (promptProps.open) {
      setPromptVisible(true);
    }
  }, [promptProps.open]);

  return { promptProps, promptVisible, setPromptVisible };
};
