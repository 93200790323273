import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  TimePicker,
  Upload,
} from "antd";
import moment from "moment";
import { getDisabledDates } from "../../../libs";
import {
  getConditionalResponse,
  getLowerCase,
  getMaxCharacters,
  mustBeArray,
} from "../../../libs/utilities";
import _ from "lodash";
import {
  AMENDMENT_AMOUNT_CURRENCY_TYPE,
  AMENDMENT_AMOUNT_DECIMAL_TYPE,
  AMENDMENT_AMOUNT_WHOLENUMBER_TYPE,
} from "../../../constants/payrun";
import InputTypeNumber from "../../../Common/InputTypeNumber";
import InputTypeNumberDefault from "../../../Common/InputTypeNumberDefault";
import { YesNoTimePeriod, KeyPairDynamic } from "../../../Common/ui";

const Option = Select.Option;
const RadioGroup = Radio.Group;
const timeFormat = "HH: mm";

type State = any;
type Props = any;

class InputTypes extends React.Component<Props, State> {
  dropDownWithArea: any;
  dropDownWithText: any;
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  uploadAmendmentFile = (value: any) => {
    this.props.uploadAmendmentFile(value);
    return false;
  };

  render() {
    const {
      question,
      inputValueChange,
      dropDownWithTextChange,
      addQuestion,
      removeQuestion,
      handleDefaultChange,
    } = this.props;
    switch (getLowerCase(question?.type)) {
      case "dropdown":
        return (
          <Select
            className="min-w-[50%] w-3/6"
            onChange={(value) => inputValueChange(value)}
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
          >
            {mustBeArray(question.options).map((option: any) => {
              return (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              );
            })}
          </Select>
        );
      case "number":
        return (
          <React.Fragment>
            <InputTypeNumberDefault
              question={question}
              handleChange={inputValueChange}
              min={question?.threshold?.minNumber}
              max={question?.threshold?.maxNumber}
            />
            {question.subLabel && (
              <span className="ml-2">{question.subLabel}</span>
            )}
          </React.Fragment>
        );
      case AMENDMENT_AMOUNT_WHOLENUMBER_TYPE:
      case AMENDMENT_AMOUNT_CURRENCY_TYPE:
      case AMENDMENT_AMOUNT_DECIMAL_TYPE:
        return (
          <InputTypeNumber
            question={question}
            handleChange={inputValueChange}
          />
        );
      case "textarea":
        return (
          <Input.TextArea
            rows={4}
            className="w-full  min-w-[100%]"
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
            onChange={(value) => inputValueChange(value.currentTarget.value)}
            maxLength={getMaxCharacters({
              validation: question?.threshold,
            })}
          />
        );
      case "email":
        return (
          <Input
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
            type="email"
            className="min-w-[50%] w-3/6"
            onChange={(value) => inputValueChange(value.currentTarget.value)}
            maxLength={getMaxCharacters({
              validation: question?.threshold,
            })}
          />
        );
      case "date":
        return (
          <DatePicker
            className="min-w-[50%] w-3/6"
            format="DD/MM/YYYY"
            onChange={(value) => inputValueChange(value?.format("DD/MM/YYYY"))}
            value={getConditionalResponse({
              condition: question?.value,
              resp1: moment(question.value, "DD/MM/YYYY"),
              resp2: null,
            })}
            disabledDate={(e) => {
              return getDisabledDates({
                selectionDate: e,
                validations: question?.threshold,
              });
            }}
            showToday={false}
            inputReadOnly
          />
        );
      case "time":
        return (
          <TimePicker
            className="min-w-[50%] w-3/6"
            onChange={(value) => inputValueChange(value?.format(timeFormat))}
            value={getConditionalResponse({
              condition: question?.value,
              resp1: moment(question.value, timeFormat),
              resp2: null,
            })}
            showNow={false}
            inputReadOnly={true}
          />
        );
      case "switch":
        return (
          <Switch
            checked={question.value}
            onChange={(value) => inputValueChange(value)}
          />
        );
      case "textonswitch":
        return (
          <React.Fragment>
            <div>
              <Switch
                checked={getConditionalResponse({
                  condition: question?.value,
                  resp1: true,
                  resp2: false,
                })}
                onChange={(value) =>
                  value ? inputValueChange(" ") : inputValueChange(value)
                }
              />
            </div>
            {question.value && (
              <Input
                value={question?.value ?? ""}
                className="min-w-[50%] w-3/6 mt-4"
                onChange={(value) =>
                  inputValueChange(value.currentTarget.value)
                }
                maxLength={getMaxCharacters({
                  validation: question?.threshold,
                })}
              />
            )}
          </React.Fragment>
        );
      case "textareaonswitch":
        return (
          <React.Fragment>
            <div>
              <Switch
                checked={getConditionalResponse({
                  condition: question?.value,
                  resp1: true,
                  resp2: false,
                })}
                onChange={(value) =>
                  value ? inputValueChange(" ") : inputValueChange(value)
                }
              />
            </div>
            {question.value && (
              <Input.TextArea
                value={getConditionalResponse({
                  condition: question?.value,
                  resp1: question.value,
                  resp2: "",
                })}
                rows={4}
                className="min-w-[50%] w-3/6 mt-4"
                onChange={(value) =>
                  inputValueChange(value.currentTarget.value)
                }
                maxLength={getMaxCharacters({
                  validation: question?.threshold,
                })}
              />
            )}
          </React.Fragment>
        );
      case "radio":
        return (
          <RadioGroup
            onChange={(value) => inputValueChange(value.target.value)}
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
          >
            {mustBeArray(question.options).map((option: any) => {
              return (
                <Radio
                  key={option.id}
                  value={option.id}
                  className="radio-vertical"
                >
                  {option.name}
                </Radio>
              );
            })}
          </RadioGroup>
        );
      case "keypairdynamic":
        return (
          <KeyPairDynamic
            question={question}
            handleChange={inputValueChange}
            addQuestion={addQuestion}
            removeQuestion={removeQuestion}
          />
        );
      case "yesnotimeperiod":
        return (
          <YesNoTimePeriod
            question={question}
            handleChange={inputValueChange}
            handleDefaultChange={handleDefaultChange}
          />
        );
      case "yesno":
        return (
          <RadioGroup
            onChange={(value) => inputValueChange(value.target.value)}
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
          >
            <Radio key={1} value={1} className="radio-vertical">
              Yes
            </Radio>
            <Radio key={2} value={2} className="radio-vertical">
              No
            </Radio>
          </RadioGroup>
        );
      case "checkbox":
        return (
          <React.Fragment>
            {mustBeArray(question.options).map((option: any, i: any) => {
              return (
                <Checkbox
                  key={i}
                  onChange={(value) => inputValueChange(value, i)}
                  checked={option.value}
                >
                  {option.name}
                </Checkbox>
              );
            })}
          </React.Fragment>
        );
      case "keypairlistdropdown":
        return mustBeArray(question.options).map((option: any, i: any) => {
          return (
            <React.Fragment key={i}>
              <Row className="mt-3">
                <Col span={6}>{option.name}</Col>
                <Col span={10}>
                  <Input
                    value={getConditionalResponse({
                      condition: option.value,
                      resp1: option.value,
                      resp2: "",
                    })}
                    className="w-full "
                    onChange={(value) =>
                      inputValueChange(value.currentTarget.value, i)
                    }
                    maxLength={getMaxCharacters({
                      validation: question?.threshold,
                    })}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        });
      case "multiselectdropdown":
        return (
          <Select
            className="min-w-[50%] w-3/6"
            mode="multiple"
            onChange={(value) => inputValueChange(value)}
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
          >
            {mustBeArray(question.options).map((option: any) => {
              return (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              );
            })}
          </Select>
        );
      case "file":
        return (
          <React.Fragment>
            <Upload
              beforeUpload={(file) => this.uploadAmendmentFile(file)}
              showUploadList={false}
              className="mr-4"
            >
              <Button className="rounded-[20px]">
                <UploadOutlined /> Upload File
              </Button>
            </Upload>
            {question?.templateUrl && (
              <a href={question.templateUrl} target="__blank">
                Download Template
              </a>
            )}
          </React.Fragment>
        );
      case "dropdownwithotherswithtext":
        return (
          <React.Fragment>
            <Select
              className="min-w-[50%] w-3/6"
              ref={(ref) => (this.dropDownWithText = ref)}
              onChange={(value) => inputValueChange(value)}
              value={getConditionalResponse({
                condition: question?.value,
                resp1: question.value,
                resp2: "",
              })}
            >
              {mustBeArray(question.options).map((option: any) => {
                return (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                );
              })}
            </Select>
            {_.find(question.options, (o) => o.id === question?.value)?.name ===
              "Other" && (
              <div>
                <Input
                  value={getConditionalResponse({
                    condition: question.textValue,
                    resp1: question.textValue,
                    resp2: "",
                  })}
                  className="min-w-[50%] w-3/6 mt-4"
                  onChange={(value) =>
                    dropDownWithTextChange(value.currentTarget.value)
                  }
                  maxLength={getMaxCharacters({
                    validation: question?.threshold,
                  })}
                />
              </div>
            )}
          </React.Fragment>
        );
      case "dropdownwithotherswithtextarea":
        return (
          <React.Fragment>
            <Select
              className="min-w-[50%] w-3/6"
              ref={(ref) => (this.dropDownWithArea = ref)}
              onChange={(value) => inputValueChange(value)}
              value={getConditionalResponse({
                condition: question?.value,
                resp1: question.value,
                resp2: "",
              })}
            >
              {mustBeArray(question.options).map((option: any) => {
                return (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                );
              })}
            </Select>
            {_.find(question.options, (o) => o.id === question?.value)?.name ===
              "Other" && (
              <div>
                <Input.TextArea
                  value={getConditionalResponse({
                    condition: question.textValue,
                    resp1: question.textValue,
                    resp2: "",
                  })}
                  rows={4}
                  className="min-w-[50%] w-3/6 mt-4"
                  onChange={(value) =>
                    dropDownWithTextChange(value.currentTarget.value)
                  }
                  maxLength={getMaxCharacters({
                    validation: question?.threshold,
                  })}
                />
              </div>
            )}
          </React.Fragment>
        );
      default:
        return (
          <Input
            value={getConditionalResponse({
              condition: question?.value,
              resp1: question.value,
              resp2: "",
            })}
            className="min-w-[50%] w-3/6"
            onChange={(value) => inputValueChange(value.currentTarget.value)}
            maxLength={getMaxCharacters({
              validation: question?.threshold,
            })}
          />
        );
    }
  }
}

export default InputTypes;
