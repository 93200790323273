import { CalendarDate, parseDate } from '@internationalized/date';

/** This function should only be used within the context of the text-date-picker v2.
 *  It will attempt to parse a string into a React Aria date object (Not a Javascript Date object). If it fails, it will return undefined.
 * Note: Only accepts an ISO 8601 date string, with no time components. Other string formats will return undefined.
 */
export function tryParseDate(value: string) {
  try {
    return parseDate(value);
  } catch (e) {
    return undefined;
  }
}

/** This function should only be used within the context of the text-date-picker v2. If no valid date is provided, it will return the current date. */
export function parseToCalendarDate(date?: Date | string) {
  let newDate: Date;
  //No date provided - use today's date
  if (!date) {
    newDate = new Date();
  }
  // Date is a string
  else if (typeof date === 'string') {
    // Check if it's a valid JavaScript Date string
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      newDate = parsedDate;
    } else {
      // Try parsing using tryParseDate
      const tryParseResult = tryParseDate(date);
      if (tryParseResult) {
        return tryParseResult;
      } else {
        newDate = new Date();
      }
    }
  }
  // Date is a Date object
  else {
    newDate = date;
  }

  return new CalendarDate(
    newDate.getFullYear(),
    newDate.getMonth() + 1,
    newDate.getDate()
  );
}
