//@ts-nocheck
import React from "react";
import BasicDetails from "../../components/basicDetails";
import { message, Spin, Tabs } from "antd";
import CompanyUserList from "../../components/users/company";
import PayScheduleList from "../../components/paySchedule/list";
import SwiftMessage from "../../../constants/message";
import {
  errorDisplay,
  mustBeArray,
  updateAuthorizationClaims,
  findAuthorizationClaims,
  delay,
  getDeviceEnrolmentId,
  getCurrentCardGrid,
  getUserArea,
  getMatchParams,
  requestUrlBuilder,
  getConditionalResponse,
  getPersonalizationData,
  getOptionalParameter,
  conditionalParameter,
  getDefaultQueryPage,
  getScrollingListDefaultPerPage,
  listHasMoreData,
  errorHandler,
  isComponentVisible,
} from "../../../libs/utilities";
import BusinessUnitList from "../../components/businessUnitList";
import ScrollableBusinessUnitTable from "../../components/businessunit/scrollableList";
import ModuleInfoCard from "../../components/common/moduleInfoCard";
import GeneralContainer from "../../../Common/ClaimContainers/GeneralContainer";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import {
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  MULTI_TAB_ACTIVE_KEY_INDEX,
  TABLE_PERSONALIZATIONS,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  SORT_COLUMN_INDEX,
  SORT_ORDER_INDEX,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  LIST_PAGINATION_TYPE_INDEX,
  SORTING_NOT_REQUIRED,
  GRID_PERSONALIZATIONS,
  GRID_ROW_INDEX,
  GRID_COLUMN_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
  GRID_PAGE_NUMBER_INDEX,
  GRID_VIEW_VALUE,
  VISIBLE_CLAIMS_ID,
} from "../../../constants/defaultClaims";
import _ from "lodash";
import ChildTabContainer from "../../../Common/ClaimContainers/ChildTabContainer";
import axios from "axios";
import UserDisplayCard from "../../components/users/userDisplayCard";
import PayScheduleDisplayCard from "../../components/paySchedule/PayScheduleDisplayCard";
import {
  BUSINESSUNIT_GRID_OPTIONS,
  BUSINESSUNIT_FIELD_OPTIONS,
  COMPANY_USER_GRID_OPTIONS,
  COMPANY_USER_FIELD_OPTIONS,
  PAYSCHEDULE_FIELD_OPTIONS,
  PAYSCHEDULE_GRID_OPTIONS,
} from "../../../constants/sortingOptions";
import ScrollableUserList from "../../components/users/ScrollableUserList";
import TabHeader from "../../../Common/tabHeader";
import ScrollablePayScheduleList from ".././../components/paySchedule/ScrollablePayScheduleTable";
import UserAuthenticationUpdate from "../../components/users/authenticationUpdate";
import userRoles from "../../../constants/userRoles";
import APIHandler from "../../../constants/apiUrl";
import AssignPayrollManagerModal from "../../components/businessunit/AssignPayrollManagerModal";
import {
  PRIMARY_PAYROLL_MANAGER_ID,
  SECONDARY_PAYROLL_MANAGER_ID,
} from "../../../constants/user";
import {
  COMPANY_DETAIL_MULTI_TAB_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { withRouter } from "../../../hooks";
import {
  getPayScheduleCreateRoute,
  getPayScheduleDetailRoute,
  getBusinessUnitDetailRoute,
} from "../../../libs";

const { TabPane } = Tabs;

type State = any;
class CompanyDetail extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      visible: false,
      newUserFormVisible: false,
      entity: "company",
      user: {},
      editMode: false,

      // pay schedule
      paySchedulePagination: 1,
      paySchedulePerPage: 10,
      payScheduleTotal: 0,
      payScheduleList: [],
      payScheduleListLoading: false,
      payScheduleAddable: false,
      payScheduleGridable: false,
      payScheduleGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      payScheduleGridView: false,
      payScheduleIsPinned: "false",
      payScheduleSortingType: "notrequired",
      payScheduleHasMoreData: false,
      payScheduleSortInfo: {},
      payScheduleGridSortOption: PAYSCHEDULE_GRID_OPTIONS,
      payScheduleSelectedGridSort: undefined,
      payScheduleFieldOptions: PAYSCHEDULE_FIELD_OPTIONS,

      // User
      userPagination: 1,
      userPerPage: 10,
      userTotal: 0,
      userList: [],
      userListLoading: false,
      userAddable: false,
      userGridable: false,
      userGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      userGridView: false,
      userSortingType: "notrequired",
      userHasMoreData: false,
      userSortInfo: {},
      userGridSortOption: COMPANY_USER_GRID_OPTIONS,
      userSelectedGridSort: undefined,
      userFieldOptions: COMPANY_USER_FIELD_OPTIONS,
      userIsPinned: "false",

      // Business unit
      businessUnitPagination: 1,
      businessUnitPerPage: 10,
      businessUnitTotal: 0,
      businessUnitList: [],
      businessUnitListLoading: false,
      businessUnitAddable: false,
      businessUnitGridable: false,
      businessUnitGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      businessUnitSelectedGridSort: undefined,
      businessUnitGridView: false,
      businessUnitIsPinned: "false",
      businessUnitSortingType: "notrequired",
      businessUnitHasMoreData: false,
      businessUnitSortInfo: {},
      businessUnitGridSortOption: BUSINESSUNIT_GRID_OPTIONS,
      businessUnitFieldOptions: BUSINESSUNIT_FIELD_OPTIONS,

      pageLoading: true,
      activeKey: "1",
      enableMFALoading: false,
      disableMFALoading: false,
      showAssignPayrollManagerModal: false,
      payrollProviderId: "",
      activeBusinessUnit: {},
      assignedPayrollManagers: [],
      saveLoading: false,
    };
  }

  componentDidMount() {
    const { pageClaims, match, type, dashboardClaims, dashboardView } =
      this.props;
    if (dashboardView) {
      switch (type) {
        case "companyUser":
          const companyUserPersonalizations = getPersonalizationData({
            type: "listContainer",
            personalizationData: dashboardClaims,
          });

          this.setState({
            userGridView: getConditionalResponse({
              condition: getOptionalParameter({
                value1: !companyUserPersonalizations,
                value2: !companyUserPersonalizations.gridView,
              }),
              resp1: false,
              resp2: true,
            }),
          });
          this.listUsers();
          break;
        case "companyBusinessUnit":
          const companyBusinessUnitPersonalizations = getPersonalizationData({
            type: "listContainer",
            personalizationData: dashboardClaims,
          });
          this.setState({
            businessUnitGridView: getConditionalResponse({
              condition: getOptionalParameter({
                value1: !companyBusinessUnitPersonalizations,
                value2: !companyBusinessUnitPersonalizations.gridView,
              }),
              resp1: false,
              resp2: true,
            }),
          });
          this.listBusinessUnits();
          break;
        case "companyPaySchedule":
          const companyPaySchedulePersonalizations = getPersonalizationData({
            type: "listContainer",
            personalizationData: dashboardClaims,
          });
          this.setState({
            payScheduleGridView: getConditionalResponse({
              condition: getOptionalParameter({
                value1: !companyPaySchedulePersonalizations,
                value2: !companyPaySchedulePersonalizations.gridView,
              }),
              resp1: false,
              resp2: true,
            }),
          });
          this.listPaySchedules({ options: {} });
          break;
        default:
          break;
      }
    } else {
      const id = getMatchParams({ match, field: "id" });
      this.updateHeader({
        title: "",
        module: "Company",
        enableBack: true,
        action: "",
        returnUrl: `/company`,
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyDetail,
      });
      const companyDetailMultiTabContainer = _.find(
        mustBeArray(
          conditionalParameter({ data: pageClaims, field: "components" })
        ),
        (o) => o.name === COMPANY_DETAIL_MULTI_TAB_CONTAINER
      );

      const activeKey =
        companyDetailMultiTabContainer?.personalizations?.[
          MULTI_TAB_ACTIVE_KEY_INDEX
        ]?.value;

      if (activeKey) {
        this.setState({ activeKey });
      }
      this.props
        .getCompany({ id, cancelToken: this.signal.token })
        .then((res) => {
          this.setState({ pageLoading: false });

          if (res.status) {
            this.updateHeader({
              title: res.data && res.data.name,
              module: "Company",
              enableBack: true,
              action: "",
              returnUrl: `/company`,
            });
          } else {
            errorDisplay(res && res.data && res.data.validationErrors);
          }
        });
      this.loadCompanyBusinessUnits();
      this.loadCompanyPaySchedules();
      this.loadCompanyUsers();
    }
  }

  loadCompanyBusinessUnits = () => {
    const businessUnitListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims && this.props.pageClaims.components,
      name: "companyBusinessUnitListContainer",
    });
    const businessUnitGeneralTable = findAuthorizationClaims({
      claims: businessUnitListContainer?.components,
      name: "companyBusinessUnitGeneralTable",
    });
    const businessUnitGeneralGrid = findAuthorizationClaims({
      claims: businessUnitListContainer?.components,
      name: "companyBusinessUnitGeneralGrid",
    });
    const businessUnitPaginationType =
      businessUnitListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
        ?.value;
    const businessUnitSortingType =
      businessUnitListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]
        ?.value;
    const businessUnitIsPinned =
      businessUnitListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      businessUnitPaginationType,
      businessUnitSortingType,
      businessUnitIsPinned,
      businessUnitGridable: businessUnitListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: businessUnitGeneralTable })) {
      const businessUnitPagination = businessUnitGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            businessUnitGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            businessUnitGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = businessUnitGeneralTable.personalizations
        ? Number(
          businessUnitGeneralTable.personalizations[PAGE_SIZE_INDEX].value ||
          20
        )
        : 20;
      const SortOrder = businessUnitGeneralTable.personalizations
        ? businessUnitGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = businessUnitGeneralTable.personalizations
        ? businessUnitGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          businessUnitPerPage:
            businessUnitPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          businessUnitPagination,
          businessUnitSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(businessUnitListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (businessUnitPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingBusinessUnit({ pageLoad: true });
            } else {
              this.listBusinessUnits({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (
      businessUnitGeneralGrid &&
      [1, 2].includes(Number(businessUnitGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = businessUnitGeneralGrid.personalizations
        ? Number(
          businessUnitGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
            .value
        )
        : 1;
      const row = businessUnitGeneralGrid.personalizations
        ? Number(businessUnitGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = businessUnitGeneralGrid.personalizations
        ? Number(
          businessUnitGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
        )
        : 4;
      const SortOrder = businessUnitGeneralGrid.personalizations
        ? businessUnitGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = businessUnitGeneralGrid.personalizations
        ? businessUnitGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          businessUnitGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          businessUnitSelectedGridSort:
            _.find(
              this.state.businessUnitGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.businessUnitGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            businessUnitListContainer &&
            mustBeArray(businessUnitListContainer.personalizations)[0] &&
            mustBeArray(businessUnitListContainer.personalizations)[0].value ===
            GRID_VIEW_VALUE
          ) {
            this.setState({ businessUnitGridView: true });
            if (businessUnitPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridBusinessUnit({ pageLoad: true });
            } else {
              this.businessUnitFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  loadCompanyUsers = () => {
    // Users
    const userListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims && this.props.pageClaims.components,
      name: "companyUserListContainer",
    });
    const userGeneralTable = findAuthorizationClaims({
      claims: userListContainer && userListContainer.components,
      name: "companyUserGeneralTable",
    });
    const userGeneralGrid = findAuthorizationClaims({
      claims: userListContainer && userListContainer.components,
      name: "companyUserGeneralGrid",
    });
    const userPaginationType =
      userListContainer &&
      userListContainer.personalizations &&
      userListContainer.personalizations[LIST_PAGINATION_TYPE_INDEX] &&
      userListContainer.personalizations[LIST_PAGINATION_TYPE_INDEX].value;
    const userSortingType =
      userListContainer &&
      userListContainer.personalizations &&
      userListContainer.personalizations[LIST_SORTING_REQUIRED_INDEX] &&
      userListContainer.personalizations[LIST_SORTING_REQUIRED_INDEX].value;
    const userIsPinned =
      userListContainer &&
      userListContainer.personalizations &&
      userListContainer.personalizations[LIST_PINNABLE_INDEX] &&
      userListContainer.personalizations[LIST_PINNABLE_INDEX].value;

    this.setState({
      userPaginationType,
      userSortingType,
      userIsPinned,
      userGridable: userListContainer && userListContainer.gridable,
    });

    // If Table
    if (
      userGeneralTable &&
      [1, 2].includes(Number(userGeneralTable.authorizationStatusId))
    ) {
      const userPagination = userGeneralTable.personalizations
        ? !_.isNaN(
          Number(userGeneralTable.personalizations[PAGE_NUMBER_INDEX].value)
        )
          ? Number(userGeneralTable.personalizations[PAGE_NUMBER_INDEX].value)
          : 1
        : 1;
      const pageSize = userGeneralTable.personalizations
        ? Number(userGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20)
        : 20;
      const SortOrder = userGeneralTable.personalizations
        ? userGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = userGeneralTable.personalizations
        ? userGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          userPerPage:
            userPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          userPagination,
          userSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            userListContainer &&
            mustBeArray(userListContainer.personalizations)[
            LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ] &&
            mustBeArray(userListContainer.personalizations)[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ].value === TABLE_VIEW_VALUE
          ) {
            if (userPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingUser({ pageLoad: true });
            } else {
              this.listUsers({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (
      userGeneralGrid &&
      [1, 2].includes(Number(userGeneralGrid.authorizationStatusId))
    ) {
      const pageNumber = userGeneralGrid.personalizations
        ? Number(userGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX].value)
        : 1;
      const row = userGeneralGrid.personalizations
        ? Number(userGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = userGeneralGrid.personalizations
        ? Number(userGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder = userGeneralGrid.personalizations
        ? userGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = userGeneralGrid.personalizations
        ? userGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          userGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          userSelectedGridSort:
            _.find(
              this.state.userGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(this.state.userGridSortOption, (o) => o.label === SortColumn)
              .id,
        },
        () => {
          if (
            userListContainer &&
            mustBeArray(userListContainer.personalizations)[0] &&
            mustBeArray(userListContainer.personalizations)[0].value ===
            GRID_VIEW_VALUE
          ) {
            this.setState({ userGridView: true });
            if (userPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridUser({ pageLoad: true });
            } else {
              this.userFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  loadCompanyPaySchedules = () => {
    // Payschedules
    const payScheduleListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims && this.props.pageClaims.components,
      name: "companyPayScheduleListContainer",
    });
    const payScheduleGeneralTable = findAuthorizationClaims({
      claims: payScheduleListContainer?.components,
      name: "companyPayScheduleGeneralTable",
    });
    const payScheduleGeneralGrid = findAuthorizationClaims({
      claims: payScheduleListContainer?.components,
      name: "companyPayScheduleGeneralGrid",
    });
    const paySchedulePaginationType =
      payScheduleListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
        ?.value;
    const payScheduleSortingType =
      payScheduleListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]
        ?.value;
    const payScheduleIsPinned =
      payScheduleListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      paySchedulePaginationType,
      payScheduleSortingType,
      payScheduleIsPinned,
      payScheduleGridable: payScheduleListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: payScheduleGeneralTable })) {
      const paySchedulePagination = payScheduleGeneralTable.personalizations
        ? !_.isNaN(
          Number(
            payScheduleGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
        )
          ? Number(
            payScheduleGeneralTable.personalizations[PAGE_NUMBER_INDEX].value
          )
          : 1
        : 1;
      const pageSize = payScheduleGeneralTable.personalizations
        ? Number(
          payScheduleGeneralTable.personalizations[PAGE_SIZE_INDEX].value ||
          20
        )
        : 20;
      const SortOrder = payScheduleGeneralTable.personalizations
        ? payScheduleGeneralTable.personalizations[SORT_ORDER_INDEX].value
        : null;
      const SortColumn = payScheduleGeneralTable.personalizations
        ? payScheduleGeneralTable.personalizations[SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          paySchedulePerPage:
            paySchedulePaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
          paySchedulePagination,
          payscheduleSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(payScheduleListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (paySchedulePaginationType === PAGINATION_SCROLLING) {
              this.listScrollingPaySchedule({ pageLoad: true });
            } else {
              this.listPaySchedules({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: payScheduleGeneralGrid })) {
      const pageNumber = payScheduleGeneralGrid.personalizations
        ? Number(
          payScheduleGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
            .value
        )
        : 1;
      const row = payScheduleGeneralGrid.personalizations
        ? Number(payScheduleGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = payScheduleGeneralGrid.personalizations
        ? Number(
          payScheduleGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
        )
        : 4;
      const SortOrder = payScheduleGeneralGrid.personalizations
        ? payScheduleGeneralGrid.personalizations[GRID_SORT_ORDER_INDEX].value
        : null;
      const SortColumn = payScheduleGeneralGrid.personalizations
        ? payScheduleGeneralGrid.personalizations[GRID_SORT_COLUMN_INDEX].value
        : null;

      this.setState(
        {
          payScheduleGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          payScheduleSelectedGridSort:
            _.find(
              this.state.payScheduleGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.payScheduleGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            mustBeArray(payScheduleListContainer?.personalizations)?.[0]
              ?.value === GRID_VIEW_VALUE
          ) {
            this.setState({ payScheduleGridView: true });
            if (paySchedulePaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridPaySchedule({ pageLoad: true });
            } else {
              this.payScheduleFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  updateHeader = (options) => {
    this.props.updateHeader({
      header: { ...options },
    });
  };

  showCompanyModal = () => {
    const { match, company } = this.props;
    const { params } = match;
    const tempHeader = {
      module: "Back to Company Detail",
      enableBack: true,
      title: `${company?.name}`,
      entity: "company",
      returnUrl: `/company/${params.id}`,
    };

    this.props.updateHeader({ header: tempHeader });
    this.props.router.navigate(`/company/${match?.params?.id}/edit`);
  };

  addPaySchedule = () => {
    const { params } = this.props.match;
    const tempHeader = {
      module: "Back to Company Detail",
      enableBack: true,
      title: `New Pay Schedule`,
      entity: "pay schedule",
      returnUrl: `/company/${params.id}`,
    };

    this.props.updateHeader({ header: tempHeader });
    this.props.router.navigate(
      getPayScheduleCreateRoute({ companyId: params?.id })
    );
  };

  addBusinessUnit = () => {
    const { params } = this.props.match;
    const tempHeader = {
      module: "Back to Company Detail",
      enableBack: true,
      title: "New Business Unit",
      gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BusinessUnitCreate,
      entity: "business unit",
      returnUrl: `/company/${params.id}`,
    };

    this.props.updateHeader({ header: tempHeader });
    this.props.router.navigate(`/company/${params.id}/businessunit/create`);
  };

  onCancel = () => {
    this.setState({ visible: false });
  };
  onSubmit = (postData) => {
    return this.props.updateCompany(postData).then((response) => {
      if (response.status) {
        message.success(SwiftMessage["company.update"]);

        this.props
          .getCompany({
            id: this.props.match.params.id,

            cancelToken: this.signal.token,
          })

          .then((res) => {
            if (res.status) {
              this.updateHeader({ title: res.data.name });
            }
          });

        this.props.listCompanies({
          options: {},

          cancelToken: this.signal.token,
        });

        this.setState({ visible: false });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
      return response;
    });
  };

  closeUserForm = () => {
    this.setState({ newUserFormVisible: false, user: {}, editMode: false });
  };

  handleEdit = (record) => {
    const { params } = this.props.match;

    this.props.updateHeader({
      header: {
        module: "Back to Company Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserEdit,
        entity: "user",
        returnUrl: `/company/${params && params.id}`,
      },
    });
    this.props.router.navigate(`/user/company/${record && record.id}/edit`);
  };
  handleDelete = (record) => {
    this.props
      .handleCompanyUserActions({
        id: record.id,
        action: "delete",

        cancelToken: this.signal.token,
        options: {},

        userMode: this.props.userMode,
      })

      .then((resp) => {
        if (resp.status) {
          message.success("User disabled successfully");
        } else {
          message.error("Unable to disable the user");
        }
      });
  };
  registerCompanyUsers = (options) => {
    if (!this.state.editMode) {
      return this.props

        .registerCompanyUsers({ ...options, userMode: this.props.userMode })

        .then((resp) => {
          if (resp.status) {
            this.setState({
              newUserFormVisible: false,
              user: {},
            });
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }

          return resp;
        });
    }
    return this.props
      .handleCompanyUserActions({
        ...options,
        id: this.state.user.id,
        action: "update",
        userMode: this.props.userMode,
      })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            user: {},
            editMode: false,
            newUserFormVisible: false,
          });
        } else {
          errorDisplay(resp && resp.data && resp.data.validationErrors);
        }
        return resp;
      });
  };

  handleCreateBusinessUnit = () => {
    const { id } = this.props.match && this.props.match.params;
    this.props.router.navigate(`/company/${id}/businessunit/create`);
  };

  handleTabChange = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.updatePersonalization("companyDetailMultiTabContainer");
    });
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;

    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member && member.details && member.details.authorizationDOM
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      let updatedDOM = [];
      const companyPayScheduleListContainer = findAuthorizationClaims({
        claims: pageClaims && pageClaims.components,
        name: "companyPayScheduleListContainer",
      });
      const companyBusinessUnitListContainer = findAuthorizationClaims({
        claims: pageClaims && pageClaims.components,
        name: "companyBusinessUnitListContainer",
      });
      const companyUserListContainer = findAuthorizationClaims({
        claims: pageClaims && pageClaims.components,
        name: "companyUserListContainer",
      });
      switch (field) {
        case "companyDetailMultiTabContainer":
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims && pageClaims.components,
            name: field,
          });
          break;
        case "companyPayScheduleGeneralTable":
          personalizations = [...TABLE_PERSONALIZATIONS];

          personalizations[
            PAGE_NUMBER_INDEX
          ].value = `${this.state.paySchedulePagination}`;

          personalizations[
            PAGE_SIZE_INDEX
          ].value = `${this.state.paySchedulePerPage}`;

          personalizations[SORT_COLUMN_INDEX].value =
            this.state.payScheduleSortInfo &&
            this.state.payScheduleSortInfo.field;

          personalizations[SORT_ORDER_INDEX].value =
            this.state.payScheduleSortInfo &&
            this.state.payScheduleSortInfo.order;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          // fieldId = component && component.id;
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims && pageClaims.components,
            name: field,
          });
          break;
        case "companyPayScheduleGeneralGrid":
          if (companyPayScheduleListContainer.personalizable === "true") {
            const { payScheduleGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${payScheduleGrid && payScheduleGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${payScheduleGrid && payScheduleGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${payScheduleGrid && payScheduleGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              payScheduleGrid &&
              payScheduleGrid.sortInfo &&
              payScheduleGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              payScheduleGrid &&
              payScheduleGrid.sortInfo &&
              payScheduleGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyPayScheduleListContainer":
          if (companyPayScheduleListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.payScheduleGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.paySchedulePaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.payScheduleSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.payScheduleIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyBusinessUnitGeneralTable":
          if (companyBusinessUnitListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.businessUnitPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.businessUnitPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.businessUnitSortInfo &&
              this.state.businessUnitSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.businessUnitSortInfo &&
              this.state.businessUnitSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            // fieldId = component && component.id;
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyBusinessUnitGeneralGrid":
          if (companyBusinessUnitListContainer.personalizable === "true") {
            const { businessUnitGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${businessUnitGrid && businessUnitGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${businessUnitGrid && businessUnitGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${businessUnitGrid && businessUnitGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              businessUnitGrid &&
              businessUnitGrid.sortInfo &&
              businessUnitGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              businessUnitGrid &&
              businessUnitGrid.sortInfo &&
              businessUnitGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyBusinessUnitListContainer":
          if (companyBusinessUnitListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.businessUnitGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.businessUnitPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.businessUnitSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.businessUnitIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyUserGeneralTable":
          if (companyUserListContainer.personalizable === "true") {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.userPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.userPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.userSortInfo && this.state.userSortInfo.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.userSortInfo && this.state.userSortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            // fieldId = component && component.id;
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyUserGeneralGrid":
          if (companyUserListContainer.personalizable === "true") {
            const { userGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[GRID_PAGE_NUMBER_INDEX].value = `${userGrid && userGrid.page
              }`;
            personalizations[GRID_ROW_INDEX].value = `${userGrid && userGrid.row
              }`;
            personalizations[GRID_COLUMN_INDEX].value = `${userGrid && userGrid.col
              }`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              userGrid && userGrid.sortInfo && userGrid.sortInfo.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              userGrid && userGrid.sortInfo && userGrid.sortInfo.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        case "companyUserListContainer":
          if (companyUserListContainer.personalizable === "true") {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.userGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.userPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.userSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.userIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims && pageClaims.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      let updateChange = update;
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent && updatedComponent.id,
          name: updatedComponent && updatedComponent.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };
      } else {
        updateChange = false;
      }
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update: updateChange,
        });
      });
    } catch (err) {
      return err;
    }
  };

  // User
  sendMail = ({ user }) => {
    this.props
      .handleCompanyUserActions({
        id: user.id,
        data: user,
        action: "sendreminder",

        userMode: this.props.userMode,
      })

      .then((resp) => {
        if (resp.status) {
          message.success(`Reminder sent successfully`);

          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        } else {
          errorHandler({ response: resp });
        }
      });
  };
  listScrollingUser = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.userHasMoreData) return false;

    const { id } = this.props.match && this.props.match.params;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.userPagination =
            prevState.userTotal === mustBeArray(prevState.userList).length
              ? prevState.userPagination
              : Number(prevState.userPagination) + 1;
        }
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = { ...this.getUserTableSortInfo() };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.userPagination) *
              Number(this.state.userPerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.userPagination,
            per_page: this.state.userPerPage,
          };
        }

        this.props
          .listCompanyUsers({
            options: tempOptions,
            companyId: id,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.userPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ userPagination: 1 }, () => {
                  this.listScrollingUser({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    userList: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.userList, ...mustBeArray(resp.data)],

                    userTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      userHasMoreData:
                        this.state.userList.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("companyUserGeneralTable");
          }
        });
      }
    );
  };
  listUsers = (userOptions) => {
    this.setState(
      (prevState) => {
        if (userOptions && userOptions.options && userOptions.options.page) {
          const { options } = userOptions;
          prevState.userPagination = options && options.page;
          prevState.userSortInfo = {
            field: options && options.field,
            order: options && options.order,
          };
        }
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.userPagination))
            ? Number(this.state.userPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.userPerPage))
            ? Number(this.state.userPerPage)
            : 10,

          ...this.getUserTableSortInfo(),
        };

        this.props
          .listCompanyUsers({
            options: newOptions,

            companyId: getMatchParams({ match: this.props.match, field: "id" }),

            cancelToken: this.signal.token,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              if (
                _.isEmpty(mustBeArray(resp.data)) &&
                Number(this.state.userPagination) !== 1 &&
                !this.props.dashboardView
              ) {
                this.setState({ userPagination: 1 }, () => {
                  this.listUsers({ options: {} });
                });
              } else {
                this.setState({
                  userList: mustBeArray(resp.data),
                  userTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyUserGeneralTable");
        });
      }
    );
  };
  getUserTableSortInfo = () => {
    if (
      this.state.userSortInfo &&
      this.state.userSortInfo.field &&
      this.state.userSortInfo.order &&
      this.state.userSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userSortInfo.field]: this.state.userSortInfo.order,
        },
      };
    } else return {};
  };
  listScrollingGridUser = ({ options = {}, pageLoad = false }) => {
    if (!pageLoad && !this.state.userHasMoreData) return false;

    this.setState(
      {
        userListLoading: true,
        userGrid: {
          ...this.state.userGrid,
          page: !pageLoad
            ? Number(this.state.userGrid && this.state.userGrid.page) + 1
            : Number(this.state.userGrid && this.state.userGrid.page),
        },
      },
      () => {
        const { userGrid } = this.state;
        const { page } = userGrid;

        const per_page = this.getUserGridPageSize();

        let sortingParams = this.getUserGridSortInfo();

        this.props
          .listCompanyUsers({
            options: {
              page: getDefaultQueryPage({ page, pageLoad }),
              per_page: getScrollingListDefaultPerPage({
                page,
                pageLoad,
                per_page,
              }),
              ...sortingParams,
            },

            companyId: getMatchParams({ match: this.props.match, field: "id" }),
            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ userListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ userGrid: { ...userGrid, page: 1 } }, () => {
                  this.listScrollingGridUser({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.userList = getConditionalResponse({
                      condition: pageLoad,
                      resp1: data,
                      resp2: prevState.userList.concat(data),
                    });
                    prevState.userHasMoreData = getConditionalResponse({
                      condition: response.total > prevState.userList.length,
                      resp1: true,
                      resp2: false,
                    });

                    prevState.userTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization("companyUserGeneralGrid");
                    });
                  }
                );
              }
            }
          });
      }
    );
  };
  userFetchMoreData = ({ isPaginated = false }) => {
    this.setState({ userListLoading: true });

    const { userGrid } = this.state;
    const { page } = userGrid;

    const per_page = this.getUserGridPageSize();

    let sortingParams = this.getUserGridSortInfo();

    this.props

      .listCompanyUsers({
        options: { page, per_page, ...sortingParams },
        companyId: getMatchParams({ match: this.props.match, field: "id" }),

        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ userGrid: { ...userGrid, page: 1 } }, () => {
              this.userFetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.userList = data;
                } else {
                  prevState.userList = prevState.userList.concat(data);
                  prevState.userHasMoreData = listHasMoreData({
                    data,
                    currentList: prevState.userList,
                    currentTotal: prevState.userTotal,
                  });
                  prevState.userGrid = getConditionalResponse({
                    condition: listHasMoreData({
                      data,
                      currentList: prevState.userList,
                      currentTotal: prevState.userTotal,
                    }),
                    resp1: {
                      ...prevState.userGrid,
                      page: prevState.userGrid.page + 1,
                    },
                    resp2: prevState.userGrid,
                  });
                }

                prevState.userTotal = response.total;
                prevState.userListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization("companyUserGeneralGrid");
                });
              }
            );
          }
        }
      });
  };

  getUsersQueryOptions = () => {
    if (this.state.userGridView) {
      return {
        page: this.state.userGrid && this.state.userGrid.page,
        per_page: this.getUserGridPageSize(),

        ...this.getUserGridSortInfo(),
        q: {
          companyId: getMatchParams({ match: this.props.match, field: "id" }),
        },
      };
    }
    return {
      page: !_.isNaN(Number(this.state.userPagination))
        ? Number(this.state.userPagination)
        : 1,

      per_page: !_.isNaN(Number(this.state.userPerPage))
        ? Number(this.state.userPerPage)
        : 10,

      ...this.getUserTableSortInfo(),
      q: {
        companyId: getMatchParams({ match: this.props.match, field: "id" }),
      },
    };
  };

  listUserGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.userGrid.page = page;
        prevState.userListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.userGrid && this.state.userGrid.page,
          per_page: this.getUserGridPageSize(),

          ...this.getUserGridSortInfo(),
        };

        this.props
          .listCompanyUsers({
            options: tempOptions,
            companyId: getMatchParams({ match: this.props.match, field: "id" }),

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ userListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.userGrid && this.state.userGrid.page) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState(
                  { userGrid: { ...this.state.userGrid, page: 1 } },
                  () => {
                    this.listUserGrid(1);
                  }
                );
              } else {
                this.setState({
                  userList: mustBeArray(resp.data),
                  userTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyUserGeneralGrid");
        });
      }
    );
  };

  getUserGridSortInfo = () => {
    if (
      this.state.userGrid.sortInfo &&
      this.state.userGrid.sortInfo.field &&
      this.state.userGrid.sortInfo.order &&
      this.state.userSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.userGrid.sortInfo.field]:
            this.state.userGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.userGrid.sortInfo &&
      this.state.userGrid.sortInfo.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.userGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  handleUserGridChange = ({ gridView, isPaginated }) => {
    if (this.state.userGridView === gridView) {
      return false;
    }

    this.setState(
      { userGridView: gridView, userList: [], userListLoading: true },
      () => {
        this.updatePersonalization("companyUserListContainer");
        if (!gridView) {
          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingUser({ pageLoad: true, options: {} });
          } else {
            this.listUsers({ options: {} });
          }
        } else {
          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };
  userUpdatePageSize = (value) => {
    if (this.state.userGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.userGrid.row = Number(row);
          prevState.userGrid.col = Number(col);
          prevState.userGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ userPerPage: Number(value), current: 1 }, () => {
        if (this.state.userPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingUser({ pageLoad: true, options: {} });
        } else {
          this.listUsers({ options: {} });
        }
      });
    }
  };
  userUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.userSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.userGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.userGrid = {
            ...prevState.userGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.userPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridUser({ pageLoad: true, options: {} });
        } else {
          this.userFetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  changeStatus = (user) => {
    user.enabled = !user.enabled;

    this.props
      .handleCompanyUserActions({
        id: user && user.id,
        data: user,
        action: "status",

        userMode: this.props.userMode,
      })

      .then((resp) => {
        if (resp.status) {
          message.success(
            `User ${user.enabled ? "enabled" : "disabled"} successfully`
          );

          if (this.state.userPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridUser({ pageLoad: true, options: {} });
          } else {
            this.userFetchMoreData({ isPaginated: true });
          }
        } else {
          errorHandler({
            hasValidationErrors: false,
            response: resp,
            oldModel: true,
          });
        }
      });
  };

  updateAuthentication = (user) => {
    this.props.handleCompanyUserActions({
      id: user.id,
      data: {
        mfaEnable: user.mfaEnable,
        requestType: "mfa",
        deviceEnrolmentEnable: user.deviceEnrolmentEnable,
      },
      action: "updatemultifactorauthentication",

      userMode: this.props.userMode,
    });
    message.info(
      `Your request to ${!user.mfaEnable ? "enable" : "disable"
      } multi-factor authentication for the user has been submitted. Please refresh the screen after two minutes.`
    );
  };

  handleUserAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/user/company/${data && data.id}`);
        break;
      case "edit":
        this.handleEdit(data);
        break;
      case "sendReminder":
        this.sendMail({ user: data });
        break;
      case "enableDisable":
        this.changeStatus(data);
        break;
      case "enableDisableMfa":
        this.updateAuthentication(data);
        break;
      case "disableDeviceEnrolment":
      case "optionalDeviceEnrolment":
      case "mandatoryDeviceEnrolment":
        message.info(`Your request to ${action === "disableDeviceEnrolment"
            ? "disable"
            : action === "mandatoryDeviceEnrolment"
              ? "enable mandatory"
              : "enable optional"
          } device enrolment for the user has been submitted.
        Please refresh the screen after two minutes.`);

        this.props.handleCompanyUserActions({
          action,
          data: {
            mfaEnable: data.mfaEnable,
            requestType: "deviceEnrolment",
            deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
          },
          id: data.id,
        });
        break;
      default:
        break;
    }
  };
  userUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.userGrid.sortInfo[field] === value) {
          prevState.userGrid.sortInfo[field] = "";
        } else {
          prevState.userGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userFetchMoreData({ isPaginated: true });
      }
    );
  };
  getUserGridPageSize = () => {
    const { row, col } = this.state.userGrid;

    return this.state.userPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };
  updateUserGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.userGrid.sortInfo[field] === value) {
          prevState.userGrid.sortInfo[field] = "";
        } else {
          prevState.userGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.userFetchMoreData({ isPaginated: true });
      }
    );
  };
  handleUserCreate = () => {
    const { params } = this.props.match;

    this.props.updateHeader({
      header: {
        module: "Back to Company Detail",
        enableBack: true,
        title: "New User",
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyUserCreate,
        entity: "user",
        returnUrl: `/company/${params && params.id}`,
      },
    });
    this.props.router.navigate(`/company/${params && params.id}/user/create`);
  };

  // Business Unit
  listScrollingBusinessUnit = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.businessUnitHasMoreData) return false;

    const { id } = this.props.match && this.props.match.params;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.businessUnitPagination =
            prevState.businessUnitTotal ===
              mustBeArray(prevState.businessUnitList).length
              ? prevState.businessUnitPagination
              : Number(prevState.businessUnitPagination) + 1;
        }
        prevState.businessUnitListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = { ...this.getBusinessUnitTableSortInfo() };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.businessUnitPagination) *
              Number(this.state.businessUnitPerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.businessUnitPagination,
            per_page: this.state.businessUnitPerPage,
          };
        }

        this.props
          .listCompanyBusinessUnits({
            options: tempOptions,
            id,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ businessUnitListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.businessUnitPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ businessUnitPagination: 1 }, () => {
                  this.listScrollingBusinessUnit({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    businessUnitList: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                        ...this.state.businessUnitList,
                        ...mustBeArray(resp.data),
                      ],

                    businessUnitTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      businessUnitHasMoreData:
                        this.state.businessUnitList.length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("companyBusinessUnitGeneralTable");
          }
        });
      }
    );
  };
  listBusinessUnits = (businessUnitOptions) => {
    this.setState(
      (prevState) => {
        if (
          businessUnitOptions &&
          businessUnitOptions.options &&
          businessUnitOptions.options.page
        ) {
          const { options } = businessUnitOptions;
          prevState.businessUnitPagination = options && options.page;
          prevState.businessUnitSortInfo = {
            field: options && options.field,
            order: options && options.order,
          };
        }
        prevState.businessUnitListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: !_.isNaN(Number(this.state.businessUnitPagination))
            ? Number(this.state.businessUnitPagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.businessUnitPerPage))
            ? Number(this.state.businessUnitPerPage)
            : 10,

          ...this.getBusinessUnitTableSortInfo(),
        };

        this.props
          .listCompanyBusinessUnits({
            options: newOptions,

            id: getMatchParams({ match: this.props.match, field: "id" }),

            targetEndpoint: this.props.targetEndpoint,

            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ businessUnitListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.businessUnitPagination) !== 1 &&
                !this.props.dashboardView &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ businessUnitPagination: 1 }, () => {
                  this.listBusinessUnits({ options: {} });
                });
              } else {
                this.setState({
                  businessUnitList: mustBeArray(resp.data),
                  businessUnitTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyBusinessUnitGeneralTable");
        });
      }
    );
  };
  getBusinessUnitTableSortInfo = () => {
    if (
      this.state.businessUnitSortInfo &&
      this.state.businessUnitSortInfo.field &&
      this.state.businessUnitSortInfo.order &&
      this.state.businessUnitSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.businessUnitSortInfo.field]:
            this.state.businessUnitSortInfo.order,
        },
      };
    } else return {};
  };
  listScrollingGridBusinessUnit = ({ options = {}, pageLoad = false }) => {
    const id = this.props.match.params && this.props.match.params.id;

    if (!pageLoad && !this.state.businessUnitHasMoreData) return false;

    this.setState(
      {
        businessUnitListLoading: true,
        businessUnitGrid: {
          ...this.state.businessUnitGrid,
          page: !pageLoad
            ? Number(
              this.state.businessUnitGrid && this.state.businessUnitGrid.page
            ) + 1
            : Number(
              this.state.businessUnitGrid && this.state.businessUnitGrid.page
            ),
        },
      },
      () => {
        const { businessUnitGrid } = this.state;
        const { page } = businessUnitGrid;

        const per_page = this.getBusinessUnitGridPageSize();

        let sortingParams = this.getBusinessUnitGridSortInfo();

        this.props
          .listCompanyBusinessUnits({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              ...sortingParams,
            },
            id,

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ businessUnitListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { businessUnitGrid: { ...businessUnitGrid, page: 1 } },
                  () => {
                    this.listScrollingGridBusinessUnit({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.businessUnitList = pageLoad
                      ? data
                      : prevState.businessUnitList.concat(data);
                    prevState.businessUnitHasMoreData = listHasMoreData({
                      data,
                      currentList: prevState.businessUnitList,
                      currentTotal: prevState.businessUnitTotal,
                    });
                    prevState.businessUnitGrid = getConditionalResponse({
                      condition: listHasMoreData({
                        data,
                        currentList: prevState.businessUnitList,
                        currentTotal: prevState.businessUnitTotal,
                      }),
                      resp1: {
                        ...prevState.businessUnitGrid,
                        page: prevState.businessUnitGrid.page + 1,
                      },
                      resp2: prevState.businessUnitGrid,
                    });

                    prevState.businessUnitTotal = response.total;
                    prevState.businessUnitListLoading = false;

                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        "companyBusinessUnitGeneralGrid"
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };
  businessUnitFetchMoreData = ({ isPaginated = false }) => {
    this.setState({ businessUnitListLoading: true });

    const { businessUnitGrid } = this.state;
    const { page } = businessUnitGrid;

    const per_page = this.getBusinessUnitGridPageSize();

    let sortingParams = this.getBusinessUnitGridSortInfo();

    this.props

      .listCompanyBusinessUnits({
        options: { page, per_page, ...sortingParams },
        targetEndpoint: this.props.targetEndpoint,

        id: getMatchParams({ match: this.props.match, field: "id" }),
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { businessUnitGrid: { ...businessUnitGrid, page: 1 } },
              () => {
                this.businessUnitFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.businessUnitList = data;
                } else {
                  prevState.businessUnitList =
                    prevState.businessUnitList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.businessUnitList.length <
                    this.state.businessUnitTotal
                  ) {
                    prevState.businessUnitHasMoreData = true;
                    prevState.businessUnitGrid = {
                      ...prevState.businessUnitGrid,
                      page: prevState.businessUnitGrid.page + 1,
                    };
                  } else {
                    prevState.businessUnitHasMoreData = false;
                  }
                }

                prevState.businessUnitTotal = response.total;
                prevState.businessUnitListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization("companyBusinessUnitGeneralGrid");
                });
              }
            );
          }
        }
      });
  };

  getBusinessUnitQueryOptions = () => {
    const {
      businessUnitGrid,
      businessUnitGridView,
      businessUnitPagination,
      businessUnitPerPage,
    } = this.state;
    if (businessUnitGridView) {
      return {
        page: businessUnitGrid && businessUnitGrid.page,
        per_page: this.getBusinessUnitGridPageSize(),

        ...this.getBusinessUnitGridSortInfo(),
      };
    }
    return {
      ...this.getBusinessUnitTableSortInfo(),
      page: businessUnitPagination,
      per_page: businessUnitPerPage,
    };
  };

  listBusinessUnitGrid = (page) => {
    const id = this.props.match.params && this.props.match.params.id;

    this.setState(
      (prevState) => {
        prevState.businessUnitGrid.page = page;
        prevState.businessUnitListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.businessUnitGrid && this.state.businessUnitGrid.page,
          per_page: this.getBusinessUnitGridPageSize(),

          ...this.getBusinessUnitGridSortInfo(),
        };

        this.props
          .listCompanyBusinessUnits({
            options: tempOptions,
            id,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ businessUnitListLoading: false });

            if (resp.status) {
              this.setState({
                businessUnitList: mustBeArray(resp.data),
                businessUnitTotal: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyBusinessUnitGeneralGrid");
        });
      }
    );
  };

  getBusinessUnitGridSortInfo = () => {
    if (
      this.state.businessUnitGrid.sortInfo &&
      this.state.businessUnitGrid.sortInfo.field &&
      this.state.businessUnitGrid.sortInfo.order &&
      this.state.businessUnitSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.businessUnitGrid.sortInfo.field]:
            this.state.businessUnitGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.businessUnitGrid.sortInfo &&
      this.state.businessUnitGrid.sortInfo.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.businessUnitGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  handleBusinessUnitGridChange = ({ gridView, isPaginated }) => {
    if (this.state.businessUnitGridView === gridView) {
      return false;
    }

    this.setState(
      {
        businessUnitGridView: gridView,
        businessUnitList: [],
        businessUnitListLoading: true,
      },
      () => {
        this.updatePersonalization("companyBusinessUnitListContainer");
        if (!gridView) {
          if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingBusinessUnit({ pageLoad: true, options: {} });
          } else {
            this.listBusinessUnits({ options: {} });
          }
        } else {
          if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridBusinessUnit({ pageLoad: true, options: {} });
          } else {
            this.businessUnitFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };
  businessUnitUpdatePageSize = (value) => {
    if (this.state.businessUnitGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.businessUnitGrid.row = Number(row);
          prevState.businessUnitGrid.col = Number(col);
          prevState.businessUnitGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridBusinessUnit({ pageLoad: true, options: {} });
          } else {
            this.businessUnitFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ businessUnitPerPage: Number(value), current: 1 }, () => {
        if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingBusinessUnit({ pageLoad: true, options: {} });
        } else {
          this.listBusinessUnits({ options: {} });
        }
      });
    }
  };
  businessUnitUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.businessUnitSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.businessUnitGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.businessUnitGrid = {
            ...prevState.businessUnitGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.businessUnitPaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridbusinessUnit({ pageLoad: true, options: {} });
        } else {
          this.businessUnitFetchMoreData({ isPaginated: true });
        }
      }
    );
  };
  handleBusinessUnitAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { id, companyId } = data;
    switch (action) {
      case "view":
        this.props.router.navigate(
          getBusinessUnitDetailRoute({ id, companyId })
        );
        break;
      case "edit":
        break;
      default:
        break;
    }
  };
  businessUnitUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.businessUnitGrid.sortInfo[field] === value) {
          prevState.businessUnitGrid.sortInfo[field] = "";
        } else {
          prevState.businessUnitGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.businessUnitFetchMoreData({ isPaginated: true });
      }
    );
  };

  getBusinessUnitGridPageSize = () => {
    const { row, col } = this.state.businessUnitGrid;

    return this.state.businessUnitPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updateBusinessUnitGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.businessUnitGrid.sortInfo[field] === value) {
          prevState.businessUnitGrid.sortInfo[field] = "";
        } else {
          prevState.businessUnitGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.businessUnitFetchMoreData({ isPaginated: true });
      }
    );
  };

  // Pay Schedule

  getPayScheduleTableSortInfo = () => {
    if (
      this.state.payScheduleSortInfo &&
      this.state.payScheduleSortInfo.field &&
      this.state.payScheduleSortInfo.order &&
      this.state.payScheduleSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.payScheduleSortInfo.field]:
            this.state.payScheduleSortInfo.order,
        },
      };
    } else return {};
  };
  listScrollingGridPaySchedule = ({ options = {}, pageLoad = false }) => {
    const { businessunitid } = this.props.match && this.props.match.params;

    if (!pageLoad && !this.state.payScheduleHasMoreData) return false;

    this.setState(
      {
        payScheduleListLoading: true,
        payScheduleGrid: {
          ...this.state.payScheduleGrid,
          page: !pageLoad
            ? Number(
              this.state.payScheduleGrid && this.state.payScheduleGrid.page
            ) + 1
            : Number(
              this.state.payScheduleGrid && this.state.payScheduleGrid.page
            ),
        },
      },
      () => {
        const { payScheduleGrid } = this.state;
        const { page } = payScheduleGrid;

        const per_page = this.getPayScheduleGridPageSize();

        let sortingParams = this.getPayScheduleGridSortInfo();

        this.props
          .getPayschedule({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { businessUnitID: businessunitid },
              ...sortingParams,
            },

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ payScheduleListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { payScheduleGrid: { ...payScheduleGrid, page: 1 } },
                  () => {
                    this.listScrollingGridPaySchedule({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.payScheduleList = pageLoad
                      ? data
                      : prevState.payScheduleList.concat(data);

                    prevState.payScheduleHasMoreData =
                      response.total > prevState.payScheduleList.length
                        ? true
                        : false;

                    prevState.payScheduleTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        "companyPayScheduleGeneralGrid"
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };
  listScrollingPaySchedule = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.payScheduleHasMoreData) return false;

    const { id } = this.props.match && this.props.match.params;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.paySchedulePagination =
            prevState.payScheduleTotal ===
              mustBeArray(prevState.payScheduleList).length
              ? prevState.paySchedulePagination
              : Number(prevState.paySchedulePagination) + 1;
        }
        prevState.payScheduleListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          ...this.getPayScheduleTableSortInfo(),
          q: { companyId: id },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.paySchedulePagination) *
              Number(this.state.paySchedulePerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.paySchedulePagination,
            per_page: this.state.paySchedulePerPage,
          };
        }

        this.props
          .listPayschedules({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ payScheduleListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.paySchedulePagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ paySchedulePagination: 1 }, () => {
                  this.listScrollingPaySchedule({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    payScheduleList: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                        ...this.state.payScheduleList,
                        ...mustBeArray(resp.data),
                      ],

                    payScheduleTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      payScheduleHasMoreData:
                        this.state.payScheduleList.length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("companyPayScheduleGeneralTable");
          }
        });
      }
    );
  };

  getPayScheduleQueryOptions = () => {
    const { payScheduleGrid, payScheduleGridView } = this.state;
    if (payScheduleGridView) {
      return {
        page: payScheduleGrid.page,
        per_page: this.getPayScheduleGridPageSize(),
        ...this.getPayScheduleGridSortInfo(),

        q: {
          companyId: getMatchParams({ match: this.props.match, field: "id" }),
        },
      };
    }
    return {
      page: !_.isNaN(Number(this.state.paySchedulePagination))
        ? Number(this.state.paySchedulePagination)
        : 1,

      per_page: !_.isNaN(Number(this.state.paySchedulePerPage))
        ? Number(this.state.paySchedulePerPage)
        : 10,

      ...this.getPayScheduleTableSortInfo(),
      q: {
        companyId: getMatchParams({ match: this.props.match, field: "id" }),
      },
    };
  };

  listPaySchedules = ({ options }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.paySchedulePagination = !_.isNaN(Number(options.page))
            ? Number(options.page)
            : 1;
          prevState.payScheduleSortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.payScheduleListLoading = true;
        }
        return prevState;
      },
      () => {
        let tempOptions = {
          page: !_.isNaN(Number(this.state.paySchedulePagination))
            ? Number(this.state.paySchedulePagination)
            : 1,

          per_page: !_.isNaN(Number(this.state.paySchedulePerPage))
            ? Number(this.state.paySchedulePerPage)
            : 10,

          ...this.getPayScheduleTableSortInfo(),
          q: {
            companyId: getMatchParams({ match: this.props.match, field: "id" }),
          },
        };

        this.props
          .listPayschedules({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ payScheduleListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.paySchedulePagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ paySchedulePagination: 1 }, () => {
                  this.listPaySchedules({ options: {} });
                });
              } else {
                this.setState({
                  payScheduleList: mustBeArray(resp.data),
                  payScheduleTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyPayScheduleGeneralTable");
        });
      }
    );
  };
  payScheduleFetchMoreData = ({ isPaginated = false }) => {
    this.setState({ payScheduleListLoading: true });

    const { payScheduleGrid } = this.state;
    const { page } = payScheduleGrid;

    const per_page = this.getPayScheduleGridPageSize();

    let sortingParams = this.getPayScheduleGridSortInfo();

    this.props

      .listPayschedules({
        options: {
          page,
          per_page,
          q: {
            companyId: getMatchParams({ match: this.props.match, field: "id" }),
          },

          ...sortingParams,
        },
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { payScheduleGrid: { ...payScheduleGrid, page: 1 } },
              () => {
                this.payScheduleFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.payScheduleList = data;
                } else {
                  prevState.payScheduleList =
                    prevState.payScheduleList.concat(data);
                  prevState.payScheduleHasMoreData = listHasMoreData({
                    data,
                    currentList: prevState.payScheduleList,
                    currentTotal: prevState.payScheduleTotal,
                  });
                  prevState.payScheduleGrid = getConditionalResponse({
                    condition: listHasMoreData({
                      data,
                      currentList: prevState.payScheduleList,
                      currentTotal: prevState.payScheduleTotal,
                    }),
                    resp1: {
                      ...prevState.payScheduleGrid,
                      page: prevState.payScheduleGrid.page + 1,
                    },
                    resp2: prevState.payScheduleGrid,
                  });
                }

                prevState.payScheduleTotal = response.total;
                prevState.payScheduleListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization("companyPayScheduleGeneralGrid");
                });
              }
            );
          }
        }
      });
  };
  listPayScheduleGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.payScheduleGrid.page = page;
        prevState.payScheduleListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.payScheduleGrid && this.state.payScheduleGrid.page,
          per_page: this.getPayScheduleGridPageSize(),

          ...this.getPayScheduleGridSortInfo(),
          q: {
            companyId: getMatchParams({ match: this.props.match, field: "id" }),
          },
        };

        this.props
          .getPayschedule({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ payScheduleListLoading: false });

            if (resp.status) {
              this.setState({
                payScheduleList: mustBeArray(resp.data),
                payScheduleTotal: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyPayScheduleGeneralGrid");
        });
      }
    );
  };

  getPayScheduleGridSortInfo = () => {
    if (
      this.state.payScheduleGrid.sortInfo &&
      this.state.payScheduleGrid.sortInfo.field &&
      this.state.payScheduleGrid.sortInfo.order &&
      this.state.payScheduleSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.payScheduleGrid.sortInfo.field]:
            this.state.payScheduleGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.payScheduleGrid.sortInfo &&
      this.state.payScheduleGrid.sortInfo.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.payScheduleGrid.sortInfo.order,
        },
      };
    } else return {};
  };
  handlePayScheduleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.payScheduleGridView === gridView) {
      return false;
    }

    this.setState(
      {
        payScheduleGridView: gridView,
        payScheduleList: [],
        payScheduleListLoading: true,
      },
      () => {
        this.updatePersonalization("companyPayScheduleListContainer");
        if (!gridView) {
          if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
            this.listScrollingPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.listPaySchedules({ options: {} });
          }
        } else {
          if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.payScheduleFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };
  payScheduleUpdatePageSize = (value) => {
    if (this.state.payScheduleGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.payScheduleGrid.row = Number(row);
          prevState.payScheduleGrid.col = Number(col);
          prevState.payScheduleGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.payScheduleFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ paySchedulePerPage: Number(value), current: 1 }, () => {
        if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
          this.listScrollingPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.listPaySchedules({ options: {} });
        }
      });
    }
  };
  payScheduleUpdateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.payScheduleSelectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.payScheduleGridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.payScheduleGrid = {
            ...prevState.payScheduleGrid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paySchedulePaginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.payScheduleFetchMoreData({ isPaginated: true });
        }
      }
    );
  };
  handlePayScheduleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { id, businessUnitID, companyId } = data;
    switch (action) {
      case "view":
        this.props.router.navigate(
          getPayScheduleDetailRoute({
            id,
            companyId,
            businessUnitId: businessUnitID,
          })
        );
        break;
      case "edit":
        break;
      default:
        break;
    }
  };
  payScheduleUpdateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.payScheduleGrid.sortInfo[field] === value) {
          prevState.payScheduleGrid.sortInfo[field] = "";
        } else {
          prevState.payScheduleGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.payScheduleFetchMoreData({ isPaginated: true });
      }
    );
  };
  getPayScheduleGridPageSize = () => {
    const { row, col } = this.state.payScheduleGrid;

    return this.state.paySchedulePaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };
  updatePayScheduleGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.payScheduleGrid.sortInfo[field] === value) {
          prevState.payScheduleGrid.sortInfo[field] = "";
        } else {
          prevState.payScheduleGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.payScheduleFetchMoreData({ isPaginated: true });
      }
    );
  };

  handlePayScheduleCreate = () => {
    console.log("");
  };

  handleUsersMFA = ({ action }) => {
    const { id } = this.props.match && this.props.match.params;
    message.info(
      `Your request to ${action === "enableAll" ? "enable" : "disable"
      } multi-factor authentication for all users has been submitted. Please refresh the screen after two minutes.`
    );

    this.props.handleCompanyUserActions({
      action,
      id,
      data: {
        mfaEnable: action === "enableAll" ? true : false,
        requestType: "mfa",
        deviceEnrolmentEnable: -1,
      },
    });
  };

  handleDeviceEnrolment = ({ action }) => {
    const { id } = this.props.match && this.props.match.params;
    message.info(`Your request to ${action === "mandatoryAllDeviceEnrolment"
        ? "enable mandatory"
        : action === "optionalAllDeviceEnrolment"
          ? "enable optional"
          : "disable"
      }
    device enrolment for all users has been submitted. Please refresh the screen after two minutes.`);

    this.props.handleCompanyUserActions({
      action,
      id,
      data: {
        mfaEnable: true,
        requestType: "deviceEnrolment",
        deviceEnrolmentEnable: getDeviceEnrolmentId({ action }),
      },
    });
  };

  handleAssign = ({ event, record }: any) => {
    const { assignedPayrollManagers } = this.state;
    if (_.find(assignedPayrollManagers, (o) => o.userId === record?.id)) {
      let newManagersList = _.filter(
        assignedPayrollManagers,
        (o) => o.userId !== record.id
      );
      this.setState({
        assignedPayrollManagers: newManagersList,
      });
    } else {
      this.setState({
        assignedPayrollManagers: [
          ...assignedPayrollManagers,
          { userId: record?.id, isPrimary: false },
        ],
      });
    }
  };

  handleChangePrimary = ({ event, record }: any) => {
    this.setState((prevState) => {
      prevState.assignedPayrollManagers =
        prevState.assignedPayrollManagers.reduce((a, b) => {
          return b.userId === record.id
            ? [...a, { ...b, isPrimary: !b.isPrimary }]
            : [...a, { ...b, isPrimary: false }];
        }, []);
      return prevState;
    });
  };

  displayPayrollManagerAssignModal = ({ e, record }) => {
    const assignedPayrollManagers = mustBeArray(record.assignedUsers).reduce(
      (a, b) => {
        return a.concat({
          userId: b.id,
          isPrimary: b.assigneeType === PRIMARY_PAYROLL_MANAGER_ID,
          firstName: b.firstName,
          lastName: b.lastName,
          profilePhoto: b.profilePhoto,
        });
      },
      []
    );
    this.setState(
      {
        activeBusinessUnit: record,
        payrollProviderId: record?.payrollServiceProviderId,
        assignedPayrollManagers,
      },
      () => {
        this.setState({ showAssignPayrollManagerModal: true });
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      showAssignPayrollManagerModal: false,
      activeBusinessUnit: {},
      payrollProviderId: null,
      assignedPayrollManagers: [],
    });
  };

  handleSaveAssignedPayrollManager = () => {
    const { assignedPayrollManagers, activeBusinessUnit } = this.state;
    if (
      !_.isEmpty(assignedPayrollManagers) &&
      !_.find(assignedPayrollManagers, (o) => o.isPrimary)
    ) {
      return message.error("Please select a primary payroll manager.");
    }
    const payload = mustBeArray(assignedPayrollManagers).reduce((a, b) => {
      return a.concat({
        userId: b.userId,
        assigneeType: b.isPrimary
          ? PRIMARY_PAYROLL_MANAGER_ID
          : SECONDARY_PAYROLL_MANAGER_ID,
      });
    }, []);
    this.setState({ saveLoading: true });
    this.props
      .businessunitHandler({
        id: activeBusinessUnit?.id,
        action: "assignPayrollManager",
        cancelToken: this.signal.token,
        payload,
      })
      .then((resp) => {
        this.setState({ saveLoading: false });
        if (resp.status) {
          message.success(`Payroll Managers updated successfully.`);
          this.handleCloseModal();
          this.listBusinessUnits({ options: {} });
        } else {
          errorHandler({ response: resp });
        }
      });
  };

  render() {
    const {
      activeKey,
      pageLoading,
      businessUnitListLoading,
      businessUnitList,
      businessUnitPagination,
      businessUnitSortInfo,
      businessUnitIsPinned,
      businessUnitPerPage,
      businessUnitTotal,
      businessUnitGrid,
      businessUnitGridable,
      businessUnitGridView,
      businessUnitFieldOptions,
      businessUnitSortingType,
      businessUnitPaginationType,
      businessUnitHasMoreData,
      payScheduleListLoading,
      payScheduleList,
      paySchedulePagination,
      payScheduleSortInfo,
      payScheduleIsPinned,
      paySchedulePerPage,
      payScheduleTotal,
      payScheduleGrid,
      payScheduleGridable,
      payScheduleGridView,
      payScheduleFieldOptions,
      payScheduleSortingType,
      paySchedulePaginationType,
      payScheduleHasMoreData,
      userListLoading,
      userList,
      userPagination,
      userSortInfo,
      userPerPage,
      userTotal,
      userGrid,
      userGridable,
      userGridView,
      userFieldOptions,
      userSortingType,
      userPaginationType,
      userIsPinned,
      userHasMoreData,
      enableMFALoading,
      disableMFALoading,
    } = this.state;

    const { pageClaims, member, dashboardView, type } = this.props;
    // Multi Tab
    const companyDetailMultiTabContainer = _.find(
      mustBeArray(pageClaims && pageClaims.components),
      (o) => o.name === COMPANY_DETAIL_MULTI_TAB_CONTAINER
    );
    // Basic Details
    const companyBasicDetailTabContainer = _.find(
      mustBeArray(companyDetailMultiTabContainer?.components),
      (o) => o.name === "companyBasicDetailTabContainer"
    );
    const companyBasicDetailChildTabContainer = _.find(
      mustBeArray(
        companyBasicDetailTabContainer &&
        companyBasicDetailTabContainer.components
      ),
      (o) => o.name === "companyBasicDetailChildTabContainer"
    );

    // Payschedule
    const companyPayScheduleTabContainer = _.find(
      mustBeArray(companyDetailMultiTabContainer?.components),
      (o) => o.name === "companyPayscheduleTabContainer"
    );
    const companyPayScheduleChildTabContainer = _.find(
      mustBeArray(companyPayScheduleTabContainer?.components),
      (o) => o.name === "companyPayscheduleChildTabContainer"
    );

    const payScheduleCardGrid = getCurrentCardGrid({
      grid: this.state.payScheduleGrid,
    });
    let companyPayScheduleTargetEndpoint = APIHandler.constructEndpoint({
      endpoint: "PAYSCHEDULE",
    });

    companyPayScheduleTargetEndpoint = requestUrlBuilder(
      companyPayScheduleTargetEndpoint,
      this.getPayScheduleQueryOptions()
    );

    // Business Unit
    const companyBusinessUnitTabContainer = _.find(
      mustBeArray(companyDetailMultiTabContainer?.components),
      (o) => o.name === "companyBusinessUnitTabContainer"
    );
    const companyBusinessUnitChildTabContainer = _.find(
      mustBeArray(companyBusinessUnitTabContainer?.components),
      (o) => o.name === "companyBusinessUnitChildTabContainer"
    );

    const businessUnitCardGrid = getCurrentCardGrid({
      grid: this.state.businessUnitGrid,
    });
    let companyBusinessUnitTargetEndpoint = APIHandler.constructEndpoint({
      endpoint: "COMPANY_BUSINESS_UNIT_URL",

      options: { id: getMatchParams({ match: this.props.match, field: "id" }) },
    });

    companyBusinessUnitTargetEndpoint = requestUrlBuilder(
      companyBusinessUnitTargetEndpoint,
      this.getBusinessUnitQueryOptions()
    );

    // Users
    const companyUserTabContainer = _.find(
      mustBeArray(companyDetailMultiTabContainer?.components),
      (o) => o.name === "companyUserTabContainer"
    );
    const companyUserChildTabContainer = _.find(
      mustBeArray(
        companyUserTabContainer && companyUserTabContainer.components
      ),
      (o) => o.name === "companyUserChildTabContainer"
    );

    const userCardGrid = getCurrentCardGrid({ grid: this.state.userGrid });
    let companyUserTargetEndpoint = APIHandler.constructEndpoint({
      endpoint: `COMPANY_USERS_URL${getConditionalResponse({
        condition: [userRoles.CompanyArea].includes(getUserArea()),
        resp1: "_COMPANYUSER",
        resp2: "",
      })}`,
    });

    companyUserTargetEndpoint = requestUrlBuilder(
      companyUserTargetEndpoint,
      this.getUsersQueryOptions()
    );

    const enableMfaClaims = findAuthorizationClaims({
      claims: companyUserChildTabContainer?.components,
      name: "companyUserEnableMfaAllButton",
    });
    const disableMfaClaims = findAuthorizationClaims({
      claims: companyUserChildTabContainer?.components,
      name: "companyUserDisableMfaAllButton",
    });
    const mandatoryDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: companyUserChildTabContainer?.components,
      name: "companyUserMandatoryDeviceEnrolmentAllButton",
    });
    const optionalDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: companyUserChildTabContainer?.components,
      name: "companyUserOptionalDeviceEnrolmentAllButton",
    });
    const disableDeviceEnrolmentClaims = findAuthorizationClaims({
      claims: companyUserChildTabContainer?.components,
      name: "companyUserDisableDeviceEnrolmentAllButton",
    });
    const CustomUserActions = () => {
      return _.filter(
        [
          disableMfaClaims,
          enableMfaClaims,
          mandatoryDeviceEnrolmentClaims,
          optionalDeviceEnrolmentClaims,
          disableDeviceEnrolmentClaims,
        ],
        (o) => {
          return VISIBLE_CLAIMS_ID.includes(
            Number(o && o.authorizationStatusId)
          );
        }
      ) && userRoles.SystemAdminArea === getUserArea() ? (
        <UserAuthenticationUpdate
          handleMFA={this.handleUsersMFA}
          handleDeviceEnrolment={this.handleDeviceEnrolment}
          enableMfaClaims={enableMfaClaims}
          disableMfaClaims={disableMfaClaims}
          mandatoryDeviceEnrolmentClaims={mandatoryDeviceEnrolmentClaims}
          optionalDeviceEnrolmentClaims={optionalDeviceEnrolmentClaims}
          disableDeviceEnrolmentClaims={disableDeviceEnrolmentClaims}
          showMandatoryDeviceEnrolmentOption={true}
          showOptionalDeviceEnrolmentOption={true}
          showDisableDeviceEnrolmentOption={true}
        />
      ) : (
        <React.Fragment></React.Fragment>
      );
    };
    return dashboardView && type === "companyUser" ? (
      <ListContainer
        name="companyUserListContainer"
        claims={companyUserChildTabContainer}
        isPinned={userIsPinned}
        updatePinnedComponent={this.updatePinnedComponent}
        gridable={userGridable}
        gridView={userGridView}
        grid={userGrid}
        per_page={userPerPage}
        GeneralTablePaginatedComponent={CompanyUserList}
        GeneralTableScrollableComponent={ScrollableUserList}
        generalTableComponentName={"companyUserGeneralTable"}
        gridComponentName={"companyUserGeneralGrid"}
        createLinkButtonName={"companyUserAddButton"}
        handleAddAction={this.handleUserCreate}
        handleGridChange={this.handleUserGridChange}
        fieldOptions={userFieldOptions}
        updateGridField={this.updateUserGridField}
        sortingType={userSortingType}
        paginationType={userPaginationType}
        list={userList}
        listLoading={userListLoading}
        listGrid={this.listUserGrid}
        listScrollingGrid={this.listScrollingGridUser}
        hasMoreData={userHasMoreData}
        gridActionComponentName={"companyUserViewDetailLink"}
        moduleType={"user"}
        handleGridAction={this.handleUserAction}
        listScrollingGeneralTable={this.listScrollingUser}
        total={userTotal}
        current={userPagination}
        sortInfo={userSortInfo}
        updatePageSize={this.userUpdatePageSize}
        listGeneralTable={({ options }) =>
          this.listUsers({
            options: {
              ...options,
            },
          })
        }
        tableActionComponentName={"companyUserViewDetailLink"}
        gridPageSize={this.getUserGridPageSize()}
        cardGrid={userCardGrid}
        reminderComponent="companyUserSendReminderButton"
        editComponent="companyUserEditIconButton"
        toggleComponent="companyUserStatusSwitchButton"
        pinnedComponentName="userIsPinned"
        showAddAndGrid={true}
        addModuleName="User"
        userType="company"
        handleEdit={this.handleEdit}
        DisplayCardComponent={UserDisplayCard}
        handleCompanyUserActions={this.props.handleCompanyUserActions}
        member={member}
        CustomActions={<CustomUserActions />}
        enableMFALoading={enableMFALoading}
        disableMFALoading={disableMFALoading}
        enableDisableComponentNameGrid="companyUserStatusSwitchButton"
        enableDisableMFAComponentNameGrid="companyUserEnableDisableMfa"
        enableDisableMFAComponentName="companyUserEnableDisableMfa"
        dashboardView={true}
        componentTitle={this.props.componentTitle}
        filterApplied={this.props.filterApplied}
        targetEndpoint={this.props.targetEndpoint}
      />
    ) : dashboardView && type === "companyBusinessUnit" ? (
      <ListContainer
        name="companyBusinessUnitListContainer"
        claims={companyBusinessUnitChildTabContainer}
        isPinned={businessUnitIsPinned}
        updatePinnedComponent={this.updatePinnedComponent}
        gridable={businessUnitGridable}
        gridView={businessUnitGridView}
        grid={businessUnitGrid}
        per_page={businessUnitPerPage}
        GeneralTablePaginatedComponent={BusinessUnitList}
        GeneralTableScrollableComponent={ScrollableBusinessUnitTable}
        generalTableComponentName={"companyBusinessUnitGeneralTable"}
        gridComponentName={"companyBusinessUnitGeneralGrid"}
        createLinkButtonName={"company  "}
        handleAddAction={this.addBusinessUnit}
        handleGridChange={this.handleBusinessUnitGridChange}
        fieldOptions={businessUnitFieldOptions}
        updateGridField={this.updateBusinessUnitGridField}
        sortingType={businessUnitSortingType}
        paginationType={businessUnitPaginationType}
        list={businessUnitList}
        listLoading={businessUnitListLoading}
        listGrid={this.listBusinessUnitGrid}
        listScrollingGrid={this.listScrollingGridBusinessUnit}
        hasMoreData={businessUnitHasMoreData}
        gridActionComponentName={"companyBusinessUnitViewDetailLink"}
        moduleType={"businessUnit"}
        handleGridAction={this.handleBusinessUnitAction}
        listScrollingGeneralTable={this.listScrollingBusinessUnit}
        total={businessUnitTotal}
        current={businessUnitPagination}
        sortInfo={businessUnitSortInfo}
        updatePageSize={this.businessUnitUpdatePageSize}
        listGeneralTable={({ options }) =>
          this.listBusinessUnits({
            options: {
              ...options,
            },
          })
        }
        tableActionComponentName={"companyBusinessUnitViewDetailLink"}
        gridPageSize={this.getBusinessUnitGridPageSize()}
        cardGrid={businessUnitCardGrid}
        reminderComponent="companyBusinessUnitSendReminderButton"
        editComponent="companyBusinessUnitEditIconButton"
        toggleComponent="companyBusinessUnitStatusSwitchButton"
        pinnedComponentName="businessUnitIsPinned"
        showAddAndGrid={true}
        addModuleName="Business Unit"
        dashboardView={true}
        componentTitle={this.props.componentTitle}
        filterApplied={this.props.filterApplied}
        targetEndpoint={this.props.targetEndpoint}
        member={member}
      />
    ) : dashboardView && type === "companyPaySchedule" ? (
      <ListContainer
        name="companyPayScheduleListContainer"
        claims={companyPayScheduleChildTabContainer}
        isPinned={payScheduleIsPinned}
        updatePinnedComponent={this.updatePinnedComponent}
        gridable={payScheduleGridable}
        gridView={payScheduleGridView}
        grid={payScheduleGrid}
        per_page={paySchedulePerPage}
        GeneralTablePaginatedComponent={PayScheduleList}
        GeneralTableScrollableComponent={ScrollablePayScheduleList}
        generalTableComponentName={"companyPayScheduleGeneralTable"}
        gridComponentName={"companyPayScheduleGeneralGrid"}
        createLinkButtonName={"companyPayScheduleAddButton"}
        handleAddAction={this.addPaySchedule}
        handleGridChange={this.handlePayScheduleGridChange}
        fieldOptions={payScheduleFieldOptions}
        updateGridField={this.updatePayScheduleGridField}
        sortingType={payScheduleSortingType}
        paginationType={paySchedulePaginationType}
        list={payScheduleList}
        listLoading={payScheduleListLoading}
        listGrid={this.listPayScheduleGrid}
        listScrollingGrid={this.listScrollingGridPaySchedule}
        hasMoreData={payScheduleHasMoreData}
        gridActionComponentName={"companyPayScheduleViewDetailLink"}
        moduleType={"paySchedule"}
        handleGridAction={this.handlePayScheduleAction}
        listScrollingGeneralTable={this.listScrollingPaySchedule}
        total={payScheduleTotal}
        current={paySchedulePagination}
        sortInfo={payScheduleSortInfo}
        updatePageSize={this.payScheduleUpdatePageSize}
        listGeneralTable={this.listPaySchedules}
        tableActionComponentName={"companyPayScheduleViewDetailLink"}
        gridPageSize={this.getPayScheduleGridPageSize()}
        cardGrid={payScheduleCardGrid}
        reminderComponent="companyPayScheduleSendReminderButton"
        editComponent="companyPayScheduleEditIconButton"
        toggleComponent="companyPayScheduleStatusSwitchButton"
        pinnedComponentName="payScheduleIsPinned"
        showAddAndGrid={true}
        addModuleName="Pay Schedule"
        view="company"
        DisplayCardComponent={PayScheduleDisplayCard}
        componentTitle={this.props.componentTitle}
        filterApplied={this.props.filterApplied}
        dashboardView={true}
        targetEndpoint={this.props.targetEndpoint}
      />
    ) : pageLoading ? (
      <Spin />
    ) : (
      <div className="module-detail">
        <ModuleInfoCard detail={this.props.company} type="company" />
        <MultiTabContainer
          name={COMPANY_DETAIL_MULTI_TAB_CONTAINER}
          claims={pageClaims}
          activeKey={activeKey}
          handleTabChange={this.handleTabChange}
        >
          {companyBasicDetailTabContainer &&
            [1, 2].includes(
              Number(companyBasicDetailTabContainer.authorizationStatusId)
            ) && (
              <TabPane key={"1"} tab="Company Details">
                <ChildTabContainer
                  name="companyBasicDetailChildTabContainer"
                  claims={companyBasicDetailTabContainer}
                >
                  <GeneralContainer
                    name={"companyGeneralDetailContainer"}
                    claims={companyBasicDetailChildTabContainer}
                    md={12}
                    sm={24}
                    lg={6}
                  >
                    <BasicDetails
                      organization={this.props.company}
                      edit={this.showCompanyModal}
                      entity="company"
                      claimComponent="companyEdit"
                      hasNoComponents={false}
                    />
                  </GeneralContainer>
                </ChildTabContainer>
              </TabPane>
            )}
          {companyPayScheduleTabContainer &&
            [1, 2].includes(
              Number(companyPayScheduleTabContainer.authorizationStatusId)
            ) && (
              <TabPane
                key={"2"}
                tab={
                  <TabHeader title="Pay Schedules" total={payScheduleTotal} />
                }
              >
                <ChildTabContainer
                  name="companyPayscheduleChildTabContainer"
                  claims={companyPayScheduleTabContainer}
                >
                  <ListContainer
                    name="companyPayScheduleListContainer"
                    claims={companyPayScheduleChildTabContainer}
                    isPinned={payScheduleIsPinned}
                    updatePinnedComponent={this.updatePinnedComponent}
                    gridable={payScheduleGridable}
                    gridView={payScheduleGridView}
                    grid={payScheduleGrid}
                    per_page={paySchedulePerPage}
                    GeneralTablePaginatedComponent={PayScheduleList}
                    GeneralTableScrollableComponent={ScrollablePayScheduleList}
                    generalTableComponentName={"companyPayScheduleGeneralTable"}
                    gridComponentName={"companyPayScheduleGeneralGrid"}
                    createLinkButtonName={"companyPayScheduleAddButton"}
                    handleAddAction={this.addPaySchedule}
                    handleGridChange={this.handlePayScheduleGridChange}
                    fieldOptions={payScheduleFieldOptions}
                    updateGridField={this.updatePayScheduleGridField}
                    sortingType={payScheduleSortingType}
                    paginationType={paySchedulePaginationType}
                    list={payScheduleList}
                    listLoading={payScheduleListLoading}
                    listGrid={this.listPayScheduleGrid}
                    listScrollingGrid={this.listScrollingGridPaySchedule}
                    hasMoreData={payScheduleHasMoreData}
                    gridActionComponentName={"companyPayScheduleViewDetailLink"}
                    moduleType={"paySchedule"}
                    handleGridAction={this.handlePayScheduleAction}
                    listScrollingGeneralTable={this.listScrollingPaySchedule}
                    total={payScheduleTotal}
                    current={paySchedulePagination}
                    sortInfo={payScheduleSortInfo}
                    updatePageSize={this.payScheduleUpdatePageSize}
                    listGeneralTable={this.listPaySchedules}
                    tableActionComponentName={
                      "companyPayScheduleViewDetailLink"
                    }
                    gridPageSize={this.getPayScheduleGridPageSize()}
                    cardGrid={payScheduleCardGrid}
                    reminderComponent="companyPayScheduleSendReminderButton"
                    editComponent="companyPayScheduleEditIconButton"
                    toggleComponent="companyPayScheduleStatusSwitchButton"
                    pinnedComponentName="payScheduleIsPinned"
                    showAddAndGrid={true}
                    addModuleName="Pay Schedule"
                    view="company"
                    DisplayCardComponent={PayScheduleDisplayCard}
                    targetEndpoint={companyPayScheduleTargetEndpoint}
                    hasNoDuplicateEntity={false}
                    componentTitle="Pay Schedules"
                    filterApplied={`Filtered by ${this.props.company && this.props.company.name
                      }`}
                  />
                </ChildTabContainer>
              </TabPane>
            )}
          {companyBusinessUnitTabContainer &&
            [1, 2].includes(
              Number(companyBusinessUnitTabContainer.authorizationStatusId)
            ) && (
              <TabPane
                key={"3"}
                tab={
                  <TabHeader title="Business Units" total={businessUnitTotal} />
                }
              >
                <ChildTabContainer
                  name="companyBusinessUnitChildTabContainer"
                  claims={companyBusinessUnitTabContainer}
                >
                  <ListContainer
                    name="companyBusinessUnitListContainer"
                    claims={companyBusinessUnitChildTabContainer}
                    isPinned={businessUnitIsPinned}
                    updatePinnedComponent={this.updatePinnedComponent}
                    gridable={businessUnitGridable}
                    gridView={businessUnitGridView}
                    grid={businessUnitGrid}
                    per_page={businessUnitPerPage}
                    GeneralTablePaginatedComponent={BusinessUnitList}
                    GeneralTableScrollableComponent={
                      ScrollableBusinessUnitTable
                    }
                    generalTableComponentName={
                      "companyBusinessUnitGeneralTable"
                    }
                    gridComponentName={"companyBusinessUnitGeneralGrid"}
                    createLinkButtonName={"companyBusinessUnitAddButton"}
                    handleAddAction={this.addBusinessUnit}
                    handleGridChange={this.handleBusinessUnitGridChange}
                    fieldOptions={businessUnitFieldOptions}
                    updateGridField={this.updateBusinessUnitGridField}
                    sortingType={businessUnitSortingType}
                    paginationType={businessUnitPaginationType}
                    list={businessUnitList}
                    listLoading={businessUnitListLoading}
                    listGrid={this.listBusinessUnitGrid}
                    listScrollingGrid={this.listScrollingGridBusinessUnit}
                    hasMoreData={businessUnitHasMoreData}
                    gridActionComponentName={
                      "companyBusinessUnitViewDetailLink"
                    }
                    moduleType={"businessUnit"}
                    handleGridAction={this.handleBusinessUnitAction}
                    listScrollingGeneralTable={this.listScrollingBusinessUnit}
                    total={businessUnitTotal}
                    current={businessUnitPagination}
                    sortInfo={businessUnitSortInfo}
                    updatePageSize={this.businessUnitUpdatePageSize}
                    listGeneralTable={({ options }) =>
                      this.listBusinessUnits({
                        options: {
                          ...options,
                        },
                      })
                    }
                    tableActionComponentName={
                      "companyBusinessUnitViewDetailLink"
                    }
                    gridPageSize={this.getBusinessUnitGridPageSize()}
                    cardGrid={businessUnitCardGrid}
                    reminderComponent="companyBusinessUnitSendReminderButton"
                    editComponent="companyBusinessUnitEditIconButton"
                    toggleComponent="companyBusinessUnitStatusSwitchButton"
                    pinnedComponentName="businessUnitIsPinned"
                    showAddAndGrid={true}
                    addModuleName="Business Unit"
                    targetEndpoint={companyBusinessUnitTargetEndpoint}
                    hasNoDuplicateEntity={false}
                    componentTitle="Business Units"
                    filterApplied={`Filtered by ${this.props.company && this.props.company.name
                      }`}
                    handleAssignPayrollManager={
                      this.displayPayrollManagerAssignModal
                    }
                    member={member}
                  />
                </ChildTabContainer>
              </TabPane>
            )}
          {companyUserTabContainer &&
            [1, 2].includes(
              Number(companyUserTabContainer.authorizationStatusId)
            ) && (
              <TabPane
                key={"4"}
                tab={<TabHeader title="Users" total={userTotal} />}
              >
                <ChildTabContainer
                  name="companyUserChildTabContainer"
                  claims={companyUserTabContainer}
                >
                  <ListContainer
                    name="companyUserListContainer"
                    claims={companyUserChildTabContainer}
                    isPinned={userIsPinned}
                    updatePinnedComponent={this.updatePinnedComponent}
                    gridable={userGridable}
                    gridView={userGridView}
                    grid={userGrid}
                    per_page={userPerPage}
                    GeneralTablePaginatedComponent={CompanyUserList}
                    GeneralTableScrollableComponent={ScrollableUserList}
                    generalTableComponentName={"companyUserGeneralTable"}
                    gridComponentName={"companyUserGeneralGrid"}
                    createLinkButtonName={"companyUserAddButton"}
                    handleAddAction={this.handleUserCreate}
                    handleGridChange={this.handleUserGridChange}
                    fieldOptions={userFieldOptions}
                    updateGridField={this.updateUserGridField}
                    sortingType={userSortingType}
                    paginationType={userPaginationType}
                    list={userList}
                    listLoading={userListLoading}
                    listGrid={this.listUserGrid}
                    listScrollingGrid={this.listScrollingGridUser}
                    hasMoreData={userHasMoreData}
                    gridActionComponentName={"companyUserViewDetailLink"}
                    moduleType={"user"}
                    handleGridAction={this.handleUserAction}
                    listScrollingGeneralTable={this.listScrollingUser}
                    total={userTotal}
                    current={userPagination}
                    sortInfo={userSortInfo}
                    updatePageSize={this.userUpdatePageSize}
                    listGeneralTable={({ options }) =>
                      this.listUsers({
                        options: {
                          ...options,
                        },
                      })
                    }
                    tableActionComponentName={"companyUserViewDetailLink"}
                    gridPageSize={this.getUserGridPageSize()}
                    cardGrid={userCardGrid}
                    reminderComponent="companyUserSendReminderButton"
                    editComponent="companyUserEditIconButton"
                    toggleComponent="companyUserStatusSwitchButton"
                    pinnedComponentName="userIsPinned"
                    showAddAndGrid={true}
                    addModuleName="User"
                    userType="company"
                    handleEdit={this.handleEdit}
                    DisplayCardComponent={UserDisplayCard}
                    handleCompanyUserActions={
                      this.props.handleCompanyUserActions
                    }
                    member={member}
                    CustomActions={<CustomUserActions />}
                    enableMFALoading={enableMFALoading}
                    disableMFALoading={disableMFALoading}
                    enableDisableComponentNameGrid="companyUserStatusSwitchButton"
                    enableDisableMFAComponentNameGrid="companyUserEnableDisableMfa"
                    enableDisableMFAComponentName="companyUserEnableDisableMfa"
                    targetEndpoint={companyUserTargetEndpoint}
                    hasNoDuplicateEntity={false}
                    componentTitle="Company Users"
                    filterApplied={`Filtered by ${this.props.company && this.props.company.name
                      }`}
                  />
                </ChildTabContainer>
              </TabPane>
            )}
        </MultiTabContainer>
        <AssignPayrollManagerModal
          visible={this.state.showAssignPayrollManagerModal}
          listPSPUsers={this.props.listPSPUsers}
          payrollProviderId={this.state.payrollProviderId}
          handleClose={this.handleCloseModal}
          activeBusinessUnit={this.state.activeBusinessUnit}
          assignedPayrollManagers={this.state.assignedPayrollManagers}
          handleAssign={this.handleAssign}
          handleChangePrimary={this.handleChangePrimary}
          handleSaveAssignedPayrollManager={
            this.handleSaveAssignedPayrollManager
          }
          saveLoading={this.state.saveLoading}
          member={member}
        />
      </div>
    );
  }
}

export default withRouter(CompanyDetail);
