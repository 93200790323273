//@ts-nocheck
import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const CreateSuccess = (props: any) => {
  const { page, actionOneLabel, actionOneLink, actionTwoLabel, actionTwoLink } =
    props;
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="text-center screen-success">
        <div className={"text-2xl font-semibold mb-4"}>"{page}" Created</div>
        <div className="mb-4">
          <CheckCircleTwoTone twoToneColor="#1FBAB6" className="text-5xl" />
        </div>
        <p className={"mb-4 text-sm font-semibold"}>What's Next?</p>
        <div className="flex justify-center">
          <Button
            type="success"
            className="ant-btn-lg rounded-[20px]"
            onClick={() => navigate(actionOneLink)}
          >
            {actionOneLabel}
          </Button>
          {actionTwoLabel && actionTwoLink && (
            <Button
              type="success"
              className="ant-btn-lg ml-6 rounded-[20px]"
              onClick={() => navigate(actionTwoLink)}
            >
              {actionTwoLabel}
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CreateSuccess;
