// @ts-nocheck
import React from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Tooltip } from "antd";

const IconWithTooltip = (props: any) => {
  return (
    <Tooltip title={props.title} placement={props.placement || "bottom"}>
      <div
        className={`hand flex justify-center items-center icon-container ${
          props.className ? props.className : ""
        }`}
      >
        {props.iconType ? (
          <LegacyIcon
            type={props.iconType}
            theme={props.theme || "filled"}
            onClick={(e) => props.onIconClicked(e)}
            className="color-white"
          />
        ) : (
          props.letter
        )}
      </div>
    </Tooltip>
  );
};

export default IconWithTooltip;
