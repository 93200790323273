// @ts-nocheck
import { mustBeArray } from "../libs/utilities";
class InvoiceStatus {
  _data: any;
  invoiceStatus: any;
  status: any;
  constructor() {
    if (!InvoiceStatus.instance) {
      this._data = [
        { id: 1, value: "Created" },
        { id: 2, value: "PartiallyPaid" },
        { id: 3, value: "FullyPaid" },
        { id: 4, value: "Skipped" },
        { id: 5, value: "Confirmed" },
        { id: 6, value: "Verified" },
      ];

      this.invoiceStatus = {
        invoicePaid: [2, 3, 5, 6],
        invoiceUnPaid: [1, 4],
      };
      InvoiceStatus.instance = this;
    }
    return InvoiceStatus.instance;
  }

  getStatusID({ status }: any) {
    if (this.invoiceStatus[status]) {
      return mustBeArray(this.invoiceStatus[status]).reduce(
        (a: any, b: any) => a + Math.pow(2, b - 1),
        0
      );
    }
    return 0;
  }

  getStatus({ id }: any) {
    if (this.status.invoiceUnpaid.includes(id)) return "invoiceUnpaid";
    if (this.status.invoicePaid.includes(id)) return "invoicePaid";
  }
}

const instance = new InvoiceStatus();
Object.freeze(instance);

export default instance;
