//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import { Table } from "antd";
import {
  mustBeArray,
  findScrollableTableHeight,
  getPayrunListColumns,
} from "../../../libs/utilities";
import { withRouter } from "../../../hooks";

class ScrollingPayrunList extends React.Component {
  componentDidMount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .addEventListener("scroll", (event) => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          if (
            (currentScroll >= maxScroll || maxScroll - currentScroll < 0.5) &&
            this.props.hasMoreData
          ) {
            this.props.handleTableChange({ options: {}, pageLoad: false });
          }
        });
  }
  componentWillUnmount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .removeEventListener("scroll", () => {
          console.log("");
        });
  }

  viewPayrun = (record: any) => {
    this.props.router.navigate(`/payrun/${record.payrunId}`);
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    switch (sorter.field) {
      case "companyName":
        field = "companyname";
        break;
      case "businessUnitName":
        field = "businessname";
        break;
      case "payOccurrenceName":
        field = "payoccurrencename";
        break;
      case "ammendmentDueDateTime":
        field = "ammendmentduedate";
        break;
      case "payDate":
        field = "paydate";
        break;
      case "ammendmentCount":
        field = "ammendmentcount";
        break;
      default:
        break;
    }
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  render() {
    const {
      sortInfo,
      claims,
      viewComponent,
      per_page,
      data,
      loading,
      dashboardView,
    } = this.props;
    let colTemplate = getPayrunListColumns({
      viewComponent,
      sortInfo,
      claims,
      dashboardView,
    });
    const column = tableColumnGenerator(colTemplate);

    // , [
    //     {
    //         title: "Action",
    //         key: "action",
    //         render: record => {
    //             return (
    //                 <EditIconButton
    //                     handleEdit={() => handleEdit(record)}
    //                     name={editComponent}
    //                     claims={claims}
    //                     hasNoComponents={true}
    //                     avatarClassName="color-primary"
    //                 />
    //             );
    //         }
    //     }
    // ]);
    return (
      <Table
        columns={column}
        dataSource={mustBeArray(data)}
        loading={loading}
        onChange={this.handleTableChange}
        pagination={false}
        rowKey={(record, index) => index}
        scroll={{
          y: findScrollableTableHeight({
            per_page,
            total: mustBeArray(data).length,
          }),
        }}
      />
    );
  }
}

export default withRouter(ScrollingPayrunList);
