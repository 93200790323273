import { css } from 'styled-components';

import { zIndex } from '@xemplo/zindex';

import { Colour } from './colours';
import { MinBreakpoint } from './media-query';

export const Overlay = css`
  position: fixed;
  inset: 0;
  z-index: ${zIndex.ModalOverlay};
  background-color: ${Colour.Gray[900]};
  opacity: 0.6;

  @media ${MinBreakpoint.mediumV2} {
    display: none;
  }
`;
