import { SVGProps } from 'react';

export const DawnCircleStop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.001C6.499 2.001 2 6.5 2 12S6.499 21.999 12 21.999 21.999 17.5 21.999 12s-4.498-9.999-10-9.999ZM3.5 12c0-4.673 3.826-8.499 8.499-8.499S20.499 7.327 20.499 12s-3.826 8.499-8.5 8.499c-4.672 0-8.498-3.826-8.498-8.499Zm2.749-.004c0 .414.336.75.75.75h9.887a.75.75 0 0 0 0-1.5H7a.75.75 0 0 0-.75.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCircleStop;
