export interface AuthorizationDOM {
  name: string;
  id: string;
  type: string;
  typeId: string;
  authorizationStatus: AuthorizationStatus;
  authorizationStatusId: string;
  personalizationSettings?: PersonalizationSettings;
  components: AuthorizationDOM[];
}

/** @knipignore */
export enum AuthorizationStatus {
  Hidden = "Hidden",
  VisibleEnabled = "Visible - Enabled",
}

export interface PersonalizationSettings {
  saveSearchHistory: string;
  saveLastPageVisited: string;
  savePageSize: string;
  saveSortOrder: string;
  saveGridTableSwap: string;
}
