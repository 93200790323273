//@ts-nocheck
import React, { Component } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Card, Button, Spin } from "antd";
import PayrunDetails from "../../components/payrun/detail/detail";
import { errorHandler, headerAction, payrunIsSkipped } from "../../../libs";
import ProcessingFees from "../../components/processingFees/fees";
import { mustBeArray, errorDisplay } from "../../../libs/utilities";
import CountryCoordinates from "../../../Common/countries";
import { If } from "../../../Common/ui";
import { withRouter } from "../../../hooks";
import { DEFAULT_ROUTES } from "../../../constants";

type State = any;

class PayrunDetailView extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      feesLoading: true,
      countryCode: "",
      current: 1,
      currencyFees: {},
    };
  }
  componentDidMount() {
    const { id } = this.props.match && this.props.match.params;
    this.props.getPayrun({ id, options: {} }).then((resp) => {
      if (this.props.businessunitDetails?.country) {
        this.setState({
          countryCode: CountryCoordinates.getCode(
            this.props.businessunitDetails.country
          ),
        });
      }

      if (resp.status && resp.data) {
        this.getCurrencyFees();
        this.setState({ loading: false, feesLoading: false });
        headerAction.set({
          title: `Pay Run : ${resp.data && resp.data.name}`,
          action: "",
          entity: "payrun",
          enableBack: true,
        });
        this.props.getBusinessUnit({
          id: resp.data.businessUnitID,
          cancelToken: this.props.signal.token,
        });
      } else {
        errorHandler({ response: resp, hasValidationErrors: true });
        if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
          this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
        }
      }
    });
  }
  getCurrencyFees = () => {
    const { id } = this.props.match && this.props.match.params;
    if (id) {
      this.props
        .payrunHandler({
          action: "getCurrencyFees",
          id,
        })
        .then((resp) => {
          if (resp.status) {
            this.setState({ currencyFees: resp.data ? resp.data : {} });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
    }
  };
  fetchProcessingFee = ({ options }) => {
    this.setState({ current: options && options.page });
  };

  getProcessingFee = () => {
    return { status: false };
  };
  render() {
    const { payrun, businessunitDetails } = this.props;
    const processingFees = payrun?.processingFees;

    const { feesLoading, loading, current, currencyFees } = this.state;
    const payRunProcessingTitle = (
      <div className="pl-5">
        Pay Run Processing Fees{" "}
        <Button
          className="ml-2.5 rounded-lg"
          type="primary"
          size={"small"}
          icon={<EditOutlined />}
          disabled
        >
          Edit
        </Button>
      </div>
    );
    return (
      <If
        condition={loading}
        then={<Spin />}
        else={
          <React.Fragment>
            <PayrunDetails payrun={payrun} currencyFees={currencyFees} />
            <Card className="mb-4 p-4" title={payRunProcessingTitle}>
              <ProcessingFees
                data={processingFees}
                payscheduleFeeCount={mustBeArray(processingFees).length}
                processingFeeTableLoading={feesLoading}
                action={false}
                businessunitDetails={businessunitDetails}
                claims={this.props.claims}
                showFeesDetails={true}
                match={this.props.match}
                listAmendmentTypes={this.props.listAmendmentTypes}
                earningLinesLookup={this.props.earningLinesLookup}
                getBusinessUnit={this.props.getBusinessUnit}
                populateStates={this.props.populateStates}
                getProcessingFee={this.getProcessingFee}
                fetchProcessingFee={this.fetchProcessingFee}
                viewPayrun={true}
                countryCode={this.state.countryCode}
                basePayMapping={payrun && payrun.basePayMapping}
                current={current}
              />
            </Card>
          </React.Fragment>
        }
      />
    );
  }
}

export default withRouter(PayrunDetailView);
