import { SVGProps } from 'react';
const DawnArrowLongLeft24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12c0-.21.086-.399.224-.534L6.47 7.47a.75.75 0 0 1 1.06 1.06l-2.72 2.72H21a.75.75 0 0 1 0 1.5H4.81l2.72 2.72a.75.75 0 1 1-1.06 1.06l-4-4a.756.756 0 0 1-.22-.53Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLongLeft24;
