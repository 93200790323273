import { Fragment } from 'react';
import { ColumnDef, RowData, Table } from '@tanstack/react-table';

import { Checkbox, CheckboxSize } from '@xemplo/checkbox';
import { formatToCurrency } from '@xemplo/common-util';
import { IconButton, IconButtonSize } from '@xemplo/icon-button';
import { DawnArrowDown16, DawnArrowUp16 } from '@xemplo/icons';

import { TableProps } from './table.types';

export const checkboxColumn = <TData extends RowData>() =>
  ({
    id: 'extra-col',
    header: ({ table, header }) => {
      const { enableRowSelection, isListTable } = table.options as TableProps<TData>;
      if (!enableRowSelection || isListTable) return null;

      return (
        <Checkbox
          id="select-all-rows"
          value={header.id}
          size={CheckboxSize.Small}
          ariaLabel={'Select all rows checkbox'}
          checked={table.getIsAllPageRowsSelected() || table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomePageRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      );
    },
    cell: ({ table, row }) => {
      if (row.depth > 0) return null;
      const { enableRowSelection, enableExpanding } = table.options;
      return (
        <Fragment>
          {enableRowSelection && (
            <Checkbox
              id={`select-row-${row.index}`}
              value={row.index}
              size={CheckboxSize.Small}
              ariaLabel={`Select row ${row.index} checkbox`}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )}

          {enableExpanding && row.getCanExpand() && (
            <IconButton
              id={`expand-row-${row.index}`}
              ariaLabel={`Expand row ${row.index} button`}
              size={IconButtonSize.Small}
              naked
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? <DawnArrowUp16 /> : <DawnArrowDown16 />}
            </IconButton>
          )}
        </Fragment>
      );
    },
  } as ColumnDef<TData>);

type CalcProps<TData extends RowData> = {
  table: Table<TData>;
  key: string;
  monetary?: CurrencyProps;
  calcMode?: 'filter' | 'page';
};

type AvgProps<TData extends RowData> = CalcProps<TData> & {
  roundUp?: boolean;
};

type NumberProps = {
  value?: number | null;
  locale?: string;
};

type CurrencyProps = NumberProps & {
  currency?: string;
};

export class CalcHelper {
  static Average<TData extends RowData>({
    table,
    key,
    roundUp = true,
    calcMode = 'filter',
  }: AvgProps<TData>) {
    const { rows } =
      calcMode === 'filter' ? table.getFilteredRowModel() : table.getPaginationRowModel();

    if (!isNumeric(rows[0].getValue(key))) return;

    const total = rows.reduce((acc, row) => acc + Number(row.getValue(key)), 0);
    const result = total / rows.length;

    return roundUp ? Math.ceil(result) : result;
  }

  static Sum<TData extends RowData>({
    table,
    key,
    monetary,
    calcMode = 'filter',
  }: CalcProps<TData>) {
    const { rows } =
      calcMode === 'filter' ? table.getFilteredRowModel() : table.getPaginationRowModel();

    if (!isNumeric(rows[0].getValue(key))) return;

    const result = rows.reduce((acc, row) => acc + Number(row.getValue(key)), 0);
    return monetary ? formatToCurrency({ ...monetary, value: result }) : result;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNumeric(value: any): boolean {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function formatNumber({ value, locale = 'en-AU' }: NumberProps) {
  return new Intl.NumberFormat(locale).format(value ?? 0);
}

export function formatNumberWithDecimals({
  value,
  locale = 'en-AU',
  minimumFractionDigits = 2,
}: NumberProps & { minimumFractionDigits?: number }) {
  return new Intl.NumberFormat(locale, { minimumFractionDigits }).format(value ?? 0);
}

/** This exists so then when the screen resizes, the column size gets properly re-adjusted */
export function hasCustomColumnSize<TData extends RowData>(
  columnId: string,
  columns: ColumnDef<TData>[]
) {
  const column = columns.find((c) => c.id === columnId);
  return column?.size != null;
}

export const kebabCase = (str: string) => {
  const re = /([0-9]+|([A-Z][a-z]+)|[a-z]+|([A-Z]+)(?![a-z]))/g;
  return (String(str ?? '').match(re) || []).map((x) => x.toLowerCase()).join('-');
};
