import styled, { css } from 'styled-components';

import { BodyXSmallMedium, Colour, Shadow } from '@xemplo/style-constants';

export const Arrow = css`
  content: '';
  background: ${Colour.Gray[800]};
  display: block;
  position: absolute;
  width: 20px;
  height: 8px;
  clip-path: path(
    'M20 8.74228e-07C15 6.55671e-07 13.125 6 10 6C6.875 6 5 2.18557e-07 0 0L20 8.74228e-07Z'
  );
`;

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ArrowStyles = css`
  &:after {
    ${Arrow};
  }

  &.is-axis-top {
    &:after {
      top: 100%;
      left: 50%;
      margin-left: -10px;
      transform: none;
    }
  }
  &.is-axis-bottom {
    &:after {
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      transform: rotate(180deg);
    }
  }
  &.is-axis-left {
    &:after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      margin-left: -6px;
      transform: rotate(270deg);
    }
  }
  &.is-axis-right {
    &:after {
      top: 50%;
      right: 100%;
      margin-top: -5px;
      margin-right: -6px;
      transform: rotate(90deg);
    }
  }

  &.is-position-TopLeft {
    &:after {
      right: 8px;
      left: auto;
      margin: 0;
    }
  }
  &.is-position-TopRight {
    &:after {
      left: 8px;
      right: auto;
      margin: 0;
    }
  }
  &.is-position-BottomLeft {
    &:after {
      right: 8px;
      left: auto;
      margin: 0;
    }
  }
  &.is-position-BottomRight {
    &:after {
      left: 8px;
      right: auto;
      margin: 0;
    }
  }
`;
export const Message = styled.div`
  ${BodyXSmallMedium};
  ${Shadow.Medium1};
  color: ${Colour.White[100]};
  background: ${Colour.Gray[800]};
  padding: 6px 10px;
  border-radius: 6px;
  position: relative;
  max-width: 272px;
  transition: all 0.1s ease-out;
  ${ArrowStyles};
`;
