import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

type CreateButtonProps = {
  name?: string;
  claims?: any;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
  hasNoComponents?: boolean;
  addModuleName?: string;
  disableCreateButton?: boolean;
  ignoreStatus?: boolean;
};

const classNameDefault = "";

const CreateSimpleButton = ({
  name,
  claims,
  className = classNameDefault,
  loading,
  onClick,
  hasNoComponents,
  addModuleName,
  disableCreateButton,
  ignoreStatus,
}: CreateButtonProps) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: !hasNoComponents,
  });
  return (
    <Button
      type="primary"
      className={`${className} ${
        ignoreStatus ? "" : claimAndStatus.status
      } rounded-[20px]`}
      loading={loading}
      onClick={onClick}
      disabled={
        claimAndStatus.status === "Visible-Disabled" || disableCreateButton
          ? true
          : false
      }
      icon={<PlusCircleOutlined />}
    >
      {`New ${addModuleName}`}
    </Button>
  );
};

export default CreateSimpleButton;
