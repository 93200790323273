import styled from 'styled-components';

import {
  BodySmallMedium,
  BodySmallSemiBold,
  Colour,
  MinBreakpoint,
} from '@xemplo/style-constants';

import { DefaultItemCounter } from '../item-counter';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px 24px;
  margin-top: auto;
`;

/**
 * The DefaultItemCounter has generic props. Due to the way styled-components works,
 * we need to redeclare it with the correct props.
 *
 * See https://github.com/styled-components/styled-components/issues/1803
 *
 * NOTE: when using @container, we also need to set the container-type to the parent of
 * the element that will have the query. A few of things to rememember is:
 * - define the container-type in the parent element
 * - define the container-name in the parent element
 * - defining the container name avoids issues querying to the incorrect element
 *   specially if the document contains multiple containers, which will likely be the case.
 * - It seems to take into account the width of the parent element, so if
 *   the parent element has paddings, it doesn't seems to work as expected.
 */
export const ItemCounter = styled(DefaultItemCounter)`
  ${BodySmallMedium};
  color: ${Colour.Gray[400]};
  position: absolute;
  left: 24px;
  display: none;

  @container table-container ${MinBreakpoint.medium} {
    display: block;
  }
` as typeof DefaultItemCounter;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > span {
    ${BodySmallSemiBold};
    color: ${Colour.Gray[700]};
    min-width: 96px;
    text-align: center;
    flex-shrink: 0;
  }
`;

export const PageSizeSelector = styled.div`
  align-items: center;
  gap: 16px;
  position: absolute;
  right: 24px;
  display: none;

  @container table-container ${MinBreakpoint.medium} {
    display: flex;
  }
`;
