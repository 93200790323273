//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import { GoBackIcon } from "../../Common/customicons";
import { colors } from "../../constants/colors";
import BreadCrumbContainer from "../../Common/ClaimContainers/BreadCrumbContainer";
import PayrunNavbarOptions from "../../Organization/components/common/Header/payrunNavbarView";
import { If } from "../../Common/ui";
import { withRouter } from "../../hooks";

const payrunRouteComponentName = "payrunBreadCrumb";

type State = any;
class NavbarDetail extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      header: {},
    };
  }

  onActionClicked = () => {
    switch (this.props.header?.module) {
      case "Company":
        this.props.router.navigate("/company");
        break;
      case "Business Unit":
        this.props.router.navigate("/businessunit");
        break;
      case "Billing Service Provider":
        this.props.router.navigate("/billingserviceproviders");
        break;
      case "Payroll Provider":
        this.props.router.navigate("/payrollprovider");
        break;
      case "Pay Run":
        this.props.router.navigate("/payrun");
        break;
      case "Pay Schedule":
        this.props.router.navigate("/payschedules");
        break;
      default:
        console.log("unrecognized ");
    }
  };

  backHistory = () => {
    const { header } = this.props;
    if (header && header.returnUrl) {
      return this.props.router.navigate(header.returnUrl);
    }
    this.props.router.navigate(-1);
  };

  render() {
    const {
      title,
      enableBack,
      routesContainer,
      routeComponentName,
      headerClassname,
    } = this.props.header;
    const { member, payrun, hasPayrunDetailLoaded } = this.props;
    return (
      <React.Fragment>
        <div
          className={"flex justify-between mb-6 navbar-detail w-full items-end"}
        >
          <div className="flex flex-1-1-56">
            {enableBack && (
              <span
                className="hand mr-[5px] color-primary leading-6"
                onClick={() => this.backHistory()}
              >
                <GoBackIcon
                  height={8}
                  fill={colors.primary}
                  className="color-primary"
                />
              </span>
            )}
            <div>
              <BreadCrumbContainer
                claims={routesContainer}
                name={routeComponentName}
                member={member}
                className="text-base"
              />
              <div
                className={`text-[32px] font-medium color-dark ${headerClassname}`}
              >
                {title}
              </div>
            </div>
          </div>
          <If
            condition={
              routeComponentName === payrunRouteComponentName &&
              window?.location?.pathname &&
              !window.location.pathname.includes(["detail", "auditReport"]) &&
              payrun
            }
            then={
              <div className="flex-1-1-44">
                <If
                  condition={hasPayrunDetailLoaded}
                  then={<PayrunNavbarOptions payrun={payrun} member={member} />}
                />
              </div>
            }
            else={<React.Fragment />}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    member: state.member,
    header: state.pageHeader.header,
    payrun: state.payrun && state.payrun.detail,
    hasPayrunDetailLoaded: state.payrun.payrunDetailLoaded,
  };
}

export default withRouter(connect(mapStateToProps, null)(NavbarDetail));
