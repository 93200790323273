import { useEffect, useState } from "react";
import {
  AMENDMENT_INTEGRATION_SETTING_SUCCESS,
  DEFAULT_GUID,
  PAYRUN_INTEGRATION_SETTING_SUCCESS,
  REPORTS_INTEGRATION_SETTING_SUCCESS,
} from "../../../../constants";
import { useAppDispatch } from "../../../../hooks";
import { handleRequest, mustBeArray } from "../../../../libs";
import {
  getIntegrationSettings,
  updateIntegrationSettings,
} from "../../../actions/businessunits";
import IntegrationSettingsUI from "./IntegrationSettingsUI";

export type BusinessUnitIntegrationSettings = {
  businessUnitId: string;
  id: string;
  isEnabled: boolean;
  isIntegrated: boolean;
  payrollConnectionId: number;
  payrollServiceProviderId: string;
  settingName: string;
};

export type IntegrationSetttingState = {
  payrun: BusinessUnitIntegrationSettings | null;
  amendment: BusinessUnitIntegrationSettings | null;
  keypayreport: BusinessUnitIntegrationSettings | null;
};

export type SwitchLoadingState = {
  payrun: boolean;
  amendment: boolean;
  keypayreport: boolean;
};

type IntegrationSettingsProps = {
  claims: any;
  businessUnitId: string;
};

type Payload = {
  businessUnitId: string;
  id?: string;
  settingName: "payrun" | "amendment" | "keypayreport";
  isIntegrated: boolean;
  apikey?: string;
};

const IntegrationSettings = (props: IntegrationSettingsProps) => {
  const { claims, businessUnitId } = props;
  const [integrationSettings, setIntegrationSettings] =
    useState<IntegrationSetttingState>({
      payrun: null,
      amendment: null,
      keypayreport: null,
    });
  const [switchLoading, setSwitchLoading] = useState<SwitchLoadingState>({
    payrun: false,
    amendment: false,
    keypayreport: false,
  });
  const [isIntegrationSettingsLoading, setIsIntegrationSettingsLoading] =
    useState(false);
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    handleIntegrationSettings();
  }, []);

  /** On initial load get the current integration settings for the business unit */
  const handleIntegrationSettings = () => {
    setIsIntegrationSettingsLoading(true);
    dispatch(getIntegrationSettings(businessUnitId)).then((res: any) => {
      setIsIntegrationSettingsLoading(false);
      if (handleRequest({ response: res }))
        mustBeArray(res.data.result).forEach((data) => {
          setIntegrationSettings((prev) => ({
            ...prev,
            [data.settingName]: data,
          }));
        });
    });
  };

  /** Update the integration settings for the business unit */
  const handleUpdateIntegrationSettings = ({
    value,
    type,
  }: {
    value: boolean;
    type: "payrun" | "amendment" | "keypayreport";
  }) => {
    const successMessage = {
      payrun: PAYRUN_INTEGRATION_SETTING_SUCCESS,
      amendment: AMENDMENT_INTEGRATION_SETTING_SUCCESS,
      keypayreport: REPORTS_INTEGRATION_SETTING_SUCCESS,
    };

    const payload: Payload = {
      businessUnitId: businessUnitId,
      id:
        integrationSettings[type]?.id !== DEFAULT_GUID
          ? integrationSettings[type]?.id
          : DEFAULT_GUID,
      settingName: type,
      isIntegrated: value,
    };
    setSwitchLoading((prev) => ({ ...prev, [type]: true }));
    // Send the request to update the integration settings
    dispatch(updateIntegrationSettings(payload)).then((res: any) => {
      setSwitchLoading((prev) => ({ ...prev, [type]: false }));
      if (
        handleRequest({ response: res, successMessage: successMessage[type] })
      )
        setIntegrationSettings((prev) => ({
          ...prev,
          [type]: { ...prev[type], isIntegrated: value },
        }));
    });
    return;
  };

  return (
    <IntegrationSettingsUI
      claims={claims}
      isLoading={isIntegrationSettingsLoading}
      handleChangeSwitch={handleUpdateIntegrationSettings}
      integrationSettings={integrationSettings}
      switchLoading={switchLoading}
    />
  );
};

export default IntegrationSettings;
