import { useMemo } from 'react';

import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonType,
  ButtonWithState,
  ButtonWithStateProps,
} from '@xemplo/button';
import { IconButtonSize } from '@xemplo/icon-button';
import { DawnArrowLongLeft16 } from '@xemplo/icons';

import { DrawerHeaderProps, DrawerMode } from '../drawer.types';
import { useDrawer } from '../hooks/use-drawer';

import * as S from './drawer-header.styles';

export const DrawerHeaderTestId = {
  standardWrapper: 'drawer-header-standard-wrapper',
  editorWrapper: 'drawer-header-editor-wrapper',
  title: 'drawer-header-title',
  primaryBtn: 'drawer-header-primary-btn',
  secondaryBtn: 'drawer-header-secondary-btn',
};

export const DrawerHeader = (props: DrawerHeaderProps) => {
  const { drawerState } = useDrawer();

  return drawerState.config.mode === DrawerMode.Standard ? (
    <StandardHeader {...props} />
  ) : (
    <EditorHeader {...props} />
  );
};

function StandardHeader({ hasBackButton, title, backButtonClick }: DrawerHeaderProps) {
  return (
    <S.DrawerHeaderContent data-testid={DrawerHeaderTestId.standardWrapper}>
      {hasBackButton && (
        <S.BackButton
          id="drawer-back-btn"
          ariaLabel="Back button on drawer"
          size={IconButtonSize.Small}
          naked
          onClick={backButtonClick}
        >
          <DawnArrowLongLeft16 />
        </S.BackButton>
      )}
      {title && <S.Title data-testid={DrawerHeaderTestId.title}>{title}</S.Title>}
    </S.DrawerHeaderContent>
  );
}

/**
 * We purposefully don't allow the user to override some of these props
 * in order to ensure design specs.
 */
function EditorHeader({
  hasBackButton,
  title,
  primaryBtn,
  secondaryBtn,
  backButtonClick,
}: DrawerHeaderProps) {
  const primaryBtnProps = useMemo(() => {
    return {
      ...primaryBtn,
      size: ButtonSize.Small,
      type: ButtonType.Primary,
      id: 'drawer-header-primary-btn',
      ariaLabel: 'Primary button on drawer header',
    } as ButtonWithStateProps;
  }, [primaryBtn]);

  const secondaryBtnProps = useMemo(() => {
    return {
      ...secondaryBtn,
      size: ButtonSize.Small,
      type: ButtonType.Tertiary,
      id: 'drawer-header-primary-btn',
      ariaLabel: 'Primary button on drawer header',
    } as ButtonProps;
  }, [secondaryBtn]);

  return (
    <S.DrawerHeaderContent data-testid={DrawerHeaderTestId.editorWrapper}>
      {hasBackButton && (
        <S.BackButton
          id="drawer-back-btn"
          ariaLabel="Back button on drawer"
          size={IconButtonSize.Small}
          naked
          onClick={backButtonClick}
        >
          <DawnArrowLongLeft16 />
        </S.BackButton>
      )}
      {title && <S.Title data-testid={DrawerHeaderTestId.title}>{title}</S.Title>}
      {primaryBtn != null || secondaryBtn != null ? (
        <S.EditorBtnWrapper>
          {secondaryBtn && (
            <Button {...secondaryBtnProps} testId={DrawerHeaderTestId.secondaryBtn}>
              {secondaryBtn.label}
            </Button>
          )}
          {primaryBtn && (
            <ButtonWithState {...primaryBtnProps} testId={DrawerHeaderTestId.primaryBtn}>
              {primaryBtn.label}
            </ButtonWithState>
          )}
        </S.EditorBtnWrapper>
      ) : null}
    </S.DrawerHeaderContent>
  );
}
