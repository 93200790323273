import { SVGProps } from 'react';
const DawnTick24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.474 4.419a.75.75 0 0 1 .107 1.055l-11.429 14a.75.75 0 0 1-1.162 0l-4.571-5.6a.75.75 0 0 1 1.162-.948l3.99 4.888L19.42 4.526a.75.75 0 0 1 1.055-.107Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnTick24;
