//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listPayrollProviders,
  getPayrollProvider,
  payrollProviderLookup,
  updatePayrollServiceProvider,
  listPayrollProviderBusinessUnit,
  payrollProviderUploadLogo,
} from "../actions/payrollprovider";
import {
  fetchPayrollConnectionName,
  addPayrollConnection,
  listPSPUsers,
  registerPSPUsers,
  handlePSPUserActions,
  updatePSPIntegrationSettings,
} from "../actions/payrollprocessor";

import {
  listCompanyUsers,
  listCompanyBusinessUnits,
  getCompany,
  companyLookup,
  handleEmailTemplatesActions,
  handleCompanyUserActions,
} from "../actions/companies";
import {
  saveBusinessUnit,
  businessunitHandler,
} from "../actions/businessunits";
import {
  listUserRoles,
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { findAuthorizationClaims } from "../../libs/utilities";
import { PAYROLL_PROVIDER_BREAD_CRUMB_CONTAINER } from "../../constants";
import { withRouter } from "../../hooks";

class PayrollProviderDetail extends SwiftContainer {
  signal: any;
  componentDidMount() {
    this.props.updateHeader({
      header: {
        routeComponentName: "payrollProviderBreadCrumb",
        routesContainer: findAuthorizationClaims({
          claims: this.state.pageClaims?.components,
          name: PAYROLL_PROVIDER_BREAD_CRUMB_CONTAINER,
        }),
      },
    });
  }
  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        userMode={this.props.userMode}
        registerPSPUsers={this.props.registerPSPUsers}
        listPSPUsers={this.props.listPSPUsers}
        pspUserDetails={this.props.pspUserDetails}
        pspUsersCount={this.props.pspUsersCount}
        pspUsersList={this.props.pspUsersList}
        payrollprovider={this.props.payrollprovider}
        businessunits={this.props.businessunit}
        totalPagesBusinessUnit={this.props.totalPagesBusinessUnit}
        listCompanyBusinessUnits={this.props.listCompanyBusinessUnits}
        getPayrollProvider={(data: any) =>
          this.executeAction(this.props.getPayrollProvider, data)
        }
        getCompany={(data: any) =>
          this.executeAction(this.props.getCompany, data)
        }
        loading={this.state.loading}
        listCompanyUsers={(data: any) =>
          this.executeAction(this.props.listCompanyUsers, data)
        }
        listBusinessUnits={(data: any) =>
          this.executeAction(this.props.listPayrollProviderBusinessUnit, data)
        }
        saveBusinessUnit={(data: any) =>
          this.executeAction(this.props.saveBusinessUnit, data)
        }
        updatePayrollServiceProvider={(data: any) =>
          this.executeAction(this.props.updatePayrollServiceProvider, data)
        }
        payrollProviderLookup={this.props.payrollProviderLookup}
        companyLookup={this.props.companyLookup}
        listPayrollProviders={this.props.listPayrollProviders}
        fetchPayrollConnectionName={(data: any) =>
          this.executeAction(this.props.fetchPayrollConnectionName, data)
        }
        addPayrollConnection={(data: any) =>
          this.executeAction(this.props.addPayrollConnection, data)
        }
        match={{ params: this.props.router.params }}
        signal={this.signal}
        connectionsList={this.props.connectionsList}
        handlePSPUserActions={this.props.handlePSPUserActions}
        listUserRoles={this.props.listUserRoles}
        handleEmailTemplatesActions={this.props.handleEmailTemplatesActions}
        pageClaims={pageClaims}
        member={this.props.member}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        handleCompanyUserActions={this.props.handleCompanyUserActions}
        payrollProviderUploadLogo={this.props.payrollProviderUploadLogo}
        businessunitHandler={this.props.businessunitHandler}
        updatePSPIntegrationSettings={this.props.updatePSPIntegrationSettings}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payrollprovider: state.payrollProvider.detail || {},
  totalPagesBusinessUnit: state.businessUnit.total,
  businessunit: state.businessUnit.list || [],
  connectionsList: state.payrollProvider.connectionsList || [],
  pspUsersList: state.payrollProvider.users && state.payrollProvider.users.list,

  pspUsersCount:
    state.payrollProvider.users && state.payrollProvider.users.count,

  pspUserDetails: state.payrollProvider.userDetails,
  userMode: "",
  member: state.member,
});

const mapDispatchToProps = {
  listPayrollProviders,
  getPayrollProvider,
  listCompanyUsers,
  listPSPUsers,
  listPayrollProviderBusinessUnit,
  updatePayrollServiceProvider,
  payrollProviderLookup,
  getCompany,
  companyLookup,
  saveBusinessUnit,
  fetchPayrollConnectionName,
  addPayrollConnection,
  registerPSPUsers,
  listCompanyBusinessUnits,
  handlePSPUserActions,
  listUserRoles,
  handleEmailTemplatesActions,
  updateHeader,
  updateAuthorizationClaim,
  handleCompanyUserActions,
  payrollProviderUploadLogo,
  businessunitHandler,
  updatePSPIntegrationSettings,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayrollProviderDetail)
);
