export type Payrun = {
  payrunId: string;
  companyID: string;
  businessUnitID: string;
  payScheduleID: string;
  name: string;
  companyName: string;
  businessUnitName: string;
  payDate: string;
  generatedDateTime: string;
  ammendmentDueDateTime: string;
  preparationDueDateTime: string;
  approvalDueDateTime: string;
  payOccurrenceName: string;
  paymentDueDateTime: string;
  bankFileUploadDueDateTime: string;
  bankFileCreatedDate: string;
  bankFileURL: string | null;
  isUploadBankFile: boolean;
  isBankFileDatePayDay: boolean;
  bankFileDateInDays: number;
  payCycleStartDate: string;
  payCycleEndDate: string;
  payrunTypeID: number;
  payrunTypeName: string;
  payrunStatusID: number;
  payrunStatusName: string;
  ammendmentCount: number;
  isActive: boolean;
  reference: string | null;
  rejectionReasonID: number;
  rejectDescription: string | null;
  isRejected: boolean;
  payrollModelId: number;
  payrollModelName: string;
  payrollSystemConfigurationID: number;
  payrollSystemConnectionId: number;
  externalBusinessUnitId: number;
  externalPayScheduleId: number;
  description?: string | null;
  processingFees: ProcessingFee[];
  isInvoiceNeeded: boolean;
  externalPayRunId: number;
  externalPayRunName: string;
  basePayMapping: BasePayMapping[];
  auditReportGenerated: boolean;
  auditReportPdfUrl: string | null;
  auditReportCsvUrl: string | null;
  lastActivityDate: string;
};

export interface PayrunDetail {
  payrunId: string;
  companyID: string;
  businessUnitID: string;
  payScheduleID: string;
  name: string;
  companyName: string;
  businessUnitName: string;
  payDate: Date;
  generatedDateTime: Date;
  ammendmentDueDateTime: Date;
  preparationDueDateTime: Date;
  approvalDueDateTime: Date;
  payOccurrenceName: null;
  paymentDueDateTime: Date;
  bankFileUploadDueDateTime: Date;
  bankFileCreatedDate: Date;
  bankFileURL: null;
  isUploadBankFile: boolean;
  isBankFileDatePayDay: boolean;
  bankFileDateInDays: number;
  payCycleStartDate: Date;
  payCycleEndDate: Date;
  payrunTypeID: number;
  payrunTypeName: string;
  payrunStatusID: number;
  payrunStatusName: string;
  ammendmentCount: number;
  isActive: boolean;
  reference: string;
  rejectionReasonID: number;
  rejectDescription: null;
  isRejected: boolean;
  payrollModelId: number;
  payrollModelName: string;
  payrollSystemConfigurationID: number;
  payrollSystemConnectionId: number;
  externalBusinessUnitId: string;
  externalPayScheduleId: string;
  description: string;
  processingFees: ProcessingFee[];
  isInvoiceNeeded: boolean;
  externalPayRunId: string;
  externalPayRunName: string;
  basePayMapping: BasePayMapping[];
  auditReportGenerated: boolean;
  auditReportPdfUrl: null;
  auditReportCsvUrl: null;
  lastActivityDate: Date;
}

export interface BasePayMapping {
  id: number;
  name: string;
}

export interface ProcessingFee {
  id: number;
  feeOptionId: number;
  rateOrPercentage: number;
  payRunId: string;
  invoiceDescription: string;
  taxFeeId: number;
  isActive: boolean;
  basePay: boolean;
  processingFeePayItem: ProcessingFeeExternalExpenseCategoryElement[];
  processingFeeEmployeeNumberRange: ProcessingFeeEmployeeNumberRange[];
  processingFeeStateRate: ProcessingFeeStateRate[];
  processingFeeExternalPayCategory: ProcessingFeeExternalExpenseCategoryElement[];
  processingFeeExternalExpenseCategory: ProcessingFeeExternalExpenseCategoryElement[];
  feeOption: string;
  taxFeeName: string;
}

export interface ProcessingFeeEmployeeNumberRange {
  id: number;
  from: number;
  to: number;
  rate: number;
}

export interface ProcessingFeeExternalExpenseCategoryElement {
  id: number;
  name: string;
  checked: boolean;
  externalExpenseCategoryId?: number;
  externalPayCategoryId?: number;
  payItemId?: number;
}

export interface ProcessingFeeStateRate {
  id: number;
  stateId: number;
  stateName: string;
  rate: number;
}

export enum PayrunTypes {
  SCHEDULED = 1,
  ADHOC = 2,
}

export interface PayrunMilestone {
  amendmentDueDateTime: Date | null;
  approvalDueDateTime: Date | null;
  id: string;
  name: string;
  payCycleEndDate: Date | null;
  payCycleStartDate: Date | null;
  payDate: Date | null;
  paymentDueDateTime: Date | null;
  preparationDueDateTime: Date | null;
  payrunStatusID: number;
  isInvoiceNeeded: boolean;
}

export interface PayrunHeader {
  id: string;
  name: string;
  businessUnitId: string;
  businessUnitName: string;
  payScheduleId: string;
  payOccurenceName: string | null;
  payCycleStartDate: Date;
  payCycleEndDate: Date;
  payDate: Date;
  payrunStatusId: number;
  payrunStatusName: string;
  payrunTypeId: number;
  payrunTypeName: string;
  countryId: number;
}
export interface PayrunSummary {
  payrunId: string;
  externalPayRunId: string;
  current: PayrunSummaryItem;
  previous: PayrunSummaryItem | null;
}

export interface PayrunSummaryItem {
  grossPay: number;
  netPay: number;
  totalHours: number;
  superannuation: number;
  totalWithheld: number;
  totalExpenses: number;
}

export interface PayrunChangeLogItem {
  changeStatusDateTime: Date;
  details: string;
  id: number;
  payRunStatusID: number;
  payRunStatusName: string;
  userInfo: string;
}

/**
 * NOTE: The prop names are capitalised because the data comeing from the API
 * is a string JSON. The prop names are capitalised in the JSON.
 * In order for us to keep it simply, we are just replicating the same.
 */
export interface PayrunChangeLogItemDetails {
  Reason: string;
  ErrorCategory: string;
}

/**
 * NOTE: The prop names are capitalised because the data comeing from the API
 * is a string JSON. The prop names are capitalised in the JSON.
 * In order for us to keep it simply, we are just replicating the same.
 */
export interface PayrunChangeLogUserInfo {
  FirstName: string;
  LastName: string;
  UserName: string;
}

export type PayrunEarningLine = {
  payrunId: string;
  externalPayrunId: string;
  externalEmployeeId: string;
  externalEmployeeName: string;
  grossPay: number;
  netPay: number;
  totalTax: number;
  totalWithheld: number;
  superAnnuationEmployerContribution: number;
  superAnnuationEmployeeContribution: number;
  activeAmendmentsCount: number | null;
  variance: number | null;
  previousPayrunId: string | null;
  payscheduleId: string | null;
  warningType: string | null;
  warningDetails: string | null;
};

//PAYRUN_STATUS_ID
export enum PAYRUN_STATUS_IDS {
  GENERATED = 1,
  DETAILS_VIEWED = 2,
  AMENDMENTS_ADDED = 3,
  AMENDEMENTS_SUBMITTED_SYSTEM_TRIGGERED = 4,
  AMENDEMENTS_SUBMITTED_USER_TRIGGERED = 5,
  CONFIRMED_BY_PAYROLL_ADMIN_SYSTEM_TRIGGERED = 6,
  CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED = 7,
  PREPARED_SYSTEM_TRIGGERED = 8,
  PREPARED_USER_TRIGGERED = 9,
  APPROVED_SYSTEM_TRIGGERED = 10,
  APPROVED_USER_TRIGGERED = 11,
  INVOICE_GENERATED_SYSTEM_TRIGGERED = 12,
  INVOICE_GENERATED_USER_TRIGGERED = 13,
  PAYMENT_PENDING_APPROVAL = 14,
  PAYMENT_CONFIRMED = 15,
  PROCESSING = 16,
  PROCESSED = 17,
  COMPLETED = 18,
  IMPORTING_EARNING_LINES_SYSTEM_TRIGGERED = 19,
  IMPORTING_EARNING_LINES_USER_TRIGGERED = 20,
  IMPORTED_EARNING_LINES_SYSTEM_TRIGGERED = 21,
  IMPORTED_EARNING_LINES_USER_TRIGGERED = 22,
  DRAFT_SYSTEM_TRIGGERED = 23,
  DRAFT_USER_TRIGGERED = 24,
}

export enum PayrunSteps {
  Submit = 'Submit',
  Prepare = 'Prepare',
  Review = 'Review',
  Pending = 'Pending',
  Payment = 'Payment',
  Process = 'Process',
  Complete = 'Complete',
}

/**
 * PEO support is priority, outsources payruns with IsInvoiceNeeded = false shouldn't exist in the current flow but in the off chance they do this will support it.
 * BE will refactor the way it progresses payrun steps so this won't be needed in the future.
 * Payruns with InvoiceNeeded = false will skip steps [10, 11, 12, 13] (all the pending/payment steps)
 */
export const StatusToStepMap = (isInvoiceNeeded: boolean) =>
  isInvoiceNeeded
    ? //isInvoiceNeeded = true
      {
        [PayrunSteps.Submit]: [1, 2, 3],
        [PayrunSteps.Prepare]: [4, 5, 6, 7, 19, 20, 21, 22],
        [PayrunSteps.Review]: [8, 9],
        [PayrunSteps.Pending]: [10, 11, 12, 13],
        [PayrunSteps.Payment]: [14],
        [PayrunSteps.Process]: [15, 16],
        [PayrunSteps.Complete]: [17],
      }
    : //isInvoiceNeeded = false
      {
        [PayrunSteps.Submit]: [1, 2, 3],
        [PayrunSteps.Prepare]: [4, 5, 6, 7, 19, 20, 21, 22],
        [PayrunSteps.Review]: [8, 9],
        [PayrunSteps.Pending]: [],
        [PayrunSteps.Payment]: [],
        [PayrunSteps.Process]: [15, 16, 10, 11, 12, 13, 14],
        [PayrunSteps.Complete]: [17],
      };

export type Step = {
  step: PayrunSteps;
  payrunStatusId: number;
  date: Date | null;
  active: boolean;
  completed: boolean;
};

export interface MappedPayrunSteps extends PayrunMilestone {
  steps: Step[];
  activeStep: Step | null;
}

export const PayrunsTableType = {
  Active: 'active',
  Complete: 'complete',
  SystemGenerated: 'systemgenerated',
} as const;

export type PayrunsTableType = (typeof PayrunsTableType)[keyof typeof PayrunsTableType];

export type PayrunTableFilterQuery = {
  payruntypeid?: string;
  statusID?: string;
  companyID?: string;
  businessUnitID?: string;
  assigneeId?: string;
  primaryOnly?: '1' | '0'; // 1 = true, 0 or missing = false
};
