//@ts-nocheck
import React, { Component, Fragment } from "react";
import { Button, Col, message, Modal, Row, Card } from "antd";
import {
  PayrunSteps,
  KeyPayLoadingModal,
  ToogleStaticContent,
} from "../../../../Common/payrun";
import ConversionFeesView from "../../../components/paySchedule/currencyConversionFeesView";
import _ from "lodash";
import AmendmentSummary from "../payrollprovider/amendmentSummary";
import ExternalPayrunList from "../payrollprovider/listExternalPayruns";
import AmendmentsModal from "../viewAmendmentsModal";
import ClaimWrapper from "../../../../Common/claimWrapper";
import {
  mustBeArray,
  delay,
  findAuthorizationClaims,
  getUserArea,
  errorHandler,
  getMatchParams,
  getOptionalParameter,
  isComponentVisible,
} from "../../../../libs/utilities";
import MissingPayCategory from "../missingPayCategory";
import EmployeeListDifference from "../employeeListDifference";
import {
  MISSING_EMPLOYEE_ERROR_CODE,
  INVALID_KEYPAY_CONNECTION_CODE,
} from "../../../../constants/errors";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import APIHandler from "../../../../constants/apiUrl";
import userRoles from "../../../../constants/userRoles";
import ClientPrepareView from "./clientPrepareView";
import {
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  SINGLE_LINE_GRID_VALUE,
} from "../../../../constants/defaultClaims";
import AmendmentDetails from "../amendmentDetails";
import ShowVariance from "../showVariance";
import {
  ADHOC_PAYRUN_ID,
  AMENDMENT_APPROVED_ONLY_STATUS,
  AMENDMENT_DECLINED_STATUS,
  PAID_PAYRUN_STATUS,
  PEO_MODEL_ID,
  RESOLVED_AMENDMENTS_IDS,
  UNRESOLVED_AMENDMENTS_IDS,
  KEYPAY_AMENDMENT_SUCCESS,
  KEYPAY_AMENDMENT_FAILED,
  PAYRUN_EXTERNAL_LIST_CONTAINER,
  PAYRUN_EARNINGLINES_CLEAR_BUTTON,
  PAYRUN_PREPARE_BUTTON,
  PAYRUN_CURRENCY_CONVERSION_FEES,
  PAYRUN_ATTACH_AMENDMENT_CONTAINER,
  PAYRUN_AMENDMENT_LIST_CONTAINER,
  PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER,
  MAX_AMENDMENT_PER_PAGE,
  DEFAULT_ROUTES,
  PAYRUN_REPORT_UPLOAD_BUTTON,
} from "../../../../constants";
import SimpleButton from "../../../../Common/ClaimComponents/Button/SimpleButton";
import AddAmendment from "../addAmendment";
import axios from "axios";
import classNames from "classnames";
import AmendmentDeclineReason from "../amendmentDeclineReason";
import {
  isComponentVisibleEnabled,
  showExternalEarninglines,
  showVariance,
  isCompanyUser,
  amendmentHasQuestionSection,
  allAmendmentsAppliedToPayroll,
  amendmentsWillBeAppliedToPayroll,
  canAutoImportEarninglines,
  handleRequest,
  isPastDate,
  amendmentsAreIntegratedToPayroll,
  payrunIsSkipped,
} from "../../../../libs";
import { LoadingModal, If } from "../../../../Common/ui";
import AttachAmendmentModal from "../../../../Common/payrun/AttachAmendmentModal";
import { EarningLinesTable } from "../EarningLines";
import messages from "../../../../constants/message";
import { withRouter } from "../../../../hooks";
import { PayrunEmployeeList } from "@xemplo/payrun-employee-list";
import PayrunDownloadOptions from "../downloadOptions";
import UploadReportsButton from "../uploadReportsButton";
import { PAYRUN_STATUS_IDS } from "@xemplo/gp-types";
import InformationDisplayCard from "../../common/informationDisplaycard";
import { PayrunBeingPreparedIcon } from "../../../../Common/customicons";
import UploadReportsModal from "../uploadReportsModal";
import { ManagerInformationCardMessage } from "./information-card.const";

const confirm = Modal.confirm;
const APPLYING_TO_PAYROLL_TEXT =
  "Earning lines will be imported after the changes have been applied in payroll. This may take a few minutes, to check on the progress please see the Amendment section on the right of the page";
type State = any;

class PayrunPrepareDetail extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      visible: false,
      bu_loading: false,
      payruns: [],
      externalEarninglines: [],
      externalPayrun: null,
      loadingTable: true,
      payrunSelected: false,
      importing: true,
      summary: [],
      unresolvedAmendments: [],
      viewAmendmentModal: false,
      viewMissingCategory: false,
      processingFees: [],
      currencyFees: {},
      employeeList: [],
      externalPayrunData: {},
      viewLoadingModal: false,
      viewKeyPayLoadingModal: false,
      highLightVarianceValue: 5,
      loadingText: "",
      approvedRejectedAmendments: [],
      amendmentSubmitted: false,
      amendmentList: [],
      submitLoading: false,
      viewAddAmendment: false,
      refreshLoading: false,
      clearLoading: false,
      keypayIntergrationLoading: false,
      nextPayrunAmendmentList: [],
      viewAttachAmendmentModal: false,
      payrunAttachNextPayrunAmendmentModalClaim: {},
      attachLoading: false,
      applyingToPayrollText: null,
    };
  }
  componentWillUnmount() {
    this.setState({
      visible: false,
      bu_loading: false,
      payruns: [],
      defaultPayruns: [],
      externalEarninglines: [],
      externalPayrun: null,
      loadingTable: false,
      payrunSelected: false,
      importing: true,
      summary: [],
      unresolvedAmendments: [],
      viewAmendmentModal: false,
      currencyFees: {},
      employeeList: [],
      externalPayrunData: {},
      singleLineGridView: false,
      pageLoad: true,
      amendmentList: [],
      highLightVarianceValue: 5,
      showVariance: true,
      filterApplied: "",
      viewAddAmendment: false,
      viewKeyPayLoadingModal: false,
      viewLoadingModal: false,
      viewUploadReportsModal: false,
      payrunReports: [],
    });
    clearInterval(this.getAmendmentStatusInterval);
    clearInterval(this.getPayrunDetailsInterval);
  }

  updatePageHeader = ({ payrun }) => {
    this.props.updateHeader({
      header: {
        title: classNames("Pay Run : ", payrun?.name, {
          "(Importing Earninglines in progress)": [19, 20].includes(
            payrun?.payrunStatusID
          ),
        }),
        action: "",
        entity: "payrun",
        enableBack: true,
      },
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps?.payrun !== this.props.payrun) {
      this.updatePageHeader({ payrun: newProps?.payrun });
    }

    if (newProps.reimportEarninglines) {
      this.handleReimportEarnings();
    }
  }

  componentDidMount() {
    this.getFiles();
    this.updatePageHeader({ payrun: this.props.payrun });
    this.setState({
      processingFees: this.props.payrun?.processingFees,
    });
    // Get pay run details to check the status of the earninglines import
    this.getPayrunDetails(true);
    this.getAmendmentStatusInterval = undefined;
    this.getPayrunDetailsInterval = undefined;

    const { pageClaims } = this.props;
    // Different view for company users (single line grid view)
    if (isCompanyUser()) {
      const payrunAmendmentListContainer = mustBeArray(
        pageClaims?.components
      ).find((o) => o.name === PAYRUN_AMENDMENT_LIST_CONTAINER);
      this.getAmendmentList();
      if (
        mustBeArray(payrunAmendmentListContainer?.personalizations)?.[
          LIST_PERSONALIZATION_GRID_TABLE_INDEX
        ]?.value === SINGLE_LINE_GRID_VALUE
      ) {
        this.setState({ singleLineGridView: true }, () => {
          this.setState({ pageLoad: false });
        });
      }
    } else {
      const list = [...mustBeArray(this.props.amendments)];
      this.setState({
        amendmentList: [...list],
        pageLoad: false,
      });
    }
  }

  handleReimportEarnings = () => {
    this.props.updateReimportEarninglines(false);
    if (!this.props.payrun) return;
    const {
      payrunId,
      payrollSystemConnectionId,
      externalBusinessUnitId,
      externalPayRunId,
    } = this.props.payrun;
    this.setState({
      loadingTable: true,
      viewLoadingModal: true,
      loadingText: "Importing Earninglines in progress",
    });
    this.props
      .reimportEarnings({
        id: payrunId,
        values: {
          externalBusinessUnitId,
          externalPayRunId,
          payrollSystemConnectionID: payrollSystemConnectionId,
          payRunID: payrunId,
        },
      })
      .then((resp) => {
        if (!resp.status) {
          errorHandler({ response: resp, hasValidationErrors: true });
          return;
        }
        this.getExternalEarningLines();
      });
  };

  // validate external payruns for automation and check if all amendments have been applied to keypay
  // If not then start the interval check to get the updated amendment list
  validateAutomationOnPageLoad = () => {
    let list = [...this.props.amendments];
    const allSubmitted = this.checkIfAllAmendmentSubmitted({
      list,
    });
    const { match, payrun } = this.props;
    const id = getMatchParams({ match, field: "id" });
    // If all amendment have been submitted and there are amendments that haven't been applid to keypay
    // start the interval
    if (
      allSubmitted &&
      amendmentsWillBeAppliedToPayroll({
        //checking if amendment will be applied to payroll/keypay
        payrun: payrun,
        amendments: list,
      }) &&
      !allAmendmentsAppliedToPayroll({ list }) &&
      !isCompanyUser() &&
      payrun?.externalPayRunId
    ) {
      this.setState({
        applyingToPayrollText: APPLYING_TO_PAYROLL_TEXT,
      });
      this.initKeyPayIntervalCheck(id);
    }
  };

  // If there are no amendments or if all amendments have been actioned auto import earninglines after checking the integration settings
  importEarninglinesWhenNoAmendmentsAdded = () => {
    const list = [...mustBeArray(this.props.amendments)];
    // If all amendment submitted and applied in keypay - auto import earninglines
    if (
      (_.isEmpty(list) ||
        (this.allSubmitted(list) &&
          allAmendmentsAppliedToPayroll({ list }) &&
          this.canImportEarningLines({ data: list }))) &&
      this.props.payrun?.externalPayRunId
    ) {
      const selectedExternalPayrun = mustBeArray(this.state.payruns).find(
        (o) => o.id === Number(this.props.payrun.externalPayRunId)
      );
      this.importEarlinglines(selectedExternalPayrun, true);
    }
  };

  handleFilterChange = ({ label, value }) => {
    this.setState({ [label]: value });
  };

  checkIfVarianceIsEnabled = (payrun, processTableList) => {
    if (
      payrun?.payrunTypeID === ADHOC_PAYRUN_ID ||
      (payrun?.payrollModelId === PEO_MODEL_ID &&
        PAID_PAYRUN_STATUS.includes(Number(payrun?.payrunStatusID)))
    ) {
      this.setState({ showVariance: false });
    } else {
      let showVariance = false;
      mustBeArray(processTableList).forEach((list) => {
        if (mustBeArray(list?.payrunSummary)[1]) {
          showVariance = true;
        }
      });
      this.setState({ showVariance });
    }
  };

  getAmendmentListPure = (id) => {
    return this.props.listPayrunAmendments({
      options: { per_page: MAX_AMENDMENT_PER_PAGE },
      id,
    });
  };

  getAmendmentList = (amendmentSubmitted) => {
    const { match } = this.props;
    const id = getMatchParams({ match, field: "id" });

    this.getAmendmentListPure(id).then((resp) => {
      if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
        this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
        return;
      }
      const list = mustBeArray(resp?.data);
      const allSubmitted = this.allSubmitted(list);
      const allAppliedToPayroll = allAmendmentsAppliedToPayroll({ list });

      if (allSubmitted && !allAppliedToPayroll && !isCompanyUser()) {
        this.initKeyPayIntervalCheck(id);
      }

      this.setState({
        amendmentSummaryLoading: false,
        amendmentList: [...mustBeArray(list)],
        amendmentSubmitted: allSubmitted,
      });
      if (amendmentSubmitted) {
        this.getPayrunDetails(true);
      }
      // If all amendments have been actioned, auto import external earninglines after checking canimportearninglines
      if (
        amendmentSubmitted &&
        allAppliedToPayroll &&
        this.canImportEarningLines({ data: resp?.data })
      ) {
        this.initiateEarninglinesImport(id);
      }
    });
  };

  initiateEarninglinesImport = () => {
    // Find the payrun that has been auto created in keypay
    const selectedExternalPayrun = mustBeArray(this.state.payruns).find(
      (o) => o.id === Number(this.props.payrun?.externalPayRunId)
    );
    this.setState({
      viewKeyPayLoadingModal: false,
      applyingToPayrollText: null,
    });
    if (selectedExternalPayrun) {
      this.setState({
        viewLoadingModal: true,
      });
      this.importEarlinglines(selectedExternalPayrun, true);
    }
  };

  canImportEarningLines = ({ data }) => {
    // If there is rejected amendment and the amendment due date hasn't passed, then payrun will go back to amendment status.
    // No need to import earninglines in such situation

    const hasRejectedAmendments = mustBeArray(data).find(
      (o) => o.amendmentStatusID === AMENDMENT_DECLINED_STATUS
    );
    if (!hasRejectedAmendments) return true;
    return isPastDate({
      date: this.props.payrun?.ammendmentDueDateTime,
    });
  };

  handleAmendmentAction = ({ event, data, action }) => {
    event?.stopPropagation();
    if (action === "view") {
      this.viewDetails(data);
    }
  };

  displayApproveRejectModal = (amendments) => {
    if (this.userCanTakeActionOnAmendments()) {
      const unresolvedAmendments = amendments.some((o) =>
        UNRESOLVED_AMENDMENTS_IDS.includes(o.amendmentStatusID)
      );
      this.setState({
        viewAmendmentModal: unresolvedAmendments,
        amendmentSubmitted: !unresolvedAmendments,
      });
    } else {
      this.setState({ viewAmendmentModal: false });
    }
  };

  getExternalEarningLines = (pageLoad, refresh = false) => {
    this.props
      .fetchExternalEarnings({
        id: this.props.payrun?.payrunId,
        options: {},
        cancelToken: this.signal.token,
      })

      .then((response) => {
        this.setState({
          loadingTable: false,
          refreshLoading: false,
        });
        if (response.status && response.data) {
          this.setState({
            externalEarninglines: response.data,
            payrunSelected: true,
            importing: false,
            viewLoadingModal: false,
          });

          if (!refresh) {
            clearInterval(this.getPayrunDetailsInterval);
            this.checkIfVarianceIsEnabled(this.props.payrun, response.data);
            this.getCurrencyFees();
          }
        } else {
          if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
            errorHandler({ response, hasValidationErrors: true });
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
            return;
          }
          if (pageLoad) {
            this.setState({ payrunSelected: false, viewLoadingModal: false });
            this.getExternalPayruns({ payrun: this.props.payrun, pageLoad });

            // On PageLoad get next pay run amendment list and display modal if list is not empty
            this.getNextPayrunAmendments();
          }
        }
      });
  };

  // Get next payrun amendment list
  getNextPayrunAmendments = () => {
    // Check in the claims if user has access to include the next payrun amendments and then call the endpoint
    const { pageClaims } = this.props;
    const payrunAttachNextPayrunAmendmentModalClaim = mustBeArray(
      pageClaims?.components
    ).find((o) => o.name === PAYRUN_ATTACH_AMENDMENT_CONTAINER);
    this.setState({ payrunAttachNextPayrunAmendmentModalClaim });
    if (
      isComponentVisible({ claim: payrunAttachNextPayrunAmendmentModalClaim })
    ) {
      this.props
        .generalAmendmentHandler({
          action: "list",
          options: {
            per_page: MAX_AMENDMENT_PER_PAGE,
            page: 1,
            q: {
              businessUnitId: this.props.payrun?.businessUnitID,
              showComplete: false,
              showDraftOnly: true,
            },
          },
        })
        .then((resp: any) => {
          if (handleRequest({ response: resp, hasValidationErrors: true })) {
            this.setState(
              { nextPayrunAmendmentList: mustBeArray(resp?.data) },
              () => {
                // Check if we need to render the modal to attach next payrun amendments or check for unresolved amendments
                if (this.state.nextPayrunAmendmentList.length === 0) {
                  // No next payrun amendments present so check for unresolved amendments
                  return this.displayApproveRejectModal([
                    ...mustBeArray(this.props.amendments),
                  ]);
                }
                this.setState({ viewAttachAmendmentModal: true });
              }
            );
          }
        });
    } else {
      this.displayApproveRejectModal([...mustBeArray(this.props.amendments)]);
    }
  };

  getPayrunDetails = (pageLoad) => {
    const { match } = this.props;
    const id = getMatchParams({ match, field: "id" });

    this.props.getPayrun({ id, options: {} }).then((resp) => {
      if (resp.status && resp.data) {
        if (resp.data.payrunStatusID === 5 && !pageLoad) {
          message.error(
            "Failed to import the external earninglines. Please try again."
          );
          clearInterval(this.getPayrunDetailsInterval);
          return this.getExternalEarningLines(true);
        }
        this.updatePageHeader({ payrun: this.props.payrun });
        switch (resp.data.payrunStatusID) {
          case 19:
          case 20:
            clearInterval(this.getPayrunDetailsInterval);
            this.getPayrunDetailsInterval = setInterval(
              this.getPayrunDetails.bind(this),
              10000
            );

            this.setState({
              payrunSelected: true,
              externalEarninglines: [],
              viewLoadingModal: false,
            });
            break;
          case 21:
          case 22:
            this.setState({ payrunSelected: true, viewLoadingModal: false });
            this.getExternalEarningLines();
            clearInterval(this.getPayrunDetailsInterval);
            break;
          default:
            if (pageLoad) return this.getExternalEarningLines(true);
        }
      } else {
        errorHandler({ response: resp, hasValidationErrors: true });
        if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
          this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
        }
      }
    });
  };

  getCurrencyFees = () => {
    const id = this.props.payrun?.payrunId;
    if (id) {
      this.props
        .payrunHandler({
          action: "getCurrencyFees",
          id,
        })
        .then((resp) => {
          if (resp.status) {
            this.setState({ currencyFees: resp.data ?? {} }, () => {
              delay(100).then(() => {
                this.forceUpdate();
              });
            });
          } else {
            errorHandler({ response: resp, hasValidationErrors: true });
          }
        });
    }
  };

  updateCurrencyFees = ({ payload }) => {
    this.setState({ updateCurrencyLoading: true });

    const id = this.props.payrun?.payrunId;
    payload.payrunId = id;
    this.props
      .payrunHandler({
        action: "updateCurrencyFees",
        id,
        payload,
      })
      .then((resp) => {
        this.setState({ updateCurrencyLoading: false });

        if (resp.status) {
          this.setState({ currencyFees: resp.data });
          message.success("Currency fees updated successfully");

          this.getCurrencyFees();
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  preparePayrun = () => {
    this.setState({ bu_loading: true });

    this.props
      .confirmPayrun({ id: this.props.payrun?.payrunId })
      .then((resp) => {
        if (resp.status) {
          this.props

            .preparePayrun({ id: this.props.payrun?.payrunId })

            .then((response) => {
              this.setState({ bu_loading: false, viewMissingCategory: false });

              if (response.status) {
                message.success(messages["save.success"]);
                this.props.router.navigate("/payrun");
              } else {
                errorHandler({ response, hasValidationErrors: true });
              }
            });
        } else {
          this.setState({ bu_loading: false });
          errorHandler({ response: resp, hasValidationErrors: true });
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  showConfirm = (amendments = this.state.unresolvedAmendments) => {
    confirm({
      title:
        "There are some unresolved amendments. Do you want to approve all of them?",
      okText: "Yes",
      cancelText: "No",
      width: "700",
      onOk() {
        mustBeArray(amendments).forEach((amendment) => {
          this.handleApproveAmendment({
            id: amendment.amendmentId,
            options: { payrunID: amendment.payrunId },
          });
        });
      },
      onCancel() {
        console.log("");
      },
    });
  };

  cancelExternalPayrun = () => {
    this.setState({ clearLoading: true });
    this.props
      .clearExternalEarnings({ id: this.props.payrun?.payrunId })
      .then((response) => {
        this.setState({ clearLoading: false });
        if (response.status) {
          this.setState(
            {
              importing: true,
              payrunSelected: false,
              externalEarninglines: [],
            },
            () => {
              this.props
                .getPayrun({
                  id: this.props.payrun?.payrunId,
                  options: {},
                })
                .then((resp) => {
                  errorHandler({ response: resp, hasValidationErrors: true });
                  if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
                    this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
                  }
                });
              this.getExternalPayruns({ payrun: this.props.payrun });
            }
          );
        } else {
          this.setState({
            importing: false,
          });
          errorHandler({ response, hasValidationErrors: true });
          if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  importEarlinglines = (value, publishAll) => {
    this.setState({
      externalPayrun: value,
      viewLoadingModal: true,
      loadingText: "Importing Earninglines in progress",
    });
    const payrunId = this.props.payrun?.payrunId;
    let values = {
      externalPayRunId: value?.id,
      payrollSystemConnectionID: this.props.payrun?.payrollSystemConnectionId,
      externalBusinessUnitId: this.props.payrun?.externalBusinessUnitId,
      payRunID: payrunId,
    };
    // if publish all

    this.setState({ externalPayrunData: values }, () => {
      let options = publishAll ? { q: { payPublish: 1 } } : {};
      this.fetchEarningLines({ options });
    });
  };

  fetchEarningLines = ({ options }) => {
    const payrunId = this.props.payrun?.payrunId;
    this.props
      .postExternalEarnings({
        id: payrunId,
        values: this.state.externalPayrunData,
        options,
      })

      .then((resp) => {
        this.setState({ refreshLoading: false });
        if (resp.status) {
          this.getPayrunDetailsInterval = setInterval(
            this.getPayrunDetails.bind(this),
            10000
          );
        } else {
          this.setState({ viewLoadingModal: false });
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            errorHandler({ response: resp, hasValidationErrors: true });
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
            return;
          }
          let error = mustBeArray(resp?.data?.validationErrors?.errors)[0];
          switch (error?.code) {
            case MISSING_EMPLOYEE_ERROR_CODE:
              this.props
                .payrunHandler({
                  id: payrunId,
                  payload: this.state.externalPayrunData,
                  action: "listEmployeesNotInPayschedule",
                })
                .then((response) => {
                  this.setState({ importing: false });

                  if (response.status) {
                    this.setState(
                      { employeeList: mustBeArray(response.data) },
                      () => {
                        this.setState({
                          viewEmployeeListDifference: true,
                          externalPayrunData: this.state.externalPayrunData,
                        });
                      }
                    );
                  } else {
                    errorHandler({ response, hasValidationErrors: true });
                  }
                });
              break;
            case INVALID_KEYPAY_CONNECTION_CODE:
              this.setState({ importing: false });
              message.error(
                "Keypay authentication failed. Please try again later or contact systemadmin."
              );
              break;
            default:
              errorHandler({ response: resp, hasValidationErrors: true });
              if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
                this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
              }
          }
        }
      });
  };

  checkImportEarnings = (value, publishAll) => {
    const amendments = structuredClone(this.props.amendments);

    // Check if all amendments have been submitted
    if (!this.checkIfAllAmendmentSubmitted({ list: amendments })) {
      return message.warning(
        "Please take action on all the amendments and submit them."
      );
    }
    // Check if integration setting is on for amendments and changes have been applied in payroll
    this.checkIfAllAmendmentsHaveBeenApplied(value, publishAll);
  };

  // Check if integration setting is on for amendments and changes have been applied in payroll before importing
  checkIfAllAmendmentsHaveBeenApplied = (value, publishAll) => {
    const { payrun, amendments } = this.props;
    const values = {
      externalPayRunId: value?.id,
      payrollSystemConnectionID: payrun?.payrollSystemConnectionId,
      externalBusinessUnitId: payrun?.externalBusinessUnitId,
    };
    if (
      amendmentsAreIntegratedToPayroll(amendments) &&
      !allAmendmentsAppliedToPayroll({ list: amendments }) &&
      (!payrun?.externalPayRunId || payrun?.externalPayRunId !== value?.id)
    ) {
      // Call the earninglines endpoint to link the payrun in GP to keypay and refetch amendments

      // Link GP to payrun in keypay
      // If the pay run doesn't have a corresponding pay run in keypay link it first
      this.props
        .linkPayrunToPayroll({
          id: payrun?.payrunId,
          values,
        })
        .then((resp) => {
          // Connection creation in progress - also applying of amendments in keypay starts
          // Display the modal with info and restart interval
          if (handleRequest({ response: resp, hasValidationErrors: true })) {
            this.setState({
              applyingToPayrollText: APPLYING_TO_PAYROLL_TEXT,
            });
            // Get updated pay run details with external payrun id
            this.props
              .getPayrun({ id: payrun?.payrunId, options: {} })
              .then(() => {
                this.initKeyPayIntervalCheck(payrun?.payrunId);
              });
          } else {
            if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
              this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
            }
          }
        });
    } else {
      if (!payrun?.externalPayRunId || payrun?.externalPayRunId !== value?.id) {
        // Link GP to payrun in keypay
        // If the pay run doesn't have a corresponding pay run in keypay link it first
        this.props
          .linkPayrunToPayroll({
            id: payrun?.payrunId,
            values,
          })
          .then((resp) => {
            if (handleRequest({ response: resp, hasValidationErrors: true })) {
              this.props.getPayrun({ id: payrun?.payrunId, options: {} });
              this.importEarlinglines(value, publishAll);
            } else {
              if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
                this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
              }
            }
          });
      } else {
        this.importEarlinglines(value, publishAll);
      }
    }
  };

  changeVisibility = () => {
    this.setState({ viewAmendmentModal: false });
    this.getExternalPayruns({ payrun: this.props.payrun, values });
  };

  getExternalPayruns = ({ payrun, pageLoad = false }) => {
    let values = {
      payrollSystemConnectionId: payrun?.payrollSystemConnectionId,
      externalBusinessUnitId: payrun?.externalBusinessUnitId,
      externalPayScheduleId: payrun?.externalPayScheduleId,
    };
    this.props
      .fetchExternalPayrun({
        values,
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        if (resp.status && resp.data) {
          const defaultPayruns = mustBeArray(resp.data);
          this.setState({ payruns: defaultPayruns, defaultPayruns }, () => {
            this.setState({ loadingTable: false, importing: false });
            if (pageLoad) {
              // Integration setting is on - can import if there are no amendments added
              this.importEarninglinesWhenNoAmendmentsAdded();
              //Check if the amendments are being applied to keypay
              this.validateAutomationOnPageLoad(defaultPayruns);
            }
          });
        } else {
          this.setState({ loadingTable: false, importing: false });
          errorHandler({
            response: resp,
            hasValidationErrors: true,
            oldModel: true,
          });
        }
      });
  };

  closeModal = () => {
    this.setState({ viewAmendmentModal: false });
  };

  // Check if user can take action on amendments
  userCanTakeActionOnAmendments = () => {
    const approveButtonClaim = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payrunAmendmentApproveIconButton",
    });
    const rejectButtonClaim = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payrunAmendmentRejectIconButton",
    });
    return (
      isComponentVisibleEnabled({ claim: approveButtonClaim }) ||
      isComponentVisibleEnabled({ claim: rejectButtonClaim })
    );
  };

  closePayCategory = () => {
    this.setState({ viewMissingCategory: false });
  };

  importMissingEmployees = () => {
    const payrunId = this.props.payrun?.payrunId;
    this.setState({ importing: true, viewEmployeeListDifference: false });
    this.props
      .payrunHandler({
        id: payrunId,
        payload: this.state.externalPayrunData,
        action: "importMissingEmployees",
      })
      .then((resp) => {
        if (resp.status) {
          this.fetchEarningLines({ options: {} });
          this.setState({ externalPayrunData: {} });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  cancelImportEarninglines = () => {
    this.setState({
      viewEmployeeListDifference: false,
      employeeList: [],
    });
  };
  toggleShowVariance = ({ showVariance }) => {
    this.setState({ showVariance });
  };
  viewDetails = (record) => {
    this.setState({ amendment: record }, () => {
      this.setState({ viewDetail: true });
    });

    this.showActions(record, true);
  };
  showActions = (rowKey, viewAction) => {
    this.setState({
      amendmentHistory: {},
      viewAmendmentHistory: viewAction ? false : true,
      historyLoading: true,
      activeAmendment: rowKey,
    });

    this.props
      .getAmendmentHistory({
        id: rowKey.amendmentId,
        options: { payrunID: rowKey.payrunId },
        queryParams: { per_page: 10 },
      })

      .then((response) => {
        if (response.status) {
          this.setState({
            amendmentHistory: response.data,
            historyLoading: false,

            totalActions: response.total,
          });
        } else {
          errorHandler({ response, hasValidationErrors: true });
        }
      });
  };
  hideModal = () => {
    this.setState({ viewDetail: false });
  };
  updateVarianceValue = (value) => {
    this.setState({ highLightVarianceValue: value });
  };

  handleApproveAmendment = ({ id }) => {
    this.setState((prevState) => {
      let newAmendmentList = structuredClone(
        mustBeArray(prevState.amendmentList)
      );
      newAmendmentList = newAmendmentList.map((item) => {
        const newItem = {};
        if (item.amendmentId === id) {
          newItem.amendmentStatusID = AMENDMENT_APPROVED_ONLY_STATUS;
          newItem.rejectDescription = null;
          newItem.rejectionReasonID = null;
        }
        return { ...item, ...newItem };
      });
      return { ...prevState, amendmentList: newAmendmentList };
    });
  };

  handleRejectPayrunAmendment = ({ data, id }) => {
    this.setState((prevState) => {
      let updatedList = structuredClone(mustBeArray(prevState.amendmentList));

      updatedList = updatedList.map((item) => {
        if (item.amendmentId === id) {
          item.amendmentStatusID = AMENDMENT_DECLINED_STATUS;
          item.rejectDescription = data && data.description;
          item.rejectionReasonID = 0;
        }
        return item;
      });
      return { ...prevState, amendmentList: updatedList };
    });
  };

  displaySubmitAmendment = () => {
    return (
      [userRoles.PSPArea, userRoles.SystemAdminArea].includes(getUserArea()) &&
      !this.state.amendmentSubmitted
    );
  };

  // Initiate the checking of amendments status in keypay
  initKeyPayIntervalCheck = (id: string) => {
    this.setState({
      submitLoading: true,
      viewKeyPayLoadingModal: true,
      keypayIntergrationLoading: true,
    });
    clearInterval(this.getAmendmentStatusInterval);

    this.getAmendmentStatusInterval = setInterval(
      this.checkKeyPayStatus,
      5000,
      id
    );
  };

  submitAmendment = () => {
    const { match, payrun } = this.props;
    const id = getMatchParams({ match, field: "id" });

    this.setState({
      submitLoading: true,
    });

    let tempAmendmentList = structuredClone(this.state.amendmentList);
    tempAmendmentList = tempAmendmentList.filter((o) =>
      [AMENDMENT_APPROVED_ONLY_STATUS, AMENDMENT_DECLINED_STATUS].includes(
        o.amendmentStatusID
      )
    );

    tempAmendmentList = mustBeArray(tempAmendmentList).reduce((a, b) => {
      let tempItem =
        b.amendmentStatusID === AMENDMENT_APPROVED_ONLY_STATUS
          ? {
              amendmentId: b.amendmentId,
            }
          : {
              amendmentId: b.amendmentId,
              rejectPayrunModel: {
                rejectReasonID: 0,
                description: b.rejectDescription,
              },
            };
      return a.concat([tempItem]);
    }, []);

    this.props
      .submitAmendments({
        id,
        payload: [...tempAmendmentList],
      })
      .then((resp) => {
        // Refetch amendment list
        if (
          !handleRequest({ response: resp, hasValidationErrors: true }) &&
          payrunIsSkipped(resp?.data?.validationErrors?.errors)
        ) {
          this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          return;
        }
        this.getAmendmentListPure(id).then((resp) => {
          const list = mustBeArray(resp?.data);
          this.setState({
            amendmentList: [...list],
            submitLoading: false,
            viewAmendmentModal: false,
            keypayIntergrationLoading: false,
          });

          // Get payrun details
          this.props.getPayrun({ id, options: {} });
          // Verify if the amendments will be applied to payroll and start the auto interval check  for amendment status in keypay.
          if (
            // check if the integration settings is on and enabled and has external pay run id.
            amendmentsWillBeAppliedToPayroll({
              amendments: list,
              payrun: this.props.payrun,
            }) &&
            !allAmendmentsAppliedToPayroll({ list }) //check for automated setting and approved amendments keypay status id
          ) {
            return this.initKeyPayIntervalCheck(id);
          } else if (
            // Check if corresponding payrun exists in keypay and the integration settings is turned on and import earninglines
            this.canImportEarningLines({ data: list }) &&
            payrun?.externalPayRunId &&
            (!amendmentsWillBeAppliedToPayroll({
              amendments: list,
              payrun: this.props.payrun,
            }) ||
              allAmendmentsAppliedToPayroll({ list }))
          ) {
            return this.initiateEarninglinesImport(id);
          }
          message.success("Amendments submitted successfully.");
        });
      });
  };

  // Check KeyPay status on submit
  checkKeyPayStatus = (id) => {
    // KeyPay status check completed
    const endLoading = (data = []) => {
      // we clear the interval
      clearInterval(this.getAmendmentStatusInterval);
      // Check if we can import earninglines else close the modal
      this.setState({
        viewKeyPayLoadingModal: false,
        submitLoading: false,
        keypayIntergrationLoading: false,
        applyingToPayrollText: null,
      });
      if (
        this.canImportEarningLines({ data }) &&
        canAutoImportEarninglines({
          externalPayruns: this.state.payruns,
          id: this.props.payrun?.externalPayRunId,
        })
      ) {
        this.initiateEarninglinesImport(id);
      } else {
        const { match } = this.props;
        const payrunId = getMatchParams({ match, field: "id" });
        this.props.getPayrun({ id: payrunId, options: {} });
      }
    };

    this.getAmendmentListPure(id).then((resp) => {
      const rows = mustBeArray(resp?.data);
      this.setState({ amendmentList: [...rows] });
      // check if the automated amendments that have been approved are still in progress
      const stillInProgress = !allAmendmentsAppliedToPayroll({ list: rows });
      // If the amendments are still in progress, keep checking
      if (stillInProgress) {
        return;
      }

      // If the amendments are not in progress, we can stop the interval and import earninglines
      endLoading(rows);

      // check if all the amendments are successful
      const allSuccess = rows.every(
        (item) => item.amendmentKeypayStatusId === KEYPAY_AMENDMENT_SUCCESS
      );
      if (allSuccess) {
        message.success(messages["payRuns.allChangesAppliedToPayroll"]);
        return;
      }

      const allFailed = rows.every(
        (item) => item.amendmentKeypayStatusId === KEYPAY_AMENDMENT_FAILED
      );

      // check if all the amendments are failed
      if (allFailed) {
        message.error(messages["payRuns.noChangesAppliedToPayroll"]);
        return;
      }

      // else we have mixed results (success, partial success, failed)
      message.error(messages["payRuns.partialChangesAppliedToPayroll"]);
    });
  };

  checkIfAllAmendmentSubmitted = ({ list }) => {
    const allSubmitted = this.allSubmitted(list);
    this.setState({ amendmentSubmitted: allSubmitted });
    return allSubmitted;
  };

  allSubmitted = (list) => {
    return mustBeArray(list).every((amendment) => {
      return RESOLVED_AMENDMENTS_IDS.includes(amendment.amendmentStatusID);
    });
  };

  showAddAmendment = () => {
    this.setState({ viewAddAmendment: true });
  };

  handleAddAmendmentCancel = () => {
    this.setState({ viewAddAmendment: false });
  };

  handleAddAmendment = (values) => {
    const payrunId = this.props.payrun?.payrunId;
    values.payrunId = payrunId;
    values.assignTo = Array.isArray(values.assignTo)
      ? JSON.stringify(values.assignTo)
      : 0;

    return this.props.addAmendment(payrunId, values).then((response) => {
      if (response.status) {
        message.success("Amendment created successfully");
        this.setState({ visible: false });
        this.handleAmendmentRedirection({ response, values });
      } else {
        errorHandler({ response, hasValidationErrors: true });
        if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
          this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
        }
      }

      return response;
    });
  };

  handleAmendmentRedirection = ({ values, response }) => {
    this.setState({ visible: false });
    if (amendmentHasQuestionSection({ id: values?.changeTaskValueId || 0 })) {
      this.props.updateHeader({
        header: {
          module: "Back to Pay Run Detail",
          enableBack: true,
          entity: "generalAmendment",
          returnUrl: `/payrun/${this.props.payrun?.payrunId}`,
        },
      });
      this.props.router.navigate(
        !values?.isReoccurring
          ? `/payrun/${this.props.payrun?.payrunId}/amendment/${response.data?.result}`
          : `/payrun/amendment/${response.data}`
      );
    } else {
      this.handleAddAmendmentCancel();
      this.getAmendmentList();
    }
  };

  showRejectionReason = () => {
    Modal.warning({
      title: "Pay Run has been rejected",
      content: (
        <AmendmentDeclineReason
          description={this.props.payrun?.rejectDescription}
          type="payrun"
        />
      ),
      width: 600,
    });
  };

  handleRefresh = () => {
    this.setState({ refreshLoading: true, loadingTable: true });
    this.importEarlinglines({ id: this.props.payrun.externalPayRunId }, false);
  };

  closeKeyPayLoadingModal = () => {
    this.setState({
      viewKeyPayLoadingModal: false,
      applyingToPayrollText: null,
    });
  };

  // Add Amendment Modal Functions
  closeAttachAmendmentModal = () => {
    this.setState({ viewAttachAmendmentModal: false });
    this.displayApproveRejectModal(this.props.amendments);
  };

  // Attach amendments to the payrun
  handleAttachAmendment = ({ checkedList }) => {
    this.setState({ attachLoading: true });
    this.props
      .attachAmendments({
        id: this.props.payrun?.payrunId,
        payload: checkedList,
      })
      .then((resp) => {
        this.setState({ attachLoading: false });
        if (resp.status) {
          message.success("Amendments attached successfully.");
          this.handleAttachmentSuccess();
        } else {
          errorHandler({
            response: resp,
            hasValidationErrors: true,
          });
          if (payrunIsSkipped(resp?.data?.validationErrors?.errors)) {
            this.props.router.navigate(DEFAULT_ROUTES.PAYRUN);
          }
        }
      });
  };

  handleAttachmentSuccess = () => {
    // Refetch amendment list to display newly attached amendments
    this.setState({
      amendmentSummaryLoading: true,
      viewAttachAmendmentModal: false,
    });
    this.getAmendmentList(false);
  };

  // Upload/Download reports and files
  closeUploadReportsModal = () =>
    this.setState({ viewUploadReportsModal: false });

  getFiles = () => {
    this.props.getPayrunReports(this.props.payrun.payrunId).then((resp) => {
      if (handleRequest({ response: resp })) {
        this.setState({ payrunReports: resp.data });
      }
    });
  };

  render() {
    const {
      bu_loading,
      currencyFees,
      updateCurrencyLoading,
      viewEmployeeListDifference,
      employeeList,
      viewLoadingModal,
      viewKeyPayLoadingModal,
      viewAddAmendment,
      keypayIntergrationLoading,
      viewAttachAmendmentModal,
      nextPayrunAmendmentList,
      payrunAttachNextPayrunAmendmentModalClaim,
      attachLoading,
      hideStaticContent,
      payrunReports,
    } = this.state;

    const allSubmitted = this.allSubmitted(this.state.amendmentList);

    const { dashboardView, match } = this.props;
    const displaySubmitAmendment = this.displaySubmitAmendment();
    return (
      <React.Fragment>
        <PayrunSteps
          claims={this.props.pageClaims}
          invoice={this.state.invoice}
        />
        <If
          condition={isCompanyUser()}
          then={
            <React.Fragment>
              <ClientPrepareView
                payrun={this.props.payrun}
                handleAmendmentAction={this.handleAmendmentAction}
                pageClaims={this.props.pageClaims}
                name={PAYRUN_AMENDMENT_LIST_CONTAINER}
                title="Amendments"
                list={this.state.amendmentList}
                dashboardView={dashboardView}
                targetEndpoint={APIHandler.constructEndpoint({
                  endpoint: "PAYRUN_AMENDMENTS_URL",
                  options: { id: getMatchParams({ match, field: "id" }) },
                })}
                hideStaticContent={hideStaticContent}
                handleToggle={() =>
                  this.setState({
                    hideStaticContent: !this.state.hideStaticContent,
                  })
                }
                payrunReports={payrunReports}
                handleUpload={() =>
                  this.setState({ viewUploadReportsModal: true })
                }
                downloadPayrunReports={this.props.downloadPayrunReports}
              />
              <Modal
                title={<div className="text-center">Amendment Details</div>}
                width={700}
                visible={this.state.viewDetail}
                onCancel={this.hideModal}
                footer={[
                  <Button
                    key="back"
                    onClick={this.hideModal}
                    className="rounded-[20px] bg-e5e5"
                  >
                    Close
                  </Button>,
                ]}
              >
                <AmendmentDetails
                  amendment={this.state.amendment}
                  businessunitDetails={this.props.businessunitDetails}
                  amendmentHistory={this.state.amendmentHistory}
                  viewAmendmentHistory={this.state.viewAmendmentHistory}
                  historyLoading={this.state.historyLoading}
                  activeAmendment={this.state.activeAmendment}
                  getAmendmentHistory={this.props.getAmendmentHistory}
                  totalActions={this.state.totalActions}
                  handleTableChange={this.handleActionTableChange}
                  viewAction={true}
                  visible={this.state.visible}
                />
              </Modal>
              <PayrunEmployeeList payrunId={this.props.payrun?.payrunId} />
            </React.Fragment>
          }
          else={
            <Row gutter={DEFAULT_GUTTER} className="mb-4">
              <Col span={18} className=" no-padding">
                {this.props.payrun?.payrunStatusID >
                  PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED && (
                  <Fragment>
                    {!this.state.hideStaticContent && (
                      <InformationDisplayCard
                        title={ManagerInformationCardMessage.prepareTitle}
                        description={ManagerInformationCardMessage.prepareDescription}
                        ActionComponent={() => (
                          <Fragment>
                            <PayrunDownloadOptions
                              claims={this.props.pageClaims}
                              classname="mr-4"
                              disabled={!payrunReports?.length}
                              payrunReports={payrunReports}
                              handleReportDownload={
                                this.props.downloadPayrunReports
                              }
                              title={
                                !payrunReports?.length
                                  ? "No Reports Available"
                                  : ""
                              }
                            />
                            <UploadReportsButton
                              handleUploadClick={() =>
                                this.setState({ viewUploadReportsModal: true })
                              }
                              claims={this.props.pageClaims}
                              componentName={PAYRUN_REPORT_UPLOAD_BUTTON}
                            />
                          </Fragment>
                        )}
                        IconComponent={PayrunBeingPreparedIcon}
                        payrun={this.props.payrun}
                        claims={this.props.pageClaims}
                      />
                    )}
                    <ToogleStaticContent
                      hideStaticContent={this.state.hideStaticContent}
                      claims={this.props.pageClaims}
                      handleIconClick={() =>
                        this.setState({
                          hideStaticContent: !this.state.hideStaticContent,
                        })
                      }
                    />
                  </Fragment>
                )}
                {showVariance({
                  payrun: this.props.payrun,
                  payrunSelected: this.state.payrunSelected,
                }) && (
                  <ShowVariance
                    showVariance={this.state.showVariance}
                    highLightVarianceValue={this.state.highLightVarianceValue}
                    updateVarianceValue={this.updateVarianceValue}
                    toggleShowVariance={this.toggleShowVariance}
                    filterApplied={this.state.filterApplied}
                    handleFilterChange={this.handleFilterChange}
                  />
                )}
                <Card className="card card-space-0">
                  <If
                    condition={showExternalEarninglines({
                      payrun: this.props.payrun,
                    })}
                    then={
                      <EarningLinesTable
                        className="invoice-list"
                        pageClaims={this.props.pageClaims}
                        loading={this.state.loadingTable}
                        list={this.state.payruns}
                        businessunitDetails={this.props.businessunitDetails}
                        invoice={this.state.invoice}
                        payrun={this.state.externalPayrun}
                        toggleShowVariance={this.toggleShowVariance}
                        showVariance={this.state.showVariance}
                        highLightVarianceValue={
                          this.state.highLightVarianceValue
                        }
                        filterApplied={this.state.filterApplied}
                        showRejectionReason={this.showRejectionReason}
                      />
                    }
                    else={
                      <React.Fragment>
                        <If
                          condition={!this.state.payrunSelected}
                          then={
                            <ClaimWrapper
                              name={PAYRUN_EXTERNAL_LIST_CONTAINER}
                              claims={this.props.pageClaims}
                            >
                              <ClaimWrapper
                                name="payrunExternalCustomTable"
                                claims={this.props.claims}
                              >
                                <ExternalPayrunList
                                  externalPayruns={this.state.payruns}
                                  importEarlinglines={this.checkImportEarnings}
                                  title="Pay Runs"
                                  importing={this.state.importing}
                                  payrun={this.state.externalPayrun}
                                  showRejectionReason={this.showRejectionReason}
                                  keypayIntergrationLoading={
                                    keypayIntergrationLoading
                                  }
                                />
                              </ClaimWrapper>
                            </ClaimWrapper>
                          }
                          else={
                            <EarningLinesTable
                              className="invoice-list"
                              pageClaims={this.props.pageClaims}
                              loading={this.state.loadingTable}
                              list={this.state.externalEarninglines}
                              businessunitDetails={
                                this.props.businessunitDetails
                              }
                              invoice={{}}
                              payrun={this.props.payrun}
                              toggleShowVariance={this.toggleShowVariance}
                              showVariance={this.state.showVariance}
                              highLightVarianceValue={
                                this.state.highLightVarianceValue
                              }
                              filterApplied={this.state.filterApplied}
                              showRejectionReason={this.showRejectionReason}
                            />
                          }
                        />
                      </React.Fragment>
                    }
                  />
                </Card>
                {[21, 22].includes(this.props.payrun?.payrunStatusID) &&
                  !!this.state.payrunSelected && (
                    <React.Fragment>
                      {!_.isEmpty(currencyFees) && (
                        <ClaimWrapper
                          name={PAYRUN_CURRENCY_CONVERSION_FEES}
                          claims={this.props.pageClaims}
                          type="action"
                        >
                          <ConversionFeesView
                            {...this.props}
                            currencyFees={currencyFees}
                            updateCurrencyFees={this.updateCurrencyFees}
                            updateLoading={updateCurrencyLoading}
                          />
                        </ClaimWrapper>
                      )}
                      <SimpleButton
                        buttonText="CLEAR"
                        onClick={this.cancelExternalPayrun}
                        claims={this.props.pageClaims}
                        name={PAYRUN_EARNINGLINES_CLEAR_BUTTON}
                        className="bg-danger mb-6"
                        loading={this.state.clearLoading}
                      />
                      <SimpleButton
                        buttonText="REFRESH"
                        onClick={this.handleRefresh}
                        ignoreStatus={true}
                        type="primary"
                        className="ml-4"
                        loading={this.state.refreshLoading}
                      />
                      <SimpleButton
                        buttonText="COMPLETE"
                        onClick={this.preparePayrun}
                        claims={this.props.pageClaims}
                        name={PAYRUN_PREPARE_BUTTON}
                        className="bg-success float-right mb-6"
                        loading={bu_loading}
                      />
                      <div className="clearfix" />
                    </React.Fragment>
                  )}
              </Col>
              <Col span={6}>
                <ClaimWrapper
                  name={PAYRUN_AMENDMENT_APPROVE_REJECT_LIST_CONTAINER}
                  claims={this.props.pageClaims}
                >
                  <ClaimWrapper
                    name="payrunAmendmentApproveRejectCustomTable"
                    claims={this.props.claims}
                  >
                    <AmendmentSummary
                      title="Amendments"
                      amendmentSummary={[...this.state.amendmentList]}
                      className="mb-4"
                      approveAmendment={this.handleApproveAmendment}
                      rejectPayrunAmendment={this.handleRejectPayrunAmendment}
                      listPayrunAmendments={this.props.listPayrunAmendments}
                      payrun={this.props.payrun}
                      userMode={this.props.userMode}
                      externalEarninglines={this.state.externalEarninglines}
                      businessunitDetails={this.props.businessunitDetails}
                      loading={this.props.amendmentSummaryLoading}
                      approveComponent="payrunAmendmentApproveIconButton"
                      rejectComponent="payrunAmendmentRejectIconButton"
                      match={this.props.match}
                      getPayrun={this.props.getPayrun}
                      handleAddAmendment={this.showAddAmendment}
                      payrunSelected={this.state.payrunSelected}
                      getAmendmentHistory={this.props.getAmendmentHistory}
                      keypayIntergrationLoading={keypayIntergrationLoading}
                    />
                    <AddAmendment
                      visible={viewAddAmendment}
                      onCancel={this.handleAddAmendmentCancel}
                      onOk={this.handleAddAmendment}
                      payrun={this.props.payrun}
                      {...this.props}
                    />
                    {getOptionalParameter({
                      value1: displaySubmitAmendment,
                      value2: !this.state.payrunSelected,
                    }) && !_.isEmpty(this.state.amendmentList) ? (
                      <div className="flex">
                        <SimpleButton
                          ignoreStatus
                          buttonDisabled={!allSubmitted}
                          className={classNames("ml-auto", {
                            "bg-success": allSubmitted,
                          })}
                          buttonText="Submit"
                          loading={this.state.submitLoading}
                          onClick={this.submitAmendment}
                        />
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                  </ClaimWrapper>
                </ClaimWrapper>
              </Col>
            </Row>
          }
        />

        <AmendmentsModal
          {...this.props}
          viewAmendmentModal={this.state.viewAmendmentModal}
          amendmentSummary={[...this.state.amendmentList]}
          payrunId={this.props.payrun?.payrunId}
          changeVisibility={this.changeVisibility}
          closeModal={this.closeModal}
          approvedRejectedAmendments={this.state.approvedRejectedAmendments}
          approveAmendment={this.handleApproveAmendment}
          rejectPayrunAmendment={this.handleRejectPayrunAmendment}
          displaySubmitAmendment={displaySubmitAmendment}
          handleSubmit={this.submitAmendment}
          submitLoading={this.state.submitLoading}
          payrunSelected={this.state.payrunSelected}
          allSubmitted={allSubmitted}
        />
        <MissingPayCategory
          visible={this.state.viewMissingCategory}
          getMissingPayCategories={this.props.getMissingPayCategories}
          payrun={this.props.payrun}
          closeModal={this.closePayCategory}
          handleContinue={this.preparePayrun}
          declineLoading={bu_loading}
        />
        <EmployeeListDifference
          visible={viewEmployeeListDifference}
          employeeList={employeeList}
          fetchEarningLines={this.importMissingEmployees}
          cancelImportEarninglines={this.cancelImportEarninglines}
        />
        <LoadingModal
          visible={viewLoadingModal}
          loadingText={this.state.loadingText}
        />
        <KeyPayLoadingModal
          visible={viewKeyPayLoadingModal}
          handleClose={this.closeKeyPayLoadingModal}
          applyingToPayrollText={this.state.applyingToPayrollText}
        />
        {/* Modal to attach next payrun amendments to this payrun */}
        <If
          condition={
            nextPayrunAmendmentList.length > 0 &&
            isComponentVisible({
              claim: payrunAttachNextPayrunAmendmentModalClaim,
            }) &&
            !isCompanyUser()
          }
          then={
            <AttachAmendmentModal
              visible={viewAttachAmendmentModal}
              handleClose={this.closeAttachAmendmentModal}
              data={nextPayrunAmendmentList}
              claim={payrunAttachNextPayrunAmendmentModalClaim}
              handleAttachAmendment={this.handleAttachAmendment}
              attachLoading={attachLoading}
            />
          }
        />
        <UploadReportsModal
          isModalVisible={this.state.viewUploadReportsModal}
          handleCancel={this.closeUploadReportsModal}
          payrun={this.props.payrun}
          refreshPayrunReportList={this.getFiles}
          payrunReports={payrunReports}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PayrunPrepareDetail);
