import React from "react";

type Props = {
  condition: boolean;
  elseCondition?: boolean;
  then: React.ReactNode;
  else?: any;
};

const If = (props: Props) => {
  let component = null;
  if (props.condition) {
    component = props.then;
  } else if (
    (typeof props.elseCondition === "undefined" || props.elseCondition) &&
    props.else
  ) {
    component = props.else;
  }

  return component;
};

export default If;
