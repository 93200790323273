import { DawnArrowLeft16 } from '@xemplo/icons';

import { SidebarTestId } from '../sidebar.constants';
import { BaseSidebarState } from '../sidebar.internal-types';

import * as S from './sidebar-handle.styles';
import { useSidebarHandle } from './use-sidebar-handle';

export function SidebarHandle(props: BaseSidebarState) {
  const { isSidebarExpanded, setIsSidebarExpanded } = props;
  const { isHandleHidden } = useSidebarHandle();
  return (
    <S.Handle
      data-testid={SidebarTestId.SidebarToggle}
      data-expanded={isSidebarExpanded}
      aria-controls="sidebar-nav"
      aria-hidden={isHandleHidden}
      onClick={() => setIsSidebarExpanded((prev) => !prev)}
      disabled={isHandleHidden}
    >
      <DawnArrowLeft16 />
    </S.Handle>
  );
}
