//@ts-nocheck
import React from "react";
import InvoiceList from "../../../components/payrun/invoice/list";
import { Tabs } from "antd";
import InvoiceStatus from "../../../../Common/invoiceStatus";
import _ from "lodash";
import {
  mustBeArray,
  findAuthorizationClaims,
  errorDisplay,
  delay,
  updateAuthorizationClaims,
  hasAuthorizationToViewComponent,
  parseItems,
  getUserArea,
  getPersonalizationData,
  setPersonalizationData,
  filteredByLabel,
  requestUrlBuilder,
  getCurrentCardGrid,
  getInvoiceRowClassname,
  getDefaultPage,
  getDefaultPerPage,
  listHasMoreData,
  getConditionalResponse,
  conditionalParameter,
  getDefaultQueryPage,
  getScrollingListDefaultPerPage,
  trimWhiteSpaces,
  validateUsersAccessRights,
  isComponentVisible,
} from "../../../../libs";
import {
  INVOICE_GRID_OPTIONS,
  INVOICE_FIELD_OPTIONS,
} from "../../../../constants/sortingOptions";
import { PAYRUN_TYPES_OPTIONS } from "../../../../constants/options";
import {
  MULTI_TAB_ACTIVE_KEY_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  PAGINATION_SCROLLING,
  GRID_VIEW_VALUE,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  SORTING_NOT_REQUIRED,
  STRING_PERSONALIZATION,
  SINGLE_LINE_GRID_VALUE,
} from "../../../../constants/defaultClaims";
import axios from "axios";
import FilterContainer from "../../../../Common/ClaimContainers/FilterContainer";
import MultiTabContainer from "../../../../Common/ClaimContainers/MultiTabContainer";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import SingleFieldTextBoxFilter from "../../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import DateRangepickerFilter from "../../../../Common/ClaimComponents/Filter/DateRangepickerFilters";
import SearchIconButton from "../../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../../Common/ClaimComponents/Button/LinkButton";
import ChildTabContainer from "../../../../Common/ClaimContainers/ChildTabContainer";
import InvoiceDisplayCard from "../../../components/payrun/invoice/InvoiceDisplayCard";
import TabHeader from "../../../../Common/tabHeader";
import moment from "moment";
import { PEO_MODEL_ID } from "../../../../constants/payrun";
import userRoles from "../../../../constants/userRoles";
import APIHandler from "../../../../constants/apiUrl";
import InvoiceSingleLineGridDisplay from "../../../components/payrun/invoice/SingleLineGridInvoiceDisplay";
import {
  GOOGLE_ANALYTICS_PAGE_TITLE,
  INVOICE_FILTER_CONTAINER,
} from "../../../../constants";
import { If } from "../../../../Common/ui";
import { withRouter } from "../../../../hooks";
import { ErrorBoundary } from "@xemplo/error-boundary";

const TabPane = Tabs.TabPane;

type State = any;

class PayrunInvoice extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      activeKey: "1",
      basic: {},
      payRun: [],
      payrunTypeId: 3,
      options: PAYRUN_TYPES_OPTIONS,
      invoicePayrunTypes: [
        {
          type: "invoicePaid",
          updateClaimName: "invoicePaidGeneralTable",
        },
        { type: "invoiceUnPaid", updateClaimName: "invoiceUnPaidGeneralTable" },
      ],
      filters: {
        invoiceNumber: "",
      },
      // paid
      invoicePaidPagination: 1,
      invoicePaidPerPage: 10,
      invoicePaidTotal: 0,
      invoicePaidList: [],
      invoicePaidListLoading: false,
      invoicePaidAddable: false,
      invoicePaidGridable: false,
      invoicePaidGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      invoicePaidGridView: false,
      invoicePaidIsPinned: "false",
      invoicePaidSortingType: "notrequired",
      invoicePaidHasMoreData: false,
      invoicePaidSortInfo: {},
      invoicePaidGridSortOption: INVOICE_GRID_OPTIONS,
      invoicePaidSelectedGridSort: undefined,
      invoicePaidFieldOptions: INVOICE_FIELD_OPTIONS,

      // Unpaid
      invoiceUnPaidPagination: 1,
      invoiceUnPaidPerPage: 10,
      invoiceUnPaidTotal: 0,
      invoiceUnPaidList: [],
      invoiceUnPaidListLoading: false,
      invoiceUnPaidAddable: false,
      invoiceUnPaidGridable: false,
      invoiceUnPaidGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      invoiceUnPaidGridView: false,
      invoiceUnPaidIsPinned: "false",
      invoiceUnPaidSortingType: "notrequired",
      invoiceUnPaidHasMoreData: false,
      invoiceUnPaidSortInfo: {},
      invoiceUnPaidGridSortOption: INVOICE_GRID_OPTIONS,
      invoiceUnPaidSelectedGridSort: undefined,
      invoiceUnPaidFieldOptions: INVOICE_FIELD_OPTIONS,
      searchLoading: false,
      updatedComponentPersonalization: [],
    };
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, type, pageClaims } = this.props;
    // Check if user has access to Invoices Page - redirect to default route if not.
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return null;
    if (dashboardView) {
      if (type === "paid") {
        const paidPersonalizations = getPersonalizationData({
          type: "listContainer",
          personalizationData: dashboardClaims,
        });
        if (!paidPersonalizations || !paidPersonalizations.gridView) {
          this.setState({ invoicePaidGridView: false });
          if (paidPersonalizations?.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingInvoice({
              pageLoad: true,
              type: "invoicePaid",
              notUpdate: true,
            });
          } else {
            this.listInvoices({
              options: {},
              type: "invoicePaid",
              notUpdate: true,
            });
          }
        } else {
          this.setState({ invoicePaidGridView: true });
          this.invoiceFetchMoreData({
            isPaginated: true,
            type: "invoicePaid",
            notUpdate: true,
          });
        }
      } else if (type === "unPaid") {
        const unPaidPersonalization = getPersonalizationData({
          type: "listContainer",
          personalizationData: dashboardClaims,
        });
        if (unPaidPersonalization && !unPaidPersonalization.gridView) {
          this.setState({ invoiceUnPaidGridView: false });
          this.listInvoices({
            options: {},
            type: "invoiceUnPaid",
            notUpdate: true,
          });
        } else {
          this.setState({ invoiceUnPaidGridView: true });
          this.invoiceFetchMoreData({
            isPaginated: true,
            type: "invoiceUnPaid",
            notUpdate: true,
          });
        }
      }
    } else {
      this.props.updateHeader({
        header: {
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.InvoiceList,
          title: "Invoices",
          module: "",
          enableBack: false,
          entity: "payrun",
          action: "",
        },
      });
      // Filter container
      const invoiceNameTextboxFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "invoiceNameTextboxFilter",
      });
      const invoiceDateRangeFilter = findAuthorizationClaims({
        claims: this.props.pageClaims?.components,
        name: "invoiceDateRangeFilter",
      });
      const nameTextBoxPersonalizations = getPersonalizationData({
        type: "filterContainer",
        personalizationData: invoiceNameTextboxFilter?.personalization,
      });
      const dateRangePersonalization = getPersonalizationData({
        type: "filterContainer",
        personalizationData: invoiceDateRangeFilter?.personalization,
      });
      const dateRangeDefaultValue = mustBeArray(
        parseItems(dateRangePersonalization?.value)
      );
      this.setState({
        filters: {
          name:
            nameTextBoxPersonalizations?.value &&
            hasAuthorizationToViewComponent(
              invoiceNameTextboxFilter?.authorizationStatusId
            )
              ? nameTextBoxPersonalizations.value
              : "",
          startDate: !_.isEmpty(dateRangeDefaultValue)
            ? dateRangeDefaultValue[0]
              ? moment(dateRangeDefaultValue[0], "DD/MM/YYYY")
              : undefined
            : undefined,
          endDate: !_.isEmpty(dateRangeDefaultValue)
            ? dateRangeDefaultValue[1]
              ? moment(dateRangeDefaultValue[1], "DD/MM/YYYY")
              : undefined
            : undefined,
        },
      });
      // Multi tab
      const invoiceMultiTabContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "invoiceMultiTabContainer"
      );
      const invoiceMultiTabPersonalizations = getPersonalizationData({
        type: "multiTabContainer",
        personalizationData: invoiceMultiTabContainer?.personalizations,
      });
      if (invoiceMultiTabPersonalizations?.activeKey) {
        this.setState({ activeKey: invoiceMultiTabPersonalizations.activeKey });
      }

      //  Paid Invoice List Containers
      const invoicePaidListContainer = findAuthorizationClaims({
        claims: mustBeArray(invoiceMultiTabContainer?.components),
        name: "invoicePaidListContainer",
      });
      const invoicePaidGeneralTable = findAuthorizationClaims({
        claims: invoicePaidListContainer?.components,
        name: "invoicePaidGeneralTable",
      });
      const invoicePaidGeneralGrid = findAuthorizationClaims({
        claims: invoicePaidListContainer?.components,
        name: "invoicePaidGeneralGrid",
      });
      const invoicePaidPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: invoicePaidListContainer?.personalizations,
      });

      this.setState({
        invoicePaidPaginationType: invoicePaidPersonalizations?.paginationType,
        invoicePaidSortingType: invoicePaidPersonalizations?.sortingType,
        invoicePaidIsPinned: invoicePaidPersonalizations?.isPinned,
        invoicePaidGridable: invoicePaidListContainer?.gridable,
      });
      if (invoicePaidPersonalizations?.gridView === SINGLE_LINE_GRID_VALUE) {
        this.setState({ invoicePaidSingleLineGridView: true });
        delay(300).then(() => {
          this.listSingleLineGridInvoice({
            options: {},
            type: "invoicePaid",
            notUpdate: true,
          });
        });
      }

      // If Table
      if (
        invoicePaidGeneralTable &&
        [1, 2].includes(Number(invoicePaidGeneralTable.authorizationStatusId))
      ) {
        const invoicePaidTablePersonalizations = getPersonalizationData({
          type: "table",
          personalizationData: invoicePaidGeneralTable.personalizations,
        });
        const invoicePaidPagination =
          invoicePaidTablePersonalizations?.pageNumber
            ? Number(invoicePaidTablePersonalizations.pageNumber)
            : 1;
        const pageSize = invoicePaidTablePersonalizations?.pageSize
          ? Number(invoicePaidTablePersonalizations.pageSize)
          : 1;
        const SortOrder = invoicePaidTablePersonalizations?.sortOrder ?? null;
        const SortColumn = invoicePaidTablePersonalizations?.sortColumn ?? null;
        this.setState(
          {
            invoicePaidPerPage:
              invoicePaidPersonalizations?.paginationType ===
              PAGINATION_NOT_REQUIRED
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
            invoicePaidPagination,
            invoicePaidSortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (!invoicePaidPersonalizations.gridView) {
              if (
                invoicePaidPersonalizations?.paginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingInvoice({
                  pageLoad: true,
                  type: "invoicePaid",
                  notUpdate: true,
                });
              } else {
                this.listInvoices({
                  options: {},
                  type: "invoicePaid",
                  notUpdate: true,
                });
              }
            }
          }
        );
      }

      // If Grid
      if (isComponentVisible({ claim: invoicePaidGeneralGrid })) {
        const invoicePaidGridPersonalizations = getPersonalizationData({
          type: "grid",
          personalizationData: invoicePaidGeneralGrid.personalizations,
        });
        const pageNumber = invoicePaidGridPersonalizations?.pageNumber
          ? Number(invoicePaidGridPersonalizations.pageNumber)
          : 1;
        const row = invoicePaidGridPersonalizations?.row
          ? Number(invoicePaidGridPersonalizations.row)
          : 5;
        const col = invoicePaidGridPersonalizations?.col
          ? Number(invoicePaidGridPersonalizations.col)
          : 4;
        const SortOrder = invoicePaidGridPersonalizations?.sortOrder ?? null;
        const SortColumn = invoicePaidGridPersonalizations?.sortColumn ?? null;
        this.setState(
          {
            invoicePaidGrid: {
              page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },
            invoicePaidSelectedGridSort:
              _.find(
                this.state.invoicePaidGridSortOption,
                (o) => o.label === SortColumn
              ) &&
              _.find(
                this.state.invoicePaidGridSortOption,
                (o) => o.label === SortColumn
              ).id,
          },
          () => {
            if (
              invoicePaidPersonalizations?.gridView &&
              invoicePaidPersonalizations.gridView !== SINGLE_LINE_GRID_VALUE
            ) {
              this.setState({ invoicePaidGridView: true });
              if (
                invoicePaidPersonalizations &&
                invoicePaidPersonalizations.paginationType ===
                  PAGINATION_SCROLLING
              ) {
                this.listScrollingGridInvoice({
                  pageLoad: true,
                  type: "invoicePaid",
                  notUpdate: true,
                });
              } else {
                this.invoiceFetchMoreData({
                  isPaginated: true,
                  type: "invoicePaid",
                  notUpdate: true,
                });
              }
            }
          }
        );
      }
      // Unpaid Invoice List
      const invoiceUnPaidListContainer = findAuthorizationClaims({
        claims: mustBeArray(invoiceMultiTabContainer?.components),
        name: "invoiceUnPaidListContainer",
      });
      const invoiceUnPaidGeneralTable = findAuthorizationClaims({
        claims: invoiceUnPaidListContainer?.components,
        name: "invoiceUnPaidGeneralTable",
      });
      const invoiceUnPaidGeneralGrid = findAuthorizationClaims({
        claims: invoiceUnPaidListContainer?.components,
        name: "invoiceUnPaidGeneralGrid",
      });
      const invoiceUnPaidPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData:
          invoiceUnPaidListContainer &&
          invoiceUnPaidListContainer.personalizations,
      });
      this.setState({
        invoiceUnPaidPaginationType:
          invoiceUnPaidPersonalizations?.paginationType,
        invoiceUnPaidSortingType: invoiceUnPaidPersonalizations?.sortingType,
        invoiceUnPaidIsPinned: invoiceUnPaidPersonalizations?.isPinned,
        invoiceUnPaidGridable: invoiceUnPaidListContainer?.gridable,
      });

      if (invoiceUnPaidPersonalizations?.gridView === SINGLE_LINE_GRID_VALUE) {
        this.setState({ invoiceUnPaidSingleLineGridView: true });
        delay(300).then(() => {
          this.listSingleLineGridInvoice({
            options: {},
            type: "invoiceUnPaid",
            notUpdate: true,
          });
        });
      }

      if (isComponentVisible({ claim: invoiceUnPaidGeneralTable })) {
        const invoiceUnPaidTablePersonalizations = getPersonalizationData({
          type: "table",
          personalizationData: invoiceUnPaidGeneralTable.personalizations,
        });
        const invoiceUnPaidPagination =
          invoiceUnPaidTablePersonalizations?.pageNumber
            ? Number(invoiceUnPaidTablePersonalizations.pageNumber)
            : 1;
        const pageSize = invoiceUnPaidTablePersonalizations?.pageSize
          ? Number(invoiceUnPaidTablePersonalizations.pageSize)
          : 1;
        const SortOrder = invoiceUnPaidTablePersonalizations?.sortOrder ?? null;
        const SortColumn =
          invoiceUnPaidTablePersonalizations?.sortColumn ?? null;
        this.setState(
          {
            invoiceUnPaidPerPage:
              invoiceUnPaidPersonalizations?.paginationType ===
              PAGINATION_NOT_REQUIRED
                ? DEFAULT_PAGE_SIZE_NO_PAGINATION
                : !_.isNaN(Number(pageSize))
                ? Number(pageSize)
                : 10,
            invoiceUnPaidPagination,
            invoiceUnPaidSortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
          },
          () => {
            if (
              invoiceUnPaidPersonalizations &&
              !invoiceUnPaidPersonalizations.gridView
            ) {
              if (
                invoiceUnPaidPersonalizations.paginationType ===
                PAGINATION_NOT_REQUIRED
              ) {
                this.listScrollingInvoice({
                  pageLoad: true,
                  type: "invoiceUnPaid",
                  notUpdate: true,
                });
              } else {
                this.listInvoices({
                  options: {},
                  type: "invoiceUnPaid",
                  notUpdate: true,
                });
              }
            }
          }
        );
      }
      // If Grid
      if (isComponentVisible({ claim: invoiceUnPaidGeneralGrid })) {
        const invoiceUnPaidGridPersonalizations = getPersonalizationData({
          type: "grid",
          personalizationData: invoiceUnPaidGeneralGrid.personalizations,
        });
        const pageNumber = invoiceUnPaidGridPersonalizations?.pageNumber
          ? Number(invoiceUnPaidGridPersonalizations.pageNumber)
          : 1;
        const row = invoiceUnPaidGridPersonalizations?.row
          ? Number(invoiceUnPaidGridPersonalizations.row)
          : 5;
        const col = invoiceUnPaidGridPersonalizations?.col
          ? Number(invoiceUnPaidGridPersonalizations.col)
          : 4;
        const SortOrder = invoiceUnPaidGridPersonalizations?.sortOrder ?? null;
        const SortColumn =
          invoiceUnPaidGridPersonalizations?.sortColumn ?? null;

        this.setState(
          {
            invoiceUnPaidGrid: {
              page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
              sortInfo: {
                order: SortOrder,
                field: SortColumn,
              },
              row,
              col,
            },
            invoiceUnPaidSelectedGridSort:
              _.find(
                this.state.invoiceUnPaidGridSortOption,
                (o) => o.label === SortColumn
              ) &&
              _.find(
                this.state.invoiceUnPaidGridSortOption,
                (o) => o.label === SortColumn
              ).id,
          },
          () => {
            if (
              invoiceUnPaidPersonalizations?.gridView &&
              invoiceUnPaidPersonalizations?.gridView !== SINGLE_LINE_GRID_VALUE
            ) {
              this.setState({ invoiceUnPaidGridView: true });
              if (
                invoiceUnPaidPersonalizations?.paginationType ===
                PAGINATION_SCROLLING
              ) {
                this.listScrollingGridInvoice({
                  pageLoad: true,
                  type: "invoiceUnPaid",
                  notUpdate: true,
                });
              } else {
                this.invoiceFetchMoreData({
                  isPaginated: true,
                  type: "invoiceUnPaid",
                  notUpdate: true,
                });
              }
            }
          }
        );
      }
    }
  }

  listSingleLineGridInvoice = ({ options, type, notUpdate }) => {
    let queryParams;
    switch (type) {
      case "invoicePaid":
        queryParams = {
          invoiceStatusId: InvoiceStatus.getStatusID({
            status: "invoicePaid",
          }),
        };
        break;
      case "invoiceUnPaid":
        queryParams = {
          invoiceStatusId: InvoiceStatus.getStatusID({
            status: "invoiceUnPaid",
          }),
        };
        break;
      default:
        break;
    }
    let newOptions = {
      page: options?.page ?? 1,
      per_page: 20,
    };
    if (this.getFilters()) {
      newOptions.q = this.getFilters();
    }

    newOptions.q = newOptions.q
      ? { ...newOptions.q, ...queryParams }
      : queryParams;
    this.props
      .listInvoices({
        options: newOptions,
        cancelToken: this.signal.token,
        targetEndpoint: this.props.targetEndpoint,
      })
      .then((resp) => {
        this.setState({ [`${type}ListLoading`]: false });
        if (resp.status) {
          this.setState({
            [`${type}List`]: mustBeArray(resp.data),
            [`${type}Total`]: resp.total,
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  listInvoices = ({ options, type, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState[`${type}Pagination`] = options.page;
          prevState[`${type}SortInfo`] = {
            field: options.field,
            order: options.order,
          };
        }
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let updateClaimName = "";
        let queryParams;
        switch (type) {
          case "invoicePaid":
            updateClaimName = "invoicePaidGeneralTable";
            queryParams = {
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoicePaid",
              }),
            };
            break;
          case "invoiceUnPaid":
            updateClaimName = "invoiceUnPaidGeneralTable";
            queryParams = {
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoiceUnPaid",
              }),
            };
            break;
          default:
            break;
        }
        let newOptions = {
          page: getDefaultPage({ page: this.state[`${type}Pagination`] }),

          per_page: getDefaultPerPage({
            perPage: this.state[`${type}PerPage`],
          }),

          ...this.getTableSortInfo({ type }),
        };

        if (this.getFilters()) {
          newOptions.q = this.getFilters();
        }

        newOptions.q = newOptions.q
          ? { ...newOptions.q, ...queryParams }
          : queryParams;

        this.props
          .listInvoices({
            options: newOptions,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ [`${type}ListLoading`]: false });

            if (resp.status) {
              if (
                Number(this.state[`${type}Pagination`]) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ [`${type}Pagination`]: 1 }, () => {
                  this.listInvoices({ options: {}, type });
                });
              } else {
                this.setState({
                  [`${type}List`]: mustBeArray(resp.data),
                  [`${type}Total`]: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(200).then(() => {
          this.handleUpdatePersonalization({ notUpdate, updateClaimName });
        });
      }
    );
  };

  invoiceFetchMoreData = ({ isPaginated = false, type, notUpdate }) => {
    this.setState({ [`${type}ListLoading`]: true });
    const { page } = this.state[`${type}Grid`];

    const per_page = this.getInvoiceGridPageSize({ type });

    let sortingParams = this.getInvoiceGridSortInfo({ type });

    let newOptions = { page, per_page, ...sortingParams, q: this.getFilters() };
    let updateClaimName = "";
    switch (type) {
      case "invoicePaid":
        updateClaimName = "invoicePaidGeneralGrid";
        newOptions.q = {
          ...newOptions.q,
          invoiceStatusId: InvoiceStatus.getStatusID({
            status: "invoicePaid",

            userMode: this.props.userMode,
          }),
        };
        break;
      case "invoiceUnPaid":
        updateClaimName = "invoiceUnPaidGeneralGrid";
        newOptions.q = {
          ...newOptions.q,
          invoiceStatusId: InvoiceStatus.getStatusID({
            status: "invoiceUnPaid",

            userMode: this.props.userMode,
          }),
        };
        break;
      default:
        break;
    }

    this.props
      .listInvoices({
        options: newOptions,

        cancelToken: this.signal.token,

        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { [`${type}Grid`]: { ...this.state[`${type}Grid`], page: 1 } },
              () => {
                this.invoiceFetchMoreData({ isPaginated, type });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState[`${type}List`] = data;
                } else {
                  prevState[`${type}List`] =
                    prevState[`${type}List`].concat(data);
                  prevState[`${type}HasMoreData`] = listHasMoreData({
                    data,
                    currentList: prevState[`${type}List`],
                    currentTotal: prevState[`${type}Total`],
                  });
                  prevState[`${type}Grid`] = getConditionalResponse({
                    condition: listHasMoreData({
                      data,
                      currentList: prevState[`${type}List`],
                      currentTotal: prevState[`${type}Total`],
                    }),
                    resp1: {
                      ...prevState[`${type}Grid`],
                      page: prevState[`${type}Grid`].page + 1,
                    },
                    resp2: prevState[`${type}Grid`],
                  });
                }

                prevState[`${type}Total`] = response.total;
                prevState[`${type}ListLoading`] = false;
                return prevState;
              },
              () => {
                this.handleUpdatePersonalization({
                  notUpdate,
                  updateClaimName,
                });
              }
            );
          }
        }
      });
  };

  handleUpdatePersonalization = ({ notUpdate, updateClaimName }) => {
    if (!notUpdate) {
      this.updatePersonalization(updateClaimName);
    }
    return;
  };

  listScrollingGridInvoice = ({ pageLoad, type, notUpdate }) => {
    if (!pageLoad && !this.state[`${type}HasMoreData`]) return false;

    this.setState(
      {
        [`${type}ListLoading`]: true,
        [`${type}Grid`]: {
          ...this.state[`${type}Grid`],
          page: !pageLoad
            ? Number(
                conditionalParameter({
                  data: this.state[`${type}Grid`],
                  field: "page",
                })
              ) + 1
            : Number(
                conditionalParameter({
                  data: this.state[`${type}Grid`],
                  field: "page",
                })
              ),
        },
      },
      () => {
        const grid = this.state[`${type}Grid`];
        const { page } = grid;

        const per_page = this.getInvoiceGridPageSize({ type });

        let sortingParams = this.getInvoiceGridSortInfo({ type });
        let updateClaimName = "";
        let newOptions = {
          page: getDefaultQueryPage({ pageLoad, page }),
          per_page: getScrollingListDefaultPerPage({
            page,
            pageLoad,
            per_page,
          }),

          ...sortingParams,
          q: this.getFilters(),
        };
        switch (type) {
          case "invoicePaid":
            updateClaimName = "invoicePaidGeneralGrid";
            newOptions.q = {
              ...newOptions.q,
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoicePaid",

                userMode: this.props.userMode,
              }),
            };
            break;
          case "invoiceUnPaid":
            updateClaimName = "invoiceUnPaidGeneralGrid";
            newOptions.q = {
              ...newOptions.q,
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoiceUnPaid",

                userMode: this.props.userMode,
              }),
            };
            break;
          default:
            break;
        }

        this.props
          .listInvoices({
            options: newOptions,

            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ [`${type}ListLoading`]: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  {
                    [`${type}Grid`]: { ...this.state[`${type}Grid`], page: 1 },
                  },
                  () => {
                    this.listScrollingGridInvoice({ pageLoad: true, type });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState[`${type}List`] = getConditionalResponse({
                      condition: pageLoad,
                      resp1: data,
                      resp2: prevState[`${type}List`].concat(data),
                    });
                    prevState[`${type}HasMoreData`] = getConditionalResponse({
                      condition:
                        response.total > prevState[`${type}List`].length,
                      resp1: true,
                      resp2: false,
                    });

                    prevState[`${type}Total`] = response.total;
                    return prevState;
                  },
                  () => {
                    this.handleUpdatePersonalization({
                      notUpdate,
                      updateClaimName,
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  listScrollingInvoice = ({
    options = {},
    pageLoad = false,
    type,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state[`${type}HasMoreData`]) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState[`${type}sortInfo`] = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState[`${type}Pagination`] =
            prevState[`${type}Total`] ===
            mustBeArray(prevState[`${type}List`]).length
              ? prevState[`${type}Pagination`]
              : Number(prevState[`${type}Pagination`]) + 1;
        }
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...this.getTableSortInfo({ type }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state[`${type}Pagination`]) *
              Number(this.state[`${type}PerPage`]),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state[`${type}Pagination`],
            per_page: this.state[`${type}PerPage`],
          };
        }
        let updateClaimName = "";
        switch (type) {
          case "invoicePaid":
            updateClaimName = "invoicePaidGeneralTable";
            tempOptions.q = {
              ...tempOptions.q,
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoicePaid",

                userMode: this.props.userMode,
              }),
            };
            break;
          case "invoiceUnPaid":
            updateClaimName = "invoiceUnPaidGeneralTable";
            tempOptions.q = {
              ...tempOptions.q,
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoiceUnPaid",

                userMode: this.props.userMode,
              }),
            };
            break;
          default:
            break;
        }

        this.props
          .listInvoices({
            options: tempOptions,
            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ [`${type}ListLoading`]: false });

            if (resp.status) {
              if (
                Number(this.state[`${type}Pagination`]) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ [`${type}Pagination`]: 1 }, () => {
                  this.listScrollingInvoice({
                    pageLoad: true,
                    type,
                    options: {},
                  });
                });
              } else {
                this.setState(
                  {
                    [`${type}List`]: pageLoad
                      ? mustBeArray(resp.data)
                      : [
                          ...this.state[`${type}List`],

                          ...mustBeArray(resp.data),
                        ],
                    [`${type}Total`]: resp.total,
                  },
                  () => {
                    this.setState({
                      [`${type}HasMoreData`]:
                        this.state[`${type}List`].length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });

        this.handleUpdatePersonalization({ notUpdate, updateClaimName });
      }
    );
  };

  onTabChange = (key) => {
    this.setState({ activeKey: key }, () => {
      this.updatePersonalization("invoiceMultiTabContainer");
    });
  };

  listInvoiceGrid = ({ page, type }) => {
    this.setState(
      (prevState) => {
        prevState[`${type}Grid`].page = page;
        prevState[`${type}ListLoading`] = true;
        return prevState;
      },
      () => {
        let updateClaimName = "";
        let queryParams;
        switch (type) {
          case "invoicePaid":
            updateClaimName = "invoicePaidGeneralGrid";
            queryParams = {
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoicePaid",

                userMode: this.props.userMode,
              }),
            };
            break;
          case "invoiceUnPaid":
            updateClaimName = "invoiceUnPaidGeneralGrid";
            queryParams = {
              invoiceStatusId: InvoiceStatus.getStatusID({
                status: "invoiceUnPaid",

                userMode: this.props.userMode,
              }),
            };
            break;
          default:
            break;
        }

        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state[`${type}Grid`] && this.state[`${type}Grid`].page,
          per_page: this.getInvoiceGridPageSize({ type }),
          q: { ...tempFilters },

          ...this.getInvoiceGridSortInfo({ type }),
        };

        tempOptions.q = tempOptions.q
          ? { ...tempOptions.q, ...queryParams }
          : queryParams;

        this.props
          .listInvoices({
            options: tempOptions,
            cancelToken: this.signal.token,

            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ [`${type}ListLoading`]: false });

            if (resp.status) {
              this.setState({
                [`${type}List`]: mustBeArray(resp.data),
                [`${type}Total`]: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });

        this.updatePersonalization(updateClaimName);
      }
    );
  };

  handleFilterChange = ({ field, value }) => {
    this.setState((prevState) => {
      prevState.filters = { ...prevState.filters, [field]: value };
      return prevState;
    });
  };

  handleFilter = () => {
    this.updatePersonalization("invoiceNameTextboxFilter", true);
    delay(400).then(() => {
      this.updatePersonalization("invoiceDateRangeFilter", true);
    });

    this.setState(
      {
        invoicePaidPagination: 1,
        invoicePaidListLoading: true,

        invoicePaidGrid: {
          ...this.state.invoicePaidGrid,
          page: 1,
          sortInfo: {},
        },
        invoiceUnPaidPagination: 1,
        invoiceUnPaidListLoading: true,

        invoiceUnPaidGrid: {
          ...this.state.invoiceUnPaidGrid,
          page: 1,
          sortInfo: {},
        },
      },
      () => {
        _.forEach(this.state.invoicePayrunTypes, (payrunTypes) => {
          return this.fetchInvoicePayrunTypesList({ ...payrunTypes });
        });
      }
    );
  };

  updateAllPersonalization = () => {
    delay(800).then(() => {
      this.updatePersonalization("invoicePaidGeneralTable", true);
    });
    delay(1100).then(() => {
      this.updatePersonalization("invoicePaidGeneralGrid", true);
    });
    delay(1400).then(() => {
      this.updatePersonalization("invoiceUnPaidGeneralTable", true);
    });
    delay(1800).then(() => {
      this.updatePersonalization("invoiceUnPaidGeneralGrid", true);
    });
  };

  resetFilter = () => {
    this.setState(
      {
        filters: {},
        invoicePaidSortInfo: {},
        invoicePaidSelectedGridSort: undefined,
        invoiceUnPaidSortInfo: {},
        invoiceUnPaidSelectedGridSort: undefined,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  fetchInvoicePayrunTypesList = ({ type, updateClaimName }) => {
    let tempFilters = this.getFilters();
    let options = { page: 1, q: { ...tempFilters } };
    let claimName = updateClaimName;

    if (this.state[`${type}GridView`]) {
      claimName = `${type}GeneralGrid`;

      options = {
        ...options,
        per_page: this.getInvoiceGridPageSize({ type }),
        ...this.getInvoiceGridSortInfo({ type }),
      };
    } else {
      options = {
        ...options,
        per_page: this.state[`${type}PerPage`],
        ...this.getTableSortInfo({ type }),
      };
    }
    switch (type) {
      case "invoicePaid":
        options.q = {
          ...options.q,
          invoiceStatusId: InvoiceStatus.getStatusID({
            status: "invoicePaid",

            userMode: this.props.userMode,
          }),
        };
        break;
      case "invoiceUnPaid":
        options.q = {
          ...options.q,
          invoiceStatusId: InvoiceStatus.getStatusID({
            status: "invoiceUnPaid",

            userMode: this.props.userMode,
          }),
        };
        break;
      default:
        break;
    }

    this.props
      .listInvoices({
        options: options,
        cancelToken: this.signal.token,
        targetEndpoint: this.props.targetEndpoint,
      })
      .then((resp) => {
        this.setState({ [`${type}ListLoading`]: false });

        if (resp.status) {
          this.setState({
            [`${type}List`]: mustBeArray(resp.data),
            [`${type}Total`]: resp.total,
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
    delay(200).then(() => {
      this.updatePersonalization(claimName);
    });
  };

  getFilters = () => {
    let tempFilters = { ...this.state.filters };
    if (!tempFilters.startDate || !tempFilters.endDate) {
      delete tempFilters.startDate;
      delete tempFilters.endDate;
    } else {
      tempFilters.startDate = moment(tempFilters.startDate).format(
        "MM-DD-YYYY"
      );
      tempFilters.endDate = moment(tempFilters.endDate).format("MM-DD-YYYY");
    }
    return { ...tempFilters, name: trimWhiteSpaces(tempFilters.name) };
  };

  getInvoiceGridSortInfo = ({ type }) => {
    if (
      this.state[`${type}Grid`]?.sortInfo?.field &&
      this.state[`${type}Grid`]?.sortInfo?.order &&
      this.state[`${type}SortingType`] !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state[`${type}Grid`].sortInfo.field]:
            this.state[`${type}Grid`].sortInfo.order,
        },
      };
    } else if (
      this.state[`${type}Grid`]?.sortInfo?.order &&
      this.state[`${type}SortingType`] !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state[`${type}Grid`].sortInfo.order,
        },
      };
    } else return {};
  };

  handleInvoiceGridChange = ({ gridView, isPaginated, type }) => {
    if (this.state[`${type}GridView`] === gridView) {
      return false;
    }

    this.setState(
      {
        [`${type}GridView`]: gridView,
        [`${type}List`]: [],
        [`${type}ListLoading`]: true,
      },
      () => {
        let updateClaimName = "";
        switch (type) {
          case "invoicePaid":
            updateClaimName = "invoicePaidListContainer";
            break;
          case "invoiceUnPaid":
            updateClaimName = "invoiceUnPaidListContainer";
            break;
          default:
            break;
        }

        this.updatePersonalization(updateClaimName);
        if (!gridView) {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingInvoice({ pageLoad: true, options: {}, type });
          } else {
            this.listInvoices({ options: {}, type });
          }
        } else {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGridInvoice({
              pageLoad: true,
              options: {},
              type,
            });
          } else {
            this.invoiceFetchMoreData({ isPaginated: true, type });
          }
        }
      }
    );
  };

  invoiceUpdatePageSize = ({ value, type }) => {
    if (this.state[`${type}GridView`]) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState[`${type}Grid`].row = Number(row);
          prevState[`${type}Grid`].col = Number(col);
          prevState[`${type}Grid`].page = 1;
          return prevState;
        },
        () => {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingGridInvoice({
              pageLoad: true,
              options: {},
              type,
            });
          } else {
            this.invoiceFetchMoreData({ isPaginated: true, type });
          }
        }
      );
    } else {
      this.setState(
        { [`${type}PerPage`]: Number(value), [`${type}Pagination`]: 1 },
        () => {
          if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
            this.listScrollingInvoice({ pageLoad: true, options: {}, type });
          } else {
            this.listInvoices({ options: {}, type });
          }
        }
      );
    }
  };

  invoiceUpdateSortOrder = ({ selectedGridSort, type }) => {
    this.setState(
      (prevState) => {
        prevState[`${type}SelectedGridSort`] = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState[`${type}GridSortOption`],
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState[`${type}Grid`] = {
            ...prevState[`${type}Grid`],
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state[`${type}PaginationType`] === PAGINATION_SCROLLING) {
          this.listScrollingGridInvoice({ pageLoad: true, options: {}, type });
        } else {
          this.invoiceFetchMoreData({ isPaginated: true, type });
        }
      }
    );
  };

  handleInvoiceAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/payrun/${data && data.payRunID}`);
        break;
      case "edit":
        this.handleEdit({ data });
        break;
      case "downloadSummary":
        window.open(data && data.summaryDownloadURL);
        break;
      case "downloadPdf":
        window.open(data && data.pdfDownloadUrl);
        break;
      default:
        break;
    }
  };

  updateInvoiceGridField = ({ field, value, type }) => {
    this.setState(
      (prevState) => {
        if (prevState[`${type}Grid`].sortInfo[field] === value) {
          prevState[`${type}Grid`].sortInfo[field] = "";
        } else {
          prevState[`${type}Grid`].sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.invoiceFetchMoreData({ isPaginated: true, type });
      }
    );
  };

  getInvoiceGridPageSize = ({ type }) => {
    const { row, col } = this.state[`${type}Grid`];

    return this.state[`${type}PaginationType`] === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };

  updatePayrunGridField = ({ field, value, type }) => {
    this.setState(
      (prevState) => {
        if (prevState[`${type}Grid`].sortInfo[field] === value) {
          prevState[`${type}Grid`].sortInfo[field] = "";
        } else {
          prevState[`${type}Grid`].sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.invoiceFetchMoreData({ isPaginated: true, type });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;

    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member?.details?.authorizationDOM
      );
      let updatedDOM = [];
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const invoiceFilterContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: INVOICE_FILTER_CONTAINER,
      });
      const invoiceMultiTabContainer = findAuthorizationClaims({
        claims: pageClaims?.components,
        name: "invoiceMultiTabContainer",
      });
      const invoicePaidListContainer = findAuthorizationClaims({
        claims: invoiceMultiTabContainer?.components,
        name: "invoicePaidListContainer",
      });
      const invoiceUnPaidListContainer = findAuthorizationClaims({
        claims: invoiceMultiTabContainer?.components,
        name: "invoiceUnPaidListContainer",
      });

      const invoicePaidGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "invoicePaidGeneralTable"
      );

      const invoicePaidGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "invoicePaidGeneralGrid"
      );

      const invoiceUnPaidGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "invoiceUnPaidGeneralTable"
      );

      const invoiceUnPaidGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "invoiceUnPaidGeneralGrid"
      );
      switch (field) {
        case "invoiceNameTextboxFilter":
          if (invoiceFilterContainer?.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.state.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoiceDateRangeFilter":
          if (invoiceFilterContainer?.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = JSON.stringify([
              `${
                this.state.filters?.startDate
                  ? `${moment(this.state.filters.startDate).format(
                      "DD/MM/YYYY"
                    )}`
                  : ""
              }`,
              `${
                this.state.filters?.endDate
                  ? `${moment(this.state.filters.endDate).format("DD/MM/YYYY")}`
                  : ""
              }`,
            ]);
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoiceMultiTabContainer":
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims?.components,
            name: field,
          });
          break;

        // paid
        case "invoicePaidGeneralTable":
          if (
            invoicePaidListContainer &&
            invoicePaidListContainer.personalizable === "true"
          ) {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.invoicePaidPagination}`,

              sortOrder: this.state.invoicePaidSortInfo?.order,

              sortColumn: this.state.invoicePaidSortInfo?.field,

              pageSize: `${this.state.invoicePaidPerPage}`,
              personalizationData: invoicePaidGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoicePaidGeneralGrid":
          if (invoicePaidListContainer?.personalizable === "true") {
            const { invoicePaidGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${invoicePaidGrid?.page}`,
              row: `${invoicePaidGrid?.row}`,
              col: `${invoicePaidGrid?.col}`,
              sortOrder: invoicePaidGrid?.sortInfo?.order,
              sortColumn: invoicePaidGrid?.sortInfo?.field,
              personalizationData: invoicePaidGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoicePaidListContainer":
          if (invoicePaidListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.invoicePaidGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.invoicePaidPaginationType,

              sortingType: this.state.invoicePaidSortingType,

              isPinned: this.state.invoicePaidIsPinned,
              personalizationData: invoicePaidListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoiceUnPaidGeneralTable":
          if (invoiceUnPaidListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.invoiceUnPaidPagination}`,

              sortOrder: this.state.invoiceUnPaidSortInfo?.order,

              sortColumn: this.state.invoiceUnPaidSortInfo?.field,

              pageSize: `${this.state.invoiceUnPaidPerPage}`,
              personalizationData: invoiceUnPaidGeneralTable?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoiceUnPaidGeneralGrid":
          if (invoiceUnPaidListContainer?.personalizable === "true") {
            const { invoiceUnPaidGrid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${invoiceUnPaidGrid?.page}`,
              row: `${invoiceUnPaidGrid?.row}`,
              col: `${invoiceUnPaidGrid?.col}`,
              sortOrder: invoiceUnPaidGrid?.sortInfo?.order,
              sortColumn: invoiceUnPaidGrid?.sortInfo?.field,
              personalizationData: invoiceUnPaidGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case "invoiceUnPaidListContainer":
          if (invoiceUnPaidListContainer?.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.invoiceUnPaidGridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.invoiceUnPaidPaginationType,

              sortingType: this.state.invoiceUnPaidSortingType,

              isPinned: this.state.invoiceUnPaidIsPinned,
              personalizationData: invoiceUnPaidListContainer.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }

      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update,
        });
      });
    } catch (err) {
      return err;
    }
  };

  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  getTableSortInfo = ({ type }) => {
    const sortInfo = `${type}SortInfo`;

    if (
      this.state[sortInfo]?.field &&
      this.state[sortInfo]?.order &&
      this.state[`${type}SortingType`] !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state[sortInfo].field]: this.state[sortInfo].order,
        },
      };
    } else return {};
  };

  handleEdit = ({ data, type }) => {
    this.props.router.navigate(`/payrun/${data && data.payRunID}`);
  };

  getRowKeyClassName = ({ record, type }) => {
    let classname = "vertical-align-top";
    if (userRoles.CompanyArea === getUserArea()) return classname;
    if (type === "invoicePaid") {
      classname = `${classname} bg-table-success`;
    } else {
      if (record && record.payrollModelID !== PEO_MODEL_ID) return classname;
      if (
        moment().format("DD/MM/YYYY") ===
        moment(record && record.paymentDueDateTime).format("DD/MM/YYYY")
      )
        return `${classname} invoice-bg-light-danger`;
      if (moment().isAfter(moment(record && record.paymentDueDateTime)))
        return `${classname} invoice-bg-danger`;
      const differenceInDays =
        Math.abs(moment().diff(record && record.paymentDueDateTime, "days")) +
        1;
    }
    return classname;
  };

  getGridKeyClassName = ({ record, type }) => {
    let classname = "vertical-align-top";
    if (userRoles.CompanyArea === getUserArea()) return classname;
    if (type === "invoicePaid") {
      classname = `${classname} bg-table-success`;
    } else {
      if (record && record.payrollModelID !== PEO_MODEL_ID) return classname;
      if (
        moment().format("DD/MM/YYYY") ===
        moment(record && record.paymentDueDateTime).format("DD/MM/YYYY")
      )
        return `${classname} invoice-bg-light-danger`;
      if (moment().isAfter(moment(record && record.paymentDueDateTime)))
        return `${classname} invoice-grid-bg-danger`;
      const differenceInDays =
        Math.abs(moment().diff(record && record.paymentDueDateTime, "days")) +
        1;
      classname = getInvoiceRowClassname({
        defaultClassName: classname,
        differenceInDays,
      });
    }
    return classname;
  };

  handleChange = ({ field, value }) => {
    if (field === "dateRange") {
      return this.setState({
        filters: {
          ...this.state.filters,
          startDate: mustBeArray(value)[0],
          endDate: mustBeArray(value)[1],
        },
      });
    }

    return this.setState({
      filters: {
        ...this.state.filters,
        [field]: value,
      },
    });
  };

  render() {
    const { pageClaims, dashboardView, type } = this.props;
    const {
      activeKey,
      searchLoading,
      invoicePaidListLoading,
      invoicePaidList,
      invoicePaidPagination,
      invoicePaidSortInfo,
      invoicePaidPerPage,
      invoicePaidTotal,
      invoicePaidGrid,
      invoicePaidGridable,
      invoicePaidGridView,
      invoicePaidFieldOptions,
      invoicePaidSortingType,
      invoicePaidPaginationType,
      invoicePaidIsPinned,
      invoicePaidHasMoreData,
      invoiceUnPaidListLoading,
      invoiceUnPaidList,
      invoiceUnPaidPagination,
      invoiceUnPaidSortInfo,
      invoiceUnPaidPerPage,
      invoiceUnPaidTotal,
      invoiceUnPaidGrid,
      invoiceUnPaidGridable,
      invoiceUnPaidGridView,
      invoiceUnPaidFieldOptions,
      invoiceUnPaidSortingType,
      invoiceUnPaidPaginationType,
      invoiceUnPaidIsPinned,
      invoiceUnPaidHasMoreData,

      filters,
    } = this.state;
    const invoiceTabContainer = _.find(
      mustBeArray(
        conditionalParameter({ data: pageClaims, field: "components" })
      ),
      (o) => o.name === "invoiceMultiTabContainer"
    );

    // Paid
    const invoicePaidTabContainer = _.find(
      mustBeArray(
        conditionalParameter({ data: invoiceTabContainer, field: "components" })
      ),
      (o) => o.name === "invoicePaidTabContainer"
    );
    const invoicePaidChildTabContainer = _.find(
      mustBeArray(
        conditionalParameter({
          data: invoicePaidTabContainer,
          field: "components",
        })
      ),
      (o) => o.name === "invoicePaidChildTabContainer"
    );

    const invoicePaidCardGrid = getCurrentCardGrid({
      grid: this.state.invoicePaidGrid,
    });

    // Unpaid
    const invoiceUnPaidTabContainer = _.find(
      mustBeArray(
        conditionalParameter({ data: invoiceTabContainer, field: "components" })
      ),
      (o) => o.name === "invoiceUnPaidTabContainer"
    );
    const invoiceUnPaidChildTabContainer = _.find(
      mustBeArray(
        conditionalParameter({
          data: invoiceUnPaidTabContainer,
          field: "components",
        })
      ),
      (o) => o.name === "invoiceUnPaidChildTabContainer"
    );

    const invoiceUnPaidCardGrid = getCurrentCardGrid({
      grid: this.state.invoiceUnPaidGrid,
    });
    const { startDate, endDate } = filters;

    const currentFilters = this.getFilters();
    const filterLabel = filteredByLabel({
      filters: currentFilters.name ? [currentFilters.name] : [],
      startDate: currentFilters.startDate ? currentFilters.startDate : null,
      endDate: currentFilters.endDate,
    });
    const baseUrl = APIHandler.constructEndpoint({
      endpoint: "PAYRUN_INVOICES_URL",
    });
    const invoiceUnPaidTargetEndpoint = requestUrlBuilder(baseUrl, {
      q: {
        invoiceStatusId: InvoiceStatus.getStatusID({
          status: "invoiceUnPaid",

          userMode: this.props.userMode,
        }),

        ...this.getFilters(),
      },
    });
    const invoicePaidTargetEndpoint = requestUrlBuilder(baseUrl, {
      q: {
        invoiceStatusId: InvoiceStatus.getStatusID({
          status: "invoicePaid",

          userMode: this.props.userMode,
        }),

        ...this.getFilters(),
      },
    });
    return (
      <ErrorBoundary>
        <If
          condition={dashboardView && type === "paid"}
          then={
            <ListContainer
              name="invoicePaidListContainer"
              claims={invoicePaidChildTabContainer}
              isPinned={invoicePaidIsPinned}
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={invoicePaidGridable}
              gridView={invoicePaidGridView}
              grid={invoicePaidGrid}
              per_page={invoicePaidPerPage}
              GeneralTablePaginatedComponent={InvoiceList}
              GeneralTableScrollableComponent={InvoiceList}
              generalTableComponentName={"invoicePaidGeneralTable"}
              gridComponentName={"invoicePaidGeneralGrid"}
              handleAddAction={this.handlePayrunCreate}
              handleGridChange={(options) =>
                this.handleInvoiceGridChange({
                  ...options,
                  type: "invoicePaid",
                })
              }
              fieldOptions={invoicePaidFieldOptions}
              updateGridField={(options) =>
                this.updateInvoiceGridField({ ...options, type: "invoicePaid" })
              }
              sortingType={invoicePaidSortingType}
              paginationType={invoicePaidPaginationType}
              list={invoicePaidList}
              listLoading={invoicePaidListLoading}
              listGrid={(page) =>
                this.listInvoiceGrid({ page, type: "invoicePaid" })
              }
              listScrollingGrid={(options) =>
                this.listScrollingGridInvoice({
                  ...options,
                  type: "invoicePaid",
                })
              }
              hasMoreData={invoicePaidHasMoreData}
              gridActionComponentName={"UserCompanyViewDetailLink"}
              handleGridAction={(options) =>
                this.handleInvoiceAction({ ...options, type: "invoicePaid" })
              }
              listScrollingGeneralTable={(options) =>
                this.listScrollingInvoice({ ...options, type: "invoicePaid" })
              }
              total={invoicePaidTotal}
              current={invoicePaidPagination}
              sortInfo={invoicePaidSortInfo}
              updatePageSize={(value) =>
                this.invoiceUpdatePageSize({ value, type: "invoicePaid" })
              }
              listGeneralTable={({ options }) =>
                this.listInvoices({ options, type: "invoicePaid" })
              }
              gridPageSize={this.getInvoiceGridPageSize({
                type: "invoicePaid",
              })}
              cardGrid={invoicePaidCardGrid}
              viewComponent="invoicePaidDetailView"
              pinnedComponentName="invoicePaidIsPinned"
              showAddAndGrid={true}
              DisplayCardComponent={InvoiceDisplayCard}
              handleActions={(options) =>
                this.handleInvoiceAction({ ...options, type: "invoicePaid" })
              }
              handleEdit={(data) =>
                this.handleEdit({ data, type: "invoicePaid" })
              }
              rowKeyClassName="bg-table-success vertical-align-top"
              getRowKeyClassName={(record) =>
                this.getRowKeyClassName({ record, type: "invoicePaid" })
              }
              getGridClassName={(record) =>
                this.getGridKeyClassName({ record, type: "invoicePaid" })
              }
              dashboardView={dashboardView}
              targetEndpoint={this.props.targetEndpoint}
              componentTitle={this.props.componentTitle}
              filterApplied={this.props.filterApplied}
              SingleLineGridDisplayComponent={InvoiceSingleLineGridDisplay}
              singleLineGridView={this.state.invoicePaidSingleLineGridView}
              rowIdParam="id"
            />
          }
        />
        <If
          condition={dashboardView && type === "unPaid"}
          then={
            <ListContainer
              name="invoiceUnPaidListContainer"
              claims={invoiceUnPaidChildTabContainer}
              isPinned={invoiceUnPaidIsPinned}
              updatePinnedComponent={this.updatePinnedComponent}
              gridable={invoiceUnPaidGridable}
              gridView={invoiceUnPaidGridView}
              grid={invoiceUnPaidGrid}
              per_page={invoiceUnPaidPerPage}
              GeneralTablePaginatedComponent={InvoiceList}
              GeneralTableScrollableComponent={InvoiceList}
              generalTableComponentName={"invoiceUnPaidGeneralTable"}
              gridComponentName={"invoiceUnPaidGeneralGrid"}
              handleAddAction={this.handlePayrunCreate}
              handleGridChange={(options) =>
                this.handleInvoiceGridChange({
                  ...options,
                  type: "invoiceUnPaid",
                })
              }
              fieldOptions={invoiceUnPaidFieldOptions}
              updateGridField={(options) =>
                this.updateInvoiceGridField({
                  ...options,
                  type: "invoiceUnPaid",
                })
              }
              sortingType={invoiceUnPaidSortingType}
              paginationType={invoiceUnPaidPaginationType}
              list={invoiceUnPaidList}
              listLoading={invoiceUnPaidListLoading}
              listGrid={(page) =>
                this.listInvoiceGrid({ page, type: "invoiceUnPaid" })
              }
              listScrollingGrid={(options) =>
                this.listScrollingGridInvoice({
                  ...options,
                  type: "invoiceUnPaid",
                })
              }
              hasMoreData={invoiceUnPaidHasMoreData}
              gridActionComponentName={"UserCompanyViewDetailLink"}
              handleGridAction={(options) =>
                this.handleInvoiceAction({ ...options, type: "invoiceUnPaid" })
              }
              listScrollingGeneralTable={(options) =>
                this.listScrollingInvoice({ ...options, type: "invoiceUnPaid" })
              }
              total={invoiceUnPaidTotal}
              current={invoiceUnPaidPagination}
              sortInfo={invoiceUnPaidSortInfo}
              updatePageSize={(value) =>
                this.invoiceUpdatePageSize({ value, type: "invoiceUnPaid" })
              }
              listGeneralTable={({ options }) =>
                this.listInvoices({ options, type: "invoiceUnPaid" })
              }
              tableActionComponentName={"userCompanyViewDetailLink"}
              gridPageSize={this.getInvoiceGridPageSize({
                type: "invoiceUnPaid",
              })}
              cardGrid={invoiceUnPaidCardGrid}
              // editComponent="invoiceUnPaidEditIconButton"
              viewComponent="invoiceUnPaidDetailView"
              pinnedComponentName="invoiceUnPaidIsPinned"
              showAddAndGrid={true}
              DisplayCardComponent={InvoiceDisplayCard}
              handleActions={(options) =>
                this.handleInvoiceAction({ ...options, type: "unPaid" })
              }
              handleEdit={(data) => this.handleEdit({ data, type: "unPaid" })}
              rowKeyClassName="vertical-align-top"
              getRowKeyClassName={(record) =>
                this.getRowKeyClassName({ record, type: "invoiceUnPaid" })
              }
              getGridClassName={(record) =>
                this.getGridKeyClassName({ record, type: "invoiceUnPaid" })
              }
              dashboardView={dashboardView}
              componentTitle={this.props.componentTitle}
              filterApplied={this.props.filterApplied}
              SingleLineGridDisplayComponent={InvoiceSingleLineGridDisplay}
              singleLineGridView={this.state.invoiceUnPaidSingleLineGridView}
              targetEndpoint={this.props.targetEndpoint}
              rowIdParam="id"
            />
          }
        />
        <If
          condition={!dashboardView}
          then={
            <div className="tab-top">
              <FilterContainer
                name={INVOICE_FILTER_CONTAINER}
                claims={pageClaims}
              >
                <SingleFieldTextBoxFilter
                  placeholder="Keywords"
                  name="invoiceNameTextboxFilter"
                  claims={this.props.claims}
                  value={this.state.filters?.name}
                  onChange={(value) =>
                    this.handleChange({ field: "name", value })
                  }
                  onSearch={this.handleFilter}
                  className="mb-[15px] w-250"
                />

                <DateRangepickerFilter
                  placeholder="Start - End date"
                  name="invoiceDateRangeFilter"
                  claims={this.props.claims}
                  value={[startDate, endDate]}
                  onChange={(value) =>
                    this.handleChange({ field: "dateRange", value })
                  }
                  onSearch={this.handleFilter}
                  className="mb-[15px] w-[240px] -ml-4"
                />

                <SearchIconButton
                  loading={searchLoading}
                  onClick={this.handleFilter}
                  name="invoiceSearchButton"
                  claims={this.props.claims}
                  className="ml-4"
                />
                <LinkButton
                  buttonText="Reset Filter"
                  className="color-primary hand"
                  onClick={this.resetFilter}
                  name="invoiceResetFilter"
                  claims={this.props.claims}
                />
              </FilterContainer>
              <MultiTabContainer
                name="invoiceMultiTabContainer"
                claims={pageClaims}
                activeKey={activeKey}
                handleTabChange={this.onTabChange}
              >
                {isComponentVisible({ claim: invoicePaidTabContainer }) && (
                  <TabPane
                    tab={<TabHeader title="Paid" total={invoicePaidTotal} />}
                    key={"1"}
                  >
                    <ChildTabContainer
                      name="invoicePaidChildTabContainer"
                      claims={invoicePaidTabContainer}
                    >
                      <ListContainer
                        name="invoicePaidListContainer"
                        claims={invoicePaidChildTabContainer}
                        isPinned={invoicePaidIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={invoicePaidGridable}
                        gridView={invoicePaidGridView}
                        grid={invoicePaidGrid}
                        per_page={invoicePaidPerPage}
                        GeneralTablePaginatedComponent={InvoiceList}
                        GeneralTableScrollableComponent={InvoiceList}
                        generalTableComponentName={"invoicePaidGeneralTable"}
                        gridComponentName={"invoicePaidGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handleInvoiceGridChange({
                            ...options,
                            type: "invoicePaid",
                          })
                        }
                        fieldOptions={invoicePaidFieldOptions}
                        updateGridField={(options) =>
                          this.updateInvoiceGridField({
                            ...options,
                            type: "invoicePaid",
                          })
                        }
                        sortingType={invoicePaidSortingType}
                        paginationType={invoicePaidPaginationType}
                        list={invoicePaidList}
                        listLoading={invoicePaidListLoading}
                        listGrid={(page) =>
                          this.listInvoiceGrid({ page, type: "invoicePaid" })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridInvoice({
                            ...options,
                            type: "invoicePaid",
                          })
                        }
                        hasMoreData={invoicePaidHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={(options) =>
                          this.handleInvoiceAction({
                            ...options,
                            type: "invoicePaid",
                          })
                        }
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingInvoice({
                            ...options,
                            type: "invoicePaid",
                          })
                        }
                        total={invoicePaidTotal}
                        current={invoicePaidPagination}
                        sortInfo={invoicePaidSortInfo}
                        updatePageSize={(value) =>
                          this.invoiceUpdatePageSize({
                            value,
                            type: "invoicePaid",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listInvoices({ options, type: "invoicePaid" })
                        }
                        gridPageSize={this.getInvoiceGridPageSize({
                          type: "invoicePaid",
                        })}
                        cardGrid={invoicePaidCardGrid}
                        // editComponent="invoicePaidEditIconButton"
                        viewComponent="invoicePaidDetailView"
                        pinnedComponentName="invoicePaidIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={InvoiceDisplayCard}
                        handleActions={(options) =>
                          this.handleInvoiceAction({
                            ...options,
                            type: "invoicePaid",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "invoicePaid" })
                        }
                        rowKeyClassName="bg-table-success vertical-align-top"
                        getRowKeyClassName={(record) =>
                          this.getRowKeyClassName({
                            record,
                            type: "invoicePaid",
                          })
                        }
                        getGridClassName={(record) =>
                          this.getGridKeyClassName({
                            record,
                            type: "invoicePaid",
                          })
                        }
                        filterLabel={filterLabel}
                        title={this.props.title || "Paid Invoices"}
                        showTitle={this.props.showTitle}
                        targetEndpoint={invoicePaidTargetEndpoint}
                        hasNoDuplicateEntity={true}
                        componentTitle="Paid Invoices"
                        filterApplied={filterLabel}
                        SingleLineGridDisplayComponent={
                          InvoiceSingleLineGridDisplay
                        }
                        singleLineGridView={
                          this.state.invoicePaidSingleLineGridView
                        }
                        rowIdParam="id"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
                {isComponentVisible({ claim: invoiceUnPaidTabContainer }) && (
                  <TabPane
                    tab={
                      <TabHeader title="Unpaid" total={invoiceUnPaidTotal} />
                    }
                    key={"2"}
                  >
                    <ChildTabContainer
                      name="invoiceUnPaidChildTabContainer"
                      claims={invoiceUnPaidTabContainer}
                    >
                      <ListContainer
                        name="invoiceUnPaidListContainer"
                        claims={invoiceUnPaidChildTabContainer}
                        isPinned={invoiceUnPaidIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={invoiceUnPaidGridable}
                        gridView={invoiceUnPaidGridView}
                        grid={invoiceUnPaidGrid}
                        per_page={invoiceUnPaidPerPage}
                        GeneralTablePaginatedComponent={InvoiceList}
                        GeneralTableScrollableComponent={InvoiceList}
                        generalTableComponentName={"invoiceUnPaidGeneralTable"}
                        gridComponentName={"invoiceUnPaidGeneralGrid"}
                        handleAddAction={this.handlePayrunCreate}
                        handleGridChange={(options) =>
                          this.handleInvoiceGridChange({
                            ...options,
                            type: "invoiceUnPaid",
                          })
                        }
                        fieldOptions={invoiceUnPaidFieldOptions}
                        updateGridField={(options) =>
                          this.updateInvoiceGridField({
                            ...options,
                            type: "invoiceUnPaid",
                          })
                        }
                        sortingType={invoiceUnPaidSortingType}
                        paginationType={invoiceUnPaidPaginationType}
                        list={invoiceUnPaidList}
                        listLoading={invoiceUnPaidListLoading}
                        listGrid={(page) =>
                          this.listInvoiceGrid({ page, type: "invoiceUnPaid" })
                        }
                        listScrollingGrid={(options) =>
                          this.listScrollingGridInvoice({
                            ...options,
                            type: "invoiceUnPaid",
                          })
                        }
                        hasMoreData={invoiceUnPaidHasMoreData}
                        gridActionComponentName={"UserCompanyViewDetailLink"}
                        handleGridAction={(options) =>
                          this.handleInvoiceAction({
                            ...options,
                            type: "invoiceUnPaid",
                          })
                        }
                        listScrollingGeneralTable={(options) =>
                          this.listScrollingInvoice({
                            ...options,
                            type: "invoiceUnPaid",
                          })
                        }
                        total={invoiceUnPaidTotal}
                        current={invoiceUnPaidPagination}
                        sortInfo={invoiceUnPaidSortInfo}
                        updatePageSize={(value) =>
                          this.invoiceUpdatePageSize({
                            value,
                            type: "invoiceUnPaid",
                          })
                        }
                        listGeneralTable={({ options }) =>
                          this.listInvoices({ options, type: "invoiceUnPaid" })
                        }
                        tableActionComponentName={"userCompanyViewDetailLink"}
                        gridPageSize={this.getInvoiceGridPageSize({
                          type: "invoiceUnPaid",
                        })}
                        cardGrid={invoiceUnPaidCardGrid}
                        // editComponent="invoiceUnPaidEditIconButton"
                        viewComponent="invoiceUnPaidDetailView"
                        pinnedComponentName="invoiceUnPaidIsPinned"
                        showAddAndGrid={true}
                        DisplayCardComponent={InvoiceDisplayCard}
                        handleActions={(options) =>
                          this.handleInvoiceAction({
                            ...options,
                            type: "unPaid",
                          })
                        }
                        handleEdit={(data) =>
                          this.handleEdit({ data, type: "unPaid" })
                        }
                        rowKeyClassName="vertical-align-top"
                        getRowKeyClassName={(record) =>
                          this.getRowKeyClassName({
                            record,
                            type: "invoiceUnPaid",
                          })
                        }
                        getGridClassName={(record) =>
                          this.getGridKeyClassName({
                            record,
                            type: "invoiceUnPaid",
                          })
                        }
                        filterLabel={filterLabel}
                        title={this.props.title || "Unpaid Invoices"}
                        showTitle={this.props.showTitle}
                        targetEndpoint={invoiceUnPaidTargetEndpoint}
                        hasNoDuplicateEntity={true}
                        componentTitle="Unpaid Invoices"
                        filterApplied={filterLabel}
                        SingleLineGridDisplayComponent={
                          InvoiceSingleLineGridDisplay
                        }
                        singleLineGridView={
                          this.state.invoiceUnPaidSingleLineGridView
                        }
                        rowIdParam="id"
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
              </MultiTabContainer>
            </div>
          }
        />
      </ErrorBoundary>
    );
  }
}

export default withRouter(PayrunInvoice);
