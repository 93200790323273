//@ts-nocheck
import { useState, useEffect } from "react";
import {
  PayrunAmendmentDetail,
  PayrunPrepareDetail,
  PayrunApprovalDetail,
  PayrunProcessCompleteDetail,
} from "../../components/payrun/status";
import PayrunStatus from "../../../Common/payrunStatus";
import { Spin } from "antd";
import { Loading } from "../../../Common/ui";
import {
  getConditionalResponse,
  getPayrunDisplayStatus,
  errorHandler,
  getMatchParams,
  getUserArea,
  mustBeArray,
} from "../../../libs/utilities";
import { handleRequest, isCompanyUser } from "../../../libs";
import {
  DEFAULT_ROUTES,
  GOOGLE_ANALYTICS_PAGE_TITLE,
  MAX_AMENDMENT_PER_PAGE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { RootState } from "../../../store";

type Props = any;

const PayrunDetailIndex = (props: Props) => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const [amendmentSummaryLoading, setAmendmentSummaryLoading] = useState(true);
  const [payrunIntegrationSettings, setPayrunIntegrationSettings] =
    useState<any>({});
  const [amendmentIntegrationSettings, setAmendmentIntegrationSettings] =
    useState<any>({});
  useState<any>({});
  const { header } = useAppSelector((state: RootState) => state.pageHeader);

  useEffect(() => {
    const id = getMatchParams({ match: props.match, field: "id" });
    const currentTitle = header?.title;

    props.updateHeader({
      header: {
        title: "",
        module: getConditionalResponse({
          condition: currentTitle === "Invoice",
          resp1: "Invoices",
          resp2: "Pay Run",
        }),
        enableBack: true,
        action: "",
        returnUrl: getConditionalResponse({
          condition: currentTitle === "Invoice",
          resp1: "/invoices",
          resp2: `/payrun`,
        }),
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayRunDetail,
      },
    });

    props
      .getPayrun({ id, options: {}, payrunDetailLoading: true })
      .then((resp) => {
        // If there is an error while fetching pay run details, redirect the user to home screen
        if (
          !handleRequest({
            response: resp,
            hasValidationErrors: true,
          })
        ) {
          // Add a delay for the users to see the message in the same screen
          setTimeout(() => {
            navigate(DEFAULT_ROUTES.PAYRUN);
          }, 2000);
          return;
        }
        if (resp.status && resp.data) {
          let status = getPayrunDisplayStatus({
            status: PayrunStatus.getStatus({ id: resp.data.payrunStatusID }),
          });

          if (status === "Draft") {
            return navigate(
              `/company/${resp.data.companyID}/payrun/${resp.data.payrunId}/edit`
            );
          }
          // Get Integration settings for SA and PSP Area

          if (!isCompanyUser()) {
            props
              .getIntegrationSettings(resp.data.businessUnitID)
              .then((resp) => {
                if (
                  handleRequest({ response: resp, hasValidationErrors: true })
                ) {
                  const payrunSettings = mustBeArray(resp.data?.result).find(
                    (o) => o.settingName === "payrun"
                  );
                  setPayrunIntegrationSettings(payrunSettings);
                  // TODO: Investigate if we need to store values for amendmentSettings.
                  // It may not be of any use.
                  const amendmentSettings = mustBeArray(resp.data?.result).find(
                    (o) => o.settingName === "amendment"
                  );
                  setAmendmentIntegrationSettings(amendmentSettings);
                }
              });
          }

          props.updateHeader({
            header: {
              title: `${resp.data.name}`,
              enableBack: true,
              action: "",
            },
          });

          props.getBusinessUnit({
            id: resp.data.businessUnitID,
            cancelToken: props.signal.token,
          });
        }

        props
          .listPayrunAmendments({
            id,
            // Once Amendments are submitted, We need to fetch all the amendments in the following screen (no pagination required)
            options: ![1, 2, 3].includes(resp?.data?.payrunStatusID)
              ? { per_page: MAX_AMENDMENT_PER_PAGE }
              : {},
            cancelToken: props.signal.token,
          })
          .then((res) => {
            setPageLoading(false);

            if (res.status) {
              setAmendmentSummaryLoading(false);
            } else {
              errorHandler({ response: res, hasValidationErrors: true });
            }
          });
      });
  }, []);

  const userArea = getUserArea();
  const status = PayrunStatus.getStatus({
    id: props.payrun?.payrunStatusID,
    userArea,
  });

  if (pageLoading) {
    return <Loading />;
  } else {
    switch (status) {
      case "amendments":
        return <PayrunAmendmentDetail {...props} />;
      case "prepare":
        return (
          <PayrunPrepareDetail
            status={status}
            amendmentSummaryLoading={amendmentSummaryLoading}
            payrunIntegrationSettings={payrunIntegrationSettings}
            amendmentIntegrationSettings={amendmentIntegrationSettings}
            {...props}
          />
        );
      case "approval":
        return (
          <PayrunApprovalDetail
            {...props}
            status={status}
            amendmentSummaryLoading={amendmentSummaryLoading}
          />
        );
      case "process":
      case "payment":
      case "invoicePaid":
      case "invoiceUnpaid":
      case "completed":
        return (
          <PayrunProcessCompleteDetail
            {...props}
            status={status}
            amendmentSummaryLoading={amendmentSummaryLoading}
          />
        );
      default:
        return <Spin />;
    }
  }
};

export default PayrunDetailIndex;
