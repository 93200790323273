import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useGetPayrunMilestonesQuery } from '@xemplo/payrun-query';

import * as S from './payrun-stepper.styles';
import { PayrunStepperProps, PayrunStepperVariant } from './payrun-stepper.types';
import { SingleStep } from './payrun-stepper-step';
import { PayrunStepperTestId } from './test';

export function PayrunStepper(props: Readonly<PayrunStepperProps>) {
  const { variant: mode = PayrunStepperVariant.Stacked, className } = props;
  const { id } = useParams();
  const { data } = useGetPayrunMilestonesQuery({ id: id ?? null });

  if (!data) return null;

  return (
    <S.Layout
      className={classNames(
        { stacked: mode === PayrunStepperVariant.Stacked },
        className
      )}
      data-testid={PayrunStepperTestId.container}
    >
      {data.steps.map((step, index) => (
        <SingleStep {...step} index={index} key={step.step} />
      ))}
    </S.Layout>
  );
}
