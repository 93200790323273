//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import { lookUpHandler } from "../actions/lookup";
import {
  updateAuthorizationClaim,
  updateHeader,
} from "../../User/actions/member";
import { varianceHandler } from "../actions/variance";
import { withRouter } from "../../hooks";

class Variance extends SwiftContainer {
  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        {...this.props}
        varianceHandler={this.props.varianceHandler}
        pageClaims={pageClaims}
        match={{ params: this.props.router.params }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  member: state.member,
});

const mapDispatchToProps = {
  varianceHandler,
  lookUpHandler,
  updateHeader,
  updateAuthorizationClaim,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Variance)
);
