import { InputNumber, Form } from "antd";
import {
  validateInputValueIncludesOnlyNumbers,
  numberFormatter,
} from "../../../libs";
import { MAX_NUMBER_VALUE } from "../../../constants";

type Props = {
  required?: boolean;
};

const UnitField = ({ required = false }: Props) => {
  return (
    <Form.Item
      label="Unit/s"
      name="units"
      initialValue={1}
      className="mb-4  col-start-1"
      rules={[
        {
          required,
          message: "Please enter unit",
        },
      ]}
    >
      <InputNumber
        formatter={numberFormatter}
        precision={2}
        parser={(value) => (value || "")?.replace(/\$\s?|(,*)/g, "")}
        className="w-full hideNumberPointers"
        onKeyDown={(e) =>
          validateInputValueIncludesOnlyNumbers({
            e,
            hasDecimal: true,
            isPositive: true,
            maxAmount: MAX_NUMBER_VALUE,
          })
        }
      />
    </Form.Item>
  );
};

export default UnitField;
