import { forwardRef, Ref, useMemo } from 'react';

import { useModal } from '../hooks';

import { StyledModalBody } from './body.style';
import { ModalBodyOverflow } from './body.types';

export const ModalBodyTestId = {
  body: 'modal-body',
};

export const ModalBody = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const { state } = useModal();
  const { body } = state;

  const overflow = useMemo(
    () => body?.overflow || ModalBodyOverflow.Standard,
    [body?.overflow]
  );

  return (
    <StyledModalBody
      ref={ref}
      data-testid={ModalBodyTestId.body}
      data-overflow={overflow}
      className="modal-body"
      $textColor={body?.color}
    >
      {body?.content}
    </StyledModalBody>
  );
});

export default ModalBody;
