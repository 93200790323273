import styled, { keyframes } from 'styled-components';

const grow = keyframes`
 0%, 50% {
    transform: scale(1);
 }
 25% {
    transform: scale(3);
 }
`;

export const Dot = styled.span<{
  $colour: string;
  $baseSize: number;
}>`
  display: inline-block;
  border-radius: 50%;
  animation: ${grow} 0.8s ease-out infinite;
  background: ${({ $colour }) => $colour};
  width: ${({ $baseSize }) => `${$baseSize}px`};
  height: ${({ $baseSize }) => `${$baseSize}px`};
  margin-right: ${({ $baseSize }) => `${$baseSize * 4}px`};

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

export const FullScreenContainer = styled.div`
  height: 100dvh;
`;

export const XemploLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100dvh - 40px);
  width: 100dvw;
  padding-bottom: 40px;
`;
