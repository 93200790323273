import React from "react";
import _ from "lodash";
import {
  currencyFormater,
  getConditionalResponse,
  mustBeArray,
} from "../../../libs/utilities";
import { AMENDMENT_AMOUNT_CURRENCY_TYPE } from "../../../constants/payrun";
import { getYesNoText } from "../../../libs/general";
import { If } from "../../../Common/ui";

type Props = {
  questionList: any;
  businessunitDetails: any;
};

const getTextName = ({ options, value, textValue }: any) => {
  let textName: string = "";
  const selectedTextName = _.find(
    mustBeArray(options),
    (o) => o.id === value
  )?.name;
  if (selectedTextName) {
    if (selectedTextName === "Other") {
      textName = textValue;
    } else {
      textName = selectedTextName;
    }
  }
  return textName;
};

const QuestionListAnswer = ({ questionList, businessunitDetails }: Props) => {
  const setElemenet = () => {
    switch (questionList.type.toLowerCase()) {
      case "dropdown":
        const name = questionList?.value ? (
          _.find(questionList.options, (o) => o.id === questionList.value).name
        ) : (
          <React.Fragment />
        );

        return <div>{name}</div>;
      case "number":
      case AMENDMENT_AMOUNT_CURRENCY_TYPE:
        return (
          <div className="word-break">
            {getConditionalResponse({
              condition: ["Amount", "HD Amount"].includes(questionList.label),
              resp1: currencyFormater(
                questionList.value,
                businessunitDetails && businessunitDetails.country
              ),
              resp2: questionList?.value ?? "",
            })}
          </div>
        );
      case "switch":
        return (
          <div>
            {getConditionalResponse({
              condition: questionList?.value,
              resp1: "Yes",
              resp2: "No",
            })}
          </div>
        );
      case "yesno":
        return (
          <div>
            {getYesNoText({ value: questionList?.value, defaultValue: "" })}
          </div>
        );
      case "radio":
        const radioName = mustBeArray(questionList.options).find(
          (o) => o.id === questionList.value
        )?.name;
        return (
          <div>
            {getConditionalResponse({
              condition: radioName,
              resp1: radioName,
              resp2: <React.Fragment />,
            })}
          </div>
        );
      case "checkbox":
        let options = _.filter(questionList.options, (o) => o.value);
        return (
          <div>
            {mustBeArray(options).map((value, i) => {
              return (
                <If
                  condition={Number(i) === 0}
                  then={
                    <span className="mb-3.5" key={i}>
                      {value.name}
                    </span>
                  }
                  else={
                    <span className="mb-3.5" key={i}>
                      , {value.name}
                    </span>
                  }
                />
              );
            })}
          </div>
        );
      case "keypairlistdropdown":
        return mustBeArray(questionList.options).map((option: any, i: any) => {
          return (
            <div key={i}>
              <div>
                {getConditionalResponse({
                  condition: option.value,
                  resp1: option.value,
                  resp2: <React.Fragment />,
                })}
              </div>
            </div>
          );
        });
      case "multiselectdropdown":
        return (
          <div>
            {mustBeArray(questionList?.value).map((value: any, i: any) => {
              return (
                <If
                  condition={Number(i) === 0}
                  then={
                    <span className="mb-3.5" key={i}>
                      {
                        _.find(questionList?.options, (o) => o.id === value)
                          .name
                      }
                    </span>
                  }
                  else={
                    <span className="mb-3.5" key={i}>
                      ,{" "}
                      {
                        _.find(questionList?.options, (o) => o.id === value)
                          .name
                      }
                    </span>
                  }
                />
              );
            })}
          </div>
        );
      case "file":
        return (
          <div>
            {mustBeArray(questionList?.value).map((value: any, i: any) => {
              return (
                <div key={i}>
                  <a href={value} target="__blank">
                    Click here to download
                  </a>
                </div>
              );
            })}
          </div>
        );
      case "dropdownwithotherswithtext":
      case "dropdownwithotherswithtextarea":
        return (
          <div className="word-break">
            {getTextName({
              options: questionList.options,
              value: questionList.value,
              textValue: questionList.textValue,
            })}
          </div>
        );
      default:
        return (
          <div className="word-break">
            {getConditionalResponse({
              condition: questionList?.value,
              resp1: questionList.value,
              resp2: <React.Fragment />,
            })}
          </div>
        );
    }
  };

  return <>{setElemenet()}</>;
};

export default QuestionListAnswer;
