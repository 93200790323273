import React from "react";
import { Card, Tooltip, Typography, Avatar, Badge } from "antd";
import GridActionComponent from "../common/gridAction";
import { ContractIcon } from "../../../Common/customicons";
import { If } from "../../../Common/ui";
import { isSettingsIntegrated } from "../../../libs";

const { Title } = Typography;

const PayrollConnectionDisplayCard = ({
  handleAction,
  content,
  claims,
  editComponent,
  payrunIntegrationSettingComponent,
  amendmentIntegrationSettingComponent,
}: any) => {
  return (
    <Card
      className="card  hand card-prop"
      bordered={false}
      onClick={() => handleAction({ data: content, action: "view" })}
    >
      <div className="position-relative pl-0 pr-0 pt-3 pb-3">
        <div className={"mb-2.5 px-[50px] position-relative"}>
          <div className="position-relative">
            <Tooltip placement="top" title={content?.name}>
              <Title ellipsis className="card-title" level={3}>
                {content?.name}
              </Title>
            </Tooltip>
          </div>

          <Avatar
            className="position-absolute-top position-absolute-top-left"
            size={36}
          >
            <ContractIcon className="text-lg" />
          </Avatar>
        </div>
        <div className="mt-6">
          <If
            condition={content?.isActive}
            then={
              <Badge
                count={"Active"}
                className={"text-xs badge badge-success"}
              />
            }
            else={
              <Badge
                count={"InActive"}
                className={"text-xs badge badge-danger"}
              />
            }
          />
        </div>
      </div>
      <GridActionComponent
        record={content}
        classname="card-item mt-4 mr-4 position-absolute-top position-absolute-top-right"
        buttonClassName="card-prop animated fadeIn"
        claims={claims}
        handleAction={(options: any) =>
          handleAction({ ...options, data: content, record: content })
        }
        editComponentNameGrid={editComponent}
        payrunIntegrationSettingComponent={payrunIntegrationSettingComponent}
        amendmentIntegrationSettingComponent={
          amendmentIntegrationSettingComponent
        }
        payrunIntegrationSettingValue={isSettingsIntegrated({
          record: content.integrationSettings,
          type: "payrun",
        })}
        amendmentIntegrationSettingValue={isSettingsIntegrated({
          record: content.integrationSettings,
          type: "amendment",
        })}
      />
    </Card>
  );
};

export default PayrollConnectionDisplayCard;
