export const PAYROLL_PROCESSING_COUNTRIES = [
  {
    id: 13,
    code: "AU",
    value: "Australia",
    sortOrder: 12,
    enabled: true,
    selected: false,
  },
  {
    id: 156,
    code: "NZ",
    value: "New Zealand",
    sortOrder: 155,
    enabled: true,
    selected: false,
  },
  // { id: 195, code: "SG", value: "Singapore", sortOrder: 194, enabled: true, selected: false },
  {
    id: 227,
    code: "GB",
    value: "United Kingdom",
    sortOrder: 226,
    enabled: true,
    selected: false,
  },
];

export const PAYROLL_PROCESSING_SYSTEMS = [
  {
    name: "Keypay",
    checked: false,
    id: "1",
  },
  // {
  //   name: "Xero",
  //   id: "2",
  //   checked: false
  // },
  // {
  //   name: "MYOB",
  //   id: "3",
  //   checked: false
  // }
];
