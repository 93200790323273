import { lazy, Suspense } from 'react';

import { EditButtonFactoryProps } from './edit-button.types';

const EditAmendment = lazy(() =>
  import('@xemplo/edit-amendment-modal').then((m) => ({ default: m.EditAmendmentModal }))
);
const EditTermination = lazy(() =>
  import('@xemplo/edit-termination-modal').then((m) => ({
    default: m.EditTerminationModal,
  }))
);
const EditPayInstruction = lazy(() =>
  import('@xemplo/edit-pay-instruction-modal').then((m) => ({
    default: m.EditPayInstructionModal,
  }))
);

export const LazyEditButtonMap = {
  amendment: EditAmendment,
  termination: EditTermination,
  payInstruction: EditPayInstruction,
};

export const EditButtonFactory = (props: EditButtonFactoryProps) => {
  const { type, amendmentId } = props;
  if (!type) return null;
  const EditButton = LazyEditButtonMap[type];
  return (
    <Suspense>
      <EditButton amendmentId={amendmentId} />
    </Suspense>
  );
};
