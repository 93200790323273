import {
  REOCCURRING_STATUS_APPLIED_FAIL_ID,
  REOCCURRING_STATUS_APPLIED_ID,
  REOCCURRING_STATUS_APPLIED_PARTIAL_ID,
  REOCCURRING_STATUS_APPLIED_SUCCESS_ID,
  REOCCURRING_STATUS_CANCELLED_APPLIED_ID,
} from "./payrun";

export const KEYPAY_AMENDMENT_CREATED = 0;
export const KEYPAY_AMENDMENT_IN_PROGRESS = 1;
export const KEYPAY_AMENDMENT_PARTIAL_SUCCESS = 2;
export const KEYPAY_AMENDMENT_SUCCESS = 3;
export const KEYPAY_AMENDMENT_FAILED = 4;

// User Messages
export const APPROVE_SUCCESS_MESSAGE = "Amendment approved successfully.";
export const REJECT_SUCCESS_MESSAGE = "Amendment rejected successfully.";

// Changes types that are automatically applied in key pay
export const AMENDMENTS_APPLIED_TO_PAYROLL = [
  KEYPAY_AMENDMENT_PARTIAL_SUCCESS,
  KEYPAY_AMENDMENT_SUCCESS,
  KEYPAY_AMENDMENT_FAILED,
];

export const RESOLVED_RECURRING_AMENDMENT_STATUSES = [
  REOCCURRING_STATUS_APPLIED_ID,
  REOCCURRING_STATUS_CANCELLED_APPLIED_ID,
  REOCCURRING_STATUS_APPLIED_SUCCESS_ID,
  REOCCURRING_STATUS_APPLIED_FAIL_ID,
  REOCCURRING_STATUS_APPLIED_PARTIAL_ID,
];

export const PERMANENT_AMENDMENT_TYPE_NAME = "Permanent";
export const RECURRING_AMENDMENT_TYPE_NAME = "Recurring";
export const PAYRUN_AMENDMENT_TYPE_NAME = "Pay Run";
// Change task names
export const ALLOWANCE_CHANGE_TYPE_NAME = "Allowance";
export const DEDUCTION_CHANGE_TYPE_NAME = "Deduction";
export const SALARY_CHANGE_TYPE_NAME = "Salary";
export const TERMINATION_CHANGE_TYPE_NAME = "Termination";

// Change Types
/** @knipignore */
export enum CHANGE_TYPES {
  ALLOWANCE = 1,
  DEDUCTION = 2,
  SALARY = 3,
  TERMINATION = 7,
}

// Upload CSV Toggle values
export enum UPLOAD_CSV_VALUES {
  MANUAL = 1,
  VARIABLE = 2,
}

//Upload CSV File size limit (Megabytes)
export const CSV_UPLOAD_LIMIT_MB = 1;

export const MAX_AMENDMENT_PER_PAGE = 1000;

//Maximum number value to send BE in number fields
export const MAX_NUMBER_VALUE: number = 99999999999999;
