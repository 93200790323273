import { SVGProps } from 'react';
const DawnDownload24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.527 14.457V3a.75.75 0 0 0-1.5 0v11.466L7.03 10.47a.75.75 0 0 0-1.061 1.06l5.272 5.273a.75.75 0 0 0 1.06 0l5.273-5.273a.75.75 0 1 0-1.06-1.06l-3.988 3.987ZM3 14.302a.75.75 0 0 1 .75.75v3.272c0 .69.56 1.25 1.25 1.25h13.574c.69 0 1.25-.56 1.25-1.25v-3.272a.75.75 0 0 1 1.5 0v3.272a2.75 2.75 0 0 1-2.75 2.75H5a2.75 2.75 0 0 1-2.75-2.75v-3.272a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnDownload24;
