import requireAuth from "../../Common/requireAuth";
import { SCOPES } from "../authorizationClaims";
import PayscheduleContainer from "../../Organization/containers/Payschedule";
import PayscheduleDetailContainer from "../../Organization/containers/payscheduleDetail";
import PayScheduleDetail from "../../Organization/pages/Payschedule/Admin/Edit";
import CreatePayschedule from "../../Organization/pages/Payschedule/Admin/create";
import PayscheduleIndex from "../../Organization/pages/Payschedule";

export const PayscheduleRoutesWithSidebar = [
  {
    path: "payschedules",
    scope: SCOPES.PAYSCHEDULE,
    index: "payschedules",
    container: PayscheduleContainer,
    componentPath: "../../Organization/pages/Payschedule",
    element: (
      <PayscheduleContainer
        scope={SCOPES.PAYSCHEDULE}
        Layout={requireAuth(PayscheduleIndex)}
      />
    ),
  },
  {
    path: "payschedule/:payscheduleid",
    scope: SCOPES.PAYSCHEDULE,
    index: "payschedules",
    container: PayscheduleDetailContainer,
    componentPath: "../../Organization/pages/Payschedule/Admin/Edit",
    element: (
      <PayscheduleDetailContainer
        scope={SCOPES.PAYSCHEDULE}
        Layout={requireAuth(PayScheduleDetail)}
      />
    ),
  },
];

export const PayscheduleRoutesWithoutSidebar = [
  {
    path: "payschedule/:payscheduleid/edit",
    scope: SCOPES.PAYSCHEDULE,
    index: "payschedules",
    container: PayscheduleContainer,
    componentPath: "../../Organization/pages/Payschedule/Admin/create",
    element: (
      <PayscheduleContainer
        scope={SCOPES.PAYSCHEDULE}
        Layout={requireAuth(CreatePayschedule)}
      />
    ),
  },
  {
    path: "payschedule/create",
    scope: SCOPES.PAYSCHEDULE,
    index: "payschedules",
    container: PayscheduleContainer,
    componentPath: "../../Organization/pages/Payschedule/Admin/create",
    element: (
      <PayscheduleContainer
        scope={SCOPES.PAYSCHEDULE}
        Layout={requireAuth(CreatePayschedule)}
      />
    ),
  },
];
