//@ts-nocheck
import React from "react";
import { getClaimAndStatus, isComponentVisible } from "../../libs/utilities";
import { Col } from "antd";
import { displayPinComponent, ignoreClaimValidation } from "../../libs";
import { PinTooltip } from "../ui";

class ChildTabContainer extends React.Component {
  render() {
    const {
      claims,
      name,
      isPinned,
      index,
      hasGrids,
      itemKey,
      md,
      sm,
      lg,
      ignoreStatus,
    } = this.props;
    const claimAndStatus = getClaimAndStatus({
      claims,
      componentName: name,
      hasComponents: true,
    });
    const claim = claimAndStatus.claim;
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { claims: claim })
    );
    return hasGrids ? (
      <Col
        lg={lg}
        key={itemKey}
        md={md}
        sm={sm}
        className={`${this.props.className} ${ignoreClaimValidation({
          ignoreStatus,
          claimAndStatus,
        })} general-container`}
      >
        {displayPinComponent({ claim }) && (
          <PinTooltip
            isPinned={isPinned}
            onClick={this.props.updatePinnedComponent}
          />
        )}
        {(isComponentVisible({ claim }) || ignoreStatus) && childrenWithProps}
      </Col>
    ) : (
      <div
        key={index}
        className={`${this.props.className} ${ignoreClaimValidation({
          ignoreStatus,
          claimAndStatus,
        })} child-tab-container`}
      >
        {displayPinComponent({ claim }) && (
          <PinTooltip
            isPinned={isPinned}
            onClick={this.props.updatePinnedComponent}
          />
        )}
        {(isComponentVisible({ claim }) || ignoreStatus) && childrenWithProps}
      </div>
    );
  }
}

export default ChildTabContainer;
