// @ts-nocheck
import { http, ResponseHandler } from "../../libs";
import { parseItems, requestBuilder } from "../../libs/utilities";
import {
  payRollProviderAction,
  businessUnitAction,
} from "../../constants/action-type/index";
import APIHandler from "../../constants/apiUrl";

const { LIST_BUSINESS_UNIT } = businessUnitAction;
const { PAYROLL_PROVIDER_LIST, PAYROLL_PROVIDER_DETAIL } =
  payRollProviderAction;

export function listPayrollProviders({ id, options, cancelToken }: any) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_PROVIDER",
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              PAYROLL_PROVIDER_LIST
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: PAYROLL_PROVIDER_LIST,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function payrollProviderLookup({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_PROVIDER_LOOKUP",
      });
      const response = await requestBuilder(id, url, options, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "PAYROLL_PROVIDER_LOOKUP"
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listPayrollProviderBusinessUnit({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_PROVIDER_BUSINESSUNIT",
        options: { id },
      });
      const response = await requestBuilder(null, url, options, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              LIST_BUSINESS_UNIT
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: LIST_BUSINESS_UNIT,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getPayrollProvider({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_PROVIDER",
      });
      const response = await requestBuilder(id, url, options, cancelToken);
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              PAYROLL_PROVIDER_DETAIL
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function savePayrollServiceProvider(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_PROVIDER",
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYROLLPROVIDER_SAVE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updatePayrollServiceProvider(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_PROVIDER",
      });
      const response = await http.put(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYROLLPROVIDER_UPDATE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export const payrollProviderUploadLogo = ({ cancelToken, payload = {} }) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_UPLOAD_LOGO_URL",
      });
      let tempResponse = await http.postFile(url, payload, "file", cancelToken);
      let parsedResponse = parseItems(tempResponse.response, {});
      let response = {
        data: { ...parsedResponse },
        status: tempResponse && tempResponse.status,
      };

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response?.data?.result,
              "UPLOAD_PSP_LOGO"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
};
