import styled from 'styled-components';

import { DawnTick16 } from '@xemplo/icons';
import { BodySmallMedium, Colour } from '@xemplo/style-constants';

export const FileItem = styled.li<{ $error?: boolean }>`
  display: grid;
  margin-bottom: 12px;
  background: ${({ $error }) => ($error ? Colour.Red[50] : Colour.Gray[50])};
  border-radius: 8px;
  color: ${({ $error }) => ($error ? Colour.Red[600] : Colour.Gray[500])};
  ${BodySmallMedium};
`;

export const FileContent = styled.div`
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  padding: 12px 20px;
  display: flex;
  align-items: center;

  & svg {
    font-size: 16px;
  }
`;

export const ErrorLabel = styled.span`
  margin: 0 16px;
`;

export const FileName = styled.span<{ $complete: boolean }>`
  margin: 0 16px;
  margin-left: ${({ $complete }) => ($complete ? '16px' : 0)};
  flex: 1;
  color: ${Colour.Gray[500]};
`;

export const ProgressBar = styled.div<{ $width: number }>`
  background: ${({ $width }) => ($width < 100 ? Colour.Blue[100] : Colour.Blue[50])};
  grid-column: 1;
  height: auto;
  width: ${({ $width }) => $width || 0}%;
  grid-row: 1;
  transition: width 0.2s ease-in-out;
  border-radius: ${({ $width }) => ($width < 100 ? '8px 0 0 8px' : '8px')};
`;

export const CancelButton = styled.button`
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  color: ${Colour.Gray[400]};
  padding-right: 0;
`;

export const FileCompletedIcon = styled(DawnTick16)`
  color: ${Colour.Blue[500]};
`;
