import { SVGProps } from 'react';
const DawnBuildingCompany = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.848 4.024c-.255.299-.447.826-.447 1.712v14.762H7.5V17a1 1 0 0 1 1-1H9a1 1 0 0 1 1 1v3.498h3.12V5.736c0-.888-.19-1.415-.444-1.713-.233-.275-.618-.47-1.301-.47h-5.22c-.683 0-1.07.195-1.307.471Zm4.22 17.974h12.236a.75.75 0 1 0 0-1.5h-2.037V17.54c1.127-.366 1.858-1.538 1.858-2.78v-2.215c0-1.5-1.066-2.897-2.609-2.897-1.542 0-2.608 1.397-2.608 2.897v2.215c0 1.242.731 2.414 1.859 2.78v2.958H14.62V5.736c0-1.065-.224-2.004-.802-2.684-.597-.704-1.46-1-2.444-1h-5.22c-.983 0-1.848.296-2.447.998-.58.68-.807 1.62-.807 2.686v14.762h-.18a.75.75 0 0 0 0 1.5h5.71L8.5 22H9c.023 0 .045 0 .068-.002Zm8.34-9.453c0-.882.59-1.397 1.108-1.397.519 0 1.109.515 1.109 1.397v2.215c0 .882-.59 1.398-1.109 1.398-.518 0-1.108-.516-1.108-1.398v-2.215ZM6.438 7.478a.75.75 0 1 0 0 1.5h4.646a.75.75 0 0 0 0-1.5H6.438Zm-.75 4.005a.75.75 0 0 1 .75-.75h4.646a.75.75 0 0 1 0 1.5H6.438a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnBuildingCompany;
