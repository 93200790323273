//@ts-nocheck
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";
import { Switch } from "antd";

export interface SwitchButtonProps {
  componentName?: string;
  name?: string;
  claims?: any;
  className?: string;
  handleChangeSwitch?: (e: any) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  hasComponents?: boolean;
  displaySwitch?: boolean;
  textClassName?: string;
  loading?: boolean;
  switchClassName?: string;
  disabled?: boolean;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  name,
  claims,
  componentName,
  checked,
  className,
  loading,
  hasComponents,
  disabled,
  textClassName,
  switchClassName,
  defaultChecked,
  handleChangeSwitch,
  displaySwitch,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName,
    hasComponents,
  });
  return (
    <span
      className={`${className ? className : ""}  ${
        displaySwitch ? "" : claimAndStatus.status
      }`}
    >
      <Switch
        defaultChecked={defaultChecked}
        className={`${switchClassName} ${checked ? "bg-success" : ""}`}
        checked={checked}
        onChange={(e) => handleChangeSwitch && handleChangeSwitch(e)}
        loading={loading}
        disabled={disabled}
      />
      {name && <span className={textClassName || ""}>&nbsp; {name}</span>}
    </span>
  );
};

export default SwitchButton;
