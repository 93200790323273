import styled from 'styled-components';

import { DawnArrowDown16 } from '@xemplo/icons';
import { Colour, MinBreakpoint, TextEllipsis } from '@xemplo/style-constants';

export const ActiveItem = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${Colour.Gray[200]};

  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  background: ${Colour.Blue[50]};
  color: ${Colour.Blue[600]};
  font-weight: 500;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 32px;
    left: 0px;
    background: ${Colour.Gradient.Linear180};
    border-radius: 0px 2px 2px 0px;
  }

  &.expanded {
    display: none;
  }

  @media ${MinBreakpoint.mediumV2} {
    display: none;
  }
`;

export const Label = styled.span`
  ${TextEllipsis};
  transition: 0.3s all ease-out;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: ${Colour.Gray[400]};
  transition: 0.3s margin ease-out;

  & path {
    fill: url(#primaryGradient);
  }
`;

export const Arrow = styled(DawnArrowDown16)`
  margin-left: auto;
  margin-right: 16px;
`;
