import styled from 'styled-components';

import {
  BodyXSmallMedium,
  BodyXSmallRegular,
  Colour,
  HeadingStandardMedium,
} from '@xemplo/style-constants';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BusinessUnitName = styled.div`
  ${BodyXSmallMedium};
  color: ${Colour.Gray[600]};
`;

export const PayrunName = styled.div`
  color: ${Colour.Gray[800]};
  ${HeadingStandardMedium};
`;

export const Details = styled.div`
  display: flex;
  gap: 16px;
  & > *:not(:last-child) {
    border-right: 1px solid ${Colour.Gray[100]};
  }
`;
export const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-right: 16px;
`;
export const DetailsItemLabel = styled.div`
  color: ${Colour.Gray[400]};
  ${BodyXSmallRegular};
`;

export const DetailsItemValue = styled.div`
  color: ${Colour.Gray[600]};
  ${BodyXSmallMedium};
`;

export const SkeletonLoader = styled.div<{ $width?: number }>`
  background-color: ${Colour.Gray[100]};
  width: ${({ $width }) => $width ?? 80}px;
  height: 1em;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(239, 241, 245, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(239, 241, 245, 1) 100%
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;
