import { EffectiveDate } from './page-1/adhoc-effective-date';
import { PayDate } from './page-1/adhoc-pay-date';
import { ChangeType } from './page-1/change-type';
import { PayrunType } from './page-1/payrun-type';
import { SelectPayrun } from './page-1/select-payrun';

export function StepOne() {
  return (
    <>
      <ChangeType />
      <PayrunType />
      <SelectPayrun />
      <EffectiveDate />
      <PayDate />
    </>
  );
}
