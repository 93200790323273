import requireAuth from "../../Common/requireAuth";
import { SCOPES } from "../authorizationClaims";
import EmailContainer from "../../Organization/containers/Email";
import EmailIndex from "../../Organization/pages/Email";
import EmailDetail from "../../Organization/pages/Email/detail";

export const EmailRoutesWithSidebar = [
  {
    path: "emailtemplate",
    scope: SCOPES.EMAIL,
    index: "emailtemplate",
    container: EmailContainer,
    componentPath: "../../Organization/pages/Email",
    element: (
      <EmailContainer scope={SCOPES.EMAIL} Layout={requireAuth(EmailIndex)} />
    ),
  },
];

export const EmailRoutesWithoutSidebar = [
  {
    path: "emailtemplate/:id",
    scope: SCOPES.EMAIL,
    index: "emailtemplate",
    container: EmailContainer,
    componentPath: "../../Organization/pages/Email/detail",
    element: (
      <EmailContainer scope={SCOPES.EMAIL} Layout={requireAuth(EmailDetail)} />
    ),
  },
];
