import { cloneElement, forwardRef, PropsWithChildren, ReactElement } from 'react';

import { StyledButton, StyledChildrenContainer } from './button.styles';
import {
  ButtonIconPosition,
  ButtonProps,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  validButtonTypes,
} from './button.types';

const iconSizeMap = {
  [ButtonSize.Small]: 16,
  [ButtonSize.Medium]: 20,
  [ButtonSize.Standard]: 20,
  [ButtonSize.Large]: 24,
};

const typeDefaultVariantMap = {
  [ButtonType.Primary]: ButtonVariant.Light,
  [ButtonType.Secondary]: ButtonVariant.Dark,
  [ButtonType.Destructive]: ButtonVariant.Light,
  [ButtonType.Tertiary]: ButtonVariant.Blue,
};

export const Button = forwardRef(
  (props: PropsWithChildren<ButtonProps>, ref?: React.Ref<HTMLButtonElement>) => {
    const {
      ariaLabel,
      children,
      size = ButtonSize.Standard,
      disabled,
      icon,
      iconPosition = ButtonIconPosition.Leading,
      className,
      onClick,
      variant,
      type,
      underline,
      testId,
    } = props;

    //Check if button variant is valid. Must be within validButtonTypes
    if (variant && !validButtonTypes[type].includes(variant)) {
      throw new Error(
        `Invalid button variant: ${variant} for button type: ${type}. Possible variants are: ${validButtonTypes[type]}`
      );
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }
    };

    const sizedIcon = icon
      ? cloneElement(icon as ReactElement, {
          width: iconSizeMap[size],
          height: iconSizeMap[size],
        })
      : null;

    return (
      <StyledButton
        ref={ref}
        aria-label={ariaLabel}
        data-size={size}
        disabled={disabled}
        onClick={handleClick}
        className={className}
        data-type={type}
        data-variant={variant ?? typeDefaultVariantMap[type]}
        data-underline={underline}
        data-testid={testId}
      >
        {icon && iconPosition === ButtonIconPosition.Leading && sizedIcon}
        <StyledChildrenContainer>{children}</StyledChildrenContainer>
        {icon && iconPosition === ButtonIconPosition.Trailing && sizedIcon}
      </StyledButton>
    );
  }
);

export default Button;
