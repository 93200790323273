import Papa from "papaparse";
import { AmendmentAppliedToUsersListType } from "../types";
import { AmendmentAppliedToUserType } from "../types/amendment";

export const formatDataForCsvGeneration = ({
  data,
}: {
  data: AmendmentAppliedToUsersListType;
}) => {
  return data.map((item: AmendmentAppliedToUserType) => [
    item.id,
    `${item.firstName} ${item.lastName}`,
    "",
  ]);
};

export const generateCsvBlob = ({
  data,
  fields,
}: {
  data: Array<Array<string | number>>;
  fields: Array<string>;
}) => {
  const csv = Papa.unparse({ data, fields });
  return new Blob([csv], { type: "text/csv;charset=utf-8;" });
};

export const downloadCsv = ({ blob }: { blob: Blob }) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = "Template";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
