import { List } from "antd";
import { If } from "../../../../Common/ui";
import {
  currencyFormater,
  getDifferenceAndVariance,
  mustBeArray,
} from "../../../../libs";
import SummaryView from "../summaryView";

type Props = {
  summary: any;
  businessunitDetails: any;
  showVariance?: boolean;
  previousSummary?: Array<any>;
};
const PayrunSummaryDeduction = ({
  summary,
  businessunitDetails,
  showVariance,
  previousSummary,
}: Props) => {
  return mustBeArray(summary).map((data, i) => {
    return (
      <If
        key={`${i}deduction-Items`}
        condition={data && Number(data.amount) !== 0}
        then={
          <List.Item
            key={`${i}deductionItem`}
            className="flex-col flex-align-normal"
          >
            <SummaryView
              key={"1bc"}
              colOneClassName="pl-3 font-medium"
              colTwoClassName="pr-0 font-medium"
              colThreeClassName="pr-0 font-medium"
              colFourClassName="font-medium"
              colOneValue={data.category}
              colTwoValue={currencyFormater(
                previousSummary?.find((o: any) => o.category === data.category)
                  ?.amount,
                businessunitDetails?.country,
                true
              )}
              colThreeValue={currencyFormater(
                data.amount,
                businessunitDetails?.country
              )}
              colFourValue={
                getDifferenceAndVariance(
                  previousSummary?.find((o) => o.category === data.category)
                    ?.amount,
                  data.amount
                ).variance
              }
              showVariance={showVariance}
            />
          </List.Item>
        }
      />
    );
  });
};
export default PayrunSummaryDeduction;
