// @ts-nocheck
import { useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { DateAndTimeDisplay } from "../../../Common/ui";
import ClaimWrapper from "../../../Common/claimWrapper";
import { Steps } from "antd";
import { getConditionalResponse } from "../../../libs/utilities";
import {
  getCurrentPayrunFlowStep,
  getPaymentTitle,
} from "../../../libs/validations";
import StepTitle from "./Title";
import { PAYRUN_STATUS_STEPS_CONTAINER } from "../../../constants";
import { isCompanyUser } from "../../../libs";

const Step: any = Steps.Step;

type Props = {
  claims: any;
  name: string;
  invoice: any;
  classname?: string;
};

const classnameDefault = "";

const PayrunSteps = ({
  claims,
  classname = classnameDefault,
  invoice,
}: Props) => {
  const payrun = useSelector(({ payrun }: any) => payrun.detail);
  const stepsClassName = classNames("cutomizedSteps payrun-steps", {
    "is-completed": Number(payrun?.payrunStatusID) === 18,
  });
  const companyUser = useMemo(() => {
    return isCompanyUser();
  }, [claims]);
  return (
    <ClaimWrapper name={PAYRUN_STATUS_STEPS_CONTAINER} claims={claims}>
      <div className="mb-6 px-4">
        <div className={stepsClassName}>
          <Steps
            current={getCurrentPayrunFlowStep({ payrun })}
            className={classname}
          >
            <Step
              key="1"
              title={<StepTitle label="Amendments Due" />}
              description={
                <DateAndTimeDisplay value={payrun?.ammendmentDueDateTime} />
              }
            />
            <Step
              key="2"
              title={
                <StepTitle
                  label={getConditionalResponse({
                    condition: companyUser,
                    resp1: "Pending Preparation",
                    resp2: "Preparing Pay Run",
                  })}
                />
              }
              description={
                <DateAndTimeDisplay value={payrun?.preparationDueDateTime} />
              }
            />
            <Step
              key="3"
              title={<StepTitle label="Review & Approve" />}
              description={
                <DateAndTimeDisplay value={payrun?.approvalDueDateTime} />
              }
            />
            {payrun?.isInvoiceNeeded ? (
              <Step
                key="4"
                title={<StepTitle label={getPaymentTitle({ invoice })} />}
                description={
                  <DateAndTimeDisplay value={payrun?.paymentDueDateTime} />
                }
              />
            ) : null}
            <Step
              key="5"
              title={<StepTitle label="Finalising Pay Run" />}
              description={<DateAndTimeDisplay value={payrun?.payDate} />}
            />
            <Step
              key="6"
              title={<StepTitle label="Pay Run Complete" />}
              description={<DateAndTimeDisplay value={payrun?.payDate} />}
            />
          </Steps>
        </div>
      </div>
    </ClaimWrapper>
  );
};

export default PayrunSteps;
