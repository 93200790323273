import { Fragment, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import HandleProgressBar from "../Common/progressBar";
import { useAppSelector } from "../hooks";
import { LazyNavbar } from "../Organization/components/common/Header";
import { NavBar, AppSidebar, DetailNavbar } from "../User/components";
import styled from "styled-components";
import { XemploLoader } from "@xemplo/loader";
import { Colour, MinBreakpoint } from "@xemplo/style-constants";

const LayoutWrapper = styled.div`
  height: 100vh;
  background: ${Colour.White[100]};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  @media ${MinBreakpoint.mediumV2} {
    flex-direction: row;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const TemplateWithSidebar = () => {
  const { id, businessunitid, payscheduleid } = useParams();
  const { loadProfile } = useAppSelector((state) => state.member);

  const ApplicationNavbar = useMemo(() => {
    const detailView = !!(id ?? businessunitid ?? payscheduleid);
    return detailView ? DetailNavbar : NavBar;
  }, [id, businessunitid, payscheduleid]);

  if (loadProfile) return <XemploLoader />;

  return (
    <Fragment>
      <HandleProgressBar />
      <LayoutWrapper>
        <LazyNavbar />
        <ContentWrapper>
          <AppSidebar />
          <MainWrapper>
            <main
              id="body-wrapper"
              className={`w-screen sm:w-full flex-1 overflow-y-auto bg-gray-100 p-6 sm:ml-6 lg:ml-0 content-height`}
            >
              <ApplicationNavbar />
              <Outlet />
            </main>
          </MainWrapper>
        </ContentWrapper>
      </LayoutWrapper>
    </Fragment>
  );
};

export default TemplateWithSidebar;
