import { getHook, setHook } from "react-hooks-outside";
import { useFeatureFlag } from "@xemplo/feature-flag";
import { Features } from "../../constants";

setHook("featureFlag", useFeatureFlag);

export const isEnabled = (feature: Features) => {
  const featureFlag = getHook("featureFlag");
  return featureFlag.isEnabled(feature);
};
