import { ProcessingFeesType, BasePayMappingType } from "../paySchedule";

/**
 * Not being used yet
 */
export type Payrun = {
  payrunId: string;
  companyID: string;
  businessUnitID: string;
  payScheduleID: string;
  name: string;
  companyName: string;
  businessUnitName: string;
  payDate: string;
  generatedDateTime: string;
  ammendmentDueDateTime: string;
  preparationDueDateTime: string;
  approvalDueDateTime: string;
  payOccurrenceName: string;
  paymentDueDateTime: string;
  bankFileUploadDueDateTime: string;
  bankFileCreatedDate: string;
  bankFileURL: string | null;
  isUploadBankFile: boolean;
  isBankFileDatePayDay: boolean;
  bankFileDateInDays: number;
  payCycleStartDate: string;
  payCycleEndDate: string;
  payrunTypeID: number;
  payrunTypeName: string;
  payrunStatusID: number;
  payrunStatusName: string;
  ammendmentCount: number;
  isActive: boolean;
  reference: string | null;
  rejectionReasonID: number;
  rejectDescription: string | null;
  isRejected: boolean;
  payrollModelId: number;
  payrollModelName: string;
  payrollSystemConfigurationID: number;
  payrollSystemConnectionId: number;
  externalBusinessUnitId: number;
  externalPayScheduleId: number;
  description: any;
  processingFees: ProcessingFeesType;
  isInvoiceNeeded: boolean;
  externalPayRunId: number;
  externalPayRunName: string;
  basePayMapping: BasePayMappingType;
  auditReportGenerated: boolean;
  auditReportPdfUrl: string | null;
  auditReportCsvUrl: string | null;
  lastActivityDate: string;
};

export enum LumpSumOptions {
  No = 0, //False
  Yes = 1, //True
}
