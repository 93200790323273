import React from "react";
import { If } from "../../../../Common/ui";
import { PREVIOUS_EXTERNAL_EARLINGLINES_INDEX } from "../../../../constants";
import {
  currencyFormater,
  getDifferenceAndVariance,
  isEmpty,
} from "../../../../libs";
import SummaryView from "../summaryView";

type Props = {
  invoice: any;
  businessunitDetails: any;
  showVariance?: boolean;
};
const PayrunSummaryFooter = ({
  invoice,
  businessunitDetails,
  showVariance,
}: Props) => {
  return (
    <If
      condition={!isEmpty(invoice)}
      then={
        <React.Fragment>
          <SummaryView
            colOneClassName="bold text-base pl-4.5"
            colTwoClassName="bold text-base"
            colThreeClassName="bold text-base"
            colFourClassName="bold text-base"
            colOneValue="Total Payable"
            colTwoValue={currencyFormater(
              invoice?.payrunTotalSummary?.[
                PREVIOUS_EXTERNAL_EARLINGLINES_INDEX
              ]?.totalAmount,
              businessunitDetails?.country,
              true
            )}
            colThreeValue={currencyFormater(
              invoice?.totalAmount,
              businessunitDetails?.country
            )}
            colFourValue={
              getDifferenceAndVariance(
                invoice?.payrunTotalSummary?.[
                  PREVIOUS_EXTERNAL_EARLINGLINES_INDEX
                ]?.totalAmount,
                invoice?.totalAmount
              ).variance
            }
            showVariance={showVariance}
          />

          <SummaryView
            colOneClassName="bold text-sm pl-4.5"
            colTwoClassName="bold text-sm"
            colThreeClassName="bold text-sm"
            colFourClassName="bold text-sm"
            colOneValue="Remaining Balance"
            colTwoValue={currencyFormater(
              invoice?.payrunTotalSummary?.[
                PREVIOUS_EXTERNAL_EARLINGLINES_INDEX
              ]?.remainingBalance,
              businessunitDetails?.country,
              true
            )}
            colThreeValue={currencyFormater(
              invoice?.remainingBalance,
              businessunitDetails?.country
            )}
            colFourValue={
              getDifferenceAndVariance(
                invoice?.payrunTotalSummary?.[
                  PREVIOUS_EXTERNAL_EARLINGLINES_INDEX
                ]?.remainingBalance,
                invoice?.remainingBalance
              ).variance
            }
            showVariance={showVariance}
          />
        </React.Fragment>
      }
    />
  );
};
export default PayrunSummaryFooter;
