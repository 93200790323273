//@ts-nocheck
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";
import { Button, Tooltip } from "antd";
import {
  buttonSizeType,
  buttonType,
  htmlType as htmlBtnType,
} from "../../../constants/paramsTypes";

export interface SimpleButtonProps {
  size?: buttonSizeType;
  btnKey?: string;
  name?: string;
  claims?: any;
  className?: string;
  loading?: boolean;
  onClick?: (e: any) => void;
  hasNoComponents?: boolean;
  buttonText: string;
  disableCreateButton?: boolean;
  ignoreStatus?: boolean;
  block?: boolean;
  buttonDisabled?: boolean;
  type?: buttonType;
  htmlType?: htmlBtnType;
  icon?: ReactNode;
  secondaryIcon?: ReactNode;
  tooltipText?: string;
  href?: string;
}

const SimpleButton: React.FC<SimpleButtonProps> = ({
  name,
  claims,
  className,
  loading,
  onClick,
  hasNoComponents,
  buttonText,
  type,
  ignoreStatus,
  size,
  block,
  buttonDisabled,
  htmlType,
  icon,
  secondaryIcon,
  tooltipText,
  href,
  btnKey,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: !hasNoComponents,
  });

  const getButtonComponent = () => {
    return (
      <Button
        data-testid={buttonText}
        {...(btnKey ? { key: btnKey } : {})}
        type={type || "default"}
        block={block}
        className={`rounded-[20px] ${className ?? ""} ${
          ignoreStatus ? "" : claimAndStatus?.status
        }`}
        loading={loading}
        onClick={onClick}
        href={href}
        disabled={
          !!(claimAndStatus.status === "Visible-Disabled" || buttonDisabled)
        }
        size={size || "middle"}
        htmlType={htmlType || "button"}
        icon={icon}
      >
        {buttonText}
        {secondaryIcon && <span className="ml-3">{secondaryIcon}</span>}
      </Button>
    );
  };
  return tooltipText ? (
    <Tooltip title={tooltipText}>{getButtonComponent()}</Tooltip>
  ) : (
    getButtonComponent()
  );
};

export default SimpleButton;
