import {
  DrawerAction,
  DrawerActions,
  DrawerConfig,
  DrawerContent,
  DrawerMode,
  DrawerPosition,
  DrawerState,
} from './drawer.types';

export const defaultState: DrawerState = {
  isOpen: false,
  contentVisible: false,
  config: {
    mode: DrawerMode.Standard,
    position: DrawerPosition.Right,
    headerKeyline: false,
    width: 488,
  },
  content: {},
};

export const reducer = (state: DrawerState, { type, payload }: DrawerAction) => {
  switch (type) {
    case DrawerActions.SetDrawerConfig:
      return {
        ...state,
        config: {
          ...state.config,
          ...payload,
        },
      };
    case DrawerActions.SetDrawerContent:
      return {
        ...state,
        content: {
          ...state.content,
          ...payload,
        },
      };
    case DrawerActions.SetContentVisible:
      return {
        ...state,
        contentVisible: payload == null ? !state.contentVisible : payload,
      };
    case DrawerActions.SetIsOpen:
      return {
        ...state,
        isOpen: payload == null ? !state.isOpen : payload,
      };
    case DrawerActions.SetPosition:
      return {
        ...state,
        config: {
          ...state.config,
          position: getPosition(state, payload),
        },
      };
    case DrawerActions.SetMode:
      return {
        ...state,
        config: {
          ...state.config,
          mode: getMode(state, payload),
        },
      };
    case DrawerActions.SetState:
      return { ...state, ...payload };
    default:
      return state;
  }
};

function getPosition(state: DrawerState, payload?: DrawerPosition) {
  if (payload != null) {
    return payload;
  }

  return state.config.position === DrawerPosition.Left
    ? DrawerPosition.Right
    : DrawerPosition.Left;
}

function getMode(state: DrawerState, payload?: DrawerMode) {
  if (payload != null) {
    return payload;
  }

  return state.config.mode === DrawerMode.Standard
    ? DrawerMode.Editor
    : DrawerMode.Standard;
}

export const setDrawerConfig = (payload: Partial<DrawerConfig>): DrawerAction => ({
  type: DrawerActions.SetDrawerConfig,
  payload,
});

export const setDrawerContent = (payload: Partial<DrawerContent>): DrawerAction => ({
  type: DrawerActions.SetDrawerContent,
  payload,
});

export const setContentVisible = (payload?: boolean): DrawerAction => ({
  type: DrawerActions.SetContentVisible,
  payload,
});

export const setIsOpen = (payload?: boolean): DrawerAction => ({
  type: DrawerActions.SetIsOpen,
  payload,
});

export const setPosition = (payload?: DrawerPosition): DrawerAction => ({
  type: DrawerActions.SetPosition,
  payload,
});

export const setMode = (payload?: DrawerMode): DrawerAction => ({
  type: DrawerActions.SetMode,
  payload,
});

export const setState = (payload: Partial<DrawerState>): DrawerAction => ({
  type: DrawerActions.SetState,
  payload,
});
