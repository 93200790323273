import { useContext } from 'react';

import { FeatureFlagContext } from './feature-flag.context';

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error('Have you register the feature mapping using the FeatureProvider?');
  }

  return context;
};
