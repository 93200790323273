import { InputHTMLAttributes, ReactNode } from 'react';

export type InputProps<T> = InputHTMLAttributes<T> & CustomInputProps;

export type CustomInputProps = {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  inputSize?: InputFieldSize;
  width?: string;
  testId?: string;
  value?: string;
  id?: string;
  defaultValue?: string;
  onChange?: (e: string) => void;
  errorMessage?: string;
  description?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  label?: string;
  formSubmitting?: boolean;
};

export enum InputFieldSize {
  Large = 'large',
  Standard = 'standard',
  Medium = 'medium',
  Small = 'small',
}
