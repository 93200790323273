import { SVGProps } from 'react';
const DawnArrowUp24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.53 15.53a.75.75 0 0 1-1.06-1.06l8-8a.752.752 0 0 1 1.06 0l8 8a.75.75 0 0 1-1.06 1.06L12 8.06l-7.47 7.47Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowUp24;
