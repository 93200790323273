import { useContext } from 'react';

import { XemploApiContext } from './xemplo-api.context';

export const useXemploApiContext = () => {
  const context = useContext(XemploApiContext);
  if (!context) {
    throw new Error('useXemploApiContext must be used within XemploApiProvider');
  }
  return { ...context };
};
