import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PayrunsTableType } from '@xemplo/gp-types';

/**
 * Hook to get the type of payrun list to display (based on the tab menu selection)
 * @returns PayrunTableType
 */
export const usePayrunsType = () => {
  const [searchParams] = useSearchParams();
  const [payrunsType, setPayrunsType] = useState<PayrunsTableType>(
    PayrunsTableType.Active
  );

  useEffect(() => {
    if (!searchParams?.get('filterby')) return;
    const filterBy = searchParams.get('filterby') ?? PayrunsTableType.Active;
    if (filterBy) setPayrunsType(filterBy as PayrunsTableType);

    return () => {
      setPayrunsType(PayrunsTableType.Active);
    };
  }, [searchParams]);

  return {
    payrunsType,
    setPayrunsType,
  };
};
