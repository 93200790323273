import { CancelToken } from 'axios';

import {
  ActivatedUser,
  BaseUserExtended,
  ExpedoUserProfile,
  ListUserRolesInput,
  PagedResult,
  Result,
  RolesLookup,
  UpdateUserProfileInput,
  UserPersona,
} from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { ApiEndpoint, getEimsApiUrl, getPayrollApiUrl } from '@xemplo/url-helper';

import {
  BaseErrorHandler,
  getRoleArea,
  mapResponseToUserProfile,
  mapSimpleResponse,
  mapWrappedResponse,
} from './util';

export const useMemberApi = () => {
  const { client, cancelSignal } = useHttpClient();

  const getProfile = async (cancelToken?: CancelToken) => {
    const url = getPayrollApiUrl(ApiEndpoint.Callback);
    return await client
      .get<ExpedoUserProfile>(url, { cancelToken })
      .then(mapResponseToUserProfile)
      .catch(BaseErrorHandler);
  };

  /**
   * When updating the user role, we shouldn't need to remap the whole user data again.
   * We need to figure it out a way to get this more efficient.
   */
  const switchPersona = async (persona: UserPersona, cancelToken?: CancelToken) => {
    const url = getPayrollApiUrl(ApiEndpoint.PersonaSwitch);
    const data = {
      userPersonaId: persona.id,
      default: persona.default,
    };

    return await client
      .post<ExpedoUserProfile>(url, data, { cancelToken })
      .then(mapSimpleResponse)
      .then((data) => ({
        ...data,
        selectedRole: persona,
        area: getRoleArea(data, persona),
      }))
      .catch(BaseErrorHandler);
  };

  const activateUserProfile = async (
    details: UpdateUserProfileInput,
    cancelToken?: CancelToken
  ) => {
    const url = getPayrollApiUrl(ApiEndpoint.ActivateUser);
    return await client
      .put<Result<ActivatedUser>>(url, details, { cancelToken })
      .then(mapWrappedResponse)
      .catch(BaseErrorHandler);
  };

  const getRoleSettings = async (cancelToken?: CancelToken) => {
    const url = getPayrollApiUrl(ApiEndpoint.UserRoles);
    return await client
      .get<UserPersona[]>(url, { cancelToken })
      .then(mapSimpleResponse)
      .catch(BaseErrorHandler);
  };

  const updateRoleSettings = async (payload: UserPersona, cancelToken?: CancelToken) => {
    const url = getPayrollApiUrl(ApiEndpoint.UpdateUserRoles);
    const data = {
      userRoleDefault: payload.id,
      lastSelectedAsDefault: payload.default,
    };

    return await client
      .post<UserPersona[]>(url, data, { cancelToken })
      .then(mapSimpleResponse)
      .catch(BaseErrorHandler);
  };

  const listUsers = async (cancelToken?: CancelToken) => {
    const url = getPayrollApiUrl(ApiEndpoint.ListUsers);
    return await client
      .get<PagedResult<BaseUserExtended>>(url, { cancelToken })
      .then(mapWrappedResponse)
      .catch(BaseErrorHandler);
  };

  const listUserRoles = async ({
    area,
    options = { per_page: 0 },
    cancelToken,
  }: ListUserRolesInput) => {
    const { per_page, page, sort, q } = options;
    const params = {
      per_page,
      q: q ?? JSON.stringify({ roleArea: area }),
      page,
      sort,
    };

    const url = getPayrollApiUrl(ApiEndpoint.ListUserRoles);
    return await client
      .get<PagedResult<RolesLookup>>(url, { params, cancelToken })
      .then(mapWrappedResponse)
      .catch(BaseErrorHandler);
  };

  const updatePassword = async (payload: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    const url = getEimsApiUrl(ApiEndpoint.UpdatePassword);
    return await client.post(url, payload).catch(BaseErrorHandler);
  };

  return {
    getProfile,
    switchPersona,
    activateUserProfile,
    getRoleSettings,
    listUsers,
    listUserRoles,
    updatePassword,
    updateRoleSettings,
    cancelSignal,
  };
};
