import { useMemo } from 'react';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { checkboxColumn } from './table.helper';
import { TableProps } from './table.types';

export const useTanstackTable = <TData>(props: TableProps<TData>) => {
  const { columns, enableExpanding, enableRowSelection, enablePagination = true } = props;

  const tableProps = useMemo(() => {
    let conditionalProps = { ...props };

    if (enableRowSelection || enableExpanding) {
      conditionalProps = {
        ...conditionalProps,
        columns: [checkboxColumn(), ...columns],
      };
    }

    if (!enablePagination) {
      conditionalProps = {
        ...conditionalProps,
        manualPagination: true,
      };
    }

    return {
      ...conditionalProps,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getSortedRowModel: getSortedRowModel(),
    };
  }, [columns, enableExpanding, enablePagination, enableRowSelection, props]);

  const table = useReactTable(tableProps);
  const hasResults = table.getCoreRowModel().rows.length > 0;
  const hasAppliedFilters = useMemo(() => {
    const filters = table.getState().columnFilters;
    return !!filters;
  }, [table]);

  return { table, hasResults, hasAppliedFilters };
};
