import React from "react";
import {
  PAYRUN_STATIC_CONTENT_HIDE_BUTTON,
  PAYRUN_STATIC_CONTENT_SHOW_BUTTON,
} from "../../../constants";
import { ZoomMinimizeIcon } from "../../ui";

type Props = {
  hideStaticContent: boolean;
  handleIconClick: () => void;
  claims: object;
};

const ToogleStaticContent = ({
  hideStaticContent,
  handleIconClick,
  claims,
}: Props) => (
  <div className="flex justify-end items-center">
    <ZoomMinimizeIcon
      expanded={hideStaticContent}
      title={`
        ${hideStaticContent ? "Show" : "Hide"} static contents
      `}
      handleClick={handleIconClick}
      claims={claims}
      name={
        hideStaticContent
          ? PAYRUN_STATIC_CONTENT_SHOW_BUTTON
          : PAYRUN_STATIC_CONTENT_HIDE_BUTTON
      }
    />
  </div>
);

export default ToogleStaticContent;
