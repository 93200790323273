import { SVGProps } from 'react';
const DawnCashStack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.43 5.115c-.575.533-.97 1.408-.97 2.805v5.15c0 1.01.207 1.744.53 2.269.319.515.78.88 1.39 1.107a3.155 3.155 0 0 0 .616.162c.15.034.308.06.473.079.203.022.417.033.641.033h8.79c1.205 0 2.095-.301 2.68-.845.575-.534.97-1.408.97-2.805V7.92c0-.41-.043-.773-.107-1.142-.183-.914-.596-1.511-1.15-1.895-.574-.396-1.371-.613-2.393-.613H6.11c-1.205 0-2.095.301-2.68.845Zm16.372.91C19.506 5 18.942 4.2 18.145 3.649c-.9-.621-2.027-.879-3.245-.879H6.11c-1.435 0-2.745.359-3.7 1.245C1.445 4.912.96 6.237.96 7.92v5.15c0 1.21.248 2.236.754 3.056.512.83 1.254 1.394 2.144 1.727l.006.002c.11.04.226.078.349.114.416 1.434 1.34 2.4 2.645 2.884.686.26 1.45.367 2.253.367h8.79c1.435 0 2.745-.358 3.7-1.245.965-.896 1.45-2.222 1.45-3.905v-5.15c0-1.388-.334-2.534-1.004-3.4-.575-.745-1.353-1.228-2.245-1.495ZM5.924 18.218c.33.605.827.995 1.457 1.229l.006.002c.473.18 1.048.271 1.724.271h8.79c1.205 0 2.095-.301 2.68-.845.575-.533.97-1.408.97-2.805v-5.15c0-1.151-.276-1.946-.691-2.484a2.63 2.63 0 0 0-.81-.692l.001.176v5.15c0 1.683-.485 3.009-1.45 3.905-.955.886-2.265 1.245-3.7 1.245h-8.79c-.063 0-.125 0-.187-.002Zm4.574-9.608a1.89 1.89 0 1 0 0 3.78 1.89 1.89 0 0 0 0-3.78Zm-3.39 1.89a3.39 3.39 0 1 1 6.78 0 3.39 3.39 0 0 1-6.78 0ZM4.78 7.55a.75.75 0 0 1 .75.75v4.4a.75.75 0 0 1-1.5 0V8.3a.75.75 0 0 1 .75-.75Zm11.442 0a.75.75 0 0 1 .75.75v4.4a.75.75 0 0 1-1.5 0V8.3a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnCashStack;
