import styled from 'styled-components';

import { Avatar as ExpedoAvatar } from '@xemplo/avatar';
import { Button } from '@xemplo/button';
import {
  Body2XSmallMedium,
  Body2XSmallSemiBold,
  BodyLargeMedium,
  BodySmallSemiBold,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
  Shadow,
  TextEllipsis,
} from '@xemplo/style-constants';
import { VarianceCell } from '@xemplo/table';

/** Drawer Header  */
export const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 32px;
`;

export const Avatar = styled(ExpedoAvatar)`
  border: 2px solid ${Colour.White[100]};
  ${Shadow.Medium1};
`;

export const TabMenuWrapper = styled.div`
  height: 72px;
  margin: 0 32px 0 32px;
`;
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Name = styled.span`
  color: ${Colour.Gray[600]};
  ${BodyLargeMedium};
`;

export const Title = styled.span`
  color: ${Colour.Gray[600]};
  ${TextEllipsis};
  ${BodyXSmallRegular};
`;

export const Salary = styled.span`
  margin-top: 8px;
  color: ${Colour.Gray[400]};
  ${TextEllipsis};
  ${BodyXSmallRegular};
`;

/** Body */

export const TempToggleButton = styled(Button)`
  margin: 24px;
`;
export const BackButton = styled(Button)`
  margin-block: 24px;
`;

export const DetailContainer = styled.div`
  margin-inline: 32px;
  position: relative;
  margin-bottom: 56px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px 0;
  margin-top: 16px;
  text-align: right;

  th {
    height: 20px;
    color: ${Colour.Gray[400]};
    ${Body2XSmallMedium};
    padding-bottom: 17.5px;
    text-transform: uppercase;
    &:nth-child(2),
    &:nth-child(3) {
      width: 80px;
      max-width: 80px;
      ${TextEllipsis};
    }
    &:nth-child(4) {
      width: 100px;
      max-width: 100px;
      ${TextEllipsis};
    }
  }

  td {
    height: 20px;
    padding-bottom: 12px;
    color: ${Colour.Gray[800]};
    ${BodyXSmallRegular};
    &:first-child {
      text-align: left;
      color: ${Colour.Gray[500]};
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 80px;
      max-width: 80px;
      ${TextEllipsis};
    }
    &:nth-child(4) {
      width: 100px;
      max-width: 100px;
      ${TextEllipsis};
    }
  }

  .heading {
    td {
      ${BodyXSmallSemiBold};
      &:last-child {
        ${BodySmallSemiBold};
      }
    }
  }
`;

/** Variance Table */
export const VarianceTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px 0;
  margin-top: 16px;
  text-align: right;

  th {
    color: ${Colour.Gray[400]};
    ${Body2XSmallMedium};
    height: 20px;
    padding-bottom: 17.5px;
    text-transform: uppercase;
    &:nth-child(2),
    &:nth-child(3) {
      width: 100px;
      max-width: 100px;
      ${TextEllipsis};
    }
    &:nth-child(4) {
      width: 80px;
      max-width: 80px;
      ${TextEllipsis};
    }
  }

  td {
    height: 20px;
    padding-bottom: 12px;
    color: ${Colour.Gray[800]};
    ${BodyXSmallRegular};

    &:first-child {
      text-align: left;
      color: ${Colour.Gray[500]};
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 100px;
      max-width: 100px;
      ${TextEllipsis};
    }
    &:nth-child(4) {
      width: 80px;
      max-width: 100px;
      ${TextEllipsis};
    }
  }

  .heading {
    td {
      &:nth-child(1) {
        ${BodyXSmallSemiBold};
      }
      &:nth-child(2),
      &:nth-child(3) {
        ${BodySmallSemiBold};
      }
    }
  }
`;
export const TotalTable = styled.table`
  height: 38px;
  width: calc(100% + 26px);
  margin-top: 16px;
  border-collapse: separate;
  border-spacing: 8px 0;
  margin-left: -13px;
  padding-inline: 13px;
  text-align: right;
  ${BodySmallSemiBold};
  background: ${Colour.Green[50]};
  color: ${Colour.Gray[800]};
  border-radius: 4px;
  .total {
    color: ${Colour.Green[800]};
    text-align: left;
  }

  td {
    &:nth-child(2),
    &:nth-child(3) {
      width: 100px;
      max-width: 100px;
      ${TextEllipsis};
    }
    &:nth-child(4) {
      width: 80px;
      max-width: 80px;
      ${TextEllipsis};
      ${Body2XSmallSemiBold};
    }
  }
`;

/** Drawer Footer */
export const Footer = styled.div`
  padding: 16px 32px 32px 32px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const CustomVarianceCell = styled(VarianceCell)`
  height: 15px;
  padding: 2.5px 6px;
  ${Body2XSmallMedium};
  svg {
    height: 10px;
    width: 10px;
  }
  &.total {
    color: ${Colour.White[100]};
    background: ${Colour.Green[500]};
    svg {
      color: ${Colour.White[100]};
    }
  }
`;
