import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import * as S from './toggle-button.styles';
import { ToggleButtonProps } from './toggle-button.types';

export const ToggleButton = (props: Readonly<ToggleButtonProps>) => {
  const { name, items, disabled, rules, containerWidth } = props;
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const { register, setValue } = useFormContext();
  const { ref, onBlur, onChange, ...rest } = register(name, rules);

  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const navElement = useRef<HTMLDivElement>(null);

  const setActiveIndicator = useCallback(
    (navElement: React.RefObject<HTMLDivElement>) => {
      if (!navElement?.current) return { newIndicatorPos: 0, targetWidth: 0 };

      const selectedElement = navElement.current.querySelector('.is-selected');

      const buttonLeftPos = selectedElement
        ? selectedElement.getBoundingClientRect().left
        : 0;
      const targetWidth = selectedElement
        ? selectedElement.getBoundingClientRect().width
        : 0;

      const wrapperLeftPos = navElement.current?.getBoundingClientRect().left;

      const newIndicatorPos = wrapperLeftPos ? buttonLeftPos - wrapperLeftPos : 0;

      return { newIndicatorPos, targetWidth };
    },
    []
  );

  const updateIndicatorPos = useCallback(() => {
    const { newIndicatorPos, targetWidth } = setActiveIndicator(navElement);

    setIndicatorPosition(newIndicatorPos);
    setIndicatorWidth(targetWidth);
  }, [setActiveIndicator]);

  useEffect(() => {
    updateIndicatorPos();
  }, [updateIndicatorPos, selectedItem]);

  const handleClick = (value: string) => {
    if (disabled) return;

    updateIndicatorPos();
    setValue(name, value, { shouldDirty: true, shouldTouch: true });
    setSelectedItem(value);
  };

  // enable pre-filled form values to update the selected item
  useEffect(() => {
    items.forEach((item) => {
      if (item.selected && !selectedItem) {
        updateIndicatorPos();
        setValue(name, item.value, { shouldDirty: false, shouldTouch: false });
        setSelectedItem(item.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <S.Wrapper
      ref={navElement}
      role="radiogroup"
      $width={containerWidth}
      $disabled={disabled}
    >
      {items.map((item, i) => {
        const key = `${name}-${item.value}`;
        const selected =
          selectedItem === item.value || (!selectedItem && items[0].value === item.value);
        return (
          <S.Label
            $width={containerWidth}
            key={key}
            id={`${key}-label`}
            htmlFor={`${key}-input`}
            className={classNames({
              'is-selected': selected,
            })}
            onClick={() => selectedItem !== item.value && handleClick(item.value)}
          >
            {item.label}
            <S.HiddenInput
              {...rest}
              disabled={disabled}
              type="radio"
              role="radio"
              ref={ref}
              key={`${key}-input`}
              onChange={(e) => onChange(e)}
              checked={selected}
              value={item.value}
              aria-labelledby={`${key}-label`}
            />
          </S.Label>
        );
      })}
      {!!indicatorWidth && !!indicatorPosition && (
        <S.Indicator
          $disabled={disabled}
          $left={indicatorPosition}
          $width={indicatorWidth}
          data-chromatic="ignore" // FD-1114: this component causes false positives, be careful when editing its styles!
        />
      )}
    </S.Wrapper>
  );
};
