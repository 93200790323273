//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Card,
  Col,
  DatePicker,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  TimePicker,
} from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import {
  delay,
  mustBeArray,
  errorDisplay,
  getDateTimeField,
  getConditionalResponse,
} from "../../../../libs/utilities";
import { If } from "../../../../Common/ui";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

type State = any;

class PayrunForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      disableIndex: 4,
      payOccurence: 1,
      payrollModel: 1,
      bankFileDateInDay: 1,
      businessunitList: [],
      businessUnitListLoading: false,
      fetching: false,
      companyList: [],
      disableBU: false,
      steps: [],
      payDate: 61,
      processingFees: [],
      taxOptions: [],
      payrun: {},
    };
  }

  componentDidMount() {
    this.updateState();
    this.fetchCompany();
    const { payscheduleDates } = this.props;
    if (payscheduleDates) {
      Object.keys(payscheduleDates).forEach((o) => {
        this.props.form.setFieldsValue({ [o]: payscheduleDates.o });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.displayModel !== nextProps.displayModel) {
      this.updateState();
    }
  }

  displayForm = () => {
    return this.props.visible || this.props.displayModel;
  };

  updateState = () => {
    if (!_.isUndefined(this.props.visible)) {
      this.props.renderData();
    }
    const { match } = this.props;
    const companyId = match?.params?.companyid;
    if (companyId) {
      this.props
        .getCompany({
          id: companyId,
          cancelToken: this.props.signal.token,
        })

        .then((res) => {
          if (res.status) {
            this.setState({
              companyList: [{ id: res.data.id, name: res.data.name }],
              disableCompany: true,
            });

            this.props.form.setFieldsValue({ company: companyId });
            this.handleChangeCompany(this.props.companyId);
          } else {
            errorDisplay(res && res.data && res.data.validationErrors);
          }
          this.setState({ fetching: false });
        });
    }
  };

  componentWillUnmount() {
    this.props.signal.cancel("Api is being canceled");
  }

  onRadioChange = (inputValue, newIndexValue) => {
    if (inputValue === 1 && newIndexValue === 3) {
      this.setState({ disableIndex: 4, payrollModel: 1 });

      this.props.form.setFieldsValue({
        PSPUploadBank: 1,
        BankfileDated: 1,
      });
    }
    if (inputValue === 2 && newIndexValue === 3) {
      this.setState({ disableIndex: 2, payrollModel: 2 });

      this.props.form.setFieldsValue({
        PSPUploadBank: 1,
        BankfileDated: 1,
      });
    }

    if (inputValue === 1 && newIndexValue === 2) {
      this.setState({ disableIndex: 2 });

      this.props.form.setFieldsValue({
        BankfileDated: 1,
      });
    }
    if (inputValue === 2 && newIndexValue === 2) {
      this.setState({ disableIndex: 3 });

      this.props.form.setFieldsValue({
        BankfileDated: 1,
      });
    }

    if (inputValue === 1 && newIndexValue === 1) {
      this.setState({ disableIndex: 2 });
    }
    if (inputValue === 2 && newIndexValue === 1) {
      this.setState({ disableIndex: 1 });
    }
  };

  disabledDate = (selectionDate: Moment, fieldName) => {
    const fields = [
      "amendmentsDueDate",
      "payrunPreparationDueDate",
      "approvalDueDate",
      "payDate",
    ];
    const fieldNameIndex = _.indexOf(fields, fieldName);
    const dayIsBeforeToday = selectionDate.isBefore(moment(), "day");
    const payDate = this.props.form.getFieldValue("payDate");
    const approvalDueDate = this.props.form.getFieldValue("approvalDueDate");
    const prevFieldDate =
      fields[fieldNameIndex - 1] &&
      this.props.form.getFieldValue(fields[fieldNameIndex - 1]);

    switch (fieldName) {
      case "payDate":
        //Paydate must be today or later
        return dayIsBeforeToday;
      case "paymentDueDate":
        //Payment due date must be before paydate and after approval due date
        return !!(
          selectionDate.isBefore(approvalDueDate, "day") ||
          selectionDate.isAfter(payDate, "day")
        );
      default:
        // All other dates must be after previous field and after/equal paydate
        return !!(
          dayIsBeforeToday ||
          selectionDate.isBefore(prevFieldDate, "day") ||
          selectionDate.isAfter(payDate, "day")
        );
    }
  };

  handleChangeCompany = (value) => {
    this.setState({ businessUnitListLoading: true });
    this.props
      .listCompanyBusinessUnits({ id: value, options: { per_page: 500 } })

      .then((response) => {
        if (response.status) {
          this.setState({
            businessunitList: response.data,
            businessUnitListLoading: false,
          });
        } else {
          errorDisplay(
            response && response.data && response.data.validationErrors
          );
        }

        if (!this.props.editMode) {
          this.props.form.resetFields(["businessunit"]);
        }
      });
  };

  fetchCompany = (value) => {
    this.setState({ fetching: true });
    if (value) {
      this.props
        .companyLookup({ options: { CompanyName: value, per_page: 500 } })

        .then((response) => {
          this.setState({ fetching: false });
          let companyList = _.map(mustBeArray(response.data), (a, b) => {
            return { ...a, name: a.value };
          });

          if (response.status) {
            this.setState({ companyList });
          } else {
            errorDisplay(
              response && response.data && response.data.validationErrors
            );
          }
        });
    } else {
      this.props
        .companyLookup({ options: { per_page: 500 } })
        .then((response) => {
          this.setState({ fetching: false });

          if (response.status) {
            let companyList = _.map(mustBeArray(response.data), (a, b) => {
              return { ...a, name: a.value };
            });

            this.setState({ companyList });
          } else {
            errorDisplay(
              response && response.data && response.data.validationErrors
            );
          }
        });
    }
  };

  setBankFileUploadDay = (val) => {
    this.setState({ bankFileDateInDay: val });

    this.props.setBankFileDate(val);
  };

  dueDayChange = (fieldName, dateValue) => {
    const dueDatesOrder = [
      "amendmentsDue",
      "payrunPreparationDue",
      "approvalDue",
    ];
    let timeValue;
    delay(500).then(() => {
      if (
        ["approvalDue", "payrunPreparationDue", "amendmentsDue"].includes(
          fieldName
        )
      ) {
        let fieldNameIndex = _.indexOf(dueDatesOrder, fieldName);
        if (
          this.props.form.getFieldValue(`${fieldName}Date`) >=
            this.props.form.getFieldValue(
              `${dueDatesOrder[fieldNameIndex + 1]}Date`
            ) ||
          !this.props.form.getFieldValue(`${fieldName}Date`)
        ) {
          this.props.form.resetFields([dueDatesOrder[fieldNameIndex + 1]]);
        }

        if (
          this.props.form.getFieldValue(fieldName) === "approvalDue" &&
          this.props.form.getFieldValue(`${fieldName}Date`) <
            this.props.form.getFieldValue("paymentDueDate")
        ) {
          this.props.form.resetFields(["paymentDueDate"]);
        }
      }
      let stepFieldName = fieldName;
      switch (fieldName) {
        case "paymentDue":
          stepFieldName = "paymentDueDateTime";

          timeValue = getDateTimeField({
            data: this.props.form.getFieldValue("paymentDueTime"),
          });

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${this.props.form
                .getFieldValue("paymentDueDate")
                .format("DD/MM/YYYY")} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        case "amendmentsDue":
          stepFieldName = "ammendmentDueDateTime";

          timeValue = getDateTimeField({
            data: this.props.form.getFieldValue("amendmentsDueTime"),
          });

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${this.props.form
                .getFieldValue("amendmentsDueDate")
                .format("DD/MM/YYYY")} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        case "payrunPreparationDue":
          stepFieldName = "preparationDueDateTime";

          timeValue = getDateTimeField({
            data: this.props.form.getFieldValue("payrunPreparationDueTime"),
          });

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${this.props.form
                .getFieldValue("payrunPreparationDueDate")
                .format("DD/MM/YYYY")} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        case "approvalDue":
          stepFieldName = "approvalDueDateTime";

          timeValue = getDateTimeField({
            data: this.props.form.getFieldValue("approvalDueTime"),
          });

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${this.props.form
                .getFieldValue("approvalDueDate")
                .format("DD/MM/YYYY")} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        default:
          console.log("unknown action");
      }
    });
  };

  dueTimeChange = (value, fieldName) => {
    const timeValue = value ? value.format("h:mm a") : null;
    let dateValue;

    let stepFieldName = fieldName;
    switch (fieldName) {
      case "paymentDueTime":
        stepFieldName = "paymentDueDateTime";

        dateValue = getDateTimeField({
          data: this.props.form.getFieldValue("paymentDueDate"),
          format: "DD/MM/YYYY",
          response: null,
        });
        if (timeValue && dateValue) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${dateValue} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            return prevState;
          });
        }
        break;
      case "amendmentsDueTime":
        stepFieldName = "ammendmentDueDateTime";

        dateValue = getDateTimeField({
          data: this.props.form.getFieldValue("amendmentsDueDate"),
          format: "DD/MM/YYYY",
          response: null,
        });
        if (timeValue && dateValue) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${dateValue} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            return prevState;
          });
        }
        break;
      case "payrunPreparationDueTime":
        stepFieldName = "preparationDueDateTime";

        dateValue = getDateTimeField({
          data: this.props.form.getFieldValue("payrunPreparationDueDate"),
          format: "DD/MM/YYYY",
          response: null,
        });
        if (timeValue && dateValue) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${dateValue} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            return prevState;
          });
        }

        break;
      case "approvalDueTime":
        stepFieldName = "approvalDueDateTime";

        dateValue = getDateTimeField({
          data: this.props.form.getFieldValue("approvalDueDate"),
          format: "DD/MM/YYYY",
          response: null,
        });
        if (timeValue && dateValue) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = moment(
              `${dateValue} ${timeValue}`,
              "DD/MM/YYYY h:mm a"
            );
            return prevState;
          });
        }
        break;
      default:
        console.log("unknown action");
    }
  };

  onPayDateChange = () => {
    delay(500).then(() => {
      const payDate = this.props.form.getFieldValue("payDate")
        ? this.props.form.getFieldValue("payDate")
        : null;
      if (_.isUndefined(payDate) || payDate === null) {
        this.setState((prevState) => {
          prevState.payrun["payDate"] = payDate;
          prevState.payrun["payDateStatus"] = "wait";
          return prevState;
        });
      } else {
        this.setState((prevState) => {
          prevState.payrun["payDate"] = payDate;
          prevState.payrun["payDateStatus"] = "finish";
          return prevState;
        });
      }
    });
  };

  checkIfPaymentRequired = () => {
    return Number(this.props.form.getFieldValue("PayrollModel")) === 1;
  };

  changedBusinessUnit = (value) => {
    this.props.changedBusinessUnit(value).then((resp) => {
      if (resp.status && resp.data) {
        this.props.form.setFieldsValue({
          isInvoiceNeeded: resp.data.isInvoiceNeeded ? 1 : 2,
        });

        if (!this.props.editMode) {
          this.props.form.resetFields(["paymentDueDate", "paymentDueTime"]);
        }
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    });
  };
  onModelChange = (value, index) => {
    this.onRadioChange(value, index);

    this.props.form.resetFields(["paymentDueDate", "paymentDueTime"]);

    this.setState(
      (prevState) => {
        prevState.payrun.paymentDueDateTime = "";
        prevState.payrun.paymentDueDateTimeStatus = "wait";
        return prevState;
      },
      () => {
        if (Number(value) === 2) {
          this.props.form.setFieldsValue({
            isInvoiceNeeded:
              this.props.form.getFieldValue("businessunit") &&
              this.props.businessUnit &&
              this.props.businessUnit.isInvoiceNeeded
                ? 1
                : 2,
            PSPUploadBank: 2,
          });

          this.uploadBankFileToggle(2, 2);
        }
      }
    );
  };
  uploadBankFileToggle = (value, index) => {
    this.onRadioChange(value, index);

    this.props.form.resetFields([
      "BankfileUploadDueDate",
      "BankfileUploadDueTime",
    ]);
  };
  isBankFileUploadDateRequired = () => {
    return Number(this.props.form.getFieldValue("PayrollModel")) !== 2 ||
      Number(this.props.form.getFieldValue("PSPUploadBank")) !== 1
      ? false
      : true;
  };

  isBankFileUploadDateDisabled = () => {
    return 2 < this.state.disableIndex &&
      this.props.form.getFieldValue("PSPUploadBank") &&
      Number(this.props.form.getFieldValue("PSPUploadBank") !== 1)
      ? true
      : false;
  };

  isBankFileDifferentDateDisabled = () => {
    return (this.props.form.getFieldValue("BankfileDated") &&
      Number(this.props.form.getFieldValue("BankfileDated") === 1)) ||
      (this.props.form.getFieldValue("PSPUploadBank") &&
        Number(this.props.form.getFieldValue("PSPUploadBank") !== 1))
      ? true
      : false;
  };

  isBankFileDatedDisabled = () => {
    return 2 < this.state.disableIndex &&
      this.props.form.getFieldValue("PSPUploadBank") &&
      Number(this.props.form.getFieldValue("PSPUploadBank") !== 1)
      ? true
      : false;
  };

  // Disabled Pay cycle end dates
  getDisabledPayCycleEndDates = (e) => {
    return e < moment(this.props.form.getFieldValue("cycleStartDate"));
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    const { editMode } = this.props;
    const formLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 11 },
    };
    const formLayoutModel = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    function onChange(time, timeString) {
      console.log("");
    }

    const { fetching, companyList, businessunitList, businessUnitListLoading } =
      this.state;

    const PEOSelected = (
      <Row gutter={16}>
        <Col span={11}>
          <FormItem {...formLayout} label="Payment Due:">
            {getFieldDecorator("paymentDueDate", {
              rules: [
                {
                  required: true,
                  message: "Please select payment due date",
                },
              ],
            })(
              <DatePicker
                onChange={(value) => this.dueDayChange("paymentDue", value)}
                format="DD/MM/YYYY"
                allowClear={false}
                className="w-full "
                disabledDate={(e) => {
                  return this.disabledDate(e, "paymentDueDate");
                }}
                placeholder="Select date"
                showToday={false}
                inputReadOnly={true}
              />
            )}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem {...formLayout} label="">
            {getFieldDecorator("paymentDueTime", {
              rules: [
                {
                  required: true,
                  message: "Please select payment due time",
                },
              ],
              initialValue: moment("00:00", "HH:mm"),
            })(
              <TimePicker
                minuteStep={5}
                className="w-full "
                onChange={(value) =>
                  this.dueTimeChange(value, "paymentDueTime")
                }
                use12Hours
                allowClear={false}
                format="h:mm a"
                placeholder="Set time"
                inputReadOnly
                showNow={false}
              />
            )}
          </FormItem>
        </Col>
      </Row>
    );

    const OutsourceSelected = (
      <div>
        <Row gutter={8}>
          <Col span={11} className="no-shadow">
            <FormItem {...formLayout} label="Generate Invoice:">
              {this.props.form.getFieldDecorator("isInvoiceNeeded", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <RadioGroup name="payrollmodel">
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={11} className="no-shadow">
            <FormItem {...formLayout} label="PSP to Upload Bank File:">
              {this.props.form.getFieldDecorator("PSPUploadBank", {
                rules: [
                  {
                    required:
                      this.props.form.getFieldValue("PayrollModel") &&
                      Number(this.props.form.getFieldValue("PayrollModel")) ===
                        2,
                  },
                ],
                initialValue: 1,
              })(
                <RadioGroup
                  name="payrollmodel"
                  onChange={(e) => this.uploadBankFileToggle(e.target.value, 2)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={11}>
            <FormItem {...formLayout} label="Bank File Upload Due:">
              {this.props.form.getFieldDecorator("BankfileUploadDueDate", {
                rules: [
                  {
                    required: this.isBankFileUploadDateRequired(),
                  },
                ],
              })(
                <DatePicker
                  className="w-full "
                  placeholder="Select"
                  disabled={this.isBankFileUploadDateDisabled()}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  showToday={false}
                  inputReadOnly={true}
                />
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="">
              {this.props.form.getFieldDecorator("BankfileUploadDueTime", {
                rules: [
                  {
                    required: this.isBankFileUploadDateRequired(),
                  },
                ],
                initialValue: moment("00:00", "HH:mm"),
              })(
                <TimePicker
                  minuteStep={5}
                  use12Hours
                  format="h:mm a"
                  onChange={onChange}
                  allowClear={false}
                  disabled={this.isBankFileUploadDateDisabled()}
                  className="w-full "
                  inputReadOnly
                  showNow={false}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={22} className="no-shadow">
            <FormItem
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 10 }}
              label="Bank File to be Dated:"
            >
              {this.props.form.getFieldDecorator("BankfileDated", {
                rules: [
                  {
                    required:
                      this.props.form.getFieldValue("PayrollModel") &&
                      Number(this.props.form.getFieldValue("PayrollModel")) ===
                        2,
                  },
                ],
                initialValue: 1,
              })(
                <RadioGroup
                  name="BankfileDated"
                  disabled={this.isBankFileDatedDisabled()}
                  onChange={(e) => this.onRadioChange(e.target.value, 1)}
                >
                  <Radio value={1}>Pay Day</Radio>
                  <Radio value={2}>
                    {" "}
                    Different Day &nbsp; &nbsp; &nbsp;
                    <DatePicker
                      className="w-[45%]"
                      placeholder="Select"
                      onChange={(val) => this.setBankFileUploadDay(val)}
                      disabled={this.isBankFileDifferentDateDisabled()}
                      format="DD/MM/YYYY"
                      allowClear={false}
                      showToday={false}
                      inputReadOnly
                    />
                  </Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={11}>
            <FormItem {...formLayout} label="Payment Due:">
              {getFieldDecorator("paymentDueDate", {
                rules: [
                  {
                    required: this.checkIfPaymentRequired(),
                    message: "Please select payment due date",
                  },
                ],
              })(
                <DatePicker
                  onChange={(value) => this.dueDayChange("paymentDue", value)}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  className="w-full "
                  disabledDate={(e) => {
                    return this.disabledDate(e, "paymentDueDate");
                  }}
                  placeholder="Select date"
                  showToday={false}
                  inputReadOnly={true}
                />
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="">
              {getFieldDecorator("paymentDueTime", {
                rules: [
                  {
                    required: this.checkIfPaymentRequired(),
                    message: "Please select payment due time",
                  },
                ],
                initialValue: moment("00:00", "HH:mm"),
              })(
                <TimePicker
                  minuteStep={5}
                  className="w-full "
                  allowClear={false}
                  onChange={(value) =>
                    this.dueTimeChange(value, "paymentDueTime")
                  }
                  use12Hours
                  format="h:mm a"
                  placeholder="Set time"
                  inputReadOnly
                  showNow={false}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
    return (
      <Card className="mb-5">
        <Row gutter={8} className="mt-8">
          <Col span={11}>
            <FormItem {...formLayout} label="Company:">
              {getFieldDecorator("company", {
                rules: [
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select company"
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  optionFilterProp={"name"}
                  onChange={this.handleChangeCompany}
                  disabled={getConditionalResponse({
                    condition: this.state.disableCompany,
                    resp1: this.state.disableCompany,
                    resp2: editMode,
                  })}
                  className="w-full "
                >
                  {mustBeArray(companyList).map((d) => (
                    <Option key={d.id} value={d.id} name={d.name}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="Business Unit:">
              {getFieldDecorator("businessunit", {
                rules: [
                  {
                    required: true,
                    message: "Please select a business unit",
                  },
                ],
              })(
                <Select
                  placeholder="Select business unit"
                  filterOption={false}
                  disabled={getConditionalResponse({
                    condition: this.props.disabled,
                    resp1: this.props.disabled,
                    resp2: editMode,
                  })}
                  onChange={(value) => this.changedBusinessUnit(value)}
                  className="w-full "
                  loading={businessUnitListLoading}
                >
                  {mustBeArray(businessunitList).map((d) => (
                    <Option key={d.id} value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={11}>
            <FormItem {...formLayout} label="Pay Run Name:">
              {getFieldDecorator("payrunName", {
                rules: [
                  {
                    required: true,
                    message: "Please enter pay run name",
                  },
                ],
              })(<Input placeholder="pay run name" />)}
            </FormItem>
          </Col>

          <Col span={11}>
            <FormItem {...formLayout} label="Pay Date:">
              {getFieldDecorator("payDate", {
                rules: [
                  {
                    required: true,
                    message: "Please select pay date",
                  },
                ],
              })(
                <DatePicker
                  className="w-full "
                  placeholder="Select"
                  format="DD/MM/YYYY"
                  allowClear={false}
                  onChange={this.onPayDateChange.bind(this)}
                  disabledDate={(e) => {
                    return this.disabledDate(e, "payDate");
                  }}
                  showToday={false}
                  inputReadOnly
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={11}>
            <FormItem {...formLayout} label="Pay Cycle Start Date:">
              {getFieldDecorator("cycleStartDate", {
                rules: [
                  {
                    required: true,
                    message: "Please select pay cycle start date",
                  },
                ],
              })(
                <DatePicker
                  className="w-full "
                  format="DD/MM/YYYY"
                  allowClear={false}
                  placeholder="Select"
                  showToday={false}
                  inputReadOnly={true}
                />
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem
              {...formLayout}
              label={<span className="pl-2">Reference:</span>}
            >
              {getFieldDecorator("reference", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input className="w-full " placeholder="Reference" />)}
            </FormItem>
          </Col>
        </Row>

        {this.props.form.getFieldValue("payDate") || this.displayForm() ? (
          <React.Fragment>
            <Row gutter={8}>
              <Col span={11}>
                <FormItem {...formLayout} label="Amendments Due:">
                  {getFieldDecorator("amendmentsDueDate", {
                    rules: [
                      {
                        required: true,
                        message: "Please select amendment due date",
                      },
                    ],
                  })(
                    <DatePicker
                      className="w-full "
                      placeholder="Select date"
                      format="DD/MM/YYYY"
                      allowClear={false}
                      onChange={() => this.dueDayChange("amendmentsDue")}
                      disabledDate={(e) => {
                        return this.disabledDate(e, "amendmentsDueDate");
                      }}
                      showToday={false}
                      inputReadOnly={true}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("amendmentsDueTime", {
                    rules: [
                      {
                        required: true,
                        message: "Please select amendment due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      minuteStep={5}
                      className="w-full "
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      onChange={(value) =>
                        this.dueTimeChange(value, "amendmentsDueTime")
                      }
                      placeholder="Set time"
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={11}>
                <FormItem {...formLayout} label="Preparation Due:">
                  {getFieldDecorator("payrunPreparationDueDate", {
                    rules: [
                      {
                        required: true,
                        message: "Please select preparation due date",
                      },
                    ],
                  })(
                    <DatePicker
                      className="w-full "
                      placeholder="Select date"
                      format="DD/MM/YYYY"
                      allowClear={false}
                      onChange={() => this.dueDayChange("payrunPreparationDue")}
                      disabledDate={(e) => {
                        return this.disabledDate(e, "payrunPreparationDueDate");
                      }}
                      showToday={false}
                      inputReadOnly={true}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("payrunPreparationDueTime", {
                    rules: [
                      {
                        required: true,
                        message: "Please select preparation due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      minuteStep={5}
                      className="w-full "
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      onChange={(value) =>
                        this.dueTimeChange(value, "payrunPreparationDueTime")
                      }
                      placeholder="Set time"
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={11}>
                <FormItem {...formLayout} label="Approval Due:">
                  {getFieldDecorator("approvalDueDate", {
                    rules: [
                      {
                        required: true,
                        message: "Please select approval due date",
                      },
                    ],
                  })(
                    <DatePicker
                      className="w-full "
                      onChange={() => this.dueDayChange("approvalDue")}
                      format="DD/MM/YYYY"
                      allowClear={false}
                      placeholder="Select date"
                      disabledDate={(e) => {
                        return this.disabledDate(e, "approvalDueDate");
                      }}
                      showToday={false}
                      inputReadOnly={true}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("approvalDueTime", {
                    rules: [
                      {
                        required: true,
                        message: "Please select approval due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      minuteStep={5}
                      className="w-full "
                      allowClear={false}
                      onChange={(value) =>
                        this.dueTimeChange(value, "approvalDueTime")
                      }
                      use12Hours
                      format="h:mm a"
                      placeholder="Set time"
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </React.Fragment>
        ) : null}

        <Row gutter={8}>
          <Col span={11} className="no-shadow">
            <FormItem
              className="pb-0"
              {...formLayoutModel}
              label="Business Model:"
            >
              {getFieldDecorator("PayrollModel", {
                rules: [
                  {
                    required: true,
                    message: "You need to select business model",
                  },
                ],
                initialValue: 1,
              })(
                <RadioGroup
                  name="payrollmodel"
                  onChange={(e) => this.onModelChange(e.target.value, 3)}
                >
                  <Radio value={1}>PEO</Radio>
                  <Radio value={2}>Outsource</Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>
        <If
          condition={this.state.payrollModel === 1}
          then={PEOSelected}
          else={OutsourceSelected}
        />
      </Card>
    );
  }
}

export default PayrunForm;
