import { SVGProps } from 'react';
const DawnArrowLongRight24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 12c0 .21-.086.398-.224.534L17.53 16.53a.75.75 0 1 1-1.06-1.06l2.72-2.72H3a.75.75 0 0 1 0-1.5h16.19l-2.72-2.72a.75.75 0 0 1 1.06-1.06l4 4a.75.75 0 0 1 .22.53Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLongRight24;
