import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import * as React from "react";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import LogoProfileImageAndInitialsView from "./LogoProfileImageAndInitialsView";

interface UploadLogoAndProfileImageProps {
  firstName?: any;
  lastName?: any;
  handleUpload: (e: any) => void;
  handleDelete?: (e: any) => void;
  name?: string;
  showUploadAndDelete?: boolean;
  uploadLoading?: boolean;
  smallView?: boolean;
  profilePhoto?: string;
}

const UploadLogoAndProfileImage: React.FC<UploadLogoAndProfileImageProps> = ({
  firstName,
  lastName,
  handleUpload,
  handleDelete,
  showUploadAndDelete,
  name,
  uploadLoading,
  smallView,
  profilePhoto,
}) => {
  return (
    <React.Fragment>
      <LogoProfileImageAndInitialsView
        imageUrl={profilePhoto}
        firstName={firstName}
        lastName={lastName}
        name={name}
        smallView={smallView}
      />
      {showUploadAndDelete && (
        <div className={`mt-4 flex`}>
          <Upload
            beforeUpload={(file) => handleUpload(file)}
            showUploadList={false}
            className={`${profilePhoto ? "" : "ml-7"}`}
          >
            <SimpleButton
              buttonText={profilePhoto ? "Replace" : "Upload"}
              ignoreStatus={true}
              icon={<UploadOutlined />}
              size="small"
              loading={uploadLoading}
            />
          </Upload>
          {profilePhoto && (
            <SimpleButton
              buttonText="Delete"
              ignoreStatus={true}
              icon={<CloseOutlined />}
              size="small"
              className="ml-4"
              onClick={handleDelete}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadLogoAndProfileImage;
