import { useRef } from 'react';
import classnames from 'classnames';

import { TabMenuItem } from './menu-item/menu-item';
import { ActiveIndicator } from './active-indicator';
import { SubMenu } from './sub-menu';
import { toggleMenu } from './tab-menu.store';
import * as S from './tab-menu.style';
import { TabMenuProps } from './tab-menu.types';
import { useMenuTruncate } from './use-menu-truncate';
import { useTabMenu } from './use-tab-menu';

export { ActiveIndicatorTestId } from './active-indicator';
export const TabMenuTestId = {
  wrapper: 'tab-menu-wrapper',
  showMore: 'tab-menu-show-more',
};

export function TabMenu(props: TabMenuProps) {
  const { className } = props;
  const menuRef = useRef<HTMLUListElement>(null);
  const { state, dispatch } = useTabMenu();
  const { visibleItems, hiddenItems, size, isPrimary } = state;
  useMenuTruncate({ menuRef });

  return (
    <S.TabMenuWrapper
      ref={menuRef}
      data-testid={TabMenuTestId.wrapper}
      role="menu"
      className={className}
    >
      {visibleItems.map((item) => (
        <TabMenuItem key={item.id} {...item} isSubMenuItem={false} />
      ))}
      <S.MenuItem
        role="menuitem"
        className={classnames({
          invisible: hiddenItems.length === 0,
          primary: !!isPrimary,
          [size.toLowerCase()]: true,
        })}
        onClick={() => dispatch(toggleMenu())}
      >
        <S.ShowMoreIcon id="ellipsis" data-testid={TabMenuTestId.showMore}>
          ...
        </S.ShowMoreIcon>
      </S.MenuItem>
      <SubMenu />
      <ActiveIndicator parentRef={menuRef} />
    </S.TabMenuWrapper>
  );
}

export default TabMenu;
