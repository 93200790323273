//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, InputNumber } from "antd";
import { mustBeArray } from "../../../libs/utilities";

type State = any;

class StateRate extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  checkHasValue = (e, i) => {
    if (!e.target.value) {
      this.props.updateStatesRate(0, i);
    }
  };
  render() {
    const {
      label,
      decorator,
      states,
      getFieldDecorator,
      updateStatesRate,
      editPayschedule,
    } = this.props;
    const formLayout = {
      labelCol: { span: editPayschedule ? 5 : 6 },
      wrapperCol: { span: editPayschedule ? 19 : 18 },
    };

    return (
      <React.Fragment>
        <Form.Item label={`${label}:`} {...formLayout}>
          {getFieldDecorator(decorator, {
            initialValue: [],
          })(
            <Row gutter={16} className="-ml-2">
              {mustBeArray(states).map((eachState, i) => {
                return (
                  <Col span={8} key={i} className="hideNumberPointers mb-4">
                    <label>{eachState.code}</label>
                    <div>
                      <InputNumber
                        value={eachState.rate}
                        className="w-3/6"
                        onChange={(e) => updateStatesRate(e, i)}
                        onBlur={(e) => this.checkHasValue(e, i)}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          )}
        </Form.Item>
      </React.Fragment>
    );
  }
}

export default StateRate;
