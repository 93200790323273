import React from "react";
import classNames from "classnames";
import { getClaimAndStatus } from "../libs/utilities";
import { VISIBLE_CLAIMS_ID } from "../constants/defaultClaims";

type Props = {
  displayComponent?: boolean;
  hasNoComponents?: boolean;
  claims?: object;
  name?: string;
  className?: string;
  children: React.ReactNode;
};

const ClaimWrapper = ({
  displayComponent,
  hasNoComponents,
  claims,
  name,
  className,
  children,
}: Props) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: !hasNoComponents,
  });
  const claim = claimAndStatus.claim;
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { claims: claim })
  );

  const wrapperClassName = classNames(className, {
    "Visible - Enabled": displayComponent,
    [claimAndStatus.status]: !displayComponent,
  });

  return (
    <span className={wrapperClassName}>
      {(VISIBLE_CLAIMS_ID.includes(Number(claim.authorizationStatusId)) ||
        displayComponent) &&
        childrenWithProps}
    </span>
  );
};

export default ClaimWrapper;
