//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../libs/table";
import { PAGINATION_NOT_REQUIRED } from "../../constants/defaultClaims";
import SimpleTableContainer from "../../Common/ClaimContainers/SimpleTableContainer";
import { getBusinessUnitListColumns } from "../../libs/utilities";

const BusinessUnitList = (props: any) => {
  const getSorterField = (field: any) => {
    let newField = field;
    switch (field) {
      case "paySchedules":
        newField = "payScheduleCount";
        break;
      case "registeredAddressCountry":
        newField = "country";
        break;
      default:
        break;
    }
    return newField;
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order
            ? sorter.order === "ascend"
              ? "asc"
              : "desc"
            : sorter.order,
          field: getSorterField(sorter.field),
        }
      : {
          page: pagination.current,
        };
    props.handleTableChange({ options });
  };

  const size = props?.size ?? "default";
  const {
    sortInfo,
    per_page,
    claims,
    component,
    paginationType,
    data,
    dashboardView,
    handleAssignPayrollManager,
    member,
    totalPages,
    current,
  } = props;

  const pagination = {
    total: totalPages,
    current: current,
    pageSize: per_page ? per_page : 20,
    hideOnSinglePage: true,
  };

  const colTemplate = getBusinessUnitListColumns({
    claims,
    component,
    sortInfo,
    handleAssignPayrollManager,
    member,
    dashboardView
  });

  const tableColumn = tableColumnGenerator(colTemplate);

  return (
    <SimpleTableContainer
      columns={tableColumn}
      size={size}
      dataSource={data}
      pagination={
        paginationType === PAGINATION_NOT_REQUIRED ? false : pagination
      }
      loading={props.loading}
      onChange={handleTableChange}
      rowIdParam="id"
      dashboardView={dashboardView}
    />
  );
};

export default BusinessUnitList;
