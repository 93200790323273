//@ts-nocheck
import { Component } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { Layout, message, Modal } from "antd";
import CustomNavbar from "../../components/customNavbar";
import MergeFields from "../../components/email/mergeFields";
import _ from "lodash";
import { If, Loading } from "../../../Common/ui";
import { errorDisplay } from "../../../libs/utilities";
import { TINY_MCE_URL } from "../../../constants/entities";
import environment from "../../../constants/environment";
import { withRouter } from "../../../hooks";

const { Header, Sider, Content } = Layout;

type State = any;

class DetailIndex extends Component<{}, State> {
  state = {
    content: "",
    loading: true,
    saveLoading: false,
  };

  componentDidMount() {
    const { params } = this.props && this.props.match;
    this.loadTinyMce(() => {
      this.setState({ tinyMceLoaded: true });
    });
    if (params?.id) {
      this.props
        .emailTemplatesHandler({
          cancelToken: this.props.signal.token,
          action: "get",
          id: params.id,
        })

        .then((resp) => {
          if (resp.status) {
            this.setState({ loading: false });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
    }
  }

  replaceAll = (value, insertTemplate) => {
    if (value) {
      if (insertTemplate) {
        value.replace(new RegExp("<<", "g"), "&lt;&lt;");
        return value.replace(new RegExp(">>", "g"), "&gt;&gt;");
      } else {
        value.replace(new RegExp("&lt;&lt;", "g"), "<<");
        return value.replace(new RegExp("&gt;&gt;", "g"), ">>");
      }
    }
  };

  componentWillUnmount() {
    this.unloadTinyMce();

    this.props.signal.cancel("Api is being canceled");
  }

  loadTinyMce = (callBack) => {
    const existingScript = document.getElementById("tinyMceScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = TINY_MCE_URL;
      script.id = "tinyMceScript";
      document.body.appendChild(script);
      //action to do after a script is loaded in our case setState
      script.onload = () => {
        if (callBack) callBack();
      };
    }
    if (existingScript && callBack) callBack();
  };

  unloadTinyMce = () => {
    let tinyMceScript = document.getElementById("tinyMceScript");
    if (tinyMceScript) {
      tinyMceScript.remove();
    }
  };

  handleEditorChange = (value) => {
    this.setState({
      content: value && value.target && value.target.getContent(),
    });
  };

  addMergeField = (field) => {
    let newField = this.replaceAll(field, true);
    window.tinymce.activeEditor.execCommand(
      "mceInsertContent",
      false,
      newField
    );
  };

  handleSave = () => {
    let payload = _.clone(this.props.emailDetails);

    payload.body =
      this.state.content ??
      (this.props?.emailDetails &&
        this.replaceAll(this.props.emailDetails?.body, false));

    this.setState({ saveLoading: true });

    this.props
      .emailTemplatesHandler({
        payload,
        action: "update",
        id: payload.id,
      })

      .then((resp) => {
        this.setState({ saveLoading: false });

        if (resp.status) {
          message.success("Email template updated successfully");
          this.props.router.navigate("/emailtemplate");
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  handleCancel = (router) => {
    Modal.confirm({
      content: (
        <b>
          Are you sure you want to cancel editing the template? Any changes done
          will not be saved!
        </b>
      ),
      width: 700,
      onOk() {
        router.navigate("/emailtemplate");
      },
      onCancel() {
        console.log("");
      },
    });
  };

  editEmailTemplates = () => {
    if (this.props && this.props.emailDetails) {
      return this.props.emailDetails.body;
    }
  };

  render() {
    const { emailDetails } = this.props;

    const { tinyMceLoaded } = this.state;

    return (
      <Layout className="email-templates-layout layout">
        <Header className="bg-white w-full layout-detail-header">
          <CustomNavbar
            handleCancel={() => this.handleCancel(this.props.router)}
            handleSave={this.handleSave}
            loading={this.state.saveLoading}
            handleDelete={this.handleDelete}
            pageTitle={"Email Template"}
            editor={true}
            hideEnableBack={true}
            header={this.props.header}
          />
        </Header>
        <Layout
          className={`mt-[73px] layout-detail-layout-${
            this.state.response ? "collapsed" : "expanded"
          }`}
        >
          <Sider width={380} className="layout-detail-sider">
            <MergeFields
              addMergeField={this.addMergeField}
              mergeFields={emailDetails?.mergeFields}
            />
          </Sider>
          <Content
            className={`py-7.5 ${
              this.state.responsive ? "pr-[15px] pl-[15px]" : "pr-[15px]"
            }`}
          >
            <If
              condition={this.state.loading && !tinyMceLoaded}
              then={<Loading />}
              else={
                <div>
                  <Editor
                    initialValue={this.replaceAll(
                      this.props.emailDetails?.body,
                      true
                    )}
                    init={{
                      plugins: "link image code lists table preview wordcount",
                      toolbar:
                        "undo redo | bold italic | alignleft aligncenter alignright | table | preview code | sizeselect | fontsizeselect | fontsize ",
                      relative_urls: true,
                      remove_script_host: false,
                      convert_urls: true,
                      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                      height: "85vh",
                      visual: false,
                    }}
                    onChange={this.handleEditorChange}
                    apiKey={environment.tiny_mce_editor_key}
                  />
                </div>
              }
            />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    header: state.pageHeader.header,
  };
}

export default connect(mapStateToProps, null)(withRouter(DetailIndex));
