import styled from 'styled-components';

import { Colour } from '@xemplo/style-constants';

export const Wrapper = styled.div<{
  $width: string | undefined;
  $disabled: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  border-radius: 24px;
  width: ${({ $width }) => ($width ? $width : 'fit-content')};
  min-width: fit-content;
  border: ${({ $disabled }) => `1px solid rgba(220, 223, 234, ${$disabled ? 0.4 : 1})`};
  justify-content: space-evenly;
  position: relative;
  padding: 1px 3px 1px 0;
  max-height: 40px;
`;

export const Label = styled.label<{
  $width: string | undefined;
}>`
  width: ${({ $width }) => ($width ? '100%' : 'fit-content')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 24px;
  margin: 1px;
  color: ${Colour.Gray[400]};
  position: relative;
  min-width: 96px;
  z-index: 1;

  &:hover {
    color: ${Colour.Gray[600]};
  }

  &.is-selected {
    color: ${Colour.Blue[600]};
  }

  /** If disabled, remove cursor effect and change opacity */
  &:has(input[type='radio']:disabled) {
    pointer-events: none;
    opacity: 0.4;
  }
`;

// FD-1114: this component is ignored by chromatic, be careful when editing its styles!
export const Indicator = styled.div<{
  $width: number;
  $left: number;
  $disabled: boolean | undefined;
}>`
  position: absolute;
  height: 93%;
  border: ${({ $disabled }) => `1px solid rgba(0, 96, 215, ${$disabled ? 0.4 : 1})`};
  border-radius: 24px;
  background: ${Colour.Blue[50]};
  width: ${({ $width }) => `${$width}px`};
  left: ${({ $left }) => `${$left}px`};
  transition: all 0.2s ease-in-out;
`;

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;
