export function hexToRGBA(hex: string, alpha?: number): string {
  let hexValue = hex.replace('#', '');

  if (hexValue.length === 3 || hexValue.length === 4) {
    hexValue = hexValue
      .split('')
      .map((char) => char + char)
      .join('');
  }

  let alphaValue = alpha ?? 1;

  if (hexValue.length === 8) {
    const alphaHex = hexValue.slice(6, 8);
    const parsedAlpha = parseInt(alphaHex, 16) / 255;
    alphaValue = alpha || parsedAlpha || 1;
    hexValue = hexValue.slice(0, 6);
  }

  const redHex = hexValue.slice(0, 2);
  const greenHex = hexValue.slice(2, 4);
  const blueHex = hexValue.slice(4, 6);

  const red = parseInt(redHex, 16);
  const green = parseInt(greenHex, 16);
  const blue = parseInt(blueHex, 16);

  return `rgba(${red}, ${green}, ${blue}, ${
    alphaValue === 0 || alphaValue === 1 ? alphaValue : alphaValue.toFixed(2)
  })`;
}
