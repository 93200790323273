import React from "react";
import SliceListProvider from "../../../../Common/SliceListProvider";
import List from "./List";

type Props = {
  pageClaims: any;
  dashboardClaims: any;
  targetEndpoint: string;
  filterApplied?: string;
  componentTitle: string;
};

const PendingGeneralAmendments = ({
  pageClaims,
  dashboardClaims,
  targetEndpoint,
  filterApplied,
  componentTitle,
}: Props) => (
  <SliceListProvider>
    <List
      pageClaims={pageClaims}
      dashboardClaims={dashboardClaims}
      targetEndpoint={targetEndpoint}
      filterApplied={filterApplied}
      componentTitle={componentTitle}
    />
  </SliceListProvider>
);

export default PendingGeneralAmendments;
