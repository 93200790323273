//@ts-nocheck
import React from "react";
import { Card, Tooltip, Typography, Avatar, Badge } from "antd";
import Flag from "react-world-flags";
import { ContractIcon } from "./customicons";
import CountryCoordinates from "../Common/countries";
import GridActionComponent from "../Organization/components/common/gridAction";

const { Title } = Typography;

const DisplayCard = ({
  type,
  handleAction,
  content,
  claims,
  editComponent,
  viewComponent,
}: any) => {
  const keyMapper = {
    businessUnit: {
      title: "name",
      subTitle: "companyName",
      paySchedules: "paySchedules",
      referenceNumber: "referenceNumber",
      countryName: "registeredAddressCountry",
      countryCode: "countryId",
      status: "isActive",
      payrollManager: "payrollManager",
    },
    company: {
      title: "name",
      subTitle: "companyName",
      businessUnitCount: "businessUnitCount",
      referenceNumber: "referenceNumber",
      countryName: "registeredAddressCountry",
      countryCode: "countryId",
      status: "isActive",
    },
    payrollProvider: {
      title: "name",
      subTitle: "companyName",
      businessUnitCount: "businessUnitCount",
      referenceNumber: "referenceNumber",
      countryName: "registeredAddressCountry",
      countryCode: "countryId",
      status: "isActive",
    },
  };
  const country =
    content[keyMapper[type]["countryName"]] &&
    CountryCoordinates.getCode(content[keyMapper[type]["countryName"]]);
  return (
    <Card
      className="card  hand card-prop h-full pb-0"
      bordered={false}
      onClick={() => handleAction({ data: content, action: "view" })}
    >
      <div className="position-relative pl-0 pr-0 pt-3 pb-3">
        <div className={"mb-2.5 px-[50px] position-relative"}>
          <div className="position-relative">
            <Tooltip
              placement="top"
              title={`${content[keyMapper[type]["title"]]}`}
            >
              <Title ellipsis className="card-title" level={3}>{`${
                content[keyMapper[type]["title"]]
              }`}</Title>
            </Tooltip>
            {["businessUnit", "paySchedule"].includes(type) && (
              <Title ellipsis className="card-subtitle" level={4}>
                {`${type === "paySchedule" ? "Business Unit" : "Company"}: ${
                  content[keyMapper[type]["subTitle"]]
                }`}
              </Title>
            )}
          </div>

          <Avatar
            className="position-absolute-top position-absolute-top-left"
            size={36}
          >
            <ContractIcon className="text-lg" />
          </Avatar>
        </div>
        {["businessUnit"].includes(type) && (
          <React.Fragment>
            <div className="flex justify-between mb-1 text-xs">
              <span>{`Pay Schedules: ${
                content[keyMapper[type]["paySchedules"]]
              }`}</span>
            </div>
          </React.Fragment>
        )}
        {["company", "payrollProvider"].includes(type) && (
          <div className="flex justify-between mb-1 text-xs mt-6">
            <span>{`Business Units: ${
              content[keyMapper[type]["businessUnitCount"]]
            }`}</span>
          </div>
        )}

        {/* Status badge */}
        <div className="mt-4">
          {content[keyMapper[type]["status"]] === true ? (
            <Badge count={"Active"} className={"text-xs badge badge-success"} />
          ) : (
            <Badge
              count={"InActive"}
              className={"text-xs badge badge-danger"}
            />
          )}
        </div>
      </div>
      {country && (
        <div className={"flags"}>
          <Tooltip
            placement="topLeft"
            title={content[keyMapper[type]["countryName"]]}
          >
            <Flag
              code={country}
              width={18}
              height={14}
              className="float-left"
            />
          </Tooltip>
        </div>
      )}
      <GridActionComponent
        record={content}
        classname="card-item mt-4 mr-4 position-absolute-top position-absolute-top-right"
        buttonClassName="card-prop animated fadeIn"
        claims={claims}
        handleAction={(options: any) =>
          handleAction({ ...options, data: content, record: content })
        }
        viewComponentNameGrid={viewComponent}
        editComponentNameGrid={editComponent}
      />
    </Card>
  );
};

export default DisplayCard;
