//@ts-nocheck
import React from "react";
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listCompanies,
  saveCompany,
  updateCompany,
  getCompany,
} from "../actions/companies";
import axios from "axios";
import {
  populateStates,
  countriesLookup,
  timezoneLookup,
  cdnLookUpHandler,
} from "../actions/lookup";
import { earningLinesLookup, listAmendmentTypes } from "../actions/payrun";
import {
  updateAuthorizationClaim,
  updateHeader,
} from "../../User/actions/member";
import { withRouter } from "../../hooks";

class Company extends SwiftContainer {
  signal = axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  render() {
    const { Layout } = this.props;
    const { pageClaims, loading } = this.state;
    return (
      <Layout
        {...this.props}
        userMode={this.props.userMode}
        companies={this.props.companies}
        totalPages={this.props.totalPages}
        loading={loading}
        listCompanies={(data: any) =>
          this.executeAction(this.props.listCompanies, data)
        }
        signal={this.signal}
        member={this.props.member}
        pageClaims={pageClaims}
        populateStates={this.props.populateStates}
        earningLinesLookup={this.props.earningLinesLookup}
        listAmendmentTypes={this.props.listAmendmentTypes}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        updateHeader={this.props.updateHeader}
        saveCompany={this.props.saveCompany}
        updateCompany={this.props.updateCompany}
        match={{ params: this.props.router.params }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  companies: state.company.list || [],
  totalPages: state.company.total,
  member: state.member,
});

const mapDispatchToProps = {
  listCompanies,
  populateStates,
  earningLinesLookup,
  listAmendmentTypes,
  updateAuthorizationClaim,
  updateHeader,
  saveCompany,
  updateCompany,
  countriesLookup,
  timezoneLookup,
  getCompany,
  cdnLookUpHandler,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Company)
);
