import { Card } from "antd";
import EditLinkButton from "../../../Common/ClaimComponents/Button/EditLinkButton";
import MultipleUserIcon from "../../../Common/icons/MultileUserIcon";
import PayrollManagerInfoCard from "./PayrollManagerInfoCard";
interface AssignedPayrollManagersCardViewProps {
  assignedPayrollManagers: Array<any>;
  handleEdit: () => void;
  claimComponent?: string;
  claims: any;
  hasNoComponents?: boolean;
}

const AssignedPayrollManagersCardView = (
  props: AssignedPayrollManagersCardViewProps
) => {
  const {
    handleEdit,
    assignedPayrollManagers,
    claims,
    claimComponent,
    hasNoComponents,
  } = props;
  const sortedManagers = [...assignedPayrollManagers].sort(
    (a, b) => a.assigneeType - b.assigneeType
  );
  return (
    <Card
      className="customized-card-header card"
      bodyStyle={{ overflow: "auto", maxHeight: "400px" }}
      title={
        <div className="flex items-center">
          <MultipleUserIcon className="mr-2 text-2xl color-disabled opacity-60" />
          <b>Payroll Managers</b>
        </div>
      }
      extra={
        <EditLinkButton
          onClick={handleEdit}
          name={claimComponent}
          claims={claims}
          hasNoComponents={hasNoComponents}
          className="bg-default color-dark"
          editText="Manage"
        />
      }
    >
      {sortedManagers.map((user) => {
        return <PayrollManagerInfoCard key={user.id} user={user} />;
      })}
    </Card>
  );
};

export default AssignedPayrollManagersCardView;
