import { payrunAction } from "../constants/action-type/index";

const initialState = {
  draft: { list: [], count: "" },
  amendment: { list: [], count: "" },
  approval: { list: [], count: "" },
  completed: { list: [], count: "" },
  total: { list: [], count: "" },
  prepare: { list: [], count: "" },
  process: { list: [], count: "" },
  paid: { list: [], count: "" },
  unpaid: { list: [], count: "" },
  rejected: { list: [], count: "" },
  processDetail: [],
  summary: [],
  detail: {},
  hasPayrunDetailLoaded: false,
  externalEarningList: [],
  amendments: [],
  amendmentListCount: "",
  reimportEarninglines: false,
};
const {
  LIST_PAYRUN_AMENDMENTS,
  LIST_PAYRUN_COMPLETED,
  LIST_PAYRUN_APPROVAL,
  LIST_PAYRUN_TOTAL,
  LIST_PAYRUN_PREPARE,
  LIST_PAYRUN_PROCESS,
  LIST_PAYRUN_UNPAID,
  LIST_PAYRUN_PAID,
  LIST_EXTERNAL_EARNINGS,
  LIST_PAYRUN_AMENDMENTLIST,
  PAYRUN_DETAIL,
  GET_KEYPAY,
  GET_SUMMARY,
  PAYRUN_DETAIL_LOADING,
  PAYRUN_EARNINGLINES_REIMPORT,
} = payrunAction;

export default function payrunReducer(state = initialState, action: any) {
  const { data, total, status, hasPayrunDetailLoaded } = action;
  switch (action.type) {
    case LIST_PAYRUN_AMENDMENTS: {
      return {
        ...state,
        amendment: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_TOTAL: {
      return {
        ...state,
        total: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_APPROVAL: {
      return {
        ...state,
        approval: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case "LIST_PAYRUN_REJECTED": {
      return {
        ...state,
        rejected: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_UNPAID: {
      return {
        ...state,
        unpaid: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_PAID: {
      return {
        ...state,
        paid: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_COMPLETED: {
      return {
        ...state,
        completed: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_PREPARE: {
      return {
        ...state,
        prepare: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case LIST_PAYRUN_PROCESS: {
      return {
        ...state,
        process: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case "LIST_PAYRUN_DRAFT": {
      return {
        ...state,
        draft: {
          list: status ? data : [],
          count: status ? total : 0,
        },
      };
    }

    case PAYRUN_DETAIL: {
      return {
        ...state,
        detail: data,
      };
    }

    case PAYRUN_DETAIL_LOADING: {
      return {
        ...state,
        payrunDetailLoaded: hasPayrunDetailLoaded,
      };
    }

    case GET_KEYPAY: {
      return {
        ...state,
        processDetail: data,
      };
    }

    case GET_SUMMARY: {
      return {
        ...state,
        summary: data,
      };
    }

    case LIST_EXTERNAL_EARNINGS: {
      return {
        ...state,
        externalEarningList: [],
      };
    }

    case LIST_PAYRUN_AMENDMENTLIST: {
      return {
        ...state,
        amendmentList: status ? data : [],
        amendmentListCount: total,
      };
    }
    case "AMENDMENT_URL_CLEAR": {
      return {
        ...state,
        amendmentList: [],
        amendmentListCount: 0,
      };
    }
    case "CLEAR_PAYRUN": {
      return {
        ...state,
        detail: {},
        processDetail: {},
        amendmentList: [],
        amendmentListCount: 0,
        externalEarningList: [],
        process: {
          list: [],
          count: 0,
        },
        prepare: {
          list: [],
          count: 0,
        },
        completed: {
          list: [],
          count: 0,
        },
        paid: {
          list: [],
          count: 0,
        },
        unpaid: {
          list: [],
          count: 0,
        },
        rejected: {
          list: [],
          count: 0,
        },
        amendment: {
          list: [],
          count: 0,
        },
        total: {
          list: [],
          count: 0,
        },
        approval: {
          list: [],
          count: 0,
        },
        draft: {
          list: [],
          count: 0,
        },
      };
    }

    case PAYRUN_EARNINGLINES_REIMPORT: {
      return {
        ...state,
        reimportEarninglines: data,
      };
    }
    default:
      return state;
  }
}
