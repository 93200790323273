//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../libs/table";
import SimpleTableContainer from "../../Common/ClaimContainers/SimpleTableContainer";
import { getCompanyListColumns } from "../../libs/utilities";

type State = any;

class CompanyList extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      searchText: "",
    };
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order
            ? sorter.order === "ascend"
              ? "asc"
              : "desc"
            : sorter.order,
          field: sorter.field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options });
  };

  render() {
    const {
      sortInfo,
      totalPages,
      current,
      per_page,
      data,
      dashboardView,
      component,
      claims,
    } = this.props;
    const pagination = {
      total: totalPages,
      current: current,
      pageSize: per_page ? per_page : 20,
      hideOnSinglePage: true,
    };
    const colTemplate = getCompanyListColumns({
      sortInfo,
      dashboardView,
      component,
      claims,
    });
    const tableColumns = tableColumnGenerator(colTemplate);
    return (
      <SimpleTableContainer
        columns={tableColumns}
        dataSource={data}
        pagination={pagination}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        rowIdParam="id"
        dashboardView={dashboardView}
      />
    );
  }
}

export default CompanyList;
