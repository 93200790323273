import { Spin } from "antd";

// sizes small, default and large
type Props = {
  title?: string;
  spinSize?: "small" | "default" | "large";
  className?: string;
};

const Loading = ({ spinSize, title = "", className = "" }: Props) => (
  <Spin size={spinSize} tip={title} className={className} />
);

export default Loading;
