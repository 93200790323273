import React from "react";
import _ from "lodash";
import {
  currencyFormater,
  getConditionalResponse,
  mustBeArray,
  trimWhiteSpaces,
} from "../../../libs/utilities";
import { getYesNoText } from "../../../libs/general";
import { If } from "../../../Common/ui";

type State = any;
type Props = any;

class InputTypesView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getAnswersForOtherOptions = ({ options, value }: any) => {
    let nametext = "";
    const { question } = this.props;
    const answer = options.find((o: any) => o.id === value)?.name;
    if (answer) {
      nametext = answer === "Other" ? question?.textValue : answer;
    }
    return nametext;
  };

  combineNamesInArray = ({ options = null, value }: any) => {
    return Array.isArray(value) ? (
      value.map((data: any, i: any) => {
        return (
          <span className="mb-3.5" key={i}>
            {`${Number(i) === 0 ? "" : ", "}${
              options
                ? mustBeArray(options).find((o: any) => o.id === data)?.name
                : data.name
            }`}
          </span>
        );
      })
    ) : (
      <div>N/A</div>
    );
  };

  render() {
    const { question, businessunitDetails } = this.props;
    switch (question?.type?.toLowerCase()) {
      case "dropdown":
        const name = getConditionalResponse({
          condition: question?.value,
          resp1: question.options.find((o: any) => o.id === question?.value)
            ?.name,
          resp2: "N/A",
        });
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right word-break">{name}</div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "number":
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right word-break">
              {getConditionalResponse({
                condition: question?.value,
                resp1: getConditionalResponse({
                  condition: ["Amount", "HD Amount"].includes(question?.label),
                  resp1: currencyFormater(
                    question.value,
                    businessunitDetails?.country
                  ),
                  resp2: question.value,
                }),
                resp2: "N/A",
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "switch":
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right">
              {getConditionalResponse({
                condition: question?.value,
                resp1: "Yes",
                resp2: "No",
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "yesno":
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right">
              {getYesNoText({ value: question?.value })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "radio":
        const radioName = getConditionalResponse({
          condition: question?.options.find(
            (o: any) => o.id === question?.value
          )?.name,
          resp1: question?.options.find((o: any) => o.id === question?.value)
            ?.name,
          resp2: "N/A",
        });
        return (
          <React.Fragment>
            <span>{question.label}:</span>
            <div className="float-right">{radioName}</div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "checkbox":
        let options = question.options.filter((o: any) => o.value);
        return (
          <React.Fragment>
            <span>{question.label}:</span>
            <div className="float-right">
              {this.combineNamesInArray({ value: options })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "keypairlistdropdown":
        return (
          <React.Fragment>
            <div>
              <b>{question.label}:</b>
            </div>
            {mustBeArray(question.options).map((option: any, i: any) => {
              return (
                <div key={i}>
                  <span className="pl-4"> {option.name}</span>
                  <div className="float-right word-break pl-7.5">
                    {getConditionalResponse({
                      condition: option?.value,
                      resp1: option.value,
                      resp2: "N/A",
                    })}
                  </div>
                  <div className="clearfix" />
                </div>
              );
            })}
          </React.Fragment>
        );
      case "multiselectdropdown":
        return (
          <React.Fragment>
            <span>{question.label}:</span>
            <div className="float-right">
              {this.combineNamesInArray({
                options: question?.options,
                value: question?.value,
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "file":
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right">
              {Array.isArray(question?.value) ? (
                question.value.map((value: any, i: any) => {
                  return (
                    <div key={i}>
                      <a href={value} target="__blank">
                        Click here to download
                      </a>
                    </div>
                  );
                })
              ) : (
                <div>N/A</div>
              )}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "dropdownwithotherswithtext":
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right word-break">
              {this.getAnswersForOtherOptions({
                value: question?.value,
                options: question?.options,
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "dropdownwithotherswithtextarea":
        return (
          <React.Fragment>
            <span>{question.label}:</span>
            <div className="float-right word-break">
              {this.getAnswersForOtherOptions({
                value: question?.value,
                options: question?.options,
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      case "keypairdynamic":
        return (
          <React.Fragment>
            <div>{question.label}</div>
            {question.questions.map(
              (
                subQuestion: { name: string; value: any },
                i: React.Key | null | undefined
              ) => {
                return (
                  <If
                    condition={
                      trimWhiteSpaces(subQuestion.name) && subQuestion.value
                    }
                    then={
                      <React.Fragment key={i}>
                        <span className="pl-2">{subQuestion.name}:</span>
                        <div className="float-right break-words">
                          {currencyFormater(subQuestion.value)}
                        </div>
                        <div className="clearfix" />
                      </React.Fragment>
                    }
                    key={i}
                  />
                );
              }
            )}
          </React.Fragment>
        );
      case "sub":
        return (
          <React.Fragment>
            <div>{question.label}</div>
            {question.questions.map(
              (
                subQuestion: { name: string; value: any },
                i: React.Key | null | undefined
              ) => {
                return <InputTypesView question={subQuestion} key={i} />;
              }
            )}
          </React.Fragment>
        );
      case "yesnotimeperiod":
        return (
          <React.Fragment>
            <span className="pl-2">{question.label}:</span>
            <div className="float-right break-words">
              {getConditionalResponse({
                condition: question?.value,
                resp1: `${question.value} hour${question.value > 1 ? "s" : ""}`,
                resp2: "No",
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <span>{question?.label}:</span>
            <div className="float-right word-break">
              {getConditionalResponse({
                condition: question?.value,
                resp1: question.value,
                resp2: "N/A",
              })}
            </div>
            <div className="clearfix" />
          </React.Fragment>
        );
    }
  }
}

export default InputTypesView;
