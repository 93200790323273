//@ts-nocheck
import React from "react";
import { Card } from "antd";
import _ from "lodash";
import EditLinkButton from "../../Common/ClaimComponents/Button/EditLinkButton";
import {
  conditionalParameter,
  getAbnAndRegistrationText,
  getConditionalResponse,
  trimWhiteSpaces,
} from "../../libs/utilities";

const editOptions = [
  "businessunit",
  "company",
  "payrollprovider",
  "billingServiceProvider",
];

const BasicDetails = (props: any) => {
  const {
    organization,
    entity,
    edit,
    claims,
    claimComponent,
    showCompleteDetail,
    hasNoComponents,
  } = props;

  if (!organization) return <span />;
  let type = "";
  switch (entity) {
    case "businessunit":
      type = "Business Unit";
      break;
    case "company":
      type = "Company";
      break;
    case "payrollprovider":
      type = "Payroll Provider";
      break;
    case "billingServiceProvider":
      type = "Billing Provider";
      break;
    default:
      break;
  }
  return (
    <Card
      title={`Basic Details`}
      className="module-basic-details card"
      bordered={false}
      extra={
        editOptions.includes(entity) && claimComponent ? (
          <EditLinkButton
            onClick={edit}
            name={claimComponent}
            claims={claims}
            hasNoComponents={getConditionalResponse({
              condition: _.isUndefined(hasNoComponents),
              resp1: true,
              resp2: hasNoComponents,
            })}
            className="bg-default color-navy"
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      {showCompleteDetail && (
        <div className="profile profile-name">
          <p>
            <strong>
              {conditionalParameter({
                data: organization,
                field: "registeredBusinessName",
              })}
            </strong>
          </p>
          {getAbnAndRegistrationText({
            countryId: organization.addressCountryID,
          })}
          : {organization.abn}
        </div>
      )}
      <div className="profile profile-reg-address">
        <p>PHYSICAL ADDRESS</p>
        {getConditionalResponse({
          condition: conditionalParameter({
            data: organization,
            field: "registeredAddressLine1",
          }),

          resp1: (
            <div>{trimWhiteSpaces(organization.registeredAddressLine1)},</div>
          ),
          resp2: null,
        })}
        {getConditionalResponse({
          condition:
            organization.registeredAddressLine2 &&
            organization.registeredAddressLine2 !== "NA",

          resp1: (
            <div>{trimWhiteSpaces(organization.registeredAddressLine2)},</div>
          ),
          resp2: null,
        })}
        {getConditionalResponse({
          condition: conditionalParameter({
            data: organization,
            field: "registeredSuburb",
          }),

          resp1: <div>{organization.registeredSuburb},</div>,
          resp2: null,
        })}
        {getConditionalResponse({
          condition: conditionalParameter({
            data: organization,
            field: "registeredAddressState",
          }),

          resp1: <div>{organization.registeredAddressState},</div>,
          resp2: null,
        })}
        {getConditionalResponse({
          condition: conditionalParameter({
            data: organization,
            field: "registeredAddressCountry",
          }),

          resp1: (
            <div className="mb-4">
              {`${organization.registeredAddressCountry}, `}
              {organization.registeredPostalCode}
            </div>
          ),
          resp2: <React.Fragment />,
        })}
      </div>

      <div className="profile profile-pos-address">
        <p>POSTAL ADDRESS</p>
        {getConditionalResponse({
          condition: conditionalParameter({
            data: organization,
            field: "postalAddressLine1",
          }),

          resp1: <div>{trimWhiteSpaces(organization.postalAddressLine1)},</div>,
          resp2: null,
        })}
        {getConditionalResponse({
          condition:
            organization.postalAddressLine2 &&
            organization.postalAddressLine2 !== "NA",

          resp1: <div>{trimWhiteSpaces(organization.postalAddressLine2)},</div>,
          resp2: null,
        })}

        {getConditionalResponse({
          condition: conditionalParameter({
            data: organization,
            field: "postalAddressState",
          }),

          resp1: <div>{organization.postalAddressState},</div>,
          resp2: null,
        })}
        {conditionalParameter({
          data: organization,
          field: "postalAddressCountry",
        }) && (
          <div className="mb-4">
            {`${organization.postalAddressCountry}, `}
            {organization.postalPostalCode}
          </div>
        )}
      </div>
      {type === "Billing Provider" && (
        <div className="profile profile-pos-address">
          <p>
            <strong>Service Country</strong>
          </p>
          {conditionalParameter({
            data: organization,
            field: "serviceCountry",
          }) ? (
            <div>{organization.serviceCountry}</div>
          ) : null}
        </div>
      )}
    </Card>
  );
};

export default BasicDetails;
