//@ts-nocheck
import { Component } from "react";
import PayScheduleForm from "../../../components/paySchedule/create";
import { message } from "antd";
import {
  errorDisplay,
  mustBeArray,
  validateUsersAccessRights,
  findAuthorizationClaims,
  getPayScheduleRoute,
} from "../../../../libs/";
import { CREATE_PAY_SCHEDULE } from "../../../../constants/authorizationClaims";
import { withRouter } from "../../../../hooks";

type State = any;

class CreatePayschedule extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      payschedules: [],
      processingFees: [],
      payscheduleDraftDetails: {},
      editMode: false,
    };
  }

  componentDidMount() {
    this.setState({ processingFees: [] });
    const { match, pageClaims } = this.props;
    // Check if user has access to create pay schedules. If not redirect to home.
    const createButton = findAuthorizationClaims({
      claims: pageClaims.components,
      name: CREATE_PAY_SCHEDULE,
    });
    if (!validateUsersAccessRights({ claim: createButton })) return;
    if (match.params?.payscheduleid) {
      this.setState({ editMode: true });
    }
  }

  removeOption = (_rowKey, index) => {
    this.setState(
      (prevState) => {
        let newFeesList = [...prevState.processingFees];
        newFeesList.splice(index, 1);
        prevState.processingFees = newFeesList;
        return prevState;
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  savePayschedules = (values) => {
    const payscheduleid = this.props.match.params?.payscheduleid;
    if (!payscheduleid) {
      values.processingFees = this.state.processingFees;
    }
    return this.props.savePaySchedule(values).then((response) => {
      if (response.status) {
        if (payscheduleid) {
          message.success(`Pay Schedule updated successfully.`);
          this.props.router.navigate(getPayScheduleRoute());
        }
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
      return response;
    });
  };

  submitSchedule = (values) => {
    this.setState((prevState) => {
      prevState.payschedules.businessUnit = values.businessunit;
      prevState.payschedules.schedule = values.schedule;
      prevState.payschedules.method = values.method;
      prevState.payschedules.option = values.option;
    });
  };

  addOptions = (newOption) => {
    this.setState((prevState) => {
      prevState.processingFees = [
        ...mustBeArray(prevState.processingFees),
        newOption,
      ];
      return prevState;
    });
  };

  handleFormValueChange = ({ value, field }) => {
    this.setState((prevState) => {
      prevState.payscheduleDraftDetails = {
        ...prevState.payscheduleDraftDetails,
        [field]: value,
      };
      return prevState;
    });
  };

  render() {
    return (
      <PayScheduleForm
        {...this.props}
        removeOption={this.removeOption}
        savePayschedules={this.savePayschedules}
        submitSchedule={this.submitSchedule}
        addOptions={this.addOptions}
        options={this.state.processingFees}
        configurePayscheduleConnection={
          this.props.configurePayscheduleConnection
        }
        editMode={this.state.editMode}
        handleFormValueChange={this.handleFormValueChange}
      />
    );
  }
}

export default withRouter(CreatePayschedule);
