//@ts-nocheck
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";

export interface LinkButtonProps {
  name?: string;
  claims?: any;
  className?: string;
  loading?: boolean;
  onClick: () => void;
  buttonText: string;
  buttonDisabled?: boolean;
  ignoreClaims?: boolean;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  name,
  claims,
  className,
  onClick,
  buttonText,
  ignoreClaims,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <span
      className={`${className || ""} ${
        ignoreClaims ? "" : claimAndStatus.status
      } color-primary hand ml-3`}
      onClick={onClick}
    >
      {buttonText}
    </span>
  );
};

export default LinkButton;
