//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import { emailTemplatesHandler } from "../actions/email";
import axios from "axios";
import { mustBeArray } from "../../libs/utilities";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { withRouter } from "../../hooks";

class EmailContainer extends SwiftContainer {
  signal = axios.CancelToken.source();
  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        {...this.props}
        signal={this.signal}
        pageClaims={pageClaims}
        match={{ params: this.props.router.params }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  emailDetails: state.email.detail || {},
  emailCount: state.email.total || 0,
  emailList: mustBeArray(state.email.list),
  userMode: "",
  email: state.email,
  member: state.member,
});

const mapDispatchToProps = {
  emailTemplatesHandler,
  updateHeader,
  updateAuthorizationClaim,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailContainer)
);
