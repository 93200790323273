import { PendingAmount } from '@xemplo/amendment-details-modal';
import { AmendmentStatus } from '@xemplo/amendment-status';

import * as S from './payrun-worker-amendments.styles';
import { AmendmentCardProps } from './payrun-worker-amendments.types';

export const AmendmentCard = (props: AmendmentCardProps) => {
  const { amendment, onClick, testId } = props;
  const {
    amount,
    units,
    isIntegrated,
    amendmentKeypayStatusId,
    amendmentSubTypeName,
    amendmentStatusID,
    amendmentStatusName,
  } = amendment;
  const totalAmount = (amount ?? 0) * (units ?? 1);
  return (
    <S.Card data-testid={testId} onClick={onClick}>
      <S.SubTypeName>{amendmentSubTypeName ?? '-'}</S.SubTypeName>
      <S.CardDetails>
        <S.Amount>
          <PendingAmount
            value={totalAmount}
            isIntegrated={isIntegrated}
            amendmentKeypayStatusId={amendmentKeypayStatusId}
          />
        </S.Amount>
        <AmendmentStatus status={amendmentStatusID} statusName={amendmentStatusName} />
      </S.CardDetails>
    </S.Card>
  );
};
