import { isAxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { useAuth } from "@xemplo/auth-provider";
import { ErrorBoundary } from "@xemplo/error-boundary";
import { useMemberApi } from "@xemplo/gp-api";
import { memberActions } from "@xemplo/gp-member-store";
import { AuthorisationRoutes } from "@xemplo/gp-routes";
import { ExpedoUserProfile, LoginActions } from "@xemplo/gp-types";
import { XemploLoader } from "@xemplo/loader";
import { HeadingStandardSemiBold } from "@xemplo/style-constants";
import { GlobalNavQueryParam } from "@xemplo/types";

import { DEFAULT_HOME_ROUTE } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getReturnUrl } from "../../libs/return-url.helper";
import { ToastType, useToast } from "@xemplo/toast";

interface LoginProps {
  action: LoginActions;
}

const Message = styled.h1`
  ${HeadingStandardSemiBold};
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Login = ({ action }: LoginProps) => {
  const [message, setMessage] = useState("");
  const [getProfileInProgress, setGetProfileInProgress] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const { isLoading, isAuthenticated, signinRedirect } = useAuth();
  const { getProfile, cancelSignal, switchPersona } = useMemberApi();
  const { loadProfile, details } = useAppSelector((state) => state.member);
  const { addToast } = useToast();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const needProfileData = useMemo(() => details == null, [details]);

  /**
   * There are different possible path after login.
   * 1. When profile data does not have the authorization DOM, it needs to sign the terms and conditions
   * 2. When profile data does not have a selected role, it needs to select a role
   * 3. It has a return url that is not within the login flow, it needs to redirect to that url
   * 4. Goes to the default home route
   */
  const handleNextRoute = (
    profile: ExpedoUserProfile,
    ignoreSearchParams?: boolean
  ) => {
    /** 
      If user has been redirected from another application with selected persona, update the selected persona
    **/
    const personaId = new URL(window.location.href).searchParams.get(
      GlobalNavQueryParam.Persona
    );
    if (personaId && !ignoreSearchParams) {
      handlePersonaUpdate(personaId, profile);
      return;
    }
    if (!profile.selectedRole) {
      navigate("/select-role");
      return;
    }

    if (!profile.authorizationDOM) {
      navigate("/verifyDetails");
      return;
    }

    navigate(getReturnUrl() ?? DEFAULT_HOME_ROUTE);
  };

  const handlePersonaUpdate = async (
    personaId: string,
    profile: ExpedoUserProfile
  ) => {
    const selectedPersona = profile.userPersona?.find(
      (p) => p.directoryServicePersonaId === personaId
    );
    if (!selectedPersona) return;
    try {
      const profile = await switchPersona(selectedPersona);
      dispatch(memberActions.setDetails(profile));
      dispatch({ type: "UPDATE_DASHBOARD_LIST", data: profile.dashboards });
      handleNextRoute(profile, true);
    } catch (error) {
      addToast({
        type: ToastType.Warning,
        text: error as any, // TODO: Not sure what the error object actually is
      });
    }
  };

  /**
   * Since we redirect to EIMS, we need to track the state of authentication
   * Triggering the getProfile directly here was having some issues as the result
   * of the getProfile modifies the member data and triggers the effect continuously
   * To fix this, created a separate effect to handle the getProfile call that depends
   * only on the member.loadprofile flag.
   */
  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated && action === LoginActions.Login) {
      setMessage("Checking credentials...");
      signinRedirect();
      return;
    }

    if (!isAuthenticated) return;
    if (!needProfileData) {
      handleNextRoute(details!);
      return;
    }
    setMessage("Getting user profile...");
    dispatch(memberActions.loadProfile());
  }, [
    signinRedirect,
    setMessage,
    isAuthenticated,
    isLoading,
    action,
    dispatch,
    needProfileData,
    handleNextRoute,
  ]);

  /**
   * Needed to do this to avoid race condition issues
   * and to avoid the effect running multiple times when adding
   * dependency on the member data. Should probably look into using
   * Signals to avoid this convoluted solution.
   */
  useEffect(() => {
    if (errorCount > 2) return;
    if (loadProfile && needProfileData && !getProfileInProgress) {
      const cancelToken = cancelSignal.token;
      setGetProfileInProgress(true);
      getProfile(cancelToken)
        .then((profile) => {
          dispatch(memberActions.setDetails(profile));
          dispatch({ type: "UPDATE_DASHBOARD_LIST", data: profile.dashboards });

          // TODO: There are code that still relies on the userArea coming from local storage
          // This should be removed once all the code is updated to use the member store
          localStorage.setItem("userArea", profile.area ?? "");
          handleNextRoute(profile);
        })
        .catch((error) => {
          const errorStatusCode = error.response?.status;
          if (
            isAxiosError(error) &&
            (errorStatusCode === 401 || errorStatusCode === 403)
          ) {
            navigate(`${AuthorisationRoutes.NoAccess}`);
          }
          setMessage(error.message);
          setErrorCount(errorCount + 1);
        })
        .finally(() => setGetProfileInProgress(false));
    }
  }, [
    loadProfile,
    needProfileData,
    cancelSignal,
    getProfile,
    dispatch,
    navigate,
    handleNextRoute,
  ]);

  return (
    <ErrorBoundary>
      <Message>{message}</Message>
      {errorCount < 2 && <XemploLoader />}
    </ErrorBoundary>
  );
};

/** @alias */
export default Login;
