import { SVGProps } from 'react';
const DawnHandMoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.468 3.53a1.373 1.373 0 1 0 0 2.746 1.373 1.373 0 0 0 0-2.746ZM7.595 4.903a2.873 2.873 0 1 1 5.746 0 2.873 2.873 0 0 1-5.746 0Zm9.667 3.723a1.373 1.373 0 1 0 0 2.746 1.373 1.373 0 0 0 0-2.746Zm-2.873 1.373a2.873 2.873 0 1 1 5.746 0 2.873 2.873 0 0 1-5.746 0ZM3.25 11.372a.75.75 0 0 0-.75.75v5.944c0 .284.16.544.415.67l5.788 2.895a3.297 3.297 0 0 0 2.659.128l9.99-3.842a.75.75 0 0 0 .478-.769l-.747.069.747-.07v-.008l-.002-.012-.004-.032a2.357 2.357 0 0 0-.09-.376 2.628 2.628 0 0 0-.44-.818c-.497-.624-1.36-1.133-2.758-1.133H14.24a4.141 4.141 0 0 0-3.347-1.698h-2.19c-.614-.67-1.485-1.072-2.254-1.312-.902-.282-1.807-.386-2.35-.386H3.25Zm15.286 4.896c.939 0 1.357.304 1.552.528l-9.265 3.564a1.797 1.797 0 0 1-1.45-.07L4 17.602v-4.731h.1c.395 0 1.148.082 1.902.317.773.242 1.407.601 1.729 1.061.14.2.37.32.614.32h2.548c1.201 0 2.215.8 2.539 1.897H8.345a.75.75 0 0 0 0 1.5h5.945a.75.75 0 0 0 .75-.75c0-.326-.038-.644-.11-.948h3.606Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnHandMoney;
