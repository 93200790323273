import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { requireAuth } from '@xemplo/auth-provider';
import { ErrorBoundary } from '@xemplo/error-boundary';
import { PayrollRoutes } from '@xemplo/gp-routes';
import { withLazyComponent } from '@xemplo/lazy-component';

const AmendmentsScreen = lazy(() =>
  import('@xemplo/amendments-screen').then((m) => ({ default: m.AmendmentsScreen }))
);

export const AmendmentsScreenRoute: RouteObject[] = [
  {
    path: PayrollRoutes.AmendmentsScreen,
    Component: requireAuth(withLazyComponent(AmendmentsScreen)),
    errorElement: <ErrorBoundary />,
  },
];
