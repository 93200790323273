//@ts-nocheck
import React from "react";
import { FilePdfOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { onlyDateFormater } from "../../../libs";
import { COMPLETED_PAYRUN_STATUS } from "../../../constants/payrun";

const InvoiceDetails = ({ invoice, payrun }: any) => {
  return (
    <Row type="flex" align="top">
      <Col span={9}>
        <h1 className="m-0">
          {invoice &&
            invoice.pdfDownloadUrl &&
            ![COMPLETED_PAYRUN_STATUS].includes(
              payrun && payrun.payrunStatusID
            ) && (
              <Tooltip title="Download Invoice" placement="bottomLeft">
                <FilePdfOutlined
                  className="color-primary text-base mr-1.5 hand"
                  onClick={() => window.open(invoice.pdfDownloadUrl)}
                />
              </Tooltip>
            )}
          Tax Invoice
        </h1>
      </Col>
      <Col span={4}>
        <span>
          <div id="test">Invoice Date</div>
          <div>{onlyDateFormater(invoice && invoice.invoiceDate)}</div>
        </span>
      </Col>
      <Col span={4}>
        <span>
          <div>Invoice Number</div>
          <div>{invoice && invoice.invoiceNumber}</div>
        </span>
      </Col>
      <Col span={4}>
        <span>
          <div>Reference</div>
          <div>{invoice && invoice.reference}</div>
        </span>
      </Col>
      <Col span={3}>
        <span>
          <div>ABN</div>
          <div>{invoice && invoice.businessNumber}</div>
        </span>
      </Col>
    </Row>
  );
};

export default InvoiceDetails;
