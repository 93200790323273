//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../../libs/table";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import { Card } from "antd";
import { formatByUnit } from "../../../../libs/utilities";

class AuditReportLeaveList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    let options = {
      page: pagination.current,
      order: sorter.order === "ascend" ? "asc" : "desc",
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  render() {
    const { leaveType, data, loading } = this.props;
    let colTemplate = [
      {
        title: "Employee Name",
        dataIndex: "employeeName",
        sorter: false,
        width: "25%",
      },
      {
        title: "Leave Type",
        dataIndex: "leaveCategory",
        sorter: false,
        width: "25%",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        sorter: false,
        width: "25%",
      },
      {
        title: leaveType === "taken" ? "Amount Taken" : "Amount Accrued",
        dataIndex: "amount",
        sorter: false,
        width: "25%",
        render: (record: any) => {
          return formatByUnit(record, "hours");
        },
      },
    ];
    const column = tableColumnGenerator(colTemplate);
    return (
      <Card
        title={
          <div className="pt-3 pl-3">{`Leave ${
            leaveType === "taken" ? "Taken" : "Accrued"
          }`}</div>
        }
        className="card-space-0"
      >
        <SimpleTableContainer
          columns={column}
          dataSource={data}
          loading={loading}
          onChange={this.handleTableChange}
          pagination={false}
          size="small"
        />
      </Card>
    );
  }
}

export default AuditReportLeaveList;
