import { ReactNode } from 'react';

export enum BadgeColour {
  Blue = 'Blue',
  Gray = 'Gray',
  Green = 'Green',
  Orange = 'Orange',
  Purple = 'Purple',
  Red = 'Red',
  White = 'White',
  LightBlue = 'LightBlue',
  LightGray = 'LightGray',
  LightGreen = 'LightGreen',
  LightOrange = 'LightOrange',
  LightPurple = 'LightPurple',
  LightRed = 'LightRed',
  LightWhite = 'LightWhite',
}

export enum BadgeType {
  Default = 'Default',
  Pill = 'Pill',
  Square = 'Square',
  Round = 'Round',
  DefaultPip = 'DefaultPip',
  PillPip = 'PillPip',
}

export enum BadgeSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

export interface BadgeProps {
  value: string;
  className?: string;
  color?: BadgeColour;
  type?: BadgeType;
  size?: BadgeSize;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  leadingIcon?: ReactNode;
}

/**
 * Omitting onClick and add a boolean flag instead because
 * styled components doesn't seems to like functions into its props
 * and it also doesn't like optional props, so wrapping it in a
 * Required<> type to make the props required
 */
export type BadgeStyleProps = Required<
  Omit<BadgeProps, 'value' | 'className' | 'onClick' | 'leadingIcon'>
>;

export type PipStyleProps = Required<Pick<BadgeProps, 'color' | 'size'>>;
