import React from "react";
import { getClaimAndStatus, isComponentVisible } from "../../../libs/utilities";
import CardWithTitleLayout from "../../layout/CardWithTitleLayout";

type GeneralTableProps = {
  claims: object;
  requiresCard?: boolean;
  hasNoTitle?: boolean;
  name?: string;
  componentName?: string;
  action?: string;
  className?: string;
  title?: string;
  children: React.ReactNode;
  ignoreStatus?: boolean;
  showTitle?: boolean;
  match?: any;
  handleEvent?: (e: any) => void;
};

const classNameDefault = "";

const GeneralTable = ({
  claims,
  requiresCard,
  hasNoTitle,
  name,
  title,
  action,
  handleEvent,
  componentName,
  ignoreStatus,
  showTitle,
  match,
  children,
  className = classNameDefault,
}: GeneralTableProps) => {
  {
    const claimAndStatus = getClaimAndStatus({
      claims,
      componentName: name,
      hasComponents: true,
    });
    const claim = claimAndStatus.claim;
    const childrenWithProps = React.Children.map(children, (child: any) =>
      React.cloneElement(child, { claims: claim })
    );
    return requiresCard ? (
      <CardWithTitleLayout
        showTitle={showTitle}
        hasNoTitle={hasNoTitle}
        className={`${className} ${ignoreStatus || claimAndStatus.status}`}
        title={title}
        name={action}
        handleEvent={handleEvent}
        componentName={componentName}
        claims={claim}
        match={match}
      >
        {(isComponentVisible({ claim }) || ignoreStatus) && childrenWithProps}
      </CardWithTitleLayout>
    ) : (
      <span className={`${className} ${ignoreStatus || claimAndStatus.status}`}>
        {(isComponentVisible({ claim }) || ignoreStatus) && childrenWithProps}
      </span>
    );
  }
};

export default GeneralTable;
