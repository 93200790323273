import { useEffect, useRef } from 'react';

import { useDebounceCallback } from '@xemplo/hooks';

export function useScrollBehaviour() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const debouncedHandleScroll = useDebounceCallback(handleScroll, 10);

  function handleScroll(this: HTMLDivElement, ev: Event) {
    headerRef.current?.classList.toggle('show-keyline', this.scrollTop > 0);
    containerRef.current?.classList.toggle(
      'show-overflow-shadow',
      !(this.scrollTop === this.scrollHeight - this.clientHeight)
    );
  }

  useEffect(() => {
    const body = bodyRef.current;
    const container = containerRef.current;
    if (!body || !container) return;
    if (checkOverflow(body)) {
      container.classList.add('show-overflow-shadow');
    }
    body.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      body?.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [bodyRef, containerRef, debouncedHandleScroll, headerRef]);

  return {
    containerRef,
    bodyRef,
    headerRef,
  };
}

// Determines if the passed element is overflowing its bounds, either vertically or horizontally.
// Will temporarily modify the "overflow" style to detect this if necessary.
function checkOverflow(el: HTMLElement) {
  const curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
  const isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;
  return isOverflowing;
}
