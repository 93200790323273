import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { PayrunTypes } from '@xemplo/gp-types';
import {
  parseToCalendarDate,
  TextDatePickerV2,
  tryParseDate,
} from '@xemplo/text-date-picker';

import * as S from '../../modal.styles';

export function PayDate() {
  const { register, unregister, watch } = useFormContext();

  const watchPayrunType = watch('payrunType');
  const watchPayDate = watch('adhocPayDate');
  const showPayDate = watchPayrunType === PayrunTypes.ADHOC;
  useEffect(() => {
    if (showPayDate) {
      register('adhocPayDate', {
        required: 'This field is required',
      });
    } else {
      unregister('adhocPayDate');
    }
  }, [register, unregister, showPayDate]);

  if (showPayDate) {
    return (
      <S.FormLabel>
        Choose pay date
        <TextDatePickerV2
          name="adhocPayDate"
          inputProps={{
            placeholder: 'Select date',
            width: '100%',
            minValue: parseToCalendarDate(),
            defaultValue: tryParseDate(watchPayDate),
          }}
        />
      </S.FormLabel>
    );
  }
}
