// @ts-nocheck
import React from "react";
import { Badge } from "antd";

type WrapperProps = {
  children: React.ReactNode;
  className?: string;
  theme: "danger" | "success" | "warning" | "info" | "white" | "primary";
};

type Props = {
  className?: string;
  children: React.ReactNode;
};

const DEFAULT_CLASS_NAME = "";

const Wrapper = ({
  children,
  theme,
  className = DEFAULT_CLASS_NAME,
}: WrapperProps) => (
  <Badge
    className={`rounded-[20px] py-1 px-2.5 text-[11px] ${className} bg-${theme}`}
  >
    {children}
  </Badge>
);

const Danger = (props: Props) => {
  return <Wrapper {...props} theme="danger" />;
};

const Info = (props: Props) => {
  return <Wrapper {...props} theme="info" />;
};

const Warning = (props: Props) => {
  return <Wrapper {...props} theme="warning" />;
};

const Success = (props: Props) => {
  return <Wrapper {...props} theme="success" />;
};

const Neutral = (props: Props) => {
  return <Wrapper {...props} theme="white" />;
};

const Primary = (props: Props) => {
  return <Wrapper {...props} theme="primary" />;
};

const BadgeCustom = { Danger, Info, Warning, Success, Neutral, Primary };

export default BadgeCustom;
