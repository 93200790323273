import styled from 'styled-components';

import { Avatar as ExpedoAvatar } from '@xemplo/avatar';
import { Button } from '@xemplo/button';
import {
  BodyLargeMedium,
  BodySmallRegular,
  BodySmallSemiBold,
  BodyStandardRegular,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
  HeadingSmallMedium,
  Shadow,
  TextEllipsis,
} from '@xemplo/style-constants';

/** Drawer Header  */
export const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 32px;
`;
export const LeadIconWrapper = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: ${Colour.Blue[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 30px;
    height: 30px;
    color: ${Colour.Blue[500]};
  }
`;
export const Avatar = styled(ExpedoAvatar)`
  border: 2px solid ${Colour.White[100]};
  ${Shadow.Medium1};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Name = styled.span`
  color: ${Colour.Gray[600]};
  ${BodyLargeMedium};
`;

export const Title = styled.span`
  color: ${Colour.Gray[600]};
  ${TextEllipsis};
  ${BodyXSmallRegular};
`;

export const Salary = styled.span`
  margin-top: 8px;
  color: ${Colour.Gray[400]};
  ${TextEllipsis};
  ${BodyXSmallRegular};
`;

/** Amendment details */
export const BackButton = styled(Button)`
  margin-block: 24px;
`;

export const DetailContainer = styled.div`
  margin-inline: 32px;
`;
export const SubType = styled.span`
  color: ${Colour.Gray[600]};
  ${BodyLargeMedium};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  & > svg {
    width: 24px;
    height: 24px;
    color: ${Colour.Gray[400]};
  }
`;

export const DetailRow = styled.div`
  margin-block: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${BodySmallRegular};
`;
export const LargeDetailRow = styled.div`
  margin-block: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  ${BodySmallRegular};
`;

export const DetailLabel = styled.span`
  color: ${Colour.Gray[500]};
`;

export const DetailValue = styled.span`
  color: ${Colour.Gray[800]};
`;

export const PendingAmount = styled.div`
  color: ${Colour.Gray[800]};
  display: flex;
  gap: 4px;
`;

export const TotalAmount = styled.div`
  box-sizing: border-box;
  background: ${Colour.Green[50]};
  border-radius: 4px;
  width: calc(100% + 26px);
  margin-left: -13px;
  padding-inline: 13px;
  padding-block: 8.5px;
  display: flex;
  align-items: center;
  color: ${Colour.Green[800]};
  justify-content: space-between;
  ${BodySmallSemiBold};
`;

/** Drawer Footer */
export const Footer = styled.div`
  padding: 16px 32px 32px 32px;
  display: flex;
  gap: 16px;
  align-items: center;
  button {
    width: 100%;
  }
`;

/** Confirm remove prompt */
export const PromptHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: ${Colour.Gray[800]};
  ${HeadingSmallMedium};
  & > svg {
    height: 40px;
    width: 40px;
    color: ${Colour.Red[500]};
  }
`;

export const PromptBody = styled.div`
  text-align: center;
  color: ${Colour.Gray[600]};
  ${BodyStandardRegular};
`;

export const ErrorMessage = styled.div`
  display: flex;
  padding: 8px;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${Colour.Red[300]};
  background: ${Colour.Red[50]};
  color: ${Colour.Gray[600]};
  justify-content: flex-start;
  ${BodyXSmallRegular};
  & > svg {
    color: ${Colour.Red[500]};
    height: 24px;
    width: 24px;
  }
`;

export const RejectReasonContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  border: 1px solid ${Colour.Red[300]};
  background: ${Colour.Red[50]};
  margin-bottom: 24px;
  & svg {
    color: ${Colour.Red[500]};
    width: 24px;
    height: 24px;
  }
`;

export const RejectReasonDescription = styled.div`
  color: ${Colour.Gray[600]};
  ${BodyXSmallRegular};
  & :first-child {
    ${BodyXSmallSemiBold};
  }
`;
