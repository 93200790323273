//@ts-nocheck
import React from "react";
import {
  ApiOutlined,
  CloseOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Input,
  Table,
  Row,
  Card,
  Col,
  Button,
  Select,
  InputNumber,
  message,
  Popconfirm,
  Checkbox,
  Tooltip,
  Avatar,
  Layout,
  Spin,
} from "antd";
import {
  formatTime,
  populateDateFields,
  populateDateAndTimeFields,
  dateFormaterPayload,
} from "../../../libs";
import {
  guid,
  currencyFormater,
  mustBeArray,
  errorDisplay,
  delay,
  getConditionalResponse,
  getRatePercentageText,
  getBusinessModelName,
  getMatchParams,
  getOptionalParameter,
  errorHandler,
  isEmpty,
  validateUsersAccessRights,
  findAuthorizationClaims,
} from "../../../libs/";
import PayScheduleConnect from "../payrun/connect";
import PayrunForm from "./form/payrun";
import ApplicableFeesOptions from "../common/feeOption";
import StateRate from "../common/stateRate";
import EmployeeRate from "../common/employeeRate";
import ProcessingFeesView from "../processingFees/view";
import PayCategory from "../common/payCategory";
import BaseSalary from "../common/baseSalary";
import ArchievedEmployees from "./archievedEmployees";
import CountryCoordinates from "../../../Common/countries";
import CreateSuccess from "../../../Common/CreateSuccess";
import FormNavbar from "../../../Layout/FormNavbar";
import NoRecord from "../../../Common/NoRecord";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import {
  END_EMPLOYEE_NUMBER,
  OUTSOURCE_MODEL_ID,
  PEO_MODEL_ID,
  PROCESSING_FEES_OPTIONS,
} from "../../../constants/payrun";
import {
  isEmployeeRangeValid,
  isProcessingFeesValid,
  processingFeesHasAtLeastOneFieldSelected,
} from "../../../libs/validations";
import { PAYRUN_CREATE_SIMPLE_BUTTON } from "../../../constants/authorizationClaims";
import { FormStepsCol, If } from "../../../Common/ui";
import { withRouter } from "../../../hooks";

const { Header, Content } = Layout;

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class AdhocPayrun extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      pageLoading: true,
      editMode: false,
      disableIndex: 4,
      submitText: "SAVE",
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      businessunit: {},
      disableBU: false,
      disableCompany: false,
      schedule: {},
      visible: false,
      connectionSet: false,
      feeOptions: PROCESSING_FEES_OPTIONS,
      selectedOptions: [],
      companyList: [],
      company: [],
      companyId: "",
      businessunitList: [],
      businessunitId: "",
      fetching: false,
      payDate: 61,
      steps: [],
      payrollProviderId: null,
      connection: {},
      taxOptions: [],
      loading: false,
      employeeNumberRangeFactor: [],
      amendments: [],
      earningLines: [],
      states: [],
      defaultAmendments: [],
      defaultEarningLines: [],
      showProcessingFeeDetails: false,
      feeDetails: {},
      validRange: [],
      payscheduleDates: {},
      formIndex: 0,
      confirmLoading: false,
      businessUnitChanged: true,
      defaultBaseSalary: [],
      baseSalary: [],
      baseSalaryLoading: false,
      expenses: [],
      defaultExpenses: [],
      expensesLoading: false,
      displayEmployeeList: false,
      archievedEmployees: [],
      addedEmployees: [],
      basePayMapping: [],
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
        },
        {
          id: 2,
          label: "External Payschedule",
        },
        {
          id: 3,
          label: "Processing Fees",
        },
      ],
      newPayrunId: "",
      actionTwoLink: "",
      showFooter: false,
      checkStateRateConfirmation: false,
      externalPayScheduleFrequency: null, //To calculate the period end date in BE
    };
  }

  componentDidMount() {
    const { match, pageClaims, updateHeader } = this.props;
    updateHeader({
      header: {
        title: "New Pay Run",
        module: "Back to Pay Run List",
        enableBack: true,
        entity: "payrun",
        returnUrl: `/payrun`,
      },
    });
    // Check if user has access to create pay runs. If not redirect to home.
    const createButton = findAuthorizationClaims({
      claims: pageClaims.components,
      name: PAYRUN_CREATE_SIMPLE_BUTTON,
    });
    if (!validateUsersAccessRights({ claim: createButton })) return;
    if (match?.params?.payrunid) {
      this.setState({ editMode: true });

      this.props
        .getPayrun({ id: match.params.payrunid, options: {} })
        .then((resp) => {
          this.setState({ pageLoading: false }, () => {
            if (resp.status && resp.data) {
              this.populateFields({ data: resp.data });
            }
          });
        });
    } else {
      this.setState({ pageLoading: false });
    }
    if (match?.params?.businessunitid) {
      this.changedBusinessUnit(
        match.params.businessunitid,
        match.params.payrunid
      );
    }
  }

  componentWillUnmount() {
    this.props.signal.cancel("Api is being canceled");
  }

  populateFields = ({ data = {} }) => {
    delay(300).then(() => {
      this.changedBusinessUnit(data.businessUnitID);
      this.props.form.setFieldsValue(
        {
          businessunit: data.businessUnitID,
          payrunName: data.name,
          payDate: populateDateFields(data.payDate),
          reference: data.reference,
          PayrollModel: data.payrollModelId,
          cycleStartDate: populateDateAndTimeFields(data.payCycleStartDate),
        },
        () => {
          this.props.form.setFieldsValue({
            amendmentsDueDate: populateDateAndTimeFields(
              data.ammendmentDueDateTime
            ),
            amendmentsDueTime: populateDateAndTimeFields(
              data.ammendmentDueDateTime
            ),
            payrunPreparationDueDate: populateDateAndTimeFields(
              data.preparationDueDateTime
            ),
            payrunPreparationDueTime: populateDateAndTimeFields(
              data.preparationDueDateTime
            ),
            approvalDueDate: populateDateAndTimeFields(
              data.approvalDueDateTime
            ),
            approvalDueTime: populateDateAndTimeFields(
              data.approvalDueDateTime
            ),
            paymentDueDate: populateDateAndTimeFields(data.paymentDueDateTime),
            paymentDueTime: populateDateAndTimeFields(data.paymentDueDateTime),
            isInvoiceNeeded: data.isInvoiceNeeded,
          });
          if (data.payrollModelId === OUTSOURCE_MODEL_ID) {
            this.props.form.setFieldsValue({
              PSPUploadBank: data.isUploadBankFile ? 1 : 2,
              BankfileUploadDueDate: populateDateAndTimeFields(
                data.bankFileUploadDueDateTime
              ),
              BankfileUploadDueTime: populateDateAndTimeFields(
                data.bankFileUploadDueDateTime
              ),
            });
          }
        }
      );
    });
  };
  handleNext = (values) => {
    switch (Number(this.state.formIndex)) {
      case 0:
        this.setState({ payscheduleDates: { ...values }, formIndex: 1 });
        break;
      case 1:
        const basePayMapping = this.state.baseSalary.filter((o) =>
          mustBeArray(this.props.form.getFieldValue("baseSalary")).includes(
            o.id
          )
        );
        if (isEmpty(basePayMapping)) {
          return message.error("Please select Base Pay.");
        }
        this.setState({ formIndex: 2, basePayMapping });
        break;
      case 3:
        // submit form
        break;
      default:
        console.log("");
        break;
    }
    this.forceUpdate();
  };
  handleBack = () => {
    this.setState(
      (prevState) => {
        prevState.formIndex = Number(prevState.formIndex) - 1;
        prevState.checkStateRateConfirmation = false;
        return prevState;
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  removeRow = (_rowKey, i) => {
    const { form } = this.props;
    this.setState(
      (prevState) => {
        let newOptions = [...prevState.selectedOptions];
        newOptions.splice(i, 1);
        prevState.selectedOptions = newOptions;
        return prevState;
      },
      () => {
        let dueDate = form.getFieldValue("paymentDueDate");
        let dueTime = form.getFieldValue("paymentDueTime");
        form.resetFields(["paymentDueDate", "paymentDueTime"]);
        form.setFieldsValue({
          paymentDueTime: dueTime,
          paymentDueDate: dueDate,
        });
      }
    );
  };

  handleSaveAsDraft = (e) => {
    e.preventDefault();
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { match } = this.props;
      const payrunid = getMatchParams({ match, field: "payrunid" });
      if (
        Number(values.PayrollModel) === 2 &&
        Number(values.BankfileDated) === 2 &&
        !this.state.bankFileDate
      ) {
        return message.warning("Bank file date is required");
      }
      if (
        getOptionalParameter({
          value1: Number(this.state.formIndex) === 1,
          value2: Number(this.state.formIndex) === 0,
        })
      ) {
        return this.handleNext(values);
      }

      this.setState({ loading: true });
      const {
        payrollSystemConnectionName,
        externalBusinessUnitName,
        externalPayScheduleName,
      } = this.state;
      values = { ...this.state.payscheduleDates };
      const postData = {
        businessUnitId: values.businessunit,
        payrunName: values.payrunName,
        reference: values.reference,
        payDate: dateFormaterPayload(values.payDate),
        amendmentsDueDate: dateFormaterPayload(values.amendmentsDueDate),
        amendmentsDueTime: formatTime(values.amendmentsDueTime),
        payCycleStartDate: dateFormaterPayload(values.cycleStartDate),
        payRunPreparationDueDate: dateFormaterPayload(
          values.payrunPreparationDueDate
        ),
        payRunPreparationDueTime: formatTime(values.payrunPreparationDueTime),
        approvalDueDate: dateFormaterPayload(values.approvalDueDate),
        approvalDueTime: formatTime(values.approvalDueTime),
        payrollModelId: values.PayrollModel,
        isUploadBankFile: getConditionalResponse({
          condition: Number(values.PSPUploadBank === 1),
          resp1: true,
          resp2: false,
        }),
        bankFileUploadDueDate:
          values.BankfileUploadDueDate &&
          dateFormaterPayload(values.BankfileUploadDueDate),
        bankFileUploadDueTime: formatTime(values.BankfileUploadDueTime),
        isBankFileUploadDatePayDay: getConditionalResponse({
          condition: Number(values.BankfileDated === 1),
          resp1: true,
          resp2: false,
        }),
        bankFileDate:
          this.state.bankFileDate &&
          dateFormaterPayload(this.state.bankFileDate),
        paymentDueDate: dateFormaterPayload(
          values.paymentDueDate,
          "1990/01/01"
        ),
        paymentDueTime: formatTime(values.paymentDueTime, "00:00"),
        payrollSystemConnectionId:
          this.state.connection.payrollSystemConnectionId,
        externalBusinessUnitId: this.state.connection.externalBusinessUnitCode,
        externalPayScheduleId: this.state.connection.externalPayScheduleCode,
        processingFees: this.state.selectedOptions,
        isInvoiceNeeded: getConditionalResponse({
          condition:
            Number(values.PayrollModel) === 2 &&
            Number(values.isInvoiceNeeded) === 2,
          resp1: false,
          resp2: true,
        }),
        payrollSystemConnectionName,
        externalBusinessUnitName,
        externalPayScheduleName,
        basePayMapping: this.state.basePayMapping,
        externalPayScheduleFrequency: this.state.externalPayScheduleFrequency,
      };
      return payrunid
        ? this.props
            .payrunHandler({
              id: payrunid,
              action: "edit",
              payload: { ...postData, id: payrunid },
            })
            .then((resp) => {
              if (resp.status) {
                message.success("Pay Run saved successfully.");
                this.props.router.navigate(`/payrun/${payrunid}`);
              } else {
                errorHandler({ response: resp, hasValidationErrors: true });
              }
            })
        : this.handleGenerateAdhocPayrun({ postData });
    });
  };

  handleGenerateAdhocPayrun = ({ postData }) => {
    this.props.generateAdhoc(postData).then((resp) => {
      if (resp.status && resp.data) {
        const payload = {
          payrollSystemConnectionId: postData.payrollSystemConnectionId,
          externalBusinessUnitId: postData.externalBusinessUnitId,
          businessUnitId: postData.businessUnitId,
          externalPayscheduleId: postData.externalPayScheduleId,
        };

        this.setState({
          actionOneLink: `/payrun/${resp.data.result}`,
          newPayrunId: resp.data.result,
        });

        this.handleListArchievedEmployees({ id: resp.data.result, payload });
      } else {
        this.setState({ loading: false });

        errorDisplay(resp & resp.data & resp.data.validationErrors);
      }

      return resp;
    });
  };

  handleListArchievedEmployees = ({ id, payload }) => {
    this.props.listArchievedEmployees({ id, payload }).then((response) => {
      this.setState({ loading: false });

      if (
        response.status &&
        response.data &&
        !response.data.isNewBusinessUnit &&
        (!isEmpty(mustBeArray(response.data.archivedEmployee)) ||
          !isEmpty(mustBeArray(response.data.addedEmployee)))
      ) {
        this.setState(
          {
            addedEmployees: response.data.addedEmployee,
            archievedEmployees: response.data.archivedEmployee,
          },
          () => {
            this.setState({ displayEmployeeList: true });
          }
        );
      } else {
        this.props.form.resetFields();
      }
    });
  };

  createConnection = (
    values,
    payrollSystemConnectionName,
    externalBusinessUnitName,
    externalPayScheduleName,
    externalPayScheduleFrequency
  ) => {
    this.setState({
      buttonProps: { ...this.state.buttonProps, loading: true },
      confirmLoading: true,
    });
    let payload = {
      payrollSystemConnectionId: values?.payrollSystemConnectionId,
      externalBusinessUnitId: values?.externalBusinessUnitCode,
    };

    this.props
      .confirmPayrollConnection({
        payload,
        id: this.props.form.getFieldValue("businessunit"),
      })
      .then((resp) => {
        this.setState({
          buttonProps: { ...this.state.buttonProps, loading: false },
          confirmLoading: false,
        });

        if (resp.status) {
          this.setState(
            {
              connection: values,
              visible: false,
              payrollSystemConnectionName,
              externalBusinessUnitName,
              externalPayScheduleName,
              connectionSet: true,
              businessUnitChanged: false,
              baseSalaryLoading: true,
              externalPayScheduleFrequency, //BE uses it to calculate the period end date
            },
            () => {
              this.props
                .listAmendmentTypes({
                  options: {},
                  businessUnitID: this.props.form.getFieldValue("businessunit"),
                  lookUp: true,
                })
                .then((res) => {
                  if (res.status && res.data) {
                    this.setState({
                      baseSalary: mustBeArray(res.data),
                      defaultBaseSalary: mustBeArray(res.data),

                      amendments: mustBeArray(res.data),
                      defaultAmendments: mustBeArray(res.data),
                    });
                  } else {
                    errorDisplay(res?.data?.validationErrors);
                  }
                });
            }
          );
        } else {
          errorDisplay(resp?.data?.validationErrors, true);
        }
      });

    this.props
      .confirmPayrollConnection({
        payload,
        id: this.props.form.getFieldValue("businessunit"),
        type: "expenses",
      })
      .then((resp) => {
        if (resp.status) {
          this.props
            .payCategoryLookUp({
              options: {
                q: {
                  businessUnitID: this.props.form.getFieldValue("businessunit"),
                },
              },
            })
            .then((res) => {
              this.setState({ baseSalaryLoading: false });

              if (res.status) {
                this.setState({
                  expenses: mustBeArray(res.data),
                  defaultExpenses: mustBeArray(res.data),
                });
              } else {
                errorDisplay(res?.data?.validationErrors, true);
              }
            });
        }
      });
  };

  handleConnectionChange = () => {
    this.setState({
      visible: true,
      buttonProps: { loading: true },
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      buttonProps: {
        ...this.state.buttonProps,
        loading: false,
        type: this.state.externalPayScheduleFrequency ? "default" : "primary",
      },
      confirmLoading: false,
    });
  };
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  onCancel = () => {
    this.props.router.navigate("/payrun");
  };

  addProcessingFees = () => {
    const form = this.props.form;

    let updatedEmployeeRangeFactor = form.getFieldValue("addEmployeeNumber")
      ? this.state.employeeNumberRangeFactor.map((o) => {
          return { ...o, rate: Number(o.rate) };
        })
      : [];

    let updatedStateRate = this.state.states.map((o) => {
      return { rate: Number(o.rate), stateName: o.value, stateId: o.id };
    });

    const validProcessingFees = isProcessingFeesValid({
      feeOption: form.getFieldValue("feeOption"),
      tax: form.getFieldValue("tax"),
      feeValue: form.getFieldValue("feeValue"),
      applyStateRate: form.getFieldValue("applyStateRate"),
      invoiceDescription: form.getFieldValue("invoiceDescription"),
      updatedStateRate,
    });
    const validEmployeeRange = isEmployeeRangeValid({
      updatedEmployeeRangeFactor,
      feeOption: form.getFieldValue("feeOption"),
      addEmployeeNumber: form.getFieldValue("addEmployeeNumber"),
      validRange: this.state.validRange,
    });
    if (!validProcessingFees || !validEmployeeRange) return;

    if ([1, 2].includes(Number(form.getFieldValue("feeOption")))) {
      let lastElement = mustBeArray(updatedEmployeeRangeFactor)[
        updatedEmployeeRangeFactor.length - 1
      ];
      if (lastElement && !lastElement.to) {
        updatedEmployeeRangeFactor[updatedEmployeeRangeFactor.length - 1].to =
          END_EMPLOYEE_NUMBER;
      }

      // validation to check at least one is selected
      if (
        !processingFeesHasAtLeastOneFieldSelected({
          earningLines: this.props.form.getFieldValue("earningLines"),

          payCategory: this.props.form.getFieldValue("payCategory"),

          expenseCategory: this.props.form.getFieldValue("expenseCategory"),
          feeOption: form.getFieldValue("feeOption"),

          basePayChecked: this.props.form.getFieldValue("basePayChecked"),
        })
      )
        return;
    }
    if (
      form.getFieldValue("applyStateRate") &&
      updatedStateRate.find((o) => isNaN(o.rate) || Number(o.rate) === 0)
    ) {
      return this.setState({ checkStateRateConfirmation: true });
    }

    this.submitProcessingFees();
  };

  submitProcessingFees = () => {
    this.cancelConfirmation();

    const { form } = this.props;

    let updatedStateRate = this.state.states.map((o) => {
      return { rate: Number(o.rate), stateName: o.value, stateId: o.id };
    });

    let updatedEmployeeRangeFactor = form.getFieldValue("addEmployeeNumber")
      ? this.state.employeeNumberRangeFactor.map((o) => {
          return { ...o, rate: Number(o.rate) };
        })
      : [];
    const lastElement = mustBeArray(updatedEmployeeRangeFactor)[
      updatedEmployeeRangeFactor.length - 1
    ];
    if (lastElement && !lastElement.to) {
      updatedEmployeeRangeFactor[updatedEmployeeRangeFactor.length - 1].to =
        END_EMPLOYEE_NUMBER;
    }

    let updatedAmendments = this.state.amendments.map((o) => {
      return {
        ...o,

        checked:
          mustBeArray(this.props.form.getFieldValue("payCategory")).includes(
            o.id
          ) ||
          mustBeArray(this.props.form.getFieldValue("payCategory")).includes(
            "all"
          ),
      };
    });

    let updatedExpenses = this.state.expenses.map((o) => {
      return {
        ...o,

        checked: mustBeArray(
          this.props.form.getFieldValue("expenseCategory")
        ).includes(o.id),
      };
    });

    let updatedEarninglines = this.state.earningLines.map((o) => {
      return {
        ...o,

        checked: mustBeArray(
          this.props.form.getFieldValue("earningLines")
        ).includes(o.id),
      };
    });
    let newOption = {
      name: this.state.feeOptions.find(
        (option) => option.id === form.getFieldValue("feeOption")
      )?.name,
      feeOptionId: form.getFieldValue("feeOption"),
      rateOrPercentage:
        [1, 2].includes(Number(form.getFieldValue("feeOption"))) &&
        form.getFieldValue("applyStateRate")
          ? 0
          : form.getFieldValue("feeValue"),
      invoiceDescription: form.getFieldValue("invoiceDescription"),
      taxFeeId: form.getFieldValue("tax"),
      taxFeeName: this.state.taxOptions.find(
        (option) => option.id === form.getFieldValue("tax")
      )?.value,
      isActive: true,
      feeOption: {
        basePayChecked: !!(
          [1].includes(Number(form.getFieldValue("feeOption"))) &&
          this.props.form.getFieldValue("basePayChecked")
        ),
        earningLines: [1].includes(Number(form.getFieldValue("feeOption")))
          ? updatedEarninglines
          : [],
        amendments: [],
        payCategory: [1].includes(Number(form.getFieldValue("feeOption")))
          ? updatedAmendments
          : [],
        expenseCategory: [1].includes(Number(form.getFieldValue("feeOption")))
          ? updatedExpenses
          : [],
      },

      employeeNumberRangeFactor:
        [1, 2].includes(Number(form.getFieldValue("feeOption"))) &&
        !isEmpty(this.state.employeeNumberRangeFactor)
          ? updatedEmployeeRangeFactor
          : [],
      stateRate:
        [1, 2].includes(Number(form.getFieldValue("feeOption"))) &&
        form.getFieldValue("applyStateRate")
          ? updatedStateRate
          : [],
    };

    this.setState(
      (prevState) => {
        prevState.selectedOptions = [
          ...mustBeArray(prevState.selectedOptions),
          newOption,
        ];
        form.resetFields([
          "feeOption",
          "invoiceDescription",
          "feeValue",
          "tax",
          "basePayChecked",
        ]);
        prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
        prevState.earningLines = [
          ...mustBeArray(prevState.defaultEarningLines),
        ];
        prevState.employeeNumberRangeFactor = [];
        prevState.states = prevState.states.map((o) => {
          return { ...o, rate: "" };
        });
        return prevState;
      },
      () => {
        let dueDate = form.getFieldValue("paymentDueDate");
        let dueTime = form.getFieldValue("paymentDueTime");
        form.resetFields(["paymentDueDate", "paymentDueTime"]);
        form.setFieldsValue({
          paymentDueTime: dueTime,
          paymentDueDate: dueDate,
        });
      }
    );
    form.resetFields([
      "feeOption",
      "invoiceDescription",
      "feeValue",
      "tax",
      "basePayChecked",
    ]);
  };
  cancelConfirmation = () => {
    this.setState({ checkStateRateConfirmation: false });
  };

  changedBusinessUnit = (id) => {
    this.setState({ businessUnitChanged: true, connectionSet: false });

    this.props.listTaxFees({ id, options: {} }).then((response) => {
      if (response.status && response.data) {
        this.setState({ taxOptions: response.data });
      } else {
        errorDisplay(response & response.data & response.data.validationErrors);
      }
    });

    this.fetchAllLookups(id);

    return this.props.getBusinessUnit({ id }).then((resp) => {
      if (resp.status && resp.data) {
        this.setState({
          payrollProviderId: resp.data.payrollServiceProviderId,
        });

        this.props
          .populateStates({ options: { countryId: resp.data.countryId } })
          .then((res) => {
            if (res.status && res.data) {
              this.setState((prevState) => {
                prevState.states = res.data.map((state, i) => {
                  return { ...state, rate: 0 };
                });
              });
            } else {
              errorDisplay(res & res.data & res.data.validationErrors);
            }
          });
      }
      return resp;
    });
  };

  fetchAllLookups = (id) => {
    this.props
      .earningLinesLookup({ options: {}, businessUnitID: id })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            earningLines: resp.data,
            defaultEarningLines: resp.data,
          });
        } else {
          errorDisplay(resp & resp.data & resp.data.validationErrors);
        }
      });
  };

  setBankFileDate = (val) => {
    this.setState({ bankFileDate: val });
  };
  addEmployeeRange = () => {
    if (isEmpty(this.state.employeeNumberRangeFactor)) {
      this.setState((prevState) => {
        prevState.employeeNumberRangeFactor = [{ from: 1, to: "", rate: "" }];
        prevState.validRange = [{ isValid: false }];
        return prevState;
      });
    } else {
      const previousRange =
        this.state.employeeNumberRangeFactor[
          this.state.employeeNumberRangeFactor.length - 1
        ];

      this.setState((prevState) => {
        prevState.employeeNumberRangeFactor =
          prevState.employeeNumberRangeFactor.concat([
            { from: Number(previousRange.to) + 1, to: "", rate: "" },
          ]);
        prevState.validRange = [
          ...mustBeArray(prevState.validRange),
          { isValid: false },
        ];
        return prevState;
      });
    }
  };
  deleteEmployeeRange = () => {
    this.setState((prevState) => {
      prevState.employeeNumberRangeFactor.pop();
      prevState.validRange.pop();
      return prevState;
    });
  };
  updateEmployeeNumber = (label, i, value) => {
    this.hideStateCheckConfirmation();
    const testPattern = /^[0-9.]+$/;

    this.setState((prevState) => {
      if (label === "rate") {
        prevState.employeeNumberRangeFactor[i][label] =
          !value || testPattern.test(Number(value))
            ? value
            : prevState.employeeNumberRangeFactor[i][label];
      } else {
        prevState.employeeNumberRangeFactor[i][label] = value;
      }
      if (
        label === "to" &&
        prevState.employeeNumberRangeFactor[i + 1] &&
        Number(value) >= prevState.employeeNumberRangeFactor[i + 1].from
      ) {
        prevState.employeeNumberRangeFactor[i + 1].from = Number(value) + 1;
      }
      if (
        label === "to" &&
        prevState.employeeNumberRangeFactor[i + 1] &&
        Number(value) <
          Number(prevState.employeeNumberRangeFactor[i + 1].from) - 1
      ) {
        prevState.employeeNumberRangeFactor[i + 1].from = Number(value) + 1;
      }
      return prevState;
    });
  };
  updateIsValid = (value, index) => {
    this.setState((prevState) => {
      prevState.validRange[index].isValid = value;
      return prevState;
    });
  };

  changeFeeOptions = (value) => {
    this.hideStateCheckConfirmation();
    if (Number(value) === 3) {
      this.setState({
        employeeNumberRangeFactor: [],
        expenses: [...mustBeArray(this.state.defaultExpenses)],

        earningLines: [...mustBeArray(this.state.defaultEarningLines)],
      });

      this.props.form.resetFields(["applyStateRate", "addEmployeeNumber"]);
    }
  };
  updateStatesRate = (value, index) => {
    this.hideStateCheckConfirmation();
    const testPattern = /^[0-9.]+$/;
    if (!value || testPattern.test(Number(value))) {
      this.setState((prevState) => {
        prevState.states[index].rate = value;
        return prevState;
      });
    }
  };
  handleChangeCheckbox = (value, label) => {
    this.hideStateCheckConfirmation();
    switch (label) {
      case "addEmployeeNumber":
        this.setState((prevState) => {
          if (!value) {
            prevState.employeeNumberRangeFactor = [
              { from: 1, to: "", rate: "" },
            ];
            prevState.validRange = [{ isValid: false }];
            return prevState;
          }
          prevState.employeeNumberRangeFactor = [];
          prevState.validRange = [];
          return prevState;
        });
        break;
      case "applyStateRate":
        this.setState((prevState) => {
          prevState.states = prevState.states.map((o) => {
            return { ...o, rate: 0 };
          });
          return prevState;
        });
        break;
      default:
        break;
    }
  };
  closeProcessingFeeModal = () => {
    this.setState({ feeDetails: {}, showProcessingFeeDetails: false });
  };

  showProcessingFeeDetails = (record) => {
    let feeDetails = {
      ...record,
      earningLines: record?.feeOption.earningLines,
      amendments: record?.feeOption.payCategory,
      feeOption: record.name,
      basePay: record?.feeOption.basePayChecked,
      expenses: record?.feeOption.expenseCategory,
    };

    this.setState({ feeDetails }, () => {
      this.setState({ showProcessingFeeDetails: true });
    });
  };
  cancelProcessingFees = () => {
    this.setState((prevState) => {
      this.props.form.setFieldsValue({
        feeOption: "",
        invoiceDescription: "",
        feeValue: "",
        tax: "",
      });
      prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
      prevState.earningLines = [...mustBeArray(prevState.defaultEarningLines)];
      prevState.states = prevState.states.map((o) => {
        return { ...o, rate: 0 };
      });
      return prevState;
    });
  };

  closeEmployeeList = () => {
    this.setState({ displayEmployeeList: false });

    this.props.form.resetFields();
  };

  disableExpenses = () => {
    let totalExpenseitem = mustBeArray(this.state.earningLines).find(
      (o) => o.name === "Total Employee Expense"
    );

    if (
      mustBeArray(this.props.form.getFieldValue("earningLines")).includes(
        totalExpenseitem?.id
      )
    ) {
      this.props.form.resetFields(["expenseCategory"]);
      return true;
    }
    return false;
  };

  clearBasePay = () => {
    this.props.form.resetFields(["baseSalary"]);
  };

  getRatePercentage = (data, record) => {
    if (Number(record && record.feeOptionId) === 3) {
      return currencyFormater(
        data,

        this.props.businessunitDetails && this.props.businessunitDetails.country
      );
    } else {
      switch (Number(record.feeOptionId)) {
        case 1:
          return !isEmpty(record && record["stateRate"])
            ? "State/s"
            : `${data}%`;
        case 2:
          return !isEmpty(record && record["stateRate"])
            ? "State/s"
            : currencyFormater(
                data,
                this.props.businessunitDetails &&
                  this.props.businessunitDetails.country
              );
        default:
          return "";
      }
    }
  };

  checkSubmitDisabled = () => {
    // Disable if user is not in the third screen
    // Processing fees are required for PEO Model
    return (
      Number(this.state.formIndex) !== 2 ||
      (isEmpty(mustBeArray(this.state.selectedOptions)) &&
        Number(this.props.form.getFieldValue("PayrollModel")) === PEO_MODEL_ID)
    );
  };

  hideStateCheckConfirmation = () => {
    if (this.state.checkStateRateConfirmation) {
      this.setState({ checkStateRateConfirmation: false });
    }
  };

  isSecondStepDisabled = () => {
    if (
      (!this.state.connectionSet ||
        isEmpty(this.props.form.getFieldValue("baseSalary")) ||
        typeof this.props.form.getFieldValue("baseSalary") === "undefined") &&
      Number(this.props.form.getFieldValue("method")) === 1
    )
      return true;
    return false;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      employeeNumberRangeFactor,
      states,
      amendments,
      earningLines,
      formIndex,
      confirmLoading,
      actionOneLink,
      newPayrunId,
      formLabels,
      loading,
    } = this.state;
    const colTemplate = [
      {
        title: "Invoice Description",
        dataIndex: "invoiceDescription",
        filterType: 0,
        key: "5",
      },
      { title: "Fee Option", dataIndex: "name", filterType: 0, key: "1" },
      {
        title: "Rate/Percentage",
        dataIndex: "rateOrPercentage",
        filterType: 0,
        key: "2",
        render: (rowKey, data) => this.getRatePercentage(rowKey, data),
      },
      {
        title: "Tax",
        dataIndex: "taxFeeName",
        filterType: 0,
        key: "3",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "4",
        render: (rowKey, i, j) => (
          <React.Fragment>
            <Tooltip title="Delete" placement="bottom">
              <Avatar size="small" onClick={() => this.removeRow(rowKey, j)}>
                <CloseOutlined className="hand color-neutral" />{" "}
              </Avatar>
            </Tooltip>
            {rowKey && Number(rowKey.feeOptionId) !== 3 ? (
              <Tooltip title="View" placement="bottom">
                <Avatar
                  size="small"
                  className={`hand bg-primary ml-3`}
                  icon={<EyeOutlined />}
                  onClick={() => this.showProcessingFeeDetails(rowKey)}
                />
              </Tooltip>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        ),
      },
    ];

    const stateTaxLayout = {
      labelCol: { span: 1 },
      wrapperCol: { span: 18 },
    };

    const countryCode =
      this.props.businessunitDetails &&
      CountryCoordinates.getCode(this.props.businessunitDetails.country);

    const secondStepDisabled = this.isSecondStepDisabled();
    return (
      <If
        condition={this.state.pageLoading}
        then={<Spin />}
        else={
          <Form
            className="mb-4"
            layout="vertical"
            onSubmit={this.handleSubmit}
            autoComplete="off"
          >
            <Layout className="h-[100vh]">
              <If
                condition={newPayrunId}
                then={
                  <NoRecord>
                    <CreateSuccess
                      page="Pay Run"
                      actionOneLabel="View Pay Run"
                      actionOneLink={actionOneLink}
                    />
                  </NoRecord>
                }
                else={
                  <React.Fragment>
                    <Header className="bg-white">
                      <FormNavbar
                        title="Pay run"
                        handleCancel={this.onCancel}
                        handleSave={this.handleSubmit}
                        handleSaveAsDraft={this.handleSaveAsDraft}
                        htmlType="submit"
                        loading={loading}
                        disabled={this.checkSubmitDisabled()}
                        enableBack={true}
                      />
                    </Header>
                    <Layout className="p-6 rounded-[5px] overflow-y-auto">
                      <Content>
                        <Row gutter={DEFAULT_GUTTER}>
                          <FormStepsCol
                            data={formLabels}
                            activeIndex={Number(formIndex) + 1}
                          />
                          <Col md={18} sm={24}>
                            <div
                              className={getConditionalResponse({
                                condition: Number(formIndex) === 0,
                                resp1: "block",
                                resp2: "display-none",
                              })}
                            >
                              <PayrunForm
                                {...this.props}
                                signal={this.props.signal}
                                changedBusinessUnit={this.changedBusinessUnit}
                                setBankFileDate={this.setBankFileDate}
                                processingFees={this.state.selectedOptions}
                                payscheduleDates={this.state.payscheduleDates}
                                adhoc={true}
                                editMode={this.state.editMode}
                              />
                              <div>
                                <Button
                                  htmlType="submit"
                                  className="bg-success mb-4 float-right rounded-[20px]"
                                >
                                  Continue
                                </Button>
                                <div className="clearfix"></div>
                              </div>
                            </div>
                            <div
                              className={getConditionalResponse({
                                condition: Number(formIndex) === 1,
                                resp1: "block",
                                resp2: "display-none",
                              })}
                            >
                              <Card className="mb-5" title="Payroll Processing">
                                <React.Fragment>
                                  <Row gutter={DEFAULT_GUTTER}>
                                    <Col span={19}>
                                      <FormItem
                                        {...{
                                          labelCol: { span: 7 },
                                          wrapperCol: { span: 17 },
                                        }}
                                        label="Available Options:"
                                      >
                                        {getFieldDecorator("options", {
                                          initialValue: "1",
                                          rules: [
                                            {
                                              required: Number(formIndex) === 1,
                                              message:
                                                "You need to select fee option",
                                            },
                                          ],
                                        })(
                                          <Select
                                            disabled={this.state.connectionSet}
                                            className="w-full "
                                          >
                                            <Option value="1">Keypay</Option>
                                          </Select>
                                        )}
                                      </FormItem>
                                    </Col>
                                    <Col span={5}>
                                      <Button
                                        loading={this.state.buttonProps.loading}
                                        type={this.state.buttonProps.type}
                                        icon={<ApiOutlined />}
                                        onClick={this.handleConnectionChange}
                                        className="rounded-[20px]"
                                      >
                                        Connect
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row gutter={DEFAULT_GUTTER}>
                                    <Col span={19}>
                                      <BaseSalary
                                        baseSalary={this.state.baseSalary}
                                        getFieldDecorator={
                                          this.props.form.getFieldDecorator
                                        }
                                        form={this.props.form}
                                        formLayout={{
                                          labelCol: { span: 7 },
                                          wrapperCol: { span: 17 },
                                        }}
                                        loading={this.state.baseSalaryLoading}
                                        required={Number(formIndex) === 1}
                                        handleFormValueChange={() => {
                                          console.log("");
                                        }}
                                      />
                                    </Col>
                                    <Col span={5}>
                                      <Button
                                        type="primary"
                                        onClick={this.clearBasePay}
                                        className="min-w-[119px] rounded-[20px]"
                                      >
                                        Clear
                                      </Button>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                                <PayScheduleConnect
                                  title="Payroll System Connection"
                                  wrappedComponentRef={this.saveFormRef}
                                  visible={this.state.visible}
                                  connectPayProcessor={
                                    this.props.connectPayProcessor
                                  }
                                  handleCancel={this.handleCancel}
                                  payrollProviderId={
                                    this.state.payrollProviderId
                                  }
                                  fetchPayrollConnectionName={
                                    this.props.fetchPayrollConnectionName
                                  }
                                  configurePayscheduleConnection={
                                    this.props.configurePayscheduleConnection
                                  }
                                  fetchExternalBusinessunit={
                                    this.props.fetchExternalBusinessunit
                                  }
                                  fetchExternalPayschedules={
                                    this.props.fetchExternalPayschedules
                                  }
                                  businessunitDetails={
                                    this.props.businessunitDetails
                                  }
                                  createConnection={this.createConnection}
                                  confirmLoading={confirmLoading}
                                  businessUnitChanged={
                                    this.state.businessUnitChanged
                                  }
                                />
                              </Card>
                              <div>
                                <Button
                                  onClick={this.handleBack}
                                  className="rounded-[20px]"
                                >
                                  Back
                                </Button>
                                <Button
                                  htmlType="submit"
                                  className={`rounded-[20px] ${getConditionalResponse(
                                    {
                                      condition: secondStepDisabled,
                                      resp1: "mb-4",
                                      resp2: "bg-success mb-4",
                                    }
                                  )} float-right`}
                                  disabled={secondStepDisabled}
                                >
                                  Continue
                                </Button>
                                <div className="clearfix"></div>
                              </div>
                            </div>
                            <div
                              className={getConditionalResponse({
                                condition: Number(formIndex) === 2,
                                resp1: "block",
                                resp2: "display-none",
                              })}
                            >
                              <Card title="Payroll Processing Fees ">
                                <Row>
                                  <Col span={24}>
                                    <Row
                                      gutter={DEFAULT_GUTTER}
                                      className="mb-3"
                                    >
                                      <Col span={5}>
                                        <h4>Business Model:</h4>
                                      </Col>
                                      <Col span={12}>
                                        <h4 className="pl-4.5">
                                          {getBusinessModelName({
                                            value:
                                              this.props.form &&
                                              Number(
                                                this.props.form.getFieldValue(
                                                  "PayrollModel"
                                                )
                                              ),
                                          })}
                                        </h4>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      {...{
                                        labelCol: { span: 6 },
                                        wrapperCol: { span: 12 },
                                      }}
                                      label={
                                        <span>
                                          <span className="color-danger mr-1">
                                            *
                                          </span>
                                          Fee Option:
                                        </span>
                                      }
                                    >
                                      {getFieldDecorator("feeOption", {
                                        rules: [
                                          {
                                            required: false,
                                            message:
                                              "You need to select fee option",
                                          },
                                        ],
                                      })(
                                        <Select
                                          placeholder="Fee options"
                                          onChange={(value) =>
                                            this.changeFeeOptions(value)
                                          }
                                        >
                                          {mustBeArray(
                                            this.state.feeOptions
                                          ).map((fee) => (
                                            <Option key={fee.id} value={fee.id}>
                                              {fee.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      )}
                                    </FormItem>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      {...{
                                        labelCol: { span: 6 },
                                        wrapperCol: { span: 12 },
                                      }}
                                      label={
                                        <span>
                                          <span className="color-danger mr-1">
                                            *
                                          </span>
                                          Invoice Description:
                                        </span>
                                      }
                                    >
                                      {getFieldDecorator("invoiceDescription", {
                                        rules: [
                                          {
                                            required: false,
                                            message:
                                              "You need to enter invoice description",
                                          },
                                        ],
                                      })(
                                        <Input
                                          placeholder="Invoice description"
                                          onChange={
                                            this.hideStateCheckConfirmation
                                          }
                                        />
                                      )}
                                    </FormItem>
                                  </Col>
                                </Row>
                                {[1].includes(
                                  Number(
                                    this.props.form.getFieldValue("feeOption")
                                  )
                                ) && (
                                  <React.Fragment>
                                    <Row>
                                      <Col span={12} offset={5}>
                                        <FormItem
                                          {...stateTaxLayout}
                                          className="pb-0"
                                          label=" "
                                        >
                                          {getFieldDecorator("basePayChecked", {
                                            rules: [
                                              {
                                                required: false,
                                              },
                                            ],
                                            valuePropName: "checked",
                                          })(<Checkbox>Base Pay </Checkbox>)}
                                        </FormItem>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={22}>
                                        <ApplicableFeesOptions
                                          form={this.props.form}
                                          getFieldDecorator={
                                            this.props.form.getFieldDecorator
                                          }
                                          applicableFeesOptions={earningLines}
                                          decorator="earningLines"
                                          label="Earningline(s)"
                                          defaultLabelCol={6}
                                          defaultWrapperCol={18}
                                        />
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col span={22}>
                                        <PayCategory
                                          payCategory={amendments}
                                          getFieldDecorator={
                                            this.props.form.getFieldDecorator
                                          }
                                          form={this.props.form}
                                          formLayout={{
                                            labelCol: { span: 6 },
                                            wrapperCol: { span: 12 },
                                          }}
                                          editMode={this.state.editMode}
                                          selectedBaseSalary={this.props.form.getFieldValue(
                                            "baseSalary"
                                          )}
                                          defaultLabelCol={3}
                                          defaultWrapperCol={18}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={22}>
                                        <BaseSalary
                                          baseSalary={this.state.expenses}
                                          getFieldDecorator={
                                            this.props.form.getFieldDecorator
                                          }
                                          form={this.props.form}
                                          formLayout={{
                                            labelCol: { span: 6 },
                                            wrapperCol: { span: 12 },
                                          }}
                                          loading={this.state.expensesLoading}
                                          required={false}
                                          label="Expense(s)"
                                          name="expenseCategory"
                                          disabled={this.disableExpenses()}
                                          handleFormValueChange={
                                            this.hideStateCheckConfirmation
                                          }
                                          defaultLabelCol={3}
                                          defaultWrapperCol={18}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      {...{
                                        labelCol: { span: 6 },
                                        wrapperCol: { span: 12 },
                                      }}
                                      className="pb-0"
                                      label={`${getRatePercentageText({
                                        feeOption:
                                          this.props.form.getFieldValue(
                                            "feeOption"
                                          ),
                                      })}:`}
                                    >
                                      {getFieldDecorator("feeValue", {
                                        rules: [
                                          {
                                            required: false,
                                            message:
                                              "You need to enter fee value",
                                          },
                                          {
                                            pattern: /^[0-9.]+$/,
                                            message:
                                              "Please enter only positive numbers and decimals",
                                          },
                                        ],
                                      })(
                                        <InputNumber
                                          placeholder="Fee value"
                                          className="w-full  hideNumberPointers"
                                          onChange={
                                            this.hideStateCheckConfirmation
                                          }
                                        />
                                      )}
                                    </FormItem>
                                  </Col>
                                </Row>
                                {[1, 2].includes(
                                  Number(
                                    this.props.form.getFieldValue("feeOption")
                                  )
                                ) && (
                                  <React.Fragment>
                                    <Row>
                                      <Col span={12} offset={5}>
                                        <FormItem
                                          {...stateTaxLayout}
                                          className="pb-0"
                                          label=" "
                                        >
                                          {getFieldDecorator("applyStateRate", {
                                            rules: [
                                              {
                                                required: false,
                                              },
                                            ],
                                            valuePropName: "checked",
                                          })(
                                            <Checkbox
                                              className="ml-[3px]"
                                              onChange={(e) =>
                                                this.handleChangeCheckbox(
                                                  e.target.value,
                                                  "applyStateRate"
                                                )
                                              }
                                            >
                                              Apply State Rate
                                            </Checkbox>
                                          )}
                                        </FormItem>
                                      </Col>
                                    </Row>
                                    {getConditionalResponse({
                                      condition:
                                        this.props.form.getFieldValue(
                                          "applyStateRate"
                                        ),
                                      resp1: (
                                        <Row>
                                          <Col span={22}>
                                            <StateRate
                                              form={this.props.form}
                                              getFieldDecorator={
                                                this.props.form
                                                  .getFieldDecorator
                                              }
                                              updateStatesRate={
                                                this.updateStatesRate
                                              }
                                              states={states}
                                              decorator="stateFeeOptions"
                                              label="State Rate"
                                            />
                                          </Col>
                                        </Row>
                                      ),
                                      resp2: <React.Fragment />,
                                    })}
                                    <Row>
                                      <Col span={12} offset={5}>
                                        <FormItem
                                          {...stateTaxLayout}
                                          className="pb-0"
                                          label=" "
                                        >
                                          {getFieldDecorator(
                                            "addEmployeeNumber",
                                            {
                                              rules: [
                                                {
                                                  required: false,
                                                },
                                              ],
                                              valuePropName: "checked",
                                            }
                                          )(
                                            <Checkbox
                                              className="ml-[3px]"
                                              onChange={(e) =>
                                                this.handleChangeCheckbox(
                                                  e.target.value,
                                                  "addEmployeeNumber"
                                                )
                                              }
                                            >
                                              Employee Number Applicable
                                            </Checkbox>
                                          )}
                                        </FormItem>
                                      </Col>
                                    </Row>
                                    {getConditionalResponse({
                                      condition:
                                        this.props.form.getFieldValue(
                                          "addEmployeeNumber"
                                        ),
                                      resp1: (
                                        <Row>
                                          <Col span={22}>
                                            <EmployeeRate
                                              form={this.props.form}
                                              getFieldDecorator={
                                                this.props.form
                                                  .getFieldDecorator
                                              }
                                              employeeNumberRangeFactor={
                                                employeeNumberRangeFactor
                                              }
                                              addEmployeeRange={
                                                this.addEmployeeRange
                                              }
                                              deleteEmployeeRange={
                                                this.deleteEmployeeRange
                                              }
                                              updateEmployeeNumber={
                                                this.updateEmployeeNumber
                                              }
                                              updateIsValid={this.updateIsValid}
                                              decorator="employeeFeeOptions"
                                              label="Employee Number Applicable"
                                              defaultLabelCol={3}
                                              defaultWrapperCol={18}
                                            />
                                          </Col>
                                        </Row>
                                      ),
                                      resp2: <React.Fragment />,
                                    })}
                                  </React.Fragment>
                                )}
                                <Row>
                                  <Col span={22}>
                                    <FormItem
                                      className="pb-0"
                                      {...{
                                        labelCol: { span: 6 },
                                        wrapperCol: { span: 12 },
                                      }}
                                      label={
                                        <span>
                                          <span className="color-danger mr-1">
                                            *
                                          </span>
                                          Tax:
                                        </span>
                                      }
                                    >
                                      {getFieldDecorator("tax", {
                                        rules: [
                                          {
                                            required: false,
                                            message: "You need to select taxes",
                                          },
                                        ],
                                      })(
                                        <Select placeholder="select tax">
                                          {mustBeArray(
                                            this.state.taxOptions
                                          ).map((tax) => (
                                            <Option key={tax.id} value={tax.id}>
                                              {tax.value}
                                            </Option>
                                          ))}
                                        </Select>
                                      )}
                                      <div>
                                        <Popconfirm
                                          title="Some states are assigned 0 rate! Are you sure you want to continue?"
                                          visible={
                                            this.state
                                              .checkStateRateConfirmation
                                          }
                                          onConfirm={this.submitProcessingFees}
                                          onCancel={this.cancelConfirmation}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <Button
                                            icon={<PlusCircleOutlined />}
                                            theme="filled"
                                            onClick={() =>
                                              this.addProcessingFees()
                                            }
                                            type="primary"
                                            className="mt-4 rounded-[20px]"
                                          >
                                            Add
                                          </Button>
                                        </Popconfirm>
                                        <Button
                                          theme="filled"
                                          onClick={() =>
                                            this.cancelProcessingFees()
                                          }
                                          className="mt-4 text-[15px] ml-4 rounded-[20px]"
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                    </FormItem>
                                  </Col>
                                </Row>
                              </Card>
                              <div className="mt-4 mb-4">
                                <Card className="card-space-0 rounded-[12px]">
                                  <Table
                                    columns={colTemplate}
                                    size={"default"}
                                    rowKey={() => guid()}
                                    dataSource={this.state.selectedOptions}
                                    pagination={{ hideOnSinglePage: true }}
                                  />
                                </Card>
                                <ProcessingFeesView
                                  visible={this.state.showProcessingFeeDetails}
                                  onCancel={this.closeProcessingFeeModal}
                                  feeDetails={this.state.feeDetails}
                                  businessunitDetails={
                                    this.props.businessunitDetails
                                  }
                                  countryCode={countryCode}
                                  basePayMapping={this.state.basePayMapping}
                                />
                              </div>
                              <ArchievedEmployees
                                visible={this.state.displayEmployeeList}
                                addedEmployees={this.state.addedEmployees}
                                archievedEmployees={
                                  this.state.archievedEmployees
                                }
                                handleCancel={this.closeEmployeeList}
                              />
                              <div>
                                <Button
                                  onClick={() => this.handleBack()}
                                  className="mr-[15px] mb-4 rounded-[20px]"
                                >
                                  Back
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Content>
                    </Layout>
                  </React.Fragment>
                }
              />
            </Layout>
          </Form>
        }
      />
    );
  }
}

export default withRouter(Form.create()(AdhocPayrun));
