import { DragEventHandler } from 'react';

export const DropzoneVariants = {
  Default: 'default',
  Hover: 'hover',
  Error: 'error',
  Disabled: 'disabled',
};
export type DropzoneVariants = (typeof DropzoneVariants)[keyof typeof DropzoneVariants];

export type DragEventHandlers = {
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDrop: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  onDragEnd: DragEventHandler<HTMLDivElement>;
  onDragOver: DragEventHandler<HTMLDivElement>;
};

export interface DropzoneProps {
  actionMessage?: React.ReactNode;
  actionMessageHelperText?: string;
  optionalHelperText?: string;
  variant?: DropzoneVariants;
  testId?: string;
  dragEventHandlers: DragEventHandlers;
}
