import styled, { css } from 'styled-components';

import { BodyXSmallRegular, Colour } from '@xemplo/style-constants';

/** DESCRIPTION / ERROR  */
const messageStyles = css`
  ${BodyXSmallRegular};
  padding: 8px 16px 0 16px;
  word-break: break-all;
`;

/**
 * Base styles for an error message element.
 */
export const errorMessageBase = css`
  ${messageStyles};
  color: ${Colour.Red[600]};
`;

/**
 * Base styles for a description element.
 */
export const descriptionBase = css`
  color: ${Colour.Black[60]};
  ${messageStyles};
`;

/** A div element that contains the error styling */
export const ErrorMessage = styled.div`
  ${errorMessageBase};
`;

export const Description = styled.div`
  ${descriptionBase};
`;
