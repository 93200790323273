import { SVGProps } from 'react';
const DawnArrowLeft16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.233 3.174a.75.75 0 0 0-1.06-1.061L3.795 7.49a.753.753 0 0 0-.173.269.75.75 0 0 0 .173.791l5.378 5.378a.75.75 0 1 0 1.06-1.06L5.386 8.02l4.847-4.847Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLeft16;
