import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { requireAuth } from '@xemplo/auth-provider';
import { ErrorBoundary } from '@xemplo/error-boundary';
import { PayrollRoutes } from '@xemplo/gp-routes';
import { withLazyComponent } from '@xemplo/lazy-component';

const UsersScreen = lazy(() =>
  import('@xemplo/users-screen').then((m) => ({ default: m.UsersScreen }))
);

export const UsersScreenRoute: RouteObject[] = [
  {
    path: PayrollRoutes.UsersScreen,
    Component: requireAuth(withLazyComponent(UsersScreen)),
    errorElement: <ErrorBoundary />,
  },
];
