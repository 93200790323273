// @ts-nocheck
import APIHandler from "../../constants/apiUrl";
import { requestBuilder } from "../../libs/utilities";
import { http, ResponseHandler } from "../../libs";

export function dashboardActionHandler({
  id,
  options = {},
  action,
  payload,
  cancelToken,
}: any) {
  return (dispatch: any) =>
    new Promise(async (resolve, reject) => {
      let response;
      let url;
      let ACTION_TYPE;
      switch (action && action.toLowerCase()) {
        case "get":
          url = APIHandler.constructEndpoint({ endpoint: "DASHBOARD_URL" });
          response = await requestBuilder(id, url, options, cancelToken);
          break;
        case "create":
          url = APIHandler.constructEndpoint({ endpoint: "DASHBOARD_URL" });
          response = await http.post(url, payload, cancelToken);
          ACTION_TYPE = "UPDATE_DASHBOARD_LIST";
          break;
        case "update":
          url = APIHandler.constructEndpoint({
            endpoint: "DASHBOARD_DETAIL_URL",
            options: { id },
          });
          response = await http.put(url, payload, cancelToken);
          ACTION_TYPE = "UPDATE_DASHBOARD_LIST";
          break;
        case "delete":
          url = APIHandler.constructEndpoint({
            endpoint: "DASHBOARD_DETAIL_URL",
            options: { id },
          });
          response = await http.del(url);
          ACTION_TYPE = "UPDATE_DASHBOARD_LIST";
          break;
        default:
          break;
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response.data, ACTION_TYPE))
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "INVALID_REQUEST",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
