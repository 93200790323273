import { cloneElement, useRef } from 'react';

import { DawnMenuMore24 } from '@xemplo/icons';
import { Popover, usePopoverState } from '@xemplo/popover';

import * as S from './action-sheet.styles';
import {
  ActionSheetButton,
  ActionSheetItemTypes,
  ActionSheetItemVariant,
  ActionSheetLink,
  ActionSheetListItem,
  ActionSheetListItemTypes,
  ActionSheetPlacement,
  ActionSheetPopoverProps,
  ActionSheetProps,
} from './action-sheet.types';

export const ActionSheetTestId = {
  trigger: 'action-sheet-trigger',
  dividerListItem: 'action-sheet-divider',
};

const TriggerComponent = (props: ActionSheetPopoverProps) => {
  const ref = useRef(null);
  const { children, placement, testId, customButton } = props;
  const { state, overlayProps, triggerProps } = usePopoverState({ ref, type: 'menu' });

  return (
    <>
      {customButton ? (
        cloneElement(customButton, {
          ...triggerProps,
          ref,
          onClick: state.toggle,
          active: state.isOpen,
          id: 'action-sheet-trigger',
          ariaLabel: 'Reveal actions',
        })
      ) : (
        <S.TriggerButton
          {...triggerProps}
          ref={ref}
          onClick={(e) => {
            state.toggle();
            e.stopPropagation();
          }}
          ariaLabel="Reveal actions"
          naked
          id="action-sheet-trigger"
          active={state.isOpen}
        >
          <DawnMenuMore24 />
        </S.TriggerButton>
      )}
      {state.isOpen && (
        <Popover
          isNonModal={false}
          state={state}
          triggerRef={ref}
          testId={testId}
          shouldUpdatePosition
          placement={`bottom ${placement}`}
          offset={4}
        >
          {cloneElement(children, overlayProps)}
        </Popover>
      )}
    </>
  );
};

const renderListItem = (item: ActionSheetListItem) => {
  switch (item.type) {
    case ActionSheetItemTypes.Link: {
      const { to, isExternal, label } = item as ActionSheetLink;
      return (
        <S.LinkItem
          to={to}
          onClick={(e) => e.stopPropagation()}
          target={isExternal ? '_blank' : undefined}
          rel="noreferrer"
        >
          {label}
        </S.LinkItem>
      );
    }

    case ActionSheetItemTypes.Button: {
      const { label, onClick } = item as ActionSheetButton;
      return (
        <S.ButtonItem
          onClick={(e) => {
            onClick(e);
            e.stopPropagation();
          }}
        >
          {label}
        </S.ButtonItem>
      );
    }
    default:
      return null;
  }
};

export const ActionSheet = (props: ActionSheetProps) => {
  const { items, placement = ActionSheetPlacement.Right, maxWidth, customButton } = props;

  return (
    <TriggerComponent
      testId={ActionSheetTestId.trigger}
      placement={placement}
      customButton={customButton}
    >
      <S.PopoverContent $maxWidth={maxWidth}>
        <S.List>
          {items.map((item: ActionSheetListItemTypes | undefined) => {
            if (item === undefined) return null;

            if (item.type === ActionSheetItemTypes.Divider) {
              return (
                <S.ListDivider
                  key={item.id}
                  data-testid={ActionSheetTestId.dividerListItem}
                />
              );
            }
            return (
              <S.ListItem
                key={item.id}
                $variant={item.variant ?? ActionSheetItemVariant.Default}
              >
                {renderListItem(item)}
              </S.ListItem>
            );
          })}
        </S.List>
      </S.PopoverContent>
    </TriggerComponent>
  );
};

export default ActionSheet;
