import { Radio } from "antd";
import * as React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";

interface ToggleOptionRadioButtonProps {
  onChange: (e: any) => void;
  value: string | number | undefined;
  positiveText: string;
  positiveId: Number;
  negativeText: string;
  negativeId: Number;
  claims?: any;
  name?: string;
  ignoreClaims?: boolean;
  hasComponents?: boolean;
}

const ToggleOptionRadioButton = (props: ToggleOptionRadioButtonProps) => {
  const {
    value,
    onChange,
    positiveId,
    positiveText,
    negativeId,
    negativeText,
    claims,
    name,
    hasComponents,
    ignoreClaims,
  } = props;
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents,
  });
  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange(e.target.value)}
      name="notifyUser"
      className={`${
        value === positiveId
          ? "customRadioButtonYesSelected"
          : "customRadioButtonNoSelected"
      } ${ignoreClaims ? "" : claimAndStatus.status}`}
    >
      <Radio.Button className="yesOption" value={positiveId}>
        {positiveText}
      </Radio.Button>
      <Radio.Button className="noOption" value={negativeId}>
        {negativeText}
      </Radio.Button>
    </Radio.Group>
  );
};

export default ToggleOptionRadioButton;
