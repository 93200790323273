//@ts-nocheck
import { InputNumber } from "antd";
import React from "react";
import {
  manageDecimalPlaces,
  validateInputValueIncludesOnlyNumbers,
} from "../libs";
import numeral from "numeral";

const InputTypeNumberDefault = (props: any) => {
  const {
    handleChange,
    question,
    className = "",
    placeholder,
    disabled = false,
    min,
    max,
    isPositive = false,
    fieldType,
  } = props;

  const handleBlur = ({ value }: any) => {
    const minValue =
      question?.threshold?.minNumber || question?.threshold?.minAmount;
    if (minValue && value && numeral(value)["_value"] < minValue) {
      return handleChange(minValue);
    }
    const maxValue =
      question?.threshold?.maxNumber || question?.threshold?.maxAmount;
    if (value && maxValue && numeral(value)["_value"] > maxValue) {
      return handleChange(maxValue);
    }
    return handleChange(manageDecimalPlaces({ value }));
  };

  const onChange = ({ value }: any) => {
    // limit to 2 decimal places
    const updatedValue = value
      ? `${value}`?.replace(/(\..*?)\..*/g, "$1").replace(/(\.\d{2}).+/g, "$1")
      : value;
    return handleChange(updatedValue);
  };

  // Update props with parser and formatter for currency
  const extraProps =
    fieldType === "hours"
      ? {
          formatter: (value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
        }
      : {};
  // check if min and max are numbers and add them to extraProps
  if (!Number.isNaN(min)) {
    extraProps.min = min;
  }
  if (!Number.isNaN(max)) {
    extraProps.max = max;
  }
  return (
    <InputNumber
      {...extraProps}
      disabled={disabled}
      onChange={(value) => onChange({ value })}
      className={`min-w-[50%] w-3/6 hideNumberPointers ${className}`}
      onKeyDown={(e) =>
        validateInputValueIncludesOnlyNumbers({
          e,
          maxAmount:
            question?.threshold?.maxNumber || question?.threshold?.maxAmount,
          hasDecimal: true,
          isPositive,
          minAmount:
            question?.threshold?.minNumber || question?.threshold?.minAmount,
        })
      }
      onBlur={() => handleBlur({ value: question?.value })}
      placeholder={placeholder ?? ""}
      value={
        question?.value || question.value === 0 ? Number(question.value) : ""
      }
    />
  );
};
export default InputTypeNumberDefault;
