import { useMemo, useRef } from 'react';
import { useCalendarCell } from '@react-aria/calendar';
import classNames from 'classnames';

import * as S from './calendar-cell.styles';
import { CalendarCellProps } from './calendar-cell.types';

export const CalendarCell = ({ state, ...props }: CalendarCellProps) => {
  const ref = useRef<HTMLTableCellElement | null>(null);
  const { date } = props;
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  const isToday = useMemo(() => {
    const todaysDate = new Date();
    return (
      date.day === todaysDate.getDate() &&
      date.month === todaysDate.getMonth() + 1 &&
      date.year === todaysDate.getFullYear()
    );
  }, [date]);

  return (
    <S.Cell
      {...cellProps}
      className={classNames('cell', {
        selected: isSelected,
        disabled: isUnavailable || isDisabled || isOutsideVisibleRange,
        today: isToday,
        focused:
          state.focusedDate?.day === date.day && state.focusedDate?.month === date.month,
      })}
    >
      <S.CellInner {...buttonProps} ref={ref}>
        {formattedDate}
      </S.CellInner>
    </S.Cell>
  );
};
