import { useEffect } from "react";
import { useAppDispatch } from "../../../../hooks";
import {
  getPersonalizationData,
  isUndefined,
  mustBeArray,
} from "../../../../libs";
import { getGeneralAmendmentList } from "../../../actions/generalAmendment";
import DefaultListContainer from "../../common/ListContainerComponents/DefaultListContainer";
import axios from "axios";
import {
  useSliceActions,
  useSliceSelector,
} from "../../../../Common/SliceListProvider";
import CompletedGeneralAmendmentTable from "../CompletedGeneralAmendmentTable";
import {
  GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER,
  GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT,
} from "../../../../constants";

type Props = {
  dashboardClaims: any;
  pageClaims?: any;
  targetEndpoint: string;
  componentTitle: string;
  filterApplied?: string;
};

const gridContainerName = "generalAmendmentCompletedGeneralGrid";

const CompletedGeneralAmendments = ({
  dashboardClaims, //Stored Claims when component was pinned to the dashboard
  pageClaims, //DOM components status
  targetEndpoint, //Fixed endpoint for pinned components
  componentTitle,
  filterApplied,
}: Props) => {
  const dispatch: any = useAppDispatch();
  const signal = axios.CancelToken.source();
  //Get list state
  const completedAmendmentState = useSliceSelector();
  // Get list actions
  const { initialDashPersonalization, dataLoaded, gridView, setLoading } =
    useSliceActions();

  useEffect(() => {
    if (
      isUndefined(completedAmendmentState) ||
      completedAmendmentState?.claimsUpdated
    ) {
      return;
    }
    // update default view (list or grid)
    const gridView = getPersonalizationData({
      type: "listContainer",
      personalizationData: dashboardClaims,
    })?.gridView;
    initialDashPersonalization({ value: gridView });
  }, [completedAmendmentState?.claimsUpdated]);

  useEffect(() => {
    if (completedAmendmentState?.claimsUpdated) {
      getData();
    }
  }, [completedAmendmentState?.claimsUpdated]);

  const getData = () => {
    setLoading({ loading: true });
    dispatch(
      getGeneralAmendmentList({
        options: { page: 1, per_page: 5 },
        cancelToken: signal.token,
        targetEndpoint: targetEndpoint,
      })
    ).then((resp: any) => {
      setLoading({ loading: false });
      dataLoaded({ data: mustBeArray(resp?.data), total: resp?.total });
    });
  };

  //List Container
  const handleGridViewChange = (view: boolean) => {
    if (completedAmendmentState?.container?.gridView === view) return;
    gridView({ view: view });
  };

  return (
    <>
      <DefaultListContainer
        dashboardView
        pageClaims={pageClaims}
        name={GENERAL_AMENDMENT_COMPLETED_LIST_CONTAINER}
        handleGridChange={handleGridViewChange}
        grid={completedAmendmentState?.grid}
        table={completedAmendmentState?.table}
        listLoading={completedAmendmentState?.loading}
        tableComponentName={GENERAL_AMENDMENT_COMPLETED_TABLE_COMPONENT}
        gridComponentName={gridContainerName}
        total={completedAmendmentState?.total}
        componentTitle={componentTitle}
        filterApplied={filterApplied}
        GeneralTablePaginatedComponent={CompletedGeneralAmendmentTable}
        targetEndpoint={targetEndpoint}
        rowIdParam="amendmentId"
        data={completedAmendmentState?.data}
        hasNoDuplicateEntity
      />
    </>
  );
};

export default CompletedGeneralAmendments;
