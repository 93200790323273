import styled from 'styled-components';

import { BodyStandardMedium, BodyXSmallRegular, Colour } from '@xemplo/style-constants';

export const ErrorMessage = styled.div`
  display: flex;
  padding: 8px;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${Colour.Red[300]};
  background: ${Colour.Red[50]};
  color: ${Colour.Gray[600]};
  justify-content: flex-start;
  ${BodyXSmallRegular};
  & > svg {
    color: ${Colour.Red[500]};
    height: 24px;
    width: 24px;
  }
`;

export const PromptBody = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 576px;
  margin-bottom: 32px;
`;

export const FormLabel = styled.div`
  ${BodyStandardMedium};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const OptionalLabel = styled.span`
  color: ${Colour.Gray[300]};
`;
