//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Card, message, Layout, Row, Col } from "antd";
import _ from "lodash";
import {
  filterGeoAddressLocation,
  delay,
  mustBeArray,
  errorDisplay,
  isAddressSame,
  errorHandler,
  conditionalParameter,
  getMatchParams,
  validateUsersAccessRights,
  findAuthorizationClaims,
  headerAction,
} from "../../../libs";
import BasicBillingServiceProviderForm from "../../components/bsp/basicForm";
import axios from "axios";
import FormNavbar from "../../../Layout/FormNavbar";
import CreateSuccess from "../../../Common/CreateSuccess";
import NoRecord from "../../../Common/NoRecord";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import {
  AUSTRALIA_COUNTRY_ID,
  GOOGLE_MAP_URL,
} from "../../../constants/entities";
import { FormStepsCol } from "../../../Common/ui";
import { BILLING_PROVIDER_CREATE_SIMPLE_BUTTON } from "../../../constants/authorizationClaims";
import { withRouter } from "../../../hooks";

const { Header, Content } = Layout;

type State = any;

class CreateBillingServiceProvider extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      isSameAddress: false,
      disableField: false,
      states: [],
      countries: [],
      timezone: [],
      editMode: false,
      finalValues: {},
      logo: {},
      uploadLoading: false,
      newBillingServiceProviderId: "",
      actionOneLink: "",
      activeIndex: "1",
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        },
      ],
      registeredAddress1: "",
      postalAddress1: "",
    };
  }

  loadGoogleMaps = (callBack) => {
    const existingScript = document.getElementById("googlePlacesScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAP_URL;
      script.id = "googlePlacesScript";
      document.body.appendChild(script);
      //action to do after a script is loaded in our case setState
      script.onload = () => {
        if (callBack) callBack();
      };
    }
    if (existingScript && callBack) callBack();
  };
  unloadGoogleMaps = () => {
    let googlePlacesScript = document.getElementById("googlePlacesScript");
    if (googlePlacesScript) {
      googlePlacesScript.remove();
    }
  };
  componentDidMount() {
    const { match, pageClaims } = this.props;
    const id = match && match.params && match.params.id;
    const editing = id ? true : false;

    // Check if user has access to create BSP. If not redirect to home.
    const createButton = findAuthorizationClaims({
      claims: pageClaims.components,
      name: BILLING_PROVIDER_CREATE_SIMPLE_BUTTON,
    });
    if (!validateUsersAccessRights({ claim: createButton })) return;

    this.loadGoogleMaps(() => {
      this.setState({ mapLoaded: true });
    });
    this.fetchCountries("");
    if (editing) {
      this.props
        .billingServiceProviderHandler({ id, action: "get", options: {} })
        .then((resp) => {
          this.fetchTimezone();

          if (resp.status) {
            headerAction.set({
              title:
                resp.data && resp.data.name
                  ? `Edit: ${resp.data.name}`
                  : "Edit:",
              action: "",
              entity: "billingserviceprovider",
              enableBack: true,
            });
            this.setState((prevState) => {
              prevState.editMode = true;

              prevState.isSameAddress = isAddressSame(resp.data);
              prevState.registeredAddress1 = resp?.data?.registeredAddressLine1;
              prevState.postalAddress1 = resp?.data?.postalAddressLine1;
              prevState.logo =
                resp.data && resp.data.logoURL
                  ? {
                      url: resp.data && resp.data.logoURL,
                    }
                  : {};
              return prevState;
            });

            if (resp.data) {
              this.props.form.setFieldsValue({
                postalAddress2: resp.data.postalAddressLine2,

                postalSuburb: resp.data.postalSuburb,

                postalPostCode: resp.data.postalPostalCode,

                postalState: Number(resp.data.postalAddressStateId),

                registeredAddress2: resp.data.registeredAddressLine2,

                registeredSuburb: resp.data.registeredSuburb,

                registeredPostCode: resp.data.registeredPostalCode,

                registeredState: Number(resp.data.registeredAddressStateId),

                name: resp.data.name,

                registeredName: resp.data.registeredBusinessName,

                abn: resp.data.abn,

                serviceCountryId: resp.data.serviceCountryId,

                proceedOnChartofAccountConflict: resp.data
                  .proceedOnChartofAccountConflict
                  ? 1
                  : 2,
              });
            }
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
    } else {
      this.fetchTimezone();
      headerAction.set({
        title: "New Billing Service Provider",
        action: "",
        entity: "billingserviceprovider",
        enableBack: true,
      });
    }
  }

  componentWillUnmount() {
    this.unloadGoogleMaps();

    this.signal.cancel("Api is being canceled");
  }

  fetchCountries = (val) => {
    let id =
      this.props.match && this.props.match.params && this.props.match.params.id;

    this.props.countriesLookup({ options: { name: val } }).then((response) => {
      if (response.status) {
        this.setState({ countries: mustBeArray(response.data) }, () => {
          const defaultCountryID = _.isUndefined(id)
            ? 13
            : _.isNull(
                this.props.billingServiceProviderDetail &&
                  this.props.billingServiceProviderDetail.countryId
              )
            ? 13
            : Number(
                this.props.billingServiceProviderDetail &&
                  this.props.billingServiceProviderDetail.countryId
              );

          this.updateLocationFields(Number(defaultCountryID), "id");
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
    });
  };

  updateLocationFields(defaultCountryID, key, registeredAddressSelect) {
    return new Promise((resolve, rej) => {
      const selectedCountry = _.find(
        this.state.countries,
        (country) => country[key] === defaultCountryID
      );
      if (selectedCountry && selectedCountry.id) {
        if (
          registeredAddressSelect &&
          selectedCountry.id !== this.props.form.getFieldValue("country")
        ) {
          this.props.form.resetFields(["abn", "operatingTimezone"]);
        }
        this.props.form.setFieldsValue({ country: selectedCountry.id });
        this.populateStates(selectedCountry.id, true).then((res) => {
          if (res.status) {
            resolve(true);
          } else {
            errorDisplay(res && res.data && res.data.validationErrors);
          }
        });
      } else {
        this.props.form.resetFields(["country"]);
      }
    });
  }

  fetchTimezone = () => {
    this.props.timezoneLookup({ options: {} }).then((resp) => {
      let timezone = mustBeArray(resp && resp.data);
      this.setState({ timezone }, () => {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.id
        ) {
          this.props.form.setFieldsValue({
            operatingTimezone:
              this.props.billingServiceProviderDetail &&
              this.props.billingServiceProviderDetail.operatingTimeZoneID,
          });
        }
      });
    });
  };

  populateStates = (val, select) => {
    let params = {};
    params.countryId = val;

    return this.props.populateStates({ options: params }).then((response) => {
      if (response.status) {
        this.setState({ states: response.data });
        delay(1000).then(() => {
          if (!_.isUndefined(this.props.model) && select) {
            let postalIDs = _.map(response.data, (state) => state.id);
            if (
              postalIDs.includes(Number(this.props.model.postalAddressStateId))
            ) {
              this.props.form.setFieldsValue({
                postalState: Number(this.props.model.postalAddressStateId),
              });
            }
            if (
              postalIDs.includes(
                Number(this.props.model.registeredAddressStateId)
              )
            ) {
              this.props.form.setFieldsValue({
                registeredState: Number(
                  this.props.model.registeredAddressStateId
                ),
              });
            }
          }
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }

      return response;
    });
  };

  onAddressSelect = (addressObject, mode) => {
    return new Promise((resolve, rej) => {
      var address = filterGeoAddressLocation(addressObject);
      if (mode === "registered") {
        this.props.form.resetFields(["registeredState"]);
        this.setState({ registeredAddress1: address.address1 });
        this.props.form.setFieldsValue({
          registeredSuburb: address.city,
          registeredPostCode: address.postalcode,
        });

        if (this.state.isSameAddress) {
          this.props.form.resetFields(["postalState"]);
          this.setState({ postalAddress1: address.address1 });
          this.props.form.setFieldsValue({
            postalSuburb: address.city,
            postalPostCode: address.postalcode,
          });
        }
      } else if (mode === "postal") {
        this.props.form.resetFields(["postalState"]);
        this.setState({ postalAddress1: address.address1 });
        this.props.form.setFieldsValue({
          postalSuburb: address.city,
          postalPostCode: address.postalcode,
        });
      }

      this.updateLocationFields(
        address.country_code,
        "code",
        mode === "registered"
      ).then((res) => {
        if (!_.isUndefined(address.state)) {
          const selectedState = _.find(
            this.state.states,
            (st) => st.code.toLowerCase() === address.state.toLowerCase()
          );
          if (mode === "postal") {
            this.props.form.setFieldsValue({
              postalState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });
          } else {
            this.props.form.setFieldsValue({
              registeredState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });

            if (this.state.isSameAddress) {
              this.props.form.setFieldsValue({
                postalState: conditionalParameter({
                  data: selectedState,
                  field: "id",
                }),
              });
            }
          }
        }
      });
      resolve(true);
    });
  };

  onSameAddressChange = (val) => {
    this.setState({
      isSameAddress: val,
    });
    if (val) {
      this.setState({ postalAddress1: this.state.registeredAddress1 });
      this.props.form.setFieldsValue({
        postalAddress: this.props.form.getFieldValue("registeredAddress"),

        postalAddress2: this.props.form.getFieldValue("registeredAddress2"),

        postalSuburb: this.props.form.getFieldValue("registeredSuburb"),

        postalPostCode: this.props.form.getFieldValue("registeredPostCode"),

        postalState: this.props.form.getFieldValue("registeredState"),
      });
    }
  };

  handleSelectChange = ({ key, value }) => {
    this.props.form.setFieldsValue({
      key: value,
    });
  };

  onChangeCountry = () => {
    this.props.form.resetFields([
      "postalState",
      "registeredState",
      "postalSuburb",
      "postalPostCode",
      "registeredSuburb",
      "registeredPostCode",
      "registeredAddress2",
      "postalAddress2",
      "operatingTimezone",
      "abn",
    ]);

    this.setState({
      isSameAddress: false,
      postalAddress1: "",
      registeredAddress1: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { form } = this.props;
    let id = getMatchParams({ match: this.props.match, field: "id" });
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!this.state.registeredAddress1) {
        return message.error("Please enter physical address line 1.");
      }
      if (!this.state.postalAddress1) {
        return message.error("Please enter postal address line 1.");
      }
      if (_.isEmpty(this.state.logo)) {
        return message.error("Please upload a logo for the billing provider");
      }

      this.setState({ submitLoading: true });
      let finalValues = {
        name: values.name,
        operatingTimeZone: values.operatingTimezone,
        registeredBusinessName: values.registeredName,
        abn: values.abn ? Number(values.abn) : "",
        address: {
          address1: this.state.registeredAddress1,
          address2: values.registeredAddress2 || "NA",
          suburb: values.registeredSuburb,
          state: values.registeredState.toString(),
          postCode: values.registeredPostCode,
          isActive: true,
        },
        postalAddress: {
          address1: this.state.postalAddress1,
          address2: values.postalAddress2 || "NA",
          suburb: values.postalSuburb,
          state: values.postalState.toString(),
          postCode: values.postalPostCode,
          isActive: true,
        },
        country: values.country,

        logoURL: this.state.logo && this.state.logo.url,
        serviceCountryId: values.serviceCountryId,
        proceedOnChartofAccountConflict: Number(
          values.proceedOnChartofAccountConflict === 1
        )
          ? true
          : false,
      };

      let country = this.props.form.getFieldValue("country");

      let abn = this.props.form.getFieldValue("abn");

      if (
        !this.props.form.getFieldValue("name") ||
        !this.props.form.getFieldValue("name").trim()
      ) {
        return message.error("Please enter billing service provider's name");
      }

      if (
        !this.props.form.getFieldValue("registeredName") ||
        !this.props.form.getFieldValue("registeredName").trim()
      ) {
        return message.error(
          "Please enter billing service provider's registered name"
        );
      }
      if (
        country === AUSTRALIA_COUNTRY_ID &&
        (abn === null || abn === "" || abn === undefined)
      ) {
        return message.error("ABN is required for Australia");
      }
      this.setState({ submitLoading: true });
      if (id) {
        finalValues.id = id;
        finalValues.isActive =
          this.props.billingServiceProviderDetail &&
          this.props.billingServiceProviderDetail.isActive;

        this.props
          .billingServiceProviderHandler({
            payload: finalValues,
            id,
            action: "update",
          })
          .then((response) => {
            this.setState({ submitLoading: false });

            if (response.status) {
              this.setState({
                disableField: false,
                isSameAddress: false,
                isSameCompany: false,
              });
              message.success("Billing Service Provider updated successfully");

              this.onCancel();
            } else {
              this.setState({ submitLoading: false });
              errorHandler({ response, hasValidationErrors: true });
            }
          });
      } else {
        finalValues.isActive = true;
        this.props
          .billingServiceProviderHandler({
            action: "create",
            payload: finalValues,
          })
          .then((response) => {
            this.setState({ submitLoading: false });
            if (response.status) {
              this.setState({
                disableField: false,
                isSameAddress: false,
                isSameCompany: false,
              });

              const newBillingServiceProviderId = response.data;

              this.setState({
                actionOneLink: `/billingserviceproviders/${newBillingServiceProviderId}`,
                newBillingServiceProviderId,
              });
            } else {
              this.setState({ submitLoading: false });
              errorHandler({ response, hasValidationErrors: true });
            }
          });
      }
    });
  };

  onCancel = () => {
    this.setState({
      disableField: false,
      isSameAddress: false,
      isSameCompany: false,
      submitLoading: false,
      registeredAddress1: "",
      postalAddress1: "",
    });

    this.props.form.resetFields();

    let id =
      this.props.match && this.props.match.params && this.props.match.params.id;
    if (id) return this.props.router.navigate(`/billingserviceproviders/${id}`);
    this.props.router.navigate(`/billingserviceproviders`);
  };

  populateSameAddressField = (value, field) => {
    if (this.state.isSameAddress) {
      this.props.form.setFieldsValue({ [field]: value });
    }
  };

  uploadLogo = (file) => {
    let imageType = ["image/jpg", "image/jpeg", "image/png"];
    if (!imageType.includes(file && file.type))
      return message.error("Please upload images only.");

    this.setState({ logo: { ...file } });

    this.props
      .billingServiceProviderHandler({
        action: "uploadLogo",
        payload: { file },
        options: {},
      })
      .then((resp) => {
        if (resp.status) {
          message.success("Logo uploaded successfully.");

          this.setState({
            logo: {
              url: resp && resp.data,
            },
          });
        } else {
          this.setState({ logo: {} });
          errorDisplay(resp && resp.data && resp.data.validationErrors, true);
        }
      });
    return false;
  };

  removeLogo = () => {
    this.setState({ logo: {} });
  };

  updateActiveStep = (activeIndex) => {
    if (Number(activeIndex) < Number(this.state.activeIndex)) {
      this.setState({ activeIndex: `${activeIndex}` });
    }
  };
  onAddressChange = (value, type) => {
    if (type === "registered") {
      this.setState({ registeredAddress1: value });
      if (this.state.isSameAddress) {
        this.setState({ postalAddress1: value });
      }
    } else {
      this.setState({ postalAddress1: value });
    }
  };
  render() {
    const { form, billingServiceProviderDetail } = this.props;
    const {
      editMode,
      states,
      timezone,
      isSameAddress,
      countries,
      submitLoading,
      logo,
      uploadLoading,
      newBillingServiceProviderId,
      actionOneLink,
      activeIndex,
      formLabels,
    } = this.state;
    return (
      <Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={false}
        autoComplete="off"
      >
        <Layout>
          {newBillingServiceProviderId ? (
            <NoRecord>
              <CreateSuccess
                page="Billing Service Provider"
                actionOneLabel="View Billing Service Provider"
                actionOneLink={actionOneLink}
                actionTwoLabel="Create Pay Schedule"
                actionTwoLink={""}
              />
            </NoRecord>
          ) : (
            <React.Fragment>
              <Header className="bg-white w-full position-absolute-top">
                <FormNavbar
                  title="Billing Service Provider"
                  handleCancel={this.onCancel}
                  handleSave={this.handleSubmit}
                  htmlType="submit"
                  loading={submitLoading}
                />
              </Header>
              <Layout className="p-6 rounded-[5px] create-screen-body overflow-y-auto w-full">
                <Content>
                  <Row gutter={DEFAULT_GUTTER} className="form-label-left">
                    <FormStepsCol data={formLabels} activeIndex={activeIndex} />
                    <Col md={18} sm={24}>
                      {
                        <div
                          className={
                            Number(activeIndex) === 1 ? "block" : "display-none"
                          }
                        >
                          <Card className="form-label-left">
                            <BasicBillingServiceProviderForm
                              form={form}
                              populateStates={this.populateStates}
                              onChangeCountry={this.onChangeCountry}
                              timezone={timezone}
                              onSameAddressChange={this.onSameAddressChange}
                              isSameAddress={isSameAddress}
                              onAddressSelect={this.onAddressSelect}
                              onAddressChange={this.onAddressChange}
                              registeredAddress1={this.state.registeredAddress1}
                              postalAddress1={this.state.postalAddress1}
                              populateSameAddressField={
                                this.populateSameAddressField
                              }
                              states={states}
                              editMode={editMode}
                              countries={countries}
                              uploadLogo={this.uploadLogo}
                              removeLogo={this.removeLogo}
                              uploadLoading={uploadLoading}
                              logo={logo}
                              billingServiceProviderDetail={
                                billingServiceProviderDetail
                              }
                              mapLoaded={this.state.mapLoaded}
                              activeIndex={activeIndex}
                            />
                          </Card>
                        </div>
                      }
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </React.Fragment>
          )}
        </Layout>
      </Form>
    );
  }
}

export default withRouter(
  Form.create({ name: "billingServiceProviderForm" })(
    CreateBillingServiceProvider
  )
);
