//@ts-nocheck
import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import { parseItems } from "../../../libs/utilities";

let label = 6;
let value = 18;

const AmendmentDeclineReason = ({ description, viewModal, type }: any) => {
  let rejectionDescription = {};
  let errorDescription = "";
  try {
    if (description) {
      rejectionDescription = parseItems(description);
      if (type === "payrun" && rejectionDescription.RejectionReason) {
        errorDescription = rejectionDescription.RejectionReason?.Description;
      }
    }
  } catch (err) {
    console.log(err);
  }
  if (!viewModal) {
    label = 7;
    value = 17;
  } else {
    label = 3;
    value = 21;
  }

  return (
    <React.Fragment>
      <Row className="mb-1">
        <Col span={label}>
          <b>Rejected by:</b>
        </Col>
        <Col span={value}>{rejectionDescription?.User?.UserName}</Col>
      </Row>
      <Row className="mb-1">
        <Col span={label}>
          <b>Date:</b>
        </Col>
        <Col span={value}>
          {moment(rejectionDescription?.User?.Date).format(
            "DD/MM/YYYY hh:mm A"
          )}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col span={label}>
          <b>Reason:</b>
        </Col>
        <Col span={value}>
          {type === "payrun"
            ? errorDescription
            : rejectionDescription?.RejectionReason}
        </Col>
      </Row>
      {type === "payrun" && (
        <Row>
          <Col span={label}>
            <b>Category:</b>
          </Col>
          <Col span={value}>
            {rejectionDescription?.RejectionReason?.ErrorCategory}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
export default AmendmentDeclineReason;
