import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import ClaimWrapper from "../../../Common/claimWrapper";
import {
  showExportToXero,
  getExportToXeroClassName,
  getExportToXeroText,
  isExportToXeroDisabled,
} from "../../../libs/validations";
import { useAnalyticsEventTracker } from "../../../hooks";
import { PAYRUN_EXPORT_TO_XERO_BUTTON } from "../../../constants";
import { If } from "../../ui";

type Props = {
  pageClaims: object;
  invoice: any;
  loading: boolean;
  onClick: () => void;
};

const ExportToXero = ({ pageClaims, invoice, loading, onClick }: Props) => {
  const payrun = useSelector(({ payrun }: any) => payrun);

  // Define the category for the event
  const gaEventTracker = useAnalyticsEventTracker("Pay Run Pending Payment");

  const handleClick = () => {
    onClick();
    gaEventTracker(exportToXeroTxt);
  };
  const showComponent = useMemo(() => {
    return showExportToXero({
      payrun: payrun.detail,
      amendments: payrun.amendmentList,
      earningLines: invoice?.invoiceEarningLines,
    });
  }, [payrun, invoice]);

  const exportToXeroTxt = getExportToXeroText({ invoice });
  return (
    <If
      condition={showComponent}
      then={
        <ClaimWrapper name={PAYRUN_EXPORT_TO_XERO_BUTTON} claims={pageClaims}>
          <Button
            className={`float-left rounded-lg mr-4 ${getExportToXeroClassName({
              invoice,
            })}`}
            loading={loading}
            disabled={isExportToXeroDisabled({
              invoice,
            })}
            onClick={handleClick}
          >
            {exportToXeroTxt}
          </Button>
        </ClaimWrapper>
      }
      else={<React.Fragment />}
    />
  );
};

export default ExportToXero;
