//@ts-nocheck
import React from "react";
import { mustBeArray, findScrollableTableHeight } from "../../libs/utilities";
import { Table } from "antd";

class ScrollableTableContainer extends React.Component {
  render() {
    const { dataSource, onChange, loading, columns, bordered, per_page } =
      this.props;
    return (
      <Table
        columns={columns}
        rowKey={(_record, index) => index}
        dataSource={mustBeArray(dataSource)}
        bordered={bordered ? bordered : false}
        pagination={false}
        onChange={onChange}
        loading={loading}
        scroll={{
          x: "max-content",
          y: findScrollableTableHeight({
            per_page,
            total: mustBeArray(dataSource).length,
          }),
        }}
      />
    );
  }
}

export default ScrollableTableContainer;
