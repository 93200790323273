
const ModalTitle = ({
  selectedAmendment,
  title,
  handleBusinessUnitView
}: any) => (
  <>
    <div>{title}</div>
       <div className="text-blue-500 hand text-sm font-semibold"
    onClick={handleBusinessUnitView}>
        {selectedAmendment?.businessUnitName}
    </div>
     
  </>
);

export default ModalTitle;
