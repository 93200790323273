import { Dispatch, useEffect, useRef } from 'react';
import { User, UserManager } from 'oidc-client-ts';

import { Action, AuthActions } from '../auth-provider.types';
import { getReturnUrl, hasAuthParams, loginError } from '../utils';

interface Props {
  userManager: UserManager;
  onSigninCallback?: (user: User | void) => Promise<void> | void;
  dispatch: Dispatch<Action>;
}

const defaultOnSignincallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

/**
 * Hook created for internal use only
 * It handles initial attempt to login
 */
export const useSigninCallback = ({ userManager, dispatch, onSigninCallback }: Props) => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!userManager || isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    dispatch({ type: AuthActions.ReturnUrl, returnUrl: getReturnUrl() });

    void (async (): Promise<void> => {
      let user: User | void | null = null;
      try {
        // check if returning back from authority server
        if (hasAuthParams()) {
          user = await userManager.signinCallback();
          if (onSigninCallback) {
            onSigninCallback(user);
          } else {
            defaultOnSignincallback(user);
          }
        }
        user = user ?? (await userManager.getUser());
        dispatch({ type: AuthActions.Initialized, user });
      } catch (error) {
        dispatch({ type: AuthActions.Error, error: loginError(error) });
      }
    })();
  }, [dispatch, onSigninCallback, userManager]);
};
