import React, { useEffect, useState } from "react";
import { ListSelectField } from "../../../ui";
import { mustBeArray } from "../../../../libs/utilities";

type Props = {
  required: boolean;
  onChange: (e: any) => void;
  listBusinessUnits: (e: any) => Promise<any>;
  disabled?: boolean;
};
const arr: any = [];

const BusinessUnitField = ({
  required,
  onChange,
  listBusinessUnits,
  disabled,
}: Props) => {
  const [businessUnitLists, setBusinessUnitLists] = useState(arr);

  useEffect(() => {
    listBusinessUnits({ options: { per_page: 500 } }).then((resp) => {
      if (resp.status) {
        setBusinessUnitLists(mustBeArray(resp.data));
      }
    });
  }, []);

  return (
    <ListSelectField
      label="Business Unit"
      name="businessUnitID"
      className="mb-3 col-start-1"
      required={required}
      ruleMsg="Please select a business unit"
      onChange={onChange}
      list={businessUnitLists}
      disabled={disabled}
    />
  );
};

export default BusinessUnitField;
