//@ts-nocheck
import React from "react";
import {
    message, Table
} from "antd";
import { tableColumnGenerator } from "../../../libs/table";
 import { errorDisplay, mustBeArray, findScrollableTableHeight, getUserListColumns, errorHandler } from "../../../libs/utilities";
import _ from "lodash";
import TableActionComponent from "../common/tableActions";

type State = any;

class ScrollableUserList extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            userDeleteLoading: false
        };
    }

    componentDidMount() {
        document.querySelector('.ant-table-body') && document.querySelector('.ant-table-body').addEventListener('scroll', (event) => {
            let maxScroll = (event.target).scrollHeight - (event.target).clientHeight;
            let currentScroll = (event.target).scrollTop;
            if (((currentScroll >= maxScroll) || (maxScroll - currentScroll < 0.5)) && this.props.hasMoreData) {
                this.props.handleTableChange({ options: {}, pageLoad: false });
            }
        })
    }
    componentWillUnmount() {
        document.querySelector('.ant-table-body') && document.querySelector('.ant-table-body').removeEventListener('scroll', () => {console.log('')});
    }

    handleTableChange = (pagination, filters, sorter) => {
            let options = {
            page: pagination.current,
            order: sorter.order === "ascend" ? "asc" : "desc",
            field: sorter.field === "emailAddress" ? "emailaddress" : sorter.field,
        }
        this.props.handleTableChange({
                  options,
            userMode: this.props.userMode
        });
    };

        changeStatus = user => {
        user.enabled = !user.enabled;
        this.props.handleCompanyUserActions({
    id: user.id,
    data: user,
    action: "status",
    userMode: this.props.userMode
})

    .then(resp => {

    if(resp.status) {
                    message.success(
                        `User ${user.enabled ? "enabled" : "disabled"} successfully`
                    );
                    this.props.handleTableChange({
                        options: {}
                    });
                } else {
                    errorHandler({hasValidationErrors: false, response: resp, oldModel: true});
                }
            });
    };

    sendMail = user => {

        this.props
            .handleCompanyUserActions({
                id: user.id,
                data: user,
                action: "sendreminder",

                userMode: this.props.userMode
            })

            .then(resp => {

                if (resp.status) {
                    message.success(`Reminder sent successfully`);

                    this.props.handleTableChange({
                        options: {}
                    });
                } else {
                    errorHandler({ response: resp });
                }
            });
    };
      handleAction = ({ record, action }) => {
        switch (action) {
            case "edit":

                return this.props.handleEdit(record);
            case "view":
                return;
            case "sendReminder":

                return this.sendMail(record);
            default:
                break;
        }
    }

    updateAuthentication = user => {
        user.mfaEnable = !user.mfaEnable;

        this.props
            .handleCompanyUserActions({
                id: user.id,
                data: { mfaEnable: user.mfaEnable },
                action: "updatemultifactorauthentication",

                userMode: this.props.userMode
            })

            .then(resp => {

                if (resp.status) {
                    message.success(
                        `Multi-Factor authentication ${user.mfaEnable ? "enabled" : "disabled"} successfully`
                    );

                    this.props.handleTableChange({
                        options: {}
                    });
                } else {

                    errorDisplay(resp && resp.data && resp.data.validationErrors);
                }
            });
    }

     render() {

        const size = this.props.size ? this.props.size : "default";
        const { sortInfo, claims, component, toggleComponent, reminderComponent,
            editComponent, per_page, userMode, dashboardView, userType,

            data, member } = this.props;

        const actions =
            [
                {
                    title: "Actions",
                    key: "action",
                                render: record => (

                        <React.Fragment>
                            <TableActionComponent
                                record={record}

                                handleAction={this.handleAction}
                                editComponentName={editComponent}
                                viewComponentName={""}
                                sendReminderComponentName={!record.activated ? reminderComponent : ""}

                                claims={this.props.claims}
                            />
                        </React.Fragment>
                    )
                }
            ];
        const column = _.concat(
            tableColumnGenerator(getUserListColumns({

                userType, updateAuthentication: this.updateAuthentication, claims, member,

                sortInfo, component, changeStatus: this.changeStatus, userMode, toggleComponent, dashboardView
            })),
            actions
        );
        return (
            <Table
                columns={column}
                size={size}
                dataSource={mustBeArray(data)}
                pagination={false}
                loading={this.props.loading}
                onChange={this.handleTableChange}
                rowKey={(record, index) => index}
                scroll={{ y: findScrollableTableHeight({ per_page, total: mustBeArray(data).length }) }}
            />
        );
    }
}

export default ScrollableUserList;
