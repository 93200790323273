// @ts-nocheck
import {
  checkClaimsAuthorization,
  findAuthorizationClaims,
  getFilterContainerPersonalizationData,
  getUserArea,
  isCompanyUser,
  isComponentVisible,
  validateUsersAccessRights,
  isUndefined,
  mustBeArray,
  parseItems,
} from "../../../libs";
import GeneralAmendmentCompanyUserView from "./GeneralAmendmentCompanyUserView";
import GeneralAmendmentPSPUserView from "./GeneralAmendmentPspUserView";
import Filters from "../../components/generalAmendment/Filters";
import { If } from "../../../Common/ui";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { setFilters } from "../../../slices/generalAmendment";
import { updateHeader } from "../../../User/actions/member";
import { AmendmentDetailsModal } from "../../../Common/amendments";
import { getPayschedule } from "../../actions/payschedule";
import axios from "axios";
import userRoles from "../../../constants/userRoles";
import { getPayrun } from "../../actions/payrun";
import { PayrunStatus } from "../../../Common";
import {
  GENERAL_AMENDMENT_FILTER_TEXTBOX,
  GENERAL_AMENDMENT_FILTER_AMENDMENT_TYPE,
  GENERAL_AMENDMENT_FILTER_CHANGE_TYPE,
  GENERAL_AMENDMENT_CREATE_SIMPLE_BUTTON,
  GENERAL_AMENDMENT_FILTER_BUSINESS_UNIT,
  GENERAL_AMENDMENT_FILTER_REQUESTED_BY,
  GENERAL_AMENDMENT_FILTER_DATE_RANGE,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { CreateAmedmentButton } from "./CreateAmendmentButton";

const GeneralAmendmentIndex = (props: any) => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const signal = axios.CancelToken.source();

  const { pageClaims, claims } = props;

  const [filtersInitialValuesLoading, setFiltersInitialValueLoading] =
    useState<boolean>(true);
  const [selectedAmendment, setSelectedAmendment] = useState<any>(null);
  const [viewDetailModal, setViewDetailModal] = useState<boolean>(false);
  const [isACompanyUser, setIsACompanyuser] = useState<boolean | undefined>(
    undefined
  );

  // Check authorisation and Read the filters stored in personalization and update store
  useEffect(() => {
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    handleHeaderUpdate();
    loadCurrentAppliedFilters();
    setIsACompanyuser(isCompanyUser());
  }, []);

  useEffect(() => {
    if (selectedAmendment) {
      setViewDetailModal(true);
    }
  }, [selectedAmendment]);

  // Update header in the navbar
  const handleHeaderUpdate = () => {
    dispatch(
      updateHeader({
        header: {
          title: "Amendments",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.AmendmentList,
          module: "",
          enableBack: false,
          entity: "generalAmendment",
          action: "",
        },
      })
    );
  };

  const loadCurrentAppliedFilters = () => {
    //get the textbox filter object from DOM
    const generalAmendmentNameTextboxFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_TEXTBOX,
    });
    // Get personalizations (saved filter) for textbox filter
    const nameTextBoxPersonalizations = getFilterContainerPersonalizationData({
      personalizationData: generalAmendmentNameTextboxFilter?.personalization,
    });
    //get the Amendment Type dropdown object from DOM
    const generalAmendmentTypeDropdownFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_AMENDMENT_TYPE,
    });
    //Get the personalizations (saved value) for the amendment type dropdown
    const amendmentTypePersonalizations = getFilterContainerPersonalizationData(
      {
        personalizationData:
          generalAmendmentTypeDropdownFilter?.personalization,
      }
    );
    //get the changeType dropdown object from the DOM
    const generalAmendmentChangeTypeDropdownFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_CHANGE_TYPE,
    });
    //Get the personalizations (saved value) for the amendment changeType dropdown
    const amendmentChangeTypePersonalizations =
      getFilterContainerPersonalizationData({
        personalizationData:
          generalAmendmentChangeTypeDropdownFilter?.personalization,
      });

    //get the business unit dropdown object from the DOM
    const generalAmendmentBusinessUnitDropdownFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_BUSINESS_UNIT,
    });
    //Get the personalizations (saved value) for the amendment BusinessUnit dropdown
    const amendmentBusinessUnitPersonalizations =
      getFilterContainerPersonalizationData({
        personalizationData:
          generalAmendmentBusinessUnitDropdownFilter?.personalization,
      });

    //get the Requested By dropdown object from the DOM
    const generalAmendmentRequestedByDropdownFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_REQUESTED_BY,
    });
    //Get the personalizations (saved value) for the amendment Requested By dropdown
    const amendmentRequestedByPersonalizations =
      getFilterContainerPersonalizationData({
        personalizationData:
          generalAmendmentRequestedByDropdownFilter?.personalization,
      });

    //get the Requested By dropdown object from the DOM
    const generalAmendmentDateRangeFilter = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_DATE_RANGE,
    });
    //Get the personalizations (saved value) for the amendment Requested By dropdown
    const amendmentDateRangePersonalizations =
      getFilterContainerPersonalizationData({
        personalizationData: generalAmendmentDateRangeFilter?.personalization,
      });

    // update state
    dispatch(
      setFilters({
        name: isComponentVisible({ claim: generalAmendmentNameTextboxFilter })
          ? nameTextBoxPersonalizations?.value
          : "",
        type:
          isComponentVisible({ claim: generalAmendmentTypeDropdownFilter }) &&
          amendmentTypePersonalizations?.value
            ? Number(amendmentTypePersonalizations?.value)
            : undefined,
        changeType:
          isComponentVisible({
            claim: generalAmendmentChangeTypeDropdownFilter,
          }) && amendmentChangeTypePersonalizations?.value
            ? Number(amendmentChangeTypePersonalizations?.value)
            : undefined,
        businessUnit:
          isComponentVisible({
            claim: generalAmendmentBusinessUnitDropdownFilter,
          }) && amendmentBusinessUnitPersonalizations?.value
            ? amendmentBusinessUnitPersonalizations?.value
            : "",
        requestedBy:
          isComponentVisible({
            claim: generalAmendmentRequestedByDropdownFilter,
          }) && amendmentRequestedByPersonalizations?.value
            ? amendmentRequestedByPersonalizations?.value
            : "",
        startDate: isComponentVisible({
          claim: generalAmendmentDateRangeFilter,
        })
          ? mustBeArray(
              parseItems(amendmentDateRangePersonalizations?.value)
            )[0] ?? null
          : null,
        endDate: isComponentVisible({
          claim: generalAmendmentDateRangeFilter,
        })
          ? mustBeArray(
              parseItems(amendmentDateRangePersonalizations?.value)
            )[1] ?? null
          : null,
        loading: false,
      })
    );
    setFiltersInitialValueLoading(false);
  };

  const viewAmendmentDetail = (amendment: any) => {
    setSelectedAmendment(amendment);
  };

  const handleCloseAmendmentModal = () => {
    setSelectedAmendment(null);
    setViewDetailModal(false);
  };

  const handlePayrunView = () => {
    if (
      [userRoles.SystemAdminArea, userRoles.PSPArea].includes(getUserArea())
    ) {
      navigate(`/payrun/${selectedAmendment?.payrunId}`);
    } else {
      dispatch(
        getPayrun({
          id: selectedAmendment?.payrunId,
          options: {},
          cancelToken: signal.token,
        })
      ).then((resp: any) => {
        if (resp.status && resp.data) {
          return PayrunStatus.getStatus({
            id: resp.data.payrunStatusID,
          }) === "draft"
            ? ""
            : navigate(`/payrun/${selectedAmendment?.payrunId}`);
        }
      });
    }
  };

  const handlePayscheduleView = () => {
    dispatch(
      getPayschedule({
        id: selectedAmendment?.payscheduleID,
        cancelToken: signal.token,
      })
    ).then((resp: any) => {
      if (resp?.status && resp.data) {
        const { id, companyId, businessUnitID } = resp.data;
        navigate(
          getPayScheduleDetailRoute({
            id,
            companyId,
            businessUnitId: businessUnitID,
          })
        );
      }
    });
  };

  return (
    <>
      <If
        condition={!filtersInitialValuesLoading && !isUndefined(isACompanyUser)}
        then={
          <If
            condition={!!isACompanyUser}
            then={<GeneralAmendmentCompanyUserView {...props} />}
            else={
              <React.Fragment>
                  <CreateAmedmentButton />
          
                <Filters pageClaims={pageClaims} claims={claims} />

                <GeneralAmendmentPSPUserView
                  pageClaims={pageClaims}
                  viewAmendmentDetail={viewAmendmentDetail}
                />
              </React.Fragment>
            }
          />
        }
      />
      <AmendmentDetailsModal
        amendmentId={selectedAmendment?.amendmentId}
        handlePayrunView={handlePayrunView}
        handlePayscheduleView={handlePayscheduleView}
        handleClose={handleCloseAmendmentModal}
        visible={viewDetailModal}
        payRunID={selectedAmendment?.payrunId}
      />
    </>
  );
};
export default GeneralAmendmentIndex;
