import requireAuth from "../../Common/requireAuth";
import BusinessUnitContainer from "../../Organization/containers/BusinessUnit";
import BusinessUnitDetailContainer from "../../Organization/containers/BusinessUnitDetail";
import BusinessUnitIndex from "../../Organization/pages/BusinessUnit/Index";
import createBusinessUnit from "../../Organization/pages/BusinessUnit/createBusinessUnit";
import BusinessUnitDetail from "../../Organization/pages/BusinessUnit/Detail";
import { SCOPES } from "../authorizationClaims";
import React from "react";

export const BusinessUnitRoutesWithSidebar = [
  {
    path: "businessunit",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    container: BusinessUnitContainer,
    componentPath: "../../Organization/pages/BusinessUnit/Index",
    element: (
      <BusinessUnitContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(BusinessUnitIndex)}
      />
    ),
  },
  {
    path: "company/:companyid/businessunit/:businessunitid",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    container: BusinessUnitDetailContainer,
    componentPath: "../../Organization/pages/BusinessUnit/Detail",
    element: (
      <BusinessUnitDetailContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(BusinessUnitDetail)}
      />
    ),
  },
  {
    path: "businessunit/:businessunitid",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    container: BusinessUnitDetailContainer,
    componentPath: "../../Organization/pages/BusinessUnit/Detail",
    element: (
      <BusinessUnitDetailContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(BusinessUnitDetail)}
      />
    ),
  },
];

export const BusinessUnitRoutesWithoutSidebar = [
  {
    path: "businessunit/create",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    container: BusinessUnitDetailContainer,
    componentPath: "../../Organization/pages/BusinessUnit/createBusinessUnit",
    element: (
      <BusinessUnitDetailContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(createBusinessUnit)}
      />
    ),
  },
  {
    path: "company/:companyid/businessunit/create",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    container: BusinessUnitDetailContainer,
    componentPath: "../../Organization/pages/BusinessUnit/createBusinessUnit",
    element: (
      <BusinessUnitDetailContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(createBusinessUnit)}
      />
    ),
  },
  {
    path: "payrollprovider/:payrollproviderid/businessunit/create",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    container: BusinessUnitDetailContainer,
    componentPath: "../../Organization/pages/BusinessUnit/createBusinessUnit",
    element: (
      <BusinessUnitDetailContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(createBusinessUnit)}
      />
    ),
  },
  {
    path: "company/:companyid/businessunit/:businessunitid/edit",
    scope: SCOPES.BUSINESSUNIT,
    index: "businessunit",
    template: <React.Fragment />,
    container: BusinessUnitDetailContainer,
    componentPath: "../../Organization/pages/BusinessUnit/createBusinessUnit",
    element: (
      <BusinessUnitDetailContainer
        scope={SCOPES.BUSINESSUNIT}
        Layout={requireAuth(createBusinessUnit)}
      />
    ),
  },
];
