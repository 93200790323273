import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import generalAmendment from "../reducers/generalAmendment";
import { mustBeArray } from "../libs";

type FilterState = {
  name: string;
  changeType?: number;
  type?: number;
  loading?: boolean;
  startDate: string | null;
  endDate: string | null;
  requestedBy?: string;
  businessUnit?: string;
};

type IntState = {
  filters: FilterState;
  payrunAmendment: any;
  adhocAmendment: any;
  reoccurringAmendment: any;
  compUserDashAmendment: any;
  detail: any;
};

const listInitialState = { list: [], count: 0 };
const filterInitalState = {
  name: "",
  changeType: undefined,
  type: undefined,
  loading: false,
  startDate: null,
  endDate: null,
  requestedBy: "",
  businessUnit: "",
};

const initialState: IntState = {
  payrunAmendment: { ...listInitialState },
  adhocAmendment: { ...listInitialState },
  reoccurringAmendment: { ...listInitialState },
  compUserDashAmendment: { ...listInitialState },
  detail: {},
  filters: filterInitalState,
};

const notificationsSlice = createSlice({
  name: "generalAmendment",
  initialState,
  reducers: {
    ...generalAmendment,
    CLEAR_GENERAL_AMENDMENTS: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
    resetFilters: (state) => {
      return {
        ...state,
        filters: { ...filterInitalState, loading: true },
      };
    },
    setFilterName: (state, action: PayloadAction<string>) => {
      state.filters.name = action.payload;
    },
    setFilterChangeType: (state, action: PayloadAction<number>) => {
      state.filters.changeType = action.payload;
    },
    setFilterType: (state, action: PayloadAction<number>) => {
      state.filters.type = action.payload;
    },
    setFilterDateRange: (state, action: PayloadAction<Array<any>>) => {
      state.filters.startDate = mustBeArray(action.payload)[0];
      state.filters.endDate = mustBeArray(action.payload)[1];
    },
    setFilterRequestedBy: (state, action: PayloadAction<string>) => {
      state.filters.requestedBy = action.payload;
    },
    setFilterBusinessUnit: (state, action: PayloadAction<string>) => {
      state.filters.businessUnit = action.payload;
    },
    // To Update both filters on page load
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setFilterLoading: (state, action: PayloadAction<boolean>) => {
      state.filters.loading = action.payload;
    },
    setCompUserDashAmendments: (state, action: PayloadAction<any>) => {
      const { data, total } = action.payload;
      return {
        ...state,
        compUserDashAmendment: {
          list: data,
          count: total,
        },
      };
    },
  },
});

const { reducer, actions } = notificationsSlice;

export const {
  setFilterName,
  setFilterType,
  setFilterChangeType,
  resetFilters,
  setFilters,
  setFilterLoading,
  setCompUserDashAmendments,
  setFilterDateRange,
  setFilterBusinessUnit,
  setFilterRequestedBy,
} = actions;

export const selectFilters = (state: RootState) =>
  state.generalAmendment.filters;
export const selectFilterIsloading = (state: RootState) =>
  state.generalAmendment.filters.loading;

export default reducer;
