import { SVGProps } from 'react';
const DawnTick16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 3.44a.75.75 0 0 1 .06 1.06l-7.143 8a.75.75 0 0 1-1.12 0L2.442 9.3a.75.75 0 0 1 1.118-1l2.298 2.574L12.441 3.5a.75.75 0 0 1 1.058-.06Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnTick16;
