//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Card,
  Col,
  Tooltip,
  DatePicker,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  TimePicker,
  Button,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import {
  formatTime,
  bankFileDifferentDayDisabled,
  delay,
  mustBeArray,
  errorDisplay,
  getConditionalResponse,
  isNullEmptyUndefined,
} from "../../../../libs";
import BaseSalary from "../../common/baseSalary";
import { DEFAULT_GUTTER } from "../../../../constants/ui";
import { subtractDays } from "../../../../libs/timeHandlers";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

type State = any;

class PayScheduleForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      editMode: true,
      disableIndex: 4,
      payOccurence: 1,
      payrollModel: 1,
      bankFileDateInDay: 1,
      businessunitList: [],
      fetching: false,
      companyList: [],
      disableBU: false,
      steps: [],
      payDate: 20,
      payrun: {},
      daysBeforeAfter: "1",
    };
  }

  componentDidMount() {
    this.updateState();
    this.fetchCompany();
    const { payschedule } = this.props;
    if (payschedule) {
      this.getDefaultPayDate({ payOccurenceId: payschedule.payOccurenceId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.displayModel !== nextProps.displayModel) {
      this.updateState();
    }
    const { payschedule } = nextProps;
    if (payschedule?.id !== this.props.payschedule?.id) {
      this.getDefaultPayDate({ payOccurenceId: payschedule.payOccurenceId });
    }
  }

  displayForm = () => {
    return this.props.visible || this.props.displayModel;
  };

  getDefaultPayDate = ({ payOccurenceId }) => {
    delay(500).then(() => {
      this.onPayOccurenceSelect(payOccurenceId, true);
    });
  };

  updateState = () => {
    const { visible } = this.props;
    if (!_.isUndefined(visible)) {
      this.props.renderData();
    }
    if (this.props.companyId) {
      this.props
        .getCompany({
          id: this.props.companyId,
          cancelToken: this.props.signal.token,
        })

        .then((res) => {
          this.setState({ fetching: false });

          if (res.status) {
            this.setState({
              companyList: [{ id: res.data.id, name: res.data.name }],
              disableCompany: true,
            });

            this.props.form.setFieldsValue({ company: this.props.companyId });

            this.handleChangeCompany(this.props.companyId);
          } else {
            errorDisplay(res & res.data & res.data.validationErrors);
          }
        });
    }
  };

  componentWillUnmount() {
    this.props.signal.cancel("Api is being canceled");
  }

  onRadioChange = (inputValue, newIndexValue) => {
    if (inputValue === 1 && newIndexValue === 3) {
      this.setState({ disableIndex: 4, payrollModel: 1 });

      this.props.handleFormValueChange({
        value: true,
        field: "isBankFileUploadDatePayDay",
      });

      this.props.handleFormValueChange({
        value: true,
        field: "isUploadBankFile",
      });

      this.props.form.setFieldsValue({
        PSPUploadBank: 1,
        BankfileDated: 1,
      });
    }
    if (inputValue === 2 && newIndexValue === 3) {
      this.props.handleFormValueChange({
        value: true,
        field: "isBankFileUploadDatePayDay",
      });

      this.props.handleFormValueChange({
        value: true,
        field: "isUploadBankFile",
      });

      this.setState({ disableIndex: 2, payrollModel: 2 });

      this.props.form.setFieldsValue({
        PSPUploadBank: 1,
        BankfileDated: 1,
      });
    }

    if (inputValue === 1 && newIndexValue === 2) {
      this.props.handleFormValueChange({
        value: true,
        field: "isBankFileUploadDatePayDay",
      });

      this.setState({ disableIndex: 2 });

      this.props.form.setFieldsValue({
        BankfileDated: 1,
      });
    }
    if (inputValue === 2 && newIndexValue === 2) {
      this.props.handleFormValueChange({
        value: true,
        field: "isBankFileUploadDatePayDay",
      });

      this.setState({ disableIndex: 3 });

      this.props.form.setFieldsValue({
        BankfileDated: 1,
      });
    }

    if (inputValue === 1 && newIndexValue === 1) {
      this.setBankFileUploadDay(0);
      let editBankFileDateInDay = document.getElementById(
        "editBankFileDateInDay"
      );
      if (
        editBankFileDateInDay &&
        editBankFileDateInDay.querySelector(
          ".ant-select-selection-selected-value"
        )
      ) {
        editBankFileDateInDay.querySelector(
          ".ant-select-selection-selected-value"
        ).innerText = 0;
      }

      this.setState({ disableIndex: 2 });
    }
    if (inputValue === 2 && newIndexValue === 1) {
      this.setState({ disableIndex: 1 });
    }
  };

  onPayOccurenceSelect = (value, pageLoad) => {
    this.setState({ payOccurence: value });
    if (!pageLoad) {
      this.handlePayCycleChange();
    }

    let firstPayDate = this.props.form?.getFieldValue("firstPayDate");
    let defaultPayDate = 25;
    if (firstPayDate) {
      defaultPayDate = Math.abs(moment().diff(firstPayDate, "day")) + 1;
    }
    switch (value) {
      case 2:
        this.setState({ payDate: defaultPayDate < 10 ? defaultPayDate : 10 });
        break;
      case 3:
        this.setState({ payDate: defaultPayDate < 4 ? defaultPayDate : 4 });
        break;
      default:
        this.setState({ payDate: defaultPayDate < 25 ? defaultPayDate : 25 });
    }
  };

  handleChangeCompany = (value) => {
    const { businessUnitID } = this.props;

    this.props
      .listCompanyBusinessUnits({ id: value, options: { per_page: 500 } })

      .then((response) => {
        if (response.status) {
          this.setState({ businessunitList: response.data });
          if (!isNullEmptyUndefined(businessUnitID)) {
            this.setState({ disableBU: true });

            this.props.form.setFieldsValue({ businessunit: businessUnitID });
          } else {
            this.props.form.resetFields(["businessunit"]);
          }
        } else {
          this.setState({ businessunitList: [] });

          this.props.form.resetFields(["businessunit"]);

          errorDisplay(
            response & response.data & response.data.validationErrors
          );
        }
      });
  };

  fetchCompany = (value) => {
    this.setState({ fetching: true });
    if (value) {
      this.props
        .companyLookup({ options: { CompanyName: value, per_page: 500 } })

        .then((response) => {
          this.setState({ fetching: false });

          if (response.status) {
            let companyList = _.map(mustBeArray(response.data), (a, b) => {
              return { ...a, name: a.value };
            });

            this.setState({ companyList });
          } else {
            errorDisplay(
              response & response.data & response.data.validationErrors
            );
          }
        });
    } else {
      this.props
        .companyLookup({ options: { per_page: 500 } })
        .then((response) => {
          if (response.status) {
            let companyList = _.map(mustBeArray(response.data), (a, b) => {
              return { ...a, name: a.value };
            });

            this.setState({ companyList });
          } else {
            errorDisplay(
              response & response.data & response.data.validationErrors
            );
          }

          this.setState({ fetching: false });
        });
    }
  };

  setBankFileUploadDay = (val) => {
    this.setState({ bankFileDateInDay: val });

    this.props.setBankFileDateInDay(val);
  };

  onPaydayChange = (value) => {
    this.setState({
      bankFileDateInDay: value,
    });
  };

  checkIfPaymentMandatory = () => {
    return (
      this.props.businessunitDetails &&
      this.props.businessunitDetails.paymentMandatory
    );
  };

  onModelChange = (value, index) => {
    this.onRadioChange(value, index);

    this.props.handleFormValueChange({ field: "payrollModelId", value });

    this.props.form.resetFields(["paymentDue", "paymentDueTime"]);

    this.setState(
      (prevState) => {
        prevState.payrun.paymentDueDateTime = "";
        prevState.payrun.paymentDueDateTimeStatus = "wait";
        return prevState;
      },
      () => {
        if (Number(value) === 2) {
          this.props.form.setFieldsValue({
            isInvoiceNeeded:
              this.props.form.getFieldValue("businessunit") &&
              this.props.businessUnit &&
              this.props.businessUnit.isInvoiceNeeded
                ? 1
                : 2,
            PSPUploadBank: 2,
          });

          this.uploadBankFileToggle(2, 2);
        }
      }
    );
  };

  handlePayCycleChange = () => {
    this.props.form.resetFields([
      "generatePayRunDays",
      "AmendmentsDue",
      "PayrunPreparationDue",
      "ApprovalDue",
      "paymentDue",
      "bankFileUploadDueInDay",
    ]);
  };

  dueDayChange = (fieldName, value) => {
    this.props.handleFormValueChange({ field: fieldName, value });
    const dueDatesOrder = [
      "generatePayRunDays",
      "AmendmentsDue",
      "PayrunPreparationDue",
      "ApprovalDue",
    ];
    let timeValue;
    delay(500).then(() => {
      let fieldNameIndex = _.indexOf(dueDatesOrder, fieldName);
      if (fieldNameIndex !== -1) {
        if (
          this.props.form.getFieldValue(fieldName) <=
            this.props.form.getFieldValue(dueDatesOrder[fieldNameIndex + 1]) ||
          (!this.props.form.getFieldValue(fieldName) &&
            dueDatesOrder[fieldNameIndex + 1])
        ) {
          this.props.form.resetFields([dueDatesOrder[fieldNameIndex + 1]]);
        }

        if (
          this.props.form.getFieldValue(fieldName) === "ApprovalDue" &&
          this.props.form.getFieldValue(fieldName) <
            this.props.form.getFieldValue("paymentDue")
        ) {
          this.props.form.resetFields(["paymentDue"]);
        }
      }

      const firstPayDate = this.getDateField(
        this.props.form.getFieldValue("firstPayDate")
      );
      let stepFieldName = fieldName;
      if (fieldName === "ApprovalDue") {
        stepFieldName = "approvalDueDateTime";
      }
      if (fieldName === "PayrunPreparationDue") {
        stepFieldName = "preparationDueDateTime";
      }
      if (fieldName === "AmendmentsDue") {
        stepFieldName = "ammendmentDueDateTime";
      }
      if (fieldName === "generatePayRunDays") {
        stepFieldName = "generatedDateTime";
      }
      if (fieldName === "paymentDue") {
        stepFieldName = "paymentDueDateTime";
      }

      switch (fieldName) {
        case "paymentDue":
          timeValue = this.getTimeField(
            this.props.form.getFieldValue("paymentDueTime")
          );

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${subtractDays(
              firstPayDate,

              this.props.form.getFieldValue("paymentDue")
            )} ${timeValue}`;
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        case "generatePayRunDays":
          timeValue = this.getTimeField(
            this.props.form.getFieldValue("generatePayRunTime")
          );

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${subtractDays(
              firstPayDate,

              this.props.form.getFieldValue("generatePayRunDays")
            )} ${timeValue}`;
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });

          break;
        case "AmendmentsDue":
          timeValue = this.getTimeField(
            this.props.form.getFieldValue("AmendmentsDueTime")
          );

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${subtractDays(
              firstPayDate,

              this.props.form.getFieldValue("AmendmentsDue")
            )} ${timeValue}`;
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        case "PayrunPreparationDue":
          timeValue = this.getTimeField(
            this.props.form.getFieldValue("PayrunPreparationDueTime")
          );

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${subtractDays(
              firstPayDate,

              this.props.form.getFieldValue("PayrunPreparationDue")
            )} ${timeValue}`;
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        case "ApprovalDue":
          timeValue = this.getTimeField(
            this.props.form.getFieldValue("ApprovalDueTime")
          );

          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${subtractDays(
              firstPayDate,

              this.props.form.getFieldValue("ApprovalDue")
            )} ${timeValue}`;
            prevState.payrun[`${stepFieldName}Status`] = "finish";
            return prevState;
          });
          break;
        default:
          console.log("");
      }
    });
  };

  dueTimeChange = (value, fieldName) => {
    this.props.handleFormValueChange({
      value: getConditionalResponse({
        condition: value,
        resp1: formatTime(value),
        resp2: "00:00",
      }),
      field: fieldName,
    });
    const timeValue = value ? value.format("h:mm a") : null;

    const firstPayDate = this.getDateField(
      this.props.form.getFieldValue("firstPayDate")
    );
    let dateValue;
    let stepFieldName = fieldName;
    switch (fieldName) {
      case "paymentDueTime":
        stepFieldName = "paymentDueDateTime";

        dateValue = getConditionalResponse({
          condition: this.props.form.getFieldValue("paymentDueTime"),

          resp1: subtractDays(
            firstPayDate,
            this.props.form.getFieldValue("paymentDue")
          ),
          resp2: null,
        });

        if (this.hasDateAndTimeValue(timeValue, dateValue)) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${dateValue} ${timeValue}`;
            return prevState;
          });
        }
        break;
      case "generatePayRunTime":
        stepFieldName = "generatedDateTime";

        dateValue = getConditionalResponse({
          condition: this.props.form.getFieldValue("generatePayRunDays"),

          resp1: subtractDays(
            firstPayDate,
            this.props.form.getFieldValue("generatePayRunDays")
          ),
          resp2: null,
        });

        if (this.hasDateAndTimeValue(timeValue, dateValue)) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${dateValue} ${timeValue}`;
            return prevState;
          });
        }
        break;
      case "AmendmentsDueTime":
        stepFieldName = "ammendmentDueDateTime";

        dateValue = getConditionalResponse({
          condition: this.props.form.getFieldValue("AmendmentsDue"),

          resp1: subtractDays(
            firstPayDate,
            this.props.form.getFieldValue("AmendmentsDue")
          ),
          resp2: null,
        });

        if (this.hasDateAndTimeValue(timeValue, dateValue)) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${dateValue} ${timeValue}`;
            return prevState;
          });
        }
        break;
      case "PayrunPreparationDueTime":
        stepFieldName = "preparationDueDateTime";

        dateValue = getConditionalResponse({
          condition: this.props.form.getFieldValue("PayrunPreparationDue"),

          resp1: subtractDays(
            firstPayDate,
            this.props.form.getFieldValue("PayrunPreparationDue")
          ),
          resp2: null,
        });

        if (this.hasDateAndTimeValue(timeValue, dateValue)) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${dateValue} ${timeValue}`;
            return prevState;
          });
        }

        break;
      case "ApprovalDueTime":
        stepFieldName = "approvalDueDateTime";

        dateValue = getConditionalResponse({
          condition: this.props.form.getFieldValue("ApprovalDue"),

          resp1: subtractDays(
            firstPayDate,
            this.props.form.getFieldValue("ApprovalDue")
          ),
          resp2: null,
        });

        if (this.hasDateAndTimeValue(timeValue, dateValue)) {
          this.setState((prevState) => {
            prevState.payrun[stepFieldName] = `${dateValue} ${timeValue}`;
            return prevState;
          });
        }
        break;
      default:
        console.log("unknown action");
    }
  };

  hasDateAndTimeValue = (timeValue, dateValue) => {
    if (dateValue && timeValue) return true;
    return false;
  };

  onPayCycleStartDateChange = (value) => {
    this.props.handleFormValueChange({
      value: value && value.local().format("YYYY-MM-DD"),
      field: "firstPayCycleStartDate",
    });

    this.props.form.resetFields(["firstPayDate"]);
  };

  onPayDateChange = (value) => {
    this.props.handleFormValueChange({
      value: value && value.local().format("YYYY-MM-DD"),
      field: "firstPayDate",
    });
    delay(500).then(() => {
      const firstPayDate = this.getDateField(
        this.props.form.getFieldValue("firstPayDate")
      );

      this.onPayOccurenceSelect(
        this.props.form && this.props.form.getFieldValue("payOccurence")
      );
      if (_.isUndefined(firstPayDate) || firstPayDate === null) {
        this.setState((prevState) => {
          prevState.payrun["payDate"] = firstPayDate;
          prevState.payrun["payDateStatus"] = "wait";
          return prevState;
        });
      } else {
        this.setState((prevState) => {
          prevState.payrun["payDate"] = firstPayDate;
          prevState.payrun["payDateStatus"] = "finish";
          return prevState;
        });
      }
    });
  };

  changedBusinessUnit = (value) => {
    this.props.changedBusinessUnit(value).then((resp) => {
      if (resp.status && resp.data) {
        this.props.form.setFieldsValue({
          isInvoiceNeeded: resp.data.isInvoiceNeeded ? 1 : 2,
        });

        this.props.form.resetFields(["paymentDue", "paymentDueTime"]);
      } else {
        errorDisplay(resp & resp.data & resp.data.validationErrors);
      }
    });
  };

  checkIfPaymentRequired = () => {
    return Number(this.props.form.getFieldValue("PayrollModel")) === 1;
  };

  uploadBankFileToggle = (value, index) => {
    this.props.handleFormValueChange({
      field: "bankFileUploadDueInDay",
      value: value && value === 1 ? true : false,
    });

    this.onRadioChange(value, index);

    this.props.form.resetFields(["BankfileUploadDue", "BankfileUploadDueTime"]);
  };

  changedDaysBeforeAfter = () => {
    this.props.form.resetFields(["paymentDue"]);
  };

  clearSalary = () => {
    this.props.form.resetFields(["baseSalary"]);
  };

  getTimeField = (field) => {
    return field ? field.format("h:mm a") : "";
  };

  getDateField = (field) => {
    return field ? field.format("MM/DD/YYYY") : null;
  };

  firstPayDateDisabledDate = (current) => {
    const firstPayCycleStartDate = this.props.form.getFieldValue(
      "firstPayCycleStartDate"
    );
    // add one day to firstPayCycleStartDate
    const payCycleStartDatePlusOneDay = moment(firstPayCycleStartDate);
    payCycleStartDatePlusOneDay.add(1, "days");

    if (payCycleStartDatePlusOneDay > current) {
      return true;
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const { handleFormValueChange } = this.props;
    const formLayout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 11 },
    };
    const basePayLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };
    const formLayoutModel = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };
    const formLayoutPayment = {
      labelCol: { span: 4 },

      wrapperCol: {
        span: getConditionalResponse({
          condition: this.checkIfPaymentMandatory(),
          resp2: 20,
          resp1: 11,
        }),
      },
    };

    const { fetching, companyList, businessunitList } = this.state;

    const PEOSelected = (
      <Row gutter={8}>
        <Col span={11}>
          <FormItem {...formLayout} label="Payment Due:">
            {this.props.form.getFieldDecorator("paymentDue", {
              rules: [
                {
                  required: true,
                  message: "You need to select payment due day",
                },
              ],
            })(
              <Select
                placeholder="Days"
                disabled={!this.state.editMode}
                onChange={(e) => this.dueDayChange("paymentDue", e)}
              >
                {_.range(
                  1,

                  getConditionalResponse({
                    condition:
                      Number(
                        this.props.form.getFieldValue("daysBeforeAfter")
                      ) === 2,
                    resp1: 31,

                    resp2:
                      Number(this.props.form.getFieldValue("ApprovalDue")) + 1,
                  })
                ).map((day, index) => (
                  <Option key={index} value={day}>
                    {day}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col
          span={getConditionalResponse({
            condition: !this.checkIfPaymentMandatory(),
            resp1: 5,
            resp2: 11,
          })}
        >
          <FormItem {...formLayoutPayment} label="">
            {this.props.form.getFieldDecorator("paymentDueTime", {
              rules: [
                {
                  required: true,
                  message: "You need to select payment due time",
                },
              ],
              initialValue: moment("00:00", "HH:mm"),
            })(
              <TimePicker
                allowClear={false}
                minuteStep={5}
                use12Hours
                format="h:mm a"
                onChange={(value) =>
                  this.dueTimeChange(value, "paymentDueTime")
                }
                disabled={!this.state.editMode}
                className="w-full "
                inputReadOnly
                showNow={false}
              />
            )}
          </FormItem>
        </Col>
        {!this.checkIfPaymentMandatory() && (
          <Col span={7}>
            <FormItem {...formLayout} label="">
              {this.props.form.getFieldDecorator("daysBeforeAfter", {
                rules: [
                  {
                    required: true,
                    message: "You need to select before or after",
                  },
                ],
                initialValue: 1,
              })(
                <Select
                  placeholder=""
                  disabled={!this.state.editMode}
                  className="ml-9"
                  onChange={this.changedDaysBeforeAfter}
                >
                  <Option key={1} value={1}>
                    Before
                  </Option>
                  <Option key={2} value={2}>
                    After
                  </Option>
                </Select>
              )}
            </FormItem>
          </Col>
        )}
      </Row>
    );
    const OutsourceSelected = (
      <div>
        <Row gutter={8}>
          <Col span={11} className="no-shadow">
            <FormItem {...formLayout} label="Generate Invoice:">
              {this.props.form.getFieldDecorator("isInvoiceNeeded", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <RadioGroup
                  name="payrollmodel"
                  onChange={(e) =>
                    handleFormValueChange({
                      value: e.target.value,
                      field: "payrollModelId",
                    })
                  }
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={11} className="no-shadow">
            <FormItem {...formLayout} label="PSP to Upload Bank File:">
              {this.props.form.getFieldDecorator("PSPUploadBank", {
                rules: [
                  {
                    required: false,
                  },
                ],
                initialValue: 1,
              })(
                <RadioGroup
                  name="payrollmodel"
                  onChange={(e) => this.uploadBankFileToggle(e.target.value, 2)}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={11}>
            <FormItem {...formLayout} label="Bank File Upload Due:">
              {this.props.form.getFieldDecorator("BankfileUploadDue", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        Number(
                          this.props.form.getFieldValue("PayrollModel")
                        ) !== 2 ||
                        Number(
                          this.props.form.getFieldValue("PSPUploadBank")
                        ) !== 1,
                      resp1: false,
                      resp2: true,
                    }),
                  },
                ],
              })(
                <Select
                  placeholder="Days before"
                  onChange={(e) =>
                    handleFormValueChange({
                      value: e,
                      field: "bankFileUploadDueInDay",
                    })
                  }
                  disabled={getConditionalResponse({
                    condition:
                      this.props.form.getFieldValue("PSPUploadBank") &&
                      Number(
                        this.props.form.getFieldValue("PSPUploadBank") !== 1
                      ) &&
                      2 < this.state.disableIndex,
                    resp1: true,
                    resp2: false,
                  })}
                >
                  {_.range(1, this.state.payDate + 1).map((day, index) => (
                    <Option key={index} value={day}>
                      {day}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="">
              {this.props.form.getFieldDecorator("BankfileUploadDueTime", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        Number(
                          this.props.form.getFieldValue("PayrollModel")
                        ) !== 2 ||
                        Number(
                          this.props.form.getFieldValue("PSPUploadBank")
                        ) !== 1,
                      resp1: false,
                      resp2: true,
                    }),
                  },
                ],
                initialValue: moment("00:00", "HH:mm"),
              })(
                <TimePicker
                  allowClear={false}
                  minuteStep={5}
                  use12Hours
                  format="h:mm a"
                  disabled={getConditionalResponse({
                    condition:
                      2 < this.state.disableIndex &&
                      Number(this.props.form.getFieldValue("PSPUploadBank")) !==
                        1 &&
                      this.props.form.getFieldValue("PSPUploadBank"),
                    resp1: true,
                    resp2: false,
                  })}
                  className="w-full "
                  onChange={(e) =>
                    handleFormValueChange({
                      value: formatTime(e),
                      field: "bankFileUploadDueTime",
                    })
                  }
                  inputReadOnly
                  showNow={false}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={22} className="no-shadow">
            <FormItem
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label="Bank File to be Dated:"
              className="w-full "
            >
              {this.props.form.getFieldDecorator("BankfileDated", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        this.props.form.getFieldValue("PayrollModel") &&
                        Number(
                          this.props.form.getFieldValue("PayrollModel")
                        ) === 2,
                      resp1: true,
                      resp2: false,
                    }),
                  },
                ],
                initialValue: 1,
              })(
                <RadioGroup
                  name="BankfileDated"
                  disabled={getConditionalResponse({
                    condition:
                      2 < this.state.disableIndex &&
                      this.props.form.getFieldValue("PSPUploadBank") &&
                      Number(
                        this.props.form.getFieldValue("PSPUploadBank") !== 1
                      ),
                    resp1: true,
                    resp2: false,
                  })}
                  onChange={(e) => this.onRadioChange(e.target.value, 1)}
                  className="w-full "
                >
                  <Radio value={1}>Pay Day</Radio>
                  <Radio value={2}>
                    <div className="flex min-w-[245px]">
                      <span className="flex-1-1-55">Different Day</span>
                      <Select
                        id="editBankFileDateInDay"
                        placeholder="Day"
                        disabled={bankFileDifferentDayDisabled({
                          bankFileDated:
                            this.props.form.getFieldValue("BankfileDated"),

                          pspUploadBank:
                            this.props.form.getFieldValue("PSPUploadBank"),
                        })}
                        onChange={(val) => this.setBankFileUploadDay(val)}
                        className="w-[30%] flex-1-1-45"
                        defaultValue={getConditionalResponse({
                          condition:
                            this.props.payschedule &&
                            this.props.payschedule.bankFileDateInDays,

                          resp1: this.props.payschedule.bankFileDateInDays,
                          resp2: null,
                        })}
                      >
                        {_.range(1, 31).map((day, index) => (
                          <Option key={index} value={day}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={11}>
            <FormItem {...formLayout} label="Payment Due:">
              {this.props.form.getFieldDecorator("paymentDue", {
                rules: [
                  {
                    required: this.checkIfPaymentRequired(),
                    message: "You need to select payment due day",
                  },
                ],
              })(
                <Select
                  placeholder="Days before"
                  disabled={!this.state.editMode}
                  onChange={() => this.dueDayChange("paymentDue")}
                >
                  {_.range(
                    1,
                    Number(this.props.form.getFieldValue("ApprovalDue")) + 1
                  ).map((day, index) => (
                    <Option key={index} value={day}>
                      {day}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="">
              {this.props.form.getFieldDecorator("paymentDueTime", {
                rules: [
                  {
                    required: this.checkIfPaymentRequired(),
                    message: "You need to select payment due time",
                  },
                ],
                initialValue: moment("00:00", "HH:mm"),
              })(
                <TimePicker
                  allowClear={false}
                  minuteStep={5}
                  use12Hours
                  format="h:mm a"
                  onChange={(value) =>
                    this.dueTimeChange(value, "paymentDueTime")
                  }
                  disabled={!this.state.editMode}
                  className="w-full "
                  inputReadOnly
                  showNow={false}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </div>
    );

    return (
      <Card className="mb-5">
        <Row gutter={8} className="mt-8">
          <Col span={11}>
            <FormItem {...formLayout} label="Company:">
              {getFieldDecorator("company", {
                rules: [
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select company"
                  notFoundContent={getConditionalResponse({
                    condition: fetching,
                    resp1: <Spin size="small" />,
                    resp2: null,
                  })}
                  optionFilterProp={"name"}
                  onChange={this.handleChangeCompany}
                  disabled={this.state.disableCompany}
                  className="w-full "
                >
                  {mustBeArray(companyList).map((d) => (
                    <Option key={d.id} value={d.id} name={d.name}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="Business Unit:">
              {getFieldDecorator("businessunit", {
                rules: [
                  {
                    required: true,
                    message: "Please select a business unit",
                  },
                ],
              })(
                <Select
                  placeholder="Select business unit"
                  filterOption={false}
                  disabled={this.props.disabled}
                  onChange={(value) => this.changedBusinessUnit(value)}
                  className="w-full "
                >
                  {mustBeArray(businessunitList).map((d) => (
                    <Option key={d.id} value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={11}>
            <FormItem {...formLayout} label="Pay Schedule Name:">
              {getFieldDecorator("payScheduleName", {
                rules: [
                  {
                    required: true,
                    message: "Please enter payschedule name",
                  },
                ],
              })(<Input placeholder="Payschedule name" />)}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="Pay Frequency:">
              {getFieldDecorator("payOccurence", {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: 1,
              })(
                <Select
                  placeholder="Day"
                  disabled={!this.state.editMode}
                  onChange={(e) => this.onPayOccurenceSelect(e)}
                  className="w-full "
                >
                  <Option value={1}>Monthly</Option>
                  <Option value={2}>Fortnightly</Option>
                  <Option value={3}>Weekly</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={11}>
            <FormItem {...formLayout} label="First Pay Cycle Start Date:">
              {getFieldDecorator("firstPayCycleStartDate", {
                rules: [
                  {
                    required: true,
                    message: "You need to select pay date",
                  },
                ],
              })(
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={(e) => this.onPayCycleStartDateChange(e)}
                  allowClear={false}
                  disabled={!this.state.editMode}
                  placeholder="Select Date"
                  className="w-full "
                  showToday={false}
                  inputReadOnly
                />
              )}
            </FormItem>
          </Col>
          <Col span={11}>
            <FormItem {...formLayout} label="Reference:">
              {getFieldDecorator("reference", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  placeholder="Enter reference"
                  onChange={(e) =>
                    handleFormValueChange({
                      value: e.currentTarget.value,
                      field: "reference",
                    })
                  }
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={11}>
            <FormItem {...formLayout} label="First Pay Date:">
              {getFieldDecorator("firstPayDate", {
                rules: [
                  {
                    required: true,
                    message: "You need to select pay date",
                  },
                ],
              })(
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={this.firstPayDateDisabledDate}
                  onChange={(e) => this.onPayDateChange(e)}
                  allowClear={false}
                  disabled={
                    !this.state.editMode ||
                    !this.props.form.getFieldValue("firstPayCycleStartDate")
                  }
                  placeholder="Select Date"
                  className="w-full "
                  showToday={false}
                  inputReadOnly
                />
              )}
            </FormItem>
          </Col>
        </Row>

        {getConditionalResponse({
          condition: this.props.form.getFieldValue("firstPayDate"),
          resp1: this.props.form.getFieldValue("firstPayDate"),
          resp2: this.displayForm(),
        }) ? (
          <React.Fragment>
            <Row gutter={8}>
              <Col span={11}>
                <FormItem
                  {...formLayout}
                  label={
                    <span>
                      Generate Pay Run:{" "}
                      <Tooltip title="This will determine the number of days before the pay date this event will occur.">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("generatePayRunDays", {
                    rules: [
                      {
                        required: true,
                        message: "You need to select pay due day",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Days before"
                      disabled={!this.state.editMode}
                      onChange={() => this.dueDayChange("generatePayRunDays")}
                    >
                      {_.range(1, this.state.payDate + 1).map((day, index) => (
                        <Option key={index} value={day}>
                          {day}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("generatePayRunTime", {
                    rules: [
                      {
                        required: true,
                        message: "You need to select pay cycle due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      minuteStep={5}
                      onChange={(value) =>
                        this.dueTimeChange(value, "generatePayRunTime")
                      }
                      disabled={!this.state.editMode}
                      placeholder="Select Time"
                      className="w-full "
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={11}>
                <FormItem {...formLayout} label="Amendments Due:">
                  {getFieldDecorator("AmendmentsDue", {
                    rules: [
                      {
                        required: true,
                        message: "You need to select amendment due day",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Days before"
                      disabled={!this.state.editMode}
                      onChange={() => this.dueDayChange("AmendmentsDue")}
                    >
                      {_.range(
                        1,
                        Number(getFieldValue("generatePayRunDays")) + 1
                      ).map((day, index) => (
                        <Option key={index} value={day}>
                          {day}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("AmendmentsDueTime", {
                    rules: [
                      {
                        required: true,
                        message: "You need to select amendment due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      minuteStep={5}
                      onChange={(value) =>
                        this.dueTimeChange(value, "AmendmentsDueTime")
                      }
                      disabled={!this.state.editMode}
                      className="w-full "
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={11}>
                <FormItem {...formLayout} label="Pay Run Preparation Due:">
                  {getFieldDecorator("PayrunPreparationDue", {
                    rules: [
                      {
                        required: true,
                        message:
                          "You need to select pay run preparation due day",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Days before"
                      disabled={!this.state.editMode}
                      onChange={() => this.dueDayChange("PayrunPreparationDue")}
                    >
                      {_.range(
                        1,
                        Number(getFieldValue("AmendmentsDue")) + 1
                      ).map((day, index) => (
                        <Option key={index} value={day}>
                          {day}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("PayrunPreparationDueTime", {
                    rules: [
                      {
                        required: true,
                        message:
                          "You need to select pay run preparation due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      allowClear={false}
                      use12Hours
                      format="h:mm a"
                      minuteStep={5}
                      onChange={(value) =>
                        this.dueTimeChange(value, "PayrunPreparationDueTime")
                      }
                      disabled={!this.state.editMode}
                      className="w-full "
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={11}>
                <FormItem {...formLayout} label="Approval Due:">
                  {getFieldDecorator("ApprovalDue", {
                    rules: [
                      {
                        required: true,
                        message: "You need to select approval due day",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Days before"
                      disabled={!this.state.editMode}
                      onChange={() => this.dueDayChange("ApprovalDue")}
                    >
                      {_.range(
                        1,
                        Number(getFieldValue("PayrunPreparationDue")) + 1
                      ).map((day, index) => (
                        <Option key={index} value={day}>
                          {day}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={11}>
                <FormItem {...formLayout} label="">
                  {getFieldDecorator("ApprovalDueTime", {
                    rules: [
                      {
                        required: true,
                        message: "You need to select approval due time",
                      },
                    ],
                    initialValue: moment("00:00", "HH:mm"),
                  })(
                    <TimePicker
                      allowClear={false}
                      use12Hours
                      minuteStep={5}
                      format="h:mm a"
                      onChange={(value) =>
                        this.dueTimeChange(value, "ApprovalDueTime")
                      }
                      disabled={!this.state.editMode}
                      className="w-full "
                      inputReadOnly
                      showNow={false}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </React.Fragment>
        ) : null}

        <Row gutter={8}>
          <Col span={11} className="no-shadow">
            <FormItem
              className="pb-0"
              {...formLayoutModel}
              label="Business Model:"
            >
              {getFieldDecorator("PayrollModel", {
                rules: [
                  {
                    required: true,
                    message: "You need to select business model",
                  },
                ],
                initialValue: 1,
              })(
                <RadioGroup
                  name="payrollmodel"
                  disabled={!this.state.editMode}
                  onChange={(e) => this.onModelChange(e.target.value, 3)}
                >
                  <Radio value={1}>PEO</Radio>
                  <Radio value={2}>Outsource</Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Col>
        </Row>

        {getConditionalResponse({
          condition: this.props.form.getFieldValue("PayrollModel") === 1,
          resp1: PEOSelected,
          resp2: OutsourceSelected,
        })}
        {this.props.editMode && (
          <Row gutter={DEFAULT_GUTTER}>
            <Col span={22}>
              <BaseSalary
                baseSalary={this.props.basePayList}
                getFieldDecorator={this.props.form.getFieldDecorator}
                form={this.props.form}
                formLayout={{ ...basePayLayout }}
                loading={this.props.basePayLoading}
                required={true}
                handleFormValueChange={() => {
                  console.log("");
                }}
              />
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                onClick={this.clearSalary}
                className="rounded-[20px] px-6 -ml-52"
              >
                Clear
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    );
  }
}

export default PayScheduleForm;
