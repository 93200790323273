import { CustomAxiosOptions } from './custom-axios';
import { TOKEN_STORAGE_KEY } from './custom-axios.constants';

export const handleTokenFromConfig = (config?: CustomAxiosOptions) => {
  if (!config) {
    return getTokenFromStorage('session');
  }

  const { authToken, tokenFromStorage } = config;
  return authToken || getTokenFromStorage(tokenFromStorage || 'session');
};

/**
 * Get the auth token from storage.
 * For now, it's allowing to get from local or session storage.
 * Ideally, it should only be from session storage. But leaving this for compatibility.
 *
 * Use this function if the app is not implementing the @xemplo/auth-provider
 */
export const getTokenFromStorage = (tokenFromStorage: 'local' | 'session') => {
  const result =
    tokenFromStorage === 'local'
      ? localStorage.getItem(TOKEN_STORAGE_KEY)
      : sessionStorage.getItem(TOKEN_STORAGE_KEY);

  return result || '';
};

/**
 * The auth token should never be stored into local storage.
 * Hence, this helper will store the auth token into session storage.
 *
 * Use this function if the app is not implementing the @xemplo/auth-provider
 */
export const setTokenToStorage = (token: string) => {
  sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
};

/**
 * The auth token should never be stored into local storage.
 * Hence, this helper will store the auth token into session storage.
 *
 * Use this function if the app is not implementing the @xemplo/auth-provider
 */
export const removeTokenFromStorage = () => {
  sessionStorage.removeItem(TOKEN_STORAGE_KEY);
};
