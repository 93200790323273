import React from "react";
import { Badge, Card } from "antd";
import DoubleVerticalEllipsisIcon from "../../../Common/icons/DoubleVerticalEllipsisIcon";
import {
  getFullName,
  isUserPrimaryPayrollManager,
} from "../../../libs/utilities";
import { Avatar, AvatarSize } from "@xemplo/avatar";

interface PayrollManagerInfoCardProps {
  user: any;
}

const PayrollManagerInfoCard = (props: PayrollManagerInfoCardProps) => {
  const { user } = props;
  return (
    <Card className="rounded-[6px] pt-4 pl-0 bg-default card-body-overflow-hidden">
      {isUserPrimaryPayrollManager({ user }) ? (
        <Badge count="PRIMARY" className="bg-badge-success float-right" />
      ) : (
        <React.Fragment />
      )}
      <div className="flex items-center w-full oveflow-hidden">
        <DoubleVerticalEllipsisIcon />
        <div
          className={`${isUserPrimaryPayrollManager({ user }) ? "-mt-4" : ""}`}
        >
          <div className="flex flex-wrap">
            <Avatar
              user={{ ...user, url: user?.profilePhoto }}
              size={AvatarSize.l}
            />
            <div className="bold text-base ml-2">
              {getFullName({ name: user })}
            </div>
          </div>
          <div className={`ml-16 pl-2 -mt-6`}>
            {!!user?.mobilePhone && (
              <div className="text-base">{user.mobilePhone}</div>
            )}
            <div className="text-base">{user?.email}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PayrollManagerInfoCard;
