//@ts-nocheck
import React from "react";
import { Card, Tooltip, Typography, Avatar } from "antd";
import { ContractIcon } from "../../../../Common/customicons";
import TableActionComponent from "../../common/tableActions";
import { currencyFormater, getUserArea } from "../../../../libs/utilities";
import userRoles from "../../../../constants/userRoles";

const { Title } = Typography;

const InvoiceDisplayCard = ({
  handleAction,
  content,
  claims,
  editComponent,
  viewComponent,
  getGridClassName,
  dashboardView,
}: any) => {
  return (
    <Card
      className={`card hand card-prop ${
        getGridClassName ? getGridClassName(content) : ""
      }`}
      bordered={false}
      onClick={(e) => handleAction({ data: content, action: "view" })}
    >
      <div className="position-relative py-3 px-0">
        <div className={"mb-2.5 px-[50px] position-relative"}>
          <div className="position-relative">
            <Tooltip placement="top" title={content?.invoiceNumber}>
              <Title
                ellipsis
                className="card-title"
                level={3}
              >{`${content?.invoiceNumber}`}</Title>
            </Tooltip>
            {[userRoles.PSPArea, userRoles.SystemAdminArea].includes(
              getUserArea()
            ) && (
              <React.Fragment>
                <Title ellipsis className="card-subtitle" level={4}>
                  {`Company: ${content?.companyName}`}
                </Title>
                <Title ellipsis className="card-subtitle" level={4}>
                  {`Business Unit: ${content?.businessUnitName}`}
                </Title>
              </React.Fragment>
            )}
          </div>
          <Avatar
            className="position-absolute-top position-absolute-top-left"
            size={36}
          >
            <ContractIcon className="text-lg" />
          </Avatar>
        </div>
        <div
          className={`flex justify-between mb-1 text-xs ${
            [userRoles.PSPArea, userRoles.SystemAdminArea].includes(
              getUserArea()
            )
              ? ""
              : "mt-6"
          }`}
        >
          <span>{`Total Tax Fees: ${currencyFormater(
            content?.totalTaxFees
          )}`}</span>
        </div>
        <div className="flex justify-between mb-1 text-xs">
          <span>{`Total Fees: ${currencyFormater(content?.totalFees)}`}</span>
        </div>
        <div className="flex justify-between mb-1 text-xs">
          <span>{`Total Amount: ${currencyFormater(
            content?.totalAmount
          )}`}</span>
        </div>
      </div>
      {!dashboardView && (
        <TableActionComponent
          classname="card-item mt-4 mr-4 position-absolute-top position-absolute-top-right"
          buttonClassName="card-prop animated fadeIn"
          claims={claims}
          handleAction={(options: any) =>
            handleAction({ ...options, data: content, record: content })
          }
          editComponentName={editComponent}
          viewComponentName={viewComponent}
          showDownloadPdf={content?.pdfDownloadUrl ? true : false}
          showDownloadSummary={content?.summaryDownloadURL ? true : false}
        />
      )}
    </Card>
  );
};

export default InvoiceDisplayCard;
