import { AxiosResponse } from 'axios';

import {
  BaseUser,
  ExpedoUserProfile,
  PagedResult,
  PersonaRole,
  Result,
  RoleArea,
  UserPersona,
  ValidationErrors,
} from '@xemplo/gp-types';

export class ResponseHandler {
  // TODO: This stuff is incomplete! Need to propertly handle http status code semantics
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/200
  // But need more information as it seems the gp api is not strictly following the http status code semantics
  static isValidStatus(status: number): boolean;
  static isValidStatus(input: { status: number }): boolean;
  static isValidStatus(arg: number | { status: number }): boolean {
    const status = typeof arg === 'number' ? arg : arg.status;
    return status >= 200 && status < 300;
  }
}

export class ApiError extends Error {
  constructor(public response: AxiosResponse, message?: string) {
    super(message);
  }
}

export class ApiErrorWithValidation extends ApiError {
  constructor(response: AxiosResponse, public validationErrors: ValidationErrors) {
    const baseMessage = 'Validation errors: \n';
    const aggregatedErrors = validationErrors.errors
      .map((error) => error.internalErrorMessageToDisplay)
      .join('\n');

    super(response, `${baseMessage}${aggregatedErrors}`);
  }
}

/**
 * TODO: This will need a bit of figuring out as well
 *
 * Currently, GP simply gets the message and pushes to AntD message component
 * Need to dig a bit more about this.
 */
export const BaseErrorHandler = (error: Error) => {
  if (error instanceof ApiError && error.response.status === 401) {
    console.error(error.message);
    // TODO: Need to look into bubbling up the error to the top level
  }

  console.error(error);
  // Need to check for other behaviours
  throw error;
};

export const isUserSA = (user: BaseUser | ExpedoUserProfile) =>
  user.username === PersonaRole.SystemAdmin;
export const areaMap = new Map<string, string>()
  .set('CU', RoleArea.CompanyArea)
  .set('PS', RoleArea.PSPArea)
  .set('SA', RoleArea.SystemAdminArea);

/** We should really get this to be part of the DB data */
export const getRoleArea = (
  user: BaseUser | ExpedoUserProfile,
  persona?: UserPersona
) => {
  const role = user.selectedRole || persona;
  const area = role?.area ? areaMap.get(role.area) : undefined;
  return area ? area : isUserSA(user) ? RoleArea.SystemAdminArea : '';
};

/**
 * This block performs a group by for the user roles and determine whether
 * there is a selected role without having to iterate through the array
 * of userPersona again to get the same information. Hopefully we can
 * get rid of this transformation in the future.
 *
 * acc is the accumulated value over each iteration
 * curr is the current item within the iteration.
 */
export const mapResponseToUserProfile = (resp: AxiosResponse<ExpedoUserProfile>) => {
  if (ResponseHandler.isValidStatus(resp.status)) {
    let user = { ...resp.data };
    if (user?.userPersona) {
      user = {
        ...user,
        ...user.userPersona.reduce((acc, curr) => {
          // Since the userRoles can be an array of roles, then the first thing we need to check is whether
          // theres's and existing object with the key of the tenant name. If there is, then we need to
          // append the current role to the existing array. If not, then we need to create a new array
          const existingRolesForTenant = acc.userRoles?.[curr.tenant.name] ?? [];

          // This is the group by operation
          acc = {
            ...acc,
            userRoles: {
              ...acc.userRoles,
              // This is the append operation for the group by where we append the current role to the existing array
              [curr.tenant.name]: [...existingRolesForTenant, curr],
            },
          };

          // This is the check for the selected role
          if (curr.default || curr.selected) {
            acc = { ...acc, selectedRole: curr };
          }

          // Then we finally return the accumulated value
          return acc;
        }, {} as Partial<ExpedoUserProfile>),
      };

      if (user.selectedRole) {
        const area = getRoleArea(user);
        user = { ...user, area };
      }

      return user;
    }
  }
  throw new ApiError(resp, resp.statusText);
};

export const mapWrappedResponse = <T>(
  resp: AxiosResponse<Result<T> | PagedResult<T>>
) => {
  if (ResponseHandler.isValidStatus(resp.status)) {
    return resp.data.result;
  }
  throw new ApiErrorWithValidation(resp, resp.data.validationErrors2);
};

export const mapSimpleResponse = <T>(resp: AxiosResponse<T>) => {
  if (ResponseHandler.isValidStatus(resp.status)) {
    return resp.data;
  }
  throw new ApiError(resp, resp.statusText);
};
