import { useCallback, useEffect } from 'react';

import { SidebarItemsProps } from './sidebar-items.types';

/**
 * This hook is responsible for controlling a situation where
 * the viewport is small (< 1024px) and the sidebar is expanded.
 *
 * Without this, the sidebar would remain expanded if the user
 * resizes the window to a larger size (> 1024px).
 *
 * We keep track of the window width to avoid unnecessary re-renders.
 */
export function useResponsiveCollapse(props: SidebarItemsProps) {
  const { isResponsiveExpanded, setIsResponsiveExpanded } = props;

  const handleResize = useCallback(() => {
    if (!isResponsiveExpanded) return;
    setIsResponsiveExpanded(false);
  }, [isResponsiveExpanded, setIsResponsiveExpanded]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (!body) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const bodyWidth = entries[0].contentRect.width;
      if (bodyWidth > 1023) handleResize();
    });

    resizeObserver.observe(body);
    return () => resizeObserver.disconnect();
  }, [handleResize, setIsResponsiveExpanded]);
}
