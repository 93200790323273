//@ts-nocheck
import React from "react";
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  getCompany,
  listCompanyUsers,
  listCompanyBusinessUnits,
  saveCompany,
  updateCompany,
  listCompanies,
  registerCompanyUsers,
  handleCompanyUserActions,
  companyLookup,
  handleEmailTemplatesActions,
} from "../actions/companies";
import {
  getPayrollProvider,
  payrollProviderLookup,
} from "../actions/payrollprovider";
import { listPSPUsers } from "../actions/payrollprocessor";
import { getPayschedule } from "../actions/payschedule";
import {
  saveBusinessUnit,
  listBusinessUnits,
  listBillingServiceProviders,
  listBillingAccounts,
  businessunitHandler,
} from "../../Organization/actions/businessunits";
import {
  listUserRoles,
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { populateStates } from "../actions/lookup";
import { earningLinesLookup, listAmendmentTypes } from "../actions/payrun";
import { findAuthorizationClaims } from "../../libs/utilities";
import { COMPANY_BREAD_CRUMB_CONTAINER } from "../../constants";
import { withRouter } from "../../hooks";

class CompanyDetail extends SwiftContainer {
  signal: any;

  componentDidMount() {
    this.props.updateHeader({
      header: {
        routeComponentName: "companyBreadCrumb",
        routesContainer: findAuthorizationClaims({
          claims: this.state.pageClaims?.components,
          name: COMPANY_BREAD_CRUMB_CONTAINER,
        }),
      },
    });
  }

  render() {
    const { Layout } = this.props;
    const { pageClaims, loading } = this.state;
    return (
      <Layout
        company={this.props.company}
        registerCompanyUsers={this.props.registerCompanyUsers}
        handleCompanyUserActions={this.props.handleCompanyUserActions}
        companyUserDetails={this.props.companyUserDetails}
        companyUsersCount={this.props.companyUsersCount}
        companyUsersList={this.props.companyUsersList}
        saveBusinessUnit={this.props.saveBusinessUnit}
        // listBusinessUnits={this.props.listBusinessUnits}
        listBillingServiceProviders={this.props.listBillingServiceProviders}
        listBillingAccounts={this.props.listBillingAccounts}
        payscheduleCount={this.props.payscheduleCount}
        userMode={this.props.userMode}
        loading={loading}
        payschedules={this.props.payschedules}
        match={{ params: this.props.router.params }}
        saveCompany={(model: any, data: any) =>
          this.executeAction(this.props.saveCompany, data)
        }
        listCompanyUsers={(data: any) =>
          this.executeAction(this.props.listCompanyUsers, data)
        }
        listBusinessUnits={(data: any) =>
          this.executeAction(this.props.listCompanyBusinessUnits, data)
        }
        getCompany={(data: any) =>
          this.executeAction(this.props.getCompany, data)
        }
        updateCompany={(data: any) =>
          this.executeAction(this.props.updateCompany, data)
        }
        getPayrollProvider={this.props.getPayrollProvider}
        payrollProviderLookup={this.props.payrollProviderLookup}
        listPayschedules={this.props.getPayschedule}
        listCompanies={this.props.listCompanies}
        companyLookup={this.props.companyLookup}
        signal={this.signal}
        listUserRoles={this.props.listUserRoles}
        handleEmailTemplatesActions={this.props.handleEmailTemplatesActions}
        pageClaims={pageClaims}
        member={this.props.member}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  company: state.company.detail || {},
  companyUserDetails: state.company.userDetails || {},
  companyUsersCount: state.company.users ? state.company.users.count : 0,
  companyUsersList: state.company.users ? state.company.users.list : [],
  payschedules: state.payschedule.list || [],
  payscheduleCount: state.payschedule.count || 0,
  userMode: "",
  member: state.member,
});

const mapDispatchToProps = {
  getCompany,
  listCompanyUsers,
  listCompanyBusinessUnits,
  saveCompany,
  updateCompany,
  payrollProviderLookup,
  getPayrollProvider,
  getPayschedule,
  listCompanies,
  saveBusinessUnit,
  listBusinessUnits,
  listBillingServiceProviders,
  listBillingAccounts,
  registerCompanyUsers,
  handleCompanyUserActions,
  companyLookup,
  listUserRoles,
  handleEmailTemplatesActions,
  listPSPUsers,
  populateStates,
  earningLinesLookup,
  listAmendmentTypes,
  updateHeader,
  updateAuthorizationClaim,
  businessunitHandler,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyDetail)
);
