import { useCallback, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { AriaButtonProps, useButton } from '@react-aria/button';
import { useSearchField } from '@react-aria/searchfield';
import { useSearchFieldState } from '@react-stately/searchfield';
import { PressEvent } from '@react-types/shared';
import classnames from 'classnames';

import { DawnCross16, DawnSearch24 } from '@xemplo/icons';
import { InputFieldSize } from '@xemplo/input-utils';

import * as S from './search-field-v2.styles';
import { SearchFieldV2Props } from './search-field-v2.types';

export const SearchFieldV2TestId = {
  container: (testId?: string) => `search-field-container-${testId}`,
  input: (testId?: string) => `search-field-input-${testId}`,
  clearButton: (testId?: string) => `search-field-clear-button-${testId}`,
};

/** SearchField size to iconSize (px) mapping */
const SearchIconSizeMap = {
  [InputFieldSize.Standard]: 24,
  [InputFieldSize.Small]: 16,
  [InputFieldSize.Medium]: 20,
};

export function SearchFieldV2(props: Readonly<SearchFieldV2Props>) {
  const {
    clearButton = true,
    width = '272px',
    inputSize = InputFieldSize.Standard,
    isDisabled,
    isReadOnly,
    className,
    testId,
    name,
    leadingIcon = (
      <DawnSearch24
        height={SearchIconSizeMap[inputSize]}
        width={SearchIconSizeMap[inputSize]}
      />
    ),
    handleClear,
    handlePressEnter,
  } = props;

  const state = useSearchFieldState(props);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { register, watch } = useFormContext();

  const { inputProps, clearButtonProps } = useSearchField(
    { ...props, 'aria-label': name },
    state,
    inputRef
  );
  const inputValue = watch(name);

  const reactHookFormProps = register(name, {
    disabled: isDisabled,
    onChange: inputProps.onChange,
  });

  // Note: this is a temp fix to complete the functionality of search bar
  // I will extract this component to not use react-aria as a permanent solution
  const handlePressClear = useCallback(
    (e: PressEvent) => {
      clearButtonProps?.onPress?.(e);
      handleClear?.(e);
    },
    [clearButtonProps?.onPress, handleClear]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handlePressEnter?.();
      }
      inputProps?.onKeyDown?.(e);
    },
    [handlePressClear, inputProps?.onKeyDown]
  );

  /** Trailing icon is converted to a clear field button */
  const trailingIcon = useMemo(() => {
    if (!clearButton || !inputValue) return null;
    return (
      <ClearButton
        {...clearButtonProps}
        onPress={handlePressClear}
        data-testid={SearchFieldV2TestId.clearButton(testId)}
      />
    );
  }, [clearButton, inputValue, clearButtonProps, testId]);

  return (
    <S.Container
      data-testid={SearchFieldV2TestId.container(testId)}
      $inputSize={inputSize}
      $width={width}
      className={classnames(
        className,
        { 'input-disabled': isDisabled },
        { 'input-readonly': isReadOnly }
      )}
      onClick={() => inputRef.current?.focus()}
    >
      {leadingIcon}
      <S.Input
        {...inputProps}
        {...reactHookFormProps}
        ref={(e) => {
          reactHookFormProps.ref(e);
          inputRef.current = e;
        }}
        onChange={(e) => {
          reactHookFormProps.onChange(e);
          inputProps.onChange?.(e);
        }}
        data-testid={SearchFieldV2TestId.input(testId)}
        onKeyDown={handleKeyDown}
      />
      {trailingIcon}
    </S.Container>
  );
}

/** Clear Search field button component */
function ClearButton(props: Readonly<AriaButtonProps<'button'>>) {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <S.ClearButton {...buttonProps} ref={ref}>
      <DawnCross16 width={16} height={16} />
    </S.ClearButton>
  );
}
