import React from "react";
import { Card } from "antd";
import { onlyDateFormater } from "../../../libs";
import { If } from "../../../Common/ui";

const PayDates = (props: any) => {
  const { data } = props;
  return (
    data && (
      <Card className="mb-4 card">
        <div className="mb-0 flex justify-between">
          {
            <If
              condition={data.firstPayCycleStartDate}
              then={
                <div>
                  <span className="text-lg">
                    Pay Cycle Start Date <br />
                    {onlyDateFormater(data.firstPayCycleStartDate)}
                  </span>
                </div>
              }
            />
          }

          <div>
            <span className="color-danger text-lg">
              Pay Day <br />
              {onlyDateFormater(data.firstPayDate)}
            </span>
          </div>
          <div>
            <span className="text-lg">
              Pay Occurence
              <br /> {data.payOccurence}
            </span>
          </div>
          <div>
            <span className="text-lg">
              Generate Pay Run
              <br /> {data.payRunGenerationDueInDays} days before the pay day
            </span>
          </div>
        </div>
      </Card>
    )
  );
};
export default PayDates;
