import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../../hooks";
import { amendmentActionHistoryColumns, mustBeArray } from "../../../../libs";
import axios from "axios";
import {
  useSliceActions,
  useSliceSelector,
} from "../../../../Common/SliceListProvider";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import { getAmendmentHistory } from "../../../actions/payrun";

type Props = {
  visible?: boolean;
  id?: string;
  payrunID?: string;
};

const AmendmentActionHistoryList = ({
  visible, //Track the changes in the modal visibility to refetch the data
  id, //Amendment ID
  payrunID,
}: Props) => {
  const dispatch: any = useAppDispatch();
  const signal = axios.CancelToken.source();
  //Get list state
  const amendmentActionHistoryState = useSliceSelector();
  // Get list actions
  const { dataLoaded, setLoading } = useSliceActions();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [visible, id, payrunID]);

  const getData = () => {
    setLoading({ loading: true });
    dispatch(
      getAmendmentHistory({
        options: { page: 1, payrunID, per_page: 50 },
        id,
        cancelToken: signal.token,
      })
    ).then((resp: any) => {
      setLoading({ loading: false });
      dataLoaded({ data: mustBeArray(resp?.data), total: resp?.total });
    });
  };

  const columns = useMemo(() => {
    return amendmentActionHistoryColumns;
  }, [visible, amendmentActionHistoryState?.data]);

  return (
    <SimpleTableContainer
      dataSource={mustBeArray(amendmentActionHistoryState?.data)}
      columns={columns}
      loading={!!amendmentActionHistoryState?.loading}
      pagination={{ pageSize: 5, hideOnSinglePage: true }}
    />
  );
};

export default AmendmentActionHistoryList;
