import { PropsWithChildren, useRef, useState } from 'react';
import { PlacementAxis } from '@react-aria/overlays';
import classNames from 'classnames';

import { Popover, usePopoverState } from '@xemplo/popover';

import { offsetMapping, placementMapping, TooltipTestId } from './tooltip.helper';
import * as S from './tooltip.styles';
import { TooltipProps } from './tooltip.types';

export const Tooltip = (props: PropsWithChildren<TooltipProps>) => {
  const { text, placement, children, className, shouldFlip = true } = props;

  const popoverRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { state, triggerProps } = usePopoverState({ ref: tooltipRef, type: 'dialog' });

  const [placementAxis, setPlacementAxis] = useState<PlacementAxis>('bottom');
  const { onPress, ...triggerPropsWithoutOnPress } = triggerProps;

  return (
    <S.Tooltip
      ref={tooltipRef}
      {...triggerPropsWithoutOnPress}
      className="label"
      onMouseEnter={() => state.open()}
      onMouseLeave={() => state.close()}
      data-testid={TooltipTestId.trigger}
    >
      {children}
      {state.isOpen && (
        <Popover
          ref={popoverRef}
          triggerRef={tooltipRef}
          state={state}
          placement={placementMapping[placement]}
          isNonModal
          onPlacementChange={(p) => setPlacementAxis(p)}
          shouldFlip={shouldFlip}
          offset={offsetMapping[placement][0]}
          crossOffset={offsetMapping[placement][1]}
        >
          <S.Message
            className={classNames(
              className,
              `is-position-${placement}`,
              'message',
              `is-axis-${placementAxis}`
            )}
            data-testid={TooltipTestId.message}
          >
            {text}
          </S.Message>
        </Popover>
      )}
    </S.Tooltip>
  );
};

export default Tooltip;
