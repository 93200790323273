import { apiUrlBuilder } from '@xemplo/gp-api';
import {
  DownloadableFile,
  EarningLineDetailVarianceItem,
  MappedPayrunSteps,
  PAYRUN_STATUS_IDS,
  PayrunMilestone,
  PayrunSteps,
  PayrunSummary,
  Result,
  StatusToStepMap,
  Step,
  ValidationErrors,
} from '@xemplo/gp-types';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { useGetPayrunFilesQuery } from './payrun-query';
import { PayrunAPI } from './payrun-query.constants';

export function mapMilestoneSteps(milestone: PayrunMilestone): MappedPayrunSteps {
  //Find the active step based on the payrunStatusID
  const isActive = (step: PayrunSteps) => {
    return StatusToStepMap(milestone.isInvoiceNeeded)[step].includes(
      milestone.payrunStatusID
    );
  };

  const step: Omit<Step, 'step' | 'active' | 'date'> = {
    payrunStatusId: milestone.payrunStatusID,
    completed: milestone.payrunStatusID === PAYRUN_STATUS_IDS.COMPLETED,
  };

  const stepDefinitions: Step[] = [
    {
      ...step,
      date: milestone.amendmentDueDateTime,
      step: PayrunSteps.Submit,
      active: isActive(PayrunSteps.Submit),
    },
    {
      ...step,
      date: milestone.preparationDueDateTime,
      step: PayrunSteps.Prepare,
      active: isActive(PayrunSteps.Prepare),
    },
    {
      ...step,
      date: milestone.approvalDueDateTime,
      step: PayrunSteps.Review,
      active: isActive(PayrunSteps.Review),
    },
    {
      ...step,
      date: milestone.payDate,
      step: PayrunSteps.Process,
      active: isActive(PayrunSteps.Process),
    },
    {
      ...step,
      date: milestone.payDate,
      step: PayrunSteps.Complete,
      active: isActive(PayrunSteps.Complete),
    },
  ];

  //If invoiceNeeded is true, add the Pending and Payment steps
  if (milestone.isInvoiceNeeded) {
    stepDefinitions.splice(
      3,
      0,
      {
        ...step,
        date: milestone.paymentDueDateTime,
        step: PayrunSteps.Pending,
        active: isActive(PayrunSteps.Pending),
      },
      {
        ...step,
        date: milestone.paymentDueDateTime,
        step: PayrunSteps.Payment,
        active: isActive(PayrunSteps.Payment),
      }
    );
  }

  //Early exit if all steps are completed (no active step)
  if (milestone.payrunStatusID === PAYRUN_STATUS_IDS.COMPLETED) {
    return { ...milestone, steps: stepDefinitions, activeStep: null };
  }

  //Find active step and set previous steps to completed
  let activeStepFound = false;
  const steps = stepDefinitions.map((step) => {
    if (step.active) {
      activeStepFound = true;
    }
    step.completed = !activeStepFound;
    return step;
  });

  return {
    ...milestone,
    steps,
    activeStep: steps.find((step) => step.active) ?? null,
  };
}

/**
 * Get an array of files associated with a pay run.
 *
 * @param {string | null} payRunId - The ID of the pay run whose files are to be managed.
 * @returns {DownloadableFile[]} An array of downloadable file objects associated with the pay run.
 */
export const useDownloadableFiles = (
  payRunId: string | null,
  payrunStatusId: number | null
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { data } = useGetPayrunFilesQuery({ id: payRunId ?? null }, payrunStatusId);
  if (!data?.result) return [];

  const mappedFiles: DownloadableFile[] = [];

  data.result.forEach((file) => {
    const url = apiUrlBuilder(PayrunAPI.fileByFileId(file.id), applicationApiUrl);

    mappedFiles.push({
      id: file.id,
      type: file.displayName,
      url,
      extension: file.originalFileName.split('.').pop() ?? '',
    });
  });

  return mappedFiles;
};

export const emptyPayrunSummaryResponse: Result<PayrunSummary> = {
  resultCode: 0,
  validationErrors2: {} as ValidationErrors,
  result: {
    payrunId: '',
    externalPayRunId: '',
    current: {
      grossPay: 0,
      netPay: 0,
      totalHours: 0,
      superannuation: 0,
      totalWithheld: 0,
      totalExpenses: 0,
    },
    previous: null,
  },
  totalRecords: 0,
};

export const emptyPayrunWorkerDetailsResponse = {
  resultCode: 0,
  validationErrors: [],
  result: {
    payrunId: '',
    previousPayrunId: '',
    externalEmployeeId: '',
    externalEmployeeName: '',
    previousAndYtdPayItems: [],
  },
  totalRecords: 0,
};

export function parseEarningLineDetailsRows(
  data?: EarningLineDetailVarianceItem[]
): EarningLineDetailVarianceItem[][] {
  if (!data) return [];
  const result: EarningLineDetailVarianceItem[][] = [];
  let subArray: EarningLineDetailVarianceItem[] = [];
  data.forEach((item) => {
    if (item.payCategoryId === null) {
      if (subArray.length > 0) {
        result.push(subArray);
      }
      subArray = [item];
    } else {
      subArray.push(item);
    }
  });
  if (subArray.length > 0) {
    result.push(subArray);
  }
  return result;
}
