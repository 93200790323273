//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import TableActionComponent from "../common/tableActions";
import ScrollableTableContainer from "../../../Common/ClaimContainers/ScrollableTableContainer";
import { getBspAccountListColumns } from "../../../libs/utilities";

class ScrollableBillinServiceProviderAccountList extends React.Component {
  componentDidMount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .addEventListener("scroll", (event) => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          if (
            (currentScroll >= maxScroll || maxScroll - currentScroll < 0.5) &&
            this.props.hasMoreData
          ) {
            this.props.handleTableChange({ options: {}, pageLoad: false });
          }
        });
  }
  componentWillUnmount() {
    document.querySelector(".ant-table-body") &&
      document
        .querySelector(".ant-table-body")
        .removeEventListener("scroll", () => {
          console.log(" ");
        });
  }
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    switch (sorter.field) {
      case "accountNumber":
        field = "accountnumber";
        break;
      case "accountName":
        field = "accountname";
        break;
      default:
        break;
    }
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order === "ascend" ? "asc" : "desc",
          field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options });
  };

  handleAction = ({ record, action }: any) => {
    switch (action) {
      case "edit":
      case "view":
        return this.props.handleEdit({ record, label: "account" });
      case "delete":
        return this.props.handleDelete({ record, label: "account" });
      default:
        break;
    }
  };
  render() {
    const { sortInfo, editComponent, claims, deleteComponent, per_page } =
      this.props;
    const colTemplate = getBspAccountListColumns({ sortInfo });
    const column = [
      ...tableColumnGenerator(colTemplate),
      {
        title: "Action",
        key: "action",
        render: (record: any) => {
          return (
            record &&
            record.enabled && (
              <React.Fragment>
                <TableActionComponent
                  record={record}
                  handleAction={this.handleAction}
                  editComponentName={editComponent}
                  deleteComponentName={deleteComponent}
                  claims={claims}
                />
              </React.Fragment>
            )
          );
        },
      },
    ];
    return (
      <ScrollableTableContainer
        columns={column}
        dataSource={this.props.data}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        per_page={per_page}
      />
    );
  }
}

export default ScrollableBillinServiceProviderAccountList;
