import React, { useState } from "react";
import { REOCCURRING_AMENDMENT_TYPE_ID } from "../../../../constants/payrun";
import { Card, Popconfirm } from "antd";
import {
  parseItems,
  currencyFormater,
  getConditionalResponse,
} from "../../../../libs/utilities";
import { onlyDateFormater, renderReoccurringEndDate } from "../../../../libs";
import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  getAssignedToUsersText,
  showDeleteGeneralAmendment,
  showRequestCancellingGeneralAmendment,
} from "../../../../libs";
import { AmendmentStatusBadges } from "../../../../Common/amendments";
import { PendingAmountValue } from "../Amendments/pending-amount-value";
import { ChangeTaskTypes } from "@xemplo/gp-types";

const GeneralAmendmentSingleLineGridDisplay = (props: any) => {
  const { content, handleAmendmentActions, dashboardView } = props;
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);

  const handlePreventDefault = (e: any, action: string) => {
    if (action === "delete") {
      setConfirmDeleteVisible(!confirmDeleteVisible);
    }
    if (action === "requestCancel") {
      setConfirmCancelVisible(!confirmCancelVisible);
    }
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const handleActions = ({ event, action, amendment }: any) => {
    if (event) {
      handlePreventDefault(event, action);
    }
    handleAmendmentActions({ action, data: amendment });
  };

  const renderActionAndUserName = (content: any) => {
    if (parseItems(content.actionedBy, {})?.UserName)
      return (
        <div
          className="text-sm italic"
          onClick={(event) =>
            handleActions({ event, data: content, action: "viewHistory" })
          }
        >
          {`${content.amendmentStatusName} by ${
            parseItems(content.actionedBy).UserName
          }`}
        </div>
      );
    return <React.Fragment />;
  };

  return content ? (
    <Card
      className={`rounded-[12px] hand ${getConditionalResponse({
        condition: dashboardView,
        resp1: "bg-white",
        resp2: "bg-default",
      })}`}
      onClick={() => handleAmendmentActions({ data: content, action: "view" })}
    >
      <div className="flex justify-between">
        <div className="flex-1-1-30">
          <div className="text-[15px] font-bold">{`${
            content.amendmentTaskTypeName
          } for ${getAssignedToUsersText({ content, minified: true })}`}</div>
          {/* {content?.amendmentSubTypeName && (
            <div className="text-sm">
              <span className="font-bold">Sub Type: </span>
              <span>{content?.amendmentSubTypeName}</span>
            </div>
          )} */}
          <div className="text-sm flex-1-1 italic">
            {`Submitted by ${
              parseItems(content.requestedBy) &&
              parseItems(content.requestedBy).UserName
            } on ${onlyDateFormater(content.requestedDate)}`}
          </div>
          {renderActionAndUserName(content)}
        </div>

        {/** Show the total amount */}
        {content.amendmentTaskTypeID !== ChangeTaskTypes.TERMINATION && (
          <div className="text-sm flex flex-1-1-20 items-center gap-2 ">
            {/**   //TODO: Replace calculated value with BE returned totalAmount https://expedo.atlassian.net/browse/GC-870  */}
            <span className="font-bold pl-3">Total Amount:</span>
            <PendingAmountValue
              value={(content.amount ?? 0) * (content.units ?? 0)}
              isIntegrated={content.isIntegrated}
              amendmentTaskTypeID={content.amendmentTaskTypeID}
              amendmentKeypayStatusId={content.amendmentKeypayStatusId}
            />
          </div>
        )}

        {/** Show the end date or maximum amout for recurring amendments */}
        {getConditionalResponse({
          condition: [REOCCURRING_AMENDMENT_TYPE_ID].includes(
            content.amendmentType
          ),
          resp1: (
            <div className="text-sm flex-1-1-20">
              <div>
                {getConditionalResponse({
                  condition: content?.recurringExpiryDate,
                  resp1: "End Date: ",
                  resp2: "Maximum Amount: ",
                })}
                {renderReoccurringEndDate(content)}
              </div>
            </div>
          ),
          resp2: <div className="flex-1-1-20">&nbsp;</div>,
        })}

        {
          // Show Amednment Status Badge
          <div
            className={`text-sm text-right flex-1-1-${
              !dashboardView ? 15 : 33
            }`}
          >
            <AmendmentStatusBadges amendment={content} />
          </div>
        }
        {!dashboardView && ( // If not dashboard view, show 'delete' and 'request cancel' buttons
          <div className="text-right text-sm flex-1-1-15">
            <React.Fragment>
              {getConditionalResponse({
                condition: showDeleteGeneralAmendment({ content }),
                resp1: (
                  <Popconfirm
                    placement="bottomLeft"
                    title={"Are you sure you want to delete this amendment?"}
                    onConfirm={(event) =>
                      handleActions({
                        event,
                        action: "delete",
                        amendment: content,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                    cancelButtonProps={{
                      onClick: (e) => handlePreventDefault(e, "delete"),
                    }}
                    visible={confirmDeleteVisible}
                  >
                    <DeleteOutlined
                      className="ml-4 hand"
                      onClick={(e) => handlePreventDefault(e, "delete")}
                    />
                  </Popconfirm>
                ),
                resp2: <React.Fragment />,
              })}

              {getConditionalResponse({
                condition: showRequestCancellingGeneralAmendment({ content }),

                resp1: (
                  <Popconfirm
                    placement="bottomLeft"
                    title={"Are you sure you want to cancel this amendment?"}
                    onConfirm={(event) =>
                      handleActions({
                        event,
                        action: "requestCancel",
                        amendment: content,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                    cancelButtonProps={{
                      onClick: (e) => handlePreventDefault(e, "requestCancel"),
                    }}
                    visible={confirmCancelVisible}
                  >
                    <CloseCircleOutlined
                      className="ml-4 hand"
                      onClick={(e) => handlePreventDefault(e, "requestCancel")}
                    />
                  </Popconfirm>
                ),

                resp2: <React.Fragment />,
              })}
            </React.Fragment>
          </div>
        )}
      </div>
    </Card>
  ) : (
    <React.Fragment></React.Fragment>
  );
};
export default GeneralAmendmentSingleLineGridDisplay;
