import { SVGProps } from 'react';
const DawnArrowLongDown16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.75a.748.748 0 0 1-.535-.225l-2.662-2.661a.75.75 0 0 1 1.061-1.061l1.386 1.386V2a.75.75 0 1 1 1.5 0v10.19l1.387-1.387a.75.75 0 1 1 1.06 1.06L8.531 14.53a.74.74 0 0 1-.53.22Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowLongDown16;
