import * as S from './maybe-error.styles';
import { InputErrorProps } from './maybe-error.types';

export const MaybeErrorTestId = {
  errorMessage: (testId?: string) => `maybe-error-message-${testId}`,
  description: (testId?: string) => `maybe-description-${testId}`,
};

export function MaybeErrorOrDescription(props: InputErrorProps) {
  const {
    description,
    descriptionProps,
    error,
    errorMessage,
    errorMessageProps,
    testId,
  } = props;

  if (errorMessage && error) {
    return (
      <S.ErrorMessage
        {...errorMessageProps}
        data-testid={MaybeErrorTestId.errorMessage(testId)}
      >
        {errorMessage}
      </S.ErrorMessage>
    );
  }

  if (description) {
    return (
      <S.Description
        {...descriptionProps}
        data-testid={MaybeErrorTestId.description(testId)}
      >
        {description}
      </S.Description>
    );
  }

  return null;
}
