import { InputNumber, Form } from "antd";
import {
  validateInputValueIncludesOnlyNumbers,
  numberFormatter,
} from "../../../libs";
import { MAX_NUMBER_VALUE } from "../../../constants";

type Props = {
  question?: any;
  handleChange: (value: any) => void;
  label?: string;
  name?: string;
  required: boolean;
  className?: string;
};

const labelDefault = "Rate";
const nameDefault = "rate";

const RateField = ({
  label = labelDefault,
  name = nameDefault,
  required,
  className,
}: Props) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className={`mb-4  col-start-1 ${className}`}
      rules={[
        {
          required: required,
          message: "Please enter rate",
        },
      ]}
    >
      <InputNumber
        formatter={numberFormatter}
        precision={2}
        parser={(value) => (value || "")?.replace(/\$\s?|(,*)/g, "")}
        className="w-full hideNumberPointers"
        onKeyDown={(e) =>
          validateInputValueIncludesOnlyNumbers({
            e,
            hasDecimal: true,
            isPositive: true,
            maxAmount: MAX_NUMBER_VALUE,
          })
        }
      />
    </Form.Item>
  );
};

export default RateField;
