import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { PAYROLL_MODEL_IDS, PAYRUN_STATUS_IDS } from '@xemplo/gp-types';
import {
  useGetBusinessUnitByIdQuery,
  useGetPayrunByIdQuery,
  useGetPayrunInvoicesQuery,
} from '@xemplo/payrun-query';

export const useShowPayInvoice = () => {
  const { id } = useParams();
  const { data: { result: invoiceData } = {}, isLoading } = useGetPayrunInvoicesQuery({
    id: id ?? null,
  });
  const { data: { result: payrunData } = {} } = useGetPayrunByIdQuery({ id: id ?? null });
  const { data: { result: businessUnitData } = {} } = useGetBusinessUnitByIdQuery({
    id: payrunData?.businessUnitID ?? null,
  });
  const showPayInvoice = useMemo(() => {
    if (isLoading) return false;

    const invoiceAlreadyPaid = ![1, 2, 4].includes(Number(invoiceData?.invoiceStatusId));
    const payrunIsCompleted = payrunData?.payrunStatusID === PAYRUN_STATUS_IDS.COMPLETED;
    const isPEO = payrunData?.payrollModelId === PAYROLL_MODEL_IDS.PEO;
    const isOutsource = payrunData?.payrollModelId === PAYROLL_MODEL_IDS.OUTSOURCE;

    if (!payrunData || !invoiceData || invoiceAlreadyPaid || payrunIsCompleted)
      return false;

    if (isOutsource && payrunData?.isInvoiceNeeded) return true;

    return !!(isPEO && businessUnitData?.paymentMandatory);
  }, [businessUnitData?.paymentMandatory, invoiceData, isLoading, payrunData]);

  return { showPayInvoice, isLoading };
};
