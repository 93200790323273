import styled from 'styled-components';

import { Button } from '@xemplo/button';
import { BodyLargeMedium, BodySmallRegular, Colour } from '@xemplo/style-constants';

export const LogoutButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`;

export const NoAccessContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 288px;
  align-items: center;
  padding-bottom: 32px;
  justify-content: center;
  color: ${Colour.Gray[400]};
  ${BodySmallRegular};
  gap: 16px;
  & > svg {
    width: 64px;
    height: 64px;
    color: ${Colour.Gray[200]};
  }
`;

export const Header = styled.div`
  color: ${Colour.Gray[500]};
  ${BodyLargeMedium};
`;

export const Wrapper = styled.div`
  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: absolute;
    inset: 0;
  }
`;
