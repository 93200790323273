// @ts-nocheck
import { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import {
  findAuthorizationClaims,
  getConditionalResponse,
  handleRequest,
  isComponentVisible,
} from "../../../libs";
import SimpleButton from "../../ClaimComponents/Button/SimpleButton";
import AmendmentDetails from "../../../Organization/components/payrun/amendmentDetails";
import {
  getAmendment,
  getAmendmentHistory,
} from "../../../Organization/actions/payrun";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { If, Loading } from "../../ui";
import ModalTitle from "./Title";
import {
  BUSINESS_UNIT_MENU_ITEM,
  DEFAULT_GUID,
  DEFAULT_HOME_ROUTE,
} from "../../../constants";
import { useNavigate } from "react-router-dom";

type Props = {
  title?: string;
  amendmentId: string;
  handlePayrunView?: () => void;
  handlePayscheduleView?: () => void;
  handleClose: () => void;
  visible: boolean;
  amendmentActionsVisible?: boolean;
  businessunitDetails?: any;
  activeAmendment?: any;
  viewAmendmentHistory?: any;
  handleTableChange?: any;
  payRunID?: string;
};

const titleDefault = "Amendment Details";

const AmendmentDetailsModal = ({
  title = titleDefault,
  amendmentId,
  handleClose,
  visible,
  amendmentActionsVisible,
  businessunitDetails,
  activeAmendment,
  handleTableChange,
  payRunID,
  viewAmendmentHistory,
}: Props) => {
  const navigate = useNavigate();
  const [selectedAmendment, setAmendment] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  // Pay run detail from the store
  const dispatch = useAppDispatch();
  const signal = axios.CancelToken.source();

  const authDom = useAppSelector(
    (state) => state?.member?.details?.authorizationDOM
  );

  const businessUnitMenuItem = useMemo(() => {
    return findAuthorizationClaims({
      claims: authDom,
      name: BUSINESS_UNIT_MENU_ITEM,
    });
  }, [authDom]);

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      getAmendmentDetails();
    }
    // Safe to add dispatch to the dependencies array
  }, [dispatch, visible]);

  const getAmendmentDetails = () => {
    dispatch(
      getAmendment({
        options: getConditionalResponse({
          condition: payRunID && amendmentId && payRunID !== DEFAULT_GUID,
          resp1: { payrunID: payRunID },
          resp2: {},
        }),
        targetEndpoint: "",
        cancelToken: signal.token,
        id: amendmentId,
      })
    ).then((res: any) => {
      setIsLoading(false);
      if (handleRequest({ response: res, hasValidationErrors: true })) {
        setAmendment(res.data);
      } else {
        if (payrunIsSkipped(response?.data?.validationErrors?.errors)) {
          handleClose();
          navigate(DEFAULT_HOME_ROUTE);
        }
      }
    });
  };

  const handleBusinessUnitView = () => {
    //Only redirect users if they have access to Business Unit menu item
    if (isComponentVisible({ claim: businessUnitMenuItem })) {
      navigate(`/businessunit/${selectedAmendment?.businessUnitId}`);
    }
  };

  return (
    <Modal
      title={
        <ModalTitle
          title={title}
          selectedAmendment={selectedAmendment}
          handleBusinessUnitView={handleBusinessUnitView}
        />
      }
      width={700}
      visible={visible}
      onCancel={handleClose}
      footer={[
        <SimpleButton key="back" onClick={handleClose} buttonText="Close" />,
      ]}
    >
      <If
        condition={isLoading}
        then={<Loading className="pt-6" />}
        else={
          <AmendmentDetails
            amendment={selectedAmendment}
            viewAction
            businessunitDetails={businessunitDetails}
            viewAmendmentHistory={viewAmendmentHistory}
            activeAmendment={activeAmendment}
            handleTableChange={handleTableChange}
            getAmendmentHistory={getAmendmentHistory}
            visible={amendmentActionsVisible}
          />
        }
      />
    </Modal>
  );
};

export default AmendmentDetailsModal;
