import styled from 'styled-components';

import { AmendmentStatus } from '@xemplo/amendment-status';
import { Amendment } from '@xemplo/gp-types';
import {
  BodySmallMedium,
  BodyXSmallMedium,
  BodyXSmallRegular,
  Colour,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';
import { Widget } from '@xemplo/widget';

export const SubTitle = styled.div`
  ${BodyXSmallMedium};
  color: ${Colour.Gray[400]};
  text-transform: uppercase;
  margin: 0 24px 8px 24px;
`;

export const CustomWidget = styled(Widget)`
  .widget-body {
    padding: 0 4px 0 4px;
    max-height: 436px;
    margin-inline: 4px;
  }
  &&.is-empty {
    padding-bottom: 16px;
  }
`;

export const AmendmentListTable = styled(XemploTable<Amendment>)`
  background: transparent;
  .action-bar-container {
    display: none;
  }
  table {
    background: transparent;
    thead {
      display: none;
    }
    tbody {
      background: transparent;
      tr {
        box-sizing: border-box;
        padding: 0;
        background: ${Colour.White[100]};
        border: none;
        border-radius: 8px;
        &:hover {
          box-shadow: none;
          cursor: pointer;
          background: ${Colour.Gray[50]};
        }
      }
      &.table-cell-wrapper {
        padding-bottom: 0;
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-block: 12px;
  justify-content: center;
  ${BodyXSmallRegular};
  color: ${Colour.Gray[400]};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${Colour.Blue[600]};
  }
`;
export const LeadIconWrapper = styled.div`
  min-width: 36px;
  min-height: 36px;
  border-radius: 8px;
  background: ${Colour.Blue[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 20px;
    height: 20px;
    color: ${Colour.Blue[500]};
  }
`;
export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  flex-wrap: nowrap;
`;

export const CellDetails = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  flex-direction: column;
  color: ${Colour.Gray[400]};
  display: flex;
`;

export const NameAndType = styled.div`
  ${BodySmallMedium};
  display: flex;
  gap: 4px;
  span {
    &:first-child {
      color: ${Colour.Gray[600]};
    }
  }
`;

export const AmendmentDate = styled.div`
  ${BodyXSmallRegular};
`;

export const AmendmentBadge = styled(AmendmentStatus)`
  flex-shrink: 0;
`;
