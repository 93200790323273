import { TextArea } from '@xemplo/text-area';

import * as S from './payrun-resubmit-amendment.styles';
import { FieldProps } from './payrun-resubmit-amendment.types';

export const ResubmitReason = (props: FieldProps) => {
  const { setPayload } = props;

  const handleChange = (e: string) => {
    setPayload(e);
  };
  return (
    <S.FormLabel>
      <div>
        Add a reason <S.OptionalLabel>(optional)</S.OptionalLabel>
      </div>
      <TextArea
        placeholder="Please expand on your reason"
        aria-label="rejection reason"
        onChange={(e) => handleChange(e as string)}
        rows={6}
      />
    </S.FormLabel>
  );
};
