import { useEffect } from 'react';

import { ModalBodyOverflow } from '../body/body.types';
import { ModalCloseEvent } from '../modal.types';

import { useModal } from './use-modal';

export const useDialogControls = () => {
  const { state, containerRef, toggleModal } = useModal();
  const { open, onCloseCallback } = state;

  const handleDialogClick = (e: ModalCloseEvent) => {
    if (state.closeButton?.hide) return;
    onCloseCallback?.(e);
    if (!onCloseCallback) {
      toggleModal(false);
    }
  };

  useEffect(() => {
    const dialog = containerRef.current;
    if (!dialog) return;

    const openAttr = dialog.getAttribute('open');
    open && openAttr == null && (state.asModal ? dialog.showModal() : dialog.show());

    !open && dialog.close();

    const overflowMode = state.body?.overflow || ModalBodyOverflow.Standard;
    const isStandard = overflowMode === ModalBodyOverflow.Standard;
    const isMobile = window.matchMedia('max-wdith: 719px').matches;

    document.body.style.overflowY = isStandard || isMobile ? 'hidden' : 'auto';
  }, [containerRef, open, state.body?.overflow, state.asModal]);

  return { handleDialogClick };
};
