import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetBusinessUnitsQuery } from '@xemplo/amendment-query';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';
import { FUTURE_SCHEDULED_PAYRUN } from '../page-1/select-payrun';

export const BusinessUnit = () => {
  const { register, watch, setValue } = useFormContext();

  const watchPayrun = watch('payrun');
  const watchBusinessUnit = watch('businessUnit');
  const showBusinessUnitField =
    !watchPayrun ||
    watchPayrun === PLACEHOLDER_OPTION ||
    watchPayrun === FUTURE_SCHEDULED_PAYRUN;
  const payrunId =
    watchPayrun === FUTURE_SCHEDULED_PAYRUN || watchPayrun === PLACEHOLDER_OPTION
      ? null
      : watchPayrun;
  const { data: payrunData } = useGetPayrunByIdQuery({
    id: payrunId,
  });

  useEffect(() => {
    if (showBusinessUnitField) {
      register('businessUnit', {
        required: 'This field is required',
        validate: (value) => {
          if (value === PLACEHOLDER_OPTION) {
            return 'This field is required';
          }
          return true;
        },
      });
    }
    if (payrunData?.result?.businessUnitID) {
      setValue('businessUnit', payrunData.result.businessUnitID);
    }
  }, [payrunData?.result.businessUnitID, register, setValue, showBusinessUnitField]);

  const { data, isLoading } = useGetBusinessUnitsQuery({
    requestParams: {
      per_page: 0,
    },
  });

  const parsedData =
    data?.result.rows.map((item) => {
      return {
        key: item.id,
        value: item.name ?? item.id,
      };
    }) ?? [];

  const getItems = () => {
    if (watchBusinessUnit) {
      return parsedData;
    }
    return [{ key: PLACEHOLDER_OPTION, value: 'Select business unit' }, ...parsedData];
  };

  if (showBusinessUnitField) {
    return (
      <S.FormLabel>
        Business Unit
        <TextDropdownMultiV2
          isDropdownMulti={false}
          items={getItems()}
          defaultSelectedKeys={watchBusinessUnit && [watchBusinessUnit]}
          selectProps={{
            placeholder: 'Select business unit',
          }}
          displayProps={{
            isDisabled: isLoading,
          }}
          name="businessUnit"
          aria-label="Business unit field"
          testId="businessUnit"
        />
      </S.FormLabel>
    );
  }
};
