//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../../libs/table";
import SimpleTableContainer from "../../../../Common/ClaimContainers/SimpleTableContainer";
import { getInvoiceListColumns } from "../../../../libs/utilities";
import { DEFAULT_FALLBACK_DATE } from "../../../../constants/payrun";
import moment from "moment";
import TableActionComponent from "../../common/tableActions";
import _ from "lodash";
import { withRouter } from "../../../../hooks";

class InvoiceList extends React.Component {
  viewPayrun = (record: any) => {
    this.props.router.navigate(`/payrun/${record.payrunId}`);
  };

  handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    let field = sorter.field;
    switch (sorter.field) {
      case "payOccurrenceName":
        field = "payoccurrencename";
        break;
      case "ammendmentDueDateTime":
        field = "ammendmentduedate";
        break;
      case "payDate":
        field = "paydate";
        break;
      case "ammendmentCount":
        field = "ammendmentcount";
        break;
      default:
        break;
    }
    let options = {
      page: pagination.current,
      order: sorter.order
        ? sorter.order === "ascend"
          ? "asc"
          : "desc"
        : sorter.order,
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  handleAction = ({ record, action }: any) => {
    switch (action) {
      case "edit":
      case "view":
        return this.props.handleEdit(record);
      case "downloadSummary":
        window.open(record && record.summaryDownloadURL);
        break;
      case "downloadPdf":
        window.open(record && record.pdfDownloadUrl);
        break;
      default:
        break;
    }
  };
  renderDate = (value: any) => {
    if (value && value !== DEFAULT_FALLBACK_DATE)
      return moment(value).format("DD/MM/YYYY");
    return " ";
  };

  render() {
    const pagination = {
      total: this.props.total,
      current: this.props.current,
      pageSize: this.props.per_page ? this.props.per_page : 20,
      hideOnSinglePage: true,
    };
    const {
      sortInfo,
      claims,
      viewComponent,
      dashboardView,
      downloadPdfComponent,
      downloadSummaryComponent,
      getRowKeyClassName,
    } = this.props;
    let colTemplate = getInvoiceListColumns({
      viewComponent,
      sortInfo,
      claims,
      dashboardView,
      renderDate: this.renderDate,
    });
    // If dashboard view leave columns as they are, if not append the action menu column
    const column = tableColumnGenerator(
      this.props.dashboardView
        ? colTemplate
        : _.concat(colTemplate, [
            {
              title: "Actions",
              key: "action",
              align: "center",

              render: (record: any) => (
                <div className="flex justify-center items-center">
                  <TableActionComponent
                    record={record}
                    handleAction={this.handleAction}
                    downloadPdfComponentName={downloadPdfComponent}
                    downloadSummaryComponentName={downloadSummaryComponent}
                    claims={this.props.claims}
                    showDownloadPdf={
                      record && record.pdfDownloadUrl ? true : false
                    }
                    showDownloadSummary={
                      record && record.summaryDownloadURL ? true : false
                    }
                  />
                </div>
              ),
            },
          ])
    );
    return (
      <SimpleTableContainer
        columns={column}
        dataSource={this.props.data}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        pagination={pagination}
        rowKeyClassName={getRowKeyClassName}
        rowIdParam="id"
        dashboardView={dashboardView}
      />
    );
  }
}

export default withRouter(InvoiceList);
