import { useCallback, useMemo, useState } from 'react';

import { Context, Environment } from '@xemplo/types';

import { FEATURE_SESSION_KEY } from './feature-flag.constants';
import {
  ApplicationFeature,
  EnvironmentFeature,
  FeatureMap,
  FeatureProviderProps,
} from './feature-flag.types';

export const useFeatureInit = (props: FeatureProviderProps) => {
  const [state, setState] = useState(() => makeInitialFeatureState(props));
  const { featureMap, environment, appContext } = state;

  /**
   * Determines whether a feature is enabled based on the provided feature map, app context, and environment.
   * If the feature is not found in the feature map, it is considered enabled by default.
   * If the feature is defined as a boolean value and set to true, it is considered enabled.
   * If the feature is defined with both environment and context, it is considered enabled if it satisfies both conditions.
   * If the feature is defined with either environment or context, it is considered enabled if it satisfies at least one condition.
   * @param feat - The name of the feature to check.
   * @returns A boolean indicating whether the feature is enabled.
   */
  const isEnabled = useCallback(
    (feat: string) => {
      // By default every feature is enabled. This allows a cleaner feature map file
      const feature = featureMap[feat];

      if (!feature) return true;

      // If we don't care about the environment and the context, we can simply define a boolean value
      if (typeof feature.enabled === 'boolean') return feature.enabled;

      // Check if the feature is enabled for the current environment
      const isEnabledByEnv = checkEnvironment(
        feature.enabled as EnvironmentFeature,
        environment
      );

      // Check if the feature is enabled for the current context
      const isEnabledByContext = checkContext(
        feature.enabled as ApplicationFeature,
        appContext
      );

      return isEnabledByEnv && isEnabledByContext;
    },
    [appContext, environment, featureMap]
  );

  const toggleFeature = useCallback(
    (feature: string) => {
      const newFeatureMap = { ...featureMap };

      newFeatureMap[feature].enabled = !newFeatureMap[feature].enabled;

      setState((prev) => ({ ...prev, featureMap: newFeatureMap }));
      localStorage.setItem(FEATURE_SESSION_KEY, JSON.stringify(newFeatureMap));
    },
    [featureMap]
  );

  const getFeatureName = useCallback(
    (feature: string) => {
      if (!featureMap) return;

      return featureMap[feature]?.name;
    },
    [featureMap]
  );

  const availableFeatures = useMemo(
    () => featureMap && getAvailableKeys(featureMap, environment, appContext),
    [appContext, environment, featureMap]
  );

  return { isEnabled, toggleFeature, getFeatureName, availableFeatures };
};

function makeInitialFeatureState(props: FeatureProviderProps) {
  const sessionFeatures = localStorage.getItem(FEATURE_SESSION_KEY);
  return { ...props, featureMap: getFeatureMap(props.featureMap, sessionFeatures) };
}

/**
 * Merges the feature map with the mapping stored on local storage.
 * If the session features are invalid, it returns the original feature map.
 * If the session features are valid, it returns a new feature map with the session features merged in.
 * The enabled value from the session features is retained when feature key match the feature map.
 *
 * NOTE: When session object is malformed, we remove it from local storage and return the original feature map.
 * The error is not important so we simply ignore the error object.
 */
export function getFeatureMap(featureMap: FeatureMap, sessionFeatures: string | null) {
  if (!sessionFeatures) return featureMap;

  try {
    const sessionFeatureMap = JSON.parse(sessionFeatures) as FeatureMap;
    const filteredSessionFeatureMap = Object.keys(sessionFeatureMap)
      .filter((key) => featureMap[key])
      .reduce((featureMapObj, key) => {
        featureMapObj[key] = sessionFeatureMap[key];
        return featureMapObj;
      }, {} as FeatureMap);

    return { ...featureMap, ...filteredSessionFeatureMap };
  } catch (error) {
    localStorage.removeItem(FEATURE_SESSION_KEY);
    return featureMap;
  }
}

/** Checks if a feature is enabled for a given environment */
export function checkEnvironment(
  feature: EnvironmentFeature,
  environment: Environment | undefined
): boolean {
  // This means an environment has not been provided, so we can assume the feature is enabled
  if (!environment) return true;

  // Check if feature configuration has environment definition
  if (!feature.environment) return true;

  // if the environment is not in the list of enabled environments, the feature is disabled
  return feature.environment.includes(environment);
}

/** Checks if a feature is enabled for a given context */
export function checkContext(
  feature: ApplicationFeature,
  appContext: Context | undefined
): boolean {
  // This means a context has not been provided, so we can assume the feature is enabled
  if (!appContext) return true;

  // Check if feature configuration has context definition
  if (!feature.context) return true;

  // if the context is not in the list of enabled contexts, the feature is disabled
  return feature.context.includes(appContext);
}

/** Get keys from featureMap that match the current Environment from context, or have a valid appContext */
export function getAvailableKeys(
  featureMap: FeatureMap,
  environment: Environment | undefined,
  appContext: Context | undefined
): string[] {
  return Object.keys(featureMap).filter((key) => {
    const feature = featureMap[key];

    if (typeof feature.enabled === 'boolean') return feature;

    const hasEnvironment = 'environment' in feature.enabled;
    const hasContext = 'context' in feature.enabled;

    const isEnvironmentMatch =
      environment &&
      'environment' in feature.enabled &&
      feature.enabled.environment.includes(environment);

    const isContextMatch =
      appContext &&
      'context' in feature.enabled &&
      feature.enabled.context.includes(appContext);

    return (!hasEnvironment || isEnvironmentMatch) && (!hasContext || isContextMatch);
  });
}
