import { SVGProps } from 'react';
const DawnArrowRight16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.795 3.174a.75.75 0 1 1 1.06-1.061l5.378 5.378a.748.748 0 0 1 0 1.06L6.856 13.93a.75.75 0 1 1-1.061-1.06l4.847-4.848-4.847-4.847Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnArrowRight16;
