import React from "react";
import { Card } from "antd";
import EditLinkButton from "./ClaimComponents/Button/EditLinkButton";
import {
  conditionalParameter,
  getAbnAndRegistrationText,
  getConditionalResponse,
  trimWhiteSpaces,
} from "../libs/utilities";
import _ from "lodash";
import LogoAndProfileImageAndInitialsView from "../Organization/components/common/LogoProfileImageAndInitialsView";

interface OrganizationOverviewCardProps {
  data: any;
  handleEdit: () => void;
  claims: any;
  claimComponent: string;
  hasNoComponents?: boolean;
}

const OrganizationOverviewCard = ({
  data,
  handleEdit,
  claims,
  claimComponent,
  hasNoComponents,
}: OrganizationOverviewCardProps) => {
  const addrssLine1 = [
    `${data?.registeredAddressLine1}, `,
    getConditionalResponse({
      condition: data?.registeredAddressLine2 !== "NA",
      resp1: `${trimWhiteSpaces(data?.registeredAddressLine2)}, `,
      resp2: "",
    }),
    getConditionalResponse({
      condition: conditionalParameter({
        data: data,
        field: "registeredSuburb",
      }),
      resp1: `${data?.registeredSuburb}, `,
      resp2: "",
    }),
  ];

  const addrssLine2 = [
    getConditionalResponse({
      condition: conditionalParameter({
        data: data,
        field: "registeredAddressState",
      }),
      resp1: `${data.registeredAddressState}, `,
      resp2: "",
    }),
    data?.registeredAddressCountry,
    getConditionalResponse({
      condition: data?.registeredPostalCode,
      resp1: `, ${data.registeredPostalCode}`,
      resp2: "",
    }),
  ];

  const postalAddressLine1 = [
    `${data?.postalAddressLine1}, `,
    getConditionalResponse({
      condition: data?.postalAddressLine2 !== "NA",
      resp1: `${trimWhiteSpaces(data?.postalAddressLine2)}, `,
      resp2: "",
    }),
    getConditionalResponse({
      condition: conditionalParameter({
        data: data,
        field: "postalSuburb",
      }),
      resp1: `${data?.postalSuburb},`,
      resp2: "",
    }),
  ];

  const postalAddressLine2 = [
    getConditionalResponse({
      condition: conditionalParameter({
        data: data,
        field: "postalAddressState",
      }),
      resp1: `${data.postalAddressState},`,
      resp2: "",
    }),
    data?.postalAddressCountry,
    data?.postalPostalCode ? `, ${data.postalPostalCode}` : "",
  ];

  return (
    <Card
      className="module-basic-details card-body-overflow-hidden card"
      bordered={false}
      extra={
        <EditLinkButton
          onClick={handleEdit}
          name={claimComponent}
          claims={claims}
          hasNoComponents={getConditionalResponse({
            condition: _.isUndefined(hasNoComponents),
            resp1: true,
            resp2: hasNoComponents,
          })}
          className="bg-default color-dark"
        />
      }
    >
      <div className="flex justify-center items-center w-full flex-col">
        {/** TODO: Replace with @exedo/avatar lib */}
        <LogoAndProfileImageAndInitialsView
          name={data?.registeredBusinessName}
        />
        <div className="text-xl font-bold mt-4 mb-3">{data?.name}</div>
        <div className="text-sm mb-2">
          <span>
            Registered as:
            <span className="font-bold ml-1">
              {data?.registeredBusinessName}
            </span>
          </span>
        </div>
        <div className="text-sm mb-4">
          <span>
            {getAbnAndRegistrationText({ countryId: data?.addressCountryID })}:
            <span className="font-bold ml-1">{data?.abn}</span>
          </span>
        </div>
        <dl className="text-sm mb-4 text-center">
          <dt className="font-bold">Registered Address:</dt>
          <dd className="mb-4">
            <div>{addrssLine1.join("")}</div>
            <div>{addrssLine2.join("")}</div>
          </dd>

          <dt className="font-bold">Postal Address:</dt>
          <dd>
            <div>{postalAddressLine1.join("")}</div>
            <div>{postalAddressLine2.join("")}</div>
          </dd>
        </dl>
      </div>
    </Card>
  );
};

export default OrganizationOverviewCard;
