import { forwardRef, useImperativeHandle } from 'react';

import { SidebarTestId } from '../sidebar.constants';

import * as S from './sidebar-tooltip.styles';
import { SidebarTooltipProps, SidebarTooltipRef } from './sidebar-tooltip.types';
import { useCustomTooltip } from './use-custom-tooltip';

export const SidebarTooltip = forwardRef<SidebarTooltipRef, SidebarTooltipProps>(
  ({ isSidebarExpanded }, ref) => {
    const { tooltipElement, tooltip, ...methods } = useCustomTooltip(isSidebarExpanded);
    useImperativeHandle(ref, () => methods, [methods]);

    return (
      <S.TooltipWrapper
        id={`tooltip-${tooltipElement.current?.textContent}`}
        ref={tooltipElement}
        data-testid={SidebarTestId.SidebarTooltip}
      >
        <S.Tooltip>{tooltip}</S.Tooltip>
      </S.TooltipWrapper>
    );
  }
);
