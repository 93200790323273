import styled from 'styled-components';

import { Colour, MinBreakpoint } from '@xemplo/style-constants';

/**
 * The toggle handler for the sidebar.
 * It is controlled by toggling aria-hidden attribute
 */
export const Handle = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;

  background: ${Colour.White[100]};
  border: 1px solid ${Colour.Gray[200]};
  border-radius: 1em;
  height: 24px;
  width: 24px;
  top: 45px;
  right: -12px;
  font-size: 14px;
  color: ${Colour.Gray[300]};
  opacity: 0;
  transition: 0.3s all ease-out;
  z-index: 1;

  &:hover {
    cursor: pointer;
    color: ${Colour.Gray[500]};
    border: 1px solid ${Colour.Gray[500]};
  }

  &[data-expanded='false'] {
    transform: rotate(180deg);
  }

  &[aria-hidden='false'] {
    opacity: 1;
  }

  @media ${MinBreakpoint.mediumV2} {
    display: flex;
  }
`;
