//@ts-nocheck
import React from "react";
import { CloseOutlined, EditFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  Tooltip,
  Typography,
  Avatar,
} from "antd";
import { ContractIcon, LgEllipsis } from "../../../Common/customicons";

const { Title } = Typography;

const ProcessingFeesDisplayCard = ({
  handleAction,
  content,
  viewPayrun,
  isPayScheduleEditable,
}: any) => {
  return (
    content && (
      <Card
        className="card card-prop hand"
        bordered={false}
        onClick={(e) => handleAction({ data: content, action: "view" })}
      >
        <div className="position-relative py-3 px-0">
          <div className={"mb-2.5 px-[50px] position-relative"}>
            <div className={"position-relative"}>
              <Tooltip placement="top" title={`${content.feeOption}`}>
                <Title
                  ellipsis
                  className="card-title"
                  level={3}
                >{`${content.feeOption}`}</Title>
              </Tooltip>
            </div>

            <Avatar
              className="position-absolute-top position-absolute-top-left"
              size={36}
            >
              <ContractIcon className="text-lg" />
            </Avatar>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-6">
            <span>{`Invoice Description: ${content.invoiceDescription}`}</span>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-1.5">
            <span>{`Rate/Percentage: ${content.rateOrPercentage}`}</span>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-1.5">
            <span>{`Tax: ${content.taxFeeName}`}</span>
          </div>
          <div className="flex justify-between mb-1 text-xs mt-1.5">
            <span>{`Base Pay: ${
              Number(content.feeOptionId) === 3
                ? "N/A"
                : content.basePay
                ? "Yes"
                : "No"
            }`}</span>
          </div>
        </div>
        <Dropdown
          className="card-item mt-4 mr-4"
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item>
                <div
                  onClick={(e) =>
                    handleAction({ event: e, data: content, action: "view" })
                  }
                >
                  <EditFilled />
                  <span> View</span>
                </div>
              </Menu.Item>
              {isPayScheduleEditable && (
                <Menu.Item>
                  <div
                    onClick={(e) =>
                      handleAction({
                        event: e,
                        data: content,
                        action: "delete",
                      })
                    }
                  >
                    <CloseOutlined />
                    <span> Delete</span>
                  </div>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            className={
              "animated fadeIn btn-dropdown-action ellipsis flex items-center px-2 position-absolute-top position-absolute-top-right"
            }
          >
            <LgEllipsis />
          </Button>
        </Dropdown>
      </Card>
    )
  );
};

export default ProcessingFeesDisplayCard;
