import * as S from './bulk-upload.styles';

export const BulkUploadErrors = ({ errors }: { errors: string[] }) => {
  return (
    <div>
      {errors.map((err, i) => (
        <div key={`${err}-${i}`}>
          {i < 10 && <S.ErrorLine>{err}</S.ErrorLine>}
          {i === 10 && <S.MoreLines>+ {errors.length - 10} more</S.MoreLines>}
        </div>
      ))}
    </div>
  );
};
