import classNames from 'classnames';

import { IconGradient } from '@xemplo/icons';

import { ActiveItem } from './active-item';
import { SidebarTestId } from './sidebar.constants';
import * as S from './sidebar.styles';
import { SidebarProps } from './sidebar.types';
import { SidebarHandle } from './sidebar-handle';
import { SidebarItems } from './sidebar-items';
import { useSidebar } from './use-sidebar';

export function Sidebar(props: SidebarProps) {
  const {
    activeItem,
    isSidebarExpanded,
    setIsSidebarExpanded,
    isResponsiveExpanded,
    setIsResponsiveExpanded,
    sidebarItemProps,
  } = useSidebar(props);

  return (
    <>
      {isResponsiveExpanded && <S.Overlay $offset={props.offset} />}
      <S.Sidenav
        id="sidebar-nav"
        data-testid={SidebarTestId.SidebarContainer}
        data-expanded={isSidebarExpanded}
        className={classNames({ 'responsive-expanded': isResponsiveExpanded })}
      >
        <ActiveItem
          activeItem={activeItem}
          isResponsiveExpanded={isResponsiveExpanded}
          setIsResponsiveExpanded={setIsResponsiveExpanded}
        />
        <SidebarItems {...sidebarItemProps} />
        <IconGradient />
        <SidebarHandle
          isSidebarExpanded={isSidebarExpanded}
          setIsSidebarExpanded={setIsSidebarExpanded}
        />
      </S.Sidenav>
    </>
  );
}
