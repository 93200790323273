import { PAYRUN_STATUS_IDS } from './payrun.types';

export const pendingAmendmentsStatuses = [
  PAYRUN_STATUS_IDS.DETAILS_VIEWED,
  PAYRUN_STATUS_IDS.AMENDMENTS_ADDED,
];

export const reviewStatuses = [
  PAYRUN_STATUS_IDS.PREPARED_SYSTEM_TRIGGERED,
  PAYRUN_STATUS_IDS.PREPARED_USER_TRIGGERED,
];

export const preparedStatuses = [
  PAYRUN_STATUS_IDS.AMENDEMENTS_SUBMITTED_SYSTEM_TRIGGERED,
  PAYRUN_STATUS_IDS.AMENDEMENTS_SUBMITTED_USER_TRIGGERED,
  PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_SYSTEM_TRIGGERED,
  PAYRUN_STATUS_IDS.CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED,
  PAYRUN_STATUS_IDS.IMPORTING_EARNING_LINES_SYSTEM_TRIGGERED,
  PAYRUN_STATUS_IDS.IMPORTING_EARNING_LINES_USER_TRIGGERED,
  PAYRUN_STATUS_IDS.IMPORTED_EARNING_LINES_SYSTEM_TRIGGERED,
  PAYRUN_STATUS_IDS.IMPORTED_EARNING_LINES_USER_TRIGGERED,
];

export enum PAYROLL_MODEL_IDS {
  PEO = 1,
  OUTSOURCE = 2,
}

export enum INVOICE_STATUS_IDS {
  CREATED = 1,
  FULLY_PAID = 3,
  CONFIRMED = 5,
}

export enum PAYRUN_TYPE_ID {
  SCHEDULED = 1,
  ADHOC = 2,
}
