import { Form, InputNumber } from "antd";

type Props = {
  handleChangeValue?: ({ label, value }: { label: string; value: any }) => void;
  required?: boolean;
};

export const PayPeriodsField = ({ required, handleChangeValue }: Props) => {
  return (
    <Form.Item
      label="Pay Periods"
      className="mb-3  xl:col-start-2 xl:row-start-5"
      name="LumpSumNumberOfPayPeriods"
      rules={[
        {
          required,
        },
      ]}
    >
      <InputNumber
        type="number"
        className="w-full hideNumberPointers"
        min={1}
        max={99}
        onChange={(e) =>
          handleChangeValue &&
          handleChangeValue({
            label: "LumpSumNumberOfPayPeriods",
            value: e,
          })
        }
      />
    </Form.Item>
  );
};
