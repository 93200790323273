//@ts-nocheck
import React from "react";
import _ from "lodash";
import {
  mustBeArray,
  findAuthorizationClaims,
  getClaimAndStatus,
  conditionalParameter,
  getOptionalParameter,
  getConditionalResponse,
  isComponentVisible,
  displayActionComponent,
  ignoreClaimValidation,
  showCreateAndGridTableToggleOption,
} from "../../libs";
import InfiniteScroll from "../../libs/infiniteScroll";
import {
  PAGINATION_SCROLLING,
  SORTING_NOT_REQUIRED,
  PAGINATION_NOT_REQUIRED,
} from "../../constants/defaultClaims";
import { DEFAULT_GUTTER } from "../../constants/ui";
import { Pagination, Row, Col } from "antd";
import CreateSimpleButton from "../ClaimComponents/Button/CreateSimpleButton";
import GridTableViewOption from "../../Organization/components/common/gridTableViewOption";
import DisplayCard from "../../Common/displayCard";
import GeneralTable from "../ClaimComponents/TableGrid/GeneralTable";
import GeneralGrid from "../ClaimComponents/TableGrid/GeneralGrid";
import { NO_RECORDS_FOUND_MESSAGE } from "../../constants/errors";
import SingleLineGrid from "../ClaimComponents/TableGrid/SingleLineGrid";
import { If, Loading } from "../ui";
import {
  GeneralPinComponent,
  DashboardPinComponent,
} from "../../Organization/components/common/PinComponents";

type ListContainerProps = {
  className?: string;
  name?: string;
  claims?: object;
  singleLineGridView?: boolean;
  gridable?: boolean;
  gridView?: boolean;
  grid?: object;
  per_page?: number;
  GeneralTablePaginatedComponent?: any;
  GeneralTableScrollableComponent?: any;
  generalTableComponentName?: string;
  createLinkButtonName?: string;
  handleAddAction?: () => void;
  handleGridChange?: (options: boolean) => void;
  fieldOptions?: object[];
  updateGridField?: (fields: object) => void;
  sortingType?: string;
  paginationType?: string;
  list?: [];
  listLoading?: boolean;
  listGrid?: (page: number, pageSize?: number) => void;
  listScrollingGrid?: (options: object) => void;
  hasMoreData?: boolean;
  gridComponentName?: string;
  gridActionComponentName?: string;
  moduleType?: string;
  handleGridAction?: (options: object) => void;
  listScrollingGeneralTable?: (options: object) => void;
  total?: number;
  current?: number;
  sortInfo?: object;
  listGeneralTable?: (options: object) => void;
  tableActionComponentName?: string;
  gridPageSize?: number;
  cardGrid?: string;
  showAddAndGrid?: boolean;
  updatePageSize?: (value: any) => void;
  addModuleName?: string;
  DisplayCardComponent?: React.ReactNode;
  listContainerTitle?: string;
  hideSorting?: boolean;
  CustomActions?: React.ReactNode;
  hideAddButton?: boolean;
  rowKeyClassName?: string;
  match?: any;
  SingleLineGridDisplayComponent?: React.ReactNode;
  singleLineGridTitle?: string;
  handleSingleGridAction?: (options: object) => void;
  dashboardView?: boolean;
  targetEndpoint?: string;
  showTitle?: boolean;
  title?: string;
  hasNoDuplicateEntity?: boolean;
  entityId?: string;
  filterApplied?: string;
  componentTitle?: string;
  disableCreateButton?: boolean;
  isDraft?: boolean;
  type?: string;
  member?: object;
};

const classNameDefault = "";

const ListContainer = (props: ListContainerProps) => {
  const {
    className = classNameDefault,
    name,
    claims,
    singleLineGridView,
    gridable,
    gridView,
    grid,
    per_page,
    GeneralTablePaginatedComponent,
    GeneralTableScrollableComponent,
    generalTableComponentName,
    createLinkButtonName,
    handleAddAction,
    handleGridChange,
    fieldOptions,
    updateGridField,
    sortingType,
    paginationType,
    list,
    listLoading,
    listGrid,
    listScrollingGrid,
    hasMoreData,
    gridComponentName,
    gridActionComponentName,
    moduleType,
    handleGridAction,
    listScrollingGeneralTable,
    total,
    current,
    listGeneralTable,
    tableActionComponentName,
    gridPageSize,
    cardGrid,
    showAddAndGrid,
    updatePageSize,
    addModuleName,
    DisplayCardComponent,
    listContainerTitle,
    hideSorting,
    CustomActions,
    hideAddButton,
    rowKeyClassName,
    match,
    SingleLineGridDisplayComponent,
    singleLineGridTitle,
    handleSingleGridAction,
    dashboardView,
    targetEndpoint,
    showTitle,
    title,
    hasNoDuplicateEntity,
    filterApplied,
    componentTitle,
    disableCreateButton,
    isDraft,
    type,
    member,
    pageClaims,
  } = props;
  const claimAndStatus = getClaimAndStatus({
    claims: claims,
    componentName: name,
    hasComponents: true,
  });
  const GridCardComponent = getOptionalParameter({
    value1: DisplayCardComponent,
    value2: DisplayCard,
  });

  const claim = claimAndStatus.claim;

  const gridComponentClaims = findAuthorizationClaims({
    claims: mustBeArray(
      conditionalParameter({ data: claim, field: "components" })
    ),
    name: gridComponentName,
  });

  const displayActionIcons = getConditionalResponse({
    condition: claim.displayActionIcons,
    resp1: true,
    resp2: false,
  });

  const wrappwerClassName = `${className} ${ignoreClaimValidation({
    ignoreStatus: dashboardView,
    claimAndStatus,
  })} list-container`;

  return (
    <div className={wrappwerClassName}>
      {/** If in dashboard view show the unpin and lock resize options */}
      <If
        condition={dashboardView}
        then={
          <div className="flex justify-end items-center">
            <DashboardPinComponent
              claim={claim}
              targetEndpoint={targetEndpoint}
              pageClaims={pageClaims}
            />
          </div>
        }
      />
      {/** If not dashboard view, show option to pin to dashboard */}
      <If
        condition={displayActionComponent({ claim, dashboardView })}
        then={
          <GeneralPinComponent
            claim={claim}
            targetEndpoint={targetEndpoint}
            hasNoDuplicateEntity={hasNoDuplicateEntity}
            filterApplied={filterApplied}
            componentTitle={componentTitle}
          />
        }
      />
      {/** Render custom actions if they exist (eg, Active/Inactive on Users List)*/}
      {(isComponentVisible({ claim }) || dashboardView) && (
        <div className={`${dashboardView ? "mb-2 pl-1.5" : ""}`}>
          {CustomActions && !dashboardView && (
            <div className="float-left"> {CustomActions} </div>
          )}
          {/** Show the title of the compenent/container */}
          {listContainerTitle && (
            <span className="text-base font-semibold">
              {listContainerTitle}
            </span>
          )}
          {/** Show "filtered by... text" in dashboard*/}
          {dashboardView && componentTitle && (
            <span>
              <span className="text-base font-semibold">{componentTitle}</span>
              {filterApplied && (
                <span className="text-[10px] font-normal ml-2.5">
                  {filterApplied}
                </span>
              )}
            </span>
          )}
          {/** Show add new ... option for relevant containers (users etc) */}
          {showCreateAndGridTableToggleOption({ claim, showAddAndGrid }) && (
            <div className="mb-4 float-right flex items-center">
              {claim.addable === "true" && !hideAddButton && !dashboardView && (
                <CreateSimpleButton
                  onClick={handleAddAction}
                  name={createLinkButtonName}
                  claims={claim}
                  hasNoComponents={false}
                  addModuleName={addModuleName}
                  disableCreateButton={disableCreateButton}
                />
              )}
              {/** Show option for swapping between grid/tabe view*/}
              {claim.gridable === "true" && !singleLineGridView && (
                <GridTableViewOption
                  gridable={gridable}
                  handleGridChange={handleGridChange}
                  gridView={gridView}
                  fieldOptions={fieldOptions}
                  updateGridField={updateGridField}
                  gridSortInfo={conditionalParameter({
                    data: grid,
                    field: "sortInfo",
                  })}
                  updatePageSize={updatePageSize}
                  grid={grid}
                  per_page={per_page}
                  paginationType={paginationType}
                  sortingType={sortingType}
                  hideSorting={hideSorting}
                  dashboardView={dashboardView}
                />
              )}
            </div>
          )}
          <div className="clearfix"></div>
        </div>
      )}
      {/** If it's a single line grid view render the <SingleLineGrid> component. Otherwise check for grid view*/}
      {getConditionalResponse({
        condition: singleLineGridView,

        resp1: (
          <React.Fragment>
            <SingleLineGrid
              ignoreStatus
              loading={listLoading}
              title={singleLineGridTitle}
              DisplayComponent={SingleLineGridDisplayComponent}
              handleAction={handleSingleGridAction}
              dashboardView={dashboardView}
              isDraft={isDraft}
              {...props}
            />
          </React.Fragment>
        ),
        // If it is not singleLineGrid view the check for grid view
        resp2: getConditionalResponse({
          condition: gridView,
          // If grid view > Show loading while loading
          resp1: getConditionalResponse({
            condition: listLoading,
            resp1: (
              <div className="flex justify-center items-center">
                <Loading />
              </div>
            ),
            // Once loaded render  <GeneralGrid> grid view
            resp2: getConditionalResponse({
              condition: _.isEmpty(mustBeArray(list)) && !listLoading,
              resp1: <div>{NO_RECORDS_FOUND_MESSAGE}</div>,
              resp2: (
                <GeneralGrid
                  name={gridComponentName}
                  claims={claim}
                  dashboardView={dashboardView}
                >
                  {getConditionalResponse({
                    condition: paginationType === PAGINATION_SCROLLING,
                    // If pagination is set to scrolling
                    resp1: (
                      <InfiniteScroll
                        throttle={300}
                        threshold={1000}
                        loading={listLoading}
                        onLoadMore={listScrollingGrid}
                        hasMoreData={hasMoreData}
                      >
                        <Row gutter={DEFAULT_GUTTER}>
                          {mustBeArray(list).map((ct: any, index: any) => (
                            <Col
                              className="gutter-row"
                              xl={getOptionalParameter({
                                value1: cardGrid,
                                value2: 6,
                              })}
                              md={12}
                              sm={12}
                              key={index}
                            >
                              <GridCardComponent
                                {...props}
                                type={moduleType}
                                handleAction={handleGridAction}
                                content={ct}
                                component={gridActionComponentName}
                                claims={gridComponentClaims}
                                displayActionIcons={displayActionIcons}
                                dashboardView={dashboardView}
                              />
                            </Col>
                          ))}
                        </Row>
                      </InfiniteScroll>
                    ),
                    // If pagination is set to paginated or undefined
                    resp2: (
                      <div className="grid-paginated">
                        <Row gutter={DEFAULT_GUTTER}>
                          {mustBeArray(list).map((ct: any, index: any) => {
                            return (
                              <Col
                                className="gutter-row pb-6"
                                xl={getOptionalParameter({
                                  value1: cardGrid,
                                  value2: 6,
                                })}
                                md={12}
                                sm={12}
                                key={index}
                              >
                                <GridCardComponent
                                  {...props}
                                  type={moduleType}
                                  handleAction={handleGridAction}
                                  content={ct}
                                  component={gridActionComponentName}
                                  claims={gridComponentClaims}
                                  displayActionIcons={displayActionIcons}
                                  dashboardView={dashboardView}
                                  amendmentType={type}
                                />
                              </Col>
                            );
                          })}
                          <div className="clearfix"></div>
                        </Row>
                        {/** if pagination is required then render the pagination component */}
                        {getConditionalResponse({
                          condition:
                            !_.isEmpty(mustBeArray(list)) &&
                            paginationType !== PAGINATION_NOT_REQUIRED &&
                            !dashboardView,

                          resp1: (
                            <div className="float-right mr-4 mb-6">
                              <Pagination
                                total={total}
                                current={conditionalParameter({
                                  data: grid,
                                  field: "page",
                                })}
                                pageSize={gridPageSize}
                                onChange={listGrid}
                                hideOnSinglePage
                              />
                            </div>
                          ),

                          resp2: <React.Fragment />,
                        })}
                        <div className="clearfix"></div>
                      </div>
                    ),
                  })}
                </GeneralGrid>
              ),
            }),
          }),
          // If it isnt a grid view render the Table View
          resp2: (
            <GeneralTable
              name={generalTableComponentName}
              claims={claim}
              hasNoTitle
              requiresCard
              ignoreStatus={dashboardView}
              showTitle={showTitle}
              title={title}
              match={match}
            >
              {/** if pagination is set to scrolling render the scrolling table component */}
              {getConditionalResponse({
                condition: paginationType === PAGINATION_SCROLLING,

                resp1: (
                  <GeneralTableScrollableComponent
                    data={list}
                    handleTableChange={listScrollingGeneralTable}
                    loading={listLoading}
                    total={total}
                    current={current}
                    per_page={per_page}
                    component={tableActionComponentName}
                    displayActionIcons={displayActionIcons}
                    rowKeyClassName={rowKeyClassName}
                    ignoreStatus={dashboardView}
                    dashboardView={dashboardView}
                    isDraft={isDraft}
                    member={member}
                    {...props}
                  />
                ),
                // if pagination is set to paginated/undefined render the Paginated table component
                resp2: (
                  <GeneralTablePaginatedComponent
                    data={list}
                    handleTableChange={listGeneralTable}
                    loading={listLoading}
                    totalPages={total}
                    current={current}
                    per_page={per_page}
                    component={tableActionComponentName}
                    displayActionIcons={displayActionIcons}
                    rowKeyClassName={rowKeyClassName}
                    ignoreStatus={dashboardView}
                    dashboardView={dashboardView}
                    isDraft={isDraft}
                    member={member}
                    {...props}
                  />
                ),
              })}
            </GeneralTable>
          ),
        }),
      })}
    </div>
  );
};

export default ListContainer;
