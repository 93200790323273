//@ts-nocheck
import React, { Component } from "react";
import { Card, Typography, Badge } from "antd";
import GridActionComponent from "../common/gridAction";
import { showAssignBusinessUnit, userCanEdit } from "../../../libs/validations";
import {
  getAssignedCompaniesAndBusinessUnits,
  getUserRoles,
} from "../../../libs/utilities";
import { Avatar, AvatarSize } from "@xemplo/avatar";
const { Title } = Typography;

type State = any;
class PayrollUserDisplayCard extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  getEditComponent = ({ user }) => {
    const { member, userType, editComponent } = this.props;
    if (userType === "psp") {
      return userCanEdit({ user, member }) ? editComponent : "";
    }
    return editComponent;
  };
  render() {
    const {
      content,
      handleAction,
      reminderComponent,
      userType,
      enableDisableComponentNameGrid,
      enableDisableMFAComponentNameGrid,
      mandatoryDeviceEnrolmentGrid,
      optionalDeviceEnrolmentGrid,
      disableDeviceEnrolmentGrid,
      member,
      dashboardView,
      pspId,
      assignBusinessUnitGrid,
    } = this.props;
    if (!content) return <React.Fragment />;
    return (
      <Card
        className="card card-prop hand shadow-default display-card-user pb-0 h-full"
        bordered="false"
        onClick={() => handleAction({ data: content, action: "view" })}
      >
        <div className="flex text-overflow justify-center items-center flex-col">
          <div className="mb-2.5">
            <Avatar user={content} size={AvatarSize.xl} />
          </div>
          <div className="text-overflow w-245 text-center leading-5">
            <Title ellipsis className="card-title" level={3}>
              {content.firstName} {content.lastName}
            </Title>
            <div>
              {getUserRoles({
                member,
                userType: "psp",
                record: content?.userPersona,
              })}
            </div>
            <div className="mb-8 mt-4">{content.emailAddress}</div>
            <div>
              <b>Companies / Business Units:</b>{" "}
              <span>
                {getAssignedCompaniesAndBusinessUnits({ data: content })}
              </span>
            </div>
            <div>
              <b>Pay Schedules:</b> <span>{content.payScheduleCount}</span>
            </div>
          </div>
        </div>
        {!dashboardView && (
          <GridActionComponent
            classname="card-item mt-4 mr-4"
            buttonClassName="animated fadeIn position-absolute-top position-absolute-top-right"
            claims={this.props.claims}
            handleAction={(options: any) =>
              handleAction({ ...options, data: content, record: content })
            }
            editComponentNameGrid={this.getEditComponent({ user: content })}
            hasNoComponents={false}
            viewComponentName={""}
            sendReminderComponentNameGrid={
              !content.activated && reminderComponent
            }
            enableDisableComponentNameGrid={enableDisableComponentNameGrid}
            enableDisableMFAComponentNameGrid={
              enableDisableMFAComponentNameGrid
            }
            mandatoryDeviceEnrolmentGrid={mandatoryDeviceEnrolmentGrid}
            optionalDeviceEnrolmentGrid={optionalDeviceEnrolmentGrid}
            disableDeviceEnrolmentGrid={disableDeviceEnrolmentGrid}
            enabled={content && content.enabled}
            mfaEnabled={content && content.mfaEnable}
            deviceEnrolmentValue={content && content.deviceEnrolmentEnable}
            showAssignBusinessUnits={showAssignBusinessUnit({
              user: content,
              member,
              userType,
              pspId,
            })}
            assignBusinessUnitGrid={
              userType === "psp" ? assignBusinessUnitGrid : ""
            }
            member={member}
            userType={userType}
            record={content}
          />
        )}
      </Card>
    );
  }
}

export default PayrollUserDisplayCard;
