import { Company, Instance, Navigation, PersonaExtended } from '@xemplo/types';

export function mapNavData(data: Navigation): Navigation {
  const personas = data.personas
    .map(makeDefaultUrl)
    .filter((p) => p.defaultUrl != null)
    .sort(byCompanyName);

  const personaLookup = Object.fromEntries(personas.map((p) => [p.id, p]));
  const instancesLookup = Object.fromEntries(
    personas.map((p) => [p.instanceId, p.instance])
  );
  const instances = Object.values(instancesLookup).sort(byInstanceName);
  const companies = personas.map((p) => p.company).filter(Boolean) as Company[];
  const companiesLookup = Object.fromEntries(companies.map((c) => [c.id, c]));

  return {
    user: data.user,
    personas,
    personaLookup,
    instances,
    instancesLookup,
    companies,
    companiesLookup,
  };
}

/**
 * The logged in user may have multiple personas within the same company name
 * and some may not have a company name at all. This function sorts the personas
 * by company name, with those without a company name coming last.
 */
function byCompanyName(a: PersonaExtended, b: PersonaExtended) {
  if (a.company?.name && b.company?.name) {
    // If both have company names, sort by company name
    return a.company.name.localeCompare(b.company.name);
  }
  if (a.company?.name) {
    // If only 'a' has a company name, it should come before 'b'
    return -1;
  }
  if (b.company?.name) {
    // If only 'b' has a company name, it should come before 'a'
    return 1;
  }
  // If neither has a company name, they are considered equal in terms of sorting
  return 0;
}

function byInstanceName(a: Instance, b: Instance) {
  return a.name.localeCompare(b.name);
}

function makeDefaultUrl(persona: PersonaExtended) {
  return {
    ...persona,
    defaultUrl: persona.items[0]?.url ?? persona.instance.modules[0]?.url,
  };
}
