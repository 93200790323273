import React from "react";
import { Radio } from "antd";

export interface RadioGroupYesNoButtonProps {
  value?: number;
  onChange?: (e: any) => void;
}

const RadioGroupYesNoButton: React.FC<RadioGroupYesNoButtonProps> = ({
  value,
  onChange,
}) => (
  <Radio.Group
    className={
      value === 1
        ? "customRadioButtonYesSelected"
        : "customRadioButtonNoSelected"
    }
    onChange={onChange}
    value={value}
  >
    <Radio.Button className="yesOption" value={1}>
      Yes
    </Radio.Button>
    <Radio.Button className="noOption" value={2}>
      No
    </Radio.Button>
  </Radio.Group>
);

export default RadioGroupYesNoButton;
