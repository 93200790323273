import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import ModalUI from "../Modal";

const LoadingModal = ({ visible, loadingText }: any) => (
  <ModalUI visible={visible}>
    <h3 className="m-0 text-center">
      <LoadingOutlined className="color-primary mr-1" />
      <span className="text-lg font-medium">{loadingText}</span>
    </h3>
  </ModalUI>
);

export default LoadingModal;
