/** @knipignore */
export enum GOOGLE_ANALYTICS_PAGE_TITLE {
  Dashboard = "Dashboard",
  CompanyList = "Company List",
  CompanyDetail = "Company Detail",
  CompanyCreate = "Company Create Form",
  CompanyEdit = "Company Edit Form",
  PayrollServiceProviderList = "Payroll Service Providers List",
  PayrollServiceProviderDetail = "Payroll Service Provider Detail",
  PayrollServiceProviderCreate = "Payroll Service Provider Create Form",
  PayrollServiceProviderEdit = "Payroll Service Provider Edit Form",
  BusinessUnitList = "Business Units List",
  BusinessUnitDetail = "Business Unit Detail",
  BusinessUnitCreate = "Business Unit Create Form",
  BusinessUnitEdit = "Business Unit Edit Form",
  BillingServiceProviderList = "Billing Service Providers List",
  BillingServiceProviderDetail = "Billing Service Provider Detail",
  BillingServiceProviderCreate = "Billing Service Provider Create Form",
  BillingServiceProviderEdit = "Billing Service Provider Edit Form",
  PayScheduleList = "Pay Schedules List",
  PayScheduleDetail = "Pay Schedule Detail",
  PayScheduleCreate = "Pay Schedule Create Form",
  PayScheduleEdit = "Pay Schedule Edit Form",
  PayRunList = "Pay Runs List",
  PayRunDetail = "Pay Run Detail",
  PayRunCreate = "Pay Run Create Form",
  PayRunEdit = "Pay Run Edit Form",
  PayRunAuditReport = "Pay Run Audit Report",
  AmendmentList = "Amendments List",
  AmendmentDetail = "Amendment Detail",
  AmendmentCreate = "Amendment Create Form",
  AmendmentEdit = "Amendment Edit Form",
  InvoiceList = "Invoices List",
  VarianceList = "Variances List",
  UserList = "Users List",
  PayrollServiceProviderUserDetail = "Payroll Service Provider User Detail",
  PayrollServiceProviderUserCreate = "Payroll Service Provider User Create Form",
  PayrollServiceProviderUserEdit = "Payroll Service Provider User Edit Form",
  CompanyUserDetail = "Company User Detail",
  CompanyUserCreate = "Company User Create Form",
  CompanyUserEdit = "Company User Edit Form",
  EmailTemplateList = "Email Templates List",
  EmailTemplateEdit = "Email Template Edit Form",
  PersonaSetting = "Persona Settings",
  UserProfile = "User Profile",
}
