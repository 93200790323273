//@ts-nocheck
import React from "react";
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  billingServiceProviderHandler,
  billingServiceProviderAccountConflictsHandler,
} from "../actions/billingServiceProvider";
import { listCurrency } from "../actions/businessunits";
import {
  populateStates,
  countriesLookup,
  timezoneLookup,
  banksLookup,
  cdnLookUpHandler,
} from "../actions/lookup";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { findAuthorizationClaims } from "../../libs/utilities";
import {
  BILLING_PROVIDER_BREAD_CRUMB_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../constants";
import { withRouter } from "../../hooks";

class BillingServiceProvider extends SwiftContainer {
  componentDidMount() {
    this.props.updateHeader({
      header: {
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BillingServiceProviderList,
        routeComponentName: "billingServiceProviderBreadCrumb",
        routesContainer: findAuthorizationClaims({
          claims: this.state.pageClaims?.components,
          name: BILLING_PROVIDER_BREAD_CRUMB_CONTAINER,
        }),
      },
    });
  }
  render() {
    const { Layout } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        billingServiceProviderList={this.props.billingServiceProviderList}
        billingServiceProviderDetail={this.props.billingServiceProviderDetail}
        total={this.props.total}
        loading={this.state.loading}
        listBusinessUnits={(data: any) =>
          this.executeAction(this.props.listBusinessUnits, data)
        }
        pageClaims={pageClaims}
        member={this.props.member}
        populateStates={this.props.populateStates}
        billingServiceProviderHandler={this.props.billingServiceProviderHandler}
        match={{ params: this.props.router.params }}
        countriesLookup={this.props.countriesLookup}
        timezoneLookup={this.props.timezoneLookup}
        listCurrency={this.props.listCurrency}
        banksLookup={this.props.banksLookup}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  billingServiceProviderList: state.billingServiceProvider.list || [],
  total: state.billingServiceProvider.total || 0,
  billingServiceProviderDetail: state.billingServiceProvider.detail || {},
  accountList: state.billingServiceProvider.accountList || [],
  accountTotal: state.billingServiceProvider.accountTotal || 0,
  accountDetail: state.billingServiceProvider.accountDetail || {},
  member: state.member,
});

const mapDispatchToProps = {
  billingServiceProviderHandler,
  populateStates,
  countriesLookup,
  timezoneLookup,
  listCurrency,
  banksLookup,
  updateHeader,
  updateAuthorizationClaim,
  billingServiceProviderAccountConflictsHandler,
  cdnLookUpHandler,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BillingServiceProvider)
);
