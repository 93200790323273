import { Badge } from "../../ui";
import {
  AMENDMENT_APPROVED_ONLY_STATUS,
  AMENDMENT_COMPLETED_STATUS,
  AMENDMENT_PREPARED_STATUS,
} from "../../../constants";

const ApprovedRejectedStatusBadge = ({ status }: { status: number }) => {
  switch (status) {
    case AMENDMENT_APPROVED_ONLY_STATUS:
    case AMENDMENT_PREPARED_STATUS:
      return <Badge.Success>APPROVED</Badge.Success>;
    case AMENDMENT_COMPLETED_STATUS:
      return <Badge.Primary>COMPLETED</Badge.Primary>;
    default:
      return <Badge.Danger>DECLINED</Badge.Danger>;
  }
};

export default ApprovedRejectedStatusBadge;
