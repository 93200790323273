//@ts-nocheck
import { useEffect, useMemo, useState } from "react";
import { Card, Table } from "antd";
import {
  getDifferenceAndVariance,
  getLowerCase,
  isNullEmptyUndefined,
  mustBeArray,
} from "../../../../libs/utilities";
import _ from "lodash";
import {
  CURRENT_EXTERNAL_EARLINGLINES_INDEX,
  PREVIOUS_EXTERNAL_EARLINGLINES_INDEX,
} from "../../../../constants/payrun";
import {
  getEarninglinesColumn,
  getPayrunEarninglinesColumnClassName,
  getVarianceRowClassName,
} from "../../../../libs";
import EarningLinesTotal from "../EarningLines/EarningLinesTotal";
import EarningLinesExtendedRowData from "./EarninglinesExtendedRowData";
import ClaimWrapper from "../../../../Common/claimWrapper";
import { PAYRUN_EARNINGLINES_EXPANDABLE_GENERAL_CONTAINER } from "../../../../constants";

type Props = {
  list?: Array<any>;
  filterApplied?: string;
  payrun?: any;
  invoice: any;
  variance: any;
  loading: boolean;
  list: Array<any>;
  businessunitDetails: any;
  highLightVarianceValue: number;
  showVariance: boolean;
  toggleShowVariance: any;
  showRejectionReason: boolean;
  pageClaims: any;
  className?: string;
};

const EarningLinesTable = ({
  list,
  filterApplied,
  payrun,
  loading,
  businessunitDetails,
  invoice,
  showVariance,
  highLightVarianceValue,
  showRejectionReason,
  pageClaims,
  className,
}: Props) => {
  const [expandedRows, setExpandedRows] = useState<Array<any>>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalPreviousAmount, setTotalPreviousAmount] = useState<number>(0);
  const [totalTaxes, setTotalTaxes] = useState<number>(0);
  const [totalPreviousTaxes, setTotalPreviousTaxes] = useState<number>(0);

  useEffect(() => {
    getTotalValues(
      filterData({
        data: list,
        label: "externalEmployeeName",
        filterApplied,
      })
    );
  }, []);

  useEffect(() => {
    getTotalValues(
      filterData({
        data: list,
        label: "externalEmployeeName",
        filterApplied,
      })
    );
  }, [payrun?.payrunId, payrun?.payrunStatusID, filterApplied, list]);

  // Get total values
  const getTotalValues = (data: any) => {
    const totalAmounts = _.sumBy(
      data,
      (o) =>
        o?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
          ?.totalEmployeeChargeable
    );
    const totalPreviousAmounts = _.sumBy(
      data,
      (o) =>
        o?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
          ?.totalEmployeeChargeable
    );
    const totalTax = _.sumBy(data, (o) => {
      return getTotalTaxesForEachEmployee({ data: o, type: "current" });
    });
    const totalPreviousTax = _.sumBy(data, (o) => {
      return getTotalTaxesForEachEmployee({ data: o, type: "previous" });
    });
    setTotalTaxes(totalTax);
    setTotalPreviousTaxes(totalPreviousTax);
    setTotalAmount(totalAmounts);
    setTotalPreviousAmount(totalPreviousAmounts);
  };

  //   Filter data
  const filterData = ({ data, label, filterApplied }: any) => {
    if (isNullEmptyUndefined(filterApplied)) return data;
    let filteredData = mustBeArray(data).filter((o) =>
      getLowerCase(o[label])?.includes(getLowerCase(filterApplied))
    );
    return filteredData;
  };

  const getTotalTaxesForEachEmployee = ({ data, type }: any) => {
    const period =
      type === "current"
        ? CURRENT_EXTERNAL_EARLINGLINES_INDEX
        : PREVIOUS_EXTERNAL_EARLINGLINES_INDEX;
    const tax = _.sumBy(
      mustBeArray(data?.payrunSummary?.[period]?.fees),
      (o) => {
        return o.taxFeeAmount;
      }
    );
    return tax;
  };

  //   Update expanded rows id in the state
  const updateExpandedRows = (i: any) => {
    let currentExpandedRows = [];
    if (expandedRows.includes(i)) {
      currentExpandedRows = expandedRows.filter((o) => o !== i);
    } else {
      currentExpandedRows = [...mustBeArray(expandedRows), i];
    }
    setExpandedRows([...currentExpandedRows]);
  };

  const getTableData = () => {
    let data = filterData({
      data: list,
      label: "externalEmployeeName",
      filterApplied: filterApplied,
    });
    if (!showVariance) return data;
    const newData = mustBeArray(data).reduce((a: any, b: any) => {
      const values = getDifferenceAndVariance(
        b?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX]
          ?.totalEmployeeChargeable,
        b?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX]
          ?.totalEmployeeChargeable
      );
      return [
        ...a,
        {
          ...b,
          difference: values?.difference,
          variance: values?.variance,
        },
      ];
    }, []);
    return newData;
  };

  const dataSource = useMemo(
    () => getTableData(),
    [
      invoice,
      showVariance,
      highLightVarianceValue,
      showRejectionReason,
      payrun,
      list,
    ]
  );
  const columnClassname = useMemo(
    () => getPayrunEarninglinesColumnClassName({ invoice, showVariance }),
    [invoice, showVariance, list, payrun, highLightVarianceValue]
  );

  const columns = useMemo(
    () =>
      getEarninglinesColumn({
        businessunitDetails,
        invoice,
        showVariance,
        showRejectionReason,
        payrun,
        highLightVarianceValue,
      }),
    [
      invoice,
      payrun,
      highLightVarianceValue,
      showRejectionReason,
      showVariance,
      list,
    ]
  );

  const TotalRow = () => {
    return (
      <EarningLinesTotal
        showVariance={showVariance}
        invoice={invoice}
        totalPreviousAmount={totalPreviousAmount}
        totalAmount={totalAmount}
        totalPreviousTaxes={totalPreviousTaxes}
        totalTaxes={totalTaxes}
      />
    );
  };
  return (
    <Card className={`card ${className}`}>
      <ClaimWrapper
        name={PAYRUN_EARNINGLINES_EXPANDABLE_GENERAL_CONTAINER}
        claims={pageClaims}
      >
        <ClaimWrapper name="payrunEarninglinesExpandableGeneralTable">
          <Table
            dataSource={mustBeArray(dataSource)}
            footer={TotalRow}
            loading={loading}
            className=" default-no-shadow table-footer-0"
            rowKey={(record) => record.externalEmployeeId}
            columns={columns}
            pagination={false}
            defaultExpandAllRows={false}
            rowClassName={(record) =>
              getVarianceRowClassName({
                data: record.variance,
                highLightVarianceValue,
              })
            }
            expandedRowRender={(record, l: any) => {
              const currentPayrunSummary =
                record?.payrunSummary?.[CURRENT_EXTERNAL_EARLINGLINES_INDEX];
              const payrollProcessingTotal =
                currentPayrunSummary?.payrollProcessing?.total;
              const previousPayrunSummary =
                record?.payrunSummary?.[PREVIOUS_EXTERNAL_EARLINGLINES_INDEX];
              const previousPayrollProcessingTotal =
                previousPayrunSummary?.payrollProcessing?.total;
              const processingVariance = getDifferenceAndVariance(
                previousPayrollProcessingTotal,
                payrollProcessingTotal
              ).variance;
              const netWageVariance = getDifferenceAndVariance(
                previousPayrunSummary?.payrollProcessing?.employeeNetEarnings,
                currentPayrunSummary?.payrollProcessing?.employeeNetEarnings
              ).variance;
              const previousFees = mustBeArray(previousPayrunSummary?.fees);
              const previousPayItems = mustBeArray(
                previousPayrunSummary?.payItems
              );
              return (
                <EarningLinesExtendedRowData
                  showVariance={showVariance}
                  invoice={invoice}
                  currentPayrunSummary={currentPayrunSummary}
                  itemKey={l}
                  record={record}
                  columnClassName={columnClassname}
                  highLightVarianceValue={highLightVarianceValue}
                  businessunitDetails={businessunitDetails}
                  expandedRows={expandedRows}
                  processingVariance={processingVariance}
                  previousPayrollProcessingTotal={
                    previousPayrollProcessingTotal
                  }
                  payrollProcessingTotal={payrollProcessingTotal}
                  netWageVariance={netWageVariance}
                  previousPayItems={previousPayItems}
                  updateExpandedRows={updateExpandedRows}
                  previousPayrunSummary={previousPayrunSummary}
                  previousFees={previousFees}
                />
              );
            }}
          />
        </ClaimWrapper>
      </ClaimWrapper>
    </Card>
  );
};

export default EarningLinesTable;
