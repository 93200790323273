import React from "react";
import { Badge } from "../../ui";
import {
  REOCCURRING_STATUS_APPLIED_FAIL_ID,
  REOCCURRING_STATUS_APPLIED_ID,
  REOCCURRING_STATUS_APPLIED_PARTIAL_ID,
  REOCCURRING_STATUS_APPLIED_SUCCESS_ID,
  REOCCURRING_STATUS_CANCELLED_APPLIED_ID,
  REOCCURRING_STATUS_CANCELLED_ID,
  REOCCURRING_STATUS_CREATED_ID,
  REOCCURRING_STATUS_EDITED_APPLIED_ID,
  REOCCURRING_STATUS_EDITED_ID,
  REOCCURRING_STATUS_IN_PROGRESS_ID,
  REOCCURRING_STATUS_REJECTED_ID,
} from "../../../constants";
import UserRole from "../../../constants/userRoles";
import { getUserArea } from "../../../libs";

type Props = {
  statusInPPSID?: number;
};

const KeypayStatusBadge = ({ statusInPPSID }: Props) => {
  switch (statusInPPSID) {
    case 0:
      return <>"N/A"</>;
    case REOCCURRING_STATUS_CREATED_ID:
      return <Badge.Primary>CREATED</Badge.Primary>;
    case REOCCURRING_STATUS_APPLIED_ID:
    case REOCCURRING_STATUS_EDITED_APPLIED_ID:
    case REOCCURRING_STATUS_APPLIED_SUCCESS_ID:
      return <Badge.Success>APPLIED</Badge.Success>;
    case REOCCURRING_STATUS_CANCELLED_ID:
      return (
        <Badge.Warning>
          {[UserRole.CompanyArea].includes(getUserArea())
            ? "CANCELLING IN PROGRESS"
            : "CANCEL REQUESTED"}
        </Badge.Warning>
      );
    case REOCCURRING_STATUS_CANCELLED_APPLIED_ID:
      return <Badge.Danger>CANCELLED</Badge.Danger>;
    case REOCCURRING_STATUS_EDITED_ID:
      return <Badge.Primary>EDITED</Badge.Primary>;
    case REOCCURRING_STATUS_IN_PROGRESS_ID:
      return <Badge.Primary>IN PROGRESS</Badge.Primary>;
    case REOCCURRING_STATUS_APPLIED_FAIL_ID:
      return <Badge.Danger>FAILED</Badge.Danger>;
    case REOCCURRING_STATUS_REJECTED_ID:
      return <Badge.Danger>REJECTED</Badge.Danger>;
    case REOCCURRING_STATUS_APPLIED_PARTIAL_ID:
      return <Badge.Warning>PARTIAL COMPLETED</Badge.Warning>;
    default:
      return <React.Fragment />;
  }
};

export default KeypayStatusBadge;
