export enum ToggleSize {
  Small = 'small',
  Standard = 'standard',
}

export type ToggleProps = {
  value: string | number;
  id: string;
  ariaLabel: string; //Required for accessibility
  checked?: boolean; //initial state of the toggle. It determines whether the toggle is on or off.
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; //callback function that is called when the toggle is clicked.
  name?: string;
  disabled?: boolean; //disable the toggle. When set to true, the toggle cannot be clicked.
  size?: ToggleSize;
};
