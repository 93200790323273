import { INVALID_URL } from './constants/error.constant';

export const hasAuthParams = (location = window.location): boolean => {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);
  if (
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  ) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace('#', '?'));
  return !!(
    (searchParams.get('code') || searchParams.get('error')) &&
    searchParams.get('state')
  );
};

const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(fallbackMessage);
  };

export const loginError = normalizeErrorFn('Login failed');

export const getReturnUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get('returnUrl');
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error(INVALID_URL);
  }
  return fromQuery || `${window.location.origin}/`;
};
