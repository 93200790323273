import { Avatar } from "antd";
import * as React from "react";
import {
  abbreviate,
  abbreviateFirstAndLastName,
  getConditionalResponse,
} from "../../../libs/utilities";

interface LogoAndProfileImageAndInitialsViewProps {
  firstName?: any;
  lastName?: any;
  imageUrl?: string;
  name?: string;
  smallView?: boolean;
  onClick?: () => void;
  className?: string | undefined;
  count?: string | undefined;
  mediumView?: boolean;
}

const LogoAndProfileImageAndInitialsView: React.FC<
  LogoAndProfileImageAndInitialsViewProps
> = ({
  imageUrl,
  firstName,
  lastName,
  name,
  smallView,
  onClick,
  className,
  count,
  mediumView,
}) => {
  const handleClick = (e: any) => {
    if (onClick) {
      onClick();
    }
  };
  const getImageSize = () => {
    return smallView ? "40px" : "140px";
  };
  const getProfileAvatar = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${imageUrl}) `,
          backgroundPosition: "center center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          objectFit: "contain",
          width: getImageSize(),
          height: getImageSize(),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          borderRadius: "50%",
          borderColor: "1px solid #bfbfbf",
        }}
      />
    );
  };

  return (
    <div onClick={(e) => handleClick(e)} className={className}>
      {imageUrl && !count ? (
        <React.Fragment>{getProfileAvatar()}</React.Fragment>
      ) : (
        <Avatar size={smallView ? 40 : mediumView ? 60 : 140}>
          <span
            className={`bold color-dark ${
              smallView ? "text-base" : "text-2xl"
            }`}
          >
            {getConditionalResponse({
              condition: count,
              resp1: count,
              resp2: name
                ? abbreviate(name)
                : abbreviateFirstAndLastName({ firstName, lastName }),
            })}
          </span>
        </Avatar>
      )}
    </div>
  );
};

export default LogoAndProfileImageAndInitialsView;
