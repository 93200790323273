import { PayrunsTableType, PayrunTableFilterQuery } from '@xemplo/gp-types';

export interface PayrunBaseTableProps {
  type?: PayrunsTableType;
  filter?: PayrunTableFilterQuery;
}

export const ColumnIds = {
  Name: 'name',
  PayrunTypeName: 'payrunTypeName',
  AmmendmentCount: 'ammendmentCount',
  PayDate: 'payDate',
  PayrunStatusID: 'payrunStatusID',
  Company: 'company',
  BusinessUnitName: 'businessUnitName',
  Frequency: 'frequency',
  StartDate: 'startDate',
  AmendmentDueDate: 'amendmentDueDate',
} as const;
export type ColumnIds = (typeof ColumnIds)[keyof typeof ColumnIds];

export type ColumnVisibility = Record<ColumnIds, boolean>;
