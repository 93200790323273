import React from "react";
import { mustBeArray } from "../../../libs/utilities";
import { Table } from "antd";

interface SelectableTableProps {
  data: any;
  loading: boolean;
  handleTableChange: (e: any) => void;
  pagination?: any;
  selectedRowKeys?: Array<string | number>;
  selectionType?: "checkbox" | "radio";
  columns: [];
}
const SelectableTable = (props: SelectableTableProps) => {
  const {
    data,
    pagination,
    handleTableChange,
    loading,
    columns,
    selectedRowKeys,
    selectionType,
  } = props;
  const rowSelection = {
    onChange: (tableSelectedRowKeys: any, tableSelectedRows: any) => {
      handleTableChange({
        selectedRowKeys: tableSelectedRowKeys,
        selectedRows: tableSelectedRows,
      });
    },
    selectedRowKeys,
  };
  return (
    <Table
      rowSelection={{
        type: selectionType || "checkbox",
        ...rowSelection,
      }}
      columns={columns}
      dataSource={mustBeArray(data)}
      pagination={pagination || false}
      loading={loading}
      rowKey={(record) => record?.id}
    />
  );
};

export default SelectableTable;
