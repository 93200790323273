import React from "react";
import { Button } from "antd";
import { EditFilled } from "@ant-design/icons";
import { getClaimAndStatus } from "../../../libs/utilities";

interface EditLinkButtonProps {
  name?: string;
  claims?: any;
  className?: string;
  loading?: boolean;
  onClick?: (e: any) => void;
  hasNoComponents?: boolean;
  editText?: string;
}

const EditLinkButton: React.FC<EditLinkButtonProps> = ({
  name,
  claims,
  className,
  loading,
  onClick,
  hasNoComponents,
  editText,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: !hasNoComponents,
  });
  return (
    <Button
      type="link"
      className={`color-primary  ${className || ""} ${
        claimAndStatus.status
      } button-link rounded-[20px]`}
      loading={loading}
      onClick={onClick}
      disabled={claimAndStatus.status === "Visible-Disabled"}
    >
      <EditFilled />
      {editText || "Edit"}
    </Button>
  );
};

export default EditLinkButton;
