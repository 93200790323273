import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTextField } from '@react-aria/textfield';
import classNames from 'classnames';

import { DawnTriangleAlert } from '@xemplo/icons';
import {
  InputFieldSize,
  LabelHelperText,
  MaybeErrorOrDescription,
} from '@xemplo/input-utils';

import * as S from './text-field-v2.styles';
import { TextFieldV2Props } from './text-field-v2.types';

export const TextFieldV2TestId = {
  container: (testId?: string) => `textfield-v2-container-${testId}`,
  input: (testId?: string) => `textfield-v2-input-${testId}`,
  label: (testId?: string) => `textfield-v2-label-${testId}`,
  labelHelperText: (testId?: string) => `textfield-v2-label-helper-text-${testId}`,
};

export const TextFieldV2 = (props: TextFieldV2Props) => {
  const {
    width,
    label,
    inputSize = InputFieldSize.Standard,
    leadingIcon,
    trailingIcon,
    id,
    description,
    isDisabled,
    isReadOnly,
    testId,
    type,
    min,
    max,
    step = 0.01,
    name,
    rules,
    hidden,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { register, watch, getFieldState, formState } = useFormContext();

  const isRequired = !!rules?.required;

  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(
    { ...props, isRequired, label: name },
    inputRef
  );

  const inputValue = watch(name);

  const canShowOptionalLabel =
    !isRequired && !isDisabled && !isReadOnly && inputSize === InputFieldSize.Large;

  const reactHookFormProps = register(name, {
    ...rules,
    disabled: isDisabled,
    min,
    max,
    onChange: props?.onChange,
  });

  const { invalid, error } = getFieldState(name, formState);

  return (
    <S.Wrapper $width={width} $hidden={hidden}>
      <S.Container
        className={classNames(
          { 'input-disabled': isDisabled },
          { 'input-readonly': isReadOnly },
          { 'input-has-error': invalid && !isDisabled },
          { 'has-label': !!label },
          { 'has-value': !!inputValue }
        )}
        data-testid={TextFieldV2TestId.container(testId)}
        $inputSize={inputSize}
        onClick={() => inputRef.current?.focus()}
      >
        {leadingIcon}

        <S.Input
          id={id}
          {...inputProps}
          className={classNames({
            'has-label': !!label,
            'has-value': !!inputValue,
          })}
          required={isRequired}
          readOnly={isReadOnly}
          min={type === 'number' ? min : undefined}
          max={type === 'number' ? max : undefined}
          step={type === 'number' ? step : undefined}
          data-testid={TextFieldV2TestId.input(testId)}
          hidden={hidden}
          aria-hidden={hidden}
          {...reactHookFormProps}
          ref={(e) => {
            reactHookFormProps.ref(e);
            inputRef.current = e;
          }}
          onChange={(e) => {
            reactHookFormProps.onChange(e);
            inputProps.onChange?.(e);
          }}
          onWheel={props?.onWheel}
        />
        <S.Label
          {...labelProps}
          className={classNames({
            'has-label': !!label,
            'has-value': !!inputValue,
            'has-leading-icon': !!leadingIcon,
          })}
          data-testid={TextFieldV2TestId.label(testId)}
        >
          {label}{' '}
          {canShowOptionalLabel && (
            <LabelHelperText
              data-testid={TextFieldV2TestId.labelHelperText(testId)}
              className="label-helper-text"
            >
              (optional)
            </LabelHelperText>
          )}
        </S.Label>
        {invalid ? <DawnTriangleAlert /> : trailingIcon}
      </S.Container>

      <MaybeErrorOrDescription
        error={invalid}
        errorMessage={
          error?.message ? error.message : inputRef.current?.validationMessage
        }
        description={description}
        descriptionProps={descriptionProps}
        errorMessageProps={errorMessageProps}
        testId={testId}
      />
    </S.Wrapper>
  );
};
