import { message } from "antd";
import { AxiosResponse } from "axios";
import { errorHandler } from "./utilities";

export const handleRequest = ({
  response,
  successMessage = "",
  hasValidationErrors = false,
  oldModel = false,
}: any) => {
  if (response?.status) {
    if (successMessage) {
      message.success(successMessage);
    }
    return true;
  }
  errorHandler({
    hasValidationErrors,
    oldModel,
    response,
  });
  return false;
};

// Take the response the return the rows of data if they exist.
export const parseResponseData = (response: AxiosResponse<any>) => {
  return response?.data?.result?.hasOwnProperty("rows")
    ? response.data.result.rows
    : response?.data?.result;
};
