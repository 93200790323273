import { LogoSize, LogoVariant, LogoXemplo } from '@xemplo/icons';

import { CenteredLoaderDots } from './centered-loader-dots';
import { XemploLoaderContainer } from './loader.styles';

export function XemploLoader() {
  return (
    <XemploLoaderContainer>
      <CenteredLoaderDots />
      <LogoXemplo variant={LogoVariant.gray} size={LogoSize.xs} />
    </XemploLoaderContainer>
  );
}
