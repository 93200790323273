export const NoResultsTestId = {
  container: 'no-results-container',
  title: 'no-results-title',
  message: 'no-results-message',
};

export const NoResultsMockData = {
  title: 'No Results',
  message: 'Please check your spelling',
};
