const LIST_BUSINESS_PAYSCHEDULE = "LIST_BUSINESS_PAYRUN";
const PAYSCHEDULE_USER_LIST = "PAYSCHEDULE_USER_LIST";
const PAYSCHEDULE_USER_ADD = "PAYSCHEDULE_USER_ADD";
const PAYSCHEDULE_USER_REMOVE = "PAYSCHEDULE_USER_REMOVE";
const LIST_PAYSCHEDULE_DETAILS = "LIST_PAYSCHEDULE_DETAILS";
const LIST_BUSINESS_PAYSCHEDULE_ACTIVE = "LIST_BUSINESS_PAYSCHEDULE_ACTIVE";
const LIST_BUSINESS_PAYSCHEDULE_DEACTIVE = "LIST_BUSINESS_PAYSCHEDULE_DEACTIVE";
const payscheduleAction = {
  LIST_BUSINESS_PAYSCHEDULE,
  PAYSCHEDULE_USER_LIST,
  PAYSCHEDULE_USER_ADD,
  PAYSCHEDULE_USER_REMOVE,
  LIST_PAYSCHEDULE_DETAILS,
  LIST_BUSINESS_PAYSCHEDULE_ACTIVE,
  LIST_BUSINESS_PAYSCHEDULE_DEACTIVE,
};

export default payscheduleAction;
