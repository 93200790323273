//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Select, message } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import _ from "lodash";

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class PayCategory extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  validateChange = (value) => {
    const { selectedBaseSalary, editMode, payCategory } = this.props;
    let valueAlreadySelected;
    let duplicateCategoryName;
    if (editMode) {
      valueAlreadySelected = _.find(
        mustBeArray(selectedBaseSalary),
        (o) => value === o.id
      );
      duplicateCategoryName = valueAlreadySelected && valueAlreadySelected.name;
    } else {
      valueAlreadySelected = mustBeArray(selectedBaseSalary).includes(value);
      duplicateCategoryName = _.find(payCategory, (o) => o.id === value).name;
    }
    if (valueAlreadySelected) {
      message.warning(
        <span>
          {duplicateCategoryName} has already been selected in <b>Base Pay</b>
        </span>
      );
    }
  };
  render() {
    const { payCategory, formLayout } = this.props;
    const { getFieldDecorator } = this.props.form;
    let defaultChecked = [];
    if (payCategory) {
      defaultChecked = mustBeArray(payCategory).reduce(
        (a, b) => _.concat(a, b.selected ? [b.id] : []),
        []
      );
    }
    return (
      <React.Fragment>
        <FormItem label="Pay Category(ies):" {...formLayout}>
          {getFieldDecorator("payCategory", {
            rules: [
              {
                required: false,
              },
            ],
            initialValue: defaultChecked,
          })(
            <Select
              placeholder="Select"
              showSearch
              optionFilterProp={"name"}
              mode="multiple"
              onSelect={(value) => this.validateChange(value)}
            >
              {mustBeArray(payCategory).map((category) => {
                return (
                  <Option
                    key={category.id}
                    name={category.name}
                    value={category.id}
                    disabled={
                      !_.isEmpty(
                        this.props.form.getFieldValue("payCategory")
                      ) &&
                      mustBeArray(
                        this.props.form.getFieldValue("payCategory")
                      ).includes("all")
                    }
                  >
                    {category.name}
                  </Option>
                );
              })}
            </Select>
          )}
        </FormItem>
      </React.Fragment>
    );
  }
}

export default PayCategory;
