import { DateSegment } from '@react-stately/datepicker';

import { DateFormats } from '../date-field/date-field.types';

export const useDateFieldSegment = (
  segments: DateSegment[],
  format: DateFormats
): { segments: DateSegment[] } => {
  const daySegment = segments.find((segment) => segment.type === 'day');
  const monthSegment = segments.find((segment) => segment.type === 'month');
  const yearSegment = segments.find((segment) => segment.type === 'year');
  const separatorSegment = segments.find((segment) => segment.type === 'literal');

  if (!daySegment || !monthSegment || !yearSegment || !separatorSegment) {
    throw new Error('Invalid date field segments');
  }

  const formattedDaySegment = {
    ...daySegment,
    text: daySegment.text.padStart(2, '0'),
  };
  const formattedMonthSegment = {
    ...monthSegment,
    text: monthSegment.text.padStart(2, '0'),
  };
  const formattedSeparatorSegment = { ...separatorSegment, text: '/' };

  if (format === DateFormats.usa) {
    return {
      segments: [
        formattedMonthSegment,
        formattedSeparatorSegment,
        formattedDaySegment,
        formattedSeparatorSegment,
        yearSegment,
      ],
    };
  }

  return { segments };
};
