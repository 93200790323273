// @ts-nocheck
class headerAction {
  _: any;
  _data: any;
  constructor() {
    if (!headerAction.instance) {
      this._data = {};
      headerAction.instance = this;
    }

    return headerAction.instance;
  }

  set({ title, action, entity, enableBack }: any) {
    this._data.title = title;
    this._data.action = action;
    this._data.entity = entity;
    this._data.enableBack = enableBack;
  }

  get() {
    return this._data;
  }
}

const instance = new headerAction();
Object.freeze(instance);

export default instance;
