import {
  GRID_COLUMN_INDEX,
  GRID_PAGE_NUMBER_INDEX,
  GRID_ROW_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  GRID_VIEW_VALUE,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  LIST_PINNABLE_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  PAGINATION_PAGENUMBER,
  PINNABLE_LIST_PERSONALIZATION,
  SINGLE_LINE_GRID_VALUE,
  SORTING_NOT_REQUIRED,
  SORTING_REQUIRED,
  SORT_COLUMN_INDEX,
  SORT_ORDER_INDEX,
  TABLE_PERSONALIZATIONS,
} from "../constants/defaultClaims";
import { ComponentClaimType, ListContainerClaimType } from "../types";
import {
  baseUrlMatches,
  findAuthorizationClaims,
  getCurrentGridPageSize,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  isComponentVisible,
  isNullEmptyUndefined,
  mustBeArray,
} from "./utilities";

// Component is personalizable
export const isComponentPersonalizable = ({
  component,
}: {
  component?: ComponentClaimType;
}) => {
  return component?.personalizable === "true";
};

// Return rows or default rows for grid view
const getGridRows = (row?: string | number) => {
  return isNullEmptyUndefined(row) ? 5 : Number(row);
};

// Return columns or default columns for grid view
const getGridCols = (col?: string | number) => {
  return isNullEmptyUndefined(col) ? 4 : Number(col);
};

export const getDefaultPage = ({ page }: { page: string | number }) => {
  if (!Number.isNaN(Number(page))) return Number(page);
  return 1;
};

export const getDefaultPerPage = ({
  perPage,
  defaultPerPage = 10,
}: {
  perPage: string | number;
  defaultPerPage?: number;
}) => {
  if (!Number.isNaN(Number(perPage))) return Number(perPage);
  return defaultPerPage;
};

// List Container Personalization
export const getListContainerPersonalizations = (
  listContainer: ListContainerClaimType
) => {
  const personalizations = mustBeArray(listContainer?.personalizations);
  const gridViewValue =
    personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX]?.value;
  let sortedPersonalization = {
    singleLineGridView: gridViewValue === SINGLE_LINE_GRID_VALUE,
    gridView: gridViewValue === GRID_VIEW_VALUE,
    gridable: listContainer?.gridable === "true",
  };
  return sortedPersonalization;
};

// Grid Personalization
export const getGeneralGridPersonalizations = (generalGrid: any) => {
  const personalizationData = mustBeArray(generalGrid?.personalizations);
  return {
    col: getGridCols(personalizationData?.[GRID_COLUMN_INDEX]?.value),
    row: getGridRows(personalizationData?.[GRID_ROW_INDEX]?.value),
    page: getDefaultPage({
      page: personalizationData?.[GRID_PAGE_NUMBER_INDEX]?.value,
    }),
    sortInfo: {
      order: personalizationData?.[GRID_SORT_ORDER_INDEX]?.value,
      field: personalizationData?.[GRID_SORT_COLUMN_INDEX]?.value,
    },
    isVisible: isComponentVisible({ claim: generalGrid }),
  };
};

export const getGridRequestOptions = (grid: any) => {
  return {
    per_page: getCurrentGridPageSize({ grid }),
    page: getDefaultPage(grid?.page),
    ...getSortInfo({ sortInfo: grid?.sortInfo }),
  };
};

// Table Personalization
export const getGeneralTablePersonalizations = (
  generalTable: any,
  defaultPerPage = 20
) => {
  const personalizationData = mustBeArray(generalTable?.personalizations);
  return {
    perPage: getDefaultPerPage({
      perPage: personalizationData?.[PAGE_SIZE_INDEX]?.value,
      defaultPerPage,
    }),
    page: getDefaultPage({
      page: personalizationData?.[PAGE_NUMBER_INDEX]?.value,
    }),
    sortInfo: {
      order: personalizationData?.[SORT_ORDER_INDEX]?.value,
      field: personalizationData?.[SORT_COLUMN_INDEX]?.value,
    },
  };
};

export const getTableRequestOptions = (table: any) => {
  return {
    per_page: getDefaultPerPage({ perPage: table?.perPage }),
    page: getDefaultPage({ page: table?.page }),
    ...getSortInfo({ sortInfo: table?.sortInfo }),
  };
};

// Get Sort Info
const getSortInfo = ({
  sortInfo,
  sortingType = SORTING_REQUIRED,
}: {
  sortInfo: { field?: string; order?: string };
  sortingType?: string;
}) => {
  if (
    sortInfo?.field &&
    sortInfo?.order &&
    sortingType !== SORTING_NOT_REQUIRED
  ) {
    return {
      sort: {
        [sortInfo.field]: sortInfo.order,
      },
    };
  }
  return {};
};

// Component is pinned
export const componentIsPinned = ({
  pinnedContainers,
  name,
  targetEndpoint,
}: {
  pinnedContainers: any;
  name: string;
  targetEndpoint?: string;
}) => {
  return mustBeArray(pinnedContainers).some((o: any) => {
    return (
      o?.name === name &&
      baseUrlMatches({ url1: o.targetEndpoint, url2: targetEndpoint })
    );
  });
};

// Component is fixed in the dashboard
export const componentIsStatic = (claim: any) => {
  return !!(claim && !claim.draggable && !claim.resizeable);
};

// Get Filter Personalization data
export const getFilterContainerPersonalizationData = ({
  personalizationData,
}: any) => {
  return { value: personalizationData?.value };
};

// Get Request options for Grid, table and single line grid view
export const getGridTableRequestOptions = ({
  filters = {},
  queryParams = {},
  gridView,
  grid,
  table,
  singleLineGridView = false,
}: any) => {
  const q = { ...queryParams, ...filters };
  if (singleLineGridView) {
    return { page: 1, per_page: 500, q };
  }
  if (gridView) {
    const per_page = getCurrentGridPageSize({ grid });
    const sortings = getCurrentGridSortInfo({ grid });
    return {
      page: grid?.page,
      per_page,
      ...sortings,
      q,
    };
  }
  const sortings = getCurrentTableSortInfo({ sortInfo: table?.sortInfo });
  return {
    page: table?.page,
    per_page: table?.perPage,
    ...sortings,
    q,
  };
};

// Set table personalization data
export const setTablePersonalizationData = ({
  pageNumber,
  pageSize,
  sortColumn,
  sortOrder,
}: {
  pageNumber: Number;
  pageSize: Number;
  sortColumn?: string;
  sortOrder?: string;
}) => {
  let items: Array<any> = [...TABLE_PERSONALIZATIONS];
  items[PAGE_NUMBER_INDEX].value = pageNumber;
  items[PAGE_SIZE_INDEX].value = pageSize;
  items[SORT_COLUMN_INDEX].value = sortColumn || "";
  items[SORT_ORDER_INDEX].value = sortOrder || "";
  return items;
};

// Set List Container personalization data
export const setListContainerPersonalizationData = ({
  gridView,
  isPinned,
}: {
  gridView: boolean;
  isPinned: boolean;
}) => {
  let items: Array<any> = [...PINNABLE_LIST_PERSONALIZATION];
  items[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = gridView;
  items[LIST_PERSONALIZATION_PAGINATION_INDEX].value = PAGINATION_PAGENUMBER;
  items[LIST_SORTING_REQUIRED_INDEX].value = SORTING_REQUIRED;
  items[LIST_PINNABLE_INDEX].value = isPinned;
  return items;
};

// Common function to create payload for persona updates
export type PersonalizationType = {
  valueDataType: string;
  value?: string | number;
};
export const generatePersonaUpdatePayload = ({
  personalizations, //current payload - array of individual personalization
  personalization, //new persona value to be updated
  claims, //component claims
  name, //name of the component to be updated
  label = "personalization", //listContainer has personalizations (an array). Rest have personalization (object)
}: {
  personalizations: Array<{
    value: PersonalizationType;
    label: string;
    id: string;
    name: string;
  }>;
  personalization: PersonalizationType;
  claims: Array<any>;
  name: string;
  label?: string;
}) => {
  const updatedComponent = findAuthorizationClaims({
    claims,
    name,
  });
  // Breaks the DOM if updatedComponent doesn't exist or if there is an error
  if (updatedComponent?.id) {
    return [
      ...personalizations,
      {
        value: personalization,
        label,
        id: updatedComponent.id,
        name: updatedComponent.name,
      },
    ];
  }
  return [...personalizations];
};
