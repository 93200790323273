import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PayrollDynamicRoutes } from '@xemplo/gp-routes';
import { CenteredLoaderDots } from '@xemplo/loader';
import { useGetPayrunAmendmentsQuery } from '@xemplo/payrun-query';

import { AmendmentCard } from './amendment-card';
import * as S from './payrun-worker-amendments.styles';
import { PayrunWorkerAmendmentsProps } from './payrun-worker-amendments.types';

export const PayrunWorkerAmendmentsTestId = {
  container: 'payrun-worker-amendments-container',
  amendmentCard: 'amendment-card',
};

export function PayrunWorkerAmendments(props: Readonly<PayrunWorkerAmendmentsProps>) {
  const { payrunId, workerId } = props;
  const navigate = useNavigate();

  const { data, isLoading } = useGetPayrunAmendmentsQuery({
    id: payrunId ?? null,
    requestParams: {
      q: { name: workerId },
    },
  });

  const handleAmendmentClick = useCallback(
    (amendmentId: string, workerId: string, e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      navigate(PayrollDynamicRoutes.WorkerAmendmentByAmendmentId(workerId, amendmentId));
    },
    [navigate]
  );
  if (isLoading) return <CenteredLoaderDots />;

  if (!payrunId || !workerId || data?.result?.total === 0) return null;

  return (
    <>
      <S.Divider />
      <S.Container data-testid={PayrunWorkerAmendmentsTestId.container}>
        <S.Title>Amendments ({data?.result.total})</S.Title>
        {data?.result?.rows?.map((item) => {
          return (
            <AmendmentCard
              amendment={item}
              key={item.amendmentId}
              testId={PayrunWorkerAmendmentsTestId.amendmentCard}
              onClick={() => handleAmendmentClick(item.amendmentId, workerId)}
            />
          );
        })}
      </S.Container>
    </>
  );
}

export default PayrunWorkerAmendments;
