import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { formatDate, HEADER_DATE_FORMAT } from '@xemplo/date-time';
import { useGetPayrunHeaderQuery } from '@xemplo/payrun-query';

import { PayrunTitleSkeleton } from './payrun-title.skeleton';
import * as S from './payrun-title.styles';

export const PayrunTitle = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetPayrunHeaderQuery({
    id: id ?? null,
  });

  const {
    name,
    businessUnitName,
    payOccurenceName,
    payCycleStartDate,
    payCycleEndDate,
    payDate,
  } = data?.result ?? {};

  const payCycle = useMemo(() => {
    if (!payCycleStartDate || !payCycleEndDate) return '';
    return `${formatDate({
      value: payCycleStartDate,
      format: HEADER_DATE_FORMAT,
    })} - ${formatDate({
      value: payCycleEndDate,
      format: HEADER_DATE_FORMAT,
    })}`;
  }, [payCycleStartDate, payCycleEndDate]);

  const payDateFormatted = useMemo(() => {
    if (!payDate) return '';
    return formatDate({ value: payDate, format: HEADER_DATE_FORMAT });
  }, [payDate]);

  if (isLoading || !data) return <PayrunTitleSkeleton />;
  return (
    <S.TitleContainer>
      <S.NameContainer>
        <S.BusinessUnitName>{businessUnitName}</S.BusinessUnitName>
        <S.PayrunName>{name}</S.PayrunName>
      </S.NameContainer>
      <S.Details>
        <S.DetailsItem>
          <S.DetailsItemLabel>Schedule</S.DetailsItemLabel>
          <S.DetailsItemValue>{payOccurenceName ?? 'Adhoc'}</S.DetailsItemValue>
        </S.DetailsItem>
        <S.DetailsItem>
          <S.DetailsItemLabel>Pay Cycle</S.DetailsItemLabel>
          <S.DetailsItemValue>{payCycle}</S.DetailsItemValue>
        </S.DetailsItem>
        <S.DetailsItem>
          <S.DetailsItemLabel>Pay Date</S.DetailsItemLabel>
          <S.DetailsItemValue>{payDateFormatted}</S.DetailsItemValue>
        </S.DetailsItem>
      </S.Details>
    </S.TitleContainer>
  );
};

export default PayrunTitle;
