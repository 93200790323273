import { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import * as S from './icon-button.styles';
import { IconButtonProps, IconButtonSize } from './icon-button.types';

/** The size map, the first number represents the font-size, the second the button heigh/width */
const SizeMap = new Map([
  [IconButtonSize.Small, [16, 32]],
  [IconButtonSize.Medium, [24, 40]],
  [IconButtonSize.Standard, [32, 48]],
  [IconButtonSize.Large, [40, 64]],
]);

export const IconButtonTestId = {
  Button: (id: string) => `icon-button-${id}`,
};

export const IconButton = forwardRef(
  (props: PropsWithChildren<IconButtonProps>, ref?: React.Ref<HTMLButtonElement>) => {
    // Stupid typescript doesn't know that the default value is set when props.size is undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const size = SizeMap.get(props.size ?? IconButtonSize.Medium)!;
    return (
      <S.IconButton
        type="button"
        ref={ref}
        id={props.id}
        $size={size}
        $naked={props.naked}
        aria-label={props.ariaLabel}
        data-testid={IconButtonTestId.Button(props.id)}
        className={classNames(props.className, props.variant, {
          naked: props.naked,
        })}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </S.IconButton>
    );
  }
);

export default IconButton;
