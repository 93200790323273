import { AvatarSize } from '@xemplo/avatar';
import { getAssignedWorker, getAssignedWorkerDisplayName } from '@xemplo/gp-utils';
import { DawnChartPath } from '@xemplo/icons';

import * as S from './payrun-amendment-detail.styles';
import { PayrunAmendmentDetailHeaderProps } from './payrun-amendment-detail.types';

export const PayrunAmendmentDetailHeader = (props: PayrunAmendmentDetailHeaderProps) => {
  const assignTo = getAssignedWorker(props.assignTo);

  return (
    <S.Container>
      {assignTo?.[0] ? (
        <S.Avatar
          user={assignTo?.[0]}
          size={AvatarSize.l}
          data-chromatic="ignore"
          customText={assignTo?.length > 1 ? assignTo.length.toString() : undefined}
        />
      ) : (
        <S.LeadIconWrapper>
          <DawnChartPath />
        </S.LeadIconWrapper>
      )}
      <S.Details>
        <S.Name>{getAssignedWorkerDisplayName(props.assignTo)}</S.Name>
        {/* <S.Title>{title}</S.Title> TODO: Out of scope for initial release*/}
        {/* <S.Salary>{salary}</S.Salary> TODO: Out of scope for initial release */}
      </S.Details>
    </S.Container>
  );
};
