import { ColumnDef } from '@tanstack/react-table';

import { GpWorker } from '@xemplo/gp-types';
import { Colour } from '@xemplo/style-constants';
import { NameCell } from '@xemplo/table';

export const payrunEmployeeListColumns: ColumnDef<GpWorker>[] = [
  {
    id: 'name',
    accessorKey: 'id',
    header: 'Employee',
    enableHiding: true,
    cell: ({ row }) => <NameCell {...row.original} avatarColor={Colour.Orange[200]} />,
  },
];
