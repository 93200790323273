// @ts-nocheck
import React, { useState, MouseEvent } from "react";
import { Collapse, Modal, Checkbox } from "antd";
import SimpleButton from "../../ClaimComponents/Button/SimpleButton";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import classNames from "classnames";
import { AppliesTo } from "../../ui";
import AmendmentDetails from "../../../Organization/components/payrun/amendmentDetails";
import { AmendmentType } from "../../../types";
import { ContainerClaimType } from "../../../types/claims";
import { getStatusClassName } from "../../../libs";

const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

type Props = {
  title?: string;
  handleClose: () => void;
  visible: boolean;
  data: Array<AmendmentType>;
  claim: ContainerClaimType;
  handleAttachAmendment: (e: any) => void;
  attachLoading: boolean;
};

const titleDefault = "Unassigned Pay Run Amendments";

const AttachAmendmentModal = ({
  title = titleDefault,
  handleClose,
  visible,
  data,
  claim,
  handleAttachAmendment,
  attachLoading,
}: Props) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setCheckAll(list.length === data.length);
  };

  const onCheckAllChange = () => {
    const amendmentIds = data.map((o) => o.amendmentId);
    setCheckedList(!checkAll ? amendmentIds : []);
    setCheckAll(!checkAll);
  };

  return (
    <Modal
      title={
        <div className="text-center">
          <div>{title}</div>
        </div>
      }
      width={700}
      visible={visible}
      onCancel={handleClose}
      className={classNames(getStatusClassName({ claim }))}
      footer={[
        <SimpleButton
          key="checkAll"
          type="primary"
          className="mr-3"
          onClick={onCheckAllChange}
          buttonText={checkAll ? "Reset" : "Select All"}
          claims={claim}
          hasNoComponents={false}
          name="payrunAttachNextPayrunAmendmentSelectButton"
        />,
        <SimpleButton
          key="include"
          className={classNames({ "bg-success": checkedList.length > 0 })}
          onClick={() => handleAttachAmendment({ checkedList })}
          buttonText="Include"
          buttonDisabled={checkedList.length === 0}
          claims={claim}
          hasNoComponents={false}
          name="payrunAttachNextPayrunAmendmentIncludeButton"
          loading={attachLoading}
        />,
      ]}
    >
      <p className="mb-6">
        The following pay run amendments have not been linked to a pay run.
        Would you like to include them in this pay run?
      </p>
      <CheckboxGroup onChange={onChange} value={checkedList} className="block">
        <Collapse>
          {data?.map((item: AmendmentType, index: number) => (
            <Panel
              header={
                <>
                  <div className="inline-block w-2/5">
                    {item.amendmentSubTypeName
                      ? item.amendmentSubTypeName
                      : item.amendmentTaskTypeName}
                  </div>
                  <div className="inline-block ml-12">
                    <AppliesTo assignTo={item.assignTo} />
                  </div>
                </>
              }
              key={index}
              className="mb-3"
              extra={
                <Checkbox
                  key={item.amendmentId}
                  value={item.amendmentId}
                  className="ml-auto"
                  onClick={(event: MouseEvent) => event.stopPropagation()}
                >
                  <span className="sr-only">{item.amendmentSubTypeName}</span>
                </Checkbox>
              }
            >
              <AmendmentDetails
                amendment={item}
                businessunitDetails
                viewAction={false}
              />
            </Panel>
          ))}
        </Collapse>
      </CheckboxGroup>
    </Modal>
  );
};

export default AttachAmendmentModal;
