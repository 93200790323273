// @ts-nocheck
import { requestBuilder } from "../../libs/utilities";
import { http, ResponseHandler } from "../../libs";
import APIHandler from "../../constants/apiUrl";
import {
  PERMANENT_AMENDMENT_TYPE,
  PAYRUN_AMENDMENT_TYPE,
  REOCCURRING_AMENDMENT_TYPE,
  COMP_USER_DASH_AMENDMENT_TYPE,
} from "../../constants/payrun";
import {
  OptionsType,
  AmendmentBulkCreatePayloadType,
  GeneralAmendmentRejectPayload,
} from "../../types";

export function generalAmendmentHandler({
  id,
  cancelToken,
  options = {},
  payload,
  action,
  type = null,
  targetEndpoint,
}: {
  id?: string;
  cancelToken?: any;
  options?: any;
  payload?: any;
  action?: string;
  type?: string;
  targetEndpoint?: string;
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let response = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "get":
          url = APIHandler.constructEndpoint({
            endpoint: "GENERAL_AMENDMENTS_DETAIL_URL",
            options: { id },
          });
          response = await http.get(url, cancelToken);
          break;
        case "list":
          url = APIHandler.constructEndpoint({
            endpoint: "GENERAL_AMENDMENTS_URL",
          });
          switch (type) {
            case PERMANENT_AMENDMENT_TYPE:
              dispatch_type = "LIST_PERMANENT_AMENDMENT";
              break;
            case PAYRUN_AMENDMENT_TYPE:
              dispatch_type = "LIST_PAYRUN_AMENDMENT";
              break;
            case REOCCURRING_AMENDMENT_TYPE:
              dispatch_type = "LIST_REOCCURRING_AMENDMENT";
              break;
            case COMP_USER_DASH_AMENDMENT_TYPE:
              dispatch_type = "LIST_COMP_USER_DASH_AMENDMENTS";
              break;
            default:
              break;
          }

          response = await requestBuilder(
            null,
            targetEndpoint || url,
            targetEndpoint ? {} : options,
            cancelToken
          );
          break;
        case "create":
          url = APIHandler.constructEndpoint({
            endpoint: "GENERAL_AMENDMENTS_CREATE_URL",
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "delete":
          url = APIHandler.constructEndpoint({
            endpoint: "GENERAL_AMENDMENTS_DETAIL_URL",
            options: { id },
          });
          response = await http.del(url, cancelToken);
          break;
        case "getHistory":
          url = APIHandler.constructEndpoint({
            endpoint: "GENERAL_AMENDMENTS_HISTORY_URL",
            options: { id },
          });
          response = await http.get(url, cancelToken);
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getGeneralAmendmentList({
  targetEndpoint,
  options = {},
  cancelToken,
}: {
  targetEndpoint?: string;
  options?: OptionsType;
  cancelToken: any;
}) {
  return () =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "GENERAL_AMENDMENTS_URL",
      });
      const response = await requestBuilder(
        null,
        targetEndpoint ?? url,
        targetEndpoint ? {} : options,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data?.result, null)
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export const rejectGeneralAmendment = ({
  id,
  data,
}: {
  id: string;
  data: GeneralAmendmentRejectPayload;
}) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "GENERAL_AMENDMENTS_REJECT_URL",
        options: { amendmentId: id },
      });
      const response = await http.post(url, data);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response?.data?.result));
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
};

// create bulk amendments
export function createBulkAmendments({
  payload,
}: {
  payload: AmendmentBulkCreatePayloadType;
}) {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: "GENERAL_AMENDMENTS_BULK_CREATE_URL",
    });
    const response = await http.post(url, payload);
    if (ResponseHandler.isValidStatus(response)) {
      return ResponseHandler.validObject(response.data, "ADD_AMMENDMENT");
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
}
