//@ts-nocheck
import React from "react";
import { EditFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  Tooltip,
  Typography,
  Avatar,
  Badge,
} from "antd";
import { ContractIcon, LgEllipsis } from "../../../Common/customicons";
import CountryCoordinates from "../../../Common/countries";
import Flag from "react-world-flags";

const { Title } = Typography;

const XeroMappingDisplayCard = ({
  handleAction,
  content,
  claims,
  component,
}: any) => {
  const country =
    content && content.country && CountryCoordinates.getCode(content.country);
  return (
    <Card
      className="card hand card-prop"
      bordered={false}
      onClick={(e) => handleAction({ data: content, action: "view" })}
    >
      <div className="position-relative py-3 px-0">
        <div className={"mb-2.5 px-[50px] position-relative"}>
          <div className="position-relative">
            <Tooltip placement="top" title={content && content.templateName}>
              <Title ellipsis className="card-title" level={3}>{`${
                content && content.templateName
              }`}</Title>
            </Tooltip>
          </div>
          {/**TODO: Replace with @xemplo/avatar lib  */}
          <Avatar
            className="position-absolute-top position-absolute-top-left"
            size={36}
          >
            <ContractIcon className="text-lg" />
          </Avatar>
        </div>
        <div className="flex justify-between mb-1 text-xs mt-8">
          <span>{`Description: ${content.description}`}</span>
        </div>
        {/* Status badge */}
        <div>
          {content && !content.isInActive ? (
            <Badge count={"Active"} className={"text-xs badge badge-success"} />
          ) : (
            <Badge
              count={"InActive"}
              className={"text-xs badge badge-danger"}
            />
          )}
        </div>
      </div>
      <div className={"flags"}>
        {country && (
          <Tooltip placement="topLeft" title={content && content.country}>
            <Flag
              code={country}
              width={18}
              height={14}
              className="float-left"
            />
          </Tooltip>
        )}
      </div>
      <Dropdown
        className="card-item mt-4 mr-4"
        trigger={["click"]}
        overlay={
          <Menu>
            <Menu.Item
              onClick={(e) => handleAction({ data: content, action: "edit" })}
            >
              {/* <ClaimWrapper
                                name={component}
                                claims={claims}
                            > */}
              <div>
                <EditFilled />
                <span> View</span>
              </div>
              {/* </ClaimWrapper> */}
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
      >
        <Button
          onClick={(e) => e.stopPropagation()}
          className={
            " animated fadeIn btn-dropdown-action ellipsis flex items-center px-2 position-absolute-top position-absolute-top-right"
          }
        >
          <LgEllipsis />
        </Button>
      </Dropdown>
    </Card>
  );
};

export default XeroMappingDisplayCard;
