//@ts-nocheck
import React, { Component } from "react";
import AdhocPayrun from "../../../components/payrun/create";

type State = any;

class CreateAdhocPayrun extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    return <AdhocPayrun {...this.props} />;
  }
}

export default CreateAdhocPayrun;
