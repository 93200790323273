//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../libs/table";
import SimpleTableContainer from "../../Common/ClaimContainers/SimpleTableContainer";
 import { getPayrollProviderListColumns } from "../../libs/utilities";

type State = any;

class PayrollProviderList extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: []
    };
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = sorter.field ? {
      page: pagination.current,
      order: sorter.order ? (sorter.order === "ascend") ? "asc" : "desc" : sorter.order,
      field: sorter.field === "businessUnitCount" ? "businessunitserviced" : sorter.field,
    }
      :
      {
        page: pagination.current
      };
    this.props.handleTableChange({ options });
  };

  render() {
    const pagination = {
      total: this.props.totalPages,
      current: this.props.current,
      pageSize: this.props.per_page || 20,
      hideOnSinglePage: true
    };
    const { sortInfo, claims, component, dashboardView, data } = this.props;
    const colTemplate = getPayrollProviderListColumns({ dashboardView, sortInfo, claims, component })
    const column = tableColumnGenerator(colTemplate);
    return (

      <SimpleTableContainer
        columns={column}
        dataSource={data}
        pagination={pagination}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        rowIdParam="id"
      />
    );
  }
}

export default PayrollProviderList;
