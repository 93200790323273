import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MenuItem, TabMenu, useTabMenu } from '@xemplo/tab-menu';

export const NavMenu = () => {
  const { workerId } = useParams();

  const TabMenuItems: MenuItem[] = useMemo(
    () => [
      {
        id: 'details',
        label: 'Details',
        url: `./worker/${workerId}`,
      },
      {
        id: 'variance',
        label: 'Variance',
        url: `./worker/${workerId}/variance`,
      },
    ],
    [workerId]
  );
  const { configTabMenu } = useTabMenu();

  useEffect(() => {
    configTabMenu({ items: TabMenuItems });
  }, [TabMenuItems, configTabMenu, workerId]);

  return <TabMenu />;
};
