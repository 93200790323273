import {
  SetItemProps,
  TabMenuAction,
  TabMenuActions,
  TabMenuProps,
  TabMenuSize,
  TabMenuState,
} from './tab-menu.types';

export const defaultState: TabMenuState = {
  items: [],
  visibleItems: [],
  hiddenItems: [],
  subMenuOpened: false,
  windowWidth: 0,
  size: TabMenuSize.Medium,
};

export const reducer = (state: TabMenuState, { type, payload }: TabMenuAction) => {
  switch (type) {
    case TabMenuActions.SetItems:
      return {
        ...state,
        visibleItems: payload.visibleItems,
        hiddenItems: payload.hiddenItems,
        needResize: payload.needResize,
        windowWidth: window.innerWidth,
      };
    case TabMenuActions.ToggleMenu:
      return { ...state, subMenuOpened: payload ?? !state.subMenuOpened };
    case TabMenuActions.SetPropsChanged:
      return {
        ...state,
        items: payload.items ?? state.items,
        visibleItems: payload.items ?? state.visibleItems,
        hiddenItems: payload.items ? [] : state.hiddenItems,
        size: payload.size ?? (state.size || TabMenuSize.Medium),
        isPrimary: payload.isPrimary ?? state.isPrimary,
        isHire: payload.isHire ?? state.isHire,
      };
    case TabMenuActions.SetNeedResize:
      return { ...state, needResize: payload };
    default:
      return state;
  }
};

export const setItems = (payload: SetItemProps): TabMenuAction => ({
  type: TabMenuActions.SetItems,
  payload,
});

export const setNeedResize = (payload: boolean): TabMenuAction => ({
  type: TabMenuActions.SetNeedResize,
  payload,
});

export const toggleMenu = (payload?: boolean): TabMenuAction => ({
  type: TabMenuActions.ToggleMenu,
  payload,
});

export const setPropsChanged = (payload: Partial<TabMenuProps>): TabMenuAction => ({
  type: TabMenuActions.SetPropsChanged,
  payload,
});
