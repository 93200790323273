//@ts-nocheck
import React from "react";
import CompanyList from "../../components/companyList";
import CountryLookup from "../../components/lookup/country";
import { message } from "antd";
import {
  mustBeArray,
  findAuthorizationClaims,
  removeUndefined,
  errorDisplay,
  updateAuthorizationClaims,
  delay,
  getPersonalizationData,
  setPersonalizationData,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  checkClaimsAuthorization,
  errorHandler,
  requestUrlBuilder,
  filteredByLabel,
  isComponentVisible,
} from "../../../libs/utilities";
import _ from "lodash";
import axios from "axios";
import {
  GRID_VIEW_VALUE,
  PAGINATION_NOT_REQUIRED,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  TABLE_VIEW_VALUE,
  STRING_PERSONALIZATION,
  INTEGER_PERSONALIZATION,
  PAGINATION_SCROLLING,
} from "../../../constants/defaultClaims";
import NoRecord from "../../../Common/NoRecord";
import ContentEmpty from "../../components/common/contentListEmpty";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import GridTableViewOption from "../../components/common/gridTableViewOption";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import { FILTER_ACTIVE_INACTIVE_OPTIONS } from "../../../constants/options";
import {
  COMPANY_GRID_OPTIONS,
  COMPANY_FIELD_OPTIONS,
} from "../../../constants/sortingOptions";
import ScrollableCompanyTable from "../../components/company/ScrollableCompanyList";
import APIHandler from "../../../constants/apiUrl";
import { updateDomChanges, validateUsersAccessRights } from "../../../libs";
import {
  COMPANY_CREATE_SIMPLE_BUTTON,
  COMPANY_FILTER_CONTAINER,
  COMPANY_LIST_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { withRouter } from "../../../hooks";

type State = any;

class CompanyIndex extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      isDefaultListEmpty: false,
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: COMPANY_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: COMPANY_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
      countryList: [],
    };
  }
  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
  }

  componentDidMount() {
    const { dashboardView, dashboardClaims, pageClaims } = this.props;
    //validate if user has access to page
    //TODO: Replace with requireSecureComponent higher order component
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    if (dashboardView) {
      const companyPersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      if (!companyPersonalizations || !companyPersonalizations.gridView) {
        this.setState({ gridView: false });
      } else {
        this.setState({ gridView: true });
      }
      this.fetchAndSaveCompanies({ options: {} });
    } else {
      this.loadCompanyComponent();
    }
  }

  fetchAndSaveCompanies = ({ options = {} }) => {
    this.setState({ listLoading: true });
    this.props
      .listCompanies({
        options,
        action: "list",
        cancelToken: this.signal.token,
        targetEndpoint: this.props.targetEndpoint,
      })
      .then((resp) => {
        this.setState({ listLoading: false });
        if (resp.status) {
          this.setState({ list: resp.data, total: resp.total });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  };

  loadCompanyComponent = () => {
    this.props.updateHeader({
      header: {
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.CompanyList,
        title: "Companies",
        module: "",
        enableBack: false,
        entity: "company",
        action: checkClaimsAuthorization({
          component: COMPANY_CREATE_SIMPLE_BUTTON,

          claims: this.props.pageClaims?.components,
        }),
      },
    });
    const companyGeneralTable = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "companyGeneralTable",
    });
    const companyGeneralGrid = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "companyGeneralGrid",
    });
    const companyCountryDropdownFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "companyCountryDropdownFilter",
    });
    const companyNameTextboxFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "companyNameTextboxFilter",
    });
    const companyStatusDropdownFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "companyStatusDropdownFilter",
    });
    const companyListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: COMPANY_LIST_CONTAINER,
    });
    // Get Personalizations
    const companyListPersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: companyListContainer?.personalizations,
    });
    const nameTextBoxPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: companyNameTextboxFilter?.personalization,
    });
    const countryPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: companyCountryDropdownFilter?.personalization,
    });
    const isActivePersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: companyStatusDropdownFilter?.personalization,
    });
    this.setState({
      filters: {
        name: nameTextBoxPersonalizations?.value,
        countryId:
          countryPersonalizations && Number(countryPersonalizations.value),
        isActive: isActivePersonalizations?.value
          ? Number(isActivePersonalizations.value)
          : 0,
      },
      paginationType:
        companyListPersonalizations &&
        companyListPersonalizations.paginationType,
      sortingType: companyListPersonalizations?.sortingType,
      isPinned: companyListPersonalizations?.isPinned,
      gridable: companyListContainer?.gridable,
    });

    // if grid
    if (isComponentVisible({ claim: companyGeneralGrid })) {
      const companyGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: companyGeneralGrid.personalizations,
      });
      const pageNumber = companyGridPersonalizations?.pageNumber
        ? Number(companyGridPersonalizations.pageNumber)
        : 1;
      const row = companyGridPersonalizations?.row
        ? Number(companyGridPersonalizations.row)
        : 5;
      const col = companyGridPersonalizations?.col
        ? Number(companyGridPersonalizations.col)
        : 4;
      const SortOrder = companyGridPersonalizations?.sortOrder
        ? companyGridPersonalizations.sortOrder
        : null;
      const SortColumn = companyGridPersonalizations?.sortColumn
        ? companyGridPersonalizations.sortColumn
        : null;

      this.setState(
        {
          grid: {
            page: pageNumber ? Number(pageNumber) : 1,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          selectedGridSort:
            _.find(this.state.gridSortOption, (o) => o.label === SortColumn) &&
            _.find(this.state.gridSortOption, (o) => o.label === SortColumn).id,
        },
        () => {
          if (
            companyListPersonalizations &&
            companyListPersonalizations.gridView
          ) {
            this.setState({ gridView: true });

            this.fetchMoreData({ isPaginated: true });
            if (
              companyListPersonalizations &&
              companyListPersonalizations.paginationType ===
                PAGINATION_SCROLLING
            ) {
              this.listScrollingGridCompany({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.fetchMoreData({ isPaginated: true, notUpdate: true });
            }
          }
        }
      );
    }
    // if table
    if (isComponentVisible({ claim: companyGeneralTable })) {
      const companyTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: companyGeneralTable.personalizations,
      });
      const pageNumber = companyTablePersonalizations?.pageNumber
        ? Number(companyTablePersonalizations.pageNumber)
        : 1;
      const pageSize = companyTablePersonalizations?.pageSize
        ? Number(companyTablePersonalizations.pageSize)
        : 20;
      const SortOrder = companyTablePersonalizations?.sortOrder
        ? companyTablePersonalizations.sortOrder
        : null;
      const SortColumn = companyTablePersonalizations?.sortColumn
        ? companyTablePersonalizations.sortColumn
        : null;

      this.setState(
        {
          per_page:
            companyListPersonalizations &&
            companyListPersonalizations.paginationType ===
              PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : pageSize
              ? Number(pageSize)
              : 20,
          current: pageNumber ? Number(pageNumber) : 1,
          sortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(companyListContainer.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (
              companyListPersonalizations?.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingCompany({ pageLoad: true, notUpdate: true });
            } else {
              this.listCompanies({ options: {}, notUpdate: true });
            }
          }
        }
      );
    }
  };

  listScrollingGridCompany = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      {
        listLoading: true,

        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid && this.state.grid.page) + 1
            : Number(this.state.grid && this.state.grid.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;

        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });

        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });

        this.props
          .listCompanies({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...options },
              ...sortingParams,
            },
          })

          .then((response) => {
            this.setState({ listLoading: false });

            if (response.status) {
              this.checkIfDeafultListEmpty({
                list: mustBeArray(response.data),
              });

              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridCompany({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization("companyGeneralGrid");
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.listLoading = true;
        prevState.current = 1;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };

        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }

        this.updateAllPersonalization();

        this.fetchAndSaveCompanies({ options });
      }
    );
  };

  handleChange = (index, value) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [index]: value,
      },
    });
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    if (Number(tempFilters.countryId) === 0) {
      delete tempFilters.countryId;
    }
    if (tempFilters.name) {
      tempFilters.name = tempFilters.name.trim(" ");
    }
    switch (Number(tempFilters.isActive)) {
      case 0:
        delete tempFilters.isActive;
        break;
      case 1:
        tempFilters.isActive = 1;
        break;
      case 2:
        tempFilters.isActive = 0;
        break;
      default:
        break;
    }
    return tempFilters;
  };

  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", countryId: 0, isActive: 0 },
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.props
          .listCompanies({
            options: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                    paginationType: this.state.paginationType,
                    grid: this.state.grid,
                  })
                : this.state.per_page,

              targetEndpoint: this.props.targetEndpoint,
            },
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization("companyNameTextboxFilter", false);
    delay(300).then(() => {
      this.updatePersonalization("companyCountryDropdownFilter", false);
    });
    delay(600).then(() => {
      this.updatePersonalization("companyStatusDropdownFilter", false);
    });
    delay(900).then(() => {
      this.updatePersonalization("companyGeneralTable", false);
    });
    delay(1200).then(() => {
      this.updatePersonalization("companyGeneralGrid");
    });
  };

  listScrollingCompany = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            prevState.total === mustBeArray(prevState.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .listCompanies({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: mustBeArray(resp.data) });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingBusinessUnits({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],
                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("companyGeneralTable");
          }
        });
      }
    );
  };

  listCompanies = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options && options.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
          prevState.listLoading = true;
        }
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .listCompanies({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: mustBeArray(resp.data) });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listCompanies({ options: {} });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("companyGeneralTable");
          }
        });
      }
    );
  };
  fetchMoreData = ({ isPaginated = false, notUpdate }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props

      .listCompanies({
        options: { page, per_page, q: { ...options }, ...sortingParams },
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          this.checkIfDeafultListEmpty({ list: mustBeArray(response.data) });

          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ grid: { ...grid, page: 1 } }, () => {
              this.fetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.list = data;
                } else {
                  prevState.list = prevState.list.concat(data);

                  if (
                    data.length !== 0 &&
                    data.length === this.state.page_size
                  ) {
                    prevState.hasMoreData = true;
                    prevState.grid = {
                      ...prevState.grid,
                      page: prevState.grid.page + 1,
                    };
                  } else {
                    prevState.hasMoreData = false;
                  }
                }

                prevState.total = response.total;
                prevState.listLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  if (!notUpdate) {
                    this.updatePersonalization("companyGeneralGrid");
                  }
                });
              }
            );
          }
        }
      });
  };

  listCompanyGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid && this.state.grid.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .listCompanies({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: mustBeArray(resp.data) });

              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            } else {
              errorDisplay(resp && resp.data && resp.data.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("companyGeneralGrid");
        });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member?.details?.authorizationDOM
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];

      const companyListContainer = mustBeArray(
        this.props.pageClaims?.components
      ).find((o) => o.name === COMPANY_LIST_CONTAINER);

      const companyFilterContainer = mustBeArray(
        this.props.pageClaims?.components
      ).find((o) => o.name === COMPANY_FILTER_CONTAINER);

      const companyGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "companyGeneralGrid"
      );

      const companyGeneralTable = mustBeArray(
        this.props.pageClaims?.components
      ).find((o) => o.name === "companyGeneralTable");
      switch (field) {
        case "companyGeneralTable":
          if (companyListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,

              sortOrder: this.state.sortInfo && this.state.sortInfo.order,

              sortColumn: this.state.sortInfo && this.state.sortInfo.field,

              pageSize: `${this.state.per_page}`,
              personalizationData:
                companyGeneralTable && companyGeneralTable.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "companyGeneralGrid":
          if (companyListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid?.page}`,
              row: `${grid?.row}`,
              col: `${grid?.col}`,
              sortOrder: grid?.sortInfo?.order,
              sortColumn: grid?.sortInfo?.field,
              personalizationData:
                companyGeneralGrid && companyGeneralGrid.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "companyCountryDropdownFilter":
          if (companyFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${
              this.state.filters?.countryId ? this.state.filters.countryId : ""
            }`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "companyStatusDropdownFilter":
          if (companyFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = this.state.filters?.isActive;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "companyNameTextboxFilter":
          if (companyFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.state.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "companyListContainer":
          if (companyListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData: companyListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }
      const updateChanges = updateDomChanges({
        update,
        condition: field === "companyGeneralGrid",
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization(COMPANY_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingCompany({ pageLoad: true, options: {} });
        } else {
          this.listCompanies({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridCompany({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridCompany({ pageLoad: true, options: {} });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingCompany({ pageLoad: true, options: {} });
        } else {
          this.listCompanies({ options: {} });
        }
      });
    }
  };
  updateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.selectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.gridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.grid = {
            ...prevState.grid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridCompany({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        this.props.router.navigate(`/company/${data && data.id}`);
        break;
      case "edit":
        break;
      case "delete":
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  handleCreate = () => {
    this.setState({ viewCompanyModal: true });
  };
  onCancel = () => {
    this.setState({ viewCompanyModal: false });
  };
  onSubmit = (values) => {
    return this.props.saveCompany(values).then((resp) => {
      if (resp.status) {
        message.success("Company created successfully.");

        this.setState({ viewCompanyModal: false });

        this.listCompanies({ options: {} });
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }

      return resp;
    });
  };
  checkIfDeafultListEmpty = ({ list }) => {
    const { current, grid } = this.state;

    if (
      _.isEmpty(mustBeArray(list)) &&
      _.isEmpty(this.getFilters()) &&
      checkClaimsAuthorization({
        component: COMPANY_CREATE_SIMPLE_BUTTON,

        claims: this.props.pageClaims?.components,
      }) &&
      Number(current) === 1 &&
      Number(grid?.page) === 1
    ) {
      return this.setState({ isDefaultListEmpty: true });
    }

    return this.setState({ isDefaultListEmpty: false });
  };

  getActiveFilters = () => {
    let activeFilters = [];

    let filtersApplied = this.getFilters();
    if (filtersApplied && filtersApplied.name) {
      activeFilters.push(filtersApplied.name);
    }
    if (filtersApplied && [1, 2].includes(filtersApplied.isActive)) {
      activeFilters.push(
        filtersApplied.isActive === FILTER_ACTIVE_INACTIVE_OPTIONS
          ? "Inactive"
          : "Active"
      );
    }
    if (filtersApplied && filtersApplied.countryId) {
      let country = _.find(
        mustBeArray(this.state.countryList),
        (o) => o.id === filtersApplied.countryId
      );
      activeFilters.push(country && country.value);
    }
    return activeFilters;
  };
  render() {
    const {
      sortInfo,
      filterLoading,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      paginationType,
      current,

      gridable,
      fieldOptions,
      isPinned,
      sortingType,
      hasMoreData,
      isDefaultListEmpty,
    } = this.state;

    const { pageClaims, dashboardView } = this.props;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });
    const companyUrl = APIHandler.constructEndpoint({
      endpoint: "COMPANY_URL",
    });
    const companyTargetEndpoint = requestUrlBuilder(companyUrl, {
      q: {
        ...this.getFilters(),
      },
    });

    let activeFilters = this.getActiveFilters();
    return isDefaultListEmpty && !dashboardView ? (
      <NoRecord>
        <ContentEmpty link="/company/create" page="Company" />
      </NoRecord>
    ) : (
      <React.Fragment>
        {!dashboardView && (
          <div className="flex justify-between items-center flex-wrap">
            <FilterContainer
              name={COMPANY_FILTER_CONTAINER}
              claims={this.props.pageClaims}
            >
              <SingleFieldTextBoxFilter
                placeholder="Search Companies"
                name="companyNameTextboxFilter"
                claims={this.props.claims}
                value={this.state.filters?.name}
                onChange={(value) => this.handleChange("name", value)}
                onSearch={(value) => this.handleFilter("name", value)}
                className="mb-[15px]"
              />
              <CountryLookup
                selectedValue={this.state.filters?.countryId}
                onSelect={(value) => this.handleChange("countryId", value)}
                name="companyCountryDropdownFilter"
                claims={this.props.claims}
                updateCountryList={(countryList) =>
                  this.setState({ countryList })
                }
                className="-ml-5"
              />
              <SingleFieldDropdownFilter
                name="companyStatusDropdownFilter"
                claims={this.props.claims}
                options={FILTER_ACTIVE_INACTIVE_OPTIONS}
                value={Number(this.state.filters?.isActive || 0)}
                defaultValue={"0"}
                onSelect={(value) => this.handleChange("isActive", value)}
                className="mb-[15px]"
                valueIsNumber={true}
              />
              <SearchIconButton
                loading={filterLoading}
                onClick={this.handleFilter}
                name="companySearchButton"
                claims={this.props.claims}
                className="ml-3"
              />
              <LinkButton
                buttonText="Reset Filter"
                className="color-primary hand"
                onClick={() => this.resetFilters()}
                name="companyResetFilter"
                claims={this.props.claims}
              />
            </FilterContainer>
            <div className={"mb-6"}>
              <GridTableViewOption
                gridable={gridable}
                handleGridChange={this.handleGridChange}
                gridView={gridView}
                fieldOptions={fieldOptions}
                updateGridField={this.updateGridField}
                gridSortInfo={this.state.grid && this.state.grid.sortInfo}
                tableSortInfo={this.state.sortInfo}
                updatePageSize={this.updatePageSize}
                grid={grid}
                per_page={per_page}
              />
            </div>
          </div>
        )}
        <ListContainer
          name={COMPANY_LIST_CONTAINER}
          claims={pageClaims}
          isPinned={isPinned}
          gridable={gridable}
          gridView={gridView}
          grid={grid}
          per_page={per_page}
          GeneralTablePaginatedComponent={CompanyList}
          GeneralTableScrollableComponent={ScrollableCompanyTable}
          generalTableComponentName={"companyGeneralTable"}
          gridComponentName={"companyGeneralGrid"}
          createLinkButtonName={""}
          handleGridChange={this.handleGridChange}
          fieldOptions={fieldOptions}
          updateGridField={this.updateGridField}
          sortingType={sortingType}
          paginationType={paginationType}
          list={list}
          listLoading={listLoading}
          listGrid={this.listCompanyGrid}
          listScrollingGrid={this.listScrollingGridCompany}
          hasMoreData={hasMoreData}
          gridActionComponentName={"companyViewDetailLink"}
          viewComponent="companyViewDetailLink"
          moduleType={"company"}
          handleGridAction={this.handleAction}
          listScrollingGeneralTable={this.listScrollingCompany}
          total={total}
          current={current}
          sortInfo={sortInfo}
          listGeneralTable={this.listCompanies}
          tableActionComponentName={"companyViewDetailLink"}
          gridPageSize={getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          })}
          cardGrid={cardGrid}
          showAddAndGrid={this.props.dashboardView ? true : false}
          targetEndpoint={this.props.targetEndpoint || companyTargetEndpoint}
          hasNoDuplicateEntity={true}
          componentTitle={
            dashboardView ? this.props.componentTitle : "Companies"
          }
          filterApplied={
            dashboardView
              ? this.props.filterApplied
              : filteredByLabel({ filters: activeFilters })
          }
          rowIdParam="id"
          dashboardView={dashboardView}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(CompanyIndex);
