//@ts-nocheck
import React from "react";
import { Table } from "antd";
import _ from "lodash";
import EditIconButton from "../../../../Common/ClaimComponents/IconButton/EditIconButton";
import { PAGINATION_NOT_REQUIRED } from "../../../../constants/defaultClaims";
import { tableColumnGenerator } from "../../../../libs/table";
import { mustBeArray } from "../../../../libs/utilities";
import SwitchButton from "../../../../Common/ClaimComponents/Filter/SwitchButton";
import { isSettingsIntegrated } from "../../../../libs/utilities";

class ConnectionList extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let options = sorter.field
      ? {
          page: pagination.current,
          order: sorter.order === "ascend" ? "asc" : "desc",
          field: sorter.field,
        }
      : {
          page: pagination.current,
        };
    this.props.handleTableChange({ options });
  };

  render() {
    const size = this.props?.size ?? "default";
    let {
      per_page,
      paginationType,
      data,
      editComponent,
      claims,
      handleEdit,
      handleIntegrationSettingChange,
      payrunIntegrationSettingComponent,
      amendmentIntegrationSettingComponent,
      reportIntegrationSettingComponent,
    } = this.props;
    const pagination = {
      total: this.props.totalPages,
      current: this.props.current,
      pageSize: per_page ? per_page : 20,
      hideOnSinglePage: true,
    };
    const colTemplate = [
      {
        title: "Name",
        dataIndex: "name",
        sorter: false,
        width: "40%",
      },
      {
        title: "Integration Settings",
        dataIndex: "integrationSettings",
        sorter: false,
        width: "45%",
        render: (record) => {
          return (
            <div>
              <div className="mb-2">
                <SwitchButton
                  handleChangeSwitch={(e: any) =>
                    handleIntegrationSettingChange({
                      value: e,
                      type: "payrun",
                      record,
                    })
                  }
                  name="Pay Runs"
                  checked={isSettingsIntegrated({
                    record,
                    type: "payrun",
                  })}
                  claims={claims}
                  componentName={payrunIntegrationSettingComponent}
                  switchClassName="mr-4"
                  hasComponents
                />
              </div>
              <div className="mb-2">
                <SwitchButton
                  handleChangeSwitch={(e: any) =>
                    handleIntegrationSettingChange({
                      value: e,
                      type: "amendment",
                      record,
                    })
                  }
                  name="Amendments"
                  checked={isSettingsIntegrated({
                    record,
                    type: "amendment",
                  })}
                  claims={claims}
                  componentName={amendmentIntegrationSettingComponent}
                  switchClassName="mr-4"
                  hasComponents
                />
              </div>
              {/* Keypay Reports */}

              <div>
                <SwitchButton
                  handleChangeSwitch={(e: any) =>
                    handleIntegrationSettingChange({
                      value: e,
                      type: "keypayreport",
                      record,
                    })
                  }
                  name="Reports"
                  checked={isSettingsIntegrated({
                    record,
                    type: "keypayreport",
                  })}
                  claims={claims}
                  componentName={reportIntegrationSettingComponent}
                  switchClassName="mr-4"
                  hasComponents
                />
              </div>
            </div>
          );
        },
      },
    ];
    const action = [
      {
        title: "Action",
        dataIndex: "",
        width: "15%",
        render: (record: any) => {
          return (
            <EditIconButton
              handleEdit={() => handleEdit(record)}
              name={editComponent}
              claims={claims}
              hasNoComponents={true}
              avatarClassName="color-primary"
            />
          );
        },
      },
    ];
    const tableColumn = _.concat(
      tableColumnGenerator(colTemplate),
      this.props.dashboardView ? [] : action
    );

    return (
      <Table
        columns={tableColumn}
        size={size}
        rowKey={(record, id) => id}
        dataSource={mustBeArray(data)}
        pagination={
          paginationType === PAGINATION_NOT_REQUIRED || this.props.dashboardView
            ? false
            : pagination
        }
        loading={this.props.loading}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default ConnectionList;
