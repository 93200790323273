import { Select } from "antd";
import { Instance } from "@xemplo/types";
import { useGetInstancesQuery } from "@xemplo/directory-services-query";
import { useAuth } from "@xemplo/auth-provider";
import { useDebounceCallback } from "@xemplo/hooks";
import { useEffect, useState } from "react";

type PayrollInstanceLookupProps = {
  handleChange?: (e: any) => void;
  disabled: boolean;
  value?: string;
};

const PayrollInstanceLookup = ({
  handleChange,
  disabled,
  value,
}: PayrollInstanceLookupProps) => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [selectedInstance, setSelectedInstance] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setSelectedInstance(value);
  }, [value]);

  // onChange has value type defined as any
  const onChange = (e: any) => {
    setSearchTerm(undefined);
    if (handleChange) {
      handleChange(e);
    }
  };

  const handleSearch = (e?: string) => {
    setSearchTerm(e);
  };

  const handleBlur = () => {
    setSearchTerm(searchTerm);
  };

  const { data, isLoading } = useGetInstancesQuery({
    authToken: user?.access_token,
    params: {
      page: 1,
      ...(searchTerm?.trim() ? { searchTerm: searchTerm.trim() } : {}),
    },
  });

  const onSearch = useDebounceCallback(handleSearch, 300);
  return (
    <Select
      placeholder="Select"
      optionFilterProp="name"
      disabled={disabled}
      onChange={onChange}
      loading={isLoading}
      onSearch={onSearch}
      showSearch
      filterOption={false}
      onBlur={handleBlur}
      value={selectedInstance}
    >
      {data
        ? data.map((instance: Instance) => (
            <Select.Option key={instance.id} value={instance.id}>
              {instance.name}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

export default PayrollInstanceLookup;
