import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { PayrunTypes } from '@xemplo/gp-types';
import {
  parseToCalendarDate,
  TextDatePickerV2,
  tryParseDate,
} from '@xemplo/text-date-picker';

import * as S from '../../modal.styles';

export function EffectiveDate() {
  const { register, unregister, watch } = useFormContext();

  const watchPayrunType = watch('payrunType');
  const watchEffectiveDate = watch('adhocEffectiveDate');
  const showEffectiveDate = watchPayrunType === PayrunTypes.ADHOC;
  useEffect(() => {
    if (showEffectiveDate) {
      register('adhocEffectiveDate', {
        required: 'This field is required',
      });
    } else {
      unregister('adhocEffectiveDate');
    }
  }, [register, unregister, showEffectiveDate]);

  if (showEffectiveDate) {
    return (
      <S.FormLabel>
        Effective on
        <TextDatePickerV2
          name="adhocEffectiveDate"
          inputProps={{
            placeholder: 'Select date',
            width: '100%',
            minValue: parseToCalendarDate(),
            defaultValue: tryParseDate(watchEffectiveDate),
          }}
        />
      </S.FormLabel>
    );
  }
}
