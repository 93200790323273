//@ts-nocheck
import React, { Component } from "react";
import { Modal, Button, Popconfirm, Spin } from "antd";
import { mustBeArray, errorDisplay } from "../../../libs/utilities";

type State = any;

class MissingPayCategory extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      missingPayCategories: [],
    };
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps && this.props && newProps.visible !== this.props.visible) {
      this.props
        .getMissingPayCategories({
          id: newProps.payrun.payrunId,
          values: {
            externalBusinessUnitId: Number(
              newProps.payrun && newProps.payrun.externalBusinessUnitId
            ),
            payrollSystemConnectionId:
              newProps.payrun.payrollSystemConnectionId,
          },
        })
        .then((resp) => {
          this.setState({ loading: false });

          if (resp.status) {
            this.setState({
              missingPayCategories: mustBeArray(
                resp.data && resp.data.data && resp.data.data.result
              ),
            });
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
    }
  }
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Missing Pay Categories"
        onCancel={() => this.props.closeModal()}
        footer={[
          <React.Fragment key="e">
            <Button
              className="float-left"
              loading={this.state.loadingDelete}
              disabled={true}
              key="a"
            >
              {" "}
              Update Configuration
            </Button>
            <div className="float-right" key="b">
              <Popconfirm
                placement="topLeft"
                key="decline"
                title={
                  "Are you sure you want to continue without these missing pay categories?"
                }
                onConfirm={() => this.props.handleContinue()}
              >
                <Button
                  key="Ok"
                  loading={this.props.declineLoading}
                  className="bg-danger color-white"
                >
                  Continue
                </Button>
              </Popconfirm>
              <Button key="cancel" onClick={() => this.props.closeModal()}>
                Cancel
              </Button>
            </div>
            <div className="clearfix" key="c" />
          </React.Fragment>,
        ]}
      >
        {this.state.loading ? (
          <Spin />
        ) : (
          mustBeArray(this.state.missingPayCategories).map((category, i) => {
            return i === 0 ? (
              <span key={i} className="text-base">
                {category.name}
              </span>
            ) : (
              <span key={i} className="text-base">{`, ${category.name}`}</span>
            );
          })
        )}
      </Modal>
    );
  }
}

export default MissingPayCategory;
