import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDrawer } from '@xemplo/drawer';
import { PayrollDynamicRoutes } from '@xemplo/gp-routes';

import { PayrunWorkerDetailBody } from './payrun-worker-detail-body';
import { PayrunWorkerDetailHeader } from './payrun-worker-detail-header';

export function PayrunWorkerDetailRoute() {
  const { id: payrunId, workerId } = useParams();

  const { setContent, toggle, setConfig } = useDrawer();
  const navigate = useNavigate();
  useEffect(() => {
    setConfig({
      onClose: () => {
        payrunId && navigate(`/${PayrollDynamicRoutes.PayrunById(payrunId)}`);
        return { toggle, headerKeyline: true };
      },
    });
  }, [payrunId]);

  useEffect(() => {
    if (workerId && payrunId) {
      setContent({
        header: <PayrunWorkerDetailHeader workerId={workerId} payrunId={payrunId} />,
        body: <PayrunWorkerDetailBody workerId={workerId} payrunId={payrunId} />,
      });
      toggle(true);
    }
  }, [workerId, payrunId]);

  return null;
}
