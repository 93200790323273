import { PropsWithChildren } from 'react';

import { useDrawerInit } from './hooks/use-drawer-init';
import { DrawerContext } from './drawer.context';
import { DrawerProps } from './drawer.types';

export function DrawerProvider({ children, ...props }: PropsWithChildren<DrawerProps>) {
  const drawerState = useDrawerInit(props);
  return <DrawerContext.Provider value={drawerState}>{children}</DrawerContext.Provider>;
}

export default DrawerProvider;
