import { PropsWithChildren } from 'react';

import useAuthState from './hooks/use-auth-state';
import { AuthContext } from './auth-provider.context';
import { AuthProviderProps } from './auth-provider.types';

export function AuthProvider(props: PropsWithChildren<AuthProviderProps>) {
  const { children, ...rest } = props;
  return (
    <AuthContext.Provider value={useAuthState(rest)}>{children}</AuthContext.Provider>
  );
}
