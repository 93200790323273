import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonWithState,
} from "@xemplo/button";

type SkipPayrunModalContentProps = {
  handleSkipPayrun: () => Promise<void>;
  handleCancelSkipPayrun: () => void;
};

export const SkipPayrunModalContent = ({
  handleSkipPayrun,
  handleCancelSkipPayrun,
}: SkipPayrunModalContentProps) => {
  return (
    <>
      <div className="mb-2 text-center">
        Are you sure you want to skip this pay run?
      </div>
      <div className="mb-2 text-center"> You cannot undo this action</div>
      {/** TODO: Remove these buttons when prompt component is used instead of modal */}
      <div className="flex justify-center">
        <Button
          type={ButtonType.Secondary}
          className="m-2"
          size={ButtonSize.Standard}
          ariaLabel="skip-payrun-button"
          onClick={handleCancelSkipPayrun}
        >
          Cancel
        </Button>
        <ButtonWithState
          type={ButtonType.Destructive}
          className="m-2"
          size={ButtonSize.Standard}
          ariaLabel="skip-payrun-button"
          onClickAsync={handleSkipPayrun}
        >
          Delete
        </ButtonWithState>
      </div>
    </>
  );
};
