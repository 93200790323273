//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, InputNumber, Modal, Button } from "antd";
import { validateInputValueIncludesOnlyNumbers } from "../../../libs";

const FormItem = Form.Item;

type State = any;
class CurrencyConversionFeesForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.visible !== this.props.visible) {
      newProps.form.setFieldsValue({
        fixedConversionRate:
          newProps.currencyFees && newProps.currencyFees.fixedConversionRate,
        percentConversionRate:
          newProps.currencyFees && newProps.currencyFees.percentConversionRate,
      });
    }
  }
  updateCurrencyFees = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      values.convertTo =
        this.props.currencyFees && this.props.currencyFees.convertTo;
      values.convertFrom =
        this.props.currencyFees && this.props.currencyFees.convertFrom;
      values.currencyConvertFrom =
        this.props.currencyFees && this.props.currencyFees.currencyConvertFrom;
      values.currencyConvertTo =
        this.props.currencyFees && this.props.currencyFees.currencyConvertTo;
      this.props.updateCurrencyFees({ payload: values });
    });
  };
  render() {
    const { visible, saveLoading, cancelEditingCurrencyFees } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        width={700}
        title="Edit Currency Conversion Fees"
        visible={visible}
        onCancel={cancelEditingCurrencyFees}
        footer={[
          <Button key="i" onClick={cancelEditingCurrencyFees}>
            Cancel
          </Button>,

          <Button
            type="primary"
            key="j"
            onClick={this.updateCurrencyFees}
            htmlType="submit"
            loading={saveLoading}
          >
            Save
          </Button>,
        ]}
      >
        <Form autoComplete="off">
          <Row gutter={16} className="mb-4">
            <Col sm={24} md={12}>
              <FormItem label="Fixed Conversion Rate">
                {getFieldDecorator("fixedConversionRate", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter fixed conversion rate",
                    },
                  ],
                })(
                  <InputNumber
                    onKeyDown={(e) =>
                      validateInputValueIncludesOnlyNumbers({
                        e,
                        hasDecimal: true,
                      })
                    }
                    placeholder="Enter"
                    className="w-full "
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label="Percent Conversion Rate">
                {getFieldDecorator("percentConversionRate", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter percent conversion rate",
                    },
                  ],
                })(
                  <InputNumber
                    onKeyDown={(e) =>
                      validateInputValueIncludesOnlyNumbers({
                        e,
                        hasDecimal: true,
                      })
                    }
                    placeholder="Enter"
                    className="w-full "
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create("conversionFees")(CurrencyConversionFeesForm);
