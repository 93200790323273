import styled from 'styled-components';

import { Avatar as ExpedoAvatar } from '@xemplo/avatar';
import { Button } from '@xemplo/button';
import {
  Body2XSmallMedium,
  BodyLargeMedium,
  BodySmallMedium,
  BodySmallSemiBold,
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
  Shadow,
  TextEllipsis,
} from '@xemplo/style-constants';

/** Drawer Header  */
export const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 32px;
`;

export const Avatar = styled(ExpedoAvatar)`
  border: 2px solid ${Colour.White[100]};
  ${Shadow.Medium1};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Name = styled.span`
  color: ${Colour.Gray[600]};
  ${BodyLargeMedium};
`;

export const Title = styled.span`
  color: ${Colour.Gray[600]};
  ${TextEllipsis};
  ${BodyXSmallRegular};
`;

export const Salary = styled.span`
  margin-top: 8px;
  color: ${Colour.Gray[400]};
  ${TextEllipsis};
  ${BodyXSmallRegular};
`;

/** Amendment details */

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 50%;
`;

export const BackButton = styled(Button)`
  margin-block: 24px;
`;

export const DetailContainer = styled.div`
  margin-inline: 32px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 16px;
  text-align: right;

  th {
    height: 20px;
    color: ${Colour.Gray[400]};
    ${Body2XSmallMedium};
    padding-bottom: 14px;

    text-transform: uppercase;
    &:nth-child(2),
    &:nth-child(3) {
      width: 120px;
    }
  }

  td {
    padding-bottom: 15px;
    height: 20px;
    color: ${Colour.Gray[800]};
    &:first-child {
      text-align: left;
      ${BodyXSmallRegular};
      color: ${Colour.Gray[500]};
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 120px;
      max-width: 120px;
      padding-left: 8px;
      ${TextEllipsis};
    }
  }
  .heading {
    td {
      &:nth-child(1) {
        ${BodyXSmallSemiBold};
      }
      &:nth-child(2),
      &:nth-child(3) {
        ${BodySmallSemiBold};
      }
    }
  }
`;

export const GoBackLink = styled.button`
  color: ${Colour.Gray[400]};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px 32px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  ${BodySmallMedium};

  &:hover {
    color: ${Colour.Gray[800]};
  }
`;
