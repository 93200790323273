import { companyAction } from "../constants/action-type/index";

const initialState = {
  users: {
    list: [],
    count: 0,
  },
  emailTemplates: {
    list: [],
    count: 0,
  },
};
const {
  LIST_COMPANIES,
  COMPANY_DETAIL,
  LIST_COMPANY_USERS,
  LIST_COMPANY_USER_DETAILS,
  LIST_COMPANY_BUSINESS_UNITS,
  LIST_COMPANY_INVOICES,
  COMPANY_PAYRUN_TOTAL,
  COMPANY_PAYRUN_PEO,
  COMPANY_PAYRUN_OUTSOURCED,
} = companyAction;

export default function companyReducer(state = initialState, action: any) {
  const { data, total, status, type } = action;
  switch (type) {
    case LIST_COMPANIES: {
      return {
        ...state,
        list: status ? data : [],
        total,
      };
    }

    case COMPANY_DETAIL: {
      return {
        ...state,
        detail: data,
      };
    }
    case LIST_COMPANY_USERS: {
      return {
        ...state,
        users: {
          list: data ? data : [],
          count: total ? total : 0,
        },
      };
    }

    case "LIST_COMPANY_USERS_CLEAR": {
      return {
        ...state,
        users: {
          list: [],
          count: 0,
        },
      };
    }

    case LIST_COMPANY_USER_DETAILS: {
      return {
        ...state,
        userDetails: data,
      };
    }

    case LIST_COMPANY_BUSINESS_UNITS: {
      return {
        ...state,
        businessunits: data,
      };
    }
    case LIST_COMPANY_INVOICES: {
      return {
        ...state,
        invoices: data,
        companyInvoicesCount: total,
      };
    }

    case COMPANY_PAYRUN_PEO: {
      return {
        ...state,
        payrunPEO: data,
      };
    }

    case COMPANY_PAYRUN_OUTSOURCED: {
      return {
        ...state,
        payrunOutsourced: data,
      };
    }

    case COMPANY_PAYRUN_TOTAL: {
      return {
        ...state,
        payrunTotal: data,
      };
    }

    case "CLEAR_COMPANY": {
      return {
        ...state,
        list: [],
        total: 0,
        detail: {},
        users: {
          list: [],
          count: 0,
        },
        userDetails: {},
      };
    }

    default:
      return state;
  }
}
