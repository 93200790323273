// @ts-nocheck
import { BaseUserExtended } from "@xemplo/gp-types";
import { useHttpClient } from "@xemplo/http";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";

import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import { sortInfoType } from "../../../constants/paramsTypes";
import { useAppSelector } from "../../../hooks";
import {
  isUserAssignedAsPayrollManager,
  isUserPrimaryPayrollManager,
  isUserPSPManager,
} from "../../../libs";
import { getCurrentTableSortInfo } from "../../../libs/utilities";
import { RootState } from "../../../store";
import { PayrollManagerUser } from "../../../types/businessUnit";
import AssignPayrollManagerList from "./AssignPayrollManagerList";

interface AssignPayrollManagerProps {
  listPSPUsers: (e: any) => Promise<any>;
  handleClose: (e: any) => void;
  handleSaveAssignedPayrollManager: (e: any) => void;
  handleChangePrimary: (e: any) => void;
  handleAssign: (e: any) => void;
  visible: undefined | boolean;
  payrollProviderId?: string;
  assignedPayrollManagers?: any;
  saveLoading?: boolean;
  activeBusinessUnit?: any;
}

const AssignPayrollManagerModal: React.FC<AssignPayrollManagerProps> = ({
  listPSPUsers,
  handleClose,
  visible,
  payrollProviderId,
  assignedPayrollManagers,
  handleAssign,
  handleChangePrimary,
  handleSaveAssignedPayrollManager,
  saveLoading,
}) => {
  // TODO: The effect should be part of this httpClient hook. Once that's done, the unmount effect can be dumped
  const { cancelSignal } = useHttpClient();

  const [sortInfo, setSortInfo] = useState<sortInfoType>({
    field: "",
    order: "",
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [payrollManagers, setPayrollManagers] = useState<PayrollManagerUser[]>(
    []
  );
  const [sortRequired, setSortRequired] = useState(true);

  const member = useAppSelector((state: RootState) => state.member);
  const isCurrentUserPSPManager = isUserPSPManager({ member });

  useEffect(() => {
    setPage(1);
    setSortInfo({ field: "", order: "" });
    setPayrollManagers([]);
    if (visible && isCurrentUserPSPManager) {
      setLoading(false);
    }
    if (visible && !isCurrentUserPSPManager) {
      setLoading(true);
      listPayrollManagers({
        sortInfo: { field: "name", order: "asc" },
      });
    }
  }, [visible]);

  useEffect(() => {
    //Each time a PM is assigned or unassigned, assignedPayrollManagers is updated and the rendered list needs to be updated
    setPayrollManagers(preparePayrollManagerList(payrollManagers));
    // if the modal was set to hidden, then the list needs to be sorted again
    if (!visible) {
      setSortRequired(true);
    }
  }, [assignedPayrollManagers, visible]);

  const listPayrollManagers = ({ sortInfo, currentPage }: any) => {
    listPSPUsers({
      options: {
        per_page: 0, //return all records in a single page
        q: {
          serviceProviderId: payrollProviderId,
          isActive: 1,
          isEnabled: 1,
        },
        ...getCurrentTableSortInfo({ sortInfo }),
      },
      cancelToken: cancelSignal.token,
    }).then((resp) => {
      setPayrollManagers(preparePayrollManagerList(resp?.data));
      setLoading(false);
    });
  };

  const preparePayrollManagerList = (data: BaseUserExtended[]) => {
    //if user is PSP manager then return the assignedPayrollManagers list (sorted so primary PM is first)
    if (isCurrentUserPSPManager) {
      return assignedPayrollManagers.sort((a: any) => (a.isPrimary ? -1 : 0));
    }
    // if user is PSP admin then return entire payroll manager list sorted
    //extend user list with a isUserAssignedAsPayrollManager and isPrimary flag
    const extendedData = data.map((user: BaseUserExtended) => {
      return {
        ...user,
        isUserAssignedAsPayrollManager: isUserAssignedAsPayrollManager({
          user,
          assignedPayrollManagers,
        }),
        isPrimary: isUserPrimaryPayrollManager({
          user,
          assignedPayrollManagers,
        }),
      };
    });
    if (sortRequired) {
      //Sort the list by the isUserAssignedAsPayrollManager flag, maintain the sorting from BE for the rest of the list
      extendedData.sort((a) => {
        return a.isUserAssignedAsPayrollManager ? -1 : 0;
      });
      //Sort primary PM first
      extendedData.sort((a) => {
        return a.isPrimary ? -1 : 0;
      });
      setSortRequired(false);
    }
    return extendedData;
  };

  /** Handle table changes (pagination, sort, filter) */
  const handlePayrollProviderChange = (options: {
    action: string;
    currentPage: number;
    field?: string;
    order?: string;
  }) => {
    //User changed page
    if (options.action === "paginate") {
      setPage(options.currentPage);
    }
    //user changed sort
    if (options.action === "sort") {
      setLoading(true);
      setSortInfo({
        field: options.field,
        order: options.order,
      });
      listPayrollManagers({
        sortInfo: {
          field: options.field,
          order: options.order,
        },
      });
    }
  };

  /** On unmount should abort any api request in progress */
  useEffect(() => {
    return () => {
      cancelSignal.cancel(
        "AssignPayrollManagerModal unmounted: Cancelling any pending API requests"
      );
    };
  }, []);

  return (
    <Modal
      title="Payroll Managers"
      onCancel={handleClose}
      visible={visible}
      width={isCurrentUserPSPManager ? 900 : 1500}
      footer={[
        <SimpleButton
          ignoreStatus={true}
          buttonText={isCurrentUserPSPManager ? "Close" : "Cancel"}
          onClick={handleClose}
          key="1"
        />,
      ].concat(
        isCurrentUserPSPManager
          ? []
          : [
              <SimpleButton
                loading={saveLoading}
                ignoreStatus={true}
                buttonText="Save"
                type="primary"
                onClick={handleSaveAssignedPayrollManager}
                key="2"
              />,
            ]
      )}
    >
      <AssignPayrollManagerList
        handlePayrollUserTableChange={handlePayrollProviderChange}
        loading={loading}
        data={payrollManagers}
        handleAssign={handleAssign}
        handleChangePrimary={handleChangePrimary}
        sortInfo={sortInfo}
        currentPage={page}
      />
    </Modal>
  );
};

export default AssignPayrollManagerModal;
