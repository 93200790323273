import { DeleteOutlined, RightSquareFilled } from "@ant-design/icons";
import { Card, Popconfirm } from "antd";
import React, { useMemo, useState } from "react";
import { If } from "../../../Common/ui";
import { AMENDMENT_DECLINED_STATUS } from "../../../constants/payrun";
import {
  onlyDateFormater,
  parseItems,
  currencyFormater,
  getConditionalResponse,
  getAssignedToUsersText,
  showDeleteGeneralAmendment,
  findAuthorizationClaims,
} from "../../../libs";
import { AmendmentStatusBadge } from "../../../Common/amendments";
import ClaimWrapper from "../../../Common/claimWrapper";
import {
  PAYRUN_AMENDMENT_DELETE,
  PAYRUN_AMENDMENT_RESUBMIT,
} from "../../../constants";
import { PendingAmountValue } from "./Amendments/pending-amount-value";

const AmendmentSingleLineGridDisplay = ({
  content,
  handleAction,
  payrun,
  dashboardView,
  dueDatePassed,
  claims,
}: any) => {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleActions = ({ event, action, amendment }: any) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleAction({ action, data: amendment });
  };

  const handlePreventDefault = (e: any) => {
    setConfirmVisible(!confirmVisible);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  // Get claims for amendment delete and amendment resubmit buttons
  const { amendmentDeleteClaim, amendmentResubmitClaim } = useMemo(() => {
    return {
      amendmentDeleteClaim: findAuthorizationClaims({
        claims: claims.components,
        name: PAYRUN_AMENDMENT_DELETE,
      }),
      amendmentResubmitClaim: findAuthorizationClaims({
        claims: claims.components,
        name: PAYRUN_AMENDMENT_RESUBMIT,
      }),
    };
  }, [claims.components]);

  return content ? (
    <Card
      className="rounded-[12px] bg-default hand"
      onClick={() => handleAction({ data: content, action: "view" })}
    >
      <div className="flex justify-between">
        <div className="flex-1-1-50">
          <div className="text-[15px] font-bold">{`${
            content.amendmentTaskTypeName
          } for ${getAssignedToUsersText({ content, minified: true })}`}</div>
          <div className="text-sm flex-1-1 italic">
            {`Submitted by ${
              parseItems(content.requestedBy) &&
              parseItems(content.requestedBy).UserName
            } on ${onlyDateFormater(content.requestedDate)}`}
          </div>
          <If
            condition={
              parseItems(content.actionedBy, {}) &&
              parseItems(content.actionedBy, {}).userName
            }
            then={
              <div
                className="text-sm italic"
                onClick={(event) =>
                  handleActions({ event, data: content, action: "viewHistory" })
                }
              >
                {`${content.amendmentStatusName} by ${
                  parseItems(content.actionedBy) &&
                  parseItems(content.actionedBy).userName
                }`}
              </div>
            }
          />
        </div>
        {/** Show the total amount */}

        <div className="text-sm flex flex-1-1-20 items-center gap-2 ">
          {/**   //TODO: Replace calculated value with BE returned totalAmount https://expedo.atlassian.net/browse/GC-870  */}
          <span className="font-bold pl-3">Total Amount:</span>
          <PendingAmountValue
            value={(content.amount ?? 0) * (content.units ?? 0)}
            isIntegrated={content.isIntegrated}
            amendmentTaskTypeID={content.amendmentTaskTypeID}
            amendmentKeypayStatusId={content.amendmentKeypayStatusId}
          />
        </div>
        <div className="text-right text-sm flex-1-1-20">
          {/** If payrun not actionable show status badges*/}
          {payrun && ![1, 2, 3].includes(payrun.payrunStatusID) ? (
            <AmendmentStatusBadge statusID={content?.amendmentStatusID} />
          ) : (
            <If
              condition={!dashboardView && !dueDatePassed}
              then={
                <>
                  <ClaimWrapper
                    claims={amendmentDeleteClaim}
                    name={PAYRUN_AMENDMENT_DELETE}
                    hasNoComponents
                  >
                    {/** DELETE BUTTON */}
                    <If
                      condition={showDeleteGeneralAmendment({ content })}
                      then={
                        <Popconfirm
                          placement="bottomLeft"
                          title={
                            "Are you sure you want to delete this amendment?"
                          }
                          onConfirm={(event) =>
                            handleActions({
                              event,
                              action: "delete",
                              amendment: content,
                            })
                          }
                          okText="Yes"
                          cancelText="No"
                          cancelButtonProps={{
                            onClick: (e) => handlePreventDefault(e),
                          }}
                          visible={confirmVisible}
                        >
                          <DeleteOutlined
                            className="ml-4 hand"
                            onClick={handlePreventDefault}
                          />
                        </Popconfirm>
                      }
                    />
                  </ClaimWrapper>
                  {/** RESUBMIT BUTTON */}
                  <If
                    condition={
                      content.amendmentStatusID === AMENDMENT_DECLINED_STATUS
                    }
                    then={
                      <ClaimWrapper
                        claims={amendmentResubmitClaim}
                        name={PAYRUN_AMENDMENT_RESUBMIT}
                        hasNoComponents
                      >
                        <RightSquareFilled
                          className="hand ml-4"
                          onClick={(event) =>
                            handleActions({
                              event,
                              action: "reSubmit",
                              amendment: content,
                            })
                          }
                        />
                      </ClaimWrapper>
                    }
                    else={<React.Fragment />}
                  />
                </>
              }
              else={<>&nbsp;</>}
            />
          )}
        </div>
      </div>
    </Card>
  ) : (
    <></>
  );
};
export default AmendmentSingleLineGridDisplay;
