const LIST_COMPANIES = "LIST_COMPANIES";
const COMPANY_DETAIL = "COMPANY_DETAIL";
const LIST_COMPANY_USERS = "LIST_COMPANY_USERS";
const LIST_COMPANY_USER_DETAILS = "LIST_COMPANY_USER_DETAILS";
const LIST_COMPANY_BUSINESS_UNITS = "LIST_COMPANY_BUSINESS_UNITS";
const LIST_COMPANY_INVOICES = "LIST_COMPANY_INVOICES";
const COMPANY_PAYRUN_PEO = "COMPANY_PAYRUN_PEO";
const COMPANY_PAYRUN_OUTSOURCED = "COMPANY_PAYRUN_OUTSOURCED";
const COMPANY_PAYRUN_TOTAL = "COMPANY_PAYRUN_TOTAL";

const companyAction = {
  LIST_COMPANIES,
  COMPANY_DETAIL,
  LIST_COMPANY_USERS,
  LIST_COMPANY_BUSINESS_UNITS,
  LIST_COMPANY_INVOICES,
  COMPANY_PAYRUN_PEO,
  COMPANY_PAYRUN_OUTSOURCED,
  COMPANY_PAYRUN_TOTAL,
  LIST_COMPANY_USER_DETAILS,
};

export default companyAction;
