import { SVGProps } from 'react';
const DawnTrash24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.015 4.978-.144.86a52.228 52.228 0 0 1 1.614-.026c.98 0 1.96.017 2.94.05l-.147-.875-.001-.004c-.074-.456-.118-.588-.183-.662-.021-.024-.152-.17-.801-.17H11c-.66 0-.786.142-.802.16-.06.068-.103.191-.183.667Zm-1.672.898a.76.76 0 0 0-.005.031c-.709.043-1.418.1-2.127.172l-1.784.175a.75.75 0 1 0 .146 1.492l1.02-.1.542 9.989v.003l.001.016c.023.395.048.817.114 1.21.068.4.187.823.432 1.203.536.83 1.48 1.183 2.819 1.183h5.62c1.338 0 2.282-.353 2.818-1.183.245-.38.364-.803.432-1.204.066-.392.09-.814.114-1.21l.001-.015v-.003l.542-9.995c.385.033.77.068 1.153.106a.75.75 0 1 0 .148-1.492 90.993 90.993 0 0 0-4.373-.328.757.757 0 0 0-.007-.051l-.192-1.136-.009-.054c-.056-.355-.146-.916-.521-1.347-.438-.506-1.104-.688-1.934-.688H11c-.819 0-1.486.168-1.927.669-.375.425-.467.984-.527 1.345l-.01.065-.193 1.147Zm9.19 1.647a87.492 87.492 0 0 0-6.048-.21 50.89 50.89 0 0 0-4.398.19l.545 10.047v.002c.025.417.046.76.097 1.06.05.299.122.5.214.642.138.214.455.496 1.558.496h5.62c1.102 0 1.419-.282 1.557-.497.092-.141.163-.343.214-.64.05-.301.072-.644.096-1.061v-.002l.544-10.027Zm-7.008 1.712a.705.705 0 0 1 .771.686l.23 7.016a.798.798 0 0 1-.723.813.705.705 0 0 1-.772-.687l-.23-7.016a.798.798 0 0 1 .724-.812Zm4.452.812a.798.798 0 0 0-.723-.812.705.705 0 0 0-.772.686l-.229 7.016a.798.798 0 0 0 .723.813.705.705 0 0 0 .772-.687l.229-7.016Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnTrash24;
