import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AmendmentTypes, PayrunTypes } from '@xemplo/gp-types';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';

export function PayrunType() {
  const { register, unregister, watch } = useFormContext();

  const watchChangeType = watch('changeType');
  const watchPayrunType = watch('payrunType');
  const showPayrunType = watchChangeType === AmendmentTypes.PAYRUN;

  useEffect(() => {
    if (showPayrunType) {
      register('payrunType', {
        required: 'This field is required',
        valueAsNumber: true,
        validate: (value) => {
          if (value === PLACEHOLDER_OPTION) {
            return 'This field is required';
          }
          return true;
        },
      });
    } else {
      unregister('payrunType');
    }
  }, [register, showPayrunType, unregister]);

  const getItems = () => {
    if (watchPayrunType) {
      return payrunTypeItems;
    }
    return [{ key: PLACEHOLDER_OPTION, value: 'Select a type' }, ...payrunTypeItems];
  };
  if (showPayrunType) {
    return (
      <S.FormLabel>
        Pay run type
        <TextDropdownMultiV2
          name="payrunType"
          defaultSelectedKeys={watchPayrunType && [watchPayrunType]}
          items={getItems()}
          isDropdownMulti={false}
          selectProps={{
            placeholder: 'Select payrun type',
          }}
        />
      </S.FormLabel>
    );
  }
}

const payrunTypeItems = [
  {
    key: PayrunTypes.ADHOC,
    value: 'Adhoc',
  },
  {
    key: PayrunTypes.SCHEDULED,
    value: 'Scheduled',
  },
];
