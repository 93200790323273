//@ts-nocheck
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";

const ZoomMinimizeIcon = (props: any) => {
  const { expanded, title, handleClick, claims, name } = props;
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <span className={`${claimAndStatus.status}`}>
      <Tooltip title={title}>
        {expanded ? (
          <FullscreenExitOutlined onClick={handleClick} />
        ) : (
          <FullscreenOutlined onClick={handleClick} />
        )}
      </Tooltip>
    </span>
  );
};
export default ZoomMinimizeIcon;
