// @ts-nocheck

const initialState = {
  list: [],
  total: 0,
  detail: {},
  accountList: [],
  accountTotal: 0,
  accountDetail: {},
};

export default function billingServiceProviderReducer(
  state = initialState,
  action: any
) {
  const { data, total } = action;
  switch (action.type) {
    case "LIST_BILLINGSERVICEPROVIDER": {
      return {
        ...state,
        list: data,
        total,
      };
    }

    case "BILLINGSERVICEPROVIDER_DETAIL": {
      return {
        ...state,
        detail: data,
      };
    }
    case "LIST_ACCOUNTS": {
      return {
        ...state,
        accountList: data,
        accountTotal: total,
      };
    }

    case "ACCOUNT_DETAIL": {
      return {
        ...state,
        accountDetail: data,
      };
    }
    case "CLEAR_BILLINGSERVICEPROVIDER": {
      return {
        ...state,
        list: [],
        total: 0,
        detail: {},
        accountList: [],
        accountTotal: 0,
        accountDetail: {},
      };
    }
    default:
      return state;
  }
}
