const getNameGridOptions = (id1: number, id2: number) => {
  return [
    { id: id1, label: "name", value: "asc", field: "Name", order: "Ascending" },
    {
      id: id2,
      label: "name",
      value: "desc",
      field: "Name",
      order: "Descending",
    },
  ];
};

const getCountryGridOptions = (id1: number, id2: number) => {
  return [
    {
      id: id1,
      label: "country",
      value: "asc",
      field: "Country",
      order: "Ascending",
    },
    {
      id: id2,
      label: "country",
      value: "desc",
      field: "Country",
      order: "Descending",
    },
  ];
};

const getCompanyNameGridOptions = (id1: number, id2: number) => {
  return [
    {
      id: id1,
      label: "companyName",
      value: "asc",
      field: "Company Name",
      order: "Ascending",
    },
    {
      id: id2,
      label: "companyName",
      value: "desc",
      field: "Company Name",
      order: "Descending",
    },
  ];
};

// Company
export const COMPANY_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  ...getCountryGridOptions(3, 4),
  {
    id: 7,
    label: "businessUnitCount",
    value: "asc",
    field: "Business Unit Count",
    order: "Ascending",
  },
  {
    id: 8,
    label: "businessUnitCount",
    value: "desc",
    field: "Business Unit Count",
    order: "Descending",
  },
];

export const COMPANY_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 3, value: "country", field: "Country" },
  { id: 4, value: "businessUnitCount", field: "Business Unit Count" },
];

// Business Unit
export const BUSINESSUNIT_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  ...getCompanyNameGridOptions(3, 4),
  ...getCountryGridOptions(5, 6),
];

export const BUSINESSUNIT_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 2, value: "companyName", field: "Company Name" },
  { id: 3, value: "country", field: "Country" },
  { id: 4, value: "payScheduleCount", field: "Pay Schedule Count" },
];

// Payroll Service Provider
export const PAYROLL_PROVIDER_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  ...getCountryGridOptions(3, 4),
  {
    id: 7,
    label: "businessunitserviced",
    value: "asc",
    field: "Business Unit Count",
    order: "Ascending",
  },
  {
    id: 8,
    label: "businessunitserviced",
    value: "desc",
    field: "Business Unit Count",
    order: "Descending",
  },
];

export const PAYROLL_PROVIDER_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 2, value: "country", field: "Country" },
  { id: 3, value: "businessunitserviced", field: "Business Unit Count" },
];

// BSP
export const BILLING_PROVIDER_GRID_OPTIONS = [...COMPANY_GRID_OPTIONS];

export const BILLING_PROVIDER_FIELD_OPTIONS = [...COMPANY_FIELD_OPTIONS];

// Payrun
export const PAYRUN_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  ...getCompanyNameGridOptions(3, 4),
  {
    id: 5,
    label: "businessunitname",
    value: "asc",
    field: "Business Unit Name",
    order: "Ascending",
  },
  {
    id: 6,
    label: "businessunitname",
    value: "desc",
    field: "Business Unit Name",
    order: "Descending",
  },
  {
    id: 7,
    label: "ammendmentcount",
    value: "asc",
    field: "Amendments Count",
    order: "Ascending",
  },
  {
    id: 8,
    label: "ammendmentcount",
    value: "desc",
    field: "Amendments Count",
    order: "Descending",
  },
  {
    id: 11,
    label: "payCycleStartDate",
    value: "asc",
    field: "Pay Cycle Start Date",
    order: "Ascending",
  },
  {
    id: 12,
    label: "payCycleStartDate",
    value: "desc",
    field: "Pay Cycle Start Date",
    order: "Descending",
  },
  {
    id: 12,
    label: "ammendmentduedate",
    value: "asc",
    field: "Amendments Due Date",
    order: "Ascending",
  },
  {
    id: 14,
    label: "ammendmentduedate",
    value: "desc",
    field: "Amendments Due Date",
    order: "Descending",
  },
  {
    id: 15,
    label: "paydate",
    value: "asc",
    field: "Pay Date",
    order: "Ascending",
  },
  {
    id: 16,
    label: "paydate",
    value: "desc",
    field: "Pay Date",
    order: "Descending",
  },
];

export const PAYRUN_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 2, value: "companyname", field: "Company Name" },
  { id: 3, value: "businessunitname", field: "Business Unit Name" },
  { id: 4, value: "ammendmentcount", field: "Amendments Count" },
  { id: 6, value: "payCycleStartDate", field: "Pay Cycle Start Date" },
  { id: 7, value: "ammendmentduedate", field: "Amendments Due Date" },
  { id: 8, value: "paydate", field: "Pay Date" },
];

// Amendment
export const AMENDMENT_GRID_OPTIONS = [
  {
    id: 1,
    label: "amendmentTaskTypeName",
    value: "asc",
    order: "Ascending",
    field: "Type",
  },
  {
    id: 2,
    label: "amendmentTaskTypeName",
    value: "desc",
    order: "Descending",
    field: "Type",
  },
  {
    id: 3,
    label: "requestedBy",
    value: "asc",
    order: "Ascending",
    field: "Requested By",
  },
  {
    id: 4,
    label: "requestedBy",
    value: "desc",
    order: "Descending",
    field: "Requested By",
  },
  {
    id: 5,
    label: "requestedDate",
    value: "asc",
    order: "Ascending",
    field: "Requested Date",
  },
  {
    id: 6,
    label: "requestedDate",
    value: "desc",
    order: "Descending",
    field: "Requested Date",
  },
];

export const AMENDMENT_FIELD_OPTIONS = [
  { id: 1, value: "amendmentTaskTypeName", field: "Type" },
  { id: 3, value: "requestedBy", field: "Requested By" },
  { id: 5, value: "requestedDate", field: "Requested Date" },
];

// Invoice
export const INVOICE_GRID_OPTIONS = [
  ...getCompanyNameGridOptions(3, 4),
  {
    id: 5,
    label: "businessUnitName",
    value: "asc",
    field: "Business Unit Name",
    order: "Ascending",
  },
  {
    id: 6,
    label: "businessUnitName",
    value: "desc",
    field: "Business Unit Name",
    order: "Descending",
  },
  {
    id: 7,
    label: "invoiceNumber",
    value: "asc",
    field: "Invoice Number",
    order: "Ascending",
  },
  {
    id: 8,
    label: "invoiceNumber",
    value: "desc",
    field: "Invoice Number",
    order: "Descending",
  },
  {
    id: 15,
    label: "payrunName",
    value: "asc",
    field: "Pay Run Name",
    order: "Ascending",
  },
  {
    id: 16,
    label: "payrunName",
    value: "desc",
    field: "Pay Run Name",
    order: "Descending",
  },
];

export const INVOICE_FIELD_OPTIONS = [
  { id: 2, value: "companyName", field: "Company Name" },
  { id: 3, value: "businessUnitName", field: "Business Unit Name" },
  { id: 4, value: "invoiceNumber", field: "Amendments Count" },
  { id: 6, value: "payrunName", field: "Pay Cycle Start Date" },
];

// Pay Schedule
export const PAYSCHEDULE_GRID_OPTIONS = [
  {
    id: 1,
    label: "payScheduleName",
    value: "asc",
    field: "Name",
    order: "Ascending",
  },
  {
    id: 2,
    label: "payScheduleName",
    value: "desc",
    field: "Name",
    order: "Descending",
  },
  ...getCompanyNameGridOptions(3, 4),
  {
    id: 5,
    label: "businessUnitName",
    value: "asc",
    field: "Business Unit",
    order: "Ascending",
  },
  {
    id: 6,
    label: "businessUnitName",
    value: "desc",
    field: "Business Unit",
    order: "Descending",
  },
  {
    id: 7,
    label: "payoccurrencename",
    value: "asc",
    field: "Pay Occurance",
    order: "Ascending",
  },
  {
    id: 8,
    label: "payoccurrencename",
    value: "desc",
    field: "Pay Occurance",
    order: "Descending",
  },
  {
    id: 9,
    label: "firstPayDate",
    value: "asc",
    field: "Start Date",
    order: "Ascending",
  },
  {
    id: 10,
    label: "firstPayDate",
    value: "desc",
    field: "Start Date",
    order: "Descending",
  },
  {
    id: 11,
    label: "countryName",
    value: "asc",
    field: "Country",
    order: "Ascending",
  },
  {
    id: 12,
    label: "countryName",
    value: "desc",
    field: "Country",
    order: "Descending",
  },
];

export const PAYSCHEDULE_FIELD_OPTIONS = [
  { id: 1, value: "payScheduleName", field: "Name" },
  { id: 2, value: "companyName", field: "Company Name" },
  { id: 3, value: "businessUnitName", field: "Business Unit Name" },
  { id: 4, value: "countryName", field: "Country" },
  { id: 5, value: "payoccurrencename", field: "Pay Occurance" },
  { id: 6, value: "firstPayDate", field: "Start Date" },
];

// Company User
export const COMPANY_USER_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  {
    id: 3,
    label: "emailaddress",
    value: "asc",
    field: "Email Address",
    order: "Ascending",
  },
  {
    id: 4,
    label: "emailaddress",
    value: "desc",
    field: "Email Address",
    order: "Descending",
  },
];

export const COMPANY_USER_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 2, value: "emailaddress", field: "Email Address" },
];

// PSP User
export const PSP_USER_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  {
    id: 3,
    label: "emailaddress",
    value: "asc",
    field: "Email Address",
    order: "Ascending",
  },
  {
    id: 4,
    label: "emailaddress",
    value: "desc",
    field: "Email Address",
    order: "Descending",
  },
  {
    id: 7,
    label: "addedBby",
    value: "asc",
    field: "Added By",
    order: "Ascending",
  },
  {
    id: 8,
    label: "addedBby",
    value: "desc",
    field: "Added By",
    order: "Descending",
  },
];

export const PSP_USER_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 3, value: "emailaddress", field: "Email Address" },
  { id: 2, value: "addedBy", field: "Added By" },
];

// EMAIL templates
export const EMAIL_TEMPLATES_GRID_OPTIONS = [
  ...getNameGridOptions(1, 2),
  {
    id: 7,
    label: "description",
    value: "asc",
    field: "Description",
    order: "Ascending",
  },
  {
    id: 8,
    label: "description",
    value: "desc",
    field: "Description",
    order: "Descending",
  },
];

export const EMAIL_TEMPLATES_FIELD_OPTIONS = [
  { id: 1, value: "name", field: "Name" },
  { id: 2, value: "description", field: "Description" },
];

// Billing Provider Accounts
export const BILLING_PROVIDER_ACCOUNTS_GRID_OPTIONS = [
  {
    id: 1,
    label: "accountName",
    value: "asc",
    field: "Name",
    order: "Ascending",
  },
  {
    id: 2,
    label: "accountName",
    value: "desc",
    field: "Name",
    order: "Descending",
  },
  {
    id: 3,
    label: "currency",
    value: "asc",
    field: "Currency",
    order: "Ascending",
  },
  {
    id: 4,
    label: "currency",
    value: "desc",
    field: "Currency",
    order: "Descending",
  },
  {
    id: 5,
    label: "accountnumber",
    value: "asc",
    field: "Account Number",
    order: "Ascending",
  },
  {
    id: 6,
    label: "accountnumber",
    value: "desc",
    field: "Account Number",
    order: "Descending",
  },
  { id: 7, label: "bank", value: "asc", field: "Bank", order: "Ascending" },
  { id: 8, label: "bank", value: "desc", field: "Bank", order: "Descending" },
  { id: 9, label: "bsb", value: "asc", field: "BSB", order: "Ascending" },
  { id: 10, label: "bsb", value: "desc", field: "BSB", order: "Descending" },
  { id: 11, label: "swift", value: "asc", field: "Swift", order: "Ascending" },
  {
    id: 12,
    label: "swift",
    value: "desc",
    field: "Swift",
    order: "Descending",
  },
  { id: 13, label: "iban", value: "asc", field: "IBAN", order: "Ascending" },
  { id: 14, label: "iban", value: "desc", field: "IBAN", order: "Descending" },
];

export const BILLING_PROVIDER_ACCOUNTS_FIELD_OPTIONS = [
  { id: 1, value: "accountName", field: "Name" },
  { id: 3, value: "currency", field: "Currency" },
  { id: 5, value: "accountnumber", field: "Account Number" },
  { id: 7, value: "bank", field: "Bank" },
  { id: 9, value: "bsb", field: "BSB" },
  { id: 11, value: "swift", field: "Swift" },
  { id: 13, value: "iban", field: "IBAN" },
];

export const BILLING_PROVIDER_XERO_MAPPING_GRID_OPTIONS = [];
export const BILLING_PROVIDER_XERO_MAPPING_FIELD_OPTIONS = [];
