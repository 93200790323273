const TermsAndConditions = () => {
  return (
    <div
      id="elementor- tab - content - 2131"
      className="elementor - tab - content elementor - clearfix elementor - active block"
      data-tab="1"
      role="tabpanel"
      aria-labelledby="elementor - tab - title - 2131"
    >
      <h3>Terms &amp; Conditions</h3>
      <div>
        <strong>Application of these Terms and Conditions</strong>
      </div>
      <br />
      <p className="pl-10">
        These Terms and Conditions of Use (the&nbsp;
        <strong>Terms and Conditions</strong>) apply to your use of our services
        by providing your details at our website located at&nbsp;xemplo.co (the{" "}
        <strong>Site</strong>) once you have logged in as a user, and all
        associated websites linked to the Site. By using our Site and services
        you agree and accept the Terms and Conditions.
      </p>
      <p className="pl-10">
        The Site and related services are made available to you, the user and/or
        customer of this Site (<strong>you</strong>,<strong> your</strong>,
        <strong> User</strong>) in accordance with the below terms and
        conditions (<strong>Terms and Conditions</strong>
        ).&nbsp; Please read the Terms and Conditions carefully.&nbsp; Your use
        of the Site indicates your acceptance of the Terms and Conditions.
      </p>

      <ol start={2}>
        <li>
          <strong>Variation of these Terms and Conditions</strong>
        </li>
      </ol>
      <p className="pl-10">
        We may make changes to these Terms and Conditions at any time. &nbsp;You
        should check these Terms and Conditions regularly for such
        changes.&nbsp; If we change these Terms and Conditions we will publish
        an updated version under the ‘Terms and Conditions’ link on the Site.
        &nbsp;Your access or use of the Site after an updated version of these
        Terms and Conditions has been made available on the Site indicates your
        acceptance of the Terms and Conditions as changed by us.
      </p>

      <ol start={3}>
        <li>
          <strong>Accounts and passwords</strong>
        </li>
      </ol>
      <p className="pl-10">
        When you create an account for using any of the services or features
        available on the Site, you are responsible for all use of your username
        and/or password and must keep your username and/or password for that
        account confidential.
      </p>

      <ol start={4}>
        <li>
          <strong>Content – Intellectual Property</strong>
        </li>
      </ol>
      <p className="pl-10">
        All materials on the Site (except for Your Content and User Content) are
        owned by us (<strong>Our Content</strong>) or our third party licensors,
        which includes materials protected by copyright, trade mark and other
        intellectual property rights.
      </p>
      <p className="pl-10">
        Third party websites, including Linked Sites (as defined in clause 13)
        may post statements, information, features, content, products or other
        materials on the Site from time to time (
        <strong>Third Party Posted Material</strong>).&nbsp; We are licensed by
        Linked Sites to use their intellectual property and Third Party Posted
        Material on the Site.
      </p>
      <p className="pl-10">
        This Site or any portion of this Site, including Third Party Posted
        Material, may not be reproduced, duplicated, copied, sold, re-sold or
        otherwise exploited without our express permission, which consent may be
        withheld in its full discretion, or otherwise permitted under the{" "}
        <em>Copyright Act 1968 </em>(Cth).
      </p>
      <p className="pl-10">
        To the extent permitted by law, we make no representations or warranties
        about the accuracy, completeness, security or currency of Our Content,
        User Content or Third Party Posted Material and have no liability to you
        for your use or reliance on Our Content, User Content or Third Party
        Posted Material.
      </p>
      <p className="pl-10">
        Any unauthorised use of the materials appearing on this Site may violate
        copyright, trade mark and other applicable laws and could result in
        criminal or civil penalties.
      </p>
      <p className="pl-10">
        All rights not expressly granted in these Terms and Conditions are
        reserved.
      </p>

      <ol start={5}>
        <li>
          <strong>Your Content</strong>
        </li>
      </ol>
      <p className="pl-10">
        The Site provides you with the ability to upload materials and
        information including personal and sensitive information relevant to
        your work and employment on the Site (<strong>Content</strong>).&nbsp;
        You retain ownership of any Content you upload and publish (
        <strong>Your Content</strong>).
      </p>

      <ol start={6}>
        <li>
          <strong>Responsibility for Your Content</strong>
        </li>
      </ol>
      <p className="pl-10">You acknowledge and agree that:</p>

      <ul>
        <li className="list-style-type: none;">
          <ul>
            <li>
              you create and provide us with Your Content at your own risk;
            </li>
            <li>you are personally responsible and liable for Your Content;</li>
            <li>
              you are not entitled to any payment from us in respect of Your
              Content or our use of it.
            </li>
          </ul>
        </li>
      </ul>
      <ol start={7}>
        <li>
          <strong>Our use of Your Content</strong>
        </li>
      </ol>
      <p className="pl-10">
        By submitting Your Content through the Site, you grant us (our
        successors and our related bodies corporate, partners and their
        successors) a royalty-free, irrevocable, non-exclusive licence to use,
        reproduce, modify, distribute, sublicense and/or otherwise exploit.
      </p>

      <ul>
        <li className="list-style-type: none;">
          <ul>
            <li>
              During the Term, Xemplo may collect, de-identify and aggregate the
              Customer Data collected by or stored in the Software or their
              Account for the purpose of providing the Software and Services and
              in accordance with the Privacy Policy.
            </li>
            <li>All Customer Data remains the property of the Customer.</li>
            <li>
              The Customer grants to Xemplo an non-exclusive, irrevocable,
              perpetual, transferrable, worldwide license to use, reproduce and
              modify the Customer Data for the purposes of this agreement,
              including providing and improving the Software and Services, and
              preparing the Content, data, information and other derivative
              works (<strong>Derivative Works</strong>) for industry
              benchmarking, data compilation and research purposes.
            </li>
            <li>
              Xemplo shall be entitled to provide such Derivative Works to third
              parties provided that:
              <ul>
                <li>
                  the data is de-identified in accordance with best industry
                  practice standards and cannot be re-identified;
                </li>
                <li>
                  none of the Customer’s Confidential Information is disclosed
                  in such Derivative Works; and
                </li>
                <li>
                  the Customer is not identified in or linked to the Derivative
                  Works.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p className="pl-10">
        You acknowledge and agree that we, our successors, assignees and
        licensees may do or omit to do anything to Your Content which may
        infringe your moral rights in Your Content, including editing, altering
        and reproducing Your Content in any manner or context, in perpetuity
        throughout the world.
      </p>

      <ol start={8}>
        <li>
          <strong>Monitoring, modification and removal of Content</strong>
        </li>
      </ol>
      <p className="pl-10">
        We do not monitor, verify, approve, endorse, sanction, encourage,
        support or agree with Your Content or User Content including without
        limitation any comments, opinions or statements submitted, uploaded or
        otherwise posted on the Site.
      </p>
      <p className="pl-10">
        We reserve the right, but have no obligation to, amend, supplement,
        delete or update Our Content (as defined in clause 3), Your Content or
        User Content, without notice to you and at our sole discretion.&nbsp;
        However, if we become aware of any defamatory, misleading, false,
        offensive or otherwise illegal material (including in Your Content), we
        may remove such material without notice to you.
      </p>

      <ol start={9}>
        <li>
          <strong>No illegal use</strong>
        </li>
      </ol>
      <p className="pl-10">
        You must not use the Site in any manner or for any purpose which is
        illegal or prohibited by any laws.
      </p>

      <ol start={10}>
        <li>
          <strong>Site disclaimers</strong>
        </li>
      </ol>
      <p className="pl-10">
        Consumers have certain rights under the Australian Consumer Law and
        similar state and territory legislation (<strong>ACL</strong>).&nbsp; If
        you have rights under the ACL in respect of the Site, including rights
        arising from any statutory guarantee, nothing in these Terms and
        Conditions operates to exclude them. &nbsp;Subject to the preceding
        sentence, you acknowledge that your use of the Site, Our Content, Your
        Content, User Content, Linked Sites and Third Party Posted Material, is
        entirely at your own risk and are provided without warranty, either
        express or implied.
      </p>
      <p className="pl-10">
        We do not accept responsibility for any interference, loss or damage to
        your data, computer system, or mobile device which arises in connection
        with your use of the Site.&nbsp; Although reasonable precautions have
        been taken, we do not guarantee that access to the Site will be
        uninterrupted, or that there will be no failures, errors or omissions or
        loss of transmitted information, or that no viruses will be transmitted
        on the Site.
      </p>

      <ol start={11}>
        <li>
          <strong>Liability and indemnity</strong>
        </li>
      </ol>
      <p className="pl-10">
        We do not represent that the information contained in the Site is
        accurate, comprehensive, verified, complete or error free.&nbsp; We will
        attempt to keep the content on the Site up to date. &nbsp;However, we do
        not warrant the accuracy or currency of the content.
      </p>
      <p className="pl-10">
        To the extent the law permits, neither we, nor our directors, officers,
        agents, employees or contractors are liable to you and you indemnify and
        hold us harmless in respect of any and all claims, loss, damage or costs
        (including legal costs on a full indemnity basis), however arising
        (whether in negligence or otherwise) in connection with:
      </p>

      <ul>
        <li className="list-style-type: none;">
          <ul>
            <li>
              your use, misuse or reliance on the Site, Our Content, User
              Content and any content accessed through the Site;
            </li>
            <li>your failure to comply with these Terms and Conditions;</li>
            <li>
              Your Content, including its use, misuse or misappropriation;
            </li>
            <li>
              your use, misuse or reliance on any Linked Site (as defined in
              clause 13); or
            </li>
            <li>any acts or omissions by you.</li>
          </ul>
        </li>
      </ul>
      <ol start={12}>
        <li>
          <strong>Third party links</strong>
        </li>
      </ol>
      <p className="pl-10">
        The Site may contain links to third party websites, including social
        media sites, which in turn may contain hyperlinks to further third party
        websites (collectively, <strong>Linked Sites</strong>).&nbsp; Access to
        Linked Sites is provided for convenience only and you are responsible
        for evaluating whether you want to use a Linked Site.
      </p>
      <p className="pl-10">You acknowledge that:</p>

      <ul>
        <li className="list-style-type: none;">
          <ul>
            <li>
              when accessing and using any Linked Sites, you will be subject to
              their terms and conditions of use;
            </li>
            <li>
              we do not control or endorse and we are not responsible for any
              features, content, products or other materials on or available
              from a Linked Site;
            </li>
            <li>
              we are not a party to your relationship with the owners or
              operators of a Linked Site;
            </li>
            <li>
              any rights, claims or actions you may have in respect of a Linked
              Site may only be brought directly against the owners or operators
              of the Linked Site; and
            </li>
            <li>
              we may receive payments and/or commissions from owners or
              operators of Linked Sites and you will not have any claims,
              benefits or rights on these payments.
            </li>
          </ul>
        </li>
      </ul>
      <ol start={13}>
        <li>
          <strong>Termination</strong>
        </li>
      </ol>
      <p className="pl-10">
        We may terminate your access to the Site (or any part of it) at any time
        without reason and without notice.&nbsp; These Terms and Conditions
        survive any such termination.
      </p>

      <ol start={14}>
        <li>
          <strong>Privacy</strong>
        </li>
      </ol>
      <p className="pl-10">
        You may be asked to input information about yourself on different areas
        of the Site.&nbsp; If so, we will only use that information in
        accordance with our Privacy Policy, a copy of which can be found via the
        tab above.
      </p>
    </div>
  );
};

export default TermsAndConditions;
