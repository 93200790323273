// @ts-nocheck
import {
  getConditionalResponse,
  getUserArea,
  requestBuilder,
} from "../../libs/utilities";
import { http, ResponseHandler, SimpleErrorHandler } from "../../libs";
import APIHandler from "../../constants/apiUrl";
import { payRollProviderAction } from "../../constants/action-type/index";
import userRoles from "../../constants/userRoles";
const { LIST_PSP_USER_DETAILS, LIST_PSP_USERS } = payRollProviderAction;

export function fetchPayrollConnectionName({
  id,
  options,
  cancelToken,
  targetEndpoint,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const selectedUserArea = getUserArea();
      const selectedEndpoint = {
        endpoint:
          userRoles.SystemAdminArea === selectedUserArea
            ? "PAYROLL_CONNECTION_URL"
            : "PAYROLL_CONNECTION_LOOKUP_URL",
        options: { id },
      };
      const url = APIHandler.constructEndpoint(selectedEndpoint);
      const response = await requestBuilder(
        null,
        targetEndpoint || url,
        targetEndpoint ? {} : options,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "CONNECTIONS_LIST"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function fetchExternalBusinessunit({ options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_BUSINESSUNIT",
      });
      const response = await http.post(url, {
        payrollSystemConnectionId: options.payrollSystemConnectionId,
      });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "EXTERNAL_BUSINESSUNITS_LIST"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function fetchExternalPayschedules({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_PAYSCHEDULE",
        options: { id },
      });
      const response = await http.post(url, {
        payrollSystemConnectionId: options.payrollSystemConnectionId,
        externalBusinessUnitId: options.externalBusinessUnitId,
      });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "EXTERNAL_PAYSCHEDULES_LIST"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function connectPayProcessor({ id, values }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_CONNECTION_URL",
        options: { id },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response, "CONNECT_PROCESSOR")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function addPayrollConnection({ id, values, action, connectionId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_CONNECTION_URL",
        options: { id },
      });
      let response = {};
      if (action === "edit") {
        response = await http.patch(`${url}/${connectionId}`, values);
      } else {
        response = await http.post(url, values);
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response, "CONNECT_PROCESSOR")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function configurePayscheduleConnection(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYROLL_CONFIG_URL",
        options: { id: values.payScheduleID },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response, "CONFIGURE_PAYSCHEDULE")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function confirmPayrollConnection({ payload, id, type }) {
  return () =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          type === "expenses"
            ? "CONNECT_KEYPAY_EXPENSES_URL"
            : "CONNECT_KEYPAY_URL",
        options: { id },
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        const action = ResponseHandler.validObject(response, "CONNECT KEYPAY");
        return resolve(action);
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function fetchExternalEarnings({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_PAYRUN_EARNINGLINES",
        options: { id },
      });
      const response = await requestBuilder(null, url, options, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "EXTERNAL_EARNINGS_GET"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function postExternalEarnings({ id, values }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_PAYRUN_EARNINGLINES_POST",
        options: { id },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response, "EXTERNAL_EARNINGS_POST")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getMissingPayCategories({ id, values }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "MISSING_EXTERNAL_PAYCATEGORY",
        options: { id },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response,
              "MISSING_EXTERNAL_PAYCATEGORY"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function clearExternalEarnings({ id, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_PAYRUN_EARNINGLINES_CLEAR",
        options: { id },
      });
      const response = await http.del(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "CLEAR_EXTERNAL_EARNINGS"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function fetchExternalPayrun({ values }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_PAYRUN_URL",
        options: {
          businessId: values?.externalBusinessUnitId,
          payscheduleId: values?.externalPayScheduleId,
        },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "LIST_EXTERNAL_EARNINGS"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getInvoice({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "INVOICE_URL",
        options: { id },
      });
      const response = await requestBuilder(null, url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GENERATE_INVOICE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listPSPUsers({
  id,
  options,
  cancelToken,
  serviceProviderId,
  targetEndpoint,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
        q: { serviceProviderId, ...options.q },
      };

      const hasServiceProviderIdAndID = serviceProviderId && id;

      const url = APIHandler.constructEndpoint({
        endpoint: hasServiceProviderIdAndID
          ? "PAYROLL_USERS_DETAIL_URL"
          : "PSP_USERS_URL",
        options: hasServiceProviderIdAndID
          ? { pspId: serviceProviderId, userId: id }
          : {},
      });
      const response = await requestBuilder(
        hasServiceProviderIdAndID ? null : id,
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: targetEndpoint,
          resp2: url,
        }),
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: {},
          resp2: hasServiceProviderIdAndID ? {} : queryOptions,
        }),
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              getConditionalResponse({
                condition: response.data.result,
                resp1: response.data.result,
                resp2: response.data,
              }),
              getConditionalResponse({
                condition: id,
                resp1: LIST_PSP_USER_DETAILS,
                resp2: LIST_PSP_USERS,
              })
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: id ? LIST_PSP_USER_DETAILS : LIST_PSP_USERS,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function registerPSPUsers({ data }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PSP_USER_REGISTRATION",
      });
      const response = await http.post(url, data);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data, "NEW_USER_REGISTRATION")
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "NEW_USER_REGISTRATION",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function handlePSPUserActions({
  id,
  options,
  action,
  data,
  cancelToken,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let response = "";
      let url = APIHandler.constructEndpoint({
        endpoint: "PSP_USERS_ACTION_URL",
        options: { id, action },
      });
      switch (action && action.toLowerCase()) {
        case "update":
          response = await http
            .put(url, data, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "delete":
          response = await http.del(url, cancelToken).catch(SimpleErrorHandler);
          break;
        case "status":
          response = await http
            .patch(url, data, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "sendreminder":
          response = await http
            .post(url, {}, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "enableall":
        case "disableall":
        case "optionalalldeviceenrolment":
        case "disablealldeviceenrolment":
        case "mandatoryalldeviceenrolment":
          url = APIHandler.constructEndpoint({
            endpoint: `PSP_USER_MULTI_FACTOR_ENABLE_URL`,
            options: { id },
          });
          response = await http
            .post(url, data, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "updatemultifactorauthentication":
        case "updatedeviceenrolment":
        case "disabledeviceenrolment":
        case "mandatorydeviceenrolment":
        case "optionaldeviceenrolment":
          url = APIHandler.constructEndpoint({
            endpoint: `USER_MULTI_FACTOR_ENABLE_URL`,
            options: { id },
          });
          response = await http
            .post(url, data, cancelToken)
            .catch(SimpleErrorHandler);
          break;
        case "sendinvite":
        case "resetpassword":
        case "verifypassword":
          response = http.post(url);
          break;
        default:
          break;
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response.data, "USER_ACTIONS"))
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "USER_ACTIONS",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updatePSPIntegrationSettings(payload) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "SET_PSP_INTEGRATION_SETTINGS",
      });
      const response = await http.put(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
