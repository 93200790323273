//@ts-nocheck
import React from "react";
import _ from "lodash";
import { http } from "./http";
import moment from "moment";
import numeral from "numeral";
import { Badge, message, Tooltip } from "antd";
import {
  GRID_COLUMN_INDEX,
  GRID_PAGE_NUMBER_INDEX,
  GRID_PERSONALIZATIONS,
  GRID_ROW_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  GRID_VIEW_VALUE,
  LIST_PAGINATION_TYPE_INDEX,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  LIST_PINNABLE_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  PINNABLE_LIST_PERSONALIZATION,
  SORT_COLUMN_INDEX,
  SORT_ORDER_INDEX,
  TABLE_PERSONALIZATIONS,
  VISIBLE_CLAIMS_ID,
  MULTI_TAB_ACTIVE_KEY_INDEX,
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  SINGLE_LINE_GRID_VALUE,
  SORTING_NOT_REQUIRED,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  SORTING_REQUIRED,
  PAGINATION_PAGENUMBER,
} from "../constants/defaultClaims";

import {
  DISABLE_DEVICE_ENROLMENT_ID,
  MANDATORY_DEVICE_ENROLMENT_ID,
  OPTIONAL_DEVICE_ENROLMENT_ID,
  PRIMARY_PAYROLL_MANAGER_ID,
} from "../constants/user";
import { SYSTEM_ADMIN_USERNAME } from "../constants/authorizationClaims";
import {
  PERMANENT_AMENDMENT_TYPE_ID,
  DEFAULT_FALLBACK_DATE,
  OUTSOURCE_MODEL_ID,
  PAYRUN_AMENDMENT_TYPE_ID,
  PEO_MODEL_ID,
  REOCCURRING_AMENDMENT_TYPE_ID,
  REOCCURRING_STATUS_APPLIED_ID,
  REOCCURRING_STATUS_CANCELLED_APPLIED_ID,
  REOCCURRING_STATUS_CANCELLED_ID,
  REOCCURRING_STATUS_EDITED_APPLIED_ID,
  ALLOWANCE_CHANGE_TYPE_ID,
  DEDUCTION_CHANGE_TYPE_ID,
  SALARY_CHANGE_TYPE_ID,
  TERMINATION_CHANGE_TYPE_ID,
} from "../constants/payrun";
import { AUSTRALIA_COUNTRY_ID } from "../constants/entities";
import UserRole from "../constants/userRoles";
import AddIconIndex from "../Common/icons/AddIcon";
import {
  CLEAR_SELECTION_VALUE,
  TABLE_COLUMN_ADD_ICON_SIZE,
} from "../constants/ui";
import { displayDates } from "./timeHandlers";
import { isUserPSPUser } from "./authorizationdom";
import { getDefaultPage, getDefaultPerPage } from "./personalization";
import { isComponentVisibleEnabled } from "./claimsValidations";
import {
  ALLOWANCE_CHANGE_TYPE_NAME,
  DEDUCTION_CHANGE_TYPE_NAME,
  PAYRUN_AMENDMENT_TYPE_NAME,
  PAYSCHEDULE_URLS,
  PERMANENT_AMENDMENT_TYPE_NAME,
  RECURRING_AMENDMENT_TYPE_NAME,
  SALARY_CHANGE_TYPE_NAME,
  TERMINATION_CHANGE_TYPE_NAME,
} from "../constants";
import { goToRoute } from "../hooks";
import { Avatar, AvatarGroup } from "@xemplo/avatar";
import { Colour } from "@xemplo/style-constants";
import store from "../store";

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function buildQueryParams(params) {
  let esc = encodeURIComponent;
  return !_.isUndefined(params)
    ? Object.keys(removeUndefined(params))
        .map((k) => {
          let queryString = _.isObject(params[k])
            ? buildFilterParams(params[k])
            : esc(params[k]);
          return esc(k) + "=" + queryString;
        })
        .join("&")
    : "";
}
function buildFilterParams(params) {
  let esc = encodeURIComponent;
  return !_.isUndefined(params)
    ? Object.keys(removeUndefined(params))
        .map((k) => {
          let queryString = _.isObject(params[k])
            ? JSON.stringify(params[k])
            : esc(params[k]);
          return esc(k) + ":" + queryString;
        })
        .join(",")
    : "";
}

export function removeUndefined(params) {
  _.each(params, function removeUndefined(value, key) {
    if (isNullEmptyUndefined(value)) {
      delete params[key];
    }
  });
  return params;
}

export function isValidRate(value) {
  return !isNullEmptyUndefined(value) && value !== 0;
}

export function guid() {
  const crypto = window.crypto || window.msCrypto;
  let array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}

export function disableBrowserAutocomplete() {
  let i;
  const el = document.getElementsByClassName("ant-select-search__field");
  for (i = 0; i < el.length; i++) {
    el[i].setAttribute("autocomplete", "registration-select");
  }
}

export async function requestBuilder(id, baseUrl, options, cancelToken) {
  try {
    const endUrl = id ? `${baseUrl}/${id}` : `${baseUrl}`;
    const newOptions = removeUndefined(options);
    const paramString = _.isEmpty(newOptions)
      ? ""
      : "?" + buildQueryParams(newOptions);
    return await http.get(endUrl + paramString, cancelToken);
  } catch (error) {
    console.error(error);
  }
}

export function requestUrlBuilder(baseUrl, options) {
  const newOptions = removeUndefined(options);
  const paramString = _.isEmpty(newOptions)
    ? ""
    : "?" + buildQueryParams(newOptions);
  return baseUrl + paramString;
}

export function dispatcher(dispatch, data, action_type, storable) {
  const actionData = { type: action_type, data: data, status: true };
  return storable ? actionData : dispatch(actionData);
}

export function templateEngine(tpl, data) {
  let re = /<%([^%>]+)?%>/g,
    match;
  while ((match = re.exec(tpl))) {
    tpl = tpl.replace(match[0], data[match[1]]);
  }
  return tpl;
}

export function parseItems(item, defaultValue = {}) {
  try {
    return JSON.parse(item);
  } catch (error) {
    return defaultValue;
  }
}

export function getMaxCharacters({ validation = {} }) {
  if (!validation?.maxCharacter) return 5000;
  return validation.maxCharacter;
}

export function errorDisplay(
  validationErrors,
  oldModel = false,
  identityServer = false
) {
  mustBeArray(validationErrors?.errors).forEach((error) => {
    const nonIdentityServerError = oldModel
      ? error?.description
      : error.internalErrorMessageToDisplay;
    return message.error(
      identityServer ? error?.message : nonIdentityServerError
    );
  });
}

export function errorHandler({
  response,
  hasValidationErrors = false,
  oldModel = false,
}) {
  const errors = mustBeArray(
    getConditionalResponse({
      condition: hasValidationErrors,
      resp1: response?.data?.validationErrors?.errors,
      resp2: oldModel ? response?.data : response?.data?.errors,
    })
  );

  errors.forEach((error, i) => {
    return message.error(
      oldModel ? error?.validatedColumn : error?.description
    );
  });
}

export function manageDecimalPlaces({ value, decimalPlaces = 2 }) {
  let currentDecimalPlaces = `${value}`.split(".")[1]
    ? `${value}`?.split(".")?.[1].length
    : 0;
  if (currentDecimalPlaces > decimalPlaces) {
    return Number(Number(value).toFixed(decimalPlaces));
  }
  return value;
}

export function trimWhiteSpaces(value) {
  return value?.trim?.() ?? value;
}

export function hasAuthorizationToViewComponent(claimsId) {
  if (VISIBLE_CLAIMS_ID.includes(Number(claimsId))) return true;
  return false;
}

export function isInvoiceGenerationRequired({ isInvoiceNeeded }) {
  if (isInvoiceNeeded === true) return true;
  return false;
}

const updateClaims = ({ claims, name, value, label }) => {
  return _.each(mustBeArray(claims), (claim) => {
    if (claim.name === name) {
      claim[label] = value;
      return claims;
    } else if (!isEmpty(mustBeArray(claim.components))) {
      return updateClaims({ claims: claim.components, name, value, label });
    }
  });
};

export function updateAuthorizationClaims({ claims, name, value, label }) {
  let newClaims = _.cloneDeep(claims);
  return updateClaims({ claims: newClaims, name, value, label });
}

export function getUserArea() {
  const state = store.getState();
  const area = state?.member.details?.area;
  const userArea = area ?? localStorage.getItem("userArea");
  return userArea ?? "";
}

export function findAuthorizationClaims({ claims, name }) {
  return mustBeArray(claims).reduce((a, claim) => {
    if (a) return a;
    if (claim.name === name) return claim;
    if (claim["components"])
      return findAuthorizationClaims({ claims: claim.components, name });
  }, null);
}

export function mustBeArray(items) {
  return Array.isArray(items) ? items : [];
}

export function findScrollableTableHeight({ per_page, total }) {
  let height = 450;
  switch (per_page) {
    case 5:
      height = 230;
      if (total >= 10) {
        height = 450;
      }
      if (total >= 20) {
        height = 590;
      }

      break;
    case 10:
      if (total >= 20) {
        height = 590;
      }
      break;
    case 20:
    case 50:
    case 100:
      height = 590;
      break;
    default:
      break;
  }
  return height;
}

export function formatNumber(value) {
  return numeral(value).format("0,0.00");
}

export function currencyFormater(
  currency,
  country = "",
  returnUndefinedValues = false
) {
  if (returnUndefinedValues && _.isUndefined(currency)) return "N/A";
  const currencyFormatted = formatNumber(currency);
  switch (country) {
    case "United Kingdom":
      return `£${currencyFormatted}`;
    case "France":
    case "Belgium":
      return `€${currencyFormatted}`;
    default:
      return `$${currencyFormatted}`;
  }
}

export function formatByUnit(value, unit, returnUndefinedValues) {
  if (returnUndefinedValues && _.isUndefined(value)) return "N/A";
  return `${formatNumber(value)} ${unit}`;
}

export function currencyFormaterByCode(currency, code) {
  const currencyFormatted = formatNumber(currency);
  switch (code) {
    case "GBP":
      return `£${currencyFormatted}`;
    case "USD":
    case "AUD":
    case "NZD":
    case "SGD":
      return `$${currencyFormatted}`;
    case "EUR":
      return `€${currencyFormatted}`;
    default:
      return `$${currencyFormatted}`;
  }
}

/** @knipignore */
export function userRoles(user) {
  let roles = [];
  _.forEach(user, (value) => roles.push(value.name));
  return roles;
}

export function mapNameFromArray(receivedArray, label) {
  return mustBeArray(receivedArray).reduce((a, b) => {
    return a === "" ? b[label] : `${a}, ${b[label]}`;
  }, "");
}

export function getCurrentCardGrid({ grid }) {
  let defaultCardGrid;
  switch (Number(grid?.col)) {
    case 1:
      defaultCardGrid = 24;
      break;
    case 2:
      defaultCardGrid = 12;
      break;
    case 3:
      defaultCardGrid = 8;
      break;
    case 4:
      defaultCardGrid = 6;
      break;
    default:
      defaultCardGrid = 8;
      break;
  }
  return defaultCardGrid;
}
export function getPayrunDisplayStatus({ status }) {
  switch (status) {
    case "draft":
      return "Draft";
    case "invoicePaid":
      return "Invoice Paid";
    case "invoiceUnpaid":
      return "Invoice Unpaid";
    case "process":
      return "Finalize";
    default:
      return makeFirstLetterUppercase(status);
  }
}

export function getSearchParamName(search) {
  return search
    ? JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : "";
}

export function isAddressSame(values) {
  if (!values) return false;
  if (
    Number(values.postalAddressCountryId) ===
      Number(values.registeredAddressCountryId) &&
    values.postalAddressLine1 === values.registeredAddressLine1 &&
    values.postalAddressLine2 === values.registeredAddressLine2 &&
    Number(values.postalAddressStateId) ===
      Number(values.registeredAddressStateId) &&
    values.postalSuburb === values.registeredSuburb &&
    values.postalPostalCode === values.registeredPostalCode
  ) {
    return true;
  }
  return false;
}

export function checkClaimsAuthorization({ claims, component }) {
  const claim = _.find(mustBeArray(claims), (o) => {
    return o.name === component;
  });
  return isComponentVisibleEnabled({ claim });
}

export function renderDefaultDate(value) {
  if (value && value !== DEFAULT_FALLBACK_DATE)
    return moment(value).format("DD/MM/YYYY");
  return " ";
}

export function filterGeoAddressLocation(results) {
  const address = {};
  if (_.first(results)) {
    _.each(results[0].address_components, (address_component) => {
      switch (_.first(address_component.types)) {
        case "subpremise":
          address["unit"] = address_component.long_name;
          break;
        case "country":
          address["country"] = address_component.long_name;
          address["country_code"] = address_component.short_name;
          break;
        case "street_number":
          address["address1"] = `${address.unit ? `${address.unit}/` : ""}${
            address_component.long_name
          }`;
          break;
        case "establishment":
        case "route":
          address["address1"] = _.isUndefined(address["address1"])
            ? address_component.long_name
            : address["address1"] + " " + address_component.long_name;
          break;
        case "postal_code":
          address["postalcode"] = address_component.long_name;
          break;
        case "administrative_area_level_1":
          address["state"] = address_component.short_name;
          break;
        case "locality":
        case "postal_town":
          address["city"] = address_component.long_name;
          break;
        default:
          break;
      }
    });
  }
  let data = {};
  switch (address["country"]) {
    case "United Kingdom":
      data = _.find(mustBeArray(results?.[0]?.address_components), (o) =>
        o.types.includes("administrative_area_level_2")
      );
      address["state"] = data?.long_name ?? address.state;
      break;
    case "United States":
      data = _.find(mustBeArray(results?.[0]?.address_components), (o) =>
        o.types.includes("neighborhood")
      );
      address["city"] = data?.long_name ?? address.state;
      break;
    default:
      break;
  }
  return address;
}

export function abbreviate(name) {
  if (name) {
    return `${name?.[0]?.toUpperCase() ?? ""}${name?.[1]?.toUpperCase() ?? ""}`;
  }
  return "";
}

export function splitTitleCase(value: string): { first: string; last: string } {
  let index = 1; // start at index 1 to avoid splitting after first letter
  while (index < value.length) {
    const char = value[index];
    if (char === char.toUpperCase()) {
      // found uppercase letter
      const first = value.slice(0, index);
      const last = value.slice(index);
      return { first, last };
    }
    index++;
  }
  return { first: value, last: "" }; // no uppercase letters found
}

export function abbreviateFirstAndLastName({ firstName, lastName }) {
  return `${firstName ? getUpperCase(firstName?.[0]) : ""}${
    lastName ? getUpperCase(lastName[0]) : ""
  }`;
}

function makeFirstLetterUppercase(string) {
  return string
    ? string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase()
    : "";
}

// Handle Personaliztions
export function getPersonalizationData({
  personalizationData,
  type,
  defaultPerPage = 10,
}) {
  let items;
  switch (type) {
    case "multiTabContainer":
      items = mustBeArray(personalizationData);
      return {
        activeKey: items?.[MULTI_TAB_ACTIVE_KEY_INDEX]?.value,
      };
    case "listContainer":
      items = mustBeArray(personalizationData);
      const gridViewValue =
        items?.[LIST_PERSONALIZATION_GRID_TABLE_INDEX]?.value;
      let sortedPersonalization = {
        gridView:
          gridViewValue === GRID_VIEW_VALUE
            ? true
            : gridViewValue === SINGLE_LINE_GRID_VALUE
            ? SINGLE_LINE_GRID_VALUE
            : false,
        isPinned: items?.[LIST_PINNABLE_INDEX]?.value,
        paginationType: items?.[LIST_PAGINATION_TYPE_INDEX]?.value,
        sortingType: items?.[LIST_SORTING_REQUIRED_INDEX]?.value,
        pinnedTo: items?.[LIST_PINNABLE_INDEX]?.pinnedTo,
      };
      return sortedPersonalization;
    case "table":
      items = mustBeArray(personalizationData);
      return {
        pageSize: getDefaultPerPage({
          perPage: items?.[PAGE_SIZE_INDEX]?.value,
          defaultPerPage,
        }),
        pageNumber: getDefaultPage({
          page: items?.[PAGE_NUMBER_INDEX]?.value,
        }),
        sortOrder: items?.[SORT_ORDER_INDEX]?.value,
        sortColumn: items?.[SORT_COLUMN_INDEX]?.value,
        gridView: false,
      };
    case "grid":
      items = mustBeArray(personalizationData);
      return {
        col: items?.[GRID_COLUMN_INDEX]?.value,
        row: items?.[GRID_ROW_INDEX]?.value,
        pageNumber: getDefaultPage({
          page: items?.[GRID_PAGE_NUMBER_INDEX]?.value,
        }),
        sortOrder: items?.[GRID_SORT_ORDER_INDEX]?.value,
        sortColumn: items?.[GRID_SORT_COLUMN_INDEX]?.value,
        gridView: true,
      };
    case "filterContainer":
      return {
        value: personalizationData?.value,
      };
    default:
      return {};
  }
}
export function setPersonalizationData({
  personalizationData,
  type,
  sortColumn,
  sortOrder,
  pageNumber,
  pageSize,
  gridView,
  paginationType,
  sortingType,
  isPinned,
  activeKey,
  row,
  col,
}) {
  let items;
  switch (type) {
    case "multiTabContainer":
      items = [...MULTI_TAB_CONTAINER_PERSONALIZATION];
      items[MULTI_TAB_ACTIVE_KEY_INDEX].value = activeKey;
      return items;
    case "listContainer":
      items = [...PINNABLE_LIST_PERSONALIZATION];
      items[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = gridView;
      items[LIST_PERSONALIZATION_PAGINATION_INDEX].value = paginationType;
      items[LIST_SORTING_REQUIRED_INDEX].value = sortingType;
      items[LIST_PINNABLE_INDEX].value = isPinned;
      return items;
    case "table":
      items = [...TABLE_PERSONALIZATIONS];
      items[PAGE_NUMBER_INDEX].value = pageNumber;
      items[PAGE_SIZE_INDEX].value = pageSize;
      items[SORT_COLUMN_INDEX].value = sortColumn || "";
      items[SORT_ORDER_INDEX].value = sortOrder || "";
      return items;
    case "grid":
      items = [...GRID_PERSONALIZATIONS];
      items[GRID_PAGE_NUMBER_INDEX].value = pageNumber;
      items[GRID_ROW_INDEX].value = row;
      items[GRID_COLUMN_INDEX].value = col;
      items[GRID_SORT_COLUMN_INDEX].value = sortColumn || "";
      items[GRID_SORT_ORDER_INDEX].value = sortOrder || "";
      return items;
    case "filterContainer":
      return {
        value: personalizationData?.value,
      };
    default:
      return {};
  }
}
export function getCurrentGridPageSize({
  grid,
  paginationType = PAGINATION_PAGENUMBER,
}) {
  if (!grid) return 15;
  const { row, col } = grid;
  if (!row || !col) return 15;
  return paginationType === PAGINATION_NOT_REQUIRED
    ? DEFAULT_PAGE_SIZE_NO_PAGINATION
    : Number(row || 1) * Number(col || 1);
}

export function isListComponentGridable({ component }) {
  if (component?.gridable === "true") return true;
  return false;
}

export function getCurrentTableSortInfo({ sortInfo, sortingType = null }) {
  if (!sortInfo) return {};
  if (
    sortInfo.field &&
    sortInfo.order &&
    sortingType !== SORTING_NOT_REQUIRED
  ) {
    return {
      sort: {
        [sortInfo.field]: sortInfo.order,
      },
    };
  } else return {};
}

export function getCurrentGridSortInfo({
  grid = {},
  sortingType = SORTING_REQUIRED,
}) {
  const sortInfo = grid && grid.sortInfo;
  if (!sortInfo) return {};
  if (
    sortInfo.field &&
    sortInfo.order &&
    sortingType !== SORTING_NOT_REQUIRED
  ) {
    return {
      sort: {
        [sortInfo.field]: sortInfo.order,
      },
    };
  } else if (sortInfo.order && sortingType !== SORTING_NOT_REQUIRED) {
    return {
      sort: {},
    };
  } else return {};
}

export function getDeviceEnrolmentId({ action }) {
  switch (action) {
    case "mandatoryDeviceEnrolment":
    case "mandatoryAllDeviceEnrolment":
      return MANDATORY_DEVICE_ENROLMENT_ID;
    case "optionalDeviceEnrolment":
    case "optionalAllDeviceEnrolment":
      return OPTIONAL_DEVICE_ENROLMENT_ID;
    case "disableDeviceEnrolment":
    case "disableAllDeviceEnrolment":
      return DISABLE_DEVICE_ENROLMENT_ID;
    default:
      break;
  }
}

export function isUserSystemAdmin({ member, username = "" }) {
  return (
    member?.details?.username === SYSTEM_ADMIN_USERNAME ||
    username === SYSTEM_ADMIN_USERNAME
  );
}

export function nullFunction() {
  return null;
}

export function getReoccurringAmendmentKeypayActionId({ action = "" }) {
  switch (action) {
    case "verifiedInKeypay":
      return REOCCURRING_STATUS_APPLIED_ID;
    case "requestCancel":
      return REOCCURRING_STATUS_CANCELLED_ID;
    case "applyCancel":
      return REOCCURRING_STATUS_CANCELLED_APPLIED_ID;
    case "applyEditedInKeypay":
      return REOCCURRING_STATUS_EDITED_APPLIED_ID;
    default:
      return "";
  }
}

export function filteredByLabel({
  filters,
  onDate,
  startDate,
  endDate,
  format = "MM-DD-YYYY",
}: any) {
  let label = "Filtered";
  let combinedFilters = onDate
    ? [...mustBeArray(filters), onDate]
    : mustBeArray(filters);
  const filtersLength = combinedFilters.length;
  switch (filtersLength) {
    case 0:
      break;
    case 1:
      label = `${label} by ${combinedFilters[0]}`;
      break;
    default:
      let filtersString = combinedFilters.reduce((a, b, index) => {
        return a
          ? `${a}${index === filtersLength - 1 ? ` and ${b}` : `, ${b}`}`
          : b;
      }, "");
      label = `${label} by ${filtersString}`;
  }
  if (label === "Filtered") {
    if (startDate && endDate) {
      return `Filtered between ${moment(startDate, format).format(
        "DD-MM-YYYY"
      )} and ${moment(endDate, format).format("DD-MM-YYYY")}`;
    } else return "";
  } else {
    if (startDate && endDate) {
      return `${label} between ${moment(startDate, format).format(
        "DD-MM-YYYY"
      )} and ${moment(endDate, format).format("DD-MM-YYYY")}`;
    }
  }

  return label;
}

// Get Table Columns
export function getPayScheduleListColumns({
  view,
  sortInfo,
  claims,
  component,
  dashboardView,
}) {
  let colTemplate = [];
  const commonProps = { sorter: !dashboardView };

  switch (view) {
    case "index":
      colTemplate = [
        {
          title: "Name",
          dataIndex: "payScheduleName",
          link: {
            url: PAYSCHEDULE_URLS.Detail,
          },
          sortOrder: getSortOrder({ sortInfo, field: "payScheduleName" }),
          claims,
          component,
          ...commonProps,
        },
        {
          title: "Company",
          dataIndex: "companyName",
          sortOrder: getSortOrder({ sortInfo, field: "companyName" }),
          ...commonProps,
        },
        {
          title: "Business Unit",
          dataIndex: "businessUnitName",
          sortOrder: getSortOrder({ sortInfo, field: "businessUnitName" }),
          ...commonProps,
        },
        {
          title: "Country",
          dataIndex: "countryName",
          sortOrder: getSortOrder({ sortInfo, field: "countryName" }),
          ...commonProps,
        },
        {
          title: "Occurrence",
          dataIndex: "payOccurence",
          sortOrder: getSortOrder({ sortInfo, field: "payoccurrencename" }),
          ...commonProps,
        },
        {
          title: "Start Date",
          dataType: "date",
          dataIndex: "firstPayDate",
          sortOrder: getSortOrder({ sortInfo, field: "firstPayDate" }),
          ...commonProps,
        },
        { title: "Status", dataIndex: "isActive" },
      ];
      break;
    case "company":
      colTemplate = [
        {
          title: "Name",
          dataIndex: "payScheduleName",
          link: {
            url: PAYSCHEDULE_URLS.Detail,
          },
          sortOrder: getSortOrder({ sortInfo, field: "payScheduleName" }),
          claims,
          component,
          ...commonProps,
        },
        {
          title: "Business Unit",
          dataIndex: "businessUnitName",
          sortOrder: getSortOrder({ sortInfo, field: "businessUnitName" }),
          ...commonProps,
        },
        {
          title: "Occurrence",
          dataIndex: "payOccurence",
          sortOrder: getSortOrder({ sortInfo, field: "payoccurrencename" }),
          ...commonProps,
        },
        {
          title: "Start Date",
          dataType: "date",
          dataIndex: "firstPayDate",
          sortOrder: getSortOrder({ sortInfo, field: "firstPayDate" }),
          ...commonProps,
        },
        { title: "Status", dataIndex: "isActive" },
      ];
      break;
    case "businessunit":
      colTemplate = [
        {
          title: "Name",
          dataIndex: "payScheduleName",
          link: {
            url: PAYSCHEDULE_URLS.Detail,
          },
          sortOrder: getSortOrder({ sortInfo, field: "payScheduleName" }),
          claims,
          component,
          ...commonProps,
        },
        {
          title: "Occurrence",
          dataIndex: "payOccurence",
          sortOrder: getSortOrder({ sortInfo, field: "payoccurrencename" }),
          ...commonProps,
        },
        {
          title: "Start Date",
          dataType: "date",
          dataIndex: "firstPayDate",
          sortOrder: getSortOrder({ sortInfo, field: "firstPayDate" }),
          ...commonProps,
        },
        { title: "Status", dataIndex: "isActive" },
      ];
      break;
    default:
      console.log("");
  }
  return colTemplate;
}

export function getPayrunListColumns({
  viewComponent,
  sortInfo,
  claims,
  dashboardView,
  isDraft,
  handleAction,
}) {
  const commonProps = { sorter: !dashboardView };
  let colTemplate = _.concat(
    [userRoles.CompanyArea].includes(getUserArea())
      ? [
          {
            title: "Pay Run Name",
            dataIndex: "name",
            sortOrder: getSortOrder({ sortInfo, field: "name" }),
            link: {
              url: isDraft
                ? "/company/<%companyID%>/payrun/<%payrunId%>/edit"
                : "/payrun/<%payrunId%>",
            },
            claims,
            component: viewComponent,
            dashboardView,
            width: 200,
            ...commonProps,
          },
        ]
      : [
          {
            title: "Pay Run Name",
            dataIndex: "name",
            sortOrder: getSortOrder({ sortInfo, field: "name" }),
            link: {
              url: isDraft
                ? "/company/<%companyID%>/payrun/<%payrunId%>/edit"
                : "/payrun/<%payrunId%>",
            },
            claims,
            component: viewComponent,
            dashboardView,
            width: 200,
            ...commonProps,
          },
          {
            title: "Company",
            dataIndex: "companyName",
            sortOrder: getSortOrder({ sortInfo, field: "companyname" }),
            ...commonProps,
          },
        ],
    isDraft
      ? [
          {
            title: "Business Unit",
            dataIndex: "businessUnitName",
            sortOrder: getSortOrder({ sortInfo, field: "businessname" }),
            ...commonProps,
          },
          {
            title: "Amendments Due Date",
            dataType: "date",
            dataIndex: "ammendmentDueDateTime",
            sortOrder: getSortOrder({ sortInfo, field: "ammendmentduedate" }),
            ...commonProps,
          },
          {
            title: "Pay Date",
            dataType: "date",
            dataIndex: "payDate",
            sortOrder: getSortOrder({ sortInfo, field: "paydate" }),
          },
          {
            title: "Amendments",
            dataIndex: "ammendmentCount",
            sortOrder: getSortOrder({ sortInfo, field: "ammendmentcount" }),
            ...commonProps,
            render: (data, record) => {
              return (
                <span
                  className={data ? "color-primary hand" : ""}
                  onClick={(event) => {
                    data
                      ? handleAction({
                          event,
                          record,
                          action: "viewAmendmentSummary",
                        })
                      : nullFunction();
                  }}
                >
                  {data}
                </span>
              );
            },
          },
        ]
      : [
          {
            title: "Business Unit",
            dataIndex: "businessUnitName",
            sortOrder: getSortOrder({ sortInfo, field: "businessname" }),
            ...commonProps,
          },
          {
            title: "Frequency",
            dataIndex: "payOccurrenceName",
            sortOrder: getSortOrder({ sortInfo, field: "payoccurrencename" }),
            ...commonProps,
          },
          {
            title: "Cycle Start Date",
            dataType: "date",
            dataIndex: "payCycleStartDate",
            sortOrder: getSortOrder({ sortInfo, field: "payCycleStartDate" }),
            ...commonProps,
          },
          {
            title: "Amendments Due Date",
            dataType: "date",
            dataIndex: "ammendmentDueDateTime",
            sortOrder: getSortOrder({ sortInfo, field: "ammendmentduedate" }),
            ...commonProps,
          },
          {
            title: "Pay Date",
            dataType: "date",
            dataIndex: "payDate",
            sortOrder: getSortOrder({ sortInfo, field: "paydate" }),
          },
          {
            title: "Amendments",
            dataIndex: "ammendmentCount",
            sortOrder: getSortOrder({ sortInfo, field: "ammendmentcount" }),
            ...commonProps,
            render: (data, record) => {
              return (
                <span
                  className={data ? "color-primary hand" : ""}
                  onClick={(event) => {
                    data
                      ? handleAction({
                          event,
                          record,
                          action: "viewAmendmentSummary",
                        })
                      : nullFunction();
                  }}
                >
                  {data}
                </span>
              );
            },
          },
        ]
  );
  let finalTemplate = _.concat(
    colTemplate,
    [userRoles.SystemAdminArea].includes(getUserArea())
      ? [
          {
            title: "Stage",
            dataType: "stages",
            align: "center",
          },
          { title: "Type", dataIndex: "payrunTypeID" },
        ]
      : isDraft
      ? []
      : [{ title: "Type", dataIndex: "payrunTypeID" }]
  );
  return finalTemplate;
}
export function getInvoiceListColumns({
  sortInfo,
  claims,
  viewComponent,
  ignoreStatus,
  renderDate,
  dashboardView,
}) {
  let colTemplate = _.concat(
    [userRoles.SystemAdminArea, userRoles.PSPArea].includes(getUserArea())
      ? [
          {
            title: "Pay Run Model",
            dataIndex: "payrollModelID",
            dataType: "payrunModel",
            sorter: false,
          },
          {
            title: "Company",
            dataIndex: "companyName",
            sorter: !dashboardView,
            sortOrder: getSortOrder({ sortInfo, field: "companyName" }),
          },
          {
            title: "Business Unit",
            dataIndex: "businessUnitName",
            sorter: !dashboardView,
            sortOrder: getSortOrder({ sortInfo, field: "businessUnitName" }),
          },
        ]
      : [],
    [
      {
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        sorter: !dashboardView,
        sortOrder: getSortOrder({ sortInfo, field: "invoiceNumber" }),
        link: { url: "/payrun/<%payRunID%>" },
        claims,
        component: viewComponent,
        ignoreStatus: ignoreStatus,
      },
      {
        title: "Pay Run Name",
        dataIndex: "payrunName",
        sorter: !dashboardView,
        sortOrder: getSortOrder({ sortInfo, field: "payrunName" }),
      },
      {
        title: "Invoice Date",
        dataIndex: "",
        key: "invoiceDate",
        sorter: false,
        render: (record) => {
          return (
            <div>
              <div>
                <Tooltip title="Invoice generated">
                  {" "}
                  {renderDate(record && record.invoiceDate)}
                </Tooltip>
              </div>
              {record && record.payrollModelID === PEO_MODEL_ID && (
                <div>
                  <Tooltip title="Payment due">
                    {" "}
                    {renderDate(record && record.paymentDueDateTime)}
                  </Tooltip>
                </div>
              )}
              {record && record.payrollModelID === PEO_MODEL_ID && (
                <div>
                  <Tooltip title="Date paid">
                    {" "}
                    {renderDate(record && record.datePaid)}
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "invoiceStatusId",
        sorter: false,
        render: (record) => {
          let statusName;
          switch (Number(record)) {
            case 1:
              statusName = <Badge count="UNPAID" className="bg-badge-danger" />;
              break;
            case 2:
              statusName = (
                <Badge
                  count="PARTIALLY PAID"
                  className="bg-badge-mid-warning"
                />
              );
              break;
            case 3:
              statusName = (
                <Badge count="FULLY PAID" className="bg-badge-warning" />
              );
              break;
            case 4:
              statusName = (
                <Badge count="PAYMENT SKIPPED" className="bg-badge-dark" />
              );
              break;
            case 5:
              statusName = (
                <Badge count="PAYMENT CONFIRMED" className="bg-badge-success" />
              );
              break;
            default:
              break;
          }

          return <div>{statusName}</div>;
        },
      },
      {
        title: "Amount",
        dataIndex: "",
        key: "totalAmount",
        align: "right",
        sorter: false,
        render: (record) => {
          return (
            <div>
              <div>
                <Tooltip title="Total Tax Fees">
                  {currencyFormater(record && record.totalTaxFees)}
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Total Fees">
                  {currencyFormater(record && record.totalFees)}
                </Tooltip>
              </div>
              <div>
                {" "}
                <Tooltip title="Total Amount">
                  {currencyFormater(record && record.totalAmount)}
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    ]
  );
  return colTemplate;
}
export function getCompanyListColumns({
  sortInfo,
  dashboardView,
  component,
  claims,
}) {
  const colTemplate = [
    {
      title: "Name",
      dataIndex: "name",
      link: { url: "/company/<%id%>" },
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "name" }),
      component: component,
      claims: claims,
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "country" }),
    },
    {
      title: "Business Units",
      dataIndex: "businessUnitCount",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "businessUnitCount" }),
    },
    {
      title: "Status",
      dataIndex: "isActive",
    },
  ];
  return colTemplate;
}

export function getBusinessUnitListColumns({
  claims,
  component,
  dashboardView,
  sortInfo,
  handleAssignPayrollManager,
}) {
  const colTemplate = [
    {
      title: "Business Name",
      dataIndex: "name",
      link: { url: "/businessunit/<%id%>?companyid=<%companyId%>" },
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "name" }),
      claims,
      component,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "companyName" }),
    },
    {
      title: "Country",
      dataIndex: "registeredAddressCountry",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "country" }),
    },
    {
      title: "Pay Schedules",
      dataIndex: "paySchedules",
      sorter: false,
      sortOrder: getSortOrder({ sortInfo, field: "payScheduleCount" }),
    },
    {
      title: "Business Users",
      dataIndex: "companyUsers",
      sorter: false,
      render: (data, record) => {
        if (_.isEmpty(mustBeArray(data)))
          return (
            <AddIconIndex
              size={TABLE_COLUMN_ADD_ICON_SIZE}
              onClick={() => console.log(" ")}
            />
          );
        return (
          <AvatarGroup users={mustBeArray(data)} color={Colour.Blue[500]} />
        );
      },
    },
    {
      title: "Payroll Managers",
      dataIndex: "assignedUsers",
      sorter: false,
      sortOrder: getSortOrder({ sortInfo, field: "assignedUsers" }),
      render: (data, record) => {
        if (_.isEmpty(mustBeArray(data)))
          return (
            <AddIconIndex
              onClick={(e) => {
                !dashboardView && handleAssignPayrollManager({ e, record });
              }}
              size={TABLE_COLUMN_ADD_ICON_SIZE}
            />
          );
        return (
          <div
            onClick={(e) => {
              !dashboardView && handleAssignPayrollManager({ e, record });
            }}
          >
            <AvatarGroup users={mustBeArray(data)} color={Colour.Blue[500]} />
          </div>
        );
      },
    },
    { title: "Status", dataIndex: "isActive" },
  ];
  return colTemplate;
}

export function getPayrollProviderListColumns({
  dashboardView,
  sortInfo,
  claims,
  component,
}) {
  const colTemplate = [
    {
      title: "Name",
      dataIndex: "name",
      link: { url: "/payrollprovider/<%id%>" },
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "name" }),
      claims,
      component,
    },
    {
      title: "Operating Country",
      dataIndex: "country",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "country" }),
    },
    {
      title: "Business Units Serviced",
      dataIndex: "businessUnitCount",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "businessunitserviced" }),
    },
    { title: "Status", dataIndex: "isActive" },
  ];
  return colTemplate;
}

export function getBillingProviderListColumns({
  sortInfo,
  claims,
  component,
  dashboardView,
}) {
  const colTemplate = [
    {
      title: "Name",
      dataIndex: "name",
      link: { url: "/billingserviceproviders/<%id%>" },
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "name" }),
      claims,
      component,
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "country" }),
    },
    {
      title: "Business Units Serviced",
      dataIndex: "businessUnitCount",
      sorter: !dashboardView,
      sortOrder: getSortOrder({ sortInfo, field: "businessNumber" }),
    },
    { title: "Status", dataIndex: "isActive" },
  ];
  return colTemplate;
}

export function getUserListColumns({
  userType,
  updateAuthentication,
  claims,
  member,
  hideUserStatus,
  sortInfo,
  component,
  changeStatus,
  userMode,
  toggleComponent,
  dashboardView,
}) {
  const twoFAColumn =
    member?.details?.username === SYSTEM_ADMIN_USERNAME
      ? [
          {
            title: "Multi-Factor Authentication",
            dataIndex: "mfaEnable",
            dataType: "toggleStatus",
            align: "center",
            displayComponent: true,
            action: updateAuthentication,
            toggleTitle: "multi-factor authentication",
            width: userType === "company" ? "12%" : "8%",
            dashboardView: dashboardView,
          },
        ]
      : [];
  const companyColTemplate = _.concat(
    _.concat(
      [
        {
          title: "Name",
          dataIndex: "name",
          dataType: "nameAndImage",
          link: { url: `/user/${userType}/<%id%>` },
          sorter: !dashboardView,
          sortOrder: getSortOrder({ sortInfo, field: "name" }),
          claims,
          component,
          imageParam: "profilePhoto",
        },
        {
          title: "Email",
          dataIndex: "emailAddress",
          sorter: !dashboardView,
          sortOrder: getSortOrder({ sortInfo, field: "emailaddress" }),
        },
        {
          title: "Role",
          dataIndex: "userPersona",
          dataType: "userPersona",
          width: "20%",
          sorter: false,
          member,
          userType: "company",
        },
      ],
      hideUserStatus
        ? [
            {
              title: "Enable/Disable",
              dataIndex: "enabled",
              dataType: "toggleStatus",
              align: "center",
              action: changeStatus,
              userMode: userMode,
              claims,
              toggleComponent,
              dashboardView: dashboardView,
            },
          ]
        : [
            {
              title: "Status",
              dataIndex: "activated",
              sorter: false,
              dataType: "active/inactive",
            },
            {
              title: "Enable/Disable",
              dataIndex: "enabled",
              dataType: "toggleStatus",
              align: "center",
              action: changeStatus,
              userMode: userMode,
              claims,
              toggleComponent,
              dashboardView: dashboardView,
            },
          ]
    ),
    twoFAColumn
  );
  const pspColTemplate = _.concat(
    _.concat(
      [
        {
          title: "Name",
          dataIndex: "name",
          dataType: "nameAndImage",
          link: { url: `/user/${userType}/<%id%>` },
          sorter: !dashboardView,
          sortOrder: getSortOrder({ sortInfo, field: "name" }),
          claims,
          width: "12%",
          component,
          imageParam: "profilePhoto",
        },
        {
          title: "Companies / Business Units",
          dataIndex: "assignedCompanies",
          sorter: false,
          width: "10%",
          sortOrder: getSortOrder({ sortInfo, field: "assignedCompanies" }),
          render: (data, record) => {
            return (
              <React.Fragment>
                <span>{record.relatedCompanies}</span>/
                <span>{record.relatedBusinesses}</span>
              </React.Fragment>
            );
          },
        },
        {
          title: "Pay Schedules",
          dataIndex: "payScheduleCount",
          sorter: false,
          sortOrder: getSortOrder({ sortInfo, field: "payScheduleCount" }),
        },
        {
          title: "Role",
          dataIndex: "userPersona",
          dataType: "userPersona",
          width: "13%",
          sorter: false,
          member,
          userType: "psp",
        },
        {
          title: "Email",
          dataIndex: "emailAddress",
          sorter: !dashboardView,
          sortOrder: getSortOrder({ sortInfo, field: "emailaddress" }),
          width: "13%",
        },
        {
          title: "Last Login",
          dataIndex: "lastLogout",
          sorter: !dashboardView,
          sortOrder: getSortOrder({ sortInfo, field: "lastLogout" }),
          render: (data) => displayDates({ value: data }),
        },
        {
          title: "Added By",
          dataIndex: "addedByUser",
          width: "14%",
          sorter: !dashboardView,
          sortOrder: getSortOrder({ sortInfo, field: "addedBy" }),
          render: (record) => {
            return getFullName({ name: record });
          },
        },
      ],
      hideUserStatus
        ? [
            {
              title: "Enable/Disable",
              dataIndex: "enabled",
              dataType: "toggleStatus",
              align: "center",
              action: changeStatus,
              userMode: userMode,
              claims,
              toggleComponent,
              userType: "psp",
              width: "7%",
              member,
              dashboardView,
            },
          ]
        : [
            {
              title: "Status",
              dataIndex: "activated",
              dataType: "active/inactive",
              sorter: false,
              width: "6%",
            },
            {
              title: "Enable/Disable",
              dataIndex: "enabled",
              dataType: "toggleStatus",
              align: "center",
              action: changeStatus,
              userMode: userMode,
              claims,
              toggleComponent,
              userType: "psp",
              width: "7%",
              member,
              dashboardView,
            },
          ]
    ),
    twoFAColumn
  );
  if (userType === "company") return companyColTemplate;
  return pspColTemplate;
}

export function getBspAccountListColumns({ sortInfo }) {
  const colTemplate = [
    {
      title: "Name",
      dataIndex: "accountName",
      sorter: true,
      sortOrder: getSortOrder({ sortInfo, field: "accountname" }),
    },
    {
      title: "Bank",
      dataIndex: "bank",
      sorter: true,
      sortOrder: getSortOrder({ sortInfo, field: "bank" }),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      sorter: true,
      sortOrder: getSortOrder({ sortInfo, field: "currency" }),
    },
    {
      title: "Account No.",
      dataIndex: "accountNumber",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "accountnumber" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
    },
    {
      title: "BSB",
      dataIndex: "bsb",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "bsb" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
    },
    {
      title: "Swift",
      dataIndex: "swift",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "swift" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
    },
    {
      title: "IBAN",
      dataIndex: "iban",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "iban" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
    },
  ];
  return colTemplate;
}

// claims
export function getClaimAndStatus({
  claims,
  componentName = "",
  hasComponents = false,
}) {
  const claim = !hasComponents
    ? claims
    : claims?.components
    ? _.find(mustBeArray(claims?.components), (c) => c.name === componentName)
    : {};
  let status = "";
  switch (claim?.authorizationStatus) {
    case "Visible - Enabled":
      break;
    case "Visible - Disabled":
      status = "Visible-Disabled";
      break;
    case "hidden":
      status = "hidden";
      break;
    default:
      status = "hidden";
      break;
  }
  return { claim: claim || {}, status };
}

export function getQueryOptionsAndType({ options = {} }) {
  let type = "";
  let temp = {};
  if (options && options.q) {
    type = options.q.type || "";
    temp = options.q;
  }
  if (temp && temp.type) {
    delete temp.type;
  }
  return { temp, type };
}

export function getBusinessModelName({ value }) {
  if (Number(value) === OUTSOURCE_MODEL_ID) return "Outsource";
  return "Professional Employer Organization (PEO)";
}

export function getAbnAndRegistrationText({ countryId }) {
  return Number(countryId) === AUSTRALIA_COUNTRY_ID
    ? "ABN"
    : "Registration Number";
}

export function getRatePercentageText({ feeOption }) {
  return Number(feeOption) === 1 ? "Percentage" : "Rate";
}

export function getStateRateAndCategoriesText(data) {
  if (_.isEmpty(mustBeArray(data))) return "No";
  return "Yes";
}

export function getPayItemsText({ data }) {
  return _.find(mustBeArray(data), (o) => o.checked) ? "Yes" : "No";
}

export function getConditionalResponse({ condition, resp1, resp2 }) {
  return condition ? resp1 : resp2;
}

export function conditionalParameter({
  data = undefined,
  field = "",
}: {
  data?: any;
  field?: string;
}) {
  return data ? data[field] : undefined;
}

export function getDateTimeField({ data, format = "h:mm a", response = "" }) {
  if (data) return data.format(format);
  return response;
}

export function getLowerCase(data) {
  return data?.toLowerCase?.();
}

function getUpperCase(data) {
  return data?.toUpperCase?.();
}

export function getOptionalParameter({ value1, value2 }) {
  return value1 || value2;
}

export function getMatchParams({ match, field }) {
  return match?.params?.[field];
}

export function getTimeZoneFilterName(data) {
  if (!data) return "";
  let stringifiedCountries = mustBeArray(data.countries).reduce((a, b) => {
    return a ? `${a}, ${b.countryName}` : b.countryName;
  }, "");
  return `${stringifiedCountries}  ${data.value}`;
}

export function reOrderTimeZoneList({ countries, selectedCountry, timezone }) {
  if (
    !selectedCountry ||
    _.isEmpty(mustBeArray(countries)) ||
    _.isEmpty(mustBeArray(timezone))
  )
    return mustBeArray(timezone);
  let selectedCountryName = _.find(
    mustBeArray(countries),
    (o) => o.id === selectedCountry
  );
  if (!selectedCountryName) return mustBeArray(timezone);
  let filteredListWithCountryName = _.filter(mustBeArray(timezone), (o) =>
    _.find(
      mustBeArray(o.countries),
      (country) =>
        getLowerCase(country.countryName) ===
        getLowerCase(selectedCountryName.value)
    )
  );
  let filteredListWithoutCountryName = _.difference(
    timezone,
    filteredListWithCountryName
  );
  return [...filteredListWithCountryName, ...filteredListWithoutCountryName];
}

export function getAmendmentTypeName(amendmentTypeId: number) {
  switch (amendmentTypeId) {
    case PAYRUN_AMENDMENT_TYPE_ID:
      return PAYRUN_AMENDMENT_TYPE_NAME;
    case PERMANENT_AMENDMENT_TYPE_ID:
      return PERMANENT_AMENDMENT_TYPE_NAME;
    case REOCCURRING_AMENDMENT_TYPE_ID:
      return RECURRING_AMENDMENT_TYPE_NAME;
    default:
      return "";
  }
}
/**
 *
 * @param changeTaskId ID of the change task
 * @returns string: name of the corresponding change task
 */
export function getChangeTaskName(changeTaskId: number) {
  switch (changeTaskId) {
    case ALLOWANCE_CHANGE_TYPE_ID:
      return ALLOWANCE_CHANGE_TYPE_NAME;
    case DEDUCTION_CHANGE_TYPE_ID:
      return DEDUCTION_CHANGE_TYPE_NAME;
    case SALARY_CHANGE_TYPE_ID:
      return SALARY_CHANGE_TYPE_NAME;
    case TERMINATION_CHANGE_TYPE_ID:
      return TERMINATION_CHANGE_TYPE_NAME;
    default:
      return "";
  }
}

export function moduleHasMoreData({ data, page_size }) {
  if (data.length !== 0 && data.length === page_size) return true;
  return false;
}

export function listHasMoreData({ data, currentList, currentTotal }) {
  if (data.length !== 0 && currentList.length < currentTotal) return true;
  return false;
}

export function getInvoiceRowClassname({ differenceInDays, defaultClassName }) {
  if (differenceInDays > 3 && differenceInDays <= 7)
    return `${defaultClassName} invoice-bg-light-warning`;
  if (differenceInDays >= 1 && differenceInDays <= 3)
    return `${defaultClassName} invoice-bg-mid-warning`;
  return defaultClassName;
}

export function getCurrentAuthorizationDom(member) {
  return mustBeArray(member?.details?.authorizationDOM);
}

export function getColumnSortOrder({ sortInfo, name }) {
  return sortInfo && sortInfo.field === name && sortInfo.order
    ? sortInfo.order === "asc"
      ? "ascend"
      : "descend"
    : false;
}

export function getFeeOptionName({ feeOptions, id }) {
  const selectedFeeOption = _.find(feeOptions, (option) => option.id === id);
  return selectedFeeOption && selectedFeeOption.name;
}

export function getTaxOptionName({ taxOptions, id }) {
  const selectedTaxOption = _.find(taxOptions, (option) => option.id === id);
  return (selectedTaxOption && selectedTaxOption.value) || "";
}

export function getDefaultQueryPage({ pageLoad, page }) {
  if (pageLoad) return 1;
  return page;
}

export function getScrollingListDefaultPerPage({ per_page, page, pageLoad }) {
  if (pageLoad) return Number(page) * per_page;
  return per_page;
}

// Table
export function getPaginationFromPersonalization({ personalizations }) {
  if (!personalizations) return 1;
  if (!_.isNaN(Number(personalizations[PAGE_NUMBER_INDEX].value)))
    return Number(personalizations[PAGE_NUMBER_INDEX].value);
  return 1;
}

export function getPageSizeFromPersonalization({ personalizations }) {
  if (!personalizations || !personalizations[PAGE_SIZE_INDEX]) return 20;
  return Number(personalizations[PAGE_SIZE_INDEX].value || 20);
}

export function getSortOrderPersonalization({ personalizations }) {
  if (!personalizations) return null;
  return personalizations[SORT_ORDER_INDEX].value || null;
}

export function getSortColumnFromPersonalization({ personalizations }) {
  if (!personalizations) return null;
  return personalizations[SORT_COLUMN_INDEX].value || null;
}

export function getPerPageFromPersonalization({ paginationType, pageSize }) {
  if (paginationType === PAGINATION_NOT_REQUIRED)
    return DEFAULT_PAGE_SIZE_NO_PAGINATION;
  return !_.isNaN(Number(pageSize)) ? Number(pageSize) : 10;
}

// Grid

export function getGridPaginationFromPersonalization({ personalizations }) {
  if (!personalizations) return 1;
  if (!_.isNaN(Number(personalizations[GRID_PAGE_NUMBER_INDEX].value)))
    return Number(personalizations[GRID_PAGE_NUMBER_INDEX].value);
  return 1;
}

export function getGridRowFromPersonalization({ personalizations }) {
  if (!personalizations || !personalizations[GRID_ROW_INDEX]) return 5;
  return Number(personalizations[GRID_ROW_INDEX].value || 5);
}

export function getGridColFromPersonalization({ personalizations }) {
  if (!personalizations || !personalizations[GRID_COLUMN_INDEX]) return 4;
  return Number(personalizations[GRID_COLUMN_INDEX].value || 4);
}

export function getGridSortOrderPersonalization({ personalizations }) {
  if (!personalizations) return null;
  return personalizations[GRID_SORT_ORDER_INDEX]?.value || null;
}

export function getGridSortColumnFromPersonalization({ personalizations }) {
  if (!personalizations) return null;
}

export function getListPaginationTypeFromPersonalization({ listContainer }) {
  return listContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]?.value;
}

export function getListSortingTypeFromPersonalization({ listContainer }) {
  return listContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]?.value;
}

export function getListIsPinnedFromPersonalization({ listContainer }) {
  return listContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;
}

export function getListIsGridableFromPersonalization({ listContainer }) {
  return !!listContainer?.gridable;
}

export function getGridRowAndColumn({ value }) {
  if (!value) return { row: 4, col: 5 };
  const splitValue = value.split("X");
  return {
    row: splitValue[0],
    col: splitValue[1],
  };
}

// Pinned Component

export function getPinnedComponentSize({ claim }) {
  return {
    width: claim?.rendering?.size?.minWidth,
    height: claim?.rendering?.size?.minHeight,
  };
}

export function isPinnedComponentResizeable({ claim }) {
  return !!claim?.rendering?.resizeable;
}

export function isComponentVisible({ claim }) {
  return VISIBLE_CLAIMS_ID.includes(Number(claim?.authorizationStatusId));
}

export function baseUrlMatches({ url1, url2 }) {
  if (!url1 || !url2) return false;
  let baseUrl1 = mustBeArray(url1.split("?"))[0];
  let baseUrl2 = mustBeArray(url2.split("?"))[0];
  return baseUrl1 === baseUrl2 ? true : false;
}

export function abbreviateUserFirstAndLastName({ firstName, lastName }) {
  return (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "");
}

export function getCurrentSelectedRoleId({ member }) {
  return member?.details?.selectedRole?.id;
}

export function getSelectedRoleTenantId({ member }) {
  return member?.details?.selectedRole?.tenant?.id;
}

export function getSelectedRole({ member }) {
  return member?.details?.selectedRole;
}

export function getFullName({ name }) {
  if (!name) return "";
  if (name.name) return name.name;
  return `${name.firstName} ${name.lastName}`;
}

export function handleAssignBusinessUnitNavigation({
  member,
  user,
  pspId = "",
}) {
  if (pspId) {
    return goToRoute(`/psp/${pspId}/user/${user?.id}/assignbusinessunits`);
  }
  if (isUserSystemAdmin({ member })) {
    const userPersonaWithManagerRole = _.find(
      mustBeArray(user.userPersona),
      (o) => [UserRole.PayrollManager, UserRole.PSPAdmin].includes(o.roleName)
    );
    const tenantId = parseItems(userPersonaWithManagerRole?.tenant)?.tenantId;
    return goToRoute(`/psp/${tenantId}/user/${user?.id}/assignbusinessunits`);
  }
  return goToRoute(
    `/psp/${getSelectedRoleTenantId({ member })}/user/${
      user?.id
    }/assignbusinessunits`
  );
}

export function getClassNameForActiveInactiveComponent({
  activeIndex,
  componentIndex,
}) {
  return componentIndex === activeIndex ? "block" : "display-none";
}

export function getAssignedCompaniesAndBusinessUnits({ data }) {
  if (!data) return "0/0";
  return `${data?.relatedCompanies || 0}/${data?.relatedBusinesses || 0}`;
}

export function getSortOrder({ sortInfo = {}, field }) {
  return sortInfo?.field === field && sortInfo.order
    ? sortInfo.order === "asc"
      ? "ascend"
      : "descend"
    : false;
}

export function getSortOrderRequestValue({ sorter }) {
  return sorter.order
    ? sorter.order === "ascend"
      ? "asc"
      : "desc"
    : sorter.order;
}

export function ShowFullNameWithImage({
  imageUrl = "",
  name = "",
  firstName = "",
  lastName = "",
}) {
  return (
    <div className="flex items-center">
      <Avatar user={{ name, firstName, lastName, profilePhoto: imageUrl }} />
      <div className="ml-3">
        {getFullName({ name: { firstName, lastName, name } })}
      </div>
    </div>
  );
}

export function formatArrayQueryParams({ data }: any) {
  if (_.isEmpty(mustBeArray(data))) return "";
  let formattedData = mustBeArray(data).join("|");
  return `|${formattedData}|`;
}

export function getUserRoles({ member, userType, record }) {
  let roles;
  if (
    isUserSystemAdmin({ member }) ||
    (userType === "company" && isUserPSPUser({ member }))
  ) {
    roles = mustBeArray(record).reduce((a: any, b: any) => {
      return a.includes(b.roleName)
        ? a
        : `${a ? `${a}, ${b.roleName}` : b.roleName}`;
    }, "");
  } else {
    let selectedRole = _.find(
      mustBeArray(record),
      (o) =>
        getSelectedRoleTenantId({ member }) === parseItems(o.tenant)?.tenantId
    );
    roles = `${selectedRole?.roleName}`;
  }
  return roles;
}

export function updateMultiSelectFilters({ newValue }) {
  if (mustBeArray(newValue).includes(CLEAR_SELECTION_VALUE)) {
    return [];
  } else {
    return [...mustBeArray(newValue)];
  }
}

export function isUserAssignedAsPayrollManager({
  user,
  assignedPayrollManagers,
}) {
  return _.find(
    mustBeArray(assignedPayrollManagers),
    (o) => o.userId === user?.id
  )
    ? true
    : false;
}

export function isUserPrimaryPayrollManager({
  user,
  assignedPayrollManagers,
}: {
  user: any;
  assignedPayrollManagers?: any;
}) {
  if (!assignedPayrollManagers) {
    return user?.assigneeType === PRIMARY_PAYROLL_MANAGER_ID;
  }
  let assignedUser = _.find(
    mustBeArray(assignedPayrollManagers),
    (o) => o.userId === user?.id
  );
  if (!assignedUser) return false;
  return assignedUser.isPrimary;
}

export function isDataEmptyOnSelectedPage({
  data,
  dashboardView = false,
  pagination,
}) {
  if (
    _.isEmpty(mustBeArray(data)) &&
    Number(pagination) !== 1 &&
    !dashboardView
  )
    return true;
  return false;
}

export function getDeviceEnrolmentActionText({ action }) {
  switch (action) {
    case "disableDeviceEnrolment":
      return "disable";
    case "mandatoryDeviceEnrolment":
      return "enable mandatory";
    default:
      return "enable optional";
  }
}

export function getAllUsersDeviceEnrolmentActionText({ action }) {
  switch (action) {
    case "mandatoryAllDeviceEnrolment":
      return "enable mandatory";
    case "optionalAllDeviceEnrolment":
      return "enable optional";
    default:
      return "disable";
  }
}

export function getActiveFiltersforDashboardLabel({ filters, countryList }) {
  let activeFilters = [];
  if (filters?.name) {
    activeFilters.push(filters.name);
  }
  if ([1, 0].includes(filters?.isActive)) {
    activeFilters.push(filters.isActive === 0 ? "Inactive" : "Active");
  }
  if (filters?.countryId) {
    let country = _.find(
      mustBeArray(countryList),
      (o) => o.id === filters.countryId
    );
    activeFilters.push(country?.value);
  }
  return activeFilters;
}

export function defaultListEmpty({
  list,
  filters,
  claims,
  component,
  current,
  grid,
}) {
  if (
    _.isEmpty(mustBeArray(list)) &&
    _.isEmpty(filters) &&
    checkClaimsAuthorization({
      component,
      claims,
    }) &&
    Number(current) === 1 &&
    Number(grid?.page) === 1
  )
    return true;
  return false;
}
export function getDueDaysText({ days }) {
  if (days === 0) return "Due today";
  if (days < 0) return "Overdue";
  return `${days} day${days === 1 ? "" : "s"}`;
}

export function multipleFieldsQueryGenerator({ value }) {
  return mustBeArray(value).reduce(
    (a: any, b: any) => a + Math.pow(2, b - 1),
    0
  );
}

export function isSettingsIntegrated({
  record,
  type,
}: {
  record: any;
  type: string;
}) {
  let object = mustBeArray(record).find((o) => o.settingName === type);
  return object.isIntegrated;
}

export function getFileExtension(fileName: string) {
  let extensionType = fileName.split(".");
  return _.last(extensionType);
}

export function isEmpty(obj) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

export function isNullEmptyUndefined(data) {
  return data === null || trimWhiteSpaces(data) === "" || data === undefined;
}

export function isNull(data) {
  return data === null;
}

export function isUndefined(data) {
  return data === undefined;
}

// Calculate variance
export function getDifferenceAndVariance(
  previousAmount: any,
  currentAmount: any
) {
  let difference =
    (isUndefined(previousAmount) ? 0 : Number(previousAmount)) -
    Number(currentAmount);
  if (Number(currentAmount) === 0 || isUndefined(currentAmount))
    return { variance: "N/A", difference };
  const variance = (difference / currentAmount) * 100;
  return { variance: variance.toFixed(2), difference };
}

export function getModuleType(type: string) {
  let moduleType = "";
  switch (type) {
    case "pay schedule":
      moduleType = "Pay Schedule";
      break;
    case "business unit":
      moduleType = "Business Unit";
      break;
    case "billingserviceprovider":
      moduleType = "Billing Service Provider";
      break;
    case "payrun":
      moduleType = "Pay Run";
      break;
    case "company":
      moduleType = "Company";
      break;
    case "payroll service provider":
      moduleType = "Payroll Service Provider";
      break;
    case "generalAmendment":
      moduleType = "Amendment";
      break;
    default:
      break;
  }
  return moduleType;
}

// Display No Record in list
export function displayNoRecordsInList({
  list,
  loading,
  current,
  gridCurrent,
  dashboardView,
  claims,
  componentName,
  filters,
}: any) {
  return !!(
    isEmpty(mustBeArray(list)) &&
    !loading &&
    isEmpty(filters) &&
    checkClaimsAuthorization({
      component: componentName,
      claims,
    }) &&
    !dashboardView &&
    Number(current) === 1 &&
    Number(gridCurrent) === 1
  );
}

export function SimpleErrorHandler(error) {
  message.error(error.message);
}

/**
 * Check's if the number is precise to the given decimal places or less than the given decimal places
 * @param num String or Number to check the decimal precision of
 * @param precision Number of decimal places to check against
 * @returns boolean
 */
export function isPrecise(num: number | string, precision: number) {
  const arr = String(num).split(".");
  // If there is no decimal point, return true
  if (arr.length === 1) return true;
  // If there is a decimal point, check the length of the decimal places
  return arr[1]?.length <= precision;
}
