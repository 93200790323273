//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listPayrunAmendments,
  listPayrunCompleted,
  listPayschedulePayruns,
  listPayrun,
  generateAdhoc,
  listInvoices,
  getAmendmentHistory,
  listAmendmentTypes,
} from "../actions/payrun";
import {
  companyLookup,
  listCompanyBusinessUnits,
  listCompanyInvoices,
  getCompanyPayrunData,
  totalPayrollPEO,
  countryPayrunData,
  countryMonthlyData,
  listCompanyUsers,
  listCompanies,
} from "../actions/companies";
import { listPSPUsers } from "../actions/payrollprocessor";
import { billingServiceProviderHandler } from "../actions/billingServiceProvider";
import { getPayschedule } from "../actions/payschedule";
import { getPayrollProvider } from "../actions/payrollprovider";
import { fetchPayrollConnectionName } from "../actions/payrollprocessor";
import {
  getBusinessUnit,
  listBusinessUnits,
  listBillingServiceProviders,
} from "../actions/businessunits";
import { generalAmendmentHandler } from "../actions/generalAmendment";
import { timezoneLookup, lookUpHandler } from "../actions/lookup";
import PayRunStatus from "../../Common/payrunStatus";
import axios from "axios";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { dashboardActionHandler } from "../actions/dashboard";
import { varianceHandler } from "../actions/variance";
import { getOptionalParameter, mustBeArray } from "../../libs/utilities";
import { withRouter } from "../../hooks";

class Dashboard extends SwiftContainer {
  listInvoiceSupervisor: any;
  listPayrunExecutive: any;
  listPayrunsSupervisor: any;
  listPayrunsforPayrollAdmin: any;
  signal = axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }
  render() {
    const { Layout, userMode } = this.props;
    const { pageClaims } = this.state;
    return (
      <Layout
        userMode={this.props.userMode}
        cancelToken={this.signal.token}
        timezoneLookup={this.props.timezoneLookup}
        totalPayrollPEO={this.props.totalPayrollPEO}
        companyLookup={this.props.companyLookup}
        companyInvoices={this.props.companyInvoices}
        payrunAmendments={this.props.payrunAmendments}
        payrunAmendmentsCount={this.props.payrunAmendmentsCount}
        payrunApproval={this.props.payrunApproval}
        payrunApprovalCount={this.props.payrunApprovalCount}
        payrunTotal={this.props.payrunTotal}
        payrunTotalCount={this.props.payrunTotalCount}
        payrunCompleted={this.props.payrunCompleted}
        payrunCompletedCount={this.props.payrunCompletedCount}
        payrunPrepare={this.props.payrunPrepare}
        payrunProcess={this.props.payrunProcess}
        payrunPrepareCount={this.props.payrunPrepareCount}
        payrunProcessCount={this.props.payrunProcessCount}
        payrunInvoicePaid={this.props.payrunInvoicePaid}
        payrunInvoiceUnpaid={this.props.payrunInvoiceUnpaid}
        payrunInvoicePaidCount={this.props.payrunInvoicePaidCount}
        payrunInvoiceUnpaidCount={this.props.payrunInvoiceUnpaidCount}
        loading={this.state.loading}
        signal={this.signal}
        listPayrunExecutive={this.listPayrunExecutive}
        listPayrunsforPayrollAdmin={this.listPayrunsforPayrollAdmin}
        listPayrunsSupervisor={this.listPayrunsSupervisor}
        listInvoiceSupervisor={this.listInvoiceSupervisor}
        listCompanyBusinessUnits={(data: any) =>
          this.executeAction(this.props.listCompanyBusinessUnits, data)
        }
        listPayrun={(data: any) =>
          this.executeAction(this.props.listPayrun, data)
        }
        listPayrunAmendments={this.props.listPayrunAmendments}
        listPayrunCompleted={({ options, targetEndpoint = "" }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: { ...options, type: "completed" },
            targetEndpoint,
          })
        }
        listPayrunDraft={({ options, targetEndpoint = "" }: any) => {
          return this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              q: {
                ...(options && options.q ? options.q : {}),
                type: "draft",
                StatusID: PayRunStatus.getStatusID({
                  status: "draft",
                  userMode,
                }),
              },
            },
            targetEndpoint,
          });
        }}
        listPayschedulePayruns={(data: any) =>
          this.executeAction(this.props.listPayschedulePayruns, data)
        }
        listPayrunTotal={({ options, targetEndpoint = "" }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: { ...options, type: "total" },
            targetEndpoint,
          })
        }
        listPaidInvoicePayrun={({ options, targetEndpoint = "" }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              type: "paid",
              StatusID: PayRunStatus.getPaidStatusID(),
            },
            targetEndpoint,
          })
        }
        listUnpaidInvoicePayrun={({ options, targetEndpoint = "" }: any) =>
          this.executeAction(this.props.listPayrun, {
            options: {
              ...options,
              type: "unpaid",
              StatusID: PayRunStatus.getStatusID({
                status: "invoiceUnpaid",
                userMode,
              }),
            },
            targetEndpoint,
          })
        }
        getBusinessUnit={(data: any) =>
          this.executeAction(this.props.getBusinessUnit, data)
        }
        generateAdhoc={(data: any) =>
          this.executeAction(this.props.generateAdhoc, data)
        }
        listCompanyInvoices={(data: any) =>
          this.executeAction(this.props.listCompanyInvoices, data)
        }
        companyInvoicesCount={this.props.companyInvoicesCount}
        getCompanyPayrunData={(data: any) =>
          this.executeAction(this.props.getCompanyPayrunData, data)
        }
        companyPayrunTotal={this.props.companyPayrunTotal}
        countryPayrunData={this.props.countryPayrunData}
        countryMonthlyData={this.props.countryMonthlyData}
        pageClaims={pageClaims}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        dashboardList={this.props.dashboardList}
        listInvoices={this.props.listInvoices}
        listCompanyUsers={this.props.listCompanyUsers}
        listPSPUsers={this.props.listPSPUsers}
        currentAuthorizationDOM={this.props.currentAuthorizationDOM}
        getAmendmentHistory={this.props.getAmendmentHistory}
        dashboardActionHandler={this.props.dashboardActionHandler}
        lookUpHandler={this.props.lookUpHandler}
        varianceHandler={this.props.varianceHandler}
        generalAmendmentHandler={this.props.generalAmendmentHandler}
        listAmendmentTypes={this.props.listAmendmentTypes}
        listCompanies={this.props.listCompanies}
        listBusinessUnits={this.props.listBusinessUnits}
        listPayschedules={this.props.getPayschedule}
        businessunit={this.props.businessunit}
        listBillingServiceProviders={this.props.listBillingServiceProviders}
        getPayrollProvider={this.props.getPayrollProvider}
        billingServiceProviderHandler={this.props.billingServiceProviderHandler}
        fetchPayrollConnectionName={this.props.fetchPayrollConnectionName}
        listAmendments={this.props.listPayrunAmendments}
        member={this.props.member}
        match={{ params: this.props.router.params }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payrunPrepare: getOptionalParameter({
    value1: state.payrun.prepare.list,
    value2: {},
  }),
  payrunPrepareCount: getOptionalParameter({
    value1: state.payrun.prepare.count,
    value2: 0,
  }),
  payrunTotal: getOptionalParameter({
    value1: state.payrun.total.list,
    value2: {},
  }),
  payrunTotalCount: getOptionalParameter({
    value1: state.payrun.total.count,
    value2: 0,
  }),
  payrunProcess: getOptionalParameter({
    value1: state.payrun.process.list,
    value2: [],
  }),
  payrunProcessCount: getOptionalParameter({
    value1: state.payrun.process.count,
    value2: 0,
  }),
  payrunAmendments: getOptionalParameter({
    value1: state.payrun.amendment.list,
    value2: [],
  }),
  payrunAmendmentsCount: getOptionalParameter({
    value1: state.payrun.amendment.count,
    value2: 0,
  }),
  payrunApproval: getOptionalParameter({
    value1: state.payrun.approval.list,
    value2: [],
  }),
  payrunApprovalCount: getOptionalParameter({
    value1: state.payrun.approval.count,
    value2: 0,
  }),
  payrunCompleted: getOptionalParameter({
    value1: state.payrun.completed.list,
    value2: [],
  }),
  payrunCompletedCount: getOptionalParameter({
    value1: state.payrun.completed.count,
    value2: 0,
  }),
  payrunInvoicePaid: getOptionalParameter({
    value1: state.payrun.paid.list,
    value2: [],
  }),
  payrunInvoicePaidCount: getOptionalParameter({
    value1: state.payrun.paid.count,
    value2: 0,
  }),
  payrunInvoiceUnpaid: getOptionalParameter({
    value1: state.payrun.unpaid.list,
    value2: [],
  }),
  payrunInvoiceUnpaidCount: getOptionalParameter({
    value1: state.payrun.unpaid.count,
    value2: 0,
  }),
  companyInvoices: getOptionalParameter({
    value1: state.company.invoices,
    value2: [],
  }),
  companyInvoicesCount: getOptionalParameter({
    value1: state.company.companyInvoicesCount,
    value2: 0,
  }),
  companyPayrunTotal: getOptionalParameter({
    value1: state.company.payrunTotal,
    value2: [],
  }),
  payrunPEO: getOptionalParameter({
    value1: state.company.payrunPEO,
    value2: [],
  }),
  payrunOutsourced: getOptionalParameter({
    value1: state.company.payrunOutsourced,
    value2: [],
  }),
  userMode: "",
  member: state.member,
  dashboardList: mustBeArray(state.dashboard && state.dashboard.list),
  currentAuthorizationDOM:
    state.member &&
    state.member.details &&
    state.member.details.authorizationDOM,
  businessunit: getOptionalParameter({
    value1: state.businessUnit.detail,
    value2: {},
  }),
});

const mapDispatchToProps = {
  listPayrun,
  listPayrunAmendments,
  listPayrunCompleted,
  listPayschedulePayruns,
  companyLookup,
  listCompanyBusinessUnits,
  timezoneLookup,
  getBusinessUnit,
  generateAdhoc,
  listCompanyInvoices,
  getCompanyPayrunData,
  totalPayrollPEO,
  countryPayrunData,
  countryMonthlyData,
  updateHeader,
  updateAuthorizationClaim,
  listInvoices,
  listCompanyUsers,
  listPSPUsers,
  getAmendmentHistory,
  dashboardActionHandler,
  lookUpHandler,
  varianceHandler,
  generalAmendmentHandler,
  listAmendmentTypes,
  listCompanies,
  listBusinessUnits,
  getPayschedule,
  listBillingServiceProviders,
  getPayrollProvider,
  billingServiceProviderHandler,
  fetchPayrollConnectionName,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
