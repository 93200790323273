import { Card } from "antd";
import React from "react";
import SwitchButton from "../../../../Common/ClaimComponents/Filter/SwitchButton";
import { IntegrationSettingsIcon } from "../../../../Common/customicons";
import { Loading } from "../../../../Common/ui";
import {
  IntegrationSetttingState,
  SwitchLoadingState,
} from "./IntegrationSettings";
import {
  BUSINESS_UNIT_AMENDMENT_INTEGRATION_SETTING,
  BUSINESS_UNIT_PAYRUN_INTEGRATION_SETTING,
  BUSINESS_UNIT_REPORTS_INTEGRATION_SETTING,
} from "../../../../constants";

interface IntegrationSettingsUIProps {
  claims?: any;
  handleChangeSwitch: (e: {
    value: boolean;
    type: "payrun" | "amendment" | "keypayreport";
  }) => void;
  integrationSettings: IntegrationSetttingState;
  switchLoading: SwitchLoadingState;
  isLoading?: boolean;
}

const IntegrationSettingsUI = (props: IntegrationSettingsUIProps) => {
  const {
    claims,
    isLoading,
    integrationSettings,
    switchLoading,
    handleChangeSwitch,
  } = props;
  return (
    <Card
      className="card"
      title={
        <div className="flex items-center">
          <IntegrationSettingsIcon className="mr-2 text-2xl color-disabled opacity-60" />
          <b>Integration Settings</b>
        </div>
      }
    >
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className="bg-default p-4 rounded-sm">
            <div>
              <SwitchButton
                claims={claims}
                name="Payruns"
                handleChangeSwitch={(e) => {
                  handleChangeSwitch({ value: e, type: "payrun" });
                }}
                componentName={BUSINESS_UNIT_PAYRUN_INTEGRATION_SETTING}
                disabled={!integrationSettings.payrun?.isEnabled}
                loading={switchLoading.payrun}
                checked={integrationSettings.payrun?.isIntegrated}
              />
            </div>
            <div className="mt-6">
              <SwitchButton
                claims={claims}
                name="Amendments"
                handleChangeSwitch={(e) => {
                  handleChangeSwitch({ value: e, type: "amendment" });
                }}
                componentName={BUSINESS_UNIT_AMENDMENT_INTEGRATION_SETTING}
                disabled={!integrationSettings.amendment?.isEnabled}
                loading={switchLoading.amendment}
                checked={integrationSettings.amendment?.isIntegrated}
              />
            </div>
            <div className="mt-6">
              <SwitchButton
                claims={claims}
                name="Reports"
                handleChangeSwitch={(e) => {
                  handleChangeSwitch({ value: e, type: "keypayreport" });
                }}
                componentName={BUSINESS_UNIT_REPORTS_INTEGRATION_SETTING}
                disabled={!integrationSettings.keypayreport?.isEnabled}
                loading={switchLoading.keypayreport}
                checked={integrationSettings.keypayreport?.isIntegrated}
              />
            </div>
          </div>
          <div className="text-xs mt-4">
            Note: Changes will take effect for newly created pay runs and
            amendments.
          </div>
        </React.Fragment>
      )}
    </Card>
  );
};

export default IntegrationSettingsUI;
