import { Checkbox, Select } from "antd";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import { CLEAR_SELECTION_VALUE } from "../../../constants";
import { mustBeArray, updateMultiSelectFilters } from "../../../libs";
import CountryFilter from "../../components/common/CountryFilter";
import _ from "lodash";

type AssignBusinessUnitFiltersProps = {
  filters: any;
  companiesList: any;
  payrollManagersList: any;
  listBusinessUnits: (e: any) => void;
  setFilters: (e: any) => void;
  listLoading: boolean;
};

export const AssignBusinessUnitFilters = ({
  filters,
  companiesList,
  payrollManagersList,
  listBusinessUnits,
  setFilters,
  listLoading,
}: AssignBusinessUnitFiltersProps) => {
  const handleSearch = () => {
    listBusinessUnits({ filtersReset: false });
  };

  const resetFilters = () => {
    setFilters({
      countryIds: [],
      companyIds: [],
      name: "",
      assigneduserIds: [],
    });
    listBusinessUnits({ filtersReset: true });
  };

  const handleFilterChange = (label: string, value: any) => {
    switch (label) {
      case "companyIds":
        return setFilters({ ...filters, [label]: [value] });
      case "countryIds":
      case "assignedUserIds":
        return setFilters({
          ...filters,
          [label]: updateMultiSelectFilters({ newValue: value }),
        });
      default:
        return setFilters({ ...filters, [label]: value });
    }
  };

  return (
    <>
      <SingleFieldTextBoxFilter
        placeholder="Business Name"
        value={filters?.name}
        onChange={(value) => handleFilterChange("name", value)}
        onSearch={handleSearch}
        ignoreStatus={true}
      />
      <SingleFieldDropdownFilter
        options={companiesList}
        value={mustBeArray(filters?.companyIds)[0]}
        onSelect={(value) => handleFilterChange("companyIds", value)}
        className="mr-4 w-150"
        searchBy="name"
        placeholder="Company"
        valueIsNumber={false}
        hasNoClaims={true}
      />
      <CountryFilter
        selectedCountries={filters?.countryIds}
        onChange={(value) => handleFilterChange("countryIds", value)}
        selectMultiple={true}
        searchBy="name"
      />
      <Select
        showArrow
        placeholder="Payroll Managers"
        onChange={(value) => handleFilterChange("assignedUserIds", value)}
        mode="multiple"
        filterOption={true}
        value={mustBeArray(filters?.assignedUserIds)}
        className={`custom-multi-selectbox w-[300px] ml-2`}
        showSearch={true}
        optionFilterProp={"searchby"}
        size="large"
        maxTagCount="responsive"
      >
        {mustBeArray(payrollManagersList).map((s: any, key: number) => (
          <Select.Option
            key={key}
            value={s.id}
            searchby={`${s.firstName} ${s.lastName}`}
            className="custom-multi-select-dropdown"
          >
            <Checkbox
              checked={mustBeArray(filters?.assignedUserIds).includes(s.id)}
              className="mr-3"
            />
            {s.firstName} {s.lastName}
          </Select.Option>
        ))}
        {!_.isEmpty(mustBeArray(payrollManagersList)) && (
          <Select.Option key="clearSelection" value={CLEAR_SELECTION_VALUE}>
            Clear Selection
          </Select.Option>
        )}
      </Select>
      <SearchIconButton
        loading={listLoading}
        onClick={handleSearch}
        hasClaims={false}
        className="ml-4"
      />
      <LinkButton
        buttonText="Reset"
        className="color-primary hand"
        onClick={resetFilters}
        ignoreClaims={true}
      />
    </>
  );
};
