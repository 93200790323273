import { RefObject, useRef } from 'react';

import * as S from './active-indicator.styles';
import { useActiveIndicator } from './use-active-indicator';

export const ActiveIndicatorTestId = {
  bar: 'tab-menu-active-indicator-bar',
};

type ActiveIndicatorProps = {
  parentRef: RefObject<HTMLElement>;
};

export const ActiveIndicator = ({ parentRef }: ActiveIndicatorProps) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  useActiveIndicator({ parentRef, indicatorRef });

  return <S.GradientBar ref={indicatorRef} data-testid={ActiveIndicatorTestId.bar} />;
};
