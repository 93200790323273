import { DawnCircleAlert, DawnTick24, DawnTriangleAlert } from '@xemplo/icons';
import { Colour } from '@xemplo/style-constants';

import * as S from './toast.style';
import { ToastProps, ToastTextWeight, ToastType } from './toast.types';

const ToastIconMap = {
  [ToastType.Confirmation]: (
    <DawnTick24
      color={Colour.Green[500]}
      fontSize="20px"
      data-testid="icon-confirmation"
    />
  ),
  [ToastType.Warning]: (
    <DawnCircleAlert
      color={Colour.Orange[400]}
      fontSize="20px"
      data-testid="icon-warning"
    />
  ),
  [ToastType.Alert]: (
    <DawnTriangleAlert color={Colour.Red[400]} fontSize="20px" data-testid="icon-alert" />
  ),
};

export function Toast(props: ToastProps) {
  const { text, type, weight = ToastTextWeight.Medium } = props;
  return (
    <S.ToastContainer $type={type}>
      {type && ToastIconMap[type]}
      <S.ToastText $weight={weight} data-testid="toast-text">
        {text}
      </S.ToastText>
    </S.ToastContainer>
  );
}

export default Toast;
