import { ExpedoUserProfile, PersonaRole, Tenant, UserPersona } from '../member';

export const CreateUserType = {
  PayrollUser: 'payrollmanagers',
  CompanyUser: 'companyusers',
} as const;

export type CreateUserType = (typeof CreateUserType)[keyof typeof CreateUserType];

export type NewUserFormInput = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobilePhone: string;
  phone?: string;
  getNotifications: 'true' | 'false' | boolean;
  mfaEnable?: 'true' | 'false' | boolean;
  deviceEnrolmentEnable?: number;
  serviceProviderId: string;
  roleId: string;
  addressId: number;
  userPersona: PersonaRoleDefinition[];
};

export interface UpdateUserFormInput {
  getNotifications: 'true' | 'false' | boolean;
  userPersona: Pick<PersonaRoleDefinition, 'companyId' | 'roleId'>[];
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobilePhone: string;
  phone?: string;
  mfaEnable?: 'true' | 'false' | boolean;
  deviceEnrolmentEnable?: number;
}

export type PersonaRoleDefinition = {
  roleId: string;
  payrollServiceProviderId?: string;
  companyId?: string;
  companyName?: string;
  payrollServiceProviderName?: string;
};

export interface SelectedPersona {
  selectedRole?: UserPersona;
  selectedTenant?: Tenant;
  selectedUserType?: CreateUserType;
  selectedRoleDisplayName?: string;
}

export type UserWithSelectedPersona = ExpedoUserProfile & Partial<SelectedPersona>;

export const DeviceEnrollmentOptions = {
  Disabled: 0,
  Optional: 1,
  Mandatory: 2,
} as const;

export type DeviceEnrollmentOptions =
  (typeof DeviceEnrollmentOptions)[keyof typeof DeviceEnrollmentOptions];

export const UserActionIdNames = {
  SendReminder: 'reminder',
  EnrolmentMandatory: 'enrolment-mandatory',
  EnrolmentOptional: 'enrolment-optional',
  EnrolmentDisable: 'enrolment-disable',
  ManageBusinessUnit: 'manage-business-unit',
} as const;
export type UserActionIdNames =
  (typeof UserActionIdNames)[keyof typeof UserActionIdNames];

export type UserRoleActions = {
  [PersonaRole.SystemAdmin]: UserActionIdNames[];
  [PersonaRole.CompanyAdmin]: UserActionIdNames[];
  [PersonaRole.PSPAdmin]: UserActionIdNames[];
};

export const UserPostApiActions = {
  SendReminder: 'sendreminder',
  SendInvite: 'sendinvite',
  Edit: 'update',
};
export type UserPostApiActions =
  (typeof UserPostApiActions)[keyof typeof UserPostApiActions];
