export const OUTSOURCE_MODEL_ID = 2;
export const PEO_MODEL_ID = 1;
export const PAYRUN_STAGES_OPTIONS = [
  { id: 9, name: "System Generated", value: "draft" },
  { id: 1, name: "Amendments", value: "amendments" },
  { id: 2, name: "Prepare", value: "prepare" },
  { id: 3, name: "Approval", value: "approval" },
  { id: 4, name: "Payment", value: "payment" },
  { id: 5, name: "Process", value: "process" },
  { id: 6, name: "Completed", value: "completed" },
  { id: 7, name: "Invoice Unpaid", value: "invoiceUnpaid" },
  { id: 8, name: "Invoice Paid", value: "invoicePaid" },
];
export const CURRENT_EXTERNAL_EARLINGLINES_INDEX = 0;
export const PREVIOUS_EXTERNAL_EARLINGLINES_INDEX = 1;

export const SCHEDULED_PAYRUN_ID = 1;
export const ADHOC_PAYRUN_ID = 2;

export const PAID_INVOICE_STATUS = [2, 3, 5, 6];
export const PAID_PAYRUN_STATUS = [15, 16, 17, 18];
export const COMPLETED_PAYRUN_STATUS = 18;

/** @knipignore */
export enum AMENDMENT_STATUS_ID {
  CREATED = 1,
  SUBMITTED = 2,
  APPROVED = 3,
  REJECTED = 4,
  PREPARED = 5,
  UPDATED = 6,
  RESUBMITTED = 8,
  ARCHIVED = 9,
  DRAFT = 10,
  COMPLETED = 11,
}

/** @deprecated use enum AMENDMENT_STATUS_ID instead */
export const AMENDMENT_APPROVED_STATUS = [3, 5]; // 3 is approved, 5 is prepared. Only approved amendments are prepared
export const AMENDMENT_DECLINED_STATUS = 4;
export const AMENDMENT_UPDATED_STATUS = 6;
export const AMENDMENT_RESUBMITTED_STATUS = 8;
export const AMENDMENT_APPROVED_ONLY_STATUS = 3;
export const AMENDMENT_CREATED_STATUS = 1;
export const AMENDMENT_SUBMITTED_STATUS = 2;
export const AMENDMENT_ARCHIVED_STATUS = 9;
export const AMENDMENT_DRAFT_STATUS = 10;
export const AMENDMENT_COMPLETED_STATUS = 11;
export const UNRESOLVED_AMENDMENTS_IDS = [1, 2, 6, 8];
export const RESOLVED_AMENDMENTS_IDS = [3, 4, 5];
export const AMENDEMNT_UNRESOLVED_STATUS = [1, 2, 4, 6, 8, 10];
export const AMENDMENT_PREPARED_STATUS = 5;
export const AMENDMENT_MODIFIED_STATUS = 6;

export const DEFAULT_FALLBACK_DATE = "0001-01-01T00:00:00";

export const INVOICE_PENDING_CONFIRMATION = [2, 3];
export const INVOICE_CONFIRM_PAYMENT = [5, 6];
export const INVOICE_PAYMENT_SKIPPED = [4];

export const AMENDMENT_AMOUNT_DECIMAL_TYPE = "decimalnumber";
export const AMENDMENT_AMOUNT_WHOLENUMBER_TYPE = "wholenumber";
export const AMENDMENT_AMOUNT_CURRENCY_TYPE = "currency";

export const PAYRUN_AMENDMENT_TYPE = "payrunAmendment";
export const PERMANENT_AMENDMENT_TYPE = "adhocAmendment";
export const REOCCURRING_AMENDMENT_TYPE = "reoccurringAmendment";
export const COMP_USER_DASH_AMENDMENT_TYPE = "compUserDashAmendment";

export const PAYRUN_AMENDMENT_TYPE_ID = 1;
export const PERMANENT_AMENDMENT_TYPE_ID = 2;
export const REOCCURRING_AMENDMENT_TYPE_ID = 3;

export const AMENDMENT_TYPES_OPTIONS = [
  { name: "Pay Run Amendment", id: PAYRUN_AMENDMENT_TYPE_ID },
  { name: "Permanent Amendment", id: PERMANENT_AMENDMENT_TYPE_ID },
  { name: "Recurring Amendment", id: REOCCURRING_AMENDMENT_TYPE_ID },
];

export const REOCCURRING_STATUS_MANUAL_ID = 0;
export const REOCCURRING_STATUS_CREATED_ID = 1;
export const REOCCURRING_STATUS_APPLIED_ID = 2; //Manually Applied
export const REOCCURRING_STATUS_CANCELLED_ID = 3;
export const REOCCURRING_STATUS_CANCELLED_APPLIED_ID = 4;
export const REOCCURRING_STATUS_EDITED_ID = 5;
export const REOCCURRING_STATUS_EDITED_APPLIED_ID = 6;
export const REOCCURRING_STATUS_IN_PROGRESS_ID = 7;
export const REOCCURRING_STATUS_APPLIED_SUCCESS_ID = 8; //Applied by integration
export const REOCCURRING_STATUS_APPLIED_FAIL_ID = 9;
export const REOCCURRING_STATUS_APPLIED_PARTIAL_ID = 10;
export const REOCCURRING_STATUS_REJECTED_ID = 12;
export const REOCCURRING_STATUS_UNAPPLIED_ID = 13;

export const REPEAT_NEVER_ID = "Never";

export const END_EMPLOYEE_NUMBER = 100000000;

export const DEFAULT_GUID = "00000000-0000-0000-0000-000000000000";

// Change type id

export const ALLOWANCE_CHANGE_TYPE_ID = 1;
export const DEDUCTION_CHANGE_TYPE_ID = 2;
export const SALARY_CHANGE_TYPE_ID = 3;
export const TERMINATION_CHANGE_TYPE_ID = 7;

// Duration type options
const MAX_AMOUNT_ID = 1;
const END_DATE_ID = 2;
export const DURATION_TYPE_OPTIONS = [
  { name: "Maximum Amount", id: MAX_AMOUNT_ID },
  { name: "End Date", id: END_DATE_ID },
];

// Payrun type options
export const SCHEDULED_PAYRUN_TYPE_ID = 1;
export const ADHOC_PAYRUN_TYPE_ID = 2;
export const NEXT_PAYRUN_TYPE_ID = 3;

export const PAYRUN_TYPE_OPTIONS = [
  { name: "Scheduled Pay Run", value: SCHEDULED_PAYRUN_TYPE_ID },
  { name: "Ad-hoc", value: ADHOC_PAYRUN_TYPE_ID },
];

//PAYRUN_STATUS_ID
/** @knipignore */
export enum PAYRUN_STATUS_IDS {
  GENERATED = 1,
  DETAILS_VIEWED = 2,
  AMENDMENTS_ADDED = 3,
  AMENDEMENTS_SUBMITTED_SYSTEM_TRIGGERED = 4,
  AMENDEMENTS_SUBMITTED_USER_TRIGGERED = 5,
  CONFIRMED_BY_PAYROLL_ADMIN_SYSTEM_TRIGGERED = 6,
  CONFIRMED_BY_PAYROLL_ADMIN_USER_TRIGGERED = 7,
  PREPARED_SYSTEM_TRIGGERED = 8,
  PREPARED_USER_TRIGGERED = 9,
  APPROVED_SYSTEM_TRIGGERED = 10,
  APPROVED_USER_TRIGGERED = 11,
  INVOICE_GENERATED_SYSTEM_TRIGGERED = 12,
  INVOICE_GENERATED_USER_TRIGGERED = 13,
  PAYMENT_PENDING_APPROVAL = 14,
  PAYMENT_CONFIRMED = 15,
  PROCESSING = 16,
  PROCESSED = 17,
  COMPLETED = 18,
  IMPORTING_EARNING_LINES_SYSTEM_TRIGGERED = 19,
  IMPORTING_EARNING_LINES_USER_TRIGGERED = 20,
  IMPORTED_EARNING_LINES_SYSTEM_TRIGGERED = 21,
  IMPORTED_EARNING_LINES_USER_TRIGGERED = 22,
  DRAFT_SYSTEM_TRIGGERED = 23,
  DRAFT_USER_TRIGGERED = 24,
}

/** @knipignore */
export enum PROCESSING_FEES_IDS {
  PERCENTAGE_EMPLOYEE_SALARY = 1,
  FIXED_RATE_PER_EMPLOYEE = 2,
  FIXED_RATE_PER_PAYRUN = 3,
}

/** @knipignore */
export enum PROCESSING_FEES_NAME {
  PERCENTAGE_EMPLOYEE_SALARY = "Percentage of employee salary",
  FIXED_RATE_PER_EMPLOYEE = "Fixed rate per employee",
  FIXED_RATE_PER_PAYRUN = "Fixed rate per pay run",
}

export const PROCESSING_FEES_OPTIONS = [
  {
    name: PROCESSING_FEES_NAME.PERCENTAGE_EMPLOYEE_SALARY,
    id: PROCESSING_FEES_IDS.PERCENTAGE_EMPLOYEE_SALARY,
  },
  {
    name: PROCESSING_FEES_NAME.FIXED_RATE_PER_EMPLOYEE,
    id: PROCESSING_FEES_IDS.FIXED_RATE_PER_EMPLOYEE,
  },
  {
    name: PROCESSING_FEES_NAME.FIXED_RATE_PER_PAYRUN,
    id: PROCESSING_FEES_IDS.FIXED_RATE_PER_PAYRUN,
  },
];

export const DEFAULT_PAY_CYCLE_END_DATE = "1/1/0001 12:00:00 AM";
