import {
  AMENDMENT_APPROVED_STATUS,
  AMENDMENT_ARCHIVED_STATUS,
  AMENDMENT_COMPLETED_STATUS,
  AMENDMENT_CREATED_STATUS,
  AMENDMENT_DECLINED_STATUS,
  AMENDMENT_DRAFT_STATUS,
  AMENDMENT_MODIFIED_STATUS,
  AMENDMENT_RESUBMITTED_STATUS,
} from "../../../constants";
import { Badge } from "../../ui";

type Props = {
  statusID?: number;
};

const DEFAULT_STATUS = -1;

const StatusBadge = ({ statusID = DEFAULT_STATUS }: Props) => {
  if (AMENDMENT_APPROVED_STATUS.includes(statusID)) {
    return <Badge.Success>APPROVED</Badge.Success>;
  }
  switch (statusID) {
    case AMENDMENT_DECLINED_STATUS:
      return <Badge.Danger>REJECTED</Badge.Danger>;
    case AMENDMENT_RESUBMITTED_STATUS:
      return <Badge.Warning>RESUBMITTED</Badge.Warning>;
    case AMENDMENT_CREATED_STATUS:
      return <Badge.Info>CREATED</Badge.Info>;
    case AMENDMENT_DRAFT_STATUS:
      return <Badge.Neutral>DRAFT</Badge.Neutral>;
    case AMENDMENT_ARCHIVED_STATUS:
      return <Badge.Warning>ARCHIVED</Badge.Warning>;
    case AMENDMENT_COMPLETED_STATUS:
      return <Badge.Primary>COMPLETED</Badge.Primary>;
    case AMENDMENT_MODIFIED_STATUS:
      return <Badge.Info>UPDATED</Badge.Info>;
    default:
      return <Badge.Primary>SUBMITTED</Badge.Primary>;
  }
};

export default StatusBadge;
