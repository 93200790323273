import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonType } from '@xemplo/button';
import { DawnTriangleAlert } from '@xemplo/icons';
import { ModalBodyOverflow, ModalSize, ModalTheme } from '@xemplo/modal';
import { RejectEarningLinesPayload, useRejectEarningLines } from '@xemplo/payrun-query';
import { PromptProps, PromptType } from '@xemplo/prompts';
import { ToastType, useToast } from '@xemplo/toast';

import * as S from './payrun-approve-reject.styles';
import { RejectCategory, RejectDescription } from './reject-prompt-fields';

export const useRejectPrompt = () => {
  const [promptVisible, setPromptVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [shouldValidate, setShouldValidate] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [payload, setPayload] = useState<RejectEarningLinesPayload>({
    description: '',
    errorCategory: '',
    errorSource: '',
    rejectionReasonID: 0,
  });

  const { id } = useParams();
  const { addToast } = useToast();
  const { mutateAsync: rejectPayrun } = useRejectEarningLines({
    payrunId: id ?? null,
    onSuccess: () => {
      setPromptVisible(false);
      addToast({
        text: 'Pay run rejected',
        type: ToastType.Confirmation,
      });
    },
    onError: (error) => {
      const errorMessage = error.message ?? 'Something went wrong, please try again.';
      setErrorMessage(errorMessage);
    },
  });

  const handleReject = useCallback(async () => {
    setShouldValidate(true);
    const form = formRef?.current as HTMLFormElement;
    if (!form.checkValidity()) {
      return;
    }
    await rejectPayrun(payload);
    setPromptVisible(false);
  }, [payload, rejectPayrun]);

  const initialPromptProps = useMemo<PromptProps>(() => {
    return {
      open: false,
      type: PromptType.Alert,
      theme: ModalTheme.Light,
      size: ModalSize.Responsive,
      header: {
        customHeader: (
          <S.PromptHeader>
            <DawnTriangleAlert />
            <div>Reject pay run</div>
          </S.PromptHeader>
        ),
      },
      body: {
        content: (
          <S.PromptBody noValidate ref={formRef}>
            <div>
              When rejecting a pay run in payroll, it is important to provide a clear and
              concise explanation of the reason for the rejection. This will help to
              ensure that the issue is resolved as quickly as possible.
            </div>
            <RejectCategory shouldValidate={shouldValidate} setPayload={setPayload} />
            <RejectDescription shouldValidate={shouldValidate} setPayload={setPayload} />
          </S.PromptBody>
        ),
        overflow: ModalBodyOverflow.Extended,
      },
      footer: {
        btnPrimary: {
          label: 'Reject',
          disabled: !(payload.description && payload.errorCategory),
          ariaLabel: 'Reject Button',
          onClickAsync: handleReject,
          type: ButtonType.Destructive,
        },
        btnSecondary: {
          label: 'Cancel',
          ariaLabel: 'Cancel Btn',
          type: ButtonType.Secondary,
          onClick: () => setPromptVisible(false),
        },
      },
    };
  }, [handleReject, payload.description, payload.errorCategory, shouldValidate]);

  const [promptProps, setPromptProps] = useState<PromptProps>(initialPromptProps);

  useEffect(() => {
    if (errorMessage) {
      setPromptProps({
        ...initialPromptProps,
        body: {
          content: (
            <>
              <S.PromptBody noValidate>
                <div>
                  When rejecting a pay run in payroll, it is important to provide a clear
                  and concise explanation of the reason for the rejection. This will help
                  to ensure that the issue is resolved as quickly as possible.
                </div>
                <RejectCategory shouldValidate={shouldValidate} setPayload={setPayload} />
                <RejectDescription
                  shouldValidate={shouldValidate}
                  setPayload={setPayload}
                />
              </S.PromptBody>
              <S.ErrorMessage>
                <DawnTriangleAlert />
                {errorMessage}
              </S.ErrorMessage>
            </>
          ),
          overflow: ModalBodyOverflow.Extended,
        },
      });
    } else {
      setPromptProps(initialPromptProps);
    }
  }, [errorMessage, initialPromptProps, shouldValidate]);

  useEffect(() => {
    if (initialPromptProps.open) {
      setPromptVisible(true);
    }
  }, [initialPromptProps.open]);

  return { promptProps, promptVisible, setPromptVisible };
};
