// @ts-nocheck
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import React from "react";
import SimpleButton from "../Common/ClaimComponents/Button/SimpleButton";
import { Badge, message } from "antd";
import { PayrunStatus } from "../Common";
import { getDifferenceInDays } from "./timeHandlers";
import moment from "moment";
import {
  ALLOWANCE_CHANGE_TYPE_ID,
  DEDUCTION_CHANGE_TYPE_ID,
  SALARY_CHANGE_TYPE_ID,
  SCHEDULED_PAYRUN_ID,
  OUTSOURCE_MODEL_ID,
  TERMINATION_CHANGE_TYPE_ID,
  COMPLETED_PAYRUN_STATUS,
  DOWNLOAD_PAYRUN_PDF_BUTTON,
  PAYRUN_STATUS_IDS,
  ApiErrors,
} from "../constants";
import { mustBeArray, isComponentVisible } from "./utilities";
import { AmendmentType, Payrun } from "../types";
import { amendmentsAreIntegratedToPayroll } from "./amendment";
import { CUSTOM_ERRORS_CODE } from "../constants/errors";

export const getPayrunColumnTitle = ({
  payrun,
  showRejectionReason,
  title = "Pay Run",
}: {
  payrun: any;
  showRejectionReason: (e: any) => void;
  title?: string;
}) => {
  return (
    <span className="font-semibold text-sm ">
      {title}{" "}
      {payrun?.isRejected && (
        <SimpleButton
          className="bg-danger ml-2"
          onClick={showRejectionReason}
          buttonText="Rejected"
          ignoreStatus={true}
          size="small"
          secondaryIcon={<InfoCircleFilled />}
          tooltipText="This has not been approved."
        />
      )}
    </span>
  );
};

export const getVarianceClassName = ({
  data,
  highLightVarianceValue,
}: {
  data: any;
  highLightVarianceValue: string | number;
}) => {
  if (Math.abs(data) >= Number(highLightVarianceValue)) {
    return Number(data) > 0 ? "color-success" : "color-danger";
  }
  return "";
};

export const getVarianceRowClassName = ({
  data,
  highLightVarianceValue,
  showVariance,
}: {
  data: any;
  highLightVarianceValue: number;
  showVariance?: boolean;
}) => {
  if (!showVariance) return "";
  if (Math.abs(data) >= Number(highLightVarianceValue)) {
    return Number(data) > 0 ? "bg-table-success" : "bg-table-warning";
  }
  return "";
};

export const getVarianceDisplayValue = ({ data }: { data: any }) => {
  if (data === "NaN" || _.isUndefined(data)) return "N/A";
  return data < 0 ? `(${Math.abs(data)})` : data;
};

export const getPayrunEarninglinesColumnClassName = ({
  showVariance,
  invoice,
}: any) => {
  if (showVariance) {
    return _.isEmpty(invoice)
      ? "process-table-variance-no-invoice"
      : "process-table-variance-invoice";
  }
  return _.isEmpty(invoice)
    ? "process-table-no-variance-no-invoice"
    : "process-table-no-variance-invoice";
};

export const showVariance = ({
  payrun,
  payrunSelected,
}: {
  payrun: any;
  payrunSelected: boolean;
}) => {
  return (
    ![1, 2, 3].includes(payrun?.payrunStatusID) &&
    payrunSelected &&
    isScheduledPayrun({ payrun })
  );
};

export const showExternalEarninglines = ({ payrun }: any) => {
  return [1, 2, 3].includes(payrun?.payrunStatusID);
};

export const getPayrunStages = ({ payrun = {} }: { payrun: any }) => {
  switch (
    PayrunStatus.getStatus({
      id: payrun?.payrunStatusID,
    })
  ) {
    case "draft":
      return (
        <Badge className="rounded-[20px] py-[3px]  px-2.5 text-[11px]">
          DRAFT
        </Badge>
      );
    case "amendments":
      return (
        <Badge className="rounded-[20px] py-[3px]  px-2.5 bg-warning text-[11px]">
          AMENDMENTS DUE
        </Badge>
      );
    case "prepare":
      return (
        <Badge className="rounded-[20px] py-[3px]  px-2.5 bg-primary text-[11px]">
          PREPARING
        </Badge>
      );
    case "approval":
      return (
        <Badge className="rounded-[20px] py-[3px]  px-2.5 bg-warning text-[11px]">
          APPROVAL DUE
        </Badge>
      );
    case "payment":
    case "invoiceUnpaid":
    case "invoicePaid":
    case "process":
      return (
        <Badge className="bg-primary rounded-[20px] py-[3px] px-2.5 text-[11px]">
          PROCESSING
        </Badge>
      );
    case "completed":
      return (
        <Badge className="bg-success px-2.5 rounded-[20px] py-[3px] text-[11px]">
          COMPLETED
        </Badge>
      );
    default:
      return <React.Fragment> </React.Fragment>;
  }
};

export const getPayrunDueDays = ({ payrun = {} }: { payrun: any }) => {
  switch (
    PayrunStatus.getStatus({
      id: payrun?.payrunStatusID,
    })
  ) {
    case "amendments":
      return {
        days: getDifferenceInDays({
          value: payrun.ammendmentDueDateTime,
          format: "MM/DD/YYYY hh:mm:ss A",
        }),
        date: moment(
          payrun.ammendmentDueDateTime,
          "MM/DD/YYYY hh:mm:ss A"
        ).format("DD MMMM YYYY hh:mm a"),
      };
    case "prepare":
      return {
        days: getDifferenceInDays({
          value: payrun.preparationDueDateTime,
          format: "MM/DD/YYYY hh:mm:ss A",
        }),
        date: moment(
          payrun.preparationDueDateTime,
          "MM/DD/YYYY hh:mm:ss A"
        ).format("DD MMMM YYYY hh:mm a"),
      };
    case "approval":
      return {
        days: getDifferenceInDays({
          value: payrun.approvalDueDateTime,
          format: "MM/DD/YYYY hh:mm:ss A",
        }),
        date: moment(
          payrun.approvalDueDateTime,
          "MM/DD/YYYY hh:mm:ss A"
        ).format("DD MMMM YYYY hh:mm a"),
      };
    case "payment":
    case "invoiceUnpaid":
    case "invoicePaid":
    case "process":
      return {
        days: getDifferenceInDays({
          value: payrun?.payDate,
          format: "MM/DD/YYYY hh:mm:ss A",
        }),
        date: moment(payrun.payDate, "MM/DD/YYYY hh:mm:ss A").format(
          "DD MMMM YYYY hh:mm a"
        ),
      };
    case "completed":
      return {
        days: undefined,
        date: undefined,
      };
    default:
      return <React.Fragment> </React.Fragment>;
  }
};

export const changeTypeHasSubTypes = ({ type }: any) => {
  return [
    ALLOWANCE_CHANGE_TYPE_ID,
    DEDUCTION_CHANGE_TYPE_ID,
    SALARY_CHANGE_TYPE_ID,
    TERMINATION_CHANGE_TYPE_ID,
  ].includes(type);
};

export const isScheduledPayrun = ({ payrun }: any) => {
  return payrun?.payrunTypeID === SCHEDULED_PAYRUN_ID;
};

export const amendmentHasQuestionSection = ({ id }: { id: number }) => {
  return [4, 7, 9].includes(id);
};

export const generalAmendmentsFormFieldsAreValid = ({
  values,
  changeTaskList,
  displayAdditionalReoccurringFields,
}: any) => {
  const selectedAmendmentType = mustBeArray(changeTaskList).find(
    (o) => o.changeTaskValueId === values?.amendmentTaskTypeID
  );
  if (values?.units <= 0) {
    message.error("Please enter valid units.");
    return false;
  }
  if (
    displayAdditionalReoccurringFields &&
    values?.durationType === 1 &&
    !values?.recurringMaximumAmount
  ) {
    message.error("Please enter maximum amount.");
    return false;
  }
  if (
    values?.recurringMaximumAmount &&
    values?.rate &&
    values.rate > values.recurringMaximumAmount
  ) {
    message.error("Please enter valid rate.");
    return false;
  }
  return true;
};

const payrunAutoCreatedInKeypay = ({
  externalPayruns,
  id,
}: {
  externalPayruns: any;
  id?: string;
}) => {
  return mustBeArray(externalPayruns).some((o) => o.id === Number(id));
};

export const canAutoImportEarninglines = ({
  externalPayruns = [],
  id = null,
}: any) => {
  return !!payrunAutoCreatedInKeypay({ externalPayruns, id });
};

export const amendmentsWillBeAppliedToPayroll = ({
  amendments,
  payrun,
}: {
  amendments: AmendmentType[];
  payrun: Payrun;
}) => {
  return !!(
    amendmentsAreIntegratedToPayroll(amendments) && payrun?.externalPayRunId
  );
};

export const isPayrunCompleted = (id: number) => {
  return id === COMPLETED_PAYRUN_STATUS;
};

// Validation to auto generate ABA file
export const autoGenerateAbaFile = ({ payrun, payrunReports }: any) => {
  const abaFile = mustBeArray(payrunReports).some(
    (o) => o.displayName === "ABA File"
  );
  if (abaFile) return false; // ABA File already exists
  return !!(
    Number(payrun?.payrollModelId === OUTSOURCE_MODEL_ID) && // Only outsource payruns can generate ABA files
    payrun?.payrunStatusID === PAYRUN_STATUS_IDS.COMPLETED
  ); // Payrun must completed
};

// User can download pay run pdfs
export const userCanDownloadPayrunPdf = ({ claims }: any) => {
  const claim = mustBeArray(claims?.components).find(
    (o) => o.name === DOWNLOAD_PAYRUN_PDF_BUTTON
  );
  return isComponentVisible({ claim });
};

// Pay run has been skipped

export const payrunIsSkipped = (errors: ApiErrors[]) => {
  return errors?.some((o) => o.code === CUSTOM_ERRORS_CODE.SKIPPED_PAY_RUN);
};
