//@ts-nocheck
import React, { Component } from "react";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Avatar, Popconfirm, Tooltip } from "antd";
import {
  mustBeArray,
  currencyFormater,
  errorDisplay,
  getConditionalResponse,
  conditionalParameter,
  getStateRateAndCategoriesText,
  getPayItemsText,
} from "../../../libs/utilities";
import ProcessingFeesView from "./view";
import _ from "lodash";
import ClaimWrapper from "../../../Common/claimWrapper";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";

type State = any;

class ProcessingFees extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showProcessingFeeDetails: false,
      feeDetails: {},
    };
  }
  closeProcessingFeeModal = () => {
    this.setState({ feeDetails: {}, showProcessingFeeDetails: false });
  };

  showProcessingFeeDetails = (record) => {
    if (this.props.viewPayrun) {
      this.setState({ feeDetails: record }, () => {
        this.setState({ showProcessingFeeDetails: true });
      });
    } else {
      this.props
        .getProcessingFee({
          id: record.payScheduleId,
          processingFeeId: record.id,
        })
        .then((resp) => {
          if (resp.status) {
            this.setState({ feeDetails: resp.data }, () => {
              this.setState({ showProcessingFeeDetails: true });
            });
          } else {
            errorDisplay(resp && resp.data && resp.data.validationErrors);
          }
        });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    let options = {
      page: pagination.current,
    };

    this.props.handleTableChange({ options: { ...options } });
  };

  displayCategories = (value) => {
    let filteredCategories = _.filter(
      mustBeArray(value),
      (o) => o.checked === true
    );
    if (_.isEmpty(filteredCategories)) return "No";
    let displayCategories = _.take(mustBeArray(filteredCategories), 3).reduce(
      (a, b) => {
        return `${a}${a.length === 0 ? "" : ", "}${b.name}`;
      },
      ""
    );
    displayCategories =
      mustBeArray(filteredCategories).length <= 3
        ? displayCategories
        : `${displayCategories} ...`;
    return displayCategories;
  };

  getRatePercentage = (data, record) => {
    if (Number(record && record.feeOptionId) === 3) {
      return currencyFormater(
        data,

        conditionalParameter({
          data: this.props.businessunitDetails,
          field: "country",
        })
      );
    } else {
      switch (Number(record.feeOptionId)) {
        case 1:
          return !_.isEmpty(
            record &&
              record[
                getConditionalResponse({
                  condition: this.props.viewPayrun,
                  resp1: "processingFeeStateRate",
                  resp2: "stateRateDto",
                })
              ]
          )
            ? "State/s"
            : `${data}%`;
        case 2:
          return !_.isEmpty(
            record &&
              record[
                getConditionalResponse({
                  condition: this.props.viewPayrun,
                  resp1: "processingFeeStateRate",
                  resp2: "stateRateDto",
                })
              ]
          )
            ? "State/s"
            : currencyFormater(
                data,
                conditionalParameter({
                  data: this.props.businessunitDetails,
                  field: "country",
                })
              );
        default:
          return "";
      }
    }
  };

  getDefaultColTemplate = () => {
    return [
      {
        title: "Invoice Description",
        dataIndex: "invoiceDescription",
        key: "1",
        width: "8%",
      },
      { title: "Fee Option", dataIndex: "feeOption", key: "2", width: "8%" },
      {
        title: "Rate/Percentage",
        dataIndex: "rateOrPercentage",
        key: "3",
        width: "8%",
        render: (rowKey, value) => this.getRatePercentage(rowKey, value),
      },
      {
        title: "State Percentage",

        dataIndex: getConditionalResponse({
          condition: this.props.viewPayrun,
          resp1: "processingFeeStateRate",
          resp2: "stateRateDto",
        }),
        key: "4",
        width: "8%",
        render: (rowKey, value) =>
          getConditionalResponse({
            condition: Number(value.feeOptionId) === 3,
            resp1: "N/A",
            resp2: getStateRateAndCategoriesText(rowKey),
          }),
      },
      {
        title: "Base Pay",
        dataIndex: "basePay",
        key: "5",
        width: "6%",
        render: (rowKey, value) =>
          getConditionalResponse({
            condition: Number(value.feeOptionId) === 3,
            resp1: "N/A",
            resp2: rowKey === true ? "Yes" : "No",
          }),
      },
      {
        title: "Pay Items",
        dataIndex: getConditionalResponse({
          condition: this.props.viewPayrun,
          resp1: "processingFeePayItem",
          resp2: "payItemDto",
        }),
        key: "6",
        width: "7%",
        render: (rowKey, value) =>
          getConditionalResponse({
            condition: Number(value.feeOptionId) === 3,
            resp1: "N/A",
            resp2: getPayItemsText({ data: rowKey }),
          }),
      },
      {
        title: "Pay Categories",

        dataIndex: getConditionalResponse({
          condition: this.props.viewPayrun,
          resp1: "processingFeeExternalPayCategory",
          resp2: "externalPayCategoryDto",
        }),
        key: "7",
        width: "13%",
        render: (rowKey, value) =>
          getConditionalResponse({
            condition: Number(value.feeOptionId) === 3,

            resp1: "N/A",
            resp2: this.displayCategories(rowKey),
          }),
      },
      {
        title: "Expense Categories",

        dataIndex: getConditionalResponse({
          condition: this.props.viewPayrun,
          resp1: "processingFeeExternalExpenseCategory",
          resp2: "externalExpenseCategoryDto",
        }),
        key: "8",
        width: "15%",
        render: (rowKey, value) =>
          getConditionalResponse({
            condition: Number(value.feeOptionId) === 3,

            resp1: "N/A",
            resp2: this.displayCategories(rowKey),
          }),
      },
      {
        title: "Employee Rate",

        dataIndex: getConditionalResponse({
          condition: this.props.viewPayrun,
          resp1: "processingFeeEmployeeNumberRange",
          resp2: "employeeNumberRangeDto",
        }),
        key: "9",
        width: "7%",
        render: (rowKey, value) =>
          getConditionalResponse({
            condition: Number(value.feeOptionId) === 3,
            resp1: "N/A",
            resp2: getStateRateAndCategoriesText(rowKey),
          }),
      },
      {
        title: "Tax",
        dataIndex: "taxFeeName",
        key: "10",
        width: "5%",
        render: (text) => <div className="word-break min-w-[30px]">{text}</div>,
      },
    ];
  };

  render() {
    const {
      data,
      remove,
      action,
      current,
      basePayMapping,
      claims,
      total,
      per_page,

      isPayScheduleEditable,
    } = this.props;

    const colTemplate = this.getDefaultColTemplate();
    if (action) {
      colTemplate.push({
        title: "Action",
        dataIndex: "",
        key: "11",
        width: "7%",
        render: (rowKey) => (
          <React.Fragment>
            <ClaimWrapper
              claims={claims}
              name="payScheduleProcessingFeesDelete"
            >
              <Popconfirm
                placement="topLeft"
                key="decline"
                title={"Are you sure you want to delete this processing fee?"}
                onConfirm={() => remove(rowKey)}
                loading={this.state.declineLoading}
              >
                <Tooltip title="Delete" placement="bottom">
                  <Avatar size="small">
                    <CloseOutlined className="hand color-neutral " />{" "}
                  </Avatar>
                </Tooltip>
              </Popconfirm>
            </ClaimWrapper>
          </React.Fragment>
        ),
      });
    }

    if (this.props.showFeesDetails) {
      colTemplate.push({
        title: "Action",
        dataIndex: "",
        width: "7%",
        key: "12",
        render: (rowKey) =>
          rowKey && Number(rowKey.feeOptionId) !== 3 ? (
            <React.Fragment>
              {!this.props.viewPayrun && isPayScheduleEditable && (
                <Popconfirm
                  placement="topLeft"
                  key="decline"
                  title={"Are you sure you want to delete this processing fee?"}
                  onConfirm={() => this.props.removeProcessingFee(rowKey)}
                  loading={this.state.declineLoading}
                >
                  <Tooltip title="Delete" placement="bottom">
                    <Avatar
                      size="small"
                      className={`hand color-neutral ml-3.5`}
                      icon={<CloseOutlined className="rounded-[20px]" />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}

              <Tooltip title="View" placement="bottom">
                <Avatar
                  size="small"
                  className={`hand bg-primary ml-3.5`}
                  icon={<EyeOutlined />}
                  onClick={() => this.showProcessingFeeDetails(rowKey)}
                />
              </Tooltip>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {!this.props.viewPayrun && isPayScheduleEditable && (
                <Popconfirm
                  placement="topLeft"
                  key="decline"
                  title={"Are you sure you want to delete this processing fee?"}
                  onConfirm={() => this.props.removeProcessingFee(rowKey)}
                  loading={this.state.declineLoading}
                >
                  <Tooltip title="Delete" placement="bottom">
                    <Avatar
                      size="small"
                      className={`hand ml-3.5 color-neutral`}
                      icon={<CloseOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </React.Fragment>
          ),
      });
    }
    return (
      <React.Fragment>
        <ProcessingFeesView
          visible={this.state.showProcessingFeeDetails}
          onCancel={this.closeProcessingFeeModal}
          feeDetails={this.state.feeDetails}
          businessunitDetails={this.props.businessunitDetails}
          viewPayrun={this.props.viewPayrun}
          countryCode={this.props.countryCode}
          basePayMapping={basePayMapping}
        />
        <SimpleTableContainer
          columns={colTemplate}
          rowKey={(record, index) => index}
          dataSource={data}
          bordered={true}
          pagination={{
            total,
            pageSize: per_page ? per_page : 10,
            current: current,
          }}
          onChange={this.handleTableChange}
          loading={this.props.loading}
        />
      </React.Fragment>
    );
  }
}

export default ProcessingFees;
