import { ModalAction, ModalActions, ModalProps, ModalState } from './modal.types';

export const defaultState: ModalState = {
  open: false,
  scrollAnimation: true,
  asModal: false,
};

export const reducer = (state: ModalState, { type, payload }: ModalAction) => {
  switch (type) {
    case ModalActions.SetModalConfig:
      return {
        ...state,
        ...payload,
      };
    case ModalActions.SetModalOpen:
      state.open = payload;
      return { ...state, open: payload };

    default:
      return state;
  }
};

export const setModalConfig = (payload: ModalProps): ModalAction => ({
  type: ModalActions.SetModalConfig,
  payload,
});

export const setModalOpen = (payload: boolean): ModalAction => ({
  type: ModalActions.SetModalOpen,
  payload,
});
