//@ts-nocheck
import React from "react";
import { Card, Tooltip, Typography, Avatar, Badge } from "antd";
import { ContractIcon } from "../../../Common/customicons";
import TableActionComponent from "../common/tableActions";

const { Title } = Typography;

const EmailDisplayCard = ({
  handleAction,
  content,
  claims,
  editComponent,
  displayActionIcons,
}: any) => {
  return (
    <Card
      className="card hand card-prop h-full pb-0"
      bordered={false}
      onClick={(e) => handleAction({ data: content, action: "view" })}
    >
      <div className="position-relative py-3 px-0">
        <div className={"mb-2.5 px-[50px] position-relative"}>
          <div className="position-relative">
            <Tooltip placement="top" title={content && content.templateName}>
              <Title ellipsis className="card-title" level={3}>{`${
                content && content.templateName
              }`}</Title>
            </Tooltip>
          </div>
          <Avatar
            className="position-absolute-top position-absolute-top-left"
            size={36}
          >
            <ContractIcon className="text-lg" />
          </Avatar>
        </div>
        <div className="flex justify-between mb-1 text-xs mt-8">
          <span>{`Description: ${content.description}`}</span>
        </div>
        <div>
          {content && !content.isInActive ? (
            <Badge count={"Active"} className={"text-xs badge badge-success"} />
          ) : (
            <Badge
              count={"InActive"}
              className={"text-xs badge badge-danger"}
            />
          )}
        </div>
      </div>
      <TableActionComponent
        classname="card-item mt-4 mr-4"
        buttonClassName="card-prop animated fadeIn position-absolute-top position-absolute-top-right"
        claims={claims}
        handleAction={(options: any) =>
          handleAction({ ...options, data: content, record: content })
        }
        editComponentName={editComponent}
        displayActionIcons={displayActionIcons}
      />
    </Card>
  );
};

export default EmailDisplayCard;
