import { useFormContext } from 'react-hook-form';
import Papa from 'papaparse';

import { ButtonType } from '@xemplo/button';
import { DawnDownload16 } from '@xemplo/icons';
import {
  useGetBusinessUnitWorkersQuery,
  useGetTerminatedBusinessUnitWorkersQuery,
} from '@xemplo/lookups-query';
import { useGetPayrunByIdQuery } from '@xemplo/payrun-query';

import { PLACEHOLDER_OPTION } from '../../../../create-amendment.helper';
import { FUTURE_SCHEDULED_PAYRUN } from '../../page-1/select-payrun';

import * as S from './bulk-upload.styles';

export const DownloadTemplateButton = () => {
  const { watch } = useFormContext();

  const watchAppliesTo = watch('appliesTo');
  const watchTerminateWorkers = watch('terminatedWorkers');
  const watchBusinessUnit = watch('businessUnit');
  const watchPayrun = watch('payrun');
  const payrunId =
    watchPayrun === FUTURE_SCHEDULED_PAYRUN || watchPayrun === PLACEHOLDER_OPTION
      ? null
      : watchPayrun;
  const { data: payrunData } = useGetPayrunByIdQuery({
    id: payrunId,
  });
  const watchBUValue =
    watchBusinessUnit === PLACEHOLDER_OPTION ? null : watchBusinessUnit;

  const { data: workerData, isLoading } = useGetBusinessUnitWorkersQuery({
    id: payrunData?.result?.businessUnitID ?? watchBUValue ?? null,
    requestParams: { per_page: 0 },
  });

  const { data: terminatedWorkerData, isLoading: terminatedWorkersLoading } =
    useGetTerminatedBusinessUnitWorkersQuery({
      id: watchBUValue ?? null,
      requestParams: { per_page: 0 },
    });

  const generateCsvData = () => {
    const selectedEmployees = workerData?.result.filter((employee) =>
      watchAppliesTo?.includes(employee.id.toString())
    );
    const selectedTerminatedEmployees = terminatedWorkerData?.result?.rows.filter(
      (employee) => watchTerminateWorkers?.includes(employee.employeeId)
    );

    const data = selectedEmployees?.map((employee) => {
      return {
        ID: employee.id,
        Name: `${employee.firstName} ${employee.lastName}`,
        Rate: '',
        Unit: '',
      };
    });

    selectedTerminatedEmployees?.forEach((employee) => {
      data?.push({
        ID: +employee.employeeId,
        Name: `${employee.firstName} ${employee.lastName}`,
        Rate: '',
        Unit: '',
      });
    });
    return data ?? [];
  };

  const handleTemplateDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const data = generateCsvData();
    //Generate csv file for download
    const fields = ['ID', 'Name', 'Rate', 'Unit'];
    const csv = Papa.unparse({
      data,
      fields,
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'template.csv');
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <S.DownloadTemplateButton
      onClick={handleTemplateDownload}
      ariaLabel="Download template"
      icon={<DawnDownload16 />}
      type={ButtonType.Primary}
      disabled={isLoading || terminatedWorkersLoading}
    >
      Download Template
    </S.DownloadTemplateButton>
  );
};
