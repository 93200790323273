//@ts-nocheck
import React from 'react';
import { LaptopOutlined, RobotOutlined, UserDeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { VISIBLE_CLAIMS_ID } from '../../../constants/defaultClaims';
 import { conditionalParameter } from '../../../libs/utilities';

const UserAuthenticationUpdate = (props: any) => {
    // const [menuVisible, setMenuVisible] = useState(false);
    const { disableMfaClaims, enableMfaClaims, mandatoryDeviceEnrolmentClaims,
        disableDeviceEnrolmentClaims, optionalDeviceEnrolmentClaims,
        handleMFA, handleDeviceEnrolment, showEnableMfaOption, showDisableMfaOption,
        showMandatoryDeviceEnrolmentOption, showOptionalDeviceEnrolmentOption,
        showDisableDeviceEnrolmentOption } = props;
    const handleMenuClick = (value: any) => {
        switch (value && value.key) {
            case "enableMFA":
                return handleMFA({ action: "enableAll" });
            case "disableMFA":
                return handleMFA({ action: "disableAll" });
            case "mandatoryDeviceEnrolment":
                return handleDeviceEnrolment({ action: "mandatoryAllDeviceEnrolment" });
            case "optionalDeviceEnrolment":
                return handleDeviceEnrolment({ action: "optionalAllDeviceEnrolment" });
            case "disableDeviceEnrolment":
                return handleDeviceEnrolment({ action: "disableAllDeviceEnrolment" });
            default:
                break;
        }
    }
    const menu = (

        <Menu onClick={handleMenuClick} className="user-authentication">
            {
                ((enableMfaClaims && VISIBLE_CLAIMS_ID.includes(Number(conditionalParameter({data: enableMfaClaims, field: "authorizationStatusId"}))))
                    || showEnableMfaOption) &&

                <Menu.Item key="enableMFA">
                        <UserOutlined />  Enable MFA
            </Menu.Item>
            }
            {
                ((disableMfaClaims && VISIBLE_CLAIMS_ID.includes(Number(conditionalParameter({data: disableMfaClaims, field: "authorizationStatusId"}))))
                    || showDisableMfaOption) &&

                <Menu.Item key="disableMFA">
                        <UserDeleteOutlined /> Disable MFA
            </Menu.Item>
            }
                <Menu.Divider />
            {
                ((mandatoryDeviceEnrolmentClaims && VISIBLE_CLAIMS_ID.includes(Number(conditionalParameter({data: mandatoryDeviceEnrolmentClaims, field: "authorizationStatusId"}))))
                    || showMandatoryDeviceEnrolmentOption) &&

                <Menu.Item key="mandatoryDeviceEnrolment">
                        <LaptopOutlined /> Mandatory Device Enrolment
            </Menu.Item>
            }
            {
                ((optionalDeviceEnrolmentClaims && VISIBLE_CLAIMS_ID.includes(Number(conditionalParameter({data: optionalDeviceEnrolmentClaims, field: "authorizationStatusId"}))))
                    || showOptionalDeviceEnrolmentOption) &&

                <Menu.Item key="optionalDeviceEnrolment">
                        <LaptopOutlined /> Optional Device Enrolment
            </Menu.Item>
            }
            {
                ((disableDeviceEnrolmentClaims && VISIBLE_CLAIMS_ID.includes(Number(conditionalParameter({data: disableDeviceEnrolmentClaims, field: "authorizationStatusId"}))))
                    || showDisableDeviceEnrolmentOption) &&

                <Menu.Item key="disableDeviceEnrolment">
                        <RobotOutlined /> Disable Device Enrolment
            </Menu.Item>
            }
        </Menu>
    );
    return (

        <Dropdown.Button
            trigger={['click']}
            type="primary"
            overlay={menu}>
            Enable/Disable Authentication for All Users
        </Dropdown.Button>
    )
}

export default UserAuthenticationUpdate;
