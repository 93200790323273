import styled from 'styled-components';

import { BodyLargeRegular, BodyLargeSemiBold, Colour } from '@xemplo/style-constants';

export const Container = styled.div`
  background: ${Colour.White[100]};
  position: relative;
  border-radius: 8px;
  border-bottom: 1px solid ${Colour.Black[10]};
  height: fit-content;
  &.show-overflow-shadow {
    &::after {
      content: '';
      pointer-events: none;
      height: 32px;
      width: 100%;
      position: absolute;
      border-radius: 8px;
      transition: 0.1s;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        ${Colour.White[100]}
      );
    }
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 24px 32px 12px 32px;
  align-items: center;
  gap: 8px;
  position: sticky;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  top: 0;
  background: ${Colour.White[100]};
  &.show-keyline {
    border-bottom: 1px solid ${Colour.Black[10]};
  }
`;

export const Title = styled.span`
  color: ${Colour.Gray[800]};
  ${BodyLargeSemiBold};
  display: flex;
  gap: 4px;
  flex-grow: 1;
`;
export const Count = styled.div`
  ${BodyLargeRegular};
  color: ${Colour.Gray[300]};
`;

export const Body = styled.div`
  overflow: auto;
  padding: 0 32px 16px 32px;
`;
