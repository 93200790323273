import React, { useMemo } from "react";
import { CameraOutlined } from "@ant-design/icons";
import { Avatar, AvatarSize } from "@xemplo/avatar";
import { useMemberApi } from "@xemplo/gp-api";
import { memberActions } from "@xemplo/gp-member-store";
import { useHttpClient } from "@xemplo/http";
import { Card, message, Upload } from "antd";
import { VISIBLE_CLAIMS_ID } from "../../constants";
import environment from "../../constants/environment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { errorDisplay, findAuthorizationClaims } from "../../libs";
import { RootState } from "../../store";
import { useState } from "react";
import { CenteredLoaderDots } from "@xemplo/loader";

const UploadPicture = ({
  setIsUploading,
}: {
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { details } = useAppSelector((state: RootState) => state.member);
  const { client, cancelSignal } = useHttpClient({ timeout: 10000 });
  const cancelToken = cancelSignal.token;
  const { getProfile } = useMemberApi();
  const dispatch = useAppDispatch();

  const authDom = details?.authorizationDOM;
  const claims = findAuthorizationClaims({
    claims: authDom,
    name: "userProfileImageUploadButton",
  });

  const isUploadEnabled = VISIBLE_CLAIMS_ID.includes(
    Number(claims?.authorizationStatusId)
  );

  /**
   * This is a refactor of the original implementation of the uploadLogo function
   * It hijacks the built-in upload functionality of the antd Upload component
   * Not sure why we need to do this, need to investigate further
   */
  const handleUpload = async (file: any) => {
    const imageType = ["image/jpg", "image/jpeg", "image/png"];
    if (!imageType.includes(file?.type)) {
      message.error("Please upload images only.");
      return false;
    }
    setIsUploading(true);
    const url = `${environment.identity_baseurl}/api/v1/users/me/profile-picture`;
    const formData = new FormData();
    formData.append("file", file);
    const response = await client
      .post(url, formData, { cancelToken })
      .catch((error) => {
        errorDisplay(error?.response?.data, false, true);
        setIsUploading(false);
      });

    if (response?.status === 200) {
      const profile = await getProfile().catch((error) =>
        message.error(error.message)
      );
      dispatch(memberActions.setDetails(profile));
    }
    setIsUploading(false);
    return false;
  };

  return isUploadEnabled ? (
    <Upload beforeUpload={handleUpload} showUploadList={false}>
      <CameraOutlined className="text-lg upload-icon" />
    </Upload>
  ) : null;
};

const AvatarWrapper = () => {
  const { details } = useAppSelector((state: RootState) => state.member);
  const [isUploading, setIsUploading] = useState(false);
  const profilePhoto = useMemo(() => {
    if (isUploading || !details?.profilePhoto) return null;
    return `${details?.profilePhoto}?timestamp=${new Date().getTime()}`;
  }, [isUploading, details?.profilePhoto]);
  return details ? (
    <div className="user-info mr-6 relative">
      {isUploading ? (
        <CenteredLoaderDots />
      ) : (
        <Avatar user={{ ...details, profilePhoto }} size={AvatarSize.xxl} />
      )}
      <UploadPicture setIsUploading={setIsUploading} />
    </div>
  ) : null;
};

const UserInfo = () => {
  const details = useAppSelector((state: RootState) => state.member.details);
  return (
    <Card bordered={false} title="Personal Details">
      <div className="flex flex-start">
        <div className="flex flex-start">
          <AvatarWrapper />
          <div className="mr-12">
            <label className="ant-card-body-label">First Name</label>
            <span className="ant-card-body-value">{details?.firstName}</span>
            <label className="ant-card-body-label">Mobile</label>
            <span className="ant-card-body-value">{details?.mobile}</span>
          </div>
          <div>
            <label className="ant-card-body-label">Last Name</label>
            <span className="ant-card-body-value">{details?.lastName}</span>
            <label className="ant-card-body-label">Email</label>
            <span className="ant-card-body-value">{details?.username}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UserInfo;
