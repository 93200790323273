// @ts-nocheck
import {
  conditionalParameter,
  getConditionalResponse,
  requestBuilder,
} from "../../libs/utilities";
import { payscheduleAction } from "../../constants/action-type/index";
import { http, ResponseHandler } from "../../libs";
import APIHandler from "../../constants/apiUrl";

const {
  LIST_BUSINESS_PAYSCHEDULE,
  LIST_BUSINESS_PAYSCHEDULE_ACTIVE,
  LIST_BUSINESS_PAYSCHEDULE_DEACTIVE,
  LIST_PAYSCHEDULE_DETAILS,
} = payscheduleAction;

export function getPayScheduleFees({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
        per_page: 10,
      };
      const url = APIHandler.constructEndpoint({
        endpoint: "PROCESSINGFEES",
        options: { id },
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, "PAYSCHEDULE_FEE")
        );
      } else {
        return resolve({
          ...ResponseHandler.inValidObject(response),
          type: "PAYSCHEDULE_FEE",
        });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

type getPayScheduleArgs = {
  id: string;
  options?: object;
  cancelToken: any;
  targetEndpoint?: string;
};

export function getPayschedule({
  id,
  options = {},
  cancelToken,
  targetEndpoint,
}: getPayScheduleArgs) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      const url = getConditionalResponse({
        condition: targetEndpoint,
        resp1: targetEndpoint,
        resp2: APIHandler.constructEndpoint({ endpoint: "PAYSCHEDULE" }),
      });
      const response = await requestBuilder(
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: null,
          resp2: id,
        }),
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: targetEndpoint,
          resp2: url,
        }),
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: {},
          resp2: queryOptions,
        }),
        cancelToken
      );
      let actionType = "";
      if (id) {
        actionType = LIST_PAYSCHEDULE_DETAILS;
      } else {
        if (conditionalParameter({ data: options, field: "type" })) {
          actionType = getConditionalResponse({
            condition: options.type === "active",
            resp1: LIST_BUSINESS_PAYSCHEDULE_ACTIVE,
            resp2: LIST_BUSINESS_PAYSCHEDULE_DEACTIVE,
          });
        } else {
          dispatch({ type: "LIST_BUSINESS_PAYSCHEDULE_CLEAR" });
          actionType = LIST_BUSINESS_PAYSCHEDULE;
        }
      }
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, actionType)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: actionType,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function savePaySchedule(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "PAYSCHEDULE" });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYSCHEDULE_SAVE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updatePaySchedule(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "PAYSCHEDULE" });
      const response = await http.put(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYSCHEDULE_UPDATE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function addProcessingFee({ id, postData }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PROCESSINGFEES",
        options: { id },
      });
      const response = await http.post(url, postData);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYSCHEDULE_FEE_ADD")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function removeProcessingFee({ id, processingFeeId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PROCESSINGFEES",
        options: { id },
      });
      const response = await http.del(`${url}/${processingFeeId}`);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYSCHEDULE_FEE_DELETE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getProcessingFee({ options, id, processingFeeId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PROCESSINGFEES",
        options: { id },
      });
      const queryOptions = {
        page: 1,
        ...options,
      };
      const response = await requestBuilder(processingFeeId, url, queryOptions);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(
            response.data && response.data.result,
            "PAYSCHEDULE_FEE_DETAILS"
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function payscheduleHandler({
  id,
  cancelToken,
  options,
  payload,
  action,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let response = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "getCurrencyFees":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYSCHEDULE_CURRENCY_FEES_URL",
            options: { id },
          });
          response = await http.get(url, cancelToken);
          break;
        case "updateCurrencyFees":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYSCHEDULE_CURRENCY_FEES_URL",
            options: { id },
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "delete":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYSCHEDULE_DELETE_URL",
            options: { id },
          });
          response = await http.del(url);
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
