import { useCallback, useReducer } from 'react';

import { defaultState, reducer, setModalConfig, setModalOpen } from '../modal.store';
import { ModalProps } from '../modal.types';

import { useResizeListener } from './use-resize-listener';

export const useModalProviderInit = () => {
  const [state, dispatch] = useReducer(reducer, { ...defaultState });
  const refs = useResizeListener(state);

  const toggleModal = useCallback(
    (value: boolean) => dispatch(setModalOpen(value)),
    [dispatch]
  );
  const configureModal = useCallback(
    (props: ModalProps) => void dispatch(setModalConfig(props)),
    [dispatch]
  );

  return {
    ...refs,
    state,
    dispatch,
    toggleModal,
    configureModal,
  };
};
