import {
  forwardRef,
  PropsWithChildren,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';

import * as S from './popover.styles';
import { PopoverProps } from './popover.types';

export const PopoverTestId = {
  popover: (testId?: string) => `popover-${testId}`,
};

export const Popover = forwardRef(
  (props: PropsWithChildren<PopoverProps>, ref?: Ref<HTMLDivElement>) => {
    const {
      state,
      children,
      testId = '',
      className,
      portalContainer,
      closeOnOutsideClick,
      onPlacementChange,
      isNonModal,
      triggerRef,
    } = props;

    const internalRef = useRef<HTMLDivElement>(null);
    const popoverRef = (ref as RefObject<HTMLDivElement>) ?? internalRef;
    const { popoverProps, underlayProps, placement } = usePopover(
      { ...props, offset: props.offset ?? 8, popoverRef },
      state
    );

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        const target = event.target as Node;
        if (triggerRef?.current?.contains(target)) {
          return;
        }
        if (!popoverRef.current?.contains(target)) {
          state.close();
        }
      },
      [triggerRef, popoverRef, state]
    );

    useEffect(() => {
      if (closeOnOutsideClick) {
        document.addEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [closeOnOutsideClick, handleClickOutside]);

    useEffect(() => {
      onPlacementChange?.(placement);
    }, [placement, onPlacementChange]);

    return (
      <Overlay
        data-testid={PopoverTestId.popover(testId)}
        portalContainer={portalContainer}
      >
        {!isNonModal && <S.Underlay {...underlayProps} />}
        <S.Wrapper {...popoverProps} ref={popoverRef} className={className}>
          <DismissButton onDismiss={state.close} />
          {children}
          <DismissButton onDismiss={state.close} />
        </S.Wrapper>
      </Overlay>
    );
  }
);

export default Popover;
