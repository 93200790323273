// @ts-nocheck
import { Upload } from "antd";
import { DragAndDropIcon } from "../../../Common/customicons";

const DragAndDropUpload = ({
  onUpload,
  fileList,
  maxCount = 10,
  multiple = true,
  accept,
  beforeUpload = () => false,
  showUploadList,
  onRemove,
}: {
  onUpload: (e: any) => void;
  fileList?: any;
  maxCount?: number;
  multiple?: boolean;
  accept: string;
  beforeUpload?: (e: any) => void;
  showUploadList?: boolean;
  onRemove?: () => void;
}) => {
  const { Dragger } = Upload;

  return (
    <Dragger
      maxCount={maxCount}
      multiple={multiple}
      onChange={onUpload}
      beforeUpload={beforeUpload}
      showUploadList={showUploadList}
      fileList={fileList}
      accept={accept}
      onRemove={onRemove}
    >
      <div className="flex justify-center">
        <DragAndDropIcon />
      </div>
      <p className="ant-upload-text">
        Drop {multiple ? "files" : "a file"} here or click to upload.
      </p>
    </Dragger>
  );
};

export default DragAndDropUpload;
