//@ts-nocheck
import React, { Component } from "react";
import PayScheduleList from "../../../components/paySchedule/list";
import CountryLookup from "../../../components/lookup/country";
import _ from "lodash";
import {
  mustBeArray,
  findAuthorizationClaims,
  removeUndefined,
  delay,
  updateAuthorizationClaims,
  getPersonalizationData,
  setPersonalizationData,
  getCurrentCardGrid,
  getCurrentGridSortInfo,
  getCurrentTableSortInfo,
  getCurrentGridPageSize,
  checkClaimsAuthorization,
  requestUrlBuilder,
  getGridRowFromPersonalization,
  getGridColFromPersonalization,
  getGridSortOrderPersonalization,
  getGridSortColumnFromPersonalization,
  errorHandler,
  isComponentVisible,
  getPerPageFromPersonalization,
  filteredByLabel,
  getActiveFiltersforDashboardLabel,
  defaultListEmpty,
  trimWhiteSpaces,
  getDefaultPage,
  getDefaultPerPage,
  updateDomChanges,
  validateUsersAccessRights,
  getPayScheduleCreateRoute,
  getPayScheduleDetailRoute,
} from "../../../../libs";
import {
  PAGINATION_SCROLLING,
  GRID_VIEW_VALUE,
  PAGINATION_NOT_REQUIRED,
  TABLE_VIEW_VALUE,
  INTEGER_PERSONALIZATION,
  STRING_PERSONALIZATION,
} from "../../../../constants/defaultClaims";
import {
  PAYSCHEDULE_FIELD_OPTIONS,
  PAYSCHEDULE_GRID_OPTIONS,
} from "../../../../constants/sortingOptions";
import NoRecord from "../../../../Common/NoRecord";
import ContentEmpty from "../../../components/common/contentListEmpty";
import { FILTER_ACTIVE_INACTIVE_OPTIONS } from "../../../../constants/options";
import SingleFieldDropdownFilter from "../../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import SearchIconButton from "../../../../Common/ClaimComponents/IconButton/SearchIconButton";
import SingleFieldTextBoxFilter from "../../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import FilterContainer from "../../../../Common/ClaimContainers/FilterContainer";
import GridTableViewOption from "../../../components/common/gridTableViewOption";
import ListContainer from "../../../../Common/ClaimContainers/ListContainer";
import LinkButton from "../../../../Common/ClaimComponents/Button/LinkButton";
import PayScheduleDisplayCard from "../../../components/paySchedule/PayScheduleDisplayCard";
import ScrollablePayScheduleTable from "../../../components/paySchedule/ScrollablePayScheduleTable";
import APIHandler from "../../../../constants/apiUrl";
import {
  CREATE_PAY_SCHEDULE,
  GOOGLE_ANALYTICS_PAGE_TITLE,
  PAYSCHEDULE_FILTER_CONTAINER,
  PAYSCHEDULE_LIST_CONTAINER,
} from "../../../../constants";
import { withRouter } from "../../../../hooks";

type State = any;

class PayscheduleAdmin extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      filters: {},
      current: 1,
      sortInfo: {},
      per_page: 20,
      listLoading: true,
      paginationType: "",
      updatedComponentPersonalization: [],
      gridView: false,
      gridable: true,
      list: [],
      grid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      total: 0,
      gridSortOption: PAYSCHEDULE_GRID_OPTIONS,
      selectedGridSort: undefined,
      fieldOptions: PAYSCHEDULE_FIELD_OPTIONS,
      sortingType: "notrequired",
      hasMoreData: false,
      isPinned: "false",
    };
  }
  UNSAFE_componentWillMount() {
    if (!this.props.dashboardView) {
      this.props.updateHeader({ header: {} });
    }
  }
  componentDidMount() {
    const { dashboardView, dashboardClaims, pageClaims } = this.props;
    //validate if user has access to page
    if (!validateUsersAccessRights({ claim: pageClaims })) return;
    if (dashboardView) {
      const paySchedulePersonalizations = getPersonalizationData({
        type: "listContainer",
        personalizationData: dashboardClaims,
      });
      this.setState({ gridView: paySchedulePersonalizations.gridView });
      this.listPaySchedules({ options: {}, notUpdate: true });
    } else {
      this.props.updateHeader({
        header: {
          title: "Pay Schedules",
          gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.PayScheduleList,
          module: "",
          enableBack: false,
          entity: "pay schedule",
          action: checkClaimsAuthorization({
            component: CREATE_PAY_SCHEDULE,
            claims: this.props.pageClaims?.components,
          }),
        },
      });
      this.loadPayScheduleComponents();
    }
  }

  loadPayScheduleComponents = () => {
    const payScheduleCountryDropdownFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payScheduleCountryDropdownFilter",
    });
    const payScheduleNameTextboxFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payScheduleNameTextboxFilter",
    });
    const payScheduleStatusDropdownFilter = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payScheduleStatusDropdownFilter",
    });
    const payScheduleListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: PAYSCHEDULE_LIST_CONTAINER,
    });
    // Personalizations
    const paySchedulePersonalizations = getPersonalizationData({
      type: "listContainer",
      personalizationData: payScheduleListContainer?.personalizations,
    });
    const nameTextBoxPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: payScheduleNameTextboxFilter?.personalization,
    });
    const countryPersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: payScheduleCountryDropdownFilter.personalization,
    });
    const isActivePersonalizations = getPersonalizationData({
      type: "filterContainer",
      personalizationData: payScheduleStatusDropdownFilter?.personalization,
    });
    //
    this.setState({
      filters: {
        name: nameTextBoxPersonalizations?.value,
        countryId: countryPersonalizations?.value
          ? Number(countryPersonalizations.value)
          : undefined,
        isActive: isActivePersonalizations?.value
          ? Number(isActivePersonalizations.value)
          : 0,
      },
      paginationType: paySchedulePersonalizations?.paginationType,
      sortingType: paySchedulePersonalizations?.sortingType,
      isPinned: paySchedulePersonalizations?.isPinned,
      gridable: payScheduleListContainer?.gridable,
    });
    this.loadPayScheduleGridComponents({ paySchedulePersonalizations });
    this.loadPayScheduleTableComponents({ paySchedulePersonalizations });
  };

  loadPayScheduleTableComponents = ({ paySchedulePersonalizations }) => {
    const payScheduleGeneralTable = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payScheduleGeneralTable",
    });
    if (isComponentVisible({ claim: payScheduleGeneralTable })) {
      const payScheduleTablePersonalizations = getPersonalizationData({
        type: "table",
        personalizationData: payScheduleGeneralTable.personalizations,
      });
      const pageNumber = getDefaultPage({
        page: payScheduleTablePersonalizations?.pageNumber,
      });
      const pageSize = getDefaultPerPage({
        perPage: payScheduleTablePersonalizations?.pageSize,
      });
      const SortOrder = payScheduleTablePersonalizations?.sortOrder;
      const SortColumn = payScheduleTablePersonalizations?.sortColumn;
      this.setState(
        {
          per_page: getPerPageFromPersonalization({
            pageSize,
            paginationType: paySchedulePersonalizations?.paginationType,
          }),
          current: getDefaultPage({ page: pageNumber }),
          sortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (!paySchedulePersonalizations.gridView) {
            this.setState({ gridView: false });
            if (
              paySchedulePersonalizations?.paginationType ===
              PAGINATION_NOT_REQUIRED
            ) {
              this.listScrollingPaySchedule({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.listPaySchedules({ options: {}, notUpdate: true });
            }
          }
        }
      );
    }
  };

  loadPayScheduleGridComponents = ({ paySchedulePersonalizations }) => {
    const payScheduleGeneralGrid = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: "payScheduleGeneralGrid",
    });
    if (isComponentVisible({ claim: payScheduleGeneralGrid })) {
      const payScheduleGridPersonalizations = getPersonalizationData({
        type: "grid",
        personalizationData: payScheduleGeneralGrid.personalizations,
      });
      const pageNumber = getDefaultPage({
        page: payScheduleGridPersonalizations?.pageNumber,
      });
      const row = getGridRowFromPersonalization({
        personalizations: payScheduleGridPersonalizations,
      });
      const col = getGridColFromPersonalization({
        personalizations: payScheduleGridPersonalizations,
      });
      const SortOrder = getGridSortOrderPersonalization({
        personalizations: payScheduleGridPersonalizations,
      });
      const SortColumn = getGridSortColumnFromPersonalization({
        personalizations: payScheduleGridPersonalizations,
      });
      this.setState(
        {
          grid: {
            page: pageNumber,
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },
          selectedGridSort: _.find(
            this.state.gridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (paySchedulePersonalizations?.gridView) {
            this.setState({ gridView: true });
            this.fetchMoreData({ isPaginated: true });
            if (
              paySchedulePersonalizations?.paginationType ===
              PAGINATION_SCROLLING
            ) {
              this.listScrollingGridPaySchedule({
                pageLoad: true,
                notUpdate: true,
              });
            } else {
              this.fetchMoreData({ isPaginated: true, notUpdate: true });
            }
          }
        }
      );
    }
  };

  listScrollingGridPaySchedule = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;
    this.setState(
      {
        listLoading: true,
        grid: {
          ...this.state.grid,
          page: !pageLoad
            ? Number(this.state.grid?.page) + 1
            : Number(this.state.grid?.page),
        },
      },
      () => {
        const { grid } = this.state;
        const { page } = grid;
        const per_page = getCurrentGridPageSize({
          paginationType: this.state.paginationType,
          grid: this.state.grid,
        });
        let filterOptions = this.getFilters();
        let sortingParams = getCurrentGridSortInfo({
          grid: this.state.grid,
          sortingType: this.state.sortingType,
        });
        this.props
          .listPayschedules({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              q: { ...filterOptions },
              ...sortingParams,
            },
            targetEndpoint: this.props.targetEndpoint,
          })

          .then((response) => {
            this.setState({ listLoading: false });
            if (response.status) {
              this.checkIfDeafultListEmpty({ list: response.data });

              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listScrollingGridPaySchedule({ pageLoad: true });
                });
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.list = pageLoad
                      ? data
                      : prevState.list.concat(data);

                    prevState.hasMoreData =
                      response.total > prevState.list.length ? true : false;

                    prevState.total = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      if (!notUpdate) {
                        this.updatePersonalization("payScheduleGeneralGrid");
                      }
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  handleFilter = () => {
    this.setState(
      (prevState) => {
        prevState.listLoading = true;
        prevState.current = 1;
        prevState.grid = { ...prevState.grid, page: 1 };
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let options = { page: 1, q: { ...tempFilters } };

        if (this.state.gridView) {
          options = {
            ...options,
            per_page: getCurrentGridPageSize({
              paginationType: this.state.paginationType,
              grid: this.state.grid,
            }),
            ...getCurrentGridSortInfo({
              grid: this.state.grid,
              sortingType: this.state.sortingType,
            }),
          };
        } else {
          options = {
            ...options,
            per_page: this.state.per_page,
            ...getCurrentTableSortInfo({
              sortInfo: this.state.sortInfo,
              sortingType: this.state.sortingType,
            }),
          };
        }

        this.updateAllPersonalization();

        this.props
          .listPayschedules({
            options,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ filterLoading: false, listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  handleChange = (index, value) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [index]: value,
      },
    });
  };

  getFilters = () => {
    const { filters } = this.state;
    let tempFilters = Object.assign({}, filters);
    tempFilters = { ...removeUndefined(tempFilters) };
    if (Number(tempFilters.countryId) === 0) {
      delete tempFilters.countryId;
    }
    tempFilters.name = trimWhiteSpaces(tempFilters.name);
    switch (Number(tempFilters.isActive)) {
      case 0:
        delete tempFilters.isActive;
        break;
      case 1:
        tempFilters.isActive = 1;
        break;
      case 2:
        tempFilters.isActive = 0;
        break;
      default:
        break;
    }
    return tempFilters;
  };
  saveChangedPersonalizationClaims = (newClaim) => {
    this.setState((prevState) => {
      let index = _.findIndex(
        mustBeArray(prevState.updatedComponentPersonalization),
        (o) => o.id === (newClaim && newClaim.id)
      );
      if (index && index !== -1) {
        prevState.updatedComponentPersonalization[index] = newClaim;
      } else {
        prevState.updatedComponentPersonalization = [
          ...prevState.updatedComponentPersonalization,
          newClaim,
        ];
      }
      return prevState;
    });
  };

  clearSavedPersonalizationChanges = () => {
    this.setState({ updatedComponentPersonalization: [] });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: { name: "", countryId: 0, isActive: 0 },
        current: 1,
        sortInfo: {},
        listLoading: true,

        grid: { ...this.state.grid, page: 1, sortInfo: {} },
        selectedGridSort: undefined,
      },
      () => {
        this.updateAllPersonalization();

        this.props
          .listPayschedules({
            options: {
              page: 1,
              per_page: this.state.gridView
                ? getCurrentGridPageSize({
                    paginationType: this.state.paginationType,
                    grid: this.state.grid,
                  })
                : this.state.per_page,
            },
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.setState({
                list: mustBeArray(resp.data),
                total: resp.total,
              });
            }
          });
      }
    );
  };

  updateAllPersonalization = () => {
    this.updatePersonalization("payScheduleNameTextboxFilter", false);
    delay(300).then(() => {
      this.updatePersonalization("payScheduleCountryDropdownFilter", false);
    });
    delay(600).then(() => {
      this.updatePersonalization("payScheduleStatusDropdownFilter", false);
    });
    delay(900).then(() => {
      this.updatePersonalization("payScheduleGeneralTable", false);
    });
    delay(1200).then(() => {
      this.updatePersonalization("payScheduleGeneralGrid");
    });
  };

  listScrollingPaySchedule = ({
    options = {},
    pageLoad = false,
    notUpdate,
  }) => {
    if (!pageLoad && !this.state.hasMoreData) return false;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.current =
            this.state.total === mustBeArray(this.state.list).length
              ? prevState.current
              : Number(prevState.current) + 1;
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();

        let tempOptions = {
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page: Number(this.state.current) * Number(this.state.per_page),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.current,
            per_page: this.state.per_page,
          };
        }

        this.props
          .listPayschedules({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listScrollingPaySchedule({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    list: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.list, ...mustBeArray(resp.data)],

                    total: resp.total,
                  },
                  () => {
                    this.setState({
                      hasMoreData:
                        this.state.list.length < resp.total ? true : false,
                    });
                  }
                );
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("payScheduleGeneralTable");
          }
        });
      }
    );
  };

  listPaySchedules = ({ options, notUpdate }) => {
    this.setState(
      (prevState) => {
        if (options?.page) {
          prevState.current = options.page;
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.current,
          per_page: this.state.per_page,
          ...getCurrentTableSortInfo({
            sortInfo: this.state.sortInfo,
            sortingType: this.state.sortingType,
          }),
          q: { ...tempFilters },
        };

        this.props
          .listPayschedules({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(this.state.current) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ current: 1 }, () => {
                  this.listPaySchedules({ options: {} });
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization("payScheduleGeneralTable");
          }
        });
      }
    );
  };
  fetchMoreData = ({ isPaginated = false, notUpdate }) => {
    this.setState({ listLoading: true });

    const { grid } = this.state;
    const { page } = grid;

    const per_page = getCurrentGridPageSize({
      paginationType: this.state.paginationType,
      grid: this.state.grid,
    });

    let options = this.getFilters();

    let sortingParams = getCurrentGridSortInfo({
      grid: this.state.grid,
      sortingType: this.state.sortingType,
    });

    this.props
      .listPayschedules({
        options: { page, per_page, q: { ...options }, ...sortingParams },
        targetEndpoint: this.props.targetEndpoint,
      })

      .then((response) => {
        if (response.status) {
          this.checkIfDeafultListEmpty({ list: response.data });

          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ grid: { ...grid, page: 1 } }, () => {
              this.fetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.list = data;
                } else {
                  prevState.list = prevState.list.concat(data);

                  if (
                    data.length !== 0 &&
                    data.length === this.state.page_size
                  ) {
                    prevState.hasMoreData = true;
                    prevState.grid = {
                      ...prevState.grid,
                      page: prevState.grid.page + 1,
                    };
                  } else {
                    prevState.hasMoreData = false;
                  }
                }

                prevState.total = response.total;
                prevState.listLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  if (!notUpdate) {
                    this.updatePersonalization("payScheduleGeneralGrid");
                  }
                });
              }
            );
          }
        }
      });
  };

  listPayScheduleGrid = (page) => {
    this.setState(
      (prevState) => {
        prevState.grid.page = page;
        prevState.listLoading = true;
        return prevState;
      },
      () => {
        const { grid } = this.state;

        let tempFilters = this.getFilters();
        let tempOptions = {
          page: this.state.grid && this.state.grid.page,
          per_page: getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          }),
          q: { ...tempFilters },

          ...getCurrentGridSortInfo({
            grid: this.state.grid,
            sortingType: this.state.sortingType,
          }),
        };

        this.props
          .listPayschedules({
            options: tempOptions,
            targetEndpoint: this.props.targetEndpoint,
          })
          .then((resp) => {
            this.setState({ listLoading: false });

            if (resp.status) {
              this.checkIfDeafultListEmpty({ list: resp.data });

              if (
                Number(grid.page) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ grid: { ...grid, page: 1 } }, () => {
                  this.listPayScheduleGrid(1);
                });
              } else {
                this.setState({
                  list: mustBeArray(resp.data),
                  total: resp.total,
                });
              }
            } else {
              errorHandler({ response: resp, hasValidationErrors: true });
            }
          });
        delay(300).then(() => {
          this.updatePersonalization("payScheduleGeneralGrid");
        });
      }
    );
  };

  updatePersonalization = (field, update = true) => {
    if (this.props.dashboardView) return;
    try {
      let currentAuthorizationDOM = mustBeArray(
        this.props.member &&
          this.props.member.details &&
          this.props.member.details.authorizationDOM
      );
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let updatedDOM = [];

      const payScheduleListContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === PAYSCHEDULE_LIST_CONTAINER
      );

      const payScheduleFilterContainer = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === PAYSCHEDULE_FILTER_CONTAINER
      );

      const payScheduleGeneralTable = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payScheduleGeneralTable"
      );

      const payScheduleGeneralGrid = _.find(
        mustBeArray(this.props.pageClaims?.components),
        (o) => o.name === "payScheduleGeneralGrid"
      );
      switch (field) {
        case "payScheduleGeneralTable":
          if (payScheduleListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "table",

              pageNumber: `${this.state.current}`,

              sortOrder: this.state.sortInfo?.order,

              sortColumn: this.state.sortInfo?.field,

              pageSize: `${this.state.per_page}`,
              personalizationData:
                payScheduleGeneralTable &&
                payScheduleGeneralTable.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleGeneralGrid":
          if (payScheduleListContainer.personalizable === "true") {
            const { grid } = this.state;
            personalizations = setPersonalizationData({
              type: "grid",
              pageNumber: `${grid?.page}`,
              row: `${grid?.row}`,
              col: `${grid?.col}`,
              sortOrder: grid?.sortInfo?.order,
              sortColumn: grid?.sortInfo?.field,
              personalizationData: payScheduleGeneralGrid?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleCountryDropdownFilter":
          if (payScheduleFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = `${this.state.filters?.countryId ?? ""}`;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleStatusDropdownFilter":
          if (payScheduleFilterContainer.personalizable === "true") {
            personalization = { ...INTEGER_PERSONALIZATION };

            personalization.value = this.state.filters?.isActive;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case "payScheduleNameTextboxFilter":
          if (payScheduleFilterContainer.personalizable === "true") {
            personalization = { ...STRING_PERSONALIZATION };

            personalization.value = this.state.filters?.name;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalization,
              label: "personalization",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        case PAYSCHEDULE_LIST_CONTAINER:
          if (payScheduleListContainer.personalizable === "true") {
            personalizations = setPersonalizationData({
              type: "listContainer",

              gridView: this.state.gridView
                ? GRID_VIEW_VALUE
                : TABLE_VIEW_VALUE,

              paginationType: this.state.paginationType,

              sortingType: this.state.sortingType,

              isPinned: this.state.isPinned,
              personalizationData: payScheduleListContainer?.personalizations,
            });
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: this.props.pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      if (!_.isEmpty(updatedComponent)) {
        const payload = {
          id: updatedComponent.id,
          name: updatedComponent.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };

        this.saveChangedPersonalizationClaims(payload);
      }
      const updateChanges = updateDomChanges({
        update,
        condition: field === "payScheduleGeneralGrid",
        updatedComponent,
        updatedComponentPersonalization:
          this.state.updatedComponentPersonalization,
      });
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: mustBeArray(
              this.state.updatedComponentPersonalization
            ),
          },
          update: updateChanges,
        });
        if (updateChanges) {
          this.clearSavedPersonalizationChanges();
        }
      });
    } catch (err) {
      return err;
    }
  };

  handleGridChange = ({ gridView, isPaginated }) => {
    if (this.state.gridView === gridView) {
      return false;
    }

    this.setState({ gridView, list: [], listLoading: true }, () => {
      this.updatePersonalization(PAYSCHEDULE_LIST_CONTAINER);
      if (!gridView) {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.listPaySchedules({ options: {} });
        }
      } else {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    });
  };

  updatePageSize = (value) => {
    if (this.state.gridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.grid.row = Number(row);
          prevState.grid.col = Number(col);
          prevState.grid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.paginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
          } else {
            this.fetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState({ per_page: Number(value), current: 1 }, () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.listPaySchedules({ options: {} });
        }
      });
    }
  };
  updateSortOrder = (selectedGridSort) => {
    this.setState(
      (prevState) => {
        prevState.selectedGridSort = selectedGridSort;
        const selectedSortOrder = _.find(
          prevState.gridSortOption,
          (o) => o.id === selectedGridSort
        );
        if (selectedSortOrder) {
          prevState.grid = {
            ...prevState.grid,
            sortInfo: {
              field: selectedSortOrder.label,
              order: selectedSortOrder.value,
            },
          };
        }
        return prevState;
      },
      () => {
        if (this.state.paginationType === PAGINATION_SCROLLING) {
          this.listScrollingGridPaySchedule({ pageLoad: true, options: {} });
        } else {
          this.fetchMoreData({ isPaginated: true });
        }
      }
    );
  };

  handleAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    const { id, companyId, businessUnitID } = data;
    switch (action) {
      case "view":
      case "edit":
        this.props.router.navigate(
          getPayScheduleDetailRoute({
            id,
            companyId,
            businessUnitId: businessUnitID,
          })
        );
        break;
      case "delete":
        break;
      default:
        break;
    }
  };

  updateGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.grid.sortInfo[field] === value) {
          prevState.grid.sortInfo[field] = "";
        } else {
          prevState.grid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.fetchMoreData({ isPaginated: true });
      }
    );
  };

  checkIfDeafultListEmpty = ({ list }) => {
    const { current, grid } = this.state;
    this.setState({
      isDefaultListEmpty: defaultListEmpty({
        current,
        grid,
        list,
        filters: this.getFilters(),
        claims: this.props.pageClaims?.components,
        component: CREATE_PAY_SCHEDULE,
      }),
    });
  };
  render() {
    const {
      sortInfo,
      filterLoading,
      listLoading,
      gridView,
      list,
      total,
      grid,
      per_page,
      paginationType,
      current,

      gridable,
      fieldOptions,
      isPinned,
      sortingType,
      hasMoreData,
      isDefaultListEmpty,
    } = this.state;

    const { pageClaims, dashboardView } = this.props;

    const cardGrid = getCurrentCardGrid({ grid: this.state.grid });

    const payScheduleUrl = APIHandler.constructEndpoint({
      endpoint: "PAYSCHEDULE",
    });
    // Endpoint that will be called by the pinned component in dashboard
    const payScheduleTargetEndpoint = requestUrlBuilder(payScheduleUrl, {
      q: {
        ...this.getFilters(),
      },
    });
    // Label to display if the list has been filtered by any field
    let activeFilters = getActiveFiltersforDashboardLabel({
      filters: this.getFilters(),
      countryList: this.state.countryList,
    });
    return isDefaultListEmpty && !dashboardView ? (
      <NoRecord>
        <ContentEmpty
          link={getPayScheduleCreateRoute({})}
          page="Pay Schedule"
        />
      </NoRecord>
    ) : (
      <React.Fragment>
        {!dashboardView && (
          <div className="flex justify-between items-center flex-wrap">
            <FilterContainer
              name={PAYSCHEDULE_FILTER_CONTAINER}
              claims={this.props.pageClaims}
            >
              <SingleFieldTextBoxFilter
                placeholder="Pay Schedules"
                name="payScheduleNameTextboxFilter"
                claims={this.props.claims}
                value={this.state.filters && this.state.filters.name}
                onChange={(value) => this.handleChange("name", value)}
                onSearch={(value) => this.handleFilter("name", value)}
                className="mb-[15px] w-[200px]"
              />
              <CountryLookup
                selectedValue={
                  this.state.filters && this.state.filters.countryId
                }
                onSelect={(value) => this.handleChange("countryId", value)}
                name="payScheduleCountryDropdownFilter"
                claims={this.props.claims}
              />
              <SingleFieldDropdownFilter
                name="payScheduleStatusDropdownFilter"
                claims={this.props.claims}
                options={FILTER_ACTIVE_INACTIVE_OPTIONS}
                value={Number(
                  (this.state.filters && this.state.filters.isActive) || 0
                )}
                defaultValue={"0"}
                onSelect={(value) => this.handleChange("isActive", value)}
                className="mb-[15px]"
                valueIsNumber={true}
              />
              <SearchIconButton
                loading={filterLoading}
                onClick={this.handleFilter}
                name="payScheduleSearchButton"
                claims={this.props.claims}
                className="ml-3"
              />
              <LinkButton
                buttonText="Reset Filter"
                className="color-primary hand"
                onClick={() => this.resetFilters()}
                name="payScheduleResetFilter"
                claims={this.props.claims}
              />
            </FilterContainer>
            <div className={"mb-6"}>
              {
                <GridTableViewOption
                  gridable={gridable}
                  handleGridChange={this.handleGridChange}
                  gridView={gridView}
                  fieldOptions={fieldOptions}
                  updateGridField={this.updateGridField}
                  gridSortInfo={this.state.grid && this.state.grid.sortInfo}
                  tableSortInfo={this.state.sortInfo}
                  updatePageSize={this.updatePageSize}
                  grid={grid}
                  per_page={per_page}
                />
              }
            </div>
          </div>
        )}
        <ListContainer
          name={PAYSCHEDULE_LIST_CONTAINER}
          claims={pageClaims}
          isPinned={isPinned}
          gridable={gridable}
          gridView={gridView}
          grid={grid}
          per_page={per_page}
          GeneralTablePaginatedComponent={PayScheduleList}
          GeneralTableScrollableComponent={ScrollablePayScheduleTable}
          generalTableComponentName={"payScheduleGeneralTable"}
          gridComponentName={"payScheduleGeneralGrid"}
          createLinkButtonName={""}
          handleAddAction={() => {
            console.log("");
          }}
          handleGridChange={this.handleGridChange}
          fieldOptions={fieldOptions}
          updateGridField={this.updateGridField}
          sortingType={sortingType}
          paginationType={paginationType}
          list={list}
          listLoading={listLoading}
          listGrid={this.listPayScheduleGrid}
          listScrollingGrid={this.listScrollingGridPaySchedule}
          hasMoreData={hasMoreData}
          gridActionComponentName={"payScheduleViewDetailLink"}
          moduleType={"paySchedule"}
          handleGridAction={this.handleAction}
          listScrollingGeneralTable={this.listScrollingPaySchedule}
          total={total}
          current={current}
          sortInfo={sortInfo}
          listGeneralTable={this.listPaySchedules}
          tableActionComponentName={"payScheduleViewDetailLink"}
          viewComponent="payScheduleViewDetailLink"
          gridPageSize={getCurrentGridPageSize({
            paginationType: this.state.paginationType,
            grid: this.state.grid,
          })}
          cardGrid={cardGrid}
          showAddAndGrid={this.props.dashboardView ? true : false} //Option to toggle between grid and table view
          view="index"
          DisplayCardComponent={PayScheduleDisplayCard} //Component for grid view
          businessunit={this.props.businessunit}
          company={this.props.company}
          rowIdParam="id" //Id for each row of table
          targetEndpoint={
            this.props.targetEndpoint || payScheduleTargetEndpoint //Fixed endpoint that will be called by the pinned component in dashboard
          }
          componentTitle={
            dashboardView ? this.props.componentTitle : "Pay Schedules" //Main title for component when pinned in the dashboard
          }
          filterApplied={
            this.props.dashboardView
              ? this.props.filterApplied
              : filteredByLabel({ filters: activeFilters })
          }
          dashboardView={dashboardView} //Is always set to true in the dashboard index
          hasNoDuplicateEntity={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PayscheduleAdmin);
