import styled from 'styled-components';

import { BodySmallRegular, Colour } from '@xemplo/style-constants';
import { zIndex } from '@xemplo/zindex';
export const ValueWrapper = styled.div`
  position: absolute;
  ${BodySmallRegular};
  inset: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  background: ${Colour.White[100]};
  margin-left: 16px;
  margin-right: 36px;
  z-index: ${zIndex.TextDropdownSingleValue};
  justify-content: space-between;
  &.has-leading-icon {
    margin-left: 48px;
  }
  &.has-siblings {
    align-self: flex-end;
    padding-bottom: 12px;
  }
  &.is-hidden {
    display: none;
  }
`;
