//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Card, message, Layout, Row, Col } from "antd";
import _ from "lodash";
import {
  filterGeoAddressLocation,
  delay,
  mustBeArray,
  abbreviate,
  errorDisplay,
  getUserArea,
  isAddressSame,
  parseItems,
  getOptionalParameter,
  getConditionalResponse,
  errorHandler,
  conditionalParameter,
  getCurrentSelectedRoleId,
  isNullEmptyUndefined,
  validateUsersAccessRights,
  findAuthorizationClaims,
  getBusinessUnitDetailRoute,
  getPayScheduleCreateRoute,
} from "../../../libs/";
import userRoles from "../../../constants/userRoles";
import { defaultCurrencyList } from "../../../constants/currency";
import BasicDetailBusinessUnitForm from "../../components/businessunit/basicDetailForm";
import AccountDetailsBusinessUnitForm from "../../components/businessunit/accountForm";
import FormNavbar from "../../../Layout/FormNavbar";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import NoRecord from "../../../Common/NoRecord";
import CreateSuccess from "../../../Common/CreateSuccess";
import axios from "axios";
import { AUSTRALIA_COUNTRY_ID } from "../../../constants/entities";
import { PRIMARY_PAYROLL_MANAGER_ID } from "../../../constants/user";
import { FormStepsCol, If } from "../../../Common/ui";
import { BUSINESS_UNIT_CREATE_SIMPLE_BUTTON } from "../../../constants/authorizationClaims";
import { withRouter } from "../../../hooks";

const { Header, Content } = Layout;

type State = any;

class CreateBusinessUnit extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      isSameAddress: false,
      disableField: false,
      providers: [],
      provider: "",
      providerId: "",
      fetching: true,
      confirmLoading: false,
      states: [],
      companyList: [],
      companyId: "",
      company: "",
      countries: [],
      timezone: [],
      editMode: false,
      disablePayroll: false,
      isSameCompany: false,
      expectedProcess: "",
      bspList: [],
      accountList: [],
      currencyList: [],
      defaultBaseSalary: [],
      defaultFBTCategory: [],
      defaultPayrollConnection: [],
      defaultExternalBusinessUnitList: [],
      pspUsersList: [],
      baseSalaryLoading: false,
      fetchingBusinessunit: false,
      fetchingConnection: false,
      activeIndex: "1",
      finalValues: {},
      isInvoiceCompany: false,
      disableCompany: false,
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        },
        {
          id: 2,
          label: "Account Details",
          description:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
        },
      ],
      newBusinessUnitId: "",
      actionTwoLink: "",
      registeredAddress1: "",
      postalAddress1: "",
    };
    this.fetchCountries = _.debounce(this.fetchCountries);
    this.fetchTimezone = _.debounce(this.fetchTimezone);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.toggleSystems = this.toggleSystems.bind(this);
    this.fetchCompany = this.fetchCompany.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
  }

  componentDidMount() {
    const { match, pageClaims } = this.props;
    const editing = !!match?.params?.businessunitid;
    const disablePayrollprovider = match?.params?.payrollproviderid;

    // Check if user has access to create business unit. If not redirect to home.
    const createButton = findAuthorizationClaims({
      claims: pageClaims.components,
      name: BUSINESS_UNIT_CREATE_SIMPLE_BUTTON,
    });
    if (!validateUsersAccessRights({ claim: createButton })) return;

    if (match?.params?.companyid) {
      this.setState({ disableCompany: true });
      delay(200).then(() => {
        this.props.form.setFieldsValue({ company: match.params.companyid });
      });
    }
    this.fetchCompany();
    this.fetchCountries("");
    this.fetchTimezone();
    this.props
      .listPayrollProviders({
        options: { per_page: 500 },
      })
      .then((response) => {
        if (response.status && response.data) {
          this.setState({ providers: response.data });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });

    if (editing) {
      if (this.isActionServiceDetailEdit(window.location)) {
        this.setState({ activeIndex: "2" });
      }
      this.props
        .getBusinessUnit({
          id: match?.params?.businessunitid,
          options: {},
        })
        .then((resp) => {
          if (resp.status && resp.data) {
            this.props.updateHeader({
              header: {
                title: resp.data.name,
              },
            });

            if (this.isActionServiceDetailEdit(window.location)) {
              this.handleSaveBasicDetailsData({ values: resp.data });
            }

            this.listServiceProviderUsers({
              id: resp.data.payrollServiceProviderId,
            });
            this.props
              .listBillingServiceProviders({
                options: {
                  q: { serviceCountryId: resp.data.countryId },
                  per_page: 500,
                },
              })
              .then((res) => {
                if (res.status && res.data) {
                  this.setState({ bspList: res.data });
                  this.handleChangeBSP(
                    resp.data?.billingServiceProviderID,
                    true
                  );
                } else {
                  errorDisplay(res?.data?.validationErrors);
                }
              });
            this.setState((prevState) => {
              prevState.editMode = true;
              prevState.isSameCompany = !!resp.data.isBusinessUnitSameAsCompany;
              prevState.isSameAddress = isAddressSame(resp.data);
              prevState.isInvoiceCompany = resp.data.isInvoiceCompany;
              return prevState;
            });

            this.props.form.setFieldsValue({
              postalAddress2: resp.data.postalAddressLine2,
              postalSuburb: resp.data.postalSuburb,
              postalPostCode: resp.data.postalPostalCode,
              postalState: Number(resp.data.postalAddressStateId),
              registeredAddress2: resp.data.registeredAddressLine2,
              registeredSuburb: resp.data.registeredSuburb,
              registeredPostCode: resp.data.registeredPostalCode,
              registeredState: Number(resp.data.registeredAddressStateId),
              name: resp.data.name,
              registeredName: resp.data.registeredBusinessName,
              abn: resp.data.abn,
            });

            this.setState({
              postalAddress1: resp.data.postalAddressLine1,
              registeredAddress1: resp.data.registeredAddressLine1,
              companyList: [
                { id: resp.data.companyId, value: resp.data.companyName },
              ],
              isSameCompany: resp.data.isBusinessUnitSameAsCompany,
            });

            let convertTo = this.getCurrencyDetail({
              label: "id",
              value: Number(resp.data.convertTo),
            });
            let convertFrom = this.getCurrencyDetail({
              label: "id",
              value: Number(resp.data.convertFrom),
            });

            this.props.form.setFieldsValue(
              {
                company: resp.data.companyId,
                bspId: resp.data.billingServiceProviderID,
                bspAccountId: resp.data.billingServiceProviderAccountID,
                isInvoiceNeeded: resp.data.isInvoiceNeeded ? 1 : 2,
                sameBusinessUnitCompany: resp.data.isBusinessUnitSameAsCompany,
                paymentMandatory: resp.data.paymentMandatory ? 1 : 2,
                referenceCode: resp.data.invoicePrefixCode,
                externalBusinessUnitId: resp.data.externalBusinessUnitId,
                addressCountryID: Number(resp.data.countryId),
                accountingSystem: resp.data.accountingSystem,
                referenceName: resp.data.referenceName,
              },
              () => {
                this.props.form.setFieldsValue({
                  convertFrom: convertFrom && convertFrom.code,
                  convertTo: convertTo && convertTo.code,
                  fixedConversionRate: resp.data.fixedConversionRate,
                  percentConversionRate: resp.data.percentConversionRate,
                });
              }
            );
            // Populate payroll provider section
            this.populatePayrollProviderFields(
              resp.data.payrollServiceProviderId
            );
            // Populate payroll connection and primary payroll manager
            this.populatePrimaryPayrollManager(
              resp.data.payrollServiceProviderId,
              resp.data.assignedUsers,
              resp.data.payrollSystemConnectionId
            );
            // Get external business unit list
            this.getExternalBusinessUnitDetails(
              resp.data.payrollSystemConnectionId,
              resp.data.externalBusinessUnitId
            );
          } else {
            errorHandler({ response: resp, hasValidationErrors: true });
          }
        });
    } else {
      this.props
        .listBillingServiceProviders({ options: { per_page: 500 } })

        .then((resp) => {
          if (resp.status && resp.data) {
            this.setState({ bspList: resp.data });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
      if ([userRoles.PSPArea].includes(getUserArea())) {
        return this.handlePspUserValidation();
      }
      if (disablePayrollprovider) {
        return this.handleDefaultSelectedPayrollProvider(
          match.params?.payrollproviderid
        );
      }
    }
  }

  // If the business unit is being created after navigating from a payroll provider screen
  handleDefaultSelectedPayrollProvider = (payrollproviderid) => {
    this.props
      .getPayrollProvider({ id: payrollproviderid })

      .then((response) => {
        if (response.status) {
          this.setState({
            providers: [{ id: response.data?.id, value: response.data?.name }],
          });

          this.props.form.setFieldsValue(
            { payrollserviceprovider: response.data?.id },
            () => {
              this.setState({ disablePayroll: true });
            }
          );
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });

    this.handleChangePayrollProvider(payrollproviderid);
  };

  // If the user creating the business unit is psp user - disable payroll provider selection and pre populate it
  handlePspUserValidation = () => {
    this.setState({ disablePayroll: true });
    this.props
      .listPSPUsers({
        id: getCurrentSelectedRoleId({ member: this.props.member }),
        options: { q: {} },
      })
      .then((resp) => {
        const serviceProviderDetails = this.getServiceProviderDetails({
          data: resp?.data,
        });

        if (resp.status) {
          this.setState({
            providers: [
              {
                id: serviceProviderDetails?.id,
                value: serviceProviderDetails?.name,
              },
            ],
          });

          this.props.form.setFieldsValue({
            payrollserviceprovider: serviceProviderDetails?.id,
          });

          this.handleChangePayrollProvider(serviceProviderDetails?.id);
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  // Populate payroll provider fields
  populatePayrollProviderFields = (payrollServiceProviderId) => {
    this.setState({
      disablePayroll: [userRoles.PSPArea].includes(getUserArea()),
    });
    this.props.form.setFieldsValue({
      payrollserviceprovider: payrollServiceProviderId,
    });
  };

  // Populate primary payroll manager and payroll connection on page load
  populatePrimaryPayrollManager = (
    payrollServiceProviderId,
    assignedUsers,
    payrollSystemConnectionId
  ) => {
    this.handleChangePayrollProvider(payrollServiceProviderId).then(
      (response) => {
        let primaryPayrollManager = mustBeArray(assignedUsers).find(
          (o) => o.assigneeType === PRIMARY_PAYROLL_MANAGER_ID
        );
        if (response.status) {
          this.setState({
            defaultPayrollConnection: mustBeArray(response.data),
          });

          this.props.form.setFieldsValue({
            payrollSystemConnectionId: payrollSystemConnectionId,
            payrollManager: primaryPayrollManager?.id,
          });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      }
    );
  };

  // Get external business unit details on pageload
  getExternalBusinessUnitDetails = (
    payrollSystemConnectionId,
    externalBusinessUnitId
  ) => {
    this.handleChangeConnection(payrollSystemConnectionId).then((response) => {
      if (response.status) {
        this.setState({
          defaultExternalBusinessUnitList: mustBeArray(response.data),
        });

        this.props.form.setFieldsValue({
          externalBusinessUnitId: Number(externalBusinessUnitId),
        });
      } else {
        errorHandler({ response, hasValidationErrors: true });
      }
    });
  };

  handleSaveBasicDetailsData = ({ values }) => {
    if (!values) return;
    let finalValues = {
      companyName: values.name,
      companyId: values.companyId,
      operatingTimeZone: values.operatingTimeZoneID,
      registeredBusinessName: values.registeredBusinessName,
      abn: values.abn ? Number(values.abn) : "",
      address: {
        address1: values.registeredAddressLine1,
        address2: values.registeredAddressLine2,
        suburb: values.registeredSuburb,
        state: values.registeredAddressStateId,
        postCode: values.registeredPostalCode,
        isActive: values.isActive,
      },
      postalAddress: {
        address1: values.postalAddressLine1,
        address2: values.postalAddressLine2,
        suburb: values.postalSuburb,
        state: values.postalAddressStateId,
        postCode: values.postalPostalCode,
        isActive: values.isActive,
      },
      country: values.countryId,
      isActive: values.isActive,
      isBusinessUnitSameAsCompany: values.isBusinessUnitSameAsCompany,
    };
    this.setState({
      finalValues,
      postalAddress1: values.postalAddressLine1,
      registeredAddress1: values.registeredAddressLine1,
    });
  };

  isActionServiceDetailEdit = (location) => {
    if (location && location.search) {
      return location.search?.split("=")[1];
    }
    return null;
  };

  componentWillUnmount() {
    this.props.signal.cancel("Api is being canceled");
  }

  listServiceProviderUsers = ({ id }) => {
    this.props
      .lookUpHandler({
        type: "pspUsers",
        options: { q: { serviceProviderId: id } },
        cancelToken: this.signal.token,
      })
      .then((response) => {
        if (response.status) {
          this.setState({ pspUsersList: mustBeArray(response.data) });
        }
      });
  };

  getServiceProviderDetails = ({ data }) => {
    const userPersona = mustBeArray(data && data.userPersona);
    const selectedPersona = userPersona.find((o) => o.isSelected);
    const tenant = parseItems(selectedPersona?.tenant);
    return { name: tenant?.tenantName, id: tenant?.tenantId };
  };

  handleChangeConnection = (value) => {
    this.props.form.resetFields(["externalBusinessUnitId"]);

    this.setState({
      fetchingExternalBusinessUnit: true,
      defaultExternalBusinessUnitList: [],
    });

    return this.props
      .fetchExternalBusinessunit({
        options: { payrollSystemConnectionId: value },
      })

      .then((response) => {
        this.setState({ fetchingExternalBusinessUnit: false });

        if (response.status) {
          this.setState({
            defaultExternalBusinessUnitList: mustBeArray(response.data),
          });
        } else {
          errorDisplay(response?.data?.validationErrors, true);
        }

        return response;
      });
  };

  fetchCountries = (val) => {
    let businessUnitId = this.props.match?.params?.businessunitid;

    this.props.countriesLookup({ options: { name: val } }).then((response) => {
      if (response.status) {
        this.setState({ countries: mustBeArray(response.data) }, () => {
          const defaultCountryID =
            businessUnitId === undefined ||
            this.props.businessunit?.addressCountryID === null
              ? AUSTRALIA_COUNTRY_ID
              : this.props.businessunit?.addressCountryID;
          this.updateLocationFields(Number(defaultCountryID), "id");
        });
      } else {
        errorDisplay(response?.data?.validationErrors);
      }
    });
  };

  updateLocationFields = (defaultCountryID, key, registeredAddressSelect) => {
    return new Promise((resolve, _rej) => {
      const selectedCountry = this.state.countries.find(
        (country) => country[key] === defaultCountryID
      );
      if (selectedCountry?.id) {
        if (
          registeredAddressSelect &&
          selectedCountry.id !== this.props.form.getFieldValue("country")
        ) {
          this.props.form.resetFields(["abn", "operatingTimezone"]);
        }

        this.props.form.setFieldsValue({ country: selectedCountry.id });

        this.populateStates(selectedCountry.id, true).then((res) => {
          if (res.status) {
            resolve(true);
          } else {
            errorDisplay(res && res.data && res.data.validationErrors);
          }
        });
      } else {
        this.props.form.resetFields(["country"]);
      }
    });
  };

  fetchTimezone = () => {
    this.props.timezoneLookup({ options: {} }).then((resp) => {
      let timezone = mustBeArray(resp && resp.data);
      this.setState({ timezone }, () => {
        if (this.props.match?.params?.businessunitid) {
          this.props.form.setFieldsValue({
            operatingTimezone: this.props.businessunit?.operatingTimeZoneID,
          });
        }
      });
    });
  };

  populateStates = (val, select) => {
    let params = {};
    params.countryId = val;

    return this.props.populateStates({ options: params }).then((response) => {
      if (response.status) {
        this.setState({ states: response.data });
        delay(1000).then(() => {
          if (this.props.model !== undefined && select) {
            let postalIDs = _.map(response.data, (state) => state.id);
            if (
              postalIDs.includes(Number(this.props.model.postalAddressStateId))
            ) {
              this.props.form.setFieldsValue({
                postalState: Number(this.props.model.postalAddressStateId),
              });
            }
            if (
              postalIDs.includes(
                Number(this.props.model.registeredAddressStateId)
              )
            ) {
              this.props.form.setFieldsValue({
                registeredState: Number(
                  this.props.model.registeredAddressStateId
                ),
              });
            }
          }
        });
      } else {
        errorDisplay(response?.data?.validationErrors);
      }

      return response;
    });
  };

  onAddressSelect = (addressObject, mode) => {
    return new Promise((resolve, rej) => {
      let address = filterGeoAddressLocation(addressObject);
      if (mode === "registered") {
        this.props.form.resetFields(["registeredState"]);

        this.props.form.setFieldsValue({
          registeredAddress1: address.address1,
          registeredSuburb: address.city,
          registeredPostCode: address.postalcode,
        });
        this.setState({ registeredAddress1: address.address1 });
        if (this.state.isSameAddress) {
          this.props.form.resetFields(["postalState"]);

          this.props.form.setFieldsValue({
            postalAddress1: address.address1,
            postalSuburb: address.city,
            postalPostCode: address.postalcode,
          });
        }
      } else if (mode === "postal") {
        this.setState({ postalAddress1: address.address1 });
        this.props.form.resetFields(["postalState"]);

        this.props.form.setFieldsValue({
          postalAddress1: address.address1,
          postalSuburb: address.city,
          postalPostCode: address.postalcode,
        });
      }

      this.updateLocationFields(
        address.country_code,
        "code",
        mode === "registered"
      ).then(() => {
        if (!_.isUndefined(address.state)) {
          const selectedState = this.state.states.find(
            (st) => st.code.toLowerCase() === address.state.toLowerCase()
          );
          if (mode === "postal") {
            this.props.form.setFieldsValue({
              postalState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });
          } else {
            this.props.form.setFieldsValue({
              registeredState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });

            if (this.state.isSameAddress) {
              this.props.form.setFieldsValue({
                postalState: conditionalParameter({
                  data: selectedState,
                  field: "id",
                }),
              });
            }
          }
        }
      });
      resolve(true);
    });
  };

  onSameAddressChange = (val) => {
    this.setState({
      isSameAddress: val,
    });
    if (val) {
      this.setState({ postalAddress1: this.state.registeredAddress1 });
      this.props.form.setFieldsValue({
        postalAddress2: this.props.form.getFieldValue("registeredAddress2"),
        postalSuburb: this.props.form.getFieldValue("registeredSuburb"),
        postalPostCode: this.props.form.getFieldValue("registeredPostCode"),
        postalState: this.props.form.getFieldValue("registeredState"),
      });
    }
  };

  handleSelectChange = ({ key, value }) => {
    this.props.form.setFieldsValue({
      key: value,
    });
  };

  useCompanyData = (value) => {
    this.setState({ isSameCompany: value });
    if (value) {
      this.props
        .getCompany({
          id: this.props.form.getFieldValue("company"),
          options: {},

          cancelToken: this.props.signal.token,
        })

        .then((resp) => {
          if (resp && resp.status && resp.data) {
            const company = resp.data;
            this.setState({
              postalAddress1: company.postalAddressLine1,
              registeredAddress1: company.registeredAddressLine1,
            });
            this.props.form.setFieldsValue({
              postalAddress2: company.postalAddressLine2,
              postalSuburb: company.postalSuburb,
              postalPostCode: company.postalPostalCode,
              postalState: Number(company.postalAddressStateId),
              registeredAddress2: company.registeredAddressLine2,
              registeredSuburb: company.registeredSuburb,
              registeredPostCode: company.registeredPostalCode,
              registeredState: Number(company.registeredAddressStateId),
              name: company.name,
              country: Number(company.countryId),
              registeredName: company.registeredBusinessName,
              operatingTimezone: company.operatingTimeZoneID,
              abn: company.abn,

              referenceCode: getOptionalParameter({
                value2: abbreviate(company.name),
                value1: this.props.form.getFieldValue("referenceCode"),
              }),
              referenceName: getConditionalResponse({
                condition: this.state.editMode,

                resp1: this.props.form.getFieldValue("referenceName"),
                resp2: company.name,
              }),
            });

            this.setState({
              disableField: true,
              operatingTimeZoneId: company.operatingTimeZoneID,
              isSameAddress: isAddressSame(resp.data),
            });
          } else {
            errorHandler({ response: resp, hasValidationErrors: true });
          }
        });
    } else {
      let companyName = this.props.form.getFieldValue("company");

      let payrollserviceprovider = this.props.form.getFieldValue(
        "payrollserviceprovider"
      );

      let bspId = this.props.form.getFieldValue("bspId");

      let bspAccountId = this.props.form.getFieldValue("bspAccountId");

      let currency = this.props.form.getFieldValue("currency");

      this.setState({
        disableField: false,
        isSameAddress: false,
        isSameCompany: false,
      });
      this.setState({ postalAddress1: "", registeredAddress1: "" });
      this.props.form.resetFields();

      this.props.form.setFieldsValue({ company: companyName });
      if (payrollserviceprovider) {
        this.props.form.setFieldsValue({ payrollserviceprovider });
      }
      if (bspId) {
        this.props.form.setFieldsValue({ bspId });
      }
      if (bspAccountId) {
        this.props.form.setFieldsValue({ bspAccountId });
      }
      if (currency) {
        this.props.form.setFieldsValue({ currency });
      }
    }
  };

  fetchPayrollProvider = (value) => {
    this.setState({ providers: [], fetching: true });

    this.props
      .payrollProviderLookup({ options: { name: value, per_page: 500 } })

      .then((response) => {
        if (response.status) {
          this.setState({ providers: response.data, fetching: false });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });
  };

  fetchCompany = (value) => {
    this.setState({ companyList: [], fetching: true });

    this.props
      .companyLookup({
        options: value
          ? { CompanyName: value, per_page: 500 }
          : { per_page: 500 },
      })

      .then((response) => {
        if (response.status) {
          this.setState({ companyList: response.data, fetching: false });
        } else {
          this.setState({ fetching: false });

          errorDisplay(response?.data?.validationErrors);
        }
      });
  };

  handleChangeCompany = (value) => {
    this.props

      .getCompany({ id: value, cancelToken: this.props.signal.token })

      .then((response) => {
        if (response.status) {
          this.setState({
            company: response.data,
            companyId: value,
            fetching: false,
          });

          if (this.props.form.getFieldValue("sameBusinessUnitCompany")) {
            this.props.form.resetFields([
              "postalAddress2",
              "postalSuburb",
              "postalPostCode",
              "postalState",
              "registeredAddress2",
              "registeredSuburb",
              "registeredPostCode",
              "registeredState",
              "name",
              "country",
              "registeredName",
              "operatingTimeZone",
              "abn",
            ]);

            this.setState({
              disableField: false,
              operatingTimeZoneId: "",
              isSameAddress: false,
              isSameCompany: false,
              registeredAddress1: "",
              postalAddress1: "",
            });
          }
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });
  };

  addExpectedProcess = (e) => {
    this.setState({ expectedProcess: e.target.value });
  };

  toggleSwitch = (_checked, system) => {
    this.setState((prevState) => {
      let found = prevState.processingSystem.find((ps) => ps.name === system);
      found.checked = !found.checked;
      return prevState;
    });
  };

  toggleSystems = () => {
    this.setState((prevState) => {
      _.each(prevState.processingSystem, (ps) => {
        ps.checked = false;
      });
      prevState.checkedProcessing = !prevState.checkedProcessing;
      return prevState;
    });
  };

  onChangeCountry = () => {
    this.props.form.resetFields([
      "postalState",
      "registeredState",
      "postalSuburb",
      "postalPostCode",
      "registeredSuburb",
      "registeredPostCode",
      "registeredAddress1",
      "registeredAddress2",
      "postalAddress1",
      "postalAddress2",
      "abn",
      "operatingTimezone",
    ]);

    this.setState({
      isSameAddress: false,
      postalAddress1: "",
      registeredAddress1: "",
    });
  };

  handleChangeBSP = (value, notReset) => {
    if (!notReset) {
      this.setState({ accountList: [] });

      this.props.form.resetFields(["bspAccountId", "convertTo"]);
    }

    this.props
      .listBillingAccounts({ options: { per_page: 500 }, id: value })

      .then((response) => {
        if (response.status && response.data) {
          this.setState({ accountList: response.data });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });
  };

  handleChangeAccount = (value) => {
    const { accountList } = this.state;
    let convertToCurrency = accountList.find((o) => o.id === value)?.currency;
    delay(100).then(() => {
      this.props.form.setFieldsValue({ convertTo: convertToCurrency });

      if (!this.props.form.getFieldValue("convertFrom")) {
        let countrySelected = mustBeArray(defaultCurrencyList).find(
          (o) =>
            o.countryID ===
            Number(this.props.form.getFieldValue("addressCountryID"))
        );
        if (countrySelected) {
          this.props.form.setFieldsValue({ convertFrom: countrySelected.code });
        }
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = this.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!this.state.registeredAddress1) {
        return message.error("Please enter physical address line 1.");
      }
      if (!this.state.postalAddress1) {
        return message.error("Please enter postal address line 1.");
      }
      switch (Number(this.state.activeIndex)) {
        case 1:
          let finalValues = {
            companyName: values.name,
            companyId: values.company,
            operatingTimeZone: values.operatingTimezone,
            registeredBusinessName: values.registeredName,
            abn: values.abn ? Number(values.abn) : "",
            address: {
              address1: this.state.registeredAddress1,
              address2: values.registeredAddress2 || "NA",
              suburb: values.registeredSuburb,
              state: values.registeredState.toString(),
              postCode: values.registeredPostCode,
              isActive: true,
            },
            postalAddress: {
              address1: this.state.postalAddress1,
              address2: values.postalAddress2 || "NA",
              suburb: values.postalSuburb,
              state: values.postalState.toString(),
              postCode: values.postalPostCode,
              isActive: true,
            },
            country: values.country,
            isActive: true,
            isBusinessUnitSameAsCompany: values.sameBusinessUnitCompany,
          };

          let country = this.props.form.getFieldValue("country");

          let abn = this.props.form.getFieldValue("abn");

          if (!this.props.form.getFieldValue("name")?.trim()) {
            return message.error("Please enter business unit name");
          }

          if (!this.props.form.getFieldValue("registeredName")?.trim()) {
            return message.error("Please enter registered business unit name");
          }
          if (country === 13 && isNullEmptyUndefined(abn)) {
            return message.error("ABN is required for Australia");
          }

          this.setState({
            finalValues: { ...this.state.finalValues, ...finalValues },
            activeIndex: 2,
            submitLoading: false,
          });
          break;
        case 2:
          this.setState({ submitLoading: true });

          let convertTo = this.getCurrencyDetail({
            value: values.convertTo,
            label: "code",
          });

          let convertFrom = this.getCurrencyDetail({
            value: values.convertFrom,
            label: "code",
          });

          this.setState(
            (prevState) => {
              prevState.finalValues["invoicePrefixCode"] = values.referenceCode;
              // prevState.finalValues["payrollServiceProviderId"] =
              //   values.payrollserviceprovider;
              prevState.finalValues["billingCurrencyID"] = values.currency;
              prevState.finalValues["billingServiceProviderID"] = values.bspId;
              prevState.finalValues["billingServiceProviderAccountID"] =
                values.bspAccountId;
              prevState.finalValues["isInvoiceNeeded"] =
                Number(values.isInvoiceNeeded) === 1;
              prevState.finalValues["paymentMandatory"] =
                Number(values.paymentMandatory) === 1;
              prevState.finalValues["externalBusinessUnitId"] =
                values.externalBusinessUnitId;
              prevState.finalValues["payrollSystemConnectionId"] =
                values.payrollSystemConnectionId;
              prevState.finalValues["addressCountryID"] = values.country;
              prevState.finalValues["country"] = values.addressCountryID;

              prevState.finalValues["isInvoiceCompany"] =
                this.state.isInvoiceCompany;
              prevState.finalValues["convertFrom"] = convertFrom?.id;
              prevState.finalValues["convertTo"] = convertTo && convertTo.id;
              prevState.finalValues["fixedConversionRate"] =
                values.fixedConversionRate ? values.fixedConversionRate : 0;
              prevState.finalValues["percentConversionRate"] =
                values.percentConversionRate ? values.percentConversionRate : 0;
              prevState.finalValues["accountingSystem"] =
                values.accountingSystem;
              prevState.finalValues["referenceName"] = values.referenceName;
              prevState.finalValues["assignedUserID"] =
                values.payrollManager || null;
              if (!convertFrom && !convertTo) {
                prevState.finalValues["convertFrom"] = 0;
                prevState.finalValues["convertTo"] = 0;
              }
              return prevState;
            },
            () => {
              if (this.state.editMode) {
                let payload = {
                  ...this.state.finalValues,
                  id: this.props.match?.params?.businessunitid,
                };

                this.props.updateBusinessUnit(payload).then((response) => {
                  this.setState({ submitLoading: false });
                  if (response.status) {
                    this.setState({
                      disableField: false,
                      isSameAddress: false,
                      isSameCompany: false,
                    });
                    form.resetFields();
                    message.success("Business Unit updated successfully");
                    this.props.router.navigate(-1);
                  } else {
                    errorDisplay(response && response.data, true);
                  }
                });
              } else {
                this.props
                  .saveBusinessUnit(this.state.finalValues)
                  .then((response) => {
                    this.setState({ submitLoading: false });
                    const actionOneLink = getBusinessUnitDetailRoute({
                      id: response.data?.result,
                      companyId: this.state.finalValues?.companyId,
                    });
                    if (response.status) {
                      this.setState({
                        disableField: false,
                        isSameAddress: false,
                        isSameCompany: false,
                        actionOneLink,
                        actionTwoLink: getPayScheduleCreateRoute({
                          companyId: this.state.finalValues?.companyId,
                          businessUnitId: response.data.result,
                        }),
                        newBusinessUnitId: response.data,
                      });
                    } else {
                      errorDisplay(response && response.data, true);
                    }
                  });
              }
            }
          );
          break;
        default:
          break;
      }
    });
  };

  onCancel = () => {
    this.setState({
      disableField: false,
      isSameAddress: false,
      isSameCompany: false,
    });

    this.props.form.resetFields(["registeredAddress", "postalAddress"]);

    this.props.form.resetFields();
    this.props.router.navigate("/businessunit");
  };

  fetchAccount = () => {
    this.props
      .listBillingAccounts({
        options: { per_page: 500 },

        id: this.props.form.getFieldValue("bspId"),
      })

      .then((response) => {
        if (response.status && response.data) {
          this.setState({ accountList: response.data });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });
  };

  fetchBSP = () => {
    this.props
      .listBillingServiceProviders({ options: { per_page: 500 } })
      .then((response) => {
        if (response.status && response.data) {
          this.setState({ bspList: response.data });
        } else {
          errorDisplay(response?.data?.validationErrors);
        }
      });
  };

  updateReferenceCode = (value) => {
    if (!this.state.editMode) {
      this.props.form.setFieldsValue({ referenceName: value });
    }
    if (!value) {
      return this.props.form.resetFields(["referenceCode"]);
    }
    if (value && value.length <= 3) {
      this.props.form.setFieldsValue({ referenceCode: abbreviate(value) });
    }
  };

  onAddressChange = (value, type) => {
    if (type === "registered") {
      this.setState({ registeredAddress1: value });
      if (this.state.isSameAddress) {
        this.setState({ postalAddress1: value });
      }
    } else {
      this.setState({ postalAddress1: value });
    }
  };

  populateSameAddressField = (value, field) => {
    if (field === "registeredAddress1") {
      this.setState({ postalAddress1: value });
    } else if (this.state.isSameAddress) {
      this.props.form.setFieldsValue({ [field]: value });
    }
  };

  fetchBaseSalaryList = (externalBusinessUnitId, payollSystemConnectionId) => {
    const payload = {
      payrollSystemConnectionId: payollSystemConnectionId,
      externalBusinessUnitId: externalBusinessUnitId,
    };

    const { match } = this.props;
    if (match && match.params && match.params.businessunitid) {
      return this.props
        .listAmendmentTypes({
          options: {},
          businessUnitID: match.params.businessunitid,
          lookUp: true,
        })
        .then((resp) => {
          return resp;
        });
    }

    return this.props
      .externalPayCategoryLookup({ options: {}, payload })
      .then((resp) => {
        return resp;
      });
  };

  handleBack = () => {
    this.setState({ activeIndex: Number(this.state.activeIndex) - 1 });
  };

  checkSubmitDisabled = () => {
    if (Number(this.state.activeIndex) !== 2) return true;
    if (
      !this.props.form.getFieldValue("externalBusinessUnitId") ||
      !this.props.form.getFieldValue("referenceCode") ||
      !this.props.form.getFieldValue("bspId") ||
      !this.props.form.getFieldValue("bspAccountId") ||
      !this.props.form.getFieldValue("payrollserviceprovider") ||
      !this.props.form.getFieldValue("payrollSystemConnectionId")
    ) {
      return true;
    }
    return false;
  };

  handleChangePayrollProvider = (value) => {
    this.props.form.resetFields([
      "payrollSystemConnectionId",
      "externalBusinessUnitId",
      "payrollManager",
    ]);

    this.setState({
      fetchingPayrollConnection: true,
      defaultPayrollConnection: [],
      defaultExternalBusinessUnitList: [],
    });
    this.listServiceProviderUsers({ id: value });
    return this.props
      .fetchPayrollConnectionName({
        id: value,
        options: {},
      })
      .then((resp) => {
        this.setState({ fetchingPayrollConnection: false });

        if (resp.status) {
          this.setState({ defaultPayrollConnection: resp.data });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }

        return resp;
      });
  };

  updateIsInvoiceCompany = (isInvoiceCompany) => {
    this.setState({ isInvoiceCompany });
  };

  handleChangeServiceCountry = (value) => {
    // Get the country data based on user selection
    const countrySelected = mustBeArray(defaultCurrencyList).find(
      (o) => o.countryID === Number(value)
    );
    //Reset the bsp, account, and conversion fields and associated saved state
    this.setState({ accountList: [], bspList: [] });
    this.props.form.resetFields(["bspAccountId", "convertTo", "bspId"]);
    // Fetch a new list of BSPs for the new country
    this.props
      .listBillingServiceProviders({
        options: { q: { serviceCountryId: value } },
      })
      .then((resp) => {
        if (resp.status && resp.data) {
          this.setState({ bspList: resp.data });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
    // update the conversion field for new country currency
    if (countrySelected) {
      delay(100).then(() => {
        this.props.form.setFieldsValue({ convertFrom: countrySelected.code });
        if (!this.props.form.getFieldValue("convertTo")) {
          this.handleChangeAccount(
            this.props.form.getFieldValue("bspAccountId")
          );
        }
      });
    }
  };

  getCurrencyDetail = ({ label, value }) => {
    return defaultCurrencyList.find((o) => o[label] === value);
  };

  render() {
    const { form, payrollprovider, member } = this.props;
    const {
      providers,
      fetchingPayroll,
      companyList,
      disableField,
      editMode,
      disablePayroll,
      activeIndex,
      states,
      timezone,
      isSameCompany,
      fetchingCompany,
      bspList,
      accountList,
      currencyList,
      defaultPayrollConnection,
      defaultExternalBusinessUnitList,
      isSameAddress,
      countries,
      submitLoading,
      fetchingExternalBusinessUnit,
      fetchingPayrollConnection,
      isInvoiceCompany,
      disableCompany,
      formLabels,
      newBusinessUnitId,
      actionOneLink,
      actionTwoLink,
      pspUsersList,
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off">
        <Layout className="h-[100vh]">
          <If
            condition={newBusinessUnitId}
            then={
              <NoRecord>
                <CreateSuccess
                  page="Business Unit"
                  actionOneLabel="View Business Unit"
                  actionOneLink={actionOneLink}
                  actionTwoLabel="Create Pay Schedule"
                  actionTwoLink={actionTwoLink}
                />
              </NoRecord>
            }
            else={
              <React.Fragment>
                <Header className="bg-white w-full position-absolute-top">
                  <FormNavbar
                    title="Business Unit"
                    handleCancel={this.onCancel}
                    handleSave={this.handleSubmit}
                    htmlType="submit"
                    loading={submitLoading}
                    disabled={this.checkSubmitDisabled()}
                    enableBack={true}
                  />
                </Header>
                <Layout className="p-6 rounded-[5px] create-screen-body defaultFormMargin overflow-y-auto w-full">
                  <Content>
                    <Row gutter={DEFAULT_GUTTER} className="form-label-left">
                      <FormStepsCol
                        data={formLabels}
                        activeIndex={activeIndex}
                      />

                      <Col md={18} sm={24}>
                        {
                          <div
                            className={
                              Number(activeIndex) === 1
                                ? "block"
                                : "display-none"
                            }
                          >
                            <Card>
                              <BasicDetailBusinessUnitForm
                                form={form}
                                companyList={companyList}
                                disableField={disableField}
                                fetchingCompany={fetchingCompany}
                                fetchCompany={this.fetchCompany}
                                handleChangeCompany={this.handleChangeCompany}
                                useCompanyData={this.useCompanyData}
                                isSameCompany={isSameCompany}
                                updateReferenceCode={this.updateReferenceCode}
                                populateStates={this.populateStates}
                                onChangeCountry={this.onChangeCountry}
                                timezone={timezone}
                                onSameAddressChange={this.onSameAddressChange}
                                isSameAddress={isSameAddress}
                                onAddressSelect={this.onAddressSelect}
                                populateSameAddressField={
                                  this.populateSameAddressField
                                }
                                states={states}
                                editMode={editMode}
                                countries={countries}
                                activeIndex={activeIndex}
                                disableCompany={disableCompany}
                                registeredAddress1={
                                  this.state.registeredAddress1
                                }
                                postalAddress1={this.state.postalAddress1}
                                onAddressChange={this.onAddressChange}
                              />
                              <div>
                                <Button
                                  htmlType="submit"
                                  className="bg-success float-right rounded-[20px]"
                                  loading={submitLoading}
                                >
                                  Continue
                                </Button>
                                <div className="clearfix"></div>
                              </div>
                            </Card>
                          </div>
                        }
                        {
                          <div
                            className={
                              Number(activeIndex) === 2
                                ? "block"
                                : "display-none"
                            }
                          >
                            <Card>
                              <AccountDetailsBusinessUnitForm
                                form={form}
                                handleChangeBSP={this.handleChangeBSP}
                                bspList={bspList}
                                fetchBSP={this.fetchBSP}
                                fetchAccount={this.fetchAccount}
                                accountList={accountList}
                                currencyList={currencyList}
                                payrollprovider={payrollprovider}
                                fetchPayrollProvider={this.fetchPayrollProvider}
                                fetchingPayroll={fetchingPayroll}
                                fetchBaseSalaryList={this.fetchBaseSalaryList}
                                providers={providers}
                                activeIndex={activeIndex}
                                disablePayroll={disablePayroll}
                                handleChangePayrollProvider={
                                  this.handleChangePayrollProvider
                                }
                                defaultPayrollConnection={
                                  defaultPayrollConnection
                                }
                                disableExternalBusinessunit={
                                  this.state.editMode
                                }
                                handleChangeConnection={
                                  this.handleChangeConnection
                                }
                                defaultExternalBusinessUnitList={
                                  defaultExternalBusinessUnitList
                                }
                                fetchingPayrollConnection={
                                  fetchingPayrollConnection
                                }
                                fetchingExternalBusinessUnit={
                                  fetchingExternalBusinessUnit
                                }
                                isInvoiceCompany={isInvoiceCompany}
                                updateIsInvoiceCompany={
                                  this.updateIsInvoiceCompany
                                }
                                handleChangeServiceCountry={
                                  this.handleChangeServiceCountry
                                }
                                handleChangeAccount={this.handleChangeAccount}
                                pspUsersList={mustBeArray(pspUsersList)}
                              />
                              <div>
                                <Button
                                  onClick={this.handleBack}
                                  className="rounded-[20px]"
                                >
                                  Back
                                </Button>
                                <div className="clearfix"></div>
                              </div>
                            </Card>
                          </div>
                        }
                      </Col>
                    </Row>
                  </Content>
                </Layout>
              </React.Fragment>
            }
          />
        </Layout>
      </Form>
    );
  }
}

export default withRouter(
  Form.create({ name: "businessunitForm" })(CreateBusinessUnit)
);
