import axios from 'axios';

import { useAuth } from '@xemplo/auth-provider';
import { CustomAxios } from '@xemplo/custom-axios';

const defaultTimeout = 30000;

export type HttpClientOptions = {
  timeout: number;
};

export const useHttpClient = (
  { timeout }: HttpClientOptions = { timeout: defaultTimeout }
) => {
  const { user } = useAuth();
  const client = new CustomAxios({ timeout, authToken: user?.access_token }).client();
  return { client, cancelSignal: axios.CancelToken.source(), user };
};
