import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AmendmentTypes } from '@xemplo/gp-types';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import { PLACEHOLDER_OPTION, TestIds } from '../../../create-amendment.helper';
import * as S from '../../modal.styles';

export function ChangeType() {
  const { register, watch } = useFormContext();

  const watchChangeType = watch('changeType');

  useEffect(() => {
    register('changeType', {
      required: 'This field is required',
      valueAsNumber: true,
      validate: (value) => {
        if (value === PLACEHOLDER_OPTION) {
          return 'This field is required';
        }
        return true;
      },
    });
  }, [register]);

  const getItems = () => {
    if (watchChangeType) {
      return changeTypes;
    }
    return [{ key: PLACEHOLDER_OPTION, value: 'Select a type' }, ...changeTypes];
  };

  return (
    <S.FormLabel>
      Change type
      <TextDropdownMultiV2
        name="changeType"
        testId={TestIds.changeType}
        defaultSelectedKeys={watchChangeType && [watchChangeType]}
        items={getItems()}
        isDropdownMulti={false}
        selectProps={{
          label: 'Change type',
          placeholder: 'Select type',
        }}
      />
    </S.FormLabel>
  );
}

const changeTypes = [
  {
    key: AmendmentTypes.PAYRUN,
    value: 'Payrun',
  },
  {
    key: AmendmentTypes.PERMANENT,
    value: 'Permanent',
  },
  {
    key: AmendmentTypes.RECURRING,
    value: 'Recurring',
  },
];
