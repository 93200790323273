export default function amendmentReducer(state: any, action: any) {
  const { data, total } = action;

  switch (action.type) {
    case "LIST_PAYRUN_AMENDMENTS": {
      return {
        ...state,
        amendment: {
          list: data,
          count: total,
        },
      };
    }

    case "LIST_PERMANENT_AMENDMENTS": {
      return {
        ...state,
        adhocAmendment: {
          list: data,
          count: total,
        },
      };
    }

    case "LIST_REOCCURRING_AMENDMENTS": {
      return {
        ...state,
        reoccurringAmendment: {
          list: data,
          count: total,
        },
      };
    }
    default:
      return state;
  }
}
