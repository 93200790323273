import classNames from "classnames";
import { isNaN } from "lodash";
import { If } from "../../../Common/ui";
import { MAX_VARIANCE_PERIODS } from "../../../constants/variance";
import {
  calculateVarianceAndSD,
  getVarianceColWidth,
  getVarianceSDColWidth,
} from "../../../libs";

type Props = {
  totalAvailablePeriod: number;
  record: any;
  field: any;
  feeIndex: any;
  data: any;
  showTax?: boolean;
  getAmount: any;
  labelClassName: string;
};
const VarianceFees = ({
  totalAvailablePeriod,
  record,
  field = "",
  feeIndex,
  data,
  showTax,
  getAmount,
  labelClassName,
}: Props) => {
  return (
    <If
      condition={!isNaN(totalAvailablePeriod)}
      then={
        <div className="flex">
          <div className={classNames(labelClassName, "pl-12")}>
            <div>{data?.feeOptionDescription}</div>
            <If
              condition={!!showTax}
              then={<div className="text-xs font-normal pl-3">Tax</div>}
            />
          </div>
          {MAX_VARIANCE_PERIODS.map((value, index) => {
            return (
              <If
                key={index}
                condition={totalAvailablePeriod >= value}
                then={getAmount({
                  field: "",
                  record,
                  position: value - 1,
                  feeOptionName: data?.feeOptionName,
                  feeIndex,
                  type: "feeOptionName",
                })}
              />
            );
          })}
          <div
            className={`flex-1-1-${getVarianceColWidth({
              totalPeriod: totalAvailablePeriod,
            })} text-right`}
          >
            {
              calculateVarianceAndSD({
                title: data?.feeOptionId,
                field,
                record,
                type: "fees",
              }).variance
            }
          </div>
          <div
            className={`flex-1-1-${getVarianceSDColWidth({
              totalPeriod: totalAvailablePeriod,
            })} text-right`}
          >
            {
              calculateVarianceAndSD({
                title: data?.feeOptionId,
                field,
                record,
                type: "fees",
              }).sd
            }
          </div>
        </div>
      }
    />
  );
};

export default VarianceFees;
