import { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounceCallback } from '@xemplo/hooks';
import { IconButtonSize } from '@xemplo/icon-button';
import { MaxBreakpoint, MinBreakpoint } from '@xemplo/style-constants';

import { ModalBodyOverflow } from '../body/body.types';
import { ModalState } from '../modal.types';

export const useResizeListener = (state: ModalState) => {
  const { open, body } = state;
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);
  const [contentOverflow, setContentOverflow] = useState(true);
  const [closeBtnDimension, setCloseBtnDimension] = useState({
    size: IconButtonSize.Small,
    marginTop: 10,
  });

  const setStyling = useCallback(() => {
    const titleHeight = titleRef?.current?.clientHeight;
    const titleOverflow = titleHeight ? titleHeight >= 48 : false;
    const isLargeView = window.matchMedia(MinBreakpoint.medium).matches;
    const marginTopLargeView = isLargeView ? 10 : 20;

    setCloseBtnDimension({
      size: isLargeView ? IconButtonSize.Small : IconButtonSize.Medium,
      marginTop: titleOverflow ? 28 : marginTopLargeView,
    });

    const dialog = contentRef.current;
    if (!dialog || !body) return setContentOverflow(false);

    const contentHeight = dialog.clientHeight || 0;
    const isMobile = window.matchMedia(MaxBreakpoint.small).matches;
    const isStandard = body?.overflow === ModalBodyOverflow.Standard;
    if (isMobile && isStandard) return setContentOverflow(false);

    const margin = isMobile ? 17 : 48;
    const willOverflow = contentHeight + margin * 2 >= window.innerHeight;
    setContentOverflow(willOverflow);
  }, [body]);

  const debouncedSetStyling = useDebounceCallback(setStyling, 200);

  useEffect(() => {
    if (open) {
      setTimeout(() => void setStyling(), 0);
      window.addEventListener('resize', debouncedSetStyling);
    } else {
      window.removeEventListener('resize', debouncedSetStyling);
    }
    return () => window.removeEventListener('resize', debouncedSetStyling);
    // TODO: Fix this lint error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, body?.content, setStyling, contentRef]);

  return {
    bodyRef,
    headerRef,
    containerRef,
    footerRef,
    contentOverflow,
    contentRef,
    titleRef,
    closeBtnDimension,
  };
};
