//@ts-nocheck
import { Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  displayDates,
  getPayrunStages,
  nullFunction,
  getConditionalResponse,
  getPayrunDueDays,
} from "../../../libs";

const PayrunSingleLineGridDisplay = (props: any) => {
  const navigate = useNavigate();
  const { content } = props;
  const dueDates = getPayrunDueDays({ payrun: content });
  return content ? (
    <Card
      className="bg-default hand rounded-[12px]"
      onClick={() => navigate(`/payrun/${content.payrunId}`)}
    >
      <div className="flex justify-between">
        <div className="flex-1-1">
          <div className="text-[15px] font-bold">{content.name}</div>
          <div className="color-disabled text-sm">{content.payrunTypeName}</div>
        </div>
        <div className="text-sm flex-1-1">
          <div>
            <span className="font-bold">Pay Date: </span>
            <span>{displayDates({ value: content.payDate })}</span>
          </div>
          <div>
            <span className="font-bold">Amendments: </span>
            <span
              className={getConditionalResponse({
                condition: content.ammendmentCount,
                resp1: "color-primary hand",
                resp2: "",
              })}
              onClick={(event) => {
                content.ammendmentCount
                  ? props.handleGridAction({
                      event,
                      data: content,
                      action: "viewAmendmentSummary",
                    })
                  : nullFunction();
              }}
            >
              {content.ammendmentCount}
            </span>
          </div>
        </div>
        <div className="text-right text-sm flex-1-1">
          <div>{getPayrunStages({ payrun: content })}</div>
          {dueDates && dueDates.days && (
            <div className="font-bold text-[15px]">
              {`${
                Number(dueDates.days) === 0
                  ? "Due today"
                  : dueDates.days < 0
                  ? "Overdue"
                  : getConditionalResponse({
                      condition: Number(dueDates.days) === 1,
                      resp1: "1 day",
                      resp2: `${dueDates.days} days`,
                    })
              }`}
            </div>
          )}
          {dueDates && dueDates.date && (
            <div className="font-bold text-[13px] color-disabled">
              {dueDates.date}
            </div>
          )}
        </div>
      </div>
    </Card>
  ) : (
    <React.Fragment></React.Fragment>
  );
};
export default PayrunSingleLineGridDisplay;
