//@ts-nocheck
import React, { Component } from "react";
import CreateBasicDetailForm from "../../../Common/createBasicDetailForm";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Layout, message, Card, Row, Col, Input, Radio } from "antd";
import NoRecord from "../../../Common/NoRecord";
import CreateSuccess from "../../../Common/CreateSuccess";
import {
  errorDisplay,
  delay,
  mustBeArray,
  filterGeoAddressLocation,
  isAddressSame,
  errorHandler,
  conditionalParameter,
  getConditionalResponse,
  getOptionalParameter,
  getMatchParams,
  validateUsersAccessRights,
  findAuthorizationClaims,
} from "../../../libs/";
import _ from "lodash";
import {
  AUSTRALIA_COUNTRY_ID,
  GOOGLE_MAP_URL,
} from "../../../constants/entities";
import axios from "axios";
import FormNavbar from "../../../Layout/FormNavbar";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { FormStepsCol } from "../../../Common/ui";
import { COMPANY_CREATE_SIMPLE_BUTTON } from "../../../constants/authorizationClaims";
import { withRouter } from "../../../hooks";
import { DEFAULT_GUID } from "../../../constants";
import { CompanyInstancesLookup } from "../../components/company";
import { connect } from "react-redux";

const { Header, Content } = Layout;

type State = any;
class CreateCompany extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);

    this.state = {
      isSameAddress: false,
      disableField: false,
      fetching: false,
      confirmLoading: false,
      states: [],
      countries: [],
      timezone: [],
      editMode: false,
      newCompanyId: "",
      activeIndex: "1",
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        },
      ],
      mapLoaded: false,
      selectedCountryTimeZone: [],
      unSelectedCountryTimeZone: [],
      registeredAddress1: "",
      postalAddress1: "",
      directoryServiceCompanyId: null,
    };
    this.fetchCountries = _.debounce(this.fetchCountries);
    this.fetchTimezone = _.debounce(this.fetchTimezone);
  }
  componentWillUnmount() {
    this.unloadGoogleMaps();
  }
  loadGoogleMaps = (callBack) => {
    const existingScript = document.getElementById("googlePlacesScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAP_URL;
      script.id = "googlePlacesScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callBack) callBack();
      };
    }
    if (existingScript && callBack) callBack();
  };
  unloadGoogleMaps = () => {
    let googlePlacesScript = document.getElementById("googlePlacesScript");
    if (googlePlacesScript) {
      googlePlacesScript.remove();
    }
  };
  componentDidMount() {
    const { match, pageClaims } = this.props;
    // Check if user has access to create company. If not redirect to home.
    const createButton = findAuthorizationClaims({
      claims: pageClaims.components,
      name: COMPANY_CREATE_SIMPLE_BUTTON,
    });
    if (!validateUsersAccessRights({ claim: createButton })) return;

    const editMode = !!match?.params?.id;
    this.loadGoogleMaps(() => {
      this.setState({ mapLoaded: true });
    });
    this.fetchTimezone();
    if (editMode) {
      this.props
        .getCompany({ id: match.params.id, cancelToken: this.signal.token })
        .then((resp) => {
          if (resp && resp.status && resp.data) {
            this.props.updateHeader({
              header: {
                enableBack: true,
                title: `${resp.data.name}`,
              },
            });

            const company = resp.data;
            const disablePSP =
              company.payrollServiceProviderId !== DEFAULT_GUID;
            this.fetchCountries(resp.data.countryId);
            this.props.form.setFieldsValue({
              postalAddress2: company.postalAddressLine2,
              postalSuburb: company.postalSuburb,
              postalPostCode: company.postalPostalCode,
              postalState: Number(company.postalAddressStateId),
              registeredAddress2: company.registeredAddressLine2,
              registeredSuburb: company.registeredSuburb,
              registeredPostCode: company.registeredPostalCode,
              registeredState: Number(company.registeredAddressStateId),
              name: company.name,
              country: Number(company.countryId),
              registeredName: company.registeredBusinessName,
              abn: company.abn,
              //If the company already has a payroll service provider, prefill and disable the field
              payrollServiceProvider:
                company.payrollServiceProviderId === DEFAULT_GUID
                  ? null
                  : company.payrollServiceProviderId,
            });
            this.setState({
              postalAddress1: resp.data.postalAddressLine1,
              registeredAddress1: resp.data.registeredAddressLine1,
              isSameAddress: isAddressSame(resp.data),
              editMode,
              disablePSP,
              directoryServiceCompanyId: resp.data.directoryServiceCompanyId,
            });
            delay(300).then(() => {
              this.props.form.setFieldsValue({
                operatingTimezone: company.operatingTimeZoneID,
                directoryServiceCompanyId: company.directoryServiceCompanyId,
              });
            });
          } else {
            errorHandler({ response: resp, hasValidationErrors: true });
          }
        });
    } else {
      this.fetchCountries("");
    }
  }

  fetchCountries = (val) => {
    this.props.countriesLookup({ options: { name: val } }).then((response) => {
      if (response.status) {
        this.setState({ countries: response.data }, () => {
          const defaultCountryID = val ? val : AUSTRALIA_COUNTRY_ID;
          this.updateLocationFields(Number(defaultCountryID), "id");
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }
    });
  };

  updateLocationFields = (defaultCountryID, key, registeredAddressSelect) => {
    return new Promise((resolve, rej) => {
      const selectedCountry = _.find(
        this.state.countries,
        (country) => country[key] === defaultCountryID
      );
      if (selectedCountry && selectedCountry.id) {
        if (
          registeredAddressSelect &&
          selectedCountry.id !== this.props.form.getFieldValue("country")
        ) {
          this.props.form.resetFields(["abn", "operatingTimezone"]);
        }

        this.props.form.setFieldsValue({ country: selectedCountry.id });

        this.populateStates(selectedCountry.id, true).then((res) => {
          if (res.status) {
            resolve(true);
          }
        });
      } else {
        this.props.form.resetFields(["country"]);
      }
    });
  };

  fetchTimezone = () => {
    this.props.timezoneLookup({ options: {} }).then((resp) => {
      let timezone = mustBeArray(resp && resp.data);
      this.setState({ timezone }, () => {
        if (!_.isUndefined(this.props.model)) {
          this.props.form.setFieldsValue({
            operatingTimezone: this.props.model.operatingTimeZoneID,
          });
        }
      });
    });
  };

  populateStates = (val, select) => {
    let params = {};
    params.countryId = val;

    return this.props.populateStates({ options: params }).then((response) => {
      if (response.status) {
        this.setState({ states: response.data });
        delay(1000).then(() => {
          if (!_.isUndefined(this.props.model) && select) {
            let postalIDs = _.map(response.data, (state) => state.id);
            if (
              postalIDs.includes(Number(this.props.model.postalAddressStateId))
            ) {
              this.props.form.setFieldsValue({
                postalState: Number(this.props.model.postalAddressStateId),
              });
            }
            if (
              postalIDs.includes(
                Number(this.props.model.registeredAddressStateId)
              )
            ) {
              this.props.form.setFieldsValue({
                registeredState: Number(
                  this.props.model.registeredAddressStateId
                ),
              });
            }
          }
        });
      } else {
        errorDisplay(
          response && response.data && response.data.validationErrors
        );
      }

      return response;
    });
  };

  onAddressSelect = (addressObject, mode) => {
    return new Promise((resolve, rej) => {
      var address = filterGeoAddressLocation(addressObject);
      if (mode === "registered") {
        this.props.form.setFieldsValue({
          registeredSuburb: address.city,
          registeredPostCode: address.postalcode,
        });
        this.setState({ registeredAddress1: address.address1 });
        if (this.state.isSameAddress) {
          this.props.form.setFieldsValue({
            postalSuburb: address.city,
            postalPostCode: address.postalcode,
          });
          this.setState({ postalAddress1: address.address1 });
        }
      } else if (mode === "postal") {
        this.setState({ postalAddress1: address.address1 });
        this.props.form.setFieldsValue({
          postalAddress1: address.address1,
          postalSuburb: address.city,
          postalPostCode: address.postalcode,
        });
      }

      this.updateLocationFields(
        address.country_code,
        "code",
        mode === "registered"
      ).then((res) => {
        if (!_.isUndefined(address.state)) {
          const selectedState = _.find(
            this.state.states,
            (st) => st.code.toLowerCase() === address.state.toLowerCase()
          );
          if (mode === "postal") {
            this.props.form.setFieldsValue({
              postalState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });
          } else {
            this.props.form.setFieldsValue({
              registeredState: conditionalParameter({
                data: selectedState,
                field: "id",
              }),
            });

            if (this.state.isSameAddress) {
              this.props.form.setFieldsValue({
                postalState: conditionalParameter({
                  data: selectedState,
                  field: "id",
                }),
              });
            }
          }
        }
      });
      resolve(true);
    });
  };

  onSameAddressChange = (val) => {
    this.setState({
      isSameAddress: val,
    });
    if (val) {
      this.setState({ postalAddress1: this.state.registeredAddress1 });
      this.props.form.setFieldsValue({
        postalAddress2: this.props.form.getFieldValue("registeredAddress2"),
        postalSuburb: this.props.form.getFieldValue("registeredSuburb"),
        postalPostCode: this.props.form.getFieldValue("registeredPostCode"),
        postalState: this.props.form.getFieldValue("registeredState"),
      });
    }
  };

  onAddressChange = (value, type) => {
    if (type === "registered") {
      this.setState({ registeredAddress1: value });
      if (this.state.isSameAddress) {
        this.setState({ postalAddress1: value });
      }
    } else {
      this.setState({ postalAddress1: value });
    }
  };

  handleSelectChange = ({ key, value }) => {
    this.props.form.setFieldsValue({
      key: value,
    });
  };

  handleSubmit = () => {
    const { form, match } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!this.state.registeredAddress1) {
        return message.error("Please enter physical address line 1.");
      }
      if (!this.state.postalAddress1) {
        return message.error("Please enter postal address line 1.");
      }
      if (
        values &&
        values.country === AUSTRALIA_COUNTRY_ID &&
        (values.abn === null || values.abn === "" || values.abn === undefined)
      ) {
        return message.error("ABN is required for Australia");
      }
      this.setState({ confirmLoading: true });
      let finalValues = {
        companyName: values.name,
        operatingTimeZone: values.operatingTimezone,
        registeredBusinessName: values.registeredName,
        abn: getConditionalResponse({
          condition: values.abn,
          resp1: Number(values.abn),
          resp2: "",
        }),
        address: {
          address1: this.state.registeredAddress1,
          address2: getOptionalParameter({
            value1: values.registeredAddress2,
            value2: "NA",
          }),
          suburb: values.registeredSuburb,
          state: values.registeredState.toString(),
          postCode: values.registeredPostCode,
          isActive: true,
        },
        postalAddress: {
          address1: this.state.postalAddress1,
          address2: getOptionalParameter({
            value1: values.postalAddress2,
            value2: "NA",
          }),
          suburb: values.postalSuburb,
          state: values.postalState.toString(),
          postCode: values.postalPostCode,
          isActive: true,
        },
        country: values.country,
        isActive: true,
        payrollServiceProviderId: values.payrollServiceProvider,
        directoryServiceCompanyId: values.newDirectoryServiceCompanyId
          ? null
          : values.directoryServiceCompanyId,
      };
      if (this.state.editMode) {
        finalValues["id"] = getMatchParams({ match, field: "id" });

        this.props.updateCompany(finalValues).then((response) => {
          this.setState({ confirmLoading: false });

          if (response.status) {
            message.success("Company updated successfully.");

            this.handleRedirection();
          } else {
            errorHandler({ response });
          }
        });
      } else {
        this.props.saveCompany(finalValues).then((response) => {
          this.setState({ confirmLoading: false });

          if (response.status && response.data) {
            this.setState({
              actionOneLink: `/company/${response.data.result}`,

              actionTwoLink: `/company/${response.data.result}/businessunit/create`,

              newCompanyId: response.data.result,
            });

            this.props.form.resetFields();
          } else {
            errorHandler({ response });
          }
        });
      }
    });
  };

  onCancel = () => {
    this.setState({
      disableField: false,
      isSameAddress: false,
    });

    this.props.form.resetFields(["registeredAddress", "postalAddress"]);

    this.props.form.resetFields();

    this.handleRedirection();
  };

  populateSameAddressField = (value, field) => {
    if (field === "registeredAddress1") {
      this.setState({ postalAddress1: value });
    } else if (this.state.isSameAddress) {
      this.props.form.setFieldsValue({ [field]: value });
    }
  };
  handleRedirection = () => {
    const { editMode } = this.state;
    if (editMode) {
      this.props.router.navigate(`/company/${this.props.match.params.id}`);
    } else {
      this.props.router.navigate("/company");
    }
  };
  onChangeCountry = () => {
    this.props.form.resetFields([
      "postalState",
      "registeredState",
      "postalSuburb",
      "postalPostCode",
      "registeredSuburb",
      "registeredPostCode",
      "registeredAddress1",
      "registeredAddress2",
      "postalAddress1",
      "postalAddress2",
      "abn",
      "operatingTimezone",
    ]);

    this.setState({ isSameAddress: false });
  };

  getDirectoryInstanceField = () =>
    this.props.payrollProviders?.find(
      (o) => o.id === this.props.form.getFieldValue("payrollServiceProvider")
    )?.directoryServiceInstanceId;

  render() {
    const { form, match } = this.props;
    const { getFieldDecorator } = form;
    const {
      disableField,
      countries,
      timezone,
      states,
      isSameAddress,
      confirmLoading,
      newCompanyId,
      actionOneLink,
      actionTwoLink,

      activeIndex,
      formLabels,
    } = this.state;
    const instanceId = this.getDirectoryInstanceField();
    return (
      <Form hideRequiredMark={false} layout="vertical" autoComplete="off">
        <Layout className="h-[100vh]">
          {newCompanyId ? (
            <NoRecord>
              <CreateSuccess
                page="Company"
                actionOneLabel="View Company"
                actionOneLink={actionOneLink}
                actionTwoLabel="Create Business Unit"
                actionTwoLink={actionTwoLink}
              />
            </NoRecord>
          ) : (
            <React.Fragment>
              <Header className="bg-white w-full position-absolute-top">
                <FormNavbar
                  title="Business Unit"
                  handleCancel={this.onCancel}
                  handleSave={this.handleSubmit}
                  htmlType="submit"
                  loading={confirmLoading}
                  enableBack={true}
                />
              </Header>
              <Layout className="p-6 rounded-[5px] w-full create-screen-body overflow-y-auto">
                <Content>
                  <Row gutter={DEFAULT_GUTTER}>
                    <FormStepsCol data={formLabels} activeIndex={activeIndex} />
                    <Col md={18} sm={24}>
                      {
                        <div
                          className={
                            Number(activeIndex) === 1 ? "block" : "display-none"
                          }
                        >
                          <Card>
                            <CreateBasicDetailForm
                              form={form}
                              getFieldDecorator={getFieldDecorator}
                              disableField={disableField}
                              entity={"company"}
                              populateStates={this.populateStates.bind(this)}
                              countries={countries}
                              timezone={timezone}
                              states={states}
                              onSameAddressChange={this.onSameAddressChange}
                              onAddressSelect={this.onAddressSelect.bind(this)}
                              onAddressChange={this.onAddressChange}
                              isSameAddress={isSameAddress}
                              populateSameAddressField={this.populateSameAddressField.bind(
                                this
                              )}
                              onChangeCountry={this.onChangeCountry.bind(this)}
                              mapLoaded={this.state.mapLoaded}
                              registeredAddress1={this.state.registeredAddress1}
                              postalAddress1={this.state.postalAddress1}
                              fieldRequired={true}
                              editMode={this.state.editMode}
                              disablePSP={this.state.disablePSP}
                            />
                            <Row gutter={DEFAULT_GUTTER}>
                              {!this.state.editMode && (
                                <Col span={12}>
                                  <Form.Item label="Create New Directory Service Company Id">
                                    {getFieldDecorator(
                                      "newDirectoryServiceCompanyId",
                                      {
                                        initialValue: true,
                                      }
                                    )(
                                      <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                      </Radio.Group>
                                    )}
                                  </Form.Item>
                                </Col>
                              )}
                              {(!form.getFieldValue(
                                "newDirectoryServiceCompanyId"
                              ) ||
                                match?.params?.id) && (
                                <Col span={12}>
                                  <Form.Item label="Directory Service Company Id">
                                    {getFieldDecorator(
                                      "directoryServiceCompanyId",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message:
                                              "Please enter directory service company id.",
                                          },
                                        ],
                                      }
                                    )(
                                      <CompanyInstancesLookup
                                        instanceId={instanceId}
                                        disabled={
                                          !!(
                                            this.state.editMode &&
                                            this.state.directoryServiceCompanyId
                                          )
                                        }
                                        handleChange={(e) =>
                                          this.props.form.setFieldsValue({
                                            directoryServiceCompanyId: e,
                                          })
                                        }
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </Card>
                        </div>
                      }
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </React.Fragment>
          )}
        </Layout>
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  payrollProviders: state?.payrollProvider?.list || [],
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Form.create({ name: "company" })(CreateCompany))
);
