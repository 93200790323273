import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type LoadingType = {
  loading: boolean;
};
type ListType = {
  data: Array<any>;
} & LoadingType;

type IntState = {
  amendmentType: ListType;
  amendmentChangeType: ListType;
  amendmentRequestedBy: ListType;
  businessUnits: ListType;
};

const initialState: IntState = {
  amendmentType: { loading: true, data: [] },
  amendmentChangeType: { loading: true, data: [] },
  amendmentRequestedBy: { loading: true, data: [] },
  businessUnits: { loading: true, data: [] },
};

const lookupsSlice = createSlice({
  name: "lookups",
  initialState,
  reducers: {
    setLookupData: (
      state: any,
      action: PayloadAction<{ name: string; data: Array<any> }>
    ) => {
      const { data, name } = action.payload;
      state[name] = { loading: false, data };
    },
  },
});

const { reducer, actions } = lookupsSlice;

export const { setLookupData } = actions;

export const selectAmendmentTypeData = (state: RootState) =>
  state.lookups.amendmentType.data;
export const selectAmendmentChangeTypeData = (state: RootState) =>
  state.lookups.amendmentChangeType.data;
export const selectAmendmentRequestedByData = (state: RootState) =>
  state.lookups.amendmentRequestedBy.data;
export const selectBusinessUnitsData = (state: RootState) =>
  state.lookups.businessUnits.data;

export const selectAmendmentTypeLoading = (state: RootState) =>
  state.lookups.amendmentType.loading;
export const selectAmendmentChangeTypeLoading = (state: RootState) =>
  state.lookups.amendmentChangeType.loading;
export const selectAmendmentRequestedByLoading = (state: RootState) =>
  state.lookups.amendmentRequestedBy.loading;
export const selectBusinessUnitsLoading = (state: RootState) =>
  state.lookups.businessUnits.loading;

export default reducer;
