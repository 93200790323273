import { Badge } from "antd";

const InvoiceStatusBadge = ({ status }: { status: number }) => {
  let statusName;
  switch (Number(status)) {
    case 1:
      statusName = <Badge count="UNPAID" className="bg-badge-danger" />;
      break;
    case 2:
      statusName = (
        <Badge count="PARTIALLY PAID" className="bg-badge-mid-warning" />
      );
      break;
    case 3:
      statusName = <Badge count="FULLY PAID" className="bg-badge-warning" />;
      break;
    case 4:
      statusName = <Badge count="PAYMENT SKIPPED" className="bg-badge-dark" />;
      break;
    case 5:
      statusName = (
        <Badge count="PAYMENT CONFIRMED" className="bg-badge-success" />
      );
      break;
    default:
      statusName = <></>;
      break;
  }
  return statusName;
};

export default InvoiceStatusBadge;
