import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
`;

export const Underlay = styled.div`
  position: fixed;
  inset: 0;
`;
