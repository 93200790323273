import { ComponentType, Suspense } from 'react';

import { CenteredLoaderDots } from '@xemplo/loader';

// Make the HOC generic with a type parameter for the component props
export const withLazyComponent = <P extends object>(
  LazyComponent: ComponentType<P>,
  useLoader = false
) => {
  const WrappedComponent: ComponentType<P> = (props) => (
    <Suspense fallback={useLoader ? <CenteredLoaderDots /> : null}>
      <LazyComponent {...props} />
    </Suspense>
  );

  return WrappedComponent;
};

export default withLazyComponent;
