import { Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceStatusBadge } from "../../../../Common/payrun";
import { PEO_MODEL_ID } from "../../../../constants/payrun";
import { currencyFormater, renderDefaultDate } from "../../../../libs";
import TableActionComponent from "../../common/tableActions";

const InvoiceSingleLineGridDisplay = (props: any) => {
  const {
    content,
    dashboardView,
    claims,
    downloadPdfComponent,
    downloadSummaryComponent,
  } = props;
  const navigate = useNavigate();
  const handleAction = ({ event, record, action }: any) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    switch (action) {
      case "downloadSummary":
        window.open(record && record.summaryDownloadURL);
        break;
      case "downloadPdf":
        window.open(record && record.pdfDownloadUrl);
        break;
      default:
        break;
    }
  };
  return content ? (
    <Card
      className="bg-default hand rounded-[12px]"
      onClick={() => navigate(`/payrun/${content.payRunID}`)}
    >
      <div className="flex justify-between">
        <div className="flex-1-15">
          <div className="text-[15px] font-bold">{content.invoiceNumber}</div>
          <div className="color-disabled text-sm">
            {content.businessUnitName}
          </div>
        </div>
        <div className="text-sm flex-1-15">
          <div>
            <span className="font-bold">Invoice Generated: </span>
            <span>{renderDefaultDate(content.invoiceDate)}</span>
          </div>
          {content.payrollModelID === PEO_MODEL_ID && (
            <div>
              <span className="font-bold">Payment Due: </span>
              <span>{renderDefaultDate(content.paymentDueDateTime)}</span>
            </div>
          )}
          {content.payrollModelID === PEO_MODEL_ID && (
            <div>
              <span className="font-bold">Date Paid: </span>
              <span>{renderDefaultDate(content.datePaid)}</span>
            </div>
          )}
        </div>
        <div className="text-sm flex-1-15">
          <div>
            <span className="font-bold">Total Tax Fees: </span>
            <span>{currencyFormater(content.totalTaxFees)}</span>
          </div>
          <div>
            <span className="font-bold">Total Fees: </span>
            <span>{currencyFormater(content.totalFees)}</span>
          </div>
          <div>
            <span className="font-bold">Total Amount: </span>
            <span>{currencyFormater(content.totalAmount)}</span>
          </div>
        </div>
        <div className="flex-1-1">
          <InvoiceStatusBadge status={content.invoiceStatusId} />
        </div>
        {!dashboardView && (
          <div className="flex-1-1">
            <TableActionComponent
              record={content}
              handleAction={handleAction}
              downloadPdfComponentName={downloadPdfComponent}
              downloadSummaryComponentName={downloadSummaryComponent}
              claims={claims}
              showDownloadPdf={content.pdfDownloadUrl ? true : false}
              showDownloadSummary={content.summaryDownloadURL ? true : false}
              buttonClassName="float-right"
            />
          </div>
        )}
      </div>
    </Card>
  ) : (
    <React.Fragment></React.Fragment>
  );
};
export default InvoiceSingleLineGridDisplay;
