import styled from 'styled-components';

import { BodySmallMedium, BodyXSmallMedium, Colour } from '@xemplo/style-constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Title = styled.div`
  ${BodySmallMedium};
  color: ${Colour.Gray[600]};
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubTypeName = styled.div`
  ${BodyXSmallMedium};
  color: ${Colour.Gray[500]};
`;

export const Amount = styled.div`
  ${BodySmallMedium};
  color: ${Colour.Gray[800]};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 20px;
  border-radius: 5px;
  background: ${Colour.Gray[50]};
  cursor: pointer;

  &:hover {
    background: ${Colour.Gray[100]};
  }
`;

export const Divider = styled.div`
  margin-block: 32px;
  border-bottom: 1px solid ${Colour.Gray[200]};
  height: 1px;
  width: 100%;
`;
