import { forwardRef, PropsWithChildren, Ref, useEffect, useRef } from 'react';
import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';

import * as S from './form-dialog-popover.styles';
import { FormDialogPopoverProps } from './form-dialog-popover.types';

export const PopoverV2TestId = {
  popover: (testId?: string) => `popover-v2-${testId}`,
};

/**
 * This is a version of the popover component that uses the Dialog element instead of a div. It is used so we can display a popover that renders on top of the Modal component.
 * This has been designed to be used for form fields that use the popover (DatePicker, DropdownMulti, etc.) and not for general use. If you need a generic popover component, use  @xemplo/popover
 * NOTE: This is a temporary solution until we can find a better and more permanent solution.
 */
export const FormDialogPopover = forwardRef(
  (props: PropsWithChildren<FormDialogPopoverProps>, ref?: Ref<HTMLDialogElement>) => {
    const { state, children, testId = '', className } = props;
    const popoverRef = useRef<HTMLDialogElement>(null);
    const { popoverProps, placement } = usePopover(
      { ...props, offset: props.offset ?? 8, popoverRef },
      state
    );

    useEffect(() => {
      props.onPlacementChange?.(placement);
    }, [placement, props.onPlacementChange]);

    useEffect(() => {
      if (!popoverRef.current) return;
      if (state.isOpen) {
        popoverRef.current.showModal();
      }
      if (!state.isOpen) {
        popoverRef.current.close();
      }
    }, [state.isOpen]);

    popoverRef.current?.addEventListener('click', function (event) {
      const rect = popoverRef.current?.getBoundingClientRect();
      if (!rect) return;
      const isInPopover =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
      if (!isInPopover) {
        state.close();
      }
    });

    // cancel event listener on unmount
    useEffect(() => {
      const popover = popoverRef.current;
      return () => {
        popover?.removeEventListener('click', () => null);
      };
    }, []);

    //cannot position a dialog element using bottom position, need to convert the bottom placement to top placement
    if (popoverProps.style?.bottom) {
      const containerRect = document.body.getBoundingClientRect();
      const popoverRect = popoverRef.current?.getBoundingClientRect();
      if (containerRect && popoverRect && popoverProps.style.bottom) {
        const offset =
          containerRect.height -
          popoverRect.height -
          parseInt(popoverProps.style.bottom as string, 10);
        popoverProps.style.top = `${offset}px`;
        delete popoverProps.style.bottom;
      }
    }
    return (
      <Overlay data-testid={PopoverV2TestId.popover(testId)}>
        <S.Dialog ref={popoverRef} {...popoverProps} className={className}>
          <DismissButton onDismiss={state.close} />
          {children}
          <DismissButton onDismiss={state.close} />
        </S.Dialog>
      </Overlay>
    );
  }
);

export default FormDialogPopover;
