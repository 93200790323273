import { useAuth } from "@xemplo/auth-provider";
import { useHttpClient } from "@xemplo/http";
import React, { useEffect, useReducer, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PayRunIconCustom } from "../../../Common/customicons";
import messages from "../../../constants/message";
import { mustBeArray } from "../../../libs/utilities";
import AmendmentSummaryList from "../../components/payrun/Amendments/AmendmentSummaryList";
import CompanyUserSingleLineGridView from "./CompanyUserSingleLineGridView";
import PayRunSingleLineGrid from "./PayRunSingleLineGrid";

// Initial Amendment modal state
const initialAmendmentModalState = {
  activePayrun: {},
  amendmentSummaryList: [],
  viewAmendmentSummary: false,
};

// Amendment modal reducer
const modalReducer = (state: any, action: any) => {
  switch (action.type) {
    case "showAmendmentSummaryList":
      return { ...state, ...action.payload };
    case "updateActivePayrun":
      return { ...state, activePayrun: action.payload };
    case "reset":
      return { ...initialAmendmentModalState };
    default:
      throw new Error();
  }
};

type Props = {
  className?: string;
  listPayrun: (options: any) => Promise<any>;
  listAmendments: (options: any) => Promise<any>;
};

const classNameDefault = "";

const PayRunCompanyUser = ({
  className = classNameDefault,
  listPayrun,
  listAmendments,
}: Props) => {
  const navigate = useNavigate();
  // Pay run loading state
  const [prIsloading, setPRLoading] = useState(true);

  // TODO: The effect should be part of this httpClient hook. Once that's done, the unmount effect can be dumped
  const { cancelSignal } = useHttpClient();

  // Amendment modal state
  const [modalState, dispatch] = useReducer(
    modalReducer,
    initialAmendmentModalState
  );

  const { isAuthenticated } = useAuth();

  // Pay run list from the store
  const prList = useSelector(
    ({ payrun }: any) => payrun.total.list,
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthenticated) return;

    // Get payruns
    listPayrun({
      options: { per_page: 5, sort: { statusAndPayDate: "asc" } },
      cancelToken: cancelSignal.token,
    })
      .then(() => {
        setPRLoading(false);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, [isAuthenticated]);

  // Handle row click
  const handleAction = ({ event, data, action }: any) => {
    event?.stopPropagation();

    switch (action) {
      // View details
      case "view":
        navigate(`/payrun/${data?.payrunId}`);
        break;
      // View amendment summary modal
      case "viewAmendmentSummary":
        return handleViewAmendmentSummary(data);
      default:
        break;
    }
  };

  // Open amendment summary modal
  const handleViewAmendmentSummary = (data: any) => {
    dispatch({ type: "updateActivePayrun", payload: data });

    listAmendments({
      id: data?.payrunId,
      options: { per_page: 500, page: 1 },
      cancelToken: cancelSignal.token,
    }).then(({ status, data }: any) => {
      if (status) {
        dispatch({
          type: "showAmendmentSummaryList",
          payload: {
            amendmentSummaryList: mustBeArray(data),
            viewAmendmentSummary: true,
          },
        });
      }
    });
  };

  // Close amendment summary
  const handleAmendmentSummaryClose = () => {
    dispatch({ type: "reset" });
  };

  useEffect(() => {
    return () => {
      cancelSignal.cancel(
        "PayRunCompanyUser unmounted: Cancelling any pending API requests"
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div className={className}>
        <CompanyUserSingleLineGridView
          handleAction={handleAction}
          loading={prIsloading}
          list={prList}
          viewMore="/payrun"
          title="Pay Runs"
          icon={<PayRunIconCustom />}
          DisplayComponent={PayRunSingleLineGrid}
          noDataDescription={messages["payRuns.noData"]}
        />
      </div>

      <AmendmentSummaryList
        visible={modalState.viewAmendmentSummary}
        list={modalState.amendmentSummaryList}
        payrun={modalState.activePayrun}
        handleAmendmentSummaryClose={handleAmendmentSummaryClose}
      />
    </React.Fragment>
  );
};

export default PayRunCompanyUser;
