import { LoginActions, LogoutActions } from "@xemplo/gp-types";
import { Spin } from "antd";
import { lazy, Suspense } from "react";

import { Login } from "../../User/pages/Login";
import { Logout } from "../../User/pages/Logout";

const SelectPersona = lazy(
  () => import("../../Organization/pages/selectPersona")
);

export const PublicRoutes = [
  {
    path: "/",
    element: <Login action={LoginActions.Login} />,
  },
  {
    path: "login",
    element: <Login action={LoginActions.Login} />,
  },
  {
    path: "confirm-login",
    element: <Login action={LoginActions.LoginCallback} />,
  },
  {
    path: "logout",
    element: <Logout action={LogoutActions.Logout} />,
  },
  {
    path: "confirm-logout",
    element: <Logout action={LogoutActions.LogoutCallback} />,
  },
  {
    path: "select-role",
    element: (
      <Suspense fallback={<Spin />}>
        <SelectPersona />
      </Suspense>
    ),
  },
];
