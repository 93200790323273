import { useModal } from '@xemplo/modal';

import { AmendmentModalBody } from './amendment-modal-body';
import { AmendmentHeader } from './amendment-modal-header';

export const useAmendmentModal = (props: { payrunId: string }) => {
  const { toggleModal, configureModal } = useModal();

  const showAmendmentModal = () => {
    configureModal({
      open: true,
      header: {
        customHeader: <AmendmentHeader />,
      },

      body: {
        content: <AmendmentModalBody {...props} />,
      },
      footer: undefined,
      onCloseCallback: () => toggleModal(false),
    });
  };
  return { showAmendmentModal };
};
