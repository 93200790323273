//@ts-nocheck
import { Component } from "react";
import axios from "axios";
import _ from "lodash";
import BasicDetails from "../../components/basicDetails";
import { Col, Row, Spin, Card, message, Tabs } from "antd";
import {
  errorDisplay,
  mustBeArray,
  findAuthorizationClaims,
  updateAuthorizationClaims,
  delay,
  getCurrentCardGrid,
  getMatchParams,
  isComponentVisible,
} from "../../../libs/utilities";
import BillinServiceProviderAccountList from "../../components/bsp/accountList";
import XeroMAppingList from "../../components/bsp/xeroMapping";
import AddXeroMapping from "../../components/bsp/addXeroMapping";
import ModuleInfoCard from "../../components/common/moduleInfoCard";
import GeneralContainer from "../../../Common/ClaimContainers/GeneralContainer";
import ChildTabContainer from "../../../Common/ClaimContainers/ChildTabContainer";
import ListContainer from "../../../Common/ClaimContainers/ListContainer";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import {
  MULTI_TAB_CONTAINER_PERSONALIZATION,
  MULTI_TAB_ACTIVE_KEY_INDEX,
  TABLE_PERSONALIZATIONS,
  PAGE_NUMBER_INDEX,
  PAGE_SIZE_INDEX,
  SORT_COLUMN_INDEX,
  SORT_ORDER_INDEX,
  PAGINATION_NOT_REQUIRED,
  DEFAULT_PAGE_SIZE_NO_PAGINATION,
  LIST_PAGINATION_TYPE_INDEX,
  SORTING_NOT_REQUIRED,
  GRID_PERSONALIZATIONS,
  GRID_ROW_INDEX,
  GRID_COLUMN_INDEX,
  GRID_SORT_COLUMN_INDEX,
  GRID_SORT_ORDER_INDEX,
  PINNABLE_LIST_PERSONALIZATION,
  LIST_PERSONALIZATION_GRID_TABLE_INDEX,
  LIST_PERSONALIZATION_PAGINATION_INDEX,
  LIST_SORTING_REQUIRED_INDEX,
  LIST_PINNABLE_INDEX,
  TABLE_VIEW_VALUE,
  PAGINATION_SCROLLING,
  GRID_PAGE_NUMBER_INDEX,
  GRID_VIEW_VALUE,
} from "../../../constants/defaultClaims";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import {
  BILLING_PROVIDER_ACCOUNTS_FIELD_OPTIONS,
  BILLING_PROVIDER_ACCOUNTS_GRID_OPTIONS,
  BILLING_PROVIDER_XERO_MAPPING_GRID_OPTIONS,
  BILLING_PROVIDER_XERO_MAPPING_FIELD_OPTIONS,
} from "../../../constants/sortingOptions";
import XeroMappingDisplayCard from "../../components/bsp/xeroMappingDisplayCard";
import AccountDisplayCard from "../../components/bsp/billingAccountsDisplayCard";
import TabHeader from "../../../Common/tabHeader";
import ScrollableBillinServiceProviderAccountList from "../../components/bsp/scrollableBillingServiceProviderAccountList";
import ConflictsTab from "../../components/bsp/conflictsTab";
import {
  BILLING_PROVIDER_ACCOUNT_ADD,
  BILLING_PROVIDER_ACCOUNT_CHILD_TAB_CONTAINER,
  BILLING_PROVIDER_ACCOUNT_DELETE,
  BILLING_PROVIDER_ACCOUNT_EDIT,
  BILLING_PROVIDER_ACCOUNT_GENERAL_GRID,
  BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE,
  BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER,
  BILLING_PROVIDER_ACCOUNT_TAB_CONTAINER,
  BILLING_PROVIDER_BREAD_CRUMB_CONTAINER,
  BILLING_PROVIDER_DETAIL_CHILD_TAB_CONTAINER,
  BILLING_PROVIDER_DETAIL_GENERAL_CONTAINER,
  BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER,
  BILLING_PROVIDER_DETAIL_TAB_CONTAINER,
  BILLING_PROVIDER_EDIT,
  BILLING_PROVIDER_LOGO_CHILD_TAB_CONTAINER,
  BILLING_PROVIDER_LOGO_GENERAL_CONTAINER,
  BILLING_PROVIDER_XERO_MAPPING_ADD_BUTTON,
  BILLING_PROVIDER_XERO_MAPPING_CHILD_TAB_CONTAINER,
  BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID,
  BILLING_PROVIDER_XERO_MAPPING_GENERAL_TABLE,
  BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER,
  BILLING_PROVIDER_XERO_MAPPING_TAB_CONTAINER,
  GOOGLE_ANALYTICS_PAGE_TITLE,
} from "../../../constants";
import { If } from "../../../Common/ui";
import { getDefaultPage, getDefaultPerPage } from "../../../libs";
import { withRouter } from "../../../hooks";

const { TabPane } = Tabs;

type State = any;

class BillingServiceProviderDetailIndex extends Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      pageLoading: true,
      saveXeroLoading: false,
      viewAddXeroAccount: false,
      activeRecord: {},
      current: 1,
      per_page: 20,
      sortInfo: {},
      // account
      accountPagination: 1,
      accountPerPage: 10,
      accountTotal: 0,
      accountList: [],
      accountListLoading: false,
      accountAddable: false,
      accountGridable: false,
      accountGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      accountGridView: false,
      accountIsPinned: "false",
      accountSortingType: "notrequired",
      accountHasMoreData: false,
      accountSortInfo: {},
      accountGridSortOption: BILLING_PROVIDER_ACCOUNTS_GRID_OPTIONS,
      accountSelectedGridSort: undefined,
      accountFieldOptions: BILLING_PROVIDER_ACCOUNTS_FIELD_OPTIONS,

      // xero mapping
      xeroMappingPagination: 1,
      xeroMappingPerPage: 10,
      xeroMappingTotal: 0,
      xeroMappingList: [],
      xeroMappingListLoading: false,
      xeroMappingAddable: false,
      xeroMappingGridable: false,
      xeroMappingGrid: {
        page: 1,
        col: 4,
        row: 5,
        sortInfo: {},
      },
      xeroMappingGridView: false,
      xeroMappingIsPinned: "false",
      xeroMappingSortingType: "notrequired",
      xeroMappingHasMoreData: false,
      xeroMappingSortInfo: {},
      xeroMappingGridSortOption: BILLING_PROVIDER_XERO_MAPPING_GRID_OPTIONS,
      xeroMappingSelectedGridSort: undefined,
      xeroMappingFieldOptions: BILLING_PROVIDER_XERO_MAPPING_FIELD_OPTIONS,
      unresolvedConflictsTotal: 0,
      resolvedConflictsTotal: 0,
      unresolvedConflictsList: [],
      resolvedConflictsList: [],
      conflictsTotal: 0,
      activeConflictTab: "1",
    };
  }
  componentDidMount() {
    const { match } = this.props;
    const id = match?.params?.id;
    const routesContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: BILLING_PROVIDER_BREAD_CRUMB_CONTAINER,
    });
    this.props.updateHeader({
      header: {
        title: "",
        module: "Billing Serivce Provider",
        enableBack: true,
        action: "",
        returnUrl: "/billingserviceproviders",
        routeComponentName: "billingServiceProviderBreadCrumb",
        routesContainer,
        gaTitle: GOOGLE_ANALYTICS_PAGE_TITLE.BillingServiceProviderDetail,
      },
    });
    this.props
      .billingServiceProviderHandler({
        id,
        action: "get",
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        this.setState({ pageLoading: false });

        if (resp.status) {
          this.props.updateHeader({
            header: {
              title: resp?.data.name,
            },
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
    //Multi tab

    const { pageClaims } = this.props;
    const billingServiceProviderDetailMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER
    );
    const activeKey =
      billingServiceProviderDetailMultiTabContainer?.personalizations?.[
        MULTI_TAB_ACTIVE_KEY_INDEX
      ]?.value;

    if (activeKey) {
      this.setState({ activeKey });
    }

    // Xero Mapping list
    const xeroMappingListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER,
    });
    const xeroMappingGeneralTable = findAuthorizationClaims({
      claims: xeroMappingListContainer?.components,
      name: BILLING_PROVIDER_XERO_MAPPING_GENERAL_TABLE,
    });
    const xeroMappingGeneralGrid = findAuthorizationClaims({
      claims: xeroMappingListContainer?.components,
      name: BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID,
    });
    const xeroMappingPaginationType =
      xeroMappingListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
        ?.value;
    const xeroMappingSortingType =
      xeroMappingListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]
        ?.value;
    const xeroMappingIsPinned =
      xeroMappingListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      xeroMappingPaginationType,
      xeroMappingSortingType,
      xeroMappingIsPinned,
      xeroMappingGridable: xeroMappingListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: xeroMappingGeneralTable })) {
      const xeroMappingPagination = getDefaultPage({
        page: xeroMappingGeneralTable?.personalizations?.[PAGE_NUMBER_INDEX]
          ?.value,
      });
      const pageSize = getDefaultPerPage({
        perPage:
          xeroMappingGeneralTable?.personalizations?.[PAGE_SIZE_INDEX]?.value,
        defaultPerPage: 20,
      });
      const SortOrder =
        xeroMappingGeneralTable?.personalizations?.[SORT_ORDER_INDEX]?.value ??
        null;
      const SortColumn =
        xeroMappingGeneralTable?.personalizations?.[SORT_COLUMN_INDEX]?.value ??
        null;

      this.setState(
        {
          xeroMappingPerPage:
            xeroMappingPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize, defaultPerPage: 20 }),
          xeroMappingPagination,
          xeroMappingSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(xeroMappingListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (xeroMappingPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingXeroMapping({ pageLoad: true });
            } else {
              this.listXeroMappings({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: xeroMappingGeneralGrid })) {
      const pageNumber = xeroMappingGeneralGrid.personalizations
        ? Number(
          xeroMappingGeneralGrid.personalizations[GRID_PAGE_NUMBER_INDEX]
            .value
        )
        : 1;
      const row = xeroMappingGeneralGrid.personalizations
        ? Number(xeroMappingGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = xeroMappingGeneralGrid.personalizations
        ? Number(
          xeroMappingGeneralGrid.personalizations[GRID_COLUMN_INDEX].value
        )
        : 4;
      const SortOrder =
        xeroMappingGeneralGrid?.personalizations?.[GRID_SORT_ORDER_INDEX]
          ?.value ?? null;
      const SortColumn =
        xeroMappingGeneralGrid?.personalizations?.[GRID_SORT_COLUMN_INDEX]
          ?.value ?? null;

      this.setState(
        {
          xeroMappingGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          xeroMappingSelectedGridSort:
            _.find(
              this.state.xeroMappingGridSortOption,
              (o) => o.label === SortColumn
            ) &&
            _.find(
              this.state.xeroMappingGridSortOption,
              (o) => o.label === SortColumn
            ).id,
        },
        () => {
          if (
            mustBeArray(xeroMappingListContainer?.personalizations)?.[0]
              ?.value === GRID_VIEW_VALUE
          ) {
            this.setState({ xeroMappingGridView: true });
            if (xeroMappingPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridXeroMapping({ pageLoad: true });
            } else {
              this.xeroMappingFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }

    // Account Mapping list
    const accountListContainer = findAuthorizationClaims({
      claims: this.props.pageClaims?.components,
      name: BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER,
    });
    const accountGeneralTable = findAuthorizationClaims({
      claims: accountListContainer?.components,
      name: BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE,
    });
    const accountGeneralGrid = findAuthorizationClaims({
      claims: accountListContainer?.components,
      name: BILLING_PROVIDER_ACCOUNT_GENERAL_GRID,
    });
    const accountPaginationType =
      accountListContainer?.personalizations?.[LIST_PAGINATION_TYPE_INDEX]
        ?.value;
    const accountSortingType =
      accountListContainer?.personalizations?.[LIST_SORTING_REQUIRED_INDEX]
        ?.value;
    const accountIsPinned =
      accountListContainer?.personalizations?.[LIST_PINNABLE_INDEX]?.value;

    this.setState({
      accountPaginationType,
      accountSortingType,
      accountIsPinned,
      accountGridable: accountListContainer?.gridable,
    });

    // If Table
    if (isComponentVisible({ claim: accountGeneralTable })) {
      const accountPagination = getDefaultPage({
        page: accountGeneralTable?.personalizations?.[PAGE_NUMBER_INDEX]?.value,
      });
      const pageSize = accountGeneralTable.personalizations
        ? Number(
          accountGeneralTable.personalizations[PAGE_SIZE_INDEX].value || 20
        )
        : 20;
      const SortOrder =
        accountGeneralTable?.personalizations?.[SORT_ORDER_INDEX]?.value ??
        null;
      const SortColumn =
        accountGeneralTable?.personalizations?.[SORT_COLUMN_INDEX]?.value ??
        null;

      this.setState(
        {
          accountPerPage:
            accountPaginationType === PAGINATION_NOT_REQUIRED
              ? DEFAULT_PAGE_SIZE_NO_PAGINATION
              : getDefaultPerPage({ perPage: pageSize, defaultPerPage: 10 }),
          accountPagination,
          accountSortInfo: {
            order: SortOrder,
            field: SortColumn,
          },
        },
        () => {
          if (
            mustBeArray(accountListContainer?.personalizations)?.[
              LIST_PERSONALIZATION_GRID_TABLE_INDEX
            ]?.value === TABLE_VIEW_VALUE
          ) {
            if (accountPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingAccount({ pageLoad: true });
            } else {
              this.listAccounts({ options: {} });
            }
          }
        }
      );
    }
    // If Grid
    if (isComponentVisible({ claim: accountGeneralGrid })) {
      const pageNumber = getDefaultPage({
        page: accountGeneralGrid?.personalizations?.[GRID_PAGE_NUMBER_INDEX]
          ?.value,
      });
      const row = accountGeneralGrid.personalizations
        ? Number(accountGeneralGrid.personalizations[GRID_ROW_INDEX].value)
        : 5;
      const col = accountGeneralGrid.personalizations
        ? Number(accountGeneralGrid.personalizations[GRID_COLUMN_INDEX].value)
        : 4;
      const SortOrder =
        accountGeneralGrid.personalizations?.[GRID_SORT_ORDER_INDEX]?.value ??
        null;
      const SortColumn =
        accountGeneralGrid?.personalizations?.[GRID_SORT_COLUMN_INDEX]?.value ??
        null;

      this.setState(
        {
          accountGrid: {
            page: _.isNaN(Number(pageNumber)) ? 1 : Number(pageNumber),
            sortInfo: {
              order: SortOrder,
              field: SortColumn,
            },
            row,
            col,
          },

          accountSelectedGridSort: _.find(
            this.state.accountGridSortOption,
            (o) => o.label === SortColumn
          )?.id,
        },
        () => {
          if (
            mustBeArray(accountListContainer?.personalizations)?.[0]?.value ===
            GRID_VIEW_VALUE
          ) {
            this.setState({ accountGridView: true });
            if (accountPaginationType === PAGINATION_SCROLLING) {
              this.listScrollingGridAccount({ pageLoad: true });
            } else {
              this.accountFetchMoreData({ isPaginated: true });
            }
          }
        }
      );
    }

    // Conflicts list

    this.getConflictsList();
  }
  getConflictsList = () => {
    const { match } = this.props;

    this.props
      .billingServiceProviderAccountConflictsHandler({
        action: "list",
        id: match?.params?.id,
      })
      .then((resp) => {
        if (resp.status) {
          let conflictsList = mustBeArray(resp.data);
          const resolvedConflictsList = _.filter(
            conflictsList,
            (o) => o.resolved
          );
          const unresolvedConflictsList = _.filter(
            conflictsList,
            (o) => !o.resolved
          );
          const unresolvedConflictsTotal = mustBeArray(
            unresolvedConflictsList
          ).length;
          const resolvedConflictsTotal = mustBeArray(
            resolvedConflictsList
          ).length;

          this.setState({
            unresolvedConflictsList,
            unresolvedConflictsTotal,
            resolvedConflictsList,
            resolvedConflictsTotal,
            conflictsTotal: unresolvedConflictsTotal + resolvedConflictsTotal,
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };
  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  addAccount = () => {
    const { match } = this.props;
    const id = match?.params?.id;

    const header = {
      module: "Back to Billing Service Provider Detail",
      enableBack: true,
      title: `New Account`,
      entity: "billingserviceprovider",
      returnUrl: `/billingserviceproviders/${id}`,
    };

    this.props.updateHeader({ header });
    this.props.router.navigate(`/billingserviceproviders/${id}/account/create`);
  };

  // Accounts
  listScrollingAccount = ({ options = {}, pageLoad = false, notUpdate }) => {
    if (!pageLoad && !this.state.accountHasMoreData) return false;

    const id = this.props.match?.params?.id;

    this.setState(
      (prevState) => {
        if (!_.isEmpty(options)) {
          prevState.sortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        if (!pageLoad && _.isEmpty(options)) {
          prevState.accountPagination =
            prevState.accountTotal === mustBeArray(prevState.accountList).length
              ? prevState.accountPagination
              : Number(prevState.accountPagination) + 1;
        }
        prevState.accountListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = { ...this.getaccountTableSortInfo() };
        if (pageLoad) {
          tempOptions = {
            ...tempOptions,
            page: 1,
            per_page:
              Number(this.state.accountPagination) *
              Number(this.state.accountPerPage),
          };
        } else {
          tempOptions = {
            ...tempOptions,
            page: this.state.accountPagination,
            per_page: this.state.accountPerPage,
          };
        }

        this.props
          .billingServiceProviderHandler({
            options: tempOptions,
            id,
            action: "listAccount",
          })
          .then((resp) => {
            this.setState({ accountListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.accountPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ accountPagination: 1 }, () => {
                  this.listScrollingAccount({ pageLoad: true });
                });
              } else {
                this.setState(
                  {
                    accountList: pageLoad
                      ? mustBeArray(resp.data)
                      : [...this.state.accountList, ...mustBeArray(resp.data)],

                    accountTotal: resp.total,
                  },
                  () => {
                    this.setState({
                      accountHasMoreData:
                        this.state.accountList.length < resp.total
                          ? true
                          : false,
                    });
                  }
                );
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          if (!notUpdate) {
            this.updatePersonalization(BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE);
          }
        });
      }
    );
  };

  listAccounts = ({ options = {} }) => {
    const { match } = this.props;
    const id = match?.params?.id;

    this.setState(
      (prevState) => {
        if (options?.page) {
          prevState.accountPagination = options.page;
          prevState.accountSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.accountListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: getDefaultPage({ page: this.state.accountPagination }),

          per_page: getDefaultPerPage({
            perPage: this.state.accountPerPage,
            defaultPerPage: 10,
          }),

          ...this.getaccountTableSortInfo(),
        };

        this.props
          .billingServiceProviderHandler({
            options: newOptions,
            id,
            action: "listAccount",

            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ accountListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.accountPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ accountPagination: 1 }, () => {
                  this.listAccounts({ options: {} });
                });
              } else {
                this.setState({
                  accountList: mustBeArray(resp.data),
                  accountTotal: resp.total,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE);
        });
      }
    );
  };

  getaccountTableSortInfo = () => {
    if (
      this.state.accountSortInfo &&
      this.state.accountSortInfo.field &&
      this.state.accountSortInfo.order &&
      this.state.accountSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.accountSortInfo.field]: this.state.accountSortInfo.order,
        },
      };
    } else return {};
  };

  listScrollingGridAccount = ({ options = {}, pageLoad = false }) => {
    const id = this.props.match?.params?.id;

    if (!pageLoad && !this.state.accountHasMoreData) return false;

    this.setState(
      {
        accountListLoading: true,
        accountGrid: {
          ...this.state.accountGrid,
          page: !pageLoad
            ? Number(this.state.accountGrid?.page) + 1
            : Number(this.state.accountGrid?.page),
        },
      },
      () => {
        const { accountGrid } = this.state;
        const { page } = accountGrid;

        const per_page = this.getAccountGridPageSize();

        let sortingParams = this.getAccountGridSortInfo();

        this.props
          .billingServiceProviderHandler({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              ...sortingParams,
            },
            action: "listAccount",
            id,
          })

          .then((response) => {
            this.setState({ accountListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { accountGrid: { ...accountGrid, page: 1 } },
                  () => {
                    this.listScrollingGridAccount({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.accountList = pageLoad
                      ? data
                      : prevState.accountList.concat(data);

                    prevState.accountHasMoreData =
                      response.total > prevState.accountList.length
                        ? true
                        : false;

                    prevState.accountTotal = response.total;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        BILLING_PROVIDER_ACCOUNT_GENERAL_GRID
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  accountFetchMoreData = ({ isPaginated = false }) => {
    const id = this.props.match?.params?.id;

    this.setState({ accountListLoading: true });

    const { accountGrid } = this.state;
    const { page } = accountGrid;

    const per_page = this.getAccountGridPageSize();

    let sortingParams = this.getAccountGridSortInfo();

    this.props
      .billingServiceProviderHandler({
        options: { page, per_page, ...sortingParams },

        id,
        action: "listAccount",
        cancelToken: this.signal.token,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState({ accountGrid: { ...accountGrid, page: 1 } }, () => {
              this.accountFetchMoreData({ isPaginated });
            });
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.accountList = data;
                } else {
                  prevState.accountList = prevState.accountList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.accountList.length < this.state.accountTotal
                  ) {
                    prevState.accountHasMoreData = true;
                    prevState.accountGrid = {
                      ...prevState.accountGrid,
                      page: prevState.accountGrid.page + 1,
                    };
                  } else {
                    prevState.accountHasMoreData = false;
                  }
                }

                prevState.accountTotal = response.total;
                prevState.accountListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(
                    BILLING_PROVIDER_ACCOUNT_GENERAL_GRID
                  );
                });
              }
            );
          }
        }
      });
  };

  listAccountGrid = (page) => {
    const id = this.props.match?.params?.id;

    this.setState(
      (prevState) => {
        prevState.accountGrid.page = page;
        prevState.accountListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.accountGrid?.page,
          per_page: this.getAccountGridPageSize(),

          ...this.getAccountGridSortInfo(),
        };

        this.props
          .billingServiceProviderHandler({
            options: tempOptions,
            id,
            cancelToken: this.signal.token,
            action: "listAccount",
          })
          .then((resp) => {
            this.setState({ accountListLoading: false });

            if (resp.status) {
              this.setState({
                accountList: mustBeArray(resp.data),
                accountTotal: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(BILLING_PROVIDER_ACCOUNT_GENERAL_GRID);
        });
      }
    );
  };

  getAccountGridSortInfo = () => {
    if (
      this.state.accountGrid.sortInfo?.field &&
      this.state.accountGrid.sortInfo?.order &&
      this.state.accountSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.accountGrid.sortInfo.field]:
            this.state.accountGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.accountGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.accountGrid.sortInfo.order,
        },
      };
    } else return {};
  };

  handleAccountGridChange = ({ gridView, isPaginated }) => {
    if (this.state.accountGridView === gridView) {
      return false;
    }

    this.setState(
      { accountGridView: gridView, accountList: [], accountListLoading: true },
      () => {
        this.updatePersonalization(BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER);
        if (!gridView) {
          if (this.state.accountPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingAccount({ pageLoad: true, options: {} });
          } else {
            this.listAccounts({ options: {} });
          }
        } else {
          if (this.state.accountPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridAccount({ pageLoad: true, options: {} });
          } else {
            this.accountFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  accountUpdatePageSize = (value) => {
    if (this.state.accountGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.accountGrid.row = Number(row);
          prevState.accountGrid.col = Number(col);
          prevState.accountGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.accountPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridAccount({ pageLoad: true, options: {} });
          } else {
            this.accountFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState(
        { accountPerPage: Number(value), accountPagination: 1 },
        () => {
          if (this.state.accountPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingAccount({ pageLoad: true, options: {} });
          } else {
            this.listAccounts({ options: {} });
          }
        }
      );
    }
  };

  handleAccountAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
    switch (action) {
      case "view":
        break;
      case "edit":
        this.handleEdit({ label: "account", record: data });
        break;
      case "delete":
        this.handleDelete({ label: "account", record: data });
        break;
      default:
        break;
    }
  };

  updateAccountGridField = ({ field, value }) => {
    this.setState(
      (prevState) => {
        if (prevState.accountGrid.sortInfo[field] === value) {
          prevState.accountGrid.sortInfo[field] = "";
        } else {
          prevState.accountGrid.sortInfo[field] = value;
        }
        return prevState;
      },
      () => {
        this.accountFetchMoreData({ isPaginated: true });
      }
    );
  };

  getAccountGridPageSize = () => {
    const { row, col } = this.state.accountGrid;

    return this.state.accountPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  // Xero Codes

  listXeroMappings = ({ options = {} }) => {
    const { match } = this.props;
    const id = match?.params?.id;

    this.setState(
      (prevState) => {
        if (options?.page) {
          prevState.xeroMappingPagination = options.page;
          prevState.xeroMappingSortInfo = {
            field: options.field,
            order: options.order,
          };
        }
        prevState.xeroMappingListLoading = true;
        return prevState;
      },
      () => {
        let newOptions = {
          page: getDefaultPage({ page: this.state.xeroMappingPagination }),

          per_page: getDefaultPerPage({
            perPage: this.state.xeroMappingPerPage,
          }),

          ...this.getXeroMappingTableSortInfo(),
        };

        this.props
          .billingServiceProviderHandler({
            options: newOptions,
            id,
            action: "listXero",

            cancelToken: this.signal.token,
          })
          .then((resp) => {
            this.setState({ xeroMappingListLoading: false });

            if (resp.status) {
              if (
                Number(this.state.xeroMappingPagination) !== 1 &&
                _.isEmpty(mustBeArray(resp.data))
              ) {
                this.setState({ xeroMappingPagination: 1 }, () => {
                  this.listXeroMappings({ options: {} });
                });
              } else {
                this.setState({
                  xeroMappingList: mustBeArray(resp.data),
                  xeroMappingTotal: mustBeArray(resp.data).length,
                });
              }
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(
            BILLING_PROVIDER_XERO_MAPPING_GENERAL_TABLE
          );
        });
      }
    );
  };

  getXeroMappingTableSortInfo = () => {
    if (
      this.state.xeroMappingSortInfo?.field &&
      this.state.xeroMappingSortInfo?.order &&
      this.state.xeroMappingSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.xeroMappingSortInfo.field]:
            this.state.xeroMappingSortInfo.order,
        },
      };
    } else return {};
  };

  listScrollingGridXeroMapping = ({ options = {}, pageLoad = false }) => {
    const id = this.props.match?.params?.id;

    if (!pageLoad && !this.state.xeroMappingHasMoreData) return false;

    this.setState(
      {
        xeroMappingListLoading: true,
        xeroMappingGrid: {
          ...this.state.xeroMappingGrid,
          page: !pageLoad
            ? Number(this.state.xeroMappingGrid?.page) + 1
            : Number(this.state.xeroMappingGrid?.page),
        },
      },
      () => {
        const { xeroMappingGrid } = this.state;
        const { page } = xeroMappingGrid;

        const per_page = this.getXeroMappingGridPageSize();

        let sortingParams = this.getXeroMappingGridSortInfo();

        this.props
          .billingServiceProviderHandler({
            options: {
              page: pageLoad ? 1 : page,
              per_page: pageLoad ? Number(page) * per_page : per_page,
              ...sortingParams,
            },
            action: "listXero",
            id,
          })

          .then((response) => {
            this.setState({ xeroMappingListLoading: false });

            if (response.status) {
              if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
                this.setState(
                  { xeroMappingGrid: { ...xeroMappingGrid, page: 1 } },
                  () => {
                    this.listScrollingGridXeroMapping({ pageLoad: true });
                  }
                );
              } else {
                this.setState(
                  (prevState) => {
                    const data = mustBeArray(response.data);
                    prevState.xeroMappingList = pageLoad
                      ? data
                      : prevState.xeroMappingList.concat(data);

                    prevState.xeroMappingHasMoreData =
                      response.total > prevState.xeroMappingList.length
                        ? true
                        : false;

                    prevState.xeroMappingTotal = mustBeArray(
                      response.data
                    ).length;
                    return prevState;
                  },
                  () => {
                    delay(300).then(() => {
                      this.updatePersonalization(
                        BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID
                      );
                    });
                  }
                );
              }
            }
          });
      }
    );
  };

  xeroMappingFetchMoreData = ({ isPaginated = false }) => {
    const id = this.props.match?.params?.id;

    this.setState({ xeroMappingListLoading: true });

    const { xeroMappingGrid } = this.state;
    const { page } = xeroMappingGrid;

    const per_page = this.getXeroMappingGridPageSize();

    let sortingParams = this.getXeroMappingGridSortInfo();

    this.props
      .billingServiceProviderHandler({
        options: { page, per_page, ...sortingParams },

        id,
        action: "listXero",
        cancelToken: this.signal.token,
      })

      .then((response) => {
        if (response.status) {
          if (Number(page) !== 1 && _.isEmpty(mustBeArray(response.data))) {
            this.setState(
              { xeroMappingGrid: { ...xeroMappingGrid, page: 1 } },
              () => {
                this.xeroMappingFetchMoreData({ isPaginated });
              }
            );
          } else {
            this.setState(
              (prevState) => {
                const data = mustBeArray(response.data);
                if (isPaginated) {
                  prevState.xeroMappingList = data;
                } else {
                  prevState.xeroMappingList =
                    prevState.xeroMappingList.concat(data);

                  if (
                    data.length !== 0 &&
                    prevState.xeroMappingList.length <
                    this.state.xeroMappingTotal
                  ) {
                    prevState.xeroMappingHasMoreData = true;
                    prevState.xeroMappingGrid = {
                      ...prevState.xeroMappingGrid,
                      page: prevState.xeroMappingGrid.page + 1,
                    };
                  } else {
                    prevState.xeroMappingHasMoreData = false;
                  }
                }

                prevState.xeroMappingTotal = response.total;
                prevState.xeroMappingListLoading = false;
                return prevState;
              },
              () => {
                delay(300).then(() => {
                  this.updatePersonalization(
                    BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID
                  );
                });
              }
            );
          }
        }
      });
  };

  listXeroMappingGrid = (page) => {
    const id = this.props.match?.params?.id;

    this.setState(
      (prevState) => {
        prevState.xeroMappingGrid.page = page;
        prevState.xeroMappingListLoading = true;
        return prevState;
      },
      () => {
        let tempOptions = {
          page: this.state.xeroMappingGrid?.page,
          per_page: this.getXeroMappingGridPageSize(),

          ...this.getXeroMappingGridSortInfo(),
        };

        this.props
          .listXeroMappings({
            options: tempOptions,
            id,
            cancelToken: this.signal.token,
            action: "listxeroMapping",
          })
          .then((resp) => {
            this.setState({ xeroMappingListLoading: false });

            if (resp.status) {
              this.setState({
                xeroMappingList: mustBeArray(resp.data),
                xeroMappingTotal: resp.total,
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        delay(300).then(() => {
          this.updatePersonalization(
            BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID
          );
        });
      }
    );
  };

  getXeroMappingGridSortInfo = () => {
    if (
      this.state.xeroMappingGrid.sortInfo?.field &&
      this.state.xeroMappingGrid.sortInfo?.order &&
      this.state.xeroMappingSortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          [this.state.xeroMappingGrid.sortInfo.field]:
            this.state.xeroMappingGrid.sortInfo.order,
        },
      };
    } else if (
      this.state.xeroMappingGrid.sortInfo?.order &&
      this.state.sortingType !== SORTING_NOT_REQUIRED
    ) {
      return {
        sort: {
          recent: this.state.xeroMappingGrid.sortInfo.order,
        },
      };
    } else return {};
  };

  handleXeroMappingGridChange = ({ gridView, isPaginated }) => {
    if (this.state.xeroMappingGridView === gridView) {
      return false;
    }

    this.setState(
      {
        xeroMappingGridView: gridView,
        xeroMappingList: [],
        xeroMappingListLoading: true,
      },
      () => {
        this.updatePersonalization(
          BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER
        );
        if (!gridView) {
          if (this.state.xeroMappingPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingXeroMapping({ pageLoad: true, options: {} });
          } else {
            this.listXeroMappings({ options: {} });
          }
        } else {
          if (this.state.xeroMappingPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridXeroMapping({ pageLoad: true, options: {} });
          } else {
            this.xeroMappingFetchMoreData({ isPaginated: true });
          }
        }
      }
    );
  };

  xeroMappingUpdatePageSize = (value) => {
    if (this.state.xeroMappingGridView) {
      let values = value && value.split("X");
      const row = values && values[0];
      const col = values && values[1];

      this.setState(
        (prevState) => {
          prevState.xeroMappingGrid.row = Number(row);
          prevState.xeroMappingGrid.col = Number(col);
          prevState.xeroMappingGrid.page = 1;
          return prevState;
        },
        () => {
          if (this.state.xeroMappingPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingGridXeroMapping({ pageLoad: true, options: {} });
          } else {
            this.xeroMappingFetchMoreData({ isPaginated: true });
          }
        }
      );
    } else {
      this.setState(
        { xeroMappingPerPage: Number(value), xeroMappingPagination: 1 },
        () => {
          if (this.state.xeroMappingPaginationType === PAGINATION_SCROLLING) {
            this.listScrollingXeroMapping({ pageLoad: true, options: {} });
          } else {
            this.listXeroMappings({ options: {} });
          }
        }
      );
    }
  };

  handleXeroMappingAction = ({ event, data, action }) => {
    if (event) {
      event.stopPropagation();
    }
  };

  getXeroMappingGridPageSize = () => {
    const { row, col } = this.state.xeroMappingGrid;

    return this.state.xeroMappingPaginationType === PAGINATION_NOT_REQUIRED
      ? DEFAULT_PAGE_SIZE_NO_PAGINATION
      : Number(row || 1) * Number(col || 1);
  };

  editXeroCode = ({ record }) => {
    this.setState({ activeRecord: record }, () => {
      this.setState({ viewAddXeroAccount: true });
    });
  };

  updateXeroMapping = ({ payload }) => {
    const { match } = this.props;
    const id = match?.params?.id;
    payload.id = id;

    this.setState({ saveXeroLoading: true });

    this.props
      .billingServiceProviderHandler({
        action: "updateXeroCode",
        id,

        xeroId: this.state.activeRecord?.id,

        payload: {
          ...this.state.activeRecord,
          xeroReferenceCode: payload?.xeroReferenceCode,
        },
      })
      .then((resp) => {
        if (resp.status) {
          message.success("Xero Code updated successfully");

          this.cancelUpdatingXeroCode();

          this.listXeroMappings({ options: {} });
        } else {
          this.setState({ saveXeroLoading: false });

          errorDisplay(resp?.data?.validationErrors, true);
        }
      });
  };

  cancelUpdatingXeroCode = () => {
    this.setState({
      saveXeroLoading: false,
      viewAddXeroAccount: false,
      activeRecord: {},
    });
  };

  getXeroMappingCardGrid = () => {
    let defaultCardGrid;

    switch (Number(this.state.xeroMappingGrid?.col)) {
      case 1:
        defaultCardGrid = 24;
        break;
      case 2:
        defaultCardGrid = 12;
        break;
      case 3:
        defaultCardGrid = 8;
        break;
      case 4:
        defaultCardGrid = 6;
        break;
      default:
        defaultCardGrid = 8;
        break;
    }
    return defaultCardGrid;
  };
  updatePersonalization = (field, update = true) => {
    const { member, pageClaims } = this.props;
    try {
      let currentAuthorizationDOM = mustBeArray(
        member?.details?.authorizationDOM
      );
      let updatedDOM = [];
      let updatedComponent = {};
      let personalizations = [];
      let personalization = {};
      let payload = {};
      const billingServiceProviderXeroMappingListContainer =
        findAuthorizationClaims({
          claims: pageClaims?.components,
          name: BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER,
        });
      const billingServiceProviderAccountListContainer =
        findAuthorizationClaims({
          claims: pageClaims?.components,
          name: BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER,
        });
      switch (field) {
        case BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER:
          personalizations = [...MULTI_TAB_CONTAINER_PERSONALIZATION];

          personalizations[
            MULTI_TAB_ACTIVE_KEY_INDEX
          ].value = `${this.state.activeKey}`;
          updatedDOM = updateAuthorizationClaims({
            claims: currentAuthorizationDOM,
            value: personalizations,
            label: "personalizations",
            name: field,
          });
          updatedComponent = findAuthorizationClaims({
            claims: pageClaims?.components,
            name: field,
          });
          break;
        case BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE:
          if (
            billingServiceProviderAccountListContainer.personalizable === "true"
          ) {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.accountPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.accountPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.accountSortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.accountSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });

            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_ACCOUNT_GENERAL_GRID:
          if (
            billingServiceProviderAccountListContainer.personalizable === "true"
          ) {
            const { accountGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${accountGrid?.page}`;
            personalizations[GRID_ROW_INDEX].value = `${accountGrid?.row}`;
            personalizations[GRID_COLUMN_INDEX].value = `${accountGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              accountGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              accountGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER:
          if (
            billingServiceProviderAccountListContainer.personalizable === "true"
          ) {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.accountGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.accountPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.accountSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.accountIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_XERO_MAPPING_GENERAL_TABLE:
          if (
            billingServiceProviderXeroMappingListContainer.personalizable ===
            "true"
          ) {
            personalizations = [...TABLE_PERSONALIZATIONS];

            personalizations[
              PAGE_NUMBER_INDEX
            ].value = `${this.state.xeroMappingPagination}`;

            personalizations[
              PAGE_SIZE_INDEX
            ].value = `${this.state.xeroMappingPerPage}`;

            personalizations[SORT_COLUMN_INDEX].value =
              this.state.xeroMappingSortInfo?.field;

            personalizations[SORT_ORDER_INDEX].value =
              this.state.xeroMappingSortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID:
          if (
            billingServiceProviderXeroMappingListContainer.personalizable ===
            "true"
          ) {
            const { xeroMappingGrid } = this.state;
            personalizations = [...GRID_PERSONALIZATIONS];
            personalizations[
              GRID_PAGE_NUMBER_INDEX
            ].value = `${xeroMappingGrid?.page}`;
            personalizations[GRID_ROW_INDEX].value = `${xeroMappingGrid?.row}`;
            personalizations[
              GRID_COLUMN_INDEX
            ].value = `${xeroMappingGrid?.col}`;
            personalizations[GRID_SORT_COLUMN_INDEX].value =
              xeroMappingGrid?.sortInfo?.field;
            personalizations[GRID_SORT_ORDER_INDEX].value =
              xeroMappingGrid?.sortInfo?.order;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        case BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER:
          if (
            billingServiceProviderXeroMappingListContainer.personalizable ===
            "true"
          ) {
            personalizations = [...PINNABLE_LIST_PERSONALIZATION];

            personalizations[LIST_PERSONALIZATION_GRID_TABLE_INDEX].value = this
              .state.xeroMappingGridView
              ? GRID_VIEW_VALUE
              : TABLE_VIEW_VALUE;

            personalizations[LIST_PERSONALIZATION_PAGINATION_INDEX].value =
              this.state.xeroMappingPaginationType;

            personalizations[LIST_SORTING_REQUIRED_INDEX].value =
              this.state.xeroMappingSortingType;

            personalizations[LIST_PINNABLE_INDEX].value =
              this.state.xeroMappingIsPinned;
            updatedDOM = updateAuthorizationClaims({
              claims: currentAuthorizationDOM,
              value: personalizations,
              label: "personalizations",
              name: field,
            });
            updatedComponent = findAuthorizationClaims({
              claims: pageClaims?.components,
              name: field,
            });
          }
          break;
        default:
          break;
      }
      let updateChange = update;
      if (!_.isEmpty(updatedComponent)) {
        payload = {
          id: updatedComponent?.id,
          name: updatedComponent?.name,
          personalizations,
          personalization: _.isEmpty(personalization) ? null : personalization,
        };
      } else {
        updateChange = false;
      }
      delay(200).then(() => {
        this.props.updateAuthorizationClaim({
          claim: updatedDOM,
          payload: {
            personaComponents: payload ? [payload] : [],
          },
          update: updateChange,
        });
      });
    } catch (err) {
      return err;
    }
  };
  handleTabChange = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.updatePersonalization(BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER);
    });
  };
  updatePinnedComponent = ({ pinnedComponent, pinnedComponentName }) => {
    this.setState(
      {
        [pinnedComponentName]:
          this.state[pinnedComponentName] === "true" ? "false" : "true",
      },
      () => {
        this.updatePersonalization(pinnedComponent);
      }
    );
  };
  handleEdit = ({ label, record = {} }) => {
    const { match, billingServiceProviderDetail } = this.props;
    const id = match?.params?.id;
    let header = {};
    switch (label) {
      case "bspDetails":
        header = {
          module: "Back to Billing Service Provider Detail",
          enableBack: true,
          title: billingServiceProviderDetail?.name,
          entity: "billingservice provider",
          returnUrl: `/billingserviceproviders/${id}`,
        };

        this.props.updateHeader({ header });
        this.props.router.navigate(`/billingserviceproviders/${id}/edit`);
        break;
      case "account":
        header = {
          module: "Back to Billing Service Provider Detail",
          enableBack: true,
          title: record?.accountName,
          entity: "billingservice provider",
          returnUrl: `/billingserviceproviders/${id}`,
        };
        this.props.updateHeader({ header });
        this.props.router.navigate(
          `/billingserviceproviders/${id}/account/${record?.id}/edit`
        );
        break;
      default:
        break;
    }
  };
  handleDelete = ({ label, record = {} }) => {
    const { match } = this.props;
    const id = getMatchParams({ match, field: "id" });
    switch (label) {
      case "account":
        this.setState({ accountListLoading: true });

        this.props
          .billingServiceProviderHandler({
            id,
            action: "deleteAccount",
            accountId: record && record.id,

            cancelToken: this.signal.token,
            options: {},
          })
          .then((resp) => {
            if (resp.status) {
              message.success("Account deleted successfully.");
              this.listAccounts({ options: {} });
            } else {
              this.setState({ accountListLoading: false });

              errorDisplay(resp?.data?.validationErrors, true);
            }
          });
        break;
      case "xeroMapping":
        break;
      default:
        break;
    }
  };

  handleConflictTabChange = (activeKey) => {
    this.setState({ activeConflictTab: activeKey });
  };

  handleResolveConflict = ({ conflict }) => {
    const { match } = this.props;

    this.props
      .billingServiceProviderAccountConflictsHandler({
        action: "resolve",
        id: match?.params?.id,
        conflictId: conflict && conflict.id,
      })
      .then((resp) => {
        if (resp.status) {
          message.success("Account conflict resolved successfully.");

          this.getConflictsList();
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };
  render() {
    const {
      pageLoading,
      activeRecord,
      viewAddXeroAccount,
      saveXeroLoading,
      activeKey,
      xeroMappingIsPinned,
      xeroMappingListLoading,
      xeroMappingGridable,
      xeroMappingPagination,
      xeroMappingGrid,
      xeroMappingPerPage,
      xeroMappingSortInfo,
      xeroMappingSortingType,
      xeroMappingFieldOptions,
      xeroMappingGridView,
      xeroMappingHasMoreData,
      xeroMappingList,
      xeroMappingTotal,
      xeroMappingPaginationType,
      accountIsPinned,
      accountListLoading,
      accountGridable,
      accountPagination,
      accountGrid,
      accountPerPage,
      accountSortInfo,
      accountSortingType,
      accountFieldOptions,
      accountGridView,
      accountHasMoreData,
      accountList,
      accountTotal,
      accountPaginationType,
      conflictsTotal,
      unresolvedConflictsTotal,
      resolvedConflictsTotal,
      unresolvedConflictsList,
      resolvedConflictsList,
      activeConflictTab,
    } = this.state;

    const { billingServiceProviderDetail, pageClaims } = this.props;
    // Multi Tab
    const billingServiceProviderDetailMultiTabContainer = _.find(
      mustBeArray(pageClaims?.components),
      (o) => o.name === BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER
    );
    // Basic Details
    const billingServiceProviderBasicDetailTabContainer = _.find(
      mustBeArray(billingServiceProviderDetailMultiTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_DETAIL_TAB_CONTAINER
    );
    const billingServiceProviderBasicDetailChildTabContainer = _.find(
      mustBeArray(billingServiceProviderBasicDetailTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_DETAIL_CHILD_TAB_CONTAINER
    );
    const billingServiceProviderLogoChildTabContainer = _.find(
      mustBeArray(billingServiceProviderBasicDetailTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_LOGO_CHILD_TAB_CONTAINER
    );

    // Xero Mapping
    const billingServiceProviderXeroMappingTabContainer = _.find(
      mustBeArray(billingServiceProviderDetailMultiTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_XERO_MAPPING_TAB_CONTAINER
    );
    const billingServiceProviderXeroMappingChildTabContainer = _.find(
      mustBeArray(billingServiceProviderXeroMappingTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_XERO_MAPPING_CHILD_TAB_CONTAINER
    );

    const xeroMappingCardGrid = this.getXeroMappingCardGrid();

    // Account
    const billingServiceProviderAccountTabContainer = _.find(
      mustBeArray(billingServiceProviderDetailMultiTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_ACCOUNT_TAB_CONTAINER
    );
    const billingServiceProviderAccountChildTabContainer = _.find(
      mustBeArray(billingServiceProviderAccountTabContainer?.components),
      (o) => o.name === BILLING_PROVIDER_ACCOUNT_CHILD_TAB_CONTAINER
    );

    const accountCardGrid = getCurrentCardGrid({
      grid: this.state.accountGrid,
    });

    return (
      <If
        condition={pageLoading}
        then={<Spin />}
        else={
          <div className="module-detail">
            <ModuleInfoCard
              detail={billingServiceProviderDetail}
              type="billingServiceProvider"
            />
            <MultiTabContainer
              name={BILLING_PROVIDER_DETAIL_MULTI_TAB_CONTAINER}
              claims={pageClaims}
              activeKey={activeKey}
              handleTabChange={this.handleTabChange}
            >
              {isComponentVisible({
                claim: billingServiceProviderBasicDetailTabContainer,
              }) && (
                  <TabPane key={"1"} tab="Billing Service Provider Details">
                    <Row gutter={DEFAULT_GUTTER}>
                      <Col lg={6} md={12} sm={24}>
                        <ChildTabContainer
                          name={BILLING_PROVIDER_DETAIL_CHILD_TAB_CONTAINER}
                          claims={billingServiceProviderBasicDetailTabContainer}
                        >
                          <GeneralContainer
                            name={BILLING_PROVIDER_DETAIL_GENERAL_CONTAINER}
                            claims={
                              billingServiceProviderBasicDetailChildTabContainer
                            }
                            md={24}
                            sm={24}
                            lg={24}
                          >
                            <BasicDetails
                              organization={billingServiceProviderDetail}
                              edit={() =>
                                this.handleEdit({ label: "bspDetails" })
                              }
                              entity="billingServiceProvider"
                              claimComponent={BILLING_PROVIDER_EDIT}
                              hasNoComponents={false}
                            />
                          </GeneralContainer>
                        </ChildTabContainer>
                      </Col>
                      <Col lg={6} md={12} sm={24}>
                        <ChildTabContainer
                          name={BILLING_PROVIDER_LOGO_CHILD_TAB_CONTAINER}
                          claims={billingServiceProviderBasicDetailTabContainer}
                        >
                          <GeneralContainer
                            name={BILLING_PROVIDER_LOGO_GENERAL_CONTAINER}
                            claims={billingServiceProviderLogoChildTabContainer}
                            md={24}
                            sm={24}
                            lg={24}
                          >
                            <div className="-mt-5">
                              <Card
                                bordered={false}
                                className="card-space-0"
                                cover={
                                  <img
                                    alt="invoice logo"
                                    className="p-10"
                                    src={billingServiceProviderDetail?.logoURL}
                                  />
                                }
                                title={
                                  <div className="pl-4 pt-4.5">Invoice Logo</div>
                                }
                              ></Card>
                            </div>
                          </GeneralContainer>
                        </ChildTabContainer>
                      </Col>
                    </Row>
                  </TabPane>
                )}

              {isComponentVisible({
                claim: billingServiceProviderAccountTabContainer,
              }) && (
                  <TabPane
                    key={"2"}
                    tab={<TabHeader title="Accounts" total={accountTotal} />}
                  >
                    <ChildTabContainer
                      name={BILLING_PROVIDER_ACCOUNT_CHILD_TAB_CONTAINER}
                      claims={billingServiceProviderAccountTabContainer}
                    >
                      <ListContainer
                        name={BILLING_PROVIDER_ACCOUNT_LIST_CONTAINER}
                        claims={billingServiceProviderAccountChildTabContainer}
                        isPinned={accountIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={accountGridable}
                        gridView={accountGridView}
                        grid={accountGrid}
                        per_page={accountPerPage}
                        GeneralTablePaginatedComponent={
                          BillinServiceProviderAccountList
                        }
                        GeneralTableScrollableComponent={
                          ScrollableBillinServiceProviderAccountList
                        }
                        generalTableComponentName={
                          BILLING_PROVIDER_ACCOUNT_GENERAL_TABLE
                        }
                        gridComponentName={BILLING_PROVIDER_ACCOUNT_GENERAL_GRID}
                        createLinkButtonName={BILLING_PROVIDER_ACCOUNT_ADD}
                        handleAddAction={this.addAccount}
                        handleGridChange={this.handleAccountGridChange}
                        fieldOptions={accountFieldOptions}
                        updateGridField={this.updateAccountGridField}
                        sortingType={accountSortingType}
                        paginationType={accountPaginationType}
                        list={accountList}
                        listLoading={accountListLoading}
                        listGrid={this.listAccountGrid}
                        listScrollingGrid={this.listScrollingGridAccount}
                        hasMoreData={accountHasMoreData}
                        handleGridAction={this.handleAccountAction}
                        listScrollingGeneralTable={this.listScrollingAccount}
                        total={accountTotal}
                        current={accountPagination}
                        sortInfo={accountSortInfo}
                        updatePageSize={this.accountUpdatePageSize}
                        listGeneralTable={this.listAccounts}
                        gridPageSize={this.getAccountGridPageSize()}
                        cardGrid={accountCardGrid}
                        pinnedComponentName="accountIsPinned"
                        showAddAndGrid={true}
                        addModuleName="Account"
                        DisplayCardComponent={AccountDisplayCard}
                        editComponent={BILLING_PROVIDER_ACCOUNT_EDIT}
                        gridEditComponent={BILLING_PROVIDER_ACCOUNT_EDIT}
                        deleteComponent={BILLING_PROVIDER_ACCOUNT_DELETE}
                        gridDeleteComponent={BILLING_PROVIDER_ACCOUNT_DELETE}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
              {
                <TabPane
                  key={"3"}
                  tab={
                    <TabHeader
                      title="Account Conflicts"
                      total={conflictsTotal}
                    />
                  }
                >
                  <ConflictsTab
                    activeKey={activeConflictTab}
                    unresolvedConflictsList={unresolvedConflictsList}
                    unresolvedConflictsTotal={unresolvedConflictsTotal}
                    resolvedConflictsList={resolvedConflictsList}
                    resolvedConflictsTotal={resolvedConflictsTotal}
                    pageClaims={pageClaims}
                    handleTabChange={this.handleConflictTabChange}
                    handleResolveConflict={this.handleResolveConflict}
                  />
                </TabPane>
              }
              {isComponentVisible({
                claim: billingServiceProviderXeroMappingTabContainer,
              }) && (
                  <TabPane
                    key={"4"}
                    tab={
                      <TabHeader title="Xero Mappings" total={xeroMappingTotal} />
                    }
                  >
                    <ChildTabContainer
                      name={BILLING_PROVIDER_XERO_MAPPING_CHILD_TAB_CONTAINER}
                      claims={billingServiceProviderXeroMappingTabContainer}
                    >
                      <ListContainer
                        name={BILLING_PROVIDER_XERO_MAPPING_LIST_CONTAINER}
                        claims={
                          billingServiceProviderXeroMappingChildTabContainer
                        }
                        isPinned={xeroMappingIsPinned}
                        updatePinnedComponent={this.updatePinnedComponent}
                        gridable={xeroMappingGridable}
                        gridView={xeroMappingGridView}
                        grid={xeroMappingGrid}
                        per_page={xeroMappingPerPage}
                        GeneralTablePaginatedComponent={XeroMAppingList}
                        GeneralTableScrollableComponent={XeroMAppingList}
                        generalTableComponentName={
                          BILLING_PROVIDER_XERO_MAPPING_GENERAL_TABLE
                        }
                        gridComponentName={
                          BILLING_PROVIDER_XERO_MAPPING_GENERAL_GRID
                        }
                        createLinkButtonName={
                          BILLING_PROVIDER_XERO_MAPPING_ADD_BUTTON
                        }
                        handleAddAction={this.addXeroMapping}
                        handleGridChange={this.handleXeroMappingGridChange}
                        fieldOptions={xeroMappingFieldOptions}
                        updateGridField={this.updateXeroMappingGridField}
                        sortingType={xeroMappingSortingType}
                        paginationType={xeroMappingPaginationType}
                        list={xeroMappingList}
                        listLoading={xeroMappingListLoading}
                        listGrid={this.listXeroMappingGrid}
                        listScrollingGrid={this.listScrollingGridXeroMapping}
                        hasMoreData={xeroMappingHasMoreData}
                        handleGridAction={this.handleXeroMappingAction}
                        listScrollingGeneralTable={this.listScrollingXeroMapping}
                        total={xeroMappingTotal}
                        current={xeroMappingPagination}
                        sortInfo={xeroMappingSortInfo}
                        updatePageSize={this.xeroMappingUpdatePageSize}
                        listGeneralTable={({ options }) =>
                          this.listXeroMappings({
                            options: {
                              ...options,
                            },
                          })
                        }
                        gridPageSize={this.getXeroMappingGridPageSize()}
                        cardGrid={xeroMappingCardGrid}
                        pinnedComponentName="xeroMappingIsPinned"
                        showAddAndGrid={true}
                        addModuleName="Xero Mapping"
                        DisplayCardComponent={XeroMappingDisplayCard}
                        handleEdit={this.editXeroCode}
                      />
                    </ChildTabContainer>
                  </TabPane>
                )}
            </MultiTabContainer>

            <AddXeroMapping
              visible={viewAddXeroAccount}
              activeRecord={activeRecord}
              handleSubmit={this.updateXeroMapping}
              handleCancel={this.cancelUpdatingXeroCode}
              saveXeroLoading={saveXeroLoading}
            />
          </div>
        }
      />
    );
  }
}

export default withRouter(BillingServiceProviderDetailIndex);
