import { AmendmentListWidget } from '@xemplo/amendment-list-widget';
import { PayrunListWidget } from '@xemplo/payrun-list-widget';

import * as S from './gp-dashboard.styles';

export function GpDashboard() {
  return (
    <S.Container>
      <S.Heading> Dashboard</S.Heading>
      <S.DashboardGrid>
        <PayrunListWidget />
        <AmendmentListWidget />
      </S.DashboardGrid>
    </S.Container>
  );
}

export default GpDashboard;
