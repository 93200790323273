//@ts-nocheck
import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Modal, Select, Spin } from "antd";
import { guid, mustBeArray, errorDisplay } from "../../../libs/utilities";
import _ from "lodash";

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class PayScheduleConnect extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      schedules: [],
      businessunits: [],
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      disableAPIKey: false,
      connectionList: [],
      fetching: false,
      fetchingBusinessunit: false,
      fetchingPayschedule: false,
      externalBusinessunitList: [],
      externalPayscheduleList: [],
      confirmLoading: false,
      payrollSystemConnectionName: "",
      externalBusinessUnitName: "",
      externalPayScheduleName: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      schedules: [],
      businessunits: [],
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      disableAPIKey: false,
      payrollSystemConnectionName: "",
      externalBusinessUnitName: "",
      externalPayScheduleName: "",
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.visible && newProps.visible !== this.props.visible) {
      const { businessunitDetails, payrollProviderId } = newProps;
      this.setState({
        fetching: true,
        fetchingBusinessunit: true,
        fetchExternalPayschedules: true,
      });
      this.getPayrollConnection({ businessunitDetails, payrollProviderId });
      this.getExternalBusinessUnits({ businessunitDetails });
      this.getExternalPayschedules({ businessunitDetails });
    }
  }

  getPayrollConnection = ({ businessunitDetails, payrollProviderId }) => {
    this.props
      .fetchPayrollConnectionName({
        id: payrollProviderId,
        options: {},
      })

      .then((resp) => {
        this.setState({ fetching: false });

        if (resp.status) {
          this.setState({
            connectionList: mustBeArray(resp.data),

            payrollSystemConnectionName: mustBeArray(resp.data).find(
              (o) =>
                Number(o.id) ===
                Number(businessunitDetails.payrollSystemConnectionId)
            )?.name,
          });

          this.props.form.setFieldsValue({
            payrollSystemConnectionId:
              businessunitDetails.payrollSystemConnectionId,
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  getExternalBusinessUnits = ({ businessunitDetails }) => {
    this.props
      .fetchExternalBusinessunit({
        options: {
          payrollSystemConnectionId:
            businessunitDetails.payrollSystemConnectionId,
        },
      })
      .then((response) => {
        this.setState({ fetchingBusinessunit: false });

        if (response.status) {
          this.setState({
            externalBusinessunitList: mustBeArray(response.data),

            externalBusinessUnitName: mustBeArray(response.data).find(
              (o) =>
                Number(o.id) ===
                Number(businessunitDetails.externalBusinessUnitId)
            )?.name,
          });

          this.props.form.setFieldsValue({
            externalBusinessUnitCode: Number(
              businessunitDetails.externalBusinessUnitId
            ),
          });
        } else {
          errorDisplay(response?.data?.validationErrors, true);
        }
      });
  };

  getExternalPayschedules = ({ businessunitDetails }) => {
    this.props
      .fetchExternalPayschedules({
        id: businessunitDetails.externalBusinessUnitId,
        options: {
          payrollSystemConnectionId:
            businessunitDetails.payrollSystemConnectionId,
          externalBusinessUnitId: businessunitDetails.externalBusinessUnitId,
        },
      })
      .then((resp) => {
        this.setState({ fetchingPayschedule: false });

        if (resp.status) {
          this.setState({ externalPayscheduleList: mustBeArray(resp.data) });
        } else {
          if (!_.isEmpty(mustBeArray(this.state.externalBusinessunitList))) {
            errorDisplay(resp?.data?.validationErrors, true);
          }
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      buttonProps: { confirmLoading: true, text: "Connecting .." },
    });

    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err, "error");
        return;
      }
      let finalValue = {
        externalPayScheduleCode: values.externalPayScheduleCode,
        payrollSystemConnectionId: values.payrollSystemConnectionId,
        externalBusinessUnitCode: values.externalBusinessUnitCode,
      };
      const {
        payrollSystemConnectionName,
        externalBusinessUnitName,
        externalPayScheduleName,
      } = this.state;

      // Frequency of the selected external pay schedule
      const externalPayScheduleFrequency = mustBeArray(
        this.state.externalPayscheduleList
      ).find((o) => o.id === values.externalPayScheduleCode)?.frequency;
      this.props.createConnection(
        finalValue,
        payrollSystemConnectionName,
        externalBusinessUnitName,
        externalPayScheduleName,
        externalPayScheduleFrequency
      );
    });
  };

  fetchPayrollConnectionName = (value) => {
    this.setState({ fetching: true });

    this.props
      .fetchPayrollConnectionName({
        id: this.props.payrollProviderId,
        options: { payrollConnectionName: value },
      })

      .then((resp) => {
        this.setState({ fetching: false });

        if (resp.status) {
          this.setState({ connectionList: mustBeArray(resp.data) });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  fetchExternalBusinessunit = () => {
    this.setState({ fetchingBusinessunit: true });

    this.props
      .fetchExternalBusinessunit({
        options: {
          payrollSystemConnectionId: this.props.form.getFieldValue(
            "payrollSystemConnectionId"
          ),
        },
      })

      .then((resp) => {
        this.setState({ fetchingBusinessunit: false });

        if (resp.status) {
          this.setState({ externalBusinessunitList: mustBeArray(resp.data) });
        } else {
          errorDisplay(resp?.data?.validationErrors, true);
        }
      });
  };

  fetchExternalPayschedules = () => {
    this.setState({ fetchingPayschedule: true });

    this.props
      .fetchExternalPayschedules({
        id: this.props.form.getFieldValue("externalBusinessUnitCode"),
        options: {
          payrollSystemConnectionId: this.props.form.getFieldValue(
            "payrollSystemConnectionId"
          ),

          externalBusinessUnitId: this.props.form.getFieldValue(
            "externalBusinessUnitCode"
          ),
        },
      })

      .then((resp) => {
        this.setState({ fetchingPayschedule: false });

        if (resp.status) {
          this.setState({ externalPayscheduleList: mustBeArray(resp.data) });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  handleChangePayschedule = (value, i) => {
    this.setState({
      externalPayScheduleName: i?.props?.children,
    });
  };

  handleCancel = () => {
    this.setState({
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      disableAPIKey: false,
      schedules: [],
      businessunits: [],
    });

    this.props.handleCancel();
  };

  render() {
    const {
      connectionList,
      fetching,
      fetchingBusinessunit,
      fetchingPayschedule,
      externalPayscheduleList,
      externalBusinessunitList,
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const { confirmLoading } = this.props;
    const isDisabled = !(
      this.props.form.getFieldValue("externalBusinessUnitCode") &&
      this.props.form.getFieldValue("externalPayScheduleCode")
    )
      ? true
      : false;
    return (
      <Modal
        width={700}
        visible={this.props.visible}
        title={this.props.title}
        onCancel={this.handleCancel}
        footer={[
          <Button
            key="submit"
            className={`rounded-[20px] ${isDisabled ? "" : "bg-success"}`}
            onClick={this.handleSubmit}
            loading={confirmLoading}
            disabled={isDisabled}
          >
            Confirm
          </Button>,
          <Button
            key="back"
            onClick={this.handleCancel}
            className="rounded-[20px]"
          >
            Cancel
          </Button>,
        ]}
        className="connectModal default-form-margin"
      >
        <Form
          className="pay-schedule-connect"
          layout="vertical"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <div>
            <FormItem {...formItemLayout} label="Connection Name:">
              {getFieldDecorator("payrollSystemConnectionId", {
                rules: [
                  {
                    required: true,
                    message: "Please input payroll system name",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select payroll system"
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.fetchPayrollConnectionName}
                  className="w-full "
                  disabled
                >
                  {mustBeArray(connectionList).map((d) => (
                    <Option key={guid()} value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </div>

          <div>
            <FormItem {...formItemLayout} label="External Business Unit:">
              {getFieldDecorator("externalBusinessUnitCode", {
                rules: [
                  {
                    required: true,
                    message: "Please select a business unit",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select business unit"
                  notFoundContent={
                    fetchingBusinessunit ? <Spin size="small" /> : null
                  }
                  filterOption={false}
                  onSearch={this.fetchExternalBusinessunit}
                  className="w-full "
                  disabled
                >
                  {mustBeArray(externalBusinessunitList).map((d) => (
                    <Option key={guid()} value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </div>
          <div>
            <FormItem {...formItemLayout} label="External Payschedule:">
              {getFieldDecorator("externalPayScheduleCode", {
                rules: [
                  {
                    required: true,
                    message: "Please select an external payschedule",
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="Select payschedule"
                  notFoundContent={
                    fetchingPayschedule ? <Spin size="small" /> : null
                  }
                  filterOption={false}
                  onSearch={this.fetchExternalPayschedules}
                  onSelect={this.handleChangePayschedule}
                  className="w-full "
                >
                  {mustBeArray(externalPayscheduleList).map((d) => (
                    <Option key={guid()} value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(PayScheduleConnect);
