import {
  ForwardedRef,
  forwardRef,
  Ref,
  RefObject,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import classnames from 'classnames';

import { FormDialogPopover } from '@xemplo/form-dialog-popover';
import { DawnArrowDown16 } from '@xemplo/icons';
import {
  InputFieldSize,
  LabelHelperText,
  MaybeErrorOrDescription,
} from '@xemplo/input-utils';
import { ListBox, ListItem, SelectionMode, SelectionStyle } from '@xemplo/listbox';

import { useMultiState } from '../hooks/use-multi-state';
import { useOutsideClick } from '../hooks/use-outside-click';
import { SingleValue } from '../single-value/single-value';
import { Tags } from '../tags/tags';
import * as S from '../v1/text-dropdown-multi.style';

import { TextDropdownMultiV2Props } from './text-dropdown-multi-v2.types';

export const TextDropdownMultiV2TestId = {
  wrapper: (testId?: string) => `dropdown-multi-wrapper-${testId}`,
  container: (testId?: string) => `dropdown-multi-container-${testId}`,
  select: (testId?: string) => `dropdown-multi-select-${testId}`,
  label: (testId?: string) => `dropdown-multi-label-${testId}`,
  placeholder: (testId?: string) => `dropdown-multi-placeholder-${testId}`,
  trailingIcon: (testId?: string) => `dropdown-multi-trailing-icon-${testId}`,
  arrow: (testId?: string) => `dropdown-multi-arrow-${testId}`,
  labelHelperText: (testId?: string) => `dropdown-multi-label-helper-text-${testId}`,
};

function TextDropdownMultiV2Internal<T extends ListItem>(
  props: TextDropdownMultiV2Props<T>,
  ref?: Ref<HTMLSelectElement>
) {
  const {
    items,
    className,
    testId,
    selectProps,
    displayProps,
    name,
    rules,
    isDropdownMulti = true,
  } = props;

  const { register, getFieldState, formState } = useFormContext();
  const { ref: registeredRef, onBlur, onChange, ...rest } = register(name, rules);

  const internalRef = useRef<HTMLSelectElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const selectRef = (ref as RefObject<HTMLSelectElement>) ?? internalRef;
  const singleValueRef = useRef<HTMLDivElement | null>(null);
  const selectionMode = isDropdownMulti ? SelectionMode.Multiple : SelectionMode.Single;
  const multiState = useMultiState({
    originalProps: props,
    containerRef,
    selectRef,
    listRef,
    singleValueRef,
  });

  const {
    listState,
    popoverState,
    searchState,
    selectProps: selectPropsInternal,
    handleContainerClick,
  } = multiState;

  useOutsideClick({ containerRef, popoverState, selectRef });
  const fieldValue = useWatch({ name });
  const { invalid, error } = getFieldState(name, formState);

  const inputSize = useMemo(
    () => displayProps?.inputSize ?? InputFieldSize.Standard,
    [displayProps?.inputSize]
  );

  const canShowOptionalLabel =
    inputSize === InputFieldSize.Large && !displayProps?.isReadOnly;

  useEffect(() => {
    // This is a workaround to clear the selection when we reset the form
    // programatically. Not sure this is the correct way, will need to keep
    // an eye on this and check if there's any side effect.
    if (fieldValue === undefined) {
      listState.selectionManager.clearSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  const isEmpty = !items?.length;
  const placeholderIsOnlyItem = items.length === 1 && items[0].key === '-1';
  const disableSingleSelect = !isDropdownMulti && (isEmpty || placeholderIsOnlyItem);

  return (
    <S.Wrapper
      className={className}
      $width={displayProps?.width}
      data-testid={TextDropdownMultiV2TestId.wrapper(testId)}
    >
      <S.Container
        ref={containerRef}
        $inputSize={inputSize}
        onClick={handleContainerClick}
        className={classnames([inputSize], {
          'input-has-error': invalid,
          'input-readonly': displayProps?.isReadOnly,
          'input-disabled': displayProps?.isDisabled || disableSingleSelect,
          focused: popoverState.isOpen,
        })}
        data-testid={TextDropdownMultiV2TestId.container(testId)}
      >
        {displayProps?.leadingIcon && (
          <S.LeadingIcon className={classnames([inputSize])}>
            {displayProps?.leadingIcon}
          </S.LeadingIcon>
        )}
        {selectProps?.placeholder && (
          <S.Placeholder
            className={classnames({
              'is-hidden': inputSize === InputFieldSize.Large && !popoverState.isOpen,
              'has-leading-icon': !!displayProps?.leadingIcon,
              'has-label': !!(inputSize === InputFieldSize.Large && selectProps?.label),
              'input-readonly': displayProps?.isReadOnly,
            })}
            data-testid={TextDropdownMultiV2TestId.placeholder(testId)}
          >
            {selectProps.placeholder}
          </S.Placeholder>
        )}
        <S.Label
          data-testid={TextDropdownMultiV2TestId.label(testId)}
          className={classnames({
            'move-up': popoverState.isOpen || !listState.selectionManager.isEmpty,
            'has-label': !!(inputSize === InputFieldSize.Large && selectProps?.label),
            'has-leading-icon': !!displayProps?.leadingIcon,
            'input-readonly': displayProps?.isReadOnly,
          })}
        >
          {inputSize === InputFieldSize.Large && selectProps?.label}

          {canShowOptionalLabel && (
            <LabelHelperText
              data-testid={TextDropdownMultiV2TestId.labelHelperText(testId)}
              className="label-helper-text"
            >
              (optional)
            </LabelHelperText>
          )}
        </S.Label>
        <S.Select
          {...selectPropsInternal}
          aria-haspopup="listbox"
          className={classnames([inputSize], {
            'has-leading-icon': !!displayProps?.leadingIcon,
            'has-label': !!selectProps?.label,
          })}
          data-testid={TextDropdownMultiV2TestId.select(testId)}
          {...rest}
          onChange={(e) => {
            selectPropsInternal?.onChange?.(e);
            onChange(e);
          }}
          ref={(e) => {
            registeredRef(e);
            internalRef.current = e;
          }}
        >
          {items.map((item) => (
            <option key={item.key} value={item.key} hidden>
              {item.value}
            </option>
          ))}
        </S.Select>{' '}
        {isDropdownMulti ? (
          <Tags
            state={listState}
            hasLeadingIcon={!!displayProps?.leadingIcon}
            testId={testId}
            items={items}
            hasSiblings={!!(inputSize === InputFieldSize.Large && selectProps?.label)}
          />
        ) : (
          <SingleValue
            state={listState}
            hasLeadingIcon={!!displayProps?.leadingIcon}
            testId={testId}
            items={items}
            popoverState={popoverState}
            ref={singleValueRef}
            {...searchState}
            hasSiblings={!!(inputSize === InputFieldSize.Large && selectProps?.label)}
          />
        )}
        <S.ArrowIcon
          className={classnames({
            open: popoverState.isOpen,
            'has-error': invalid,
          })}
          data-testid={TextDropdownMultiV2TestId.arrow(testId)}
        >
          <DawnArrowDown16 />
        </S.ArrowIcon>
      </S.Container>
      <MaybeErrorOrDescription
        error={invalid}
        errorMessage={error?.message ?? internalRef.current?.validationMessage}
        description={displayProps?.description}
      />
      {popoverState.isOpen && (
        <FormDialogPopover
          triggerRef={containerRef}
          state={popoverState}
          containerPadding={-10}
          isNonModal
        >
          <ListBox
            ref={listRef}
            items={items}
            state={listState}
            aria-label={selectProps?.label}
            aria-labelledby={`${name}-label`}
            selectionStyle={
              isDropdownMulti ? SelectionStyle.Checkbox : SelectionStyle.Highlight
            }
            selectionMode={selectionMode}
            width={`${containerRef.current?.clientWidth ?? 0}px`}
            testId={testId}
            {...searchState}
          />
        </FormDialogPopover>
      )}
    </S.Wrapper>
  );
}

export const TextDropdownMultiV2 = forwardRef(TextDropdownMultiV2Internal) as <
  T extends ListItem
>(
  props: TextDropdownMultiV2Props<T> & {
    ref?: ForwardedRef<HTMLSelectElement>;
    name: string;
  }
) => JSX.Element;

export default TextDropdownMultiV2;
