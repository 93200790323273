// @ts-nocheck
import { http, ResponseHandler, SimpleErrorHandler } from "../../libs";
import {
  requestBuilder,
  getUserArea,
  getConditionalResponse,
} from "../../libs/utilities";
import CountryCoordinates from "../../Common/countries";
import _ from "lodash";
import { companyAction } from "../../constants/action-type/index";
import { months } from "../../constants/entities";
import APIHandler from "../../constants/apiUrl";
import userRoles from "../../constants/userRoles";

const {
  LIST_COMPANIES,
  COMPANY_DETAIL,
  LIST_COMPANY_USERS,
  LIST_COMPANY_BUSINESS_UNITS,
  LIST_COMPANY_INVOICES,
  LIST_COMPANY_USER_DETAILS,
} = companyAction;

export function listCompanies({ id, options, cancelToken, targetEndpoint }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url =
        targetEndpoint ||
        APIHandler.constructEndpoint({ endpoint: "COMPANY_URL" });
      const response = await requestBuilder(
        targetEndpoint ? null : id,
        url,
        targetEndpoint ? {} : queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, LIST_COMPANIES)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: LIST_COMPANIES,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listCompanyUsers({
  id,
  options,
  cancelToken,
  companyId,
  targetEndpoint,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
        q: { companyId, ...options.q },
      };
      const url = APIHandler.constructEndpoint({
        endpoint: `COMPANY_USERS_URL${getConditionalResponse({
          condition: [userRoles.CompanyArea].includes(getUserArea()),
          resp1: "_COMPANYUSER",
          resp2: "",
        })}`,
      });
      const response = await requestBuilder(
        id,
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: targetEndpoint,
          resp2: url,
        }),
        getConditionalResponse({
          condition: targetEndpoint,
          resp1: {},
          resp2: queryOptions,
        }),
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              getConditionalResponse({
                condition: response.data.result,
                resp1: response.data.result,
                resp2: response.data,
              }),
              getConditionalResponse({
                condition: id,
                resp1: LIST_COMPANY_USER_DETAILS,
                resp2: LIST_COMPANY_USERS,
              })
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: id ? LIST_COMPANY_USER_DETAILS : LIST_COMPANY_USERS,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function registerCompanyUsers({ data, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: `COMPANY_USER_REGISTRATION${
          [userRoles.CompanyArea].includes(localStorage.getItem("userArea"))
            ? "_COMPANYUSER"
            : ""
        }`,
      });
      const response = await http.post(url, data);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data, "NEW_USER_REGISTRATION")
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "NEW_USER_REGISTRATION",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function handleCompanyUserActions({ id, action, data, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let response = "";
      let url = APIHandler.constructEndpoint({
        endpoint: `COMPANY_USERS_ACTION_URL${
          [userRoles.CompanyArea].includes(getUserArea()) ? "_COMPANYUSER" : ""
        }`,
        options: { id, action },
      });
      switch (action && action.toLowerCase()) {
        case "update":
          response = await http.put(url, data, cancelToken).catch(SimpleErrorHandler);
          break;
        case "delete":
          response = await http.del(url, cancelToken).catch(SimpleErrorHandler);
          break;
        case "status":
          response = await http.patch(url, data, cancelToken).catch(SimpleErrorHandler);
          break;
        case "sendreminder":
          response = await http.post(url, {}, cancelToken).catch(SimpleErrorHandler);
          break;
        case "enableall":
        case "disableall":
        case "mandatoryalldeviceenrolment":
        case "disablealldeviceenrolment":
        case "optionalalldeviceenrolment":
          url = APIHandler.constructEndpoint({
            endpoint: `COMPANY_USER_MULTI_FACTOR_ENABLE_URL`,
            options: { id },
          });
          response = await http.post(url, data, cancelToken).catch(SimpleErrorHandler);
          break;
        case "updatemultifactorauthentication":
        case "updatedeviceenrolment":
        case "disabledeviceenrolment":
        case "mandatorydeviceenrolment":
        case "optionaldeviceenrolment":
          url = APIHandler.constructEndpoint({
            endpoint: `USER_MULTI_FACTOR_ENABLE_URL`,
            options: { id },
          });
          response = await http.post(url, data, cancelToken).catch(SimpleErrorHandler);
          break;
        case "sendinvite":
        case "resetpassword":
        case "verifypassword":
          response = http.post(url).catch(SimpleErrorHandler);
          break;
        default:
          break;
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response.data, "USER_ACTIONS"))
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "USER_ACTIONS",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listCompanyBusinessUnits({
  id,
  options,
  cancelToken,
  targetEndpoint = null,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      const url =
        targetEndpoint ||
        APIHandler.constructEndpoint({
          endpoint: id ? "COMPANY_BUSINESS_UNIT_URL" : "BUSINESS_UNIT_URL",
          options: { id },
        });
      const response = await requestBuilder(
        null,
        targetEndpoint || url,
        targetEndpoint ? {} : queryOptions,
        cancelToken
      );

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            LIST_COMPANY_BUSINESS_UNITS
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function companyLookup({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "COMPANY_LOOKUP_URL",
      });
      let queryOptions = {
        ...options,
        per_page: options && options.per_page ? options.per_page : 20,
      };
      const response = await requestBuilder(id, url, queryOptions, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, "COMPANY_LOOKUP")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getCompany({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({ endpoint: "COMPANY_URL" });
      const response = await requestBuilder(id, url, queryOptions, cancelToken);
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, COMPANY_DETAIL)
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function saveCompany(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "COMPANY_URL" });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "COMPANY_SAVE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updateCompany(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "COMPANY_URL" });
      const response = await http.put(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "COMPANY_UPDATE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listCompanyInvoices({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "COMPANY_INVOICE",
        options: { id },
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              LIST_COMPANY_INVOICES
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: LIST_COMPANY_INVOICES,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getCompanyPayrunData({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "COMPANY_PAYRUNDATA",
        options: { id },
      });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              `COMPANY_PAYRUN_${options.type}`
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: `COMPANY_PAYRUN_${options.type}`,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function totalPayrollPEO({ month }) {
  return (dispatch, store) =>
    new Promise(async (resolve, reject) => {
      const totalPEO = store().company.payrunTotal;
      let monthTotal = 0;
      let prevMonthDiff = 0;
      let graphData = [];
      _.each(totalPEO, (payrun) => {
        const monthPayroll = _.filter(
          payrun.monthlyPayRunData,
          (monthPayrun) => Number(monthPayrun.month) === Number(month)
        );
        const prevMonthPayroll = _.filter(
          payrun.monthlyPayRunData,
          (monthPayrun) => Number(monthPayrun.month) === Number(month) - 1
        );
        monthTotal = _.sumBy(monthPayroll, (payroll) => payroll.totalAmount);
        prevMonthDiff = _.sumBy(
          prevMonthPayroll,
          (payroll) => payroll.totalAmount
        );
        _.each(months, (eachMonth) => {
          const eachMonthPayroll = _.filter(
            payrun.monthlyPayRunData,
            (monthPayrun) => Number(monthPayrun.month) === eachMonth.value
          );
          const eachMonthTotal = _.sumBy(
            eachMonthPayroll,
            (payroll) => payroll.totalAmount
          );
          graphdata.push({ x: eachMonth.name, y: eachMonthTotal.toFixed(2) });
        });
      });
      return resolve({
        status: true,
        data: {
          graphData: graphData,
          total: monthTotal,
          difference:
            prevMonthDiff !== 0
              ? (((monthTotal - prevMonthDiff) / prevMonthDiff) * 100).toFixed(
                  2
                )
              : monthTotal === 0
              ? 0
              : 100,
        },
      });
    }).catch(async (err) => {
      throw err.message;
    });
}

export function countryPayrunData() {
  return (dispatch, store) =>
    new Promise(async (resolve, reject) => {
      const payrunTotal = store().company.payrunTotal;
      let countryPayrundata = [];
      _.each(payrunTotal, (payrun) => {
        const countryTotal = _.sumBy(
          payrun.monthlyPayRunData,
          (payroll) => payroll.totalAmount
        );
        const countryData = {
          id: payrun.countryID,
          name: payrun.countryName,
          coordinates: CountryCoordinates.getCoordinates(payrun.countryName),
          amount: countryTotal,
        };
        countryPayrundata.push(countryData);
      });
      return resolve({
        status: true,
        data: { countryPayrundata: countryPayrundata },
      });
    }).catch(async (err) => {
      throw err.message;
    });
}

export function countryMonthlyData({ month, year }) {
  return (dispatch, store) =>
    new Promise(async (resolve, reject) => {
      const payrunTotal = store().company.payrunTotal;
      let countryMonthlydata = [];
      let monthTotal = 0;
      let prevMonthDiff = 0;
      _.each(payrunTotal, (payrun) => {
        const monthPayroll = _.filter(
          payrun.monthlyPayRunData,
          (monthPayrun) =>
            Number(monthPayrun.month) === Number(month) &&
            Number(monthPayrun.year) === Number(year)
        );
        const prevMonthPayroll = _.filter(
          payrun.monthlyPayRunData,
          (monthPayrun) =>
            Number(monthPayrun.month) === Number(month - 1) &&
            Number(monthPayrun.year) === Number(year)
        );
        monthTotal = _.sumBy(monthPayroll, (payroll) => payroll.totalAmount);
        prevMonthDiff = _.sumBy(
          prevMonthPayroll,
          (payroll) => payroll.totalAmount
        );
        let graphData = [];
        _.each(months, (eachMonth) => {
          const eachMonthPayroll = _.filter(
            payrun.monthlyPayRunData,
            (monthPayrun) =>
              Number(monthPayrun.month) === eachMonth.value &&
              Number(monthPayrun.year) === Number(year)
          );
          const eachMonthTotal = _.sumBy(
            eachMonthPayroll,
            (payroll) => payroll.totalAmount
          );
          graphdata.push({ x: eachMonth.name, y: eachMonthTotal.toFixed(2) });
        });
        const countryData = {
          id: payrun.countryID,
          graphData: graphData,
          countryName: payrun.countryName,
          countryCode: CountryCoordinates.getCode(payrun.countryName),
          difference:
            prevMonthDiff !== 0
              ? (((monthTotal - prevMonthDiff) / prevMonthDiff) * 100).toFixed(
                  2
                )
              : monthTotal === 0
              ? 0
              : 100,
        };
        countryMonthlydata.push(countryData);
      });
      return resolve({
        status: true,
        data: { countryMonthlydata: countryMonthlydata },
      });
    }).catch(async (err) => {
      throw err.message;
    });
}

export function handleEmailTemplatesActions({
  id,
  options,
  action,
  cancelToken,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      let url = "";
      let response = "";
      let action_type = "";
      dispatch({ type: "SET_PROGRESS_BAR" });
      switch (action) {
        case "list":
          url = APIHandler.constructEndpoint({
            endpoint: "EMAIL_TEMPLATES_URL",
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          action_type = "LIST_EMAIL_TEMPLATES";
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          break;
        case "get":
          url = APIHandler.constructEndpoint({
            endpoint: "EMAIL_TEMPLATES_URL",
            id,
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          action_type = "GET_EMAIL_TEMPLATE";
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          break;
        default:
          console.log("unregonized action");
          break;
      }
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, action_type)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: action_type,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
