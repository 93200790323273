import { MANUAL_RETURN_URL } from "../constants";

const pathToSkip = [
  "home",
  "login",
  "logout",
  "confirm-login",
  "confirm-logout",
  "redirect",
];

function isPathToSkip(pathname: string) {
  if (pathname === "/") return false;
  return pathToSkip.some((path) => pathname.includes(path));
}

export function handleReturnUrl() {
  const { href, pathname } = window.location;
  if (isPathToSkip(pathname)) return;
  sessionStorage.setItem(MANUAL_RETURN_URL, href);
}

export function getReturnUrl() {
  let returnUrl = sessionStorage.getItem(MANUAL_RETURN_URL);
  if (!returnUrl) return null;

  const url = new URL(returnUrl);
  const pathName = url.pathname;
  if (isPathToSkip(pathName)) {
    sessionStorage.removeItem(MANUAL_RETURN_URL);
    return null;
  }

  return url.pathname !== "/" ? url.pathname + url.search : null;
}
