import { Sidebar } from "@xemplo/sidebar";
import { useMemo } from "react";

import { DEFAULT_MENU_ITEMS, SCOPES } from "../../constants";
import { useAppSelector } from "../../hooks";
import { findAuthorizationClaims, isComponentVisible } from "../../libs";
import { RootState } from "../../store";

export const AppSidebar = () => {
  const { details } = useAppSelector((state: RootState) => state.member);

  const hasSettings = useMemo(() => {
    return findAuthorizationClaims({
      claims: details?.authorizationDOM ?? [],
      name: SCOPES.SETTINGS,
    });
  }, [details?.selectedRole]);

  const visibleItems = useMemo(() => {
    if (!details?.authorizationDOM) return [];

    const items = DEFAULT_MENU_ITEMS.filter((item) => {
      if (hasSettings && item.isHeader) return true;
      const claim = findAuthorizationClaims({
        claims: details.authorizationDOM,
        name: item.id,
      });

      return isComponentVisible({ claim });
    });

    return items;
  }, [details?.selectedRole]);

  return <Sidebar items={visibleItems} offset={80}></Sidebar>;
};
