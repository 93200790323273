import { createContext, useContext, useEffect, useRef } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteList,
  resetSlice,
  resetList,
  initList,
  setLoading,
  dataLoaded,
  updateGrid,
  updateTable,
  gridView,
  initialPersonalization,
  initialDashPersonalization,
} from "../../slices/list";

const SliceContext = createContext<any>({});

const SliceListProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const id = useRef(nanoid()).current;

  useEffect(() => {
    // Initialize list slice
    dispatch(initList(id));

    return () => {
      // remove the list from the store
      dispatch(deleteList(id));
    };
  }, []);

  return <SliceContext.Provider value={id}>{children}</SliceContext.Provider>;
};

const useSliceActions = () => {
  const actions: any = {
    deleteList,
    resetSlice,
    resetList,
    initList,
    setLoading,
    dataLoaded,
    updateGrid,
    updateTable,
    gridView,
    initialPersonalization,
    initialDashPersonalization,
  };

  const id = useContext(SliceContext);

  const dispatch = useAppDispatch();

  // return an object with the actions that merge the id with the payload
  return Object.keys(actions).reduce((acc: any, key: any) => {
    acc[key] = (payload: any) =>
      dispatch(actions[key]({ list: id, ...payload }));
    return acc;
  }, {});
};

const useSliceSelector = () => {
  const id = useContext(SliceContext);

  return useAppSelector((state: any) => state.list[id]);
};

export default SliceListProvider;
export { useSliceActions, useSliceSelector };
