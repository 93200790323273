import { forwardRef } from 'react';

import { HeaderIconProps } from './common';
import { FullscreenModalHeaderIcon } from './fullscreen-modal-header-icon';
import { ModalHeaderIcon } from './modal-header-icon';

export const HeaderIcon = forwardRef<HTMLDivElement, HeaderIconProps>(
  ({ isFullScreenModalIcon = false, ...rest }, ref) => {
    return isFullScreenModalIcon ? (
      <FullscreenModalHeaderIcon {...rest} ref={ref} />
    ) : (
      <ModalHeaderIcon {...rest} ref={ref} />
    );
  }
);
