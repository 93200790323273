import React from "react";
import RadioGroupYesNoButton from "../../../ClaimComponents/Button/RadioGroupYesNo";
import InputTypeNumberDefault from "../../../InputTypeNumberDefault";
import { Question } from "../../../../types";

interface YesNoTimePeriodProps {
  question: Question;
  handleChange: (value: any) => void;
  handleDefaultChange: (value: any) => void;
}

const YesNoTimePeriod: React.FC<YesNoTimePeriodProps> = ({
  question,
  handleChange,
  handleDefaultChange,
}) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 items-center mb-4">
      <div className="lg:col-span-1 col-span-2">{question?.label}</div>
      <div className="col-span-1">
        <RadioGroupYesNoButton
          onChange={(e) => handleDefaultChange(e.target.value)}
          value={question?.default ? 1 : 2}
        />
      </div>
      <div className="col-span-1 inline-flex items-center">
        <InputTypeNumberDefault
          disabled={!question?.default}
          question={question}
          handleChange={(value: number) => handleChange(value)}
          fieldType="hours"
        />
        <label className="ml-2">{question.subLabel}</label>
      </div>
    </div>
  );
};

export default YesNoTimePeriod;
