import { useMemo } from 'react';

import { CustomAxios, CustomAxiosOptions } from '@xemplo/custom-axios';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { DirectoryServices } from './directory-services';

export const useDirectoryServices = (config?: CustomAxiosOptions) => {
  const { dsUrl } = useXemploApiContext();
  if (!dsUrl) {
    throw new Error(
      'Either the Directory Services URL is not set or the Xemplo API Provider is not set up correctly.'
    );
  }

  return useMemo(() => {
    const client = new CustomAxios(config).client();
    return new DirectoryServices({ client, baseUrl: dsUrl });
  }, [config, dsUrl]);
};
