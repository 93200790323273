import { ColumnDef } from '@tanstack/react-table';

import { MonetaryCell } from '@xemplo/table';

import { BulkUploadWarnings } from './bulk-upload.warnings';
import * as S from './bulk-upload-summary.styles';
import { BulkUploadVerifyProps, SummaryTableData } from './bulk-upload-summary.types';
import { SummaryHeader } from './summary-header';

export const columns: ColumnDef<SummaryTableData>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    header: 'Employee ID',
  },
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Employee name',
  },
  {
    id: 'rate',
    accessorKey: 'amount',
    header: 'Rate',
    cell: ({ row }) => <MonetaryCell value={row.original.rate} />,
  },
  {
    id: 'units',
    accessorKey: 'units',
    header: 'Units',
  },
];
export const BulkUploadSummary = (props: BulkUploadVerifyProps) => {
  const { csvDataWarnings, csvData } = props;

  return (
    <S.VerifyContainer>
      <SummaryHeader />
      <S.SummaryTable
        data={csvData ?? []}
        columns={columns}
        enablePagination
        enableRowSelection={false}
      />
      <BulkUploadWarnings warnings={csvDataWarnings} />
    </S.VerifyContainer>
  );
};
