//@ts-nocheck
import React from "react";
import { List } from "antd";
 import { mustBeArray } from "../../../libs/utilities";

const MergeFields = ({
  addMergeField,
  mergeFields
}: any) => {
  return (

    <List

      header={<div> Merge Fields</div>}
      bordered
      className="border-r-none"
      dataSource={mustBeArray(mergeFields && mergeFields.split(","))}
      renderItem={item => (

        <List.Item>
            <span onClick={() => addMergeField(item)} className="hand">
            {item}
          </span>
        </List.Item>
      )}
    />
  );
};
export default MergeFields;
