import React from "react";
import { Checkbox, Form } from "antd";

type Props = {
  onChange: (e: any) => void;
  className?: string;
};

const classNameDefault = "";

const IsRecurring = ({ onChange, className = classNameDefault }: Props) => (
  <Form.Item className={className} name="isReoccurring" valuePropName="checked">
    <Checkbox onChange={onChange}>Is recurring?</Checkbox>
  </Form.Item>
);

export default IsRecurring;
