import styled from 'styled-components';

export const Dialog = styled.dialog`
  margin: 0;
  padding: 4px;
  background: transparent;
  border: none;
  &::backdrop {
    background: transparent;
  }
`;
