import { UserPostApiActions } from '@xemplo/gp-types';

export const RoleEndpoint = {
  authorization: 'api/authorization-access',
};

export const CallbackEndpoint = 'api/callback';

export const UserEndpoints = {
  listCompanyUsers: '<%userArea%>/users',
  listPSPACompanyUsers: `<%userArea%>/users/companyuser`,
  listPSPAServiceProviderUsers: `<%userArea%>/users/serviceprovideruser`,
  listServiceProviders: `<%userArea%>/payrollserviceproviders`,
  listCompanies: `<%userArea%>/Lookups/companies`,
  listRoles: `<%userArea%>/lookups/roles`,
  postCreateCompanyUserForCA: `<%userArea%>/users/register`,
  postCreateCompanyUser: `<%userArea%>/users/companyuser/register`,
  postPspUser: `<%userArea%>/users/serviceprovideruser/register`,
  enableDisableCompanyUser: (id: string) => `<%userArea%>/users/${id}/status`,
  enableDisablePSPACompanyUser: (id: string) =>
    `<%userArea%>/users/companyuser/${id}/status`,
  enableDisablePSPAServiceProviderUser: (id: string) =>
    `<%userArea%>/users/serviceprovideruser/${id}/status`,
  enableDisableMultiFactor: (id: string) => `<%userArea%>/users/mfa-enable/${id}`,
  postClientUserAction: (userId: string, action: UserPostApiActions) =>
    `/<%userArea%>/users/${userId}/${action}`,
  postPspUserAction: (userId: string, action: UserPostApiActions) =>
    `/<%userArea%>/users/serviceprovideruser/${userId}/${action}`,
  postCompanyUserAction: (userId: string, action: UserPostApiActions) =>
    `/<%userArea%>/users/companyuser/${userId}/${action}`,
};
