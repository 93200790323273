import { useEffect } from "react";
import LinkButton from "../../../Common/ClaimComponents/Button/LinkButton";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import SingleFieldTextBoxFilter from "../../../Common/ClaimComponents/Filter/SingleFieldTextBoxFilter";
import SearchIconButton from "../../../Common/ClaimComponents/IconButton/SearchIconButton";
import FilterContainer from "../../../Common/ClaimContainers/FilterContainer";
import {
  STRING_PERSONALIZATION,
  GENERAL_AMENDMENT_FILTER_CONTAINER,
  GENERAL_AMENDMENT_FILTER_TEXTBOX,
  GENERAL_AMENDMENT_FILTER_AMENDMENT_TYPE,
  GENERAL_AMENDMENT_FILTER_CHANGE_TYPE,
  GENERAL_AMENDMENT_SEARCH_BUTTON,
  GENERAL_AMENDMENT_RESET_FILTER,
  GENERAL_AMENDMENT_FILTER_DATE_RANGE,
  GENERAL_AMENDMENT_FILTER_BUSINESS_UNIT,
  GENERAL_AMENDMENT_FILTER_REQUESTED_BY,
} from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  convertDatestoMoment,
  displayDates,
  findAuthorizationClaims,
  generatePersonaUpdatePayload,
  isComponentPersonalizable,
  mustBeArray,
} from "../../../libs";
import {
  resetFilters,
  selectFilters,
  setFilterLoading,
  setFilterName,
  setFilterType,
  setFilterChangeType,
  setFilterDateRange,
  setFilterRequestedBy,
  setFilterBusinessUnit,
} from "../../../slices/generalAmendment";
import { savePersonalizations } from "../../../User/actions/member";
import {
  amendmentChangeTypeLookup,
  amendmentRequestedByLookup,
  amendmentTypeLookup,
  businessUnitLookup,
} from "../../actions/lookup";
import {
  selectAmendmentChangeTypeData,
  selectAmendmentChangeTypeLoading,
  selectAmendmentRequestedByLoading,
  selectAmendmentTypeData,
  selectAmendmentTypeLoading,
  selectBusinessUnitsData,
  selectBusinessUnitsLoading,
  selectAmendmentRequestedByData,
} from "../../../slices/lookups";
import DateRangepickerFilter from "../../../Common/ClaimComponents/Filter/DateRangepickerFilters";

const Filter = ({ pageClaims }: any, props: any) => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector(selectFilters);
  const changeTypeList = useAppSelector(selectAmendmentChangeTypeData);
  const changeTypeLoading = useAppSelector(selectAmendmentChangeTypeLoading);
  const amendmentTypeList = useAppSelector(selectAmendmentTypeData);
  const amendmentTypeLoading = useAppSelector(selectAmendmentTypeLoading);
  const businessUnitList = useAppSelector(selectBusinessUnitsData);
  const businessUnitLoading = useAppSelector(selectBusinessUnitsLoading);
  const requestedByLoading = useAppSelector(selectAmendmentRequestedByLoading);
  const requestedByList = useAppSelector(selectAmendmentRequestedByData);

  const handleSearch = () => {
    dispatch(setFilterLoading(true));
    // Update Dom personalization for searched fields triggered by filters loading in use effect
  };

  useEffect(() => {
    //Lookup list of change types for dropdown options
    dispatch(amendmentChangeTypeLookup({ changeTypeId: undefined }));

    // Lookup list of amendment types for dropdown options
    dispatch(amendmentTypeLookup());

    // Lookup list of requested by users
    // TODO: Will be released next sprint
    dispatch(amendmentRequestedByLookup());

    // Lookup list of business units
    dispatch(businessUnitLookup({}));
  }, []);

  useEffect(() => {
    if (filters?.loading) {
      updateDOMPersonalization();
    }
  }, [filters?.loading]);

  const updateDOMPersonalization = () => {
    const generalAmendmentFilterContainer = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_FILTER_CONTAINER,
    });

    // Check if filter Container is personalizable
    if (
      isComponentPersonalizable({ component: generalAmendmentFilterContainer })
    ) {
      let personalizations: Array<any> = [];

      // Update Textbox personalization value in the DOM
      personalizations = generatePersonaUpdatePayload({
        personalizations,
        personalization: { ...STRING_PERSONALIZATION, value: filters?.name },
        claims: pageClaims?.components,
        name: GENERAL_AMENDMENT_FILTER_TEXTBOX,
      });

      // Update Amendment Type personalization selection in the DOM
      personalizations = generatePersonaUpdatePayload({
        personalizations,
        personalization: { ...STRING_PERSONALIZATION, value: filters?.type },
        claims: pageClaims?.components,
        name: GENERAL_AMENDMENT_FILTER_AMENDMENT_TYPE,
      });

      // Update Amendment Change Type personalization selection in the DOM
      personalizations = generatePersonaUpdatePayload({
        personalizations,
        personalization: {
          ...STRING_PERSONALIZATION,
          value: filters?.changeType,
        },
        claims: pageClaims?.components,
        name: GENERAL_AMENDMENT_FILTER_CHANGE_TYPE,
      });

      // Update date range personalization
      personalizations = generatePersonaUpdatePayload({
        personalizations,
        personalization: {
          ...STRING_PERSONALIZATION,
          value: JSON.stringify([filters.startDate, filters.endDate]),
        },
        claims: pageClaims?.components,
        name: GENERAL_AMENDMENT_FILTER_DATE_RANGE,
      });

      // Update business unit personalization
      personalizations = generatePersonaUpdatePayload({
        personalizations,
        personalization: {
          ...STRING_PERSONALIZATION,
          value: filters.businessUnit,
        },
        claims: pageClaims?.components,
        name: GENERAL_AMENDMENT_FILTER_BUSINESS_UNIT,
      });

      // Update requested by personalization
      personalizations = generatePersonaUpdatePayload({
        personalizations,
        personalization: {
          ...STRING_PERSONALIZATION,
          value: filters.requestedBy,
        },
        claims: pageClaims?.components,
        name: GENERAL_AMENDMENT_FILTER_REQUESTED_BY,
      });
      // Update Local DOM
      dispatch(
        savePersonalizations({
          personalizations,
          update: false,
        })
      );
    }
  };

  const handleReset = () => {
    dispatch(resetFilters());
  };

  const handleDateChange = (value: any) => {
    const dateRange = [
      displayDates({ value: mustBeArray(value)[0] }),
      displayDates({ value: mustBeArray(value)[1] }),
    ];
    dispatch(setFilterDateRange(dateRange));
  };

  return (
    <FilterContainer
      name={GENERAL_AMENDMENT_FILTER_CONTAINER}
      claims={pageClaims}
    >
      <SingleFieldTextBoxFilter
        placeholder="Keywords"
        name={GENERAL_AMENDMENT_FILTER_TEXTBOX}
        value={filters?.name}
        onChange={(val) => dispatch(setFilterName(val))}
        onSearch={handleSearch}
        className="mb-[15px] w-250"
      />
      {/** Filter by business unit */}
      <SingleFieldDropdownFilter
        options={businessUnitList}
        name={GENERAL_AMENDMENT_FILTER_BUSINESS_UNIT}
        value={filters.businessUnit || undefined}
        defaultValue={undefined}
        onSelect={(val) => dispatch(setFilterBusinessUnit(val))}
        className="mb-6 mr-7 -ml-5 w-[224px]"
        nameParam="name"
        canBeEmpty
        placeholder="Business Unit"
        loading={businessUnitLoading}
        hasNoClaims
        showSearch
        filterOption
        searchBy="name"
      />

      {/** Filter by amendment type (Permanent, recurring, payrun ) */}
      <SingleFieldDropdownFilter
        options={amendmentTypeList}
        name={GENERAL_AMENDMENT_FILTER_AMENDMENT_TYPE}
        value={filters?.type || undefined}
        defaultValue={undefined}
        onSelect={(val) => dispatch(setFilterType(val))}
        className="mb-6 mr-7 -ml-5"
        valueIsNumber
        nameParam="name"
        canBeEmpty
        placeholder="Amendment Type"
        loading={amendmentTypeLoading}
      />

      {/* Date Range Filter*/}
      <DateRangepickerFilter
        placeholder="Start - End date"
        name={GENERAL_AMENDMENT_FILTER_DATE_RANGE}
        value={[
          convertDatestoMoment({ date: filters?.startDate }),
          convertDatestoMoment({ date: filters?.endDate }),
        ]}
        onChange={(value) => handleDateChange(value)}
        className="mb-[15px] w-[240px] -ml-4 mr-3"
        ignoreClaims
      />

      {/** Filter by who requested the amendment */}
      <SingleFieldDropdownFilter
        options={requestedByList}
        name={GENERAL_AMENDMENT_FILTER_REQUESTED_BY}
        value={filters.requestedBy || undefined}
        defaultValue={undefined}
        onSelect={(val) => dispatch(setFilterRequestedBy(val))}
        className="mb-6 mr-3 w-[224px]"
        nameParam="name"
        canBeEmpty
        placeholder="Requested by"
        loading={requestedByLoading}
        hasNoClaims
        showSearch
        filterOption
        searchBy="name"
      />

      {/** Filter by change type (Deduction, Termination, Allowance, Salary etc.*/}
      <SingleFieldDropdownFilter
        options={changeTypeList}
        name={GENERAL_AMENDMENT_FILTER_CHANGE_TYPE}
        value={filters?.changeType || undefined}
        defaultValue={undefined}
        onSelect={(val) => dispatch(setFilterChangeType(val))}
        className="mb-6 mr-3"
        valueIsNumber
        nameParam="name"
        valueParam="changeTaskValueId"
        canBeEmpty
        placeholder="Change Type"
        loading={changeTypeLoading}
      />

      <SearchIconButton
        loading={filters?.loading}
        onClick={handleSearch}
        name={GENERAL_AMENDMENT_SEARCH_BUTTON}
      />
      <LinkButton
        buttonText="Reset Filter"
        className="color-primary hand"
        onClick={handleReset}
        name={GENERAL_AMENDMENT_RESET_FILTER}
      />
    </FilterContainer>
  );
};

export default Filter;
