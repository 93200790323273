import classNames from "classnames";
import _ from "lodash";
import { useMemo } from "react";
import { If } from "../../../Common/ui";
import { MAX_VARIANCE_PERIODS } from "../../../constants/variance";
import {
  calculateVarianceAndSD,
  getVarianceColWidth,
  getVarianceSDColWidth,
  mustBeArray,
} from "../../../libs";

type Props = {
  subPayItems: any;
  totalAvailablePeriod: number;
  record: any;
  getAmount: any;
  field: any;
  labelClassName: string;
};
const VarianceSubPayItems = ({
  subPayItems,
  totalAvailablePeriod,
  record,
  getAmount,
  field = "",
  labelClassName,
}: Props) => {
  // Group by id current and previous pay items to combine similar ones
  const groupedSubPayItems = useMemo(() => {
    return _.groupBy(mustBeArray(subPayItems), (o) => o.id);
  }, [subPayItems]);
  return (
    <If
      condition={!isNaN(totalAvailablePeriod)}
      then={Object.keys(groupedSubPayItems).map((id) => {
        const subPayItemName = groupedSubPayItems?.[id]?.[0]?.name;
        const varianceAndSD = calculateVarianceAndSD({
          title: subPayItemName,
          field,
          record,
          type: "subPayItem",
        });
        return (
          <div className="flex" key={id}>
            <div className={classNames(labelClassName, "pl-16")}>
              {subPayItemName}
            </div>
            {MAX_VARIANCE_PERIODS.map((value, index) => {
              return (
                <If
                  key={index}
                  condition={totalAvailablePeriod >= value}
                  then={getAmount({
                    field: "",
                    record,
                    position: value - 1,
                    subPayItemName,
                    type: "subPayItemName",
                  })}
                />
              );
            })}
            <div
              className={`flex-1-1-${getVarianceColWidth({
                totalPeriod: totalAvailablePeriod,
              })} text-right`}
            >
              {varianceAndSD?.variance}
            </div>
            <div
              className={`flex-1-1-${getVarianceSDColWidth({
                totalPeriod: totalAvailablePeriod,
              })} text-right`}
            >
              {varianceAndSD?.sd}
            </div>
          </div>
        );
      })}
    />
  );
};

export default VarianceSubPayItems;
