import { Rectangle } from './v1/checkbox.styles';
import { CheckboxProps } from './v1/checkbox.types';
import { checkmarkSizeMap } from './checkbox.constants';
import { CheckboxSize } from './checkbox.types';

export const Checkmark = ({
  size = CheckboxSize.Large,
  checked,
  indeterminate,
}: Partial<CheckboxProps>) => {
  if (indeterminate) {
    return <Rectangle data-size={size} />;
  }
  if (!checked) return null;

  return checkmarkSizeMap[size];
};

export default Checkmark;
