import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AmendmentTypes, ChangeTaskTypes } from '@xemplo/gp-types';
import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import * as S from '../../modal.styles';

export const RecurringType = () => {
  const { register, watch, unregister } = useFormContext();
  const watchChangeTask = watch('changeTask');
  const watchRecurringType = watch('recurringType');
  const watchChangeType = watch('changeType');
  const showRecurringType =
    watchChangeType === AmendmentTypes.RECURRING &&
    (watchChangeTask === ChangeTaskTypes.ALLOWANCE ||
      watchChangeTask === ChangeTaskTypes.DEDUCTION);

  useEffect(() => {
    if (showRecurringType) {
      register('recurringType', {
        required: 'This field is required',
        valueAsNumber: true,
      });
    } else {
      unregister('recurringType');
    }
  }, [register, showRecurringType, unregister]);

  if (showRecurringType) {
    return (
      <S.FormLabel>
        Recurring type
        <TextDropdownMultiV2
          items={RecurringTypeOptions}
          name="recurringType"
          isDropdownMulti={false}
          defaultSelectedKeys={watchRecurringType && [watchRecurringType]}
          selectProps={{
            placeholder: 'Select type',
          }}
          testId="recurringType"
          aria-label="Recurring type field"
        />
      </S.FormLabel>
    );
  }
};

export const RecurringTypeOptions = [
  { key: 1, value: 'Maximum Amount' },
  { key: 2, value: 'End Date' },
];

export const RecurringTypeValues = {
  MAXIMUM_AMOUNT: 1,
  END_DATE: 2,
} as const;
