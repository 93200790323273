import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_STATE_LIST_CONTAINER } from "../constants";

type InitialState = typeof INITIAL_STATE_LIST_CONTAINER;

const initialState: InitialState = {
  // Common states
  claimsUpdated: false, //To fetch the date on page load - ensure claims are read first for the default filters, sortings and pagination
  loading: true,
  total: 0,
  data: [],
  container: {
    gridView: false, //intial view grid or table
    gridable: false, //option to switch between grid and table view
    singleLineGridView: false, //Only applicable for company users
  },
  // Table View
  table: {
    sortInfo: {},
    page: 1,
    perPage: 20,
    isVisible: true,
  },
  // Grid View
  grid: {
    page: 1,
    col: 4,
    row: 5,
    sortInfo: {},
    isVisible: false,
  },
};
const baseState: any = {};
const listSlice = createSlice({
  name: "list",
  initialState: baseState,
  reducers: {
    resetSlice() {
      return {};
    },
    resetList(state: any, action: PayloadAction<string>) {
      state[action.payload] = initialState;
    },
    deleteList(state: any, action: PayloadAction<string>) {
      delete state[action.payload];
    },
    initList(state: any, action: PayloadAction<string>) {
      state[action.payload] = initialState;
    },
    setLoading(
      state: any,
      action: PayloadAction<{ list: string; loading: boolean }>
    ) {
      const { list, loading } = action.payload;
      /**
       * this intended to address when the action is called from an async function
       * and the component is unmounted before the action is called
       */
      if (state[list]) {
        state[list].loading = loading;
      }
    },
    dataLoaded(
      state: any,
      action: PayloadAction<{ list: string; data: any; total: number }>
    ) {
      const { list, data, total } = action.payload;
      if (state[list]) {
        state[list].loading = false;
        state[list].data = data;
        state[list].total = total;
      }
    },
    updateGrid(
      state: any,
      action: PayloadAction<{
        list: string;
        value: { row?: number; col?: number; page?: number; sortInfo?: any };
      }>
    ) {
      const { list, value } = action.payload;
      if (state[list]) {
        state[list].grid = { ...state[list].grid, ...value };
      }
    },
    updateTable(
      state: any,
      action: PayloadAction<{
        list: string;
        value: { sortInfo?: any; page?: number; perPage?: number };
      }>
    ) {
      const { list, value } = action.payload;
      if (state[list]) {
        state[list].table = { ...state[list].table, ...value };
      }
    },
    gridView(
      state: any,
      action: PayloadAction<{
        list: string;
        value: boolean;
      }>
    ) {
      const { list, value } = action.payload;
      if (state[list]) {
        state[list] = {
          ...state[list],
          loading: true,
          data: [],
          total: 0,
          container: { ...state.container, gridView: value },
        };
      }
    },
    initialDashPersonalization(
      state: any,
      action: PayloadAction<{
        list: string;
        value: any;
      }>
    ) {
      const { list, value } = action.payload;
      if (state[list]) {
        state[list] = {
          ...state[list],
          container: { ...state.container, gridView: value },
          claimsUpdated: true,
        };
      }
    },
    initialPersonalization(
      state: any,
      action: PayloadAction<{
        list: string;
        grid: any;
        table: any;
        container: any;
      }>
    ) {
      const { list, grid, table, container } = action.payload;
      if (state[list]) {
        state[list] = {
          ...state[list],
          grid: {
            ...grid,
          },
          table: { ...table },
          container: { ...container },
          claimsUpdated: true,
        };
      }
    },
  },
});

const { reducer, actions } = listSlice;

export const {
  deleteList,
  resetSlice,
  resetList,
  initList,
  setLoading,
  dataLoaded,
  updateGrid,
  updateTable,
  gridView,
  initialDashPersonalization,
  initialPersonalization,
} = actions;

export default reducer;
