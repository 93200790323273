import classNames from 'classnames';

import * as S from './active-item.styles';
import { ActiveItemProps } from './active-item.types';

export function ActiveItem({
  activeItem,
  isResponsiveExpanded,
  setIsResponsiveExpanded,
}: ActiveItemProps) {
  if (!activeItem) return null;

  return (
    <S.ActiveItem
      className={classNames({ expanded: isResponsiveExpanded })}
      onClick={() => setIsResponsiveExpanded(true)}
    >
      {activeItem.icon && <S.Icon>{activeItem.icon}</S.Icon>}
      <S.Label>{activeItem.label}</S.Label>
      <S.Arrow />
    </S.ActiveItem>
  );
}
