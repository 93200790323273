import { forwardRef } from 'react';

import { IconGradient } from '@xemplo/icons';

import { HeaderIconProps } from '../common';

import * as S from './modal-header-icon.styles';

export const ModalHeaderIcon = forwardRef<HTMLDivElement, HeaderIconProps>(
  ({ icon, mode }, ref) => {
    return (
      <S.StyledIconContainer ref={ref}>
        <S.StyledHeaderIconWrapper data-mode={mode} className="header-icon">
          {icon}
        </S.StyledHeaderIconWrapper>
        <IconGradient />
      </S.StyledIconContainer>
    );
  }
);
