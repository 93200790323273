import styled, { css } from 'styled-components';

import { Badge } from '@xemplo/badge';
import { BodySmallMedium, BodyXSmallRegular, Colour } from '@xemplo/style-constants';

import { DropzoneVariants } from './dropzone.types';

export const Wrapper = styled.div`
  text-align: center;
`;

export const Dropzone = styled.div<{ $variant: DropzoneVariants }>`
  color: ${Colour.Gray[400]};
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  text-align: center;
  ${(props) => variant(props.$variant)};
`;

export const ActionMessage = styled.div`
  ${BodySmallMedium};
`;

export const ActionMessageHelper = styled.p`
  ${BodyXSmallRegular};
`;

export const PlusBadge = styled(Badge)`
  position: absolute;
  left: 5px;
  top: -3px;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 74px;
  position: relative;
  margin: 0 auto;
  filter: drop-shadow(0px 4.119999885559082px 10px rgba(64, 73, 104, 0.08));
`;

export const HelperText = styled.span`
  display: block;
  margin-top: 8px;
  ${BodyXSmallRegular};
  color: ${Colour.Black[60]};
`;

function variant(variant: DropzoneVariants) {
  switch (variant) {
    case DropzoneVariants.Hover:
      return css`
        border: 1px dashed ${Colour.Blue[400]};
        background: ${Colour.Blue[50]};
      `;

    case DropzoneVariants.Disabled:
      return css`
        border: 1px dashed ${Colour.Gray[300]};
        background: rgba(242, 248, 255, 0.4);
        color: ${Colour.Gray[400]};
        opacity: 0.6;
        cursor: no-drop;
      `;

    case DropzoneVariants.Error:
      return css`
        border: 1px dashed ${Colour.Red[400]};
        background: ${Colour.Red[50]};
        color: ${Colour.Red[500]};
      `;

    case DropzoneVariants.Default:
    default:
      return css`
        border: 1px dashed ${Colour.Gray[300]};
        background: rgba(242, 248, 255, 0.4);
      `;
  }
}
