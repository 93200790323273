import React, { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';

import RadioButton from './radio-button';
import { RadioButtonSize, RadioGroupProps } from './radio-button.types';

const StyledLabel = styled.label`
  &[data-disabled='true'] {
    pointer-events: none;
  }
`;

export function RadioGroup({
  onChange,
  name,
  options,
  id,
  ariaLabel,
  size,
  showLabel,
}: RadioGroupProps) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    },
    [onChange]
  );

  return (
    options && (
      <>
        {options.map(({ label, value, disabled, error }) => (
          <StyledLabel key={`${name}-${value}`} data-disabled={disabled}>
            {showLabel && label}
            <RadioButton
              id={id ?? name}
              size={size ?? RadioButtonSize.Medium}
              error={error}
              onChange={() => !disabled && handleChange}
              name={name}
              value={value}
              disabled={disabled}
              ariaLabel={`${ariaLabel} ${label}`}
            />
          </StyledLabel>
        ))}
      </>
    )
  );
}

export default RadioGroup;
