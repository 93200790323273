import { useParams } from 'react-router-dom';

import { AvatarSize } from '@xemplo/avatar';
import { LoaderDots } from '@xemplo/loader';
import { useGetPayrunEarningLineDetailsVarianceQuery } from '@xemplo/payrun-query';

import * as S from './payrun-earning-line-detail.styles';

export const PayrunEarningLineDetailHeader = () => {
  const { workerId, id } = useParams();
  const { data, isLoading } = useGetPayrunEarningLineDetailsVarianceQuery({
    id: id ?? null,
    workerId: workerId ?? null,
  });

  const name = data?.result.externalEmployeeName ?? '';
  const [firstName, lastName] = name.split(' ');
  return (
    <S.Container>
      <S.Avatar user={{ firstName, lastName }} size={AvatarSize.l} />
      <S.Details>
        {isLoading && (
          <div>
            <LoaderDots />
          </div>
        )}

        <S.Name>{`${name}`}</S.Name>
        {/* <S.Title>{title}</S.Title> TODO: Out of scope for initial release*/}
        {/* <S.Salary>{salary}</S.Salary> TODO: Out of scope for initial release */}
      </S.Details>
    </S.Container>
  );
};
