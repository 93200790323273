import React from "react";
import { getClaimAndStatus } from "../../libs/utilities";
import { VISIBLE_CLAIMS_ID } from "../../constants/defaultClaims";

const FilterContainer = (props: any) => {
  const { claims, name, children, className = "" } = props;
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  const claim = claimAndStatus.claim;
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { claims: claim })
  );

  return (
    <div className={`${className} ${claimAndStatus.status}`}>
      {VISIBLE_CLAIMS_ID.includes(Number(claim.authorizationStatusId)) &&
        childrenWithProps}
    </div>
  );
};

export default FilterContainer;
