// @ts-nocheck
import React from "react";
import { Col, Divider, Row, Collapse } from "antd";
import InputTypesView from "./inputTypesView";
import AmendmentDeclineReason from "./amendmentDeclineReason";
import {
  parseItems,
  mustBeArray,
  currencyFormater,
  conditionalParameter,
  getAmendmentAssignedToText,
  getAmendmentRequestedByUserName,
  isEmpty,
  getAmendmentTypeName,
  displayTaxCalculationMethod,
} from "../../../libs";
import ReoccurringFields from "./ReoccurringFields";
import {
  ALLOWANCE_CHANGE_TYPE_ID,
  DEFAULT_GUID,
  TERMINATION_CHANGE_TYPE_ID,
} from "../../../constants/payrun";
import { If } from "../../../Common/ui";
import { AmendmentType } from "../../../types";
import { YesNoPartialStatus } from "../../../Common/amendments";
import AmendmentStatusBadge from "../../../Common/amendments/AmendmentStatusBadges";
import AmendmentActionHistory from "../generalAmendment/ActionHistory";
import { AmendmentFiles } from "@xemplo/amendment-files";
import { ChangeTaskTypes } from "@xemplo/gp-types";
import { PendingAmountValue } from "./Amendments/pending-amount-value";

const { Panel } = Collapse;

type Props = {
  amendment: AmendmentType;
  businessunitDetails: any;
  viewAction: boolean;
  visible?: boolean;
};

const AmendmentDetails = ({
  amendment,
  businessunitDetails,
  viewAction,
  visible,
}: Props) => {
  if (!amendment) return null;
  return (
    <div>
      {/** Amendment Type */}
      <Row gutter={16} className="mb-3">
        <Col span={12}>
          <label htmlFor="fld-requested-by" className="text-sm">
            <b> Amendment Type:</b>
          </label>
          <div className="text-base">
            {getAmendmentTypeName(amendment.amendmentTypeId)}
          </div>
        </Col>
      </Row>

      {/** Type */}
      <Row gutter={16} className="mb-3">
        <Col span={12}>
          <label htmlFor="fld-task-type" className="text-sm">
            <b>Type:</b>
          </label>
          <div className="text-base">{amendment.amendmentTaskTypeName}</div>
        </Col>

        {/** Sub Type */}
        <Col span={12}>
          <If
            condition={amendment.amendmentSubTypeName?.length > 0}
            then={
              <React.Fragment>
                <label htmlFor="fld-requested-by" className="text-sm">
                  <b>Sub Type:</b>
                </label>
                <div className="text-base">
                  {amendment.amendmentSubTypeName}
                </div>
              </React.Fragment>
            }
          />
        </Col>
      </Row>

      {/** Applies to */}
      <Row gutter={16} className="mb-3">
        <Col span={12}>
          <label htmlFor="fld-requested-by" className="text-sm">
            <b> Applies To:</b>
          </label>
          <div className="text-base">
            {getAmendmentAssignedToText({ amendment })}
          </div>
        </Col>

        {/** Requested By */}
        <Col span={12}>
          <label htmlFor="fld-requested-by" className="text-sm">
            <b>Requested By:</b>
          </label>
          <div className="text-base">
            {getAmendmentRequestedByUserName({ amendment })}
          </div>
        </Col>
      </Row>

      {/** Status (Approved, Created, Declined) */}
      <Row gutter={16} className="mb-3">
        <Col span={12}>
          <label htmlFor="fld-task-type" className="text-sm">
            <b>Status:</b>
          </label>
          <AmendmentStatusBadge amendment={amendment} />
        </Col>

        {/** Applied? (Yes, No, Partial) */}
        <Col span={12}>
          <label htmlFor="fld-task-type" className="text-sm">
            <b>Applied:</b>
          </label>
          <YesNoPartialStatus
            statusInPPSID={amendment.amendmentStatusInPPSID}
          />
        </Col>
      </Row>

      {amendment.amendmentTaskTypeID !== ChangeTaskTypes.TERMINATION && (
        <>
          {/** Amount */}
          <Row gutter={16} className="mb-3">
            <Col span={12}>
              <label htmlFor="fld-task-type" className="text-sm">
                <b>Rate:</b>
              </label>
              <PendingAmountValue
                value={amendment.amount}
                isIntegrated={amendment.isIntegrated}
                amendmentKeypayStatusId={amendment.amendmentKeypayStatusId}
                amendmentTaskTypeID={amendment.amendmentTaskTypeID}
              />
            </Col>

            {/** Units */}
            {amendment.amendmentTaskTypeID !==
              ChangeTaskTypes.PAY_INSTRUCTION && (
              <Col span={12}>
                <label className="text-sm">
                  <b>Unit/s:</b>
                </label>
                <div className="text-base">{amendment.units}</div>
              </Col>
            )}
          </Row>
          {/** Total Amount */}
          {amendment.amendmentTaskTypeID !==
            ChangeTaskTypes.PAY_INSTRUCTION && (
            <Row gutter={16} className="mb-3">
              <Col span={12}>
                <label htmlFor="fld-task-type" className="text-sm">
                  <b>Total Amount:</b>
                </label>
                <PendingAmountValue
                  //TODO: Replace calculated value with BE returned total amount https://expedo.atlassian.net/browse/GC-870
                  value={(amendment.amount ?? 0) * (amendment.units ?? 0)}
                  isIntegrated={amendment.isIntegrated}
                  amendmentKeypayStatusId={amendment.amendmentKeypayStatusId}
                  amendmentTaskTypeID={amendment.amendmentTaskTypeID}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      {/** Tax Calculation & Pay Periods */}
      {(!!amendment.lumpSumCalculationMethod ||
        !!amendment.lumpSumNumberOfPayPeriods) && (
        <Row gutter={16} className="mb-3">
          {!!amendment.lumpSumCalculationMethod && (
            <Col span={12}>
              <label className="text-sm">
                <b>Tax Calculation Method:</b>
              </label>
              <div className="text-base">
                {displayTaxCalculationMethod(
                  amendment.lumpSumCalculationMethod
                )}
              </div>
            </Col>
          )}
          {!!amendment.lumpSumNumberOfPayPeriods && (
            <Col span={12}>
              <label className="text-sm">
                <b>Pay Periods:</b>
              </label>
              <div className="text-base">
                {amendment.lumpSumNumberOfPayPeriods}
              </div>
            </Col>
          )}
        </Row>
      )}

      {/** Expiry  */}
      <div className="mb-3">
        <ReoccurringFields
          amendment={amendment}
          businessunitDetails={businessunitDetails}
        />
      </div>

      {/** Attachent / File  */}
      <If
        condition={
          amendment.amendmentTaskTypeID === ChangeTaskTypes.PAY_INSTRUCTION
        }
        then={
          <div className="mb-3">
            <div className="text-sm pb-2">
              <b>Attachment:</b>
            </div>
            <AmendmentFiles amendmentId={amendment.amendmentId} />
          </div>
        }
      />

      {/** Note */}
      <If
        condition={amendment.note?.length}
        then={
          <div className="mb-3">
            <label htmlFor="fld-task-type" className="text-sm">
              <b>Note:</b>
            </label>
            <div className="text-base">{amendment.note}</div>
          </div>
        }
      />
      {/** Summary */}
      <If
        condition={
          !isEmpty(
            mustBeArray(parseItems(amendment.amendmentSummary).questions)
          )
        }
        then={
          <React.Fragment>
            <Divider />
            <div className="text-sm">
              <b>Summary:</b>
            </div>
            <div>
              {mustBeArray(
                parseItems(amendment.amendmentSummary).questions
              ).map((question: any, i: any) => {
                return (
                  <div key={i}>
                    <InputTypesView
                      question={question}
                      businessunitDetails={businessunitDetails}
                    />
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        }
      />
      <If
        condition={!!amendment.rejectDescription}
        then={
          <React.Fragment>
            <Divider />
            <AmendmentDeclineReason
              description={amendment.rejectDescription}
              viewModal
            />
          </React.Fragment>
        }
      />

      <If
        condition={viewAction}
        then={
          <React.Fragment>
            <Divider />
            <Collapse>
              <Panel header="Actions" key="1">
                <AmendmentActionHistory
                  visible={visible}
                  id={amendment.amendmentId}
                  payrunID={
                    amendment.payrunId && amendment.payrunId !== DEFAULT_GUID
                      ? amendment.payrunId
                      : undefined
                  }
                />
              </Panel>
            </Collapse>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default AmendmentDetails;
