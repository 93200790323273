import { useState } from 'react';

import {
  useGetAmendmentFileDownloadQuery,
  useGetAmendmentFilesQuery,
} from '@xemplo/amendment-query';
import { AmendmentFile } from '@xemplo/gp-types';
import { DawnAttachment } from '@xemplo/icons';

import * as S from './amendment-files.styles';

export interface AmendmentFilesProps {
  amendmentId: string;
}

export function AmendmentFiles(props: AmendmentFilesProps) {
  const { amendmentId } = props;

  const { data: amendmentFiles } = useGetAmendmentFilesQuery({
    id: amendmentId,
  });

  return (
    <div>
      {amendmentFiles?.result?.map((file) => (
        <AmendmentFileRender key={file.id} file={file} />
      ))}
    </div>
  );
}

export default AmendmentFiles;

function AmendmentFileRender({ file }: { file: AmendmentFile }) {
  const [fileId, setFileId] = useState<string | null>(null);
  const { data } = useGetAmendmentFileDownloadQuery({
    id: fileId,
  });

  const handleClick = () => {
    setFileId(file.id);
  };
  return (
    <S.AmendmentFileItem
      onClick={handleClick}
      aria-label={`Download ${file.displayName}`}
    >
      <DawnAttachment />
      {file.originalFileName}
    </S.AmendmentFileItem>
  );
}
