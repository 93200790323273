export const getBusinessUnitDetailRoute = ({
  id,
  companyId,
}: {
  id: string;
  companyId?: string;
}) => {
  return companyId
    ? `/businessunit/${id}?companyid=${companyId}`
    : `/businessunit/${id}`;
};

export const getBusinessUnitEditRoute = ({
  id,
  companyId,
}: {
  id: string;
  companyId: string;
}) => {
  return `/company/${companyId}/businessunit/${id}/edit`;
};
