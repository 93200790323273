import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { SidebarTestId } from '../sidebar.constants';
import { SidebarTooltip, SidebarTooltipRef } from '../sidebar-tooltip';

import * as S from './sidebar-items.styles';
import { SidebarItemsProps } from './sidebar-items.types';
import { useResponsiveCollapse } from './use-responsive-collapse';

export function SidebarItems(props: SidebarItemsProps) {
  const { items, isResponsiveExpanded, isSidebarExpanded } = props;
  const tooltipRef = useRef<SidebarTooltipRef>(null);
  useResponsiveCollapse(props);

  return (
    <S.Wrapper className={classNames({ 'responsive-expanded': isResponsiveExpanded })}>
      <S.Items role="menu" aria-busy="true">
        {items.map((item) => (
          <S.Item
            key={item.id}
            role="presentation"
            data-heading={item.isHeader}
            data-testid={SidebarTestId.SidebarItem(item.id)}
            data-expanded={isSidebarExpanded}
            onMouseEnter={(e) => tooltipRef.current?.handleTooltipVisible(e, item)}
            onMouseLeave={() => tooltipRef.current?.handleTooltipHidden()}
          >
            {item.isHeader ? (
              <S.Label
                role="heading"
                id={`label-${item.id}`}
                aria-level={1}
                aria-label={item.label}
                data-testid={SidebarTestId.SidebarItemLabel(item.id)}
                data-expanded={isSidebarExpanded}
                data-header={item.isHeader}
                title={item.label}
              >
                {item.label}
              </S.Label>
            ) : (
              <NavLink
                to={item.route}
                role="menuitem"
                aria-describedby={`label-${item.id}`}
                data-testid={SidebarTestId.SidebarItemLink(item.id)}
                onClick={() => item.callback?.(item)}
              >
                {item.icon && (
                  <S.Icon data-expanded={isSidebarExpanded}>{item.icon}</S.Icon>
                )}
                <S.Label
                  data-testid={SidebarTestId.SidebarItemLabel(item.id)}
                  data-expanded={isSidebarExpanded}
                  data-header={item.isHeader}
                >
                  {item.label}
                </S.Label>
              </NavLink>
            )}
          </S.Item>
        ))}
      </S.Items>
      <SidebarTooltip ref={tooltipRef} isSidebarExpanded={isSidebarExpanded} />
    </S.Wrapper>
  );
}
