import React, { useMemo } from "react";
import { If } from "../../../../Common/ui";
import {
  displayActionComponent,
  findAuthorizationClaims,
  getClaimAndStatus,
  getOptionalParameter,
  ignoreClaimValidation,
  mustBeArray,
} from "../../../../libs";
import DisplayCard from "../../../../Common/displayCard";
import { DashboardPinComponent, GeneralPinComponent } from "../PinComponents";
import ListHeaderAndActions from "./ListHeaderAndActions";
import SingleLineGrid from "../../../../Common/ClaimComponents/TableGrid/SingleLineGrid";
import GridComponent from "./GridComponent";
import TableComponent from "./TableComponent";
import { ConfigProvider } from "antd";
import { NONCE_ID } from "../../../../constants";

type Props = {
  //   Common Props
  name?: string;
  dashboardView?: boolean;
  className?: string;
  pageClaims?: any;
  targetEndpoint?: string;
  hasNoDuplicateEntity?: boolean;
  total: number;
  handleGridViewChange?: (e: any) => void;
  isDraft?: boolean;
  listLoading: boolean;
  handleAction?: (e: any) => void;
  data: Array<any>;
  rowIdParam?: string;
  container?: any;

  //   Header and custom actions
  componentTitle: string;
  filterApplied?: string;
  handleCreate?: () => void;
  addModuleName?: string;

  // TODO: To get the correct props for the FC, we need to make this a generic component.
  // and most likely the underlying table component.
  CustomActions?: React.ReactNode | React.FC<any>;

  //   Single Line Grid
  singleLineGridTitle?: string;
  SingleLineGridDisplayComponent?: React.ReactNode;

  //   Grid Props
  DisplayCardComponent?: React.ReactNode;
  gridComponentName: string;
  grid?: any;
  fieldOptions?: any;
  handleGridChange?: (e: any) => void;
  cardGrid?: number;

  //   Table Props
  table?: any;
  handleTableChange?: (e: any) => void;
  tableComponentName?: string;
  GeneralTablePaginatedComponent?: React.ReactNode | React.FC<any>;
  hasNoTitle?: boolean;
};
const DefaultListContainer = ({
  //   Common Props
  name,
  dashboardView,
  className,
  pageClaims,
  targetEndpoint,
  hasNoDuplicateEntity,
  total,
  handleGridViewChange,
  isDraft,
  listLoading,
  handleAction,
  data,
  rowIdParam,
  container,

  //   Header and custom actions
  componentTitle,
  filterApplied,
  addModuleName,
  CustomActions,

  //   Single Line Grid
  singleLineGridTitle,
  SingleLineGridDisplayComponent,

  //   Grid Props
  DisplayCardComponent,
  gridComponentName,
  grid,
  fieldOptions,
  handleGridChange,
  cardGrid,

  //   Table Props
  table,
  handleTableChange,
  tableComponentName,
  GeneralTablePaginatedComponent,
  hasNoTitle,
}: Props) => {
  const claimAndStatus = getClaimAndStatus({
    claims: pageClaims,
    componentName: name,
    hasComponents: true,
  });
  const claim = claimAndStatus.claim;
  const GridCardComponent = getOptionalParameter({
    value1: DisplayCardComponent,
    value2: DisplayCard,
  });

  const gridComponentClaims = findAuthorizationClaims({
    claims: mustBeArray(claim?.components),
    name: gridComponentName,
  });

  const wrappwerClassName = `${className || ""} ${ignoreClaimValidation({
    ignoreStatus: dashboardView,
    claimAndStatus,
  })} list-container`;

  // create a var using usememo to avoid rerendering or calling the function again on every rerender
  const displayGralPinComponent = useMemo(
    () =>
      displayActionComponent({
        claim,
        dashboardView,
      }),
    [claim, dashboardView]
  );

  return (
    <ConfigProvider csp={{ nonce: NONCE_ID.DEFAULT_LIST_CONTAINER }}>
      <div className={wrappwerClassName}>
        {/* Pin component for general and dashboard view */}
        <If
          condition={!!dashboardView}
          then={
            <div className="flex justify-end items-center">
              <DashboardPinComponent
                claim={claim}
                targetEndpoint={targetEndpoint}
                pageClaims={pageClaims}
              />
            </div>
          }
        />
        <If
          condition={displayGralPinComponent}
          then={
            <GeneralPinComponent
              claim={claim}
              targetEndpoint={targetEndpoint}
              hasNoDuplicateEntity={hasNoDuplicateEntity}
              filterApplied={filterApplied}
              componentTitle={componentTitle}
              pageClaims={pageClaims}
            />
          }
        />

        {/* Component titles, filters applied and create and custom actions */}
        <ListHeaderAndActions
          dashboardView={dashboardView}
          claim={claim}
          componentTitle={componentTitle}
          filterApplied={filterApplied}
          handleAddAction={() => handleAction?.({ action: "create" })}
          addModuleName={addModuleName}
          CustomActions={CustomActions}
          total={total}
          gridView={container?.gridView}
          gridable={container?.gridable}
          grid={grid}
          table={table}
          fieldOptions={fieldOptions}
          handleGridViewChange={handleGridViewChange}
          handleGridChange={handleGridChange}
          handleTableChange={handleTableChange}
        />

        {/* Single Line view or grid/table view */}
        <If
          condition={!!container?.singleLineGridView}
          then={
            <SingleLineGrid
              ignoreStatus
              loading={listLoading}
              title={singleLineGridTitle}
              DisplayComponent={SingleLineGridDisplayComponent}
              handleAction={handleAction}
              dashboardView={dashboardView}
              isDraft={isDraft}
            />
          }
          else={
            <If
              condition={container?.gridView}
              then={
                <GridComponent
                  name={gridComponentName}
                  claim={gridComponentClaims} // may be use use the same claim for both grid/table - to be verified
                  dashboardView={dashboardView}
                  data={data}
                  GridCardComponent={GridCardComponent}
                  listLoading={listLoading}
                  handleGridAction={handleAction}
                  handleGridChange={handleGridChange}
                  total={total}
                  grid={grid}
                  cardGrid={cardGrid}
                  rowIdParam={rowIdParam}
                />
              }
              else={
                <TableComponent
                  name={tableComponentName}
                  claim={claim}
                  dashboardView={dashboardView}
                  data={data}
                  GeneralTablePaginatedComponent={
                    GeneralTablePaginatedComponent
                  }
                  listLoading={listLoading}
                  total={total}
                  handleTableChange={handleTableChange}
                  hasNoTitle={hasNoTitle}
                  table={table}
                  rowIdParam={rowIdParam}
                  handleAction={handleAction}
                />
              }
            />
          }
        />
      </div>
    </ConfigProvider>
  );
};

export default DefaultListContainer;
