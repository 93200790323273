import { Button, ButtonSize, ButtonType, ButtonVariant } from '@xemplo/button';
import {
  PAYRUN_COMPONENTS,
  ROLE_AUTHORISATION_MODULE_IDS,
  useRoleAuthorisation,
} from '@xemplo/gp-api';
import { Prompt } from '@xemplo/prompts';

import { useConfirmPrompt } from './useConfirmPrompt';

export const PayrunSubmitAmendmentTestId = {
  submitButton: 'submit-amendment',
};

export function PayrunSubmitAmendments() {
  const { promptProps, promptVisible, setPromptVisible } = useConfirmPrompt();
  const { componentVisible, componentDisabled } = useRoleAuthorisation(
    ROLE_AUTHORISATION_MODULE_IDS.payrun
  );
  if (!componentVisible(PAYRUN_COMPONENTS.AMENDMENT_SUBMIT)) return null;
  return (
    <>
      <Button
        ariaLabel="Submit amendment"
        testId={PayrunSubmitAmendmentTestId.submitButton}
        onClick={() => {
          setPromptVisible(true);
        }}
        variant={ButtonVariant.Light}
        type={ButtonType.Primary}
        size={ButtonSize.Small}
        disabled={componentDisabled(PAYRUN_COMPONENTS.AMENDMENT_SUBMIT)}
      >
        Submit
      </Button>
      <Prompt
        {...promptProps}
        open={promptVisible}
        onCloseCallback={() => setPromptVisible(false)}
      />
    </>
  );
}

export default PayrunSubmitAmendments;
