import { useGetAmendmentByIdQuery } from '@xemplo/amendment-query';
import { tryJsonParse } from '@xemplo/common-util';
import { formatDate } from '@xemplo/date-time';
import { AmendmentStatusIds, RejectionReason } from '@xemplo/gp-types';
import { DawnTriangleAlert } from '@xemplo/icons';

import * as S from './payrun-amendment-detail.styles';
import { RejectReasonProps } from './payrun-amendment-detail.types';
export const RejectReason = (props: RejectReasonProps) => {
  const { data } = useGetAmendmentByIdQuery({ id: props.amendment.amendmentId });

  if (
    props.amendment.amendmentStatusID !== AmendmentStatusIds.REJECTED ||
    !data?.result.rejectDescription
  ) {
    return null;
  }

  const rejectionReason = tryJsonParse<RejectionReason>(data.result.rejectDescription);
  const { RejectionReason, User } = rejectionReason ?? {};

  return (
    <S.RejectReasonContainer>
      <DawnTriangleAlert />
      <S.RejectReasonDescription>
        <div>Amendment Rejected</div>
        {!!User?.UserName && (
          <div>
            Rejected by: {User.UserName} {formatDate({ value: User.Date })}
          </div>
        )}
        {!!RejectionReason && <div>Reason: {RejectionReason}</div>}
      </S.RejectReasonDescription>
    </S.RejectReasonContainer>
  );
};
