//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Checkbox, Row, Col } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import _ from "lodash";

type State = any;

class ApplicableFeesOptions extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      label,
      decorator,
      applicableFeesOptions,
      getFieldDecorator,
      editPayschedule,
      defaultLabelCol,
      defaultValueCol,
    } = this.props;
    const formLayout = {
      labelCol: {
        span: defaultLabelCol ? defaultLabelCol : editPayschedule ? 5 : 6,
      },
      wrapperCol: {
        span: defaultValueCol ? defaultValueCol : editPayschedule ? 19 : 18,
      },
    };
    let defaultChecked = [];
    if (applicableFeesOptions) {
      defaultChecked = mustBeArray(applicableFeesOptions).reduce(
        (a, b) => _.concat(a, b.selected ? [b.id] : []),
        []
      );
    }
    return (
      <React.Fragment>
        <Form.Item label={`${label}:`} {...formLayout}>
          {getFieldDecorator(decorator, {
            initialValue: defaultChecked,
          })(
            <Checkbox.Group className="ml-0 w-full">
              <Row gutter={16}>
                {mustBeArray(applicableFeesOptions).map((fees, i) => {
                  return (
                    <Col span={8} key={i}>
                      <Checkbox value={fees.id}>{fees.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          )}
        </Form.Item>
      </React.Fragment>
    );
  }
}

export default ApplicableFeesOptions;
