import { Form, Select, Tooltip } from "antd";

type Props = {
  handleChangeValue?: ({ label, value }: { label: string; value: any }) => void;
  required?: boolean;
};

export enum TaxCalculationMethodOptions {
  A = "A",
  B = "B2",
  NA = "NotApplicable",
}

export const TaxCalculationMethodField = ({
  required,
  handleChangeValue,
}: Props) => {
  return (
    <Form.Item
      label="Tax Calculation Method"
      className="mb-3  xl:col-start-2 xl:row-start-4 "
      name="LumpSumCalculationMethod"
      rules={[
        {
          required,
        },
      ]}
    >
      <Select
        placeholder="Select"
        onChange={(e) =>
          handleChangeValue &&
          handleChangeValue({
            label: "LumpSumCalculationMethod",
            value: e,
          })
        }
      >
        <Select.Option value={TaxCalculationMethodOptions.A}>A</Select.Option>
        <Select.Option value={TaxCalculationMethodOptions.B}>
          B(ii)
        </Select.Option>
        <Select.Option value={TaxCalculationMethodOptions.NA}>
          Not Applicable
        </Select.Option>
      </Select>
    </Form.Item>
  );
};
