
import { Col, Row } from "antd";
import { DEFAULT_GUTTER, REOCCURRING_AMENDMENT_TYPE_ID } from "../../../constants";
import {
  conditionalParameter,
  currencyFormater,
  isNullEmptyUndefined,
  renderReoccurringEndDate,
} from "../../../libs";
import { If } from "../../../Common/ui";
import classNames from "classnames";

type Props = {
  amendment: any;
  businessunitDetails: any;
};


const ReoccurringFields = ({ amendment, businessunitDetails }: Props) => {
  const recurringAmendment = amendment?.amendmentTypeId === REOCCURRING_AMENDMENT_TYPE_ID

return (
  <div>
    <Row className="mb-3" gutter={DEFAULT_GUTTER}>
      <Col span={12} className={classNames("text-sm", {"text-gray-600": !recurringAmendment})}>
        <label htmlFor="fld-task-type" className="text-sm">
          <b>Expiry:</b>
        </label>
        <div className="text-base">
          <If 
          condition={!recurringAmendment}
          then={<>-</>}
          else={
            <If
              condition={!isNullEmptyUndefined(amendment?.recurringMaximumAmount)}
              then={
                <>
                  {currencyFormater(
                    conditionalParameter({
                      data: amendment,
                      field: "recurringMaximumAmount",
                    }),
                    conditionalParameter({
                      data: businessunitDetails,
                      field: "country",
                    })
                  )}
                </>
              }
              else={<>
              {renderReoccurringEndDate(amendment)}</>}
            />
            } />
        </div>
      </Col>
    </Row>
  </div>
);
          }
export default ReoccurringFields;