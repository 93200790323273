//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import { getPayrunListColumns } from "../../../libs/utilities";
import _ from "lodash";
import { withRouter } from "../../../hooks";

class PayRunAmendments extends React.Component {
  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let field = sorter.field;
    switch (sorter.field) {
      case "companyName":
        field = "companyname";
        break;
      case "businessUnitName":
        field = "businessname";
        break;
      case "payOccurrenceName":
        field = "payoccurrencename";
        break;
      case "ammendmentDueDateTime":
        field = "ammendmentduedate";
        break;
      case "payDate":
        field = "paydate";
        break;
      case "ammendmentCount":
        field = "ammendmentcount";
        break;
      default:
        break;
    }
    let options = {
      page: pagination.current,
      order: sorter.order
        ? sorter.order === "ascend"
          ? "asc"
          : "desc"
        : sorter.order,
      field: field,
    };
    this.props.handleTableChange({ options });
  };

  handleAction = ({ record, action }: any) => {
    const { isDraft } = this.props;
    switch (action) {
      case "edit":
        return isDraft
          ? this.props.router.navigate(
              `/company/${record && record.companyID}/payrun/${
                record && record.payrunId
              }/edit`
            )
          : this.props.handleEdit(record);
      case "view":
        return isDraft
          ? this.props.router.navigate(
              `/company/${record && record.companyID}/payrun/${
                record && record.payrunId
              }/edit`
            )
          : this.props.handleEdit(record);
      case "viewAmendmentSummary":
        return this.props.handleViewAmendmentSummary(record);
      default:
        break;
    }
  };

  render() {
    const pagination = {
      total: this.props.total,
      current: this.props.current,
      pageSize: this.props.per_page ? this.props.per_page : 20,
      hideOnSinglePage: true,
    };
    const { sortInfo, claims, viewComponent, dashboardView, isDraft } =
      this.props;
    let colTemplate = getPayrunListColumns({
      viewComponent,
      sortInfo,
      claims,
      dashboardView,
      isDraft,
      handleAction: this.handleAction,
    });
    const column = tableColumnGenerator(colTemplate);
    return (
      <SimpleTableContainer
        columns={column}
        dataSource={this.props.data}
        loading={this.props.loading}
        onChange={this.handleTableChange}
        pagination={pagination}
        rowIdParam="payrunId"
        dashboardView={dashboardView}
      />
    );
  }
}

export default withRouter(PayRunAmendments);
