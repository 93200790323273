// @ts-nocheck
import {
  dispatcher,
  getQueryOptionsAndType,
  requestBuilder,
} from "../../libs/utilities";
import { payrunAction } from "../../constants/action-type/index";
import APIHandler from "../../constants/apiUrl";
import axios from "axios";
import environment from "../../constants/environment";
import {
  checkTargetEndpoint,
  http,
  ResponseHandler,
  parseItems,
  delay,
  errorDisplay,
  payrunIsSkipped,
} from "../../libs";

const {
  LIST_PAYRUN_COMPLETED,
  LIST_PAYRUN_TOTAL,
  LIST_PAYRUN_AMENDMENTLIST,
  PAYRUN_DETAIL,
  PAYRUN_DETAIL_LOADING,
  PAYRUN_EARNINGLINES_REIMPORT,
} = payrunAction;
import ReactGA from "react-ga4";
import { httpClient, goToRoute } from "../../hooks";
import { DEFAULT_ROUTES } from "../../constants";
import { message } from "antd";
import { UNATHORIZED_ACCESS_ERRORS } from "../../constants/errors";

export function listPayrun({ id, options, cancelToken, targetEndpoint }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let type = getQueryOptionsAndType({ options }).type;
      let temp = getQueryOptionsAndType({ options }).temp;
      const queryOptions = {
        page: 1,
        ...options,
        q: { ...temp },
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({ endpoint: "PAYRUN_URL" });
      const response = await requestBuilder(
        id,
        checkTargetEndpoint({ targetEndpoint, url }),
        targetEndpoint ? {} : queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });

      const actionType = `LIST_PAYRUN_${type ? type.toUpperCase() : "TOTAL"}`;

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, actionType)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: actionType,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listInvoices({ id, options, cancelToken, targetEndpoint }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      dispatch({ type: "SET_PROGRESS_BAR" });
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_INVOICES_URL",
      });
      const response = await requestBuilder(
        id,
        checkTargetEndpoint({ targetEndpoint, url }),
        targetEndpoint ? {} : queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      const actionType = `LIST_PAYRUN_INVOICES`;

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, actionType)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: actionType,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getAmendment({ id, options, cancelToken, targetEndpoint }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = targetEndpoint
        ? targetEndpoint
        : APIHandler.constructEndpoint({
            endpoint: options.payrunID
              ? "AMENDMENT_URL"
              : "GENERAL_AMENDMENTS_DETAIL_URL",
            options: { id: options.payrunID ? options.payrunID : id },
          });

      const response = await requestBuilder(
        options.payrunID ? id : null,
        url,
        {},
        cancelToken
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, "AMMENDMENT")
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// Audit Report
export function getAuditReport({ id, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_AUDIT_REPORT_URL",
        options: { id },
      });

      const response = await requestBuilder(null, url, {}, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, "AMMENDMENT")
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function approveAmendment({ id, options, cancelToken }: any) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_ACTIONID_URL",
        options: { id: options.payrunID, amendmentId: id, action: "approve" },
      });
      const response = await http.patch(url);
      if (ResponseHandler.isValidStatus(response)) {
        ReactGA.event({
          category: "Pay run Amendment Actions",
          action: "Approve",
          label: "APPROVE_AMMENDMENT",
        });
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "APPROVE_AMMENDMENT"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function reSubmitAmendment({ id, options, payload }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_ACTIONID_URL",
        options: { id: options.payrunID, amendmentId: id, action: "resubmit" },
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        ReactGA.event({
          category: "Pay run Amendment Actions",
          action: "Approve",
          label: "RESUBMIT_AMMENDMENT",
        });
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "RESUBMIT_AMMENDMENT"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getAmendmentHistory({
  id,
  options,
  queryParams,
  cancelToken,
}: any) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const option = {
        page: 1,
        per_page: options?.per_page ?? 50,
        ...queryParams,
      };
      const url = APIHandler.constructEndpoint({
        endpoint: options?.payrunID
          ? "AMENDMENT_ACTIONID_URL"
          : "GENERAL_AMENDMENTS_HISTORY_URL",
        options: {
          id: options?.payrunID ?? id,
          amendmentId: id,
          action: "history",
        },
      });
      const response = await requestBuilder(null, url, option, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "GET_AMMENDMENT_HISTORY"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getPayrunChangeLog({ id, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const option = {
        page: 1,
      };
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_CHANGELOG_URL",
        options: {
          id,
        },
      });
      const response = await requestBuilder(null, url, option);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "GET_PAYRUN_CHANGELOG"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getPayrunRejectionReasons() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = environment.cdn_url;
      const response = await axios.get(`${url}/RejectionReason.json`);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve({ status: true, data: response && response.data });
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function rejectPayrunAmendment({ id, options, cancelToken, data }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_ACTIONID_URL",
        options: { id: options.payrunID, amendmentId: id, action: "reject" },
      });
      const response = await http.post(url, data);
      if (ResponseHandler.isValidStatus(response)) {
        ReactGA.event({
          category: "Pay run Amendment Actions",
          action: "Reject",
          label: "DECLINE_AMMENDMENT",
        });
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "DECLINE_AMMENDMENT"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function deleteAmendment({
  id,
  options = {},
  payrunId,
}: {
  id?: string;
  options?: any;
  payrunId?: string;
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_URL",
        options: { id: payrunId || options.payrunID },
      });
      const response = await http.del(`${url}/${id}`);
      if (ResponseHandler.isValidStatus(response)) {
        ReactGA.event({
          category: "Pay run Amendment Actions",
          action: "Delete",
          label: "DECLINE_AMMENDMENT",
        });

        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "DECLINE_AMMENDMENT"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function submitAmendments({ id, payload }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_SUBMIT_URL",
        options: { id },
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        ReactGA.event({
          category: "Pay run Amendment Actions",
          action: "Submit",
          label: "SUBMIT_ALL_AMMENDMENTS",
        });

        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "SUBMIT_ALL_AMMENDMENTS"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listPayrunAmendments({
  id,
  options,
  cancelToken,
  targetEndpoint,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      if (queryOptions.q) {
        queryOptions.q = { ...queryOptions.q, showComplete: true };
      }
      const url =
        targetEndpoint ||
        APIHandler.constructEndpoint({
          endpoint: "PAYRUN_AMENDMENTS_URL",
          options: { id },
        });
      dispatch({ type: "SET_PROGRESS_BAR" });
      const response = await requestBuilder(
        null,
        url,
        queryOptions,
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (!id) {
        dispatch({ type: "AMENDMENT_URL_CLEAR" });
      }
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              LIST_PAYRUN_AMENDMENTLIST
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: LIST_PAYRUN_AMENDMENTLIST,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function submitAmendment({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "amendments/submit" },
      });
      dispatch({ type: "SET_PROGRESS_BAR" });
      const response = await http.post(url);
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "SUBMIT_ALL_AMENDMENTS"
            )
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: "SUBMIT_AMENTDMENTS",
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listPayrunCompleted({ id, options, targetEndpoint }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      const url = APIHandler.constructEndpoint({ endpoint: "PAYRUN_COMPLETE" });
      const response = await requestBuilder(
        id,
        targetEndpoint ? targetEndpoint : url,
        queryOptions
      );
      if (response && response.data) {
        const data = {
          count: response.data.data.length,
          data: response.data.data,
        };
        return resolve(
          dispatcher(dispatch, data, LIST_PAYRUN_COMPLETED, false)
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function clearApprovedPayrun({ id, cancelToken, data }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_REJECT_URL",
        options: { id },
      });
      const response = await http.post(url, data);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data.result, "REJECT_PAYRUN")
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listPayschedulePayruns({ id, options, cancelToken }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYSCHEDULE_PAYRUN",
        options: { id },
      });
      dispatch({ type: "SET_PROGRESS_BAR" });
      const response = await requestBuilder(
        null,
        url,
        options || {},
        cancelToken
      );
      dispatch({ type: "UNSET_PROGRESS_BAR" });
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, LIST_PAYRUN_TOTAL)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: LIST_PAYRUN_TOTAL,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getPayrun({
  id,
  options,
  cancelToken,
  payrunDetailLoading = false,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
      };
      if (payrunDetailLoading) {
        dispatch({ type: PAYRUN_DETAIL_LOADING, hasPayrunDetailLoaded: false });
      }

      const url = APIHandler.constructEndpoint({ endpoint: "PAYRUN_URL" });
      const response = await requestBuilder(id, url, queryOptions, cancelToken);
      if (response.status === 404) {
        message.error(UNATHORIZED_ACCESS_ERRORS);
        delay(400).then(() => goToRoute(DEFAULT_HOME_ROUTE));
      }

      if (payrunDetailLoading) {
        dispatch({ type: PAYRUN_DETAIL_LOADING, hasPayrunDetailLoaded: true });
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, PAYRUN_DETAIL)
          )
        );
      } else {
        return resolve(
          dispatch({
            ...ResponseHandler.inValidObject(response),
            type: PAYRUN_DETAIL,
          })
        );
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function addAmendment(id, values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "ADD_AMENDMENT",
        options: { id },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "ADD_AMMENDMENT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function uploadAmendmentFile({ id, values, payrunId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: payrunId
          ? "AMENDMENT_UPLOAD_URL"
          : "GENERAL_AMENDMENTS_UPLOAD_URL",
        options: payrunId
          ? { id: payrunId, amendmentId: id, action: "upload" }
          : { amendmentId: id, action: "upload" },
      });
      const response = await http.postFile(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.responseText, "UPLOAD_AMENDMENT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function updateAmendment({ id, values, payrunId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: payrunId
          ? "AMENDMENT_ACTIONID_URL"
          : "GENERAL_AMENDMENTS_DETAIL_URL",
        options: { id: payrunId || id, amendmentId: id, action: "update" },
      });
      const response = await http.put(payrunId ? url : `${url}/update`, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "ADD_AMMENDMENT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function generateAdhoc(values) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "ADHOC_PAYRUN" });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_GENERATE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function payInvoice({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "paymentmade" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_PAYMENT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function confirmPayrun({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "confirm" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_CONFIRM")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function preparePayrun({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "prepare" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_PREPARE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function approvePayrun({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "APPROVE_EARNING",
        options: { id },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_APPROVE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function makePayment({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "paymentmade" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_PAYMENT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function confirmPayment({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "confirmpayment" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "CONFIRM_PAYMENT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function processPayrun({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "processing" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_PROCESS")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function confirmProcessingPayrun({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "processed" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_PROCESS_COMPLETE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function completePayrun({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_ACTION_URL",
        options: { id, action: "completed" },
      });
      const response = await http.post(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "PAYRUN_COMPLETE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function exportTo({ id, invoiceId, action, newContact }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXPORT_URL",
        options: { id, invoiceId },
      });
      const isNewContact = newContact ? true : false;
      const response = await http.post(
        `${url}/${action}${
          action === "exportToXero" ? `?newContact=${isNewContact}` : ""
        }`
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "INVOICE_EXPORT")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function xeroCallback({ returnUrl }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "XERO_CALLBACK",
        options: {},
      });
      const response = await http.get(`${url}?returnUrl=${returnUrl}`);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "XERO_CALLBACK")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function downloadInvoice({ id }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "INVOICE_DOWNLOAD_URL",
        options: { id },
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "DOWNLOAD_INVOICE")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listAmendmentTypes({
  options,
  businessUnitID,
  amendmentType,
  lookUp,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
        per_page: 1500,
        q: {},
      };
      if (businessUnitID) {
        queryOptions.q = { businessUnitID };
      }
      if (amendmentType) {
        queryOptions.q = { ...queryOptions.q, amendmentType };
      }
      const url = APIHandler.constructEndpoint({
        endpoint: lookUp ? "PAY_CATEGORY_LOOKUP_URL" : "AMENDMENT_TASK_URL",
        options: {},
      });

      const response = await requestBuilder(null, url, queryOptions);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GET_AMENDMENT_TYPES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getAmendmentTaskByType({ options = {}, changeTypeId }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        ...options,
        q: {},
      };

      if (changeTypeId) {
        queryOptions.q = { ...queryOptions.q, changeTypeId };
      }
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_TASK_BY_TYPE_URL",
        options: {},
      });

      const response = await requestBuilder(null, url, queryOptions);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GET_AMENDMENT_TYPES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getAmendmentTaskSubType({
  options = {},
  changeTaskValueId,
  businessUnitId,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        ...options,
        q: {},
      };

      if (changeTaskValueId) {
        queryOptions.q = {
          ...queryOptions.q,
          changeTaskValueId,
          businessUnitId,
        };
      }
      const url = APIHandler.constructEndpoint({
        endpoint: "AMENDMENT_TASK_SUB_TYPES_URL",
        options: {},
      });

      const response = await requestBuilder(null, url, queryOptions);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GET_AMENDMENT_TYPES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function payCategoryLookUp({ options, businessUnitID, type }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const queryOptions = {
        page: 1,
        ...options,
        per_page: 1500,
      };
      let endpoint = "EXPENSES_LOOKUP_URL";
      const url = APIHandler.constructEndpoint({
        endpoint,
        options: {},
      });

      const response = await requestBuilder(null, url, queryOptions);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GET_PAYCATEGORY_TYPES"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function exportABA({ id, data }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXPORT_ABA",
        options: { id },
      });
      const response = await http.post(url, data);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response.data, "EXPORT_ABA")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function earningLinesLookup({ options, businessUnitID }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EARNINGLINES_LOOKUP",
        options: {},
      });
      const response = await requestBuilder(
        null,
        `${url}?per_page=500&q=businessUnitID:${businessUnitID}`
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GET_EARNINGLINES_APPLICABLE"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function externalPayCategoryLookup({ payload, options }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "EXTERNAL_PAY_CATEGORY_LOOKUP_URL",
        options: {},
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              "GET_EXTERNAL_PAY_CATEGORY"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function listArchievedEmployees({ id, cancelToken, payload }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "ARCHIEVED_EMPLOYEES_URL",
        options: { id },
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(
            response.data.result,
            "ARCHIEVED_EMPLOYEES_URL"
          )
        );
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function payrunHandler({ id, cancelToken, options, payload, action }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let response = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "getCurrencyFees":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYRUN_CURRENCY_FEES_URL",
            options: { id },
          });
          response = await http.get(url, cancelToken);
          break;
        case "updateCurrencyFees":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYRUN_CURRENCY_FEES_URL",
            options: { id },
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "listEmployeesNotInPayschedule":
          url = APIHandler.constructEndpoint({
            endpoint: "EMPLOYEES_NOT_IN_PAYSCHEDULE_URL",
            options: { id },
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "importMissingEmployees":
          url = APIHandler.constructEndpoint({
            endpoint: "IMPORT_MISSING_EMPLOYEES_URL",
            options: { id },
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "edit":
          url = APIHandler.constructEndpoint({
            endpoint: "PAYRUN_EDIT_URL",
            options: { id },
          });
          response = await http.put(url, payload, cancelToken);
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getAmendmentKeypayStatus({ id, options = {} }: any) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_AMENDMENT_KEYPAY_STATUS_URL",
        options: { id },
      });
      const response = await requestBuilder(null, url, options);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response?.data?.result));
      } else {
        return resolve({ ...ResponseHandler.inValidObject(response) });
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function uploadPayrunReports({ id, payload = {}, displayName }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let url = APIHandler.constructEndpoint({
        endpoint: "UPLOAD_PAYRUN_REPORTS_URL",
        options: { id: id, displayName: displayName },
      });
      let tempResponse = await http.postFile(url, { file: payload }, "file");
      let parsedResponse = parseItems(tempResponse.response, {});
      let response = {
        data: { ...parsedResponse },
        status: tempResponse?.status,
      };
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response?.data?.result));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function getPayrunReports(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "GET_PAYRUN_REPORTS_URL",
        options: { id },
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response?.data?.result));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export function downloadPayrunReports({ id, fileName }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "DOWNLOAD_PAYRUN_REPORTS_URL",
        options: { id },
      });
      const response = await http.downloadFile(url, fileName);
      return resolve(response);
    }).catch(async (err) => {
      throw err.message;
    });
}

// Attach next payrun amendments to a payrun
export function attachAmendments({
  payload,
  id,
}: {
  payload: Array<string>;
  id: string;
}) {
  return () =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "ATTACH_AMENDMENTS_URL",
        options: { id },
      });
      const response = await http.post(url, payload);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(ResponseHandler.validObject(response.data));
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

// Link the payrun in GP to one in the payroll
export function linkPayrunToPayroll({ id, values }) {
  return () =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "LINK_EXTERNAL_PAYRUN_URL",
        options: { id },
      });
      const response = await http.post(url, values);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          ResponseHandler.validObject(response, "EXTERNAL_EARNINGS_POST")
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}

export async function updatePayrunDates({ payload, cancelToken }) {
  const { client } = httpClient();
  const url = APIHandler.constructEndpoint({
    endpoint: "UPDATE_PAYRUN_DATES_URL",
  });
  const response = await client.put(url, payload, cancelToken).catch((err) => {
    errorDisplay(err.response.data.validationErrors, true);
    if (payrunIsSkipped(err.response.data.validationErrors.errors)) {
      goToRoute(DEFAULT_ROUTES.PAYRUN);
    }
  });

  return response;
}

export async function skipPayruns({ payload, cancelToken }) {
  const { client } = httpClient();
  const url = APIHandler.constructEndpoint({
    endpoint: "SKIP_PAYRUNS_URL",
  });
  const response = await client
    .put(url, payload, cancelToken)
    .catch((err) => errorDisplay(err.response.data.validationErrors, true));

  return response;
}

export function updateReimportEarninglines(reimportEarningLines: boolean) {
  return (dispatch) => {
    dispatch({
      type: PAYRUN_EARNINGLINES_REIMPORT,
      data: reimportEarningLines,
    });
  };
}

export function reimportEarnings({ id, values }) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "PAYRUN_REIMPORT_EARNINGS_URL",
        options: { payrunId: id },
      });
      const response = await http.post(url, values);
      return resolve(response);
    }).catch(async (err) => {
      throw err.message;
    });
}
