import { useParams } from 'react-router-dom';

import { ButtonSize, ButtonType, ButtonWithState } from '@xemplo/button';
import { usePayInvoice } from '@xemplo/payrun-query';
import { ToastType, useToast } from '@xemplo/toast';
export const payInvoiceTestId = 'pay-invoice-button';
export function PayrunPayInvoice() {
  const { id } = useParams();
  const { addToast } = useToast();
  const { mutateAsync: payInvoice } = usePayInvoice({
    payrunId: id ?? null,
    onSuccess: () => {
      addToast({
        text: 'Invoice paid',
        type: ToastType.Confirmation,
      });
    },
    onError: () => {
      addToast({
        text: 'Something went wrong, please try again',
        type: ToastType.Warning,
      });
    },
  });

  return (
    <ButtonWithState
      testId={payInvoiceTestId}
      type={ButtonType.Primary}
      size={ButtonSize.Small}
      ariaLabel="Pay-invoice"
      onClickAsync={payInvoice}
    >
      Pay Invoice
    </ButtonWithState>
  );
}

export default PayrunPayInvoice;
