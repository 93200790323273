//@ts-nocheck
import React from "react";
import { getClaimAndStatus, mustBeArray } from "../../../libs/utilities";
import { Checkbox, Select, Tooltip } from "antd";
import { CLEAR_SELECTION_VALUE } from "../../../constants/ui";

const Option = Select.Option;

export interface MultiFieldDropdownFilterProps {
  name?: string;
  claims?: any;
  className?: string;
  placeholder?: string;
  onChange: (e: any) => void;
  value?: any;
  options?: any;
  onChange: (e: any) => void;
  filterOption?: boolean;
  searchBy: string;
  valueParam?: string;
  showSearch?: boolean;
  ignoreStatus?: boolean;
  nameParam?: string;
  onClear?: (e: any) => void;
}

const MultiFieldDropdownFilter: React.FC<MultiFieldDropdownFilterProps> = ({
  name,
  claims,
  className,
  placeholder,
  onChange,
  filterOption,
  value,
  searchBy,
  showSearch,
  ignoreStatus,
  valueParam,
  nameParam,
  options,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <Select
      showArrow
      placeholder={placeholder}
      onChange={onChange}
      mode="multiple"
      filterOption={filterOption || false}
      value={mustBeArray(value)}
      className={`${className} ${
        ignoreStatus ? "" : claimAndStatus.status
      } custom-multi-selectbox`}
      showSearch={showSearch || false}
      optionFilterProp={"searchby"}
      autoComplete="none"
      size="large"
      maxTagCount="responsive"
    >
      {mustBeArray(options).map((s: any, key: number) => (
        <Option
          key={key}
          value={s[valueParam || "id"]}
          searchby={s[searchBy]}
          className="custom-multi-select-dropdown"
        >
          <Checkbox
            checked={mustBeArray(value).includes(s[valueParam || "id"])}
            className="mr-3"
          />
          <Tooltip title={s[nameParam || "value"]} placement="topRight">
            {s[nameParam || "value"]}
          </Tooltip>
        </Option>
      ))}

      {!_.isEmpty(mustBeArray(options)) && (
        <Option key="clearSelection" value={CLEAR_SELECTION_VALUE}>
          Clear Selection
        </Option>
      )}
    </Select>
  );
};

export default MultiFieldDropdownFilter;
