import { EllipsisOutlined } from "@ant-design/icons";
import React from "react";

const DoubleVerticalEllipsisIcon = () => {
  return (
    <React.Fragment>
      <EllipsisOutlined className="origin-center rotate-90 text-3xl opacity-80 font-light" />
      <EllipsisOutlined className="origin-center rotate-90 text-3xl -ml-5 opacity-80 font-light" />
    </React.Fragment>
  );
};

export default DoubleVerticalEllipsisIcon;
