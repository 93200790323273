//@ts-nocheck
import React from "react";
import {
  ApiOutlined,
  CloseOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  message,
  Checkbox,
  Avatar,
  Tooltip,
  Layout,
} from "antd";
import _ from "lodash";
import moment from "moment";
import {
  formatTime,
  getPayScheduleDetailRoute,
  getSearchParamsFromUrl,
  isEmpty,
  validateInputValueIncludesOnlyNumbers,
} from "../../../libs";
import {
  guid,
  currencyFormater,
  mustBeArray,
  errorDisplay,
  getConditionalResponse,
  conditionalParameter,
  getRatePercentageText,
  getFeeOptionName,
  getTaxOptionName,
  errorHandler,
} from "../../../libs/utilities";
import PayScheduleConnect from "../payrun/connect";
import PayScheduleForm from "./form/payschedule";
import ApplicableFeesOptions from "../common/feeOption";
import EmployeeRate from "../common/employeeRate";
import StateRate from "../common/stateRate";
import ProcessingFeesView from "../processingFees/view";
import PayCategory from "../common/payCategory";
import BaseSalary from "../common/baseSalary";
import CountryCoordinates from "../../../Common/countries";
import CreateSuccess from "../../../Common/CreateSuccess";
import FormNavbar from "../../../Layout/FormNavbar";
import NoRecord from "../../../Common/NoRecord";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import {
  END_EMPLOYEE_NUMBER,
  PROCESSING_FEES_OPTIONS,
} from "../../../constants/payrun";
import {
  isEmployeeRangeValid,
  isProcessingFeesValid,
  processingFeesHasAtLeastOneFieldSelected,
  validateCreatePaySchedule,
} from "../../../libs/validations";
import axios from "axios";
import { FormStepsCol, If } from "../../../Common/ui";
import {
  DEFAULT_HOME_ROUTE,
  PAYSCHEDULE_EDITABLE_PARAM,
} from "../../../constants";
import messages from "../../../constants/message";
import { withRouter } from "../../../hooks";

const { Header, Content } = Layout;

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

type State = any;

class CreatePaySchedule extends React.Component<{}, State> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      editMode: false,
      disableIndex: 4,
      submitText: "SAVE",
      buttonProps: {
        loading: false,
        type: "primary",
        text: "Connect",
      },
      businessunit: {},
      disableBU: false,
      disableCompany: false,
      schedule: {},
      visible: false,
      connectionSet: false,
      feeOptions: PROCESSING_FEES_OPTIONS,
      taxOptions: [],
      selectedOptions: [],
      companyList: [],
      company: [],
      companyId: "",
      businessunitList: [],
      businessunitId: "",
      fetching: false,
      payDate: 61,
      steps: [],
      payrollProviderId: null,
      connection: {},
      loading: false,
      employeeNumberRangeFactor: [],
      amendments: [],
      earningLines: [],
      states: [],
      defaultAmendments: [],
      defaultEarningLines: [],
      showProcessingFeeDetails: false,
      feeDetails: {},
      validRange: [],
      payscheduleDates: {},
      formIndex: 0,
      confirmLoading: false,
      businessUnitChanged: true,
      expenses: [],
      defaultExpenses: [],
      expensesLoading: false,
      baseSalary: [],
      defaultBaseSalary: [],
      baseSalaryLoading: false,
      currencyFees: {},
      formLabels: [
        {
          id: 1,
          label: "Basic Details",
        },
        {
          id: 2,
          label: "External Payschedule",
        },
        {
          id: 3,
          label: "Processing Fees",
        },
      ],
      newPayScheduleId: "",
      actionOneLink: "",
      payschedule: {},
      basePayList: [],
      bankFileDateInDay: 0,
      checkStateRateConfirmation: false,
      businessunitid: getSearchParamsFromUrl("businessunitid"),
      companyid: getSearchParamsFromUrl("companyid"),
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const payscheduleid = match.params?.payscheduleid;
    const { businessunitid } = this.state;
    if (businessunitid) {
      this.getCurrencyFees(businessunitid);
      this.changedBusinessUnit(businessunitid);
    }
    if (payscheduleid) {
      this.setState({
        editMode: true,
        formLabels: [
          {
            id: 1,
            label: "Basic Details",
          },
        ],
      });
      this.props
        .listPayschedules({
          id: payscheduleid,
          options: {},
          cancelToken: this.signal.token,
        })
        .then((response) => {
          if (response.status && response.data) {
            this.props.updateHeader({
              header: {
                title: response?.data?.payScheduleName,
                enableBack: true,
              },
            });
            // Check if the pay schedule has been processed already - processed payschedule cannot be edited
            this.payScheduleCanBeEdited(response.data);

            this.setState({ payschedule: response.data });
            this.getBasePayList(response.data.businessUnitID);
            this.preFillFormFields(response.data);
          } else {
            errorDisplay(response?.data?.validationErrors);
          }
        });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.forceUpdate();
  }

  payScheduleCanBeEdited = (data) => {
    if (data[PAYSCHEDULE_EDITABLE_PARAM]) {
      message.error(messages["paySchedule.editNotAllowed"]);
      return this.props.router.navigate(DEFAULT_HOME_ROUTE);
    }
  };

  getBasePayList = (businessUnitID) => {
    this.props
      .listAmendmentTypes({
        options: {},
        businessUnitID,
        lookUp: true,
        cancelToken: this.signal.token,
      })
      .then((resp) => {
        if (resp.status && resp.data) {
          this.setState({
            basePayList: mustBeArray(resp.data),
            defaultBaseSalary: mustBeArray(resp.data),
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });
  };

  preFillFormFields = (payschedule) => {
    if (!_.isEmpty(payschedule)) {
      let basePayList = payschedule.basePayMapping;
      let selectedBasePayList = mustBeArray(basePayList).reduce(
        (a, b) => [...a, b.id],
        []
      );

      this.props.form.setFieldsValue(
        {
          payScheduleName: payschedule.payScheduleName,
          payOccurence: payschedule.payOccurenceId,
          firstPayDate: moment(payschedule.firstPayDate),
          firstPayCycleStartDate: moment(payschedule.firstPayCycleStartDate),

          PayrollModel: Number(payschedule.payrollModelID),
          reference: payschedule.reference,
          baseSalary: selectedBasePayList,
        },
        () => {
          this.props.form.setFieldsValue({
            generatePayRunDays: payschedule.payRunGenerationDueInDays,
            generatePayRunTime: moment(
              payschedule.payRunGenerationDueTime,
              "HH:mm"
            ),
            isInvoiceNeeded: getConditionalResponse({
              condition: payschedule.isInvoiceNeeded,
              resp1: 1,
              resp2: 2,
            }),
            daysBeforeAfter: getConditionalResponse({
              condition: Number(payschedule.paymentDueInDays) >= 0,
              resp1: 1,
              resp2: 2,
            }),
            AmendmentsDue: payschedule.amendmentDueInDays,
            AmendmentsDueTime: moment(payschedule.amendmentDueTime, "HH:mm"),
            PayrunPreparationDue: payschedule.payRunPreparationDueInDays,
            PayrunPreparationDueTime: moment(
              payschedule.payRunPreparationDueTime,
              "HH:mm"
            ),
            ApprovalDue: payschedule.approvalDueInDays,
            ApprovalDueTime: moment(payschedule.approvalDueTime, "HH:mm"),
            PSPUploadBank: getConditionalResponse({
              condition: payschedule.isUploadBankFile,
              resp1: 1,
              resp2: 2,
            }),
            BankfileUploadDue: payschedule.bankFileUploadDueInDays,
            BankfileUploadDueTime:
              payschedule.bankFileUploadDueTime &&
              moment(payschedule.bankFileUploadDueTime),
            BankfileDated: getConditionalResponse({
              condition: payschedule.isBankFileUploadDatePayDay,
              resp1: 1,
              resp2: 2,
            }),
            bankFileDateInDay: payschedule.bankFileDateInDays,
            paymentDue: Math.abs(payschedule.paymentDueInDays),
            paymentDueTime:
              payschedule.paymentDueTime &&
              moment(payschedule.paymentDueTime, "HH:mm"),
          });
        }
      );

      this.setState({
        bankFileDateInDay: payschedule.bankFileDateInDays,
      });
      if (!_.isNull(payschedule.bankFileUploadDueTime)) {
        this.props.form.setFieldsValue({
          BankfileUploadDueTime: moment(
            payschedule.bankFileUploadDueTime,
            "HH:mm"
          ),
        });

        this.forceUpdate();
      }
    }
  };

  getCurrencyFees = (id) => {
    if (id) {
      this.props
        .businessunitHandler({
          action: "getCurrencyFees",
          id,
        })
        .then((resp) => {
          if (resp.status) {
            this.setState({ currencyFees: resp.data ? resp.data : {} }, () => {
              this.props.form.setFieldsValue({
                fixedConversionRate: resp?.data?.fixedConversionRate,

                percentConversionRate: resp?.data?.percentConversionRate,
              });
            });
          } else {
            errorDisplay(resp?.data?.validationErrors);
          }
        });
    }
  };

  handleNext = (values) => {
    switch (Number(this.state.formIndex)) {
      case 0:
        this.setState({ payscheduleDates: { ...values }, formIndex: 1 });
        break;
      case 1:
        const basePayMapping = this.state.baseSalary.filter((o) =>
          mustBeArray(this.props.form.getFieldValue("baseSalary")).includes(
            o.id
          )
        );
        if (isEmpty(basePayMapping))
          return message.error("Please select Base Pay.");

        this.setState({ basePayMapping, formIndex: 2 });

        this.props
          .listAmendmentTypes({
            options: {},
            businessUnitID: this.props.form.getFieldValue("businessunit"),
            lookUp: true,
            cancelToken: this.signal.token,
          })
          .then((resp) => {
            if (resp.status && resp.data) {
              this.setState({
                amendments: mustBeArray(resp.data),
                defaultAmendments: mustBeArray(resp.data),
              });
            } else {
              errorDisplay(resp?.data?.validationErrors);
            }
          });
        break;
      case 3:
        // submit form
        break;
      default:
        console.log("");
    }

    this.forceUpdate();
  };

  handleBack = () => {
    this.setState(
      (prevState) => {
        prevState.formIndex = Number(prevState.formIndex) - 1;
        prevState.loading = false;
        prevState.checkStateRateConfirmation = false;
        return prevState;
      },
      () => {
        this.forceUpdate();
      }
    );
  };
  hideStateCheckConfirmation = () => {
    if (this.state.checkStateRateConfirmation) {
      this.setState({ checkStateRateConfirmation: false });
    }
  };

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const isValidated = validateCreatePaySchedule({
        values,

        currencyFees: this.state.currencyFees,

        formIndex: this.state.formIndex,

        bankFileDateInDay: this.state.bankFileDateInDay,

        editMode: this.state.editMode,

        updatePaySchedule: this.updatePaySchedule,

        handleNext: this.handleNext,
      });
      if (!isValidated) return;

      this.setState({ loading: true });
      const {
        payrollSystemConnectionName,
        externalBusinessUnitName,
        externalPayScheduleName,
      } = this.state;
      let postData = {
        businessUnitId: values.businessunit,
        reference: values.reference,
        payScheduleName: values.payScheduleName,
        payOccurenceId: values.payOccurence,
        firstPayDate: values.firstPayDate.local().format("YYYY-MM-DD"),
        firstPayCycleStartDate: values.firstPayCycleStartDate
          .local()
          .format("YYYY-MM-DD"),
        payRunGenerationDueInDay: values.generatePayRunDays,
        payRunGenerationDueTime: formatTime(values.generatePayRunTime),
        amendmentDueInDay: values.AmendmentsDue,
        amendmentDueTime: formatTime(values.AmendmentsDueTime),
        payRunPreparationDueInDay: values.PayrunPreparationDue,
        payRunPreparationDueTime: formatTime(values.PayrunPreparationDueTime),
        payRunApprovalDueInDay: values.ApprovalDue,
        payRunApprovalDueTime: formatTime(values.ApprovalDueTime),
        payrollModelId: values.PayrollModel,
        isInvoiceNeeded: getConditionalResponse({
          condition:
            Number(values.PayrollModel) === 2 &&
            Number(values.isInvoiceNeeded) === 2,
          resp1: false,
          resp2: true,
        }),
        isUploadBankFile: getConditionalResponse({
          condition: Number(values.PSPUploadBank) === 1,
          resp1: true,
          resp2: false,
        }),
        bankFileUploadDueInDay: values.BankfileUploadDue,
        bankFileUploadDueTime:
          formatTime(values.BankfileUploadDueTime) !== "Invalid date" ??
          "00:00",
        isBankFileUploadDatePayDay: getConditionalResponse({
          condition: Number(values.BankfileDated) === 1,
          resp1: true,
          resp2: false,
        }),

        bankFileDateInDay: this.state.bankFileDateInDay,
        paymentDueInDay: values.paymentDue
          ? getConditionalResponse({
              condition: Number(values.daysBeforeAfter) === 2,
              resp1: Number(values.paymentDue) * -1,
              resp2: values.paymentDue,
            })
          : 0,
        paymentDueTime: values.paymentDueTime
          ? formatTime(values.paymentDueTime)
          : "00:00",
        isActive: true,

        payrollSystemConnectionId:
          this.state.connection.payrollSystemConnectionId,

        externalBusinessUnitId: this.state.connection.externalBusinessUnitCode,

        externalPayScheduleId: this.state.connection.externalPayScheduleCode,
        externalPayScheduleName,
        payrollSystemConnectionName,
        externalBusinessUnitName,

        basePayMapping: this.state.basePayMapping,
      };

      if (!_.isEmpty(this.state.currencyFees)) {
        const currencyFee = {
          fixedConversionRate: values.fixedConversionRate,
          percentConversionRate: values.percentConversionRate,

          convertFrom: this.state.currencyFees.convertFrom,

          convertTo: this.state.currencyFees.convertTo,
        };
        postData = {
          ...postData,
          currencyFee,
        };
      } else {
        postData.currencyFee = {
          fixedConversionRate: 0,
          percentConversionRate: 0,
          convertFrom: 0,
          convertTo: 0,
        };
      }
      return this.props.savePayschedules(postData).then((resp) => {
        this.setState({ loading: false });
        if (resp.status && resp.data) {
          this.setState({
            newPayScheduleId: resp.data.result,

            actionOneLink: getPayScheduleDetailRoute({
              id: resp.data.result,
              companyId: this.props.businessunitDetails.companyId,
              businessUnitId: values.businessunit,
            }),
          });

          let configuration = { ...this.state.connection };

          configuration["payScheduleID"] = resp.data.result;
          configuration["name"] = "test";
        } else {
          errorHandler({ response: resp });
        }
      });
    });
  };

  updatePaySchedule = (values) => {
    this.setState({ loading: true });

    const basePayMapping = _.filter(mustBeArray(this.state.basePayList), (o) =>
      mustBeArray(values.baseSalary).includes(o.id)
    );
    try {
      const postData = {
        businessUnitId: values.businessunit,
        payScheduleName: values.payScheduleName,
        payOccurenceId: values.payOccurence,
        firstPayDate: values.firstPayDate.local().format("YYYY-MM-DD"),
        firstPayCycleStartDate: values.firstPayCycleStartDate
          .local()
          .format("YYYY-MM-DD"),
        payRunGenerationDueInDay: values.generatePayRunDays,
        payRunGenerationDueTime: formatTime(values.generatePayRunTime),
        amendmentDueInDay: values.AmendmentsDue,
        amendmentDueTime: formatTime(values.AmendmentsDueTime),
        payRunPreparationDueInDay: values.PayrunPreparationDue,
        payRunPreparationDueTime: formatTime(values.PayrunPreparationDueTime),
        payRunApprovalDueInDay: values.ApprovalDue,
        payRunApprovalDueTime: formatTime(values.ApprovalDueTime),
        payrollModelId: values.PayrollModel,
        isUploadBankFile: getConditionalResponse({
          condition: Number(values.PSPUploadBank) === 1,
          resp1: true,
          resp2: false,
        }),
        bankFileUploadDueInDay: values.BankfileUploadDue,
        bankFileUploadDueTime:
          formatTime(values.BankfileUploadDueTime) !== "Invalid date" ??
          "00:00",
        isBankFileUploadDatePayDay: getConditionalResponse({
          condition: Number(values.BankfileDated) === 1,
          resp1: true,
          resp2: false,
        }),

        bankFileDateInDay: this.state.bankFileDateInDay,
        paymentDueInDay: values.paymentDue
          ? Number(values.daysBeforeAfter) === 2
            ? Number(values.paymentDue) * -1
            : values.paymentDue
          : 0,
        paymentDueTime: values.paymentDueTime
          ? formatTime(values.paymentDueTime)
          : "00:00",

        isActive: this.state.payschedule?.isActive,
        reference: values.reference,
        basePayMapping,
        isInvoiceNeeded: getConditionalResponse({
          condition:
            values.PayrollModel &&
            values.isInvoiceNeeded &&
            Number(values.PayrollModel) === 2 &&
            Number(values.isInvoiceNeeded) === 2,
          resp1: false,
          resp2: true,
        }),

        id: this.state.payschedule?.id,
      };
      this.props.updatePaySchedule(postData).then((resp) => {
        this.setState({ loading: false });

        if (resp.status) {
          message.success("Pay Schedule updated successfully.");
          const payscheduleid = this.props.match.params?.payscheduleid;
          const { companyid, businessunitid } = this.state;
          this.props.router.navigate(
            getPayScheduleDetailRoute({
              id: payscheduleid,
              companyId: companyid,
              businessUnitId: businessunitid,
            })
          );
        } else {
          errorHandler({ response: resp });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  createConnection = (
    values,
    payrollSystemConnectionName,
    externalBusinessUnitName,
    externalPayScheduleName
  ) => {
    this.setState({
      buttonProps: { ...this.state.buttonProps, loading: true },
      confirmLoading: true,
    });
    let payload = {
      payrollSystemConnectionId: values && values.payrollSystemConnectionId,
      externalBusinessUnitId: values.externalBusinessUnitCode,
    };

    this.props
      .confirmPayrollConnection({
        payload,
        id: this.props.form.getFieldValue("businessunit"),
      })
      .then((resp) => {
        this.setState({
          buttonProps: { ...this.state.buttonProps, loading: false },
          confirmLoading: false,
        });

        if (resp.status) {
          this.props.handleFormValueChange({
            field: "payrollSystemConnectionId",
            value: values && values.payrollSystemConnectionId,
          });

          this.props.handleFormValueChange({
            field: "externalBusinessUnitId",
            value: values && values.externalBusinessUnitId,
          });

          this.props.handleFormValueChange({
            field: "externalPayScheduleId",
            value: values && values.externalPayScheduleCode,
          });

          this.props.handleFormValueChange({
            field: "externalPayScheduleName",
            value: externalPayScheduleName,
          });

          this.props.handleFormValueChange({
            field: "payrollSystemConnectionName",
            value: payrollSystemConnectionName,
          });

          this.props.handleFormValueChange({
            field: "externalBusinessUnitName",
            value: externalBusinessUnitName,
          });

          this.setState(
            {
              connection: values,
              visible: false,
              payrollSystemConnectionName,
              externalBusinessUnitName,
              externalPayScheduleName,
              connectionSet: true,
              businessUnitChanged: false,
            },
            () => {
              this.props
                .listAmendmentTypes({
                  options: {},
                  businessUnitID: this.props.form.getFieldValue("businessunit"),
                  lookUp: true,
                })
                .then((response) => {
                  this.setState({ baseSalaryLoading: false });

                  if (response.status && response.data) {
                    this.setState({
                      baseSalary: mustBeArray(response.data),
                      defaultBaseSalary: mustBeArray(response.data),

                      amendments: mustBeArray(response.data),
                      defaultAmendments: mustBeArray(response.data),
                    });
                  } else {
                    errorDisplay(
                      response & response.data & response.data.validationErrors
                    );
                  }
                });
            }
          );
        } else {
          errorDisplay(resp?.data?.validationErrors, true);
        }
      });

    this.props
      .confirmPayrollConnection({
        payload,
        id: this.props.form.getFieldValue("businessunit"),
        type: "expenses",
      })
      .then((resp) => {
        if (resp.status) {
          this.props
            .payCategoryLookUp({
              options: {
                q: {
                  BusinessUnitID: this.props.form.getFieldValue("businessunit"),
                },
              },
            })
            .then((response) => {
              this.setState({ baseSalaryLoading: false });

              if (response.status) {
                this.setState({
                  expenses: mustBeArray(response.data),
                  defaultExpenses: mustBeArray(response.data),
                });
              } else {
                errorDisplay(response?.data?.validationErrors, true);
              }
            });
        }
      });
  };

  handleConnectionChange = () => {
    this.setState({
      visible: true,
      buttonProps: { loading: true },
    });
  };

  handleOk = () => {
    const form = this.formRef?.props?.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values.businessunit = this.formRef.getObjectById(
        values.keypayBusinessUnit,
        "businessunits"
      );

      values.schedule = this.formRef.getObjectById(
        values.keypayPaySchedule,
        "schedules"
      );

      this.setState(values);

      values["method"] = this.state.method;

      values["option"] = this.state.option;

      this.props.submitSchedule(values);
    });

    this.setState({
      visible: false,
      buttonProps: { loading: false, type: "danger", text: "Disconnect" },
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      buttonProps: { loading: false, type: "primary", text: "Connect" },
      confirmLoading: false,
    });
  };
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };
  onCancel = () => {
    if (this.props.header && this.props.header.returnUrl) {
      return this.props.router.navigate(this.props.header.returnUrl);
    }
    this.props.router.navigate(-1);
  };

  removeRow = (rowKey, index) => {
    const { form } = this.props;

    this.setState((prevState) => {
      _.remove(prevState.selectedOptions, function (n) {
        return n === rowKey.name;
      });
      return prevState;
    });

    this.props.removeOption(rowKey, index);
    let dueDate = form.getFieldValue("paymentDue");
    let dueTime = form.getFieldValue("paymentDueTime");
    form.resetFields(["paymentDue", "paymentDueTime"]);
    form.setFieldsValue({
      paymentDueTime: dueTime,
      paymentDue: dueDate,
    });
  };

  setBankFileDateInDay = (val) => {
    this.setState({ bankFileDateInDay: val });
  };

  addProcessingFees = () => {
    const form = this.props.form;

    let updatedEmployeeRangeFactor = form.getFieldValue("addEmployeeNumber")
      ? _.map(this.state.employeeNumberRangeFactor, (o) => {
          return { ...o, rate: Number(o.rate) };
        })
      : [];

    let updatedStateRate = _.map(this.state.states, (o) => {
      return { rate: Number(o.rate), stateName: o.value, stateId: o.id };
    });

    const validProcessingFees = isProcessingFeesValid({
      feeOption: form.getFieldValue("feeOption"),
      tax: form.getFieldValue("tax"),
      feeValue: form.getFieldValue("feeValue"),
      applyStateRate: form.getFieldValue("applyStateRate"),
      invoiceDescription: form.getFieldValue("invoiceDescription"),
      updatedStateRate,
    });
    const validEmployeeRange = isEmployeeRangeValid({
      updatedEmployeeRangeFactor,
      feeOption: form.getFieldValue("feeOption"),
      addEmployeeNumber: form.getFieldValue("addEmployeeNumber"),
      validRange: this.state.validRange,
    });
    if (!validProcessingFees || !validEmployeeRange) return;

    if ([1, 2].includes(Number(form.getFieldValue("feeOption")))) {
      // validation to check at least one is selected
      if (
        !processingFeesHasAtLeastOneFieldSelected({
          earningLines: this.props.form.getFieldValue("earningLines"),

          payCategory: this.props.form.getFieldValue("payCategory"),

          expenseCategory: this.props.form.getFieldValue("expenseCategory"),
          feeOption: form.getFieldValue("feeOption"),

          basePayChecked: this.props.form.getFieldValue("basePayChecked"),
        })
      )
        return;

      if (
        form.getFieldValue("applyStateRate") &&
        _.find(updatedStateRate, (o) => _.isNaN(o.rate) || Number(o.rate) === 0)
      ) {
        return this.setState({ checkStateRateConfirmation: true });
      }
    }

    this.submitProcessingFees();
  };

  submitProcessingFees = () => {
    this.cancelConfirmation();

    const { form } = this.props;

    let updatedStateRate = _.map(this.state.states, (o) => {
      return { rate: Number(o.rate), stateName: o.value, stateId: o.id };
    });

    let updatedEmployeeRangeFactor = form.getFieldValue("addEmployeeNumber")
      ? _.map(this.state.employeeNumberRangeFactor, (o) => {
          return { ...o, rate: Number(o.rate) };
        })
      : [];
    if (
      _.last(mustBeArray(updatedEmployeeRangeFactor)) &&
      !_.last(mustBeArray(updatedEmployeeRangeFactor)).to
    ) {
      updatedEmployeeRangeFactor[updatedEmployeeRangeFactor.length - 1].to =
        END_EMPLOYEE_NUMBER;
    }

    let updatedAmendments = _.map(this.state.amendments, (o) => {
      return {
        ...o,

        checked:
          mustBeArray(this.props.form.getFieldValue("payCategory")).includes(
            o.id
          ) ||
          mustBeArray(this.props.form.getFieldValue("payCategory")).includes(
            "all"
          ),
      };
    });

    let updatedEarninglines = _.map(this.state.earningLines, (o) => {
      return {
        ...o,

        checked: mustBeArray(
          this.props.form.getFieldValue("earningLines")
        ).includes(o.id),
      };
    });

    let updatedExpenses = _.map(this.state.expenses, (o) => {
      return {
        ...o,

        checked: mustBeArray(
          this.props.form.getFieldValue("expenseCategory")
        ).includes(o.id),
      };
    });
    let lastRangeFactor = _.last(updatedEmployeeRangeFactor);
    if (lastRangeFactor) {
      updatedEmployeeRangeFactor = [
        ...updatedEmployeeRangeFactor,
        {
          ...lastRangeFactor,
          from: Number(lastRangeFactor.to + 1),
          to: END_EMPLOYEE_NUMBER,
          rate: 0,
        },
      ];
    }

    let newOption = {
      name: getFeeOptionName({
        feeOptions: this.state.feeOptions,
        id: form.getFieldValue("feeOption"),
      }),
      feeOptionId: form.getFieldValue("feeOption"),
      rateOrPercentage:
        [1, 2].includes(Number(form.getFieldValue("feeOption"))) &&
        form.getFieldValue("applyStateRate")
          ? 0
          : form.getFieldValue("feeValue"),
      invoiceDescription: form.getFieldValue("invoiceDescription"),
      taxFeeId: form.getFieldValue("tax"),

      taxFeeName: getTaxOptionName({
        taxOptions: this.state.taxOptions,
        id: form.getFieldValue("tax"),
      }),
      isActive: true,
      feeOption: {
        basePayChecked: !!(
          [1].includes(Number(form.getFieldValue("feeOption"))) &&
          this.props.form.getFieldValue("basePayChecked")
        ),
        earningLines: [1].includes(Number(form.getFieldValue("feeOption")))
          ? updatedEarninglines
          : [],
        amendments: [],
        payCategory: [1].includes(Number(form.getFieldValue("feeOption")))
          ? updatedAmendments
          : [],
        expenseCategory: [1].includes(Number(form.getFieldValue("feeOption")))
          ? updatedExpenses
          : [],
      },
      employeeNumberRangeFactor:
        [1, 2].includes(Number(form.getFieldValue("feeOption"))) &&
        !_.isEmpty(this.state.employeeNumberRangeFactor)
          ? updatedEmployeeRangeFactor
          : [],
      stateRate:
        [1, 2].includes(Number(form.getFieldValue("feeOption"))) &&
        form.getFieldValue("applyStateRate")
          ? updatedStateRate
          : [],
    };

    this.props.addOptions(newOption);

    this.setState(
      (prevState) => {
        prevState.selectedOptions = [...prevState.selectedOptions, newOption];
        form.resetFields([
          "feeOption",
          "invoiceDescription",
          "feeValue",
          "tax",
          "basePayChecked",
        ]);
        prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
        prevState.earningLines = [
          ...mustBeArray(prevState.defaultEarningLines),
        ];
        prevState.employeeNumberRangeFactor = [];
        prevState.states = _.map(prevState.states, (o) => {
          return { ...o, rate: "" };
        });
        return prevState;
      },
      () => {
        let dueDate = form.getFieldValue("paymentDueDate");
        let dueTime = form.getFieldValue("paymentDueTime");
        form.resetFields(["paymentDueDate", "paymentDueTime"]);
        form.setFieldsValue({
          paymentDueTime: dueTime,
          paymentDueDate: dueDate,
        });
      }
    );
    form.resetFields([
      "feeOption",
      "invoiceDescription",
      "feeValue",
      "tax",
      "basePayChecked",
    ]);
  };
  cancelConfirmation = () => {
    this.setState({ checkStateRateConfirmation: false });
  };

  changedBusinessUnit = (id) => {
    this.setState({ businessUnitChanged: true, connectionSet: false });

    this.props.listTaxFees({ id, options: {} }).then((response) => {
      if (response.status && response.data) {
        this.setState({ taxOptions: response.data });
      } else {
        errorDisplay(response?.data?.validationErrors);
      }
    });

    this.fetchAllLookups(id);
    return this.props.getBusinessUnit({ id }).then((resp) => {
      if (resp.status && resp.data) {
        this.setState({
          payrollProviderId: resp.data.payrollServiceProviderId,
        });

        this.props
          .populateStates({ options: { countryId: resp.data.countryId } })
          .then((response) => {
            if (response.status && response.data) {
              this.setState((prevState) => {
                prevState.states = response.data.map((state) => {
                  return { ...state, rate: 0 };
                });
              });
            } else {
              errorDisplay(response?.data?.validationErrors);
            }
          });
      }
      return resp;
    });
  };

  fetchAllLookups = (id) => {
    this.props
      .earningLinesLookup({ options: {}, businessUnitID: id })
      .then((resp) => {
        if (resp.status) {
          this.setState({
            earningLines: resp.data,
            defaultEarningLines: resp.data,
          });
        } else {
          errorDisplay(resp?.data?.validationErrors);
        }
      });

    this.getCurrencyFees(id);
  };
  addEmployeeRange = () => {
    if (_.isEmpty(this.state.employeeNumberRangeFactor)) {
      this.setState((prevState) => {
        prevState.employeeNumberRangeFactor = [{ from: 1, to: "", rate: "" }];
        prevState.validRange = [{ isValid: false }];
        return prevState;
      });
    } else {
      const previousRange =
        this.state.employeeNumberRangeFactor[
          this.state.employeeNumberRangeFactor.length - 1
        ];

      this.setState((prevState) => {
        prevState.employeeNumberRangeFactor = _.concat(
          prevState.employeeNumberRangeFactor,
          [{ from: Number(previousRange.to) + 1, to: "", rate: "" }]
        );
        prevState.validRange = [
          ...mustBeArray(prevState.validRange),
          { isValid: false },
        ];
        return prevState;
      });
    }
  };
  deleteEmployeeRange = () => {
    this.setState((prevState) => {
      prevState.employeeNumberRangeFactor.pop();
      prevState.validRange.pop();
      return prevState;
    });
  };
  updateEmployeeNumber = (label, i, value) => {
    this.hideStateCheckConfirmation();
    const testPattern = /^[0-9.]+$/;

    this.setState((prevState) => {
      if (label === "rate") {
        prevState.employeeNumberRangeFactor[i][label] =
          !value || testPattern.test(Number(value))
            ? value
            : prevState.employeeNumberRangeFactor[i][label];
      } else {
        prevState.employeeNumberRangeFactor[i][label] = value;
      }
      if (
        label === "to" &&
        prevState.employeeNumberRangeFactor[i + 1] &&
        Number(value) >= prevState.employeeNumberRangeFactor[i + 1].from
      ) {
        prevState.employeeNumberRangeFactor[i + 1].from = Number(value) + 1;
      }
      if (
        label === "to" &&
        prevState.employeeNumberRangeFactor[i + 1] &&
        Number(value) <
          Number(prevState.employeeNumberRangeFactor[i + 1].from) - 1
      ) {
        prevState.employeeNumberRangeFactor[i + 1].from = Number(value) + 1;
      }
      return prevState;
    });
  };
  updateIsValid = (value, index) => {
    this.setState((prevState) => {
      prevState.validRange[index].isValid = value;
      return prevState;
    });
  };

  changeFeeOptions = (value) => {
    this.hideStateCheckConfirmation();
    if (Number(value) === 3) {
      this.setState({
        employeeNumberRangeFactor: [],
        expenses: [...mustBeArray(this.state.defaultExpenses)],

        earningLines: [...mustBeArray(this.state.defaultEarningLines)],
      });

      this.props.form.resetFields(["applyStateRate", "addEmployeeNumber"]);
    }
  };
  updateStatesRate = (value, index) => {
    this.hideStateCheckConfirmation();
    const testPattern = /^[0-9.]+$/;
    if (!value || testPattern.test(Number(value))) {
      this.setState((prevState) => {
        prevState.states[index].rate = value;
        return prevState;
      });
    }
  };

  handleChangeCheckbox = (value, label) => {
    this.hideStateCheckConfirmation();
    switch (label) {
      case "addEmployeeNumber":
        this.setState((prevState) => {
          if (!value) {
            prevState.employeeNumberRangeFactor = [
              { from: 1, to: "", rate: "" },
            ];
            prevState.validRange = [{ isValid: false }];
            return prevState;
          }
          prevState.employeeNumberRangeFactor = [];
          prevState.validRange = [];
          return prevState;
        });
        break;
      case "applyStateRate":
        this.setState((prevState) => {
          prevState.states = _.map(prevState.states, (o) => {
            return { ...o, rate: 0 };
          });
          return prevState;
        });
        break;
      default:
        break;
    }
  };
  showProcessingFeeDetails = (record) => {
    let feeDetails = {
      ...record,
      earningLines: record.feeOption && record.feeOption.earningLines,
      amendments: record.feeOption && record.feeOption.payCategory,
      feeOption: record.name,
      basePay: record.feeOption && record.feeOption.basePayChecked,
      expenses: record.feeOption && record.feeOption.expenseCategory,
    };

    this.setState({ feeDetails }, () => {
      this.setState({ showProcessingFeeDetails: true });
    });
  };
  closeProcessingFeeModal = () => {
    this.setState({ feeDetails: {}, showProcessingFeeDetails: false });
  };
  cancelProcessingFees = () => {
    this.setState((prevState) => {
      this.props.form.setFieldsValue({
        feeOption: "",
        invoiceDescription: "",
        feeValue: "",
        tax: "",
      });
      prevState.amendments = [...mustBeArray(prevState.defaultAmendments)];
      prevState.earningLines = [...mustBeArray(prevState.defaultEarningLines)];
      prevState.states = _.map(prevState.states, (o) => {
        return { ...o, rate: 0 };
      });
      return prevState;
    });
  };

  disableExpenses = () => {
    let totalExpenseitem = _.find(
      mustBeArray(this.state.earningLines),
      (o) => o.name === "Total Employee Expense"
    );

    if (
      mustBeArray(this.props.form.getFieldValue("earningLines")).includes(
        totalExpenseitem?.id
      )
    ) {
      this.props.form.resetFields(["expenseCategory"]);
      return true;
    }
    return false;
  };
  clearBasePay = () => {
    this.props.form.resetFields(["baseSalary"]);
  };

  getRatePercentage = (data, record) => {
    if (Number(record && record.feeOptionId) === 3) {
      return currencyFormater(data, this.props.businessunitDetails?.country);
    } else {
      switch (Number(record.feeOptionId)) {
        case 1:
          return !_.isEmpty(record && record["stateRate"])
            ? "State/s"
            : `${data}%`;
        case 2:
          return !_.isEmpty(record && record["stateRate"])
            ? "State/s"
            : currencyFormater(data, this.props.businessunitDetails?.country);
        default:
          return "";
      }
    }
  };

  checkSubmitDisabled = () => {
    if (this.state.editMode) return false;
    if (Number(this.state.formIndex) !== 2) return true;
    if (
      _.isEmpty(mustBeArray(this.props.options)) &&
      Number(this.props.form.getFieldValue("PayrollModel")) !== 2
    )
      return true;
    return false;
  };

  handleBasePayMappingChange = () => {
    console.log("");
  };

  handleFormValueChange = ({ field = "", value = "" }) => {
    this.hideStateCheckConfirmation();

    this.props.handleFormValueChange({ field, value });
  };

  getContinueClassName = () => {
    if (
      (!this.state.connectionSet ||
        _.isEmpty(this.props.form.getFieldValue("baseSalary")) ||
        _.isUndefined(this.props.form.getFieldValue("baseSalary"))) &&
      Number(this.props.form.getFieldValue("method")) === 1
    )
      return "";
    return "bg-success";
  };

  isContinueDisabled = () => {
    if (
      (!this.state.connectionSet ||
        _.isEmpty(this.props.form.getFieldValue("baseSalary")) ||
        _.isUndefined(this.props.form.getFieldValue("baseSalary"))) &&
      Number(this.props.form.getFieldValue("method")) === 1
    )
      return true;
    return false;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      employeeNumberRangeFactor,
      states,
      earningLines,
      formIndex,
      confirmLoading,
      currencyFees,
      newPayScheduleId,
      actionOneLink,

      formLabels,
      loading,
      basePayList,
      editMode,
    } = this.state;

    const { handleFormValueChange } = this.props;
    const colTemplate = [
      {
        title: "Invoice Description",
        dataIndex: "invoiceDescription",
        filterType: 0,
        key: "5",
      },
      { title: "Fee Option", dataIndex: "name", filterType: 0, key: "1" },
      {
        title: "Rate/Percentage",
        dataIndex: "rateOrPercentage",
        filterType: 0,
        key: "2",
        render: (rowKey, data) => this.getRatePercentage(rowKey, data),
      },
      {
        title: "Tax",
        dataIndex: "taxFeeName",
        filterType: 0,
        key: "3",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "4",
        render: (rowKey, _i, j) => (
          <React.Fragment>
            <Tooltip title="Delete" placement="bottom">
              <Avatar size="small" onClick={() => this.removeRow(rowKey, j)}>
                <CloseOutlined className="hand" />{" "}
              </Avatar>
            </Tooltip>
            {getConditionalResponse({
              condition: rowKey && Number(rowKey.feeOptionId) !== 3,

              resp1: (
                <Tooltip title="View" placement="bottom">
                  <Avatar
                    size="small"
                    className={`hand bg-primary ml-3.5`}
                    icon={<EyeOutlined />}
                    onClick={() => this.showProcessingFeeDetails(rowKey)}
                  />
                </Tooltip>
              ),
              resp2: <React.Fragment />,
            })}
          </React.Fragment>
        ),
      },
    ];
    const formLayoutModel = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };
    const stateTaxLayout = {
      labelCol: { span: 1 },
      wrapperCol: { span: 18 },
    };

    const countryCode = CountryCoordinates.getCode(
      conditionalParameter({
        data: this.props.businessunitDetails,
        field: "country",
      })
    );
    return (
      <Form
        className="mb-4"
        layout="vertical"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <Layout className="h-[100vh]">
          <If
            condition={newPayScheduleId}
            then={
              <NoRecord>
                <CreateSuccess
                  page="Pay Schedule"
                  actionOneLabel="View Pay Schedule"
                  actionOneLink={actionOneLink}
                />
              </NoRecord>
            }
            else={
              <React.Fragment>
                <Header className="bg-white">
                  <FormNavbar
                    title="Pay Schedule"
                    handleCancel={this.onCancel}
                    handleSave={this.handleSubmit}
                    htmlType="submit"
                    loading={loading}
                    disabled={this.checkSubmitDisabled()}
                    enableBack={true}
                    saveAsDraft={this.props.editMode}
                  />
                </Header>
                <Layout className="p-6 rounded-[5px] overflow-y-auto">
                  <Content>
                    <Row gutter={DEFAULT_GUTTER}>
                      <FormStepsCol
                        data={formLabels}
                        activeIndex={Number(formIndex) + 1}
                      />
                      <Col md={18} sm={24}>
                        <div
                          className={getConditionalResponse({
                            condition: Number(formIndex) === 0,
                            resp1: "block",
                            resp2: "display-none",
                          })}
                        >
                          <PayScheduleForm
                            {...this.props}
                            companyId={this.state.companyid}
                            businessUnitID={this.state.businessunitid}
                            signal={this.signal}
                            changedBusinessUnit={this.changedBusinessUnit}
                            setBankFileDateInDay={this.setBankFileDateInDay}
                            processingfees={this.props.options}
                            editMode={editMode}
                            basePayList={basePayList}
                            handleFormValueChange={handleFormValueChange}
                            payschedule={this.state.payschedule}
                            businessunitid={this.state.businessunitid}
                          />
                          <div>
                            {getConditionalResponse({
                              condition: !this.state.editMode,
                              resp1: (
                                <React.Fragment>
                                  <Button
                                    htmlType="submit"
                                    className="bg-success mb-4 float-right rounded-[20px]"
                                  >
                                    Continue
                                  </Button>
                                  <div className="clearfix"></div>
                                </React.Fragment>
                              ),
                              resp2: <React.Fragment />,
                            })}
                          </div>
                        </div>
                        <div
                          className={getConditionalResponse({
                            condition: Number(formIndex) === 1,
                            resp1: "block",
                            resp2: "display-none",
                          })}
                        >
                          <Card className="mb-5" title="Payroll Processing">
                            <React.Fragment>
                              <Row gutter={DEFAULT_GUTTER}>
                                <Col span={19}>
                                  <FormItem
                                    {...{
                                      labelCol: { span: 7 },
                                      wrapperCol: { span: 17 },
                                    }}
                                    label="Available Options:"
                                  >
                                    {getFieldDecorator("options", {
                                      initialValue: "1",
                                      rules: [
                                        {
                                          required: Number(formIndex) === 1,
                                          message: "Please select an option",
                                        },
                                      ],
                                    })(
                                      <Select
                                        disabled={this.state.connectionSet}
                                        className="w-full "
                                      >
                                        <Option value="1">Keypay</Option>
                                      </Select>
                                    )}
                                  </FormItem>
                                </Col>
                                <Col span={5}>
                                  <Button
                                    className="rounded-[20px]"
                                    loading={this.state.buttonProps.loading}
                                    type={this.state.buttonProps.type}
                                    icon={<ApiOutlined />}
                                    onClick={() =>
                                      this.handleConnectionChange()
                                    }
                                  >
                                    Connect
                                  </Button>
                                </Col>
                              </Row>
                              <Row gutter={DEFAULT_GUTTER}>
                                <Col span={19}>
                                  <BaseSalary
                                    baseSalary={this.state.baseSalary}
                                    getFieldDecorator={
                                      this.props.form.getFieldDecorator
                                    }
                                    form={this.props.form}
                                    formLayout={{
                                      labelCol: { span: 7 },
                                      wrapperCol: { span: 17 },
                                    }}
                                    loading={this.state.baseSalaryLoading}
                                    required={Number(formIndex) === 1}
                                    handleFormValueChange={
                                      this.handleBasePayMappingChange
                                    }
                                  />
                                </Col>
                                <Col span={5}>
                                  <Button
                                    type="primary"
                                    onClick={this.clearBasePay}
                                    className="min-w-[119px] rounded-[20px]"
                                  >
                                    Clear
                                  </Button>
                                </Col>
                              </Row>
                            </React.Fragment>
                            <PayScheduleConnect
                              title="Payroll System Connection"
                              wrappedComponentRef={this.saveFormRef}
                              visible={this.state.visible}
                              connectPayProcessor={
                                this.props.connectPayProcessor
                              }
                              handleOk={this.handleOk}
                              handleCancel={this.handleCancel}
                              payrollProviderId={this.state.payrollProviderId}
                              fetchPayrollConnectionName={
                                this.props.fetchPayrollConnectionName
                              }
                              configurePayscheduleConnection={
                                this.props.configurePayscheduleConnection
                              }
                              fetchExternalBusinessunit={
                                this.props.fetchExternalBusinessunit
                              }
                              fetchExternalPayschedules={
                                this.props.fetchExternalPayschedules
                              }
                              createConnection={this.createConnection.bind(
                                this
                              )}
                              confirmLoading={confirmLoading}
                              businessunitDetails={
                                this.props.businessunitDetails
                              }
                              businessUnitChanged={
                                this.state.businessUnitChanged
                              }
                            />
                          </Card>
                          <div>
                            <Button
                              onClick={this.handleBack}
                              className="rounded-[20px]"
                            >
                              Back
                            </Button>
                            <Button
                              htmlType="submit"
                              className={`rounded-[20px] ${this.getContinueClassName()} float-right`}
                              disabled={this.isContinueDisabled()}
                            >
                              Continue
                            </Button>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div
                          className={getConditionalResponse({
                            condition: Number(formIndex) === 2,
                            resp1: "block",
                            resp2: "display-none",
                          })}
                        >
                          {!_.isEmpty(currencyFees) && (
                            <Card
                              className="mb-4"
                              title={`Currency Conversion Fees`}
                            >
                              <h3>
                                {`From ${conditionalParameter({
                                  data: currencyFees,
                                  field: "convertFromCurrency",
                                })} to ${conditionalParameter({
                                  data: currencyFees,
                                  field: "convertToCurrency",
                                })}`}
                              </h3>
                              <Row gutter={DEFAULT_GUTTER}>
                                <Col span={12}>
                                  <FormItem
                                    label={
                                      <span>
                                        <span className="color-danger mr-1">
                                          *
                                        </span>
                                        Fixed Conversion Rate:
                                      </span>
                                    }
                                  >
                                    {getFieldDecorator("fixedConversionRate", {
                                      rules: [
                                        {
                                          required: false,
                                        },
                                      ],
                                      initialValue: 0,
                                    })(
                                      <InputNumber
                                        placeholder="Enter value"
                                        className="w-3/6"
                                        onKeyDown={(e) =>
                                          validateInputValueIncludesOnlyNumbers(
                                            { e, hasDecimal: true }
                                          )
                                        }
                                        onChange={(e) =>
                                          this.handleFormValueChange({
                                            field: "fixedConversionRate",
                                            value: e,
                                          })
                                        }
                                      />
                                    )}
                                  </FormItem>
                                </Col>
                                <Col span={12}>
                                  <FormItem
                                    label={
                                      <span>
                                        <span className="color-danger mr-1">
                                          *
                                        </span>
                                        Percent Conversion Rate:
                                      </span>
                                    }
                                  >
                                    {getFieldDecorator(
                                      "percentConversionRate",
                                      {
                                        rules: [
                                          {
                                            required: false,
                                          },
                                        ],
                                        initialValue: 0,
                                      }
                                    )(
                                      <InputNumber
                                        placeholder="Enter value"
                                        className="w-3/6"
                                        onKeyDown={(e) =>
                                          validateInputValueIncludesOnlyNumbers(
                                            { e, hasDecimal: true }
                                          )
                                        }
                                        onChange={(e) =>
                                          this.handleFormValueChange({
                                            field: "percentConversionRate",
                                            value: e,
                                          })
                                        }
                                      />
                                    )}
                                  </FormItem>
                                </Col>
                              </Row>
                            </Card>
                          )}

                          <Card
                            className="mb-4"
                            title="Payroll Processing Fees"
                          >
                            <Row>
                              <Col span={24}>
                                <Row gutter={DEFAULT_GUTTER} className="mb-3">
                                  <Col span={5}>
                                    <h4>Business Model:</h4>
                                  </Col>
                                  <Col span={12}>
                                    <h4 className="pl-6">
                                      {getConditionalResponse({
                                        condition:
                                          this.props.form &&
                                          Number(
                                            this.props.form.getFieldValue(
                                              "PayrollModel"
                                            )
                                          ) === 2,
                                        resp1: "Outsource",
                                        resp2:
                                          "Professional Employer Organisation (PEO)",
                                      })}
                                    </h4>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={22}>
                                <FormItem
                                  {...formLayoutModel}
                                  label={
                                    <span>
                                      <span className="color-danger mr-1">
                                        *
                                      </span>
                                      Fee Option:
                                    </span>
                                  }
                                >
                                  {getFieldDecorator("feeOption", {
                                    rules: [
                                      {
                                        required: false,
                                        message:
                                          "You need to select fee option",
                                      },
                                    ],
                                  })(
                                    <Select
                                      placeholder="Fee options"
                                      onChange={(value) =>
                                        this.changeFeeOptions(value)
                                      }
                                    >
                                      {mustBeArray(this.state.feeOptions).map(
                                        (fee) =>
                                          !this.state.selectedOptions.includes(
                                            fee.name
                                          ) ? (
                                            <Option key={guid()} value={fee.id}>
                                              {fee.name}
                                            </Option>
                                          ) : null
                                      )}
                                    </Select>
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={22}>
                                <FormItem
                                  {...formLayoutModel}
                                  label={
                                    <span>
                                      <span className="color-danger mr-1">
                                        *
                                      </span>
                                      Invoice Description:
                                    </span>
                                  }
                                >
                                  {getFieldDecorator("invoiceDescription", {
                                    rules: [
                                      {
                                        required: false,
                                        message:
                                          "You need to enter invoice description",
                                      },
                                    ],
                                  })(
                                    <Input
                                      placeholder="Invoice description"
                                      onChange={this.hideStateCheckConfirmation}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                            <div>
                              {[1].includes(
                                Number(
                                  this.props.form.getFieldValue("feeOption")
                                )
                              ) && (
                                <React.Fragment>
                                  <Row>
                                    <Col span={12} offset={5}>
                                      <FormItem
                                        {...stateTaxLayout}
                                        className="pb-0"
                                        label=" "
                                      >
                                        {getFieldDecorator("basePayChecked", {
                                          rules: [
                                            {
                                              required: false,
                                            },
                                          ],
                                          valuePropName: "checked",
                                        })(<Checkbox>Base Pay</Checkbox>)}
                                      </FormItem>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={22}>
                                      <ApplicableFeesOptions
                                        form={this.props.form}
                                        getFieldDecorator={
                                          this.props.form.getFieldDecorator
                                        }
                                        applicableFeesOptions={earningLines}
                                        decorator="earningLines"
                                        label="Earningline(s)"
                                        handleFormValueChange={
                                          this.handleFormValueChange
                                        }
                                        createPayschedule={true}
                                        defaultLabelCol={6}
                                        defaultWrapperCol={18}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col span={22}>
                                      <PayCategory
                                        payCategory={this.state.amendments}
                                        getFieldDecorator={
                                          this.props.form.getFieldDecorator
                                        }
                                        form={this.props.form}
                                        formLayout={formLayoutModel}
                                        editMode={false}
                                        selectedBaseSalary={this.props.form.getFieldValue(
                                          "baseSalary"
                                        )}
                                        handleFormValueChange={
                                          this.handleFormValueChange
                                        }
                                        defaultLabelCol={3}
                                        defaultWrapperCol={18}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={22}>
                                      <BaseSalary
                                        baseSalary={this.state.expenses}
                                        getFieldDecorator={
                                          this.props.form.getFieldDecorator
                                        }
                                        form={this.props.form}
                                        formLayout={formLayoutModel}
                                        loading={this.state.expensesLoading}
                                        disabled={this.disableExpenses()}
                                        required={false}
                                        label="Expense(s)"
                                        name="expenseCategory"
                                        handleFormValueChange={
                                          this.handleFormValueChange
                                        }
                                        defaultLabelCol={3}
                                        defaultWrapperCol={18}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </div>

                            <Row>
                              <Col span={22}>
                                <FormItem
                                  {...formLayoutModel}
                                  className="pb-0"
                                  label={`${getRatePercentageText({
                                    feeOption:
                                      this.props.form.getFieldValue(
                                        "feeOption"
                                      ),
                                  })}:`}
                                >
                                  {getFieldDecorator("feeValue", {
                                    rules: [
                                      {
                                        required: false,
                                        message: "You need to enter fee value",
                                      },
                                      {
                                        pattern: /^[0-9.]+$/,
                                        message:
                                          "Please enter only positive numbers and decimals",
                                      },
                                    ],
                                  })(
                                    <InputNumber
                                      min={0}
                                      placeholder="fee value"
                                      className="w-full  hideNumberPointers"
                                      onChange={this.hideStateCheckConfirmation}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                            {[1, 2].includes(
                              Number(this.props.form.getFieldValue("feeOption"))
                            ) && (
                              <React.Fragment>
                                <Row>
                                  <Col span={12} offset={5}>
                                    <FormItem
                                      {...stateTaxLayout}
                                      className="pb-0"
                                      label=" "
                                    >
                                      {getFieldDecorator("applyStateRate", {
                                        rules: [
                                          {
                                            required: false,
                                          },
                                        ],
                                        valuePropName: "checked",
                                      })(
                                        <Checkbox
                                          onChange={(e) =>
                                            this.handleChangeCheckbox(
                                              e.target.value,
                                              "applyStateRate"
                                            )
                                          }
                                        >
                                          Apply State Rate
                                        </Checkbox>
                                      )}
                                    </FormItem>
                                  </Col>
                                </Row>
                                {this.props.form.getFieldValue(
                                  "applyStateRate"
                                ) && (
                                  <Row>
                                    <Col span={22}>
                                      <StateRate
                                        form={this.props.form}
                                        getFieldDecorator={
                                          this.props.form.getFieldDecorator
                                        }
                                        updateStatesRate={this.updateStatesRate}
                                        createPayschedule={true}
                                        states={states}
                                        decorator="stateFeeOptions"
                                        label="State Rate"
                                      />
                                    </Col>
                                  </Row>
                                )}
                                <Row>
                                  <Col span={12} offset={5}>
                                    <FormItem
                                      {...stateTaxLayout}
                                      className="pb-0"
                                      label=" "
                                    >
                                      {getFieldDecorator("addEmployeeNumber", {
                                        rules: [
                                          {
                                            required: false,
                                          },
                                        ],
                                        valuePropName: "checked",
                                      })(
                                        <Checkbox
                                          onChange={(e) =>
                                            this.handleChangeCheckbox(
                                              e.target.value,
                                              "addEmployeeNumber"
                                            )
                                          }
                                        >
                                          Employee Number Applicable
                                        </Checkbox>
                                      )}
                                    </FormItem>
                                  </Col>
                                </Row>
                                {this.props.form.getFieldValue(
                                  "addEmployeeNumber"
                                ) && (
                                  <Row>
                                    <Col span={22}>
                                      <EmployeeRate
                                        form={this.props.form}
                                        getFieldDecorator={
                                          this.props.form.getFieldDecorator
                                        }
                                        employeeNumberRangeFactor={
                                          employeeNumberRangeFactor
                                        }
                                        addEmployeeRange={this.addEmployeeRange}
                                        deleteEmployeeRange={
                                          this.deleteEmployeeRange
                                        }
                                        updateEmployeeNumber={
                                          this.updateEmployeeNumber
                                        }
                                        updateIsValid={this.updateIsValid}
                                        createPayschedule={true}
                                        decorator="employeeFeeOptions"
                                        label="Employee Number Applicable"
                                        defaultLabelCol={3}
                                        defaultWrapperCol={18}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </React.Fragment>
                            )}
                            <Row>
                              <Col span={22}>
                                <FormItem
                                  className="pb-0"
                                  labelCol={{ span: 6 }}
                                  wrapperCol={{ span: 12 }}
                                  label={
                                    <span>
                                      <span className="color-danger mr-1">
                                        *
                                      </span>
                                      Tax:
                                    </span>
                                  }
                                >
                                  {getFieldDecorator("tax", {
                                    rules: [
                                      {
                                        required: false,
                                        message: "You need to select taxes",
                                      },
                                    ],
                                  })(
                                    <Select
                                      className="pr-0"
                                      placeholder="select tax"
                                    >
                                      {mustBeArray(this.state.taxOptions).map(
                                        (tax) => (
                                          <Option
                                            key={guid()}
                                            value={tax.id}
                                            className="pr-0"
                                          >
                                            {tax.value}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={11}>
                                <FormItem
                                  className="pb-0"
                                  labelCol={{ span: 12 }}
                                  wrapperCol={{ span: 12 }}
                                  label=" "
                                >
                                  <Popconfirm
                                    title="Some states are assigned 0 rate! Are you sure you want to continue?"
                                    visible={
                                      this.state.checkStateRateConfirmation
                                    }
                                    onConfirm={this.submitProcessingFees}
                                    onCancel={this.cancelConfirmation}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      icon={<PlusCircleOutlined />}
                                      theme="filled"
                                      onClick={() => this.addProcessingFees()}
                                      type="primary"
                                      className="mt-4 rounded-[20px]"
                                    >
                                      Add
                                    </Button>
                                  </Popconfirm>
                                  <Button
                                    theme="filled"
                                    onClick={() => this.cancelProcessingFees()}
                                    className="mt-4 text-[15px] ml-4 rounded-[20px]"
                                  >
                                    Cancel
                                  </Button>
                                </FormItem>
                              </Col>
                            </Row>
                          </Card>

                          <Card className="mb-4 card card-space-0">
                            <Table
                              columns={colTemplate}
                              size={"default"}
                              rowKey={(record, i) => i}
                              dataSource={mustBeArray(this.props.options)}
                              pagination={{ hideOnSinglePage: true }}
                            />
                            <ProcessingFeesView
                              visible={this.state.showProcessingFeeDetails}
                              onCancel={this.closeProcessingFeeModal}
                              feeDetails={this.state.feeDetails}
                              businessunitDetails={
                                this.props.businessunitDetails
                              }
                              countryCode={countryCode}
                              basePayMapping={this.state.basePayMapping}
                            />
                          </Card>
                          <div>
                            <Button
                              onClick={() => this.handleBack()}
                              className="mr-[15px] mb-4 rounded-[20px]"
                            >
                              Back
                            </Button>
                            <div className="clearfix" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Content>
                </Layout>
              </React.Fragment>
            }
          />
        </Layout>
      </Form>
    );
  }
}

export default withRouter(Form.create()(CreatePaySchedule));
