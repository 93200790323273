import { Item, TextDropdown } from '@xemplo/text-dropdown';

import * as S from '../payrun-approve-reject.styles';
import { FieldProps } from '../payrun-approve-reject.types';

export const CATEGORIES = [
  {
    index: '1',
    errorCategory: 'Wrong employee in the list',
    errorSource: 'Internal',
  },
  {
    index: '2',
    errorCategory: 'Wrong Amount for employee',
    errorSource: 'Internal',
  },
  {
    index: '3',
    errorCategory: 'Change in employee amendment(s)',
    errorSource: 'External',
  },
  {
    index: '4',
    errorCategory: 'Change in Employee wage',
    errorSource: 'External',
  },
  {
    index: '100',
    errorCategory: 'others',
    errorSource: 'External',
  },
];
export const RejectCategory = (props: FieldProps) => {
  const { shouldValidate, setPayload } = props;

  const handleChange = (e: React.Key) => {
    const errorCategory = CATEGORIES.find((item) => item.index === e);
    if (!errorCategory) return;
    setPayload((prev) => ({
      ...prev,
      errorCategory: errorCategory.errorCategory,
      errorSource: errorCategory.errorSource,
      rejectionReasonID: Number(errorCategory.index),
    }));
  };
  return (
    <S.FormLabel>
      Category
      <TextDropdown
        formSubmitting={shouldValidate}
        defaultItems={CATEGORIES}
        placeholder="Select category"
        aria-label="Select category for rejection"
        width="100%"
        isRequired
        onSelectionChange={handleChange}
      >
        {(item) => (
          <Item textValue={item.errorCategory} key={item.index}>
            {item.errorCategory}
          </Item>
        )}
      </TextDropdown>
    </S.FormLabel>
  );
};
