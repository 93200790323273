import _ from "lodash";
import { Form, Select } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import { ChangeTask, ChangeTaskTypes } from "@xemplo/gp-types";

type Props = {
  selectedChangeTaskType: ChangeTask;
  fields: any;
  userList: [];
  setSelectedUsers: (selectedUsers: []) => void;
  setFields: (options: any) => void;
  required: boolean;
  loading?: boolean;
  resetCSVToggle?: () => void;
};

const defaultLoading = false;

const AppliesToField = ({
  selectedChangeTaskType,
  userList,
  setSelectedUsers,
  setFields,
  fields,
  required,
  loading = defaultLoading,
  resetCSVToggle,
}: Props) => {
  const handleChange = (value: any, e: any) => {
    let newSelectedUsers;
    // If the selected User array is one person or less, reset the toggleCSVUpload value so the form shows the amount field instead of upload
    // Only applicable for amendment/create route. /payrun route won't have the resetCSVToggle prop.
    if (mustBeArray(value).length <= 1 && !!resetCSVToggle) {
      resetCSVToggle();
    }
    if (Array.isArray(value) && Array.isArray(e)) {
      newSelectedUsers = e.reduce((a, b) => {
        return [...a, b.key];
      }, []);
    } else {
      newSelectedUsers = [e.key];
    }
    setSelectedUsers(newSelectedUsers);
    let newSatus = {};
    if (newSelectedUsers && mustBeArray(newSelectedUsers).includes("all")) {
      newSatus = {
        disabled: true,
      };
    } else if (newSelectedUsers && !_.isEmpty(newSelectedUsers)) {
      newSatus = {
        allDisabled: true,
      };
    } else {
      newSatus = {
        allDisabled: false,
        disabled: false,
      };
    }

    setFields({
      ...fields,
      ...newSatus,
    });
  };
  const isPayInstruction =
    selectedChangeTaskType?.changeTaskValueId ===
    ChangeTaskTypes.PAY_INSTRUCTION;

  return (
    <Form.Item
      label="Applies To"
      className="mb-3  col-start-1"
      name="assignTo"
      rules={[
        {
          required: required && !isPayInstruction,
          message: "Please select employees",
        },
      ]}
    >
      <Select
        placeholder="Select"
        showSearch
        onChange={(value, e) => handleChange(value, e)}
        mode={selectedChangeTaskType?.multiplicity ? "multiple" : undefined}
        notFoundContent="No Employees Found"
        loading={loading}
      >
        {selectedChangeTaskType?.multiplicity && userList.length > 1 && (
          <Select.Option key="all" value="all" disabled={fields?.allDisabled}>
            All
          </Select.Option>
        )}
        {mustBeArray(userList).map((user: any) => {
          return (
            <Select.Option
              key={user.id}
              value={`${user.firstName} ${user.lastName}`}
              disabled={fields?.disabled}
            >
              {
                <div>
                  {`${user.firstName} ${user.lastName}`}
                  <span className="text-gray-700 text-xs">{` ${user.id}`}</span>
                </div>
              }
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default AppliesToField;
