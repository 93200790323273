// @ts-nocheck
import { http, ResponseHandler } from "../../libs";
import { requestBuilder, parseItems } from "../../libs/utilities";
import APIHandler from "../../constants/apiUrl";

export const billingServiceProviderHandler = ({
  id = null,
  accountId = null,
  xeroId = null,
  cancelToken,
  options = {},
  payload = {},
  targetEndpoint,
  action,
}) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let queryOptions = {
        page: 1,
        ...options,
      };
      let response: any = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "get":
          dispatch({ type: "SET_PROGRESS_BAR" });
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_PROVIDERS_URL",
            options: {
              id,
            },
          });
          response = await requestBuilder(id, url, queryOptions, cancelToken);
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "BILLINGSERVICEPROVIDER_DETAIL";
          break;
        case "getAccount":
          dispatch({ type: "SET_PROGRESS_BAR" });
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_ACCOUNT_URL",
            options: {
              id,
            },
          });
          response = await requestBuilder(
            accountId,
            url,
            queryOptions,
            cancelToken
          );
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "ACCOUNT_DETAIL";
          break;
        case "list":
          dispatch({ type: "SET_PROGRESS_BAR" });
          url =
            targetEndpoint ||
            APIHandler.constructEndpoint({
              endpoint: "BILLING_PROVIDERS_URL",
            });
          response = await requestBuilder(
            null,
            url,
            targetEndpoint ? {} : queryOptions,
            cancelToken
          );
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "LIST_BILLINGSERVICEPROVIDER";
          break;
        case "listAccount":
          dispatch({ type: "SET_PROGRESS_BAR" });
          queryOptions = { ...queryOptions, q: { enabled: true } };
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_ACCOUNT_URL",
            options: {
              id,
            },
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "LIST_ACCOUNTS";
          break;
        case "listXero":
          dispatch({ type: "SET_PROGRESS_BAR" });
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_XERO_URL",
            options: {
              id,
            },
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "LIST_XERO";
          // return (resolve(response));
          break;
        case "create":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_PROVIDERS_URL",
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "uploadLogo":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_PROVIDERS_UPLOAD_LOGO_URL",
          });
          let tempResponse = await http.postFile(
            url,
            payload,
            "file",
            cancelToken
          );
          let parsedResponse = parseItems(tempResponse.response, {});
          response = {
            data: { ...parsedResponse },
            status: tempResponse && tempResponse.status,
          };
          break;
        case "createAccount":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_ACCOUNT_URL",
            options: {
              id,
            },
          });
          response = await http.post(url, payload, cancelToken);
          break;
        case "update":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_PROVIDERS_URL",
            options: {
              id,
            },
          });
          response = await http.put(url, payload, cancelToken);
          break;
        case "updateAccount":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_ACCOUNT_DETAIL_URL",
            options: {
              id,
              accountId,
            },
          });
          response = await http.put(url, payload, cancelToken);
          break;
        case "updateXeroCode":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_XERO_DETAIL_URL",
            options: {
              id,
              xeroId,
            },
          });
          response = await http.put(url, payload, cancelToken);
          break;
        case "deleteAccount":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_ACCOUNT_DETAIL_URL",
            options: {
              id,
              accountId,
            },
          });
          response = await http.del(url, cancelToken);
          break;
        case "updateXero":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_XERO_URL",
            options: {
              id,
            },
          });
          response = await http.put(url, payload, cancelToken);
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
};

export function billingServiceProviderAccountConflictsHandler({
  id,
  conflictId,
  cancelToken,
  options,
  payload,
  action,
}) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      let queryOptions = {
        page: 1,
        ...options,
      };
      let response = { status: 200 };
      let url = "";
      let dispatch_type = "";
      switch (action) {
        case "list":
          dispatch({ type: "SET_PROGRESS_BAR" });
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_PROVIDERS_CONFLICTS_URL",
            options: { id },
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          dispatch({ type: "UNSET_PROGRESS_BAR" });
          dispatch_type = "LIST_BILLINGSERVICEPROVIDER_CONFLICTS";
          break;
        case "resolve":
          url = APIHandler.constructEndpoint({
            endpoint: "BILLING_PROVIDERS_CONFLICTS_RESOLVE_URL",
            options: {
              id,
              conflictId,
            },
          });
          response = await http.put(url, payload, cancelToken);
          dispatch_type = "UPDATE_BILLINGSERVICEPROVIDER_CONFLICTS";
          break;
        default:
          break;
      }

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result,
              dispatch_type
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async (err) => {
      throw err.message;
    });
}
