import { useScrollBehaviour } from './use-scroll-behaviour';
import * as S from './widget.styles';
import { WidgetProps } from './widget.types';

export function Widget(props: Readonly<WidgetProps>) {
  const { title, count, button, className } = props;
  const { containerRef, bodyRef, headerRef } = useScrollBehaviour();

  return (
    <S.Container className={className} ref={containerRef}>
      <S.Header className="widget-header" ref={headerRef}>
        <S.Title>
          {title}
          {count && <S.Count>({count})</S.Count>}
        </S.Title>
        {button}
      </S.Header>
      <S.Body className="widget-body" ref={bodyRef}>
        {props.children}
      </S.Body>
    </S.Container>
  );
}

export default Widget;
