import { Payrun } from "../../../../types";
export type EditMileStonesProps = {
  payrun: Payrun;
  setPayrunDatesState: (value: MilestoneState) => void;
  errorMessage: string | null;
};

export enum Milestone {
  AMENDMENT_DUE = "ammendmentDueDateTime",
  PREPARING_PAYRUN = "preparationDueDateTime",
  APPROVAL_DUE = "approvalDueDateTime",
  PAY_DATE = "payDate",
}

export type MilestoneTypes =
  | Milestone.AMENDMENT_DUE
  | Milestone.PREPARING_PAYRUN
  | Milestone.APPROVAL_DUE
  | Milestone.PAY_DATE;

export type MileStoneStateProps = {
  isDisabled: boolean;
  newValue: string | null;
  newValueIsWeekend: boolean;
};
export type MilestoneState = {
  [Milestone.AMENDMENT_DUE]: MileStoneStateProps;
  [Milestone.PREPARING_PAYRUN]: MileStoneStateProps;
  [Milestone.APPROVAL_DUE]: MileStoneStateProps;
  [Milestone.PAY_DATE]: MileStoneStateProps;
};

/** @knipignore */
export enum ActionType {
  SET_NEW_VALUE = "SET_NEW_VALUE",
  SET_NEW_VALUE_IS_WEEKEND = "SET_NEW_VALUE_IS_WEEKEND",
  DISABLE_FIELD = "DISABLE_FIELD",
  RESET_MILESTONES = "RESET_MILESTONE",
  RESET = "RESET",
}
export type Action = SetNewValue | DisableField | ResetMilestones | Reset;

type SetNewValue = {
  type: ActionType.SET_NEW_VALUE;
  payload: {
    milestone: Milestone;
    newValue: string | null;
    newValueIsWeekend: boolean;
  };
};

type DisableField = {
  type: ActionType.DISABLE_FIELD;
  payload: {
    milestones: Milestone[];
  };
};

type ResetMilestones = {
  type: ActionType.RESET_MILESTONES;
  payload: {
    milestones: Milestone[];
  };
};

type Reset = {
  type: ActionType.RESET;
};
