import { CloseOutlined, PushpinOutlined } from "@ant-design/icons";
import { message, Tooltip } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../hooks";
import {
  componentIsStatic,
  findAuthorizationClaims,
  getActiveDashboard,
  getConditionalResponse,
  getCurrentAuthorizationDom,
  handleRequest,
  isEmpty,
  mustBeArray,
  trimWhiteSpaces,
  updateAuthorizationClaims,
} from "../../../../libs";
import { RootState } from "../../../../store";
import { updateAuthorizationClaim } from "../../../../User/actions/member";
import { dashboardActionHandler } from "../../../actions/dashboard";

type Props = {
  claim: any;
  targetEndpoint?: string;
  pageClaims: any;
};
const DashboardPinComponent = ({
  claim,
  targetEndpoint,
  pageClaims,
}: Props) => {
  const dispatchAction: any = useDispatch();
  const { member, dashboard } = useAppSelector((state: RootState) => state);
  const dashboardList = dashboard.list;

  // The current dashboard object
  const [selectedDashboard, setSelectedDashboard] = useState<any>({});
  // claim for current dashboard component
  const [activeClaim, setActiveClaim] = useState<any>({});
  // boolean to indicate if component is resizable and draggable
  const [isStatic, setIsStatic] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadDashboardState();
  }, [dashboardList]);

  const loadDashboardState = () => {
    // Get the current dashboard component
    const activeDashboardList = getActiveDashboardComponents();
    // if there is no active dashboard matches, return the default dashboard instead
    const selectedDashboardList = getConditionalResponse({
      condition: !activeDashboardList && !isEmpty(dashboardList),
      resp1: dashboardList[0], //default dashboard
      resp2: activeDashboardList, //current dashboard (matched by url)
    });
    //find the current component in the list of pinned containers of the dashbaord
    let activeClaim = mustBeArray(selectedDashboardList?.pinnedContainers).find(
      (item) => item.targetEndpoint === targetEndpoint
    );
    //set local state for current component claim, isStatic
    setSelectedDashboard(selectedDashboardList);
    setActiveClaim(activeClaim);
    setIsStatic(componentIsStatic(activeClaim));
  };

  //   Get the list of components from the active dashboard
  const getActiveDashboardComponents = () => {
    // get name of current dashboard from url
    const activeDashName = getActiveDashboard();
    //match the url name with dashboard list in global state
    const selectedDashboard = dashboardList.find(
      (o: any) => trimWhiteSpaces(o?.name) === trimWhiteSpaces(activeDashName)
    );
    return selectedDashboard;
  };

  //   Update dashboard (delete component from dashboard)
  const updateSelectedDashboard = () => {
    //Only update if not loading
    if (!loading) {
      setLoading(true);
      let newPinnedContainersList = [];
      let payload = {};
      // Create a new list of dash components without the current component
      newPinnedContainersList = selectedDashboard?.pinnedContainers?.filter(
        (o: any) => o.targetEndpoint !== targetEndpoint
      );
      //update payload with new list
      payload = {
        ...selectedDashboard,
        pinnedContainers: [...newPinnedContainersList],
      };
      const id = selectedDashboard?.id;
      // Save the updated components list in BE
      dispatchAction(
        dashboardActionHandler({
          action: "update",
          id,
          payload,
        })
      ).then((resp: any) => {
        handleRequest({
          response: resp,
          successMessage: "Component unpinned from the dashboard successfully.",
        });
      });
      let tempPersonalizations = structuredClone(claim?.personalizations);
      tempPersonalizations[1].pinnedTo = mustBeArray(
        tempPersonalizations[1].pinnedTo
      ).filter((o: any) => o !== activeClaim?.id);
      handleClaimsUpdate({ value: tempPersonalizations });
      setLoading(false);
    }
  };

  //   Update DOM
  const handleClaimsUpdate = ({
    value,
    label = "personalizations",
  }: {
    value: any;
    label?: string;
  }) => {
    const currentAuthorizationDOM = getCurrentAuthorizationDom({ member });
    const updatedDOM = updateAuthorizationClaims({
      claims: currentAuthorizationDOM,
      value,
      label,
      name: claim?.name,
    });
    const updatedComponent = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: claim?.name,
    });
    dispatchAction(
      updateAuthorizationClaim({
        claim: updatedDOM,
        payload: {
          personaComponents: [updatedComponent],
        },
        update: true,
      })
    );
  };

  //   Component position
  const handleComponentPosition = () => {
    // only update if not loading
    if (!loading) {
      setLoading(true);
      //create a new list of pinned dash components
      let newPinnedContainersList = mustBeArray(
        selectedDashboard?.pinnedContainers
      ).map((item) => {
        // if the pinned component is the active one, toggle its draggable and resizable properties
        return item.targetEndpoint === targetEndpoint
          ? {
              ...item,
              draggable: !item.draggable,
              resizeable: !item.resizeable,
            }
          : item;
      });
      //create payload with new pinned component list
      const payload = {
        ...selectedDashboard,
        pinnedContainers: newPinnedContainersList,
      };
      const dashboardId = selectedDashboard?.id;
      // Update the dashboard list in the BE
      dispatchAction(
        dashboardActionHandler({
          action: "update",
          id: dashboardId,
          payload,
        })
      ).then((resp: any) => {
        if (resp.status) {
          message.success(
            `Component ${getConditionalResponse({
              condition: isStatic,
              resp1: "made draggable and resizeable",
              resp2: "made static",
            })} successfully.`
          );
          setLoading(false);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Unpin from dashboard">
        <CloseOutlined
          className="color-danger hand"
          onClick={() => updateSelectedDashboard()}
        />
      </Tooltip>
      <Tooltip
        title={`Make component ${
          isStatic ? "draggable and resizeable" : "static"
        }`}
      >
        <PushpinOutlined
          className={classNames("text-base ml-2.5 hand", {
            "color-primary transform-minus-45": isStatic,
          })}
          onClick={handleComponentPosition}
        />
      </Tooltip>
    </React.Fragment>
  );
};

export default DashboardPinComponent;
