import { SVGProps } from 'react';
const DawnMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.758 4.393a3.75 3.75 0 0 0-3.75 3.75v7.714a3.75 3.75 0 0 0 3.75 3.75h12.487a3.75 3.75 0 0 0 3.75-3.75V8.143a3.75 3.75 0 0 0-3.75-3.75H5.758Zm-2.25 3.75a2.25 2.25 0 0 1 2.25-2.25h12.487a2.25 2.25 0 0 1 2.25 2.25v7.714a2.25 2.25 0 0 1-2.25 2.25H5.758a2.25 2.25 0 0 1-2.25-2.25V8.143Zm4.319.969a.75.75 0 1 0-.894 1.205l4.026 2.986c.62.46 1.466.46 2.085 0l4.026-2.986a.75.75 0 0 0-.893-1.205l-4.027 2.986a.25.25 0 0 1-.297 0L7.827 9.112Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnMail;
