// @ts-nocheck
import "@ant-design/compatible/assets/index.css";

import {
  Col,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import _ from "lodash";
import React, { useEffect } from "react";

import { Form } from "@ant-design/compatible";
import { useHttpClient } from "@xemplo/http";

import { defaultCurrencyList } from "../../../constants/currency";
import { PAYROLL_PROCESSING_COUNTRIES } from "../../../constants/payroll";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { useAppDispatch } from "../../../hooks";
import { mustBeArray } from "../../../libs/utilities";
import { getCompany } from "../../actions/companies";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

/**
 * @knipignore
 */
export function AccountDetailsBusinessUnitForm(props: any) {
  const {
    form,
    handleChangeBSP,
    bspList,
    fetchBSP,
    fetchAccount,
    accountList,
    fetchingPayroll,
    handleChangeConnection,
    fetchingPayrollConnection,
    isInvoiceCompany,
    updateIsInvoiceCompany,
    payrollprovider,
    fetchingExternalBusinessUnit,
    handleChangeAccount,
    defaultPayrollConnection,
    defaultExternalBusinessUnitList,
    pspUsersList,
    providers,
    activeIndex,
    handleChangePayrollProvider,
    handleChangeServiceCountry,
  } = props;

  const dispatch = useAppDispatch();
  const { cancelSignal } = useHttpClient();

  //Whenever the company is changed, fetch the company PSP and apply it to the psp field
  useEffect(() => {
    //fetch the company data for the selected company
    if (form.getFieldValue("company")) {
      dispatch(
        getCompany({
          id: form.getFieldValue("company"),
          options: {},
          cancelToken: cancelSignal.token,
        })
      ).then((res: any) => {
        // assign the company PSP to the form PSP field
        form.setFieldsValue({
          payrollserviceprovider: res.data.payrollServiceProviderId,
        });
        handleChangePayrollProvider(res.data.payrollServiceProviderId);
      });
    }
  }, [form.getFieldValue("company")]);

  const checkInputValue = (e: any) => {
    if (/^([a-zA-Z!@#$%^&*<>/?-])$/.test(e.key)) {
      return e.preventDefault();
    }
  };

  const getCountryCurrencyCode = () => {
    const countrySelected = _.find(
      mustBeArray(defaultCurrencyList),
      (o) =>
        o.countryID === Number(props.form.getFieldValue("addressCountryID"))
    );
    return countrySelected?.code;
  };
  const getAccountCurrencyCode = () => {
    const accountId = props.form.getFieldValue("bspAccountId");
    return (
      _.find(mustBeArray(accountList), (o) => o.id === accountId) &&
      _.find(mustBeArray(accountList), (o) => o.id === accountId).currency
    );
  };

  const { getFieldDecorator } = form;
  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="Invoice Prefix">
            {getFieldDecorator("referenceCode", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Reference code is required",
                },
              ],
            })(<Input placeholder="Enter reference code" />)}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Service Country">
            {getFieldDecorator("addressCountryID", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please select service country!",
                },
              ],
            })(
              <Select
                placeholder="Please select service country"
                showSearch
                optionFilterProp="name"
                onChange={handleChangeServiceCountry}
              >
                {PAYROLL_PROCESSING_COUNTRIES.map((country, key) => (
                  <Option key={key} value={country.id} name={country.value}>
                    {country.value}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="Select BSP">
            {getFieldDecorator("bspId", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Business Service Provider is required",
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Select BSP"
                filterOption={false}
                onSearch={fetchBSP}
                className="w-full "
                id="selectBSP"
                onChange={(value) => handleChangeBSP(value)}
              >
                {mustBeArray(bspList).map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label="Account">
            {getFieldDecorator("bspAccountId", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please select account",
                },
              ],
            })(
              <Select
                placeholder="Please select account"
                showSearch
                onSearch={fetchAccount}
                defaultActiveFirstOption={true}
                onChange={(value) => handleChangeAccount(value)}
              >
                {mustBeArray(accountList).map((account, key) => (
                  <Option value={account.id} key={key}>
                    {account.accountName}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="Payroll Service Provider">
            {getFieldDecorator("payrollserviceprovider", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please select service provider",
                },
              ],
              initialValue: payrollprovider?.name,
            })(
              <Select
                disabled={true}
                notFoundContent={fetchingPayroll ? <Spin size="small" /> : null}
                className="w-full "
              >
                {mustBeArray(providers).map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.value || d.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Payroll Manager">
            {getFieldDecorator("payrollManager", {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <Select
                placeholder="Select payroll manager"
                className="w-full "
                optionFilterProp="name"
                showSearch={true}
              >
                {mustBeArray(pspUsersList).map(
                  (d) =>
                    d.activated &&
                    d.enabled && (
                      <Option
                        key={d.id}
                        value={d.id}
                        name={`${d.firstName} ${d.lastName}`}
                      >
                        {`${d.firstName} ${d.lastName}`}
                      </Option>
                    )
                )}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={DEFAULT_GUTTER}>
        <Col span={8}>
          <FormItem label="Payroll Connection">
            {getFieldDecorator("payrollSystemConnectionId", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please select payroll connection",
                },
              ],
            })(
              <Select
                placeholder="Select payroll connection"
                filterOption={false}
                className="w-full "
                onChange={(e) => handleChangeConnection(e)}
                notFoundContent={fetchingPayrollConnection ? <Spin /> : null}
              >
                {mustBeArray(defaultPayrollConnection).map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="External Business Unit">
            {getFieldDecorator("externalBusinessUnitId", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                },
              ],
            })(
              <Select
                showSearch
                optionFilterProp="label"
                placeholder="Select external business unit"
                className="w-full "
                notFoundContent={fetchingExternalBusinessUnit ? <Spin /> : null}
              >
                {_.sortBy(
                  mustBeArray(defaultExternalBusinessUnitList),
                  "name"
                ).map((d) => (
                  <Option key={d.id} value={d.id} label={d.name}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="Accounting System">
            {getFieldDecorator("accountingSystem", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please enter accounting system",
                },
              ],
              initialValue: 1,
            })(
              <Select
                placeholder="Select"
                filterOption={false}
                className="w-full "
              >
                <Option key={"a"} value={1}>
                  Xero
                </Option>
              </Select>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Contact Name / ReferenceName">
            {getFieldDecorator("referenceName", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "",
                },
              ],
            })(
              <Input
                placeholder="Enter accounting contact name"
                maxLength={256}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="Generate Invoice">
            {getFieldDecorator("isInvoiceNeeded", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please select if invoice is needed",
                },
              ],
              initialValue: 1,
            })(
              <RadioGroup name="invoiceNeeded">
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </RadioGroup>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Payment Mandatory">
            {getFieldDecorator("paymentMandatory", {
              rules: [
                {
                  required: Number(activeIndex) === 2,
                  message: "Please select if payment is mandatory",
                },
              ],
              initialValue: 1,
            })(
              <RadioGroup name="paymentMandatory">
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </RadioGroup>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Invoice Company">
            {getFieldDecorator("isInvoiceCompany", {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <Switch
                className="ml-2.5"
                checked={isInvoiceCompany}
                onChange={(value) => updateIsInvoiceCompany(value)}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      {(!props.form.getFieldValue("addressCountryID") ||
        !props.form.getFieldValue("bspAccountId") ||
        getCountryCurrencyCode() !== getAccountCurrencyCode()) && (
        <Row gutter={16}>
          <Col span={4}>
            <FormItem label="Currency Conversion From">
              {getFieldDecorator("convertFrom", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input disabled />)}
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label="To">
              {getFieldDecorator("convertTo", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input disabled />)}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Fixed Conversion Rate">
              {getFieldDecorator("fixedConversionRate", {
                rules: [
                  {
                    required: false,
                  },
                ],
                initialValue: 0,
              })(
                <InputNumber
                  placeholder="Enter value"
                  className="w-full "
                  onKeyDown={(e) => checkInputValue(e)}
                />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Percent Conversion Rate">
              {getFieldDecorator("percentConversionRate", {
                rules: [
                  {
                    required: false,
                  },
                ],
                initialValue: 0,
              })(
                <InputNumber
                  placeholder="Enter value"
                  className="w-full "
                  onKeyDown={(e) => checkInputValue(e)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

/** @alias */
export default AccountDetailsBusinessUnitForm;
