//@ts-nocheck
import React from "react";
import { getClaimAndStatus } from "../../../libs/utilities";
import { Switch } from "antd";
import { switchType } from "../../../constants/paramsTypes";

export interface SwitchButtonWithTextProps {
  componentName?: string;
  claims?: any;
  className?: string;
  onChange: (e: any) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  hasComponents?: boolean;
  textClassName?: string;
  loading?: boolean;
  switchClassName?: string;
  size?: switchType;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
}

const SwitchButtonWithText: React.FC<SwitchButtonWithTextProps> = ({
  claims,
  componentName,
  checked,
  className,
  loading,
  hasComponents,
  size,
  checkedChildren,
  unCheckedChildren,
  defaultChecked,
  onChange,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName,
    hasComponents,
  });
  return (
    <Switch
      defaultChecked={defaultChecked}
      className={`${className || ""} ${checked ? "bg-success" : ""} ${
        claimAndStatus.status
      }`}
      checked={checked}
      onChange={onChange}
      loading={loading}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      size={size || "default"}
    />
  );
};

export default SwitchButtonWithText;
