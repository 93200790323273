//@ts-nocheck
import React, { Component } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Card, Col, Row, Button } from "antd";
import {
  parseItems,
  currencyFormaterByCode,
  isNullEmptyUndefined,
} from "../../../../libs/utilities";
import {
  displayDateAndTime,
  displayDates,
  formatDateRange,
} from "../../../../libs";
import _ from "lodash";
import { If } from "../../../../Common/ui";
import {
  DEFAULT_PAY_CYCLE_END_DATE,
  US_FORMAT_DATE,
} from "../../../../constants";

type State = any;

class PayrunDetails extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  render() {
    const { payrun, currencyFees } = this.props;

    const payrunDetailsTitle = <div>Pay Run Details</div>;
    return (
      <If
        condition={!isNullEmptyUndefined(payrun)}
        then={
          <React.Fragment>
            <Card className="mb-4">
              <Col md={24}>
                <ul className="list-inline mb-0 flex justify-between">
                  <li>
                    <span className="color-danger text-lg">
                      Pay Day <br />
                      {displayDates({ value: payrun.payDate })}
                    </span>
                  </li>
                  <li>
                    <span className="text-lg">
                      Pay Occurence
                      <br /> {payrun.payOccurrenceName || "Adhoc"}
                    </span>
                  </li>
                  <li>
                    <span className="text-lg">
                      Generate Pay Run
                      <br />{" "}
                      {displayDateAndTime({ value: payrun.generatedDateTime })}
                    </span>
                  </li>
                </ul>
              </Col>
            </Card>
            <Row gutter={20}>
              <Col span={!_.isEmpty(currencyFees) ? 10 : 16}>
                <Card className="mb-4" gutter={16} title={payrunDetailsTitle}>
                  <div className="px-2 mb-1">
                    <b>Business Model:</b> <br />
                    {payrun.payrollModelName}
                  </div>
                  <div className="grid grid-cols-2 px-2 gap-6 mb-1">
                    <div className="col-span-1">
                      <b>Amendments Due:</b> <br />
                      {displayDateAndTime({
                        value: payrun.ammendmentDueDateTime,
                      })}
                    </div>
                    <div className="col-span-1">
                      <b>Pay Run Preparation Due:</b> <br />
                      {displayDateAndTime({
                        value: payrun.preparationDueDateTime,
                      })}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 px-2 gap-6 mb-1">
                    <div className="col-span-1">
                      <b>Approval Due:</b> <br />
                      {displayDateAndTime({
                        value: payrun.approvalDueDateTime,
                      })}
                    </div>
                    <div className="col-span-1">
                      <b>Payment Due:</b> <br />
                      {displayDateAndTime({
                        value: payrun.paymentDueDateTime,
                      })}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 px-2 gap-6 mb-1">
                    <div className="col-span-1">
                      <b>Pay Cycle Start Date:</b> <br />
                      {displayDates({
                        value: payrun.payCycleStartDate,
                        format: US_FORMAT_DATE,
                      })}
                    </div>
                    <If
                      condition={
                        payrun.payCycleEndDate !== DEFAULT_PAY_CYCLE_END_DATE
                      }
                      then={
                        <div className="col-span-1">
                          <b>Pay Cycle End Date:</b> <br />
                          {displayDates({
                            value: payrun.payCycleEndDate,
                            format: US_FORMAT_DATE,
                          })}
                        </div>
                      }
                    />
                  </div>
                </Card>
              </Col>
              <Col span={!_.isEmpty(currencyFees) ? 6 : 8}>
                <Card
                  title={"Pay Run Processing"}
                  className="overflow-y-auto h-262"
                >
                  <ul className="list-unstyled mb-0">
                    <li>
                      <b>Connected Instance:</b>{" "}
                      {parseItems(payrun.description, {})?.Name}
                    </li>
                    <li>
                      <b>Business Unit:</b>{" "}
                      {parseItems(payrun.description)?.ExternalBusinessUnitName}
                    </li>
                    <li>
                      <b>Pay Schedule:</b>{" "}
                      {parseItems(payrun.description)?.ExternalPayScheduleName}
                    </li>
                    {payrun?.externalPayRunName && (
                      <li>
                        <b>Pay Run Name:</b>{" "}
                        {formatDateRange({ value: payrun.externalPayRunName })}
                      </li>
                    )}
                    {payrun?.externalPayRunId && (
                      <li>
                        <b>Pay Run ID:</b> {payrun.externalPayRunId}
                      </li>
                    )}
                  </ul>
                </Card>
              </Col>
              {!_.isEmpty(currencyFees) && currencyFees && (
                <Col md={8}>
                  <Card
                    className="mb-4 h-262 overflow-y-auto"
                    title={
                      <div className="hand">
                        Currency Conversion Fees{" "}
                        <Button
                          className="ml-2.5"
                          type="primary"
                          size={"small"}
                          icon={<EditOutlined />}
                          disabled={true}
                        >
                          Edit
                        </Button>
                      </div>
                    }
                  >
                    <h3>
                      {`From ${currencyFees.convertFromCurrency} to ${currencyFees.convertToCurrency}`}
                    </h3>
                    <Row gutter={16} className="mb-4">
                      <Col sm={24} md={12}>
                        <b>Fixed Conversion Rate</b>
                        <div>
                          {currencyFormaterByCode(
                            currencyFees.fixedConversionRate,
                            currencyFees.convertFromCurrency
                          )}
                        </div>
                      </Col>
                      <Col sm={24} md={12}>
                        <b>Percent Conversion Rate</b>
                        <div>{`${currencyFees.percentConversionRate}%`}</div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
          </React.Fragment>
        }
      />
    );
  }
}

export default PayrunDetails;
