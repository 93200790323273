export type Step = {
  step: PayrunSteps;
  date: Date | null;
  active: boolean;
  completed: boolean;
};

export type SingleStepProps = Step & {
  index: number;
};

export type StepIconProps = Omit<SingleStepProps, 'step' | 'date'>;

export enum PayrunSteps {
  Submit = 'Submit',
  Prepare = 'Prepare',
  Review = 'Review',
  Pending = 'Pending',
  Payment = 'Payment',
  Process = 'Process',
  Complete = 'Complete',
}

export enum PayrunStepperVariant {
  Stacked = 'stacked',
  Inline = 'inline',
}
export interface PayrunStepperProps {
  variant?: PayrunStepperVariant;
  className?: string;
}

export const StatusToStepMap = {
  [PayrunSteps.Submit]: [1, 2, 3],
  [PayrunSteps.Prepare]: [4, 5, 6, 7, 19, 20, 21, 22],
  [PayrunSteps.Review]: [8, 9],
  [PayrunSteps.Pending]: [10, 11, 12, 13],
  [PayrunSteps.Payment]: [14],
  [PayrunSteps.Process]: [15, 16],
  [PayrunSteps.Complete]: [17],
};
