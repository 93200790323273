import { useContext } from 'react';

import { AuthContext, AuthContextProps } from '../auth-provider.context';
import { MISSING_PROVIDER } from '../constants/error.constant';

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context) {
    return context;
  }

  throw new Error(MISSING_PROVIDER);
};
