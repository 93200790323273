//@ts-nocheck
import React from "react";
import { DatePicker } from "antd";
import { getClaimAndStatus } from "../../../libs/utilities";
import classNames from "classnames";
const { RangePicker } = DatePicker;

export interface DateRangepickerFilterProps {
  name?: string;
  claims?: any;
  className?: string;
  placeholder?: string;
  onChange: (e: any) => void;
  value?: any;
  ignoreClaims?: boolean;
}

const DateRangepickerFilter: React.FC<DateRangepickerFilterProps> = ({
  name,
  claims,
  value,
  onChange,
  className,
  ignoreClaims = false,
}) => {
  const claimAndStatus = getClaimAndStatus({
    claims,
    componentName: name,
    hasComponents: true,
  });
  return (
    <RangePicker
      onChange={(e) => onChange(e)}
      value={value}
      placeholder={["Start Date", "End Date"]}
      format="DD/MM/YYYY"
      className={classNames("rounded-[20px] w-[220px]", className, [
        { [claimAndStatus.status]: !ignoreClaims },
      ])}
    />
  );
};

export default DateRangepickerFilter;
