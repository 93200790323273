import { SVGProps } from 'react';
const DawnAttachment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.127 3.846a3.75 3.75 0 1 1 6.495 3.75l-7.25 12.558a3.75 3.75 0 1 1-6.495-3.75l4-6.929a2.25 2.25 0 0 1 3.897 2.25l-3.25 5.63a.75.75 0 0 1-1.299-.75l3.25-5.63a.75.75 0 0 0-1.299-.75l-4 6.929a2.25 2.25 0 0 0 3.897 2.25l7.25-12.558a2.25 2.25 0 0 0-3.897-2.25l-1.5 2.598a.75.75 0 1 1-1.299-.75l1.5-2.598Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnAttachment;
