import requireAuth from "../../Common/requireAuth";
import {
  SCOPES,
  USER_COMPANY_GENERAL_ADD_BUTTON,
  USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON,
} from "../authorizationClaims";
import UsersContainer from "../../Organization/containers/Users";
import UserIndex from "../../Organization/pages/User/user";
import CompanyUserCreate from "../../Organization/pages/User/companyUserCreate";
import PspUserCreate from "../../Organization/pages/User/pspUserCreate";
import UserDetailContainer from "../../Organization/containers/UserDetails";
import UserDetail from "../../Organization/pages/User/Detail";
import AssignBusinessUnitIndex from "../../Organization/pages/User/AssignBusinessUnit";
import MemberContainer from "../../User/containers/Member";
import UserProfile from "../../User/pages/Profile";
import requireSecureComponent from "../../Common/requireSecureComponent";

export const UserRoutesWithSidebar = [
  {
    path: "users",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/user",
    element: (
      <UsersContainer scope={SCOPES.USER} Layout={requireAuth(UserIndex)} />
    ),
  },
  {
    path: "user/psp/:id",
    scope: SCOPES.USER,
    index: "users",
    container: UserDetailContainer,
    componentPath: "../../Organization/pages/User/Detail",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(UserDetail)}
        userType="psp"
      />
    ),
  },
  {
    path: "user/company/:id",
    scope: SCOPES.USER,
    index: "users",
    container: UserDetailContainer,
    componentPath: "../../Organization/pages/User/Detail",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(UserDetail)}
        userType="company"
      />
    ),
  },
  {
    path: "psp/:pspId/user/:id/assignbusinessunits",
    scope: SCOPES.USER,
    index: "users",
    container: UserDetailContainer,
    componentPath: "../../Organization/pages/User/AssignBusinessUnit",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(AssignBusinessUnitIndex)}
        userType="psp"
      />
    ),
  },
  {
    path: "profile",
    scope: SCOPES.USER,
    index: "users",
    container: MemberContainer,
    componentPath: "../../User/pages/Profile",
    element: <MemberContainer Layout={requireAuth(UserProfile)} />,
  },
];

export const UserRoutesWithoutSidebar = [
  {
    path: "users/company/create",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/companyUserCreate",
    element: (
      <UsersContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            CompanyUserCreate,
            USER_COMPANY_GENERAL_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "users/psp/create",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/pspUserCreate",
    element: (
      <UsersContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            PspUserCreate,
            USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "user/company/:id/edit",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/companyUserCreate",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            CompanyUserCreate,
            USER_COMPANY_GENERAL_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "company/:companyId/user/:id/edit",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/companyUserCreate",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            CompanyUserCreate,
            USER_COMPANY_GENERAL_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "company/:companyId/user/create",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/companyUserCreate",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            CompanyUserCreate,
            USER_COMPANY_GENERAL_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "user/psp/:id/edit",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/pspUserCreate",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            PspUserCreate,
            USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "payrollprovider/:payrollProviderId/user/:id/edit",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/pspUserCreate",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            PspUserCreate,
            USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON
          )
        )}
      />
    ),
  },
  {
    path: "payrollprovider/:payrollProviderId/user/create",
    scope: SCOPES.USER,
    index: "users",
    container: UsersContainer,
    componentPath: "../../Organization/pages/User/pspUserCreate",
    element: (
      <UserDetailContainer
        scope={SCOPES.USER}
        Layout={requireAuth(
          requireSecureComponent(
            PspUserCreate,
            USER_PAYROLL_SERVICE_PROVIDER_ADD_BUTTON
          )
        )}
      />
    ),
  },
];
