// @ts-nocheck
import { mustBeArray, getUserArea } from "../libs/utilities";
class PayRunStatus {
  _data: any;
  status: any;
  constructor() {
    if (!PayRunStatus.instance) {
      this._data = [
        { id: 1, value: "Generated" },
        { id: 2, value: "DetailsViewed" },
        { id: 3, value: "AmmendmentsAdded" },
        { id: 4, value: "AmmendmentsSubmittedSystemTriggered" },
        { id: 5, value: "AmmendmentsSubmittedUserTriggered" },
        { id: 6, value: "ConfirmedByPayrollAdminSystemTriggered" },
        { id: 7, value: "ConfirmedByPayrollAdminUserTriggered" },
        { id: 8, value: "PreparedSystemTriggered" },
        { id: 9, value: "PreparedUserTriggered" },
        { id: 10, value: "ApprovedSystemTriggered" },
        { id: 11, value: "ApprovedUserTriggered" },
        { id: 12, value: "InvoiceGeneratedSystemTriggered" },
        { id: 13, value: "InvoiceGeneratedUserTriggered" },
        { id: 14, value: "PaymentPendingApproval" },
        { id: 15, value: "PaymentConfirmed" },
        { id: 16, value: "Processing" },
        { id: 17, value: "Processed" },
        { id: 18, value: "Completed" },
        { id: 19, value: "ImportingEarningLinesSystemTriggered" },
        { id: 20, value: "ImportingEarningLinesUserTriggered" },
        { id: 21, value: "ImportedEarningLinesSystemTriggered" },
        { id: 22, value: "ImportedEarningLinesUserTriggered" },
        { id: 23, value: "DraftSystemTriggered" },
        { id: 24, value: "DraftUserTriggered" },
      ];
      this.status = {
        "api/sa": {
          draft: [24, 23],
          amendments: [1, 2, 3],
          prepare: [4, 5, 6, 7, 19, 20, 21, 22],
          approval: [8, 9],
          payment: [10, 11],
          process: [12, 13, 14, 15, 16, 17],
          completed: [18],
          invoiceUnpaid: [12, 13],
          invoicePaid: [14, 15],
        },
        "api/pspa": {
          draft: [24, 23],
          amendments: [1, 2, 3],
          prepare: [4, 5, 6, 7, 19, 20, 21, 22],
          approval: [8, 9],
          payment: [10, 11],
          process: [12, 13, 14, 15, 16, 17],
          completed: [18],
          invoiceUnpaid: [12, 13],
          invoicePaid: [14, 15],
        },
        "api/cu": {
          draft: [24, 23],
          amendments: [1, 2, 3],
          prepare: [4, 5, 6, 7, 19, 20, 21, 22],
          approval: [8, 9],
          payment: [],
          process: [12, 13, 14, 15, 16, 17],
          completed: [18],
          invoiceUnpaid: [10, 11, 12, 13],
          invoicePaid: [14, 15],
        },
      };
      PayRunStatus.instance = this;
    }
    return PayRunStatus.instance;
  }

  getStatusID({ status }: any) {
    const userMode = getUserArea();
    if (userMode) {
      return mustBeArray(this.status[userMode][status]).reduce(
        (a: any, b: any) => a + Math.pow(2, b - 1),
        0
      );
    }
    return 0;
  }

  getPaidStatusID() {
    return [15, 16, 17, 18].reduce((a, b) => a + Math.pow(2, b - 1), 0);
  }

  getStatus({ id }: any) {
    const userMode = getUserArea();
    if (this.status[userMode].draft.includes(id)) return "draft";
    if (this.status[userMode].amendments.includes(id)) return "amendments";
    if (this.status[userMode].prepare.includes(id)) return "prepare";
    if (this.status[userMode].approval.includes(id)) return "approval";
    if (this.status[userMode].payment.includes(id)) return "payment";
    if (this.status[userMode].invoiceUnpaid.includes(id))
      return "invoiceUnpaid";
    if (this.status[userMode].invoicePaid.includes(id)) return "invoicePaid";
    if (this.status[userMode].process.includes(id)) return "process";
    if (this.status[userMode].completed.includes(id)) return "completed";
  }
}

const instance = new PayRunStatus();
Object.freeze(instance);

export default instance;
