import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextDropdownMultiV2 } from '@xemplo/text-dropdown-multi';

import * as S from '../../modal.styles';

export const TaxCalculationMethod = () => {
  const { register, watch, unregister } = useFormContext();
  const watchLumpSum = watch('lumpSum');
  const watchTaxCalculationMethod = watch('taxCalculationMethod');

  const showTaxCalculationMethod = watchLumpSum === 'Yes';
  useEffect(() => {
    if (showTaxCalculationMethod) {
      register('taxCalculationMethod', {
        required: 'This field is required',
      });
    } else {
      unregister('taxCalculationMethod');
    }
  }, [register, showTaxCalculationMethod, unregister]);
  if (showTaxCalculationMethod) {
    return (
      <S.FormLabel>
        Tax calculation method
        <TextDropdownMultiV2
          name="taxCalculationMethod"
          items={taxCalcOptions}
          testId="taxCalculationMethod"
          aria-label="Tax calculation method field"
          defaultSelectedKeys={watchTaxCalculationMethod && [watchTaxCalculationMethod]}
          selectProps={{
            placeholder: 'Select method',
          }}
          isDropdownMulti={false}
        />
      </S.FormLabel>
    );
  }
};
export const taxCalcOptions = [
  { key: 'A', value: 'A' },
  { key: 'B(ii)', value: 'B(ii)' },
  { key: 'NotApplicable', value: 'NotApplicable' },
];
