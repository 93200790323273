import styled from 'styled-components';

import {
  containerBase,
  containerDisabled,
  containerError,
  containerFocus,
  containerHasLabelPadding,
  containerReadonly,
  descriptionBase,
  errorMessageBase,
  hiddenLabelBase,
  inputBase,
  inputDisabled,
  InputFieldSize,
  inputReadonly,
  inputWithLabel,
  labelInputHasFocus,
  labelInputHasValue,
  labelVisible,
  wrapperBase,
} from '@xemplo/input-utils';

export const Wrapper = styled.div<{ $width?: string }>`
  ${wrapperBase};
`;

export const Container = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerFocus};
  ${containerDisabled};
  ${containerError};
  ${containerHasLabelPadding};
  ${containerReadonly};
`;

export const Input = styled.input`
  ${inputBase};
  ${inputDisabled};
  ${inputReadonly};
  ${inputWithLabel};
  ${labelInputHasFocus};
  /* Remove arrows from number input */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Label = styled.label`
  ${hiddenLabelBase};
  ${labelVisible};
  ${labelInputHasValue};
`;

export const ErrorMessage = styled.div`
  ${errorMessageBase};
`;

export const Description = styled.div`
  ${descriptionBase};
`;
