import { Col, Row } from "antd";
import React from "react";
import { If } from "../../../../Common/ui";
import { currencyFormater, isEmpty } from "../../../../libs";

type Props = {
  showVariance: boolean;
  invoice: any;
  totalPreviousAmount?: Number;
  totalAmount?: Number;
  totalPreviousTaxes?: Number;
  totalTaxes?: Number;
};
const EarningLinesTotal = ({
  showVariance,
  invoice,
  totalPreviousAmount,
  totalAmount,
  totalPreviousTaxes,
  totalTaxes,
}: Props) => {
  return (
    <React.Fragment>
      <If
        condition={showVariance}
        then={
          <If
            condition={isEmpty(invoice)}
            then={
              <div key="varianceWithoutInvoice" className="process-table">
                <div className="pl-4 process-table-variance-no-invoice-description">
                  <b>Subtotal</b>
                </div>
                <div className="text-right process-table-variance-no-invoice-previousAmount">
                  <b>{currencyFormater(totalPreviousAmount)}</b>
                </div>
                <div className="text-right process-table-variance-no-invoice-amount pr-[7px]">
                  <b>{currencyFormater(totalAmount)}</b>
                </div>
                <div className="text-right pr-4 process-table-variance-no-invoice-variance">
                  <b>N/A</b>
                </div>
              </div>
            }
            else={
              <div key="varianceWithInvoice" className="process-table">
                <div className="pl-4 process-table-variance-invoice-description">
                  <b>Subtotal</b>
                </div>
                <div className="text-right process-table-variance-invoice-previousTaxes pr-1">
                  <b>{currencyFormater(totalPreviousTaxes)}</b>
                </div>
                <div className="text-right process-table-variance-invoice-previousAmount pr-3">
                  <b>{currencyFormater(totalPreviousAmount)}</b>
                </div>
                <div className="text-right process-table-variance-invoice-taxes pr-2">
                  <b>{currencyFormater(totalTaxes)}</b>
                </div>
                <div className="text-right process-table-variance-invoice-amount">
                  <b>{currencyFormater(totalAmount)}</b>
                </div>
                <div className="text-right pr-6 process-table-variance-invoice-variance">
                  <b>N/A</b>
                </div>
              </div>
            }
          />
        }
        else={
          <If
            condition={isEmpty(invoice)}
            then={
              <Row key="noVarianceWithoutInvoice">
                <Col span={12} className="pl-4">
                  <b>Subtotal</b>
                </Col>
                <Col span={12} className="text-right pr-4">
                  <b>{currencyFormater(totalAmount)}</b>
                </Col>
              </Row>
            }
            else={
              <div key="noVarianceWithInvoice" className="process-table">
                <div className="pl-8 process-table-no-variance-invoice-description">
                  <b>Subtotal</b>
                </div>
                <div className="text-right process-table-no-variance-invoice-taxes">
                  <b>{currencyFormater(totalTaxes)}</b>
                </div>
                <div className="text-right pr-4 process-table-no-variance-invoice-amount">
                  <b>{currencyFormater(totalAmount)}</b>
                </div>
              </div>
            }
          />
        }
      />
    </React.Fragment>
  );
};
export default EarningLinesTotal;
