import { ListSelectField } from "../../ui";
import { DURATION_TYPE_OPTIONS } from "../../../constants";

type Props = { required: boolean; onChange: (e: any) => void };

const DurationType = ({ required, onChange }: Props) => (
  <ListSelectField
    label="Duration Type"
    name="durationType"
    className="mb-4  xl:row-start-2 xl:col-start-2"
    required={required}
    ruleMsg="Please select duration type"
    onChange={onChange}
    list={DURATION_TYPE_OPTIONS}
  />
);

export default DurationType;
