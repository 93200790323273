// @ts-nocheck
import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button, Tooltip } from "antd";
import { getFileExtension, isEmpty } from "../../../libs/utilities";
import classNames from "classnames";
import { mustBeArray, userCanDownloadPayrunPdf } from "../../../libs";
import { FileTypeIcon, If } from "../../../Common/ui";
import { useAnalyticsEventTracker } from "../../../hooks";

const commonIconClasses = "text-xs ml-2";

const PayrunDownloadOptions = ({
  classname,
  disabled,
  payrunReports,
  handleReportDownload,
  claims,
  title,
}: any) => {
  const [exportABALoading, setExportABALoading] = React.useState(false);

  const gaEventTracker = useAnalyticsEventTracker("Pay Run Download");

  const onClickDownloadOption = (option: any) => {
    let extensionType = getFileExtension(option.originalFileName);
    const fileName = `${option.displayName}.${extensionType}`;
    handleReportDownload({ id: option.id, fileName: fileName });
    gaEventTracker(`Report Downloaded`, `Report downloaded`);
  };

  const menu = (
    <Menu className="user-authentication">
      {/** Loop through payrunReports (all payrun files) and render menu option for each one */}
      {mustBeArray(payrunReports).map((option: any, i: any) => {
        return (
          <Menu.Item key={i} onClick={() => onClickDownloadOption(option)}>
            <DownloadOutlined /> {option.displayName}
            <FileTypeIcon
              className={commonIconClasses}
              type={getFileExtension(option.originalFileName)}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <If
      condition={userCanDownloadPayrunPdf({ claims })}
      then={
        <Tooltip title={title ?? ""}>
          <Dropdown
            className={`btn-dropdown ${classNames(
              classname,
              {
                "Visible-Disabled":
                  disabled && isEmpty(mustBeArray(payrunReports)),
              },
              {
                "btn-dropdown-success":
                  !disabled || !isEmpty(mustBeArray(payrunReports)),
              }
            )}`}
            trigger={["click"]}
            overlay={menu}
          >
            <Button
              disabled={disabled && isEmpty(mustBeArray(payrunReports))}
              className={`rounded-lg ${classNames(
                {
                  "Visible-Disabled bg-disabled":
                    disabled && isEmpty(mustBeArray(payrunReports)),
                },
                {
                  "bg-success":
                    !disabled || !isEmpty(mustBeArray(payrunReports)),
                }
              )}`}
            >
              Download
            </Button>
          </Dropdown>
        </Tooltip>
      }
      else={<React.Fragment />}
    />
  );
};

export default PayrunDownloadOptions;
