import { ButtonProps, ButtonWithStateProps } from '@xemplo/button';
import { IconButtonProps } from '@xemplo/icon-button';

export interface DrawerProps {
  isOpen?: boolean;
  mode?: DrawerMode;
  position?: DrawerPosition;
  className?: string;

  /** Offset drawer from other sticky elements */
  customTop?: number;
}

export type DrawerHeaderProps = {
  title?: string;
  hasBackButton?: boolean;
  backButtonClick?: IconButtonProps['onClick'];
  primaryBtn?: Partial<ButtonWithStateProps> & { label: string };
  secondaryBtn?: Partial<ButtonProps> & { label: string };
};

export enum DrawerMode {
  Standard = 'standard',
  Editor = 'editor',
}

export enum DrawerPosition {
  Left = 'left',
  Right = 'right',
}

//------- State types -------//
export type DrawerState = {
  isOpen?: boolean;
  contentVisible?: boolean;
  config: DrawerConfig;
  content: DrawerContent;
};

export type DrawerConfig = {
  mode?: DrawerMode;
  position?: DrawerPosition;
  width?: number;
  headerKeyline?: boolean;
  onClose?: IconButtonProps['onClick'];
};

export type DrawerContent = {
  header?: React.ReactNode | DrawerHeaderProps;
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

export enum DrawerActions {
  SetDrawerConfig = 'SET_DRAWER_STATE',
  SetDrawerContent = 'SET_DRAWER_CONTENT',
  SetContentVisible = 'SET_CONTENT_VISIBLE',
  SetIsOpen = 'SET_IS_OPEN',
  SetPosition = 'SET_POSITION',
  SetMode = 'SET_MODE',
  SetState = 'SET_STATE',
}

export type DrawerAction =
  | { type: DrawerActions.SetDrawerConfig; payload: Partial<DrawerConfig> }
  | { type: DrawerActions.SetDrawerContent; payload: Partial<DrawerContent> }
  | { type: DrawerActions.SetContentVisible; payload?: boolean }
  | { type: DrawerActions.SetIsOpen; payload?: boolean }
  | { type: DrawerActions.SetPosition; payload?: DrawerPosition }
  | { type: DrawerActions.SetMode; payload?: DrawerMode }
  | { type: DrawerActions.SetState; payload: Partial<DrawerState> };
