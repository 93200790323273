import styled from 'styled-components';

import { Amendment } from '@xemplo/gp-types';
import {
  BodySmallRegular,
  BodyXSmallMedium,
  Colour,
  HeadingStandardMedium,
  Shadow,
  TextEllipsis,
} from '@xemplo/style-constants';
import { XemploTable } from '@xemplo/table';

export const HeaderContainer = styled.div`
  padding: 44px 40px 0;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const HeaderTitle = styled.h1`
  ${HeadingStandardMedium};
  color: ${Colour.Gray[800]};
`;

export const AppliesTo = styled.span`
  ${TextEllipsis};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: ${Colour.White[100]};
  border-radius: 50%;
  border: 1px solid ${Colour.Gray[50]};
  border-radius: 12px;
  ${Shadow.Medium2};
`;
export const ModalBodyContainer = styled.div`
  height: calc(100vh - 300px);
`;
export const StyledTable = styled(XemploTable<Amendment>)`
  border-radius: 8px;
  border: 1px solid ${Colour.Gray[200]};
  ${BodySmallRegular};
  color: ${Colour.Gray[600]};
  width: 100%;
  .action-bar-container {
    display: none;
  }
  table {
    thead {
      border: none;
      color: ${Colour.Gray[700]};
      ${BodyXSmallMedium};
    }
    tbody {
      tr {
        &:hover {
          box-shadow: none;
        }
        box-sizing: border-box;
        border: 1px solid ${Colour.Black[5]};
      }
    }
  }
`;
