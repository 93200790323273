//@ts-nocheck
import React, { Component } from "react";
import {
  mustBeArray,
  disableBrowserAutocomplete,
  getConditionalResponse,
  getTimeZoneFilterName,
  reOrderTimeZoneList,
} from "../../../libs/utilities";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Switch, Row, Col, Select, Button, Upload, Radio } from "antd";
import LocationSearchInput from "../../../Common/LocationSearchInput";
import _ from "lodash";
import { PAYROLL_PROCESSING_COUNTRIES } from "../../../constants/payroll";
import { isCountryAustralia } from "../../../libs/validations";
import { onPasteValidateNumber, abnValidator } from "../../../libs";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { RequiredSign } from "../../../Common/ui";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

class BasicBillingServiceProviderForm extends Component {
  getImageUrlFromBlob = (logo) => {
    if (!_.isEmpty(logo)) {
      let blob = new File([logo], logo.name);
      return URL.createObjectURL(blob);
    }
    return "";
  };
  render() {
    const {
      form,
      disableField,
      activeIndex,
      uploadLogo,
      logo,
      removeLogo,
      uploadLoading,
      populateStates,
      onChangeCountry,
      timezone,
      onSameAddressChange,
      isSameAddress,
      onAddressChange,
      onAddressSelect,
      populateSameAddressField,
      states,
      countries,
      mapLoaded,
      editMode,
      registeredAddress1,
      postalAddress1,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Country">
              {getFieldDecorator("country", {
                rules: [
                  { required: true, message: "Please select your country." },
                ],
              })(
                <Select
                  autoComplete="none"
                  placeholder="Please select a country"
                  showSearch
                  optionFilterProp="name"
                  onFocus={() => disableBrowserAutocomplete()}
                  onSelect={(val) => populateStates(val, false)}
                  disabled={disableField}
                  onChange={(value) => onChangeCountry(value)}
                >
                  {mustBeArray(countries).map((country, key) => (
                    <Option key={key} value={country.id} name={country.value}>
                      {country.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={getConditionalResponse({
                condition: isCountryAustralia({
                  country: Number(this.props.form.getFieldValue("country")),
                }),
                resp1: "ABN",
                resp2: "Registration Number",
              })}
            >
              {getFieldDecorator("abn", {
                rules: [
                  {
                    required: isCountryAustralia({
                      country: Number(this.props.form.getFieldValue("country")),
                    }),
                    message: "Please input ABN",
                  },
                ],
              })(
                <Input
                  onKeyDown={(e) => abnValidator(e, 10)}
                  placeholder={`Please input ${getConditionalResponse({
                    condition: isCountryAustralia({
                      country: Number(this.props.form.getFieldValue("country")),
                    }),
                    resp1: "ABN",
                    resp2: "registration",
                  })} number`}
                  disabled={disableField}
                  className="w-full  hideNumberPointers"
                  onPaste={(e) => onPasteValidateNumber(e)}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Registered Business Name">
              {getFieldDecorator("registeredName", {
                rules: [
                  {
                    required: true,
                    message: "Registered Business Name",
                  },
                ],
              })(
                <Input
                  placeholder="Please input registered business name"
                  maxLength={60}
                  disabled={disableField}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please input name",
                  },
                ],
              })(
                <Input
                  placeholder={`Please input billing service provider name`}
                  maxLength={40}
                  disabled={disableField}
                  autoComplete="none"
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem label="Operating Timezone">
              {getFieldDecorator("operatingTimezone", {
                rules: [
                  {
                    required: true,
                    message: "Please select your timezone!",
                  },
                ],
              })(
                <Select
                  placeholder="Please select a timezone"
                  showSearch
                  defaultActiveFirstOption={true}
                  disabled={disableField}
                  optionFilterProp="name"
                >
                  {reOrderTimeZoneList({
                    timezone,
                    countries,
                    selectedCountry: form.getFieldValue("country"),
                  }).map((time, key) => {
                    return (
                      time.enabled && (
                        <Option
                          value={time.id}
                          name={getTimeZoneFilterName(time)}
                          key={key}
                        >
                          {time.value}
                        </Option>
                      )
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row
          justify="end"
          type="flex"
          className="items-start mt-2 -mb-6"
          gutter={16}
        >
          <Col span={12} className="pt-2">
            <strong>Physical Address</strong>
          </Col>
          <Col span={6} className="pt-2">
            <strong>Postal Address</strong>
          </Col>
          <Col span={6}>
            <FormItem
              labelCol={{ span: 19 }}
              wrapperCol={{ span: 5 }}
              label="Same as Physical Address"
            >
              {getFieldDecorator("isSameAddress", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Switch
                  onChange={(val) => onSameAddressChange(val)}
                  checked={isSameAddress}
                  disabled={disableField}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label={
                <span>
                  <RequiredSign />
                  &nbsp;Address 1
                </span>
              }
            >
              {getFieldDecorator("registeredAddress1", {
                initialValue: {
                  placeholder: "Address 1",
                  className: "registered-address-input",
                },
              })(
                <LocationSearchInput
                  disabled={getConditionalResponse({
                    condition: editMode,
                    resp1: false,
                    resp2: disableField,
                  })}
                  onChange={(res) => onAddressSelect(res, "registered")}
                  mapLoaded={mapLoaded}
                  onAddressChange={(value) =>
                    onAddressChange(value, "registered")
                  }
                  address={registeredAddress1}
                  editMode={editMode}
                  type="registered"
                />
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              label={
                <span>
                  <RequiredSign />
                  &nbsp;Address 1
                </span>
              }
            >
              {getFieldDecorator("postalAddress1", {
                initialValue: {
                  placeholder: "Address 1",
                  className: "postal-address-input",
                },
              })(
                <LocationSearchInput
                  disabled={getConditionalResponse({
                    condition: isSameAddress,
                    resp1: isSameAddress,
                    resp2: disableField,
                  })}
                  onChange={(res) => onAddressSelect(res, "postal")}
                  mapLoaded={mapLoaded}
                  onAddressChange={(value) => onAddressChange(value, "postal")}
                  address={postalAddress1}
                  editMode={editMode}
                  type="postal"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Address Line 2">
              {getFieldDecorator("registeredAddress2", {
                rules: [
                  {
                    required: false,
                    message: "Address line 2",
                  },
                ],
                defaultValue: " ",
              })(
                <Input
                  placeholder="Physical Address line 2"
                  maxLength={40}
                  disabled={disableField}
                  onChange={(e) =>
                    populateSameAddressField(
                      e.currentTarget.value,
                      "postalAddress2"
                    )
                  }
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Address Line 2">
              {getFieldDecorator("postalAddress2", {
                rules: [
                  {
                    required: false,
                    message: "Address line 2",
                  },
                ],
                defaultValue: " ",
              })(
                <Input
                  disabled={getConditionalResponse({
                    condition: isSameAddress,
                    resp1: isSameAddress,
                    resp2: disableField,
                  })}
                  placeholder="Postal Address line 2"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="City / Town">
                  {getFieldDecorator("registeredSuburb", {
                    rules: [
                      {
                        required: true,
                        message: "Please input City / Town",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Please input suburbs name"
                      maxLength={30}
                      disabled={disableField}
                      onChange={(e) =>
                        populateSameAddressField(
                          e.currentTarget.value,
                          "postalSuburb"
                        )
                      }
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Postcode">
                  {getFieldDecorator("registeredPostCode", {
                    rules: [
                      {
                        required: true,
                        message: "Please input postcode",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Please input postcode"
                      className="w-full "
                      disabled={disableField}
                      onChange={(e) =>
                        populateSameAddressField(
                          e.currentTarget.value,
                          "postalPostCode"
                        )
                      }
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="City / Town">
                  {getFieldDecorator("postalSuburb", {
                    rules: [
                      {
                        required: true,
                        message: "Please input City / Town",
                      },
                    ],
                  })(
                    <Input
                      disabled={getConditionalResponse({
                        condition: isSameAddress,
                        resp1: isSameAddress,
                        resp2: disableField,
                      })}
                      placeholder="Please input City / Town"
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Postcode">
                  {getFieldDecorator("postalPostCode", {
                    rules: [
                      {
                        required: true,
                        message: "Please input postcode",
                      },
                    ],
                  })(
                    <Input
                      disabled={getConditionalResponse({
                        condition: isSameAddress,
                        resp1: isSameAddress,
                        resp2: disableField,
                      })}
                      placeholder="Please input postcode"
                      className="w-full "
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={DEFAULT_GUTTER}>
          <Col span={12}>
            <FormItem label="Province / State">
              {getFieldDecorator("registeredState", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        Number(activeIndex) === 1 &&
                        !_.isEmpty(mustBeArray(states)),
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select your Province / State!",
                  },
                ],
              })(
                <Select
                  autoComplete="none"
                  showSearch={true}
                  optionFilterProp="name"
                  placeholder="Please select a Province / State"
                  disabled={disableField}
                  onChange={(e) => populateSameAddressField(e, "postalState")}
                >
                  {mustBeArray(states).map((state, index) => (
                    <Option key={index} value={state.id} name={state.value}>
                      {state.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Province / State">
              {getFieldDecorator("postalState", {
                rules: [
                  {
                    required: getConditionalResponse({
                      condition:
                        Number(activeIndex) === 1 &&
                        !_.isEmpty(mustBeArray(states)),
                      resp1: true,
                      resp2: false,
                    }),
                    message: "Please select your Province / State!",
                  },
                ],
              })(
                <Select
                  autoComplete="none"
                  disabled={getConditionalResponse({
                    condition: isSameAddress,
                    resp1: isSameAddress,
                    resp2: disableField,
                  })}
                  placeholder="Please select a Province / State"
                  showSearch={true}
                  optionFilterProp="name"
                >
                  {mustBeArray(states).map((state, index) => (
                    <Option key={index} value={state.id} name={state.value}>
                      {state.value}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8} sm={24}>
            <Row>
              <FormItem label="Service Country">
                {getFieldDecorator("serviceCountryId", {
                  rules: [
                    {
                      required: true,
                      message: "Please select service country!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Please select service country"
                    showSearch
                    optionFilterProp="name"
                    autoComplete="none"
                  >
                    {PAYROLL_PROCESSING_COUNTRIES.map((country, key) => (
                      <Option key={key} value={country.id} name={country.value}>
                        {country.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Row>
            <Row>
              <FormItem label="Proceed on Account Conflict">
                {getFieldDecorator("proceedOnChartofAccountConflict", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                  initialValue: 1,
                })(
                  <RadioGroup name="proceedOnChartofAccountConflict">
                    <Radio value={1}>Yes</Radio>
                    <Radio value={2}>No</Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </Row>
          </Col>
          <Col md={{ span: 12, offset: 4 }} sm={24}>
            <FormItem
              label={
                <span>
                  <span className="color-danger mr-1">*</span>Logo
                </span>
              }
            >
              {getFieldDecorator("logo", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <React.Fragment>
                  <Upload
                    beforeUpload={(file) => uploadLogo(file)}
                    showUploadList={false}
                    className="mr-4 mb-3"
                  >
                    <Button
                      type="primary"
                      loading={uploadLoading}
                      className="rounded-[20px]"
                    >
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                  {!_.isEmpty(logo) ? (
                    logo.url ? (
                      <React.Fragment>
                        <img
                          src={logo.url}
                          alt="logo"
                          className="logo-uploaded"
                        />
                        <LegacyIcon
                          className="color-primary hand ml-9"
                          type={"close"}
                          onClick={removeLogo}
                        />
                      </React.Fragment>
                    ) : (
                      <div>
                        <LegacyIcon
                          className="color-primary hand ml-9"
                          type={"loading"}
                        />
                      </div>
                    )
                  ) : (
                    <React.Fragment />
                  )}
                </React.Fragment>
              )}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default BasicBillingServiceProviderForm;
