import { ReactNode } from 'react';

export interface TooltipProps {
  children: ReactNode;
  text: string;
  placement: TooltipPlacement;
  shouldFlip?: boolean;
  className?: string;
}

export enum TooltipPlacement {
  Top = 'Top',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  Left = 'Left',
  Right = 'Right',
  Bottom = 'Bottom',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
}
