import { Fragment, useRef } from 'react';
import { flexRender } from '@tanstack/react-table';
import { ReactTableDevtools } from '@tanstack/react-table-devtools';
import classNames from 'classnames';

import { DawnArrowDown16, DawnArrowUp16 } from '@xemplo/icons';
import { CenteredLoaderDots } from '@xemplo/loader';
import { NoResult } from '@xemplo/no-result';

import { ActionBar } from './action/action-bar';
import { TableBody, TableBodyTestId } from './body/body';
import { Pagination } from './pagination/pagination';
import { Totals } from './totals/totals';
import { hasCustomColumnSize, kebabCase } from './table.helper';
import * as S from './table.style';
import { TableProps } from './table.types';
import { useTanstackTable } from './use-react-table';

export const TableTestId = {
  wrapper: 'table-wrapper',
  table: 'table',
  header: 'table-header',
  headerRow: 'table-header-row',
  body: TableBodyTestId,
};

export function XemploTable<TData>(props: TableProps<TData>) {
  const { table, hasResults } = useTanstackTable(props);
  const {
    maxHeight,
    minHeight,
    minWidth: containerMinWidth, // TODO: Rename minWidth to containerMinWidth leaving for compatibility
    tableOverflowControlMinWidth,
    isListTable,
    enableTableDevTools,
    isLoading,
    noResultProps = {},
  } = props;
  const { enableExpanding, enableSorting, enableRowSelection, columns } = table.options;
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const isExpandable =
    enableExpanding &&
    table.getCoreRowModel().rows.some((row) => row.subRows?.length > 0);

  const hasRows = table.getCoreRowModel().rows.length > 0;
  return (
    <Fragment>
      <S.Container
        data-testid={TableTestId.wrapper}
        className={`table-wrapper ${props.className}`}
        $minHeight={minHeight}
        $minWidth={containerMinWidth}
      >
        {hasRows && <ActionBar table={table} />}
        <S.TableOverflowControl>
          <S.Table
            data-testid={TableTestId.table}
            data-selectable={enableRowSelection}
            data-sortable={enableSorting}
            data-expandable={isExpandable}
            $maxHeight={maxHeight}
            $minWidth={tableOverflowControlMinWidth}
          >
            {hasRows && (
              <S.TableHeader
                data-testid={TableTestId.header}
                $minWidth={tableOverflowControlMinWidth}
                ref={theadRef}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const isCheckboxCell = header.id === 'extra-col';

                      const headerCellClassName = classNames(
                        'no-user-select',
                        {
                          'extra-col-cell': isCheckboxCell,
                          'list-table': isListTable && isCheckboxCell && !isExpandable,
                        },
                        kebabCase(header.id)
                      );
                      return (
                        <S.HeaderCell
                          className={headerCellClassName}
                          key={header.id}
                          colSpan={header.colSpan}
                          $minWidth={header.column.columnDef.minSize}
                          $width={
                            hasCustomColumnSize(header.column.id, columns)
                              ? header.getSize()
                              : undefined
                          }
                          data-selectable={enableRowSelection}
                          data-expandable={isExpandable}
                        >
                          {header.isPlaceholder ? null : (
                            <S.CellWrapper
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <DawnArrowUp16 width={12} height={12} />,
                                desc: <DawnArrowDown16 width={12} height={12} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </S.CellWrapper>
                          )}
                        </S.HeaderCell>
                      );
                    })}
                  </tr>
                ))}
                <Totals table={table} />
              </S.TableHeader>
            )}
            <TableBody table={table} theadRef={theadRef} />
          </S.Table>
        </S.TableOverflowControl>
        <Pagination table={table} />
        {isLoading ? (
          <CenteredLoaderDots />
        ) : (
          !hasResults && <NoResult {...noResultProps} />
        )}
      </S.Container>

      {enableTableDevTools && (
        <S.TableDevTools>
          <ReactTableDevtools table={table} panelProps={{ style: { width: '100vw' } }} />
        </S.TableDevTools>
      )}
    </Fragment>
  );
}

export default XemploTable;
