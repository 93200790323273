import React from "react";
import { Avatar, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

interface AddIconProps {
  onClick: (e: any) => void;
  title: string | undefined;
  size?: "large" | "small" | "default" | number;
}

const AddIconIndex: React.FC<AddIconProps> = ({ onClick, title, size }) => {
  return (
    <React.Fragment>
      <Tooltip title={title} placement="bottom">
        <Avatar className="hand bg-primary" size={size || 35}>
          <PlusOutlined onClick={onClick} />
        </Avatar>
      </Tooltip>
    </React.Fragment>
  );
};

export default AddIconIndex;
