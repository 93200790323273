// @ts-nocheck
import { Modal, Table } from "antd";
import { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import {
  getCurrentTableSortInfo,
  getFullName,
  getSortOrder,
  getSortOrderRequestValue,
  mustBeArray,
} from "../../../../libs";
import { tableColumnGenerator } from "../../../../libs/table";
import { getAmendmentKeypayStatus } from "../../../actions/payrun";
import AmendmentKeypayStatus from "./AmendmentKeypayStatus";

const initialModalState = {
  loading: true,
  sortInfo: {},
  data: [],
  page: 1,
  total: 0,
};

const amendmentKeypayModalReducer = (state: any, action: any) => {
  switch (action.type) {
    case "loading":
    case "sortInfo":
      return { ...state, sortInfo: action.value };
    case "loadData":
      return {
        ...state,
        loading: false,
        data: action.value,
        total: action.total,
      };
    case "tableChange":
      return {
        ...state,
        loading: true,
        page: action.page,
        sortInfo: action.sortInfo,
      };
    case "reset":
      return { ...initialModalState };
    default:
      throw new Error();
  }
};

const AmendmentKeypayStatusModal = ({
  isModalVisible,
  handleClose,
  amendment,
  getAmendmentKeypayStatus,
}: {
  isModalVisible: boolean;
  handleClose: () => void;
  amendment: any;
  getAmendmentKeypayStatus: any;
}) => {
  const [amendmentKeypayModalState, dispatchAmendmentKeypayModal] = useReducer(
    amendmentKeypayModalReducer,
    initialModalState
  );

  useEffect(() => {
    if (isModalVisible) {
      getDataSource();
    } else {
      dispatchAmendmentKeypayModal({ type: "reset" });
    }
  }, [isModalVisible]);

  const getDataSource = () => {
    dispatchAmendmentKeypayModal({ type: "loading", payload: true });
    getAmendmentKeypayStatus({
      id: amendment?.amendmentId,
      options: {
        page: amendmentKeypayModalState.page,
        per_page: 10,
        ...getCurrentTableSortInfo({
          sortInfo: amendmentKeypayModalState.sortInfo,
        }),
      },
    }).then((resp: any) => {
      dispatchAmendmentKeypayModal({
        type: "loadData",
        value: mustBeArray(resp?.data),
        total: resp?.total,
      });
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      getDataSource();
    }
  }, [amendmentKeypayModalState.page, amendmentKeypayModalState.sortInfo]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    dispatchAmendmentKeypayModal({
      page: pagination.current,
      sortInfo: {
        order: getSortOrderRequestValue({ sorter }),
        field: sorter?.field,
      },
      type: "tableChange",
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      sortOrder: getSortOrder({
        sortInfo: amendmentKeypayModalState.sortInfo,
        field: "firstName",
      }),
      key: "name",
      render: (data: any, record: any) => {
        return `${getFullName({ name: record })}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      sortOrder: getSortOrder({
        sortInfo: amendmentKeypayModalState.sortInfo,
        field: "status",
      }),
      key: "status",
      render: (record: any) => {
        return <AmendmentKeypayStatus status={record} hideModal={true} />;
      },
    },
  ];
  return (
    <Modal
      width={700}
      title="Amendment Status"
      visible={isModalVisible}
      onCancel={handleClose}
      footer={[]}
    >
      <div className="mb-4">
        {amendment.amendmentTaskTypeName && (
          <div className="grid grid-cols-5">
            <span>Change Type:</span>
            <b className="col-span-3">{amendment.amendmentTaskTypeName}</b>
          </div>
        )}
        {amendment.amendmentSubTypeName && (
          <div className="grid grid-cols-5">
            <span>Sub Type:</span>
            <b className="col-span-3">{amendment.amendmentSubTypeName}</b>
          </div>
        )}
      </div>
      <Table
        dataSource={amendmentKeypayModalState.data}
        columns={tableColumnGenerator(columns)}
        loading={amendmentKeypayModalState.loading}
        pagination={{
          total: amendmentKeypayModalState.total,
          current: amendmentKeypayModalState.page,
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        rowKey={(record) => record?.id}
        onChange={handleTableChange}
      />
    </Modal>
  );
};

const mapDispatchToProps = {
  getAmendmentKeypayStatus,
};

export default connect(null, mapDispatchToProps)(AmendmentKeypayStatusModal);
