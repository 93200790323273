import { useContext, useEffect } from 'react';

import { DrawerContext } from '../drawer.context';
import { DrawerState } from '../drawer.types';

export const useDrawer = (initialState?: Partial<DrawerState>) => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }

  useEffect(() => {
    initialState && context.configureDrawer(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return context;
};
