import { DawnCircleAlert } from '@xemplo/icons';

import * as S from './bulk-upload-summary.styles';

export const BulkUploadWarnings = ({ warnings }: { warnings: string[] }) => {
  return (
    warnings.length > 0 && (
      <div>
        <S.WarningBanner>
          <DawnCircleAlert />
          <div className="bold">Error: </div> The following rows were not included due to
          the rate and unit/s being equal to zero.
        </S.WarningBanner>
        {warnings.map((warning, i) => (
          <div key={i}>
            {i < 10 && <S.WarningLine>{warning}</S.WarningLine>}
            {i === 10 && <S.MoreLines>+ {warnings.length - 10} more</S.MoreLines>}
          </div>
        ))}
      </div>
    )
  );
};
