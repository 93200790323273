//@ts-nocheck
import React from "react";
import { Card, Tooltip, Typography, Avatar, Badge } from "antd";
import Flag from "react-world-flags";
import CountryCoordinates from "../../../Common/countries";
import TableActionComponent from "../common/tableActions";

const { Title } = Typography;

const BillingServiceProviderDisplayCard = ({
  handleAction,
  content,
  claims,
  viewComponent,
}: any) => {
  const country =
    content && content.country && CountryCoordinates.getCode(content.country);
  return (
    content && (
      <Card
        className="card hand card-prop h-full pb-0"
        bordered={false}
        onClick={(e) => handleAction({ data: content, action: "view" })}
      >
        <div className="position-relative pt-3 pb-3 pl-0 pr-0">
          <div className={"mb-2.5 px-[50px] position-relative"}>
            <div className={"position-relative"}>
              <Tooltip placement="top" title={`${content.name}`}>
                <Title
                  ellipsis
                  className="card-title"
                  level={3}
                >{`${content.name}`}</Title>
              </Tooltip>
            </div>

            <Avatar
              className="position-absolute-top position-absolute-top-left"
              size={38}
              src={content.logoURL}
            />
          </div>
          <div className="flex justify-between mb-1 text-xs mt-6">
            <span>{`Business Unit Count: ${content.businessUnitCount}`}</span>
          </div>
          {/* Status badge */}
          <div>
            {content.isActive ? (
              <Badge
                count={"Active"}
                className={"text-xs badge badge-success"}
              />
            ) : (
              <Badge
                count={"InActive"}
                className={"text-xs badge badge-danger"}
              />
            )}
          </div>
        </div>
        {country && (
          <div className={"flags"}>
            <Tooltip placement="topLeft" title={content.country}>
              <Flag
                code={country}
                width={18}
                height={14}
                className="float-left"
              />
            </Tooltip>
          </div>
        )}
        <TableActionComponent
          classname="card-item mt-4 mr-4"
          buttonClassName="card-prop animated fadeIn position-absolute-top position-absolute-top-right"
          claims={claims}
          handleAction={(options: any) =>
            handleAction({ ...options, data: content, record: content })
          }
          viewComponentName={viewComponent}
        />
      </Card>
    )
  );
};

export default BillingServiceProviderDisplayCard;
