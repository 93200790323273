import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import { getAmendmentTaskSubType } from "../../../Organization/actions/payrun";
import { mustBeArray, errorHandler } from "../../../libs/utilities";
import { ListSelectField } from "../../ui";
import { SALARY_CHANGE_TYPE_ID } from "../../../constants";
import _ from "lodash";

type Props = {
  required: boolean;
  customLabel?: string;
  onChange?: (e: any, itemSelected: string) => void;
  task: any;
  businessUnitId: string | undefined;
  updateSubTypeList: (list: Array<any>) => void;
};

const onChangeDefault = () => {
  console.log("");
};

const ChangeTaskSubTypes = ({
  required,
  customLabel,
  onChange = onChangeDefault,
  task,
  businessUnitId,
  updateSubTypeList,
}: Props) => {
  const dispatch: any = useAppDispatch();
  const [list, setList] = useState<any>([]);
  const [label, setLabel] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // Get task subtypes based on task type
  useEffect(() => {
    if (task) {
      setList([]);
      setLoading(true);
      dispatch(
        getAmendmentTaskSubType({
          changeTaskValueId: task?.changeTaskValueId,
          businessUnitId,
        })
      ).then((response: any) => {
        setLoading(false);
        if (response.status) {
          setList(_.sortBy(mustBeArray(response.data), (o) => o.name));
          updateSubTypeList(mustBeArray(response.data));
        } else {
          errorHandler({ response, hasValidationErrors: true });
        }
      });
      const newLabel =
        task?.changeTaskValueId === SALARY_CHANGE_TYPE_ID
          ? "Earnings Category"
          : `${task?.name} Type`;

      setLabel(customLabel ?? newLabel);
    }
  }, [task]);

  const handleOnChange = (optionID: number) => {
    const itemSelected = list.find((item: any) => item.id === optionID);
    onChange(optionID, itemSelected?.name);
  };

  return (
    <ListSelectField
      label={label}
      name="changeTaskSubType"
      className="mb-3  col-start-1"
      required={required}
      ruleMsg={`Please select ${task?.name} type`}
      onChange={handleOnChange}
      list={list}
      loading={loading}
    />
  );
};

export default ChangeTaskSubTypes;
