//@ts-nocheck
import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip, Avatar } from "antd";
import { getClaimAndStatus } from "../../../libs/utilities";

class EditIconButton extends React.Component {
  render() {
    const {
      name,
      claims,
      className,
      handleEdit,
      avatarSize,
      hasNoComponents,
      avatarClassName,
    } = this.props;
    const claimAndStatus = getClaimAndStatus({
      claims,
      componentName: name,
      hasComponents: !hasNoComponents,
    });
    return (
      <span
        className={`${className} ${claimAndStatus.status}`}
        onClick={handleEdit}
      >
        <Tooltip title="Edit" placement="bottom">
          <Avatar
            size={avatarSize || "small"}
            className={`color-neutral ${avatarClassName}`}
            icon={<EditOutlined />}
          />
        </Tooltip>
      </span>
    );
  }
}

export default EditIconButton;
