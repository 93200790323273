import styled from 'styled-components';

import { Colour, Shadow } from '@xemplo/style-constants';

import { TabMenuItem } from '../menu-item';

export { MenuItem } from '../menu-item/menu-item.styles';
/**
 * When is Primary, it means is the top navigation menu and therefore will always have
 * a fixed height of 80px and the submenu panel is aligned to 68px from the top.
 * As for the other sizes, the height is dependent on the parent container and the
 * submenu might need to receive custom top value. At this stage, just setting pre-defined
 * values for the other sizes. We might need to tweak it later depending on the use case.
 */
export const SubMenuPanelWrapper = styled.div`
  position: absolute;
  max-width: 320px;
  right: 0;
  border: 1px solid ${Colour.Gray[200]};
  border-radius: 10px;
  box-shadow: ${Shadow.Medium2};
  transition: 0.2s opacity ease-out;
  z-index: 1;

  &.small {
    top: 25px;
  }

  &.medium {
    top: 30px;
  }

  &.large {
    top: 68px;
  }

  &.primary {
    right: 24px;
    top: 68px;
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
  }
`;

export const SubMenuPanel = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background: ${Colour.White[100]};
  border-radius: 10px;
`;

export const SubMenuItem = styled(TabMenuItem)`
  flex-grow: 1;
  margin: 0;
  padding: 0;

  &:first-child > a {
    border-radius: 10px 10px 0 0;
  }

  &:last-child > a {
    border-radius: 0 0 10px 10px;
  }

  &:only-child > a {
    border-radius: 10px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Colour.Gray[200]};
  }

  &.large {
    > a {
      padding: 20px 32px;
    }
  }

  & > a {
    justify-content: center;
    width: 100%;
    padding: 12px 24px !important;
    margin-right: 0 !important;

    &:hover:not(.active) {
      color: ${Colour.Gray[800]};
      background: ${Colour.Gray[50]};
    }

    &.active {
      background: ${Colour.Blue[50]};
      color: ${Colour.Gray[800]};
    }
  }
`;
