import { getWeeksInMonth } from '@internationalized/date';
import { useCalendarGrid } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';

import { CalendarCell } from '../cell';

import * as S from './calendar-grid.styles';
import { CalendarGridProps } from './calendar-grid.types';

export const CalendarGridTestId = {
  grid: 'calendar-grid',
};

export function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    { ...props, weekdayStyle: 'short' },
    state
  );
  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  const weeksArray = Array.from(Array(weeksInMonth).keys());

  return (
    <S.Grid {...gridProps} data-testid={CalendarGridTestId.grid}>
      <S.GridHeader {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <S.GridHeaderCell key={index}>{day}</S.GridHeaderCell>
          ))}
        </tr>
      </S.GridHeader>
      <tbody>
        {weeksArray.map((weekIndex) => {
          return (
            <tr key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex)
                .map((date, i) =>
                  date ? (
                    <CalendarCell key={i} state={state} date={date} />
                  ) : (
                    <td key={i} />
                  )
                )}
            </tr>
          );
        })}
      </tbody>
    </S.Grid>
  );
}
