import { Colour } from '@xemplo/style-constants';

export type LogoProps = {
  variant?: LogoVariant;
  size?: LogoSize;
};

export enum LogoVariant {
  default = 'default',
  dark = 'dark',
  light = 'light',
  xemplo = 'xemplo',
  gray = 'gray',
}

export enum LogoSize {
  xxs = '16px',
  xs = '24px',
  s = '32px',
  m = '40px',
  l = '56px',
  xl = '72px',
}

export const logoColourMap = {
  [LogoVariant.xemplo]: Colour.Blue[500],
  [LogoVariant.default]: Colour.Blue[600],
  [LogoVariant.dark]: Colour.Gray[800],
  [LogoVariant.light]: Colour.White[100],
  [LogoVariant.gray]: Colour.Gray[300],
};
