import styled from 'styled-components';

import { Body2XSmallSemiBold, Colour } from '@xemplo/style-constants';

export const Grid = styled.table`
  padding: 0 16px 16px;
`;

export const GridHeader = styled.thead`
  ${Body2XSmallSemiBold};
  text-align: center;
  color: ${Colour.Gray[400]};
  text-transform: uppercase;
`;

export const GridHeaderCell = styled.th`
  width: 34px;
  height: 30px;
`;
