import { RefObject, useCallback, useEffect } from 'react';
import { OverlayTriggerState } from '@react-stately/overlays';

type UseOutsideClickProps = {
  popoverState: OverlayTriggerState;
  containerRef: RefObject<HTMLElement>;
  selectRef: RefObject<HTMLSelectElement>;
};

export const useOutsideClick = (props: UseOutsideClickProps) => {
  const { containerRef, popoverState, selectRef } = props;

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as Node;

      // The popover component moves the listbox to the body, so we need to check
      // if the target is inside the listbox.
      // We could use the listRef, but the search and select all elements are not
      // within the listRef.
      // TODO: think on a more efficient way of doing this...
      const listboxWrapper = document.getElementsByClassName('listbox-wrapper');
      const isTargetInListbox = Array.from(listboxWrapper).some((element) =>
        element.contains(target)
      );

      if (containerRef.current?.contains(target) || isTargetInListbox) return;
      if (popoverState.isOpen) {
        popoverState.close();
        selectRef.current?.blur();
      }
    },
    [containerRef, popoverState, selectRef]
  );

  useEffect(() => {
    if (popoverState.isOpen) {
      window.addEventListener('click', handleOutsideClick);
    } else {
      window.removeEventListener('click', handleOutsideClick);
    }
    return () => window.removeEventListener('click', handleOutsideClick);
  });
};
