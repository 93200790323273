import { DateValue } from '@internationalized/date';
import type { AriaDatePickerProps } from '@react-aria/datepicker';

export type DateFieldProps = AriaDatePickerProps<DateValue> & {
  className?: string;
  format: DateFormats;
  testId?: string;
};

export enum DateFormats {
  default = 'dd/mm/yyyy',
  usa = 'mm/dd/yyyy',
}
