import * as React from 'react';
import SelectableTable from '../../../Common/ClaimComponents/TableGrid/SelectableTable';

interface AssignBusinessUnitTableProps{
  list: any,
  handleTableChange: (e:any) => void,
  loading: boolean,
  columns: any,
  selectedRows?: Array<string|number>
};

const AssignBusinessUnitTable = (props:AssignBusinessUnitTableProps) => {
  const {list, loading, handleTableChange, columns, selectedRows} = props;
  return(
    <SelectableTable
        data={list}
        loading={loading}
        handleTableChange={handleTableChange}
        columns={columns}
        selectedRowKeys={selectedRows}
    />
  )
}

export default AssignBusinessUnitTable;
