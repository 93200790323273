import { ColumnDef } from '@tanstack/react-table';

import { AmendmentStatus } from '@xemplo/amendment-status';
import { tryJsonParse } from '@xemplo/common-util';
import { DATE_TIME_FORMAT_AM_PM, formatDate } from '@xemplo/date-time';
import { AmendmentChangeLog, AmendmentStatusIds } from '@xemplo/gp-types';

export interface PayrunChangeLogUserInfo {
  Date: Date;
  EmailAddress: string;
  IpAddress: string;
  UserID: string;
  UserName: string;
}
export const columns: ColumnDef<AmendmentChangeLog>[] = [
  {
    id: 'changeStatusDateTime',
    accessorKey: 'changeStatusDateTime',
    header: 'Date',
    cell: ({ row }) =>
      formatDate({
        value: row.original.changeStatusDateTime,
        format: DATE_TIME_FORMAT_AM_PM,
      }),
  },
  {
    id: 'userInfo',
    accessorKey: 'userInfo',
    header: 'User',
    cell: ({ row }) => {
      const userInfo = tryJsonParse<PayrunChangeLogUserInfo>(row.original.userInfo);
      return userInfo?.UserName ?? '';
    },
  },
  {
    id: 'amendmentStatusName',
    accessorKey: 'amendmentStatusName',
    header: 'Action',
    cell: ({ row }) => {
      const status = row.original.amendmentStatusName;
      return (
        <AmendmentStatus
          statusName={status}
          status={
            AmendmentStatusIds[status.toUpperCase() as keyof typeof AmendmentStatusIds]
          }
        />
      );
    },
  },
];
