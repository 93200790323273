import styled from 'styled-components';

import {
  IconCommonStyles,
  IconContainerCommonStyles,
  IconWrapperModeCommonStyles,
} from '../common';

export const StyledIconContainer = styled.div`
  ${IconContainerCommonStyles};
  width: 40px;
  & > span {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
`;

export const StyledHeaderIconWrapper = styled.span`
  flex-shrink: 0;
  ${IconWrapperModeCommonStyles};
  ${IconCommonStyles};
  width: 40px;
  height: 40px;
  border-radius: 8px;
`;
