import environment from "./environment";

export const months = [
  {
    name: "January",
    value: 1,
  },
  {
    name: "February",
    value: 2,
  },
  {
    name: "March",
    value: 3,
  },
  {
    name: "April",
    value: 4,
  },
  {
    name: "May",
    value: 5,
  },
  {
    name: "June",
    value: 6,
  },
  {
    name: "July",
    value: 7,
  },
  {
    name: "August",
    value: 8,
  },
  {
    name: "September",
    value: 9,
  },
  {
    name: "October",
    value: 10,
  },
  {
    name: "November",
    value: 11,
  },
  {
    name: "December",
    value: 12,
  },
];

export const Weekends = ["Saturday", "Sunday"];

export const AUSTRALIA_COUNTRY_ID = 13;

export const TINY_MCE_URL = `https://cdn.tiny.cloud/1/${environment.tiny_mce_editor_key}/tinymce/5/tinymce.min.js`;
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${environment.google_api_key}&callback=initMap&libraries=places`;

export const YES_VALUE = 1;
export const NO_VALUE = 2;
