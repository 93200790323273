import { AvatarSize } from '@xemplo/avatar';
import { useGetPayrunWorkerDetailsQuery } from '@xemplo/payrun-query';

import * as S from './payrun-worker-detail.styles';
import { PayrunWorkerDetailHeaderProps } from './payrun-worker-detail.types';

export const PayrunWorkerDetailHeader = (props: PayrunWorkerDetailHeaderProps) => {
  const { payrunId, workerId } = props;

  const { data, isLoading } = useGetPayrunWorkerDetailsQuery({
    id: payrunId ?? null,
    workerId: workerId ?? null,
  });

  const { externalEmployeeName } = data?.result ?? {};
  const [firstName, lastName] = externalEmployeeName?.split(' ') ?? [];

  if (isLoading || !data) return null;
  return (
    <S.Container>
      <S.Avatar user={{ firstName, lastName }} size={AvatarSize.l} />
      <S.Details>
        <S.Name>{externalEmployeeName}</S.Name>
        {/* <S.Title>{title}</S.Title> TODO: Out of scope for initial release*/}
        {/* <S.Salary>{salary}</S.Salary> TODO: Out of scope for initial release */}
      </S.Details>
    </S.Container>
  );
};
