export const DEFAULT_GUTTER = 24;
export const DEFAULT_MODAL_WIDTH_MD = 700;

// Table
export const TABLE_COLUMN_ADD_ICON_SIZE = 40;

export const CLEAR_SELECTION_VALUE = "clearFilter";

// Nonce Values
export enum NONCE_ID {
  TABLE_ACTIONS = "xqGmqXLNZS",
  DEFAULT_LIST_CONTAINER = "qcEa5QPgXJ",
}
