import { useRef } from 'react';
import classnames from 'classnames';

import * as S from './drawer-body.styles';
import { DrawerBodyProps } from './drawer-body.types';
import { useBodyScroll } from './use-body-scroll';

export function DrawerBody({ children, customTop }: DrawerBodyProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isOverflowing } = useBodyScroll(ref);

  return (
    <S.DrawerBodyWrapper ref={ref} $offset={customTop}>
      {children}
      <S.DrawerScrollFade
        id="scroll-fade"
        className={classnames({ hidden: !isOverflowing })}
      />
    </S.DrawerBodyWrapper>
  );
}
