import { ButtonSize, ButtonType, ButtonWithState } from '@xemplo/button';
import { useGeneratePayrun } from '@xemplo/pay-schedule-query';
import { ToastType, useToast } from '@xemplo/toast';

import { GeneratePayrunButtonProps } from './generate-payrun.types';

export const GeneratePayrun = (props: GeneratePayrunButtonProps) => {
  const { payScheduleId, buttonProps } = props;
  const { addToast } = useToast();

  const { mutateAsync } = useGeneratePayrun({
    payScheduleId: payScheduleId ?? null,
    onSuccess: () => {
      addToast({
        text: `Pay run created`,
        type: ToastType.Confirmation,
      });
    },
    onError: () => {
      addToast({
        text: `Error creating pay run`,
        type: ToastType.Warning,
      });
    },
  });

  if (!payScheduleId) {
    return null;
  }
  return (
    <ButtonWithState
      type={ButtonType.Secondary}
      size={ButtonSize.Small}
      {...buttonProps}
      ariaLabel="generate payrun button"
      onClickAsync={() => mutateAsync()}
    >
      Generate Payrun
    </ButtonWithState>
  );
};

export default GeneratePayrun;
