import { useCallback } from 'react';

import { hexToRGBA } from '@xemplo/color-util';
import { Colour } from '@xemplo/style-constants';

import { useModal } from './use-modal';

export const useScrollAnimation = () => {
  const { bodyRef, headerRef, footerRef, state } = useModal();

  const alwaysShowKeyline = state.header?.showKeyline;

  return useCallback(() => {
    if (!bodyRef.current || !headerRef.current) return;

    handleHeaderShadow(bodyRef.current, headerRef.current, alwaysShowKeyline);
    handleFooter(bodyRef.current, footerRef.current);
  }, [bodyRef, footerRef, headerRef, alwaysShowKeyline]);
};

/**
 * The UX team needs to revisit the specifcations for this function.
 * It is applying what is in the design spec, but the result is not what is expected.
 * So they need to have a better understanding of what they want.
 *
 * Leaving this for now so that we can revisit it later.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleHeaderCollapse(headerEl: HTMLElement, scrollFactor: number) {
  const icon = headerEl.getElementsByClassName('header-icon');
  const preTitle = headerEl.getElementsByClassName('pre-title');
  const descriptor = headerEl.getElementsByClassName('descriptor');

  if (icon.length) {
    const iconEl = icon?.[0] as HTMLElement;
    iconEl && handleIconResize(iconEl, scrollFactor);
  }

  if (preTitle.length) {
    const preTitleEl = preTitle[0] as HTMLElement;
    preTitleEl && handleDisappearingElement(preTitleEl, 14, scrollFactor);
  }

  if (descriptor.length) {
    const descriptorEl = descriptor[0] as HTMLElement;
    descriptorEl && handleDisappearingElement(descriptorEl, 14, scrollFactor);
  }
}

function handleHeaderShadow(
  bodyEl: HTMLDivElement,
  headerEl: HTMLElement,
  showKeyline?: boolean
) {
  const scrollTop = bodyEl.scrollTop;

  const allowKeyline = showKeyline !== false;

  if (allowKeyline && scrollTop > 0) {
    headerEl.classList.add('can-show-shadow', 'can-show-keyline');
  } else if (allowKeyline) {
    headerEl.classList.remove('can-show-shadow');
    return;
  }

  if (scrollTop > 0) {
    headerEl.classList.add('can-show-shadow', 'can-show-keyline');
  } else {
    headerEl.classList.remove('can-show-shadow', 'can-show-keyline');
  }
}

function handleFooter(bodyEl: HTMLDivElement, footerEl: HTMLElement | null) {
  if (footerEl) {
    const scrollPercentage = getScrollingPercentage(bodyEl); // From total body height
    const alpha = scrollPercentage === 100 ? 0 : 1;
    const newColor = hexToRGBA(Colour.Gray[200], alpha);

    footerEl.style.borderTop = `1px solid ${newColor}`;
  }
}

/**
 * The UX team needs to revisit the specifcations for this function.
 * It is applying what is in the design spec, but the result is not what is expected.
 * So they need to have a better understanding of what they want.
 *
 * Leaving this for now so that we can revisit it later.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handlePadding(el: HTMLElement, scrollFactor: number) {
  el.style.paddingTop = calculateInRange(44, scrollFactor, 24) + 'px';
  el.style.paddingBottom = calculateInRange(32, scrollFactor, 24) + 'px';
}

function handleDisappearingElement(
  el: HTMLElement,
  fontSize: number,
  scrollFactor: number
) {
  let calculatedOpacity = calculateInRange(1, scrollFactor, 0, true, false);
  calculatedOpacity = isNaN(calculatedOpacity) ? 1 : calculatedOpacity;
  el.style.opacity = calculatedOpacity.toString();

  const dynamicFontSize = calculateInRange(fontSize, scrollFactor, 0);
  el.style.fontSize = `${dynamicFontSize}px`;

  const dynamicLineHeight = calculateInRange(21, scrollFactor, 0);
  el.style.lineHeight = `${dynamicLineHeight}px`;
}

function handleIconResize(iconEl: HTMLElement, scrollFactor: number) {
  const iconWrapperMax = 56;
  const iconWrapperMin = 40;
  const iconMax = 32;
  const iconMin = 24;

  const iconSvg = iconEl.getElementsByTagName('svg');
  const iconSvgEl = iconSvg?.[0];

  if (scrollFactor === 0) {
    iconEl.style.width = `${iconWrapperMax}px`;
    iconEl.style.height = `${iconWrapperMax}px`;
    iconEl.style.transform = 'unset';

    if (iconSvgEl) {
      iconSvgEl.style.width = `${iconMax}px`;
      iconSvgEl.style.height = `${iconMax}px`;
    }
    return;
  }

  const wrapperSize = calculateInRange(iconWrapperMax, scrollFactor, iconWrapperMin);
  iconEl.style.width = `${wrapperSize}px`;
  iconEl.style.height = `${wrapperSize}px`;

  if (iconSvgEl) {
    const iconSize = calculateInRange(iconMax, scrollFactor, iconMin);
    iconSvgEl.style.width = `${iconSize}px`;
    iconSvgEl.style.height = `${iconSize}px`;
  }
}

/**
 * The scrolling factor is based on the a percentage of the scroll position relative
 * to the height of the header. The scrolling factor only starts when the scroll position
 * is greater than the padding of the content area.
 * The desired effect is to have the content bumping the header and then causing it to shrink.
 * This function calculates the percentage of the scroll position relative to the height of the header.
 * so the header can be resized proportionally.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getScrollingFactor(
  bodyEl: HTMLDivElement | null,
  headerEl?: HTMLElement | null
) {
  if (!bodyEl || !headerEl) return 0;

  const animationStart = 40;
  const scrollTop = bodyEl.scrollTop;

  if (scrollTop < animationStart) return 0;

  const headerHeight = headerEl.clientHeight;
  const headerOffset = headerHeight - animationStart;
  return (scrollTop / headerOffset) * 100;
}

/**
 * The scrolling percentage is a calculation of the scroll position relative
 * to the offset between the total height of the content and the visible height.
 * It also limits the values to be within 0 and 100.
 */
function getScrollingPercentage(bodyEl: HTMLDivElement | null) {
  if (!bodyEl) return 0;

  const scrollTop = bodyEl.scrollTop;
  const visibleHeight = bodyEl.clientHeight;
  const totalHeight = bodyEl.scrollHeight;

  const percentage = (scrollTop / (totalHeight - visibleHeight)) * 100;
  return Math.max(0, Math.min(100, percentage));
}

/**
 * This function calculates a value within a range based on a percentage.
 * The percentage is a value between 0 and 100.
 * The result is a value between the max and min.
 *
 * In certain cases, we need to round up the result. The default behavior is to round up.
 * In addition, we can specify if the result should be positive or negative (for negative margin).
 */
function calculateInRange(
  max: number,
  scrollPercentage: number,
  min: number,
  isPositive = true,
  roundUp = true
) {
  const range = max - min;
  const marginOffset = (range * scrollPercentage) / 100;

  const result = isPositive
    ? Math.max(min, Math.min(max, max - marginOffset))
    : max - marginOffset;

  return roundUp ? Math.ceil(result) : result;
}
