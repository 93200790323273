//@ts-nocheck
import { Component } from "react";
import { Collapse, Row, Col, InputNumber, message } from "antd";
import { validateInputValueIncludesOnlyNumbers } from "../../../libs";
import {
  currencyFormaterByCode,
  delay,
  isEmpty,
  isNullEmptyUndefined,
} from "../../../libs/utilities";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import {
  PAYRUN_CURRENCY_CONVERSION_FEES_CANCEL,
  PAYRUN_CURRENCY_CONVERSION_FEES_SAVE,
} from "../../../constants";

const { Panel } = Collapse;

type State = any;
class CurrencyConversionFeesView extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      fixedConversionRate: "",
      percentConversionRate: "",
      currencyCode: "",
      activeKey: "",
    };
  }

  componentDidMount() {
    const { currencyFees } = this.props;
    if (!isEmpty(currencyFees)) {
      this.setState(
        {
          fixedConversionRate: currencyFees?.fixedConversionRate,
          percentConversionRate: currencyFees?.percentConversionRate,
          currencyCode: currencyFees?.currencyConvertFrom,
        },
        () => {
          delay(100).then(() => {
            this.forceUpdate();
          });
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (
      newProps.currencyFees &&
      newProps.currencyFees !== this.props.currencyFees
    ) {
      const { currencyFees } = newProps;
      this.setState(
        {
          fixedConversionRate: currencyFees?.fixedConversionRate,
          percentConversionRate: currencyFees?.percentConversionRate,
          currencyCode: currencyFees?.currencyConvertFrom,
        },
        () => {
          delay(100).then(() => {
            this.forceUpdate();
          });
        }
      );
    }
  }
  updateActiveKey = (activeKey: any) => {
    const { currencyFees } = this.props;
    this.setState({ activeKey }, () => {
      if (!activeKey) {
        this.setState({
          fixedConversionRate: currencyFees?.fixedConversionRate,
          percentConversionRate: currencyFees?.percentConversionRate,
        });
      }
    });
  };
  updateValue = ({ label, value }: any) => {
    this.setState({ [label]: value });
  };

  saveCurrencyRates = () => {
    const { fixedConversionRate, percentConversionRate } = this.state;
    if (isNullEmptyUndefined(fixedConversionRate))
      return message.error("Please enter fixed conversion rate");
    if (isNullEmptyUndefined(percentConversionRate))
      return message.error("Please enter percentage conversion rate");
    let values = { fixedConversionRate, percentConversionRate };
    values.convertTo = this.props.currencyFees?.convertTo;
    values.convertFrom = this.props.currencyFees?.convertFrom;
    values.currencyConvertFrom = this.props.currencyFees?.currencyConvertFrom;
    values.currencyConvertTo = this.props.currencyFees?.currencyConvertTo;
    this.props.updateCurrencyFees({ payload: values });
  };

  render() {
    const { fixedConversionRate, percentConversionRate, currencyCode } =
      this.state;
    const { currencyFees, claims, updateLoading } = this.props;
    return (
      <Collapse
        activeKey={this.state.activeKey}
        onChange={this.updateActiveKey}
        className="my-4"
      >
        <Panel
          key="1"
          header={`Conversion Fees (Fixed: ${currencyFormaterByCode(
            fixedConversionRate,
            currencyCode
          )}, Percentage: ${percentConversionRate}%)`}
        >
          <h3>
            {`From ${currencyFees?.convertFromCurrency} to ${currencyFees?.convertToCurrency}`}
          </h3>
          <Row gutter={16} className="mb-4">
            <Col sm={24} md={12}>
              <label>Fixed Conversion Rate</label>
              <InputNumber
                onKeyDown={(e) =>
                  validateInputValueIncludesOnlyNumbers({ e, hasDecimal: true })
                }
                placeholder="Enter"
                onChange={(e) =>
                  this.updateValue({ label: "fixedConversionRate", value: e })
                }
                value={fixedConversionRate}
                className="w-3/6 mt-1.5"
              />
            </Col>
            <Col sm={24} md={12}>
              <label>Percent Conversion Rate</label>
              <InputNumber
                onKeyDown={(e) =>
                  validateInputValueIncludesOnlyNumbers({ e, hasDecimal: true })
                }
                placeholder="Enter"
                onChange={(e) =>
                  this.updateValue({ label: "percentConversionRate", value: e })
                }
                value={percentConversionRate}
                className="w-3/6 mt-1.5"
              />
            </Col>
          </Row>
          <SimpleButton
            claims={claims}
            onClick={this.updateActiveKey}
            buttonText="Cancel"
            name={PAYRUN_CURRENCY_CONVERSION_FEES_CANCEL}
          />
          <SimpleButton
            className="float-right"
            onClick={this.saveCurrencyRates}
            loading={updateLoading}
            claims={claims}
            buttonText="Save"
            type="primary"
            name={PAYRUN_CURRENCY_CONVERSION_FEES_SAVE}
          />
          <div className="clearfix"></div>
        </Panel>
      </Collapse>
    );
  }
}

export default CurrencyConversionFeesView;
