const initialState = {
  list: [],
  count: 0,
  details: {},
};

export default function emailReducer(state = initialState, action: any) {
  const { data, total, type } = action;
  switch (type) {
    case "LIST_EMAIL_TEMPLATES": {
      return {
        ...state,
        list: data ? data : [],
        count: total ? total : 0,
      };
    }
    case "GET_EMAIL_TEMPLATE": {
      return {
        ...state,
        detail: data,
      };
    }
    case "CLEAR_EMAIL_TEMPLATE": {
      return {
        ...state,
        detail: {},
        list: [],
        count: 0,
      };
    }

    default:
      return state;
  }
}
