import { SVGProps } from 'react';
const DawnChartGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.758 2.008a4.75 4.75 0 0 0-4.75 4.75v10.484a4.75 4.75 0 0 0 4.75 4.75h10.484a4.75 4.75 0 0 0 4.75-4.75V6.758a4.75 4.75 0 0 0-4.75-4.75H6.758Zm-3.25 4.75a3.25 3.25 0 0 1 3.25-3.25h10.484a3.25 3.25 0 0 1 3.25 3.25v10.484a3.25 3.25 0 0 1-3.25 3.25H6.758a3.25 3.25 0 0 1-3.25-3.25V6.758Zm5.776 4.087a.75.75 0 1 0-1.5 0v5.776a.75.75 0 0 0 1.5 0v-5.776ZM12 12.405a.75.75 0 0 1 .75.75v3.466a.75.75 0 0 1-1.5 0v-3.466a.75.75 0 0 1 .75-.75Zm4.215-3.87a.75.75 0 0 0-1.5 0v8.086a.75.75 0 1 0 1.5 0V8.534Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnChartGraph;
