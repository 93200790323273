import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MAX_NUMBER_VALUE } from '@xemplo/gp-constants';
import { ChangeTaskTypes } from '@xemplo/gp-types';
import { DawnCircleInformation } from '@xemplo/icons';
import { Colour } from '@xemplo/style-constants';
import { TextFieldV2 } from '@xemplo/text-field';
import { Tooltip, TooltipPlacement } from '@xemplo/tooltip';

import * as S from '../../modal.styles';

import { AmountMethodOptions } from './bulk-upload/bulk-upload.types';

export const Rate = () => {
  const { register, watch, unregister } = useFormContext();

  const watchRate = watch('rate');
  const watchAmountMethod = watch('amountMethod');
  const maxAmount = watch('recurringMaxAmount');
  const watchUnits = watch('units');
  const watchChangeTask = watch('changeTask');

  const isTermination = watchChangeTask === ChangeTaskTypes.TERMINATION;
  const showRateField =
    watchAmountMethod !== AmountMethodOptions.Variable && !isTermination;
  const isPayInstruction = watchChangeTask === ChangeTaskTypes.PAY_INSTRUCTION;
  useEffect(() => {
    if (showRateField) {
      register('rate', {
        validate: (value) => {
          if (maxAmount && value * watchUnits > maxAmount) {
            return `Total must be less than or equal to maximum amount`;
          }
          return true;
        },
      });
    } else {
      unregister('rate');
    }
  }, [register, showRateField, unregister, maxAmount, watchUnits]);

  if (!showRateField) {
    return null;
  }
  return (
    <S.FormLabel>
      <S.LabelWithTooltip>
        {isPayInstruction ? (
          <span>
            Amount
            <S.OptionalLabel>(optional) </S.OptionalLabel>
          </span>
        ) : (
          <>
            <span>
              Rate
              <S.OptionalLabel>(optional) </S.OptionalLabel>
            </span>
            <Tooltip
              text="If no rate is specified, the worker’s rate from PPS will be used."
              placement={TooltipPlacement.BottomRight}
            >
              <DawnCircleInformation height={24} width={24} color={Colour.Gray[300]} />
            </Tooltip>
          </>
        )}
      </S.LabelWithTooltip>
      <TextFieldV2
        defaultValue={watchRate}
        placeholder={isPayInstruction ? 'Enter amount' : 'Enter rate'}
        type="number"
        name="rate"
        testId="rate"
        aria-label="Rate field"
        min={0}
        max={MAX_NUMBER_VALUE}
        onWheel={(e) => e.currentTarget.blur()}
      />
    </S.FormLabel>
  );
};
