//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  getPayschedule,
  getProcessingFee,
  payscheduleHandler,
  updatePaySchedule,
  addProcessingFee,
  removeProcessingFee,
} from "../actions/payschedule";
import { getBusinessUnit, listTaxFees } from "../actions/businessunits";
import { timezoneLookup, populateStates } from "../actions/lookup";
import {
  listPayschedulePayruns,
  generateAdhoc,
  listAmendmentTypes,
  earningLinesLookup,
  payCategoryLookUp,
} from "../actions/payrun";
import axios from "axios";
import {
  updateHeader,
  updateAuthorizationClaim,
} from "../../User/actions/member";
import { findAuthorizationClaims } from "../../libs/utilities";
import { PAYSCHEDULE_BREAD_CRUMB_CONTAINER } from "../../constants";
import { withRouter } from "../../hooks";

class PayscheduleDetails extends SwiftContainer {
  signal = axios.CancelToken.source();
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { id, payscheduleid } = this.props.router.params;
    const header = {
      routeComponentName: "payScheduleBreadCrumb",
      routesContainer: findAuthorizationClaims({
        claims: this.state.pageClaims?.components,
        name: PAYSCHEDULE_BREAD_CRUMB_CONTAINER,
      }),
    };
    this.props.updateHeader({ header });
    this.executeAction(this.props.listPayschedulePayruns, {
      id: id || payscheduleid,
      options: { payrunTypeID: 1 },
      cancelToken: this.signal.token,
    });
    return id
      ? this.executeAction(this.props.getPayschedule, {
          id: id || payscheduleid,
          cancelToken: this.signal.token,
        })
      : true;
  }

  componentWillUnmount() {
    this.signal.cancel("Api is being canceled");
  }

  render() {
    const { Layout, payrun } = this.props;
    const { pageClaims, loading } = this.state;
    return (
      <Layout
        userMode={this.props.userMode}
        businessunitDetails={this.props.businessunitDetails}
        memberDetails={this.props.memberDetails}
        payrun={payrun.list}
        timezoneLookup={this.props.timezoneLookup}
        loading={loading}
        payschedule={this.props.payschedule}
        listPayschedulePayruns={(data: any) =>
          this.executeAction(this.props.listPayschedulePayruns, data)
        }
        getPayschedule={(data: any) =>
          this.executeAction(this.props.getPayschedule, data)
        }
        getBusinessUnit={(data: any) =>
          this.executeAction(this.props.getBusinessUnit, data)
        }
        signal={this.signal}
        generateAdhoc={(data: any) =>
          this.executeAction(this.props.generateAdhoc, data)
        }
        pageClaims={pageClaims}
        member={this.props.member}
        populateStates={this.props.populateStates}
        listAmendmentTypes={this.props.listAmendmentTypes}
        earningLinesLookup={this.props.earningLinesLookup}
        getProcessingFee={this.props.getProcessingFee}
        payCategoryLookUp={this.props.payCategoryLookUp}
        payscheduleHandler={this.props.payscheduleHandler}
        updateHeader={this.props.updateHeader}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        match={{ params: this.props.router.params }}
        listTaxFees={this.props.listTaxFees}
        updatePaySchedule={this.props.updatePaySchedule}
        addProcessingFee={this.props.addProcessingFee}
        removeProcessingFee={this.props.removeProcessingFee}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  payschedule: state.payschedule.detail || {},
  payrun: state.payrun.total,
  memberDetails: state.member.details || {},
  userMode: "",
  businessunitDetails: state.businessUnit.detail || {},
  member: state.member,
});

const mapDispatchToProps = {
  getPayschedule,
  listPayschedulePayruns,
  generateAdhoc,
  getBusinessUnit,
  timezoneLookup,
  populateStates,
  listAmendmentTypes,
  earningLinesLookup,
  getProcessingFee,
  payCategoryLookUp,
  payscheduleHandler,
  updateHeader,
  updateAuthorizationClaim,
  listTaxFees,
  updatePaySchedule,
  addProcessingFee,
  removeProcessingFee,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayscheduleDetails)
);
