import { formatToCurrency } from '@xemplo/common-util';
import { AmendmentKeypayStatusIds } from '@xemplo/gp-types';
import { DawnCircleInformation } from '@xemplo/icons';
import { Tooltip, TooltipPlacement } from '@xemplo/tooltip';

import * as S from '../../amendment-details-modal-styles';

//We only need to show pending state for amounts if the amendment is integrated and has not yet been applied to keypay
export const PendingAmount = (props: {
  value?: number | null;
  isIntegrated?: boolean;
  amendmentKeypayStatusId?: number | null;
  tooltipPosition?: TooltipPlacement;
}) => {
  const {
    value,
    isIntegrated,
    amendmentKeypayStatusId,
    tooltipPosition = TooltipPlacement.BottomRight,
  } = props;

  if (value) return formatToCurrency({ value });

  if (
    isIntegrated &&
    amendmentKeypayStatusId !== AmendmentKeypayStatusIds.Success &&
    !value
  ) {
    return (
      <S.Pending>
        Pending
        <Tooltip
          text="Pending amounts will be available once amendment is applied in PPS (Payroll Processing Service) "
          placement={tooltipPosition}
        >
          <DawnCircleInformation height={16} width={16} />
        </Tooltip>
      </S.Pending>
    );
  }
  return formatToCurrency({ value });
};
