type Id = string | number | null;
export const PayrunAPI = {
  payruns: '<%userArea%>/payruns',
  payrunById: (id: Id) => `<%userArea%>/payruns/${id}`,
  amendmentsByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/amendments`,
  amendmentByPayrunId: (id: Id, amendmentId: string) =>
    `<%userArea%>/payruns/${id}/amendment/${amendmentId}`,
  workersByPayrunId: (id: Id) => `<%userArea%>/keypay/getEmployeesWithQuery/${id}`,
  workerDetailsById: (id: Id, workerId: Id) =>
    `<%userArea%>/payruns/${id}/getPayrunEmployeeLastPayAndYtd/${workerId}`,
  submitAmendmentsByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/amendments/submit`,
  milesontesByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/milestones`,
  headerByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/header`,
  approveEarningLinesByPayrunId: (id: Id) =>
    `/<%userArea%>/Payruns/${id}/payrunEarningLines`,
  rejectEarningLinesByPayrunId: (id: Id) => `/<%userArea%>/Payruns/${id}/reject`,
  changeLogByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/history`,
  earningLinesByPayrunId: (id: Id) =>
    `<%userArea%>/payruns/${id}/getPayrunEmployeeTotals`,
  earningLineDetailsVarianceById: (id: Id, workerId: Id) =>
    `<%userArea%>/payruns/${id}/getPayrunEmployeeEarningLinesPreviousAndCurrent/${workerId}`,
  summaryByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/getPayRunSummary`,
  filesByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/getFiles`,
  fileByFileId: (id: Id) => `<%userArea%>/payruns/downloadFile/${id}`,
  invoiceByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/invoices`,
  payInvoiceByPayrunId: (id: Id) => `<%userArea%>/payruns/${id}/paymentmade`,
};

export const BusinessUnitAPI = {
  businessUnitById: (id: Id) => `<%userArea%>/businessunits/${id}`,
};
