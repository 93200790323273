import { DawnCircleAlert, DawnCross16, DawnTick16 } from '@xemplo/icons';
import { Colour } from '@xemplo/style-constants';

import * as S from './bulk-upload.styles';
import { FileItemProps } from './bulk-upload.types';

export const FileItem = ({ file, error, onCancel }: FileItemProps) => {
  if (!file) {
    return null;
  }
  if (error) {
    return (
      <S.FileItemWrapper className="error">
        <S.FileItemContent>
          <DawnCircleAlert /> Upload Failed
          <S.FileName>
            Errors were identified, please resolve and upload again.
          </S.FileName>
          <S.CancelButton onClick={(e) => onCancel(e)}>
            <DawnCross16 />
          </S.CancelButton>
        </S.FileItemContent>
      </S.FileItemWrapper>
    );
  }

  return (
    <S.FileItemWrapper>
      <S.FileItemContent>
        <DawnTick16 color={Colour.Blue[500]} />
        <S.FileName>{file?.name}</S.FileName>
        <S.CancelButton onClick={(e) => onCancel(e)}>
          <DawnCross16 />
        </S.CancelButton>
      </S.FileItemContent>
    </S.FileItemWrapper>
  );
};
