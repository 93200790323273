import { memo, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Drawer, DrawerProvider } from '@xemplo/drawer';
import { DawnCross24 } from '@xemplo/icons';

import * as S from './layout-with-routing.styles';

export interface LayoutWithRoutingProps {
  header: React.ReactNode;
  navigation: React.ReactNode;
  onCloseRoute?: string;
}

export const LayoutTestId = {
  container: 'layout-with-routing-container',
  header: 'layout-with-routing-header',
  navigation: 'layout-with-routing-secondary-navigation',
  mainSection: 'layout-with-routing-main-section',
  closeButton: 'icon-button-close-layout-btn', //Icon button controls it's own data-testid attribute
};

export function LayoutWithRouting(props: Readonly<LayoutWithRoutingProps>) {
  const navigationRef = useRef<HTMLDivElement>(null);
  const [customTop, setCustomTop] = useState<number>();

  /** Handles the offset calculation for the drawer so it snaps to the layout header */
  const handleCustomTop = () => {
    if (!navigationRef.current) return;

    const { bottom } = navigationRef.current.getBoundingClientRect();
    setCustomTop(bottom);
  };

  return (
    <S.LayoutContainer
      onLoad={handleCustomTop}
      onScroll={handleCustomTop}
      data-testid={LayoutTestId.container}
    >
      <S.HeaderContainer data-testid={LayoutTestId.header}>
        {props.header}
        <CloseButton onCloseRoute={props.onCloseRoute} />
      </S.HeaderContainer>
      <S.Divider />
      <S.NavigationContainer ref={navigationRef} data-testid={LayoutTestId.navigation}>
        {props.navigation}
      </S.NavigationContainer>
      <S.MainSectionContainer data-testid={LayoutTestId.mainSection}>
        <DrawerProvider>
          <Outlet />
          <Drawer customTop={customTop} />
        </DrawerProvider>
      </S.MainSectionContainer>
    </S.LayoutContainer>
  );
}

export default LayoutWithRouting;

const CloseButton = memo(({ onCloseRoute }: { onCloseRoute?: string }) => {
  const navigate = useNavigate();
  return (
    <S.CloseButton
      data-testid={LayoutTestId.closeButton}
      id="close-layout-btn"
      naked
      ariaLabel="Close current route"
      onClick={() => navigate(onCloseRoute || '/')}
    >
      <DawnCross24 />
    </S.CloseButton>
  );
});
