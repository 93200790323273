//@ts-nocheck
import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import _ from "lodash";

class ArchievedEmployees extends React.Component {
  mapArrayToString = (receivedArray: any) => {
    return mustBeArray(receivedArray).reduce((a: any, b: any) => {
      return a === ""
        ? `${b.firstName} ${b.lastName}`
        : `${a}, ${b.firstName} ${b.lastName}`;
    }, "");
  };
  render() {
    const { visible, addedEmployees, archievedEmployees, handleCancel } =
      this.props;
    return (
      <Modal
        visible={visible}
        onCancel={handleCancel}
        okText={"Save"}
        closable={true}
        title="Added/Left Employees"
        centered={true}
        width={700}
        footer={[
          <Button key="submit" type="primary" onClick={() => handleCancel()}>
            Close
          </Button>,
        ]}
        className="pb-0"
      >
        <Row gutter={16}>
          <Col span={6}>
            <b>Added Employees:</b>
          </Col>
          <Col span={18}>
            {_.isEmpty(addedEmployees)
              ? "-"
              : this.mapArrayToString(addedEmployees)}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <b>Left Employees:</b>
          </Col>
          <Col span={18}>
            {_.isEmpty(archievedEmployees)
              ? "-"
              : this.mapArrayToString(archievedEmployees)}
          </Col>
        </Row>
      </Modal>
    );
  }
}
export default ArchievedEmployees;
