//@ts-nocheck
import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Col, Row, Select, Button } from "antd";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import { mustBeArray } from "../../../libs/utilities";

const FormItem = Form.Item;
const Option = Select.Option;

type State = any;

class SystemAdminAssignRolesUserForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  clearForm = () => {
    this.props.form.resetFields([
      "roleId",
      this.props.type === "psp" ? "serviceProviderId" : "company",
    ]);
  };
  getAssignedRolesId = (list) => {
    return mustBeArray(list).reduce((a, b) => {
      return a.concat(
        this.props.type === "company" ? b.companyId : b.payrollServiceProviderId
      );
    }, []);
  };
  render() {
    const {
      companyList,
      userRolesList,
      payrollProviderList,
      type,
      addRoles,
      assignedRolesList,
      getFieldDecorator,
    } = this.props;
    const assignedRolesId = this.getAssignedRolesId(assignedRolesList);
    return (
      <React.Fragment>
        <Row gutter={DEFAULT_GUTTER}>
          <Col md={12} sm={24}>
            {type === "psp" ? (
              <FormItem label="Payroll Provider:">
                {getFieldDecorator("serviceProviderId", {
                  rules: [
                    {
                      required: false,
                      message: "Please select a payroll provider",
                    },
                  ],
                })(
                  <Select
                    placeholder="Select a payroll provider"
                    filterOption={true}
                    className="w-288"
                    optionFilterProp="filtername"
                    showSearch
                  >
                    {mustBeArray(
                      _.filter(
                        payrollProviderList,
                        (o) => !assignedRolesId.includes(o.id)
                      )
                    ).map((d) => (
                      <Option key={d.id} value={d.id} filtername={d.name}>
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            ) : (
              <FormItem label="Company:">
                {getFieldDecorator("company", {
                  rules: [
                    {
                      required: false,
                      message: "Please select a company",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder="Select company"
                    filterOption={true}
                    className="w-288"
                    optionFilterProp="filtername"
                  >
                    {mustBeArray(
                      _.filter(
                        companyList,
                        (o) => !assignedRolesId.includes(o.id)
                      )
                    ).map((d) => (
                      <Option key={d.id} value={d.id} filtername={d.value}>
                        {d.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}
          </Col>
          <Col md={12} sm={24}>
            <FormItem label="Role:">
              {getFieldDecorator("roleId", {
                rules: [
                  {
                    required: false,
                    message: "Please select a user role",
                  },
                ],
              })(
                <Select
                  showSearch={false}
                  placeholder="Select user role"
                  filterOption={false}
                  className="w-288"
                >
                  {mustBeArray(userRolesList).map((d) => (
                    <Option key={d.id} value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <span>
          <Button className="bg-success mr-4 rounded-[20px]" onClick={addRoles}>
            Add
          </Button>
          <Button onClick={this.clearForm} className="rounded-[20px]">
            Clear
          </Button>
        </span>
      </React.Fragment>
    );
  }
}

export default SystemAdminAssignRolesUserForm;
