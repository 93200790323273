import styled from 'styled-components';

import { zIndex } from '@xemplo/zindex';

/**
 * The padding-bottom is needed to compensate for the offset applied on the
 * content wrapper. This is to ensure that the content does not get cut off
 * It's only relevant when the $offset prop is provided. It also needs
 * an extra padding to ensure long content to be visible and has correct
 * padding at the bottom.
 */
export const DrawerBodyWrapper = styled.div<{ $offset?: number }>`
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: ${({ $offset }) => ($offset ? `${$offset + 24}px` : undefined)};
`;

/**
 * The fade is used to indicate that the content is scrollable
 * when the content is overflowing. The variable --drawer-scroll-shadown-bottom
 * is used to control the bottom position of the fade.
 * When the content has a footer, the fade position needs to be adjusted
 * to ensure the fade does not cover the footer.
 *
 *
 * It's a trade-off to the fixed position for both the body content and the fade.
 * See an example of the override on libs/shared/ui/drawer/src/lib/test/test.component.tsx
 *
 * Since it's a css variable, it can be overridden by using JS as well.
 * @default --drawer-scroll-shadown-bottom: 0;
 */
export const DrawerScrollFade = styled.div`
  position: fixed;
  bottom: var(--drawer-scroll-shadown-bottom);
  height: 56px;
  width: 100%;
  background: linear-gradient(360deg, #fff 30%, rgba(255, 255, 255, 0) 100%);
  transition: all 0.2s ease-out;
  z-index: ${zIndex.StickyFooter};

  &.hidden {
    display: none;
  }
`;
