import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Colour, Shadow } from '@xemplo/style-constants';

import { RadioButtonProps, RadioButtonSize } from './radio-button.types';

export const StyledRadioButton = styled.input`
  appearance: none;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 50%;
  transition: 0.1s box-shadow ease-out;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 2px solid ${Colour.Gray[300]};
  box-shadow: 0 0 0 12px transparent;

  &:hover {
    &:not([data-error='true']) {
      border: 2px solid ${Colour.Gray[400]};
      background: ${Colour.Black[5]};
      box-shadow: 0 0 0 12px ${Colour.Black[5]};
    }
    &:checked {
      box-shadow: 0 0 0 12px rgb(0, 96, 215, 0.1);
    }
  }
  &:checked {
    border: 6px solid ${Colour.Blue[500]} !important;
  }

  /** Small variant */
  &[data-size=${RadioButtonSize.Small}] {
    width: 16px;
    height: 16px;
    box-shadow: 0 0 0 8px transparent;
    &:hover {
      &:not([data-error='true']) {
        border: 2px solid ${Colour.Gray[400]};
        background: ${Colour.Black[5]};
        box-shadow: 0 0 0 8px ${Colour.Black[5]};
      }
      &:checked {
        box-shadow: 0 0 0 8px rgb(0, 96, 215, 0.1);
      }
    }
    &:checked {
      border: 4px solid ${Colour.Blue[500]}!important;
    }
  }
  /** Medium variant */
  &[data-size=${RadioButtonSize.Medium}] {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 10px transparent;
    &:hover {
      &:not([data-error='true']) {
        border: 2px solid ${Colour.Gray[400]};
        background: ${Colour.Black[5]};
        box-shadow: 0 0 0 10px ${Colour.Black[5]};
      }
      &:checked {
        box-shadow: 0 0 0 10px rgb(0, 96, 215, 0.1);
      }
    }
    &:checked {
      border: 5px solid ${Colour.Blue[500]}!important;
    }
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  /** Error styling  */
  &[data-error='true'] {
    &:not(:checked) {
      border: 2px solid ${Colour.Red[500]};
      &::before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: ${Shadow.Error};
        top: -2px;
        left: -2px;
      }
      &[data-size=${RadioButtonSize.Small}] {
        &::before {
          width: 16px;
          height: 16px;
        }
      }
      &[data-size=${RadioButtonSize.Medium}] {
        &::before {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

export function RadioButton({
  checked,
  onChange,
  name,
  value,
  disabled,
  id,
  ariaLabel,
  size,
  error,
}: RadioButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checked != null && inputRef.current) {
      inputRef.current.checked = checked;
    }
  }, [checked]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      !disabled && onChange && onChange(event);
    },
    [onChange, disabled]
  );

  return (
    <StyledRadioButton
      ref={inputRef}
      role="radio"
      type="radio"
      id={id ?? name}
      data-size={size ?? RadioButtonSize.Medium}
      data-error={error}
      onChange={handleChange}
      name={name}
      value={value}
      disabled={disabled}
      aria-label={ariaLabel}
    />
  );
}

export default RadioButton;
