//@ts-nocheck
import SwiftContainer from "../../Common/SwiftContainer";
import { connect } from "react-redux";
import {
  listUsers,
  updateHeader,
  updateAuthorizationClaim,
  listUserRoles,
} from "../../User/actions/member";
import axios from "axios";
import {
  listCompanyUsers,
  companyLookup,
  listCompanyBusinessUnits,
  handleCompanyUserActions,
  registerCompanyUsers,
} from "../actions/companies";
import {
  listPSPUsers,
  handlePSPUserActions,
  registerPSPUsers,
} from "../actions/payrollprocessor";
import {
  listPayrollProviders,
  listPayrollProviderBusinessUnit,
} from "../actions/payrollprovider";
import { businessunitHandler } from "../actions/businessunits";
import { lookUpHandler } from "../actions/lookup";
import {
  conditionalParameter,
  findAuthorizationClaims,
  mustBeArray,
} from "../../libs/utilities";
import _ from "lodash";
import { USER_BREAD_CRUMB_CONTAINER } from "../../constants";
import { withRouter } from "../../hooks";

class UserDetailsContainer extends SwiftContainer {
  signal = axios.CancelToken.source();

  componentDidMount() {
    let routerClaims = findAuthorizationClaims({
      claims: conditionalParameter({
        data: this.state.pageClaims,
        field: "components",
      }),
      name: USER_BREAD_CRUMB_CONTAINER,
    });
    let tempRoutes = findAuthorizationClaims({
      claims: conditionalParameter({
        data: this.state.pageClaims,
        field: "components",
      }),
      name: "userBreadCrumb",
    });
    let newRoutes;
    if (window.location.pathname.includes("psp")) {
      newRoutes = _.map(
        mustBeArray(
          conditionalParameter({ data: tempRoutes, field: "routes" })
        ),
        (item) => {
          if (item.title === "Company") {
            item.visible = "false";
          } else if (item.title === "Payroll Service Provider") {
            item.visible = "true";
          }
          return item;
        }
      );
    } else {
      newRoutes = _.map(
        mustBeArray(
          conditionalParameter({ data: tempRoutes, field: "routes" })
        ),
        (item) => {
          if (item.title === "Payroll Service Provider") {
            item.visible = "false";
          } else if (item.title === "Company") {
            item.visible = "true";
          }
          return item;
        }
      );
    }
    let tempRoute = tempRoutes
      ? { ...structuredClone(tempRoutes), routes: [...newRoutes] }
      : null;
    let routeClaims = routerClaims
      ? { ...structuredClone(routerClaims), components: [tempRoute] }
      : null;
    this.props.updateHeader({
      header: {
        routeComponentName: "userBreadCrumb",
        routesContainer: routeClaims,
      },
    });
  }
  render() {
    const { Layout } = this.props;
    const { pageClaims, loading } = this.state;
    return (
      <Layout
        users={this.props.users}
        userType={this.props.userType}
        listPSPUsers={this.props.listPSPUsers}
        listCompanyUsers={this.props.listCompanyUsers}
        userMode={this.props.userMode}
        loading={loading}
        signal={this.signal}
        match={{ params: this.props.router.params }}
        companyUsersCount={this.props.companyUsersCount}
        companyUsersList={this.props.companyUsersList}
        pspUsersList={this.props.pspUsersList}
        pspUsersCount={this.props.pspUsersCount}
        companyLookup={this.props.companyLookup}
        listCompanyBusinessUnits={this.props.listCompanyBusinessUnits}
        handleCompanyUserActions={this.props.handleCompanyUserActions}
        handlePSPUserActions={this.props.handlePSPUserActions}
        companyUserDetails={this.props.companyUserDetails}
        pspUserDetails={this.props.pspUserDetails}
        pageClaims={pageClaims}
        member={this.props.member}
        updateAuthorizationClaim={this.props.updateAuthorizationClaim}
        updateHeader={this.props.updateHeader}
        listUserRoles={this.props.listUserRoles}
        listPayrollProviders={this.props.listPayrollProviders}
        registerPSPUsers={this.props.registerPSPUsers}
        registerCompanyUsers={this.props.registerCompanyUsers}
        listPayrollProviderBusinessUnit={
          this.props.listPayrollProviderBusinessUnit
        }
        businessunitHandler={this.props.businessunitHandler}
        lookUpHandler={this.props.lookUpHandler}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  users: state.member.users || [],
  userMode: "",
  companyUserDetails: state.company.userDetails || {},
  pspUserDetails: state.payrollProvider.userDetails,
  member: structuredClone(state.member),
});

const mapDispatchToProps = {
  listUsers,
  listPSPUsers,
  listPayrollProviders,
  listCompanyUsers,
  companyLookup,
  listCompanyBusinessUnits,
  handleCompanyUserActions,
  handlePSPUserActions,
  updateAuthorizationClaim,
  updateHeader,
  listUserRoles,
  registerPSPUsers,
  registerCompanyUsers,
  listPayrollProviderBusinessUnit,
  businessunitHandler,
  lookUpHandler,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer)
);
