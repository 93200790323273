import { CloseOutlined, EditFilled } from "@ant-design/icons";
import { AvatarGroup } from "@xemplo/avatar";
import { useHttpClient } from "@xemplo/http";
import { Colour } from "@xemplo/style-constants";
import { message, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import SingleFieldDropdownFilter from "../../../Common/ClaimComponents/Filter/SingleFieldDropdownFilter";
import MultiTabContainer from "../../../Common/ClaimContainers/MultiTabContainer";
import TabHeader from "../../../Common/tabHeader";
import { If, Loading } from "../../../Common/ui";
import { DEFAULT_UNASSIGNED_MSG } from "../../../constants/errors";
import { useAppSelector } from "../../../hooks";
import {
  errorHandler,
  formatArrayQueryParams,
  getMatchParams,
  isUserSystemAdmin,
  mustBeArray,
  parseItems,
  removeUndefined,
} from "../../../libs/utilities";
import { BusinessUnitType } from "../../../types";
import { AssignedUserType, AssigneeType } from "../../../types/businessUnit";
import AssignBusinessUnitTable from "../../components/users/assignBusinessUnitTable";
import { AssignBusinessUnitFilters } from "./AssignBusinessUnitFilters";

interface AssignBusinessUnitProps {
  listPSPUsers: (e: any) => Promise<any>;
  updateHeader: (e: any) => void;
  match: any;
  listPayrollProviderBusinessUnit: (e: any) => Promise<any>;
  businessunitHandler: (e: any) => Promise<any>;
  lookUpHandler: (e: any) => Promise<any>;
}

type FilterState = {
  countryIds: string[];
  companyIds: string[];
  name: string;
  assignedUserIds: string[];
};

enum ListType {
  unAssigned = "unAssigned",
  assignedToUser = "assignedToUser",
  assignedToOtherUser = "assignedToOtherUser",
}

const AssignBusinessUnitIndex: React.FC<AssignBusinessUnitProps> = ({
  listPSPUsers,
  updateHeader,
  match,
  listPayrollProviderBusinessUnit,
  businessunitHandler,
  lookUpHandler,
}) => {
  // TODO: The effect should be part of this httpClient hook. Once that's done, the unmount effect can be dumped
  const { cancelSignal } = useHttpClient();
  const member = useAppSelector((state) => state.member);

  //List state
  const [companiesList, setCompaniesList] = useState<Array<any>>([]);
  const [payrollManagersList, setPayrollManagersList] = useState<Array<any>>(
    []
  );
  const [payrollProvidersList, setPayrollProvidersList] = useState<any>([]);
  const [unassignedList, setUnassignedList] = useState<BusinessUnitType[]>([]);
  const [assignedToOtherUserList, setAssignedToOtherUserList] = useState<
    BusinessUnitType[]
  >([]);
  const [assignedToUserList, setAssignedToUserList] = useState<
    BusinessUnitType[]
  >([]);
  //Selected list state
  const [assignedToUserSelectedList, setAssignedToUserSelectedList] = useState<
    string[]
  >([]);
  const [unassignedSelectedList, setUnassignedSelectedList] = useState<
    string[]
  >([]);
  const [assignedToOtherUserSelectedList, setAssignedToOtherUserSelectedList] =
    useState<string[]>([]);

  //Loading state
  const [pageLoading, setPageLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);

  //Other state
  const [selectedPayrollId, setSelectedPayrollId] = useState("");
  const [activeKey, setActiveKey] = useState<string>("1");
  const [filters, setFilters] = useState<FilterState>({
    countryIds: [],
    companyIds: [],
    name: "",
    assignedUserIds: [],
  });
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
  });

  const currentUserId: string = useMemo(
    () => getMatchParams({ match, field: "id" }),
    [match]
  );
  const currentPSPId: string = useMemo(
    () => getMatchParams({ match, field: "pspId" }),
    [match]
  );
  const navigate = useNavigate();

  useEffect(() => {
    updateHeader({
      header: {
        title: "",
        module: "Users",
        enableBack: true,
        action: "",
        returnUrl: "/users",
      },
    });
    setFilters({
      countryIds: [],
      companyIds: [],
      name: "",
      assignedUserIds: [],
    });
    listUserDetail();
    listBusinessUnits({ filtersReset: true });
    listCompanies();
    listPayrollManagers();
    if (isUserSystemAdmin({ member })) {
      setSelectedPayrollId(currentPSPId);
    }
  }, [currentPSPId]);

  // user details
  const listUserDetail = () => {
    listPSPUsers({ id: currentUserId, options: {} }).then((response) => {
      if (response.status && response.data) {
        if (!response.data.enabled || !response.data.activated) {
          message.error(
            "Disabled/Inactive users cannot assigned Business Units."
          );
          return navigate("/users");
        }
        if (isUserSystemAdmin({ member })) {
          const pspList = mustBeArray(response.data.userPersona).map((o) => {
            const tenant = parseItems(o.tenant);
            return {
              name: tenant?.tenantName,
              id: tenant?.tenantId,
            };
          });
          setPayrollProvidersList(pspList);
        }
        setPageLoading(false);
        setUserDetails(response.data);
        setListLoading(false);
        updateHeader({
          header: {
            title: `${response.data.firstName} ${response.data.lastName}`,
            module: "Users",
            enableBack: true,
            action: "",
            returnUrl: "/users",
          },
        });
      }
      setPageLoading(false);
    });
  };

  // Payroll Managers
  const listPayrollManagers = () => {
    listPSPUsers({
      options: {
        per_page: 0,
        q: { ...getFilters(), isEnabled: 1, isActive: 1 },
      },
      serviceProviderId: currentPSPId,
    }).then((resp) => {
      setPayrollManagersList(mustBeArray(resp?.data));
    });
  };

  // Companies lookup
  const listCompanies = () => {
    lookUpHandler({
      options: {},
      cancelToken: cancelSignal.token,
      type: "company",
    }).then((response) => {
      if (response.status) {
        setCompaniesList(mustBeArray(response.data));
      }
    });
  };

  //Fetch the list of business units for selected psp
  const listBusinessUnits = ({ filtersReset }: any) => {
    setListLoading(true);
    listPayrollProviderBusinessUnit({
      options: { per_page: 0, q: filtersReset ? {} : { ...getFilters() } },
      id: currentPSPId,
      cancelToken: cancelSignal.token,
    }).then((response) => {
      setListLoading(false);
      if (response.status) {
        updateBusinessUnitList({ list: mustBeArray(response.data) });
      }
    });
  };

  const getFilters = () => {
    let tempFilters: {
      name?: string;
      countryIds?: string;
      assignedUserIds?: string;
      companyIds?: string;
    } = {};
    tempFilters.name = filters.name.trim();
    tempFilters.countryIds = formatArrayQueryParams({
      data: filters.countryIds,
    });
    tempFilters.assignedUserIds = formatArrayQueryParams({
      data: filters.assignedUserIds,
    });
    tempFilters.companyIds = formatArrayQueryParams({
      data: filters.companyIds,
    });
    tempFilters = { ...removeUndefined(tempFilters) };
    return tempFilters;
  };

  const updateBusinessUnitList = ({ list }: { list: BusinessUnitType[] }) => {
    // Filter out the BU list to separate "assigned to user", "assigned to other user" and "unassigned"
    /** Assigned to current user  */
    const businessUnitAssignedToUser = list.filter((o) => {
      return o.assignedUsers?.some(
        (assignedUser) => assignedUser.id === currentUserId
      );
    });
    setAssignedToUserList(businessUnitAssignedToUser);
    /** Assigned to other users  */
    const businessUnitAssignedToOtherUser = list.filter((o) => {
      if (o.assignedUsers?.length === 0) {
        return false;
      }
      return o.assignedUsers?.every(
        (assignedUser) => assignedUser.id !== currentUserId
      );
    });
    setAssignedToOtherUserList(businessUnitAssignedToOtherUser);
    /** Unassigned / no payroll manager  */
    const unAssignedBusinessUnit = list.filter(
      (o) => o.assignedUsers?.length === 0 || !o.assignedUsers
    );
    setUnassignedList(unAssignedBusinessUnit);
    //Reset selected BUs from lists
    setUnassignedSelectedList([]);
    setAssignedToOtherUserSelectedList([]);
    setAssignedToUserSelectedList([]);
  };

  const selectedByTypeMap = {
    [ListType.assignedToUser]: assignedToUserSelectedList,
    [ListType.unAssigned]: unassignedSelectedList,
    [ListType.assignedToOtherUser]: assignedToOtherUserSelectedList,
  };

  // Unassign a Payroll Manager from a single Business Unit
  const handleUnAssign = ({ record }: { record: BusinessUnitType }) => {
    //Primary payroll manager cannot be unassigned if there are other users assigned to the BU
    const userIsPrimaryPM = record.assignedUsers.some(
      (user) =>
        user.assigneeType === AssigneeType.primary && user.id === currentUserId
    );
    if (
      userIsPrimaryPM &&
      record.assignedUsers.length > 1 &&
      activeKey !== "3"
    ) {
      message.error("Cannot unassign primary payroll manager");
      return;
    }

    //Remove current user from the list of assigned users
    const filtered = record.assignedUsers
      .filter((user) => user.id !== currentUserId)
      .map((user) => ({
        userId: user.id,
        assigneeType: user.assigneeType,
      }));

    businessunitHandler({
      id: record.id,
      action: "assignPayrollManager",
      cancelToken: cancelSignal.token,
      //If unassigning from the third tab, send an empty array to remove all users
      payload: activeKey === "3" ? [] : filtered,
    }).then((response) => {
      handleAssignRequestResponse({
        response,
        successMessage: "User unassigned from business unit successfully.",
      });
    });
  };
  //Assign a Payroll Manager to a single Business Unit
  const handleAssign = ({ record }: { record: BusinessUnitType }) => {
    //Get the business unit's current list of assigned user & create payload with current secondary users and extend with new user
    const assignedSecondaryUsers =
      record.assignedUsers?.filter(
        (user) => user.assigneeType === AssigneeType.secondary
      ) ?? [];
    const payload = [
      ...assignedSecondaryUsers.map((user) => ({
        userId: user.id,
        assigneeType: AssigneeType.secondary,
      })),
      { userId: currentUserId, assigneeType: AssigneeType.primary },
    ];
    businessunitHandler({
      id: record.id,
      action: "assignPayrollManager",
      cancelToken: cancelSignal.token,
      payload,
    }).then((response) => {
      handleAssignRequestResponse({
        response,
        successMessage: "User assigned to business unit successfully.",
      });
    });
  };

  // Unassign ALL Payroll Managers from muiltiple Business Units
  const handleBulkUnAssign = ({ type }: { type: ListType }) => {
    let action = "unassignAllPMs";
    let payload: { businessUnitIds: string[]; userId?: string } = {
      businessUnitIds: selectedByTypeMap[type],
    };
    // If unassigning from the first tab, send the current user's id to unassign only that user
    if (activeKey === "2") {
      payload = { ...payload, userId: currentUserId };
      action = "unassignSinglePM";
    }
    businessunitHandler({
      action,
      cancelToken: cancelSignal.token,
      payload,
    }).then((response) => {
      handleAssignRequestResponse({
        response,
        successMessage: "Users unassigned from business units successfully.",
        action: "unassignAll",
      });
    });
  };
  // Assign a Payroll Manager to multiple Business Units at once
  const handleBulkAssign = ({ type }: { type: ListType }) => {
    // Current implementation only allows primary users to be assigned. Secondary User option coming in future enhancement. If there are existing primary users, then it demotes them to secondary
    const payload = selectedByTypeMap[type].map((id) => ({
      businessUnitId: id,
      primaryAssignedUserId: currentUserId,
      secondaryAssignedUserId: null,
    }));
    businessunitHandler({
      id: currentUserId,
      action: "assignPayrollManagerMultiple",
      cancelToken: cancelSignal.token,
      payload,
    }).then((response) => {
      handleAssignRequestResponse({
        response,
        successMessage: "User assigned to business units successfully.",
      });
    });
  };

  /** Run after every assign/unassign  - if success, refetch BU list*/
  const handleAssignRequestResponse = ({
    response,
    successMessage,
    action = "",
  }: any) => {
    if (response?.status) {
      message.success(successMessage);
      listBusinessUnits({ filtersReset: false });
    } else {
      //Returned validation errors are different for unassign all
      action === "unassignAll"
        ? message.error(
            response?.data?.errors[0]?.validatedColumn ?? DEFAULT_UNASSIGNED_MSG
          )
        : errorHandler({ response, hasValidationErrors: false });
    }
  };

  const commonColumns: Array<unknown> = [
    {
      title: "Business Name",
      dataIndex: "name",
      sorter: false,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      sorter: false,
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: false,
    },
    {
      title: "Pay Schedules",
      dataIndex: "paySchedules",
      sorter: false,
    },
  ];
  const assignedBusinessColumns: Array<unknown> = [
    ...commonColumns,
    {
      title: "Payroll Managers",
      dataIndex: "assignedUser",
      sorter: false,
      width: "20%",
      key: "2",
      render: (data: any, record: BusinessUnitType) => {
        return (
          <AvatarGroup users={record?.assignedUsers} color={Colour.Blue[500]} />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: "20%",
      key: "3",
      render: (data: any, record: any) => (
        <div className="float-right pr-4 flex items-center">
          <If
            condition={
              !record?.assignedUsers?.some(
                (o: AssignedUserType) => o.id === currentUserId
              )
            }
            then={
              <div
                className="flex items-center hand"
                onClick={() => handleAssign({ record })}
              >
                <EditFilled className="color-primary" /> &nbsp;Assign
              </div>
            }
          />
          <div
            className="flex items-center hand ml-4"
            onClick={() => handleUnAssign({ record })}
          >
            <CloseOutlined className="color-danger " />
            &nbsp;Unassign
          </div>
        </div>
      ),
    },
  ];
  const unAssignedBusinessColumns: Array<unknown> = [
    ...commonColumns,
    {
      title: "",
      dataIndex: "action",
      width: "20%",
      key: "3",
      render: (data: any, record: any) => (
        <div
          className="float-right pr-4 flex items-center hand"
          onClick={() => handleAssign({ record })}
        >
          <EditFilled className="color-primary" /> &nbsp;Assign
        </div>
      ),
    },
  ];

  const handlePayrollProviderChange = (value: string) => {
    navigate(`/psp/${value}/user/${currentUserId}/assignBusinessunits`);
  };

  const getTabActions = () => {
    switch (Number(activeKey)) {
      case 1:
        return unassignedSelectedList.length > 1 ? (
          <SimpleButton
            ignoreStatus={true}
            buttonText={`Assign to ${userDetails?.firstName} ${userDetails?.lastName}`}
            type="primary"
            onClick={() => handleBulkAssign({ type: ListType.unAssigned })}
          />
        ) : (
          <React.Fragment />
        );
      case 2:
        return assignedToUserSelectedList.length > 1 ? (
          <SimpleButton
            ignoreStatus={true}
            buttonText={`Unassign Businesses`}
            className="bg-danger float-right"
            onClick={() =>
              handleBulkUnAssign({
                type: ListType.assignedToUser,
              })
            }
          />
        ) : (
          <React.Fragment />
        );
      case 3:
        return assignedToOtherUserSelectedList.length > 1 ? (
          <>
            <SimpleButton
              ignoreStatus={true}
              buttonText={`Assign to ${userDetails?.firstName} ${userDetails?.lastName}`}
              type="primary"
              className=""
              onClick={() =>
                handleBulkAssign({
                  type: ListType.assignedToOtherUser,
                })
              }
            />
            <SimpleButton
              ignoreStatus={true}
              buttonText={`Unassign Businesses`}
              className="bg-danger ml-4"
              onClick={() =>
                handleBulkUnAssign({
                  type: ListType.assignedToOtherUser,
                })
              }
            />
          </>
        ) : (
          <React.Fragment />
        );
      default:
        return <React.Fragment />;
    }
  };

  /** On unmount should abort any api request in progress */
  useEffect(() => {
    return () => {
      cancelSignal.cancel(
        "AssignBusinessUnit unmounted: Cancelling any pending API requests"
      );
    };
  }, []);

  return pageLoading ? (
    <Loading />
  ) : (
    <div className="custom-tabs">
      <div className="flex items-center mb-6">
        {isUserSystemAdmin({ member }) &&
          mustBeArray(payrollProvidersList).length > 1 && (
            <SingleFieldDropdownFilter
              options={payrollProvidersList}
              value={selectedPayrollId}
              defaultValue={selectedPayrollId}
              onSelect={(value) => handlePayrollProviderChange(value)}
              className="w-[250px] mr-4"
              hasNoClaims={true}
              valueParam="id"
              nameParam="name"
              placeholder="Select Payroll Serivce Provider"
            />
          )}
        <AssignBusinessUnitFilters
          listLoading={listLoading}
          filters={filters}
          companiesList={companiesList}
          payrollManagersList={payrollManagersList}
          listBusinessUnits={listBusinessUnits}
          setFilters={setFilters}
        />
      </div>
      <MultiTabContainer
        handleTabChange={(e: string) => setActiveKey(e)}
        activeKey={activeKey}
        ignoreClaims={true}
        extraContent={{
          right: getTabActions(),
        }}
      >
        <Tabs.TabPane
          key={"2"}
          tab={
            <TabHeader
              title={`Current Businesses`}
              total={assignedToUserList.length}
            />
          }
        >
          <AssignBusinessUnitTable
            list={assignedToUserList}
            loading={listLoading}
            handleTableChange={({ selectedRowKeys }) =>
              setAssignedToUserSelectedList(selectedRowKeys)
            }
            columns={assignedBusinessColumns}
            selectedRows={assignedToUserSelectedList}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={"1"}
          tab={
            <TabHeader
              title="Unassigned Businesses"
              total={unassignedList.length}
            />
          }
        >
          <AssignBusinessUnitTable
            list={unassignedList}
            loading={listLoading}
            handleTableChange={({ selectedRowKeys }) =>
              setUnassignedSelectedList(selectedRowKeys)
            }
            columns={unAssignedBusinessColumns}
            selectedRows={unassignedSelectedList}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={"3"}
          tab={
            <TabHeader
              title="Assigned to others"
              total={assignedToOtherUserList.length}
            />
          }
        >
          <AssignBusinessUnitTable
            list={assignedToOtherUserList}
            loading={listLoading}
            handleTableChange={({ selectedRowKeys }) =>
              setAssignedToOtherUserSelectedList(selectedRowKeys)
            }
            columns={assignedBusinessColumns}
            selectedRows={assignedToOtherUserSelectedList}
          />
        </Tabs.TabPane>
      </MultiTabContainer>
    </div>
  );
};
export default AssignBusinessUnitIndex;
