import { RoleCode } from '@xemplo/types';

import { AuthorizationDOM } from '../authorization';
import { DashboardItem } from '../dashboard';

/** All TODO's refers to: https://expedo.atlassian.net/browse/GP-2203  */

/** TODO: Replace this with types that comes from Directory Services */
export interface BaseUser {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  mobile?: string;
  profilePhoto?: string;
  selectedRole?: UserPersona;
  area?: string;
  directoryServiceUserId?: string | null;
}

export interface BaseUserExtended extends BaseUser {
  emailAddress: string;
  mobilePhone: string;
  addressId: number;
  address: string;
  enabled: boolean;
  activated: boolean;
  getNotifications: boolean;
  deleteReason: string;
  mfaEnable: boolean;
  deviceEnrolmentEnable: number;
  lastLogout: Date;
  phone: string;
  userPersona: UserPersona[];
}

export type ExpedoUserProfile = BaseUserExtended & {
  userPersona?: UserPersona[];
  dashboards: DashboardItem[];
  authorizationDOM?: AuthorizationDOM[];
  relatedBusinesses?: number;
  relatedCompanies?: number;
  payScheduleCount?: number;
  addedByUser: AddedByUser;
  userRoles?: UserRoles; // Hopefully we can dump this, leaving for compatibility
};

export type AddedByUser = {
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  id: string;
};

/** TODO: Replace this with types that comes from Directory Services */
export type UserRoles = {
  [key: string]: UserPersona[];
};

/** TODO: Replace this with types that comes from Directory Services */
export type UserRole = {
  id: string;
  name: RoleCode;
};

/** TODO: Replace this with types that comes from Directory Services */
export type Tenant = {
  type: string;
  id: string;
  name: string;
};

/** TODO: Replace this with types that comes from Directory Services */
export type UserPersona = {
  area: string;
  default: boolean;
  id: string;
  personalization: boolean;
  role: UserRole;
  selected: boolean;
  isSelected?: boolean;
  tenant: Tenant;
  directoryServicePersonaId?: string | null;
  roleName?: string;
  roleId?: string;
};

export type PageHeader = {
  action?: boolean;
  enableBack?: boolean;
  entity?: string;
  gaTitle?: string;
  module?: string;
  title?: string;
  returnUrl?: string;
};

/** TODO: Replace this with types that comes from Directory Services */
export enum PersonaRole {
  PayrollManager = 'PayrollManager',
  CompanyEmployee = 'CompanyEmployee',
  CompanyAdmin = 'CompanyAdmin',
  CompanyAccountant = 'CompanyAccountant',
  PayrollSupervisor = 'PayrollSupervisor',
  PSPAdmin = 'PSPAdmin',
  SystemAdmin = 'SystemAdmin',
}

export interface RolesLookup {
  id: string;
  name: string;
  normalizedName: string;
  description: string;
  referenceId: string;
}

export enum RoleArea {
  SystemAdminArea = 'api/sa',
  PSPArea = 'api/pspa',
  CompanyArea = 'api/cu',
}

// Activate User Profile types //
export type ActivatedUser = Omit<
  BaseUser,
  'userPersona' | 'dashboards' | 'authorizationDOM' | 'userRoles'
> & {
  userTypeId: number;
  getNotifications: boolean;
  activated: boolean;
  enabled: boolean;
  emailAddress: string;
  addressId: number;
  mobilePhone?: string;
};
