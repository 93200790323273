import HeaderDataView from "./headerDataView";
import { DEFAULT_PAY_CYCLE_END_DATE } from "../../../../constants/payrun";
import {
  onlyDateFormater,
  displayDates,
  isNullEmptyUndefined,
} from "../../../../libs";
import PayrunViewOptions from "../../payrun/viewOptions";

const PayrunNavbarOptions = (props: any) => {
  const { payrun } = props;
  const getPayCycleDate = ({ data }: any) => {
    if (isNullEmptyUndefined(data)) return "";
    return `${onlyDateFormater(data.payCycleStartDate)} ${
      data.payCycleEndDate !== DEFAULT_PAY_CYCLE_END_DATE
        ? `- ${onlyDateFormater(data.payCycleEndDate)}`
        : ""
    }`;
  };

  const payCycleDate = getPayCycleDate({ data: payrun });
  const payDate = displayDates({ value: payrun?.payDate });

  return (
    <div className="flex justify-between items-end">
      <HeaderDataView title="Pay Cycle" value={payCycleDate} />
      <HeaderDataView title="Pay Date" value={payDate} />

      <PayrunViewOptions payrun={payrun} />
    </div>
  );
};

export default PayrunNavbarOptions;
