import { RouteObject } from 'react-router';

import { requireAuth } from '@xemplo/auth-provider';
import { ErrorBoundary } from '@xemplo/error-boundary';
import { AuthorisationRoutes as Routes } from '@xemplo/gp-routes';
import { withLazyComponent } from '@xemplo/lazy-component';
import { NoAccessPage } from '@xemplo/no-access-page';

export const AuthorisationRoutes: RouteObject[] = [
  {
    path: Routes.NoAccess,
    Component: requireAuth(withLazyComponent(NoAccessPage)),
    errorElement: <ErrorBoundary />,
  },
];
