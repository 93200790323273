import React from 'react';

import { DEFAULT_DATE_TIME_FORMAT, formatDate } from '@xemplo/date-time';
import { RejectionReason } from '@xemplo/gp-types';

import * as S from '../../amendment-details-modal-styles';

interface RejectionInfoProps {
  rejectionReason: RejectionReason | null | undefined;
}

export function RejectionInfo(props: RejectionInfoProps) {
  if (!props.rejectionReason) {
    return null;
  }

  return (
    <>
      <S.Divider />
      <S.DLRow>
        <S.DT>Rejected by</S.DT>
        <S.DD> {props.rejectionReason.User.UserName}</S.DD>
        <S.DT>Date</S.DT>
        <S.DD>
          {' '}
          {formatDate({
            value: props.rejectionReason.User.Date,
            format: DEFAULT_DATE_TIME_FORMAT,
          })}
        </S.DD>
      </S.DLRow>
    </>
  );
}
