import { useSelector } from "react-redux";
import GeneralTable from "../../../../Common/ClaimComponents/TableGrid/GeneralTable";

type Props = {
  claim: any;
  name?: string;
  hasNoTitle?: boolean;
  requiresCard?: boolean;
  dashboardView?: boolean;
  showTitle?: boolean;
  title?: string;
  match?: any;
  rowKeyClassName?: string;
  data: any;
  handleTableChange?: (e: any) => void;
  GeneralTablePaginatedComponent: any;
  displayActionIcons?: boolean;
  listLoading: boolean;
  actionComponentName?: string;
  total: number;
  table: any;
  isDraft?: boolean;
  rowIdParam?: string;
  handleAction: any;
};
const TableComponent = ({
  claim,
  name,
  hasNoTitle,
  requiresCard,
  dashboardView,
  showTitle,
  title,
  match,
  rowKeyClassName,
  data,
  GeneralTablePaginatedComponent,
  displayActionIcons,
  listLoading,
  handleTableChange,
  actionComponentName,
  total,
  table,
  isDraft,
  rowIdParam,
  handleAction,
}: Props) => {
  const member = useSelector(({ member }: any) => member);
  return (
    <GeneralTable
      name={name}
      claims={claim}
      hasNoTitle={hasNoTitle}
      requiresCard={requiresCard}
      ignoreStatus={dashboardView}
      showTitle={showTitle}
      title={title}
      match={match}
    >
      <GeneralTablePaginatedComponent
        data={data}
        handleTableChange={handleTableChange}
        loading={listLoading}
        total={total}
        current={table?.page}
        per_page={table?.perPage}
        component={actionComponentName}
        displayActionIcons={displayActionIcons}
        rowKeyClassName={rowKeyClassName}
        ignoreStatus={dashboardView}
        dashboardView={dashboardView}
        isDraft={isDraft}
        member={member}
        rowIdParam={rowIdParam}
        claims={claim}
        table={table}
        handleAction={handleAction}
      />
    </GeneralTable>
  );
};

export default TableComponent;
