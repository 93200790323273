//@ts-nocheck
import React from "react";
import { getClaimAndStatus, getConditionalResponse } from "../libs/utilities";
import { VISIBLE_CLAIMS_ID } from "../constants/defaultClaims";

class TileContainer extends React.Component {
  renderComponent = ({ claim, ignoreStatus }: any) => {
    if (
      (claim &&
        VISIBLE_CLAIMS_ID.includes(Number(claim.authorizationStatusId))) ||
      ignoreStatus
    )
      return true;
    return false;
  };

  render() {
    const {
      hasNoComponents,
      claims,
      name,
      className,
      ignoreStatus,
      resizeable,
      children,
    } = this.props;

    const claimAndStatus = getClaimAndStatus({
      claims,
      componentName: name,
      hasComponents: !hasNoComponents,
    });
    const claim = claimAndStatus.claim;
    const childrenWithProps = children ? (
      React.Children.map(children, (child) =>
        React.cloneElement(child, { ...this.props, claims: claim })
      )
    ) : (
      <React.Fragment />
    );
    let status = claimAndStatus.status;

    return (
      <div
        className={`${className}
            ${getConditionalResponse({
              condition: ignoreStatus,
              resp1: "",
              resp2: status,
            })}
            tile-container ${getConditionalResponse({
              condition: resizeable,
              resp1: "tile-container-resizeable",
              resp2: "tile-container-hidden",
            })}`}
      >
        {this.renderComponent({ claim, ignoreStatus }) && childrenWithProps}
      </div>
    );
  }
}

export default TileContainer;
