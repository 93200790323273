//@ts-nocheck
import React from "react";
import { tableColumnGenerator } from "../../../libs/table";
import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import _ from "lodash";
import { Popconfirm } from "antd";

class ConflictList extends React.Component {
  render() {
    const pagination = {
      total: this.props.totalPages,
      current: this.props.current,
      pageSize: this.props.per_page ? this.props.per_page : 20,
      hideOnSinglePage: true,
    };
    const { type } = this.props;
    const colTemplate = [
      {
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        sorter: false,
      },
      {
        title: "Submission date",
        dataIndex: "submissionDate",
        dataType: "date",
        sorter: false,
      },
      {
        title: "Account code",
        dataIndex: "accountCode",
        sorter: false,
      },
      {
        title: "Account name",
        dataIndex: "accountName",
        sorter: false,
      },
      {
        title: "Account type",
        dataIndex: "accountType",
        sorter: false,
      },
    ];
    const column = tableColumnGenerator(
      type === "resolved"
        ? colTemplate
        : _.concat(colTemplate, [
            {
              title: "Action",
              dataIndex: "",
              sorter: false,
              render: (record: any) => {
                return (
                  <React.Fragment>
                    <Popconfirm
                      placement="bottomLeft"
                      title={"Are you sure this conflict has been resolved?"}
                      onConfirm={() =>
                        this.props.handleResolveConflict({ conflict: record })
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <span className="color-primary hand">Resolve</span>
                    </Popconfirm>
                  </React.Fragment>
                );
              },
            },
          ])
    );
    return (
      <SimpleTableContainer
        columns={column}
        dataSource={this.props.data}
        pagination={pagination}
        loading={this.props.loading}
        onChange={() => {
          console.log("");
        }}
      />
    );
  }
}

export default ConflictList;
