const PayrollManager = "PayrollManager";
const CompanyEmployee = "CompanyEmployee";
const CompanyAdmin = "CompanyAdmin";
const CompanyAccountant = "CompanyAccountant";
const PayrollSupervisor = "PayrollSupervisor";
const PSPAdmin = "PSPAdmin";
const SystemAdmin = "SystemAdmin";
const SystemAdminArea = "api/sa";
const PSPArea = "api/pspa";
const CompanyArea = "api/cu";

const userRoles = {
  PayrollManager,
  CompanyEmployee,
  CompanyAdmin,
  CompanyAccountant,
  PayrollSupervisor,
  PSPAdmin,
  SystemAdmin,
  SystemAdminArea,
  PSPArea,
  CompanyArea,
};

export default userRoles;
