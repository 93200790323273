import { useState } from 'react';
import { DatePickerState } from '@react-stately/datepicker';

export const useTextDatePicker = (state: DatePickerState) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  const handleCalendarClick = (e: React.MouseEvent) => {
    state.toggle();
  };

  const handleOnBlur = () => {
    setInputFocused(false);
  };

  const handleKeyboardEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      (document.activeElement as HTMLElement)?.blur();
      handleOnBlur();
    }
  };

  const handleContainerFocus = () => {
    setInputFocused(true);
  };

  return {
    inputFocused,
    handleCalendarClick,
    handleOnBlur,
    handleKeyboardEnter,
    handleContainerFocus,
  };
};
