import styled from 'styled-components';

import {
  containerBase,
  containerDisabled,
  containerError,
  containerFocus,
  containerHasLabelPadding,
  containerReadonly,
  descriptionBase,
  errorMessageBase,
  hiddenLabelBase,
  inputBase,
  inputDisabled,
  InputFieldSize,
  inputReadonly,
  inputWithLabel,
  labelInputHasFocus,
  labelInputHasValue,
  labelVisible,
  wrapperBase,
} from '@xemplo/input-utils';

export const Wrapper = styled.div<{ $width?: string }>`
  ${wrapperBase};
`;

export const Container = styled.div<{ $inputSize: InputFieldSize }>`
  ${containerBase};
  ${containerFocus};
  ${containerDisabled};
  ${containerError};
  ${containerHasLabelPadding};
  ${containerReadonly};
  /** Custom Text Area Styling */
  align-items: flex-start;
  height: auto;

  &.has-label {
    & > svg {
      margin-top: 8px;
    }
  }
`;

export const TextArea = styled.textarea`
  ${inputBase};
  ${inputDisabled};
  ${inputReadonly};
  ${inputWithLabel};
  ${labelInputHasFocus};
  /** Custom Text Area Styling */
  resize: none;
  &.has-label {
    margin-top: 19px;
  }
  &:focus + label {
    top: 12px;
  }
`;

export const Label = styled.label`
  ${hiddenLabelBase};
  ${labelVisible};
  ${labelInputHasValue};
  /** Custom Text Area Styling */
  &.has-value {
    top: 12px;
  }
`;

export const ErrorMessage = styled.div`
  ${errorMessageBase};
`;

export const Description = styled.div`
  ${descriptionBase};
`;
