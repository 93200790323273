import { SVGProps } from 'react';
const DawnDownload16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.417 9.303 4.968 6.855a.75.75 0 0 0-1.06 1.06l3.697 3.698c.141.14.332.22.53.22h.016a.75.75 0 0 0 .658-.362l3.554-3.556a.75.75 0 1 0-1.06-1.06L8.916 9.24V1.84a.75.75 0 1 0-1.5 0v7.464Zm-5.581.413a.75.75 0 0 1 .75.75v1.698c0 .69.56 1.25 1.25 1.25h8.325c.69 0 1.25-.56 1.25-1.25v-1.698a.75.75 0 1 1 1.5 0v1.698a2.75 2.75 0 0 1-2.75 2.75H3.836a2.75 2.75 0 0 1-2.75-2.75v-1.698a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnDownload16;
