export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss A';
export const DEFAULT_DATE_TIME_FORMAT_LOWER_CASE = 'DD/MM/YYYY HH:mm:ss a';
export const DEFAULT_DATE_TIME_FORMAT_SHORT = 'D/M/YYYY HH:mm:ss A';
export const STEPPER_DATE_FORMAT = 'D/M/YY · h:mm a';
export const DATE_TIME_FORMAT_AM_PM = 'D/M/YY h:mma';
export const HEADER_DATE_FORMAT = 'D MMM, YYYY';
export const WIDGET_DATE_FORMAT = 'D MMM YYYY';
export const LOGIN_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const LOGIN_DATE_FORMAT2 = 'DD/MM/YYYY HH:mma';

export const DATE_MONTH_SHORT = 'DD MMM';
export const DATE_MONTH_YEAR_SHORT = 'DD MMM YYYY';
export const DAY_DATE_SHORT = 'ddd, D MMM';

export const DateFormat = {
  Default: DEFAULT_DATE_FORMAT,
  DateTime: DEFAULT_DATE_TIME_FORMAT,
  DateTimeLower: DEFAULT_DATE_TIME_FORMAT_LOWER_CASE,
  DateTimeShort: DEFAULT_DATE_TIME_FORMAT_SHORT,
  Stepper: STEPPER_DATE_FORMAT,
  AmPm: DATE_TIME_FORMAT_AM_PM,
  Header: HEADER_DATE_FORMAT,
  Widget: WIDGET_DATE_FORMAT,
  Login: LOGIN_DATE_FORMAT,
  Login2: LOGIN_DATE_FORMAT2,
  DateMonthShort: DATE_MONTH_SHORT,
  DateMonthYearShort: DATE_MONTH_YEAR_SHORT,
} as const;
