import React from "react";
import { Tooltip } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import {
  getPinnableComponentTitle,
  getPinnedClassName,
} from "../../../libs/claimsValidations";

interface PinTooltipProps {
  className?: string;
  onClick: () => void;
  isPinned: boolean;
}

const classNameDefault = "";

const PinTooltip = ({
  className = classNameDefault,
  onClick,
  isPinned,
}: PinTooltipProps) => (
  <div className={`flex justify-end items-center ${className}`}>
    <div
      className={getPinnedClassName({
        isPinned,
      })}
      onClick={onClick}
    >
      <Tooltip
        placement="bottomLeft"
        title={getPinnableComponentTitle({ isPinned })}
      >
        <PushpinOutlined className="text-lg hand" />
      </Tooltip>
    </div>
  </div>
);

export default PinTooltip;
