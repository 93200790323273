import { TextArea } from '@xemplo/text-area';

import * as S from '../payrun-approve-reject.styles';
import { FieldProps } from '../payrun-approve-reject.types';

export const RejectDescription = (props: FieldProps) => {
  const { shouldValidate, setPayload } = props;

  const handleChange = (e: string) => {
    setPayload((prev) => ({
      ...prev,
      description: e,
    }));
  };
  return (
    <S.FormLabel>
      Description
      <TextArea
        formSubmitting={shouldValidate}
        isRequired
        placeholder="Please enter reason"
        aria-label="rejection reason"
        onChange={(e) => handleChange(e as string)}
        stretch
      />
    </S.FormLabel>
  );
};
