import { DawnTick16, DawnTick24 } from '@xemplo/icons';

import { CheckboxSize } from './checkbox.types';

export const checkmarkSizeMap = {
  [CheckboxSize.Large]: <DawnTick24 fontSize="1.5em" />,
  [CheckboxSize.Medium]: <DawnTick16 fontSize="1.25em" />,
  [CheckboxSize.Small]: <DawnTick16 />,
};

export const heightAndWidthMap = {
  [CheckboxSize.Large]: '24px',
  [CheckboxSize.Medium]: '20px',
  [CheckboxSize.Small]: '16px',
};
export const boxShadowMap = {
  [CheckboxSize.Large]: '12px',
  [CheckboxSize.Medium]: '10px',
  [CheckboxSize.Small]: '8px',
};
