import _ from "lodash";
import React, { Key, useMemo } from "react";
import { If } from "../../../../Common/ui";
import {
  currencyFormater,
  getDifferenceAndVariance,
  getVarianceRowClassName,
  isEmpty,
  mustBeArray,
} from "../../../../libs";

type Props = {
  currentPayrunSummary: any;
  itemKey: Key;
  highLightVarianceValue: number;
  columnClassName?: string;
  showVariance: boolean;
  invoice: any;
  businessunitDetails: any;
  expandedRows: Array<any>;
  previousPayrunSummary: any;
  netWageVariance: any;
};
const EarningLinesSubPayItems = ({
  currentPayrunSummary,
  highLightVarianceValue,
  columnClassName,
  showVariance,
  invoice,
  businessunitDetails,
  previousPayrunSummary,
}: Props) => {
  // Group by id current and previous pay items to combine similar ones
  const currentGroupedEarninglines = useMemo(() => {
    return _.groupBy(
      mustBeArray(currentPayrunSummary?.payrollProcessing?.earningsLine),
      (o) => o.id
    );
  }, [currentPayrunSummary?.payrollProcessing?.earningsLine]);
  const previousGroupedEarninglines = useMemo(() => {
    return _.groupBy(
      mustBeArray(previousPayrunSummary?.payrollProcessing?.earningsLine),
      (o) => o.id
    );
  }, [previousPayrunSummary?.payrollProcessing?.earningsLine]);
  return (
    <React.Fragment>
      {/* Earning lines */}
      {Object.keys(currentGroupedEarninglines).map((data: any, i: any) => {
        // get total for current cycle
        const currentTotalAmount = _.sumBy(
          mustBeArray(currentGroupedEarninglines[data]),
          (o) => o.amount
        );
        // Total for previous cycle
        const previousTotalAmount = _.sumBy(
          mustBeArray(previousGroupedEarninglines[data]),
          (o) => o.amount
        );
        const variance = getDifferenceAndVariance(
          previousTotalAmount,
          currentTotalAmount
        ).variance;

        const name =
          currentGroupedEarninglines[data]?.[0]?.name ||
          previousGroupedEarninglines[data]?.[0]?.name;
        return (
          <If
            key={i}
            condition={name}
            then={
              <div
                key={`${i}b`}
                className={`process-table ${getVarianceRowClassName({
                  data: variance,
                  highLightVarianceValue,
                })}`}
              >
                <div
                  className={`${columnClassName}-processing-description pl-[60px]`}
                >
                  {name}
                </div>
                <If
                  condition={showVariance && !isEmpty(invoice)}
                  then={
                    <div
                      className={`${columnClassName}-processing-previousTaxes text-right`}
                    ></div>
                  }
                />
                <If
                  condition={showVariance}
                  then={
                    <div
                      className={`${columnClassName}-processing-previousAmount text-right`}
                    >
                      {currencyFormater(
                        previousTotalAmount,
                        businessunitDetails?.country
                      )}
                    </div>
                  }
                />
                <If
                  condition={!isEmpty(invoice)}
                  then={
                    <div
                      className={`${columnClassName}-processing-taxes text-right`}
                    ></div>
                  }
                />
                <div
                  className={`${columnClassName}-processing-amount text-right`}
                >
                  {currencyFormater(
                    currentTotalAmount,
                    businessunitDetails?.country
                  )}
                </div>
                <If
                  condition={showVariance}
                  then={
                    <div
                      className={`${columnClassName}-processing-variance`}
                    ></div>
                  }
                />
              </div>
            }
          />
        );
      })}
    </React.Fragment>
  );
};
export default EarningLinesSubPayItems;
