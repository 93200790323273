import { SVGProps } from 'react';
const DawnChartPath = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.695 3.716a.75.75 0 1 0-1.053 1.068l2.206 2.173h-5.19c-1.125 0-2.208.44-3.007 1.228a4.18 4.18 0 0 0-1.252 2.979v4.435H2.762a.75.75 0 1 0 0 1.5h16.595l-2.205 2.173a.75.75 0 1 0 1.053 1.068l3.51-3.457a.747.747 0 0 0 0-1.069l-3.51-3.456a.75.75 0 1 0-1.053 1.069l2.206 2.172H7.898v-4.435c0-.714.289-1.402.805-1.91a2.787 2.787 0 0 1 1.956-.797h5.189l-2.206 2.172a.75.75 0 1 0 1.053 1.07l3.51-3.458a.76.76 0 0 0 .219-.451.76.76 0 0 0-.123-.5.753.753 0 0 0-.096-.117l-3.51-3.457Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnChartPath;
