// @ts-nocheck
import ErrorMessage from "../constants/errors";
import _ from "lodash";

class ResponseHandler {
  _data: any;
  constructor() {
    if (!ResponseHandler.instance) {
      this._data = [];
      ResponseHandler.instance = this;
    }

    return ResponseHandler.instance;
  }

  isValidStatus({ status }: any) {
    switch (status) {
      case 400:
      case 401:
      case 402:
      case 403:
      case 404:
      case 405:
      case 406:
      case 407:
      case 408:
        return false;
      default:
        return true;
    }
  }

  validObject(response: any, actionType: any) {
    return {
      type: actionType,
      total: response?.total ?? 0,
      data: _.isEmpty(response)
        ? null
        : response.hasOwnProperty("rows")
        ? response.rows
        : response,
      status: true,
    };
  }

  inValidObject({ status, data }: any) {
    return {
      message: ErrorMessage[status.toString()],
      data: data,
      status: false,
    };
  }
}

const instance = new ResponseHandler();
Object.freeze(instance);

export default instance;
