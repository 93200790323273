//@ts-nocheck
import React, { Component } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Table, Tooltip, Avatar } from "antd";
import { mustBeArray } from "../../../libs/utilities";

class AssignedRolesList extends Component {
  render() {
    const { data, type, loading, total, per_page } = this.props;
    const colTemplate = [
      {
        title: type === "psp" ? "Payroll Provider" : "Company",
        dataIndex: type === "psp" ? "payrollProviderName" : "companyName",
        key: "1",
      },
      { title: "Role", dataIndex: "roleName", key: "2" },
      {
        title: "Action",
        dataIndex: "",
        key: "3",
        render: (record: any) => {
          return (
            <span
              className={`hand`}
              onClick={() => this.props.handleDelete({ record })}
            >
              <Tooltip title="Delete" placement="bottom">
                <Avatar size="small" icon={<CloseOutlined />} />
              </Tooltip>
            </span>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <Table
          columns={colTemplate}
          rowKey={(record, index) => index}
          dataSource={mustBeArray(data)}
          bordered={false}
          pagination={{
            total,
            pageSize: per_page ? per_page : 10,
            hideOnSinglePage: true,
          }}
          loading={loading}
        />
      </React.Fragment>
    );
  }
}

export default AssignedRolesList;
