import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { If, RequiredSign } from "../../../Common/ui";
import { conditionalParameter } from "../../../libs";
import { Question, Questions } from "../../../types";
import { cdnLookUpHandler } from "../../actions/lookup";
import InputTypes from "../../components/payrun/inputTypes";

type Props = {
  questions: Questions;
  onChangeField: (field: string, value: any, isDefault?: boolean) => void;
  addQuestion: (field: string) => void;
  removeQuestion: (field: string, index: number) => void;
};

const QuestionRows = ({
  questions,
  onChangeField,
  addQuestion,
  removeQuestion,
}: Props) => {
  const [holidays, setHolidays] = useState<any>({});
  const signal = axios.CancelToken.source();

  useEffect(() => {
    void (async () => {
      const result = await cdnLookUpHandler(
        { type: "holidaysPeriod" },
        signal.token
      );
      setHolidays(conditionalParameter({ data: result, field: "data" }));
    })();

    return () => {
      signal.cancel("QuestionRows unmounted: Cancelling any pending API calls");
    };
  }, []);

  const handleChange = (field: string, value: any) => {
    onChangeField(field, value);
  };

  const makeRows = (qs: any, parentName?: string) => {
    // Loop through the questions and create rows
    return qs.map((question: Question, index: number) => {
      return (
        <div
          key={index}
          className={classNames(
            {
              "lg:col-start-1": index % 2 !== 0,
              "lg:col-start-2": index !== 0 && index % 2 === 0,
              "lg:col-span-1": question?.type !== "Date",
              "lg:col-start-1 lg:col-end-2": question?.type === "Date", // Date is a special case, just one per row
            },
            "mb-2"
          )}
        >
          {question?.type === "Sub" ? (
            <>
              <label className="h-8 flex items-center">
                {question.required && (
                  <React.Fragment>
                    <RequiredSign className="mr-1" />{" "}
                  </React.Fragment>
                )}
                <span className="font-semibold">{question?.label}</span>
              </label>
              {/* If the question type is "Sub" we loop through its questions*/}
              {makeRows(question.questions, question.name)}
            </>
          ) : (
            <>
              <If
                condition={question?.type !== "YesNoTimePeriod"}
                then={
                  <label className="h-8 flex items-center">
                    {question.required && (
                      <React.Fragment>
                        <RequiredSign className="mr-1" />{" "}
                      </React.Fragment>
                    )}
                    <span className="font-semibold">{question?.label}</span>
                    {/* display info icon for the questions that have guides */}
                    <If
                      condition={!!question.guide}
                      then={
                        <React.Fragment>
                          <InfoCircleOutlined className="text-xs ml-2" />
                          <span className="text-xs font-light ml-1">
                            {question.guide}
                          </span>
                        </React.Fragment>
                      }
                    />
                  </label>
                }
              />
              <InputTypes
                addQuestion={addQuestion}
                removeQuestion={removeQuestion}
                question={question}
                holidays={holidays}
                inputValueChange={(value: any) => {
                  onChangeField(
                    parentName ?? question.name,
                    parentName
                      ? { type: question.type, value, name: question.name }
                      : value
                  );
                }}
                dropDownWithTextChange={handleChange}
                // Handle change for radio button
                handleDefaultChange={(value: any) => {
                  onChangeField(
                    parentName ?? question.name,
                    parentName
                      ? { type: question.type, value, name: question.name }
                      : value,
                    true
                  );
                }}
              />
            </>
          )}
        </div>
      );
    });
  };

  return makeRows(questions);
};

export default QuestionRows;
