import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarItem, SidebarProps } from './sidebar.types';

export function useSidebar(props: SidebarProps) {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isResponsiveExpanded, setIsResponsiveExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState<SidebarItem | null>(null);
  const [items, setItems] = useState(props.items);
  const location = useLocation();

  const sidebarItemProps = useMemo(() => {
    return {
      items,
      isSidebarExpanded,
      setIsSidebarExpanded,
      isResponsiveExpanded,
      setIsResponsiveExpanded,
    };
  }, [isResponsiveExpanded, isSidebarExpanded, items]);

  useEffect(() => {
    // Append internal handlers to each item
    setItems(
      props.items.map((item) => ({
        ...item,
        callback: () => {
          item.callback?.(item);
          setIsResponsiveExpanded(false);
          setActiveItem(item);
        },
      }))
    );
  }, [props.items]);

  useEffect(() => {
    // Find active item based on the current URL
    const activeItem = items.find((item) => {
      if (!item.route) return false;
      return location.pathname.includes(item.route);
    });

    if (activeItem) setActiveItem(activeItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    activeItem,
    sidebarItemProps,
    ...sidebarItemProps,
  };
}
