import SimpleTableContainer from "../../../Common/ClaimContainers/SimpleTableContainer";
import {
  displayAppliedToPayroll,
  displayApproveAmendment,
  displayDeleteAmendment,
  displayRejectAmendment,
  getGeneralAmendmentColumns,
  getSortOrderRequestValue,
  tableColumnGenerator,
} from "../../../libs";
import TableActionComponent from "../common/tableActions";
import {
  GENERAL_AMENDMENT_PENDING_DETAIL_VIEW,
  GENERAL_AMENDMENT_PENDING_REJECT,
  GENERAL_AMENDMENT_PENDING_APPROVE,
  GENERAL_AMENDMENT_PENDING_APPLY_IN_KEYPAY,
  GENERAL_AMENDMENT_PENDING_DELETE,
} from "../../../constants";
import React from "react";

type Props = {
  handleTableChange: (e: any) => void;
  table?: any;
  data?: Array<any>;
  loading?: boolean;
  rowIdParam?: string;
  dashboardView?: boolean;
  handleAction?: (e: any) => void;
  claims?: any;
  total?: number;
};
const PendingGeneralAmendmentTable = ({
  handleTableChange,
  table,
  data,
  loading,
  rowIdParam,
  dashboardView,
  handleAction,
  claims,
  total,
}: Props) => {
  const columns = tableColumnGenerator(
    dashboardView
      ? [
          ...getGeneralAmendmentColumns({
            sortInfo: table?.sortInfo,
            type: "pending",
            dashboardView,
          }),
        ]
      : [
          ...getGeneralAmendmentColumns({
            sortInfo: table?.sortInfo,
            type: "pending",
            dashboardView,
          }),
          {
            key: "action",
            align: "center",
            render: (record: any) => (
              <React.Fragment>
                <div className="float-right pr-2">
                  <TableActionComponent
                    record={record}
                    handleAction={handleAction}
                    viewComponentName={GENERAL_AMENDMENT_PENDING_DETAIL_VIEW}
                    deleteComponentName={
                      displayDeleteAmendment({ record })
                        ? GENERAL_AMENDMENT_PENDING_DELETE
                        : ""
                    }
                    claims={claims}
                    appliedInKeypay={
                      displayAppliedToPayroll({ record })
                        ? GENERAL_AMENDMENT_PENDING_APPLY_IN_KEYPAY
                        : ""
                    }
                    approveComponentName={
                      displayApproveAmendment({ record })
                        ? GENERAL_AMENDMENT_PENDING_APPROVE
                        : ""
                    }
                    rejectComponentName={
                      displayRejectAmendment({ record })
                        ? GENERAL_AMENDMENT_PENDING_REJECT
                        : ""
                    }
                  />
                </div>
                <div className="clearfix" />
              </React.Fragment>
            ),
          },
        ]
  );
  const onTableChange = (pagination: any, _filters: any, sorter: any) => {
    handleTableChange({
      page: pagination?.current,
      perPage: pagination?.pageSize,
      sortInfo: {
        field: sorter?.field,
        order: getSortOrderRequestValue({ sorter }),
      },
    });
  };

  return (
    <SimpleTableContainer
      dataSource={data}
      columns={columns}
      loading={loading}
      rowIdParam={rowIdParam}
      dashboardView={dashboardView}
      onChange={onTableChange}
      pagination={{
        total,
        current: table?.page,
        pageSize: table?.perPage,
        hideOnSinglePage: true,
      }}
    />
  );
};
export default PendingGeneralAmendmentTable;
