// @ts-nocheck
import React, { useMemo } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  EyeFilled,
  MailFilled,
  MessageOutlined,
  RightSquareFilled,
  CloseCircleOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Menu, Dropdown, Button, Tooltip, Popconfirm, Switch } from "antd";
import _ from "lodash";
import {
  PERMANENT_AMENDMENT_TYPE_ID,
  DISABLE_DEVICE_ENROLMENT_ID,
  MANDATORY_DEVICE_ENROLMENT_ID,
  OPTIONAL_DEVICE_ENROLMENT_ID,
} from "../../../constants";
import {
  getClaimAndStatus,
  getConditionalResponse,
  getOptionalParameter,
  isComponentVisible,
  isUserSystemAdmin,
  displayGeneralAmendmentActionOptions,
  getGeneralAmendmentActionText,
  isAmendmentAutomated,
  getGeneralAmendmentStatusText,
} from "../../../libs";
import {
  IntegrationSettingsIcon,
  LgEllipsis,
} from "../../../Common/customicons";
import { If } from "../../../Common/ui";

const GridActionComponent = (props: any) => {
  const {
    record,
    handleAction,
    editComponentNameGrid,
    viewComponentNameGrid,
    sendReminderComponentNameGrid,
    deleteComponentNameGrid,
    enableDisableComponentNameGrid,
    enableDisableMFAComponentNameGrid,
    claims,
    hasNoComponents,
    buttonClassName,
    classname,
    displayActionIcons,
    showDeleteOption,
    showEditOption,
    showViewOption,
    enabled,
    mfaEnabled,
    deviceEnrolmentValue,
    member,
    appliedInKeypay,
    applyCancel,
    applyEditedInKeypay,
    requestCancel,
    resubmitComponentNameGrid,
    showAssignBusinessUnits,
    assignBusinessUnitGrid,
    payrunIntegrationSettingComponent,
    amendmentIntegrationSettingComponent,
    payrunIntegrationSettingValue,
    amendmentIntegrationSettingValue,
  } = props;

  const assignBusinessUnitClaim = getClaimAndStatus({
    claims,
    componentName: assignBusinessUnitGrid,
    hasComponents: !hasNoComponents,
  });
  const editClaim = getClaimAndStatus({
    claims,
    componentName: editComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const viewClaim = getClaimAndStatus({
    claims,
    componentName: viewComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const deleteClaim = getClaimAndStatus({
    claims,
    componentName: deleteComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const resubmitClaim = getClaimAndStatus({
    claims,
    componentName: resubmitComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const sendReminderClaim = getClaimAndStatus({
    claims,
    componentName: sendReminderComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const enableDisableClaim = getClaimAndStatus({
    claims,
    componentName: enableDisableComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const enableDisableMFAClaim = getClaimAndStatus({
    claims,
    componentName: enableDisableMFAComponentNameGrid,
    hasComponents: !hasNoComponents,
  });
  const appliedInKeypayClaim = getClaimAndStatus({
    claims,
    componentName: appliedInKeypay,
    hasComponents: !hasNoComponents,
  });
  const requestCancelClaim = getClaimAndStatus({
    claims,
    componentName: requestCancel,
    hasComponents: !hasNoComponents,
  });
  const applyCancelClaim = getClaimAndStatus({
    claims,
    componentName: applyCancel,
    hasComponents: !hasNoComponents,
  });
  const applyEditedInKeypayClaim = getClaimAndStatus({
    claims,
    componentName: applyEditedInKeypay,
    hasComponents: !hasNoComponents,
  });

  // Payroll Provider integration setings
  const payrunIntegrationSettingClaim = getClaimAndStatus({
    claims,
    componentName: payrunIntegrationSettingComponent,
    hasComponents: !hasNoComponents,
  });
  const amendmentIntegrationSettingClaim = getClaimAndStatus({
    claims,
    componentName: amendmentIntegrationSettingComponent,
    hasComponents: !hasNoComponents,
  });

  const assignBusinessUnitStatus = assignBusinessUnitClaim?.status;
  const editStatus = editClaim?.status;
  const viewStatus = viewClaim?.status;
  const sendReminderStatus = sendReminderClaim?.status;
  const deleteStatus = deleteClaim?.status;
  const enableDisableStatus = enableDisableClaim?.status;
  const enableDisableMFAStatus = enableDisableMFAClaim?.status;
  const resubmitStatus = resubmitClaim?.status;

  const generalAmendmentCommonProps = {
    appliedInKeypayClaim: appliedInKeypayClaim.claim,
    applyCancelClaim: applyCancelClaim.claim,
    applyEditedInKeypayClaim: applyEditedInKeypayClaim.claim,
  };
  const isSystemAdmin = useMemo(() => {
    return isUserSystemAdmin({ member });
  }, [member?.details?.username]);

  return (
    <React.Fragment>
      {getConditionalResponse({
        condition: displayActionIcons,

        resp1: (
          <React.Fragment>
            {getOptionalParameter({
              value1: isComponentVisible({ claim: viewClaim.claim }),
              value2: showViewOption,
            }) && (
              <Tooltip title="View">
                <EyeFilled
                  className={`mr-3 ${viewStatus}`}
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "view" })
                  }
                />
              </Tooltip>
            )}
            {getOptionalParameter({
              value1: isComponentVisible({ claim: editClaim.claim }),
              value2: showEditOption,
            }) && (
              <Tooltip title="Edit">
                <EditFilled
                  className={`mr-3 ${editStatus} color-primary`}
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "edit" })
                  }
                />
              </Tooltip>
            )}
            {getOptionalParameter({
              value1: isComponentVisible({ claim: deleteClaim.claim }),
              value2: showDeleteOption,
            }) && (
              <Tooltip title="Delete">
                <CloseOutlined
                  className={`mr-3 ${deleteStatus}`}
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "delete" })
                  }
                />
              </Tooltip>
            )}
            {isComponentVisible({ claim: sendReminderClaim.claim }) && (
              <Tooltip title="Send Reminder">
                <MailFilled
                  className={`mr-3 ${sendReminderStatus} color-primary`}
                  onClick={(e) =>
                    handleAction({ event: e, record, action: "sendReminder" })
                  }
                />
              </Tooltip>
            )}
            {isComponentVisible({ claim: enableDisableClaim.claim }) && (
              <Popconfirm
                placement="topLeft"
                okText="Yes"
                cancelText="No"
                key="decline"
                title={`Are you sure you want to ${getConditionalResponse({
                  condition: enabled,
                  resp1: "disable",
                  resp2: "enable",
                })} this user?`}
                onConfirm={(e) =>
                  handleAction({ event: e, record, action: "enableDisable" })
                }
              >
                <div className={`mr-3 ${enableDisableStatus} color-primary`}>
                  <Switch checked={enabled} />
                </div>
              </Popconfirm>
            )}
            {isComponentVisible({ claim: enableDisableMFAClaim.claim }) && (
              <Popconfirm
                placement="topLeft"
                okText="Yes"
                cancelText="No"
                key="decline"
                title={`Are you sure you want to ${getConditionalResponse({
                  condition: enabled,
                  resp1: "disable",
                  resp2: "enable",
                })} multi-factor authentication?`}
                onConfirm={(e) =>
                  handleAction({ event: e, record, action: "enableDisableMfa" })
                }
              >
                <div className={`mr-3 ${enableDisableMFAStatus} color-primary`}>
                  <Switch checked={enabled} />
                </div>
              </Popconfirm>
            )}
          </React.Fragment>
        ),

        resp2: (
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu className="table-actions">
                {getOptionalParameter({
                  value1: isComponentVisible({ claim: viewClaim.claim }),
                  value2: showViewOption,
                }) && (
                  <Menu.Item key="a">
                    <div
                      onClick={(e) =>
                        handleAction({ event: e, record, action: "view" })
                      }
                      className={showViewOption ? "" : viewStatus}
                    >
                      <EyeFilled /> &nbsp;
                      <span> View</span>
                    </div>
                  </Menu.Item>
                )}
                {getOptionalParameter({
                  value1: isComponentVisible({ claim: editClaim.claim }),
                  value2: showEditOption,
                }) && (
                  <Menu.Item key="b">
                    <div
                      onClick={(e) =>
                        handleAction({ event: e, record, action: "edit" })
                      }
                      className={showEditOption ? "" : editStatus}
                    >
                      <EditFilled /> &nbsp;
                      <span> Edit</span>
                    </div>
                  </Menu.Item>
                )}
                {getConditionalResponse({
                  condition: getOptionalParameter({
                    value1: isComponentVisible({ claim: deleteClaim.claim }),
                    value2: showDeleteOption,
                  }),

                  resp1: (
                    <Menu.Item key="c">
                      <div
                        onClick={(e) =>
                          handleAction({ event: e, record, action: "delete" })
                        }
                        className={showDeleteOption ? "" : deleteStatus}
                      >
                        <CloseOutlined /> &nbsp;
                        <span> Delete</span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isComponentVisible({ claim: resubmitClaim.claim }),

                  resp1: (
                    <Menu.Item key="d">
                      <div
                        onClick={(e) =>
                          handleAction({ event: e, record, action: "reSubmit" })
                        }
                        className={getOptionalParameter({
                          value2: "",
                          value1: resubmitStatus,
                        })}
                      >
                        <RightSquareFilled /> &nbsp;
                        <span> Resubmit</span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isComponentVisible({
                    claim: sendReminderClaim.claim,
                  }),

                  resp1: (
                    <Menu.Item key="e">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "sendReminder",
                          })
                        }
                        className={sendReminderStatus}
                      >
                        <MailFilled /> &nbsp;
                        <span> Send Reminder</span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isComponentVisible({
                    claim: enableDisableClaim.claim,
                  }),

                  resp1: (
                    <Menu.Item key="f">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "enableDisable",
                          })
                        }
                        className={enableDisableStatus}
                      >
                        <LegacyIcon
                          type={getConditionalResponse({
                            condition: enabled,
                            resp1: "user-delete",
                            resp2: "user",
                          })}
                        />{" "}
                        &nbsp;
                        <span>
                          {" "}
                          {getConditionalResponse({
                            condition: enabled,
                            resp1: "Disable",
                            resp2: "Enable",
                          })}
                        </span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isComponentVisible({
                    claim: enableDisableMFAClaim.claim,
                  }),

                  resp1: (
                    <Menu.Item key="g">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "enableDisableMfa",
                          })
                        }
                        className={enableDisableMFAStatus}
                      >
                        <MessageOutlined /> &nbsp;
                        <span>
                          {" "}
                          {`${getConditionalResponse({
                            condition: mfaEnabled,
                            resp1: "Disable",
                            resp2: "Enable",
                          })} MFA`}
                        </span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                <If
                  condition={isComponentVisible({
                    claim: payrunIntegrationSettingClaim.claim,
                  })}
                  then={
                    <Menu.Item key="payrunsIntegrationSettings">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            data: record,
                            type: "payrun",
                            action: "integrationSettings",
                            value: !payrunIntegrationSettingValue,
                          })
                        }
                        className={payrunIntegrationSettingClaim.status}
                      >
                        <div className="inline-flex items-center">
                          <IntegrationSettingsIcon className="mr-2 text-2xl color-disabled custom-integration-icon-size" />{" "}
                          <span>
                            {" "}
                            {`${getConditionalResponse({
                              condition: payrunIntegrationSettingValue,
                              resp1: "Disable",
                              resp2: "Enable",
                            })} Pay Runs Integration`}
                          </span>
                        </div>
                      </div>
                    </Menu.Item>
                  }
                  else={<React.Fragment />}
                />
                <If
                  condition={isComponentVisible({
                    claim: amendmentIntegrationSettingClaim.claim,
                  })}
                  then={
                    <Menu.Item key="amendmentIntegrationSettings">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            data: record,
                            type: "amendment",
                            action: "integrationSettings",
                            value: !amendmentIntegrationSettingValue,
                          })
                        }
                        className={amendmentIntegrationSettingClaim.status}
                      >
                        <div className="inline-flex items-center">
                          <IntegrationSettingsIcon className="mr-2 text-2xl color-disabled custom-integration-icon-size" />{" "}
                          <span>
                            {" "}
                            {`${getConditionalResponse({
                              condition: amendmentIntegrationSettingValue,
                              resp1: "Disable",
                              resp2: "Enable",
                            })} Amendments Integration`}
                          </span>
                        </div>
                      </div>
                    </Menu.Item>
                  }
                  else={<React.Fragment />}
                />
                {getConditionalResponse({
                  condition: displayGeneralAmendmentActionOptions({
                    ...generalAmendmentCommonProps,
                  }),

                  resp1: (
                    <Menu.Item key="h">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: getGeneralAmendmentActionText({
                              ...generalAmendmentCommonProps,
                            }),
                          })
                        }
                      >
                        <If
                          condition={
                            isAmendmentAutomated({ amendment: record }) ||
                            record?.amendmentTypeId ===
                              PERMANENT_AMENDMENT_TYPE_ID
                          }
                          then={<CheckOutlined />}
                          else={<CheckCircleOutlined />}
                        />
                        &nbsp;&nbsp;
                        <span>
                          {getGeneralAmendmentStatusText({
                            ...generalAmendmentCommonProps,
                            amendment: record,
                          })}
                        </span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isComponentVisible({
                    claim: requestCancelClaim.claim,
                  }),

                  resp1: (
                    <Menu.Item key="i">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "requestCancel",
                          })
                        }
                      >
                        <CloseCircleOutlined /> &nbsp;
                        <span>Cancel Amendment</span>
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition:
                    showAssignBusinessUnits &&
                    isComponentVisible({
                      claim: assignBusinessUnitClaim?.claim,
                    }),
                  resp1: (
                    <Menu.Item key="t">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "assignBusinessUnit",
                          })
                        }
                        className={assignBusinessUnitStatus}
                      >
                        <CheckSquareOutlined /> &nbsp;
                        <span>Assign Business Unit</span>
                      </div>
                    </Menu.Item>
                  ),
                  resp2: <React.Fragment />,
                })}

                <If condition={isSystemAdmin} then={<Menu.Divider key="j" />} />

                {getConditionalResponse({
                  condition: !mfaEnabled && isSystemAdmin,

                  resp1: (
                    <Menu.Item key="k">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "mandatoryDeviceEnrolment",
                          })
                        }
                      >
                        <LegacyIcon type={"laptop"} /> &nbsp;
                        <span>Mandatory Device Enrolment</span>
                        {getConditionalResponse({
                          condition:
                            deviceEnrolmentValue ===
                            MANDATORY_DEVICE_ENROLMENT_ID,

                          resp1: <CheckOutlined className="text-xs ml-1.5" />,

                          resp2: <React.Fragment />,
                        })}
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isSystemAdmin,

                  resp1: (
                    <Menu.Item key="l">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "optionalDeviceEnrolment",
                          })
                        }
                      >
                        <LegacyIcon type={"laptop"} /> &nbsp;
                        <span>Optional Device Enrolment</span>
                        {getConditionalResponse({
                          condition:
                            deviceEnrolmentValue ===
                            OPTIONAL_DEVICE_ENROLMENT_ID,

                          resp1: <CheckOutlined className="text-xs ml-1.5" />,

                          resp2: <React.Fragment />,
                        })}
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
                {getConditionalResponse({
                  condition: isSystemAdmin,

                  resp1: (
                    <Menu.Item key="m">
                      <div
                        onClick={(e) =>
                          handleAction({
                            event: e,
                            record,
                            action: "disableDeviceEnrolment",
                          })
                        }
                      >
                        <LegacyIcon type={"robot"} /> &nbsp;
                        <span>Disable Device Enrolment</span>
                        {getConditionalResponse({
                          condition:
                            deviceEnrolmentValue ===
                            DISABLE_DEVICE_ENROLMENT_ID,

                          resp1: <CheckOutlined className="text-xs ml-1.5" />,
                          resp2: <React.Fragment />,
                        })}
                      </div>
                    </Menu.Item>
                  ),

                  resp2: <React.Fragment />,
                })}
              </Menu>
            }
            placement="bottomRight"
            className={`${getOptionalParameter({
              value2: "",
              value1: classname,
            })} action-menu`}
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              className={`${getOptionalParameter({
                value2: "",
                value1: buttonClassName,
              })} no-border btn-dropdown-action flex ellipsis items-center px-1.5 rounded-full`}
            >
              <LgEllipsis />
            </Button>
          </Dropdown>
        ),
      })}
    </React.Fragment>
  );
};
export default GridActionComponent;
