import { useMemo } from 'react';
import classNames from 'classnames';

import { formatToCurrency, tryJsonParse } from '@xemplo/common-util';
import {
  AmendmentQuestionType,
  AmendmentSummary,
  AmendmentSummaryQuestion,
} from '@xemplo/gp-types';

import * as S from './termination-summary.styles';
import { AmendmentTerminationSummaryProps } from './termination-summary.types';

export function TerminationSummary(props: Readonly<AmendmentTerminationSummaryProps>) {
  const terminationSummary = tryJsonParse<AmendmentSummary>(props.summary);
  const { questions } = terminationSummary ?? {};

  const isSummaryEmpty = useMemo(() => {
    return questions?.every(
      (question) =>
        !question.value &&
        (!question.questions || question.questions.every((q) => !q.value))
    );
  }, [questions]);

  if (isSummaryEmpty || !questions) {
    return null;
  }
  return (
    <S.Wrapper>
      <S.Header>Summary</S.Header>
      {questions.map((question, index) => {
        return (
          <S.QuestionGroup
            key={question.name}
            className={classNames({ 'extra-margin': index !== 0 })}
          >
            <S.LineItem className="header">
              <span>{question.label}</span>
              {!!question.value && (
                <span className="item-value">
                  {question.value} {getLabelText(question.subLabel)}
                </span>
              )}
            </S.LineItem>
            {question.questions?.map((subQuestion) => (
              <SubQuestion key={subQuestion.name} {...subQuestion} />
            ))}
          </S.QuestionGroup>
        );
      })}
    </S.Wrapper>
  );
}

const SubQuestion = (question: AmendmentSummaryQuestion) => {
  if (!question.name && !question.value) {
    return null;
  }
  if (question.type === AmendmentQuestionType.Currency) {
    return (
      <S.LineItem>
        <span>{question.name}</span>
        <span className="item-value">
          {formatToCurrency({ value: Number(question.value) })}
        </span>
      </S.LineItem>
    );
  }
  return (
    <S.LineItem>
      <span>{question.label}</span>
      <span className="item-value">
        {question.value ? question.value : 0} {getLabelText(question.subLabel)}
      </span>
    </S.LineItem>
  );
};

function getLabelText(label: string) {
  switch (label) {
    case 'Hours':
      return 'hrs';
    case 'Days':
    case 'Weeks':
      return label.toLowerCase();
    default:
      return label;
  }
}
