// @ts-nocheck
import React, { useMemo, useState } from "react";
import { Button, Col, Collapse, Divider, Modal, Row } from "antd";
import {
  displayDates,
  findAuthorizationClaims,
  isComponentVisible,
} from "../../../../libs";
import { If } from "../../../../Common/ui";
import PayrunChangeLog from "../ChangeLog";
import {
  BUSINESS_UNIT_MENU_ITEM,
  PAYRUN_CHANGELOG_MODAL_CONTAINER,
} from "../../../../constants";
import { useAppSelector } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

type Props = {
  title?: string;
  handleClose: () => void;
  visible: boolean;
  payrun: any;
  claims: any;
  viewChangeLog: boolean;
};

const { Panel } = Collapse;
const titleDefault = "Payrun Change Log";

const PayrunChangeLogModal = ({
  title = titleDefault,
  handleClose,
  visible,
  payrun,
  claims,
  viewChangeLog,
}: Props) => {
  const authDom = useAppSelector(
    (state) => state?.member?.details?.authorizationDOM
  );
  const navigate = useNavigate();

  // define active key to collapse list view - doesn't detect change in modal visibility otherwise
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const businessUnitMenuItem = useMemo(() => {
    return findAuthorizationClaims({
      claims: authDom,
      name: BUSINESS_UNIT_MENU_ITEM,
    });
  }, [authDom]);

  // Pay run detail from the store
  const payDate = displayDates({ value: payrun?.payDate });
  const startDate = displayDates({ value: payrun?.payCycleStartDate });
  const endDate = displayDates({ value: payrun?.payCycleEndDate });
  const frequency = payrun?.payOccurrenceName ?? "N/A";
  // Get claim for PayrunChangeLogModalContainer
  const claim = useMemo(() => {
    return findAuthorizationClaims({
      claims: claims?.components,
      name: PAYRUN_CHANGELOG_MODAL_CONTAINER,
    });
  }, [claims]);

  const handleBusinessUnitClick = () => {
    // Validate user access
    if (isComponentVisible({ claim: businessUnitMenuItem })) {
      navigate(`/businessunit/${payrun?.businessUnitID}`);
    }
  };

  const realTitle = (
    <>
      <div className="text-xl">{title}</div>
      <div
        className="text-blue-500 hand text-base font-semibold"
        onClick={handleBusinessUnitClick}
      >
        {payrun?.businessUnitName}
      </div>
    </>
  );

  const handleModalClose = () => {
    setActiveKey([]);
    handleClose();
  };

  return (
    <If
      condition={isComponentVisible({ claim })}
      then={
        <Modal
          title={realTitle}
          width={1100}
          visible={visible}
          onCancel={handleModalClose}
          footer={[
            <Button key="back" onClick={handleModalClose} children={"Close"} />,
          ]}
        >
          <div>
            <Row gutter={16} className="mb-3">
              <Col span={6}>
                <label className="text-sm">
                  <b>Pay Frequency:</b>
                </label>
                <div className="text-base">{frequency}</div>
              </Col>
              <Col span={6}>
                <label className="text-sm">
                  <b>Period Start:</b>
                </label>
                <div className="text-base">{startDate}</div>
              </Col>
              <Col span={6}>
                <label className="text-sm">
                  <b>Period End:</b>
                </label>
                <div className="text-base">{endDate}</div>
              </Col>
              <Col span={6}>
                <label className="text-sm">
                  <b>Pay Date:</b>
                </label>
                <div className="text-base">{payDate}</div>
              </Col>
            </Row>

            <If
              condition={viewChangeLog}
              then={
                <React.Fragment>
                  <Divider />
                  <Collapse
                    activeKey={activeKey}
                    onChange={(e: string | string[]) => setActiveKey(e)}
                  >
                    <Panel header="Change Log" key="1">
                      <PayrunChangeLog
                        visible={activeKey.includes("1")}
                        payrun={payrun}
                      />
                    </Panel>
                  </Collapse>
                </React.Fragment>
              }
            />
          </div>
        </Modal>
      }
    />
  );
};

export default PayrunChangeLogModal;
