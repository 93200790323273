import React from "react";
import { Card } from "antd";
import CreateLinkButton from "../ClaimComponents/Button/CreateLinkButton";
import EditLinkButton from "../ClaimComponents/Button/EditLinkButton";

type CardWithTitleLayoutProps = {
  claims: object;
  hasNoTitle?: boolean;
  name?: string;
  componentName?: string;
  className?: string;
  title?: string;
  children: React.ReactNode;
  showTitle?: boolean;
  match?: any;
  handleEvent?: (e: any) => void;
};

const classNameDefault = "";

const CardWithTitleLayout = (props: CardWithTitleLayoutProps) => {
  const {
    claims,
    title,
    name,
    handleEvent,
    hasNoTitle,
    className = classNameDefault,
    componentName,
    showTitle,
    children,
  } = props;
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child || <React.Fragment />, { ...props })
  );
  return children ? (
    <Card
      bordered={false}
      className={`${className} has-table card ${
        showTitle
          ? "card-with-title"
          : "card-space-0 card-noPadding card-noMargin "
      } card-space-h-16 rounded-[12px]`}
      title={
        hasNoTitle && !showTitle ? null : (
          <div>
            {title}{" "}
            {name === "Add" && !showTitle ? (
              <CreateLinkButton
                onClick={handleEvent}
                name={componentName}
                claims={claims}
                hasNoComponents={true}
              />
            ) : (
              !showTitle && (
                <EditLinkButton
                  onClick={handleEvent}
                  name={componentName}
                  claims={claims}
                  hasNoComponents={true}
                />
              )
            )}
          </div>
        )
      }
    >
      {childrenWithProps}
    </Card>
  ) : (
    <React.Fragment />
  );
};

export default CardWithTitleLayout;
