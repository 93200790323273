import { SVGProps } from 'react';
const DawnMenuMore24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM12 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-8-1.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
      fill="currentColor"
    />
  </svg>
);
export default DawnMenuMore24;
