import { useState } from 'react';

import { useGetAmendmentChangeLogQuery } from '@xemplo/amendment-query';
import { IconButton } from '@xemplo/icon-button';
import { DawnArrowDown24, DawnArrowUp24 } from '@xemplo/icons';

import { columns } from './amendment-change-log.helper';
import * as S from './amendment-change-log.styles';
import { AmendmentDetailModalChangeLogProps } from './amendment-change-log.types';

export const AmendmentChangeLog = (props: AmendmentDetailModalChangeLogProps) => {
  const { amendmentId, title } = props;
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <S.HeaderBar>
        <S.Header>{title ?? 'Change log'}</S.Header>
        <IconButton
          id="reveal-changelog"
          ariaLabel="reveal-changelog"
          onClick={() => setToggle((prev) => !prev)}
          naked
        >
          {toggle ? <DawnArrowUp24 /> : <DawnArrowDown24 />}
        </IconButton>
      </S.HeaderBar>
      {toggle && <ChangeLogTable id={amendmentId} />}
    </>
  );
};

export const ChangeLogTable = (props: { id?: string }) => {
  const { id } = props;
  const { data, isLoading } = useGetAmendmentChangeLogQuery({
    id: id ?? null,
  });

  return (
    <S.StyledTable
      tableOverflowControlMinWidth={0}
      isLoading={isLoading}
      data={data?.result?.rows ?? []}
      columns={columns}
      enableRowSelection={false}
      enablePagination={false}
      enableSorting={false}
    />
  );
};
