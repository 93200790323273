import { Placement } from '@react-aria/overlays';

import { TooltipPlacement } from './tooltip.types';

export const TooltipTestId = {
  trigger: 'tooltip-trigger',
  arrow: 'tooltip-arrow',
  message: 'tooltip-message',
};

export const placementMapping: Record<TooltipPlacement, Placement> = {
  [TooltipPlacement.Top]: 'top',
  [TooltipPlacement.TopLeft]: 'top right',
  [TooltipPlacement.TopRight]: 'top left',
  [TooltipPlacement.Left]: 'left',
  [TooltipPlacement.Right]: 'right',
  [TooltipPlacement.Bottom]: 'bottom',
  [TooltipPlacement.BottomLeft]: 'bottom right',
  [TooltipPlacement.BottomRight]: 'bottom left',
};

export const offsetMapping: Record<TooltipPlacement, number[]> = {
  [TooltipPlacement.Top]: [8, 0],
  [TooltipPlacement.TopLeft]: [8, 8],
  [TooltipPlacement.TopRight]: [8, -8],
  [TooltipPlacement.Left]: [8, 0],
  [TooltipPlacement.Right]: [8, 0],
  [TooltipPlacement.Bottom]: [8, 0],
  [TooltipPlacement.BottomLeft]: [8, 8],
  [TooltipPlacement.BottomRight]: [8, -8],
};
