//@ts-nocheck
import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { connect } from "react-redux";

TopBarProgress.config({
  barColors: {
    "0": "#5f6875",
    "0.5": "#4a535f",
    "1.0": "#373d45",
  },
  shadowBlur: 0,
  barThickness: 2,
});

/**
 * @knipignore
 */
export class ProgressBar extends React.Component {
  render() {
    const { progressBarStatus } = this.props;

    if (progressBarStatus) {
      return <TopBarProgress />;
    } else {
      return "";
    }
  }
}

//redux container component
const mapStateToProps = (state: any) => {
  return {
    progressBarStatus: state.ui.progressBarStatus,
  };
};

/**
 * @knipignore
 */
export const HandleProgressBar = connect(mapStateToProps, null)(ProgressBar);

/** @alias */
export default HandleProgressBar;
