export const VISIBLE_ENABLED_ID = 1;
export const HIDDEN_ID = 3;

export const GRID_PERSONALIZATIONS = [
  { name: "MatrixColumns", id: "1", value: "4", valueDataType: "integer" },
  { name: "MatrixRows", id: "2", value: "5", valueDataType: "integer" },
  { name: "PageNumber", id: "3", value: "1", valueDataType: "string" },
  { name: "SortOrder", id: "4", value: "", valueDataType: "string" },
  { name: "SortColumn", id: "5", value: "", valueDataType: "string" },
];

export const TABLE_PERSONALIZATIONS = [
  { name: "PageSize", id: "1", value: "", valueDataType: "string" },
  { name: "PageNumber", id: "2", value: "", valueDataType: "string" },
  { name: "SortOrder", id: "3", value: "", valueDataType: "string" },
  { name: "SortColumn", id: "4", value: "", valueDataType: "string" },
];

export const GRID_SIZE_OPTIONS = [
  { id: 1, name: "5 X 3", value: "5X3" },
  { id: 2, name: "5 X 4", value: "5X4" },
  { id: 3, name: "10 X 3", value: "10X3" },
  { id: 4, name: "10 X 4", value: "10X4" },
  { id: 5, name: "25 X 4", value: "25X4" },
];

export const TABLE_SIZE_OPTIONS = [
  { id: 1, name: "5", value: "5" },
  { id: 2, name: "10", value: "10" },
  { id: 3, name: "20", value: "20" },
  { id: 4, name: "50", value: "50" },
  { id: 5, name: "100", value: "100" },
];

export const PAGE_SIZE_INDEX = 0;
export const PAGE_NUMBER_INDEX = 1;
export const SORT_ORDER_INDEX = 2;
export const SORT_COLUMN_INDEX = 3;

export const GRID_COLUMN_INDEX = 0;
export const GRID_ROW_INDEX = 1;
export const GRID_PAGE_NUMBER_INDEX = 2;
export const GRID_SORT_ORDER_INDEX = 3;
export const GRID_SORT_COLUMN_INDEX = 4;

export const STRING_PERSONALIZATION = {
  value: "",
  valueDataType: "string",
};
export const INTEGER_PERSONALIZATION = {
  value: 0,
  valueDataType: "integer",
};

export const PINNABLE_LIST_PERSONALIZATION = [
  { id: "1", name: "GridTableToggle", value: "table", valueDataType: "string" },
  { id: "2", name: "Pin", value: "false", valueDataType: "string" },
  {
    id: "3",
    name: "PaginationType",
    value: "pagenumber",
    valueDataType: "string",
  },
  { id: "4", name: "SortingType", value: "required", valueDataType: "string" },
];

export const PAGINATION_NOT_REQUIRED = "notrequired";
export const PAGINATION_SCROLLING = "scrolling";
export const PAGINATION_PAGENUMBER = "pagenumber";
export const SORTING_REQUIRED = "required";
export const SORTING_NOT_REQUIRED = "notrequired";

export const DEFAULT_PAGE_SIZE_NO_PAGINATION = 1000;
export const LIST_PAGINATION_TYPE_INDEX = 2;
export const LIST_SORTING_REQUIRED_INDEX = 3;

export const LIST_PERSONALIZATION_GRID_TABLE_INDEX = 0;
export const LIST_PINNABLE_INDEX = 1;
export const LIST_PERSONALIZATION_PAGINATION_INDEX = 2;

export const GRID_VIEW_VALUE = "grid";
export const TABLE_VIEW_VALUE = "table";
export const SINGLE_LINE_GRID_VALUE = "singleLineGrid";

export const MULTI_TAB_CONTAINER_PERSONALIZATION = [
  { id: "1", name: "ActiveTab", value: "2", valueDataType: "string" },
  { id: "2", name: "Pin", value: "false", valueDataType: "string" },
];
export const MULTI_TAB_ACTIVE_KEY_INDEX = 0;

// status
export const VISIBLE_CLAIMS_ID = [1, 2];
export const VISIBLE_STATUS_NAME = "Visible - Enabled";
export const VISIBLE_DISABLED_STATUS_NAME = "Visible - Disabled";

// New Claims
export const INITIAL_STATE_LIST_CONTAINER = {
  // Common states
  claimsUpdated: false, //To fetch the date on page load - ensure claims are read first for the default filters, sortings and pagination
  loading: true,
  total: 0,
  data: [],
  container: {
    gridView: false, //intial view grid or table
    gridable: false, //option to switch between grid and table view
    singleLineGridView: false, //Only applicable for company users
  },
  // Table View
  table: {
    sortInfo: {},
    page: 1,
    perPage: 20,
    isVisible: true,
  },
  // Grid View
  grid: {
    page: 1,
    col: 4,
    row: 5,
    sortInfo: {},
    isVisible: false,
  },
};
