import { useFormContext } from 'react-hook-form';

import {
  useGetBusinessUnitsQuery,
  useGetChangeTaskSubTypeQuery,
} from '@xemplo/amendment-query';

import * as S from './bulk-upload-summary.styles';

export const SummaryHeader = () => {
  const { watch } = useFormContext();
  const watchChangeTask = watch('changeTask');
  const watchChangeTaskSubType = watch('changeTaskSubType');
  const watchBusinessUnit = watch('businessUnit');

  const { data: businessUnitData } = useGetBusinessUnitsQuery({});

  const { data: subTypeData } = useGetChangeTaskSubTypeQuery({
    requestParams: {
      q: {
        changeTaskValueId: watchChangeTask,
        businessUnitId: watchBusinessUnit,
      },
    },
  });
  const subType = subTypeData?.result?.rows.find(
    (type) => type.id === watchChangeTaskSubType
  );

  const businessUnitName = businessUnitData?.result.rows.find(
    (row) => row.id === watchBusinessUnit
  )?.name;

  const changeTask = subType?.changeTaskValue;
  const changeTaskType = subType?.name;

  return (
    <S.Details>
      <S.DetailItem>
        <div>Change Type</div>
        <div>Payrun</div>
      </S.DetailItem>
      <S.DetailItem>
        <div>Business Unit</div>
        <div>{businessUnitName}</div>
      </S.DetailItem>
      <S.DetailItem>
        <div>Change Task</div>
        <div>{changeTask}</div>
      </S.DetailItem>
      <S.DetailItem>
        <div>{changeTask} Type</div>
        <div>{changeTaskType}</div>
      </S.DetailItem>
    </S.Details>
  );
};
