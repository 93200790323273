//@ts-nocheck
import React from "react";
import _ from "lodash";
import { mustBeArray, findAuthorizationClaims } from "../libs/utilities";
import { GLOBAL_PAYROLL_APPLICATION_NAME } from "../constants";

type Props = any;
type State = any;
class SwiftContainer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      pageClaims: {},
    };
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.scope &&
      mustBeArray(this.props.member?.details?.authorizationDOM).find(
        (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
      )
    ) {
      const globalDash = mustBeArray(
        this.props.member.details.authorizationDOM
      ).find((o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME);
      const containerClaims = mustBeArray(globalDash?.components);
      const claims = findAuthorizationClaims({
        claims: containerClaims,
        name: this.props.scope,
      });
      this.setState({ pageClaims: claims });
    }
  }
  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (
      newProps.scope !== this.props.scope ||
      (newProps.member?.details &&
        newProps.member.details?.authorizationDOM) !==
        this.props.member?.details?.authorizationDOM
    ) {
      const expedoComponents = _.find(
        mustBeArray(newProps.member?.details?.authorizationDOM),
        (o) => o.name === GLOBAL_PAYROLL_APPLICATION_NAME
      );
      const containerClaims = mustBeArray(expedoComponents?.components);
      const claims = findAuthorizationClaims({
        claims: containerClaims,
        name: newProps.scope,
      });
      this.setState({ pageClaims: claims });
    }
  }

  async executeAction(action: any, data: any) {
    this.setState({ loading: true });
    try {
      const res = await action(data);
      this.setState({ loading: false });
      if (!res.status) {
        this.setState({
          error: true,
          errorMessage: res.message,
        });
      }
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default SwiftContainer;
