import styled from 'styled-components';

import { Colour } from '@xemplo/style-constants';

export const GradientBar = styled.div`
  background: ${Colour.Gradient.Linear90};
  border-radius: 2px;
  bottom: 0;
  position: absolute;
  transition: 0.3s all cubic-bezier(0, 1, 0, 1);
  width: 0;
`;
