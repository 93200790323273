import styled from 'styled-components';

import {
  containerBase,
  containerDisabled,
  containerFocus,
  containerReadonly,
  inputBase,
  inputDisabled,
  InputFieldSize,
  inputReadonly,
} from '@xemplo/input-utils';
import { Colour } from '@xemplo/style-constants';

export const Container = styled.div<{ $inputSize: InputFieldSize; $width: string }>`
  ${containerBase};
  ${containerFocus};
  ${containerDisabled};
  ${containerReadonly};
  width: ${({ $width }) => $width};

  /* ensures the clear button follows the same styling */
  &.input-disabled {
    & > button {
      color: ${Colour.Gray[300]};
    }
  }

  &.input-readonly {
    & > button {
      color: ${Colour.Gray[500]};
    }
  }
`;

export const Input = styled.input`
  ${inputBase};
  ${inputDisabled};
  ${inputReadonly};
  /* clears the ‘X’ from Internet Explorer */
  &[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`;

export const ClearButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: ${Colour.Gray[400]};
  font-size: 16px;
`;
