import { useState } from 'react';

import { getAssignedWorker, getAssignedWorkerDisplayName } from '@xemplo/gp-utils';
import { IconButton, IconButtonSize } from '@xemplo/icon-button';
import { DawnArrowDown24, DawnArrowUp24 } from '@xemplo/icons';

import * as S from '../../amendment-details-modal-styles';

export function AppliesTo(props: { assignTo: string | null }) {
  const { assignTo } = props;
  const [toggle, setToggle] = useState(false);

  const displayWorkerName = getAssignedWorkerDisplayName(assignTo);
  const assignedWorkersString = getAssignedWorker(assignTo)
    ?.map((worker) => `${worker.firstName} ${worker.lastName}`)
    .join(', ');

  const showExpandButton = assignedWorkersString !== displayWorkerName;
  return (
    <S.DLRow className="single-column">
      <S.AppliesToLabel>
        Applies to
        {showExpandButton && (
          <IconButton
            id="reveal-changelog"
            ariaLabel="reveal-changelog"
            onClick={() => setToggle((prev) => !prev)}
            naked
            size={IconButtonSize.Small}
          >
            {toggle ? <DawnArrowUp24 /> : <DawnArrowDown24 />}
          </IconButton>
        )}
      </S.AppliesToLabel>
      <S.DD>{toggle ? assignedWorkersString : displayWorkerName}</S.DD>
    </S.DLRow>
  );
}
