import { ForwardedRef, forwardRef, Ref, RefObject, useEffect, useRef } from 'react';
import { useListBox, useOption } from '@react-aria/listbox';
import classnames from 'classnames';

import { CheckboxSize } from '@xemplo/checkbox';
import { DawnTick16 } from '@xemplo/icons';
import { InputFieldSize } from '@xemplo/input-utils';
import { SearchField } from '@xemplo/search-field';

import * as S from './listbox.styles';
import {
  ListBoxProps,
  OptionProps,
  SelectionMode,
  SelectionStyle,
} from './listbox.types';

import 'simplebar-react/dist/simplebar.min.css';

export const ListBoxTestId = {
  listBox: (testId?: string) => `listbox-${testId}`,
  scrollbar: (testId?: string) => `scrollbar-${testId}`,
};

function ListBoxInternal<T>(props: ListBoxProps<T>, ref?: Ref<HTMLUListElement>) {
  const internalRef = useRef<HTMLUListElement>(null);
  const listBoxRef = (ref as RefObject<HTMLUListElement>) ?? internalRef;
  const {
    state,
    maxHeight,
    testId,
    selectionStyle,
    items,
    width,
    selectionMode,
    onListClick,
    onItemClick,
    ...rest
  } = props;
  const { listBoxProps } = useListBox({ ...rest, selectionMode }, state, listBoxRef);

  const scrollBarRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: Event) => {
    const target = e.target as HTMLDivElement;
    const scrollPosition = target.scrollTop;
    const container = target.getElementsByClassName('items-mode-multiple-container')[0];
    if (container) {
      container?.classList.toggle('shadow', scrollPosition > 0);
    }
  };

  useEffect(() => {
    const scrollBar = scrollBarRef.current;
    if (scrollBar) {
      scrollBar.addEventListener('scroll', handleScroll);
    }
    return () => {
      scrollBar?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollBarRef]);

  return (
    <S.ScrollBar
      scrollableNodeProps={{
        ref: scrollBarRef,
      }}
      $maxheight={maxHeight}
      $width={width}
      data-testid={ListBoxTestId.scrollbar(testId)}
      className="listbox-wrapper"
    >
      {selectionMode === SelectionMode.Multiple && <ItemsModeMultiple {...props} />}
      <S.List
        {...listBoxProps}
        ref={listBoxRef}
        data-testid={ListBoxTestId.listBox(testId)}
        $width={width}
        onClick={onListClick}
        className={classnames({ 'has-search': selectionMode === SelectionMode.Multiple })}
      >
        {Array.from(state.collection).map((item) => (
          <Option
            key={item.key}
            item={item}
            state={state}
            selectionStyle={selectionStyle}
            onItemClick={onItemClick}
          />
        ))}
      </S.List>
    </S.ScrollBar>
  );
}

/**
 * When the prop selectionMode is set to SelectionMode.Multiple,
 * we need to introduce a search input and a select all checkbox.
 *
 * This component handles the rendering of those elements.
 *
 * NOTE: For the search to work, it needs to provide the setSearchValue.
 * At this stage, it has been done via useState.dispatch, but it could be done
 * by a pure function. Will leave like this for now and should we need, we can
 * change it later.
 */
function ItemsModeMultiple<T>(props: ListBoxProps<T>) {
  const { state, searchValue, setSearchValue, selectionStyle, testId } = props;
  const { collection, selectionManager } = state;
  const onSelectAllClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    props.onItemClick?.(e);

    if (selectionManager.isSelectAll) {
      selectionManager.clearSelection();
    } else {
      selectionManager.selectAll();
    }
  };

  return (
    <S.Container className="items-mode-multiple-container">
      <S.NonListItem className="search-item">
        <SearchField
          aria-label="List search field"
          placeholder="Search..."
          inputSize={InputFieldSize.Standard}
          testId={testId}
          width="100%"
          defaultValue={searchValue}
          onChange={setSearchValue}
        />
      </S.NonListItem>
      {collection.size === 0 && (
        <S.NonListItem>
          <S.ItemContent>No results</S.ItemContent>
        </S.NonListItem>
      )}
      {selectionStyle === SelectionStyle.Checkbox &&
        collection.size > 0 &&
        searchValue === '' && (
          <S.NonListItem id="list-select-all" onClick={onSelectAllClick}>
            <S.ItemContent className="bold">Select all</S.ItemContent>
            <S.CheckboxSelection
              checked={selectionManager.isSelectAll}
              ariaLabel={`List select all checkbox`}
              id={`list-select-all-${testId}`}
              size={CheckboxSize.Small}
              value={'all'}
              indeterminate={
                !selectionManager.isSelectAll && selectionManager.selectedKeys.size > 0
              }
            />
          </S.NonListItem>
        )}
    </S.Container>
  );
}

function Option<T>({
  item,
  state,
  onItemClick,
  selectionStyle = SelectionStyle.Highlight,
}: OptionProps<T>) {
  const ref = useRef<HTMLLIElement>(null);
  const { disabledKeys } = state;
  const { key, rendered, index, textValue } = item;
  const { optionProps, isSelected, isFocused, labelProps } = useOption(
    { key },
    state,
    ref
  );
  /**
   * Due to the focus being set on the list item (by react-aria), we need to
   * prevent the click event to trigger the onChange event of the checkbox.
   */
  const handleItemClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    optionProps.onClick?.(e);
    onItemClick?.(e, item);
  };

  return (
    <S.ListItem
      {...optionProps}
      ref={ref}
      onClick={handleItemClick}
      className={classnames({
        'item-focused': isFocused,
        'item-selected': isSelected,
      })}
    >
      <S.ItemContent
        {...labelProps}
        className={classnames({
          'item-selected': isSelected,
        })}
        title={textValue}
      >
        {rendered}
      </S.ItemContent>
      {selectionStyle === SelectionStyle.Checkbox && (
        <S.CheckboxSelection
          checked={isSelected}
          ariaLabel={`Item ${index} selected`}
          id={`item-${index}`}
          size={CheckboxSize.Small}
          value={textValue}
          disabled={disabledKeys.has(key)}
        />
      )}
      {isSelected && selectionStyle === SelectionStyle.Highlight && <DawnTick16 />}
    </S.ListItem>
  );
}

/**
 * This is a workaround to fix the issue with the forwardRef and the generics.
 * The default implementation would compile fine, but would set the T type to
 * unknown, which would cause us to lose the type inference.
 */
export const ListBox = forwardRef(ListBoxInternal) as <T>(
  props: ListBoxProps<T> & { ref?: ForwardedRef<HTMLUListElement> }
) => JSX.Element;
